import React from 'react';
import { Tooltip, Modal } from 'antd';
import { I18N_MODULE_KEYS } from '../../../../../Components/i18n/core/constants';
import { Folder } from '../../Core/constants';

export const columnsConfig = (
  t: any,
  PROJECT_ICONS: any,
  isOpenArr: boolean[],
  setIsOpenArr: (arr: boolean[]) => void,
  showDrawer: (filename: string) => void,
  setShowModalforFile: (visible: boolean) => void,
  showModalforFile: boolean,
  handleDeleteDocument: (filename: string) => void,
  selectedRecord: string,
  setSelectedRecord: (filename: string) => void,
  modalRef: React.RefObject<HTMLDivElement>,
  handlestateofChildFolder:(foldername:string,folder:Folder)=>void,
  handleDeleteChildFolder:(documentId:any)=>void
) => [
    {
        title: `Name`,
        dataIndex: 'filename', // This can remain 'filename' or be any key since we handle both in render
        key: 'filename', 
        render: (value: any, record: any, index: any) => {
          const displayValue = record.filename || record.name || "";
          return (<>
          {record.type==="folder"?(<>
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }} onClick={()=>{handlestateofChildFolder(record.name,record)}} >
                <a style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>
          </>):(
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }}>
                <a href={record.s3_url+"?web=1"} target="_blank" rel="noopener noreferrer" style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>)}
            </>
          );
        }
      },
  {
    title: "Type",
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded By"]),
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded Date"]),
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: any, record: any, index: any) => (
      <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }}>
        {value?.split('T')[0]}
      </span>
    )
  },
  {
    title: t(I18N_MODULE_KEYS["semantic.search.filesystem.actions"]),
    dataIndex: 'actions',
    key: 'actions',
    render: (value: any, record: any, index: any) => (
        record.type==="folder"?(<>
        <span style={{ color: '#034EA2', fontWeight: '600', paddingLeft: '0', marginLeft: '0', cursor: 'pointer' }} onClick={()=>{handleDeleteChildFolder(record.name)}}>
        {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}
        </span>
        </>):(
        <>      <div style={{ display: 'flex' }}>
        <span style={{ color: '#034EA2', fontWeight: '600', paddingLeft: '0', marginLeft: '0', cursor: 'pointer' }} onClick={() => showDrawer(record.filename)}>
          {t(I18N_MODULE_KEYS["semantic.search.filesystem.chat"])}
        </span>
        <img src={PROJECT_ICONS.DOTS} style={{ paddingLeft: '30px', color: '#034EA2', fontWeight: '600', cursor: 'pointer' }} onClick={() => {
          const newIsOpenArr = [...isOpenArr];
          newIsOpenArr[index] = !newIsOpenArr[index];
          setIsOpenArr(newIsOpenArr);
        }} />
        {isOpenArr[index] ? (
          <div style={{
            position: 'absolute',
            top: '30px',
            left: '40px',
            width: '50%',
            marginTop: '5px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#fff',
            zIndex: 1000,
          }}>
            <div style={{ padding: '4px', cursor: 'pointer' }} ref={modalRef} onClick={() => {
              setSelectedRecord(record?.filename);
              setShowModalforFile(true);
            }}>
              {t(I18N_MODULE_KEYS["content.button.delete"])}
            </div>
          </div>
        ) : null}
        <Modal
          width={400}
          title=""
          centered
          visible={showModalforFile}
          onCancel={() => { setShowModalforFile(false); }}
          onOk={() => {
            handleDeleteDocument(selectedRecord);
            setShowModalforFile(false);
          }}
          okText={t(I18N_MODULE_KEYS["content.button.yes"])}
          cancelText={t(I18N_MODULE_KEYS["content.button.no"])}
        >
          <div style={{ display: "flex", paddingTop: '10px', paddingBottom: '10px' }}>
            <p style={{ fontWeight: "bold", color: "black" }}>{t(I18N_MODULE_KEYS["semantic.search.filesystem.deletionfileconfirmation"])}</p>
          </div>
        </Modal>
      </div>
      </>)
    )
  },
];

export const imagecolumnsConfig = (
    t: any,
    PROJECT_ICONS: any,
    isOpenArr: boolean[],
    setIsOpenArr: (arr: boolean[]) => void,
    showDrawer: (filename: string) => void,
    setShowModalforFile: (visible: boolean) => void,
    showModalforFile: boolean,
    handleDeleteDocument: (filename: string) => void,
    selectedRecord: string,
    setSelectedRecord: (filename: string) => void,
    modalRef: React.RefObject<HTMLDivElement>,
    handlestateofChildFolder:(foldername:string,folder:Folder)=>void,
  handleDeleteChildFolder:(documentId:any)=>void
  ) => [
    {
        title: `Name`,
        dataIndex: 'filename', // This can remain 'filename' or be any key since we handle both in render
        key: 'filename', 
        render: (value: any, record: any, index: any) => {
          const displayValue = record.filename || record.name || "";
          return (<>
          {record.type==="folder"?(<>
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }} onClick={()=>{handlestateofChildFolder(record.name,record)}} >
                <a style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>
          </>):(
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }}>
                <a href={record.s3_url} target="_blank" rel="noopener noreferrer" style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>)}
            </>
          );
        }
      },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded By"]),
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded Date"]),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value:any, record:any, index:any) => (
      
          
            <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" ,cursor:'pointer'}} >
            {/* {value?.includes(".pdf") ? value : value?.slice(0, 30) + "..." || " "} */}
            {value?.split('T')[0]}
          </span>
          
  
      )
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.actions"]),
      dataIndex: 'actions',
      key: 'actions',
      render: (value:any, record:any, index:any) =>( record.type==="folder"?(<>
        <span style={{ color: '#034EA2', fontWeight: '600', paddingLeft: '0', marginLeft: '0', cursor: 'pointer' }} onClick={()=>{handleDeleteChildFolder(record.name)}}>
        {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}
        </span>
        </>):( <><div style={{display:'flex'}}><span  style={{color:'#034EA2',fontWeight:'600',paddingLeft:'0',marginLeft:'0',cursor:'pointer'}} onClick={()=>{
        setSelectedRecord(record?.filename)
        setShowModalforFile(true)
       }} >   {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}</span>
    
      <img  src={PROJECT_ICONS.DOTS} style={{paddingLeft:'30px',color:'#034EA2',fontWeight:'600',cursor:'pointer'}} ></img>
    
          
   <Modal
    
    width={400}
      title=""
      centered
      visible={showModalforFile}
      onCancel={()=>{setShowModalforFile(false)}}
      onOk={()=>{
        // handleDeleteD(selectedFolderTable?.name)
        handleDeleteDocument(selectedRecord)
        setShowModalforFile(false)
      }}
      okText={ t(I18N_MODULE_KEYS["content.button.yes"])}
cancelText={t(I18N_MODULE_KEYS["content.button.no"])}
    >
      
         <div style={{display:"flex", paddingTop:'10px',paddingBottom:'10px'}}>
                 <p style={{ fontWeight: "bold", color: "black" }}>{t(I18N_MODULE_KEYS["semantic.search.filesystem.deletionimageconfirmation"])}</p>
       
    </div>
    </Modal> 
      </div></>))
    },
  ];


  export const audiocolumnsConfig = (
    t: any,
    PROJECT_ICONS: any,
    isOpenArr: boolean[],
    setIsOpenArr: (arr: boolean[]) => void,
    showDrawer: (filename: string) => void,
    setShowModalforFile: (visible: boolean) => void,
    showModalforFile: boolean,
    handleDeleteDocument: (filename: string) => void,
    selectedRecord: string,
    setSelectedRecord: (filename: string) => void,
    modalRef: React.RefObject<HTMLDivElement>,
    handlestateofChildFolder:(foldername:string,folder:Folder)=>void,
  handleDeleteChildFolder:(documentId:any)=>void
  ) => [
    {
        title: `Name`,
        dataIndex: 'filename', // This can remain 'filename' or be any key since we handle both in render
        key: 'filename', 
        render: (value: any, record: any, index: any) => {
          const displayValue = record.filename || record.name || "";
          return (<>
          {record.type==="folder"?(<>
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }} onClick={()=>{handlestateofChildFolder(record.name,record)}} >
                <a style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>
          </>):(
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }}>
                <a href={record.s3_url} target="_blank" rel="noopener noreferrer" style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>)}
            </>
          );
        }
      },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded By"]),
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded Date"]),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value:any, record:any, index:any) => (
      
          
            <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" ,cursor:'pointer'}} >
            {/* {value?.includes(".pdf") ? value : value?.slice(0, 30) + "..." || " "} */}
            {value?.split('T')[0]}
          </span>
          
  
      )
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.actions"]),
      dataIndex: 'actions',
      key: 'actions',
      render: (value:any, record:any, index:any) =>( record.type==="folder"?(<>
        <span style={{ color: '#034EA2', fontWeight: '600', paddingLeft: '0', marginLeft: '0', cursor: 'pointer' }} onClick={()=>{handleDeleteChildFolder(record.name)}}>
        {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}
        </span>
        </>):(<> <div style={{display:'flex'}}><span  style={{color:'#034EA2',fontWeight:'600',paddingLeft:'0',marginLeft:'0',cursor:'pointer'}} onClick={()=>{
        setSelectedRecord(record?.filename)
        setShowModalforFile(true)
       }} >   {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}</span>
    
      <img  src={PROJECT_ICONS.DOTS} style={{paddingLeft:'30px',color:'#034EA2',fontWeight:'600',cursor:'pointer'}} ></img>
    
          
   <Modal
    
    width={400}
      title=""
      centered
      visible={showModalforFile}
      onCancel={()=>{setShowModalforFile(false)}}
      onOk={()=>{
        // handleDeleteD(selectedFolderTable?.name)
        handleDeleteDocument(selectedRecord)
        setShowModalforFile(false)
      }}
      okText={ t(I18N_MODULE_KEYS["content.button.yes"])}
cancelText={t(I18N_MODULE_KEYS["content.button.no"])}
    >
      
         <div style={{display:"flex", paddingTop:'10px',paddingBottom:'10px'}}>
                 <p style={{ fontWeight: "bold", color: "black" }}>{t(I18N_MODULE_KEYS["semantic.search.filesystem.deletionaudioconfirmation"])}</p>
       
    </div>
    </Modal> 
      </div></>))
    },
  ];


  export const videocolumnsConfig = (
    t: any,
    PROJECT_ICONS: any,
    isOpenArr: boolean[],
    setIsOpenArr: (arr: boolean[]) => void,
    showDrawer: (filename: string) => void,
    setShowModalforFile: (visible: boolean) => void,
    showModalforFile: boolean,
    handleDeleteDocument: (filename: string) => void,
    selectedRecord: string,
    setSelectedRecord: (filename: string) => void,
    modalRef: React.RefObject<HTMLDivElement>,
    handlestateofChildFolder:(foldername:string,folder:Folder)=>void,
  handleDeleteChildFolder:(documentId:any)=>void
  ) =>[
    {
        title: `Name`,
        dataIndex: 'filename', // This can remain 'filename' or be any key since we handle both in render
        key: 'filename', 
        render: (value: any, record: any, index: any) => {
          const displayValue = record.filename || record.name || "";
          return (<>
          {record.type==="folder"?(<>
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }} onClick={()=>{handlestateofChildFolder(record.name,record)}} >
                <a style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>
          </>):(
            <Tooltip title={displayValue}>
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer' }}>
                <a href={record.s3_url} target="_blank" rel="noopener noreferrer" style={{color: '#034EA2'}}>
                  {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                </a>
              </span>
            </Tooltip>)}
            </>
          );
        }
      },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded By"]),
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.Uploaded Date"]),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value:any, record:any, index:any) => (
      
          
            <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" ,cursor:'pointer'}} >
            {/* {value?.includes(".pdf") ? value : value?.slice(0, 30) + "..." || " "} */}
            {value?.split('T')[0]}
          </span>
          
  
      )
    },
    {
      title: t(I18N_MODULE_KEYS["semantic.search.filesystem.actions"]),
      dataIndex: 'actions',
      key: 'actions',
      render: (value:any, record:any, index:any) =>( record.type==="folder"?(<>
        <span style={{ color: '#034EA2', fontWeight: '600', paddingLeft: '0', marginLeft: '0', cursor: 'pointer' }} onClick={()=>{handleDeleteChildFolder(record.name)}}>
        {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}
        </span>
        </>) :(<><div style={{display:'flex'}}><span  style={{color:'#034EA2',fontWeight:'600',paddingLeft:'0',marginLeft:'0',cursor:'pointer'}} onClick={()=>{
        setSelectedRecord(record?.filename)
        setShowModalforFile(true)
       }} >   {t(I18N_MODULE_KEYS["content.button.delete"]).toUpperCase()}</span>
    
      <img  src={PROJECT_ICONS.DOTS} style={{paddingLeft:'30px',color:'#034EA2',fontWeight:'600',cursor:'pointer'}} ></img>
    
          
   <Modal
    
    width={400}
      title=""
      centered
      visible={showModalforFile}
      onCancel={()=>{setShowModalforFile(false)}}
      onOk={()=>{
        // handleDeleteD(selectedFolderTable?.name)
        handleDeleteDocument(selectedRecord)
        setShowModalforFile(false)
      }}
      okText={ t(I18N_MODULE_KEYS["content.button.yes"])}
cancelText={t(I18N_MODULE_KEYS["content.button.no"])}
    >
      
         <div style={{display:"flex", paddingTop:'10px',paddingBottom:'10px'}}>
                 <p style={{ fontWeight: "bold", color: "black" }}>{t(I18N_MODULE_KEYS["semantic.search.filesystem.deletionvideoconfirmation"])}</p>
       
    </div>
    </Modal> 
      </div></>))
    }, 
  ];
 