import { RoutesConstantTypes } from "../Utils/_gTypes";
import Login from "../Pages/Authentication/_Login";
import { Dashboard } from "../Pages/Dashboard";
import { ContentTranscreationWrapper } from "../Pages/Content Transcreation";
import Signup from "../Pages/Authentication/_Signup";
import { SemanticSearchWrapper } from "../Pages/Semantic Search";
import { ContentHistoryWrapper } from "../Pages/Content Transcreation/Content History";
import VerifiedPage from "../Pages/Authentication/_VerifiedPage";
import Forgot from "../Pages/Authentication/_Forgot";
import { ContentAIWrapper } from "../Pages/Content AI";
import { UseCasesLibraryWrapper } from "../Pages/Usecase Library";
import { ENUM_ROUTE_URLS } from "../Utils";
import { ContentTranslationWrapper } from "../Pages/Content Transcreation/Content Translation/ContentTranslationWrapper";
import { IDetailExtractWrapper } from "../Pages/IDetail Extract";
import { TranslationModuleWrapper } from "../Pages/Translation Module";


const PublicRoutes: RoutesConstantTypes[] = [
  {
    path: ENUM_ROUTE_URLS.LOGIN,
    component: <Login />
  },
  {
    path: ENUM_ROUTE_URLS.SIGNUP,
    component: <Signup />
  },
  {
    path: ENUM_ROUTE_URLS.VERIFIEDPAGE,
    component: <VerifiedPage />
  },
  {
    path: ENUM_ROUTE_URLS.FORGOT,
    component: <Forgot />
  }
];

const PrivateRoutes: RoutesConstantTypes[] = [
  {
    path: ENUM_ROUTE_URLS.DASHBOARD,
    component: <Dashboard />
  },
  {
    path: ENUM_ROUTE_URLS.CONTENT_TRANSCREATION,
    component: <ContentTranscreationWrapper />
  },
  {
    path: ENUM_ROUTE_URLS.SEMANTIC_SEARCH,
    component: <SemanticSearchWrapper />
  },
  {
    path: ENUM_ROUTE_URLS.CONTENT_HISTORY,
    component: <ContentHistoryWrapper />
  },
  {
    path: ENUM_ROUTE_URLS.CONTENT_TRANSLATION,
    component: <ContentTranslationWrapper />
  },
  {
    path: ENUM_ROUTE_URLS.IDETAIL_EXTRACT,
    component: <IDetailExtractWrapper />
  },
  {
    path: ENUM_ROUTE_URLS.TRANSLATION_MODULE,
    component: <TranslationModuleWrapper />
  },

  ...[
    ENUM_ROUTE_URLS.CONTENT_TRANSCREATION,
    ENUM_ROUTE_URLS.SEMANTIC_SEARCH,
    ENUM_ROUTE_URLS.INTELLIGENT_ASSISTANT,
    ENUM_ROUTE_URLS.CONTENT_AI,
    ENUM_ROUTE_URLS.FIELD_COPILOT,
    ENUM_ROUTE_URLS.HYPER_PERSONALISATION,
    ENUM_ROUTE_URLS.SENTIMENT_ANALYSIS,
    ENUM_ROUTE_URLS.KOL_VIDEO,
    ENUM_ROUTE_URLS.GEN_AI_DASHBOARD_ASSISTANT,
    ENUM_ROUTE_URLS.PATIENT_JOURNEY_MAPPING,
    ENUM_ROUTE_URLS.TRANSLATION_LOCALISATION,
    ENUM_ROUTE_URLS.PERSONALIZED_VIDEO,
    ENUM_ROUTE_URLS.CONTENT_CREATION,
    ENUM_ROUTE_URLS.IDETAIL_EXTRACT,
    ENUM_ROUTE_URLS.TRANSLATION_MODULE
  ].map((path) => ({
    path: ENUM_ROUTE_URLS.USE_CASES + path,
    component: <UseCasesLibraryWrapper />
  })),

  {
    path: ENUM_ROUTE_URLS.CONTENT_AI,
    component: <ContentAIWrapper />
  }
];

export { PublicRoutes, PrivateRoutes, ENUM_ROUTE_URLS };
