export const TM_SET_MAIN_TABS = "translationModule/TM_SET_MAIN_TABS";
export const TM_SET_ACTIVE_SCREEN = "translationModule/TM_SET_ACTIVE_SCREEN";
export const TM_SET_OPEN_ADD_GLOSSARY_MODAL = "translationModule/TM_SET_OPEN_ADD_GLOSSARY_MODAL";
export const TM_SET_LANGUAGE_LIST = "translationModule/TM_SET_LANGUAGE_LIST";

export const TM_SET_UPLOAD_DOCS_DATA = "translationModule/TM_SET_UPLOAD_DOCS_DATA";
export const TM_SET_TEXT_TRANSLATION_DATA = "translationModule/TM_SET_TEXT_TRANSLATION_DATA";
export const TM_SET_FILE_TRANSLATION_API_RESPONSE = "translationModule/TM_SET_FILE_TRANSLATION_API_RESPONSE";
export const TM_SET_FT_SELECTED_FILE = "translationModule/TM_SET_FT_SELECTED_FILE";
export const TM_SET_FT_SELECTED_LANGUAGE = "translationModule/TM_SET_FT_SELECTED_LANGUAGE";
export const TM_SET_FT_TRANSLATE_FILE_FLAG = "translationModule/TM_SET_FT_TRANSLATE_FILE_FLAG";
export const TM_SET_FILE_TRANSLATION_RESET_DATA = "translationModule/TM_SET_FILE_TRANSLATION_RESET_DATA";

export const TM_SET_GLOSSARY_LIST = "translationModule/TM_SET_GLOSSARY_LIST";
