import { setLoginDetailsAction, setSessionExpiredFlagAction, setUserDetailsAction } from "../Store/actions/_authActions";
import _localStorageService from "./_localStorageService";
import { setLoadingStatusAction, setNotificationMsgAction } from "../Store/actions/_commonActions";
import { globalConstant } from "../Utils";
import { toast } from "react-toastify";
import LogRocket from "logrocket";
export class Authorizer {
  BASE_URL = process.env.REACT_APP_AUTHORIZER_URL || "";
  METHODS = { GET: "GET", POST: "POST" };
  QUERIES = {
    LOGIN:
      "\nmutation login($data: LoginInput!) { login(params: $data) { message access_token expires_in refresh_token id_token user { id email email_verified given_name family_name middle_name nickname preferred_username picture signup_methods gender birthdate phone_number phone_number_verified roles created_at updated_at is_multi_factor_auth_enabled  }}}\n                ",
    SIGNUP:
      "\nmutation signup($data: SignUpInput!) { signup(params: $data) { message access_token expires_in refresh_token id_token user { id email email_verified given_name family_name middle_name nickname preferred_username picture signup_methods gender birthdate phone_number phone_number_verified roles created_at updated_at is_multi_factor_auth_enabled  }}}\n        ",
    FORGOT: "\nmutation forgotPassword($data: ForgotPasswordInput!) {	forgot_password(params: $data) { message } }\n",
    VERIFY_AUTH:
      '{"query":"query {\n  validate_jwt_token(\n    params: { token_type: "access_token", token: "<access_token>"  }\n  ) {\n    is_valid\n  }\n}\n","variables":null}'
  };

  async generateXhrRequest() {
    try {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr;
    } catch (err) {
      console.log(`Authorizer::generateXhrRequest >> ${err}`);
    }
  }

  async callAPI(xhr: XMLHttpRequest, method: string, data: string) {
    try {
      xhr?.open(method, this.BASE_URL);
      xhr?.setRequestHeader("Content-Type", "application/json");
      xhr?.send(data);
    } catch (err) {
      console.log(`Authorizer::callAPI >> ${err}`);
    }
  }

  async doLogin(variables: { email: string; password: string }, dispatch: any) {
    try {
      dispatch(setLoadingStatusAction(true));

      const xhr = await this.generateXhrRequest();

      if (!xhr) throw new Error("Xhr should be defined");

      await this.callAPI(
        xhr,
        this.METHODS.POST,
        JSON.stringify({
          query: this.QUERIES.LOGIN,
          variables: { data: { ...variables } }
        })
      );

      await xhr?.addEventListener("readystatechange", function () {
        dispatch(setLoadingStatusAction(false));
        console.log("readystatechange");

        const { readyState, responseText } = this;
        if (readyState === 4) {
          const objParsedRes = JSON.parse(responseText);

          if (objParsedRes?.data?.login) {
            const { user, access_token, expires_in, id_token, message } = objParsedRes?.data?.login;

            _localStorageService.setToken(access_token);

            dispatch(
              setLoginDetailsAction({
                access_token,
                expires_in,
                id_token,
                message
              })
            );

            if (user) {
              dispatch(setUserDetailsAction(user));

              LogRocket.identify("USER_ID", {
                name: user?.preferred_username,
                email: user?.email
              });
            } else {
              dispatch(setNotificationMsgAction(globalConstant.INVALID_CREDENTIALS));
            }
          } else {
            dispatch(setNotificationMsgAction(globalConstant.INVALID_CREDENTIALS));
          }
        }
      });
    } catch (err) {
      console.log(`Authorizer::doLogin >> ${err}`);
      dispatch(setLoadingStatusAction(false));
      dispatch(setNotificationMsgAction("Error while logging in"));
    }
  }

  async doSignup(variables: { email: string; password: string; confirm_password: string }, dispatch: any): Promise<string | undefined> {
    // Return a Promise<string | undefined>
    console.log(variables);
    try {
      const xhr = await this.generateXhrRequest();

      if (!xhr) throw new Error("Xhr should be defined");

      return new Promise<string | undefined>((resolve, reject) => {
        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            const objParsedRes1 = JSON.parse(this.responseText);
            if (objParsedRes1?.data?.signup?.message) {
              dispatch(setNotificationMsgAction(globalConstant.SUCCESSFUL_SIGNUP));
            } else {
              dispatch(setNotificationMsgAction(objParsedRes1?.errors[0]?.message));
            }
            resolve(objParsedRes1?.data?.signup?.message);
          }
        });

        const data = {
          query: this.QUERIES.SIGNUP,
          variables: { data: { ...variables } }
        };

        this.callAPI(xhr, this.METHODS.POST, JSON.stringify(data));
      });
    } catch (err) {
      console.log(`Authorizer::doSignup >> ${err}`);
      // Reject the Promise on error
    }
  }

  async doForgot(variables: { email: string }, dispatch: any): Promise<string | undefined> {
    // Return a Promise<string | undefined>
    console.log(variables);
    try {
      const xhr = await this.generateXhrRequest();

      if (!xhr) throw new Error("Xhr should be defined");

      return new Promise<string | undefined>((resolve, reject) => {
        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            const objParsedRes2 = JSON.parse(this.responseText);
            console.log(objParsedRes2);
            if (objParsedRes2?.data?.forgot_password?.message) {
              dispatch(setNotificationMsgAction(objParsedRes2?.data?.forgot_password?.message));
            } else {
              dispatch(setNotificationMsgAction(objParsedRes2?.errors[0]?.message));
            }
            resolve(objParsedRes2?.data?.signup?.message);
          }
        });

        const data = {
          query: this.QUERIES.FORGOT,
          variables: { data: { ...variables } }
        };

        this.callAPI(xhr, this.METHODS.POST, JSON.stringify(data));
      });
    } catch (err) {
      console.log(`Authorizer::doSignup >> ${err}`);
      // Reject the Promise on error
    }
  }

  async verifyAuth(access_token: string, dispatch: any): Promise<string | undefined> {
    try {
      const xhr = await this.generateXhrRequest();

      if (!xhr) throw new Error("Xhr should be defined");

      return new Promise<string | undefined>((resolve, reject) => {
        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            const objParsedRes = JSON.parse(this.responseText);

            if (objParsedRes?.data?.validate_jwt_token?.is_valid === true) {
              resolve("true");
              return;
            }

            resolve("");

            dispatch(setSessionExpiredFlagAction(true));
            // alert("session expired")
          }

          // resolve("");
        });

        const data = {
          query: `\nquery {\n  validate_jwt_token(\n    params: { token_type: "access_token", token: "${access_token}" }\n  ) {\n    is_valid\n  }\n}\n                `,
          variables: {}
        };

        this.callAPI(xhr, this.METHODS.POST, JSON.stringify(data));
      });
    } catch (err) {
      console.log(`Authorizer::verifyAuth >> ${err}`);
      // Reject the Promise on error
    }
  }

  //
}
