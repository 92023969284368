import { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import "../../Assets/Css/IDetailExtract.css";
import { CoreClaimMain } from "./_CoreClaimMain";
import { UploadFileCard } from "./_UploadFileCard";
import { CategoriesModal } from "./_CategoriesModal";
import { ListKeyMessagesTypes, UpdateAssetGuide } from "./core/modal";
import { apiResponse } from "../../Utils/_gTypes";
import _iDetailExtractRequests from "../../Store/requests/_iDetailExtractRequests";
import { siteConfig } from "../../Services";
import { useDispatch } from "react-redux";
import {
  setActiveScreenIDXAction,
  setAssetGuideDataIDXAction,
  setOpenCategoryModalStateIDXAction,
  setSelectedCategoriesIDXAction,
  setUploadedPdfDataAction
} from "../../Store/actions/_iDetailExtractActions";
import { enumIDXActiveScreen } from "./core/constant";
import FinalHTMLOutputModal from "./FinalHTMLOutputModal";

export const IDetailExtractWrapper: FC = () => {
  const dispatch = useDispatch();

  const { uploadedPDFData, openCategoryModal, assetGuideData, activeScreen } = useSelector((state: any) => state?.iDetailExtractReducer);

  const [listKeyMessages, setListKeyMessages] = useState<ListKeyMessagesTypes | null>(null);

  siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_IDETAIL_EXTRACT;

  useEffect(() => {
    // dispatch(setActiveScreenIDXAction(enumIDXActiveScreen.CORE_CLAIM_SCREEN));

    return () => {
      cleanUpFunc();
    };
  }, []);

  const cleanUpFunc = () => {
    dispatch(setUploadedPdfDataAction(null));
    dispatch(setAssetGuideDataIDXAction(null));
    dispatch(setSelectedCategoriesIDXAction([]));
    dispatch(setOpenCategoryModalStateIDXAction(false));
    dispatch(setActiveScreenIDXAction(enumIDXActiveScreen.UPLOAD_PDF));
  };

  const handleAfterUploadingFile = async (status: boolean, fileId: number) => {
    const res: apiResponse = await _iDetailExtractRequests.getListKeyMessages(fileId);

    if (res?.data) {
      setListKeyMessages(res?.data);

      dispatch(setOpenCategoryModalStateIDXAction(true));
    }
  };

  const handleAfterProceed = async (assetGuideData: UpdateAssetGuide) => {
    if (assetGuideData.assetguide_id) {
      const res: apiResponse = await _iDetailExtractRequests.getClaimsData(assetGuideData.assetguide_id);

      if (res?.data?.assetguide_id) {
        dispatch(setAssetGuideDataIDXAction(res?.data));

        dispatch(setOpenCategoryModalStateIDXAction(false));

        dispatch(setActiveScreenIDXAction(enumIDXActiveScreen.CORE_CLAIM_SCREEN));
      }
    }
  };

  return (
    <Row gutter={[16, 16]} className="IDetailExtract">
      <Col xs={{ span: 24 }}>
        {activeScreen === enumIDXActiveScreen.UPLOAD_PDF && (
          <UploadFileCard afterUploading={(status, fileId) => handleAfterUploadingFile(status, fileId)} cancelPDFUpload={cleanUpFunc} />
        )}

        <CategoriesModal
          openModal={openCategoryModal}
          listKeyMessages={listKeyMessages}
          setListKeyMessage={(data) => setListKeyMessages(data)}
          afterProceed={(data: UpdateAssetGuide) => handleAfterProceed(data)}
          closeModal={() => dispatch(setOpenCategoryModalStateIDXAction(false))}
        />

        {activeScreen === enumIDXActiveScreen.CORE_CLAIM_SCREEN && (
          <CoreClaimMain assetGuideData={assetGuideData} filename={uploadedPDFData?.name as string} cancelPDFUpload={cleanUpFunc} />
        )}
      </Col>
      <FinalHTMLOutputModal />
    </Row>
  );
};
