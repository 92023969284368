import { FC } from "react";
import { Col, Row } from "antd";
import { CoreClaimPdfDocEditor, CoreClaimPdfPreview, HeaderTab } from "./Components";
import { UpdateAssetGuide } from "./core/modal";

type IProps = {
  filename: string;
  cancelPDFUpload: () => void;
  assetGuideData: UpdateAssetGuide | null;
};

export const CoreClaimMain: FC<IProps> = ({ filename, cancelPDFUpload, assetGuideData }) => {
  return (
    <Row gutter={[16, 8]}>
      <Col xs={{ span: 24 }}>
        <HeaderTab filename={filename} cancelPDFUpload={cancelPDFUpload} />
      </Col>
      <CoreClaimPdfPreview fileURL={assetGuideData?.file_url || ""} />
      <CoreClaimPdfDocEditor
        assetGuideId={assetGuideData ? assetGuideData.assetguide_id : null}
        coreClaims={assetGuideData ? assetGuideData.claims : null}
      />
    </Row>
  );
};
