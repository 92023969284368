import { Button, Col, Image, Input, Row, Spin, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { Folder } from "../../Core/constants";
const { Paragraph } = Typography;
interface RightCardDisplayingPathandSubFolderProps {
  pathName: Array<{ name: string }>; // Array representing the path structure, with each item containing a name
  setModalForSubFolderTrue: (value: boolean) => void; // Function to toggle the modal for creating a sub-folder
  getColumns: (filecardType: any) => (any[] | undefined); // Allow undefined
  dataSource: Array<any>; // DataSource for the Table component, can be more specific based on the structure
  handleUploadDocumentreverse: () => void; // Function to handle the upload document action
  handleToggleBetweenParentChildFolders: (folder: Folder) => void; // Updated to use Folder type
}

export const RightCardDisplayingPathandSubFolder: React.FC<RightCardDisplayingPathandSubFolderProps> = ({
  pathName,
  setModalForSubFolderTrue,
  getColumns,
  dataSource,
  handleUploadDocumentreverse,
  handleToggleBetweenParentChildFolders,
}) => {
  const { tabList, filecardType } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  return (
    <>
      {" "}
      <Row justify="space-between">
        <Col style={{ display: "flex" }} xs={{ span: 12 }}>
          <Paragraph style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "16px" }}>Folder Path : </Paragraph>
          <p style={{ marginLeft: "5px", fontWeight: "bold", fontSize: "16px" }}>
            {pathName.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <a
                  style={{ color: "#66676B", cursor: "pointer" }}
                  onClick={() => {
                    handleToggleBetweenParentChildFolders(item);
                  }}
                >
                  {" "}
                  {item?.name}
                </a>
                {index < pathName.length - 1 && <span style={{ marginLeft: "5px", marginRight: "5px" }}>{">"}</span>}
              </React.Fragment>
            ))}
          </p>{" "}
        </Col>
        <Col style={{}}>
          <Button
            type="link"
            style={{ height: "40px", color: "#1e4da1", fontWeight: "bold" }}
            onClick={() => {
              setModalForSubFolderTrue(true);
            }}
          >
            Create Sub-Folder
          </Button>

          <Button style={{ height: "40px", background: "#1e4da1", color: "white" }} onClick={handleUploadDocumentreverse}>
            {t(I18N_MODULE_KEYS["semantic.search.filesystem.uploaddocument"])}
          </Button>
        </Col>
      </Row>
      <div style={{ height: "420px", overflowY: "auto" }}>
        <Table columns={getColumns(filecardType)} dataSource={dataSource} pagination={false} />
      </div>
    </>
  );
};
