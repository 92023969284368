type BreakSegment = {
  time: number;
  content: string;
};

class BreakParser {
  private input: string;
  private index: number = 0;

  constructor(input: string) {
    this.input = input;
  }

  private parseNumber(): number | undefined {
    let numStr = "";
    while (!isNaN(Number(this.input[this.index])) || this.input[this.index] === ".") {
      numStr += this.input[this.index];
      this.index++;
    }
    return parseFloat(numStr) || undefined;
  }

  private parseContent(): string | undefined {
    let content = "";
    while (this.index < this.input.length && this.input[this.index] !== "<") {
      content += this.input[this.index];
      this.index++;
    }
    return content.trim() || undefined;
  }

  public parse(): (string | number)[] {
    try {
      const segments: (string | number)[] = [];
      let currentContent = "";

      while (this.index < this.input.length) {
        if (this.input[this.index] === "<" && this.input.substr(this.index, 7) === "<break ") {
          if (currentContent) {
            segments.push(currentContent);
            currentContent = "";
          }

          this.index += 7; // skip '<break '
          while (this.input[this.index] !== "'") {
            this.index++;
          }
          this.index++; // skip opening quote
          const time = this.parseNumber();
          if (time !== undefined) {
            segments.push(time);
          }
          this.index++; // skip closing quote
          this.index += 3; // skip ' />'
        } else {
          currentContent += this.input[this.index];
          this.index++;
        }
      }

      if (currentContent) {
        segments.push(currentContent);
      }

      return segments;
    } catch (err) {
      console.log("Break Parser parse()", err);
      return [];
    }
  }
}

export default BreakParser;
