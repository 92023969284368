import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Lottie from "lottie-react";
import { ToastContainer } from "react-toastify";
import { Row, Col, Form, Button, message } from "antd";

import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import _localStorageService from "../../Services/_localStorageService";

import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";

import "../../Assets/Css/Authentication/login.css";

const VerifiedPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (location.state && location.state.from === "/dashboard") {
      message.success("Successfully logged out!");
    }
  }, [location]);

  return (
    <>
      <Form labelAlign="left" labelCol={{ span: 24 }} form={form} className="authentication container1">
        <Row gutter={16}>
          <Col span={12} className="section1">
            <Row className="animcontainer">
              <Col className="animation">
                <Lottie id="email" animationData={PROJECT_ICONS.EMAIL_ANIMATION} loop={true} />
              </Col>
              <Col className="animation-text-wrapper">
                <p>{t(I18N_MODULE_KEYS["content.login.text.customizeyouremailusingai"])}</p>
                <p>{t(I18N_MODULE_KEYS["content.login.text.convertyouremailintoHTMLCraftImagesand"])}</p>
                <p>{t(I18N_MODULE_KEYS["content.login.text.weavevideostoleavealasting"])}</p>
              </Col>
            </Row>
          </Col>
          <Col span={12} className="login-container">
            <div className="flex" style={{ paddingLeft: "250px" }}>
              <Col span={12} className="form-conatiner">
                <Lottie id="emailverificationsent" animationData={PROJECT_ICONS.SUCCESS} loop={true} height={"100px"} />
              </Col>
              <Col span={12}>{t(I18N_MODULE_KEYS["content.verifiedpage.text.signupsuccessfull"])}</Col>
              <Col span={12}>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(ENUM_ROUTE_URLS.LOGIN);
                  }}
                >
                  {/* Go to Login Page */}
                  {t(I18N_MODULE_KEYS["content.forgot.text.gotologinpage"])}
                </Button>
              </Col>
            </div>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </>
  );
};

export default VerifiedPage;
