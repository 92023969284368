import React, { useState, useEffect } from "react";
import { Layout, Image, Row, Col, Input, Modal } from "antd";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import { useSelector, useDispatch } from "react-redux";
import {
  setEditableScreen,
  setLoadingforReportGeneration,
  setPdfForReports,
  setSlidesForReports,
} from "../../../../../Store/actions/_semanticSearchActions";
import _localStorageService from "../../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { generatePdf } from "../../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";

export const ModalLoaderForPDFGenerationRegenerate: React.FC<any> = ({ setContent, setPdfId, setReportId, editorRef }) => {
  const { loadingForReportGeneration, conversationId } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const accessToken = token || "";
  // const [retryEnable, setRetryEnable] = useState(false);

  useEffect(() => {
    const generateReport = async () => {
      const res = await generatePdf({ conversation_id: conversationId, regenerate: true });
      if (!res) {
        dispatch(setNotificationMsgAction("Please try again for regenerating the PDF."));
        dispatch(setLoadingforReportGeneration(false));
        // setRetryEnable(true);
        return;
      }
      if (editorRef.current) {
        editorRef.current.setContent(res?.content);
      }
      dispatch(setPdfForReports(res));
      setPdfId(res?.pdf_id);
      setReportId(res?.report_id);
      setContent(res?.content);
      dispatch(setEditableScreen(true));
      dispatch(setLoadingforReportGeneration(false));
    };

    generateReport();
  }, []);
  const handleCancel = async () => {
    dispatch(setLoadingforReportGeneration(false));
  };


  return (
    <>
      <Modal
        visible={loadingForReportGeneration}
        footer={null}
        closable={false}
        centered
        onCancel={() => {
          dispatch(setLoadingforReportGeneration(false));
        }}
        maskStyle={{
          backdropFilter: "blur(30px)", // Background blur effect
          background: "rgba(44, 78, 101, 0.5)", // semi-transparent background with the color #2C4E65}}
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "40px" }}>
          <Lottie animationData={PROJECT_ICONS.COFFEE} style={{ height: "140px", width: "100px" }} />
          <span style={{ fontSize: "16px", fontWeight: "500", color: "#515151", textAlign: "center" }}>Hey, John Doe, your PDF is regenerating.</span>
          <span style={{ fontSize: "16px", fontWeight: "500", color: "#515151", textAlign: "center" }}>Please wait and grab a cup of coffee.</span>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <button
            style={{
              background: "#1E4DA1",
              border: "1px solid #1E4DA1",
              color: "white",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "4px",
              padding: "6px 12px",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            Cancel
          </button>
          
        </div>
      </Modal>
    </>
  );
};
