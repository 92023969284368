import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useDispatch } from "react-redux";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { PostSemanticChatSession, getChats, getChatsSpecific, getQuestionRecommendation } from "../../../../Store/requests/_semanticSearchRequests";
import _localStorageService from "../../../../Services/_localStorageService";
import { setAdvancedFilter, setFilterListAction, setAdvancedFilterStatus } from "../../../../Store/actions/_semanticSearchActions";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { useEffect } from "react";
// import Markdown from "markdown-to-jsx";
import Markdown from "markdown-to-jsx";
import { PostSemanticChat } from "../../../../Store/requests/_semanticSearchRequests";
import { ComponentChatWithDocProps, SSEData } from "../Core/constants";   
import "./_ChatWithDocumentComponent.css"
interface Folder {
  id: number;
  name: string;
  files: Record<string, string>; // A record of file name to file URL
}

export const ChatWithDocumentComponent: React.FC<ComponentChatWithDocProps> = ({
  drawervisible,
  filenamefromnextinsight,
  setfilename,
  setdrawervisible,
}) => {
  const { searchQuery, initialFolders, Format, cardData } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const folders = initialFolders as Folder[];
  const token = _localStorageService.getAccessToken();
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>();
  const [drawerVisible, setDrawerVisible] = useState(drawervisible);
  const [userInput, setUserInput] = useState("");
  const [userInputForSearch, setUserInputForSearch] = useState("");
  const [id, setId] = useState("");
  const [msgid, setMsgId] = useState("");
  const [sseData, setSSEData] = useState<SSEData[]>([]);
  const [filename, setFileName] = useState<string>(filenamefromnextinsight);
  const [currentid, setCurrentid] = useState<any>(0);
  const [disable, setInputDisabled] = useState(false);
  const [s3url, sets3url] = useState<String>("");
  const scrollableDivRef = useRef<HTMLDivElement>(null); // Ref for the scrollable div
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isopenforFolderTime, setisopenforFolderTime] = useState<boolean>(false);
  const [downloadinprogressfolder, setDownloadProgressFolder] = useState<Boolean>(false);
  const [previouschats, setpreviouschats] = useState<any[]>([]);
  const [isloadingpreviouschats, setisloadingpreviouschats] = useState<boolean>(false);
  const [recommendQuestion, setRecommendQuestion] = useState<any[]>([]);
  const dropdownOptionsForTimeSpan = [
    { id: "1d", labelKey: t(I18N_MODULE_KEYS["chatdocument.search.today"]) },
    { id: "7d", labelKey: t(I18N_MODULE_KEYS["chatdocument.search.7days"]) },
    { id: "1m", labelKey: t(I18N_MODULE_KEYS["chatdocument.search.lastmonth"]) },
    { id: "1y", labelKey: t(I18N_MODULE_KEYS["chatdocument.search.lastyear"]) },
  ];

  const staticQuestionOptions = [
    { text: "Provide summary of this document", action: "Provide summary of this document" },
    { text: "Give interesting insights from this document", action: "Give interesting insights from this document" },
  ];
  const getchatsfunctioninitial = async () => {
    setisloadingpreviouschats(true);
    const res = await getChats({ filename: filename });
    if (!res) {
      setpreviouschats([]);
      setisloadingpreviouschats(false);
      return;
    }

    console.log(res);
    setpreviouschats(res?.data);
    setisloadingpreviouschats(false);
  };

  useEffect(() => {
    const getchatsfunction = async () => {
      setisloadingpreviouschats(true);
      const res = await getChats({ filename: filename });
      if (!res) {
        setpreviouschats([]);
        setisloadingpreviouschats(false);
        return;
      }
      const resp = await getQuestionRecommendation({ filename: filename });
      if (!resp) {
        setpreviouschats([]);
        setisloadingpreviouschats(false);
        return;
      }
      console.log(res);
      console.log(resp);
      setRecommendQuestion(resp?.data);
      setpreviouschats(res?.data);
      console.log("It is working opening");
      setisloadingpreviouschats(false);
    };
    if (drawerVisible) {
      getchatsfunction();
    }
  }, [drawerVisible]);
  const handleUserInput = (event: any) => {
    setUserInput(event.target.value);
    setUserInputForSearch(event.target.value);
  };

  useEffect(() => {
    setSelectedFolder(folders[0]);
  }, []);

  const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName || "file"); // Set the filename

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const ResetEverything = () => {
    setUserInput("");
    setUserInputForSearch("");
    setisopenforFolderTime(false);
    setDownloadProgressFolder(false);
    setCurrentid("");
    setId("");
    setSSEData([]);
    setMsgId("");
    setFileName("");
    setInputDisabled(false);
    setDrawerVisible(false);
    setfilename("");
    setdrawervisible(false);
  };
  const handlePostRequest = async (s?:any) => {
    const question = s || userInput;
    if (question) {
      setInputDisabled(true);
  
      let res;
      if (id) {
        res = await PostSemanticChatSession({ filename: filename, question: question, chat_id: id });
      } else {
        res = await PostSemanticChat({ filename: filename, question: question });
      }
  
      if (!res) {
        setInputDisabled(false);
        return;
      }
  
      setId(res?.data?.chat_id);
      setMsgId(res?.data?.message_id);
      setCurrentid(res?.data?.chat_id);
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      }
  
      console.log(res?.chat_id, "chatid");
      console.log(res);
  
      setUserInputForSearch("");
  
      if (!id) {
        getchatsfunctioninitial();
      }
  
      // If a string was passed in, update userInput state
      if (s) {
        setUserInput(s);
      }
    }
  };
  
  useEffect(() => {
    console.log("m on door");
    if (id) {
      if (userInput.trim() !== "") {
        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
        setSSEData((prevData) => [
          ...prevData,
          {
            id: msgid,
            question: userInput,
            answer: "Waiting for response...",
            created_at: "", // Set to empty string
            interested: null, // Set to null
            page_number: null, // Set to null
            k: 0, // Set to zero
            score_threshold: 0, // Set to zero
            status: "",
          },
        ]);
      }

      const accessToken = token || ""; // Provide a default value if it's null
      console.log(accessToken);
      const headers: Record<string, string> = {
        Authorization: accessToken,
      };

      const eventSource = new EventSourcePolyfill(`${apiurl}/document/chat/${msgid}`, {
        headers,
      });

      eventSource.onmessage = (event) => {
        console.log("data", event);
      };

      eventSource.addEventListener("end", (event) => {
        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
        console.log("end", event);
        const messageEvent = event as MessageEvent;
        console.log("end", event);
        const newResult = messageEvent?.data; // Assuming data is JSON
        setSSEData((prevData) => {
          // Update the specific entry matching the userInput
          console.log(currentid + "This is the one to be matched");
          const updatedData = prevData.map((data) => (data.id === msgid ? { ...data, answer: newResult } : data));

          return updatedData;
        });
        eventSource.close();
        setInputDisabled(false);
        setUserInput("");
      });

      eventSource.onerror = (error) => {
        console.error("SSE Error:", error);
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.connectionerror"])));
        eventSource.close();
        setInputDisabled(false);
        setUserInput("");
        // Enable input on SSE error
      };

      return () => {
        console.log("Fired Closed");
        eventSource.close();
      };
    }
  }, [msgid]);

  const handleDownloadForFolder = (s: string) => {
    setDownloadProgressFolder(true);
    const accessToken = token || ""; // Provide a default value if it's null
    console.log(accessToken);
    const headers: Record<string, string> = {
      Authorization: accessToken,
    };
    const eventSource = new EventSourcePolyfill(`${apiurl}/document/chat/download?chat_id=${id}&time=${s}`, {
      headers,
    });
    eventSource.onmessage = (event) => {
      console.log("data", event);
    };

    eventSource.addEventListener("end", (event) => {
      console.log("end", event);
      const messageEvent = event as MessageEvent;
      console.log("end", event);
      const newResult = messageEvent?.data;
      sets3url(newResult);
      downloadFile(newResult, newResult);
      setDownloadProgressFolder(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["chat.drawer.notification.chatdownload"])));
      eventSource.close();
    });
    eventSource.addEventListener("error", (event) => {
      eventSource.close();
      setDownloadProgressFolder(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["chat.drawer.notification.errorchat"])));
      console.log("error", event);
    });
   
    return () => {
      console.log("Fired Closed");
      setDownloadProgressFolder(false);
      eventSource.close(); // Close the SSE connection when component unmounts or ID changes
    };

    // Close the SSE connection when component unmounts or ID changes
  };
  
  const handleCallChatSession = async (id: any) => {
    const res = await getChatsSpecific({ filename: filename, chat_id: id });
    setInputDisabled(false);
    setId(id);
    setSSEData(res?.data[0]?.messages);
    console.log(res?.data[0]?.messgages + "iclicked here");
    console.log(sseData + "iclicked here");
  };

  return (
    <>
      <Drawer
        placement="right"
        width={1300}
        visible={drawerVisible}
        closable={false} // Hides the default close button
        mask={false}
        style={{ background: "#EEF4FF", padding: "8px" }}
      >
        <div className="chatwithdocument-drawer-content">
          <div className="chatwithdocument-left-pane">
            <div className="chatwithdocument-header">
              <div style={{ fontSize: "16px", display: "flex" }}>
                <p style={{ fontWeight: "700" }}>{t(I18N_MODULE_KEYS["chatdocument.search.chathistory"])}</p>
              </div>
              <div style={{ color: "#034EA2" }}>{t(I18N_MODULE_KEYS["chatdocument.search.clearall"])}</div>
            </div>
            <div></div>
            {isloadingpreviouschats ? (
              <>
                <Spin></Spin>
              </>
            ) : (
              <>
                {previouschats?.length > 0 ? (
                  previouschats?.map((item) => (
                    <div
                      key={item.id}
                      className="chatwithdocument-chat-item"
                      onClick={() => {
                        handleCallChatSession(item.chat_id);
                      }}
                    >
                      <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
                        <img className="chatwithdocument-chat-item-icon" src={PROJECT_ICONS.MESSAGE} alt="Message Icon" />
                        <div>
                          <div style={{ fontSize: "16px", display: "flex" }}>
                            <Tooltip title={item.title}>
                              <p style={{ marginLeft: "2px", fontWeight: "bold" }}>{item?.title?.slice(0, 35)}...</p>
                            </Tooltip>
                          </div>
                          <div style={{ display: "flex" }}>
                            <img className="chatwithdocument-chat-item-pages-icon" src={PROJECT_ICONS.UNION} alt="Union Icon" />
                            <p className="chatwithdocument-chat-item-pages-text ">7 {t(I18N_MODULE_KEYS["chatdocument.search.pages"])}</p>
                          </div>
                        </div>
                      </div>
                      <div className="chatwithdocument-chat-item-time">{item?.days_since_last_use}d</div>
                    </div>
                  ))
                ) : (
                  <div style={{ display: "flex", padding: "20px", fontSize: "16px", fontWeight: "600" }}>
                    {t(I18N_MODULE_KEYS["chatdocument.search.notfound"])}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="chatwithdocument-right-pane">
            <div className="chatwithdocument-right-header">
              <div style={{ padding: "10px", display: "flex", gap: "30px" }}>
                <img style={{ fontSize: "16px", height: "30px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                <div>
                  <div className="chatwithdocument-right-header-title">
                    <p style={{ color: "#66676B", fontWeight: "500" }}>{t(I18N_MODULE_KEYS["chatdocument.search.chatwith"])}</p>
                    <Tooltip title={filename}>
                      <p style={{ marginLeft: "5px", fontWeight: "bold",color:'black' }}>{filename?.slice(0, 36)}...</p>
                    </Tooltip>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img style={{ paddingTop: "5px", height: "18px" }} src={PROJECT_ICONS.UNION} />
                    <p style={{ color: "#66676B", paddingTop: "2px", paddingLeft: "5px" }}>7 {t(I18N_MODULE_KEYS["chatdocument.search.pages"])}</p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "90px", padding: "10px" }}>
                <div
                  className="chatwithdocument-right-header-download"
                  onClick={() => {
                    {
                      !downloadinprogressfolder ? setisopenforFolderTime(!isopenforFolderTime) : <></>;
                    }
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {t(I18N_MODULE_KEYS["chatdocument.search.downloadchat"])}{" "}
                    {!downloadinprogressfolder ? (
                      <img src={PROJECT_ICONS.ARROW} className="chatwithdocument-right-header-download-arrow" />
                    ) : (
                      <>
                        <Spin style={{ paddingLeft: "5px" }}></Spin>
                      </>
                    )}
                  </div>
                  {isopenforFolderTime ? (
                    <div className="chatwithdocument-right-header-download-dropdown">
                      {dropdownOptionsForTimeSpan.map((option: any) => (
                        <div
                          key={option.id}
                          className="chatwithdocument-right-header-download-dropdown-item"
                          onClick={() => {
                            handleDownloadForFolder(option.id);
                            setisopenforFolderTime(false);
                          }}
                        >
                          {option.labelKey}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="chatwithdocument-right-header-reset" onClick={ResetEverything}>
                  X
                </div>
              </div>
            </div>

            <div className="chatwithdocument-right-body">
              <div ref={scrollableDivRef} className="chatwithdocument-scrollable ">
                <div>
                  <div className="chatwithdocument-suggestion ">
                    <img className="chatwithdocument-suggestion-icon" src={PROJECT_ICONS.MESSAGE}></img>
                    <p className="chatwithdocument-suggestion-text">
                      Don't know what to ask ? Click on any suggested topics or ask your own question
                    </p>
                  </div>
                  <div className="chatwithdocument-topics">
                    <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                    <div>
                      <p
                        style={{ fontSize: "15px", marginLeft: "25px", background: "#F8F9FD", color: "black", borderRadius: "8px", padding: "10px" }}
                      >
                        Suggested Topics
                      </p>
                      {staticQuestionOptions.map((option: any) => (
                        <p
                          key={option.action}
                          className="chatwithdocument-topic-link"
                          onClick={() => {
                            handlePostRequest(option.action);
                          }}
                        >
                          {option.text}
                        </p>
                      ))}
                      {recommendQuestion?.map((question, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            handlePostRequest(`${question}`);
                          }}
                          style={{
                            fontSize: "15px",
                            marginLeft: "25px",
                            background: "#F8F9FD",
                            color: "#034EA2",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            padding: "10px",
                            paddingTop: "2px",
                            cursor: "pointer",
                          }}
                        >
                          {question}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                {sseData?.map((data, index) => (
                  <div key={index}>
                    <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                      <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.PERSON}></img>
                      <p
                        style={{ fontSize: "15px", marginLeft: "25px", background: "#8C32FF", color: "white", borderRadius: "8px", padding: "10px" }}
                      >
                        {data.question}
                      </p>
                    </div>
                    <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                      <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                      <p
                        style={{ fontSize: "15px", marginLeft: "25px", background: "#F8F9FD", color: "black", borderRadius: "8px", padding: "15px" }}
                      >
                        {/* {data?.answer && data?.answer.split('\n').map((item, index) => (
                    <ul key={index}>{item}</ul>
                    ))} */}
                        {data?.answer ? (
                          <>
                            <Markdown>{data?.answer}</Markdown>
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: "20px" }}>
                <Input
                  style={{ border: "1px solid #949494 ", borderRadius: "3px" }}
                  value={userInputForSearch}
                  suffix={
                    <Button
                      style={{ border: "none" }}
                      ref={buttonRef}
                      disabled={disable}
                      onClick={() => {
                        handlePostRequest();
                      }}
                    >
                      <span style={{ color: "#66676B", fontSize: "10px" }}>{t(I18N_MODULE_KEYS["semantic.search.input.heading.search"])}</span>
                    </Button>
                  }
                  placeholder={t(I18N_MODULE_KEYS["semantic.search.input.heading.entersearch"])}
                  onChange={handleUserInput}
                  disabled={disable}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
