import { SET_LOADING_STATUS, SET_NOTIFICATION_MESSAGE } from "../constants/_commonConstants";

const initialState = {
  isLoading: false,
  notificationMsg: ""
};

export default function nonPersistedReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING_STATUS: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case SET_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        notificationMsg: payload
      };
    }
    default: {
      return state;
    }
  }
}
