import { siteConfig } from "./_siteConfig";

class LocalStorageService {
  getAccessToken() {
    try {
      let access_token: string | null = localStorage.getItem(siteConfig.ACCESS_TOKEN);
      return access_token;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  setToken(token: string) {
    try {
      localStorage.setItem(siteConfig.ACCESS_TOKEN, token);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  removeToken() {
    try {
      localStorage.removeItem(siteConfig.ACCESS_TOKEN);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getRefreshToken() {
    try {
      let access_token: string | null = localStorage.getItem(siteConfig.ACCESS_TOKEN);
      return access_token;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getSessionExpired() {
    try {
      let session_expired: string | null = localStorage.getItem(siteConfig.SESSION_EXPIRED);
      return session_expired === "true" ? true : false;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  setSessionExpired(flag: boolean) {
    try {
      localStorage.setItem(siteConfig.SESSION_EXPIRED, flag ? "true" : "false");
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  removeSessionExpired() {
    try {
      localStorage.removeItem(siteConfig.SESSION_EXPIRED);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getAcceptLanguage() {
    try {
      let accept_language: string | null = localStorage.getItem(siteConfig.ACCEPT_LANGUAGE);
      return accept_language || "en-US"; // Default language is English
    } catch (err) {
      console.log(err);
      return "en-US";
    }
  }

  setAcceptLanguage(locale: string) {
    try {
      localStorage.setItem(siteConfig.ACCEPT_LANGUAGE, locale);
    } catch (err) {
      console.log(err);
    }
  }

  removeAcceptLanguage() {
    try {
      localStorage.removeItem(siteConfig.ACCEPT_LANGUAGE);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new LocalStorageService() as LocalStorageService;
