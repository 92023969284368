import { useSelector } from "react-redux";
import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip ,Radio, InputNumber} from "antd";
import { Select } from 'antd';
import './StylesFolderForNextInsight/_ModalComponentForFormat.css'
import { useDispatch} from "react-redux";
import {
  setAdvancedFilter,
  setFormat, 
  setRegenerateStatus,
  setActiveCardType,
  setLandingScreen,
  setEncodedSemanticSearchIdKvalue
} from "../../../../Store/actions/_semanticSearchActions";
import { PROJECT_ICONS
 } from "../../../../Assets/Img/_DIcons";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { DynamicInputs } from "./_DynamicInputs";

import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
interface ComponentAProps {
  columns:string[];
  setColumns:React.Dispatch<React.SetStateAction<string[]>>;
  internalVarA: number;
  setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
  internalVarB: string;
  setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
  setStepperForInternalVarb:React.Dispatch<React.SetStateAction<number>>;
  stepperForinternalVarB:number;
  onHide: () => void;
  componentFormatVisible:boolean;

}

const { Option } = Select;
const choicestyles = {
  background: "#F3F6FA",
  border: "1px solid #034EA2",
  borderRadius: "24px",
  opacity: 1,
  color: "#034EA2"
};



export const ModalComponentForFormat: React.FC<ComponentAProps> = ({ onHide,stepperForinternalVarB,setStepperForInternalVarb,internalVarA, setInternalVarA ,internalVarB,setInternalVarB,columns,setColumns,componentFormatVisible}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { searchQuery, encodedSearchQuery, selectedReports, semanticEncodedSearchidkvalue, advancedFilterStatus,regeneratestatus, advancedFilterList, advancedFilter,Format,kvalue ,semanticSearchid,semanticEncodedSearchid,cardData
  } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const [items, setItems] = useState<string[]>([""]);
  const [selectedFormat, setSelectedFormat] = useState(cardData?.output_format ?? "text");
  const [selectedScope, setSelectedScope] = useState(cardData?.k?.toString() ?? "10");
  const [numColumns, setNumColumns] = useState<number>(0);
  const [numRows, setNumRows] = useState<number>(5);
  const [generatedSchema,setGeneratedSchema]= useState<boolean>(false);
  const scopeTextMap: { [key: string]: string } = {
    '10': 'Quick View',
    '15': 'Deep Dive',
    '20': 'Full Spectrum',
  };

  console.log(internalVarA+"this is the ultimate hehe"+selectedScope.toString())
  const handleCreateNewConversation = async()=>{
   
    dispatch(setFormat(internalVarB))
    dispatch(setLandingScreen(false));
    dispatch(setAdvancedFilter(false));
    dispatch(setLoadingStatusAction(true));
    if(selectedScope==="10"){
      setInternalVarA(10)}
      else if(selectedScope==="15"){
        setInternalVarA(15);
      }
      else{
        setInternalVarA(20)
      } 
      if(selectedFormat==="text"){
        setStepperForInternalVarb(1)}
        else if(selectedFormat==="list"){
          setStepperForInternalVarb(2)
        }
        else{
          setStepperForInternalVarb(3)
        }
        if(selectedFormat==="table"){
        setColumns(items)
    onHide();
        }
        else{
            onHide();
        }
    if(semanticEncodedSearchidkvalue===0){
      dispatch(setEncodedSemanticSearchIdKvalue(1));
     }
     else if(semanticEncodedSearchidkvalue===1){
      dispatch(setEncodedSemanticSearchIdKvalue(10));
     }
     else if(semanticEncodedSearchidkvalue===10){
      dispatch(setEncodedSemanticSearchIdKvalue(1));
  }
}

const handleGenerateSchema = ()=>{
  setGeneratedSchema(!generatedSchema)
}

// Function to increment the number of columns
const incrementColumns = () => {
  console.log("click"+numColumns)
  if(numColumns<10)
  setNumColumns(numColumns + 1);
};

// Function to decrement the number of columns
const decrementColumns = () => {
  if (numColumns > 0) {
    setNumColumns(numColumns - 1);
  }
};



  const handleRadioChangeScope = (e:any) => {
    console.log(e + "thhis is value of k")
    let selectedvalue = 10
  if(e === "10"){
    selectedvalue=10
} else if (e === "15"){
selectedvalue=15
}else{
selectedvalue=20
} 
setSelectedScope(e)
};

  const handleRadioChange = (e:any) => {
      setSelectedFormat(e);
      let selectedvalue = 1
    if(e === t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"])){
      selectedvalue=1
 } else if (e === t(I18N_MODULE_KEYS["semantic.search.result.formatoption.listsmall"])){
  selectedvalue=2
 }else{
  selectedvalue=3
 }  
 setStepperForInternalVarb(selectedvalue)
  
    if (e === t(I18N_MODULE_KEYS["semantic.search.result.formatoption.tablesmall"])) {
      setItems([""])
      setColumns([""])
    } else {
      setItems([""]) 
      setColumns([""])
      setNumColumns(0);
      setNumRows(0);
      setGeneratedSchema(false);
    }
  };
 const handleProceedForManualColumnsOrRegenerateState = (e:any)=>{
  if(selectedScope==="10"){
  setInternalVarA(10)}
  else if(selectedScope==="15"){
    setInternalVarA(15);
  }
  else{
    setInternalVarA(20)
  } 
  if(selectedFormat==="text"){
    setStepperForInternalVarb(1)}
    else if(selectedFormat==="list"){
      setStepperForInternalVarb(2)
    }
    else{
      setStepperForInternalVarb(3)
    }
    if(selectedFormat==="table"){
      dispatch(setRegenerateStatus(true))
    setColumns(items)
onHide();
    }
    else{
        dispatch(setRegenerateStatus(true))
        onHide();
    }
 }

  return (
<>
<div className="format-overlay">
  <div className="format-close-button" onClick={onHide}>X</div>
  <Col span={21} className="format-content-container">
    <Card className="cardStyle">
      <Row>
        <Col span={5} className="format-colStyle">
          <div className="format-padding-container">
            <div className="format-title">Search Depth</div>
            <div className="format-radio-group">
              {['10', '15', '20'].map((scope) => (
                <div
                  key={scope}
                  className={`format-radioContainer ${selectedScope === scope ? 'selected' : ''}`}
                  onClick={() => handleRadioChangeScope(scope)}
                >
                  <Image src={selectedScope === scope ? PROJECT_ICONS.RADIOCHECK : PROJECT_ICONS.RADIO} />
                  <div className="format-radioLabel">{scopeTextMap[scope]}</div>

                </div>
              ))}
            </div>
          </div>
          <div className="format-padding-container" style={{paddingTop:'10px'}}>
            <div className="format-title">Choose Insight Format</div>
            <div className="format-radio-group" style={{marginLeft:'10px'}}>
              {['text', 'list', 'table'].map((format) => (
                <div
                  key={format}
                  className={`format-radioContainer ${selectedFormat === format ? 'selected' : ''}`}
                  onClick={() => handleRadioChange(format)}
                >
                  <Image src={selectedFormat === format ? PROJECT_ICONS.RADIOCHECK : PROJECT_ICONS.RADIO} />
                  <div className="format-radioLabel">{format.charAt(0).toUpperCase() + format.slice(1).toLowerCase()}</div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col span={18} className="format-colStyle2">
          {selectedFormat === "table" ? (
            <>
              <div className="format-table-container">
                <Row className="format-table-header">
                  <Col span={12} className="format-table-header-col">
                    <div className="format-table-header-content">
                      <span>Number of Columns required </span>
                      <div className="format-input-container">
                        <InputNumber
                          className="format-input"
                          value={numColumns}
                          min={0}
                          max={10}
                          controls={false}
                          disabled={generatedSchema}
                          onChange={(value:any) => setNumColumns(value)}
                        />
                        <div className="format-input-buttons">
                          <button className="format-button" disabled={generatedSchema} onClick={decrementColumns}>-</button>
                          <span className="format-separator">|</span>
                          <button className="format-button" disabled={generatedSchema} onClick={incrementColumns}>+</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="format-generate-button-col" span={12}>
                    <div className="format-generate-button-col-container">
                    <div style={{padding:'5px',color:generatedSchema?"white":'#034EA2',border:'1px solid #034EA2',borderRadius:'2px',fontSize:'13px',background:generatedSchema?'#034EA2':"",cursor:'pointer'}} onClick={handleGenerateSchema}>{generatedSchema?"GENERATED  TABLE  SCHEMA":"GENERATE  TABLE  SCHEMA"}
                    </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {!generatedSchema ? (
                <div className="format-notgenerated-container">
                  <div className="format-notgenerated-content">
                    <div className="format-notgenerated-icon">
                      <Image src={PROJECT_ICONS.IDEA} style={{height:'32px',width:'32px'}}></Image>
                    </div>
                    <div className="format-notgenerated-text">Enter column/row count to generate schema & enter the column headers</div>
                  </div>
                </div>
              ) : (
                <div className="format-generated-container">
                  <div className="format-generated-header">
                    <div className="format-generated-title">Table Schema</div>
                    <div className="format-generated-separator">|</div>
                    <div className="format-generated-subtitle">Please Provide Headers for your columns</div>
                  </div>
                  <DynamicInputs numColumns={numColumns} numRows={6} items={items} setItems={setItems}/>
                </div>   
              )}
            </>
          ) : (
            <div className="format-nottableformat">
              <div className="format-nottableformat-content">
                <div className="format-nottableformat-icon">
                  <Image src={PROJECT_ICONS.IDEA} style={{height:'32px',width:'32px'}}></Image>
                </div>
                <div className="format-nottableformat-text">Please click on generate button to proceed</div>
              </div>
            </div>
          )}
          <div className="format-generate-button">
            <Button className="format-generate-main-button" onClick={(e) => {
              if (selectedScope === internalVarA.toString()) {
                handleProceedForManualColumnsOrRegenerateState(e);
              } else {
                handleCreateNewConversation()
              }
            }}>
              GENERATE
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  </Col>
</div>

          

</>

  );
};
