import { FC, useEffect, useState } from "react";
import { CheckOutlined, LoadingOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Modal, Row, Select, Space, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

import { useSelector } from "react-redux";
import { NameValueTypes } from "../../../../Utils/_gTypes";
import { Option } from "antd/es/mentions";
import { SlideDataType, VoiceListGenderType, VoiceListType } from "../core/modals";
import { fetchAudioRequest, getElaiResponseDataRequest, saveSelectedVoiceRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { useDispatch } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { handleCancelApiRequest } from "../../../../Utils";
import { getELaiResponse, initialVoiceListGenderType } from "../core/constants";
import { setElaiTranslationDataAction, setSaveElaiObject } from "../../../../Store/actions/_contentTranscreationActions";
import { useNavigate } from "react-router-dom";
import { setSelectedVoiceAction } from "../../../../Components/Player";

const { Text } = Typography;

const enumPlayer = {
  PLAY: "play",
  PAUSE: "pause",
  LOADING: "loading"
};

const genderList: NameValueTypes[] = [
  {
    id: 1,
    name: "Male",
    value: "Male"
  },
  {
    id: 2,
    name: "Female",
    value: "Female"
  }
];

export const VoiceListModal: FC<{
  showModal: boolean;
  closeModal: () => void;
  narration: string;
  slide: SlideDataType;
  selectedVoiceItem: VoiceListGenderType;
  activeSlide: number;
  setAudioList: Function;
}> = ({ showModal, closeModal, narration, slide, selectedVoiceItem, activeSlide, setAudioList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { voiceList } = useSelector((state: any) => state?.playerReducer);
  const { elaiResponse } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [selectedVoice, setSelectedVoice] = useState<string>("");
  const [tagList, setTagList] = useState<NameValueTypes[]>([{ value: "upbeat" }]);
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [filteredVoiceList, setFilteredVoiceList] = useState<VoiceListGenderType[]>([]);

  const [selectedTag, setSelectedTag] = useState<string>("");
  const [selectedFilterItem, setSelectedFilterItem] = useState<VoiceListGenderType>({ ...initialVoiceListGenderType });

  useEffect(() => {
    if (selectedGender) {
      filterVoiceList();
    }

    if (selectedVoice || selectedGender) {
      handleTagList();
    }
  }, [selectedGender, selectedVoice]);

  useEffect(() => {
    filterVoiceList();
  }, [selectedTag]);

  useEffect(() => {
    if (voiceList?.length && slide?.language) {
      setSelectedVoice(slide?.language);
      setSelectedGender(selectedVoiceItem?.gender);
      setSelectedFilterItem({ ...selectedVoiceItem });
    }
  }, [voiceList, slide, selectedVoiceItem]);

  useEffect(() => {
    console.log(selectedFilterItem, "sft");
  }, [selectedFilterItem]);

  useEffect(() => {
    console.log(tagList, "useefect of tagList");
  }, [tagList]);

  const filterVoiceList = () => {
    let arrFilteredList: VoiceListGenderType[] = [];

    const arrVoiceList: VoiceListType[] = [...voiceList?.filter((item: VoiceListType) => item?.name === selectedVoice)];

    arrVoiceList?.forEach((vlItem: VoiceListType) => {
      //@ts-ignore
      vlItem[selectedGender?.toLocaleLowerCase?.()]?.forEach((genderItem: VoiceListGenderType) => {
        arrFilteredList?.push(genderItem);
      });
    });

    if (selectedTag) {
      arrFilteredList = [...arrFilteredList?.filter((item: VoiceListGenderType) => item?.tags?.includes(selectedTag))];
    }

    console.log(arrFilteredList);

    setFilteredVoiceList([...arrFilteredList]);

    return [...arrFilteredList];
  };

  const handleTagList = () => {
    let arrFilteredList: VoiceListGenderType[] = [...filterVoiceList()];

    let arrTagList: any[] = [];

    arrFilteredList.forEach((item: VoiceListGenderType) => {
      //@ts-ignore
      item.tags.forEach((item: string) => {
        if (!arrTagList.includes(item)) {
          arrTagList.push(item);
        }
      });
    });

    arrTagList = [
      ...arrTagList?.map((item: string) => {
        return { value: item };
      })
    ];

    console.log(arrTagList, "arrTagList");

    setTagList([...arrTagList]);
  };

  // const handleSaveVoice = async () => {
  //   const res = await saveSelectedVoiceRequest({
  //     video_id: elaiResponse?.video_id,
  //     voice_id: selectedFilterItem?.id,
  //     tag: selectedFilterItem?.selectedTag ? selectedFilterItem?.selectedTag : ""
  //   });

  //   if (res?.detail) {
  //     return;
  //   }

  //   getELaiResponse(elaiResponse, elaiResponse?.video_id, elaiResponse?.storyboard_id, selectedFilterItem?.voice, dispatch, navigate, false);

  //   dispatch(setSelectedVoiceAction(selectedFilterItem?.voice));

  //   closeModal();

  //   //Clear Audio Cache
  //   setAudioList([]);
  // };

  const handleSaveVoice = async () => {
    const updatedElaiResponse = JSON.parse(JSON.stringify(elaiResponse));

    const slide = updatedElaiResponse.slides[activeSlide];

    slide.voice = selectedFilterItem?.voice;
    slide.voiceProvider = selectedFilterItem?.voiceProvider;

    dispatch(setSaveElaiObject({ ...updatedElaiResponse, updatedObject: "avatar" })); // updatedObject: "avatar" to clear the Audio Cache on success

    closeModal();
  };

  const applyVoiceToAllSlides = async () => {
    const updatedElaiResponse = JSON.parse(JSON.stringify(elaiResponse));

    for (let slide of updatedElaiResponse.slides) {
      if (slide.avatar?.gender === selectedFilterItem?.gender) {
        slide.voice = selectedFilterItem?.voice;
        slide.voiceProvider = selectedFilterItem?.voiceProvider;
      }
    }

    dispatch(setSaveElaiObject({ ...updatedElaiResponse, updatedObject: "avatar" })); // updatedObject: "avatar" to clear the Audio Cache on success

    closeModal();
  };

  return (
    <Modal
      closable
      open={showModal}
      footer={false}
      onCancel={() => {
        closeModal();
      }}
      title={
        <Space direction="vertical">
          <Text style={{ opacity: 1, fontSize: "16px", fontWeight: "600", lineHeight: "1.2rem" }}>
            {t(I18N_MODULE_KEYS["content.translation.voicemodal.Select a Voice"])}
          </Text>
          <Text style={{ opacity: 1, fontSize: "14px", fontWeight: "400", lineHeight: "1.2rem", color: "rgba(0, 0, 0, 0.45)" }}>
            {t(I18N_MODULE_KEYS["content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs."])}
          </Text>
        </Space>
      }
      width={1100}
      bodyStyle={{
        background: `url("https://app.elai.io/static/media/background.7888f07e.png")`,
        backgroundPosition: "50%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "20px 10px",
        borderRadius: "10px"
      }}
    >
      <Row gutter={[16, 24]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Select
            showSearch
            placeholder={`${t(I18N_MODULE_KEYS["content.text.selectalanguage"])}`}
            style={{ width: "100%", height: "34px" }}
            allowClear={false}
            onChange={(k) => setSelectedVoice(k)}
            value={selectedVoice}
            defaultValue={selectedVoice}
          >
            {voiceList?.map?.((p: VoiceListType, i: number) => (
              //@ts-ignore
              <Option value={p?.name} key={i}>
                {" "}
                <div>
                  <Avatar
                    //@ts-ignore
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${p?.[
                      selectedGender?.toLocaleLowerCase?.()
                    ]?.[0]?.icon?.toLocaleUpperCase?.()}.svg`}
                    style={{ width: "27px", height: "27px", marginRight: "5px" }}
                  />

                  <Text style={{ opacity: 1, fontSize: "11px", fontWeight: "bold" }}>{p?.name}</Text>
                </div>
              </Option>
            ))}
          </Select>
        </Col>
        {/* <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Select
            style={{ width: "100%", height: "34px" }}
            placeholder={`${t(I18N_MODULE_KEYS["content.translation.voicemodal.Select Gender"])}`}
            options={genderList}
            defaultValue={selectedGender?.charAt(0).toLocaleUpperCase() + selectedGender?.slice(1)}
            onChange={(k) => setSelectedGender(k)}
            value={selectedGender?.charAt(0).toLocaleUpperCase() + selectedGender?.slice(1)}
          />
        </Col> */}
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Select
            style={{ width: "100%", height: "34px" }}
            placeholder={`${t(I18N_MODULE_KEYS["content.translation.voicemodal.Select by Tags"])}`}
            options={tagList}
            onChange={(k) => setSelectedTag(k)}
            // defaultValue={selectedTag}
            allowClear
          />
        </Col>
        <Col xs={{ span: 24 }} style={{ maxHeight: "42vh", overflowY: "scroll", overflowX: "hidden" }}>
          <Row gutter={[16, 16]}>
            {filteredVoiceList && filteredVoiceList?.length ? (
              <>
                {filteredVoiceList?.map?.((item: VoiceListGenderType, i: number) => {
                  return (
                    <Col
                      xs={{ span: 24 }}
                      lg={{ span: 8 }}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (item?.id !== selectedFilterItem?.id || !selectedFilterItem?.id) {
                          setSelectedFilterItem({ ...item });
                        }
                      }}
                    >
                      <VoiceCard
                        key={i}
                        item={item}
                        selectedFilterItem={selectedFilterItem}
                        narration={narration}
                        selectedVoice={selectedVoice}
                        selectedGender={selectedGender}
                        getSelectedtag={(tag: string) => {
                          setSelectedFilterItem((p) => ({ ...p, selectedTag: tag }));
                        }}
                      />
                    </Col>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Row>
        </Col>
        <Col xs={{ span: 24 }} style={{ padding: "0px 24px 0px " }}>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title={`Apply voice to all ${slide.avatar?.gender} avatars`}>
              <Button
                style={{ backgroundColor: "#31353c", borderColor: "#31353c", borderRadius: "8px", color: "#fff" }}
                onClick={applyVoiceToAllSlides}
              >
                {t(I18N_MODULE_KEYS["content.translation.voicemodal.Apply to All"])}
              </Button>
            </Tooltip>
            <Button type="primary" onClick={handleSaveVoice}>
              {/* {t(I18N_MODULE_KEYS["content.translation.voicemodal.Apply Voices"])} */}
              Apply Voice
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

const VoiceCard: FC<{
  item: VoiceListGenderType;
  selectedFilterItem: VoiceListGenderType;
  key: number;
  narration: string;
  selectedVoice: string;
  selectedGender: string;
  getSelectedtag: (tag: string) => void;
}> = ({ item, selectedFilterItem, key, narration, selectedVoice, selectedGender, getSelectedtag }) => {
  const dispatch = useDispatch();

  const [audioData, setAudioData] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [player, setPlayer] = useState<string>(enumPlayer.PLAY);
  const [audioElem, setAudioElem] = useState<HTMLAudioElement | null>(null);
  const [selectedTagForVoice, setSelectedTagForVoice] = useState<string>("");

  useEffect(() => {
    return () => {
      setAudioElem(null);
      setIsLoading(false);
      setAudioData("");
    };
  }, []);

  useEffect(() => {
    setAudioData("");
    audioElem?.pause();
    setAudioElem(null);
    setIsLoading(false);
    setPlayer(enumPlayer.PLAY);
  }, [selectedVoice, selectedGender, selectedTagForVoice]);

  useEffect(() => {
    if (item?.id !== selectedFilterItem?.id) {
      audioElem?.pause();
      setPlayer(enumPlayer.PLAY);
      setIsLoading(false);
    }
  }, [selectedFilterItem, selectedTagForVoice]);

  const handlePlayer = async (type: string) => {
    let audio = audioElem;

    let strAudioData: string = audioData;

    setIsLoading(true);

    if (!strAudioData) {
      const res = await fetchAudioRequest({ text: narration, voice_id: item?.id, tag: selectedTagForVoice ? selectedTagForVoice : "" });
      setIsLoading(false);

      if (!res?.data) {
        dispatch(setNotificationMsgAction(res?.detail));

        handleCancelApiRequest();
        return;
      }

      strAudioData = res?.data ? res?.data : "";

      setAudioData(strAudioData);
    }

    setIsLoading(false);

    if (!audio) {
      audio = new Audio(`data:audio/x-wav;base64, ${strAudioData}`);
      setAudioElem(audio);
    }

    audio.preload = "auto";

    if (type === enumPlayer.PLAY) {
      audio.play();

      setPlayer(enumPlayer.PAUSE);
    } else if (type === enumPlayer.PAUSE) {
      setPlayer(enumPlayer.PLAY);

      audio.pause();
    }

    if (audio) {
      audio.onended = () => {
        setPlayer(enumPlayer.PLAY);
      };
    }
  };
  return (
    <Card
      key={`voice_list_card_${key}`}
      style={{ border: item?.id === selectedFilterItem?.id ? "2px solid #4868ff" : "", position: "relative", height: "100%", minHeight: "76px" }}
    >
      <Space style={{ padding: "0 20px" }}>
        <Avatar
          src={`https://d3u63mhbhkevz8.cloudfront.net/flags/${item.icon?.toLocaleLowerCase?.()}.svg`}
          style={{ width: "40px", height: "40px", marginRight: "5px" }}
        />

        <div>
          <Text style={{ /*whiteSpace: "nowrap",*/ fontSize: "14px", fontWeight: "750", lineHeight: "1.2rem" }}>{`${
            item?.character
          } (${selectedVoice} - ${item?.icon?.toLocaleUpperCase?.()})`}</Text>
          <div>
            {item?.tags && item?.tags?.length ? (
              <>
                {item?.tags?.length <= 2 ? (
                  <>
                    {item?.tags?.map?.((tag: string) => {
                      return (
                        <Text
                          style={{
                            opacity: 1,
                            fontSize: "11px",
                            fontWeight: "bold",
                            lineHeight: "1.2rem",
                            color: "rgba(0, 0, 0, 0.45)",
                            marginRight: "5px"
                          }}
                        >
                          {tag}
                        </Text>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <Select
                      options={[
                        { value: "default" },
                        ...item?.tags?.map((item: string) => {
                          return { value: item };
                        })
                      ]}
                      style={{ width: "85%", height: "26px" }}
                      defaultValue={"default"}
                      onChange={(k: string) => {
                        getSelectedtag(k === "default" ? "" : k);
                        setSelectedTagForVoice(k === "default" ? "" : k);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={{ width: "100%", position: "absolute", left: "300px", top: "35px" }}>
          {isLoading === true ? (
            <LoadingOutlined />
          ) : (
            <>
              {player === enumPlayer.PLAY && (
                <PlayCircleOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => handlePlayer(enumPlayer.PLAY)}
                  disabled={isLoading && item?.id !== selectedFilterItem?.id}
                />
              )}
              {player === enumPlayer.PAUSE && (
                <PauseCircleOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => handlePlayer(enumPlayer.PAUSE)}
                  disabled={isLoading && item?.id !== selectedFilterItem?.id}
                />
              )}
            </>
          )}
        </div>
      </Space>

      {item?.id === selectedFilterItem?.id && (
        <CheckOutlined
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "-2px",
            backgroundColor: "#4868ff",
            color: "#fff",
            fontSize: "16px",
            padding: "2px 4px",
            borderRadius: "4px 0"
          }}
        />
      )}
    </Card>
  );
};
