import { Button, Col, Image, Input, Row, Spin, Typography, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { UploadOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;
interface RightCardHeaderFileUploadProps {
    createnewfolderstatuslocal: boolean;
  selectedOption: number;
  handleToggle: (option: number) => void;
  handleUploadDocument: (option: number) => void;
  selectedFiles: any[];
  setSelectedFiles: (files: any[]) => void;
  renderFileList: () => JSX.Element;
}

export const RightCardHeaderFileUploadScreen: React.FC<RightCardHeaderFileUploadProps> = ({
    createnewfolderstatuslocal,
    selectedOption,
    handleToggle,
    handleUploadDocument,
    selectedFiles,
    setSelectedFiles,
    renderFileList,
  })=> {
  const { tabList, filecardType } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  return (
    <>
      <Row
        style={{
          border: "1px #BABABA dashed",
          padding: "5px",
          borderRadius: "6px",
          backgroundColor: createnewfolderstatuslocal ? "#F1F1F1" : "white",
          overflowY: "auto",
          maxHeight: "100px",
        }}
      >
        {/* <Col xs={21}  style={{textAlign:'center',padding:'5px',fontSize:'20px'}}>*/}
        <Col span={4} style={{ padding: "10px", display: "flex", gap: "20px", paddingTop: "18px" }}>
          <div style={{ display: "flex" }} onClick={() => handleToggle(0)}>
            <Image
              preview={false}
              src={selectedOption === 0 ? PROJECT_ICONS.RADIOSELECT : PROJECT_ICONS.RADIOUNSELECT}
              style={{ height: "20px", width: "20px" }}
            />
            <p style={{ marginLeft: "10px", fontSize: "16px" }}>Quick</p>
          </div>
          <div style={{ display: "flex" }} onClick={() => handleToggle(1)}>
            <Image
              preview={false}
              src={selectedOption === 1 ? PROJECT_ICONS.RADIOSELECT : PROJECT_ICONS.RADIOUNSELECT}
              style={{ height: "20px", width: "20px" }}
            />
            <p style={{ marginLeft: "10px", fontSize: "16px" }}>Detail</p>
          </div>
        </Col>

        <Col span={17} style={{ textAlign: "center", padding: "5px", fontSize: "20px" }}>
          <Upload
            disabled={createnewfolderstatuslocal ? true : false}
            style={{ border: "none", padding: "5px", cursor: "pointer" }}
            multiple
            onChange={(info) => {
              const { fileList } = info.fileList ? info : { fileList: [] };
              setSelectedFiles([...selectedFiles, ...fileList]);
              console.log(selectedFiles + "These are it");
            }}
            customRequest={() => {}} //To prevent the POST request error
            fileList={[]} // To display uploaded files by default
            onRemove={() => false} // To prevent removal of files
          >
            <div
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                padding: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <UploadOutlined style={{ color: "#1e4da1", fontSize: "30px", paddingRight: "20px" }} />
              <p style={{ fontSize: "20px" }}>{t(I18N_MODULE_KEYS["semantic.search.filesystem.browsefolder"])}</p>
            </div>
            <div className="custom-file-list">{renderFileList()}</div>
          </Upload>
        </Col>
        {/* </Col> */}
        <Col style={{ textAlign: "center", padding: "9px", paddingTop: "12px" }}>
          <Button
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleUploadDocument(selectedOption);
            }}
            disabled={createnewfolderstatuslocal ? true : false}
          >
            {t(I18N_MODULE_KEYS["semantic.search.filesystem.upload"])}
          </Button>
        </Col>
      </Row>
    </>
  );
};
