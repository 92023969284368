import { FC, useEffect, useRef } from "react";
import { ContentAIHeader, FloatingSidebar } from "./Components";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { getDefaultLayout, handleCancelApiRequest } from "../../Utils";
import { setLayoutSyncAction } from "../../Store/actions/_commonActions";
import { Authorizer, siteConfig } from "../../Services";
import { CONTENT_AI_MODULES, CONTENT_CREATOR_STEPS, SELECTED_TAGS_INITIAL_STATE } from "./Core/constants";
import "./Core/content-ai.css";

import {
  setActiveModuleActions,
  setCurrentActiveStepActions,
  setNavigatedFlagAction,
  setPromptIDAction,
  setResponseDataAction,
  setResponseLoaderFlagAction,
  setSelectedTagsAction
} from "../../Store/actions/_contentAIActions";
import { ContentCreatorWrapper, ContentUniverse, LLMComplianceMarker, LLMExperienceMarker, LLMGuidelines } from "./Modules";
import _localStorageService from "../../Services/_localStorageService";

const authorizer = new Authorizer();

export const ContentAIWrapper: FC = () => {
  const dispatch = useDispatch();
  const getResultTimerRef: any = useRef();

  const { activeModule, navigated } = useSelector((state: any) => state?.contentAIReducer);
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  const setGetResultTimer = (timer: any) => {
    getResultTimerRef.current = timer;
  };

  const clearGetResultTimer = () => {
    clearTimeout(getResultTimerRef.current);
  };

  siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;

  useEffect(() => {
    init();
    return () => {
      //console.log("ContentAIWrapper unmount");
      cleanUpAPICalls();
    };
  }, []);

  const isValid = () => {
    authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);
  };

  const init = async () => {
    isValid();
    
    if (!layoutSync.CONTENT_AI) {
      const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);
      dispatch(setLayoutSyncAction({ ...objNewLayoutSync, CONTENT_AI: true }));
    }
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;

    if (navigated) {
      resetContentAIReduxData();
      dispatch(setNavigatedFlagAction(false));
    } else !activeModule && dispatch(setActiveModuleActions(CONTENT_AI_MODULES.CONTENT_CREATOR));

    dispatch(setResponseLoaderFlagAction(false)); //Disable the Response Loader as API calls would have been cancelled on refresh
  };

  const cleanUpAPICalls = () => {
    clearGetResultTimer(); //cancel getResult() timer
    dispatch(setResponseLoaderFlagAction(false));
    handleCancelApiRequest();
  };

  const resetContentAIReduxData = () => {
    dispatch(setPromptIDAction(0)); //Reset promptID
    dispatch(setResponseDataAction({ prompt: "", content: "", rules: [] }));
    dispatch(setActiveModuleActions(CONTENT_AI_MODULES.CONTENT_CREATOR));
    dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.GENERATE_RESPONSE));
    dispatch(setSelectedTagsAction(SELECTED_TAGS_INITIAL_STATE));
    dispatch(setResponseLoaderFlagAction(false));
  };

  const ContentAIModules = {
    [CONTENT_AI_MODULES.CONTENT_CREATOR]: ContentCreatorWrapper,
    [CONTENT_AI_MODULES.LLM_EXPERIENCE_MARKER]: LLMExperienceMarker,
    [CONTENT_AI_MODULES.LLM_COMPLIANCE_MARKER]: LLMComplianceMarker,
    [CONTENT_AI_MODULES.LLM_MLR_GUIDELINES]: LLMGuidelines,
    [CONTENT_AI_MODULES.CONTENT_UNIVERSE]: ContentUniverse
  };

  const ActiveModuleComponent = ContentAIModules[activeModule] || (() => <></>);

  return (
    <div className="content-ai">
      <header>
        <ContentAIHeader />
      </header>

      {/* Side Bar */}
      {/* <FloatingSidebar /> */}

      {/* <!-- middle content --> */}
      <div className="py-4" style={{ marginLeft: "2.5rem", marginRight: "2.5rem", height: "100%" }}>
        <ActiveModuleComponent setGetResultTimer={setGetResultTimer} clearGetResultTimer={clearGetResultTimer} />
      </div>
    </div>
  );
};
