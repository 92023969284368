import { Button, Col, Image, Input, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
const { Paragraph } = Typography;

export const RightCardNoFilesAnimation: React.FC = () => {
  const { tabList, filecardType } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  return (
    <>
      {" "}
      <div style={{ paddingTop: "40px" }}>
        <Col span={24} className="d-flex justify-content-center align-items-center">
          <Lottie id="email" animationData={PROJECT_ICONS.CREATE_FOLDERS} loop={true} style={{ height: "35vh", width: "35vw" }} />
        </Col>
        <Col span={24} className="d-flex justify-content-center align-items-center" style={{ fontSize: "16px", color: "#231F20", fontWeight: "400" }}>
          {t(I18N_MODULE_KEYS["semantic.search.filesystem.nodocsavaliable"])}
        </Col>
        <Col span={24} className="d-flex justify-content-center align-items-center" style={{ fontSize: "16px", color: "#66676B", fontWeight: "400" }}>
          {t(I18N_MODULE_KEYS["semantic.search.filesystem.nodocsavaliablecreate"])}
        </Col>
      </div>
    </>
  );
};
