import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Skeleton, Spin, Typography } from "antd";
import { getVideoTemplatesRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { globalConstant } from "../../../../Utils";
import { setResponseCtAction } from "../../../../Store/actions/_contentTranscreationActions";
import ImageTile from "../ImageTile";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
const { Paragraph, Text } = Typography;

const selectedCardStyle: React.CSSProperties = {
  boxShadow: "0px 6px 18px #00000029",
  border: " 5px solid rgb(0, 84, 207)",
  borderRadius: "14px"
};

const ProcessTemplate: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { responseCT } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [videoTemplates, setVT] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getVideoTemplates();
  }, []);

  const getVideoTemplates = async () => {
    setLoading(true);
    const res = await getVideoTemplatesRequest(dispatch);
    setLoading(false);

    console.log("Templates response", res);

    if (res && res.length) {
      setVT(res);
      if (!responseCT.template) updateReduxState(res[0]);
    } else {
      // dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
    }
  };

  const updateReduxState = async (videoTemplate: {}) => {
    dispatch(
      setResponseCtAction({
        ...responseCT,
        template: videoTemplate
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Text
          style={{
            font: "normal normal bold 26px/36px Nunito Sans",
            margin: "1rem 0 2rem 0"
          }}
        >
          {/* Choose your Template */}
          {t(I18N_MODULE_KEYS["content.transcreation.process.template.title.chooseyourtemplate"])}
        </Text>
      </div>
      <Row
        className="d-flex justify-content-center align-items-center "
        // style={{ height: "40vh" }}
        gutter={[
          { xs: 8, md: 16, lg: 32 },
          { xs: 8, md: 16, lg: 32 }
        ]}
      >
        {loading ? (
          <Spin />
        ) : (
          <>
            {videoTemplates.length ? (
              videoTemplates.map(({ id, thumbnail: imgSrc, name: description, targetLink }, index) => (
                <Col md={{ span: 6 }} key={id}>
                  <ImageTile
                    imgSrc={imgSrc}
                    description={description}
                    style={responseCT?.template?.id === id ? selectedCardStyle : {}}
                    onClick={() => {
                      updateReduxState(videoTemplates[index]);
                    }}
                  />
                </Col>
              ))
            ) : (
              <Paragraph
                type="secondary"
                className="optimize-font"
                style={{
                  fontSize: "12px"
                }}
              >
                {/* No Templates Found! */}
                {t(I18N_MODULE_KEYS["content.transcreation.process.template.title.notemplatefound"])}
              </Paragraph>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default ProcessTemplate;
