import { EyeOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../i18n/core/constants";

const { Paragraph } = Typography;

export const ImageMask: FC = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[8, 16]}>
      <Col>
        <EyeOutlined />
      </Col>
      <Col>
        <Paragraph style={{ color: "white" }}>{t(I18N_MODULE_KEYS["content.text.preview"])}</Paragraph>
      </Col>
    </Row>
  );
};
