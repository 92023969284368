import { FC } from "react";
import { Col, Flex, Row } from "antd";

import { OptionalContainer } from "./Components/Translate Asset/_OptionalContainer";
import { FooterContainer, HeadingContainer, ListingContainer, SearchingContainer } from "./Components/Translate Asset";

export const TranslateAssetMain: FC = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <Flex vertical gap="middle">
          <HeadingContainer />

          <SearchingContainer />

          <ListingContainer />
        </Flex>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <OptionalContainer />
      </Col>
      <Col span={24} style={{ position: "absolute", bottom: "16px", width: "98%" }}>
        <FooterContainer />
      </Col>
    </Row>
  );
};
