import { globalConstant } from "../../../../Utils";

export const enLocale = {
  /*Main constants*/
  ["app.name"]: "Generative AI",
  ["app.logo"]: "extgen",

  /*Header constants*/
  ["header.searchtext"]: "Search here...",
  ["header.menu.language.english"]: "English",
  ["header.menu.language.spanish"]: "Spanish",
  ["header.menu.language.chinese"]: "Chinese",
  ["header.menu.language.hindi"]: "Hindi",
  ["header.menu.myprofile"]: "My Profile",
  ["header.menu.logout"]: "Logout",

  /*Sidebar constants*/
  ["sidebar.dashboard"]: "Dashboard",
  ["sidebar.history"]: "Content History",

  /*Module Constants */
  ["module.dashboard"]: "Dashboard",
  ["module.login"]: "Login",
  ["module.signup"]: "Signup",
  ["module.transcreation"]: "Content Transcreation",
  ["module.history"]: "Content History",
  ["module.translation"]: "Content Translation",
  ["module.semantic"]: "NEXT GIST",
  ["module.fieldcopilot"]: "Field Copilot",
  ["module.contentcreation"]: "Content Creation",
  ["module.idetailextract"]: "iDetailExtract",
  ["module.translation-module"]: "Translation",

  /*Content constants*/
  ["content.dashboard.card.generativeai.title"]: "Generative AI",
  ["content.dashboard.card.generativeai.desc"]:
    "Generative artificial intelligence (AI) is artificial intelligence capable of generating text, images, or other media, using generative models. Generative AI models learn the patterns and structure of their input training data and then generate new data that has similar characteristics.",

  ["content.dashboard.card.idetail.title"]: "iDetailXtract",
  ["content.dashboard.card.idetail.desc"]:
    "Transformative AI powered engine converting iDetail into versatile range of assets ranging from print - ready banner to personalised emails, tailored to suit diverse audiences",

  ["content.dashboard.card.translation-module.title"]: "Content Translation",
  ["content.dashboard.card.translation-module.desc"]:
    "Generative AI powered tool to provide real-time, context and terminology aware translation across multiple language",

  ["content.dashboard.card.transcreation.title"]: "Content Transcreation",
  ["content.dashboard.card.transcreation.desc"]:
    "Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone.",

  ["content.dashboard.card.semantic.title"]: "NEXT GIST",
  ["content.dashboard.card.semantic.desc"]:
    "Advanced semantic search transforming unstructured data into actionable insights, automating research, and enhancing strategic decisions.",

  ["content.dashboard.card.contentai.title"]: "Content AI",
  ["content.dashboard.card.contentai.desc"]:
    "AI provides personalized support with cognitive computing skills, leveraging its capacity to understand, learn, and adapt to individual needs.",

  ["content.dashboard.card.fieldcopilot.title"]: "Field Copilot",
  ["content.dashboard.card.fieldcopilot.desc"]:
    "Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations.",

  ["content.dashboard.card.kolvideo.title"]: "KOL Video",
  ["content.dashboard.card.kolvideo.desc"]:
    "Create KOL videos seamlessly with AI-generated KOL avatars, GenAI powered script generation, and a comprehensive editing suite.",

  ["content.dashboard.card.assistant.title"]: "Gen AI Dashboard Assistant",
  ["content.dashboard.card.assistant.desc"]:
    "Simplifying data analysis with natural language conversational inputs, interactive visual insights, and easy narratives for informed and quick decision-making.",

  ["content.dashboard.card.hyper.title"]: "Hyper Personalisation",
  ["content.dashboard.card.hyper.desc"]:
    "Tailoring experiences to the core of individual preferences and needs, creating a deeply personalized and meaningful interaction.",

  ["content.dashboard.card.patientjourney.title"]: "Patient Journey Mapping",
  ["content.dashboard.card.patientjourney.desc"]:
    "Revolutionizing patient care in pharma through Gen AI-driven patient journey mapping, understanding and personalizing healthcare experiences.",

  ["content.dashboard.card.translationlocalisation.title"]: "Content Translation & Localisation",
  ["content.dashboard.card.translationlocalisation.desc"]:
    "Adapting content for global audiences through translation & localization, ensuring cultural relevance, linguistic accuracy, and regional appeal.",

  ["content.dashboard.card.personalizedvideo.title"]: "Personalized Videos",
  ["content.dashboard.card.personalizedvideo.desc"]:
    "Revolutionize healthcare communication with personalized videos to tailor information delivery for Healthcare Professionals (HCPs) and patients.",

  ["content.dashboard.card.sentimentanalysis.title"]: "Sentiment Analysis",
  ["content.dashboard.card.sentimentanalysis.desc"]:
    "Using cognitive computing to identify and categorize user opinions in text for enhanced sentiment analysis and comprehension.",

  ["content.dashboard.card.contentcreation.title"]: "Social Media Content Creation",
  ["content.dashboard.card.contentcreation.desc"]:
    "Craft Social media content and transcreate messages effortlessly to redefine communication for HCPs, patients, and HCP marketing.",

  ["content.dashboard.card.button.description"]: "Description",
  ["content.dashboard.card.button.demo"]: "Demo",

  //transcreation select source step
  ["content.transcreation.selectsource.1.title"]: "What would you like to create today?",
  ["content.transcreation.selectsource.1.card.video"]: "Video",
  ["content.transcreation.selectsource.1.card.social"]: "Social Media Post",
  ["content.transcreation.selectsource.1.card.banner"]: "Banner",
  ["content.transcreation.selectsource.1.card.journalad"]: "Journal Ad",
  ["content.transcreation.selectsource.1.card.literature"]: "Leave Behind Literature",
  ["content.transcreation.selectsource.1.card.poster"]: "Poster",
  ["content.transcreation.selectsource.1.card.newsletter"]: "Newsletter",
  ["content.transcreation.selectsource.1.card.brochure"]: "Brochure",

  ["content.transcreation.selectsource.2.title"]: "Select an option",
  ["content.transcreation.selectsource.2.card.email"]: "Email to Video",
  ["content.transcreation.selectsource.2.card.blog"]: "Blog to Video",
  ["content.transcreation.selectsource.2.card.banner"]: "Banner to Video",
  ["content.transcreation.selectsource.2.card.socialmediapost"]: "Social Media Post to Video",
  ["content.transcreation.selectsource.2.card.poster"]: "Poster to Video",

  ["content.transcreation.source.card.upload"]: "Upload File",
  ["content.transcreation.source.card.pullClaims"]: "Pull Claims from DAMS",
  ["content.transcreation.source.card.uploadasset"]: "Upload asset and extract claims",
  ["content.transcreation.source.card.uploaded"]: "Uploaded File",

  ["content.transcreation.source.card.text.drag"]: "Drag and drop to upload or click on Browse File to choose a file",
  ["content.transcreation.source.card.text.file"]: "File Type: PDF, Max File Size: 30 MB",
  ["content.transcreation.source.card.text.nofileselected"]: "No File Selected!",

  ["content.transcreation.source.card.text.subject"]: "Subject Line",
  ["content.transcreation.source.card.text.image"]: "Image Used",
  ["content.transcreation.source.card.text.body"]: "Body",

  ["content.transcreation.process.storyboard.title"]: "Create Story Board",
  ["content.transcreation.process.storyboard.text.selectall"]: "Select All",
  ["content.transcreation.process.storyboard.text.deselectall"]: "De-Select All",
  ["content.transcreation.process.storyboard.text.scene"]: "Scene",
  ["content.transcreation.process.storyboard.text.title"]: "Title",
  ["content.transcreation.process.storyboard.text.narration"]: "Narration",
  ["content.transcreation.process.storyboard.text.story"]: "Story",
  ["content.transcreation.process.storyboard.text.nostoryfound"]: "No Story Found!",

  ["content.transcreation.process.storyboard.modal.title.editstoryboard"]: "Edit Story Board",
  ["content.transcreation.process.selectimage.title.chooseplatform"]: "Choose Your Platform For Image/Clip Creation",
  ["content.transcreation.process.generatedimage.title.imagegeneration"]: "Image Generation",
  ["content.transcreation.process.generatedimage.title.clipgeneration"]: "Clip Generation",
  ["content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo"]: "Kindly choose a single or multiple options to generate video",
  ["content.transcreation.process.generatedimage.drawer.title.addliveclips"]: "Add Live Clips",

  ["content.transcreation.process.template.title.chooseyourtemplate"]: "Choose your Template",
  ["content.transcreation.process.template.title.notemplatefound"]: "No Templates Found!",
  ["content.transcreation.process.avatar.title.chooseyouravatar"]: "Choose your Avatar",
  ["content.transcreation.process.avatar.title.noavatarfound"]: "No Avatars Found!",

  ["content.transcreation.successful.title.videogeneration"]: "Video Generation",
  ["content.transcreation.successful.title.generatevideo"]: "Generate Video",

  ["content.transcreation.viewsummary.drawer.title.summary"]: "Summary",
  ["content.transcreation.viewsummary.drawer.title.storyboard"]: "Storyboard",
  ["content.transcreation.viewsummary.drawer.title.images"]: "Images",
  ["content.transcreation.viewsummary.drawer.title.templates"]: "Templates",
  ["content.transcreation.viewsummary.drawer.title.avatars"]: "Avatars",
  ["content.transcreation.viewsummary.drawer.title.clips"]: "Clips",

  //transcreation header
  ["content.transcreation.activebar.heading.source"]: "Source",
  ["content.transcreation.activebar.heading.process"]: "Process",
  ["content.transcreation.activebar.heading.successful"]: "Successful",

  //transcreation footer
  ["content.transcreation.footer.button.getstarted"]: "Get Started",
  ["content.transcreation.footer.button.viewdashboard"]: "View Dashboard",
  ["content.transcreation.footer.button.regeneratedoc"]: "Regenerate Doc",
  ["content.transcreation.footer.button.createstoryboard"]: "Create Storyboard",
  ["content.transcreation.footer.button.regeneratestory"]: "Regenerate Story",
  ["content.transcreation.footer.button.choosetemplate"]: "Choose Template",
  ["content.transcreation.footer.button.chooseavatar"]: "Choose Avatar",
  ["content.transcreation.footer.button.viewsummary"]: "View Summary",
  ["content.transcreation.footer.button.ordervideo"]: "Order Video",

  ["content.transcreation.modal.ordervideosuccess"]: "The Video is ordered successfully",
  ["content.transcreation.modal.regneration"]: "Warning! Video is already generated",
  ["content.transcreation.modal.regenerationdescription"]:
    "The succeeding content like Storyboard, Image and Video will be discarded and the video will be generated again. Do you want to proceed?",

  //notifications transcreation
  ["content.transcreation.steppers.1.notification.selectfile"]: "Please select any file to upload.",
  ["content.transcreation.steppers.1.notification.filesizeissue"]: "File size exceeded the 30MB limit!",
  ["content.transcreation.steppers.1.notification.filetypeissue"]: "File Type not supported",
  ["content.transcreation.steppers.2.notification.textcopied"]: "Text Copied Successfully!",
  ["content.transcreation.steppers.blogmodal.notification.fillblogurl"]: "Please fill blog url.",
  ["content.transcreation.steppers.blogmodal.notification.entervalidurl"]: "Please enter valid URL",

  ["content.transcreation.wrapper.notification.postpublicationfail"]: "Post publication failed!",
  ["content.transcreation.wrapper.notification.postpublicationsuccess"]: "Post published successfully!",
  ["content.history.notification.featureinprogress"]: "This feature is work in progress.",
  ["content.history.core.notification.filedeletionsuccess"]: "File has been successfully deleted.",
  ["content.history.core.notification.filerenamesucess"]: "File has been successfully renamed.",
  ["content.history.core.notification.videodownloadsucess"]: "Video has been successfully downloaded.",
  ["content.history.core.notification.videodownloaderror"]: "Error occured while downloading video.",
  ["content.history.view.gridview.notification.videogenerationinprogress"]: "Video Generation still in progress!",
  ["content.translation.translationwrapper.notification.videorendererror"]: "Error while rendering video",
  ["content.translation.core.notification.limitexceed"]: "Limit exceeds! data not updated.",
  ["content.translation.core.notification.noslide"]: "There is no slide to translate.",

  //content ai constants
  ["content.contentai.headings.contentai"]: "CONTENT AI",
  ["content.contentai.headings.contentcreator"]: "CONTENT CREATOR",
  ["content.contentai.headings.llmexperience"]: "LLM EXPERIENCE MARKER",
  ["content.contentai.headings.llmcompliance"]: "LLM COMPLIANCE MARKER",
  ["content.contentai.headings.llmmlrguidline"]: "LLM MLR GUIDELINES",
  ["content.contentai.headings.contentuniverse"]: "CONTENT UNIVERSE",

  ["content.contentai.headings.contentai.text.mynameisline"]: "My Name is Lin",
  ["content.contentai.headings.contentai.text.howcanihelpyou"]: "How can I help you?",
  ["content.contentai.headings.contentai.text.createpharmacontent"]: "Create Pharma Content with Domain Specificity",
  ["content.contentai.headings.contentai.text.complianceandregulation"]: "Compliance and Regulations.",

  ["content.contentai.headings.contentai.text.enrichprompt"]: "Enrich Prompt",
  ["content.contentai.headings.contentai.text.revisedprompt"]: "Revised Prompt",
  ["content.contentai.headings.contentai.text.appliedrules"]: "Applied Rules",
  ["content.contentai.headings.contentai.text.triggeredprompt"]: "Triggered Prompt",
  ["content.contentai.headings.contentai.text.refinedresponse"]: "Refined Response",

  ["content.contentai.headings.llm.text.rule"]: "Rule",
  ["content.contentai.headings.llm.text.rules"]: "Rules",
  ["content.contentai.headings.llm.text.addnewrules"]: "Add New Rules",
  ["content.contentai.headings.llm.text.norulefound"]: "No Rules Found",
  ["content.contentai.headings.llm.text.enterrule"]: "Enter Rule",
  ["content.contentai.headings.llm.text.applicability"]: "Applicabilty",
  ["content.contentai.headings.llm.text.tagsupdated"]: "tags updated!",

  ["content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags"]: "Content not available for selected tag(s).",
  ["content.contentai.headings.contentuniverse.text.pleaseselectatag"]: "Please select a tag.",
  ["content.contentai.headings.contentuniverse.text.step1"]: "STEP 1",
  ["content.contentai.headings.contentuniverse.text.step2"]: "STEP 2",
  ["content.contentai.headings.contentuniverse.text.fileupload"]: "File Upload",
  ["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"]: "Drag and drop your files here.",
  ["content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB"]: "Maximum upload file size: 128 MB.",
  ["content.contentai.headings.contentuniverse.text.supportedfiletype"]: "Supported file type:",
  ["content.contentai.headings.contentuniverse.text.uploadedfile"]: "Uploaded File",
  ["content.contentai.headings.contentuniverse.text.choosedetails"]: "Choose Details",

  //content AI Notifications
  ["content.contentai.headings.contentuniverse.notification.contentdeleted"]: "Content deleted!",
  ["content.contentai.headings.contentuniverse.notification.contentemptyerror"]: "Content cannot be empty!",
  ["content.contentai.headings.contentuniverse.notification.contentupdate"]: "Content updated!",
  ["content.contentai.LLM.notification.ruleemptyerror"]: "Rule name cannot be empty!",
  ["content.contentai.LLM.notification.newruleadded"]: "New Rule Added!",
  ["content.contentai.LLM.LLMBasecomponent.notification.cancelled"]: "Cancelled!",
  ["content.contentai.LLM.LLMBasecomponent.notification.rulesapplied"]: "Content Universe Rules applied!",
  ["content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied"]: "Prompt Rules applied!",
  ["content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat"]: "Unsupported format!\nOnly .xlsx files are supported.",

  //semantic search module floating sidebar
  ["semantic.search.floatingsidebar.search"]: "Search",
  ["semantic.search.floatingsidebar.filemanagement"]: "File Management",

  //semantic search
  ["semantic.search.header.heading.search"]: "Search Query",
  ["semantic.search.landing.content.enterquery"]: "Enter Query to view results",
  ["semantic.search.landing.content.number"]: "Number of",
  ["semantic.search.landing.content.documentsindexed"]: "Documents indexed",
  ["semantic.search.landing.content.insightsgiven"]: "Insights given",
  ["semantic.search.landing.content.insightsdownloaded"]: "Insights downloaded",
  ["semantic.search.input.heading.search"]: "SEARCH",
  ["semantic.search.input.heading.entersearch"]: "Enter Search query",
  ["semantic.search.input.heading.clear"]: "CLEAR",
  ["semantic.search.result.enterfeedback"]: "Enter your feedback",
  ["semantic.search.loading.pleasewait"]: "Please wait ...",
  ["semantic.search.loading.searchinprogress"]: "Your search is in progress",
  ["semantic.search.result.searchdepth"]: "Search Depth",
  ["semantic.search.result.format"]: "Format",
  ["semantic.search.result.regenerate"]: "Re-Generate",
  ["semantic.search.result.depthoption.quickview"]: "Quick View",
  ["semantic.search.result.depthoption.deepdive"]: "Deep Dive",
  ["semantic.search.result.depthoption.fullspectrum"]: "Full Spectrum",
  ["semantic.search.result.depthoption.quickviewsmall"]: "quick View",
  ["semantic.search.result.depthoption.deepdivesmall"]: "deep Dive",
  ["semantic.search.result.depthoption.fullspectrumsmall"]: "full Spectrum",
  ["semantic.search.result.formatoption.text"]: "Text",
  ["semantic.search.result.formatoption.list"]: "List",
  ["semantic.search.result.formatoption.table"]: "Table",
  ["semantic.search.result.formatoption.textsmall"]: "text",
  ["semantic.search.result.formatoption.listsmall"]: "list",
  ["semantic.search.result.formatoption.tablesmall"]: "table",
  ["semantic.search.result.answercard.files"]: "Reference File(s)",
  ["semantic.search.result.answercard.answerfeedback"]: "Feedback",
  ["semantic.search.result.answercard.nextinsight"]: "Next Insight",
  ["semantic.search.result.notification.fileuploaded"]: "File Downloaded",
  ["semantic.search.result.notification.copy"]: "Text copied successfully",
  ["semantic.search.result.notification.unabletodownload"]: "Unable to download file",
  ["semantic.search.result.notification.connectionerror"]: "Connection Error",
  ["semantic.search.result.notification.feedbacksubmitted"]: "Feedback submitted successfully",
  ["semantic.search.result.modaltable.addcolumns"]: "Add Manual Columns",
  ["semantic.search.result.modaltable.douwanttoaddcolumns"]: "Do you want to add Manual Columns ?",
  ["semantic.search.result.modaltable.notification.manualcolumnsadded"]: "Manual Columns Added",
  ["semantic.search.result.modaltable.enternewitem"]: "Enter a new item",

  //semantic-search generate report
  ["semantic.search.result.modal.generate"]: "Generate Report",
  ["semantic.search.result.modaltable.share"]: "Share",
  ["semantic.search.result.modaltable.add"]: "Add to Report",
  ["semantic.search.result.modaltable.added"]: "Added to Report",
  ["semantic.search.result.viewmore"]: "View More",
  ["semantic.search.result.noanswers"]: "No Answers Found!",

  ["semantic.search.result.noreference"]: "Not found any reference",
  ["semantic.search.result.assets"]: "Assets Location",
  ["semantic.search.result.searchresults"]: "Search Results",
  ["semantic.search.result.actions"]: "Actions",
  ["semantic.search.result.noreportselected"]: "No Reports Selected !",

  //drawer
  ["semantic.search.result.drawer.pagenumber"]: "Page numbers:",
  ["semantic.search.result.drawer.searchquery"]: "Search Query",
  ["semantic.search.result.drawer.notfound"]: "Not Found",

  //semantic advanced filter
  ["semantic.search.header.searchbyfoldername"]: "Search by Folder Name , Document Name",
  ["semantic.search.header.enterfoldername"]: "Enter Folder Name",

  ["semantic.search.header.advancedfilters"]: "Advanced Filters",
  ["semantic.search.header.appliedfilters"]: "Applied Filters",
  ["semantic.search.advancedfilters.tip"]: "Tip: Click on the category to view folders",
  ["semantic.search.advancedfilters.selectall"]: "Select All",
  ["semantic.search.advancedfilters.document"]: "Document",
  ["semantic.search.advancedfilters.URL"]: "URL",
  ["semantic.search.advancedfilters.image"]: "Image",
  ["semantic.search.advancedfilters.video"]: "Video",
  ["semantic.search.advancedfilters.audio"]: "Audio",
  ["semantic.search.advancedfilters.notification.applied"]: "Advanced Filters have been applied for your search",

  //semantic billing page
  ["semantic.search.billing.heading"]: "Usage and Billing Details",
  ["semantic.search.billing.downloadreport"]: "Download Report",
  ["semantic.search.billing.apiconsumption"]: "API Consumption",
  ["semantic.search.billing.monthlybilling"]: "Monthly Billing",
  ["semantic.search.billing.api"]: "API",
  ["semantic.search.billing.units"]: "Units",
  ["semantic.search.billing.unitprice"]: "Unit Price ($)",
  ["semantic.search.billing.cost"]: "Cost ($)",
  ["semantic.search.billing.costheads"]: "Cost Heads",
  ["semantic.search.billing.amount"]: "Amount ($)",
  ["semantic.search.billing.chat"]: "Chat",
  ["semantic.search.billing.embeddings"]: "Embeddings",
  ["semantic.search.billing.notavailable"]: "Not Available",
  ["semantic.search.billing.totalamount"]: "Total Amount",
  ["semantic.search.billing.notification"]: "File is downloaded successfully",

  //semantic userhistory
  ["semantic.search.userhistory.heading"]: "User History",
  ["semantic.search.userhistory.insightssearched"]: "Insights Searched",
  ["semantic.search.userhistory.chats"]: "Chats",
  ["semantic.search.userdetails.nochatsfound"]: "No Chats Found",
  ["semantic.search.userdetails.noinsightsfound"]: "No Insights Found",

  //semnatic FileSystem
  ["semantic.search.filesystem.uploaddocument"]: "UPLOAD DOCUMENT",
  ["semantic.search.filesystem.upload"]: "Upload",
  ["semantic.search.filesystem.createnewfolder"]: "CREATE NEW FOLDER",
  ["semantic.search.filesystem.createfolder"]: "Create New Folder",
  ["semantic.search.filesystem.browsefolder"]: "Drag and Drop Files to Upload or Browse",
  ["semantic.search.filesystem.nodocsavaliable"]: "No Documents are Available",
  ["semantic.search.filesystem.nodocsavaliablecreate"]: "Create folders and upload your documents",
  ["semantic.search.filesystem.chat"]: "CHAT",
  ["semantic.search.filesystem.Document By"]: "Document Name",
  ["semantic.search.filesystem.DocumentType"]: "Document Type",
  ["semantic.search.filesystem.Uploaded By"]: "Uploaded By",
  ["semantic.search.filesystem.Uploaded Date"]: "Uploaded Date",
  ["semantic.search.filesystem.actions"]: "Actions",
  ["semantic.search.filesystem.documents"]: "Documents",
  ["semantic.search.filesystem.videos"]: "Videos",
  ["semantic.search.filesystem.audios"]: "Audios",
  ["semantic.search.filesystem.images"]: "Images",
  ["semantic.search.filesystem.urls"]: "Web URLs",
  ["semantic.search.filesystem.documentsuploading"]: "Documents Uploading",
  ["semantic.search.filesystem.deletionfolderconfirmation"]: "Do u wish to proceed with folder deletion",
  ["semantic.search.filesystem.deletionfileconfirmation"]: "Do u wish to proceed with file deletion",
  ["semantic.search.filesystem.notification.foldercreation"]: "Folder created successfully",
  ["semantic.search.filesystem.notification.noinput"]: "No input given for folder creation",
  ["semantic.search.filesystem.notification.folderdeletion"]: "Folder Deleted Successfully",
  ["semantic.search.filesystem.notification.filedeletion"]: "File Deleted Successfully",
  ["semantic.search.filesystem.notification.folderdeletion.notupdated"]: "Folder Deleted Successfully but not updated on interface",
  ["semantic.search.filesystem.notification.filedeletion.notupdated"]: "File Deleted Successfully but not updated on interface",
  ["semantic.search.filesystem.notification.errorfolderdeletion"]: "Error in Folder Deletion",
  ["semantic.search.filesystem.notification.errorfiledeletion"]: "Error in File Deletion",
  ["semantic.search.filesystem.notification.nofileselected"]: "No File was selected for uploading",
  ["semantic.search.filesystem.notification.fileuploaded"]: "Files uploaded successfully",
  ["semantic.search.filesystem.notification.foldercannotbecreated"]: "Folder couldnot be created successfully",
  ["semantic.search.filesystem.notification.filecannotbeuploaded"]: "Files were not able to upload successfully",

  ["semantic.search.filesystem.deletionimageconfirmation"]: "Do u want to proceed with image deletion",
  ["semantic.search.filesystem.notification.imagedeletion"]: "Image Deleted Successfully",
  ["semantic.search.filesystem.notification.imagedeletion.notupdated"]: "Image Deleted Successfully but not updated on interface",
  ["semantic.search.filesystem.notification.errorimagedeletion"]: "Error in Image Deletion",
  ["semantic.search.filesystem.notification.noimageselected"]: "No Image was selected for uploading",
  ["semantic.search.filesystem.notification.imageuploaded"]: "Images uploaded successfully",
  ["semantic.search.filesystem.notification.imagecannotbeuploaded"]: "Images were not able to upload successfully",

  ["semantic.search.filesystem.deletionaudioconfirmation"]: "Do u want to proceed with audio deletion",
  ["semantic.search.filesystem.notification.audiodeletion"]: "Audio Deleted Successfully",
  ["semantic.search.filesystem.notification.audiodeletion.notupdated"]: "Audio Deleted Successfully but not updated on interface",
  ["semantic.search.filesystem.notification.erroraudiodeletion"]: "Error in Audio Deletion",
  ["semantic.search.filesystem.notification.noaudioselected"]: "No Audio was selected for uploading",
  ["semantic.search.filesystem.notification.audiouploaded"]: "Audio uploaded successfully",
  ["semantic.search.filesystem.notification.audiocannotbeuploaded"]: "Audios were not able to upload successfully",

  ["semantic.search.filesystem.deletionvideoconfirmation"]: "Do u want to proceed with video deletion",
  ["semantic.search.filesystem.notification.videodeletion"]: "Video Deleted Successfully",
  ["semantic.search.filesystem.notification.videodeletion.notupdated"]: "Video Deleted Successfully but not updated on interface",
  ["semantic.search.filesystem.notification.errorvideodeletion"]: "Error in Video Deletion",
  ["semantic.search.filesystem.notification.novideoselected"]: "No Video was selected for uploading",
  ["semantic.search.filesystem.notification.videouploaded"]: "Video uploaded successfully",
  ["semantic.search.filesystem.notification.videocannotbeuploaded"]: "Videos were not able to upload successfully",

  //chat document
  ["chatdocument.search.chatwithdocument"]: "Chat with document",
  ["chatdocument.search.chathistory"]: "Chat History",
  ["chatdocument.search.chatwith"]: "Chat with",
  ["chatdocument.search.clearall"]: "Clear All",
  ["chatdocument.search.downloadchat"]: "DOWNLOAD CHAT",
  ["chatdocument.search.pages"]: "pages",
  ["chatdocument.search.notfound"]: "Not Found any previous chats",
  ["chatdocument.search.today"]: "Today's Chat",
  ["chatdocument.search.7days"]: "Last 7 Days",
  ["chatdocument.search.lastmonth"]: "Last Month",
  ["chatdocument.search.lastyear"]: "Last 1 Year",
  ["chat.drawer.notification.chatdownload"]: "Chat Downloaded",
  ["chat.drawer.notification.errorchat"]: "Connection error or no chat initiated for download",

  //content history
  ["content.history.wrapper.text.newfilename"]: "New File Name",
  ["content.history.wrapper.text.searchvideo"]: "Search Video",
  ["content.history.wrapper.text.nohistoryfound"]: "No History Found",

  //login
  ["content.login.text.customizeyouremailusingai"]: "Customize your Email using AI",
  ["content.login.text.email"]: "Email",
  ["content.login.text.password"]: "Password",
  ["content.login.text.confirmpassword"]: "Confirm Password",
  ["content.login.text.convertyouremailintoHTMLCraftImagesand"]: "Convert Your Email into HTML, Craft Images, and",
  ["content.login.text.weavevideostoleavealasting"]: "Weave Videos to Leave a Lasting",
  ["content.login.text.pleaseenteravalidemailaddress"]: "Please enter a valid email address",
  ["content.login.text.pleaseenteravalidpassword"]: "Please enter a valid password",
  ["content.login.text.passwordisrequired"]: "Password is required",
  ["content.login.text.emailisrequired"]: "Email is required",
  ["content.login.text.rememberme"]: "Remember me",
  ["content.login.text.forgotpassword"]: "Forgot Password?",
  ["content.login.text.donthaveanaccount"]: "Don't have an account?",
  ["content.login.text.createanaccount"]: "Create an account",
  ["content.signup.text.alreadyhaveanaccount"]: "Already have an account?",
  ["content.verifiedpage.text.signupsuccessfull"]: "Signup Successfull . Please check your mail for verification",
  ["content.forgot.text.gotologinpage"]: "Go to Login Page",
  ["content.forgot.text.resetpassword"]: "RESET PASSWORD",
  ["content.forgot.text.clicktologin"]: "Click to Login",
  ["content.forgot.text.helloagain"]: "Hello Again!",

  //Use Case Constants
  /** Field Copilot */
  ["content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant"]: "Field Copilot - Your Generative AI-Powered Assistant",
  ["content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations"]:
    "Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations",
  ["content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point"]: "One-Stop Access Point",
  ["content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools"]:
    "Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools",
  ["content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations"]: "Tailored Recommendations",
  ["content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time"]:
    "Personalized answers to rep questions, providing contextual recommendations in real-time",
  ["content.usecase.fieldcopilot.description.tabcontents.Scientific Responses"]: "Scientific Responses",
  ["content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric"]: "Compliance-Centric",
  ["content.usecase.fieldcopilot.description.tabcontents.Every interaction and recommendation is designed to be compliant, ensuring peace of mind"]:
    "Every interaction and recommendation is designed to be compliant, ensuring peace of mind",
  ["content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base"]: "Curated Knowledge Base",
  ["content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base"]:
    "Access to a meticulously chosen corpus of pharma company and product data & knowledge base",
  ["content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access"]: "Marketing 360 Insights Access",
  ["content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey"]:
    "Seamless integration with digital platform ecosystem providing real-time updates on customer journey",
  ["content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management"]: "Assisted Tasks Management",
  ["content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders"]:
    "Quick access to daily schedules, upcoming appointments, and task reminders",
  [`content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation`]: `Not just answers, but insights into the "why", "who", and "what" behind every recommendation`,

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"]: "Business Value Proposition",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop"]:
    "Intuitive Interface and Feedback Loop",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface"]: "Interactive Interface",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI."]:
    "Allowing Reps to engage and provide input to the AI.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection"]: "Feedback Collection",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model."]:
    "Capturing user responses to refine and improve the model.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability"]: "Real-time Adaptability",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback."]:
    "Updating the model's responses based on immediate feedback.",

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture"]: "Generative AI Model Architecture",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI"]: "Generative AI",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures."]:
    "Select LLM using GANs or Transformer architectures.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization"]: "Training and Optimization",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques."]:
    "Enhancing performance through training techniques.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability"]: "Deployment & Scalability",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions."]: "Ensuring seamless solutions.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System"]: "Integrated Data & Knowledge System",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources"]: "Diverse Data Sources",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data."]:
    "Gathering varied and high-quality data.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning"]: "Data Cleaning",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data."]:
    "Removing inconsistencies, noise, or irrelevant data.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering"]: "Feature Engineering",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance."]:
    "Enhancing the data to improve model performance.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework"]: "Compliance and Reporting Framework",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation"]: "Bias Mitigation",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases."]:
    "Ensuring the model doesn't perpetuate harmful biases.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency"]: "Transparency",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions."]:
    "Providing insights into how the AI makes decisions.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails"]: "Compliance & Guardrails",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries."]:
    "Operating within legal and ethical boundaries.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library"]: "Comprehensive Prompt Library",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries"]: "Dynamic Queries",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs."]:
    "Adaptable prompts for diverse user needs.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions"]: "Guided Interactions",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results."]:
    "Directing users to obtain desired results.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts"]: "Categorized Prompts",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access."]:
    "Organizing queries by themes or functions for easy access.",

  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"]: "Return on Investment",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact"]: "Financial Impact",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency"]: "Cost Efficiency",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules."]:
    "Reduce training expenses by leveraging AI-assisted, on-demand modules.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI"]: "Maximized ROI",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns."]:
    "Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence"]: "Sales Ops Excellence",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding"]: "Rapid Onboarding",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity."]:
    "AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability"]: "Dynamic Adaptability",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs."]:
    "Equip reps with real-time market insights, minimizing extensive retraining needs.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements"]: "Enhanced Engagements",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements"]: "Informed Engagements",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning."]:
    "Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation"]: "Risk Mitigation",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation."]:
    "Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation.",

  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"]: "Success Metrics",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time"]: "Task Completion Time",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it"]:
    "Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement"]: "Sales/Productivity Improvement",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot"]:
    "Increase in sales or productivity achieved by medical representatives while using the AI co-pilot.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontentsAccuracy of Information"]: "Accuracy of Information",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot"]:
    "The accuracy of the information provided by the AI co-pilot.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback"]: "Customer Feedback",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot"]:
    "Feedback from customers who interacted with the medical representatives while using the AI co-pilot.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate"]: "Engagement Rate",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions"]:
    "How often medical representatives actively engage with the AI co-pilot during their interactions.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization"]: "Personalization",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths"]:
    "The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations"]: "Compliance and Regulations",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies"]:
    "The AI co-pilot assists medical representatives in adhering to industry regulations and company policies.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings"]: "Cost Savings",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot"]:
    "The cost savings achieved by using the AI co-pilot.",

  ["content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation"]: "Content Generation",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"]: "Future Potential",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements"]:
    "AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations"]: "Training Simulations",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations"]:
    "Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation"]: "Language Translation",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach"]:
    "AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics"]: "Predictive Analytics",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement"]:
    "By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement..",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction"]: "Voice Interaction",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points"]:
    "With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Ethical Considerations"]: "Ethical Considerations",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA"]:
    "While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA.",
  /************/

  /**Content Creation */
  ["content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations"]:
    "Faster content generation with Domain Specificity, Compliance and Regulations",
  ["content.usecase.contentai.description.Prompt Enrichment"]: "Prompt Enrichment",
  ["content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience"]:
    "Allows user to enrich prompt to get the desired content that resonates with the target audience",
  ["content.usecase.contentai.description.Automatic regulatory compliance check"]: "Automatic regulatory compliance check",
  ["content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards."]:
    "Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards.",
  ["content.usecase.contentai.description.Localization optimization"]: "Localization optimization",
  ["content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement"]:
    "Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement",
  ["content.usecase.contentai.description.Responsive design generator"]: "Responsive design generator",
  ["content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices."]:
    "Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices.",
  ["content.usecase.contentai.description.Scalable"]: "Scalable",
  ["content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs"]:
    "Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs",

  ["content.usecase.contentai.business-value-proposition.Faster Time-To-Market"]: "Faster Time-To-Market",
  ["content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches."]:
    "With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches.",
  ["content.usecase.contentai.business-value-proposition.Cost Efficiency"]: "Cost Efficiency",
  ["content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets."]:
    "Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets.",
  ["content.usecase.contentai.business-value-proposition.Market Expansion"]: "Market Expansion",
  ["content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities"]:
    "Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities",
  ["content.usecase.contentai.business-value-proposition.Increased Engagements"]: "Increased Engagements",
  ["content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs"]:
    "Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs",

  ["content.usecase.contentai.return-on-investment.Financial Benefits"]: "Financial Benefits",
  ["content.usecase.contentai.return-on-investment.Cost savings"]: "Cost savings",
  ["content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors"]:
    "Higher speed and efficiency, less trainings costs, reduced errors",
  ["content.usecase.contentai.return-on-investment.Improved top-line"]: "Improved top-line",
  ["content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream"]:
    "Enhanced engagement can lead to increased sales, directly impacting the revenue stream",
  ["content.usecase.contentai.return-on-investment.Operational Benefits"]: "Operational Benefits",
  ["content.usecase.contentai.return-on-investment.Time saving"]: "Time saving",
  ["content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution"]:
    "Faster content generation, localization, optimization, streamlining campaign execution",
  ["content.usecase.contentai.return-on-investment.Employee efficiency"]: "Employee efficiency",
  ["content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects"]:
    "Instead of repetitive tasks, human resources can focus on strategic aspects",
  ["content.usecase.contentai.return-on-investment.Marketing Benefits"]: "Marketing Benefits",
  ["content.usecase.contentai.return-on-investment.Global reach"]: "Global reach",
  ["content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint"]:
    "Content tailoring facilitate effective communication with global audiences, expanding the market footprint",
  ["content.usecase.contentai.return-on-investment.Market agility"]: "Market agility",
  ["content.usecase.contentai.return-on-investment.Rapid content generation allow businesses to swiftly react to market trends or changes"]:
    "Rapid content generation allow businesses to swiftly react to market trends or changes",

  ["content.usecase.contentai.success-metrics.Content production speed"]: "Content production speed",
  ["content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe"]:
    "Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe",
  ["content.usecase.contentai.success-metrics.Cost per content piece"]: "Cost per content piece",
  ["content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content"]:
    "Calculate the cost associated with producing each piece of content",
  ["content.usecase.contentai.success-metrics.CAC"]: "CAC",
  ["content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content"]:
    "The cost associated with acquiring a customer through the content",
  ["content.usecase.contentai.success-metrics.ROI"]: "ROI",
  ["content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content"]:
    "Net profit from the content divided by the cost of producing the content",
  ["content.usecase.contentai.success-metrics.Conversion rate"]: "Conversion rate",
  ["content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content"]:
    "The percentage of users who take a desired action after viewing the content",
  ["content.usecase.contentai.success-metrics.Error rates"]: "Error rates",
  ["content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication"]:
    "Frequency of errors (e.g., grammatical, factual) identified post-publication",
  ["content.usecase.contentai.success-metrics.Revision rate"]: "Revision rate",
  ["content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production."]:
    "Measure the frequency of revisions or corrections post-production.",
  /***********/

  /**Content translation */
  ["content.translation.voicemodal.Select a Voice"]: "Select a Voice",
  ["content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs."]:
    "Listen to voice samples and use tags to find the best voice for your needs.",
  ["content.translation.voicemodal.Select Gender"]: "Select Gender",
  ["content.translation.voicemodal.Select by Tags"]: "Select by Tags",
  ["content.translation.voicemodal.Show Premium"]: "Show Premium",
  ["content.translation.voicemodal.Apply to All"]: "Apply to All",
  ["content.translation.voicemodal.Apply Voices"]: "Apply Voices",

  // basics
  ["content.button.submit"]: "Submit",
  ["content.button.cancel"]: "Cancel",
  ["content.button.goback"]: "Go back",
  ["content.button.save"]: "Save",
  ["content.button.proceed"]: "Proceed",
  ["content.button.regenerate"]: "Regenerate",
  ["content.button.replace"]: "Replace",
  ["content.button.add"]: "Add",
  ["content.button.showmore"]: "Show More",
  ["content.button.discard"]: "Discard",
  ["content.text.noimagefound"]: "No Image Found!",
  ["content.text.gotodashboard"]: "Go To Dashboard",
  ["content.button.next"]: "Next",
  ["content.button.generate"]: "Generate",
  ["content.text.enteryourprompt"]: "Enter Your Prompt",
  ["content.text.generateinitialresponse"]: "Generate Initial Response",
  ["content.text.searchhere"]: "Search Here",

  ["content.text.promptcannotbeempty"]: "Prompt cannot be Empty",
  ["content.text.filterby"]: "Filter By",
  ["content.text.nofilterapplied"]: "No Filters Applied",
  ["content.button.reset"]: "Reset",
  ["content.button.back"]: "Back",
  ["content.button.apply"]: "Apply",
  ["content.button.upload"]: "Upload",
  ["content.text.sessionexpired"]: "Session Expired",
  ["content.text.sessionhasexpired"]: "Your session has expired. Please login again!",
  ["content.text.actions"]: "Actions",
  ["content.text.filterapplied"]: "Filters Applied",
  ["content.text.selected"]: "Selected",
  ["content.text.edit"]: "Edit",
  ["content.text.delete"]: "Delete",
  ["content.text.interested"]: "Interested",
  ["content.text.notinterested"]: "Not Interested",
  ["content.text.share"]: "Share",
  ["content.text.response"]: "Response",
  ["content.button.update"]: "Update",
  ["content.text.errornoresponse"]: "Error: No response!",
  ["content.text.erroruploadfailed"]: "Error: Upload Failed!",
  ["content.text.nofilesuploaded"]: "No files uploaded!",
  ["content.button.delete"]: "Delete",
  ["content.button.no"]: "No",
  ["content.button.yes"]: "Yes",
  ["content.button.stop"]: "Stop",
  ["content.button.yesproceed"]: "Yes,Proceed",
  ["content.transcreation.button.summary"]: "Summary",
  ["content.text.selectalanguage"]: "Select a Language",
  ["content.text.translate"]: "Translate",
  ["content.text.name"]: "Name",
  ["content.text.uploadedon"]: "Uploaded On",
  ["content.text.storyboard"]: "Storyboard",
  ["content.text.image/clips"]: "Image/Clips",
  ["content.text.video"]: "Video",
  ["content.text.action"]: "Action",
  ["content.text.somethingwentwrong"]: "Something went wrong",
  ["content.text.internalservererror"]: "Internal Server Error",
  ["content.text.completed"]: "Completed",
  ["content.text.inprogress"]: "In Progress",
  ["content.text.notstarted"]: "Not Started",
  ["content.text.notapplicable"]: "Not Applicable",
  ["content.text.failed"]: "Failed",
  ["content.text.preview"]: "Preview",
  ["content.text.download"]: "Download",
  ["content.text.rename"]: "Rename",
  ["content.text.render"]: "Render",
  ["content.text.pause"]: "Pause",
  ["content.text.changevoice"]: "Change Voice",
  ["content.text.speechtext"]: "Speech text",
  ["content.text.changeavatar"]: "CHANGE AVATAR",
  ["content.text.filteravatar"]: "Filter avatar by outfit",
  ["content.text.sendtoreport"]: "Send to Report",
  ["content.text.close"]: "Close",
  ["content.text.chat"]: "  Chat",

  ["content.text.phoneme"]: "Phoneme",
  ["content.text.phonemedictionary"]: "Phoneme Dictionary",
  ["content.text.addphoneme"]: "Add Phoneme",
  ["content.text.word"]: "Word",
  ["content.text.pronunciation"]: "Pronounciation",
  ["content.text.phonemedescription"]:
    "Your list of phonemes that will be used in all your videos across the account. Any word here will be spelled as in “Pronunciation“ column in all your videos.",

  ["content.text.error.400"]: "Response : Bad Request",
  ["content.text.error.401"]: "Response : Unauthorized",
  ["content.text.error.402"]: "Response : Payment Required",
  ["content.text.error.403"]: "Response : Forbidden",
  ["content.text.error.404"]: "Response : Not Found",
  ["content.text.error.405"]: "Response : Method Not Allowed",
  ["content.text.error.406"]: "Response : Not Acceptable",
  ["content.text.error.407"]: "Response : Proxy Authentication Required",
  ["content.text.error.408"]: "Response : Request Timeout",
  ["content.text.error.409"]: "Response : Conflict",
  ["content.text.error.410"]: "Response : Gone",
  ["content.text.error.411"]: "Response : Length Required",
  ["content.text.error.412"]: "Response : Precondition Failed",
  ["content.text.error.413"]: "Response : Request Too Large",
  ["content.text.error.414"]: "Response : Request-URI Too Long",
  ["content.text.error.415"]: "Response : Unsupported Media Type",
  ["content.text.error.416"]: "Response : Range Not Satisfiable",
  ["content.text.error.417"]: "Response : Expectation Failed",
  ["content.text.error.500"]: "Response : Internal Server Error",
  ["content.text.error.501"]: "Response : Not Implemented",
  ["content.text.error.502"]: "Response : Bad Gateway",
  ["content.text.error.503"]: "Response : Service Unavailable",
  ["content.text.error.504"]: "Response : Gateway Timeout",
  ["content.text.error.505"]: "Response : HTTP Version Not Supported",
  ["content.text.error.511"]: "Response : Network Authentication Required",

  ["content.text.disardallchanges"]: "Discard All Changes",
  ["content.text.areyousuretodiscard"]: "Are you sure to discard all the changes?",
  ["content.text.novideosfound"]: "No Videos Found!",

  ["content.text.generating.initialresponse"]: "Generating Initial Response",
  ["content.text.generating.refinedresponse"]: "Generating Refined Response",
  ["content.text.regenerating.refinedresponse"]: "Regenerating Refined Response",

  ["content.text.notification"]: "Notification",
  ["content.text.enterblogurl"]: "Enter Blog URL",
  ["content.text.description"]: "Description",
  ["content.button.start"]: "Start",

  ["content.text.Strategic ROI of"]: "Strategic ROI of",

  ["content.text.Title should not be empty!"]: "Title should not be empty!",
  ["content.text.Scene should not be empty!"]: "Scene should not be empty!",
  ["content.text.Narration should not be empty!"]: "Narration should not be empty!",
  ["content.text.Updation of generated content is not allowed now as Video is rendered."]:
    "Updation of generated content is not allowed now as Video is rendered.",
  ["content.text.Are you sure you want to delete?"]: "Are you sure you want to delete?",
  ["content.text.Remove"]: "Remove",

  /**
   * I detail extract multilingiual
   */
  ["idetailextract._UploadFileCard.Upload Document"]: "Upload Document",
  ["idetailextract._UploadFileCard.Uploading"]: "Uploading",
  ["idetailextract._UploadFileCard.Click to Upload or Drop PDF here "]: "Click to Upload or Drop PDF here ",
  ["idetailextract._UploadFileCard.from url"]: "from url",
  ["idetailextract._UploadFileCard.Set Language for Scanned files"]: "Set Language for Scanned files",
  ["idetailextract._UploadFileCard.Country"]: "Country",
  ["idetailextract._UploadFileCard.Brand"]: "Brand",
  ["idetailextract._UploadFileCard.Language"]: "Language",

  ["idetailextract._CategoriesModal.Selected Categories"]: "Selected Categories",
  ["idetailextract._CategoriesModal.Search Catogory"]: "Search Catogory",
  ["idetailextract._CategoriesModal.Suggested"]: "Suggested",
  ["idetailextract._CategoriesModal.Additional"]: "Additional",
  ["idetailextract._CategoriesModal.No Data Found"]: "No Data Found",
  ["idetailextract._CategoriesModal.Identified Categories"]: "Identified Categories",

  ["idetailextract._CoreClaimMain._Headertab.Create Copy"]: "Create Copy",

  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview"]: "Uploaded PDF Preview",
  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Page"]: "Page",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.PDF > Document Copy"]: "PDF > Document Copy",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Selected Categories"]: "Selected Categories",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.ADD CATEGORIES"]: "ADD CATEGORIES",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.This is a preview version and you can edit and create a copy out of it."]:
    "Note: This is a preview version and you can edit and create a copy out of it.",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Step"]: "Step",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.of CVA"]: "of CVA",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Add"]: "Add",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Next Step"]: "Next Step",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Previous Step"]: "Previous Step",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE ASSET TYPE"]: "CHOOSE ASSET TYPE",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE CLAIM CATEGORY"]: "CHOOSE CLAIM CATEGORY",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Choose ‘Asset & Claim category to proceed"]: "Choose ‘Asset & Claim category to proceed",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Core Claims"]: "Core Claims",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Asset Creation Recommendation"]: "Asset Creation Recommendation",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated"]: "Content Updated",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]: "Content Deleted",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content"]: "Content",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page No"]: "Page No",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content can not be empty"]: "Content can not be empty",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page number can not be empty"]: "Page number can not be emptyContent",

  ["idetailextract._CoreClaimMain._TinyMCE.Loading Editor"]: "Loading Editor",

  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content saved"]: "Content saved",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Saving failed! Please retry."]: "Saving failed! Please retry.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content discarded!"]: "Content discarded!",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No recent changes to save."]: "No recent changes to save.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No unsaved changes to discard."]: "No unsaved changes to discard.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Your Emailer Suggestion"]: "Your Emailer Suggestion",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save"]: "Save",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard"]: "Discard",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit Edit Mode"]: "Exit Edit Mode",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Preview"]: "Preview",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Cancel"]: "Cancel",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Lose Changes"]: "Lose Changes",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.If you proceed, you will lose all the changes you have made."]:
    "If you proceed, you will lose all the changes you have made.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit without Saving"]: "Exit without Saving",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save and Exit"]: "Save and Exit",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard unsaved changes"]: "Discard unsaved changes",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Unsaved changes"]: "Unsaved changes",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Proceed"]: "Proceed",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Close"]: "Close",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Edit"]: "Edit",

  /**
   * Translation Module
   */
  ["translation-module._TextTranslationMain.Choose Base Language"]: "Choose Base Language",
  ["translation-module._TextTranslationMain.Choose Target Language"]: "Choose Target Language",
  ["translation-module._TextTranslationMain.RESET"]: "RESET",
  ["translation-module._TextTranslationMain.TRANSLATE"]: "TRANSLATE",
  ["translation-module._TextTranslationMain.Input"]: "Input",
  ["translation-module._TextTranslationMain.Output"]: "Output",

  ["translation-module._TextTranslationMain._IOTextTranslation.Enter your text"]: "Enter your text",
  ["translation-module._TextTranslationMain._IOTextTranslation.ADD GLOSSARY"]: "ADD GLOSSARY",

  ["translation-module._AddGlossaryModal.Add Glossary"]: "Add Glossary",
  ["translation-module._AddGlossaryModal.Set rules for how words and phrases are translated"]: "Set rules for how words and phrases are translated",
  ["translation-module._AddGlossaryModal.Replace"]: "Replace",
  ["translation-module._AddGlossaryModal.e.g, hello!"]: "e.g, hello!",
  ["translation-module._AddGlossaryModal.With"]: "With",
  ["translation-module._AddGlossaryModal.e.g, 更高"]: "e.g, 更高",
  ["translation-module._AddGlossaryModal.Source --> Target"]: "Source --> Target",
  ["translation-module._AddGlossaryModal.RESET"]: "RESET",
  ["translation-module._AddGlossaryModal.ADD"]: "ADD",
  ["translation-module._AddGlossaryModal.UPDATE"]: "UPDATE",
  ["translation-module._AddGlossaryModal.Search item"]: "Search item",
  ["translation-module._AddGlossaryModal.OR"]: "OR",
  ["translation-module._AddGlossaryModal.Action"]: "Action",

  ["translation-module._MainTabs.TEXT TRANSLATION"]: "TEXT TRANSLATION",
  ["translation-module._MainTabs.UPLOAD DOCUMENT(S)"]: "UPLOAD DOCUMENT(S)",

  ["translation-module._UploadDocumentMain.File Upload"]: "File Upload",
  ["translation-module._UploadDocumentMain.ADD GLOSSARY"]: "ADD GLOSSARY",
  ["translation-module._UploadDocumentMain.Drag And Drop Your Files Here."]: "Drag And Drop Your Files Here.",
  ["translation-module._UploadDocumentMain."]: "Supported file type: .pdf",
  ["translation-module._UploadDocumentMain.of"]: "of",
  ["translation-module._UploadDocumentMain.files uploaded"]: "files uploaded",
  ["translation-module._UploadDocumentMain.Uploaded File"]: "Uploaded File",
  ["translation-module._UploadDocumentMain.No files uploaded!"]: "No files uploaded!",
  ["translation-module._UploadDocumentMain.NEXT"]: "NEXT",
  ["translation-module._UploadDocumentMain.Clear All"]: "Clear All",

  ["translation-module._TranslateAssetMain._HeadingContainer.Translate your Asset"]: "Translate your Asset",
  ["translation-module._TranslateAssetMain._HeadingContainer.ADD GLOSSARY"]: "ADD GLOSSARY",
  ["translation-module._TranslateAssetMain._HeadingContainer.At the moment, AI-generated translation will be applied to all of your assets."]:
    "At the moment, AI-generated translation will be applied to all of your assets.",

  ["translation-module._TranslateAssetMain._SearchingContainer.Languages"]: "Languages",
  ["translation-module._TranslateAssetMain._SearchingContainer.Search Language"]: "Search Language",
  ["translation-module._TranslateAssetMain._SearchingContainer.Base Language English (US)"]: "Base Language: English (US)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Add Context to the Translation (Optional)"]:
    "Add Context to the Translation (Optional)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Enter your text"]: "Enter your text",

  ["translation-module._TranslateAssetMain._FooterContainer.Language Selected"]: "Language Selected",
  ["translation-module._TranslateAssetMain._FooterContainer.CANCEL"]: "CANCEL",
  ["translation-module._TranslateAssetMain._FooterContainer.PROCEED"]: "PROCEED",

  ["translation-module.FinalTranslationScreen.Translation is Done!"]: "Translation is Done!",
  ["translation-module.FinalTranslationScreen.Please select a file from the Dropdown to view the translated asset."]:
    "Please select a file from the Dropdown to view the translated asset.",
  ["translation-module.FinalTranslationScreen.Edit"]: "Edit",
  ["translation-module.FinalTranslationScreen.Input"]: "Input",
  ["translation-module.FinalTranslationScreen.Output"]: "Output",
  ["translation-module.FinalTranslationScreen.DOWNLOAD"]: "DOWNLOAD",
  ["translation-module.FinalTranslationScreen.Languages"]: "Languages",
  ["translation-module.FinalTranslationScreen.START AGAIN"]: "START AGAIN",
  ["translation-module.FinalTranslationScreen.GET YOUR QUALITY REPORT"]: "GET YOUR QUALITY REPORT",

  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translate your package"]: "Translate your package",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.At the moment, AI-generated translation will be applied to all of your assets."]:
    "At the moment, AI-generated translation will be applied to all of your assets.",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Please wait..."]: "Please wait...",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Selected Language"]: "Selected Language",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.“Translation”"]: "“Translation”",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.is in Progress"]: "is in Progress",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancel Translation"]: "Cancel Translation",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancelling Translation"]: "Cancelling Translation",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translation Cancelled!"]: "Translation Cancelled!",

  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content saved!"]: "Content saved!",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Saving failed! Please retry."]: "Saving failed! Please retry.",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content discarded!"]: "Content discarded!",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.No recent changes to save."]: "No recent changes to save.",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.No unsaved changes to discard."]: "No unsaved changes to discard.",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Modify Translated Content"]: "Modify Translated Content",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save"]: "Save",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard"]: "Discard",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Close"]: "Close",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard unsaved changes"]: "Discard unsaved changes",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Cancel"]: "Cancel",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Lose Changes"]: "Lose Changes",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Unsaved changes"]: "Unsaved changes",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save and Exit"]: "Save and Exit",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Exit without Saving"]: "Exit without Saving",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.If you proceed, you will lose all the changes you have made."]:
    "If you proceed, you will lose all the changes you have made."
};
