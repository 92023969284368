import { CloseOutlined, DeleteOutlined, EyeOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, GetProp, Image, Row, Space, Spin, Upload, UploadProps, message, notification } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../../../Components/i18n/core/constants";
import { style } from "../avatar/constants";
import { RcFile } from "antd/es/upload";
import { deleteFileFromS3, getUploadedFiles, uploadFileToS3 } from "../../../../../../Store/requests/_contentTranscreationRequests";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../../Store/actions/_contentTranscreationActions";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const beforeUpload = (file: FileType) => {
  console.log("beforeUpload");

  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const UploadsTab = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imagesList, setImagesList] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (loading) return;

    //fetch images list
    (async function () {
      const res = await getUploadedFiles();

      if (!res) return;

      console.log({ res });

      if (res.uploads) setImagesList(res.uploads);
    })();
  }, [loading]);

  const uploadButton = (
    <button style={{ border: 0, background: "none", cursor: "pointer" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handlePreview = (item: any, index: number, visibleStatus: boolean, isAdd: boolean, forImage: boolean) => {
    // let arrList: VideoListTypes[] | ImageListTypes[] | any;
    // if (forImage) arrList = [...imageList];
    // else arrList = [...videoList];
    // arrList[index]["visible"] = visibleStatus;
    // if (isAdd) {
    //   handleAddLiveImageOrVideo(item);
    //   arrList[index]["visible"] = false;
    // }
    // if (forImage) setImageList([...arrList]);
    // else setVideoList([...arrList]);

    const imagesListCopy = [...imagesList];

    imagesListCopy[index]["visible"] = visibleStatus;

    if (isAdd) {
      placeImageOnCanvas(item?.url);

      imagesListCopy[index]["visible"] = false;
    }

    setImagesList(imagesListCopy);
  };

  const handleImagePreviewAdd = (item: any, index: number) => {
    const imagesListCopy = [...imagesList];

    placeImageOnCanvas(item?.url);
    imagesListCopy[index]["visible"] = false;

    setImagesList(imagesListCopy);
  };

  const placeImageOnCanvas = (imageUrl: string) => {
    //addToCanvas

    dispatch(setActiveObjectModifier({ newObject: "image", url: imageUrl }));
  };
  const placeVideoOnCanvas = (file: any) => {
    //addToCanvas

    const videos = file.video_files.filter((f: any) => f.quality === "hd" && f.width <= 1920);
    videos.sort((a: any, b: any) => b.width - a.width);
    dispatch(
      setActiveObjectModifier({
        newObject: "video",
        url: videos.length ? videos[0].link : file.video_files[0].link,
        thumbnail: file.screenshot
      })
    );
  };

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    console.log("handleChange");

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        console.log("handleChange url", url);
        //setImageUrl(url);
      });
    }
  };

  const handleCustomRequest = async (file: string | RcFile | Blob) => {
    setLoading(true);
    console.log("handleCustomRequest: e", file);

    const formData: FormData = new FormData();
    formData.append("file", file);

    console.log(await uploadFileToS3(formData));

    setLoading(false);
  };

  const handleClickDeleteIcon = async (item: any) => {
    setLoading(true);
    const res = await deleteFileFromS3(item.id, item.type);

    if (!res) {
      notification.error({ message: "Deletion failed!" });
    } else message.success("Deleted!");

    setLoading(false);
  };

  return (
    <div>
      <Row gutter={[8, 8]} style={{ maxHeight: "calc(100vh - 250px)", overflowY: "scroll" }}>
        <Col xs={{ span: 8 }}>
          <div
            style={{
              border: "1px dashed #d9d9d9",
              color: "red",
              fontSize: "20px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              cursor: "pointer"
            }}
          >
            <Upload
              showUploadList={false}
              accept="image/png, image/jpeg"
              //action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              customRequest={({ file }) => handleCustomRequest(file)}
            >
              {uploadButton}
            </Upload>
          </div>
        </Col>
        {imagesList?.length ? (
          imagesList?.map((item: any, index: number) => {
            return (
              <Col xs={{ span: 8 }} key={`item_${index}`}>
                <Image
                  src={item?.url}
                  style={{ borderRadius: "5px", width: "107px", height: "61px", objectFit: "cover" }}
                  preview={{
                    visible: item?.visible,
                    closeIcon: <CustomCloseIcon handleCloseIconClick={() => handlePreview(item, index, false, false, true)} />,
                    mask: (
                      <CustomOverlay
                        handleClickAddIcon={() => {
                          console.log("handleClickAddIcon called");
                          handlePreview(item, index, false, true, true);
                        }}
                        handleClickPreviewIcon={() => {
                          handlePreview(item, index, true, false, true);
                        }}
                        handleClickDeleteIcon={async (e) => {
                          e.stopPropagation();
                          handleClickDeleteIcon(item);
                        }}
                      />
                    ),

                    imageRender: () => (
                      <PreviewModal
                        visible={visible}
                        key={`item-preview_${index}`}
                        id={`item_image_preview_${index}`}
                        handleAdd={() => {
                          handlePreview(item, index, false, true, true);
                        }}
                        onCancel={() => {
                          setVisible(false);
                        }}
                        //loading={renderLoading}
                      >
                        <Image
                          id={`item_video_preview_${index}`}
                          alt="example"
                          style={{ maxHeight: "80vh" }}
                          src={item?.url}
                          preview={false}
                          loading={"eager"}
                          onLoad={() => {
                            // setRenderLoading(false);
                          }}
                          //onEnded={() => setRenderLoading(true)}
                        />
                      </PreviewModal>
                    ),
                    toolbarRender: () => null
                  }}
                />
              </Col>
            );
          })
        ) : (
          <Paragraph
            type="secondary"
            className="optimize-font"
            style={{
              fontSize: "12px"
            }}
          >
            No Items Found!
          </Paragraph>
        )}
      </Row>
    </div>
  );
};

type PreviewModalTypes = {
  children: any;
  loading?: boolean;
  visible?: boolean;
  onCancel: () => void;
  handleAdd: () => void;
  id: string;
};

const PreviewModal: FC<PreviewModalTypes> = ({ children, loading, handleAdd, visible, onCancel, id }) => {
  const { t } = useTranslation();
  return (
    <Space style={{ background: "white", borderRadius: "10px" }} direction="vertical">
      {children}
      <div style={{ display: "flex", justifyContent: loading ? "center" : "flex-end", marginBottom: "10px", marginRight: "10px" }}>
        {loading ? (
          <Spin />
        ) : (
          <Button type="primary" onClick={handleAdd}>
            {t(I18N_MODULE_KEYS["content.button.add"])}
          </Button>
        )}
      </div>
    </Space>
  );
};

type CustomOverLayTypes = {
  handleClickAddIcon: () => void;
  handleClickPreviewIcon: () => void;
  handleClickDeleteIcon: MouseEventHandler<HTMLSpanElement>;
};
const CustomOverlay: FC<CustomOverLayTypes> = ({ handleClickAddIcon, handleClickPreviewIcon, handleClickDeleteIcon }) => {
  return (
    <Space>
      <PlusOutlined onClick={handleClickAddIcon} />
      <EyeOutlined onClick={handleClickPreviewIcon} />
      <DeleteOutlined onClick={handleClickDeleteIcon} />
    </Space>
  );
};

type CustomCloseIconsProps = {
  handleCloseIconClick: () => void;
};
const CustomCloseIcon: FC<CustomCloseIconsProps> = ({ handleCloseIconClick }) => {
  return (
    <Space onClick={handleCloseIconClick}>
      <CloseOutlined />
    </Space>
  );
};
