import { FC, useMemo, useState } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip } from "antd";
import { FolderOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import {
  setAdvancedFilter,
  setFilterListAction,
  setAdvancedFilterStatus,
  setKvalue,
  setFormat,
  setActiveCardType,
  setLandingScreen,
} from "../../../../Store/actions/_semanticSearchActions";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { useEffect } from "react";
import { ComponentAdvancedFilterProps, MED_USER_EMAIL, SEMANTIC_SEARCH_CARD } from "../Core/constants";
import {
  advancedFilterApplyButtonStyle,
  advancedFilterCancelButtonStyle,
  advancedFilterCardStyle,
  advancedFilterFileIconStyle,
  advancedFilterFolderIconStyle,
  advancedFilterSelectAllCheckboxStyle,
  advancedFilterSelectAllStyle,
  advancedFilterSelectedCountStyle,
  advancedFilterSelectedFolderStyle,
  advancedFilterTipStyle,
} from "./_AdvancedFiltersStylesConstants";

interface Folder {
  id: number;
  name: string;
  files: Array<{ filename: string; s3_url: string; created_at: string; user: string; document_type: string }>;
  source: string;
}

export const AdvancedFilterContent: React.FC<ComponentAdvancedFilterProps> = ({ stepperForinternalVarB, internalVarB, internalVarA, columns }) => {
  const { tabList, advancedFilterList, searchQuery, initialFolders, advancedFilterStatus } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const {userDetails} = useSelector((state: any) => state?.authReducer);

  const folders = initialFolders as Folder[];
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFolders, setSelectedFolders] = useState<string[]>(folders.map((folder) => folder.name));
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>();
  const [selectAllChecked, setSelectAllChecked] = useState(true); // Track "Select All" checkbox state
  const [searchFolder, setSearchFolder] = useState<string>("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const items = [
    {
      key: "Document",
      count: folders?.length,
      isDisabled: false,
      isSelected: selectedFolders.length > 0,
      selectedCount: selectedFolders.length,
    },
    {
      key: `${t(I18N_MODULE_KEYS["semantic.search.advancedfilters.URL"])}`,
      count: "00",
      isDisabled: true,
    },
    {
      key: `${t(I18N_MODULE_KEYS["semantic.search.advancedfilters.image"])}`,
      count: "00",
      isDisabled: true,
    },
    {
      key: `${t(I18N_MODULE_KEYS["semantic.search.advancedfilters.video"])}`,
      count: "00",
      isDisabled: true,
    },
    {
      key: `${t(I18N_MODULE_KEYS["semantic.search.advancedfilters.audio"])}`,
      count: "00",
      isDisabled: true,
    },
  ];

  useEffect(() => {
    setSelectedFolder(folders[0]);
  }, []);

  // Function to handle the input change and trigger search after a pause
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Clear any previous search timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to trigger the search after 3 seconds
    const newTimeout = setTimeout(() => {
      setSearchFolder(inputValue);
      performSearch(inputValue);
    }, 2000);

    setSearchTimeout(newTimeout);
  };
  const performSearch = async (query: string) => {
    // Implement your search logic here
    const matchingFolder = folders.find((folder) => folder.name.toLowerCase() === query.toLowerCase());

    // If a matching folder is found, set it as the selectedFolder
    if (matchingFolder) {
      console.log(searchFolder);
      setSelectedFolder(matchingFolder);
      console.log(matchingFolder);
    } else if (query === "" && !matchingFolder) {
      setSelectedFolder(folders[0]);
    } else {
      setSelectedFolder(null);
    }

    // Update the search query in the state
  };

  const handleSelectAllToggle = () => {
    if (!selectAllChecked) {
      // Select all folders
      const allFolderNames = folders.map((folder) => folder.name);
      setSelectedFolders(allFolderNames);
    } else {
      // Unselect all folders
      setSelectedFolders([]);
    }
    setSelectAllChecked(!selectAllChecked); // Toggle the state of "Select All" checkbox
  };

  const handleFolderClick = (folderName: any) => {
    setSelectedFolder(folderName);
  };
  const handleFolderToggle = (folderName: any) => {
    if (selectedFolders.includes(folderName)) {
      // If the folder is already selected, unselect it by removing its ID from the array
      setSelectedFolders(selectedFolders.filter((name) => name !== folderName));

      const allOtherchecked = folders.every((folder) => folder.name !== folderName || selectedFolders.includes(folder.name));
      if (allOtherchecked) {
        // If all other checkboxes are unchecked, uncheck the "Select All" checkbox
        setSelectAllChecked(false);
      }
    } else {
      // If the folder is not selected, select it by adding its ID to the array
      setSelectedFolders([...selectedFolders, folderName]);
    }
  };
  const handleStates = async () => {
    if (!searchQuery && userDetails.email!==MED_USER_EMAIL) {
      dispatch(setKvalue(internalVarA));
      dispatch(setFormat(stepperForinternalVarB));
      await dispatch(setAdvancedFilterStatus(true));
      await dispatch(setFilterListAction(selectedFolders));
      dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
      dispatch(setLandingScreen(true));

      await dispatch(setAdvancedFilter(false));
      await dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.advancedfilters.notification.applied"])));

    } 
    else if(userDetails.email===MED_USER_EMAIL){
      dispatch(setKvalue(internalVarA));
      dispatch(setFormat(stepperForinternalVarB));
      await dispatch(setAdvancedFilterStatus(true));
      await dispatch(setFilterListAction(selectedFolders));
      dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
      await dispatch(setAdvancedFilter(false));
      await dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.advancedfilters.notification.applied"])));
    }
  };

  const handleCancel = async () => {
    if(userDetails.email!==MED_USER_EMAIL)
    {dispatch(setLandingScreen(true));}

    await dispatch(setAdvancedFilter(false));
  };
  const handleReset = async () => {
    setSelectedFolders(folders.map((folder) => folder.name));
    setSelectAllChecked(true);
  };

  const prefix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#1677ff",
      }}
    />
  );

  const suffix = <span style={{ color: "#66676B", fontSize: "10px" }}>{t(I18N_MODULE_KEYS["semantic.search.input.heading.search"])}</span>;
  return (
    <>
      <Col span={6}>
        <Card style={advancedFilterCardStyle}>
          <Row>
            <Col span={24}>
              <Checkbox value="selectAll" disabled style={advancedFilterSelectAllCheckboxStyle}>
                {t(I18N_MODULE_KEYS["semantic.search.advancedfilters.selectall"])}
              </Checkbox>
            </Col>
          </Row>
          <div style={{ width: "100%" }}>
            {items.map((item: any, index: any) => (
              <Col span={24} key={index} style={{ paddingBottom: "15px" }}>
                <Checkbox
                  defaultChecked={!item.isDisabled}
                  disabled={item.isDisabled}
                  style={{
                    textAlign: "left",
                    font: "normal normal 16px/30px Nunito Sans",
                    letterSpacing: "0px",
                    color: "#034EA2",
                    opacity: 1,
                    fontWeight: item.isDisabled ? "400" : "600",
                  }}
                >
                  {item.key} | {item.count}
                </Checkbox>
                {item.isSelected && <Col style={advancedFilterSelectedCountStyle}>Selected : {item.selectedCount}</Col>}
              </Col>
            ))}
          </div>

          <div style={advancedFilterTipStyle}>{t(I18N_MODULE_KEYS["semantic.search.advancedfilters.tip"])}</div>
        </Card>
      </Col>

      <Col span={18}>
        <Card style={{ width: "100%", boxShadow: "0px 0px 10px #0000001F", minHeight: "500px", opacity: "1" }}>
          {/* Content for Card 2 */}
          <Row style={{ marginBottom: "16px" }}>
            <Col span={24}>
              <Col span={16} className="d-flex flex-direction-column align-items-start">
                <Col className="d-flex" style={{ width: "100%" }}>
                  <Col xs={{ span: 20 }}>
                    <Input
                      prefix={prefix}
                      suffix={suffix}
                      placeholder={t(I18N_MODULE_KEYS["semantic.search.header.searchbyfoldername"])}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Col>
              </Col>
            </Col>
          </Row>

          {/* Second Row with Two Columns */}
          <Row style={{ minHeight: "370px" }}>
            {/* First Column */}
            <Col span={5} style={{ borderRight: "1px solid #949494" }}>
              <Row>
                <Col span={24}>
                  <Checkbox value="selectAll" style={advancedFilterSelectAllStyle} checked={selectAllChecked} onChange={handleSelectAllToggle}>
                    {t(I18N_MODULE_KEYS["semantic.search.advancedfilters.selectall"])}
                  </Checkbox>
                </Col>
              </Row>
              <div style={{ width: "100%", paddingLeft: "8px", overflowY: "scroll", height: "320px" }}>
                {searchFolder && selectedFolder ? (
                  <Col span={24}>
                    <div>
                      <Checkbox
                        style={advancedFilterSelectAllStyle}
                        value={selectedFolder?.id}
                        checked={selectedFolders.includes(selectedFolder?.name)}
                        onChange={() => handleFolderToggle(selectedFolder?.name)}
                      ></Checkbox>
                      <span
                        onClick={() => handleFolderClick(selectedFolder)} // Handle folder name click
                        style={{ cursor: "pointer" }}
                      >
                        <FolderOutlined style={advancedFilterFolderIconStyle} />
                        {selectedFolder?.name}
                      </span>
                    </div>
                  </Col>
                ) : searchFolder && !selectedFolder ? (
                  <>
                    <Col style={{ paddingLeft: "15px" }}>Not Found !!</Col>
                  </>
                ) : (
                  <>
                    {folders.map((folder, index) => (
                      <Col span={24} key={index} style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            style={{
                              textAlign: "left",
                              font: "normal normal bold 16px/30px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#034EA2",
                              opacity: 1,
                              paddingTop: folder.source !== "Manual" ? "" : "5px",
                              paddingBottom: folder.source !== "Manual" ? "10px" : "",
                            }}
                            value={folder.id}
                            checked={selectedFolders.includes(folder.name)}
                            onChange={() => handleFolderToggle(folder.name)}
                          />
                          <div style={{ display: "flex", flexDirection: "column" }} onClick={() => handleFolderClick(folder)}>
                            <span style={{ cursor: "pointer", color: "#034EA2" }}>
                              <FolderOutlined
                                style={{
                                  color: selectedFolder && folder?.name === selectedFolder?.name ? "#034EA2" : "#83CCFB",
                                  fontSize: "20px",
                                  padding: "5px",
                                  paddingTop: folder.source !== "manual" ? "10px" : "5px",
                                }}
                              />
                              <span
                                style={{ paddingBottom: "10px", fontWeight: selectedFolder && folder?.name === selectedFolder?.name ? "800" : "" }}
                              >
                                {folder.name}
                              </span>
                            </span>

                            {folder.source !== "Manual" && ( // Display "via Sharepoint" if folder source is not manual
                              <span
                                style={{
                                  fontSize: "10px",
                                  fontStyle: "italic",
                                  color: "#555",
                                  paddingLeft: "30px",
                                  fontWeight: selectedFolder && folder?.name === selectedFolder?.name ? "800" : "",
                                }}
                              >
                                via Sharepoint
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                )}
              </div>
            </Col>

            {/* Second Column */}
            <Col span={19} style={{ paddingLeft: "40px" }} className="overflowY">
              {/* Content for the second column */}
              <Row style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                {/* <Col>{selectedFolder?.files?.filename}</Col> */}
                {selectedFolder && selectedFolder.files ? (
                  selectedFolder.files.map((file) => (
                    <Col style={advancedFilterSelectedFolderStyle} span={6}>
                      <Tooltip title={file.filename}>
                        <a href={file.s3_url} target="_blank" rel="noopener noreferrer" style={{ color: "#66676B" }}>
                          <Space style={{ display: "flex", alignItems: "center" }}>
                            <img src={PROJECT_ICONS.SEMANTIC_FILELOGO} alt="Custom Icon" style={advancedFilterFileIconStyle} />
                            {file.filename.slice(0, 14)}
                            {/* {file.filename > 13 ? `${file.slice(0, 14)}...` : file} */}
                          </Space>
                        </a>
                      </Tooltip>
                    </Col>
                  ))
                ) : (
                  // Handle the case when `selectedFolder` is null, undefined, or not an object
                  <div>No files to display.</div>
                )}
              </Row>
            </Col>
          </Row>
          <Row gutter={8} justify="end">
            <Col span={2}>
              <Button type="link" style={{ width: "100%" }} onClick={handleReset}>
                {t(I18N_MODULE_KEYS["content.button.reset"]).toUpperCase()}
              </Button>
            </Col>
            <Col span={2} style={{ paddingRight: "100px" }}>
              <Button style={advancedFilterCancelButtonStyle} onClick={handleCancel}>
                {t(I18N_MODULE_KEYS["content.button.cancel"]).toUpperCase()}
              </Button>
            </Col>
            <Col span={2}>
              <Button style={advancedFilterApplyButtonStyle} onClick={handleStates}>
                {t(I18N_MODULE_KEYS["content.button.apply"]).toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};
