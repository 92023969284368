import { Card, Typography } from "antd";
import { FC } from "react";
import { TileContent, TileProps } from "../Core/types";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const LeftAlignedAvatarTile: FC<TileProps> = ({ image, heading, description, tileColor }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: "0px 6px 18px #00000029",
        borderRadius: "12px",
        height: "100%"
      }}
      bodyStyle={{ padding: 0, height: "100%" }}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: tileColor || "#DCEAFF",
            borderRadius: "12px",
            margin: "2px",
            // padding: "20px 10px",
            padding: "20px 15px"
          }}
        >
          <img src={image} width="32px" height="32px" />
        </div>

        <div
          style={{
            padding: "1.5rem 1rem"
          }}
        >
          <Title
            level={5}
            style={{
              fontWeight: "700",
              marginTop: "5px"
            }}
          >
            {t(heading)}
          </Title>
          <div style={{ fontSize: "12px" }}>
            {typeof description === "string" ? (
              <Text style={{ fontSize: "12px" }}>{t(description) || ""}</Text>
            ) : (
              <ul>
                {(description as TileContent)?.map?.((item, key) => (
                  <li key={key}>
                    <span style={{ fontWeight: 650 }}>{t(item.heading)}: </span> {t(item.description)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export { LeftAlignedAvatarTile };
