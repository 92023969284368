import { siteConfig } from "../../Services";
import { apiResponse } from "../../Utils/_gTypes";
import { CoreClaimsTypes, UpdateAssetGuide } from "../../Pages/IDetail Extract/core/modal";
import { handleGetDataFromApi, handlePatchDataFromApi, handlePostDataFromApi, handlePutDataFromApi } from "../../Utils";

const getUserID = () => "11";

class iDetailUplaodRequests {
  async uploadFile(body: FormData, isLoading?: any, config?: any) {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.IDX_UPLOAD_FILE + `?user_id=${getUserID()}`,
      body,
      isLoading ? isLoading : undefined,
      config
    );

    return res?.data;
  }

  async getListKeyMessages(file_id: number, isLoading?: any) {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.IDX_LIST_KEY_MESSAGE + `/${file_id}`, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async createClaims(body: { keymessages: number[]; file_id: number }, isLoading?: any) {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.IDX_CREATE_CLAIMS,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    return res?.data;
  }

  async updateCategories(body: { assetguide_id: number; keymessages: number[] }, isLoading?: any) {
    const res: apiResponse = await handlePatchDataFromApi(
      siteConfig.IDX_UPDATE_CATEGORIES,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    return res?.data;
  }

  async updateClaims(body: UpdateAssetGuide, isLoading?: any) {
    const res: apiResponse = await handlePutDataFromApi(siteConfig.IDX_UPDATE_CLAIMS, body, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async getClaimsData(assetguide_id: number, isLoading?: any) {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.IDX_GET_CLAIMS + `/${assetguide_id}`, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async getAssetTypes(isLoading?: any) {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.IDX_ASSET_TYPES, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async createMainAsset(
    body: {
      assetguide_id: number;
      keymessages: number[];
      asset_types: number[];
    },
    isLoading?: any
  ) {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.IDX_CREATE_MAIN_ASSET,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    return res?.data;
  }

  async getAssetMainData(asset_id: number, isLoading?: any) {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.IDX_GET_MAIN_ASSET + `/${asset_id}`, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async saveFinalHTMLData(assetId: number, html: string, isLoading?: any) {
    const res: apiResponse = await handlePutDataFromApi(
      siteConfig.IDX_CREATE_MAIN_ASSET,
      { asset_id: assetId, asset: html, user_id: 0 },
      isLoading ? isLoading : undefined
    );

    return res?.data;
  }

  async uploadImage(assetId: number, body: FormData, isLoading?: any) {
    const res: apiResponse = await handlePostDataFromApi(
      `${siteConfig.IDX_CREATE_MAIN_ASSET}/${assetId}${siteConfig.IDX_UPLOAD_IMAGE}`,
      body,
      isLoading ? isLoading : undefined
    );

    return res?.data;
  }

  async getHTMLOutputPDF(assetId: number) {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.IDX_GET_MAIN_ASSET + `/${assetId}` + siteConfig.IDX_DOWNLOAD);

    return res?.data;
  }
}

export default new iDetailUplaodRequests();
