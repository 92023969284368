import { VoiceListType } from "../../Pages/Content Transcreation/Content Translation/core/modals";
import { NameValueTypes } from "../../Utils/_gTypes";

export const PLAYER_CONSTANT = {
  PLAY: "PLAYER_CONSTANT/PLAY",
  PAUSE: "PLAYER_CONSTANT/PAUSE",
  RESUME: "PLAYER_CONSTANT/RESUME",
  STOP: "PLAYER_CONSTANT/STOP",
  DURATION: "PLAYER_CONSTANT/DURATION",
  NARRATION: "PLAYER_CONSTANT/NARRATION",
  LANGUAGE: "PLAYER_CONSTANT/LANGUAGE",
  GET_VOICE_LIST: "PLAYER_CONSTANT/GET_VOICE_LIST",
  SELECTED_VOICE: "PLAYER_CONSTANT/SELECTED_VOICE"
};

export const setPlayAction = (data: boolean) => {
  return { type: PLAYER_CONSTANT.PLAY, payload: data };
};
export const setPauseAction = (data: boolean) => {
  return { type: PLAYER_CONSTANT.PAUSE, payload: data };
};
export const setResumeAction = (data: boolean) => {
  return { type: PLAYER_CONSTANT.RESUME, payload: data };
};
export const setStopAction = (data: boolean) => {
  return { type: PLAYER_CONSTANT.STOP, payload: data };
};
export const setDurationAction = (data: boolean) => {
  return { type: PLAYER_CONSTANT.DURATION, payload: data };
};
export const setNarrationAction = (data: any[]) => {
  return { type: PLAYER_CONSTANT.NARRATION, payload: data };
};
export const setLanguageAction = (data: string) => {
  return { type: PLAYER_CONSTANT.LANGUAGE, payload: data };
};
export const getVoiceListAction = (data: VoiceListType[]) => {
  return { type: PLAYER_CONSTANT.GET_VOICE_LIST, payload: data };
};
export const setSelectedVoiceAction = (data: string) => {
  return { type: PLAYER_CONSTANT.SELECTED_VOICE, payload: data };
};

const initialState = {
  play: false,
  pause: false,
  resume: false,
  stop: false,
  narration: [],
  language: "",
  voiceList: [],
  selectedVoice: ""
};

export default function playerReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case PLAYER_CONSTANT.PLAY: {
      return {
        ...state,
        play: payload
      };
    }
    case PLAYER_CONSTANT.PAUSE: {
      return {
        ...state,
        pause: payload
      };
    }
    case PLAYER_CONSTANT.RESUME: {
      return {
        ...state,
        resume: payload
      };
    }
    case PLAYER_CONSTANT.STOP: {
      return {
        ...state,
        stop: payload
      };
    }
    case PLAYER_CONSTANT.DURATION: {
      return {
        ...state,
        duration: payload
      };
    }
    case PLAYER_CONSTANT.NARRATION: {
      return {
        ...state,
        narration: payload
      };
    }
    case PLAYER_CONSTANT.LANGUAGE: {
      return {
        ...state,
        language: payload
      };
    }
    case PLAYER_CONSTANT.GET_VOICE_LIST: {
      return {
        ...state,
        voiceList: payload
      };
    }
    case PLAYER_CONSTANT.SELECTED_VOICE: {
      return {
        ...state,
        selectedVoice: payload
      };
    }
    default: {
      return state;
    }
  }
}
