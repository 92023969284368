import React, { useState } from "react";
import { Card, Input, Button, Row, Col, Modal, Upload } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { setBatchQAData, setLLMModel } from "../../../../Store/actions/_semanticSearchActions";
import { useDispatch, useSelector } from "react-redux";
import "./StylesFolderForNextInsight/_MultiModal.css";
import { ChatModalScreen } from "./_ChatModalScreen";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import MultiModalFileUpload from "../_MultiModalFileUpload";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";

const { TextArea } = Input;

interface MultiModalSelectionProps {
  handleAdvancedSearchClose: () => void;
}

const MultiModalSelection: React.FC<MultiModalSelectionProps> = ({ handleAdvancedSearchClose }) => {
  const { llmModel } = useSelector((state: any) => state?.semanticSearchReducer);

  const dispatch = useDispatch();

  const [task, setTask] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("Full Text");
  const [llmmodel, setLlmModel] = useState<string>(llmModel);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]); // State to manage the file list
  const [isModalVisibleForBatchQA,setIsModalVisibleForBatchQAFiles]=useState<boolean>(false);

  const handleTaskChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTask(e.target.value);
  };

  const handleSearchTypeChange = (newSearchType: string) => {
    if (newSearchType === "Chat with Document") {
      setIsModalVisible(true);
    } else {
      setSearchType(newSearchType);
    }
  };

  const handleLlmModelChange = (newLlmModel: string) => {
    setLlmModel(newLlmModel);
  };

  const handleSubmit = () => {
    console.log("Task:", task);
    console.log("Search Type:", searchType);
    console.log("LLM Model:", llmmodel);
    dispatch(setLLMModel(llmmodel));
    handleAdvancedSearchClose();
  };

  const isSelected = (currentValue: string, selectedValue: string) => {
    return currentValue === selectedValue ? { fontWeight: "bold" } : {};
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleOkModal = () => {
    setIsModalVisible(false);
    // Handle any additional actions if needed
  };
  const handleFileChange = (info: any) => {
    if (info.fileList) {
      // Filter only CSV files
      const csvFiles = info.fileList
        .map((file: any) => file.originFileObj)
        .filter((file: File) => file.type === 'text/csv' || file.name.endsWith('.csv'));
  
      setFileList(csvFiles);
    }
  };
  
  const handleDeleteFileFromList = (fileIndex: number) => {
    setFileList(fileList.filter(file => file.uid !== fileIndex));
  };
  
  const beforeUpload = (file: File) => {
    const isCSV = file.type === 'text/csv';
    
    if (!isCSV) {
      dispatch(setNotificationMsgAction(`${file.name} is not a csv file`))
    
    }
    return isCSV; // Allow upload if CSV, otherwise reject
};


  console.log(fileList)
  return (
    <div className="multillm-container">
      <Row>
        <Col span={8} style={{ backgroundColor: "white", padding: "30px", paddingRight: "10px", paddingLeft: "20px" }}>
          <div style={{ display: "flex", gap: "3px" }}>
            <Button type="link" style={{ color: "#034EA2", fontWeight: "bolder" }}>
              Batch QA
            </Button>
            <Button type="link" style={{ color: "#034EA2" }}>
              Chat
            </Button>
          </div>
          {fileList.length != 0 ? (
            <>
              <div
                style={{
                  background: "#F4F8FF",
                  height: "290px",
                  marginTop: "10px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontWeight: "bolder", fontSize: "15px", marginLeft: "10px", marginTop: "10px" }}>Added Files {"("}{fileList.length}{")"}</div>
                <div style={{ height: "220px" }}>
                  
                    

                    {fileList?.map((file, index) => (
     <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
     <div style={{ color: "#363939" }}>
       <p>{file?.name}</p>
       <p style={{ fontSize: "10px", paddingTop: "5px" }}>{file.size}kb</p>
     </div>
     <img src={PROJECT_ICONS.TRASH} style={{ height: "20px", paddingRight: "10px" }} onClick={()=>{handleDeleteFileFromList(file?.uid)}} />
   </div>
  ))}
  
              
    </div>
    
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", padding: "10px" }}>
                  <Button
                    type="link"
                    style={{ color: "#034EA2" }}
                    onClick={() => {
                      setFileList([]);
                    }}
                  >
                    REMOVE ALL
                  </Button>

                  <Button style={{ background: "#034EA2", color: "white" }} onClick={()=>{setIsModalVisibleForBatchQAFiles(true)}}>UPLOAD</Button>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                background: "#F4F8FF",
                height: "290px",
                marginTop: "5px",
                marginLeft: "10px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Upload.Dragger
                style={{ border: "none", background: "transparent" }}
                showUploadList={false} // Hide the default file list
                onChange={
                  handleFileChange
                }
                beforeUpload={beforeUpload}

                multiple
              >
                <Lottie animationData={PROJECT_ICONS.UPLOAD_FILE} style={{ height: "100px" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "400",
                    fontSize: "18px",
                    color: "#66676B",
                  }}
                >
                  <p>Drag And Drop Your Files Here.</p>
                  <p>Or Import From:</p>
                </div>
              </Upload.Dragger>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px", marginTop: "6px" }}>
                <img
                  src={PROJECT_ICONS.COMPUTERSOLID}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                    padding: "10px", // Adjust this padding to fit your circle size
                    width: "43px", // Adjust as needed
                    height: "43px", // Adjust as needed
                    boxShadow: "0px 0px 10px 2px #0000000D",
                  }}
                />
                <img
                  src={PROJECT_ICONS.GOOGLEDRIVE}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                    padding: "10px",
                    width: "43px",
                    height: "43px",
                    boxShadow: "0px 0px 10px 2px #0000000D",
                  }}
                />
                <img
                  src={PROJECT_ICONS.DROPBOX}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                    padding: "10px",
                    width: "43px",
                    height: "43px",
                    boxShadow: "0px 0px 10px 2px #0000000D",
                  }}
                />
                <img
                  src={PROJECT_ICONS.BOXLINK}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                    padding: "10px",
                    width: "43px",
                    height: "43px",
                    boxShadow: "0px 0px 10px 2px #0000000D",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px", padding: "10px", marginBottom: "5px" }}>
                <Button type="link" style={{ color: fileList.length==0?"#A9A9A9": "#034EA2" }} disabled={fileList.length==0}>
                  REMOVE ALL
                </Button>

                <Button style={{ background: fileList.length==0?"#EDEDED": "#034EA2", color: fileList.length==0?"#A9A9A9":"white" }}  disabled={fileList.length==0}>UPLOAD</Button>
              </div>
            </div>
          )}
        </Col>
        <Col span={15} style={{ padding: "30px" }}>
          <Row>
            <Col span={8} style={{ paddingLeft: "40px", borderLeft: "1px solid #DADADA" }}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center" }}>
                <div style={{ marginBottom: "15px" }}>
                  <span style={{ color: "#231F20", fontWeight: "400" }}>Type of Search</span>
                  <InfoCircleOutlined style={{ marginLeft: "10px", color: "#231F20" }} />
                </div>
                <div
                  style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("Full Text", searchType) }}
                  onClick={() => handleSearchTypeChange("Full Text")}
                >
                  Full Text
                </div>
                <div
                  style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "not-allowed", ...isSelected("Semantic", searchType) }}
                >
                  Semantic
                </div>
                <div style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "not-allowed", ...isSelected("Hybrid", searchType) }}>
                  Hybrid
                </div>
                <div
                  style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "not-allowed", ...isSelected("Pro Mode", searchType) }}
                >
                  Pro Mode
                </div>
                <div
                  style={{
                    color: "#034EA2",
                    fontSize: "14px",
                    marginBottom: "20px",
                    cursor: "pointer",
                    ...isSelected("Chat with Document", searchType),
                  }}
                  onClick={() => handleSearchTypeChange("Chat with Document")}
                >
                  Chat with Document
                </div>
              </div>
            </Col>
            <Col span={14} style={{ paddingLeft: "60px", borderLeft: "1px solid #DADADA" }}>
              <div style={{ marginBottom: "15px" }}>
                <span style={{ color: "#231F20", fontWeight: "400" }}>LLM Models</span>
                <InfoCircleOutlined style={{ marginLeft: "10px", color: "#231F20" }} />
              </div>
              <Row>
                <Col span={10}>
                  <div
                    style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("gpt-4o", llmmodel) }}
                    onClick={() => handleLlmModelChange("gpt-4o")}
                  >
                    GPT 4o
                  </div>
                  <div
                    style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("gpt-4-turbo", llmmodel) }}
                    onClick={() => handleLlmModelChange("gpt-4-turbo")}
                  >
                    GPT 4 Turbo
                  </div>
                  <div
                    style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("gpt-4", llmmodel) }}
                    onClick={() => handleLlmModelChange("gpt-4")}
                  >
                    GPT 4
                  </div>
                  <div
                    style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("gpt-3.5", llmmodel) }}
                    onClick={() => handleLlmModelChange("gpt-3.5")}
                  >
                    GPT 3.5
                  </div>
                  <div
                    style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("gpt-3.5-turbo", llmmodel) }}
                    onClick={() => handleLlmModelChange("gpt-3.5-turbo")}
                  >
                    GPT 3.5 Turbo
                  </div>
                  <div
                    style={{
                      color: "#034EA2",
                      fontSize: "14px",
                      marginBottom: "20px",
                      cursor: "pointer",
                      ...isSelected("gpt-3.5-turbo-16k", llmmodel),
                    }}
                    onClick={() => handleLlmModelChange("gpt-3.5-turbo-16k")}
                  >
                    GPT 3.5 Turbo 16k
                  </div>
                </Col>
                <Col span={14}>
                  <div
                    style={{
                      color: "#034EA2",
                      fontSize: "14px",
                      marginBottom: "20px",
                      cursor: "pointer",
                      ...isSelected("anthropic.claude-3-sonnet-20240229-v1:0", llmmodel),
                    }}
                    onClick={() => handleLlmModelChange("anthropic.claude-3-sonnet-20240229-v1:0")}
                  >
                    Claude -Sonnet
                  </div>
                  <div
                    style={{
                      color: "#034EA2",
                      fontSize: "14px",
                      marginBottom: "20px",
                      cursor: "pointer",
                      ...isSelected("anthropic.claude-3-haiku-20240307-v1:0", llmmodel),
                    }}
                    onClick={() => handleLlmModelChange("anthropic.claude-3-haiku-20240307-v1:0")}
                  >
                    Claude -Haiku
                  </div>
                  <div
                    style={{ color: "#034EA2", fontSize: "14px", marginBottom: "20px", cursor: "pointer", ...isSelected("gemini-1.5", llmmodel) }}
                    onClick={() => handleLlmModelChange("gemini-1.5")}
                  >
                    Gemini 1.5
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
            <Button
              style={{ marginLeft: "10px", border: "1px solid #204D9C", borderRadius: "3px", opacity: "1", color: "#034EA2" }}
              onClick={() => {
                dispatch(setLLMModel(llmModel));
                handleAdvancedSearchClose();
              }}
            >
              CANCEL
            </Button>
            <Button
              style={{ marginLeft: "10px", backgroundColor: "#034EA2", color: "white", borderRadius: "3px", opacity: "1" }}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          </div>
        </Col>
      </Row>

      {/* Modal for Chat with Document */}
      {isModalVisible && <ChatModalScreen setIsModalVisible={setIsModalVisible} handleAdvancedSearchClose={handleAdvancedSearchClose} />}
      {isModalVisibleForBatchQA && <MultiModalFileUpload isModalVisibleForBatchQA={isModalVisibleForBatchQA} setIsModalVisibleForBatchQAFiles={setIsModalVisibleForBatchQAFiles} fileList={fileList} />}
    </div>
  );
};

export default MultiModalSelection;
