import { FC } from "react";
import { Flex, Space, Typography } from "antd";
import { useDispatch } from "react-redux";
import { setOpenAddGlossaryModalTMAction } from "../../../../Store/actions/_translationModuleActions";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const HeadingContainer: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._TranslateAssetMain._HeadingContainer.${value as string}`])}`;
  };

  return (
    <Flex vertical gap={1} justify="center">
      <Flex align="center" gap="small">
        <Text style={{ fontSize: "18px", fontWeight: "600" }}>{getTranslatedText("Translate your Asset")}</Text>
        <Text style={{ fontSize: "18px", fontWeight: "600" }}>|</Text>
        <Text
          style={{ fontSize: "15px", fontWeight: "600", color: "rgba(3, 78, 162, 1)", lineHeight: "12px", cursor: "pointer" }}
          onClick={() => dispatch(setOpenAddGlossaryModalTMAction(true))}
        >
          {getTranslatedText("ADD GLOSSARY")}
        </Text>
      </Flex>

      <Text style={{ fontSize: "12px", fontWeight: "400", color: "rgba(123, 123, 123, 1)" }}>
        {getTranslatedText("At the moment, AI-generated translation will be applied to all of your assets.")}
      </Text>
    </Flex>
  );
};
