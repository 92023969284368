import { FC } from "react";
import { Flex, Pagination, Table, Typography } from "antd";
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";

import { Tooltip } from "antd";

import { useTranslation } from "react-i18next";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { GlossaryTypes } from "../../Core/modals";
import { PageTypes } from "../../../Content Transcreation/Content History/core/modals";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

type CHListViewTypes = {
  page: PageTypes;
  loading: boolean;
  list: GlossaryTypes[];
  handleOnChange: (current: number) => void;
  handleDelete: (record: GlossaryTypes) => void;
  handleEdit: (record: GlossaryTypes) => void;
};

export const GlossaryTable: FC<CHListViewTypes> = ({ list, loading, handleOnChange, page, handleDelete, handleEdit }) => {
  const { t } = useTranslation();

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._AddGlossaryModal.${value as string}`])}`;
  };

  const ContentHistoryColumns: ColumnsType<any> = [
    {
      title: getTranslatedText(`Replace`),
      dataIndex: "source",
      render: (value, record, index) => (
        <Tooltip title={value || ""}>
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textWrap: "wrap" }}>
            {value?.length < 10 ? value : value?.slice(0, 10) + "..." || " "}
          </span>
        </Tooltip>
      )
    },
    {
      title: getTranslatedText(`With`),
      dataIndex: "target",
      render: (value) => (
        <Tooltip title={value || ""}>
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {value?.length < 10 ? value : value?.slice(0, 10) + "..." || " "}
          </span>
        </Tooltip>
      )
    },
    {
      title: `${t(I18N_MODULE_KEYS["content.text.action"])}`,
      dataIndex: "action",
      render: (value, record, index) => (
        <Flex gap={"small"}>
          <EditOutlined style={{ color: "#0546A5", cursor: "pointer" }} onClick={() => handleEdit(record)} />
          <DeleteOutlined style={{ color: "#0546A5", cursor: "pointer" }} onClick={() => handleDelete(record)} />
        </Flex>
      )
    }
  ];

  return (
    <Table
      columns={ContentHistoryColumns}
      dataSource={list}
      pagination={{
        pageSize: page.page_size,
        current: page.current_page,
        total: page.total_count,
        onChange: (page) => handleOnChange(page),
        hideOnSinglePage: true
      }}
      loading={loading}
      // size="middle"
    />
  );
};
