import { FC, useMemo, useState, useRef, useEffect } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { AlignLeftOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import TextArea from "antd/es/input/TextArea";
import { getDocuments } from "../../../../../Store/requests/_semanticSearchRequests";
import { setActiveCardType, setAdvancedFilter, setEncodedSearchQueryAction, setEncodedSemanticSearchId, setInitialFolders, setLandingScreen, setSearchQuery, setSemanticSearchId } from "../../../../../Store/actions/_semanticSearchActions";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { handleCancelApiRequest } from "../../../../../Utils";
import { SEMANTIC_SEARCH_CARD } from "../../Core/constants";

export const LiteratureReferenceCardSearchBar: FC = () => {
  const { cardDataCollection, proModeScreen, loadingForReportGeneration } = useSelector((state: any) => state.semanticSearchReducer);

  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const btnRef: any = useRef();
  const timerRef: any = useRef();

  const { searchQuery, advancedFilterStatus, advancedFilterList, advancedFilter, semanticEncodedSearchid } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );

  const { isLoading }: { isLoading: boolean } = useSelector((state: any) => state?.nonPersistedReducer);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [kval, setKval] = useState<any>(4);
  const [inputValue, setInputValue] = useState("");

  const [localsearcjid, setlocalsearchid] = useState(semanticEncodedSearchid);
  const [promodeinitial, setPromodeInitial] = useState<boolean>(false); // State for toggle switch
  const [fontSize, setFontSize] = useState("38px");
  const [isadvancedSearchOpen, setisAdvancedSearchOpen] = useState<boolean>(false);
  
  // Update input value only if search is not in progress


  const getFoldersBackend = async () => {
    if (isLoading) {
    } else {
      try {
        // Step 3: Set isFetching to true when fetching advanced filters
        setIsFetching(true);

        const folderData = await getDocuments();
        if (folderData) {
          await dispatch(setInitialFolders(folderData?.data));
          dispatch(setLandingScreen(false));
          await dispatch(setAdvancedFilter(true));
        }
      } catch (error) {
        await dispatch(setInitialFolders([]));
        // dispatch(setAdvancedFilter(true));
        dispatch(setLandingScreen(true));

        dispatch(setNotificationMsgAction("Folders cannot be loaded for Filter"));
        console.error("Error fetching folder data:", error);
      } finally {
        // Step 4: Set isFetching back to false after fetching
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      console.log(semanticEncodedSearchid + "this is one now  currently looking");
      if (!localsearcjid) {
       
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(searchQuery);
  }, [searchQuery]);

  const handleInputChange = (e: any) => {
    if (!isLoading) {
      setSearchTerm(e.target.value);
      setInputValue(e.target.value);
      if (e.target.value === "") {
      
        setlocalsearchid(0);
      }
    }
  };

  const savePrompt = async (isSave: boolean) => {
    dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
    if (!isSave) {
      setSearchTerm("");
      dispatch(setLandingScreen(true));

      dispatch(setSearchQuery(""));
      setInputValue("");
      clearTimeout(timerRef.current);
      handleCancelApiRequest();
      dispatch(setLoadingStatusAction(false));
      dispatch(setEncodedSemanticSearchId(0));
      dispatch(setSemanticSearchId(0));
      dispatch(setEncodedSearchQueryAction(""));

      return;
    }

    if (!searchTerm) return;
    dispatch(setLandingScreen(false));
    dispatch(setAdvancedFilter(false));
    dispatch(setSearchQuery(searchTerm));
    dispatch(setLoadingStatusAction(true));

    if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };



  
  const handleSearch = async (s: string) => {

  };

  const handleStateInitialisationbeforequerying = async () => {
 
  };

  const handleResetOnError = async () => {
  
  };

  const handleDisptachSemanticId = async (res: any) => {
  
  };



  useEffect(() => {
    console.log(cardDataCollection);
  }, [cardDataCollection]);

  return (
    <>
        <Col span={20} style={{ paddingLeft: "60px" }}>
            <div style={{ color: "black", borderRadius: "40px", boxShadow: "0px 0px 14px grey" }}>
              <div style={{ background: "white", borderRadius: "50px" ,padding:'10px'}}>
      {/* Text Icon */}
      <img src={PROJECT_ICONS.TEXTREFERENCE} style={{ marginRight: '16px',height:'14px', color: '#ccc' ,marginLeft:'10px',marginTop:'0px',marginBottom:'2px'}}/>
    
      
      {/* Input Field */}
      <TextArea
        placeholder="Enter content"
        style={{
          border: 'none',
          outline: 'none',
          flex: 1,
          fontSize: '14px',
          width:'70%',
          resize:'none'
        }}
        draggable={false}
        autoSize={false} 
        rows={1}
        onChange={handleInputChange}
        readOnly={isLoading}
        disabled={isLoading}
        onKeyDownCapture={(e) => {
          const { which } = e;

          if (which === 13) {
            btnRef?.current?.click();
            if (!isLoading) {
            }
          }
        }}
      />


      {/* Advanced Filter Link */}
      <a style={{ fontSize: '14px', marginRight: '10px' ,marginLeft:'50px',color: "#1E4DA1"
}} onClick={getFoldersBackend}>
        Advanced Filter
      </a>

      {/* Submit Button */}
      <button
        style={{
          backgroundColor: '#f1f1f1',
          borderRadius: '20px',
          padding: '5px 15px',
          cursor: 'pointer', 
          fontSize: '14px',
          color:'#66676B',
          border:'none',
          fontWeight:'bold'
        }}
        onClick={() => savePrompt(searchTerm && searchQuery && isLoading ? false : true)}
                ref={btnRef}
      >
        SUBMIT
      </button>
    </div>
              </div>
          </Col>
    </>
  );
};
