import { FC } from "react";
import { CT_DASHBOARD_STATUS, onDownload } from "../../../../../Utils";
import { Button, Card, Checkbox, Col, Image, Row, Skeleton, Space, Spin, Typography, Upload } from "antd";
import {
  DownloadOutlined,
  EyeOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from "@ant-design/icons";
import Lottie from "lottie-react";
import { NameValueTypes } from "../../../../../Utils/_gTypes";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { ImageMask } from "../../../../../Components/Image";

const { Paragraph } = Typography;

type IProps = {
  platform: NameValueTypes;
  stories: any[];
  replaceGeneratedImage: (id: any, file: any) => void;
  regenerateImage: (id: any) => void;
  addLiveImageOrVideo: (story_id: number, scene: string) => void;
  isLoading: boolean;
};

export const GeneratedImageScreen: FC<IProps> = ({ stories, replaceGeneratedImage, isLoading, regenerateImage, addLiveImageOrVideo, platform }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 24]}>
      {stories && stories?.length ? (
        <>
          {stories?.map((item: any, index: number) => {
            return (
              <>
                {item?.checked && (
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} key={`proceed_to_video_${index}`}>
                    <Card
                      style={{
                        border: "0.4000000059604645px solid #000000",
                        borderRadius: "12px",
                        height: "100%"
                      }}
                      bodyStyle={{ height: "100%", display: "flex", flexDirection: "column" }}
                    >
                      <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                        {/* <Checkbox style={{ paddingTop: "2px" }} /> */}
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <Paragraph
                          className="optimize-font"
                          style={{
                            fontSize: "15px",
                            marginBottom: "0px",
                            font: "normal normal bold 16px/24px Nunito Sans"
                          }}
                        >
                          {/* Scene {index + 1} */}
                          {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.scene"])} {index + 1}
                        </Paragraph>
                        <Paragraph
                          style={{
                            fontSize: "15px",
                            marginBottom: "0px"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              marginBottom: "0px",
                              fontWeight: "bold"
                            }}
                          >
                            {/* Title: */}
                            {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.title"])}
                          </span>{" "}
                          {item?.title || ""}
                        </Paragraph>

                        <Paragraph
                          style={{
                            fontSize: "15px",
                            marginBottom: "0px"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              marginBottom: "0px",
                              fontWeight: "bold"
                            }}
                          >
                            {/* Scene: */}
                            {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.scene"])}
                          </span>{" "}
                          {item?.scene || ""}
                        </Paragraph>

                        <Paragraph style={{ fontSize: "15px" }}>
                          <span
                            style={{
                              fontSize: "14px",
                              marginBottom: "0px",
                              fontWeight: "bold"
                              // font: "normal normal bold 16px/24px Nunito Sans"
                            }}
                          >
                            {/* Narration: */}
                            {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.narration"])}
                          </span>{" "}
                          {item?.narration || ""}
                        </Paragraph>
                      </div>
                      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {platform?.platform_type === "image" ? (
                          <>
                            {item?.image_status === CT_DASHBOARD_STATUS.FAILED || !item?.image_status ? (
                              <Paragraph
                                type="secondary"
                                className="optimize-font"
                                style={{
                                  fontSize: "12px"
                                }}
                              >
                                {/* {item?.image_error || "No Image Found"} */}
                                {item?.image_error || `${t(I18N_MODULE_KEYS["content.text.noimagefound"])}`}
                              </Paragraph>
                            ) : (
                              <>
                                {item?.image_status !== CT_DASHBOARD_STATUS.SUCCESS ? (
                                  <div className="space-align-block d-flex justify-content-center" style={{ margin: "20px 10px" }}>
                                    <Space>
                                      <Spin />
                                    </Space>
                                  </div>
                                ) : (
                                  <>
                                    {item?.image_url && item?.image_url?.length ? (
                                      <>
                                        <Row gutter={[16, 24]}>
                                          <Col key={`proceed_to_video_images_${index}`} xs={{ span: 24 }}>
                                            {item?.image_url?.map((item: string, index: number) => {
                                              return (
                                                <div key={`proceed_to_video_images_${index}`}>
                                                  <Image
                                                    src={item}
                                                    style={{
                                                      borderRadius: "8px",
                                                      width: "35vh"
                                                      // margin: "20px 10px"
                                                    }}
                                                    preview={{
                                                      toolbarRender: (
                                                        _,
                                                        {
                                                          transform: { scale },
                                                          actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn }
                                                        }
                                                      ) => (
                                                        <Space size={12} className="toolbar-wrapper">
                                                          <DownloadOutlined onClick={() => onDownload(item)} />
                                                          <SwapOutlined rotate={90} onClick={onFlipY} />
                                                          <SwapOutlined onClick={onFlipX} />
                                                          <RotateLeftOutlined onClick={onRotateLeft} />
                                                          <RotateRightOutlined onClick={onRotateRight} />
                                                          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                        </Space>
                                                      ),
                                                      mask: <ImageMask />
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })}
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <Paragraph
                                        type="secondary"
                                        className="optimize-font"
                                        style={{
                                          fontSize: "12px"
                                        }}
                                      >
                                        {/* No Image Found! */}
                                        {t(I18N_MODULE_KEYS["content.text.noimagefound"])}
                                      </Paragraph>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Row gutter={[16, 24]}>
                              <Col key={`proceed_to_video_images_${index}`} xs={{ span: 24 }}>
                                <div>
                                  {item?.video?.id ? (
                                    <Image
                                      src={item?.video?.thumbnail_url}
                                      style={{ borderRadius: "5px" }}
                                      preview={{
                                        imageRender: () => <video style={{ maxHeight: "50vh" }} controls src={item?.video?.video_url} />,
                                        toolbarRender: () => null,
                                        mask: <ImageMask />
                                      }}
                                    />
                                  ) : (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                      <Lottie
                                        id="email"
                                        animationData={PROJECT_ICONS.CT_VIDEO_CLIP_ANIMATION}
                                        loop={true}
                                        style={{ height: "50%", width: "50%" }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <Upload
                          accept={platform?.platform_type === "image" ? ".png, .jpg, .jpeg" : "video/mp4"}
                          onChange={({ file, fileList }) => {
                            if (file.status !== "uploading") {
                              replaceGeneratedImage(item?.id, file);
                            }
                          }}
                          showUploadList={false}
                          // disabled={isLoading}
                        >
                          <Button type="link">
                            {/* Replace */}
                            {t(I18N_MODULE_KEYS["content.button.replace"])}
                          </Button>
                        </Upload>
                        {platform?.platform_type === "image" && (
                          <Button type="link" onClick={() => regenerateImage(item?.id)} disabled={item?.image_status === CT_DASHBOARD_STATUS.PENDING}>
                            {/* Regenerate */}
                            {t(I18N_MODULE_KEYS["content.button.regenerate"])}
                          </Button>
                        )}
                        {platform?.platform_type === "video" && (
                          <Button
                            type="link"
                            onClick={() => {
                              addLiveImageOrVideo(item?.id, item?.scene);
                            }}
                            disabled={isLoading}
                            style={{ fontWeight: "800" }}
                          >
                            {/* Add */}
                            {t(I18N_MODULE_KEYS["content.button.add"])}
                          </Button>
                        )}
                      </div>
                    </Card>
                  </Col>
                )}
              </>
            );
          })}
        </>
      ) : (
        <Skeleton active />
      )}
    </Row>
  );
};

// {item?.image_status !== CT_DASHBOARD_STATUS.SUCCESS ? (
//   <div className="space-align-block d-flex justify-content-center" style={{ margin: "20px 10px" }}>
//     <Space>
//       <Spin />
//     </Space>
//   </div>
// ) : (
//   <>
//     {/* {!item?.video ? ( */}
//     {platform?.platform_type === "image" ? (
//       <>
//         {item?.image_url && item?.image_url?.length ? (
//           <>
//             <Row gutter={[16, 24]}>
//               <Col key={`proceed_to_video_images_${index}`} xs={{ span: 24 }}>
//                 {item?.image_url?.map((item: string, index: number) => {
//                   return (
//                     <div key={`proceed_to_video_images_${index}`}>
//                       <Image
//                         src={item}
//                         style={{
//                           borderRadius: "8px",
//                           width: "35vh",
//                           margin: "20px 10px"
//                         }}
//                         preview={{
//                           toolbarRender: (
//                             _,
//                             {
//                               transform: { scale },
//                               actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn }
//                             }
//                           ) => (
//                             <Space size={12} className="toolbar-wrapper">
//                               <DownloadOutlined onClick={() => onDownload(item)} />
//                               <SwapOutlined rotate={90} onClick={onFlipY} />
//                               <SwapOutlined onClick={onFlipX} />
//                               <RotateLeftOutlined onClick={onRotateLeft} />
//                               <RotateRightOutlined onClick={onRotateRight} />
//                               <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
//                               <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
//                             </Space>
//                           )
//                         }}
//                       />
//                     </div>
//                   );
//                 })}
//               </Col>
//             </Row>
//           </>
//         ) : (
//           <Paragraph
//             type="secondary"
//             className="optimize-font"
//             style={{
//               fontSize: "12px"
//             }}
//           >
//             No Image Found!
//           </Paragraph>
//         )}
//       </>
//     ) : (
//       <>
//         <Row gutter={[16, 24]}>
//           <Col key={`proceed_to_video_images_${index}`} xs={{ span: 24 }}>
//             <div>
//               {item?.video?.id ? (
//                 <Image
//                   src={item?.video?.thumbnail_url}
//                   style={{ borderRadius: "5px", margin: "20px 10px", width: "50vh" }}
//                   preview={{
//                     imageRender: () => <video style={{ maxHeight: "50vh" }} controls src={item?.video?.video_url} />,
//                     toolbarRender: () => null
//                   }}
//                 />
//               ) : (
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Lottie
//                     id="email"
//                     animationData={PROJECT_ICONS.CT_VIDEO_CLIP_ANIMATION}
//                     loop={true}
//                     style={{ height: "50%", width: "50%" }}
//                   />
//                 </div>
//               )}
//             </div>
//           </Col>
//         </Row>
//       </>
//     )}
//   </>
// )}
