import { CSSProperties } from 'react';

export const nextinsightsearchquerycontainer: CSSProperties = {
    paddingRight: "14px", fontSize: "23px", fontWeight: "500", wordWrap: "break-word", maxWidth: "700px", color: "#66676B" ,display:'flex'
};
export const nextinsightmenu: CSSProperties = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    position: "absolute",
    zIndex: 10000,
    borderRadius: "10px",}

export const nextinsightsubmenu : CSSProperties = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "700px",
    position: "absolute",
    zIndex: 10000,
    marginTop: "47px",
    borderRadius: "10px",
};
export const nextinsightmagicpen: CSSProperties = {
    
    borderRadius: "30px",
    background: "#DEE8FA",
    height: "50px",
    width: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  
};

export const nextinsightpreviousnextbutton: CSSProperties = {
    paddingLeft: "10px", color: "#034EA2", fontSize: "14px", fontWeight: "700", cursor: "pointer" 
};
export const nextinsightanswerwordingstyle: CSSProperties = {
    fontSize: "15px",
    paddingTop: "2px",
    color: "#66676B",
};


export const nextinsightsecondextracardstyle: CSSProperties = {
    
        background: '#1E4DA1',
        borderRadius: '6px', 
        marginLeft: '10px', 
        height: '80px', 
        display: 'flex', 
        alignContent: 'center', 
        justifyContent: 'center', 
        alignItems: 'center',
        cursor: 'pointer'
      
};

export const nextinsightsecondcardindividualstyle: CSSProperties={
     
        background: '#EDF4FF', 
        borderRadius: '6px', 
        marginLeft: '10px', 
        height: '80px', 
        display: 'flex', 
        alignContent: 'center', 
        justifyContent: 'center', 
        alignItems: 'center',
        cursor: 'pointer'
      
  }
  export const nextinsightsecondcardindividualstyledisable: CSSProperties={
     
    background: '#EDF4FF', 
    borderRadius: '6px', 
    marginLeft: '10px', 
    height: '80px', 
    display: 'flex', 
    alignContent: 'center', 
    justifyContent: 'center', 
    alignItems: 'center',
    cursor: 'not-allowed'
  
}


