import {
  FileTranslationAPIResponseType,
  GlossaryTypes,
  LanguageListTypes,
  TextTranslationTypes,
  UploadDocsTypes
} from "../../Pages/Translation Module/Core/modals";
import {
  TM_SET_ACTIVE_SCREEN,
  TM_SET_FILE_TRANSLATION_API_RESPONSE,
  TM_SET_FILE_TRANSLATION_RESET_DATA,
  TM_SET_FT_SELECTED_FILE,
  TM_SET_FT_SELECTED_LANGUAGE,
  TM_SET_FT_TRANSLATE_FILE_FLAG,
  TM_SET_GLOSSARY_LIST,
  TM_SET_LANGUAGE_LIST,
  TM_SET_MAIN_TABS,
  TM_SET_OPEN_ADD_GLOSSARY_MODAL,
  TM_SET_TEXT_TRANSLATION_DATA,
  TM_SET_UPLOAD_DOCS_DATA
} from "../constants/_translationModuleConstants";

export const setMainTabsTMAction = (data: number) => {
  return { type: TM_SET_MAIN_TABS, payload: data };
};

export const setActiveScreenTMAction = (data: number) => {
  return { type: TM_SET_ACTIVE_SCREEN, payload: data };
};

export const setOpenAddGlossaryModalTMAction = (data: boolean) => {
  return { type: TM_SET_OPEN_ADD_GLOSSARY_MODAL, payload: data };
};

export const setLanguageListTMAction = (data: LanguageListTypes[]) => {
  return { type: TM_SET_LANGUAGE_LIST, payload: data };
};

export const setUploadDocsDataTMAction = (data: UploadDocsTypes) => {
  return { type: TM_SET_UPLOAD_DOCS_DATA, payload: data };
};

export const setTextTranslationDataTMAction = (data: TextTranslationTypes) => {
  return { type: TM_SET_TEXT_TRANSLATION_DATA, payload: data };
};

export const setFileTranslationAPIResponseTMAction = (data: FileTranslationAPIResponseType) => {
  return { type: TM_SET_FILE_TRANSLATION_API_RESPONSE, payload: data };
};

export const setFTSelectedFileTMAction = (data: number) => {
  return { type: TM_SET_FT_SELECTED_FILE, payload: data };
};

export const setFTSelectedLanguageTMAction = (data: string) => {
  return { type: TM_SET_FT_SELECTED_LANGUAGE, payload: data };
};

export const setFTTranslateFileFlagTMAction = (data: boolean) => {
  return { type: TM_SET_FT_TRANSLATE_FILE_FLAG, payload: data };
};

export const setFileTranslationResetDataTMAction = () => {
  return { type: TM_SET_FILE_TRANSLATION_RESET_DATA };
};

export const setGlossaryListTMAction = (data: GlossaryTypes[]) => {
  return { type: TM_SET_GLOSSARY_LIST, payload: data };
};
