import { Slider } from "antd";
import { useEffect, useState } from "react";

const LazySlider = ({
  value,
  onChange,
  onStep,
  linkedElement,
  linkedElementKey,
  ...props
}: {
  value: any;
  onChange: Function;
  onStep?: Function;
  linkedElement?: any;
  linkedElementKey?: any;
  [x: string]: unknown;
}) => {
  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <Slider
      value={sliderValue}
      onChange={(v) => {
        if (linkedElement) {
          const keys = Array.isArray(linkedElementKey) ? linkedElementKey : [linkedElementKey];
          (linkedElement._objects || [linkedElement]).forEach((o: any) => keys.forEach((k) => o.set(k, v)));
          linkedElement.addWithUpdate?.();
          linkedElement.canvas.renderAll();
        }
        setSliderValue(v);
        onStep?.(v);
      }}
      onAfterChange={(v) => onChange(v)}
      {...props}
    />
  );
};

export default LazySlider;
