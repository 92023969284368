import {
  SET_ACTIVE_MODULE,
  SET_CURRENT_ACTIVE_STEP,
  SET_CU_SELECTED_TAB,
  SET_NAVIGATED_FLAG,
  SET_PROMPT_ID,
  SET_REFINED_RESPONSE_DATA,
  SET_RESPONSE_LOADER_FLAG,
  SET_SELECTED_TAGS,
  SET_TAGS_DATA
} from "../constants/_contentAIConstants";

export const setActiveModuleActions = (data: string) => {
  return { type: SET_ACTIVE_MODULE, payload: data };
};

export const setCurrentActiveStepActions = (data: string) => {
  return { type: SET_CURRENT_ACTIVE_STEP, payload: data };
};

export const setTagsDataAction = (data: any) => {
  return { type: SET_TAGS_DATA, payload: data };
};

export const setPromptIDAction = (data: number) => {
  return { type: SET_PROMPT_ID, payload: data };
};

export const setResponseDataAction = (data: { prompt: string; content: string; rules: string[] }) => {
  return { type: SET_REFINED_RESPONSE_DATA, payload: data };
};

export const setSelectedTagsAction = (data: { [key: string]: number[] }) => {
  return { type: SET_SELECTED_TAGS, payload: data };
};

export const setNavigatedFlagAction = (data: boolean) => {
  return { type: SET_NAVIGATED_FLAG, payload: data };
};

export const setResponseLoaderFlagAction = (data: boolean) => {
  return { type: SET_RESPONSE_LOADER_FLAG, payload: data };
};

export const setCUSelectedTabAction = (data: string) => {
  return { type: SET_CU_SELECTED_TAB, payload: data };
};
