import { Drawer } from "antd";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { componentCache } from "./_ComponentCache";
import { onDrawerBackAction, setActionDrawerHeaderOptionsAction, setActionDrawerOpenAction } from "./State";
import { LayoutSyncTypes } from "../../Utils/_gTypes";

type IProps = {};

export const ActionDrawer: FC<IProps> = () => {
  const dispatch = useDispatch();

  const { drawerTitle, drawerDescription, drawerWidth, drawerPermanent, drawerOpen, componentId, afterClose, backButton, backAction, headerOptions } =
    useSelector((state: any) => state?.actionDrawerReducer);

  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  const ComponentToRender = componentId ? componentCache[componentId] : null;

  //console.log(ComponentToRender, "ComponentToRender");

  useEffect(() => {
    if (!drawerOpen) {
      dispatch(setActionDrawerOpenAction(false));
    }
  }, []);

  const closeDrawer = () => {
    dispatch(setActionDrawerOpenAction(false));

    dispatch(onDrawerBackAction(() => {}));

    dispatch(setActionDrawerHeaderOptionsAction([]));

    if (typeof afterClose === "function") {
      afterClose();
    }
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      size={"large"}
      width={layoutSync.SEMANTIC_SEARCH ? "1000px" : drawerWidth}
      onClose={closeDrawer}
      open={drawerOpen}
      style={
        layoutSync.SEMANTIC_SEARCH
          ? {
              background: "#F7FCFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 5px 14px #00000017",
              borderRadius: "10px 0px 0px 10px",
              opacity: 1
            }
          : {}
      }
      headerStyle={layoutSync.SEMANTIC_SEARCH ? { display: "none" } : {}}
      // bodyStyle={{padding:"20px", paddingRight:"0px"}}
    >
      {ComponentToRender && <ComponentToRender />}
    </Drawer>
  );
};
