const fallbackFonts = [
  'Noto Sans Devanagari',
  'Noto Sans Bengali',
  'Noto Sans Arabic',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans TC',
  'Noto Sans SC',
  'Noto Serif Devanagari',
  'Noto Serif Bengali',
].join(', ')

export const getFontFamilyWithFallback = (font) => `${font}, ${fallbackFonts}`
