import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { SelectTypes, SelectedTagsType } from "./types";

export const CONTENT_AI_MODULES = {
  CONTENT_CREATOR: "CONTENT_CREATOR",
  LLM_EXPERIENCE_MARKER: "LLM_EXPERIENCE_MARKER",
  LLM_COMPLIANCE_MARKER: "LLM_COMPLIANCE_MARKER",
  LLM_MLR_GUIDELINES: "LLM_MLR_GUIDELINES",
  CONTENT_UNIVERSE: "CONTENT_UNIVERSE"
};

export const CONTENT_AI_MODULE_NAMES = {
  [CONTENT_AI_MODULES.CONTENT_CREATOR]: I18N_MODULE_KEYS["content.contentai.headings.contentcreator"], //"Home"
  [CONTENT_AI_MODULES.LLM_EXPERIENCE_MARKER]: I18N_MODULE_KEYS["content.contentai.headings.llmexperience"],
  [CONTENT_AI_MODULES.LLM_COMPLIANCE_MARKER]: I18N_MODULE_KEYS["content.contentai.headings.llmcompliance"],
  [CONTENT_AI_MODULES.LLM_MLR_GUIDELINES]: I18N_MODULE_KEYS["content.contentai.headings.llmmlrguidline"],
  [CONTENT_AI_MODULES.CONTENT_UNIVERSE]: I18N_MODULE_KEYS["content.contentai.headings.contentuniverse"]
};

// export const CONTENT_AI_MODULE_NAMES = {
//   [CONTENT_AI_MODULES.CONTENT_CREATOR]: "Content Creator", //"Home"
//   [CONTENT_AI_MODULES.LLM_EXPERIENCE_MARKER]: "LLM Experience Marker",
//   [CONTENT_AI_MODULES.LLM_COMPLIANCE_MARKER]: "LLM Compliance Marker",
//   [CONTENT_AI_MODULES.LLM_MLR_GUIDELINES]: "LLM MLR Guidelines",
//   [CONTENT_AI_MODULES.CONTENT_UNIVERSE]: "Content Universe"
// };

export const CONTENT_CREATOR_STEPS = {
  GENERATE_RESPONSE: "GENERATE_RESPONSE",
  INITIAL_RESPONSE: "INITIAL_RESPONSE",
  REFINED_RESPONSE: "REFINED_RESPONSE"
};

export const CONTENT_AI_TAGS_CATEGORIES = Object.freeze({
  REGION: "region",
  CHANNEL: "channel",
  BRAND: "brand"
});

export const LLM_MODULES = {
  LLM_EXPERIENCE_MARKER: "llm_experience",
  LLM_COMPLIANCE_MARKER: "llm_compliance",
  LLM_MLR_GUIDELINES: "llm_mlr_guidelines"
};

export const SELECTED_TAGS_INITIAL_STATE: SelectedTagsType = {
  [LLM_MODULES.LLM_COMPLIANCE_MARKER]: [],
  [LLM_MODULES.LLM_EXPERIENCE_MARKER]: [],
  [LLM_MODULES.LLM_MLR_GUIDELINES]: [],
  [CONTENT_AI_MODULES.CONTENT_UNIVERSE.toLowerCase()]: []
};

export const LLM_MODULE_NAMES = {
  [LLM_MODULES.LLM_EXPERIENCE_MARKER]: "LLM Experience Marker",
  [LLM_MODULES.LLM_COMPLIANCE_MARKER]: "LLM Compliance Marker",
  [LLM_MODULES.LLM_MLR_GUIDELINES]: "LLM MLR Guidelines"
};

export const RESPONSE_LOADER_TEXT = {
  [CONTENT_CREATOR_STEPS.GENERATE_RESPONSE]: `${I18N_MODULE_KEYS["content.text.generating.initialresponse"]}`,
  [CONTENT_CREATOR_STEPS.INITIAL_RESPONSE]: `${I18N_MODULE_KEYS["content.text.generating.refinedresponse"]}`,
  [CONTENT_CREATOR_STEPS.REFINED_RESPONSE]: `${I18N_MODULE_KEYS["content.text.regenerating.refinedresponse"]}`
};

// export const RESPONSE_LOADER_TEXT = {
//   [CONTENT_CREATOR_STEPS.GENERATE_RESPONSE]: "Generating Initial Response",
//   [CONTENT_CREATOR_STEPS.INITIAL_RESPONSE]: "Generating Refined Response",
//   [CONTENT_CREATOR_STEPS.REFINED_RESPONSE]: "Regenerating Refined Response"
// };

export const CONTENT_AI_CU_TABS = {
  CONTENT_UNIVERSE: "content_universe",
  CU_UPLOAD: "cu_upload"
};
