import { Navigate, useLocation } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { DContent, DLayout } from "../../Layouts";
import { useSelector } from "react-redux";
import { siteConfig } from "../../Services";

const AccessToPrivateRoutes = ({ children }: any) => {
  const location = useLocation();

  let strLStoken: string | null = localStorage.getItem(siteConfig.ACCESS_TOKEN);
  // const { userDetail } = useSelector((state: any) => state?.authReducer);

  if (!strLStoken) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={ENUM_ROUTE_URLS.LOGIN} state={{ from: location }} />;
  }

  // authorized so return child components
  return (
    <DLayout>
      <DContent>{children}</DContent>
    </DLayout>
  );
};

export default AccessToPrivateRoutes;
