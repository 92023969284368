import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Authorizer } from "../../Services/_authorizer";

import Lottie from "lottie-react";

import { Spin } from "antd";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import { Row, Col, Form, Image, Typography, Input, Button, Space, Checkbox, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import _localStorageService from "../../Services/_localStorageService";

import { getDefaultLayout } from "../../Utils";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { setLayoutSyncAction } from "../../Store/actions/_commonActions";

import { useTranslation } from "react-i18next";

import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import "../../Assets/Css/Authentication/login.css";

const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [form] = Form.useForm();
  const authorizer = new Authorizer();

  const { loginDetails, userDetails } = useSelector((state: any) => state?.authReducer);
  const { layoutSync } = useSelector((state: any) => state?.commonReducer);
  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  useEffect(() => {
    if (_localStorageService.getAccessToken()) {
      navigate(ENUM_ROUTE_URLS.DASHBOARD);
    }
  }, [loginDetails]);

  const onSignupHandler = () => {
    message.success("Successful sign up", 3, () => {
      window.location.reload();
    });
  };

  const onLoginHandler = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields
      const { mail: email, password } = values;

      await authorizer.doLogin({ email, password }, dispatch);

      const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);

      dispatch(
        setLayoutSyncAction({
          ...objNewLayoutSync,
          CONTENT_TRANSCREATION: true
        })
      );
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };

  const onForgotPasswordHandler = () => {
    navigate(ENUM_ROUTE_URLS.FORGOT);
  };

  useEffect(() => {
    if (location.state && location.state.from === "/dashboard") {
      message.success("Successfully logged out!");
    }
  }, [location]);

  return (
    <Form labelAlign="left" labelCol={{ span: 24 }} form={form} className="authentication container1">
      <Row gutter={16}>
        <Col span={12} className="section1">
          <Row className="animcontainer">
            <Col className="animation">
              <Lottie id="email" animationData={PROJECT_ICONS.EMAIL_ANIMATION} loop={true} />
            </Col>
            <Col className="animation-text-wrapper">
              <p>{t(I18N_MODULE_KEYS["content.login.text.customizeyouremailusingai"])}</p>
              <p>{t(I18N_MODULE_KEYS["content.login.text.convertyouremailintoHTMLCraftImagesand"])}</p>
              <p>{t(I18N_MODULE_KEYS["content.login.text.weavevideostoleavealasting"])}</p>
              {/* <p>Customize your Email using AI</p>
              <p>Convert Your Email into HTML, Craft Images, and</p>
              <p>Weave Videos to Leave a Lasting</p> */}
            </Col>
          </Row>
        </Col>
        <Col
          span={12}
          className="login-container"
          style={{
            backgroundImage: "linear-gradient(to right top, white , #e8fbff)",
            backdropFilter: "blur(10px)"
          }}
        >
          <Col className="form-conatiner">
            <Col className="second-content">
              <Image src={PROJECT_ICONS.NEXTGEN_LOGO} alt="Loading" style={{ height: "35px" }} />
              <Title level={3}>{t(I18N_MODULE_KEYS["content.forgot.text.helloagain"])}</Title>
            </Col>
            <Form.Item
              name="mail"
              label={`${t(I18N_MODULE_KEYS["content.login.text.email"])}`}
              rules={[
                { required: true, message: `${t(I18N_MODULE_KEYS["content.login.text.emailisrequired"])}` },
                {
                  type: "email",
                  message: `${t(I18N_MODULE_KEYS["content.login.text.pleaseenteravalidemailaddress"])}`
                }
              ]}
            >
              <Input size="large" placeholder="Enter your email" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              label={`${t(I18N_MODULE_KEYS["content.login.text.password"])}`}
              name="password"
              rules={[
                {
                  required: true,
                  message: `${t(I18N_MODULE_KEYS["content.login.text.passwordisrequired"])}`
                }
              ]}
            >
              <Input.Password size="large" placeholder="Enter your password" prefix={<LockFilled />} />
            </Form.Item>
            <Row justify={"space-between"}>
              <Form.Item>
                {/* <Checkbox>Remember me</Checkbox> */}
                <Checkbox>{t(I18N_MODULE_KEYS["content.login.text.rememberme"])}</Checkbox>
              </Form.Item>
              <Button onClick={onForgotPasswordHandler} type="link">
                {/* Forgot Password? */}
                {t(I18N_MODULE_KEYS["content.login.text.forgotpassword"])}
              </Button>
            </Row>
            <Button
              onClick={onLoginHandler}
              type="primary"
              block
              size="large"
              test-id="submit-button"
              disabled={isLoading} // Disable the button while loading
            >
              {isLoading ? <Spin size="small" /> : `${t(I18N_MODULE_KEYS["module.login"])}`}
            </Button>

            <Space className="login-space" direction="horizontal">
              <Text>
                {/* Don't have an account?{" "} */}
                {t(I18N_MODULE_KEYS["content.login.text.donthaveanaccount"])}
                <a href={ENUM_ROUTE_URLS.SIGNUP} onClick={onSignupHandler}>
                  {/* Create an account */}
                  {t(I18N_MODULE_KEYS["content.login.text.createanaccount"])}
                </a>
              </Text>
            </Space>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export default Login;
