import { Avatar, Button, Card, Checkbox, Col, Row, Skeleton, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { CT_DASHBOARD_STATUS } from "../../../../../Utils";
import { GenerateImageContentTypes } from "../../../Core/modals";
import { EditOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { ChatBot } from "../../Chat/ChatBot";

const { Text, Paragraph } = Typography;

type IProps = {
  status: string;
  stories: GenerateImageContentTypes[];
  handlePrompts: (e: any, item: any, index: number) => void;
  handleSceneReorder: Function;
  setCurrentStoryBoardForEdit: (item: any) => void;
  setShowModal: (t: boolean) => void;
  showModal: boolean;
  isAllSelected: boolean;
  handleSelectAll: (isAllSelected: boolean, stories: any[]) => void;
  updateStoryboard: Function;
};

export const StoryBoardListScreen: FC<IProps> = ({
  status,
  stories,
  handlePrompts,
  handleSceneReorder,
  setCurrentStoryBoardForEdit,
  setShowModal,
  showModal,
  handleSelectAll,
  isAllSelected,
  updateStoryboard
}) => {
  const { t } = useTranslation();

  const contentTranscreationRootEl = document.getElementById("content-transcreation-root");

  useEffect(() => {
    console.log("storyboard list screen", { stories });
  }, [stories]);

  return (
    <Row gutter={[8, 16]}>
      {/* <Col xs={{ span: 24 }} lg={{ span: 16 }}> */}
      {status === CT_DASHBOARD_STATUS.SUCCESS ? (
        <Col xs={{ span: 24 }}>
          <div className="d-flex " style={{ justifyContent: "space-between" }}>
            <Text className="font-nunito-sans optimize-font" style={{ marginBottom: "4px", fontWeight: "800" }}>
              {/* Create Story Board ( */}
              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.title"])} (
              <Button
                type="link"
                style={{ padding: "4px 3px" }}
                onClick={() => {
                  handleSelectAll(!isAllSelected, stories);
                }}
              >
                {/* {!isAllSelected ? "Select All" : "De-Select All"} */}
                {!isAllSelected
                  ? `${t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.selectall"])}`
                  : `${t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.deselectall"])}`}
              </Button>
              )
            </Text>
          </div>
          <DragDropContext
            onDragEnd={(result) => {
              if (!result.destination) {
                // The item was dropped outside of any droppable area
                return;
              }

              const { source, destination, draggableId } = result;

              //console.log("Drag Ended!", "Index", { source, destination });

              const reorderedStories = [...stories.map((story) => ({ ...story }))];
              const [draggedStory] = reorderedStories.splice(source.index, 1);
              reorderedStories.splice(destination.index, 0, draggedStory);

              handleSceneReorder(reorderedStories);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div style={{ overflow: "hidden", borderRadius: "12px" }}>
                  <Card
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="overflowY"
                    style={{
                      height: "50vh",
                      border: "0.4000000059604645px solid #000000",
                      borderRadius: "12px",
                      backgroundColor: snapshot.isDraggingOver ? "lightcyan" : "unset"
                    }}
                    bodyStyle={{ padding: "0" }}
                  >
                    {stories && stories?.length ? (
                      <>
                        {stories?.map((item: GenerateImageContentTypes, index: number) => {
                          return (
                            <Draggable key={item.id} draggableId={item.id?.toString()} index={index}>
                              {(provided: any, snapshot: any) => {
                                return (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={index}
                                    style={{ ...provided.draggableProps.style, backgroundColor: snapshot.isDragging ? "lightblue" : "white" }}
                                  >
                                    <div
                                      className="d-flex"
                                      style={{
                                        alignItems: "flex-start",
                                        justifyContent: "space-between"
                                      }}
                                      key={`generate_images_content_${index}`}
                                    >
                                      <div className="d-flex" style={{ alignItems: "flex-start" }}>
                                        <Checkbox
                                          style={{ paddingTop: "2px" }}
                                          onChange={(e) => handlePrompts(e, item, index)}
                                          checked={item?.checked || false}
                                        />

                                        <div style={{ marginLeft: "10px" }}>
                                          <Paragraph
                                            className="optimize-font"
                                            style={{
                                              fontSize: "15px",
                                              marginBottom: "0px",
                                              font: "normal normal bold 16px/24px Nunito Sans"
                                            }}
                                          >
                                            {/* {`Scene ${index + 1}`} */}
                                            {`${t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.scene"])} ${index + 1}`}
                                          </Paragraph>
                                          <Paragraph
                                            style={{
                                              fontSize: "15px",
                                              marginBottom: "0px"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                marginBottom: "0px",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {/* Title: */}
                                              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.title"])}
                                            </span>{" "}
                                            {item?.title || ""}
                                          </Paragraph>

                                          <Paragraph
                                            style={{
                                              fontSize: "15px",
                                              marginBottom: "0px"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                marginBottom: "0px",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {/* Scene: */}
                                              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.scene"])}
                                            </span>{" "}
                                            {item?.scene || ""}
                                          </Paragraph>
                                          <Paragraph style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                marginBottom: "0px",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {/* Narration: */}
                                              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.narration"])}
                                            </span>{" "}
                                            {item?.narration || ""}
                                          </Paragraph>
                                        </div>
                                      </div>
                                      <div>
                                        <Avatar
                                          style={{
                                            cursor: "pointer",
                                            background: "#034EA2"
                                          }}
                                          icon={<EditOutlined style={{ fontSize: "15px" }} />}
                                          onClick={() => {
                                            setCurrentStoryBoardForEdit(item);

                                            setTimeout(() => {
                                              setShowModal(!showModal);
                                            }, 100);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Card>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </>
                    ) : (
                      <Paragraph
                        type="secondary"
                        className="optimize-font"
                        style={{
                          fontSize: "12px",
                          padding: "24px"
                        }}
                      >
                        {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.nostoryfound"])}
                      </Paragraph>
                    )}
                    {provided.placeholder}
                  </Card>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {contentTranscreationRootEl && createPortal(<ChatBot updateStoryboard={updateStoryboard}/>, contentTranscreationRootEl)}
        </Col>
      ) : (
        <Skeleton active />
      )}
    </Row>
  );
};
