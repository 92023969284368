import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Flex, Image, Row } from "antd";

import { globalConstant } from "../../Utils";
import { apiResponse } from "../../Utils/_gTypes";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { initialTextTranslationData } from "./Core/constant";
import { IOTextTranslation } from "./Components/Text Translation";
import { IODataType, LanguageListTypes, TextTranslationTypes } from "./Core/modals";
import _translationModuleRequests from "../../Store/requests/_translationModuleRequests";
import { setTextTranslationDataTMAction } from "../../Store/actions/_translationModuleActions";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const TextTranslationMain: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { languageList, textTranslationData }: { languageList: LanguageListTypes[]; textTranslationData: TextTranslationTypes } = useSelector(
    (state: any) => state?.translationModuleReducer
  );

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log(textTranslationData);
  }, [textTranslationData]);

  useEffect(() => {
    if (languageList?.length) {
      dispatch(
        setTextTranslationDataTMAction({
          ...textTranslationData,
          IOData: { ...textTranslationData.IOData, baseLanguage: languageList?.[0]?.name, targetLanguage: languageList?.[1]?.name }
        })
      );
    }
  }, [languageList]);

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._TextTranslationMain.${value as string}`])}`;
  };

  const handleUpdateIOData = (value: string, type: string) => {
    dispatch(setTextTranslationDataTMAction({ ...textTranslationData, IOData: { ...textTranslationData.IOData, [type]: value } }));
  };

  const handleExhangeIOData = () => {
    const objIOdata: IODataType = { ...textTranslationData.IOData };

    objIOdata["input"] = objIOdata["output"];
    objIOdata["baseLanguage"] = objIOdata["targetLanguage"];
    objIOdata["targetLanguage"] = textTranslationData.IOData["baseLanguage"];
    objIOdata["output"] = textTranslationData.IOData["input"];

    dispatch(setTextTranslationDataTMAction({ ...textTranslationData, IOData: { ...textTranslationData.IOData, ...objIOdata } }));
  };

  const handleTranslate = async () => {
    const objIOData: IODataType = { ...textTranslationData.IOData };

    setLoading(true);
    const res: apiResponse = await _translationModuleRequests.translationText({
      content: objIOData?.input,
      input_language: languageList?.find((item: LanguageListTypes) => item.name === objIOData?.baseLanguage)?.id?.toString?.() as string,
      output_language: languageList?.find((item: LanguageListTypes) => item.name === objIOData?.targetLanguage)?.id?.toString?.() as string
      // glossary: textTranslationData.glossary
      // glossary: []
    });
    setLoading(false);

    if (res?.message?.toUpperCase?.() === globalConstant.SUCCESS) {
      dispatch(setTextTranslationDataTMAction({ ...textTranslationData, IOData: { ...textTranslationData.IOData, output: res?.data } }));
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }} md={{ span: 11 }}>
        <IOTextTranslation
          key={"input-IOTextTranslation"}
          textHeading={getTranslatedText("Input")}
          selectData={{
            id: `baseLanguage`,
            value: textTranslationData.IOData.baseLanguage || `${getTranslatedText("Choose Base Language")} (${languageList?.length})`,
            placeholder: `${getTranslatedText("Choose Base Language")} (${languageList?.length})`,
            options: languageList?.map((item: LanguageListTypes) => {
              return { value: item.name };
            }),
            onChange: (name: string) => handleUpdateIOData(name, "baseLanguage")
          }}
          isGlossaryEnable={false}
          textareaValue={textTranslationData.IOData.input}
          updateTextareaValue={handleUpdateIOData}
          isEditable={true}
        />
      </Col>
      <Col span={2}>
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Image src={PROJECT_ICONS.TM_EXCHANGE_ICON} preview={false} style={{ cursor: "pointer" }} onClick={handleExhangeIOData} />
        </Flex>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 11 }}>
        <IOTextTranslation
          key={"output-IOTextTranslation"}
          selectData={{
            id: `targetLanguage`,
            value: textTranslationData.IOData.targetLanguage || `${getTranslatedText("Choose Target Language")} (${languageList?.length})`,
            placeholder: `${getTranslatedText("Choose Target Language")} (${languageList?.length})`,
            options: languageList?.map((item: LanguageListTypes) => {
              return { value: item.name };
            }),
            onChange: (name: string) => handleUpdateIOData(name, "targetLanguage")
          }}
          textHeading={getTranslatedText("Output")}
          isGlossaryEnable={true}
          textareaValue={textTranslationData.IOData.output}
          updateTextareaValue={() => null}
          loading={loading}
          isEditable={false}
        />
      </Col>
      <Col span={24}>
        <Flex justify="flex-end" align="center" gap={"middle"}>
          <Button
            style={{ fontSize: "12px", color: "rgba(3, 78, 162, 1)" }}
            type="link"
            onClick={() =>
              dispatch(setTextTranslationDataTMAction({ ...textTranslationData, IOData: { ...textTranslationData.IOData, input: "", output: "" } }))
            }
          >
            {getTranslatedText("RESET")}
          </Button>
          <Button
            type="primary"
            style={{
              fontSize: "12px",
              background:
                loading || !textTranslationData.IOData.input || !textTranslationData.IOData.baseLanguage || !textTranslationData.IOData.targetLanguage
                  ? "rgba(230, 231, 232, 1)"
                  : "rgba(3, 78, 162, 1)",
              color:
                loading || !textTranslationData.IOData.input || !textTranslationData.IOData.baseLanguage || !textTranslationData.IOData.targetLanguage
                  ? "rgba(102, 103, 107, 1)"
                  : "white",
              borderRadius: "0px"
            }}
            onClick={handleTranslate}
            loading={loading}
            disabled={
              loading || !textTranslationData.IOData.input || !textTranslationData.IOData.baseLanguage || !textTranslationData.IOData.targetLanguage
            }
          >
            {getTranslatedText("TRANSLATE")}
          </Button>
        </Flex>
      </Col>
    </Row>
  );
};
