import { FC, Fragment, useRef, useState } from "react";
import { CUContentType, ContentAITag, LLMLeftPaneProps } from "../../Core/types";
import { addRulesToPromptRequest, getPromptResultRequest } from "../../../../Store/requests/_contentAIRequests";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { globalConstant } from "../../../../Utils";
import { Skeleton } from "antd";
import {
  setActiveModuleActions,
  setCurrentActiveStepActions,
  setResponseDataAction,
  setResponseLoaderFlagAction,
  setSelectedTagsAction
} from "../../../../Store/actions/_contentAIActions";
import { CONTENT_AI_MODULES, CONTENT_CREATOR_STEPS, LLM_MODULES, LLM_MODULE_NAMES } from "../../Core/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import LabelCheckBox from "../Common/LabelCheckBox";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const LLMLeftPane: FC<LLMLeftPaneProps> = ({
  props: {
    allTags,
    setAllTags,
    selectedUITags,
    setSelectedUITags,
    getCUSelectedTagsIds,
    getAllTags,
    getSelectedTagsIds,
    loadingTags,
    contentAIModule,
    setCUD,
    updateSelectedUITags,
    setCUContentTagIDs,
    setCUL
  }
}) => {
  const dispatch = useDispatch();
  const timerRef: any = useRef();

  const { t } = useTranslation();

  const { activeModule, currentActiveStep, promptID } = useSelector((state: any) => state?.contentAIReducer);

  const [loadingApply, setLoadingApply] = useState(false);
  const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({});

  const handleTagSelection = (checked: boolean, tag: ContentAITag) => {
    tag.selected = checked;
    setAllTags({ ...allTags });
    activeModule !== CONTENT_AI_MODULES.CONTENT_UNIVERSE &&
      setSelectedUITags((prevsSelectedUITags: ContentAITag[]) => {
        if (checked) {
          prevsSelectedUITags.push(tag);
        } else {
          let indexOfDeselectedTag = selectedUITags.findIndex((selectedTag) => selectedTag.id === tag.id);
          prevsSelectedUITags.splice(indexOfDeselectedTag, 1);
        }
        return prevsSelectedUITags;
      });
  };

  const resetTagsSelection = () => {
    getAllTags();
    setSelectedUITags([]);
  };

  const addRulesToPrompt = async () => {
    setLoadingApply(true);
    activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE && setCUL(true);

    let selectedTagsIds = activeModule !== CONTENT_AI_MODULES.CONTENT_UNIVERSE ? getSelectedTagsIds() : getCUSelectedTagsIds();

    //console.log("Final Selected Tag IDs", selectedTagsIds);
    const res = await addRulesToPromptRequest({ tags: selectedTagsIds, prompt: promptID, rule_type: contentAIModule }, dispatch);

    setLoadingApply(false);
    activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE && setCUL(false);

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase() || res?.status === globalConstant.SUCCESS) {
      if (activeModule !== CONTENT_AI_MODULES.CONTENT_UNIVERSE) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied"])));
        console.log("Prompt Rules applied!");

        dispatch(setActiveModuleActions(CONTENT_AI_MODULES.CONTENT_CREATOR));
        dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.REFINED_RESPONSE));
        getEnrichedResult();
      } else {
        updateSelectedUITags(allTags);
        let { content, content_ids }: { content: CUContentType[]; content_ids: number[] | null } = res;
        let contentTagIds: Set<number> = new Set<number>();

        content.forEach((item) => {
          if (content_ids?.includes(item.id)) item.selected = true;
          item.tags.forEach((tag) => contentTagIds.add(tag));
        });

        //console.log({ contentTagIds });
        setCUContentTagIDs(contentTagIds);

        content && setCUD(content);
        //content && setCUD(data.content);
        //console.log({ allTags, content });
      }
    }
  };

  const getEnrichedResult = async (tagsSet?: boolean) => {
    dispatch(setResponseLoaderFlagAction(true));
    clearTimeout(timerRef.current);

    const res = await getPromptResultRequest(promptID, true);

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      return;
    }

    //Set the selectedTags if not already set which is being tracked using tagsSet
    if (!tagsSet && res["selected_tags"]) {
      dispatch(setSelectedTagsAction(res["selected_tags"]));
    }

    if (res?.status === globalConstant.SUCCESS) {
      dispatch(setResponseLoaderFlagAction(false));
      dispatch(
        setResponseDataAction({
          prompt: res.prompt,
          content: res.html_result,
          rules: res.rules
        })
      );
    } else {
      timerRef.current = setTimeout(() => {
        getEnrichedResult(true);
      }, 5000);
    }
  };

  const getSelectedTagsCount = (categroy: string) => {
    let count = 0;
    allTags[categroy].forEach((tag) => {
      tag.selected && count++;
    });
    return (count.toString().length === 1 ? "0" : "") + count;
  };

  return (
    <div className={`col-3 p-0 ${activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE ? "content-universe" : ""}`}>
      <div className="leftPaneBlock">
        <div className="row" style={{ flexWrap: "nowrap", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <div>
            <div style={{ padding: "0 15px" }}>
              {/* <div className="title">Filter by</div> */}
              <div className="title">{t(I18N_MODULE_KEYS["content.text.filterby"])}</div>
              <br />
            </div>
            {loadingTags ? (
              <div style={{ padding: "0 15px" }}>
                <Skeleton active />
              </div>
            ) : (
              <>
                {/* Header rendered height: 62px, py-4: 1.5rem + 1.5rem.  130px is the combined height of 'Filter By' and Buttons*/}
                {/* 2x20px margin */}
                {/* CU : 35px Tabs List height */}
                <div
                  className="filters"
                  style={{
                    overflowY: "scroll",
                    height: `calc(100vh - 62px - 3rem - 135px - 40px - ${activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE ? "35px" : "0px"})`,
                    padding: "0 15px"
                  }}
                >
                  {Object.keys(allTags).map((category, key, arr) => {
                    //console.log("category:", category);
                    return (
                      <Fragment key={key}>
                        <div
                          className="list"
                          style={{
                            color: "#034EA2",
                            fontSize: "14px",
                            fontWeight: 600,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                          onClick={() => {
                            setCollapse((prevState) => ({ ...prevState, [category]: !prevState[category] }));
                          }}
                        >
                          <img
                            src={collapse[category] ? PROJECT_ICONS.C_AI_DOWN_ARROW : PROJECT_ICONS.C_AI_UP_ARROW}
                            style={{ width: "16px", height: "20px" /* marginRight: "5px", paddingBottom: "1px" */ }}
                          />
                          <span style={{ textTransform: "capitalize" }}>
                            {category.includes("mlr") ? "MLR Approved" : category.split("_").join(" ")}
                          </span>
                          ({getSelectedTagsCount(category)})
                        </div>
                        {!collapse[category] && (
                          <div>
                            <div className="brandList">
                              {allTags[category].map((tag, key) => (
                                <LabelCheckBox
                                  key={key}
                                  label={tag.name}
                                  labelStyle={{ textTransform: "capitalize", margin: "10px 0" }}
                                  className="brand"
                                  name="brand"
                                  value={tag.name}
                                  //checked={tag.selected ? true : false}
                                  checked={tag.selected || tag.is_default ? true : false}
                                  onChange={(event) => {
                                    handleTagSelection(event.target.checked, tag);
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {key !== arr.length - 1 ? <hr /> : null}
                      </Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {!loadingTags ? (
            <div
              className="text-right"
              style={{
                padding: "0 15px",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <span className="closeBtn" onClick={resetTagsSelection}>
                {/* Reset */}
                {t(I18N_MODULE_KEYS["content.button.reset"])}
              </span>

              <span
                className={`updateBtn ${
                  !(currentActiveStep === CONTENT_CREATOR_STEPS.REFINED_RESPONSE || activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE) &&
                  "disabled"
                }`}
                style={{ margin: 0 }}
                onClick={addRulesToPrompt}
              >
                {loadingApply && <LoadingOutlined style={{ marginRight: "5px" }} />}
                {/* Apply */}
                {t(I18N_MODULE_KEYS["content.button.apply"])}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
