import { FC, useState } from "react";
import { CUContentType, CUContentsComponentProps } from "../../Core/types";
import CUContentComponent from "../Content Universe/CUContentComponent";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const CUContentsComponent: FC<CUContentsComponentProps> = ({
  props: { contentUniverseData, setCUD, cuContentTagIDs, cuSelectedUITag, selectedUITags, getAppliedContent, getCUSelectedTagsIds }
}) => {
  const { t } = useTranslation();

  const [editContentId, setEditContentId] = useState(0);

  const onContentSelectionChange = (id: number) => {
    setCUD((prevCUD: CUContentType[]) =>
      prevCUD.map((item) => {
        if (item.id === id) item.selected = !item.selected;
        return item;
      })
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px", ...(editContentId ? { height: "100%" } : {}) }}>
      {selectedUITags.length ? (
        contentUniverseData.length ? (
          contentUniverseData.map((item, key) => {
            //If No item is being edited
            if (!editContentId) {
              return (
                <CUContentComponent
                  key={item.id}
                  content={item}
                  onChange={onContentSelectionChange}
                  setEditContentId={setEditContentId}
                  getAppliedContent={getAppliedContent}
                  getCUSelectedTagsIds={getCUSelectedTagsIds}
                  setCUD={setCUD}
                />
              );
            } else {
              //Return the item that is being edited
              return (
                editContentId === item.id && (
                  <CUContentComponent
                    key={item.id}
                    content={item}
                    onChange={onContentSelectionChange}
                    setEditContentId={setEditContentId}
                    getAppliedContent={getAppliedContent}
                    getCUSelectedTagsIds={getCUSelectedTagsIds}
                    setCUD={setCUD}
                  />
                )
              );
            }
          })
        ) : (
          <div className="rules">
            <div className="row">
              <div className="col-12 heading" style={{ textAlign: "center" }}>
                {/* Content not available for selected tag(s). */}
                {t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags"])}
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="rules">
          <div className="row">
            <div className="col-12 heading" style={{ textAlign: "center" }}>
              {/* Please select a tag. */}
              {t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.pleaseselectatag"])}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
