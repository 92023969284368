import { FC, useState } from "react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentActiveStepActions,
  setPromptIDAction,
  setResponseLoaderFlagAction,
  setSelectedTagsAction
} from "../../../../Store/actions/_contentAIActions";

import { CONTENT_CREATOR_STEPS, SELECTED_TAGS_INITIAL_STATE } from "../../Core/constants";
import { createEnrichPromptRequest, regenerateInitialResponse } from "../../../../Store/requests/_contentAIRequests";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

type IProps = {
  getResult: (prompt_id: number, isEnriched: boolean) => void;
  setRegenerating: Function;
};

type StatusTypes = {
  interested: boolean;
  not_interested: boolean;
};

const initialStatus: StatusTypes = {
  interested: false,
  not_interested: false
};

export const InitialResponseScreen: FC<IProps> = ({ getResult, setRegenerating }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    promptID,
    refinedResponseData: { prompt, content }
  } = useSelector((state: any) => state?.contentAIReducer);

  const [status, setStatus] = useState<StatusTypes>({ ...initialStatus });

  const handleStatus = (key: string, value: boolean | null, otherKey: string) => {
    setStatus((p: StatusTypes) => ({
      ...p,
      [key]: value,
      [otherKey]: false
    }));
  };

  const handleCreateEnrichPromptClick = async (isBack: boolean | undefined = false) => {
    if (isBack) {
      dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.GENERATE_RESPONSE));
      dispatch(setSelectedTagsAction(SELECTED_TAGS_INITIAL_STATE));
      dispatch(setPromptIDAction(0));
      return;
    }
    dispatch(setResponseLoaderFlagAction(true));

    const res = await createEnrichPromptRequest(promptID, "openai");

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      return;
    }

    getResult(res?.id, true);
  };

  const handleRegenerateInitialResposne = async () => {
    dispatch(setResponseLoaderFlagAction(true));
    setRegenerating(true);

    const res = await regenerateInitialResponse({ prompt_id: promptID, regenerate: true });

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      setRegenerating(false);
      return;
    }

    getResult(res?.id, false);
  };

  return (
    <>
      <div className="col-12 textBlock initial-response">
        <div className="prompt-title-block">
          <span className="user-prompt">{prompt ? prompt + " ?" : ""}</span>
          <span style={{ margin: "0 1rem" }}>|</span>
          <span className="button-group">
            <a className="edit" onClick={() => handleCreateEnrichPromptClick(true)}>
              {/* Back */}
              {t(I18N_MODULE_KEYS["content.button.back"])}
            </a>
            <a
              className="edit"
              style={{ whiteSpace: "nowrap", border: "1px solid #004ea2", borderRadius: "3px" }}
              onClick={handleRegenerateInitialResposne}
            >
              {/* Enrich Prompt */}
              {t(I18N_MODULE_KEYS["content.button.regenerate"])}
            </a>
            <a className="button" style={{ whiteSpace: "nowrap" }} onClick={() => handleCreateEnrichPromptClick()}>
              {/* Enrich Prompt */}
              {t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.enrichprompt"])}
            </a>
          </span>
        </div>
        <hr />
        <div className="row">
          <div className="col-6">
            {/* <div className="title">Response</div> */}
            <div className="title">{t(I18N_MODULE_KEYS["content.text.response"])}</div>
          </div>
          <div className="col-6 socialLinks">
            <span>
              <img
                src={status.interested === true ? PROJECT_ICONS.THUMBS_UP_SOLID : PROJECT_ICONS.THUMBS_UP}
                width="20"
                className="mr-2"
                onClick={() => handleStatus("interested", !status.interested, "not_interested")}
              />
              {/* Interested */}
              {t(I18N_MODULE_KEYS["content.text.interested"])}
            </span>
            <span>
              <img
                src={status.not_interested === true ? PROJECT_ICONS.THUMBS_UP_SOLID : PROJECT_ICONS.THUMBS_UP}
                onClick={() => handleStatus("not_interested", !status.not_interested, "interested")}
                style={{ transform: "rotate(180deg)" }}
                width="20"
                className="mr-2"
              />
              {/* Not Interested */}
              {t(I18N_MODULE_KEYS["content.text.notinterested"])}
            </span>
            <span>
              <img src={PROJECT_ICONS.C_AI_SHARE} width="20" className="mr-2" />
              {/* Share */}
              {t(I18N_MODULE_KEYS["content.text.share"])}
            </span>
          </div>
        </div>
        <br />

        {/* <>{content ? content : "No Content Available"}</> */}
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </>
  );
};
