import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { setElaiTranslationDataAction, setSelectedEmailDocAction } from "../../../../Store/actions/_contentTranscreationActions";
import { getElaiResponseDataRequest, saveElaiTranslatedJson } from "../../../../Store/requests/_contentTranscreationRequests";
import { ENUM_ROUTE_URLS, globalConstant } from "../../../../Utils";
import { SlideDataType, VoiceListGenderType, VoiceListType } from "./modals";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
export const cardStyle = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 10px #00000014",
  borderRadius: "10px",
  opacity: 1
};

export const saveELaiData = async (video_id: number, storyboard_id: number) => {
  await saveElaiTranslatedJson({ video_id: video_id, storyboard_id: storyboard_id });
};

let counter: number = 0;
let timer: any = 0;
export const getELaiResponse = async (
  elaiData: any,
  video_id: number,
  storyboardId: number,
  locale: string,
  dispatch: any,
  navigate: any,
  isInitial: boolean
) => {
  clearTimeout(timer);

  const obj = {
    _id: "65b76351c33cb80921d19ba5",
    name: "Paincure Asset (Afrikaans)",
    slides: [
      {
        id: 82126033145,
        speech:
          "Stel Paincure voor<break time='0.5s' /> 'n Medisyne wat aanvaar word vir beperkte gebruik as 'n behandelingsopsie vir chroniese limfositiese leukemie (CLL) in NHS Skotland",
        avatar: {
          _id: 6,
          id: "kira.doctor",
          name: "Doctor",
          type: null,
          status: 2,
          gender: "female",
          tilt: {
            left: 0.05
          },
          listeningAvatar: {
            canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira-doctor.png",
            file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
            alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
          },
          thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
          intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-doctor-1080.mp4",
          introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
        },
        language: "Afrikaans",
        voice: "af-ZA-AdriNeural",
        voiceProvider: "azure",
        voiceType: "text",
        animation: "fade_in",
        canvas: {
          version: "5.3.0",
          objects: [
            {
              type: "avatar",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 0.5,
              top: 187.66667163,
              width: 0,
              height: 0,
              fill: "#4868FF",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.15771604,
              scaleY: 0.15771604,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
              crossOrigin: null,
              filters: [],
              avatarType: "transparent",
              tilt: {
                left: 0.05
              },
              animation: {
                type: null,
                exitType: null
              },
              _exists: true
            },
            {
              type: "image",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 376.16665649,
              top: -4.73745259,
              width: 433,
              height: 650,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.60508083,
              scaleY: 0.55805762,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              id: 412403017237,
              src: "https://images.pexels.com/photos/7659542/pexels-photo-7659542.jpeg?auto=compress&cs=tinysrgb&h=650&w=940&withcors",
              crossOrigin: "anonymous",
              filters: [],
              _exists: true
            },
            {
              type: "image",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 104,
              top: 69,
              width: 295,
              height: 84,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.6412429,
              scaleY: 0.60026384,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              id: 1319100537215,
              animation: {
                type: "fade_in",
                startTime: 1,
                startMarker: false,
                startScene: false
              },
              src: "https://d3u63mhbhkevz8.cloudfront.net/production/uploads/655da1c1b6b63ed327df25f1/paincure-logo_whszvf.png?Expires=1707091200&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9wcm9kdWN0aW9uL3VwbG9hZHMvNjU1ZGExYzFiNmI2M2VkMzI3ZGYyNWYxL3BhaW5jdXJlLWxvZ29fd2hzenZmLnBuZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcwNzA5MTIwMH19fV19&Signature=JwQpA6Tb7%7EqaoW4bb1mRteX0Wj8V%7E5OPfv4I8I0JKdyZoq7SUES021qb2tClDygrp-o86gfax7XgMDeQTriVcEV5O5khwFPYkELZD4PHsqa5aEZldBBSPHfcHG%7EhUfcQA%7EgDiRGvaPUB9VzjHJpn3vX08GxyWl8heYRr571%7EpMO1H1sLhqY5SdSApK2NzphP76OVLMuzsfXfcOJJ1TgT22RVmi4By42rSra8uLweIFYxZ1ckn0R5NerlWWNXsFIPu6TO8BIP4p4pSepfasVMYOKucjg0VtT9VRoaXaEDU6mkctHKn6%7EAom24aluGNGjpT397RTKfFJYXajl4E%7EvQdg__&Key-Pair-Id=K1Y7U91AR6T7E5",
              crossOrigin: "anonymous",
              filters: [],
              _exists: true
            }
          ],
          background: "#2347e6"
        },
        newlyAdded: false,
        status: "edited",
        approxDuration: 8.764705882352942
      },
      {
        id: 1415370241063,
        speech:
          "Paincure Acalabrutinib word aanvaar vir beperkte gebruik as monoterapie vir voorheen onbehandelde CLL-pasiënte met 'n del 17p of tp53-mutasie<break time='0.5s' /> en by wie chemoterapie ongeskik is",
        avatar: {
          _id: 6,
          id: "kira.doctor",
          name: "Doctor",
          type: null,
          status: 2,
          gender: "female",
          tilt: {
            left: 0.05
          },
          listeningAvatar: {
            canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira-doctor.png",
            file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
            alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
          },
          thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
          intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-doctor-1080.mp4",
          introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
        },
        language: "Afrikaans",
        voice: "af-ZA-AdriNeural",
        voiceProvider: "azure",
        voiceType: "text",
        animation: "fade_in",
        canvas: {
          version: "5.3.0",
          objects: [
            {
              type: "video",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: -6.400000000000034,
              top: -3.6000000000000227,
              width: 1920,
              height: 1080,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.34,
              scaleY: 0.34,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://player.vimeo.com/external/484756416.hd.mp4?s=b1a2f65a32008fe4bc3b21dd78300a09ad5cb3e5&profile_id=175&oauth2_token_id=57447761&withcors",
              crossOrigin: "anonymous",
              filters: [],
              bg: true,
              id: 1573624827932,
              loop: false,
              durationFitAudio: false,
              speedRate: 1,
              volume: 0,
              hasAudio: false,
              trimStart: 0,
              trimEnd: 24.32,
              animation: {
                type: null,
                startTime: 0,
                exitType: null
              },
              thumbnail: "https://images.pexels.com/videos/6011430/pexels-photo-6011430.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
              selectable: false,
              _exists: true
            },
            {
              type: "avatar",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 487.5,
              top: 181.66667296,
              width: 0,
              height: 0,
              fill: "#4868FF",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.16141975,
              scaleY: 0.16141975,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
              crossOrigin: null,
              filters: [],
              avatarType: "transparent",
              tilt: {
                left: 0.05
              },
              animation: {
                type: null,
                exitType: null
              },
              _exists: true
            },
            {
              type: "image",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: -1,
              top: 0,
              width: 295,
              height: 84,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.52550569,
              scaleY: 0.52550569,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              id: 1390088362972,
              src: "https://d3u63mhbhkevz8.cloudfront.net/production/uploads/655da1c1b6b63ed327df25f1/paincure-logo_whszvf.png?Expires=1707091200&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9wcm9kdWN0aW9uL3VwbG9hZHMvNjU1ZGExYzFiNmI2M2VkMzI3ZGYyNWYxL3BhaW5jdXJlLWxvZ29fd2hzenZmLnBuZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcwNzA5MTIwMH19fV19&Signature=JwQpA6Tb7%7EqaoW4bb1mRteX0Wj8V%7E5OPfv4I8I0JKdyZoq7SUES021qb2tClDygrp-o86gfax7XgMDeQTriVcEV5O5khwFPYkELZD4PHsqa5aEZldBBSPHfcHG%7EhUfcQA%7EgDiRGvaPUB9VzjHJpn3vX08GxyWl8heYRr571%7EpMO1H1sLhqY5SdSApK2NzphP76OVLMuzsfXfcOJJ1TgT22RVmi4By42rSra8uLweIFYxZ1ckn0R5NerlWWNXsFIPu6TO8BIP4p4pSepfasVMYOKucjg0VtT9VRoaXaEDU6mkctHKn6%7EAom24aluGNGjpT397RTKfFJYXajl4E%7EvQdg__&Key-Pair-Id=K1Y7U91AR6T7E5",
              crossOrigin: "anonymous",
              filters: [],
              _exists: true
            }
          ],
          background: "#2347e6"
        },
        newlyAdded: false,
        status: "edited",
        approxDuration: 11.411764705882353
      },
      {
        id: 1109464419179,
        speech:
          "SMC-raad gee die oorwegings van area-medisyn- en terapeutiese komitees en NHS-raad in Skotland vir plaaslike gebruik of formularium-insluiting van Paincure Acalabrutinib",
        avatar: {
          _id: 6,
          id: "kira.doctor",
          name: "Doctor",
          type: null,
          status: 2,
          gender: "female",
          tilt: {
            left: 0.05
          },
          listeningAvatar: {
            canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira-doctor.png",
            file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
            alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
          },
          thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
          intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-doctor-1080.mp4",
          introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
        },
        language: "Afrikaans",
        voice: "af-ZA-AdriNeural",
        voiceProvider: "azure",
        voiceType: "text",
        animation: "fade_in",
        canvas: {
          version: "5.3.0",
          objects: [
            {
              type: "video",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: -6.400000000000034,
              top: -3.6000000000000227,
              width: 1920,
              height: 1080,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.34,
              scaleY: 0.34,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://player.vimeo.com/external/464691122.hd.mp4?s=6ec1ee46f70386b385361b60291da6a7c496accb&profile_id=175&oauth2_token_id=57447761&withcors",
              crossOrigin: "anonymous",
              filters: [],
              bg: true,
              id: 1606149584497,
              loop: false,
              durationFitAudio: false,
              speedRate: 1,
              volume: 0,
              hasAudio: false,
              trimStart: 0,
              trimEnd: 17.851167,
              animation: {
                type: null,
                startTime: 0,
                exitType: null
              },
              thumbnail: "https://images.pexels.com/videos/5519944/pexels-photo-5519944.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
              selectable: false,
              _exists: true
            },
            {
              type: "avatar",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 0.5,
              top: 177.66666956,
              width: 0,
              height: 0,
              fill: "#4868FF",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.1669753,
              scaleY: 0.1669753,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
              crossOrigin: null,
              filters: [],
              avatarType: "transparent",
              tilt: {
                left: 0.05
              },
              animation: {
                type: null,
                exitType: null
              },
              _exists: true
            },
            {
              type: "image",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: -1,
              top: 0,
              width: 295,
              height: 84,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.52550569,
              scaleY: 0.52550569,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              id: 1594706936858,
              src: "https://d3u63mhbhkevz8.cloudfront.net/production/uploads/655da1c1b6b63ed327df25f1/paincure-logo_whszvf.png?Expires=1707091200&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9wcm9kdWN0aW9uL3VwbG9hZHMvNjU1ZGExYzFiNmI2M2VkMzI3ZGYyNWYxL3BhaW5jdXJlLWxvZ29fd2hzenZmLnBuZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcwNzA5MTIwMH19fV19&Signature=JwQpA6Tb7%7EqaoW4bb1mRteX0Wj8V%7E5OPfv4I8I0JKdyZoq7SUES021qb2tClDygrp-o86gfax7XgMDeQTriVcEV5O5khwFPYkELZD4PHsqa5aEZldBBSPHfcHG%7EhUfcQA%7EgDiRGvaPUB9VzjHJpn3vX08GxyWl8heYRr571%7EpMO1H1sLhqY5SdSApK2NzphP76OVLMuzsfXfcOJJ1TgT22RVmi4By42rSra8uLweIFYxZ1ckn0R5NerlWWNXsFIPu6TO8BIP4p4pSepfasVMYOKucjg0VtT9VRoaXaEDU6mkctHKn6%7EAom24aluGNGjpT397RTKfFJYXajl4E%7EvQdg__&Key-Pair-Id=K1Y7U91AR6T7E5",
              crossOrigin: "anonymous",
              filters: [],
              _exists: true
            }
          ],
          background: "#2347e6"
        },
        newlyAdded: false,
        status: "edited",
        approxDuration: 9.882352941176471
      },
      {
        id: 1291130822114,
        speech:
          "Paincure Acalabrutinib verskaf 'n teikengerigte behandelingsopsie vir CLL-pasiënte in verskillende stadiums van die siekte, <break time='0.5s' /> dit verbeter progressievrye oorlewing en bied addisionele keuses in die terapeutiese klas",
        avatar: {
          _id: 6,
          id: "kira.doctor",
          name: "Doctor",
          type: null,
          status: 2,
          gender: "female",
          tilt: {
            left: 0.05
          },
          listeningAvatar: {
            canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira-doctor.png",
            file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
            alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
          },
          thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
          intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-doctor-1080.mp4",
          introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
        },
        language: "Afrikaans",
        voice: "af-ZA-AdriNeural",
        voiceProvider: "azure",
        voiceType: "text",
        animation: "fade_in",
        canvas: {
          version: "5.3.0",
          objects: [
            {
              type: "video",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: -6.400000000000034,
              top: -3.6000000000000227,
              width: 1920,
              height: 1080,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.34,
              scaleY: 0.34,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://player.vimeo.com/external/537181108.hd.mp4?s=5bc4b7ddbb27d3e9e174f7453413c486927247e4&profile_id=175&oauth2_token_id=57447761&withcors",
              crossOrigin: "anonymous",
              filters: [],
              bg: true,
              id: 1679461350166,
              loop: false,
              durationFitAudio: false,
              speedRate: 1,
              volume: 0,
              hasAudio: false,
              trimStart: 0,
              trimEnd: 11.44,
              animation: {
                type: null,
                startTime: 0,
                exitType: null
              },
              thumbnail: "https://images.pexels.com/videos/7517376/pexels-photo-7517376.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
              selectable: false,
              _exists: true
            },
            {
              type: "avatar",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 0.5,
              top: 176.83333522,
              width: 0,
              height: 0,
              fill: "#4868FF",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.16774691,
              scaleY: 0.16774691,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
              crossOrigin: null,
              filters: [],
              avatarType: "transparent",
              tilt: {
                left: 0.05
              },
              animation: {
                type: null,
                exitType: null
              },
              _exists: true
            },
            {
              type: "image",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: -1,
              top: 0,
              width: 295,
              height: 84,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.52550569,
              scaleY: 0.52550569,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              id: 1443556195074,
              src: "https://d3u63mhbhkevz8.cloudfront.net/production/uploads/655da1c1b6b63ed327df25f1/paincure-logo_whszvf.png?Expires=1707091200&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9wcm9kdWN0aW9uL3VwbG9hZHMvNjU1ZGExYzFiNmI2M2VkMzI3ZGYyNWYxL3BhaW5jdXJlLWxvZ29fd2hzenZmLnBuZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcwNzA5MTIwMH19fV19&Signature=JwQpA6Tb7%7EqaoW4bb1mRteX0Wj8V%7E5OPfv4I8I0JKdyZoq7SUES021qb2tClDygrp-o86gfax7XgMDeQTriVcEV5O5khwFPYkELZD4PHsqa5aEZldBBSPHfcHG%7EhUfcQA%7EgDiRGvaPUB9VzjHJpn3vX08GxyWl8heYRr571%7EpMO1H1sLhqY5SdSApK2NzphP76OVLMuzsfXfcOJJ1TgT22RVmi4By42rSra8uLweIFYxZ1ckn0R5NerlWWNXsFIPu6TO8BIP4p4pSepfasVMYOKucjg0VtT9VRoaXaEDU6mkctHKn6%7EAom24aluGNGjpT397RTKfFJYXajl4E%7EvQdg__&Key-Pair-Id=K1Y7U91AR6T7E5",
              crossOrigin: "anonymous",
              filters: [],
              _exists: true
            }
          ],
          background: "#2347e6"
        },
        newlyAdded: false,
        status: "edited",
        approxDuration: 13.41764705882353
      },
      {
        id: 722150567854,
        speech:
          "Paincure<break time='0.5s' />: SMC goedgekeurde dwelm vir Chroniese limfositiese leukemie in volwassenes<break time='0.5s' />\nBereik ons ​​by www.paincure.com vir al jou vrae en ons uitvoerende beampte sal jou bereik",
        avatar: {
          _id: 6,
          id: "kira.doctor",
          name: "Doctor",
          type: null,
          status: 2,
          gender: "female",
          tilt: {
            left: 0.05
          },
          listeningAvatar: {
            canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira-doctor.png",
            file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
            alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
          },
          thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
          intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-doctor-1080.mp4",
          introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
        },
        language: "Afrikaans",
        voice: "af-ZA-AdriNeural",
        voiceProvider: "azure",
        voiceType: "text",
        animation: "fade_left",
        canvas: {
          version: "5.3.0",
          objects: [
            {
              type: "avatar",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 0.5,
              top: 177.33333522,
              width: 0,
              height: 0,
              fill: "#4868FF",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.16728395,
              scaleY: 0.16728395,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              src: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
              crossOrigin: null,
              filters: [],
              avatarType: "transparent",
              tilt: {
                left: 0.05
              },
              animation: {
                type: null,
                exitType: null
              },
              _exists: true
            },
            {
              type: "image",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 230.88852837,
              top: 38,
              width: 295,
              height: 84,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 0.68762917,
              scaleY: 0.68762917,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              cropX: 0,
              cropY: 0,
              id: 1053482137668,
              src: "https://d3u63mhbhkevz8.cloudfront.net/production/uploads/655da1c1b6b63ed327df25f1/paincure-logo_whszvf.png?Expires=1707091200&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9wcm9kdWN0aW9uL3VwbG9hZHMvNjU1ZGExYzFiNmI2M2VkMzI3ZGYyNWYxL3BhaW5jdXJlLWxvZ29fd2hzenZmLnBuZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcwNzA5MTIwMH19fV19&Signature=JwQpA6Tb7%7EqaoW4bb1mRteX0Wj8V%7E5OPfv4I8I0JKdyZoq7SUES021qb2tClDygrp-o86gfax7XgMDeQTriVcEV5O5khwFPYkELZD4PHsqa5aEZldBBSPHfcHG%7EhUfcQA%7EgDiRGvaPUB9VzjHJpn3vX08GxyWl8heYRr571%7EpMO1H1sLhqY5SdSApK2NzphP76OVLMuzsfXfcOJJ1TgT22RVmi4By42rSra8uLweIFYxZ1ckn0R5NerlWWNXsFIPu6TO8BIP4p4pSepfasVMYOKucjg0VtT9VRoaXaEDU6mkctHKn6%7EAom24aluGNGjpT397RTKfFJYXajl4E%7EvQdg__&Key-Pair-Id=K1Y7U91AR6T7E5",
              crossOrigin: "anonymous",
              filters: [],
              _exists: true
            },
            {
              type: "textbox",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 188,
              top: 103,
              width: 296.5,
              height: 48.816,
              fill: "#ffffff",
              stroke: null,
              strokeWidth: 1,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              fontFamily:
                "Georgia, Noto Sans Devanagari, Noto Sans Bengali, Noto Sans Arabic, Noto Sans JP, Noto Sans KR, Noto Sans TC, Noto Sans SC, Noto Serif Devanagari, Noto Serif Bengali",
              fontWeight: "normal",
              fontSize: 20,
              text: "SMC het 'n medisyne vir chroniese\nlimfositiese leukemie by volwassenes goedgekeur",
              underline: false,
              overline: false,
              linethrough: false,
              textAlign: "left",
              fontStyle: "normal",
              lineHeight: 1.16,
              textBackgroundColor: "",
              charSpacing: 0,
              styles: [],
              direction: "ltr",
              path: null,
              pathStartOffset: 0,
              pathSide: "left",
              pathAlign: "baseline",
              minWidth: 20,
              splitByGrapheme: false,
              id: 417633420475,
              animation: {
                type: "fade_left",
                startTime: 1,
                startMarker: false,
                startScene: false
              },
              style: []
            },
            {
              type: "group",
              version: "5.3.0",
              originX: "left",
              originY: "top",
              left: 267.5,
              top: 256.16666888,
              width: 216.5,
              height: 26.16665937,
              fill: "rgb(0,0,0)",
              stroke: null,
              strokeWidth: 0,
              strokeDashArray: null,
              strokeLineCap: "butt",
              strokeDashOffset: 0,
              strokeLineJoin: "miter",
              strokeUniform: false,
              strokeMiterLimit: 4,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
              flipX: false,
              flipY: false,
              opacity: 1,
              shadow: null,
              visible: true,
              backgroundColor: "",
              fillRule: "nonzero",
              paintFirst: "fill",
              globalCompositeOperation: "source-over",
              skewX: 0,
              skewY: 0,
              id: 225384943315,
              animation: {
                type: "fade_left",
                startTime: 7,
                startMarker: false,
                startScene: false
              },
              objects: [
                {
                  type: "rect",
                  version: "5.3.0",
                  originX: "left",
                  originY: "top",
                  left: -108.25,
                  top: -13.08332969,
                  width: 216.5,
                  height: 26.16665937,
                  fill: "#d718aa",
                  stroke: "#d9d9d9",
                  strokeWidth: 0,
                  strokeDashArray: null,
                  strokeLineCap: "butt",
                  strokeDashOffset: 0,
                  strokeLineJoin: "miter",
                  strokeUniform: true,
                  strokeMiterLimit: 4,
                  scaleX: 1,
                  scaleY: 1,
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  opacity: 1,
                  shadow: null,
                  visible: true,
                  backgroundColor: "",
                  fillRule: "nonzero",
                  paintFirst: "fill",
                  globalCompositeOperation: "source-over",
                  skewX: 0,
                  skewY: 0,
                  rx: 0,
                  ry: 0,
                  id: 133499296288
                },
                {
                  type: "textbox",
                  version: "5.3.0",
                  originX: "left",
                  originY: "top",
                  left: -88.81665039,
                  top: -11.33666888,
                  width: 178.63330078,
                  height: 20.34,
                  fill: "#ffffff",
                  stroke: null,
                  strokeWidth: 1,
                  strokeDashArray: null,
                  strokeLineCap: "butt",
                  strokeDashOffset: 0,
                  strokeLineJoin: "miter",
                  strokeUniform: false,
                  strokeMiterLimit: 4,
                  scaleX: 1,
                  scaleY: 1,
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  opacity: 1,
                  shadow: null,
                  visible: true,
                  backgroundColor: "",
                  fillRule: "nonzero",
                  paintFirst: "fill",
                  globalCompositeOperation: "source-over",
                  skewX: 0,
                  skewY: 0,
                  fontFamily:
                    "Georgia, Noto Sans Devanagari, Noto Sans Bengali, Noto Sans Arabic, Noto Sans JP, Noto Sans KR, Noto Sans TC, Noto Sans SC, Noto Serif Devanagari, Noto Serif Bengali",
                  fontWeight: "normal",
                  fontSize: 18,
                  text: "www.paincure.com",
                  underline: false,
                  overline: false,
                  linethrough: false,
                  textAlign: "left",
                  fontStyle: "normal",
                  lineHeight: 1.16,
                  textBackgroundColor: "",
                  charSpacing: 0,
                  styles: [],
                  direction: "ltr",
                  path: null,
                  pathStartOffset: 0,
                  pathSide: "left",
                  pathAlign: "baseline",
                  minWidth: 20,
                  splitByGrapheme: false,
                  id: 1591550113905,
                  meta: {
                    splittedText: "www.paincure.com"
                  }
                }
              ]
            }
          ],
          background: "#2347e6"
        },
        newlyAdded: false,
        status: "edited",
        approxDuration: 13
      }
    ],
    tags: ["api"],
    deleted: false,
    status: "draft",
    verified: false,
    public: false,
    parentFolderDeleted: false,
    version: 105,
    userId: "655da1c1b6b63ed327df25f1",
    accountId: "655da1c1b6b63ed327df25f2",
    comments: [],
    createdAt: "2024-01-29T08:35:39.242Z",
    updatedAt: "2024-01-29T08:35:39.242Z",
    __v: 0,
    data: {
      priority: 1
    },
    moderation: null,
    duration: null,
    thumbnail: null,
    url: null,
    sourceId: "659f8b3ce150344c0f621465",
    update_status: true,
    is_render: false,
    video_id: 96
  };

  // dispatch(setElaiTranslationDataAction({ ...elaiData, ...res, locale: locale, storyboard_id: storyboardId, video_id: video_id }));
  // dispatch(setElaiTranslationDataAction({ ...obj, ...obj, locale: locale, storyboard_id: storyboardId, video_id: video_id }));

  // return;

  counter++;

  if (counter === 10) {
    counter = 0;

    dispatch(setLoadingStatusAction(false));

    dispatch(setNotificationMsgAction(I18N_MODULE_KEYS["content.translation.core.notification.limitexceed"]));

    return;
  }

  dispatch(setLoadingStatusAction(true));

  const res = await getElaiResponseDataRequest(video_id);

  if (!res?._id) {
    counter = 0;
    dispatch(setLoadingStatusAction(false));

    dispatch(setNotificationMsgAction(globalConstant.SOMETHING_WENT_WRONG));

    return false;
  }

  dispatch(setElaiTranslationDataAction({ ...elaiData, ...res, locale: locale, storyboard_id: storyboardId, video_id: video_id }));

  if (isInitial) {
    if (res?.slides?.length) {
      navigate(ENUM_ROUTE_URLS.CONTENT_TRANSLATION);
    } else {
      dispatch(setNotificationMsgAction(I18N_MODULE_KEYS["content.translation.core.notification.noslide"]));
    }
  }

  if (res?.update_status === true) {
    counter = 0;
    dispatch(setLoadingStatusAction(false));
    return;
  }

  timer = setTimeout(() => {
    getELaiResponse(elaiData, video_id, storyboardId, locale, dispatch, navigate, false);
  }, 10000);
};

export const initialVoiceListGenderType: VoiceListGenderType = {
  character: "",
  gender: "",
  icon: "",
  id: 0,
  locale: "",
  name: "",
  voice: "",
  voiceProvider: "",
  tags: [],
  selectedTag: ""
};
