import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ConfigProvider, FloatButton, Space, Spin, Typography, notification, theme } from "antd";

import MainRoutes from "./Routes";

import { NotificationPlacement } from "antd/es/notification/interface";
import { setNotificationMsgAction } from "./Store/actions/_commonActions";

import { useTranslation } from "react-i18next";
import { globalConstant, networkErrorMsgs } from "./Utils";
import SessionExpiredModal from "./Pages/Authentication/SessionExpiredModal";

import i18n from "./Components/i18n/_i18n";
import { I18N_MODULE_KEYS } from "./Components/i18n/core/constants";
import _localStorageService from "./Services/_localStorageService";

import "./App.css";
import TextArea from "antd/es/input/TextArea";
import { Authorizer } from "./Services";

const { Paragraph } = Typography;

const authorizer = new Authorizer();

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [api, contextHolder] = notification.useNotification();

  const { isDarkMode } = useSelector((state: any) => state?.commonReducer);
  const { notificationMsg } = useSelector((state: any) => state?.nonPersistedReducer);
  const { sessionExpired } = useSelector((state: any) => state?.authReducer);

  const [isloading, setIsloading] = useState<boolean>(false);
  const contextValue = useMemo(() => ({ name: notificationMsg }), [notificationMsg]);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    // setIsloading(true);
    // await authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);
    // // await authorizer.verifyAuth(
    // //   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGxvd2VkX3JvbGVzIjpbInVzZXIiXSwiYXVkIjoiZTUzNjVmZmItMWZlNy00OTBmLWJhNzgtOGQyYjMzMDY3MjBiIiwiZXhwIjoxNzA3NDY5MzY5LCJpYXQiOjE3MDc0NTQ5NjksImlzcyI6Imh0dHBzOi8vYXV0aC1kZXYuZGV2a3JhZnQuaW4iLCJsb2dpbl9tZXRob2QiOiJiYXNpY19hdXRoIiwibm9uY2UiOiIwY2RhYmY1MC03NTk5LTQ0OTgtYjVhMi01ZDE3NDRkNjZmMzEiLCJyb2xlcyI6WyJ1c2VyIl0sInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicHJvZmlsZSJdLCJzdWIiOiJlM2JhZjMzNy0xZDcyLTRiMDctOGY1Ni02Yjk2NDNhMWQ2ZDIiLCJ0b2tlbl90eXBlIjoiYWNjZXNzX3Rva2VuIn0.BCTeoV0U6r9hAlM_Fdm_OBU6ofpvGjrGH-lcKu8f0-aPrrGc-Vj5AmhwJcRhvd-QbRVyeWtikwMLDLbE7B3b-ktv6B45iMeMCOHYFAknNlcw6Sxkr512393z2p4l4co8icQG-fJ2TMitN2dEMgW3EG6T_tQwL43ngsnzObtPE8659qTw71D4vsnFZg2I94v11uh9uX_iJpd1mdAQTEoR1DXlMWcQ51RijWRGSIrOKwyXimn8MCO4pmTLXhPa-OGrVDX_r_iz9aghSSqklyNCxCTBAysfLTI8unIBNkeW5438fvoCHIptQ3Dm_ynaJdduolA6ZmkrcCxOZU-8LsSzvg",
    // //   dispatch
    // // );
    // setIsloading(false);

    i18n?.changeLanguage?.(_localStorageService.getAcceptLanguage());
  };

  useEffect(() => {
    console.log(isloading, "isloading");
  }, [isloading]);

  useEffect(() => {
    if (notificationMsg && notificationMsg?.length) {
      openNotification("topRight", notificationMsg);
    }
  }, [notificationMsg]);

  const openNotification = (placement: NotificationPlacement, notificationMsg: string) => {
    let msg: string = notificationMsg;

    const networkErrors: string[] = Object?.values(networkErrorMsgs);

    if (
      notificationMsg === globalConstant.INTERNAL_SERVER_ERROR ||
      notificationMsg === globalConstant.SOMETHING_WENT_WRONG ||
      networkErrors?.includes(notificationMsg) ||
      notificationMsg?.includes("content.")
    ) {
      msg = `${t(notificationMsg)}`;
    }

    api.info({
      message: `${t(I18N_MODULE_KEYS["content.text.notification"])}`,
      description: (
        <>
          <TextArea
            className="narration-textarea"
            style={{ resize: "none", border: 0, cursor: "pointer", padding: 0 }}
            value={msg}
            readOnly={true}
            autoSize={false}
          />
        </>
      ),
      onClose: () => {
        setTimeout(() => {
          dispatch(setNotificationMsgAction(""));
        }, 100);
      },
      style: { whiteSpace: "pre" },
      duration: 5
    });
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm
      }}
    >
      {contextHolder}
      {isloading ? (
        <Space style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Spin />
        </Space>
      ) : (
        <>
          <MainRoutes />
        </>
      )}

      <SessionExpiredModal open={sessionExpired} />

      <FloatButton.BackTop />
    </ConfigProvider>
  );
};

export default App;
