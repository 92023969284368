import { siteConfig } from "../../Services";
import { apiResponse } from "../../Utils/_gTypes";
import { CoreClaimsTypes, UpdateAssetGuide } from "../../Pages/IDetail Extract/core/modal";
import { handleDeleteDataFromApi, handleGetDataFromApi, handlePatchDataFromApi, handlePostDataFromApi, handlePutDataFromApi } from "../../Utils";
import { GlossaryTTDataTypes, GlossaryUDDataTypes } from "../../Pages/Translation Module/Core/modals";

const getUserID = () => "11";

class translationModuleRequests {
  async uploadFile(body: FormData, package_id: number | null, isLoading?: any, config?: any) {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.TM_UPLOAD_FILE + `?user_id=${getUserID()}${package_id ? `&package_id=${package_id}` : ""}`,
      body,
      isLoading ? isLoading : undefined,
      config
    );

    return res?.data;
  }

  async getLanguageList(isLoading?: any) {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.TM_GET_LANGUAGE_LIST, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async deleteFile(file_id: number, isLoading?: any) {
    const res: apiResponse = await handleDeleteDataFromApi(siteConfig.TM_DELETE_FILE + `/${file_id}`, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async translationText(body: GlossaryTTDataTypes, isLoading?: any, config?: any) {
    const res: apiResponse = await handlePostDataFromApi(siteConfig.TM_TRANSLATION_TEXT, body, isLoading ? isLoading : undefined, config);

    return res?.data;
  }

  async addGlossary(body: { source_language_id: number; target_language_id: number; source: string; target: string }, isLoading?: any, config?: any) {
    const res: apiResponse = await handlePostDataFromApi(siteConfig.TM_GLOSSARY, body, isLoading ? isLoading : undefined, config);

    return res?.data;
  }

  async updateGlossary(
    body: { source_language_id: number; target_language_id: number; source: string; target: string },
    glossary_id: number,
    isLoading?: any
  ) {
    const res: apiResponse = await handlePutDataFromApi(siteConfig.TM_GLOSSARY + `/${glossary_id}`, body, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async getGLossary(source_id: number, target_id: number, term: string, page?: number, isLoading?: any) {
    const res: apiResponse = await handleGetDataFromApi(
      siteConfig.TM_GLOSSARY + `?source=${source_id}&target=${target_id}&term=${term}` + `${page ? `&page=${page}&page_size=7` : "&order=true"}`,
      isLoading ? isLoading : undefined
    );

    return res?.data;
  }

  async deleteGlossary(glossary_id: number, isLoading?: any) {
    const res: apiResponse = await handleDeleteDataFromApi(siteConfig.TM_GLOSSARY + `/${glossary_id}`, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async updateTranslatedText(body: { translation_id: number; text: string }, isLoading?: any) {
    const res: apiResponse = await handlePutDataFromApi(siteConfig.TM_UPDATE_TRANSLATION, body, isLoading ? isLoading : undefined);

    return res?.data;
  }

  async revokeFileTranslation(packageId: number) {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.TM_TRANSLATION_FILE + "/" + packageId + siteConfig.TM_TRANSLATION_FILE_REVOKE,
      {}
    );
  }

  async glossaryUploadExcel(body: FormData, isLoading?: any, config?: any) {
    const res: apiResponse = await handlePostDataFromApi(siteConfig.TM_GLOSSARY_UPLOAD_EXCEL, body, isLoading ? isLoading : undefined, config);

    return res?.data;
  }
}

export default new translationModuleRequests();
