import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Card, Carousel, Col, Image, Row, Skeleton, Space, Spin, Typography } from "antd";
import { DrawerBody, DrawerFooter } from "../../Components/ActionDrawer/Components";
import { setActionDrawerComponentAction, setActionDrawerOpenAction } from "../../Components/ActionDrawer/State";

import { DownloadOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { globalConstant, onDownload } from "../../Utils";
import { setNotificationMsgAction } from "../../Store/actions/_commonActions";
import { getSummaryRequest } from "../../Store/requests/_contentTranscreationRequests";
import ImageTile from "./Components/ImageTile";
import AvatarVideoTile from "./Components/Process/AvatarVideoTile";
import "./_ViewSummary.css";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { ImageMask } from "../../Components/Image";

const { Text, Paragraph } = Typography;

type ViewSummaryType = {
  storyboard_id: number | null;
  avatar: any;
  template: any;
  stories: any[];
};

const initialResponse: ViewSummaryType = {
  storyboard_id: null,
  avatar: {},
  template: {},
  stories: []
};

export const ViewSummary: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { drawerOpen, componentId, others } = useSelector((state: any) => state?.actionDrawerReducer);

  const { responseCT } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [response, setResponse] = useState<ViewSummaryType>({
    ...initialResponse
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log("In view Summary use effect");
    setLoading(true);
    setResponse(initialResponse); //carousel was not rendering the images on reopening the drawer
    drawerOpen && getSummary();
  }, [drawerOpen]);

  const getSummary = async () => {
    const res = await getSummaryRequest(
      {
        storyboard_id: responseCT?.storyboard_id,
        video_id: responseCT?.video_id,
        avatar_id: responseCT?.avatar?.id,
        template_id: responseCT?.template?.id
      },
      dispatch
    );
    setLoading(false);

    if (res) {
      setResponse(res);
    } else {
      //dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
    }
  };

  useEffect(() => {
    if (componentId === "SemanticSearchView" && !drawerOpen) {
      dispatch(setActionDrawerComponentAction(null));
    }
  }, [drawerOpen]);

  return (
    <>
      <DrawerBody>
        {/* Storyboard */}
        <Row gutter={[8, 16]}>
          <Col xs={{ span: 24 }}>
            <Paragraph
              className="optimize-font"
              style={{
                fontSize: "18px",
                marginBottom: "10px",
                font: "normal normal bold 16px/24px Nunito Sans"
              }}
            >
              {/* Story Board */}

              {t(I18N_MODULE_KEYS["content.transcreation.viewsummary.drawer.title.storyboard"])}
            </Paragraph>
            <div style={{ overflow: "hidden", borderRadius: "12px" }}>
              <Card
                className="overflowY"
                style={{
                  minHeight: "4rem",
                  maxHeight: "37vh",
                  border: "1px solid lightgray",
                  borderRadius: "12px"
                }}
                bodyStyle={{ padding: "0" }}
              >
                {loading ? (
                  <div
                    style={{
                      textAlign: "center"
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    {response?.stories && response?.stories?.length ? (
                      <>
                        {response?.stories?.map((item: any, index: number) => {
                          return (
                            <Card key={index}>
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "flex-start",
                                  justifyContent: "space-between"
                                }}
                                key={`generate_images_content_${index}`}
                              >
                                <div className="d-flex" style={{ alignItems: "flex-start" }}>
                                  <div style={{ marginLeft: "10px" }}>
                                    <Paragraph
                                      className="optimize-font"
                                      style={{
                                        fontSize: "15px",
                                        marginBottom: "0px",
                                        font: "normal normal bold 16px/24px Nunito Sans"
                                      }}
                                    >
                                      {/* {`Story ${index + 1}`} */}
                                      {`${t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.story"])} ${index + 1}`}
                                    </Paragraph>
                                    <Paragraph
                                      style={{
                                        fontSize: "15px",
                                        marginBottom: "0px"
                                      }}
                                    >
                                      {/* Scene: {item?.scene || ""} */}
                                      {`${t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.scene"])}: ${item?.scene || ""} `}
                                    </Paragraph>
                                    <Paragraph style={{ fontSize: "15px" }}>
                                      {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.narration"])}:{item?.narration || ""}
                                    </Paragraph>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                      </>
                    ) : (
                      <Paragraph
                        type="secondary"
                        className="optimize-font"
                        style={{
                          fontSize: "12px",
                          padding: "24px",
                          paddingBottom: "12px"
                        }}
                      >
                        {/* No Story Found! */}
                        {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.nostoryfound"])}
                      </Paragraph>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Col>
          <Col xs={{ span: 24 }}>
            <Paragraph
              className="optimize-font"
              style={{
                fontSize: "15px",
                marginBottom: "10px",
                font: "normal normal bold 16px/24px Nunito Sans"
              }}
            >
              {/* {response?.stories?.[0]?.video ? "Clips" : "Images"} */}
              {response?.stories?.[0]?.video
                ? `${t(I18N_MODULE_KEYS["content.transcreation.viewsummary.drawer.title.clips"])}`
                : `${t(I18N_MODULE_KEYS["content.transcreation.viewsummary.drawer.title.images"])}`}
            </Paragraph>
            <Card
              style={{
                border: "1px solid lightgray",
                borderRadius: "12px",
                textAlign: "center"
              }}
            >
              {loading ? (
                <Spin />
              ) : (
                <>
                  {response?.stories && response?.stories?.length ? (
                    <>
                      <Row gutter={[16, 24]} style={{ justifyContent: "center" }}>
                        <Col xs={{ span: 12 }}>
                          <Carousel
                            autoplay
                            className="ct-viewsummary-carousel"
                            style={{
                              margin: "20px 10px"
                            }}
                          >
                            {response?.stories?.map((story, index) => {
                              return story?.image_url
                                ? story.image_url.map((imgSrc: string, index: number) => (
                                    <div key={`proceed_to_video_images_${index}`}>
                                      <Image
                                        src={imgSrc}
                                        style={{
                                          borderRadius: "8px"
                                        }}
                                        preview={{
                                          toolbarRender: (
                                            _,
                                            { transform: { scale }, actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
                                          ) => (
                                            <Space size={12} className="toolbar-wrapper">
                                              <DownloadOutlined onClick={() => onDownload(imgSrc)} />
                                              <SwapOutlined rotate={90} onClick={onFlipY} />
                                              <SwapOutlined onClick={onFlipX} />
                                              <RotateLeftOutlined onClick={onRotateLeft} />
                                              <RotateRightOutlined onClick={onRotateRight} />
                                              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            </Space>
                                          ),
                                          mask: <ImageMask />
                                        }}
                                      />
                                    </div>
                                  ))
                                : story?.video && (
                                    <div key={index}>
                                      <Image
                                        src={story?.video?.thumbnail_url}
                                        style={{
                                          borderRadius: "8px"
                                        }}
                                        preview={{
                                          imageRender: () => <video style={{ maxHeight: "50vh" }} controls src={story?.video?.video_url} />,
                                          toolbarRender: () => null,
                                          mask: <ImageMask />
                                        }}
                                      />
                                    </div>
                                  );
                            })}
                          </Carousel>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Paragraph
                      type="secondary"
                      className="optimize-font"
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      {/* No Image Found! */}
                      {t(I18N_MODULE_KEYS["content.text.noimagefound"])}
                    </Paragraph>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col xs={{ span: 12 }}>
            <Paragraph
              className="optimize-font"
              style={{
                fontSize: "15px",
                marginBottom: "10px",
                font: "normal normal bold 16px/24px Nunito Sans"
              }}
            >
              {/* Template */}
              {t(I18N_MODULE_KEYS["content.transcreation.viewsummary.drawer.title.templates"])}
            </Paragraph>
            <Card
              style={{
                border: "1px solid lightgray",
                borderRadius: "12px",
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {loading ? <Spin /> : <ImageTile imgSrc={response?.template?.thumbnail} description={response?.template?.name} />}
            </Card>
          </Col>
          <Col xs={{ span: 12 }}>
            <Paragraph
              className="optimize-font"
              style={{
                fontSize: "15px",
                marginBottom: "10px",
                font: "normal normal bold 16px/24px Nunito Sans"
              }}
            >
              {/* Avatar */}
              {t(I18N_MODULE_KEYS["content.transcreation.viewsummary.drawer.title.avatars"])}
            </Paragraph>
            <Card
              style={{
                border: "1px solid lightgray",
                borderRadius: "12px",
                padding: "20px",
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {loading ? (
                <Spin />
              ) : (
                <AvatarVideoTile
                  imgSrc={response?.avatar?.introPoster}
                  videoSrc={response?.avatar?.intro}
                  description={response?.avatar?.name + " " + response?.avatar?.variant_name}
                  showRight={false}
                />
              )}
            </Card>
          </Col>
        </Row>
      </DrawerBody>
      <DrawerFooter>
        <Button
          className="ant-btn-lg"
          type="primary"
          style={{ margin: "5px", background: "#0054CF" }}
          onClick={() => {
            dispatch(setActionDrawerOpenAction(false));
          }}
        >
          {/* Go Back */}
          {t(I18N_MODULE_KEYS["content.button.goback"])}
        </Button>
      </DrawerFooter>
    </>
  );
};
