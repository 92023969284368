import { FC, useEffect, useRef, useState } from "react";
import { PauseCircleTwoTone, PlayCircleTwoTone } from "@ant-design/icons";
import { Card, Col, Progress, Row, Space, Spin, Tag, Typography } from "antd";
import { useDispatch } from "react-redux";
import { setLanguageAction, setNarrationAction, setPauseAction, setPlayAction, setResumeAction, setStopAction } from "./_playerReducer";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../i18n/core/constants";
import { useTranslation } from "react-i18next";
import { fetchAudioRequest } from "../../Store/requests/_contentTranscreationRequests";
import { setNotificationMsgAction } from "../../Store/actions/_commonActions";
import { SlideDataType, VoiceListGenderType, VoiceListType } from "../../Pages/Content Transcreation/Content Translation/core/modals";
import { initialVoiceListGenderType } from "../../Pages/Content Transcreation/Content Translation/core/constants";
const enumPlayer = {
  PLAY: "play",
  PAUSE: "pause"
};

//  play >> tts play
//  play && pause >> tts pause
//  play && pause === false && resume=== true // tts resume
//  stop  && tts stop

const { Text } = Typography;

export const Player: FC<{ duration: number; language: string; slide: SlideDataType; activeSlide: number }> = ({ language, slide, activeSlide }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timerRef: any = useRef(null);

  const { voiceList } = useSelector((state: any) => state?.playerReducer);
  const { elaiResponse, phonemeList } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [timer, setTimer] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [audioData, setAudioData] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [player, setPlayer] = useState<string>(enumPlayer.PLAY);
  const [audioElem, setAudioElem] = useState<HTMLAudioElement | null>(null);
  const [selectedVoiceItem, setSelectedVoiceItem] = useState<VoiceListGenderType>({ ...initialVoiceListGenderType });

  useEffect(() => {
    return () => {
      setAudioElem(null);
      setIsLoading(false);
      setAudioData("");
    };
  }, []);

  useEffect(() => {
    handleStopPlayer();
  }, [activeSlide]);

  useEffect(() => {
    stopTimer();
    if (timer === 0) {
      handleStopPlayer();
      return;
    }

    if (timer.toFixed(1) === parseFloat(duration + "")?.toFixed(1)) {
      setTimeout(() => {
        resetTimer();
        handleStopPlayer();
      }, 1000);
      return;
    }

    startTimer();
  }, [timer]);

  useEffect(() => {
    if (voiceList?.length && slide?.voice && slide?.language && slide?.avatar?.gender) {
      handleSelectedVoiceName();
    }
  }, [voiceList, slide, elaiResponse]);

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      setTimer((t) => {
        const newTimer = parseFloat((t + 0.1).toFixed(1)); // Round to two decimal places
        return newTimer;
      });
    }, 100);
  };

  const stopTimer = () => {
    clearTimeout(timerRef.current);
  };

  const resetTimer = () => {
    setPlayer(enumPlayer.PLAY);
    setTimer(0);
  };

  const handlePlayer = async (type: string) => {
    let audio = audioElem;

    let strAudioData: string = audioData;

    setIsLoading(true);

    if (!strAudioData) {
      const res = await fetchAudioRequest({ text: slide?.speech, voice_id: selectedVoiceItem?.id });

      setIsLoading(false);

      if (!res?.data) {
        dispatch(setNotificationMsgAction(res?.detail));
        return;
      }

      setDuration(res?.duration);

      strAudioData = res?.data ? res?.data : "";

      setAudioData(strAudioData);
    }

    startTimer();

    setIsLoading(false);

    if (!audio) {
      audio = new Audio(`data:audio/x-wav;base64, ${strAudioData}`);
      setAudioElem(audio);
    }

    audio.preload = "auto";

    if (type === enumPlayer.PLAY) {
      audio.play();

      setPlayer(enumPlayer.PAUSE);
    } else if (type === enumPlayer.PAUSE) {
      setPlayer(enumPlayer.PLAY);
      stopTimer();
      audio.pause();
    }

    if (audio) {
      audio.onended = () => {
        setPlayer(enumPlayer.PLAY);
      };
    }
  };

  const handleStopPlayer = () => {
    setAudioData("");
    audioElem?.pause();
    setAudioElem(null);
    setIsLoading(false);
    setPlayer(enumPlayer.PLAY);
    setTimer(0);
    stopTimer()
  };

  const handleSelectedVoiceName = () => {
    const objFilteredVoiceList: VoiceListType = { ...voiceList?.find((item: VoiceListType) => item?.name === slide?.language) };

    if (slide?.avatar?.gender) {
      //@ts-ignore
      const objSelectedVoice: VoiceListGenderType = objFilteredVoiceList[slide?.avatar?.gender]?.find?.(
        (item: VoiceListGenderType) => item?.voice === slide?.voice
      );

      if (objSelectedVoice) {
        setSelectedVoiceItem(objSelectedVoice);
        // setSelectedVoiceName(`${objSelectedVoice?.character} (${slide?.language} - ${objSelectedVoice?.icon?.toLocaleUpperCase?.()})`);
      }
    } else {
      setSelectedVoiceItem({ ...initialVoiceListGenderType });
    }
  };

  return (
    <Card
      style={{
        justifyContent: "space-between",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: " 10px",
        opacity: 1,
        marginTop: "10px"
      }}
      bodyStyle={{ padding: "13px 13px" }}
    >
      <Row
        style={{
          display: "flex"
        }}
      >
        <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {isLoading ? (
            <Spin size="small" />
          ) : (
            <>
              {player === enumPlayer.PLAY && <PlayCircleTwoTone style={{ fontSize: "22px" }} onClick={() => handlePlayer(enumPlayer.PLAY)} />}
              {player === enumPlayer.PAUSE && <PauseCircleTwoTone style={{ fontSize: "22px" }} onClick={() => handlePlayer(enumPlayer.PAUSE)} />}
            </>
          )}
        </Col>
        <Col span={17}>
          <Progress percent={(timer / duration) * 100} showInfo={false} style={{ margin: "0px 0px 4px 0px" }} strokeColor={"#0546A5"} />
        </Col>
        <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Text style={{ opacity: 1, fontSize: "11px", fontWeight: "bold" }}>
            {timer ? (!timer?.toString?.()?.includes(".") ? timer + ".0s" : timer + "s") : "0s"}
          </Text>
        </Col>
        <Col span={2}>
          <Tag color="error" onClick={handleStopPlayer} style={{ cursor: "pointer" }}>
            {t(I18N_MODULE_KEYS["content.button.stop"])}
          </Tag>
        </Col>
      </Row>
    </Card>
  );
};
