import React, { useEffect, useState } from "react";
import { Card, Col, Row, Skeleton, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { globalConstant } from "../../../../Utils";
import { getAvatarsRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { setResponseCtAction } from "../../../../Store/actions/_contentTranscreationActions";
import AvatarVideoTile from "./AvatarVideoTile";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
const { Paragraph, Text } = Typography;

const handleClick = () => {};

const selectedCardStyle: React.CSSProperties = {
  boxShadow: "0px 6px 18px #00000029",
  border: " 5px solid rgb(0, 84, 207)",
  borderRadius: "12px"
};

const ProcessAvatar: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { responseCT } = useSelector((state: any) => state?.contentTranscreationReducer);
  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getAvatars();
  }, []);

  const getAvatars = async () => {
    setLoading(true);
    const res = await getAvatarsRequest(dispatch);
    setLoading(false);

    if (res && res.length) {
      setAvatars(res);
      if (!responseCT.avatar) updateReduxState(res[0]);
    } else {
      // dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
    }
  };

  const updateReduxState = async (avatar: {}) => {
    dispatch(
      setResponseCtAction({
        ...responseCT,
        avatar
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Text
          style={{
            font: "normal normal bold 26px/36px Nunito Sans",
            margin: "1rem 0 2rem 0",
            fontWeight: "800"
          }}
        >
          {/* Choose your Avatar */}
          {t(I18N_MODULE_KEYS["content.transcreation.process.avatar.title.chooseyouravatar"])}
        </Text>
      </div>
      <Row
        className="d-flex justify-content-center align-items-center"
        gutter={[
          { xs: 8, md: 16 },
          { xs: 8, md: 16, lg: 32 }
        ]}
      >
        {loading ? (
          <Spin />
        ) : (
          <>
            {avatars.length ? (
              avatars
                .filter((avatar: any) => avatar["is_active"])
                .map(({ id, intro: videoSrc, introPoster: imgSrc, name: description, variant_name: variantName }, index, activeAvatars) => (
                  <Col key={id}>
                    <AvatarVideoTile
                      imgSrc={imgSrc}
                      videoSrc={videoSrc}
                      description={description + " " + variantName}
                      style={responseCT?.avatar?.id === id ? selectedCardStyle : {}}
                      onClick={() => {
                        updateReduxState(activeAvatars[index]);
                      }}
                    />
                  </Col>
                ))
            ) : (
              <Paragraph
                type="secondary"
                className="optimize-font"
                style={{
                  fontSize: "12px"
                }}
              >
                {/* No Avatars Found! */}
                {t(I18N_MODULE_KEYS["content.transcreation.process.avatar.title.noavatarfound"])}
              </Paragraph>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default ProcessAvatar;
