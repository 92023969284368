import { FC, useCallback, useMemo } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { AlignLeftOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import TextArea from "antd/es/input/TextArea";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";

export const LiteratureReferenceCard: FC = () => {
  const { cardDataCollection, cardsData, searchQuery } = useSelector((state: any) => state.semanticSearchReducer);
  const dispatch = useDispatch();

  const handleOpeningFile = async (url: any) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleCopyContent = async (content: string) => {
    if (content) {
      await navigator.clipboard.writeText(content);
      dispatch(setNotificationMsgAction("Content Copied Successfully"));
      // Optionally, display a message or change state to indicate success
    }
  };

  return (
    <Col span={24}>
      <Card style={{ height: "575px" }}>
        <div style={{ fontWeight: "bold", fontSize: "18px" }}>Results</div>
        <div style={{ display: "flex", fontWeight: "400", color: "#66676B", fontSize: "18px", marginTop: "10px" }}>
          <div>Input :</div>
          <div>{searchQuery || "N/A"}</div> {/* Checking for null/undefined values */}
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", height: "450px", overflowY: "scroll" }}>
          <div style={{ width: "76%" }}>
            {cardsData && cardsData.map((doc: any, index: any) => (
              <div key={index} style={{ background: "rgb(237, 244, 255)", padding: "12px", borderRadius: "10px", marginTop: "30px" }}>
                <div style={{ display: "flex", fontSize: "16px" }}>
                  <p style={{ fontWeight: "bold" }}>Document Name :</p>
                  <p style={{ cursor: 'pointer' }} onClick={() => handleOpeningFile(doc.s3_url)}>{doc.filename || "Unnamed Document"}</p>
                </div>
                {doc.chunks && doc.chunks.map((chunk: any, chunkIndex: any) => (
                  <div key={chunkIndex} style={{ borderBottom: "2px solid #D9E8FF", paddingTop: "15px", paddingBottom: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", color: "#66676B", marginTop: "5px" }}>
                      <p style={{ width: "80%" }}>{chunk.content || "No content available"}</p>
                      <p style={{ fontSize: "14px", fontWeight: "500" }}>Page no.{chunk.page_number}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '10px' }}
                         onClick={() => handleCopyContent(chunk.content)}>
                      <img src={PROJECT_ICONS.COPY} style={{ height: "16px", cursor: "pointer" }} alt="Copy" />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </Col>
  );
};
