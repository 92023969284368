import { FC, useEffect, useState } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Spin, Flex } from "antd";
import {
  getDocuments,
  getFileManagementDocuments,
  getImageDocuments,
  getSemanticSearchDashboard,
} from "../../../../Store/requests/_semanticSearchRequests";
import { setFileLength } from "../../../../Store/actions/_semanticSearchActions.js";
import { useSelector } from "react-redux";
import { SEMANTIC_SEARCH_MODULES } from "../Core/constants"
import { FileSystemTabs } from "./_FileSystemTabs";
import { FileSystemFolders } from "./_FileSystemFolders" 

const FileSystemWrapper: FC = () => {
  const { activeModule, filecardType } = useSelector((state: any) => state?.semanticSearchReducer);
  const [foldersLength, setFoldersLength] = useState<Number>(0);
  const [isloading, setIsLoading] = useState<Boolean>(true);
  const [folderData, setFolderData] = useState<any>([]);

  useEffect(() => {
    const getFoldersBackend = async () => {
      if (activeModule === SEMANTIC_SEARCH_MODULES.FILE_MANAGEMENT_SYSTEM) {
        const folderData = await getFileManagementDocuments();

        if (!folderData) {
          setIsLoading(false);
          return;
        }
        const length = folderData?.data;
        setFolderData(length);
        setFoldersLength(length?.length);
        console.log(length?.length);
        setIsLoading(false);
      }
    };
    getFoldersBackend();
  }, [SEMANTIC_SEARCH_MODULES]); // Empt
  console.log(filecardType);
  return (
    <>
      {!isloading ? (
        <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>
          <>
            {foldersLength !== 0 ? (
              <Col span={24} style={{ paddingTop: "2px", paddingBottom: "10px" }}>
                <FileSystemTabs
                  folderData={folderData}
                  setFolderData={setFolderData}
                  foldersLength={foldersLength}
                  setFoldersLength={setFoldersLength}
                />
              </Col>
            ) : (
              <></>
            )}
            <FileSystemFolders
              folderData={folderData}
              setFolderData={setFolderData}
              foldersLength={foldersLength}
              setFoldersLength={setFoldersLength}
            />
          </>
        </Row>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "300px" }}>
            <Spin></Spin>
          </div>{" "}
        </>
      )}
    </>
  );
};

export { FileSystemWrapper };
