export const I18N_MODULE_KEYS = {
  /*Main constants*/
  ["app.name"]: "app.name",
  ["app.logo"]: "app.logo",

  /*Header constants*/
  ["header.searchtext"]: "header.searchtext",
  ["header.menu.language.english"]: "header.menu.language.english",
  ["header.menu.language.spanish"]: "header.menu.language.spanish",
  ["header.menu.language.chinese"]: "header.menu.language.chinese",
  ["header.menu.language.hindi"]: "header.menu.language.hindi",
  ["header.menu.myprofile"]: "header.menu.myprofile",
  ["header.menu.logout"]: "header.menu.logout",

  ["module.dashboard"]: "module.dashboard",
  ["module.login"]: "module.login",
  ["module.signup"]: "module.signup",
  ["module.transcreation"]: "module.transcreation",
  ["module.history"]: "module.history",
  ["module.translation"]: "module.translation",
  ["module.semantic"]: "module.semantic",
  ["module.fieldcopilot"]: "module.fieldcopilot",
  ["module.contentcreation"]: "module.contentcreation",
  ["module.idetailextract"]: "module.idetailextract",
  ["module.translation-module"]: "module.translation-module",

  /*Sidebar constants*/
  ["sidebar.dashboard"]: "sidebar.dashboard",
  ["sidebar.history"]: "sidebar.history",

  /*Content constants*/

  //dashboard
  ["content.dashboard.card.generativeai.title"]: "content.dashboard.card.generativeai.title",
  ["content.dashboard.card.generativeai.desc"]: "content.dashboard.card.generativeai.desc",

  ["content.dashboard.card.idetail.title"]: "content.dashboard.card.idetail.title",
  ["content.dashboard.card.idetail.desc"]: "content.dashboard.card.idetail.desc",

  ["content.dashboard.card.translation-module.title"]: "content.dashboard.card.translation-module.title",
  ["content.dashboard.card.translation-module.desc"]: "content.dashboard.card.translation-module.desc",

  ["content.dashboard.card.transcreation.title"]: "content.dashboard.card.transcreation.title",
  ["content.dashboard.card.transcreation.desc"]: "content.dashboard.card.transcreation.desc",

  ["content.dashboard.card.semantic.title"]: "content.dashboard.card.semantic.title",
  ["content.dashboard.card.semantic.desc"]: "content.dashboard.card.semantic.desc",

  ["content.dashboard.card.contentai.title"]: "content.dashboard.card.contentai.title",
  ["content.dashboard.card.contentai.desc"]: "content.dashboard.card.contentai.desc",

  ["content.dashboard.card.fieldcopilot.title"]: "content.dashboard.card.fieldcopilot.title",
  ["content.dashboard.card.fieldcopilot.desc"]: "content.dashboard.card.fieldcopilot.desc",

  ["content.dashboard.card.kolvideo.title"]: "content.dashboard.card.kolvideo.title",
  ["content.dashboard.card.kolvideo.desc"]: "content.dashboard.card.kolvideo.desc",

  ["content.dashboard.card.assistant.title"]: "content.dashboard.card.assistant.title",
  ["content.dashboard.card.assistant.desc"]: "content.dashboard.card.assistant.desc",

  ["content.dashboard.card.hyper.title"]: "content.dashboard.card.hyper.title",
  ["content.dashboard.card.hyper.desc"]: "content.dashboard.card.hyper.desc",

  ["content.dashboard.card.patientjourney.title"]: "content.dashboard.card.patientjourney.title",
  ["content.dashboard.card.patientjourney.desc"]: "content.dashboard.card.patientjourney.desc",

  ["content.dashboard.card.translationlocalisation.title"]: "content.dashboard.card.translationlocalisation.title",
  ["content.dashboard.card.translationlocalisation.desc"]: "content.dashboard.card.translationlocalisation.desc",

  ["content.dashboard.card.personalizedvideo.title"]: "content.dashboard.card.personalizedvideo.title",
  ["content.dashboard.card.personalizedvideo.desc"]: "content.dashboard.card.personalizedvideo.desc",

  ["content.dashboard.card.sentimentanalysis.title"]: "content.dashboard.card.sentimentanalysis.title",
  ["content.dashboard.card.sentimentanalysis.desc"]: "content.dashboard.card.sentimentanalysis.desc",

  ["content.dashboard.card.contentcreation.title"]: "content.dashboard.card.contentcreation.title",
  ["content.dashboard.card.contentcreation.desc"]: "content.dashboard.card.contentcreation.desc",

  ["content.dashboard.card.button.description"]: "content.dashboard.card.button.description",
  ["content.dashboard.card.button.demo"]: "content.dashboard.card.button.demo",

  //transcreation select source step
  ["content.transcreation.selectsource.1.title"]: "content.transcreation.selectsource.1.title",
  ["content.transcreation.selectsource.1.card.video"]: "content.transcreation.selectsource.1.card.video",
  ["content.transcreation.selectsource.1.card.social"]: "content.transcreation.selectsource.1.card.social",
  ["content.transcreation.selectsource.1.card.banner"]: "content.transcreation.selectsource.1.card.banner",
  ["content.transcreation.selectsource.1.card.journalad"]: "content.transcreation.selectsource.1.card.journalad",
  ["content.transcreation.selectsource.1.card.literature"]: "content.transcreation.selectsource.1.card.literature",
  ["content.transcreation.selectsource.1.card.poster"]: "content.transcreation.selectsource.1.card.poster",
  ["content.transcreation.selectsource.1.card.newsletter"]: "content.transcreation.selectsource.1.card.newsletter",
  ["content.transcreation.selectsource.1.card.brochure"]: "content.transcreation.selectsource.1.card.brochure",

  ["content.transcreation.selectsource.2.title"]: "content.transcreation.selectsource.2.title",
  ["content.transcreation.selectsource.2.card.email"]: "content.transcreation.selectsource.2.card.email",
  ["content.transcreation.selectsource.2.card.blog"]: "content.transcreation.selectsource.2.card.blog",
  ["content.transcreation.selectsource.2.card.banner"]: "content.transcreation.selectsource.2.card.banner",
  ["content.transcreation.selectsource.2.card.socialmediapost"]: "content.transcreation.selectsource.2.card.socialmediapost",
  ["content.transcreation.selectsource.2.card.poster"]: "content.transcreation.selectsource.2.card.poster",

  //transcreation source step
  ["content.transcreation.source.card.upload"]: "content.transcreation.source.card.upload",
  ["content.transcreation.source.card.pullClaims"]: "content.transcreation.source.card.pullClaims",
  ["content.transcreation.source.card.uploadasset"]: "content.transcreation.source.card.uploadasset",
  ["content.transcreation.source.card.uploaded"]: "content.transcreation.source.card.uploaded",
  ["content.transcreation.source.card.text.nofileselected"]: "content.transcreation.source.card.text.nofileselected",

  ["content.transcreation.source.card.text.drag"]: "content.transcreation.source.card.text.drag",
  ["content.transcreation.source.card.text.file"]: "content.transcreation.source.card.text.file",

  ["content.transcreation.source.card.text.subject"]: "content.transcreation.source.card.text.subject",
  ["content.transcreation.source.card.text.image"]: "content.transcreation.source.card.text.image",
  ["content.transcreation.source.card.text.body"]: "content.transcreation.source.card.text.body",

  //transcreation process step
  ["content.transcreation.process.storyboard.title"]: "content.transcreation.process.storyboard.title",
  ["content.transcreation.process.storyboard.text.selectall"]: "content.transcreation.process.storyboard.text.selectall",
  ["content.transcreation.process.storyboard.text.deselectall"]: "content.transcreation.process.storyboard.text.deselectall",
  ["content.transcreation.process.storyboard.text.scene"]: "content.transcreation.process.storyboard.text.scene",
  ["content.transcreation.process.storyboard.text.title"]: "content.transcreation.process.storyboard.text.title",
  ["content.transcreation.process.storyboard.text.narration"]: "content.transcreation.process.storyboard.text.narration",
  ["content.transcreation.process.storyboard.text.story"]: "content.transcreation.process.storyboard.text.story",
  ["content.transcreation.process.storyboard.text.nostoryfound"]: "content.transcreation.process.storyboard.text.nostoryfound",

  ["content.transcreation.process.storyboard.modal.title.editstoryboard"]: "content.transcreation.process.storyboard.modal.title.editstoryboard",
  ["content.transcreation.process.selectimage.title.chooseplatform"]: "content.transcreation.process.selectimage.title.chooseplatform",
  ["content.transcreation.process.generatedimage.title.imagegeneration"]: "content.transcreation.process.generatedimage.title.imagegeneration",
  ["content.transcreation.process.generatedimage.title.clipgeneration"]: "content.transcreation.process.generatedimage.title.clipgeneration",
  ["content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo"]:
    "content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo",
  ["content.transcreation.process.generatedimage.drawer.title.addliveclips"]:
    "content.transcreation.process.generatedimage.drawer.title.addliveclips",

  ["content.transcreation.process.template.title.chooseyourtemplate"]: "content.transcreation.process.template.title.chooseyourtemplate",
  ["content.transcreation.process.template.title.notemplatefound"]: "content.transcreation.process.template.title.notemplatefound",
  ["content.transcreation.process.avatar.title.chooseyouravatar"]: "content.transcreation.process.avatar.title.chooseyouravatar",
  ["content.transcreation.process.avatar.title.noavatarfound"]: "content.transcreation.process.avatar.title.noavatarfound",

  ["content.transcreation.successful.title.videogeneration"]: "content.transcreation.successful.title.videogeneration",
  ["content.transcreation.successful.title.generatevideo"]: "content.transcreation.successful.title.generatevideo",

  ["content.transcreation.viewsummary.drawer.title.summary"]: "content.transcreation.viewsummary.drawer.title.summary",
  ["content.transcreation.viewsummary.drawer.title.storyboard"]: "content.transcreation.viewsummary.drawer.title.storyboard",
  ["content.transcreation.viewsummary.drawer.title.images"]: "content.transcreation.viewsummary.drawer.title.images",
  ["content.transcreation.viewsummary.drawer.title.templates"]: "content.transcreation.viewsummary.drawer.title.templates",
  ["content.transcreation.viewsummary.drawer.title.avatars"]: "content.transcreation.viewsummary.drawer.title.avatars",
  ["content.transcreation.viewsummary.drawer.title.clips"]: "content.transcreation.viewsummary.drawer.title.clips",

  //transcreation header
  ["content.transcreation.activebar.heading.source"]: "content.transcreation.activebar.heading.source",
  ["content.transcreation.activebar.heading.process"]: "content.transcreation.activebar.heading.process",
  ["content.transcreation.activebar.heading.successful"]: "content.transcreation.activebar.heading.successful",

  //transcreation footer
  ["content.transcreation.footer.button.getstarted"]: "content.transcreation.footer.button.getstarted",
  ["content.transcreation.footer.button.viewdashboard"]: "content.transcreation.footer.button.viewdashboard",
  ["content.transcreation.footer.button.regeneratedoc"]: "content.transcreation.footer.button.regeneratedoc",
  ["content.transcreation.footer.button.createstoryboard"]: "content.transcreation.footer.button.createstoryboard",
  ["content.transcreation.footer.button.regeneratestory"]: "content.transcreation.footer.button.regeneratestory",
  ["content.transcreation.footer.button.choosetemplate"]: "content.transcreation.footer.button.choosetemplate",
  ["content.transcreation.footer.button.chooseavatar"]: "content.transcreation.footer.button.chooseavatar",
  ["content.transcreation.footer.button.viewsummary"]: "content.transcreation.footer.button.viewsummary",
  ["content.transcreation.footer.button.ordervideo"]: "content.transcreation.footer.button.ordervideo",

  ["content.transcreation.modal.ordervideosuccess"]: "content.transcreation.modal.ordervideosuccess",
  ["content.transcreation.modal.regneration"]: "content.transcreation.modal.regneration",
  ["content.transcreation.modal.regenerationdescription"]: "content.transcreation.modal.regenerationdescription",

  //notifications transcreation
  ["content.transcreation.steppers.1.notification.selectfile"]: "content.transcreation.steppers.1.notification.selectfile",
  ["content.transcreation.steppers.1.notification.filesizeissue"]: "content.transcreation.steppers.1.notification.filesizeissue",
  ["content.transcreation.steppers.1.notification.filetypeissue"]: "content.transcreation.steppers.1.notification.filetypeissue",
  ["content.transcreation.steppers.2.notification.textcopied"]: "content.transcreation.steppers.2.notification.textcopied",
  ["content.transcreation.steppers.blogmodal.notification.fillblogurl"]: "content.transcreation.steppers.blogmodal.notification.fillblogurl",
  ["content.transcreation.steppers.blogmodal.notification.entervalidurl"]: "content.transcreation.steppers.blogmodal.notification.entervalidurl",

  ["content.transcreation.wrapper.notification.postpublicationfail"]: "content.transcreation.wrapper.notification.postpublicationfail",
  ["content.transcreation.wrapper.notification.postpublicationsuccess"]: "content.transcreation.wrapper.notification.postpublicationsuccess",
  ["content.history.notification.featureinprogress"]: "content.history.notification.featureinprogress",

  ["content.history.core.notification.filedeletionsuccess"]: "content.history.core.notification.filedeletionsuccess",
  ["content.history.core.notification.filerenamesucess"]: "content.history.core.notification.filerenamesucess",
  ["content.history.core.notification.videodownloadsucess"]: "content.history.core.notification.videodownloadsucess",
  ["content.history.core.notification.videodownloaderror"]: "content.history.core.notification.videodownloaderror",
  ["content.history.view.gridview.notification.videogenerationinprogress"]: "content.history.view.gridview.notification.videogenerationinprogress",
  ["content.translation.translationwrapper.notification.videorendererror"]: "content.translation.translationwrapper.notification.videorendererror",
  ["content.translation.core.notification.limitexceed"]: "content.translation.core.notification.limitexceed",
  ["content.translation.core.notification.noslide"]: "content.translation.core.notification.noslide",

  //content ai constants
  ["content.contentai.headings.contentai"]: "content.contentai.headings.contentai",
  ["content.contentai.headings.contentcreator"]: "content.contentai.headings.contentcreator",
  ["content.contentai.headings.llmexperience"]: "content.contentai.headings.llmexperience",
  ["content.contentai.headings.llmcompliance"]: "content.contentai.headings.llmcompliance",
  ["content.contentai.headings.llmmlrguidline"]: "content.contentai.headings.llmmlrguidline",
  ["content.contentai.headings.contentuniverse"]: "content.contentai.headings.contentuniverse",

  ["content.contentai.headings.contentai.text.mynameisline"]: "content.contentai.headings.contentai.text.mynameisline",
  ["content.contentai.headings.contentai.text.howcanihelpyou"]: "content.contentai.headings.contentai.text.howcanihelpyou",
  ["content.contentai.headings.contentai.text.createpharmacontent"]: "content.contentai.headings.contentai.text.createpharmacontent",
  ["content.contentai.headings.contentai.text.complianceandregulation"]: "content.contentai.headings.contentai.text.complianceandregulation",

  ["content.contentai.headings.contentai.text.enrichprompt"]: "content.contentai.headings.contentai.text.enrichprompt",
  ["content.contentai.headings.contentai.text.revisedprompt"]: "content.contentai.headings.contentai.text.revisedprompt",
  ["content.contentai.headings.contentai.text.appliedrules"]: "content.contentai.headings.contentai.text.appliedrules",
  ["content.contentai.headings.contentai.text.triggeredprompt"]: "content.contentai.headings.contentai.text.triggeredprompt",
  ["content.contentai.headings.contentai.text.refinedresponse"]: "content.contentai.headings.contentai.text.refinedresponse",

  ["content.contentai.headings.llm.text.rules"]: "content.contentai.headings.llm.text.rules",
  ["content.contentai.headings.llm.text.rule"]: "content.contentai.headings.llm.text.rule",
  ["content.contentai.headings.llm.text.addnewrules"]: "content.contentai.headings.llm.text.addnewrules",
  ["content.contentai.headings.llm.text.norulefound"]: "content.contentai.headings.llm.text.norulefound",
  ["content.contentai.headings.llm.text.applicability"]: "content.contentai.headings.llm.text.applicability",
  ["content.contentai.headings.llm.text.enterrule"]: "content.contentai.headings.llm.text.enterrule",
  ["content.contentai.headings.llm.text.tagsupdated"]: "content.contentai.headings.llm.text.tagsupdated",

  ["content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags"]:
    "content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags",
  ["content.contentai.headings.contentuniverse.text.pleaseselectatag"]: "content.contentai.headings.contentuniverse.text.pleaseselectatag",
  ["content.contentai.headings.contentuniverse.text.step1"]: "content.contentai.headings.contentuniverse.text.step1",
  ["content.contentai.headings.contentuniverse.text.step2"]: "content.contentai.headings.contentuniverse.text.step2",
  ["content.contentai.headings.contentuniverse.text.fileupload"]: "content.contentai.headings.contentuniverse.text.fileupload",
  ["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"]:
    "content.contentai.headings.contentuniverse.text.draganddropyourfileshere",
  ["content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB"]:
    "content.contentai.headings.contentuniverse.text.draganddropyourfileshere",
  ["content.contentai.headings.contentuniverse.text.supportedfiletype"]: "content.contentai.headings.contentuniverse.text.supportedfiletype",
  ["content.contentai.headings.contentuniverse.text.uploadedfile"]: "content.contentai.headings.contentuniverse.text.uploadedfile",
  ["content.contentai.headings.contentuniverse.text.choosedetails"]: "content.contentai.headings.contentuniverse.text.choosedetails",

  //contentai notifications
  ["content.contentai.headings.contentuniverse.notification.contentdeleted"]:
    "content.contentai.headings.contentuniverse.notification.contentdeleted",
  ["content.contentai.headings.contentuniverse.notification.contentemptyerror"]:
    "content.contentai.headings.contentuniverse.notification.contentemptyerror",
  ["content.contentai.headings.contentuniverse.notification.contentupdate"]: "content.contentai.headings.contentuniverse.notification.contentupdate",
  ["content.contentai.LLM.notification.ruleemptyerror"]: "content.contentai.LLM.notification.ruleemptyerror",
  ["content.contentai.LLM.notification.newruleadded"]: "content.contentai.LLM.notification.newruleadded",
  ["content.contentai.LLM.LLMBasecomponent.notification.cancelled"]: "content.contentai.LLM.LLMBasecomponent.notification.cancelled",
  ["content.contentai.LLM.LLMBasecomponent.notification.rulesapplied"]: "content.contentai.LLM.LLMBasecomponent.notification.rulesapplied",
  ["content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied"]: "content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied",
  ["content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat"]:
    "content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat",

  //semantic-search

  ["semantic.search.floatingsidebar.search"]: "semantic.search.floatingsidebar.search",
  ["semantic.search.floatingsidebar.filemanagement"]: "semantic.search.floatingsidebar.filemanagement",

  ["semantic.search.header.heading.search"]: "semantic.search.header.heading.search",
  ["semantic.search.landing.content.enterquery"]: "semantic.search.landing.content.enterquery",
  ["semantic.search.landing.content.number"]: "semantic.search.landing.content.number",
  ["semantic.search.landing.content.documentsindexed"]: "semantic.search.landing.content.documentsindexed",
  ["semantic.search.landing.content.insightsgiven"]: "semantic.search.landing.content.insightsgiven",
  ["semantic.search.landing.content.insightsdownloaded"]: "semantic.search.landing.content.insightsdownloaded",
  ["semantic.search.input.heading.search"]: "semantic.search.input.heading.search",
  ["semantic.search.input.heading.entersearch"]: "semantic.search.input.heading.entersearch",
  ["semantic.search.input.heading.clear"]: "semantic.search.input.heading.clear",
  ["semantic.search.result.enterfeedback"]: "semantic.search.result.enterfeedback",
  ["semantic.search.loading.pleasewait"]: "semantic.search.loading.pleasewait",
  ["semantic.search.loading.searchinprogress"]: "semantic.search.loading.searchinprogress",
  ["semantic.search.result.searchdepth"]: "semantic.search.result.searchdepth",
  ["semantic.search.result.format"]: "semantic.search.result.format",
  ["semantic.search.result.regenerate"]: "semantic.search.result.regenerate",
  ["semantic.search.result.depthoption.quickview"]: "semantic.search.result.depthoption.quickview",
  ["semantic.search.result.depthoption.deepdive"]: "semantic.search.result.depthoption.deepdive",
  ["semantic.search.result.depthoption.fullspectrum"]: "semantic.search.result.depthoption.fullspectrum",
  ["semantic.search.result.depthoption.quickviewsmall"]: "semantic.search.result.depthoption.quickviewsmall",
  ["semantic.search.result.depthoption.deepdivesmall"]: "semantic.search.result.depthoption.deepdivesmall",
  ["semantic.search.result.depthoption.fullspectrumsmall"]: "semantic.search.result.depthoption.fullspectrumsmall",
  ["semantic.search.result.formatoption.text"]: "semantic.search.result.formatoption.text",
  ["semantic.search.result.formatoption.list"]: "semantic.search.result.formatoption.list",
  ["semantic.search.result.formatoption.table"]: "semantic.search.result.formatoption.table",
  ["semantic.search.result.formatoption.textsmall"]: "semantic.search.result.formatoption.textsmall",
  ["semantic.search.result.formatoption.listsmall"]: "semantic.search.result.formatoption.listsmall",
  ["semantic.search.result.formatoption.tablesmall"]: "semantic.search.result.formatoption.tablesmall",
  ["semantic.search.result.answercard.files"]: "semantic.search.result.answercard.files",
  ["semantic.search.result.answercard.answerfeedback"]: "semantic.search.result.answercard.answerfeedback",
  ["semantic.search.result.answercard.nextinsight"]: "semantic.search.result.answercard.nextinsight",
  ["semantic.search.result.notification.copy"]: "semantic.search.result.notification.copy",
  ["semantic.search.result.notification.fileuploaded"]: "semantic.search.result.notification.fileuploaded",
  ["semantic.search.result.notification.unabletodownload"]: "semantic.search.result.notification.unabletodownload",
  ["semantic.search.result.notification.connectionerror"]: "semantic.search.result.notification.connectionerror",
  ["semantic.search.result.notification.feedbacksubmitted"]: "semantic.search.result.notification.feedbacksubmitted",
  ["semantic.search.result.modaltable.addcolumns"]: "semantic.search.result.modaltable.addcolumns",
  ["semantic.search.result.modaltable.douwanttoaddcolumns"]: "semantic.search.result.modaltable.douwanttoaddcolumns",
  ["semantic.search.result.modaltable.notification.manualcolumnsadded"]: "semantic.search.result.modaltable.notification.manualcolumnsadded",
  ["semantic.search.result.modaltable.enternewitem"]: "semantic.search.result.modaltable.enternewitem",

  // semantic-search generate report
  ["semantic.search.result.modal.generate"]: "semantic.search.result.modal.generate",
  ["semantic.search.result.modaltable.share"]: "semantic.search.result.modaltable.share",
  ["semantic.search.result.modaltable.add"]: "semantic.search.result.modaltable.add",
  ["semantic.search.result.modaltable.added"]: "semantic.search.result.modaltable.added",
  ["semantic.search.result.viewmore"]: "semantic.search.result.viewmore",
  ["semantic.search.result.noanswers"]: "semantic.search.result.noanswers",
  ["semantic.search.result.noreference"]: "semantic.search.result.noreference",
  ["semantic.search.result.assets"]: "semantic.search.result.assets",
  ["semantic.search.result.searchresults"]: "semantic.search.result.searchresults",
  ["semantic.search.result.actions"]: "semantic.search.result.actions",
  ["semantic.search.result.noreportselected"]: "semantic.search.result.noreportselected",

  // drawer
  ["semantic.search.result.drawer.pagenumber"]: "semantic.search.result.drawer.pagenumber",
  ["semantic.search.result.drawer.searchquery"]: "semantic.search.result.drawer.searchquery",
  ["semantic.search.result.drawer.notfound"]: "semantic.search.result.drawer.notfound",

  ["semantic.search.header.searchbyfoldername"]: "semantic.search.header.searchbyfoldername",
  ["semantic.search.header.enterfoldername"]: "semantic.search.header.enterfoldername",

  ["semantic.search.header.advancedfilters"]: "semantic.search.header.advancedfilters",
  ["semantic.search.header.appliedfilters"]: "semantic.search.header.appliedfilters",
  ["semantic.search.advancedfilters.tip"]: "semantic.search.advancedfilters.tip",
  ["semantic.search.advancedfilters.selectall"]: "semantic.search.advancedfilters.selectall",
  ["semantic.search.advancedfilters.document"]: "semantic.search.advancedfilters.document",
  ["semantic.search.advancedfilters.URL"]: "semantic.search.advancedfilters.URL",
  ["semantic.search.advancedfilters.image"]: "semantic.search.advancedfilters.image",
  ["semantic.search.advancedfilters.video"]: "semantic.search.advancedfilters.video",
  ["semantic.search.advancedfilters.audio"]: "semantic.search.advancedfilters.audio",
  ["semantic.search.advancedfilters.notification.applied"]: "semantic.search.advancedfilters.notification.applied",

  ["semantic.search.billing.heading"]: "semantic.search.billing.heading",
  ["semantic.search.billing.downloadreport"]: "semantic.search.billing.downloadreport",
  ["semantic.search.billing.apiconsumption"]: "semantic.search.billing.apiconsumption",
  ["semantic.search.billing.monthlybilling"]: "semantic.search.billing.monthlybilling",
  ["semantic.search.billing.api"]: "semantic.search.billing.api",
  ["semantic.search.billing.units"]: "semantic.search.billing.units",
  ["semantic.search.billing.unitprice"]: "semantic.search.billing.unitprice",
  ["semantic.search.billing.cost"]: "semantic.search.billing.cost",
  ["semantic.search.billing.costheads"]: "semantic.search.billing.costheads",
  ["semantic.search.billing.amount"]: "semantic.search.billing.amount",
  ["semantic.search.billing.chat"]: "semantic.search.billing.chat",
  ["semantic.search.billing.embeddings"]: "semantic.search.billing.embeddings",
  ["semantic.search.billing.notavailable"]: "semantic.search.billing.notavailable",
  ["semantic.search.billing.totalamount"]: "semantic.search.billing.totalamount",
  ["semantic.search.billing.notification"]: "semantic.search.billing.notification",

  ["semantic.search.userhistory.heading"]: "semantic.search.userhistory.heading",
  ["semantic.search.userhistory.insightssearched"]: "semantic.search.userhistory.insightssearched",
  ["semantic.search.userhistory.chats"]: "semantic.search.userhistory.chats",
  ["semantic.search.userdetails.nochatsfound"]: "semantic.search.userdetails.nochatsfound",
  ["semantic.search.userdetails.noinsightsfound"]: "semantic.search.userdetails.noinsightsfound",

  ["semantic.search.filesystem.uploaddocument"]: "semantic.search.filesystem.uploaddocument",
  ["semantic.search.filesystem.upload"]: "semantic.search.filesystem.upload",
  ["semantic.search.filesystem.createnewfolder"]: "semantic.search.filesystem.createnewfolder",
  ["semantic.search.filesystem.createfolder"]: "semantic.search.filesystem.createfolder",
  ["semantic.search.filesystem.browsefolder"]: "semantic.search.filesystem.browsefolder",
  ["semantic.search.filesystem.nodocsavaliable"]: "semantic.search.filesystem.nodocsavaliable",
  ["semantic.search.filesystem.nodocsavaliablecreate"]: "semantic.search.filesystem.nodocsavaliablecreate",
  ["semantic.search.filesystem.chat"]: "semantic.search.filesystem.chat",
  ["semantic.search.filesystem.Document By"]: "semantic.search.filesystem.Document By",
  ["semantic.search.filesystem.DocumentType"]: "semantic.search.filesystem.DocumentType",
  ["semantic.search.filesystem.Uploaded By"]: "semantic.search.filesystem.Uploaded By",
  ["semantic.search.filesystem.Uploaded Date"]: "semantic.search.filesystem.Uploaded Date",
  ["semantic.search.filesystem.actions"]: "semantic.search.filesystem.actions",
  ["semantic.search.filesystem.documents"]: "semantic.search.filesystem.documents",
  ["semantic.search.filesystem.videos"]: "semantic.search.filesystem.videos",
  ["semantic.search.filesystem.audios"]: "semantic.search.filesystem.audios",
  ["semantic.search.filesystem.images"]: "semantic.search.filesystem.images",
  ["semantic.search.filesystem.urls"]: "semantic.search.filesystem.urls",
  ["semantic.search.filesystem.documentsuploading"]: "semantic.search.filesystem.documentsuploading",
  ["semantic.search.filesystem.deletionfolderconfirmation"]: "semantic.search.filesystem.deletionfolderconfirmation",
  ["semantic.search.filesystem.deletionfileconfirmation"]: "semantic.search.filesystem.deletionfileconfirmation",
  ["semantic.search.filesystem.notification.foldercreation"]: "semantic.search.filesystem.notification.foldercreation",
  ["semantic.search.filesystem.notification.noinput"]: "semantic.search.filesystem.notification.noinput",
  ["semantic.search.filesystem.notification.folderdeletion"]: "semantic.search.filesystem.notification.folderdeletion",
  ["semantic.search.filesystem.notification.filedeletion"]: "semantic.search.filesystem.notification.filedeletion",
  ["semantic.search.filesystem.notification.folderdeletion.notupdated"]: "semantic.search.filesystem.notification.folderdeletion.notupdated",
  ["semantic.search.filesystem.notification.filedeletion.notupdated"]: "semantic.search.filesystem.notification.filedeletion.notupdated",
  ["semantic.search.filesystem.notification.errorfolderdeletion"]: "semantic.search.filesystem.notification.errorfolderdeletion",
  ["semantic.search.filesystem.notification.errorfiledeletion"]: "semantic.search.filesystem.notification.errorfiledeletion",
  ["semantic.search.filesystem.notification.nofileselected"]: "semantic.search.filesystem.notification.nofileselected",
  ["semantic.search.filesystem.notification.fileuploaded"]: "semantic.search.filesystem.notification.fileuploaded",
  ["semantic.search.filesystem.notification.foldercannotbecreated"]: "semantic.search.filesystem.notification.foldercannotbecreated",
  ["semantic.search.filesystem.notification.filecannotbeuploaded"]: "semantic.search.filesystem.notification.filecannotbeuploaded",

  ["semantic.search.filesystem.deletionimageconfirmation"]: "semantic.search.filesystem.deletionimageconfirmation",
  ["semantic.search.filesystem.notification.imagedeletion"]: "semantic.search.filesystem.notification.imagedeletion",
  ["semantic.search.filesystem.notification.imagedeletion.notupdated"]: "semantic.search.filesystem.notification.imagedeletion.notupdated",
  ["semantic.search.filesystem.notification.errorimagedeletion"]: "semantic.search.filesystem.notification.errorimagedeletion",
  ["semantic.search.filesystem.notification.noimageselected"]: "semantic.search.filesystem.notification.noimageselected",
  ["semantic.search.filesystem.notification.imageuploaded"]: "semantic.search.filesystem.notification.imageuploaded",
  ["semantic.search.filesystem.notification.imagecannotbeuploaded"]: "semantic.search.filesystem.notification.imagecannotbeuploaded",

  ["semantic.search.filesystem.deletionaudioconfirmation"]: "semantic.search.filesystem.deletionaudioconfirmation",
  ["semantic.search.filesystem.notification.audiodeletion"]: "semantic.search.filesystem.notification.audiodeletion",
  ["semantic.search.filesystem.notification.audiodeletion.notupdated"]: "semantic.search.filesystem.notification.audiodeletion.notupdated",
  ["semantic.search.filesystem.notification.erroraudiodeletion"]: "semantic.search.filesystem.notification.erroraudiodeletion",
  ["semantic.search.filesystem.notification.noaudioselected"]: "semantic.search.filesystem.notification.noaudioselected",
  ["semantic.search.filesystem.notification.audiouploaded"]: "semantic.search.filesystem.notification.audiouploaded",
  ["semantic.search.filesystem.notification.audiocannotbeuploaded"]: "semantic.search.filesystem.notification.audiocannotbeuploaded",

  ["semantic.search.filesystem.deletionvideoconfirmation"]: "semantic.search.filesystem.deletionvideoconfirmation",
  ["semantic.search.filesystem.notification.videodeletion"]: "semantic.search.filesystem.notification.videodeletion",
  ["semantic.search.filesystem.notification.videodeletion.notupdated"]: "semantic.search.filesystem.notification.videodeletion.notupdated",
  ["semantic.search.filesystem.notification.errorvideodeletion"]: "semantic.search.filesystem.notification.errorvideodeletion",
  ["semantic.search.filesystem.notification.novideoselected"]: "semantic.search.filesystem.notification.novideoselected",
  ["semantic.search.filesystem.notification.videouploaded"]: "semantic.search.filesystem.notification.videouploaded",
  ["semantic.search.filesystem.notification.videocannotbeuploaded"]: "semantic.search.filesystem.notification.videocannotbeuploaded",

  //chat document
  ["chatdocument.search.chatwithdocument"]: "chatdocument.search.chatwithdocument",
  ["chatdocument.search.chathistory"]: "chatdocument.search.chathistory",
  ["chatdocument.search.chatwith"]: "chatdocument.search.chatwith",
  ["chatdocument.search.clearall"]: "chatdocument.search.clearall",
  ["chatdocument.search.downloadchat"]: "chatdocument.search.downloadchat",
  ["chatdocument.search.pages"]: "chatdocument.search.pages",
  ["chatdocument.search.notfound"]: "chatdocument.search.notfound",
  ["chatdocument.search.today"]: "chatdocument.search.today",
  ["chatdocument.search.7days"]: "chatdocument.search.7days",
  ["chatdocument.search.lastmonth"]: "chatdocument.search.lastmonth",
  ["chatdocument.search.lastyear"]: "chatdocument.search.lastyear",
  ["chat.drawer.notification.chatdownload"]: "chat.drawer.notification.chatdownload",
  ["chat.drawer.notification.errorchat"]: "chat.drawer.notification.errorchat",

  /**Content translation */
  ["content.translation.voicemodal.Select a Voice"]: "content.translation.voicemodal.Select a Voice",
  ["content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs."]:
    "content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs.",
  ["content.translation.voicemodal.Select Gender"]: "content.translation.voicemodal.Select Gender",
  ["content.translation.voicemodal.Select by Tags"]: "content.translation.voicemodal.Select by Tags",
  ["content.translation.voicemodal.Show Premium"]: "content.translation.voicemodal.Show Premium",
  ["content.translation.voicemodal.Apply to All"]: "content.translation.voicemodal.Apply to All",
  ["content.translation.voicemodal.Apply Voices"]: "content.translation.voicemodal.Apply Voices",

  //content history
  ["content.history.wrapper.text.newfilename"]: "content.history.wrapper.text.newfilename",
  ["content.history.wrapper.text.searchvideo"]: "content.history.wrapper.text.searchvideo",
  ["content.history.wrapper.text.nohistoryfound"]: "content.history.wrapper.text.nohistoryfound",

  //login
  ["content.login.text.customizeyouremailusingai"]: "content.login.text.customizeyouremailusingai",
  ["content.login.text.email"]: "content.login.text.email",
  ["content.login.text.password"]: "content.login.text.password",
  ["content.login.text.confirmpassword"]: "content.login.text.confirmpassword",
  ["content.login.text.convertyouremailintoHTMLCraftImagesand"]: "content.login.text.convertyouremailintoHTMLCraftImagesand",
  ["content.login.text.weavevideostoleavealasting"]: "content.login.text.weavevideostoleavealasting",
  ["content.login.text.pleaseenteravalidemailaddress"]: "content.login.text.pleaseenteravalidemailaddress",
  ["content.login.text.passwordisrequired"]: "content.login.text.passwordisrequired",
  ["content.login.text.emailisrequired"]: "content.login.text.emailisrequired",
  ["content.login.text.pleaseenteravalidpassword"]: "content.login.text.pleaseenteravalidpassword",
  ["content.login.text.rememberme"]: "content.login.text.rememberme",
  ["content.login.text.forgotpassword"]: "content.login.text.forgotpassword",
  ["content.login.text.donthaveanaccount"]: "content.login.text.donthaveanaccount",
  ["content.login.text.createanaccount"]: "content.login.text.createanaccount",
  ["content.signup.text.alreadyhaveanaccount"]: "content.signup.text.alreadyhaveanaccount",
  ["content.verifiedpage.text.signupsuccessfull"]: "content.verifiedpage.text.signupsuccessfull",
  ["content.forgot.text.gotologinpage"]: "content.forgot.text.gotologinpage",
  ["content.forgot.text.resetpassword"]: "content.forgot.text.resetpassword",
  ["content.forgot.text.clicktologin"]: "content.forgot.text.clicktologin",
  ["content.forgot.text.helloagain"]: "content.forgot.text.helloagain",

  //Use Case Constants
  /** Field Copilot */
  ["content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant"]:
    "content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant",
  ["content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations"]:
    "content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations",
  ["content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point"]:
    "content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point",
  ["content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools"]:
    "content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools",
  ["content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations"]:
    "content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations",
  ["content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time"]:
    "content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time",
  ["content.usecase.fieldcopilot.description.tabcontents.Scientific Responses"]:
    "content.usecase.fieldcopilot.description.tabcontents.Scientific Responses",
  ["content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric"]:
    "content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric",
  ["content.usecase.fieldcopilot.description.tabcontents.Every interaction and recommendation is designed to be compliant, ensuring peace of mind"]:
    "content.usecase.fieldcopilot.description.tabcontents.Every interaction and recommendation is designed to be compliant, ensuring peace of mind",
  ["content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base"]:
    "content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base",
  ["content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base"]:
    "content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base",
  ["content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access"]:
    "content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access",
  ["content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey"]:
    "content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey",
  ["content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management"]:
    "content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management",
  ["content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders"]:
    "content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders",
  [`content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation`]: `content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation`,

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts"]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access."]:
    "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access.",

  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules."]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns."]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity."]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs."]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning."]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation"]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation."]:
    "content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation.",

  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Accuracy of Information"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Accuracy of Information",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings"]: "content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot"]:
    "content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot",

  ["content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Ethical Considerations"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.Ethical Considerations",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA"]:
    "content.usecase.fieldcopilot.future-potential.tabcontents.While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA",

  /************/

  /**Content Creation */
  ["content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations"]:
    "content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations",
  ["content.usecase.contentai.description.Prompt Enrichment"]: "content.usecase.contentai.description.Prompt Enrichment",
  ["content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience"]:
    "content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience",
  ["content.usecase.contentai.description.Automatic regulatory compliance check"]:
    "content.usecase.contentai.description.Automatic regulatory compliance check",
  ["content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards."]:
    "content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards.",
  ["content.usecase.contentai.description.Localization optimization"]: "content.usecase.contentai.description.Localization optimization",
  ["content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement"]:
    "content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement",
  ["content.usecase.contentai.description.Responsive design generator"]: "content.usecase.contentai.description.Responsive design generator",
  ["content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices."]:
    "content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices.",
  ["content.usecase.contentai.description.Scalable"]: "content.usecase.contentai.description.Scalable",
  ["content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs"]:
    "content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs",

  ["content.usecase.contentai.business-value-proposition.Faster Time-To-Market"]:
    "content.usecase.contentai.business-value-proposition.Faster Time-To-Market",
  ["content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches."]:
    "content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches.",
  ["content.usecase.contentai.business-value-proposition.Cost Efficiency"]: "content.usecase.contentai.business-value-proposition.Cost Efficiency",
  ["content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets."]:
    "content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets.",
  ["content.usecase.contentai.business-value-proposition.Market Expansion"]: "content.usecase.contentai.business-value-proposition.Market Expansion",
  ["content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities"]:
    "content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities",
  ["content.usecase.contentai.business-value-proposition.Increased Engagements"]:
    "content.usecase.contentai.business-value-proposition.Increased Engagements",
  ["content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs"]:
    "content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs",

  ["content.usecase.contentai.return-on-investment.Financial Benefits"]: "content.usecase.contentai.return-on-investment.Financial Benefits",
  ["content.usecase.contentai.return-on-investment.Cost savings"]: "content.usecase.contentai.return-on-investment.Cost savings",
  ["content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors"]:
    "content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors",
  ["content.usecase.contentai.return-on-investment.Improved top-line"]: "content.usecase.contentai.return-on-investment.Improved top-line",
  ["content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream"]:
    "content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream",
  ["content.usecase.contentai.return-on-investment.Operational Benefits"]: "content.usecase.contentai.return-on-investment.Operational Benefits",
  ["content.usecase.contentai.return-on-investment.Time saving"]: "content.usecase.contentai.return-on-investment.Time saving",
  ["content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution"]:
    "content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution",
  ["content.usecase.contentai.return-on-investment.Employee efficiency"]: "content.usecase.contentai.return-on-investment.Employee efficiency",
  ["content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects"]:
    "content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects",
  ["content.usecase.contentai.return-on-investment.Marketing Benefits"]: "content.usecase.contentai.return-on-investment.Marketing Benefits",
  ["content.usecase.contentai.return-on-investment.Global reach"]: "content.usecase.contentai.return-on-investment.Global reach",
  ["content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint"]:
    "content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint",
  ["content.usecase.contentai.return-on-investment.Market agility"]: "content.usecase.contentai.return-on-investment.Market agility",
  ["content.usecase.contentai.return-on-investment.Rapid content generation allow businesses to swiftly react to market trends or changes"]:
    "content.usecase.contentai.return-on-investment.Rapid content generation allow businesses to swiftly react to market trends or changes",

  ["content.usecase.contentai.success-metrics.Content production speed"]: "content.usecase.contentai.success-metrics.Content production speed",
  ["content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe"]:
    "content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe",
  ["content.usecase.contentai.success-metrics.Cost per content piece"]: "content.usecase.contentai.success-metrics.Cost per content piece",
  ["content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content"]:
    "content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content",
  ["content.usecase.contentai.success-metrics.CAC"]: "content.usecase.contentai.success-metrics.CAC",
  ["content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content"]:
    "content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content",
  ["content.usecase.contentai.success-metrics.ROI"]: "content.usecase.contentai.success-metrics.ROI",
  ["content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content"]:
    "content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content",
  ["content.usecase.contentai.success-metrics.Conversion rate"]: "content.usecase.contentai.success-metrics.Conversion rate",
  ["content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content"]:
    "content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content",
  ["content.usecase.contentai.success-metrics.Error rates"]: "content.usecase.contentai.success-metrics.Error rates",
  ["content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication"]:
    "content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication",
  ["content.usecase.contentai.success-metrics.Revision rate"]: "content.usecase.contentai.success-metrics.Revision rate",
  ["content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production."]:
    "content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production.",
  /***********/

  // basics
  ["content.button.submit"]: "content.button.submit",
  ["content.button.cancel"]: "content.button.cancel",
  ["content.button.goback"]: "content.button.goback",
  ["content.button.save"]: "content.button.save",
  ["content.button.proceed"]: "content.button.proceed",
  ["content.button.regenerate"]: "content.button.regenerate",
  ["content.button.replace"]: "content.button.replace",
  ["content.button.add"]: "content.button.add",
  ["content.button.showmore"]: "content.button.showmore",
  ["content.button.next"]: "content.button.next",
  ["content.button.discard"]: "content.button.discard",
  ["content.text.noimagefound"]: "content.text.noimagefound",
  ["content.text.gotodashboard"]: "content.text.gotodashboard",
  ["content.button.generate"]: "content.button.generate",
  ["content.text.enteryourprompt"]: "content.text.enteryourprompt",
  ["content.text.generateinitialresponse"]: "content.text.generateinitialresponse",
  ["content.text.searchhere"]: "content.text.searchhere",
  ["content.text.promptcannotbeempty"]: "content.text.promptcannotbeempty",

  ["content.text.filterby"]: "content.text.filterby",
  ["content.text.nofilterapplied"]: "content.text.nofilterapplied",
  ["content.button.reset"]: "content.button.reset",
  ["content.button.back"]: "content.button.back",
  ["content.button.apply"]: "content.button.apply",
  ["content.button.upload"]: "content.button.upload",
  ["content.text.sessionexpired"]: "content.text.sessionexpired",
  ["content.text.sessionhasexpired"]: "content.text.sessionhasexpired",
  ["content.text.actions"]: "content.text.actions",
  ["content.text.filterapplied"]: "content.text.filterapplied",
  ["content.text.selected"]: "content.text.selected",
  ["content.text.edit"]: "content.text.edit",
  ["content.text.delete"]: "content.text.delete",
  ["content.text.interested"]: "content.text.interested",
  ["content.text.notinterested"]: "content.text.notinterested",
  ["content.text.share"]: "content.text.share",
  ["content.text.response"]: "content.text.response",
  ["content.button.update"]: "content.button.update",
  ["content.text.errornoresponse"]: "content.text.errornoresponse",
  ["content.text.erroruploadfailed"]: "content.text.erroruploadfailed",
  ["content.text.nofilesuploaded"]: "content.text.nofilesuploaded",
  ["content.button.delete"]: "content.button.delete",
  ["content.button.no"]: "content.button.no",
  ["content.button.yes"]: "content.button.yes",
  ["content.button.stop"]: "content.button.stop",
  ["content.button.yesproceed"]: "content.button.yesproceed",
  ["content.transcreation.button.summary"]: "content.transcreation.button.summary",

  ["content.text.selectalanguage"]: "content.text.selectalanguage",
  ["content.button.translate"]: "content.text.translate",
  ["content.text.name"]: "content.text.name",
  ["content.text.uploadedon"]: "content.text.uploadedon",
  ["content.text.storyboard"]: "content.text.storyboard",
  ["content.text.image/clips"]: "content.text.image/clips",
  ["content.text.video"]: "content.text.video",
  ["content.text.action"]: "content.text.action",
  ["content.text.somethingwentwrong"]: "content.text.somethingwentwrong",
  ["content.text.internalservererror"]: "content.text.internalservererror",
  ["content.text.completed"]: "content.text.completed",
  ["content.text.inprogress"]: "content.text.inprogress",
  ["content.text.notstarted"]: "content.text.notstarted",
  ["content.text.notapplicable"]: "content.text.notapplicable",
  ["content.text.failed"]: "content.text.failed",

  ["content.text.preview"]: "content.text.preview",
  ["content.text.download"]: "content.text.download",
  ["content.text.rename"]: "content.text.rename",
  ["content.text.render"]: "content.text.render",
  ["content.text.pause"]: "content.text.pause",
  ["content.text.changevoice"]: "content.text.changevoice",
  ["content.text.speechtext"]: "content.text.speechtext",
  ["content.text.changeavatar"]: "content.text.changeavatar",
  ["content.text.filteravatar"]: "content.text.filteravatar",
  ["content.text.sendtoreport"]: "content.text.sendtoreport",
  ["content.text.close"]: "content.text.close",
  ["content.text.chat"]: "content.text.chat",

  ["content.text.phoneme"]: "content.text.phoneme",
  ["content.text.phonemedictionary"]: "content.text.phonemedictionary",
  ["content.text.addphoneme"]: "content.text.addphoneme",
  ["content.text.word"]: "content.text.word",
  ["content.text.pronunciation"]: "content.text.pronunciation",
  ["content.text.phonemedescription"]: "content.text.phonemedescription",

  ["content.text.error.400"]: "content.text.error.400",
  ["content.text.error.401"]: "content.text.error.401",
  ["content.text.error.402"]: "content.text.error.402",
  ["content.text.error.403"]: "content.text.error.403",
  ["content.text.error.404"]: "content.text.error.404",
  ["content.text.error.405"]: "content.text.error.405",
  ["content.text.error.406"]: "content.text.error.406",
  ["content.text.error.407"]: "content.text.error.407",
  ["content.text.error.408"]: "content.text.error.408",
  ["content.text.error.409"]: "content.text.error.409",
  ["content.text.error.410"]: "content.text.error.410",
  ["content.text.error.411"]: "content.text.error.411",
  ["content.text.error.412"]: "content.text.error.412",
  ["content.text.error.413"]: "content.text.error.413",
  ["content.text.error.414"]: "content.text.error.414",
  ["content.text.error.415"]: "content.text.error.415",
  ["content.text.error.416"]: "content.text.error.416",
  ["content.text.error.417"]: "content.text.error.417",
  ["content.text.error.500"]: "content.text.error.500",
  ["content.text.error.501"]: "content.text.error.501",
  ["content.text.error.502"]: "content.text.error.502",
  ["content.text.error.503"]: "content.text.error.503",
  ["content.text.error.504"]: "content.text.error.504",
  ["content.text.error.505"]: "content.text.error.505",
  ["content.text.error.511"]: "content.text.error.511",

  ["content.text.disardallchanges"]: "content.text.disardallchanges",
  ["content.text.areyousuretodiscard"]: "content.text.areyousuretodiscard",
  ["content.text.novideosfound"]: "content.text.novideosfound",
  ["content.text.generating.initialresponse"]: "content.text.generating.initialresponse",
  ["content.text.generating.refinedresponse"]: "content.text.generating.refinedresponse",
  ["content.text.regenerating.refinedresponse"]: "content.text.regenerating.refinedresponse",
  ["content.text.notification"]: "content.text.notification",
  ["content.text.enterblogurl"]: "content.text.enterblogurl",
  ["content.text.description"]: "content.text.description",
  ["content.button.start"]: "content.button.start",
  ["content.text.Strategic ROI of"]: "content.text.Strategic ROI of",

  ["content.text.Title should not be empty!"]: "content.text.Title should not be empty!",
  ["content.text.Scene should not be empty!"]: "content.text.Scene should not be empty!",
  ["content.text.Narration should not be empty!"]: "content.text.Narration should not be empty!",

  ["content.text.Updation of generated content is not allowed now as Video is rendered."]:
    "content.text.Updation of generated content is not allowed now as Video is rendered.",
  ["content.text.Are you sure you want to delete?"]: "content.text.Are you sure you want to delete?",
  ["content.text.Remove"]: "content.text.Remove",

  /**
   * I detail extract multilingiual
   */
  ["idetailextract._UploadFileCard.Upload Document"]: "idetailextract._UploadFileCard.Upload Document",
  ["idetailextract._UploadFileCard.Uploading"]: "idetailextract._UploadFileCard.Uploading",
  ["idetailextract._UploadFileCard.Click to Upload or Drop PDF here "]: "idetailextract._UploadFileCard.Click to Upload or Drop PDF here ",
  ["idetailextract._UploadFileCard.from url"]: "idetailextract._UploadFileCard.from url",
  ["idetailextract._UploadFileCard.Set Language for Scanned files"]: "idetailextract._UploadFileCard.Set Language for Scanned files",
  ["idetailextract._UploadFileCard.Country"]: "idetailextract._UploadFileCard.Country",
  ["idetailextract._UploadFileCard.Brand"]: "idetailextract._UploadFileCard.Brand",
  ["idetailextract._UploadFileCard.Language"]: "idetailextract._UploadFileCard.Language",

  ["idetailextract._CategoriesModal.Selected Categories"]: "idetailextract._CategoriesModal.Selected Categories",
  ["idetailextract._CategoriesModal.Search Catogory"]: "idetailextract._CategoriesModal.Search Catogory",
  ["idetailextract._CategoriesModal.Suggested"]: "idetailextract._CategoriesModal.Suggested",
  ["idetailextract._CategoriesModal.Additional"]: "idetailextract._CategoriesModal.Additional",
  ["idetailextract._CategoriesModal.No Data Found"]: "idetailextract._CategoriesModal.No Data Found",
  ["idetailextract._CategoriesModal.Identified Categories"]: "idetailextract._CategoriesModal.Identified Categories",

  ["idetailextract._CoreClaimMain._Headertab.Create Copy"]: "idetailextract._CoreClaimMain._Headertab.Create Copy",

  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview",
  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Page"]: "idetailextract._CoreClaimMain._CoreClaimPdfPreview.Page",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.PDF > Document Copy"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.PDF > Document Copy",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Selected Categories"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Selected Categories",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.ADD CATEGORIES"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.ADD CATEGORIES",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.This is a preview version and you can edit and create a copy out of it."]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.This is a preview version and you can edit and create a copy out of it.",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Step"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Step",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.of CVA"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.of CVA",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Add"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Add",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE ASSET TYPE"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE ASSET TYPE",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE CLAIM CATEGORY"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE CLAIM CATEGORY",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Next Step"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Next Step",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Previous Step"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Previous Step",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Choose ‘Asset & Claim category to proceed"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Choose ‘Asset & Claim category to proceed",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Core Claims"]: "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Core Claims",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Asset Creation Recommendation"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Asset Creation Recommendation",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content can not be empty"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content can not be empty",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page No"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page No",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page number can not be empty"]:
    "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page number can not be emptyContent",

  ["idetailextract._CoreClaimMain._TinyMCE.Loading Editor"]: "idetailextract._CoreClaimMain._TinyMCE.Loading Editor",

  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content saved"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content saved",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Saving failed! Please retry."]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Saving failed! Please retry.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content discarded!"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content discarded!",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No recent changes to save."]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.No recent changes to save.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No unsaved changes to discard."]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.No unsaved changes to discard.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Your Emailer Suggestion"]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Your Emailer Suggestion",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit Edit Mode"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit Edit Mode",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Preview"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Preview",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Cancel"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Cancel",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Lose Changes"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Lose Changes",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.If you proceed, you will lose all the changes you have made."]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.If you proceed, you will lose all the changes you have made.",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit without Saving"]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit without Saving",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save and Exit"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save and Exit",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard unsaved changes"]:
    "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard unsaved changes",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Unsaved changes"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Unsaved changes",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Proceed"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Proceed",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Close"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Close",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Edit"]: "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Edit",

  /**
   * Translation Module
   */
  ["translation-module._TextTranslationMain.Choose Base Language"]: "translation-module._TextTranslationMain.Choose Base Language",
  ["translation-module._TextTranslationMain.Choose Target Language"]: "translation-module._TextTranslationMain.Choose Target Language",
  ["translation-module._TextTranslationMain.RESET"]: "translation-module._TextTranslationMain.RESET",
  ["translation-module._TextTranslationMain.TRANSLATE"]: "translation-module._TextTranslationMain.TRANSLATE",
  ["translation-module._TextTranslationMain.Input"]: "translation-module._TextTranslationMain.Input",
  ["translation-module._TextTranslationMain.Output"]: "translation-module._TextTranslationMain.Output",

  ["translation-module._TextTranslationMain._IOTextTranslation.Enter your text"]:
    "translation-module._TextTranslationMain._IOTextTranslation.Enter your text",
  ["translation-module._TextTranslationMain._IOTextTranslation.ADD GLOSSARY"]:
    "translation-module._TextTranslationMain._IOTextTranslation.ADD GLOSSARY",

  ["translation-module._AddGlossaryModal.Add Glossary"]: "translation-module._AddGlossaryModal.Add Glossary",
  ["translation-module._AddGlossaryModal.Set rules for how words and phrases are translated"]:
    "translation-module._AddGlossaryModal.Set rules for how words and phrases are translated",
  ["translation-module._AddGlossaryModal.Replace"]: "translation-module._AddGlossaryModal.Replace",
  ["translation-module._AddGlossaryModal.e.g, hello!"]: "translation-module._AddGlossaryModal.e.g, hello!",
  ["translation-module._AddGlossaryModal.With"]: "translation-module._AddGlossaryModal.With",
  ["translation-module._AddGlossaryModal.e.g, 更高"]: "translation-module._AddGlossaryModal.e.g, 更高",
  ["translation-module._AddGlossaryModal.Source --> Target"]: "translation-module._AddGlossaryModal.Source --> Target",
  ["translation-module._AddGlossaryModal.RESET"]: "translation-module._AddGlossaryModal.RESET",
  ["translation-module._AddGlossaryModal.ADD"]: "translation-module._AddGlossaryModal.ADD",
  ["translation-module._AddGlossaryModal.UPDATE"]: "translation-module._AddGlossaryModal.UPDATE",
  ["translation-module._AddGlossaryModal.Search item"]: "translation-module._AddGlossaryModal.Search item",
  ["translation-module._AddGlossaryModal.OR"]: "translation-module._AddGlossaryModal.OR",
  ["translation-module._AddGlossaryModal.Action"]: "translation-module._AddGlossaryModal.Action",

  ["translation-module._MainTabs.TEXT TRANSLATION"]: "translation-module._MainTabs.TEXT TRANSLATION",
  ["translation-module._MainTabs.UPLOAD DOCUMENT(S)"]: "translation-module._MainTabs.UPLOAD DOCUMENT(S)",

  ["translation-module._UploadDocumentMain.File Upload"]: "translation-module._UploadDocumentMain.File Upload",
  ["translation-module._UploadDocumentMain.ADD GLOSSARY"]: "translation-module._UploadDocumentMain.ADD GLOSSARY",
  ["translation-module._UploadDocumentMain.Drag And Drop Your Files Here."]: "translation-module._UploadDocumentMain.Drag And Drop Your Files Here.",
  ["translation-module._UploadDocumentMain."]: "translation-module._UploadDocumentMain.Supported file type: .pdf",
  ["translation-module._UploadDocumentMain.of"]: "translation-module._UploadDocumentMain.of",
  ["translation-module._UploadDocumentMain.files uploaded"]: "translation-module._UploadDocumentMain.files uploaded",
  ["translation-module._UploadDocumentMain.Uploaded File"]: "translation-module._UploadDocumentMain.Uploaded File",
  ["translation-module._UploadDocumentMain.No files uploaded!"]: "translation-module._UploadDocumentMain.No files uploaded!",
  ["translation-module._UploadDocumentMain.NEXT"]: "translation-module._UploadDocumentMain.NEXT",
  ["translation-module._UploadDocumentMain.Clear All"]: "translation-module._UploadDocumentMain.Clear All",

  ["translation-module._TranslateAssetMain._HeadingContainer.Translate your Asset"]:
    "translation-module._TranslateAssetMain._HeadingContainer.Translate your Asset",
  ["translation-module._TranslateAssetMain._HeadingContainer.ADD GLOSSARY"]: "translation-module._TranslateAssetMain._HeadingContainer.ADD GLOSSARY",
  ["translation-module._TranslateAssetMain._HeadingContainer.At the moment, AI-generated translation will be applied to all of your assets."]:
    "translation-module._TranslateAssetMain._HeadingContainer.At the moment, AI-generated translation will be applied to all of your assets.",

  ["translation-module._TranslateAssetMain._SearchingContainer.Languages"]: "translation-module._TranslateAssetMain._SearchingContainer.Languages",
  ["translation-module._TranslateAssetMain._SearchingContainer.Search Language"]:
    "translation-module._TranslateAssetMain._SearchingContainer.Search Language",
  ["translation-module._TranslateAssetMain._SearchingContainer.Base Language English (US)"]:
    "translation-module._TranslateAssetMain._SearchingContainer.Base Language English (US)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Add Context to the Translation (Optional)"]:
    "translation-module._TranslateAssetMain._OptionalContainer.Add Context to the Translation (Optional)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Enter your text"]:
    "translation-module._TranslateAssetMain._OptionalContainer.Enter your text",

  ["translation-module._TranslateAssetMain._FooterContainer.Language Selected"]:
    "translation-module._TranslateAssetMain._FooterContainer.Language Selected",
  ["translation-module._TranslateAssetMain._FooterContainer.CANCEL"]: "translation-module._TranslateAssetMain._FooterContainer.CANCEL",
  ["translation-module._TranslateAssetMain._FooterContainer.PROCEED"]: "translation-module._TranslateAssetMain._FooterContainer.PROCEED",

  ["translation-module.FinalTranslationScreen.Translation is Done!"]: "translation-module.FinalTranslationScreen.Translation is Done!",
  ["translation-module.FinalTranslationScreen.Please select a file from the Dropdown to view the translated asset."]:
    "translation-module.FinalTranslationScreen.Please select a file from the Dropdown to view the translated asset.",
  ["translation-module.FinalTranslationScreen.Edit"]: "translation-module.FinalTranslationScreen.Edit",
  ["translation-module.FinalTranslationScreen.Input"]: "translation-module.FinalTranslationScreen.Input",
  ["translation-module.FinalTranslationScreen.Output"]: "translation-module.FinalTranslationScreen.Output",
  ["translation-module.FinalTranslationScreen.DOWNLOAD"]: "translation-module.FinalTranslationScreen.DOWNLOAD",
  ["translation-module.FinalTranslationScreen.Languages"]: "translation-module.FinalTranslationScreen.Languages",
  ["translation-module.FinalTranslationScreen.START AGAIN"]: "translation-module.FinalTranslationScreen.START AGAIN",
  ["translation-module.FinalTranslationScreen.GET YOUR QUALITY REPORT"]: "translation-module.FinalTranslationScreen.GET YOUR QUALITY REPORT",

  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translate your package"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translate your package",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.At the moment, AI-generated translation will be applied to all of your assets."]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.At the moment, AI-generated translation will be applied to all of your assets.",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Please wait..."]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.Please wait...",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Selected Language"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.Selected Language",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.“Translation”"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.“Translation”",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.is in Progress"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.is in Progress",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancel Translation"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancel Translation",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancelling Translation"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancelling Translation",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translation Cancelled!"]:
    "translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translation Cancelled!",

  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content saved!"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content saved!",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Saving failed! Please retry."]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Saving failed! Please retry.",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content discarded!"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content discarded!",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.No recent changes to save."]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No recent changes to save.",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.No unsaved changes to discard."]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No unsaved changes to discard.",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Modify Translated Content"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Modify Translated Content",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Close"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Close",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard unsaved changes"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard unsaved changes",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Cancel"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Cancel",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Lose Changes"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Lose Changes",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Unsaved changes"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Unsaved changes",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save and Exit"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save and Exit",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.Exit without Saving"]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Exit without Saving",
  ["translation-module.FinalTranslationScreen.EditTranslatedTextModal.If you proceed, you will lose all the changes you have made."]:
    "translation-module.FinalTranslationScreen.EditTranslatedTextModal.If you proceed, you will lose all the changes you have made."
};
