import { Col, Row } from "antd";
import { LeftAlignedAvatarTile } from "../Components";
import { TabData } from "../Core/types";
import { useTranslation } from "react-i18next";

const SuccessMetricsTab: React.FC<{ data: TabData }> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      {data?.content?.map((tile, key) => (
        <Col key={key} xs={{ span: 24 }} md={{ span: 8 }}>
          <LeftAlignedAvatarTile image={tile.imgSrc} heading={tile.point} description={tile.description as string} tileColor={tile.tileColor} />
        </Col>
      ))}
    </Row>
  );
};

export { SuccessMetricsTab };
