export const storyBoardStrings = [
`
Slide 1
Title: Addressing LDL-C Levels
Narration: Understand the challenge of reaching LDL-C targets in high-risk ASCVD patients despite treatment.
Scene: An impactful image illustrating the struggle of patients to achieve optimal LDL-C levels.

Slide 2
Title: Indications Overview
Narration: For adults with established cardiovascular disease, addressing the risk of serious heart-related events is crucial. Recognizing the role of effective treatment is essential in this fight.
Scene: A dynamic visual showcasing a diverse group of patients consulting with healthcare providers, emphasizing the need for personalized treatment strategies to manage cardiovascular risk.

Slide 3
Title: ACC Consensus Pathway
Narration: Most very high-risk ASCVD patients fail to achieve recommended LDL-C levels despite treatment efforts.
Scene: A visual representation of the 2022 ACC Consensus Pathway criteria, highlighting the hurdles faced by patients in meeting their LDL-C goals.

Slide 4
Title: Adjunctive Therapy
Narration: Many high-risk ASCVD patients may need additional therapies to reach optimal LDL-C levels beyond standard treatments.
Scene: An informative depiction of various treatment modalities, underscoring the importance of combination approaches in managing cholesterol levels.

Slide 5
Title: Treatment Failures
Narration: Even with high-intensity statin therapy, a substantial proportion of very high-risk ASCVD patients do not achieve target LDL-C levels.
Scene: A visual chart illustrating the real impact of treatment regimens on LDL-C levels, emphasizing the gaps in reaching the goal.

Slide 6
Title: Importance of Effective Solutions
Narration: It’s critical to recognize the significance of innovative therapies for ASCVD patients who struggle to meet LDL-C goals through standard treatments.
Scene: A compelling image depicting the transformation in patient care, showcasing a significant decrease in LDL-C levels and improved cardiovascular outcomes.

Slide 7
Title: Conclusion
Narration: Innovative treatments play a vital role in supporting the management of LDL-C levels in high-risk ASCVD patients who do not respond adequately to traditional therapies.
Scene: An inspiring image symbolizing hope, depicting healthcare professionals and patients working together toward optimal cardiovascular health.
`,
`
Slide 1
Title: Addressing LDL-C Levels
Narration: Understand the challenge of reaching LDL-C targets in high-risk ASCVD patients despite treatment.
Scene: An impactful image illustrating the struggle of patients to achieve optimal LDL-C levels.

Slide 2
Title: Indications for Treatment
Narration: Recognize that many adults with established cardiovascular disease require additional help to mitigate heart-related events.
Scene: A powerful visual showing a diverse group of adults engaging in discussions with healthcare providers, emphasizing the shared goal of understanding treatment options for managing cardiovascular risk effectively.

Slide 3
Title: ACC Consensus Pathway
Narration: Most very high-risk ASCVD patients fail to achieve recommended LDL-C levels despite treatment.
Scene: A visual representation of the 2022 ACC Consensus Pathway criteria and the challenges patients face in meeting LDL-C goals.

Slide 4
Title: Adjunctive Therapy
Narration: High-risk ASCVD patients may require additional therapies to reach optimal LDL-C levels.
Scene: An illustrative comparison of treatment options, emphasizing the role of adjunctive therapies in LDL-C reduction.

Slide 5
Title: Treatment Failures
Narration: Even with high-intensity statins, a significant percentage of very high-risk ASCVD patients do not achieve target LDL-C levels.
Scene: A comparative visualization showcasing the percentage of patients failing to reach LDL-C <55 mg/dL despite different treatment regimens.

Slide 6
Title: Importance of Treatment
Narration: Recognize the importance of additional strategies for patients with ASCVD who struggle to meet LDL-C goals with standard therapies.
Scene: A compelling visual demonstrating the impact of lowering LDL-C levels and reducing cardiovascular risk in high-risk ASCVD patients.

Slide 7
Title: Conclusion
Narration: Additional therapies play a vital role in managing LDL-C levels in high-risk ASCVD patients who do not respond adequately to standard treatments.
Scene: An inspiring image symbolizing hope and progress in achieving optimal cardiovascular health through comprehensive management strategies.
`
];
