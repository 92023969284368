import { FC, useState } from "react";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { Avatar, Card, Col, Flex, Row, Space } from "antd";
import Usericon from "../../../Components/Navbar/_Usericon";
import { Link, useNavigate } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../../Routes/_routesConfig";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActiveModuleActions } from "../../../Store/actions/_contentAIActions";
import { MenuI18N } from "../../../Components/i18n";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { BellFilled, InfoOutlined } from "@ant-design/icons";
import { resetTMData } from "../Core/constant";

export const TranslationModuleHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { activeModule } = useSelector((state: any) => state?.contentAIReducer);

  const [open, setOpen] = useState(false);

  const handleClick = (module: string) => {
    setOpen(false);
    activeModule !== module && dispatch(setActiveModuleActions(module));
  };

  return (
    <Flex justify="space-between" style={{ width: "100%", height: "100%" }}>
      <Flex vertical gap={15}>
        <img
          src={PROJECT_ICONS.TM_LOGO}
          alt="infoIcon"
          style={{ cursor: "pointer" }}
          onClick={() => {
            resetTMData(dispatch);
            navigate(ENUM_ROUTE_URLS.DASHBOARD);
          }}
        />

        <span style={{ lineHeight: "6px", marginTop: "17px", fontSize: "20px", cursor: "pointer", fontWeight: "700" }}>
          {t(I18N_MODULE_KEYS["module.translation-module"])}
        </span>
      </Flex>

      <Space style={{ marginLeft: "10px" }}>
        <Avatar
          icon={<BellFilled />}
          style={{
            marginRight: "10px",
            borderRadius: "15px",
            backgroundColor: "#1e4da1"
          }}
        />
        <Avatar icon={<InfoOutlined />} style={{ marginRight: "10px", borderRadius: "15px", backgroundColor: "#1e4da1" }} />
        <Usericon />
        <MenuI18N />
      </Space>
    </Flex>
  );
};
