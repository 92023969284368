import { BoldOutlined, ItalicOutlined, UnderlineOutlined, AlignLeftOutlined, AlignRightOutlined, AlignCenterOutlined } from "@ant-design/icons";
import Icon from "../../common/Icon";

export const STYLE = {
  width60: { width: 60 },
  width128: { width: "128px" },
  marginColor: { margin: "3.5px 0 0" },
  marginBottom10: { marginBottom: "10px" },
  marginBottom16: { marginBottom: "16px" },
  myFontsTitle: { fontSize: "12.5px", fontWeight: 600 },
  allFontsTitle: { fontSize: "12.5px", fontWeight: 600 }
};

export const boldIcon = <BoldOutlined />;
export const italicIcon = <ItalicOutlined />;
export const underlineIcon = <UnderlineOutlined />;
export const alignCenterIcon = <AlignCenterOutlined />;
export const alignLeftIcon = <AlignLeftOutlined />;
export const alignRightIcon = <AlignRightOutlined />;
export const minusIcon = <Icon name="minus" />;
export const listIcon = <Icon name="list" />;
export const orderedListIcon = <Icon name="ordered_list" />;

export const mapSubfamilyToStyle = (subfamily) => {
  const fontWeight = subfamily?.includes("Bold") ? "bold" : "normal";
  const fontStyle = subfamily?.includes("Italic") ? "italic" : "normal";

  return { fontWeight, fontStyle };
};
