import { Flex, Select, Typography } from "antd";
import { FC } from "react";

const { Text } = Typography;

type IProps = {
  label: string;
  value: string;
  placeholder: string;
  options: { value: string }[];
  defaultValue: string;
};

export const SelectComp: FC<IProps> = ({ label, value, placeholder, options, defaultValue }) => {
  return (
    <Select
      labelRender={() => (
        <Flex vertical>
          <Text style={{ fontSize: "11px", fontWeight: "400", color: "rgba(102, 103, 107, 1)" }}>{label}</Text>
          <Text style={{ fontSize: "12px", fontWeight: "400", color: "rgba(35, 31, 32, 1)" }}>{value}</Text>
        </Flex>
      )}
      placeholder={placeholder || ""}
      style={{ marginLeft: "5px", width: "200px", height: "40px" }}
      options={options}
      defaultValue={defaultValue || ""}
    />
  );
};
