import { PlusOutlined } from "@ant-design/icons";
import { Card, Col, Space, Spin, Typography } from "antd";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setRefetchContentHistoryListAction, setShowMoreListAction } from "../../../../../Store/actions/_contentTranscreationActions";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const ShowMoreListCard: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  return (
    <Col key={`ShowMoreListCard`} xs={{ span: 24 }} md={{ span: 8 }}>
      <Card
        style={{ height: "100%" }}
        bodyStyle={{
          padding: "8px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 6px 18px #00000029",
          borderRadius: "12px",
          opacity: 1,
          height: "100%",
          fontSize: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          minHeight: "200px"
        }}
        onClick={() => dispatch(setShowMoreListAction(true))}
      >
        <Space direction="vertical">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {isLoading ? (
              <Spin />
            ) : (
              <>
                <PlusOutlined />
                {/* <Text style={{ opacity: 1, fontSize: "22px", fontWeight: "bold" }}>Show More</Text> */}
                <Text style={{ opacity: 1, fontSize: "22px", fontWeight: "bold" }}>{t(I18N_MODULE_KEYS["content.button.showmore"])}</Text>
              </>
            )}
          </div>
        </Space>
      </Card>
    </Col>
  );
};
