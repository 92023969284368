import { LayoutSyncTypes } from "../../Utils/_gTypes";
import {
  SET_MINI_MASTER_DATA,
  SET_LOADING_STATUS,
  SET_DARK_MODE_STATUS,
  SET_NOTIFICATION_MESSAGE,
  SET_LAYOUT_SYNC,
  SET_TOGGLE_SIDEBAR_COLLAPSE,
  SET_AXIOS_SOURCE_TOKEN
} from "../constants/_commonConstants";

export const setLoadingStatusAction = (data: boolean) => {
  return { type: SET_LOADING_STATUS, payload: data };
};

export const setMiniMasterDataListAction = (data: any[]) => {
  return { type: SET_MINI_MASTER_DATA, payload: data };
};
export const setDarkModeStatusAction = (data: boolean) => {
  return { type: SET_DARK_MODE_STATUS, payload: data };
};
export const setNotificationMsgAction = (data: string) => {
  return { type: SET_NOTIFICATION_MESSAGE, payload: data };
};

export const setLayoutSyncAction = (data: LayoutSyncTypes) => {
  return { type: SET_LAYOUT_SYNC, payload: data };
};

export const settoggleSidebarCollapse = (data: boolean) => {
  return { type: SET_TOGGLE_SIDEBAR_COLLAPSE, payload: data }; // Use the new constant
};

export const setAxiosSourceTokenAction = (data: any) => {
  return { type: SET_AXIOS_SOURCE_TOKEN, payload: data }; // Use the new constant
};
