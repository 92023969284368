export const fontFamilyList = [
  "Anek Bangla",
  "Archivo",
  "Arimo",
  "Arial",
  "Arial Black",
  "Arial Narrow",
  "Arial Unicode MS",
  "Averia Serif Libre",
  "Azeret Mono",
  "Balsamiq Sans",
  "Georgia",
  "Glory",
  "Gluten",
  "Grandstander",
  "Great Vibes",
  "Hi Melody",
  "Hind Madurai",
  "Impact",
  "Inter",
  "JetBrains Mono",
  "Josefin Sans",
  "Josefin Slab",
  "Courier New",
  "Cousine",
  "Cuprum",
  "DM Mono",
  "DM Sans",
  "Epilogue",
  "Exo",
  "Expletus Sans",
  "Fahkwang",
  "Galada",
  "Genos",
  "Bellota",
  "Bodoni Moda",
  "Black Han Sans",
  "Brush Script MT",
  "Catamaran",
  "Caveat",
  "Comic Neue",
  "Comic Sans MS",
  "Cormorant Infant",
  "Courier",
  "Kufam",
  "Lato",
  "Lobster Two",
  "Mada",
  "Microsoft Sans Serif",
  "Mohave",
  "Montserrat",
  "Montserrat Alternates",
  "Noto Serif",
  "Noto Serif Display",
  "Nunito",
  "PT Sans",
  "PT Serif",
  "Papyrus",
  "Poppins",
  "Prompt",
  "Raleway",
  "Roboto",
  "Rockwell",
  "Sansita Swashed",
  "Source Serif Pro",
  "Sora",
  "Spectral SC",
  "Syne",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Varela",
  "Varela Round",
  "Verdana",
  "Work Sans"
];

export const elaiAvatarsList = [
  {
    id: "65841fc2c10c114a3d348cf7",
    code: "gia",
    name: "Gia",
    type: null,
    status: 2,
    gender: "female",
    limit: 300,
    defaultVoice: "elevenlabs@EXAVITQu4vr4xnSDxMaL",
    tilt: {},
    age: 32,
    ethnicity: "Black",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/business/listening/Gia_business_1.png",
          file: "s3://elai-avatars/common/gia/business/listening/gia_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/gia/business/listening/gia_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/business/gia_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/business/gia_business.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/gia_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/Gia_business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.png",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/listening/Gia_casual_1.png",
          file: "s3://elai-avatars/common/gia/casual/listening/gia_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/gia/casual/listening/gia_casual_listening_alpha.mp4"
        },
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/gia_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/Gia_casual.png"
      }
    ]
  },
  {
    id: "6580ca13695d492e7050097e",
    code: "dylan",
    name: "Dylan",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    tilt: {},
    age: 25,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/casual/listening/Dylan_casual_1.png",
          file: "s3://elai-avatars/common/dylan/casual/listening/dylan_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/dylan/casual/listening/dylan_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/casual/dylan_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/casual/dylan_casual.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/new_intro/dylan_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/new_intro/Dylan-casual.png"
      },
      {
        code: "call_centre",
        id: "call_centre",
        name: "Call Centre",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/callcentre/listening/Dylan_callcenter_1.png",
          file: "s3://elai-avatars/common/dylan/callcentre/listening/dylan_callcentre_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/dylan/callcentre/listening/dylan_callcentre_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/callcentre/dylan_callcentre.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/callcentre/dylan_callcentre.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/new_intro/dylan_callcentre_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/dylan/new_intro/Dylan-callcentre.png"
      }
    ]
  },
  {
    id: "64d4ad3d1de1feeffaa94f92",
    code: "1547917583563",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "female",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v5_3d_render_small_quit_girl_with_flower_in_hair_p_0-1_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92NV8zZF9yZW5kZXJfc21hbGxfcXVpdF9naXJsX3dpdGhfZmxvd2VyX2luX2hhaXJfcF8wLTFfdGh1bWJuYWlsLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=AXdU8bNqF32Kz%7EfIOtzxe9l86udOFkInQ56nkIMNbj4Ru4XJ-gP2d89Nt1qpZAJAUWd9ukskaq1M%7Ex3yu8bpW6BFPAZ0p7%7E7qPb93spULswD8kCOJ4RO1nDnLcdeAPM9Tf4gWuYaAZ5T2vS5OtLfpgQzRdevYAUEErXgCCIpBBXOAf38lINLbh51OXz0icxKM21X%7E9K40sbTMH5VWQ4PKs9vuNKFGOnaAb3AB2BM4aB9DULNgRsKDa9aivT8qEYZcoGnIF%7EJKgEDfmk4lYhzuj7Wsxz9pTrwijK3qNNGRa1xQ5hIgngiHz9UMPxLanevFoFN05qhimye3ZkR6JEr2g__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v5_3d_render_small_quit_girl_with_flower_in_hair_p_0-1.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92NV8zZF9yZW5kZXJfc21hbGxfcXVpdF9naXJsX3dpdGhfZmxvd2VyX2luX2hhaXJfcF8wLTEuanBnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzIxNjA2NDAwfX19XX0_&Signature=SzT5HVNcPNfUCXrXLLZ0lLh2kN90UXUeNpZMIGptN2uWN3u79naP5K06iiBNE7gTbvhjRrW%7EM9f5zrN80lRcJfLoIYuCqbWwEM2JrE5wpl%7Etr-88q4jo2GDKOF2DUdG9lD%7ERdkcqvOPxDgHFyk2UiLgNus8qTQhFYhtMvM94POeIqZ5s%7EHz2Z76-1lJPJEleHF01RB%7EAMLe16XEcna51jff7fslveTziNKSAUN-0Lgadpz2xf1xrsqGnidFlGArwOdxcmiExhXFIPyTOwue6Pd9Ufirqh4jnwV5ljJrGW2jXtiDp1UcUSbbYxQph6VCjwZslrYmbeOGi5s8H49lb0A__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-5.mp4",
    tilt: {
      top: 0,
      left: 0,
      zoom: 1
    },
    faceBbox: {
      x: 0,
      y: 0,
      faceWidth: 768,
      faceHeight: 830,
      faceShare: 0.7204861111111112
    },
    variants: []
  },
  {
    id: "65841e95b950b44a972ce953",
    code: "max",
    name: "Max",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    defaultVoice: "elevenlabs@TX3LPaxmHKxFdv7VOQHJ",
    tilt: {},
    age: 29,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/listening_business/Max_business_1.png",
          file: "s3://elai-avatars/common/max/listening_business/max_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/max/listening_business/max_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/max/max_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/max_business.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/doctor/listening_doctor/Max_doctor_1.png",
          file: "s3://elai-avatars/common/max/doctor/listening_doctor/max_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/max/doctor/listening_doctor/max_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/max/doctor/max_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/doctor/max_doctor.png",
        tilt: {
          left: 0.02
        },
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png"
      },
      {
        code: "construction",
        id: "construction",
        name: "Construction",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/max/const/max_const.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/const/max_const_1.png",
        tilt: {
          left: 0.04
        },
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max_constr_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-constr.png"
      }
    ]
  },
  {
    id: "6661836bf80eaede6dfded9a",
    code: "vadim",
    name: "Vadim",
    type: null,
    status: 2,
    gender: "male",
    defaultVoice: "elevenlabs@flq6f7yk4E4fJM5XTYuZ",
    tilt: {},
    age: 40,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/business/vadim_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/business/vadim_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/intro/vadim_bus.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/intro/vadim_bus.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/casual/vadim_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/casual/vadim_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/intro/vadim_cas.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/vadim/intro/vadim_cas.png"
      }
    ]
  },
  {
    id: "66617eae7fd7ac53132dc43e",
    code: "evelina",
    name: "Evelina",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 20,
    ethnicity: "Asian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/evelina/casual/evelina_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/evelina/casual/evelina_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/evelina/intro/evelina_cas.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/evelina/intro/evelina_cas.png"
      }
    ]
  },
  {
    id: "654e1923d592bf95fdfe715d",
    code: "daniel",
    name: "Daniel",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 30,
    ethnicity: "Black, Latino / Hispanic",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/casual/daniel_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/casual/daniel_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/intro/daniel.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/intro/Daniel.png"
      },
      {
        code: "fitness",
        id: "fitness",
        name: "Fitness",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/fitness/daniel_fitness.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/fitness/daniel_fitness.png",
        intro: "https://elai-avatars.s3.us-east-2.amazonaws.com/common/daniel/intro/fitness.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/intro/fitness.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/business/daniel_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/daniel/business/daniel_business.png",
        intro: "",
        introPoster: ""
      }
    ]
  },
  {
    id: "66617d0c7fd7ac53132db45f",
    code: "neyson",
    name: "Neyson",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 25,
    ethnicity: "Black",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/neyson/business/2/neyson.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/neyson/business/neyson.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/neyson/business/intro/neyson.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/neyson/business/intro/neyson_int.png"
      }
    ]
  },
  {
    id: "666180062d19afbfdde76329",
    code: "orhan",
    name: "Orhan",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 34,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/casual/orhan.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/casual/orhan.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/intro/orhan_cas.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/intro/orhan_cas_1.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/business/orhan.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/business/orhan.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/intro/orhan_bus.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/orhan/intro/orhan_bus_1.png"
      }
    ]
  },
  {
    id: "64d4f4ff6a67cfe9cefbafd3",
    code: "1363961221861",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "male",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/photoreal_a_photorealistic_portrait_of_a_man_in_a_crisp_busine_3_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9waG90b3JlYWxfYV9waG90b3JlYWxpc3RpY19wb3J0cmFpdF9vZl9hX21hbl9pbl9hX2NyaXNwX2J1c2luZV8zX3RodW1ibmFpbC5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=SEzsO2WYDgKBG2vEeaSPAzciFO3t2X2QcLuEBU4OW-HcCi0t7Jn4oJG0yMJafY6rzOI-dOcdMVnPith%7EqGIgCzMHwCcax9etVjErv5k2OXrqnRrcPlKV6Y15xQq2hfS1ZjpF024uAvI4jCUjPt117Kec9-9sOM85XlDvUPR4zraCB2dRbf0Oej928ehtigcXSMwusCcE0K4GeuGiKi1XyEiXQnIQH4GDaa-ovL-C6p8cwWUM03iTJK-kwGZJWhxwuT%7EZclgsKwfXCxXS-Xnj8E6RQhFRVUsXhTVj6WYQCIlwu3u2DsZxxLrVfSPAWCw2BulncJHZ6Jz5Mg9hv4B48w__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/photoreal_a_photorealistic_portrait_of_a_man_in_a_crisp_busine_3.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9waG90b3JlYWxfYV9waG90b3JlYWxpc3RpY19wb3J0cmFpdF9vZl9hX21hbl9pbl9hX2NyaXNwX2J1c2luZV8zLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=OnS2sGLUTbbJIzhMWTHN2IQFrS0XBq3QA4tNlN3eie3SHlO1%7E7DxjOvn9SwEtytF7ch8%7E9zu-lTQzF9DqBITFbAI1ew0Gx9MAAvTJP6WnsHlNDRnJSb95qgc7WdYEADy%7EaL-994Uo8ClD7ivnq2rqlEVmxSZQ4nr4ccDw3L-DP8MCVTBVXTuww5t58OBAtkibzsd3buXfz%7EAlqRzT8tJY3g3QPOt12J7OivMXgUid3l2HeMduawZLdE1JMOMop6HCbkq8-dRjBtEwLd3f9pLp33%7ERvN2fDoKaCfgfiPRx4jNRKZ8FKwO0qLZnWST250T97SwzpTito6vyveb9iIgYA__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-6.mp4",
    tilt: {
      top: -0.1366120218579235,
      left: 0.08469945355191257,
      zoom: 2.098360655737705
    },
    faceBbox: {
      x: 170,
      y: 50,
      faceWidth: 366,
      faceHeight: 366,
      faceShare: 0.3177083333333333
    },
    variants: []
  },
  {
    id: "657214832593422a4c52f8ce",
    code: "mila",
    name: "Mila",
    type: null,
    status: 2,
    gender: "female",
    defaultVoice: "elevenlabs@ThT5KcBeYPX3keUQqHPh",
    tilt: {},
    age: 27,
    ethnicity: "Asian",
    variants: [
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/doctor/listening/Mila_doctor_1.png",
          file: "s3://elai-avatars/common/mila/doctor/listening/mila_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/mila/doctor/listening/mila_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/doctor/mila_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/doctor/mila_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/new_intro/mila_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/new_intro/Mia-doctor.png"
      },
      {
        code: "chief",
        id: "chief",
        name: "Chef",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/chief/listening/Mila_chief_1.png",
          file: "s3://elai-avatars/common/mila/chief/listening/mila_chief_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/mila/chief/listening/mila_chief_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/chief/mila_chief.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/chief/mila_chief.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/new_intro/mila_chef_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/mila/new_intro/Mia-chief.png"
      }
    ]
  },
  {
    id: "66130c58235ab9d159223da9",
    code: "magnolia_v2",
    name: "Magnolia",
    type: null,
    status: 2,
    gender: "female",
    limit: 300,
    tilt: {},
    age: 35,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/magnolia/listening_business/Magnolia_business_1.png",
          file: "s3://elai-avatars/common/magnolia/listening_business/magnolia_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/magnolia/listening_business/magnolia_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/magnolia/magnolia_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/magnolia/magnolia_business.png",
        limit: 300
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/magnolia/casual/listening_casual/Magnolia-casual.png",
          file: "s3://elai-avatars/common/magnolia/casual/listening_casual/magnolia_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/magnolia/casual/listening_casual/magnolia_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/magnolia/casual/magnolia_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/magnolia/casual/magnolia_casual.png",
        limit: 300
      }
    ]
  },
  {
    id: "65841c08c10c114a3d34719f",
    code: "derek",
    name: "Derek",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    defaultVoice: "elevenlabs@VR6AewLTigWG4xSOukaG",
    tilt: {},
    age: 29,
    ethnicity: "Latino / Hispanic",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/listening/Derek_casual_1.png",
          file: "s3://elai-avatars/common/derek/listening/derek_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/derek/listening/derek_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/derek_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/derek_casual.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/new_intro/derek_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/new_intro/Derek-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/business/listening/Derek_business__1.png",
          file: "s3://elai-avatars/common/derek/business/listening/derek_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/derek/business/listening/derek_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/business/derek_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/business/derek_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/new_intro/derek_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/derek/new_intro/Derek-business.png"
      }
    ]
  },
  {
    id: "65841d5ae17155d131497677",
    code: "enzo",
    name: "Enzo",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    tilt: {},
    age: 40,
    ethnicity: "Black",
    variants: [
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/listening/Enzo_doctor_1.png",
          file: "s3://elai-avatars/common/enzo/listening/enzo_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/enzo/listening/enzo_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/enzo_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/enzo_doctor..png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/enzo_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/Enzo-doctor.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/casual/listening/Enzo_casual_1.png",
          file: "s3://elai-avatars/common/enzo/casual/listening/enzo_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/enzo/casual/listening/enzo_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/casual/enzo_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/casual/enzo_casual.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/enzo_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/Enzo-casual.png"
      }
    ]
  },
  {
    id: "658426c149039534efe16891",
    code: "rory",
    name: "Rory",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 25,
    ethnicity: "Latino / Hispanic",
    variants: [
      {
        code: "fitness",
        id: "fitness",
        name: "Fitness",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/rory_fitness.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/rory_fitness.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/new_intro/rory_fitness_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/new_intro/Rory-fintess.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/business/rory_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/business/rory_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/new_intro/rory_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/rory/new_intro/Rory-business.png"
      }
    ]
  },
  {
    id: "657ae88bed838d449b1341d8",
    code: "scarlett",
    name: "Scarlett",
    type: null,
    status: 2,
    gender: "female",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/scarlett/business/scarlett_business.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/scarlett/business/scarlett_business.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/scarlett/new_intro/scarlett.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/scarlett/new_intro/scarlett.png",
    tilt: {},
    age: 40,
    ethnicity: "White / Caucasian",
    variants: []
  },
  {
    id: "658410a3e17155d131492098",
    code: "roy",
    name: "Roy",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 25,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/casual/roy_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/casual/roy_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/new_intro/roy_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/new_intro/Roy_casual.png"
      },
      {
        code: "chief",
        id: "chief",
        name: "Chef",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/chief/roy_chief.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/chief/roy_chief.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/new_intro/roy_chef_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/roy/new_intro/Roy_chief.png"
      }
    ]
  },
  {
    id: "657c863730e8f1c712c8dd33",
    code: "parker",
    name: "Parker",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 35,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        tilt: {
          left: -0.05
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/business/parker_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/business/parker_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/new_intro/parker_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/new_intro/Parker.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/casual/parker_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/casual/parker_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/new_intro/parker_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/parker/new_intro/Parker_casual+.png"
      }
    ]
  },
  {
    id: "65a6b9fd7981f995c0d7155f",
    code: "flora",
    name: "Flora ",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 35,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/business/flora_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/business/flora_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/new_intro/flora_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/new_intro/flora_business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/casual/flora_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/casual/flora_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/new_intro/flora_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/flora/new_intro/Flora_casual.png"
      }
    ]
  },
  {
    id: "64d4f0fd6e7e7e7f8cc0d9e0",
    code: "1641142113121",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "female",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/photoreal_a_realistic_portrait_of_a_young_woman_with_long_flow_0_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9waG90b3JlYWxfYV9yZWFsaXN0aWNfcG9ydHJhaXRfb2ZfYV95b3VuZ193b21hbl93aXRoX2xvbmdfZmxvd18wX3RodW1ibmFpbC5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=obgrDs9qwbvEUmE6RsSfVWWVkgkhYSRA%7EFAYSj-tLwi5ryb6agiBHIUUyNMIdx24hq4WXW%7E90YKcka1BUh0fk7LQ1cguyzAct8GH1Z3uJOK0R2XmAL9aWOu6kbcS0WZNkgwOd-p75k-P3OWUmEQL1ddl2KDrX1qnsuYVignahZ8zOQLnjmYvFPBkMOSEIJb%7EMvChVJ4m2ypSOmCtuSrqr6WS-kcFP3nIkgizOJmKG%7EkBp2BObmZR0jwmLIzp1ULmzmOo0qpppQpOSX9yW5rBbS8J2ISBh7aXt6-BJfcLI31sWNmsweLr%7EKbFTjIXTPMJ8pOrCl%7EHrDwWpDGn2NLMhg__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/photoreal_a_realistic_portrait_of_a_young_woman_with_long_flow_0.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9waG90b3JlYWxfYV9yZWFsaXN0aWNfcG9ydHJhaXRfb2ZfYV95b3VuZ193b21hbl93aXRoX2xvbmdfZmxvd18wLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=s4-i5FwV%7E0cxCB6HbcmOnhj3eHitVRfXRkj2E0ZhpeLZ%7Ebb8PHYDymYeEIR4L%7E7I6LOVkRWT7auerxJqlQxJRQD3YagvM2y7NZN3Gnn75A8EaOrr%7EY5kbkjz0C%7EgHL%7E%7EFb0LFtQMeO%7EBN5DK9ELcK%7EIOQD%7E6vKHDSHMM2P34V7APsHxoI1p75OMkwhGV-z%7ErvspjwHQ7N0PS38S5J0lNXAfUH5YsWjsLLAaCjj46cgJG6JLz89God2MZoliCkC%7EWpOwFLyG0d9pFqET1DshymD8KXt0Qn5ejIVVfOSID%7E4X4tMhdU9liNaOBOYH0QvMaO6htLjMyl0qso-dcMmKIzQ__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-4.mp4",
    tilt: {
      top: -0.04684684684684685,
      left: -0.0045045045045045045,
      zoom: 1.3837837837837839
    },
    faceBbox: {
      x: 109,
      y: 26,
      faceWidth: 555,
      faceHeight: 555,
      faceShare: 0.4817708333333333
    },
    variants: []
  },
  {
    id: "6513f1ec48c497321426da23",
    code: "joseph",
    name: "Joseph",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 42,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "construction",
        id: "construction",
        name: "Construction",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/constr/joseph_constr.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/constr/joseph_constr.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/new_intro/joseph-construction.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/new_intro/Joseph-constr.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/business/joseph_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/business/joseph_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/new_intro/joseph.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/joseph/new_intro/joseph.png"
      }
    ]
  },
  {
    id: "6589ce7349039534eff3ffdd",
    code: "amanda",
    name: "Amanda",
    type: null,
    status: 2,
    gender: "female",
    defaultVoice: "elevenlabs@XrExE9yKIg1WjnnlVkGX",
    tilt: {},
    age: 27,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/amanda_regular_low.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/amanda_regular_low.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/new_intro/amanda-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/new_intro/Amanda-casual.png",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/listening_regular/Amanda_regular_1.png",
          file: "s3://elai-avatars/common/amanda/listening_regular/amanda_regular_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/amanda/listening_regular/amanda_regular_listening_alpha.mp4"
        }
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/amanda_business_low.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/amanda_business_low.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/new_intro/amanda-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/new_intro/Amanda-business.png",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amanda/listening_business/Amanda_business_1.png",
          file: "s3://elai-avatars/common/amanda/listening_business/amanda_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/amanda/listening_business/amanda_business_listening_alpha.mp4"
        }
      }
    ]
  },
  {
    id: "65842b4db950b44a972d45bf",
    code: "kamal",
    name: "Kamal",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    tilt: {},
    age: 43,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/listening_casual/Kamal_casual_1.png",
          file: "s3://elai-avatars/common/kamal/listening_casual/kamal_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kamal/listening_casual/kamal_casual_listening_alpha.mp4"
        },
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/new_intro/kamal-casual-1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/new_intro/Kamal_casual.png",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/low/kamal_look2.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/low/kamal_look2_low.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/listening_business/Kamal_business_1.png",
          file: "s3://elai-avatars/common/kamal/listening_business/kamal_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kamal/listening_business/kamal_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/low/kamal.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/low/kamal_low.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/new_intro/kamal-business-1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/new_intro/Kamal_business.png",
        limit: 300
      },
      {
        code: "thobe",
        id: "thobe",
        name: "Thobe",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/thobe/kamal_look3.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/thobe/kamal_look3.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/new_intro/kamal-thobe-1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kamal/new_intro/Kamal_thobe.png"
      }
    ]
  },
  {
    id: "65a7ed54503912ebae8cd889",
    code: "cody",
    name: "Cody",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 35,
    ethnicity: "Black",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/casual/cody_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/casual/cody_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/cody-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/Cody-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/business/cody_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/business/cody_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/cody-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/Cody-business.png"
      }
    ]
  },
  {
    id: "6584149db950b44a972caa3b",
    code: "kira",
    name: "Kira",
    type: null,
    status: 2,
    gender: "female",
    tilt: {
      left: 0.05
    },
    age: 25,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/listening_business/Kira_business_1.png",
          file: "s3://elai-avatars/common/kira/listening_business/kira_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kira/listening_business/kira_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/kira_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/kira_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira_doctor_1.png",
          file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
      }
    ]
  },
  {
    id: "65a8285e867a43f28b56890f",
    code: "stella",
    name: "Stella",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 35,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/casual/stella_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/casual/stella_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/new_intro/stella_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/new_intro/Stella-casual.png"
      },
      {
        code: "casual_2",
        id: "casual_2",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/casual2/stella_casual_2.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/casual2/stella_casual_2.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/new_intro/stella_casual2_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/new_intro/casual_2.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/business/stella_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/business/stella_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/new_intro/stella_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/stella/business/new_intro/Stella.png"
      }
    ]
  },
  {
    id: "658ab4b6b950b44a97496f85",
    code: "richard",
    name: "Richard",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 60,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/business/richard_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/business/richard_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/new_intro/richard-business.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/new_intro/Richard-business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/casual/richard_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/casual/richard_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/new_intro/richard-casual.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/richard/new_intro/Richard-casual.png"
      }
    ]
  },
  {
    id: "65842cc949039534efe19421",
    code: "amber",
    name: "Amber",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 30,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/casual/amber_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/casual/amber_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/new_intro/amber-casual-1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/new_intro/Amber_casual.png",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/casual/listening/Amber_casual_1.png",
          file: "s3://elai-avatars/common/amber/casual/listening/amber_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/amber/casual/listening/amber_casual_listening_alpha.mp4"
        }
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/business/amber_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/business/amber_business.png",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/business/listening/Amber_business_1.png",
          file: "s3://elai-avatars/common/amber/business/listening/amber_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/amber/business/listening/amber_business_listening_alpha.mp4"
        },
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/new_intro/amber-business-1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/amber/new_intro/Amber_business.png"
      }
    ]
  },
  {
    id: "648ac9207c5e565e49d07321",
    code: "naomi",
    name: "Naomi",
    type: null,
    status: 2,
    gender: "female",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/naomi_intro_2.mp4",
    tilt: {},
    age: 25,
    ethnicity: "Asian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/business_listening/Naomi_business_1.png",
          file: "s3://elai-avatars/common/naomi/business_listening/naomi_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/naomi/business_listening/naomi_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/naomi_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/naomi_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/new_intro/naomi_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/new_intro/Naomi_busines.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/casual/naomi_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/casual/naomi_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/new_intro/naomi_casual.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/naomi/new_intro/Naomi_casual.png"
      }
    ]
  },
  {
    id: "65a8274968c71ab472d70ee4",
    code: "sonya",
    name: "Sonya",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 25,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/business/sonya_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/business/sonya_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/new_intro/intro.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/new_intro/Sonya.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/casual/sonya_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/casual/sonya_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/new_intro/Sonya_casual.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/sonya/new_intro/Sonya_casual.png"
      }
    ]
  },
  {
    id: "6572165d3bb58d9c67987fef",
    code: "taylor",
    name: "Taylor",
    type: null,
    status: 2,
    gender: "female",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/taylor_intro_2.mp4",
    tilt: {},
    age: 28,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/casual/taylor_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/casual/taylor_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/new_intro/taylor-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/new_intro/Taylor-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/business/taylor_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/business/taylor_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/new_intro/taylor-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/taylor/new_intro/Taylor-business.png"
      }
    ]
  },
  {
    id: "657215e53bb58d9c67987e8f",
    code: "luke",
    name: "Luke",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 26,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/business/luke_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/business/luke_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/new_intro/luke-business.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/new_intro/Luke-business-1.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/casual/luke_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/casual/luke_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/new_intro/luke-casual.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/new_intro/Luke-casual.png"
      },
      {
        code: "business_2",
        id: "business_2",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/business2/luke_business_2.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/business2/luke_business_2.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/new_intro/luke-business-2.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/luke/new_intro/Luke-business-2.png"
      }
    ]
  },
  {
    id: "658432e7c10c114a3d352fef",
    code: "nancy",
    name: "Nancy",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 30,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/business/listening/Nancy_business_1.png",
          file: "s3://elai-avatars/common/nancy/business/listening/nancy_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/nancy/business/listening/nancy_business_listening_alpha.mp4"
        },
        tilt: {
          left: -0.02
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/business/nancy_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/business/nancy_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/new_intro/nancy-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/new_intro/Nancy-business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/casual/listening/Nancy_casual_1.png",
          file: "s3://elai-avatars/common/nancy/casual/listening/nancy_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/nancy/casual/listening/nancy_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/casual/nancy_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/casual/nancy_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/new_intro/nancy-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/nancy/new_intro/Nancy-casual.png"
      }
    ]
  },
  {
    id: "65a82c10c3563dc9c70cf295",
    code: "emilia",
    name: "Emilia",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 25,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/business/emilia_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/business/emilia_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/new_intro/emilia_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/new_intro/Emilia__business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/doctor/emilia_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/doctor/emilia_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/new_intro/emilia_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/emilia/new_intro/emilia_doctor.png"
      }
    ]
  },
  {
    id: "658ab558c10c114a3d48a286",
    code: "kevin",
    name: "Kevin",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 27,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/listening_casual/Kevin_casual_1.png",
          file: "s3://elai-avatars/common/kevin/listening_casual/kevin_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kevin/listening_casual/kevin_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/kevin_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/kevin_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/new_intro/kevin-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/new_intro/Kevin-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/business/listening_business/Kevin_business_1.png",
          file: "s3://elai-avatars/common/kevin/business/listening_business/kevin_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kevin/business/listening_business/kevin_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/business/kevin_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/business/kevin_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/new_intro/kevin-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/new_intro/Kevin-business.png"
      },
      {
        code: "construction",
        id: "construction",
        name: "Construction",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/const/listening_constr/Kevin-const.png",
          file: "s3://elai-avatars/common/kevin/const/listening_constr/kevin_const_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kevin/const/listening_constr/kevin_const_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/const/kevin_const.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/const/kevin_const.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/new_intro/kevin-construction_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kevin/new_intro/Kevin-constr.png"
      }
    ]
  },
  {
    id: "65842de5e17155d13149f0be",
    code: "michael",
    name: "Michael",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 37,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/casual/michael_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/casual/michael_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/new_intro/michael-casual.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/new_intro/Michael-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/business/michael_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/business/michael_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/new_intro/michael-business.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/new_intro/Michael-business.png"
      },
      {
        code: "construction",
        id: "construction",
        name: "Construction",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/constr/michael_constr.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/constr/michael_constr.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/new_intro/michael-construction.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/michael/new_intro/Michael-constr.png"
      }
    ]
  },
  {
    id: "6584305ab950b44a972d64e1",
    code: "lei",
    name: "Lei",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 30,
    ethnicity: "Asian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/lei_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/lei_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/new_intro/lei-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/new_intro/Lei-business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/casual/lei_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/casual/lei_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/new_intro/lei-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/lei/new_intro/Lei-casual.png"
      }
    ]
  },
  {
    id: "64d4c4301de1feeffaa956ad",
    code: "657802031273",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "male",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/rectangle-1220_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9yZWN0YW5nbGUtMTIyMF90aHVtYm5haWwuanBnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzIxNjA2NDAwfX19XX0_&Signature=oyU7FGblE5tKg3XEKhmfPBJk3vX5-3tkdtnt3WbprdpjssUILQ103OhC-nQB%7EwmLZGhGsP9jyVFAeKZcHfmyxf68k1RECN3CL-RyI3UGhEy2sBbrAAh-XNFHkOicmOSCfIqoiGFtj1zzRxJSaotpsHpOPssrhaOOKl5cbuMk46YA3%7E0q5KhQL85Pqa0ybrLlynmQ3kir2k28O6F7ROpHPpu5Gsesr8wDjQvUPogdALfKQvQAV2uqv3wBj76KZQVHHn9eOwDkdbBJM9HzJkkNvWlJoKqRPYt9EuVIJN67F9FL4l8AwicwD-h3R7-ir5YmwR10qwzJGGrxsy12gc37GQ__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/rectangle-1220.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9yZWN0YW5nbGUtMTIyMC5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=lrPCEASYHJRqd9KsIPzTQE9mTG5JldM9PQDK1OaXaUXEiz5BM9x4%7Egh2nb3qqeeamzzUQA7gaPoRcw-swZ791wS4F4fs7JTQTT3GYAQcjKx74LW1GiFMlQEyb91Gbl8UnequcKAu8XwBSB%7EM5If5CvJ0JrcXftjCsCqSr0%7ER9bduUncDEyqfQ9AWcpja7IMCI%7EtOqsV8guLdstlQNMefr0twciPov36FYcb6lQwfzhfthcrTGsJgZEFW6q5xxMnhXYZQZ19nTaojVhlkVbW2y7tiKDRG7W35u20be2WcIlKr7zMaS1QRldNrWoRa7QLt3mt4j36ZCL5tJ-HKvW1sWA__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-7.mp4",
    tilt: {
      top: 0,
      left: 0.03474520185307743,
      zoom: 1.0814030443414957
    },
    faceBbox: {
      x: 9,
      y: 0,
      faceWidth: 1511,
      faceHeight: 1441,
      faceShare: 0.8818849449204407
    },
    variants: []
  },
  {
    id: "64d4bcfcd82ddd2e1d21e3be",
    code: "388613879006",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "female",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_3d_render_business_woman_light_colors_potrait_1_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N18zZF9yZW5kZXJfYnVzaW5lc3Nfd29tYW5fbGlnaHRfY29sb3JzX3BvdHJhaXRfMV90aHVtYm5haWwuanBnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzIxNjA2NDAwfX19XX0_&Signature=pTp8XoJmu3pk1Y2M9%7Ei8ojHHHLIn4Zzwaydj1jyxafBfjL8NNmABZkxvkMw4obAaFElp1aEucO9h%7Eh8s27ZBpcZtey7sfk10AM-OPHh0sWE8wivmisPyTuijjNueNL8C2ZsRo%7E7XHiUuv-wDOtwnkqvsau0MA7o7UutWaNVgx4RyRozJTc3SBcabt9t2c4cQely0UnfW5KJ2xNWVTHk09JTyM4ydIwEMIuKp8f522w0pIvJ32RTfNWJ5H0icV6PgZFfuthEdo7O5mP5TPAZqJa3zrgJSdjXxxF2dQ7%7Ej9ofi6YTg23DRSpq0CGRRFIFdjgzRCN4wwUxmN0aBzVjZAQ__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_3d_render_business_woman_light_colors_potrait_1.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N18zZF9yZW5kZXJfYnVzaW5lc3Nfd29tYW5fbGlnaHRfY29sb3JzX3BvdHJhaXRfMS5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=tTLm0pn-bTbqTFFTomcB9UKlxs7ed%7E5Pj8UFOmbsHQe5oMzZPFsu4tT8fcsMoypCrdvbN4oaZXkjZHhsIcPk9Kg172p6xr6w-IargTnsy4uJzSf9CGzl6cMhZvubDi1kpGleGNEgUcdp9qSRteYd5RA9gGAGC3VDFRd%7Ew%7Ep75XGBD77iz2nknJ--WVjAUjKZt8Vp6sk2JPdkcrb%7EmxgRcHee3%7E4pfSnC7eN3XHi2ZI9htn4uaXiskBm3e-4t5b9izGVNKnUxAzHlqnN-76FZGUEJHMYKSFnVCeb8Y%7E1vhREvMEDZffrgXdKYTC8qNrDHMMIFAZitPqQbvj3DBY3nZw__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-9.mp4",
    tilt: {
      top: 0,
      left: 0.03092006033182504,
      zoom: 1.158371040723982
    },
    faceBbox: {
      x: 32,
      y: 0,
      faceWidth: 663,
      faceHeight: 620,
      faceShare: 0.60546875
    },
    variants: []
  },
  {
    id: "657216bb2593422a4c53063f",
    code: "leyla",
    name: "Leyla",
    type: null,
    status: 2,
    gender: "female",
    defaultVoice: "elevenlabs@Xb7hH8MSUJpSbSDYk0k2",
    tilt: {},
    age: 25,
    ethnicity: "Black",
    variants: [
      {
        code: "fitness",
        id: "fitness",
        name: "Fitness",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/leyla_fitness.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/leyla_fitness.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/new_intro/leyla_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/new_intro/Leyla-fitness.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/business/leyla_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/business/leyla_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/new_intro/leyla-business.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/leyla/new_intro/Leyla-business.png"
      }
    ]
  },
  {
    id: "63933d3e77915a436f6bdd4e",
    code: "aniqa",
    name: "Aniqa",
    type: null,
    status: 2,
    gender: "female",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/aniqa/aniqa_low.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/aniqa/aniqa_low.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/aniqa/new_intro/aniqa_1080.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/aniqa/new_intro/Aniqa.png",
    tilt: {},
    age: 28,
    ethnicity: "Middle Eastern",
    variants: []
  },
  {
    id: "658431b5b950b44a972d6d62",
    code: "megan",
    name: "Megan",
    type: null,
    status: 2,
    gender: "female",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/megan_intro_2.mp4",
    tilt: {},
    age: 30,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/megan_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/megan_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/megan-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/Megan-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/business/megan_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/business/megan_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/megan-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/Megan-business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/doctor/megan_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/doctor/megan_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/megan-doctor_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/Megan-doctor.png",
        tilt: {
          left: 0.05
        }
      }
    ]
  },
  {
    id: "65a8243f503912ebae8e3349",
    code: "dora",
    name: "Dora",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 31,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/business/dora_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/business/dora_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/business/new_intro/dora_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/business/new_intro/dora_business_new.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/casual/dora_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/casual/dora_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/casual/new_intro/dora_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/dora/casual/new_intro/dora.png"
      }
    ]
  },
  {
    id: "658aa1c549039534eff51cfd",
    code: "noah",
    name: "Noah",
    type: null,
    status: 2,
    gender: "male",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/avatars/custom/noah_hq.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/avatars/custom/noah_hq.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/new_intro/noah-business_1080.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/new_intro/Noah.png",
    tilt: {},
    age: 35,
    ethnicity: "Asian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/noah1/noah.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/noah1/noah.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/doctor_2_hq/noah_doctor_hq.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/doctor_2_hq/noah_doctor_hq.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/new_intro/noah-doctor_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/noah/new_intro/Noah-doctor.png"
      }
    ]
  },
  {
    id: "64d4db0dd82ddd2e1d21ec6b",
    code: "1110947882243",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "male",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_a_closeup_of_a_distinguished_professor_wearing_0_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N19hX2Nsb3NldXBfb2ZfYV9kaXN0aW5ndWlzaGVkX3Byb2Zlc3Nvcl93ZWFyaW5nXzBfdGh1bWJuYWlsLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=gvQwvJmJrVLQ%7En93qd2Bd8rUiBxWSWBWses%7EzQVY9iyCQ9qCGjPl%7Ecj2vgomQxFmQhKKsW2ws8Q0owccQGoMDsUKn%7EoHgu1fs1Ea1FstYn11p3-R4lfTlTI8rxieNsKtQgDvHoBNzBmMkX5zS8zqB5grIX3xHeWYZ44QoqsrRQ-mp4QSF3IiXduqrnENb2v-mAt%7E-%7EJfoJgl2eD6LUzEJ6CJuf05lYYZdsDryLuAr6ShJ3CPAXY33uVxszFBQeT7JNcHgQgv2Plv5pwHwjhwZn3ScwBvHQhcywjH9Ze-CJvHrSqt0ycqu39H3FMzzTQD8aL0AaxPxKIE-IR1M1p6mg__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_a_closeup_of_a_distinguished_professor_wearing_0.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N19hX2Nsb3NldXBfb2ZfYV9kaXN0aW5ndWlzaGVkX3Byb2Zlc3Nvcl93ZWFyaW5nXzAuanBnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzIxNjA2NDAwfX19XX0_&Signature=MR5NeWSyCTxNojmVPNoI%7E6cs-KggngJIzpAVaf2RfnPK03RCNpwi2ctgWFJLHb%7EQ5z5AbtZ9anAZWxf%7EdCoNeoFUvHyDXTK6z4ZYVxgNq8S9X4HqB2OZ2oVJobhsAiS2k-sx0NWjNGZZgTONQrJiZS%7EmdeivU%7En144SuiwG6RQOQW55Zs03THUpwL8OF1ERtPYY58Xeo1-bHSVTS7MlYb-F8UIO%7Em0Peys5CCUIlAloApTHtPZ4QKSiirviH9r6iPQz8B6uM9e0%7EqdfrXOvkCKDJ9hz3M2JghYWBjqQUQv0vn8MQGDLITIcvAK3RcGkl22HlF4KMVZft4zbdz-dRUA__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-8.mp4",
    tilt: {
      top: 0,
      left: 0.27121771217712176,
      zoom: 2.8339483394833946
    },
    faceBbox: {
      x: 175,
      y: 0,
      faceWidth: 271,
      faceHeight: 271,
      faceShare: 0.529296875
    },
    variants: []
  },
  {
    id: "6584340bb950b44a972d9d76",
    code: "aspen",
    name: "Aspen",
    type: null,
    status: 2,
    gender: "female",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/aspen_intro_2.mp4",
    tilt: {},
    age: 25,
    ethnicity: "Asian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/aspen_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/aspen_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/new_intro/aspen-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/new_intro/Aspen-business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/casual/aspen_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/casual/aspen_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/new_intro/aspen-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/aspen/new_intro/Aspen-casual.png"
      }
    ]
  },
  {
    id: "658aa4c7e17155d1315d6cdb",
    code: "margaret",
    name: "Margaret",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 50,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/business/margaret_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/business/margaret_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/new_intro/margaret-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/new_intro/Margaret-business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/doctor/margaret_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/doctor/margaret_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/new_intro/margaret-doctor_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/new_intro/Margaret-doctor.png"
      }
    ]
  },
  {
    id: "658a9ff7c10c114a3d4855d6",
    code: "tyler",
    name: "Tyler",
    type: null,
    status: 2,
    gender: "male",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/tyler_intro_2.mp4",
    tilt: {},
    age: 35,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/casual/tyler_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/casual/tyler_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/new_intro/tyler-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/new_intro/Tyler-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/business/tyler_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/business/tyler_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/new_intro/tyler-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/tyler/new_intro/Tyler-business.png"
      }
    ]
  },
  {
    id: "65a82924503912ebae8e46f0",
    code: "olivia",
    name: "Olivia ",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 38,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/casual/listening_casual/Olivia_casual_1.png",
          file: "s3://elai-avatars/common/olivia/casual/listening_casual/olivia_casual listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/olivia/casual/listening_casual/olivia_casual listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/casual/olivia_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/casual/olivia_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/new_intro/olivia-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/new_intro/Olivia-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/business/listening_business/Olivia_business_1.png",
          file: "s3://elai-avatars/common/olivia/business/listening_business/olivia_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/olivia/business/listening_business/olivia_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/business/olivia_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/business/olivia_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/new_intro/olivia-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/olivia/new_intro/Olivia-business.png"
      }
    ]
  },
  {
    id: "64d4c4b31de1feeffaa956df",
    code: "533713721819",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "female",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_a_mischievous_american_school_girl_with_a_backp_1_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N19hX21pc2NoaWV2b3VzX2FtZXJpY2FuX3NjaG9vbF9naXJsX3dpdGhfYV9iYWNrcF8xX3RodW1ibmFpbC5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=rthO%7EYa-KdausgQWwLeJK0vsXORfpvT1Ajn508fNaDi8nTfgsWUo2ZH6JOARe2yUkvG7jtexV%7E8a9%7Ed9B7hayXHAXNS4jALVlbIhsuRe2gEwqIzoKEhygX0a2S04t80hu37Vjtf99iHoSoxg3w8Ja-ky-01HCKV8osdwBY0DlQlURISxSThWGq2tWKpzIl2Es0TOF3RhXNlYEQRt%7EXdKzLQblPhHOGruPCLXsx0jtfhATeRvaoea-8MDt3CirRAS1t1uzwR-1XiU5Uu38e4%7E94F1Sv3TJ9V4gDax%7EdHdK42vNqoBpv8VzM7v8N7Np0%7EphroS2lUltUHMab6nyJ624w__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_a_mischievous_american_school_girl_with_a_backp_1.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N19hX21pc2NoaWV2b3VzX2FtZXJpY2FuX3NjaG9vbF9naXJsX3dpdGhfYV9iYWNrcF8xLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=cuzgVVaIVHIuLRo5ZbpnS5RthSRg%7EgI2BXxZYQ9MIRF3UJgFdjPYzWFqJUfBkqUXW232soCUTLM73GhLnnFfFydTKpu-5fDZpoks6Ovy57vm5tg4SCsFqteTO4i0AmYJr4lcCRxonXtvQfH0nEfeFFMtXE5EHlAybt3HqcKvw%7EzyB-%7EznUJ--smQkhp1CAEYymQJwsjCwXo0RyyJvLrSEDJeF7i4mwHKcd3ekSAIWRaDAXD-bAyYWU1G84QpSjl3Q6hlPSS4S0gtok48NNv66qPb6l320%7Enw47hBhh03a47nxlEHu6QkzT4kssUi2SlYyazgY6f6SEMefB2qmjbwag__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-11.mp4",
    tilt: {
      top: 0,
      left: 0.16719745222929935,
      zoom: 1.8343949044585988
    },
    faceBbox: {
      x: 157,
      y: 0,
      faceWidth: 628,
      faceHeight: 577,
      faceShare: 0.7513020833333334
    },
    variants: []
  },
  {
    id: "64d4d451d82ddd2e1d21e9d4",
    code: "496765495523",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "male",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_a_portrait_of_a_young_man_illuminated_by_a_warm_1_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N19hX3BvcnRyYWl0X29mX2FfeW91bmdfbWFuX2lsbHVtaW5hdGVkX2J5X2Ffd2FybV8xX3RodW1ibmFpbC5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=AAdxy3Uxq0v8QTWHhDquZj%7E8ljNe41nC21ZmgXKux0rp4%7EpDl0nkz%7E6rdeY552FnMwD9RBblxLjexJtSa%7EoUlqgNpmJ-ld7v66I04JbnL4Qu0lq6q8NaOrVl9BabacX9Sm7xZoeyn7pnr7Ba%7EgRDUOFtAWoviSRq6rHDLWJeRwLFO7zZ%7EkrCrdjDSdUT3rJHl9JWCzREQ0XPMtmtNq9MGPsJENziygFYN4VOGYtE8n9dRuTPZtrZLTX9PBL5He3FrEj80VBsYPQwK64IhR%7E%7EnqRKd9M8fMPf8aZbPC4Flpa7Wl6FtgFunkZAf5s1g3aHkU9%7EyWBvqQ4SA0wQem7ssA__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v7_a_portrait_of_a_young_man_illuminated_by_a_warm_1.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92N19hX3BvcnRyYWl0X29mX2FfeW91bmdfbWFuX2lsbHVtaW5hdGVkX2J5X2Ffd2FybV8xLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=nR%7ESC8KhqyLGkntn7AMcColVsuJpUCBd77PxsoR%7ECvZLbN41k8VIV5Oeqo8y7CM34yyfD78QGH-m9ttIYodZDP3pwX-Qd%7E8SyehtOx160wBbeEYHOj%7Etpis%7EEgvwhQ%7EDZomvBSwq4GzQh6Y18aHy3Sli5jJk90TnHC9R%7EekyGmk68nBwwcDaYcFYY7MyuwXcPlibfwHmYRhm11STfNMmcqeRFjz2deCW4V%7EgFDlT7BEdGLf9H46zviIlIoqyDlyV3Jj8%7EmsDwmjDg2OlbeP6V7gdk36Kab4q6FBwwbeGEGN7rUd3MQv%7Ej2aY3Msdhgien5gKEQTzseyJNZxOP1sEYQ__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-10.mp4",
    tilt: {
      top: 0,
      left: 0.05506607929515418,
      zoom: 1.6916299559471366
    },
    faceBbox: {
      x: 132,
      y: 0,
      faceWidth: 454,
      faceHeight: 429,
      faceShare: 0.837890625
    },
    variants: []
  },
  {
    id: "658ab50dc10c114a3d489f39",
    code: "alma",
    name: "Alma",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 32,
    ethnicity: "Black",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/casual/alma_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/casual/alma_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/new_intro/alma-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/new_intro/Alma-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/business/alma_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/business/alma_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/new_intro/alma-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/alma/new_intro/Alma-business.png"
      }
    ]
  },
  {
    id: "65842edcb950b44a972d5925",
    code: "ethan",
    name: "Ethan",
    type: null,
    status: 2,
    gender: "male",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/ethan/business/ethan_business_low.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/ethan/business/ethan_business_low.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/ethan/new_intro/ethan_1080.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/ethan/new_intro/Ethan.png",
    tilt: {
      left: -0.02
    },
    age: 35,
    ethnicity: "Black",
    variants: []
  },
  {
    id: "64d4c256d82ddd2e1d21e4f9",
    code: "42734972195",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "female",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v5_a_stunning_image_of_a_young_woman_in_a_light_bl_1-1_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92NV9hX3N0dW5uaW5nX2ltYWdlX29mX2FfeW91bmdfd29tYW5faW5fYV9saWdodF9ibF8xLTFfdGh1bWJuYWlsLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=sn7AKpz8D0BK12iudS4J%7Ewa5B8KHosVfqKuyGh4J7A8vJwXoZG1Vl9QPmpsTjcBJsidFs5FHljjUMivb8S7PrTokU%7EnzxYNhTdgyo6XkjbglHMbCNokRPUUybmgl1ALbsp8cyaWoqGnISuOo5ko9ZzcsX%7ET-serYCwB8CEJ1RUHvb5rnQ9WGj58i4sBoCHQE1UGTCN8bu2UaFIfDd04i7JOp0ZKaoq%7EpsSZWgiafE4WgEkOpHG5dxGgYkOeJ8lcpBSsPaPI0Aaoww2X12jMyH6Bgu%7Ej0S0IARSVLnJHym9GQekaPHpDSskdqO7jX6Ks%7EaXlvA2zDmSHrc6J4HSjRSw__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/dreamshaper_v5_a_stunning_image_of_a_young_woman_in_a_light_bl_1-1.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9kcmVhbXNoYXBlcl92NV9hX3N0dW5uaW5nX2ltYWdlX29mX2FfeW91bmdfd29tYW5faW5fYV9saWdodF9ibF8xLTEuanBnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzIxNjA2NDAwfX19XX0_&Signature=G9w8qA-pwvnJ0%7ExPiIRxp1Z2%7E2MRu74ZNRSIAszqQphk7kMvSa1Gw-ZR7AnW%7E2aFdte%7ESHb6Y4eSPMqY70i9vXxJU4wWkpMdfnNX7uLwuBAakpfWLdMS8dC6G%7E7Yi1eSO3dK0ZUN5vE0Bta%7E7aXLOv-QHN8SRs2kjozBrd-c7xuBF0DoDw%7E68s8YBMyuIG5QGgKXXbRaARxaK9KvDbzA6%7EXLOBP3NB-MmNU0PuFB2VDP2lXLHYb8Xzml1DCwtt4QpzSOT7X1YVWGyU7XDC0EdGKfvk50TrODzlo31aLqvcxW7CdDVSvbwVUKmKE8W2vdg8IBaUyXYNUorfX-2BTgXw__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-12.mp4",
    tilt: {
      top: 0,
      left: -0.025451559934318555,
      zoom: 1.0509031198686372
    },
    faceBbox: {
      x: 31,
      y: 0,
      faceWidth: 609,
      faceHeight: 667,
      faceShare: 0.8016826923076923
    },
    variants: []
  },
  {
    id: "658aa457b950b44a974944ed",
    code: "brooke",
    name: "Brooke",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 25,
    ethnicity: "Black",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/brooke_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/brooke_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/new_intro/brooke_business.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/new_intro/brooke_business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/casual/brooke_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/casual/brooke_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/new_intro/brooke_casual.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/brooke/new_intro/brooke_casual.png"
      }
    ]
  },
  {
    id: "635a53661089f51294f0368b",
    code: "jade",
    name: "Jade",
    type: null,
    status: 2,
    gender: "female",
    thumbnail: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-footage/jade_jade_.silent_09.12.jpg",
    canvas: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-footage/jade_jade_silent_look_modified_hq_low_1.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/jade/new_intro/jade_intro_2.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/jade/new_intro/Jade.png",
    tilt: {},
    age: 23,
    ethnicity: "White / Caucasian",
    variants: []
  },
  {
    id: "64d4f96a6a67cfe9cefbb12e",
    code: "1539984167115",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "female",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/tamara-bellis-edvqwvmlmgu-unsplash_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy90YW1hcmEtYmVsbGlzLWVkdnF3dm1sbWd1LXVuc3BsYXNoX3RodW1ibmFpbC5qcGciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MjE2MDY0MDB9fX1dfQ__&Signature=D-RHepDEsBb3mLEk16bngbrWO2OT0eextsPTpxvHqpZDP2wNXOHo91p1Wj9C5PYmbwPWQmc1R26438kmW6K2O17K%7EEaVSRDBWHvp88pVXMMw-ci0Wqmdutm-WVEJ9VWzlye8Gx9RLdT9XjXrO1ARr9qfO9RRCT68ngRqOACrxTK21zxPsD%7ElPwYWVaxK6B6zKrRREAO%7E4zjSZfIPEaMnAXDcb%7EJTq8KeRBzRpfkbRTjDFRUKXRYkTs3JOFTuNo-xVryDB8x3D%7EvPWz8vdbfR5aFlHeuHDjkrN1ikA6k5EEHdXYW49P53CO0UkHFre8CjdkSAosEAQGA2Gmq-93lRWQ__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/tamara-bellis-edvqwvmlmgu-unsplash.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy90YW1hcmEtYmVsbGlzLWVkdnF3dm1sbWd1LXVuc3BsYXNoLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=RJTnAnIEVMSS-1%7E-UM0gGG4U9XUgEdhvKlqNxjXUwJscro8BWUaBm2Dm-kzbj7vJ%7EwyyjshS6e9uHskQnWK1%7Eu7ErUPhBBq4Zj7BrD3SSIP75JfjumJp5so7IfIwlfk4v6Hny0ju1dXeZliRV41NAKhl0eoTeAcgoH7TQTpyWgrkSbrgp0BGXhD8XhpJklO9FU12cg9RKVpRxwWG%7EHlmQRpHyy9Np0ysm5f9GWBEzOdNFyZ0aVJMiCmsHcmx5hH34UukzknIMl2cd7cIbMioJZOLDzfVq6Ey9-l0qhX0ADRf1zUDx664RfdXSFaxnc-CmwycXq93uhtREcYIyG7TgQ__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-2.mp4",
    tilt: {
      top: -0.08703535811423391,
      left: -0.0022665457842248413,
      zoom: 1.3055303717135087
    },
    faceBbox: {
      x: 171,
      y: 96,
      faceWidth: 1103,
      faceHeight: 1103,
      faceShare: 0.5106481481481482
    },
    variants: []
  },
  {
    id: "6682920c2f7c721b42c9e2a8",
    code: "gia_realtime_smooth",
    name: "Gia Seamless",
    type: null,
    status: 2,
    gender: "female",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.png",
    defaultVoice: "elevenlabs@EXAVITQu4vr4xnSDxMaL",
    tilt: {},
    variants: []
  },
  {
    id: "65a6bb9a10f5995884e55535",
    code: "skye",
    name: "Skye",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 27,
    ethnicity: "Black",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/skye_casual_2.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/skye_casual_2.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/new_intro/skye_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/new_intro/Skye_casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/business/skye_business_2.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/business/skye_business_2.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/new_intro/skye-business.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/skye_2/new_intro/Skye_business.png"
      }
    ]
  },
  {
    id: "65a6c08f5eeacacfb01ebae8",
    code: "amira",
    name: "Amira",
    type: null,
    status: 2,
    gender: "female",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/amira/hq/new_intro/amira__1080.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/amira/hq/new_intro/Amira.png",
    tilt: {},
    age: 29,
    ethnicity: "South Asian / Indian",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/amira/hq/amira_1.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/amira/hq/amira.png"
      }
    ]
  },
  {
    id: "64d4fb2d6e7e7e7f8cc0dbff",
    code: "40937680419",
    type: "photo",
    status: 2,
    accountId: null,
    gender: "male",
    thumbnail:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/stephanie-nakagawa-adskin0scdg-unsplash_thumbnail.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9zdGVwaGFuaWUtbmFrYWdhd2EtYWRza2luMHNjZGctdW5zcGxhc2hfdGh1bWJuYWlsLmpwZyIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTYwNjQwMH19fV19&Signature=cZE6VEPeLpdhtK1bO%7EHqD361Zy%7EoJaQgS8iy%7EENwyknZkpt8Bt9v7qzZlyOqMsDH5Mu9LOaX2NX-epcNZPHJaxXUwrFcJrrQ7cfTW33rc6Crok5-2J60UE-cSl4Q1zilk7Wj-5LbHjvKxtrDJy8ZOJHpDtwZjlkuzI4u8xokmtQUeePL2qf7RB%7ELMlTSx4ljr0wyb1u-Ze6MIFCO19oG%7ExDqdQO9cgn-dPRc3lJTUwiKaRyi3%7Ez7BAs6epGx23V6ENHU6MV%7EMp51nZ3b2aPusZdEJwtA4AvPs5ZHdMGvB57c2fc25-x28yLTcbZpBeBPGMRKLGnismEqjCks508new__&Key-Pair-Id=K1Y7U91AR6T7E5",
    canvas:
      "https://d3u63mhbhkevz8.cloudfront.net/staging/avatar_uploads/63105155b5c45d0e07492bfc/stephanie-nakagawa-adskin0scdg-unsplash.jpg?Expires=1721606400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kM3U2M21oYmhrZXZ6OC5jbG91ZGZyb250Lm5ldC9zdGFnaW5nL2F2YXRhcl91cGxvYWRzLzYzMTA1MTU1YjVjNDVkMGUwNzQ5MmJmYy9zdGVwaGFuaWUtbmFrYWdhd2EtYWRza2luMHNjZGctdW5zcGxhc2guanBnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzIxNjA2NDAwfX19XX0_&Signature=U5sAL-g2215W3qKCZo80XuVNOwGk%7EU6CyfD6Xu9Ynh0YLen6aUnrYbYhFpf-T%7EXNyZbFJTQeyXmmdfItjSTHVfU6b%7EGcnvLCkrNSbHhPFPEqj7QdC5A2Okdn8Pr2DcMyE9K-tfmjZe%7Eev2fYPVkSVOh3KLiVQHlgJ0UdArXt7Dqn7EtWR4hGazVpK687CbcRzOQ5ldhhCVfr4cYfbbxmYh79itINc75UijIzTA9pxtI1irIqNODtIybuqNMPkm2hvSBnL1X7cD5CTImsxL7r7fxqlinYqTfE9HjQKGRNySSVy68dctPSWFApI3nmJFeNq1KAnfKH76KM0enNQMM9Xw__&Key-Pair-Id=K1Y7U91AR6T7E5",
    intro: "https://elai-media.s3.eu-west-2.amazonaws.com/avatars-examples/photo-avatar-1.mp4",
    tilt: {
      top: -0.07985480943738657,
      left: 0.03901996370235935,
      zoom: 2.613430127041742
    },
    faceBbox: {
      x: 423,
      y: 44,
      faceWidth: 551,
      faceHeight: 551,
      faceShare: 0.2550925925925926
    },
    variants: []
  },
  {
    id: "65a82b86867a43f28b56987d",
    code: "terry",
    name: "Terry",
    type: null,
    status: 2,
    gender: "male",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/terry/terry_main.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/terry/terry_main_3_1.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/terry/new_intro/terry.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/terry/new_intro/Terry.png",
    tilt: {},
    age: 25,
    ethnicity: "White / Caucasian",
    variants: []
  },
  {
    id: "6572152d3bb58d9c679878fa",
    code: "rose",
    name: "Rose",
    type: null,
    status: 2,
    gender: "female",
    thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/rose/rose.jpg",
    canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/rose/rose.png",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/rose/new_intro/rose_1080.mp4",
    introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/rose/new_intro/Rose.png",
    tilt: {},
    age: 33,
    ethnicity: "White / Caucasian",
    variants: []
  },
  {
    id: "62f2402eb5faf6fb70be3992",
    code: "elai-cartoon",
    name: "Cartoon Cat",
    type: "mascot",
    status: 2,
    accountId: null,
    gender: "male",
    tilt: {},
    variants: [
      {
        code: "elai_regular_anim",
        id: "elai_regular_anim",
        name: "Standing",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/regular/regular_1.png",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/regular/regular.jpg"
      },
      {
        code: "elai_business_anim",
        id: "elai_business_anim",
        name: "Business",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/business/business.png",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/business/business.png"
      },
      {
        code: "elai_basketball_anim",
        id: "elai_basketball_anim",
        name: "Basketball",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/basketball/basketball.png",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/basketball/basketball.png"
      },
      {
        code: "elai_superhero_anim",
        id: "elai_superhero_anim",
        name: "Superhero",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/superhero/superhero.png",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/superhero/superhero.png"
      },
      {
        code: "elai_skater",
        id: "elai_skater",
        name: "Skater",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/skater/skater.png",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/cartoons/elai/skater/skater.png"
      }
    ]
  }
];

export const elaiApprovedAvatarsList = [
  {
    id: "65841fc2c10c114a3d348cf7",
    code: "gia",
    name: "Gia",
    type: null,
    status: 2,
    gender: "female",
    limit: 300,
    defaultVoice: "elevenlabs@EXAVITQu4vr4xnSDxMaL",
    tilt: {},
    age: 32,
    ethnicity: "Black",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/business/listening/Gia_business_1.png",
          file: "s3://elai-avatars/common/gia/business/listening/gia_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/gia/business/listening/gia_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/business/gia_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/business/gia_business.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/gia_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/Gia_business.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.png",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/listening/Gia_casual_1.png",
          file: "s3://elai-avatars/common/gia/casual/listening/gia_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/gia/casual/listening/gia_casual_listening_alpha.mp4"
        },
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/gia_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/gia/new_intro/Gia_casual.png"
      }
    ]
  },
  {
    id: "65841e95b950b44a972ce953",
    code: "max",
    name: "Max",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    defaultVoice: "elevenlabs@TX3LPaxmHKxFdv7VOQHJ",
    tilt: {},
    age: 29,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/listening_business/Max_business_1.png",
          file: "s3://elai-avatars/common/max/listening_business/max_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/max/listening_business/max_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/max/max_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/max_business.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/doctor/listening_doctor/Max_doctor_1.png",
          file: "s3://elai-avatars/common/max/doctor/listening_doctor/max_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/max/doctor/listening_doctor/max_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/max/doctor/max_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/max/doctor/max_doctor.png",
        tilt: { left: 0.02 },
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png"
      }
    ]
  },
  {
    id: "65841d5ae17155d131497677",
    code: "enzo",
    name: "Enzo",
    type: null,
    status: 2,
    gender: "male",
    limit: 300,
    tilt: {},
    age: 40,
    ethnicity: "Black",
    variants: [
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/listening/Enzo_doctor_1.png",
          file: "s3://elai-avatars/common/enzo/listening/enzo_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/enzo/listening/enzo_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/enzo_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/enzo_doctor..png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/enzo_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/Enzo-doctor.png"
      },
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/casual/listening/Enzo_casual_1.png",
          file: "s3://elai-avatars/common/enzo/casual/listening/enzo_casual_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/enzo/casual/listening/enzo_casual_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/casual/enzo_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/casual/enzo_casual.png",
        limit: 300,
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/enzo_casual_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/enzo/new_intro/Enzo-casual.png"
      }
    ]
  },
  {
    id: "65a7ed54503912ebae8cd889",
    code: "cody",
    name: "Cody",
    type: null,
    status: 2,
    gender: "male",
    tilt: {},
    age: 35,
    ethnicity: "Black",
    variants: [
      {
        code: "casual",
        id: "casual",
        name: "Casual",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/casual/cody_casual.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/casual/cody_casual.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/cody-casual_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/Cody-casual.png"
      },
      {
        code: "business",
        id: "business",
        name: "Business",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/business/cody_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/business/cody_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/cody-business_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/cody/new_intro/Cody-business.png"
      }
    ]
  },
  {
    id: "6584149db950b44a972caa3b",
    code: "kira",
    name: "Kira",
    type: null,
    status: 2,
    gender: "female",
    tilt: { left: 0.05 },
    age: 25,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "business",
        id: "business",
        name: "Business",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/listening_business/Kira_business_1.png",
          file: "s3://elai-avatars/common/kira/listening_business/kira_business_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kira/listening_business/kira_business_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/kira_business.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/kira_business.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira_business_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-business.png"
      },
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        listeningAvatar: {
          canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/listening_doctor/Kira_doctor_1.png",
          file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening.mp4",
          alpha_video_file: "s3://elai-avatars/common/kira/doctor/listening_doctor/kira_doctor_listening_alpha.mp4"
        },
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/doctor/kira_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira_doctor_new.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png"
      }
    ]
  },
  {
    id: "658431b5b950b44a972d6d62",
    code: "megan",
    name: "Megan",
    type: null,
    status: 2,
    gender: "female",
    intro: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/megan_intro_2.mp4",
    tilt: {},
    age: 30,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/doctor/megan_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/doctor/megan_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/megan-doctor_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/megan/new_intro/Megan-doctor.png",
        tilt: { left: 0.05 }
      }
    ]
  },
  {
    id: "658aa4c7e17155d1315d6cdb",
    code: "margaret",
    name: "Margaret",
    type: null,
    status: 2,
    gender: "female",
    tilt: {},
    age: 50,
    ethnicity: "White / Caucasian",
    variants: [
      {
        code: "doctor",
        id: "doctor",
        name: "Doctor",
        thumbnail: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/doctor/margaret_doctor.jpg",
        canvas: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/doctor/margaret_doctor.png",
        intro: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/new_intro/margaret-doctor_1080.mp4",
        introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/margaret/new_intro/Margaret-doctor.png"
      }
    ]
  }
];

export const elaiVoicesList = [
  {
    name: "English",
    male: [
      {
        voiceProvider: "azure",
        character: "Andrew M",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AndrewMultilingualNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-andrewmultilingualneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Tony",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-TonyNeural",
        icon: "us",
        styleList: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
        defaultStyle: "friendly",
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:cheerful-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:friendly-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:hopeful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:sad-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:shouting-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:terrified-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural:whispering-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-tonyneural-en-us.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Davis",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-DavisNeural",
        icon: "us",
        styleList: ["chat", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
        defaultStyle: "friendly",
        stylePreview: {
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:chat-en-us.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:cheerful-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:friendly-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:hopeful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:sad-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:shouting-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:terrified-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural:whispering-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-davisneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Jason",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-JasonNeural",
        icon: "us",
        styleList: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
        defaultStyle: "friendly",
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:cheerful-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:friendly-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:hopeful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:sad-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:shouting-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:terrified-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural:whispering-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jasonneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Ryan",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-RyanNeural",
        icon: "gb",
        styleList: ["cheerful", "chat"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-ryanneural:cheerful-en-us.mp3",
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-ryanneural:chat-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-ryanneural-en-us.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Brian M",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-BrianMultilingualNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-brianmultilingualneural-en-us.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Wade",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "30",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-30-en-us.mp3"
          },
          {
            id: "26",
            name: "Promo",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-26-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Lee",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        playedTags: [
          {
            id: "23",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_lee.m._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Paul",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "41",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_paul.b._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Jeremy",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "13",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_jeremy.g._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Tobin",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "16",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_tobin.a._narration_fast-paced.wav"
          }
        ],
        approxDurationCoeficient: 23
      },
      {
        character: "Zach",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "54",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_zach.e._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Tristan",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "18",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_tristan.f._narration_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Theo",
        name: "English (GB)",
        locale: "en-GB",
        icon: "gb",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "57",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_theo.k._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Steve",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "37",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_steve.b._promo_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 22
      },
      {
        character: "Se'von",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "105",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-105-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 28
      },
      {
        character: "Raine",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "52",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_raine.b._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Philip",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "middle-aged"],
        playedTags: [
          {
            id: "60",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_philip.j._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Patrick",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "middle-aged"],
        playedTags: [
          {
            id: "19",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_patrick.k._narration_calm.wav"
          },
          {
            id: "47",
            name: "conversational",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_patrick.k._conversational_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Owen",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "middle-aged"],
        playedTags: [
          {
            id: "53",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_owen.c._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Oliver",
        name: "English (GB)",
        locale: "en-GB",
        icon: "gb",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "middle-aged"],
        playedTags: [
          {
            id: "98",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-98-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Michael",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "76",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-76-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Marcus",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "61",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_marcus.g._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Lulu",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "101",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-101-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Lorenzo",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "middle-aged"],
        playedTags: [
          {
            id: "100",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-100-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Kai",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "fast-paced", "young"],
        playedTags: [
          {
            id: "32",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_kai.m._narration_upbeat.wav"
          },
          {
            id: "44",
            name: "conversational",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_kai.m._conversational_fast-paced.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Jude",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "33",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_jude.d._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 23
      },
      {
        character: "Joe",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "character", "middle-aged"],
        playedTags: [
          {
            id: "27",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_joe.f._narration_calm.wav"
          },
          {
            id: "28",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_joe.f._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Jimmy",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "106",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-106-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Jensen",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "96",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-96-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Jay",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "107",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-107-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 21
      },
      {
        character: "Jarvis",
        name: "English (GB)",
        locale: "en-GB",
        icon: "gb",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "56",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_jarvis.h._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "James",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "58",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_james.b._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Jack",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "97",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-97-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Greg",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "66",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_greg.g._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Garry",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "old"],
        playedTags: [
          {
            id: "29",
            name: "character",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_garry.j._character_slow-paced_elderly.wav"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Eric",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "34",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_eric.s._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Diarmid",
        name: "English (Australia)",
        locale: "en-AU",
        icon: "au",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "middle-aged"],
        playedTags: [
          {
            id: "69",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_diarmid.c._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 15
      },
      {
        character: "Damian",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        playedTags: [
          {
            id: "21",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_damien.p._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Chase",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "35",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_chase.j._narration_fast-paced.wav"
          }
        ],
        approxDurationCoeficient: 27
      },
      {
        character: "Ben",
        name: "English (South Africa)",
        locale: "en-ZA",
        icon: "za",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "137",
            name: "Conversational",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-137-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 22
      },
      {
        character: "Antony",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "middle-aged"],
        playedTags: [
          {
            id: "50",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_anthony.a._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Alan",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "71",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_alan.t._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Aaron",
        name: "English (Australia)",
        locale: "en-AU",
        icon: "au",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "middle-aged"],
        playedTags: [
          {
            id: "112",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-112-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 15
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/ee9ac367-91ee-4a56-818a-2bd1a9dbe83a.mp3",
        order: 1,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
        order: 2,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/316050b7-c4e0-48de-acf9-a882bb7fc43b.mp3",
        order: 3,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 21
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/38a69695-2ca9-4b9e-b9ec-f07ced494a58.mp3",
        order: 4,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/52f0842a-cf81-4715-8cf0-76cfbd77088e.mp3",
        order: 5,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/t0jbNlBVZ17f02VDIeMI/e26939e3-61a4-4872-a41d-33922cfbdcdc.mp3",
        order: 6,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/flq6f7yk4E4fJM5XTYuZ/c6431a82-f7d2-4905-b8a4-a631960633d6.mp3",
        order: 7,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3",
        order: 8,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 21
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/SOYHLrjzK2X1ezoPC6cr/86d178f6-f4b6-4e0e-85be-3de19f490794.mp3",
        order: 9,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/ODq5zmih8GrVes37Dizd/0ebec87a-2569-4976-9ea5-0170854411a9.mp3",
        order: 10,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 20
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/GBv7mTt0atIp3Br8iCZE/98542988-5267-4148-9a9e-baa8c4f14644.mp3",
        order: 11,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/29vD33N1CtxCmqQRPOHJ/e8b52a3f-9732-440f-b78a-16d5e26407a1.mp3",
        order: 12,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/5Q0t7uMcjvnagumLfvZi/1094515a-b080-4282-aac7-b1b8a553a3a8.mp3",
        order: 13,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 18
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3",
        order: 14,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/zcAOhNBS3c14rBihAFp1/e7410f8f-4913-4cb8-8907-784abee5aff8.mp3",
        order: 15,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 18
      },
      {
        character: "Ethan",
        voice: "g5CIjZEefAph4nQFvHAz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["whisper", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/g5CIjZEefAph4nQFvHAz/26acfa99-fdec-43b8-b2ee-e49e75a3ac16.mp3",
        order: 16,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/2EiwWnXFnvU5JabPnv8n/65d80f52-703f-4cae-a91d-75d4e200ed02.mp3",
        order: 17,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 18
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/365e8ae8-5364-4b07-9a3b-1bfb4a390248.mp3",
        order: 19,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 21
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/CYw3kZ02Hs0563khs1Fj/872cb056-45d3-419e-b5c6-de2b387a93a0.mp3",
        order: 20,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 18
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/Zlb1dXrM653N07WRdFW3/daa22039-8b09-4c65-b59f-c79c48646a72.mp3",
        order: 21,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 18
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3",
        order: 22,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 19
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
        order: 23,
        name: "English (United States)",
        locale: "en-US",
        icon: "au",
        approxDurationCoeficient: 18
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/ZQe5CZNOzWyzPSCn5a3c/35734112-7b72-48df-bc2f-64d5ab2f791b.mp3",
        order: 24,
        name: "English (United States)",
        locale: "en-US",
        icon: "au",
        approxDurationCoeficient: 17
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/D38z5RcWu1voky8WS1ja/a470ba64-1e72-46d9-ba9d-030c4155e2d2.mp3",
        order: 25,
        name: "English (United States)",
        locale: "en-US",
        icon: "ie",
        approxDurationCoeficient: 23
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/bVMeCyTHy58xNoL34h3p/66c47d58-26fd-4b30-8a06-07952116a72c.mp3",
        order: 26,
        name: "English (United States)",
        locale: "en-US",
        icon: "ie",
        approxDurationCoeficient: 19
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/c1bda571-7123-418e-a796-a2b464b373b4.mp3",
        order: 99999,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 24
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/f4dbda0c-aff0-45c0-93fa-f5d5ec95a2eb.mp3",
        order: 99999,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 20
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/ac9d1c91-92ce-4b20-8cc2-3187a7da49ec.mp3",
        order: 99999,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "William",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-WilliamNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-williamneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Darren",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-DarrenNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-darrenneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Duncan",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-DuncanNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-duncanneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Ken",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-KenNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-kenneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Neil",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-NeilNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-neilneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Tim",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-TimNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-timneural-en-us.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Liam",
        name: "English (Canada)",
        locale: "en-CA",
        voice: "en-CA-LiamNeural",
        icon: "ca",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ca-liamneural-en-us.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Alfie",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-AlfieNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-alfieneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Elliot",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-ElliotNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-elliotneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Ethan",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-EthanNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-ethanneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Noah",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-NoahNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-noahneural-en-us.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Oliver",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-OliverNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-oliverneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Thomas",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-ThomasNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-thomasneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Sam",
        name: "English (Hong Kong SAR)",
        locale: "en-HK",
        voice: "en-HK-SamNeural",
        icon: "hk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-hk-samneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Connor",
        name: "English (Ireland)",
        locale: "en-IE",
        voice: "en-IE-ConnorNeural",
        icon: "ie",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ie-connorneural-en-us.mp3",
        approxDurationCoeficient: 29
      },
      {
        voiceProvider: "azure",
        character: "Prabhat",
        name: "English (India)",
        locale: "en-IN",
        voice: "en-IN-PrabhatNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-in-prabhatneural-en-us.mp3",
        approxDurationCoeficient: 26
      },
      {
        voiceProvider: "azure",
        character: "Chilemba",
        name: "English (Kenya)",
        locale: "en-KE",
        voice: "en-KE-ChilembaNeural",
        icon: "ke",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ke-chilembaneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Abeo",
        name: "English (Nigeria)",
        locale: "en-NG",
        voice: "en-NG-AbeoNeural",
        icon: "ng",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ng-abeoneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Mitchell",
        name: "English (New Zealand)",
        locale: "en-NZ",
        voice: "en-NZ-MitchellNeural",
        icon: "nz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-nz-mitchellneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "James",
        name: "English (Philippines)",
        locale: "en-PH",
        voice: "en-PH-JamesNeural",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ph-jamesneural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Wayne",
        name: "English (Singapore)",
        locale: "en-SG",
        voice: "en-SG-WayneNeural",
        icon: "sg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-sg-wayneneural-en-us.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Elimu",
        name: "English (Tanzania)",
        locale: "en-TZ",
        voice: "en-TZ-ElimuNeural",
        icon: "tz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-tz-elimuneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Andrew",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AndrewNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-andrewneural-en-us.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Brian",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-BrianNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-brianneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Guy",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-GuyNeural",
        icon: "us",
        styleList: ["newscast", "angry", "cheerful", "sad", "excited", "friendly", "terrified", "shouting", "unfriendly", "whispering", "hopeful"],
        defaultStyle: "friendly",
        stylePreview: {
          newscast: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:newscast-en-us.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:cheerful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:sad-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:friendly-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:terrified-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:shouting-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:whispering-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural:hopeful-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-guyneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Brandon",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-BrandonNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-brandonneural-en-us.mp3",
        approxDurationCoeficient: 28
      },
      {
        voiceProvider: "azure",
        character: "Christopher",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-ChristopherNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-christopherneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Eric",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-EricNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-ericneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Jacob",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-JacobNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jacobneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Roger",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-RogerNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-rogerneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Ryan M",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-RyanMultilingualNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-ryanmultilingualneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Steffan",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-SteffanNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-steffanneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Luke",
        name: "English (South Africa)",
        locale: "en-ZA",
        voice: "en-ZA-LukeNeural",
        icon: "za",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-za-lukeneural-en-us.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Oscar K",
        name: "English-US",
        voice: "en-US-Casual-K",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-casual-k-en-us.mp3"
      },
      {
        character: "Miles B",
        name: "English-AU",
        voice: "en-AU-Neural2-B",
        icon: "au",
        voiceProvider: "google",
        locale: "en-AU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-au-neural2-b-en-us.mp3"
      },
      {
        character: "James D",
        name: "English-AU",
        voice: "en-AU-Neural2-D",
        icon: "au",
        voiceProvider: "google",
        locale: "en-AU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-au-neural2-d-en-us.mp3"
      },
      {
        character: "Emmet B",
        name: "English-GB",
        voice: "en-GB-Neural2-B",
        icon: "gb",
        voiceProvider: "google",
        locale: "en-GB",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-gb-neural2-b-en-us.mp3"
      },
      {
        character: "Jack D",
        name: "English-GB",
        voice: "en-GB-Neural2-D",
        icon: "gb",
        voiceProvider: "google",
        locale: "en-GB",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-gb-neural2-d-en-us.mp3"
      },
      {
        character: "Cole B",
        name: "English-IN",
        voice: "en-IN-Neural2-B",
        icon: "in",
        voiceProvider: "google",
        locale: "en-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-in-neural2-b-en-us.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Ralph C",
        name: "English-IN",
        voice: "en-IN-Neural2-C",
        icon: "in",
        voiceProvider: "google",
        locale: "en-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-in-neural2-c-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Joe A",
        name: "English-US",
        voice: "en-US-Neural2-A",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-a-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Hayden D",
        name: "English-US",
        voice: "en-US-Neural2-D",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-d-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Oscar I",
        name: "English-US",
        voice: "en-US-Neural2-I",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-i-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Miles J",
        name: "English-US",
        voice: "en-US-Neural2-J",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-j-en-us.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Jane",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-JaneNeural",
        icon: "us",
        styleList: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
        defaultStyle: "friendly",
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:cheerful-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:friendly-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:hopeful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:sad-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:shouting-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:terrified-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural:whispering-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-janeneural-en-us.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Emma",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-EmmaNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-emmaneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Nancy",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-NancyNeural",
        icon: "us",
        styleList: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
        defaultStyle: "friendly",
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:cheerful-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:friendly-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:hopeful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:sad-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:shouting-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:terrified-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural:whispering-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-nancyneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Sara",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-SaraNeural",
        icon: "us",
        styleList: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
        defaultStyle: "friendly",
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:cheerful-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:friendly-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:hopeful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:sad-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:shouting-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:terrified-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural:whispering-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-saraneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Joanne",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-JoanneNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-joanneneural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Sonia",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-SoniaNeural",
        icon: "gb",
        styleList: ["cheerful", "sad"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-sonianeural:cheerful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-sonianeural:sad-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-sonianeural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Ava",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "upbeat", "young"],
        playedTags: [
          {
            id: "72",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_ava.m._promo_upbeat.wav"
          },
          {
            id: "31",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_ava.m._narration_upbeat.wav"
          },
          {
            id: "43",
            name: "conversational",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_ava.m._conversational_fast-paced.wav"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Alana",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "3",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_alana.b._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Nicole",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "14",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_nicole.l._narration_calm.wav"
          },
          {
            id: "45",
            name: "conversational",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_nicole.l._conversational_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Sofia",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "upbeat", "calm", "young"],
        playedTags: [
          {
            id: "20",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_sofia.h._promo_upbeat.wav"
          },
          {
            id: "8",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_sofia.h._narration_slow-paced.wav"
          },
          {
            id: "42",
            name: "conversational",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_sofia.h._conversational_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Vanessa",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "calm", "young"],
        playedTags: [
          {
            id: "10",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_vanessa.n._narration_slow-paced_elderly.wav"
          },
          {
            id: "48",
            name: "conversational",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_vanessa.n._conversational_calm_elderly.wav"
          }
        ],
        approxDurationCoeficient: 15
      },
      {
        character: "Zoey",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "67",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_zoey.o._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 15
      },
      {
        character: "Tilda",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "39",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_tilda.c._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 24
      },
      {
        character: "Terra",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "59",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_terra.g._narration_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Shelby",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "104",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-104-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 26
      },
      {
        character: "Selene",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "24",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_selene.r._promo_fast-paced.wav"
          }
        ],
        approxDurationCoeficient: 22
      },
      {
        character: "Ramona",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "upbeat", "young"],
        playedTags: [
          {
            id: "4",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_ramona.j._narration_slow-paced.wav"
          },
          {
            id: "5",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_ramona.j._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Paula",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "78",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-78-en-us.mp3"
          },
          {
            id: "129",
            name: "Promo",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-129-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Paige",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast-paced", "young"],
        playedTags: [
          {
            id: "15",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_paige.l._narration_fast-paced.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Kari",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "middle-aged"],
        playedTags: [
          {
            id: "68",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_kari.n._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Jordan",
        name: "English (Australia)",
        locale: "en-AU",
        icon: "au",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "62",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_jordan.t._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Jodi",
        name: "English (Canada)",
        locale: "en-CA",
        icon: "ca",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "upbeat", "young"],
        playedTags: [
          {
            id: "22",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_jodi.p._promo_upbeat.wav"
          },
          {
            id: "51",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_jodi.p._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Issa",
        name: "English (South Africa)",
        locale: "en-ZA",
        icon: "za",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "109",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-109-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Isabel",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "middle-aged"],
        playedTags: [
          {
            id: "11",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_isabel.b._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Hannah",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["fast", "young"],
        playedTags: [
          {
            id: "99",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-99-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 21
      },
      {
        character: "Gia",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "49",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_gia.w._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Genevieve",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "55",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_genevieve.m._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 18
      },
      {
        character: "Fiona",
        name: "English (United Kingdom)",
        locale: "en-GB",
        icon: "gb",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "63",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_fiona.h._narration_calm.wav"
          }
        ],
        approxDurationCoeficient: 17
      },
      {
        character: "Donna",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["slow-paced", "young"],
        playedTags: [
          {
            id: "65",
            name: "narration",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_donna.w._narration_slow-paced.wav"
          }
        ],
        approxDurationCoeficient: 24
      },
      {
        character: "Charlie",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["calm", "young"],
        playedTags: [
          {
            id: "40",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_charlie.z._promo_calm.wav"
          }
        ],
        approxDurationCoeficient: 20
      },
      {
        character: "Cameron",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["middle-aged"],
        playedTags: [
          {
            id: "77",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-77-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 19
      },
      {
        character: "Bella",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["upbeat", "young"],
        playedTags: [
          {
            id: "38",
            name: "promo",
            url: "https://elai-media.s3.eu-west-2.amazonaws.com/voices/wsl_bella.b._promo_upbeat.wav"
          }
        ],
        approxDurationCoeficient: 31
      },
      {
        character: "Ali",
        name: "English (Australia)",
        locale: "en-AU",
        icon: "au",
        voiceProvider: "wsl",
        premium: true,
        tags: ["young"],
        playedTags: [
          {
            id: "111",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-111-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 16
      },
      {
        character: "Abbi",
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        voiceProvider: "wsl",
        premium: true,
        tags: ["middle-aged"],
        playedTags: [
          {
            id: "102",
            name: "Narration",
            url: "https://d3u63mhbhkevz8.cloudfront.net/voices/wsl/wsl-102-en-us.mp3"
          }
        ],
        approxDurationCoeficient: 24
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3",
        order: 1,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 21
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
        order: 3,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/oWAxZDx7w5VEj9dCyTzz/84a36d1c-e182-41a8-8c55-dbdd15cd6e72.mp3",
        order: 4,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/508e12d0-a7f7-4d86-a0d3-f3884ff353ed.mp3",
        order: 5,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 19
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/jBpfuIE2acCO8z3wKNLl/3a7e4339-78fa-404e-8d10-c3ef5587935b.mp3",
        order: 6,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 20
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/pMsXgVXv3BLzUgSXRplE/d61f18ed-e5b0-4d0b-a33c-5c6e7e33b053.mp3",
        order: 7,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 20
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/z9fAnlkpzviPz146aGWa/cbc60443-7b61-4ebb-b8e1-5c03237ea01d.mp3",
        order: 8,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/zrHiDhphv9ZnVXBqCLjz/decbf20b-0f57-4fac-985b-a4f0290ebfc4.mp3",
        order: 9,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 17
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/LcfcDJNUP1GQjkzn1xUU/e4b994b7-9713-4238-84f3-add8fccaaccd.mp3",
        order: 10,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 16
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/0ab8bd74-fcd2-489d-b70a-3e1bcde8c999.mp3",
        order: 11,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 19
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3",
        order: 12,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 15
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/ThT5KcBeYPX3keUQqHPh/981f0855-6598-48d2-9f8f-b6d92fbbe3fc.mp3",
        order: 13,
        name: "English (United States)",
        locale: "en-US",
        icon: "gb",
        approxDurationCoeficient: 20
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/6851ec91-9950-471f-8586-357c52539069.mp3",
        order: 99999,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 20
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        url: "https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/f5409e2f-d9c3-4ac9-9e7d-916a5dbd1ef1.mp3",
        order: 99999,
        name: "English (United States)",
        locale: "en-US",
        icon: "us",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Natasha",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-NatashaNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-natashaneural-en-us.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Annette",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-AnnetteNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-annetteneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Carly",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-CarlyNeural",
        icon: "au",
        tags: ["child"],
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-carlyneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Elsie",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-ElsieNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-elsieneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Freya",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-FreyaNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-freyaneural-en-us.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Kim",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-KimNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-kimneural-en-us.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Tina",
        name: "English (Australia)",
        locale: "en-AU",
        voice: "en-AU-TinaNeural",
        icon: "au",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-au-tinaneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Clara",
        name: "English (Canada)",
        locale: "en-CA",
        voice: "en-CA-ClaraNeural",
        icon: "ca",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ca-claraneural-en-us.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Libby",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-LibbyNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-libbyneural-en-us.mp3",
        approxDurationCoeficient: 14
      },
      {
        voiceProvider: "azure",
        character: "Abbi",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-AbbiNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-abbineural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Bella",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-BellaNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-bellaneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Hollie",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-HollieNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-hollieneural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Maisie",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-MaisieNeural",
        icon: "gb",
        tags: ["child"],
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-maisieneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Olivia",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-OliviaNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-olivianeural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Mia",
        name: "English (United Kingdom)",
        locale: "en-GB",
        voice: "en-GB-MiaNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-gb-mianeural-en-us.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Yan",
        name: "English (Hong Kong SAR)",
        locale: "en-HK",
        voice: "en-HK-YanNeural",
        icon: "hk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-hk-yanneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Emily",
        name: "English (Ireland)",
        locale: "en-IE",
        voice: "en-IE-EmilyNeural",
        icon: "ie",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ie-emilyneural-en-us.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Neerja",
        name: "English (India)",
        locale: "en-IN",
        voice: "en-IN-NeerjaNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-in-neerjaneural-en-us.mp3",
        approxDurationCoeficient: 26
      },
      {
        voiceProvider: "azure",
        character: "Asilia",
        name: "English (Kenya)",
        locale: "en-KE",
        voice: "en-KE-AsiliaNeural",
        icon: "ke",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ke-asilianeural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Ezinne",
        name: "English (Nigeria)",
        locale: "en-NG",
        voice: "en-NG-EzinneNeural",
        icon: "ng",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ng-ezinneneural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Molly",
        name: "English (New Zealand)",
        locale: "en-NZ",
        voice: "en-NZ-MollyNeural",
        icon: "nz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-nz-mollyneural-en-us.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Rosa",
        name: "English (Philippines)",
        locale: "en-PH",
        voice: "en-PH-RosaNeural",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-ph-rosaneural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Luna",
        name: "English (Singapore)",
        locale: "en-SG",
        voice: "en-SG-LunaNeural",
        icon: "sg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-sg-lunaneural-en-us.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Imani",
        name: "English (Tanzania)",
        locale: "en-TZ",
        voice: "en-TZ-ImaniNeural",
        icon: "tz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-tz-imanineural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Ava M",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AvaMultilingualNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-avamultilingualneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Emma M",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-EmmaMultilingualNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-emmamultilingualneural-en-us.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Ava",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AvaNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-avaneural-en-us.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Jenny",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-JennyNeural",
        icon: "us",
        styleList: [
          "assistant",
          "chat",
          "customerservice",
          "newscast",
          "angry",
          "cheerful",
          "sad",
          "excited",
          "friendly",
          "terrified",
          "shouting",
          "unfriendly",
          "whispering",
          "hopeful"
        ],
        defaultStyle: "friendly",
        stylePreview: {
          assistant: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:assistant-en-us.mp3",
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:chat-en-us.mp3",
          customerservice: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:customerservice-en-us.mp3",
          newscast: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:newscast-en-us.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:angry-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:cheerful-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:sad-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:friendly-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:terrified-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:shouting-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:whispering-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural:hopeful-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennyneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Aria",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AriaNeural",
        icon: "us",
        styleList: [
          "chat",
          "customerservice",
          "narration-professional",
          "newscast-casual",
          "newscast-formal",
          "cheerful",
          "empathetic",
          "angry",
          "sad",
          "excited",
          "friendly",
          "terrified",
          "shouting",
          "unfriendly",
          "whispering",
          "hopeful"
        ],
        defaultStyle: "friendly",
        stylePreview: {
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:chat-en-us.mp3",
          customerservice: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:customerservice-en-us.mp3",
          "narration-professional": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:narration-professional-en-us.mp3",
          "newscast-casual": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:newscast-casual-en-us.mp3",
          "newscast-formal": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:newscast-formal-en-us.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:cheerful-en-us.mp3",
          empathetic: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:empathetic-en-us.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:angry-en-us.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:sad-en-us.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:excited-en-us.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:friendly-en-us.mp3",
          terrified: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:terrified-en-us.mp3",
          shouting: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:shouting-en-us.mp3",
          unfriendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:unfriendly-en-us.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:whispering-en-us.mp3",
          hopeful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural:hopeful-en-us.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-arianeural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Amber",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AmberNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-amberneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Ana",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AnaNeural",
        icon: "us",
        tags: ["child"],
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-ananeural-en-us.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Ashley",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-AshleyNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-ashleyneural-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Cora",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-CoraNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-coraneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Elizabeth",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-ElizabethNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-elizabethneural-en-us.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Jenny M",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-JennyMultilingualNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-jennymultilingualneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Michelle",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-MichelleNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-michelleneural-en-us.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Monica",
        name: "English (United States)",
        locale: "en-US",
        voice: "en-US-MonicaNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-us-monicaneural-en-us.mp3",
        approxDurationCoeficient: 25
      },
      {
        voiceProvider: "azure",
        character: "Leah",
        name: "English (South Africa)",
        locale: "en-ZA",
        voice: "en-ZA-LeahNeural",
        icon: "za",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-en-za-leahneural-en-us.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Elanor A",
        name: "English-AU",
        voice: "en-AU-Neural2-A",
        icon: "au",
        voiceProvider: "google",
        locale: "en-AU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-au-neural2-a-en-us.mp3"
      },
      {
        character: "Lucy C",
        name: "English-AU",
        voice: "en-AU-Neural2-C",
        icon: "au",
        voiceProvider: "google",
        locale: "en-AU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-au-neural2-c-en-us.mp3"
      },
      {
        character: "Daisy A",
        name: "English-GB",
        voice: "en-GB-Neural2-A",
        icon: "gb",
        voiceProvider: "google",
        locale: "en-GB",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-gb-neural2-a-en-us.mp3"
      },
      {
        character: "Scarlett C",
        name: "English-GB",
        voice: "en-GB-Neural2-C",
        icon: "gb",
        voiceProvider: "google",
        locale: "en-GB",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-gb-neural2-c-en-us.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Jane F",
        name: "English-GB",
        voice: "en-GB-Neural2-F",
        icon: "gb",
        voiceProvider: "google",
        locale: "en-GB",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-gb-neural2-f-en-us.mp3"
      },
      {
        character: "Lily A",
        name: "English-IN",
        voice: "en-IN-Neural2-A",
        icon: "in",
        voiceProvider: "google",
        locale: "en-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-in-neural2-a-en-us.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Ella D",
        name: "English-IN",
        voice: "en-IN-Neural2-D",
        icon: "in",
        voiceProvider: "google",
        locale: "en-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-in-neural2-d-en-us.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "juliet C",
        name: "English-US",
        voice: "en-US-Neural2-C",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-c-en-us.mp3"
      },
      {
        character: "Ellie E",
        name: "English-US",
        voice: "en-US-Neural2-E",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-e-en-us.mp3"
      },
      {
        character: "Evelyn F",
        name: "English-US",
        voice: "en-US-Neural2-F",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-f-en-us.mp3"
      },
      {
        character: "Annie G",
        name: "English-US",
        voice: "en-US-Neural2-G",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-g-en-us.mp3"
      },
      {
        character: "Elanor H",
        name: "English-US",
        voice: "en-US-Neural2-H",
        icon: "us",
        voiceProvider: "google",
        locale: "en-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-en-us-neural2-h-en-us.mp3"
      }
    ]
  },
  {
    name: "Afrikaans",
    male: [
      {
        voiceProvider: "azure",
        character: "Willem",
        name: "Afrikaans (South Africa)",
        locale: "af-ZA",
        voice: "af-ZA-WillemNeural",
        icon: "za",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-af-za-willemneural-af-za.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Adri",
        name: "Afrikaans (South Africa)",
        locale: "af-ZA",
        voice: "af-ZA-AdriNeural",
        icon: "za",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-af-za-adrineural-af-za.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Albanian",
    male: [
      {
        voiceProvider: "azure",
        character: "Ilir",
        name: "Albanian (Albania)",
        locale: "sq-AL",
        voice: "sq-AL-IlirNeural",
        icon: "al",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sq-al-ilirneural-sq-al.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Anila",
        name: "Albanian (Albania)",
        locale: "sq-AL",
        voice: "sq-AL-AnilaNeural",
        icon: "al",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sq-al-anilaneural-sq-al.mp3",
        approxDurationCoeficient: 18
      }
    ]
  },
  {
    name: "Amharic",
    male: [
      {
        voiceProvider: "azure",
        character: "Ameha",
        name: "Amharic (Ethiopia)",
        locale: "am-ET",
        voice: "am-ET-AmehaNeural",
        icon: "et",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-am-et-amehaneural-am-et.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Oscar B",
        name: "Amharic-ET",
        voice: "am-ET-Wavenet-B",
        icon: "et",
        voiceProvider: "google",
        locale: "am-ET",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-am-et-wavenet-b-am-et.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Mekdes",
        name: "Amharic (Ethiopia)",
        locale: "am-ET",
        voice: "am-ET-MekdesNeural",
        icon: "et",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-am-et-mekdesneural-am-et.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Elanor A",
        name: "Amharic-ET",
        voice: "am-ET-Wavenet-A",
        icon: "et",
        voiceProvider: "google",
        locale: "am-ET",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-am-et-wavenet-a-am-et.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Arabic",
    male: [
      {
        voiceProvider: "azure",
        character: "Hamdan",
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        voice: "ar-AE-HamdanNeural",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ae-hamdanneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-ar-ae.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-ar-ae.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-ar-ae.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-ar-ae.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-ar-ae.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-ar-ae.mp3",
        approxDurationCoeficient: 8
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-ar-ae.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-ar-ae.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-ar-ae.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-ar-ae.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-ar-ae.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-ar-ae.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-ar-ae.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-ar-ae.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-ar-ae.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-ar-ae.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-ar-ae.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Ali",
        name: "Arabic (Bahrain)",
        locale: "ar-BH",
        voice: "ar-BH-AliNeural",
        icon: "bh",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-bh-alineural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Ismael",
        name: "Arabic (Algeria)",
        locale: "ar-DZ",
        voice: "ar-DZ-IsmaelNeural",
        icon: "dz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-dz-ismaelneural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Shakir",
        name: "Arabic (Egypt)",
        locale: "ar-EG",
        voice: "ar-EG-ShakirNeural",
        icon: "eg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-eg-shakirneural-ar-ae.mp3",
        approxDurationCoeficient: 14
      },
      {
        voiceProvider: "azure",
        character: "Bassel",
        name: "Arabic (Iraq)",
        locale: "ar-IQ",
        voice: "ar-IQ-BasselNeural",
        icon: "iq",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-iq-basselneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Taim",
        name: "Arabic (Jordan)",
        locale: "ar-JO",
        voice: "ar-JO-TaimNeural",
        icon: "jo",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-jo-taimneural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Fahed",
        name: "Arabic (Kuwait)",
        locale: "ar-KW",
        voice: "ar-KW-FahedNeural",
        icon: "kw",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-kw-fahedneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Rami",
        name: "Arabic (Lebanon)",
        locale: "ar-LB",
        voice: "ar-LB-RamiNeural",
        icon: "lb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-lb-ramineural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Omar",
        name: "Arabic (Libya)",
        locale: "ar-LY",
        voice: "ar-LY-OmarNeural",
        icon: "ly",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ly-omarneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Jamal",
        name: "Arabic (Morocco)",
        locale: "ar-MA",
        voice: "ar-MA-JamalNeural",
        icon: "ma",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ma-jamalneural-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Abdullah",
        name: "Arabic (Oman)",
        locale: "ar-OM",
        voice: "ar-OM-AbdullahNeural",
        icon: "om",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-om-abdullahneural-ar-ae.mp3",
        approxDurationCoeficient: 14
      },
      {
        voiceProvider: "azure",
        character: "Moaz",
        name: "Arabic (Qatar)",
        locale: "ar-QA",
        voice: "ar-QA-MoazNeural",
        icon: "qa",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-qa-moazneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Hamed",
        name: "Arabic (Saudi Arabia)",
        locale: "ar-SA",
        voice: "ar-SA-HamedNeural",
        icon: "sa",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-sa-hamedneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Laith",
        name: "Arabic (Syria)",
        locale: "ar-SY",
        voice: "ar-SY-LaithNeural",
        icon: "sy",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-sy-laithneural-ar-ae.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Hedi",
        name: "Arabic (Tunisia)",
        locale: "ar-TN",
        voice: "ar-TN-HediNeural",
        icon: "tn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-tn-hedineural-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Saleh",
        name: "Arabic (Yemen)",
        locale: "ar-YE",
        voice: "ar-YE-SalehNeural",
        icon: "ye",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ye-salehneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Zayn B",
        name: "Arabic-XA",
        voice: "ar-XA-Wavenet-B",
        icon: "xa",
        voiceProvider: "google",
        locale: "ar-XA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ar-xa-wavenet-b-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Amir C",
        name: "Arabic-XA",
        voice: "ar-XA-Wavenet-C",
        icon: "xa",
        voiceProvider: "google",
        locale: "ar-XA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ar-xa-wavenet-c-ar-ae.mp3",
        approxDurationCoeficient: 13
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Fatima",
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        voice: "ar-AE-FatimaNeural",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ae-fatimaneural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-ar-ae.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-ar-ae.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-ar-ae.mp3",
        approxDurationCoeficient: 6
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-ar-ae.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-ar-ae.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Arabic (United Arab Emirates)",
        locale: "ar-AE",
        icon: "ae",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-ar-ae.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Laila",
        name: "Arabic (Bahrain)",
        locale: "ar-BH",
        voice: "ar-BH-LailaNeural",
        icon: "bh",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-bh-lailaneural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Amina",
        name: "Arabic (Algeria)",
        locale: "ar-DZ",
        voice: "ar-DZ-AminaNeural",
        icon: "dz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-dz-aminaneural-ar-ae.mp3",
        approxDurationCoeficient: 10
      },
      {
        voiceProvider: "azure",
        character: "Salma",
        name: "Arabic (Egypt)",
        locale: "ar-EG",
        voice: "ar-EG-SalmaNeural",
        icon: "eg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-eg-salmaneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Rana",
        name: "Arabic (Iraq)",
        locale: "ar-IQ",
        voice: "ar-IQ-RanaNeural",
        icon: "iq",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-iq-rananeural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Sana",
        name: "Arabic (Jordan)",
        locale: "ar-JO",
        voice: "ar-JO-SanaNeural",
        icon: "jo",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-jo-sananeural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Noura",
        name: "Arabic (Kuwait)",
        locale: "ar-KW",
        voice: "ar-KW-NouraNeural",
        icon: "kw",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-kw-nouraneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Layla",
        name: "Arabic (Lebanon)",
        locale: "ar-LB",
        voice: "ar-LB-LaylaNeural",
        icon: "lb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-lb-laylaneural-ar-ae.mp3",
        approxDurationCoeficient: 11
      },
      {
        voiceProvider: "azure",
        character: "Iman",
        name: "Arabic (Libya)",
        locale: "ar-LY",
        voice: "ar-LY-ImanNeural",
        icon: "ly",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ly-imanneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Mouna",
        name: "Arabic (Morocco)",
        locale: "ar-MA",
        voice: "ar-MA-MounaNeural",
        icon: "ma",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ma-mounaneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Aysha",
        name: "Arabic (Oman)",
        locale: "ar-OM",
        voice: "ar-OM-AyshaNeural",
        icon: "om",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-om-ayshaneural-ar-ae.mp3",
        approxDurationCoeficient: 14
      },
      {
        voiceProvider: "azure",
        character: "Amal",
        name: "Arabic (Qatar)",
        locale: "ar-QA",
        voice: "ar-QA-AmalNeural",
        icon: "qa",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-qa-amalneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Zariyah",
        name: "Arabic (Saudi Arabia)",
        locale: "ar-SA",
        voice: "ar-SA-ZariyahNeural",
        icon: "sa",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-sa-zariyahneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Amany",
        name: "Arabic (Syria)",
        locale: "ar-SY",
        voice: "ar-SY-AmanyNeural",
        icon: "sy",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-sy-amanyneural-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Reem",
        name: "Arabic (Tunisia)",
        locale: "ar-TN",
        voice: "ar-TN-ReemNeural",
        icon: "tn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-tn-reemneural-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Maryam",
        name: "Arabic (Yemen)",
        locale: "ar-YE",
        voice: "ar-YE-MaryamNeural",
        icon: "ye",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ar-ye-maryamneural-ar-ae.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Lila A",
        name: "Arabic-XA",
        voice: "ar-XA-Wavenet-A",
        icon: "xa",
        voiceProvider: "google",
        locale: "ar-XA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ar-xa-wavenet-a-ar-ae.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Yara D",
        name: "Arabic-XA",
        voice: "ar-XA-Wavenet-D",
        icon: "xa",
        voiceProvider: "google",
        locale: "ar-XA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ar-xa-wavenet-d-ar-ae.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Armenian",
    male: [
      {
        voiceProvider: "azure",
        character: "Hayk",
        name: "Armenian (Armenia)",
        locale: "hy-AM",
        voice: "hy-AM-HaykNeural",
        icon: "am",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hy-am-haykneural-hy-am.mp3",
        approxDurationCoeficient: 16
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Anahit",
        name: "Armenian (Armenia)",
        locale: "hy-AM",
        voice: "hy-AM-AnahitNeural",
        icon: "am",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hy-am-anahitneural-hy-am.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Azerbaijani",
    male: [
      {
        voiceProvider: "azure",
        character: "Babek",
        name: "Azerbaijani (Latin, Azerbaijan)",
        locale: "az-AZ",
        voice: "az-AZ-BabekNeural",
        icon: "az",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-az-az-babekneural-az-az.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Banu",
        name: "Azerbaijani (Latin, Azerbaijan)",
        locale: "az-AZ",
        voice: "az-AZ-BanuNeural",
        icon: "az",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-az-az-banuneural-az-az.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Bangla",
    male: [
      {
        voiceProvider: "azure",
        character: "Pradeep",
        name: "Bangla (Bangladesh)",
        locale: "bn-BD",
        voice: "bn-BD-PradeepNeural",
        icon: "bd",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bn-bd-pradeepneural-bn-bd.mp3",
        approxDurationCoeficient: 22
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Nabanita",
        name: "Bangla (Bangladesh)",
        locale: "bn-BD",
        voice: "bn-BD-NabanitaNeural",
        icon: "bd",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bn-bd-nabanitaneural-bn-bd.mp3",
        approxDurationCoeficient: 20
      }
    ]
  },
  {
    name: "Basque",
    male: [
      {
        voiceProvider: "azure",
        character: "Ander",
        name: "Basque",
        locale: "eu-ES",
        voice: "eu-ES-AnderNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-eu-es-anderneural-eu-es.mp3",
        approxDurationCoeficient: 14
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Ainhoa",
        name: "Basque",
        locale: "eu-ES",
        voice: "eu-ES-AinhoaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-eu-es-ainhoaneural-eu-es.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Bengali",
    male: [
      {
        voiceProvider: "azure",
        character: "Bashkar",
        name: "Bengali (India)",
        locale: "bn-IN",
        voice: "bn-IN-BashkarNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bn-in-bashkarneural-bn-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Oscar B",
        name: "Bengali-IN",
        voice: "bn-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "bn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-bn-in-wavenet-b-bn-in.mp3"
      },
      {
        character: "Miles D",
        name: "Bengali-IN",
        voice: "bn-IN-Wavenet-D",
        icon: "in",
        voiceProvider: "google",
        locale: "bn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-bn-in-wavenet-d-bn-in.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Tanishaa",
        name: "Bengali (India)",
        locale: "bn-IN",
        voice: "bn-IN-TanishaaNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bn-in-tanishaaneural-bn-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Elanor A",
        name: "Bengali-IN",
        voice: "bn-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "bn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-bn-in-wavenet-a-bn-in.mp3"
      },
      {
        character: "Lucy C",
        name: "Bengali-IN",
        voice: "bn-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "bn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-bn-in-wavenet-c-bn-in.mp3"
      }
    ]
  },
  {
    name: "Bosnian",
    male: [
      {
        voiceProvider: "azure",
        character: "Goran",
        name: "Bosnian (Bosnia and Herzegovina)",
        locale: "bs-BA",
        voice: "bs-BA-GoranNeural",
        icon: "ba",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bs-ba-goranneural-bs-ba.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Vesna",
        name: "Bosnian (Bosnia and Herzegovina)",
        locale: "bs-BA",
        voice: "bs-BA-VesnaNeural",
        icon: "ba",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bs-ba-vesnaneural-bs-ba.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Bulgarian",
    male: [
      {
        voiceProvider: "azure",
        character: "Borislav",
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        voice: "bg-BG-BorislavNeural",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bg-bg-borislavneural-bg-bg.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-bg-bg.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-bg-bg.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-bg-bg.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-bg-bg.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-bg-bg.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-bg-bg.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-bg-bg.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-bg-bg.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-bg-bg.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-bg-bg.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-bg-bg.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-bg-bg.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-bg-bg.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-bg-bg.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-bg-bg.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-bg-bg.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-bg-bg.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-bg-bg.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-bg-bg.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-bg-bg.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-bg-bg.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-bg-bg.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-bg-bg.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-bg-bg.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-bg-bg.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-bg-bg.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-bg-bg.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Kalina",
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        voice: "bg-BG-KalinaNeural",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-bg-bg-kalinaneural-bg-bg.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-bg-bg.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-bg-bg.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-bg-bg.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-bg-bg.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-bg-bg.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-bg-bg.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-bg-bg.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-bg-bg.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-bg-bg.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-bg-bg.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-bg-bg.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-bg-bg.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-bg-bg.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Bulgarian (Bulgaria)",
        locale: "bg-BG",
        icon: "bg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-bg-bg.mp3"
      }
    ]
  },
  {
    name: "Burmese",
    male: [
      {
        voiceProvider: "azure",
        character: "Thiha",
        name: "Burmese (Myanmar)",
        locale: "my-MM",
        voice: "my-MM-ThihaNeural",
        icon: "mm",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-my-mm-thihaneural-my-mm.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Nilar",
        name: "Burmese (Myanmar)",
        locale: "my-MM",
        voice: "my-MM-NilarNeural",
        icon: "mm",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-my-mm-nilarneural-my-mm.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Catalan",
    male: [
      {
        voiceProvider: "azure",
        character: "Enric",
        name: "Catalan (Spain)",
        locale: "ca-ES",
        voice: "ca-ES-EnricNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ca-es-enricneural-ca-es.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Joana",
        name: "Catalan (Spain)",
        locale: "ca-ES",
        voice: "ca-ES-JoanaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ca-es-joananeural-ca-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Alba",
        name: "Catalan (Spain)",
        locale: "ca-ES",
        voice: "ca-ES-AlbaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ca-es-albaneural-ca-es.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Chinese",
    male: [
      {
        voiceProvider: "azure",
        character: "Yunyi M",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunyiMultilingualNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyimultilingualneural-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-zh-cn.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-zh-cn.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-zh-cn.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-zh-cn.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-zh-cn.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-zh-cn.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-zh-cn.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-zh-cn.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-zh-cn.mp3",
        approxDurationCoeficient: 3
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-zh-cn.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-zh-cn.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-zh-cn.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-zh-cn.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-zh-cn.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-zh-cn.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-zh-cn.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-zh-cn.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-zh-cn.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-zh-cn.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-zh-cn.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-zh-cn.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-zh-cn.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-zh-cn.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Yunzhe",
        name: "Chinese (Wu, Simplified)",
        locale: "wuu-CN",
        voice: "wuu-CN-YunzheNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-wuu-cn-yunzheneural-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        voiceProvider: "azure",
        character: "YunSong",
        name: "Chinese (Cantonese, Simplified)",
        locale: "yue-CN",
        voice: "yue-CN-YunSongNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-yue-cn-yunsongneural-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Yunxi",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunxiNeural",
        icon: "cn",
        rolePlayList: ["Narrator", "YoungAdultMale", "Boy"],
        styleList: [
          "narration-relaxed",
          "embarrassed",
          "fearful",
          "cheerful",
          "disgruntled",
          "serious",
          "angry",
          "sad",
          "depressed",
          "chat",
          "assistant",
          "newscast"
        ],
        stylePreview: {
          "narration-relaxed": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:narration-relaxed-zh-cn.mp3",
          embarrassed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:embarrassed-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:disgruntled-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:serious-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:sad-zh-cn.mp3",
          depressed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:depressed-zh-cn.mp3",
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:chat-zh-cn.mp3",
          assistant: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:assistant-zh-cn.mp3",
          newscast: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural:newscast-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxineural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Yunjian",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunjianNeural",
        icon: "cn",
        styleList: [
          "narration-relaxed",
          "sports-commentary",
          "sports-commentary-excited",
          "angry",
          "disgruntled",
          "cheerful",
          "sad",
          "serious",
          "depressed",
          "documentary-narration"
        ],
        stylePreview: {
          "narration-relaxed": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:narration-relaxed-zh-cn.mp3",
          "sports-commentary": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:sports-commentary-zh-cn.mp3",
          "sports-commentary-excited":
            "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:sports-commentary-excited-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:angry-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:disgruntled-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:cheerful-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:sad-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:serious-zh-cn.mp3",
          depressed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:depressed-zh-cn.mp3",
          "documentary-narration": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural:documentary-narration-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjianneural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Yunyang",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunyangNeural",
        icon: "cn",
        styleList: ["customerservice", "narration-professional", "newscast-casual"],
        stylePreview: {
          customerservice: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyangneural:customerservice-zh-cn.mp3",
          "narration-professional": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyangneural:narration-professional-zh-cn.mp3",
          "newscast-casual": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyangneural:newscast-casual-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyangneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Yunfeng",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunfengNeural",
        icon: "cn",
        styleList: ["angry", "disgruntled", "cheerful", "fearful", "sad", "serious", "depressed"],
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:angry-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:disgruntled-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:cheerful-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:fearful-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:sad-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:serious-zh-cn.mp3",
          depressed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural:depressed-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunfengneural-zh-cn.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Yunhao",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunhaoNeural",
        icon: "cn",
        styleList: ["advertisement-upbeat"],
        stylePreview: {
          "advertisement-upbeat": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunhaoneural:advertisement-upbeat-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunhaoneural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Yunjie",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunjieNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunjieneural-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Yunxia",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunxiaNeural",
        icon: "cn",
        styleList: ["calm", "fearful", "cheerful", "angry", "sad"],
        stylePreview: {
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxianeural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxianeural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxianeural:cheerful-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxianeural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxianeural:sad-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunxianeural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Yunye",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunyeNeural",
        icon: "cn",
        rolePlayList: ["YoungAdultFemale", "YoungAdultMale", "OlderAdultFemale", "OlderAdultMale", "SeniorFemale", "SeniorMale", "Girl", "Boy"],
        styleList: ["embarrassed", "calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "sad"],
        stylePreview: {
          embarrassed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:embarrassed-zh-cn.mp3",
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:disgruntled-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:serious-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural:sad-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunyeneural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Yunze",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-YunzeNeural",
        icon: "cn",
        rolePlayList: ["OlderAdultMale", "SeniorMale"],
        styleList: ["calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "sad", "depressed", "documentary-narration"],
        stylePreview: {
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:disgruntled-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:serious-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:sad-zh-cn.mp3",
          depressed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:depressed-zh-cn.mp3",
          "documentary-narration": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural:documentary-narration-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-yunzeneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Yundeng",
        name: "Chinese (Zhongyuan Mandarin Henan, Simplified)",
        locale: "zh-CN-henan",
        voice: "zh-CN-henan-YundengNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-henan-yundengneural-zh-cn.mp3",
        approxDurationCoeficient: 10
      },
      {
        voiceProvider: "azure",
        character: "Yunxiang",
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        voice: "zh-CN-shandong-YunxiangNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-shandong-yunxiangneural-zh-cn.mp3",
        approxDurationCoeficient: 8
      },
      {
        voiceProvider: "azure",
        character: "YunxiSichuan",
        name: "Chinese (Southwestern Mandarin, Simplified)",
        locale: "zh-CN-sichuan",
        voice: "zh-CN-sichuan-YunxiNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-sichuan-yunxineural-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "WanLung",
        name: "Chinese (Cantonese, Traditional)",
        locale: "zh-HK",
        voice: "zh-HK-WanLungNeural",
        icon: "hk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-hk-wanlungneural-zh-cn.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "YunJhe",
        name: "Chinese (Taiwanese Mandarin, Traditional)",
        locale: "zh-TW",
        voice: "zh-TW-YunJheNeural",
        icon: "tw",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-tw-yunjheneural-zh-cn.mp3",
        approxDurationCoeficient: 7
      },
      {
        character: "Cheng B",
        name: "Chinese-CN",
        voice: "cmn-CN-Wavenet-B",
        icon: "cn",
        voiceProvider: "google",
        locale: "cmn-CN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-cn-wavenet-b-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Fu C",
        name: "Chinese-CN",
        voice: "cmn-CN-Wavenet-C",
        icon: "cn",
        voiceProvider: "google",
        locale: "cmn-CN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-cn-wavenet-c-zh-cn.mp3"
      },
      {
        character: "Wu B",
        name: "Chinese-TW",
        voice: "cmn-TW-Wavenet-B",
        icon: "tw",
        voiceProvider: "google",
        locale: "cmn-TW",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-tw-wavenet-b-zh-cn.mp3"
      },
      {
        character: "Yi C",
        name: "Chinese-TW",
        voice: "cmn-TW-Wavenet-C",
        icon: "tw",
        voiceProvider: "google",
        locale: "cmn-TW",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-tw-wavenet-c-zh-cn.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Xiaochen M",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaochenMultilingualNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaochenmultilingualneural-zh-cn.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Xiaoxiao M",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoxiaoMultilingualNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaomultilingualneural-zh-cn.mp3"
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-zh-cn.mp3",
        approxDurationCoeficient: 7
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-zh-cn.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-zh-cn.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-zh-cn.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-zh-cn.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-zh-cn.mp3",
        approxDurationCoeficient: 3
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-zh-cn.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-zh-cn.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-zh-cn.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-zh-cn.mp3",
        approxDurationCoeficient: 3
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-zh-cn.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-zh-cn.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Chinese (Jilu Mandarin, Simplified)",
        locale: "zh-CN-shandong",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Xiaotong",
        name: "Chinese (Wu, Simplified)",
        locale: "wuu-CN",
        voice: "wuu-CN-XiaotongNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-wuu-cn-xiaotongneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "XiaoMin",
        name: "Chinese (Cantonese, Simplified)",
        locale: "yue-CN",
        voice: "yue-CN-XiaoMinNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-yue-cn-xiaominneural-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        voiceProvider: "azure",
        character: "Xiaoxiao",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoxiaoNeural",
        icon: "cn",
        styleList: [
          "assistant",
          "chat",
          "customerservice",
          "newscast",
          "affectionate",
          "angry",
          "calm",
          "cheerful",
          "disgruntled",
          "fearful",
          "gentle",
          "lyrical",
          "sad",
          "serious",
          "poetry-reading",
          "friendly",
          "chat-casual",
          "whispering",
          "sorry",
          "excited"
        ],
        defaultStyle: "friendly",
        stylePreview: {
          assistant: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:assistant-zh-cn.mp3",
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:chat-zh-cn.mp3",
          customerservice: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:customerservice-zh-cn.mp3",
          newscast: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:newscast-zh-cn.mp3",
          affectionate: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:affectionate-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:angry-zh-cn.mp3",
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:calm-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:disgruntled-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:fearful-zh-cn.mp3",
          gentle: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:gentle-zh-cn.mp3",
          lyrical: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:lyrical-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:sad-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:serious-zh-cn.mp3",
          "poetry-reading": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:poetry-reading-zh-cn.mp3",
          friendly: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:friendly-zh-cn.mp3",
          "chat-casual": "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:chat-casual-zh-cn.mp3",
          whispering: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:whispering-zh-cn.mp3",
          sorry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:sorry-zh-cn.mp3",
          excited: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural:excited-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaoneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaoyi",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyiNeural",
        icon: "cn",
        styleList: ["angry", "disgruntled", "affectionate", "cheerful", "fearful", "sad", "embarrassed", "serious", "gentle"],
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:angry-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:disgruntled-zh-cn.mp3",
          affectionate: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:affectionate-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:cheerful-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:fearful-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:sad-zh-cn.mp3",
          embarrassed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:embarrassed-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:serious-zh-cn.mp3",
          gentle: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural:gentle-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyineural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaochen",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaochenNeural",
        icon: "cn",
        styleList: ["livecommercial"],
        stylePreview: {
          livecommercial: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaochenneural:livecommercial-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaochenneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaohan",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaohanNeural",
        icon: "cn",
        styleList: ["calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "sad", "gentle", "affectionate", "embarrassed"],
        stylePreview: {
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:disgruntled-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:serious-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:sad-zh-cn.mp3",
          gentle: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:gentle-zh-cn.mp3",
          affectionate: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:affectionate-zh-cn.mp3",
          embarrassed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural:embarrassed-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaohanneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaomeng",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaomengNeural",
        icon: "cn",
        styleList: ["chat"],
        stylePreview: {
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomengneural:chat-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomengneural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Xiaomo",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaomoNeural",
        icon: "cn",
        rolePlayList: ["YoungAdultFemale", "YoungAdultMale", "OlderAdultFemale", "OlderAdultMale", "SeniorFemale", "SeniorMale", "Girl", "Boy"],
        styleList: [
          "embarrassed",
          "calm",
          "fearful",
          "cheerful",
          "disgruntled",
          "serious",
          "angry",
          "sad",
          "depressed",
          "affectionate",
          "gentle",
          "envious"
        ],
        stylePreview: {
          embarrassed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:embarrassed-zh-cn.mp3",
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:disgruntled-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:serious-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:sad-zh-cn.mp3",
          depressed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:depressed-zh-cn.mp3",
          affectionate: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:affectionate-zh-cn.mp3",
          gentle: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:gentle-zh-cn.mp3",
          envious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural:envious-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaomoneural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Xiaoqiu",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoqiuNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoqiuneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaorou",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaorouNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaorouneural-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Xiaorui",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoruiNeural",
        icon: "cn",
        styleList: ["calm", "fearful", "angry", "sad"],
        stylePreview: {
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoruineural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoruineural:fearful-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoruineural:angry-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoruineural:sad-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoruineural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Xiaoshuang",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoshuangNeural",
        icon: "cn",
        styleList: ["chat"],
        tags: ["child"],
        stylePreview: {
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoshuangneural:chat-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoshuangneural-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        voiceProvider: "azure",
        character: "Xiaoxiao Dialects",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoxiaoDialectsNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxiaodialectsneural-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Xiaoyan",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyanNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyanneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaoyou",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyouNeural",
        icon: "cn",
        tags: ["child"],
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyouneural-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        voiceProvider: "azure",
        character: "Xiaoyu M",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyuMultilingualNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoyumultilingualneural-zh-cn.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Xiaozhen",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaozhenNeural",
        icon: "cn",
        styleList: ["angry", "disgruntled", "cheerful", "fearful", "sad", "serious"],
        stylePreview: {
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural:angry-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural:disgruntled-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural:cheerful-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural:fearful-zh-cn.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural:sad-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural:serious-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaozhenneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaoxuan",
        name: "Chinese (Mandarin, Simplified)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoxuanNeural",
        icon: "cn",
        rolePlayList: ["YoungAdultFemale", "YoungAdultMale", "OlderAdultFemale", "OlderAdultMale", "SeniorFemale", "SeniorMale", "Girl", "Boy"],
        styleList: ["calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "gentle", "depressed"],
        stylePreview: {
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:calm-zh-cn.mp3",
          fearful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:fearful-zh-cn.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:cheerful-zh-cn.mp3",
          disgruntled: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:disgruntled-zh-cn.mp3",
          serious: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:serious-zh-cn.mp3",
          angry: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:angry-zh-cn.mp3",
          gentle: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:gentle-zh-cn.mp3",
          depressed: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural:depressed-zh-cn.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-xiaoxuanneural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Xiaobei",
        name: "Chinese (Northeastern Mandarin, Simplified)",
        locale: "zh-CN-liaoning",
        voice: "zh-CN-liaoning-XiaobeiNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-liaoning-xiaobeineural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        voiceProvider: "azure",
        character: "Xiaoni",
        name: "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
        locale: "zh-CN-shaanxi",
        voice: "zh-CN-shaanxi-XiaoniNeural",
        icon: "cn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-cn-shaanxi-xiaonineural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "HiuMaan",
        name: "Chinese (Cantonese, Traditional)",
        locale: "zh-HK",
        voice: "zh-HK-HiuMaanNeural",
        icon: "hk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-hk-hiumaanneural-zh-cn.mp3",
        approxDurationCoeficient: 8
      },
      {
        voiceProvider: "azure",
        character: "HiuGaai",
        name: "Chinese (Cantonese, Traditional)",
        locale: "zh-HK",
        voice: "zh-HK-HiuGaaiNeural",
        icon: "hk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-hk-hiugaaineural-zh-cn.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "HsiaoChen",
        name: "Chinese (Taiwanese Mandarin, Traditional)",
        locale: "zh-TW",
        voice: "zh-TW-HsiaoChenNeural",
        icon: "tw",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-tw-hsiaochenneural-zh-cn.mp3",
        approxDurationCoeficient: 8
      },
      {
        voiceProvider: "azure",
        character: "HsiaoYu",
        name: "Chinese (Taiwanese Mandarin, Traditional)",
        locale: "zh-TW",
        voice: "zh-TW-HsiaoYuNeural",
        icon: "tw",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zh-tw-hsiaoyuneural-zh-cn.mp3",
        approxDurationCoeficient: 5
      },
      {
        character: "Mei A",
        name: "Chinese-CN",
        voice: "cmn-CN-Wavenet-A",
        icon: "cn",
        voiceProvider: "google",
        locale: "cmn-CN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-cn-wavenet-a-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Wei D",
        name: "Chinese-CN",
        voice: "cmn-CN-Wavenet-D",
        icon: "cn",
        voiceProvider: "google",
        locale: "cmn-CN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-cn-wavenet-d-zh-cn.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Jiā A",
        name: "Chinese-TW",
        voice: "cmn-TW-Wavenet-A",
        icon: "tw",
        voiceProvider: "google",
        locale: "cmn-TW",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cmn-tw-wavenet-a-zh-cn.mp3"
      }
    ]
  },
  {
    name: "Croatian",
    male: [
      {
        voiceProvider: "azure",
        character: "Srecko",
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        voice: "hr-HR-SreckoNeural",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hr-hr-sreckoneural-hr-hr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-hr-hr.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-hr-hr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-hr-hr.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-hr-hr.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-hr-hr.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-hr-hr.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-hr-hr.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-hr-hr.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-hr-hr.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-hr-hr.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-hr-hr.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-hr-hr.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-hr-hr.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-hr-hr.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-hr-hr.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-hr-hr.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-hr-hr.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-hr-hr.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-hr-hr.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-hr-hr.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-hr-hr.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-hr-hr.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-hr-hr.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-hr-hr.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-hr-hr.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-hr-hr.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-hr-hr.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Gabrijela",
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        voice: "hr-HR-GabrijelaNeural",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hr-hr-gabrijelaneural-hr-hr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-hr-hr.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-hr-hr.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-hr-hr.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-hr-hr.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-hr-hr.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-hr-hr.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-hr-hr.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-hr-hr.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-hr-hr.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-hr-hr.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-hr-hr.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-hr-hr.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-hr-hr.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Croatian (Croatia)",
        locale: "hr-HR",
        icon: "hr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-hr-hr.mp3"
      }
    ]
  },
  {
    name: "Czech",
    male: [
      {
        voiceProvider: "azure",
        character: "Antonin",
        name: "Czech (Czechia)",
        locale: "cs-CZ",
        voice: "cs-CZ-AntoninNeural",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-cs-cz-antoninneural-cs-cz.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-cs-cz.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-cs-cz.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-cs-cz.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-cs-cz.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-cs-cz.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-cs-cz.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-cs-cz.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-cs-cz.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-cs-cz.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-cs-cz.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-cs-cz.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-cs-cz.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-cs-cz.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-cs-cz.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-cs-cz.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-cs-cz.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-cs-cz.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-cs-cz.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-cs-cz.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-cs-cz.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-cs-cz.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-cs-cz.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-cs-cz.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-cs-cz.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-cs-cz.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-cs-cz.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-cs-cz.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Vlasta",
        name: "Czech (Czechia)",
        locale: "cs-CZ",
        voice: "cs-CZ-VlastaNeural",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-cs-cz-vlastaneural-cs-cz.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-cs-cz.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-cs-cz.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-cs-cz.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-cs-cz.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-cs-cz.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-cs-cz.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-cs-cz.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-cs-cz.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-cs-cz.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-cs-cz.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-cs-cz.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-cs-cz.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-cs-cz.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Czech (Czech)",
        locale: "cs-CZ",
        icon: "cz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-cs-cz.mp3"
      },
      {
        character: "Elanor A",
        name: "Czech-CZ",
        voice: "cs-CZ-Wavenet-A",
        icon: "cz",
        voiceProvider: "google",
        locale: "cs-CZ",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-cs-cz-wavenet-a-cs-cz.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Danish",
    male: [
      {
        voiceProvider: "azure",
        character: "Jeppe",
        name: "Danish (Denmark)",
        locale: "da-DK",
        voice: "da-DK-JeppeNeural",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-da-dk-jeppeneural-da-dk.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-da-dk.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-da-dk.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-da-dk.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-da-dk.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-da-dk.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-da-dk.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-da-dk.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-da-dk.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-da-dk.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-da-dk.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-da-dk.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-da-dk.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-da-dk.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-da-dk.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-da-dk.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-da-dk.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-da-dk.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-da-dk.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-da-dk.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-da-dk.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-da-dk.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-da-dk.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-da-dk.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-da-dk.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-da-dk.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-da-dk.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-da-dk.mp3"
      },
      {
        character: "Arne C",
        name: "Danish-DK",
        voice: "da-DK-Wavenet-C",
        icon: "dk",
        voiceProvider: "google",
        locale: "da-DK",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-da-dk-wavenet-c-da-dk.mp3",
        approxDurationCoeficient: 21
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Christel",
        name: "Danish (Denmark)",
        locale: "da-DK",
        voice: "da-DK-ChristelNeural",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-da-dk-christelneural-da-dk.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-da-dk.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-da-dk.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-da-dk.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-da-dk.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-da-dk.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-da-dk.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-da-dk.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-da-dk.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-da-dk.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-da-dk.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-da-dk.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-da-dk.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-da-dk.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Danish (Denmark)",
        locale: "da-DK",
        icon: "dk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-da-dk.mp3"
      },
      {
        character: "Agnete D",
        name: "Danish-DK",
        voice: "da-DK-Neural2-D",
        icon: "dk",
        voiceProvider: "google",
        locale: "da-DK",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-da-dk-neural2-d-da-dk.mp3"
      },
      {
        character: "Alice A",
        name: "Danish-DK",
        voice: "da-DK-Wavenet-A",
        icon: "dk",
        voiceProvider: "google",
        locale: "da-DK",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-da-dk-wavenet-a-da-dk.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Catrine E",
        name: "Danish-DK",
        voice: "da-DK-Wavenet-E",
        icon: "dk",
        voiceProvider: "google",
        locale: "da-DK",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-da-dk-wavenet-e-da-dk.mp3",
        approxDurationCoeficient: 20
      }
    ]
  },
  {
    name: "Dutch",
    male: [
      {
        voiceProvider: "azure",
        character: "Maarten",
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        voice: "nl-NL-MaartenNeural",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nl-nl-maartenneural-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-nl-nl.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-nl-nl.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-nl-nl.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-nl-nl.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-nl-nl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-nl-nl.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-nl-nl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-nl-nl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-nl-nl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-nl-nl.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-nl-nl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-nl-nl.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-nl-nl.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-nl-nl.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-nl-nl.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-nl-nl.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-nl-nl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-nl-nl.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-nl-nl.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-nl-nl.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Arnaud",
        name: "Dutch (Belgium)",
        locale: "nl-BE",
        voice: "nl-BE-ArnaudNeural",
        icon: "be",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nl-be-arnaudneural-nl-nl.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Oscar B",
        name: "Dutch-BE",
        voice: "nl-BE-Wavenet-B",
        icon: "be",
        voiceProvider: "google",
        locale: "nl-BE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-be-wavenet-b-nl-nl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Miles B",
        name: "Dutch-NL",
        voice: "nl-NL-Wavenet-B",
        icon: "nl",
        voiceProvider: "google",
        locale: "nl-NL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-nl-wavenet-b-nl-nl.mp3",
        approxDurationCoeficient: 23
      },
      {
        character: "James C",
        name: "Dutch-NL",
        voice: "nl-NL-Wavenet-C",
        icon: "nl",
        voiceProvider: "google",
        locale: "nl-NL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-nl-wavenet-c-nl-nl.mp3",
        approxDurationCoeficient: 22
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Fenna",
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        voice: "nl-NL-FennaNeural",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nl-nl-fennaneural-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-nl-nl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-nl-nl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-nl-nl.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-nl-nl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-nl-nl.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-nl-nl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-nl-nl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-nl-nl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-nl-nl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-nl-nl.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-nl-nl.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Dena",
        name: "Dutch (Belgium)",
        locale: "nl-BE",
        voice: "nl-BE-DenaNeural",
        icon: "be",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nl-be-denaneural-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Colette",
        name: "Dutch (Netherlands)",
        locale: "nl-NL",
        voice: "nl-NL-ColetteNeural",
        icon: "nl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nl-nl-coletteneural-nl-nl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Dutch-BE",
        voice: "nl-BE-Wavenet-A",
        icon: "be",
        voiceProvider: "google",
        locale: "nl-BE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-be-wavenet-a-nl-nl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Lucy A",
        name: "Dutch-NL",
        voice: "nl-NL-Wavenet-A",
        icon: "nl",
        voiceProvider: "google",
        locale: "nl-NL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-nl-wavenet-a-nl-nl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Daisy D",
        name: "Dutch-NL",
        voice: "nl-NL-Wavenet-D",
        icon: "nl",
        voiceProvider: "google",
        locale: "nl-NL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-nl-wavenet-d-nl-nl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Scarlett E",
        name: "Dutch-NL",
        voice: "nl-NL-Wavenet-E",
        icon: "nl",
        voiceProvider: "google",
        locale: "nl-NL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nl-nl-wavenet-e-nl-nl.mp3",
        approxDurationCoeficient: 25
      }
    ]
  },
  {
    name: "Estonian",
    male: [
      {
        voiceProvider: "azure",
        character: "Kert",
        name: "Estonian (Estonia)",
        locale: "et-EE",
        voice: "et-EE-KertNeural",
        icon: "ee",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-et-ee-kertneural-et-ee.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Anu",
        name: "Estonian (Estonia)",
        locale: "et-EE",
        voice: "et-EE-AnuNeural",
        icon: "ee",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-et-ee-anuneural-et-ee.mp3",
        approxDurationCoeficient: 19
      }
    ]
  },
  {
    name: "Filipino",
    male: [
      {
        voiceProvider: "azure",
        character: "Angelo",
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        voice: "fil-PH-AngeloNeural",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fil-ph-angeloneural-fil-ph.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-fil-ph.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-fil-ph.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-fil-ph.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-fil-ph.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-fil-ph.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-fil-ph.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-fil-ph.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-fil-ph.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-fil-ph.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-fil-ph.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-fil-ph.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-fil-ph.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-fil-ph.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-fil-ph.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-fil-ph.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-fil-ph.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-fil-ph.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-fil-ph.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-fil-ph.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-fil-ph.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-fil-ph.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-fil-ph.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-fil-ph.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-fil-ph.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-fil-ph.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-fil-ph.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-fil-ph.mp3"
      },
      {
        character: "Oscar D",
        name: "Filipino-ph",
        voice: "fil-ph-Neural2-D",
        icon: "ph",
        voiceProvider: "google",
        locale: "fil-PH",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fil-ph-neural2-d-fil-ph.mp3"
      },
      {
        character: "Miles C",
        name: "Filipino-PH",
        voice: "fil-PH-Wavenet-C",
        icon: "ph",
        voiceProvider: "google",
        locale: "fil-PH",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fil-ph-wavenet-c-fil-ph.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Blessica",
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        voice: "fil-PH-BlessicaNeural",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fil-ph-blessicaneural-fil-ph.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-fil-ph.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-fil-ph.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-fil-ph.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-fil-ph.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-fil-ph.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-fil-ph.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-fil-ph.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-fil-ph.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-fil-ph.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-fil-ph.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-fil-ph.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-fil-ph.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-fil-ph.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Filipino (Philippines)",
        locale: "fil-PH",
        icon: "ph",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-fil-ph.mp3"
      },
      {
        character: "Elanor A",
        name: "Filipino-ph",
        voice: "fil-ph-Neural2-A",
        icon: "ph",
        voiceProvider: "google",
        locale: "fil-PH",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fil-ph-neural2-a-fil-ph.mp3"
      },
      {
        character: "Lucy B",
        name: "Filipino-PH",
        voice: "fil-PH-Wavenet-B",
        icon: "ph",
        voiceProvider: "google",
        locale: "fil-PH",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fil-ph-wavenet-b-fil-ph.mp3",
        approxDurationCoeficient: 19
      }
    ]
  },
  {
    name: "Finnish",
    male: [
      {
        voiceProvider: "azure",
        character: "Harri",
        name: "Finnish (Finland)",
        locale: "fi-FI",
        voice: "fi-FI-HarriNeural",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fi-fi-harrineural-fi-fi.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-fi-fi.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-fi-fi.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-fi-fi.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-fi-fi.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-fi-fi.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-fi-fi.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-fi-fi.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-fi-fi.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-fi-fi.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-fi-fi.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-fi-fi.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-fi-fi.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-fi-fi.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-fi-fi.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-fi-fi.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-fi-fi.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-fi-fi.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-fi-fi.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-fi-fi.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-fi-fi.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-fi-fi.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-fi-fi.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-fi-fi.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-fi-fi.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-fi-fi.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-fi-fi.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-fi-fi.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Selma",
        name: "Finnish (Finland)",
        locale: "fi-FI",
        voice: "fi-FI-SelmaNeural",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fi-fi-selmaneural-fi-fi.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-fi-fi.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-fi-fi.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-fi-fi.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-fi-fi.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-fi-fi.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-fi-fi.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-fi-fi.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-fi-fi.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-fi-fi.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-fi-fi.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-fi-fi.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-fi-fi.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-fi-fi.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Finnish (Finland)",
        locale: "fi-FI",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-fi-fi.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Noora",
        name: "Finnish (Finland)",
        locale: "fi-FI",
        voice: "fi-FI-NooraNeural",
        icon: "fi",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fi-fi-nooraneural-fi-fi.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Finnish-FI",
        voice: "fi-FI-Wavenet-A",
        icon: "fi",
        voiceProvider: "google",
        locale: "fi-FI",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fi-fi-wavenet-a-fi-fi.mp3"
      }
    ]
  },
  {
    name: "French",
    male: [
      {
        voiceProvider: "azure",
        character: "Remy M",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-RemyMultilingualNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-remymultilingualneural-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Jerome",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-JeromeNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-jeromeneural-fr-fr.mp3",
        approxDurationCoeficient: 22
      },
      {
        voiceProvider: "azure",
        character: "Henri",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-HenriNeural",
        icon: "fr",
        styleList: ["cheerful", "sad"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-henrineural:cheerful-fr-fr.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-henrineural:sad-fr-fr.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-henrineural-fr-fr.mp3",
        approxDurationCoeficient: 25
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-fr-fr.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-fr-fr.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-fr-fr.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-fr-fr.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-fr-fr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-fr-fr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-fr-fr.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-fr-fr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-fr-fr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-fr-fr.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-fr-fr.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-fr-fr.mp3",
        approxDurationCoeficient: 24
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-fr-fr.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-fr-fr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-fr-fr.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-fr-fr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-fr-fr.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-fr-fr.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-fr-fr.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-fr-fr.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-fr-fr.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-fr-fr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Gerard",
        name: "French (Belgium)",
        locale: "fr-BE",
        voice: "fr-BE-GerardNeural",
        icon: "be",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-be-gerardneural-fr-fr.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Jean",
        name: "French (Canada)",
        locale: "fr-CA",
        voice: "fr-CA-JeanNeural",
        icon: "ca",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-ca-jeanneural-fr-fr.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Antoine",
        name: "French (Canada)",
        locale: "fr-CA",
        voice: "fr-CA-AntoineNeural",
        icon: "ca",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-ca-antoineneural-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Thierry",
        name: "French (Canada)",
        locale: "fr-CA",
        voice: "fr-CA-ThierryNeural",
        icon: "ca",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-ca-thierryneural-fr-fr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Fabrice",
        name: "French (Switzerland)",
        locale: "fr-CH",
        voice: "fr-CH-FabriceNeural",
        icon: "ch",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-ch-fabriceneural-fr-fr.mp3",
        approxDurationCoeficient: 24
      },
      {
        voiceProvider: "azure",
        character: "Alain",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-AlainNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-alainneural-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Claude",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-ClaudeNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-claudeneural-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Maurice",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-MauriceNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-mauriceneural-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Yves",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-YvesNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-yvesneural-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Oscar B",
        name: "French-CA",
        voice: "fr-CA-Neural2-B",
        icon: "ca",
        voiceProvider: "google",
        locale: "fr-CA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-ca-neural2-b-fr-fr.mp3"
      },
      {
        character: "Miles D",
        name: "French-CA",
        voice: "fr-CA-Neural2-D",
        icon: "ca",
        voiceProvider: "google",
        locale: "fr-CA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-ca-neural2-d-fr-fr.mp3"
      },
      {
        character: "James B",
        name: "French-FR",
        voice: "fr-FR-Neural2-B",
        icon: "fr",
        voiceProvider: "google",
        locale: "fr-FR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-fr-neural2-b-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Emmet D",
        name: "French-FR",
        voice: "fr-FR-Neural2-D",
        icon: "fr",
        voiceProvider: "google",
        locale: "fr-FR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-fr-neural2-d-fr-fr.mp3",
        approxDurationCoeficient: 20
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Vivienne M",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-VivienneMultilingualNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-viviennemultilingualneural-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Jacqueline",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-JacquelineNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-jacquelineneural-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-fr-fr.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-fr-fr.mp3",
        approxDurationCoeficient: 23
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-fr-fr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-fr-fr.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-fr-fr.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-fr-fr.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-fr-fr.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-fr-fr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "French (France)",
        locale: "fr-FR",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Charline",
        name: "French (Belgium)",
        locale: "fr-BE",
        voice: "fr-BE-CharlineNeural",
        icon: "be",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-be-charlineneural-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Sylvie",
        name: "French (Canada)",
        locale: "fr-CA",
        voice: "fr-CA-SylvieNeural",
        icon: "ca",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-ca-sylvieneural-fr-fr.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Ariane",
        name: "French (Switzerland)",
        locale: "fr-CH",
        voice: "fr-CH-ArianeNeural",
        icon: "ch",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-ch-arianeneural-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Denise",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-DeniseNeural",
        icon: "fr",
        styleList: ["cheerful", "sad"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-deniseneural:cheerful-fr-fr.mp3",
          sad: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-deniseneural:sad-fr-fr.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-deniseneural-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Brigitte",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-BrigitteNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-brigitteneural-fr-fr.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Celeste",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-CelesteNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-celesteneural-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Coralie",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-CoralieNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-coralieneural-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Eloise",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-EloiseNeural",
        icon: "fr",
        tags: ["child"],
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-eloiseneural-fr-fr.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Josephine",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-JosephineNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-josephineneural-fr-fr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Yvette",
        name: "French (France)",
        locale: "fr-FR",
        voice: "fr-FR-YvetteNeural",
        icon: "fr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fr-fr-yvetteneural-fr-fr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Elanor A",
        name: "French-CA",
        voice: "fr-CA-Neural2-A",
        icon: "ca",
        voiceProvider: "google",
        locale: "fr-CA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-ca-neural2-a-fr-fr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Lucy C",
        name: "French-CA",
        voice: "fr-CA-Neural2-C",
        icon: "ca",
        voiceProvider: "google",
        locale: "fr-CA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-ca-neural2-c-fr-fr.mp3",
        approxDurationCoeficient: 26
      },
      {
        character: "Daisy A",
        name: "French-FR",
        voice: "fr-FR-Neural2-A",
        icon: "fr",
        voiceProvider: "google",
        locale: "fr-FR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-fr-neural2-a-fr-fr.mp3"
      },
      {
        character: "Scarlett C",
        name: "French-FR",
        voice: "fr-FR-Neural2-C",
        icon: "fr",
        voiceProvider: "google",
        locale: "fr-FR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-fr-neural2-c-fr-fr.mp3"
      },
      {
        character: "Jane E",
        name: "French-FR",
        voice: "fr-FR-Neural2-E",
        icon: "fr",
        voiceProvider: "google",
        locale: "fr-FR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-fr-fr-neural2-e-fr-fr.mp3"
      }
    ]
  },
  {
    name: "Galician",
    male: [
      {
        voiceProvider: "azure",
        character: "Roi",
        name: "Galician",
        locale: "gl-ES",
        voice: "gl-ES-RoiNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-gl-es-roineural-gl-es.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sabela",
        name: "Galician",
        locale: "gl-ES",
        voice: "gl-ES-SabelaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-gl-es-sabelaneural-gl-es.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Georgian",
    male: [
      {
        voiceProvider: "azure",
        character: "Giorgi",
        name: "Georgian (Georgia)",
        locale: "ka-GE",
        voice: "ka-GE-GiorgiNeural",
        icon: "ge",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ka-ge-giorgineural-ka-ge.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Eka",
        name: "Georgian (Georgia)",
        locale: "ka-GE",
        voice: "ka-GE-EkaNeural",
        icon: "ge",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ka-ge-ekaneural-ka-ge.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "German",
    male: [
      {
        voiceProvider: "azure",
        character: "Conrad",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-ConradNeural",
        icon: "de",
        styleList: ["cheerful"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-conradneural:cheerful-de-de.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-conradneural-de-de.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Florian M",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-FlorianMultilingualNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-florianmultilingualneural-de-de.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Killian",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-KillianNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-killianneural-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-de-de.mp3",
        approxDurationCoeficient: 23
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-de-de.mp3",
        approxDurationCoeficient: 29
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-de-de.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-de-de.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-de-de.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-de-de.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-de-de.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-de-de.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-de-de.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Jonas",
        name: "German (Austria)",
        locale: "de-AT",
        voice: "de-AT-JonasNeural",
        icon: "at",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-at-jonasneural-de-de.mp3",
        approxDurationCoeficient: 24
      },
      {
        voiceProvider: "azure",
        character: "Jan",
        name: "German (Switzerland)",
        locale: "de-CH",
        voice: "de-CH-JanNeural",
        icon: "ch",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-ch-janneural-de-de.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Bernd",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-BerndNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-berndneural-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Christoph",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-ChristophNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-christophneural-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Kasper",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-KasperNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-kasperneural-de-de.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Klaus",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-KlausNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-klausneural-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Ralf",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-RalfNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-ralfneural-de-de.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Noah B",
        name: "German-DE",
        voice: "de-DE-Neural2-B",
        icon: "de",
        voiceProvider: "google",
        locale: "de-DE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-de-de-neural2-b-de-de.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Matteo D",
        name: "German-DE",
        voice: "de-DE-Neural2-D",
        icon: "de",
        voiceProvider: "google",
        locale: "de-DE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-de-de-neural2-d-de-de.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Finn E",
        name: "German-DE",
        voice: "de-DE-Wavenet-E",
        icon: "de",
        voiceProvider: "google",
        locale: "de-DE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-de-de-wavenet-e-de-de.mp3",
        approxDurationCoeficient: 20
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Seraphina M",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-SeraphinaMultilingualNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-seraphinamultilingualneural-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Louisa",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-LouisaNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-louisaneural-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Elke",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-ElkeNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-elkeneural-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-de-de.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-de-de.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-de-de.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-de-de.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-de-de.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-de-de.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-de-de.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "German (Germany)",
        locale: "de-DE",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Ingrid",
        name: "German (Austria)",
        locale: "de-AT",
        voice: "de-AT-IngridNeural",
        icon: "at",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-at-ingridneural-de-de.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Leni",
        name: "German (Switzerland)",
        locale: "de-CH",
        voice: "de-CH-LeniNeural",
        icon: "ch",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-ch-lenineural-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Katja",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-KatjaNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-katjaneural-de-de.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Amala",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-AmalaNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-amalaneural-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Gisela",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-GiselaNeural",
        icon: "de",
        tags: ["child"],
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-giselaneural-de-de.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Klarissa",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-KlarissaNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-klarissaneural-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Maja",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-MajaNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-majaneural-de-de.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Tanja",
        name: "German (Germany)",
        locale: "de-DE",
        voice: "de-DE-TanjaNeural",
        icon: "de",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-de-de-tanjaneural-de-de.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Emilia A",
        name: "German-DE",
        voice: "de-DE-Neural2-A",
        icon: "de",
        voiceProvider: "google",
        locale: "de-DE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-de-de-neural2-a-de-de.mp3"
      },
      {
        character: "Mia C",
        name: "German-DE",
        voice: "de-DE-Neural2-C",
        icon: "de",
        voiceProvider: "google",
        locale: "de-DE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-de-de-neural2-c-de-de.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Hannah F",
        name: "German-DE",
        voice: "de-DE-Neural2-F",
        icon: "de",
        voiceProvider: "google",
        locale: "de-DE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-de-de-neural2-f-de-de.mp3",
        approxDurationCoeficient: 22
      }
    ]
  },
  {
    name: "Greek",
    male: [
      {
        voiceProvider: "azure",
        character: "Nestoras",
        name: "Greek (Greece)",
        locale: "el-GR",
        voice: "el-GR-NestorasNeural",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-el-gr-nestorasneural-el-gr.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-el-gr.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-el-gr.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-el-gr.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-el-gr.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-el-gr.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-el-gr.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-el-gr.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-el-gr.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-el-gr.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-el-gr.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-el-gr.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-el-gr.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-el-gr.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-el-gr.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-el-gr.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-el-gr.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-el-gr.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-el-gr.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-el-gr.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-el-gr.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-el-gr.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-el-gr.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-el-gr.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-el-gr.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-el-gr.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-el-gr.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-el-gr.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Athina",
        name: "Greek (Greece)",
        locale: "el-GR",
        voice: "el-GR-AthinaNeural",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-el-gr-athinaneural-el-gr.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-el-gr.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-el-gr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-el-gr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-el-gr.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-el-gr.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-el-gr.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-el-gr.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-el-gr.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-el-gr.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-el-gr.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-el-gr.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-el-gr.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-el-gr.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Greek (Greece)",
        locale: "el-GR",
        icon: "gr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-el-gr.mp3"
      },
      {
        character: "Elanor A",
        name: "Greek-GR",
        voice: "el-GR-Wavenet-A",
        icon: "gr",
        voiceProvider: "google",
        locale: "el-GR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-el-gr-wavenet-a-el-gr.mp3",
        approxDurationCoeficient: 20
      }
    ]
  },
  {
    name: "Gujarati",
    male: [
      {
        voiceProvider: "azure",
        character: "Niranjan",
        name: "Gujarati (India)",
        locale: "gu-IN",
        voice: "gu-IN-NiranjanNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-gu-in-niranjanneural-gu-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Oscar B",
        name: "Gujarati-IN",
        voice: "gu-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "gu-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-gu-in-wavenet-b-gu-in.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Miles D",
        name: "Gujarati-IN",
        voice: "gu-IN-Wavenet-D",
        icon: "in",
        voiceProvider: "google",
        locale: "gu-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-gu-in-wavenet-d-gu-in.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Dhwani",
        name: "Gujarati (India)",
        locale: "gu-IN",
        voice: "gu-IN-DhwaniNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-gu-in-dhwanineural-gu-in.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Elanor A",
        name: "Gujarati-IN",
        voice: "gu-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "gu-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-gu-in-wavenet-a-gu-in.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Lucy C",
        name: "Gujarati-IN",
        voice: "gu-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "gu-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-gu-in-wavenet-c-gu-in.mp3"
      }
    ]
  },
  {
    name: "Hebrew",
    male: [
      {
        voiceProvider: "azure",
        character: "Avri",
        name: "Hebrew (Israel)",
        locale: "he-IL",
        voice: "he-IL-AvriNeural",
        icon: "il",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-he-il-avrineural-he-il.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Oscar B",
        name: "Hebrew-IL",
        voice: "he-IL-Wavenet-B",
        icon: "il",
        voiceProvider: "google",
        locale: "he-IL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-he-il-wavenet-b-he-il.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Miles D",
        name: "Hebrew-IL",
        voice: "he-IL-Wavenet-D",
        icon: "il",
        voiceProvider: "google",
        locale: "he-IL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-he-il-wavenet-d-he-il.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Hila",
        name: "Hebrew (Israel)",
        locale: "he-IL",
        voice: "he-IL-HilaNeural",
        icon: "il",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-he-il-hilaneural-he-il.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Elanor A",
        name: "Hebrew-IL",
        voice: "he-IL-Wavenet-A",
        icon: "il",
        voiceProvider: "google",
        locale: "he-IL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-he-il-wavenet-a-he-il.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Lucy C",
        name: "Hebrew-IL",
        voice: "he-IL-Wavenet-C",
        icon: "il",
        voiceProvider: "google",
        locale: "he-IL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-he-il-wavenet-c-he-il.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Hindi",
    male: [
      {
        voiceProvider: "azure",
        character: "Madhur",
        name: "Hindi (India)",
        locale: "hi-IN",
        voice: "hi-IN-MadhurNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hi-in-madhurneural-hi-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-hi-in.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-hi-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-hi-in.mp3",
        approxDurationCoeficient: 34
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-hi-in.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-hi-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-hi-in.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-hi-in.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-hi-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-hi-in.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-hi-in.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-hi-in.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-hi-in.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-hi-in.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-hi-in.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-hi-in.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-hi-in.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-hi-in.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-hi-in.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-hi-in.mp3",
        approxDurationCoeficient: 23
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-hi-in.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-hi-in.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-hi-in.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-hi-in.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-hi-in.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-hi-in.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-hi-in.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-hi-in.mp3"
      },
      {
        character: "Oscar B",
        name: "Hindi-IN",
        voice: "hi-IN-Neural2-B",
        icon: "in",
        voiceProvider: "google",
        locale: "hi-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-hi-in-neural2-b-hi-in.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Miles C",
        name: "Hindi-IN",
        voice: "hi-IN-Neural2-C",
        icon: "in",
        voiceProvider: "google",
        locale: "hi-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-hi-in-neural2-c-hi-in.mp3",
        approxDurationCoeficient: 47
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Swara",
        name: "Hindi (India)",
        locale: "hi-IN",
        voice: "hi-IN-SwaraNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hi-in-swaraneural-hi-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-hi-in.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-hi-in.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-hi-in.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-hi-in.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-hi-in.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-hi-in.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-hi-in.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-hi-in.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-hi-in.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-hi-in.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-hi-in.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-hi-in.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-hi-in.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Hindi (India)",
        locale: "hi-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-hi-in.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Elanor A",
        name: "Hindi-IN",
        voice: "hi-IN-Neural2-A",
        icon: "in",
        voiceProvider: "google",
        locale: "hi-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-hi-in-neural2-a-hi-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Lucy D",
        name: "Hindi-IN",
        voice: "hi-IN-Neural2-D",
        icon: "in",
        voiceProvider: "google",
        locale: "hi-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-hi-in-neural2-d-hi-in.mp3",
        approxDurationCoeficient: 13
      }
    ]
  },
  {
    name: "Hungarian",
    male: [
      {
        voiceProvider: "azure",
        character: "Tamas",
        name: "Hungarian (Hungary)",
        locale: "hu-HU",
        voice: "hu-HU-TamasNeural",
        icon: "hu",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hu-hu-tamasneural-hu-hu.mp3",
        approxDurationCoeficient: 20
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Noemi",
        name: "Hungarian (Hungary)",
        locale: "hu-HU",
        voice: "hu-HU-NoemiNeural",
        icon: "hu",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-hu-hu-noemineural-hu-hu.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Hungarian-HU",
        voice: "hu-HU-Wavenet-A",
        icon: "hu",
        voiceProvider: "google",
        locale: "hu-HU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-hu-hu-wavenet-a-hu-hu.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Icelandic",
    male: [
      {
        voiceProvider: "azure",
        character: "Gunnar",
        name: "Icelandic (Iceland)",
        locale: "is-IS",
        voice: "is-IS-GunnarNeural",
        icon: "is",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-is-is-gunnarneural-is-is.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Gudrun",
        name: "Icelandic (Iceland)",
        locale: "is-IS",
        voice: "is-IS-GudrunNeural",
        icon: "is",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-is-is-gudrunneural-is-is.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Indonesian",
    male: [
      {
        voiceProvider: "azure",
        character: "Ardi",
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        voice: "id-ID-ArdiNeural",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-id-id-ardineural-id-id.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-id-id.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-id-id.mp3",
        approxDurationCoeficient: 8
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-id-id.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-id-id.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-id-id.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-id-id.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-id-id.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-id-id.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-id-id.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-id-id.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-id-id.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-id-id.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-id-id.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-id-id.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-id-id.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-id-id.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-id-id.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-id-id.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-id-id.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-id-id.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-id-id.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-id-id.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-id-id.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-id-id.mp3",
        approxDurationCoeficient: 25
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-id-id.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-id-id.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-id-id.mp3"
      },
      {
        character: "Oscar B",
        name: "Indonesian-ID",
        voice: "id-ID-Wavenet-B",
        icon: "id",
        voiceProvider: "google",
        locale: "id-ID",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-id-id-wavenet-b-id-id.mp3",
        approxDurationCoeficient: 25
      },
      {
        character: "Miles C",
        name: "Indonesian-ID",
        voice: "id-ID-Wavenet-C",
        icon: "id",
        voiceProvider: "google",
        locale: "id-ID",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-id-id-wavenet-c-id-id.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Gadis",
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        voice: "id-ID-GadisNeural",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-id-id-gadisneural-id-id.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-id-id.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-id-id.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-id-id.mp3",
        approxDurationCoeficient: 26
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-id-id.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-id-id.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-id-id.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-id-id.mp3",
        approxDurationCoeficient: 25
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-id-id.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-id-id.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-id-id.mp3",
        approxDurationCoeficient: 27
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-id-id.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-id-id.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-id-id.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Indonesian (Indonesia)",
        locale: "id-ID",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-id-id.mp3"
      },
      {
        character: "Elanor A",
        name: "Indonesian-ID",
        voice: "id-ID-Wavenet-A",
        icon: "id",
        voiceProvider: "google",
        locale: "id-ID",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-id-id-wavenet-a-id-id.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Lucy D",
        name: "Indonesian-ID",
        voice: "id-ID-Wavenet-D",
        icon: "id",
        voiceProvider: "google",
        locale: "id-ID",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-id-id-wavenet-d-id-id.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Irish",
    male: [
      {
        voiceProvider: "azure",
        character: "Colm",
        name: "Irish (Ireland)",
        locale: "ga-IE",
        voice: "ga-IE-ColmNeural",
        icon: "ie",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ga-ie-colmneural-ga-ie.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Orla",
        name: "Irish (Ireland)",
        locale: "ga-IE",
        voice: "ga-IE-OrlaNeural",
        icon: "ie",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ga-ie-orlaneural-ga-ie.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Italian",
    male: [
      {
        voiceProvider: "azure",
        character: "Diego",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-DiegoNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-diegoneural-it-it.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-it-it.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-it-it.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-it-it.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-it-it.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-it-it.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-it-it.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-it-it.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-it-it.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-it-it.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-it-it.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-it-it.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-it-it.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-it-it.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-it-it.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-it-it.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-it-it.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-it-it.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-it-it.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-it-it.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-it-it.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-it-it.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-it-it.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-it-it.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-it-it.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-it-it.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-it-it.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-it-it.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Benigno",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-BenignoNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-benignoneural-it-it.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Calimero",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-CalimeroNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-calimeroneural-it-it.mp3",
        approxDurationCoeficient: 25
      },
      {
        voiceProvider: "azure",
        character: "Cataldo",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-CataldoNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-cataldoneural-it-it.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Gianni",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-GianniNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-giannineural-it-it.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Giuseppe",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-GiuseppeNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-giuseppeneural-it-it.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Lisandro",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-LisandroNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-lisandroneural-it-it.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Rinaldo",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-RinaldoNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-rinaldoneural-it-it.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Oscar C",
        name: "Italian-IT",
        voice: "it-IT-Neural2-C",
        icon: "it",
        voiceProvider: "google",
        locale: "it-IT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-it-it-neural2-c-it-it.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Miles D",
        name: "Italian-IT",
        voice: "it-IT-Wavenet-D",
        icon: "it",
        voiceProvider: "google",
        locale: "it-IT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-it-it-wavenet-d-it-it.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Elsa",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-ElsaNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-elsaneural-it-it.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-it-it.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-it-it.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-it-it.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-it-it.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-it-it.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-it-it.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-it-it.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-it-it.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-it-it.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-it-it.mp3",
        approxDurationCoeficient: 26
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-it-it.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-it-it.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-it-it.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Italian (Italy)",
        locale: "it-IT",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-it-it.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Isabella",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-IsabellaNeural",
        icon: "it",
        styleList: ["cheerful", "chat"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-isabellaneural:cheerful-it-it.mp3",
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-isabellaneural:chat-it-it.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-isabellaneural-it-it.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Fabiola",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-FabiolaNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-fabiolaneural-it-it.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Fiamma",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-FiammaNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-fiammaneural-it-it.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Imelda",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-ImeldaNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-imeldaneural-it-it.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Irma",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-IrmaNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-irmaneural-it-it.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Palmira",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-PalmiraNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-palmiraneural-it-it.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Pierina",
        name: "Italian (Italy)",
        locale: "it-IT",
        voice: "it-IT-PierinaNeural",
        icon: "it",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-it-it-pierinaneural-it-it.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Elanor A",
        name: "Italian-IT",
        voice: "it-IT-Neural2-A",
        icon: "it",
        voiceProvider: "google",
        locale: "it-IT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-it-it-neural2-a-it-it.mp3"
      },
      {
        character: "Lucy B",
        name: "Italian-IT",
        voice: "it-IT-Wavenet-B",
        icon: "it",
        voiceProvider: "google",
        locale: "it-IT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-it-it-wavenet-b-it-it.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Japanese",
    male: [
      {
        voiceProvider: "azure",
        character: "Keita",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-KeitaNeural",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-keitaneural-ja-jp.mp3",
        approxDurationCoeficient: 7
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-ja-jp.mp3",
        approxDurationCoeficient: 7
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-ja-jp.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-ja-jp.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-ja-jp.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-ja-jp.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-ja-jp.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-ja-jp.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-ja-jp.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-ja-jp.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-ja-jp.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-ja-jp.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-ja-jp.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-ja-jp.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-ja-jp.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-ja-jp.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-ja-jp.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-ja-jp.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-ja-jp.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-ja-jp.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-ja-jp.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-ja-jp.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-ja-jp.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-ja-jp.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-ja-jp.mp3",
        approxDurationCoeficient: 6
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-ja-jp.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-ja-jp.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-ja-jp.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Daichi",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-DaichiNeural",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-daichineural-ja-jp.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Naoki",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-NaokiNeural",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-naokineural-ja-jp.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Oscar C",
        name: "Japanese-JP",
        voice: "ja-JP-Neural2-C",
        icon: "jp",
        voiceProvider: "google",
        locale: "ja-JP",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ja-jp-neural2-c-ja-jp.mp3",
        approxDurationCoeficient: 7
      },
      {
        character: "Miles D",
        name: "Japanese-JP",
        voice: "ja-JP-Neural2-D",
        icon: "jp",
        voiceProvider: "google",
        locale: "ja-JP",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ja-jp-neural2-d-ja-jp.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Nanami",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-NanamiNeural",
        icon: "jp",
        styleList: ["chat", "customerservice", "cheerful"],
        stylePreview: {
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-nanamineural:chat-ja-jp.mp3",
          customerservice: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-nanamineural:customerservice-ja-jp.mp3",
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-nanamineural:cheerful-ja-jp.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-nanamineural-ja-jp.mp3",
        approxDurationCoeficient: 6
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-ja-jp.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-ja-jp.mp3",
        approxDurationCoeficient: 6
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-ja-jp.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-ja-jp.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-ja-jp.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-ja-jp.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-ja-jp.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-ja-jp.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-ja-jp.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-ja-jp.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-ja-jp.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-ja-jp.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-ja-jp.mp3",
        approxDurationCoeficient: 3
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Japanese (Japan)",
        locale: "ja-JP",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-ja-jp.mp3",
        approxDurationCoeficient: 6
      },
      {
        voiceProvider: "azure",
        character: "Aoi",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-AoiNeural",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-aoineural-ja-jp.mp3",
        approxDurationCoeficient: 9
      },
      {
        voiceProvider: "azure",
        character: "Mayu",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-MayuNeural",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-mayuneural-ja-jp.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Shiori",
        name: "Japanese (Japan)",
        locale: "ja-JP",
        voice: "ja-JP-ShioriNeural",
        icon: "jp",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ja-jp-shiorineural-ja-jp.mp3"
      },
      {
        character: "Elanor B",
        name: "Japanese-JP",
        voice: "ja-JP-Neural2-B",
        icon: "jp",
        voiceProvider: "google",
        locale: "ja-JP",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ja-jp-neural2-b-ja-jp.mp3",
        approxDurationCoeficient: 3
      },
      {
        character: "Lucy A",
        name: "Japanese-JP",
        voice: "ja-JP-Wavenet-A",
        icon: "jp",
        voiceProvider: "google",
        locale: "ja-JP",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ja-jp-wavenet-a-ja-jp.mp3",
        approxDurationCoeficient: 8
      }
    ]
  },
  {
    name: "Javanese",
    male: [
      {
        voiceProvider: "azure",
        character: "Dimas",
        name: "Javanese (Latin, Indonesia)",
        locale: "jv-ID",
        voice: "jv-ID-DimasNeural",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-jv-id-dimasneural-jv-id.mp3",
        approxDurationCoeficient: 25
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Siti",
        name: "Javanese (Latin, Indonesia)",
        locale: "jv-ID",
        voice: "jv-ID-SitiNeural",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-jv-id-sitineural-jv-id.mp3",
        approxDurationCoeficient: 13
      }
    ]
  },
  {
    name: "Kannada",
    male: [
      {
        voiceProvider: "azure",
        character: "Gagan",
        name: "Kannada (India)",
        locale: "kn-IN",
        voice: "kn-IN-GaganNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-kn-in-gaganneural-kn-in.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Oscar B",
        name: "Kannada-IN",
        voice: "kn-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "kn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-kn-in-wavenet-b-kn-in.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Miles D",
        name: "Kannada-IN",
        voice: "kn-IN-Wavenet-D",
        icon: "in",
        voiceProvider: "google",
        locale: "kn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-kn-in-wavenet-d-kn-in.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sapna",
        name: "Kannada (India)",
        locale: "kn-IN",
        voice: "kn-IN-SapnaNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-kn-in-sapnaneural-kn-in.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Elanor A",
        name: "Kannada-IN",
        voice: "kn-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "kn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-kn-in-wavenet-a-kn-in.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Lucy C",
        name: "Kannada-IN",
        voice: "kn-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "kn-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-kn-in-wavenet-c-kn-in.mp3"
      }
    ]
  },
  {
    name: "Kazakh",
    male: [
      {
        voiceProvider: "azure",
        character: "Daulet",
        name: "Kazakh (Kazakhstan)",
        locale: "kk-KZ",
        voice: "kk-KZ-DauletNeural",
        icon: "kz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-kk-kz-dauletneural-kk-kz.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Aigul",
        name: "Kazakh (Kazakhstan)",
        locale: "kk-KZ",
        voice: "kk-KZ-AigulNeural",
        icon: "kz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-kk-kz-aigulneural-kk-kz.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Khmer",
    male: [
      {
        voiceProvider: "azure",
        character: "Piseth",
        name: "Khmer (Cambodia)",
        locale: "km-KH",
        voice: "km-KH-PisethNeural",
        icon: "kh",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-km-kh-pisethneural-km-kh.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sreymom",
        name: "Khmer (Cambodia)",
        locale: "km-KH",
        voice: "km-KH-SreymomNeural",
        icon: "kh",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-km-kh-sreymomneural-km-kh.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Korean",
    male: [
      {
        voiceProvider: "azure",
        character: "InJoon",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-InJoonNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-injoonneural-ko-kr.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-ko-kr.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-ko-kr.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-ko-kr.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-ko-kr.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-ko-kr.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-ko-kr.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-ko-kr.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-ko-kr.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-ko-kr.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-ko-kr.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-ko-kr.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-ko-kr.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-ko-kr.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-ko-kr.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-ko-kr.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-ko-kr.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-ko-kr.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-ko-kr.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-ko-kr.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-ko-kr.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-ko-kr.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-ko-kr.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-ko-kr.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-ko-kr.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-ko-kr.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-ko-kr.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-ko-kr.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "BongJin",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-BongJinNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-bongjinneural-ko-kr.mp3",
        approxDurationCoeficient: 7
      },
      {
        voiceProvider: "azure",
        character: "GookMin",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-GookMinNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-gookminneural-ko-kr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Hyunsu",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-HyunsuNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-hyunsuneural-ko-kr.mp3"
      },
      {
        character: "Oscar C",
        name: "Korean-KR",
        voice: "ko-KR-Neural2-C",
        icon: "kr",
        voiceProvider: "google",
        locale: "ko-KR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ko-kr-neural2-c-ko-kr.mp3"
      },
      {
        character: "Miles D",
        name: "Korean-KR",
        voice: "ko-KR-Wavenet-D",
        icon: "kr",
        voiceProvider: "google",
        locale: "ko-KR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ko-kr-wavenet-d-ko-kr.mp3",
        approxDurationCoeficient: 9
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sun-Hi",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-SunHiNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-sunhineural-ko-kr.mp3",
        approxDurationCoeficient: 7
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-ko-kr.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-ko-kr.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-ko-kr.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-ko-kr.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-ko-kr.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-ko-kr.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-ko-kr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-ko-kr.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-ko-kr.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-ko-kr.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-ko-kr.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-ko-kr.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-ko-kr.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Korean (Korea)",
        locale: "ko-KR",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-ko-kr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "JiMin",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-JiMinNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-jiminneural-ko-kr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "SeoHyeon",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-SeoHyeonNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-seohyeonneural-ko-kr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "SoonBok",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-SoonBokNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-soonbokneural-ko-kr.mp3"
      },
      {
        voiceProvider: "azure",
        character: "YuJin",
        name: "Korean (Korea)",
        locale: "ko-KR",
        voice: "ko-KR-YuJinNeural",
        icon: "kr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ko-kr-yujinneural-ko-kr.mp3"
      },
      {
        character: "Elanor A",
        name: "Korean-KR",
        voice: "ko-KR-Neural2-A",
        icon: "kr",
        voiceProvider: "google",
        locale: "ko-KR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ko-kr-neural2-a-ko-kr.mp3"
      },
      {
        character: "Lucy B",
        name: "Korean-KR",
        voice: "ko-KR-Neural2-B",
        icon: "kr",
        voiceProvider: "google",
        locale: "ko-KR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ko-kr-neural2-b-ko-kr.mp3"
      }
    ]
  },
  {
    name: "Lao",
    male: [
      {
        voiceProvider: "azure",
        character: "Chanthavong",
        name: "Lao (Laos)",
        locale: "lo-LA",
        voice: "lo-LA-ChanthavongNeural",
        icon: "la",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-lo-la-chanthavongneural-lo-la.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Keomany",
        name: "Lao (Laos)",
        locale: "lo-LA",
        voice: "lo-LA-KeomanyNeural",
        icon: "la",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-lo-la-keomanyneural-lo-la.mp3",
        approxDurationCoeficient: 13
      }
    ]
  },
  {
    name: "Latvian",
    male: [
      {
        voiceProvider: "azure",
        character: "Nils",
        name: "Latvian (Latvia)",
        locale: "lv-LV",
        voice: "lv-LV-NilsNeural",
        icon: "lv",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-lv-lv-nilsneural-lv-lv.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Everita",
        name: "Latvian (Latvia)",
        locale: "lv-LV",
        voice: "lv-LV-EveritaNeural",
        icon: "lv",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-lv-lv-everitaneural-lv-lv.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Lithuanian",
    male: [
      {
        voiceProvider: "azure",
        character: "Leonas",
        name: "Lithuanian (Lithuania)",
        locale: "lt-LT",
        voice: "lt-LT-LeonasNeural",
        icon: "lt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-lt-lt-leonasneural-lt-lt.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Ona",
        name: "Lithuanian (Lithuania)",
        locale: "lt-LT",
        voice: "lt-LT-OnaNeural",
        icon: "lt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-lt-lt-onaneural-lt-lt.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Macedonian",
    male: [
      {
        voiceProvider: "azure",
        character: "Aleksandar",
        name: "Macedonian (North Macedonia)",
        locale: "mk-MK",
        voice: "mk-MK-AleksandarNeural",
        icon: "mk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mk-mk-aleksandarneural-mk-mk.mp3",
        approxDurationCoeficient: 20
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Marija",
        name: "Macedonian (North Macedonia)",
        locale: "mk-MK",
        voice: "mk-MK-MarijaNeural",
        icon: "mk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mk-mk-marijaneural-mk-mk.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Malay",
    male: [
      {
        voiceProvider: "azure",
        character: "Osman",
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        voice: "ms-MY-OsmanNeural",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ms-my-osmanneural-ms-my.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-ms-my.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-ms-my.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-ms-my.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-ms-my.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-ms-my.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-ms-my.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-ms-my.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-ms-my.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-ms-my.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-ms-my.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-ms-my.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-ms-my.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-ms-my.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-ms-my.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-ms-my.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-ms-my.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-ms-my.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-ms-my.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-ms-my.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-ms-my.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-ms-my.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-ms-my.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-ms-my.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-ms-my.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-ms-my.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-ms-my.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-ms-my.mp3"
      },
      {
        character: "Oscar B",
        name: "Malay-MY",
        voice: "ms-MY-Wavenet-B",
        icon: "my",
        voiceProvider: "google",
        locale: "ms-MY",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ms-my-wavenet-b-ms-my.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Miles D",
        name: "Malay-MY",
        voice: "ms-MY-Wavenet-D",
        icon: "my",
        voiceProvider: "google",
        locale: "ms-MY",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ms-my-wavenet-d-ms-my.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Yasmin",
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        voice: "ms-MY-YasminNeural",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ms-my-yasminneural-ms-my.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-ms-my.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-ms-my.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-ms-my.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-ms-my.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-ms-my.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-ms-my.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-ms-my.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-ms-my.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-ms-my.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-ms-my.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-ms-my.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-ms-my.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-ms-my.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Malay (Malaysia)",
        locale: "ms-MY",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-ms-my.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Malay-MY",
        voice: "ms-MY-Wavenet-A",
        icon: "my",
        voiceProvider: "google",
        locale: "ms-MY",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ms-my-wavenet-a-ms-my.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Lucy C",
        name: "Malay-MY",
        voice: "ms-MY-Wavenet-C",
        icon: "my",
        voiceProvider: "google",
        locale: "ms-MY",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ms-my-wavenet-c-ms-my.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Malayalam",
    male: [
      {
        voiceProvider: "azure",
        character: "Midhun",
        name: "Malayalam (India)",
        locale: "ml-IN",
        voice: "ml-IN-MidhunNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ml-in-midhunneural-ml-in.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Oscar B",
        name: "Malayalam-IN",
        voice: "ml-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "ml-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ml-in-wavenet-b-ml-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Miles D",
        name: "Malayalam-IN",
        voice: "ml-IN-Wavenet-D",
        icon: "in",
        voiceProvider: "google",
        locale: "ml-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ml-in-wavenet-d-ml-in.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sobhana",
        name: "Malayalam (India)",
        locale: "ml-IN",
        voice: "ml-IN-SobhanaNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ml-in-sobhananeural-ml-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Elanor A",
        name: "Malayalam-IN",
        voice: "ml-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "ml-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ml-in-wavenet-a-ml-in.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Lucy C",
        name: "Malayalam-IN",
        voice: "ml-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "ml-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ml-in-wavenet-c-ml-in.mp3",
        approxDurationCoeficient: 21
      }
    ]
  },
  {
    name: "Maltese",
    male: [
      {
        voiceProvider: "azure",
        character: "Joseph",
        name: "Maltese (Malta)",
        locale: "mt-MT",
        voice: "mt-MT-JosephNeural",
        icon: "mt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mt-mt-josephneural-mt-mt.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Grace",
        name: "Maltese (Malta)",
        locale: "mt-MT",
        voice: "mt-MT-GraceNeural",
        icon: "mt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mt-mt-graceneural-mt-mt.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Marathi",
    male: [
      {
        voiceProvider: "azure",
        character: "Manohar",
        name: "Marathi (India)",
        locale: "mr-IN",
        voice: "mr-IN-ManoharNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mr-in-manoharneural-mr-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Oscar B",
        name: "Marathi-IN",
        voice: "mr-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "mr-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-mr-in-wavenet-b-mr-in.mp3",
        approxDurationCoeficient: 46
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Aarohi",
        name: "Marathi (India)",
        locale: "mr-IN",
        voice: "mr-IN-AarohiNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mr-in-aarohineural-mr-in.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Elanor A",
        name: "Marathi-IN",
        voice: "mr-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "mr-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-mr-in-wavenet-a-mr-in.mp3",
        approxDurationCoeficient: 38
      },
      {
        character: "Lucy C",
        name: "Marathi-IN",
        voice: "mr-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "mr-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-mr-in-wavenet-c-mr-in.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Mongolian",
    male: [
      {
        voiceProvider: "azure",
        character: "Bataa",
        name: "Mongolian (Mongolia)",
        locale: "mn-MN",
        voice: "mn-MN-BataaNeural",
        icon: "mn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mn-mn-bataaneural-mn-mn.mp3",
        approxDurationCoeficient: 20
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Yesui",
        name: "Mongolian (Mongolia)",
        locale: "mn-MN",
        voice: "mn-MN-YesuiNeural",
        icon: "mn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-mn-mn-yesuineural-mn-mn.mp3",
        approxDurationCoeficient: 18
      }
    ]
  },
  {
    name: "Nepali",
    male: [
      {
        voiceProvider: "azure",
        character: "Sagar",
        name: "Nepali (Nepal)",
        locale: "ne-NP",
        voice: "ne-NP-SagarNeural",
        icon: "np",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ne-np-sagarneural-ne-np.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Hemkala",
        name: "Nepali (Nepal)",
        locale: "ne-NP",
        voice: "ne-NP-HemkalaNeural",
        icon: "np",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ne-np-hemkalaneural-ne-np.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Norwegian",
    male: [
      {
        voiceProvider: "azure",
        character: "Finn",
        name: "Norwegian Bokmål (Norway)",
        locale: "nb-NO",
        voice: "nb-NO-FinnNeural",
        icon: "no",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nb-no-finnneural-nb-no.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Oscar B",
        name: "Norwegian-NO",
        voice: "nb-NO-Wavenet-B",
        icon: "no",
        voiceProvider: "google",
        locale: "nb-NO",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nb-no-wavenet-b-nb-no.mp3",
        approxDurationCoeficient: 8
      },
      {
        character: "Miles D",
        name: "Norwegian-NO",
        voice: "nb-NO-Wavenet-D",
        icon: "no",
        voiceProvider: "google",
        locale: "nb-NO",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nb-no-wavenet-d-nb-no.mp3",
        approxDurationCoeficient: 11
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Pernille",
        name: "Norwegian Bokmål (Norway)",
        locale: "nb-NO",
        voice: "nb-NO-PernilleNeural",
        icon: "no",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nb-no-pernilleneural-nb-no.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Iselin",
        name: "Norwegian Bokmål (Norway)",
        locale: "nb-NO",
        voice: "nb-NO-IselinNeural",
        icon: "no",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-nb-no-iselinneural-nb-no.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Norwegian-NO",
        voice: "nb-NO-Wavenet-A",
        icon: "no",
        voiceProvider: "google",
        locale: "nb-NO",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nb-no-wavenet-a-nb-no.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Lucy C",
        name: "Norwegian-NO",
        voice: "nb-NO-Wavenet-C",
        icon: "no",
        voiceProvider: "google",
        locale: "nb-NO",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nb-no-wavenet-c-nb-no.mp3",
        approxDurationCoeficient: 24
      },
      {
        character: "Daisy E",
        name: "Norwegian-NO",
        voice: "nb-NO-Wavenet-E",
        icon: "no",
        voiceProvider: "google",
        locale: "nb-NO",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-nb-no-wavenet-e-nb-no.mp3",
        approxDurationCoeficient: 20
      }
    ]
  },
  {
    name: "Pashto",
    male: [
      {
        voiceProvider: "azure",
        character: "Gul Nawaz",
        name: "Pashto (Afghanistan)",
        locale: "ps-AF",
        voice: "ps-AF-GulNawazNeural",
        icon: "af",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ps-af-gulnawazneural-ps-af.mp3",
        approxDurationCoeficient: 17
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Latifa",
        name: "Pashto (Afghanistan)",
        locale: "ps-AF",
        voice: "ps-AF-LatifaNeural",
        icon: "af",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ps-af-latifaneural-ps-af.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Persian",
    male: [
      {
        voiceProvider: "azure",
        character: "Farid",
        name: "Persian (Iran)",
        locale: "fa-IR",
        voice: "fa-IR-FaridNeural",
        icon: "ir",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fa-ir-faridneural-fa-ir.mp3",
        approxDurationCoeficient: 14
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Dilara",
        name: "Persian (Iran)",
        locale: "fa-IR",
        voice: "fa-IR-DilaraNeural",
        icon: "ir",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-fa-ir-dilaraneural-fa-ir.mp3",
        approxDurationCoeficient: 13
      }
    ]
  },
  {
    name: "Polish",
    male: [
      {
        voiceProvider: "azure",
        character: "Marek",
        name: "Polish (Poland)",
        locale: "pl-PL",
        voice: "pl-PL-MarekNeural",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pl-pl-marekneural-pl-pl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-pl-pl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-pl-pl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-pl-pl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-pl-pl.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-pl-pl.mp3",
        approxDurationCoeficient: 25
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-pl-pl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-pl-pl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-pl-pl.mp3",
        approxDurationCoeficient: 22
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-pl-pl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-pl-pl.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-pl-pl.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-pl-pl.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-pl-pl.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-pl-pl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-pl-pl.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-pl-pl.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-pl-pl.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-pl-pl.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-pl-pl.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-pl-pl.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-pl-pl.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-pl-pl.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-pl-pl.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-pl-pl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-pl-pl.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-pl-pl.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-pl-pl.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Oscar B",
        name: "Polish-PL",
        voice: "pl-PL-Wavenet-B",
        icon: "pl",
        voiceProvider: "google",
        locale: "pl-PL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pl-pl-wavenet-b-pl-pl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Miles C",
        name: "Polish-PL",
        voice: "pl-PL-Wavenet-C",
        icon: "pl",
        voiceProvider: "google",
        locale: "pl-PL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pl-pl-wavenet-c-pl-pl.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Agnieszka",
        name: "Polish (Poland)",
        locale: "pl-PL",
        voice: "pl-PL-AgnieszkaNeural",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pl-pl-agnieszkaneural-pl-pl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-pl-pl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-pl-pl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-pl-pl.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-pl-pl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-pl-pl.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-pl-pl.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-pl-pl.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-pl-pl.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-pl-pl.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-pl-pl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-pl-pl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-pl-pl.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-pl-pl.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Polish (Poland)",
        locale: "pl-PL",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-pl-pl.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Zofia",
        name: "Polish (Poland)",
        locale: "pl-PL",
        voice: "pl-PL-ZofiaNeural",
        icon: "pl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pl-pl-zofianeural-pl-pl.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Polish-PL",
        voice: "pl-PL-Wavenet-A",
        icon: "pl",
        voiceProvider: "google",
        locale: "pl-PL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pl-pl-wavenet-a-pl-pl.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Lucy D",
        name: "Polish-PL",
        voice: "pl-PL-Wavenet-D",
        icon: "pl",
        voiceProvider: "google",
        locale: "pl-PL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pl-pl-wavenet-d-pl-pl.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Daisy E",
        name: "Polish-PL",
        voice: "pl-PL-Wavenet-E",
        icon: "pl",
        voiceProvider: "google",
        locale: "pl-PL",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pl-pl-wavenet-e-pl-pl.mp3",
        approxDurationCoeficient: 18
      }
    ]
  },
  {
    name: "Portuguese",
    male: [
      {
        voiceProvider: "azure",
        character: "Duarte",
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        voice: "pt-PT-DuarteNeural",
        icon: "pt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-pt-duarteneural-pt-pt.mp3",
        approxDurationCoeficient: 22
      },
      {
        voiceProvider: "azure",
        character: "Valerio",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-ValerioNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-valerioneural-pt-pt.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Nicolau",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-NicolauNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-nicolauneural-pt-pt.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Julio",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-JulioNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-julioneural-pt-pt.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Antonio",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-AntonioNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-antonioneural-pt-pt.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Donato",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-DonatoNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-donatoneural-pt-pt.mp3",
        approxDurationCoeficient: 25
      },
      {
        voiceProvider: "azure",
        character: "Fabio",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-FabioNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-fabioneural-pt-pt.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Humberto",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-HumbertoNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-humbertoneural-pt-pt.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Oscar B",
        name: "Portuguese-BR",
        voice: "pt-BR-Neural2-B",
        icon: "br",
        voiceProvider: "google",
        locale: "pt-BR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-br-neural2-b-pt-pt.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Miles B",
        name: "Portuguese-PT",
        voice: "pt-PT-Wavenet-B",
        icon: "pt",
        voiceProvider: "google",
        locale: "pt-PT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-pt-wavenet-b-pt-pt.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "James C",
        name: "Portuguese-PT",
        voice: "pt-PT-Wavenet-C",
        icon: "pt",
        voiceProvider: "google",
        locale: "pt-PT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-pt-wavenet-c-pt-pt.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Fernanda",
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        voice: "pt-PT-FernandaNeural",
        icon: "pt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-pt-fernandaneural-pt-pt.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Raquel",
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        voice: "pt-PT-RaquelNeural",
        icon: "pt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-pt-raquelneural-pt-pt.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Francisca",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-FranciscaNeural",
        icon: "br",
        styleList: ["calm"],
        stylePreview: {
          calm: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-franciscaneural:calm-pt-pt.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-franciscaneural-pt-pt.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Yara",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-YaraNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-yaraneural-pt-pt.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld:eleven_multilingual_v2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld:eleven_multilingual_v2-pt-pt.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2:eleven_multilingual_v1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Portuguese (Portugal)",
        locale: "pt-PT",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2:eleven_multilingual_v1-pt-pt.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Brenda",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-BrendaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-brendaneural-pt-pt.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Elza",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-ElzaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-elzaneural-pt-pt.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Giovanna",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-GiovannaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-giovannaneural-pt-pt.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Leila",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-LeilaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-leilaneural-pt-pt.mp3",
        approxDurationCoeficient: 22
      },
      {
        voiceProvider: "azure",
        character: "Leticia",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-LeticiaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-leticianeural-pt-pt.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Manuela",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-ManuelaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-manuelaneural-pt-pt.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Thalita",
        name: "Portuguese (Brazil)",
        locale: "pt-BR",
        voice: "pt-BR-ThalitaNeural",
        icon: "br",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-pt-br-thalitaneural-pt-pt.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Elanor A",
        name: "Portuguese-BR",
        voice: "pt-BR-Neural2-A",
        icon: "br",
        voiceProvider: "google",
        locale: "pt-BR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-br-neural2-a-pt-pt.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Lucy C",
        name: "Portuguese-BR",
        voice: "pt-BR-Neural2-C",
        icon: "br",
        voiceProvider: "google",
        locale: "pt-BR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-br-neural2-c-pt-pt.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Daisy A",
        name: "Portuguese-PT",
        voice: "pt-PT-Wavenet-A",
        icon: "pt",
        voiceProvider: "google",
        locale: "pt-PT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-pt-wavenet-a-pt-pt.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Scarlett D",
        name: "Portuguese-PT",
        voice: "pt-PT-Wavenet-D",
        icon: "pt",
        voiceProvider: "google",
        locale: "pt-PT",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pt-pt-wavenet-d-pt-pt.mp3",
        approxDurationCoeficient: 19
      }
    ]
  },
  {
    name: "Punjabi",
    male: [
      {
        character: "Oscar B",
        name: "Punjabi-IN",
        voice: "pa-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "pa-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pa-in-wavenet-b-pa-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Miles D",
        name: "Punjabi-IN",
        voice: "pa-IN-Wavenet-D",
        icon: "in",
        voiceProvider: "google",
        locale: "pa-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pa-in-wavenet-d-pa-in.mp3",
        approxDurationCoeficient: 13
      }
    ],
    female: [
      {
        character: "Elanor A",
        name: "Punjabi-IN",
        voice: "pa-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "pa-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pa-in-wavenet-a-pa-in.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Lucy C",
        name: "Punjabi-IN",
        voice: "pa-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "pa-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-pa-in-wavenet-c-pa-in.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Romanian",
    male: [
      {
        voiceProvider: "azure",
        character: "Emil",
        name: "Romanian (Romania)",
        locale: "ro-RO",
        voice: "ro-RO-EmilNeural",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ro-ro-emilneural-ro-ro.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-ro-ro.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-ro-ro.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-ro-ro.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-ro-ro.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-ro-ro.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-ro-ro.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-ro-ro.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-ro-ro.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-ro-ro.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-ro-ro.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-ro-ro.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-ro-ro.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-ro-ro.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-ro-ro.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-ro-ro.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-ro-ro.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-ro-ro.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-ro-ro.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-ro-ro.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-ro-ro.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-ro-ro.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-ro-ro.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-ro-ro.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-ro-ro.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-ro-ro.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-ro-ro.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-ro-ro.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Alina",
        name: "Romanian (Romania)",
        locale: "ro-RO",
        voice: "ro-RO-AlinaNeural",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ro-ro-alinaneural-ro-ro.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-ro-ro.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-ro-ro.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-ro-ro.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-ro-ro.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-ro-ro.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-ro-ro.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-ro-ro.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-ro-ro.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-ro-ro.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-ro-ro.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-ro-ro.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-ro-ro.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-ro-ro.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Romanian (Romania)",
        locale: "ro-RO",
        icon: "ro",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-ro-ro.mp3"
      },
      {
        character: "Elanor A",
        name: "Romanian-RO",
        voice: "ro-RO-Wavenet-A",
        icon: "ro",
        voiceProvider: "google",
        locale: "ro-RO",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ro-ro-wavenet-a-ro-ro.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Russian",
    male: [
      {
        voiceProvider: "azure",
        character: "Dmitry",
        name: "Russian (Russia)",
        locale: "ru-RU",
        voice: "ru-RU-DmitryNeural",
        icon: "ru",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ru-ru-dmitryneural-ru-ru.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Oscar B",
        name: "Russian-RU",
        voice: "ru-RU-Wavenet-B",
        icon: "ru",
        voiceProvider: "google",
        locale: "ru-RU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ru-ru-wavenet-b-ru-ru.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Miles D",
        name: "Russian-RU",
        voice: "ru-RU-Wavenet-D",
        icon: "ru",
        voiceProvider: "google",
        locale: "ru-RU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ru-ru-wavenet-d-ru-ru.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Svetlana",
        name: "Russian (Russia)",
        locale: "ru-RU",
        voice: "ru-RU-SvetlanaNeural",
        icon: "ru",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ru-ru-svetlananeural-ru-ru.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Dariya",
        name: "Russian (Russia)",
        locale: "ru-RU",
        voice: "ru-RU-DariyaNeural",
        icon: "ru",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ru-ru-dariyaneural-ru-ru.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Russian-RU",
        voice: "ru-RU-Wavenet-A",
        icon: "ru",
        voiceProvider: "google",
        locale: "ru-RU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ru-ru-wavenet-a-ru-ru.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Lucy C",
        name: "Russian-RU",
        voice: "ru-RU-Wavenet-C",
        icon: "ru",
        voiceProvider: "google",
        locale: "ru-RU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ru-ru-wavenet-c-ru-ru.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Daisy E",
        name: "Russian-RU",
        voice: "ru-RU-Wavenet-E",
        icon: "ru",
        voiceProvider: "google",
        locale: "ru-RU",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ru-ru-wavenet-e-ru-ru.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Serbian",
    male: [
      {
        voiceProvider: "azure",
        character: "Nicholas",
        name: "Serbian (Latin, Serbia)",
        locale: "sr-Latn-RS",
        voice: "sr-Latn-RS-NicholasNeural",
        icon: "rs",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sr-latn-rs-nicholasneural-sr-latn-rs.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Nicholas",
        name: "Serbian (Cyrillic, Serbia)",
        locale: "sr-RS",
        voice: "sr-RS-NicholasNeural",
        icon: "rs",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sr-rs-nicholasneural-sr-latn-rs.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sophie",
        name: "Serbian (Latin, Serbia)",
        locale: "sr-Latn-RS",
        voice: "sr-Latn-RS-SophieNeural",
        icon: "rs",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sr-latn-rs-sophieneural-sr-latn-rs.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Sophie",
        name: "Serbian (Cyrillic, Serbia)",
        locale: "sr-RS",
        voice: "sr-RS-SophieNeural",
        icon: "rs",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sr-rs-sophieneural-sr-latn-rs.mp3",
        approxDurationCoeficient: 18
      }
    ]
  },
  {
    name: "Sinhala",
    male: [
      {
        voiceProvider: "azure",
        character: "Sameera",
        name: "Sinhala (Sri Lanka)",
        locale: "si-LK",
        voice: "si-LK-SameeraNeural",
        icon: "lk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-si-lk-sameeraneural-si-lk.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Thilini",
        name: "Sinhala (Sri Lanka)",
        locale: "si-LK",
        voice: "si-LK-ThiliniNeural",
        icon: "lk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-si-lk-thilinineural-si-lk.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Slovak",
    male: [
      {
        voiceProvider: "azure",
        character: "Lukas",
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        voice: "sk-SK-LukasNeural",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sk-sk-lukasneural-sk-sk.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-sk-sk.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-sk-sk.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-sk-sk.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-sk-sk.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-sk-sk.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-sk-sk.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-sk-sk.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-sk-sk.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-sk-sk.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-sk-sk.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-sk-sk.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-sk-sk.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-sk-sk.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-sk-sk.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-sk-sk.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-sk-sk.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-sk-sk.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-sk-sk.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-sk-sk.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-sk-sk.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-sk-sk.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-sk-sk.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-sk-sk.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-sk-sk.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-sk-sk.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-sk-sk.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-sk-sk.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Viktoria",
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        voice: "sk-SK-ViktoriaNeural",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sk-sk-viktorianeural-sk-sk.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-sk-sk.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-sk-sk.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-sk-sk.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-sk-sk.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-sk-sk.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-sk-sk.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-sk-sk.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-sk-sk.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-sk-sk.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-sk-sk.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-sk-sk.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-sk-sk.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-sk-sk.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Slovak (Slovakia)",
        locale: "sk-SK",
        icon: "sk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-sk-sk.mp3"
      },
      {
        character: "Elanor A",
        name: "Slovak-SK",
        voice: "sk-SK-Wavenet-A",
        icon: "sk",
        voiceProvider: "google",
        locale: "sk-SK",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-sk-sk-wavenet-a-sk-sk.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Slovenian",
    male: [
      {
        voiceProvider: "azure",
        character: "Rok",
        name: "Slovenian (Slovenia)",
        locale: "sl-SI",
        voice: "sl-SI-RokNeural",
        icon: "si",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sl-si-rokneural-sl-si.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Petra",
        name: "Slovenian (Slovenia)",
        locale: "sl-SI",
        voice: "sl-SI-PetraNeural",
        icon: "si",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sl-si-petraneural-sl-si.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Somali",
    male: [
      {
        voiceProvider: "azure",
        character: "Muuse",
        name: "Somali (Somalia)",
        locale: "so-SO",
        voice: "so-SO-MuuseNeural",
        icon: "so",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-so-so-muuseneural-so-so.mp3",
        approxDurationCoeficient: 21
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Ubax",
        name: "Somali (Somalia)",
        locale: "so-SO",
        voice: "so-SO-UbaxNeural",
        icon: "so",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-so-so-ubaxneural-so-so.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Spanish",
    male: [
      {
        voiceProvider: "azure",
        character: "Saul",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-SaulNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-saulneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Arnau",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-ArnauNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-arnauneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Teo",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-TeoNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-teoneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Jorge",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-JorgeNeural",
        icon: "mx",
        styleList: ["cheerful", "chat"],
        stylePreview: {
          cheerful: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-jorgeneural:cheerful-es-es.mp3",
          chat: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-jorgeneural:chat-es-es.mp3"
        },
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-jorgeneural-es-es.mp3",
        approxDurationCoeficient: 22
      },
      {
        voiceProvider: "azure",
        character: "Cecilio",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-CecilioNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-cecilioneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-es-es.mp3",
        approxDurationCoeficient: 27
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-es-es.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-es-es.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-es-es.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-es-es.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-es-es.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-es-es.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-es-es.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-es-es.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-es-es.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-es-es.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-es-es.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-es-es.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-es-es.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-es-es.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-es-es.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-es-es.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Alvaro",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-AlvaroNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-alvaroneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Dario",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-DarioNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-darioneural-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Elias",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-EliasNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-eliasneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Nil",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-NilNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-nilneural-es-es.mp3"
      },
      {
        character: "Hugo B",
        name: "Spanish-ES",
        voice: "es-ES-Neural2-B",
        icon: "es",
        voiceProvider: "google",
        locale: "es-ES",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-es-neural2-b-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Arlo F",
        name: "Spanish-ES",
        voice: "es-ES-Neural2-F",
        icon: "es",
        voiceProvider: "google",
        locale: "es-ES",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-es-neural2-f-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Gerardo",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-GerardoNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-gerardoneural-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Liberto",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-LibertoNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-libertoneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Luciano",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-LucianoNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-lucianoneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Pelayo",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-PelayoNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-pelayoneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Yago",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-YagoNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-yagoneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Tomas",
        name: "Spanish (Argentina)",
        locale: "es-AR",
        voice: "es-AR-TomasNeural",
        icon: "ar",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ar-tomasneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Marcelo",
        name: "Spanish (Bolivia)",
        locale: "es-BO",
        voice: "es-BO-MarceloNeural",
        icon: "bo",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-bo-marceloneural-es-es.mp3",
        approxDurationCoeficient: 22
      },
      {
        voiceProvider: "azure",
        character: "Lorenzo",
        name: "Spanish (Chile)",
        locale: "es-CL",
        voice: "es-CL-LorenzoNeural",
        icon: "cl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-cl-lorenzoneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Gonzalo",
        name: "Spanish (Colombia)",
        locale: "es-CO",
        voice: "es-CO-GonzaloNeural",
        icon: "co",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-co-gonzaloneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Juan",
        name: "Spanish (Costa Rica)",
        locale: "es-CR",
        voice: "es-CR-JuanNeural",
        icon: "cr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-cr-juanneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Manuel",
        name: "Spanish (Cuba)",
        locale: "es-CU",
        voice: "es-CU-ManuelNeural",
        icon: "cu",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-cu-manuelneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Emilio",
        name: "Spanish (Dominican Republic)",
        locale: "es-DO",
        voice: "es-DO-EmilioNeural",
        icon: "do",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-do-emilioneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Luis",
        name: "Spanish (Ecuador)",
        locale: "es-EC",
        voice: "es-EC-LuisNeural",
        icon: "ec",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ec-luisneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Javier",
        name: "Spanish (Equatorial Guinea)",
        locale: "es-GQ",
        voice: "es-GQ-JavierNeural",
        icon: "gq",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-gq-javierneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Andres",
        name: "Spanish (Guatemala)",
        locale: "es-GT",
        voice: "es-GT-AndresNeural",
        icon: "gt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-gt-andresneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Carlos",
        name: "Spanish (Honduras)",
        locale: "es-HN",
        voice: "es-HN-CarlosNeural",
        icon: "hn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-hn-carlosneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Federico",
        name: "Spanish (Nicaragua)",
        locale: "es-NI",
        voice: "es-NI-FedericoNeural",
        icon: "ni",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ni-federiconeural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Roberto",
        name: "Spanish (Panama)",
        locale: "es-PA",
        voice: "es-PA-RobertoNeural",
        icon: "pa",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-pa-robertoneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Alex",
        name: "Spanish (Peru)",
        locale: "es-PE",
        voice: "es-PE-AlexNeural",
        icon: "pe",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-pe-alexneural-es-es.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Victor",
        name: "Spanish (Puerto Rico)",
        locale: "es-PR",
        voice: "es-PR-VictorNeural",
        icon: "pr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-pr-victorneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Mario",
        name: "Spanish (Paraguay)",
        locale: "es-PY",
        voice: "es-PY-MarioNeural",
        icon: "py",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-py-marioneural-es-es.mp3",
        approxDurationCoeficient: 25
      },
      {
        voiceProvider: "azure",
        character: "Rodrigo",
        name: "Spanish (El Salvador)",
        locale: "es-SV",
        voice: "es-SV-RodrigoNeural",
        icon: "sv",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-sv-rodrigoneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Alonso",
        name: "Spanish (United States)",
        locale: "es-US",
        voice: "es-US-AlonsoNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-us-alonsoneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Mateo",
        name: "Spanish (Uruguay)",
        locale: "es-UY",
        voice: "es-UY-MateoNeural",
        icon: "uy",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-uy-mateoneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Sebastian",
        name: "Spanish (Venezuela)",
        locale: "es-VE",
        voice: "es-VE-SebastianNeural",
        icon: "ve",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ve-sebastianneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Mateo B",
        name: "Spanish-US",
        voice: "es-US-Neural2-B",
        icon: "us",
        voiceProvider: "google",
        locale: "es-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-us-neural2-b-es-es.mp3",
        approxDurationCoeficient: 26
      },
      {
        character: "Diego C",
        name: "Spanish-US",
        voice: "es-US-Neural2-C",
        icon: "us",
        voiceProvider: "google",
        locale: "es-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-us-neural2-c-es-es.mp3",
        approxDurationCoeficient: 26
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Elvira",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-ElviraNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-elviraneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Abril",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-AbrilNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-abrilneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Estrella",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-EstrellaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-estrellaneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Carlota",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-CarlotaNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-carlotaneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Beatriz",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-BeatrizNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-beatrizneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-es-es.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-es-es.mp3",
        approxDurationCoeficient: 8
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-es-es.mp3",
        approxDurationCoeficient: 26
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-es-es.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Spanish (Spain)",
        locale: "es-ES",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Irene",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-IreneNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-ireneneural-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Laia",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-LaiaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-laianeural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Lia",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-LiaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-lianeural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Triana",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-TrianaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-triananeural-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Vera",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-VeraNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-veraneural-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Ximena",
        name: "Spanish (Spain)",
        locale: "es-ES",
        voice: "es-ES-XimenaNeural",
        icon: "es",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-es-ximenaneural-es-es.mp3"
      },
      {
        character: "Isla A",
        name: "Spanish-ES",
        voice: "es-ES-Neural2-A",
        icon: "es",
        voiceProvider: "google",
        locale: "es-ES",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-es-neural2-a-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elena C",
        name: "Spanish-ES",
        voice: "es-ES-Neural2-C",
        icon: "es",
        voiceProvider: "google",
        locale: "es-ES",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-es-neural2-c-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Lucia D",
        name: "Spanish-ES",
        voice: "es-ES-Neural2-D",
        icon: "es",
        voiceProvider: "google",
        locale: "es-ES",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-es-neural2-d-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Maya E",
        name: "Spanish-ES",
        voice: "es-ES-Neural2-E",
        icon: "es",
        voiceProvider: "google",
        locale: "es-ES",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-es-neural2-e-es-es.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Dalia",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-DaliaNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-dalianeural-es-es.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Candela",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-CandelaNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-candelaneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Larissa",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-LarissaNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-larissaneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Marina",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-MarinaNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-marinaneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Nuria",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-NuriaNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-nurianeural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Renata",
        name: "Spanish (Mexico)",
        locale: "es-MX",
        voice: "es-MX-RenataNeural",
        icon: "mx",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-mx-renataneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Elena",
        name: "Spanish (Argentina)",
        locale: "es-AR",
        voice: "es-AR-ElenaNeural",
        icon: "ar",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ar-elenaneural-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Sofia",
        name: "Spanish (Bolivia)",
        locale: "es-BO",
        voice: "es-BO-SofiaNeural",
        icon: "bo",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-bo-sofianeural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Catalina",
        name: "Spanish (Chile)",
        locale: "es-CL",
        voice: "es-CL-CatalinaNeural",
        icon: "cl",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-cl-catalinaneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Salome",
        name: "Spanish (Colombia)",
        locale: "es-CO",
        voice: "es-CO-SalomeNeural",
        icon: "co",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-co-salomeneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Maria",
        name: "Spanish (Costa Rica)",
        locale: "es-CR",
        voice: "es-CR-MariaNeural",
        icon: "cr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-cr-marianeural-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Belkys",
        name: "Spanish (Cuba)",
        locale: "es-CU",
        voice: "es-CU-BelkysNeural",
        icon: "cu",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-cu-belkysneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Ramona",
        name: "Spanish (Dominican Republic)",
        locale: "es-DO",
        voice: "es-DO-RamonaNeural",
        icon: "do",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-do-ramonaneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Andrea",
        name: "Spanish (Ecuador)",
        locale: "es-EC",
        voice: "es-EC-AndreaNeural",
        icon: "ec",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ec-andreaneural-es-es.mp3",
        approxDurationCoeficient: 21
      },
      {
        voiceProvider: "azure",
        character: "Teresa",
        name: "Spanish (Equatorial Guinea)",
        locale: "es-GQ",
        voice: "es-GQ-TeresaNeural",
        icon: "gq",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-gq-teresaneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Marta",
        name: "Spanish (Guatemala)",
        locale: "es-GT",
        voice: "es-GT-MartaNeural",
        icon: "gt",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-gt-martaneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Karla",
        name: "Spanish (Honduras)",
        locale: "es-HN",
        voice: "es-HN-KarlaNeural",
        icon: "hn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-hn-karlaneural-es-es.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Yolanda",
        name: "Spanish (Nicaragua)",
        locale: "es-NI",
        voice: "es-NI-YolandaNeural",
        icon: "ni",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ni-yolandaneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        voiceProvider: "azure",
        character: "Margarita",
        name: "Spanish (Panama)",
        locale: "es-PA",
        voice: "es-PA-MargaritaNeural",
        icon: "pa",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-pa-margaritaneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Camila",
        name: "Spanish (Peru)",
        locale: "es-PE",
        voice: "es-PE-CamilaNeural",
        icon: "pe",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-pe-camilaneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Karina",
        name: "Spanish (Puerto Rico)",
        locale: "es-PR",
        voice: "es-PR-KarinaNeural",
        icon: "pr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-pr-karinaneural-es-es.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Tania",
        name: "Spanish (Paraguay)",
        locale: "es-PY",
        voice: "es-PY-TaniaNeural",
        icon: "py",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-py-tanianeural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Lorena",
        name: "Spanish (El Salvador)",
        locale: "es-SV",
        voice: "es-SV-LorenaNeural",
        icon: "sv",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-sv-lorenaneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Paloma",
        name: "Spanish (United States)",
        locale: "es-US",
        voice: "es-US-PalomaNeural",
        icon: "us",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-us-palomaneural-es-es.mp3",
        approxDurationCoeficient: 20
      },
      {
        voiceProvider: "azure",
        character: "Valentina",
        name: "Spanish (Uruguay)",
        locale: "es-UY",
        voice: "es-UY-ValentinaNeural",
        icon: "uy",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-uy-valentinaneural-es-es.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Paola",
        name: "Spanish (Venezuela)",
        locale: "es-VE",
        voice: "es-VE-PaolaNeural",
        icon: "ve",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-es-ve-paolaneural-es-es.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Lola A",
        name: "Spanish-US",
        voice: "es-US-Neural2-A",
        icon: "us",
        voiceProvider: "google",
        locale: "es-US",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-es-us-neural2-a-es-es.mp3",
        approxDurationCoeficient: 18
      }
    ]
  },
  {
    name: "Sundanese",
    male: [
      {
        voiceProvider: "azure",
        character: "Jajang",
        name: "Sundanese (Indonesia)",
        locale: "su-ID",
        voice: "su-ID-JajangNeural",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-su-id-jajangneural-su-id.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Tuti",
        name: "Sundanese (Indonesia)",
        locale: "su-ID",
        voice: "su-ID-TutiNeural",
        icon: "id",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-su-id-tutineural-su-id.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Swahili",
    male: [
      {
        voiceProvider: "azure",
        character: "Rafiki",
        name: "Swahili (Kenya)",
        locale: "sw-KE",
        voice: "sw-KE-RafikiNeural",
        icon: "ke",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sw-ke-rafikineural-sw-ke.mp3",
        approxDurationCoeficient: 19
      },
      {
        voiceProvider: "azure",
        character: "Daudi",
        name: "Swahili (Tanzania)",
        locale: "sw-TZ",
        voice: "sw-TZ-DaudiNeural",
        icon: "tz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sw-tz-daudineural-sw-ke.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Zuri",
        name: "Swahili (Kenya)",
        locale: "sw-KE",
        voice: "sw-KE-ZuriNeural",
        icon: "ke",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sw-ke-zurineural-sw-ke.mp3",
        approxDurationCoeficient: 18
      },
      {
        voiceProvider: "azure",
        character: "Rehema",
        name: "Swahili (Tanzania)",
        locale: "sw-TZ",
        voice: "sw-TZ-RehemaNeural",
        icon: "tz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sw-tz-rehemaneural-sw-ke.mp3",
        approxDurationCoeficient: 14
      }
    ]
  },
  {
    name: "Swedish",
    male: [
      {
        voiceProvider: "azure",
        character: "Mattias",
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        voice: "sv-SE-MattiasNeural",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sv-se-mattiasneural-sv-se.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-sv-se.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-sv-se.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-sv-se.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-sv-se.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-sv-se.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-sv-se.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-sv-se.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-sv-se.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-sv-se.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-sv-se.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-sv-se.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-sv-se.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-sv-se.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-sv-se.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-sv-se.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-sv-se.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-sv-se.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-sv-se.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-sv-se.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-sv-se.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-sv-se.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-sv-se.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-sv-se.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-sv-se.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-sv-se.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-sv-se.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-sv-se.mp3"
      },
      {
        character: "Oscar C",
        name: "Swedish-SE",
        voice: "sv-SE-Wavenet-C",
        icon: "se",
        voiceProvider: "google",
        locale: "sv-SE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-sv-se-wavenet-c-sv-se.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Miles E",
        name: "Swedish-SE",
        voice: "sv-SE-Wavenet-E",
        icon: "se",
        voiceProvider: "google",
        locale: "sv-SE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-sv-se-wavenet-e-sv-se.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Sofie",
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        voice: "sv-SE-SofieNeural",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sv-se-sofieneural-sv-se.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-sv-se.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-sv-se.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-sv-se.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-sv-se.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-sv-se.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-sv-se.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-sv-se.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-sv-se.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-sv-se.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-sv-se.mp3",
        approxDurationCoeficient: 20
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-sv-se.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-sv-se.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-sv-se.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-sv-se.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Hillevi",
        name: "Swedish (Sweden)",
        locale: "sv-SE",
        voice: "sv-SE-HilleviNeural",
        icon: "se",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-sv-se-hillevineural-sv-se.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Elanor A",
        name: "Swedish-SE",
        voice: "sv-SE-Wavenet-A",
        icon: "se",
        voiceProvider: "google",
        locale: "sv-SE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-sv-se-wavenet-a-sv-se.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Lucy B",
        name: "Swedish-SE",
        voice: "sv-SE-Wavenet-B",
        icon: "se",
        voiceProvider: "google",
        locale: "sv-SE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-sv-se-wavenet-b-sv-se.mp3"
      },
      {
        character: "Daisy D",
        name: "Swedish-SE",
        voice: "sv-SE-Wavenet-D",
        icon: "se",
        voiceProvider: "google",
        locale: "sv-SE",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-sv-se-wavenet-d-sv-se.mp3",
        approxDurationCoeficient: 26
      }
    ]
  },
  {
    name: "Tamil",
    male: [
      {
        voiceProvider: "azure",
        character: "Valluvar",
        name: "Tamil (India)",
        locale: "ta-IN",
        voice: "ta-IN-ValluvarNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-in-valluvarneural-ta-in.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-ta-in.mp3"
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-ta-in.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-ta-in.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-ta-in.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-ta-in.mp3"
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-ta-in.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-ta-in.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-ta-in.mp3"
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-ta-in.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-ta-in.mp3"
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-ta-in.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-ta-in.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-ta-in.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-ta-in.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-ta-in.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-ta-in.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-ta-in.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-ta-in.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-ta-in.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-ta-in.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-ta-in.mp3"
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-ta-in.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-ta-in.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-ta-in.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-ta-in.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-ta-in.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-ta-in.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Kumar",
        name: "Tamil (Sri Lanka)",
        locale: "ta-LK",
        voice: "ta-LK-KumarNeural",
        icon: "lk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-lk-kumarneural-ta-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Surya",
        name: "Tamil (Malaysia)",
        locale: "ta-MY",
        voice: "ta-MY-SuryaNeural",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-my-suryaneural-ta-in.mp3",
        approxDurationCoeficient: 17
      },
      {
        voiceProvider: "azure",
        character: "Anbu",
        name: "Tamil (Singapore)",
        locale: "ta-SG",
        voice: "ta-SG-AnbuNeural",
        icon: "sg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-sg-anbuneural-ta-in.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Oscar B",
        name: "Tamil-IN",
        voice: "ta-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "ta-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ta-in-wavenet-b-ta-in.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Miles D",
        name: "Tamil-IN",
        voice: "ta-IN-Wavenet-D",
        icon: "in",
        voiceProvider: "google",
        locale: "ta-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ta-in-wavenet-d-ta-in.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Pallavi",
        name: "Tamil (India)",
        locale: "ta-IN",
        voice: "ta-IN-PallaviNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-in-pallavineural-ta-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-ta-in.mp3",
        approxDurationCoeficient: 26
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-ta-in.mp3"
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-ta-in.mp3"
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-ta-in.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-ta-in.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-ta-in.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-ta-in.mp3"
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-ta-in.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-ta-in.mp3"
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-ta-in.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-ta-in.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-ta-in.mp3"
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-ta-in.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Tamil (India)",
        locale: "ta-IN",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-ta-in.mp3"
      },
      {
        voiceProvider: "azure",
        character: "Saranya",
        name: "Tamil (Sri Lanka)",
        locale: "ta-LK",
        voice: "ta-LK-SaranyaNeural",
        icon: "lk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-lk-saranyaneural-ta-in.mp3",
        approxDurationCoeficient: 12
      },
      {
        voiceProvider: "azure",
        character: "Kani",
        name: "Tamil (Malaysia)",
        locale: "ta-MY",
        voice: "ta-MY-KaniNeural",
        icon: "my",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-my-kanineural-ta-in.mp3",
        approxDurationCoeficient: 23
      },
      {
        voiceProvider: "azure",
        character: "Venba",
        name: "Tamil (Singapore)",
        locale: "ta-SG",
        voice: "ta-SG-VenbaNeural",
        icon: "sg",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ta-sg-venbaneural-ta-in.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Elanor A",
        name: "Tamil-IN",
        voice: "ta-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "ta-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ta-in-wavenet-a-ta-in.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Lucy C",
        name: "Tamil-IN",
        voice: "ta-IN-Wavenet-C",
        icon: "in",
        voiceProvider: "google",
        locale: "ta-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ta-in-wavenet-c-ta-in.mp3",
        approxDurationCoeficient: 18
      }
    ]
  },
  {
    name: "Telugu",
    male: [
      {
        voiceProvider: "azure",
        character: "Mohan",
        name: "Telugu (India)",
        locale: "te-IN",
        voice: "te-IN-MohanNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-te-in-mohanneural-te-in.mp3",
        approxDurationCoeficient: 13
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Shruti",
        name: "Telugu (India)",
        locale: "te-IN",
        voice: "te-IN-ShrutiNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-te-in-shrutineural-te-in.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Thai",
    male: [
      {
        voiceProvider: "azure",
        character: "Niwat",
        name: "Thai (Thailand)",
        locale: "th-TH",
        voice: "th-TH-NiwatNeural",
        icon: "th",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-th-th-niwatneural-th-th.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Premwadee",
        name: "Thai (Thailand)",
        locale: "th-TH",
        voice: "th-TH-PremwadeeNeural",
        icon: "th",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-th-th-premwadeeneural-th-th.mp3",
        approxDurationCoeficient: 14
      },
      {
        voiceProvider: "azure",
        character: "Achara",
        name: "Thai (Thailand)",
        locale: "th-TH",
        voice: "th-TH-AcharaNeural",
        icon: "th",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-th-th-acharaneural-th-th.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Elanor C",
        name: "Thai-TH",
        voice: "th-TH-Neural2-C",
        icon: "th",
        voiceProvider: "google",
        locale: "th-TH",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-th-th-neural2-c-th-th.mp3",
        approxDurationCoeficient: 12
      }
    ]
  },
  {
    name: "Turkish",
    male: [
      {
        voiceProvider: "azure",
        character: "Ahmet",
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        voice: "tr-TR-AhmetNeural",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-tr-tr-ahmetneural-tr-tr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-tr-tr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-tr-tr.mp3"
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-tr-tr.mp3"
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-tr-tr.mp3"
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-tr-tr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-tr-tr.mp3"
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-tr-tr.mp3"
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-tr-tr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-tr-tr.mp3"
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-tr-tr.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-tr-tr.mp3"
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-tr-tr.mp3"
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-tr-tr.mp3"
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-tr-tr.mp3"
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-tr-tr.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-tr-tr.mp3"
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-tr-tr.mp3"
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-tr-tr.mp3"
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-tr-tr.mp3"
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-tr-tr.mp3"
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-tr-tr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-tr-tr.mp3"
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-tr-tr.mp3"
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-tr-tr.mp3"
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-tr-tr.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-tr-tr.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-tr-tr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Oscar B",
        name: "Turkish-TR",
        voice: "tr-TR-Wavenet-B",
        icon: "tr",
        voiceProvider: "google",
        locale: "tr-TR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-tr-tr-wavenet-b-tr-tr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Miles E",
        name: "Turkish-TR",
        voice: "tr-TR-Wavenet-E",
        icon: "tr",
        voiceProvider: "google",
        locale: "tr-TR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-tr-tr-wavenet-e-tr-tr.mp3",
        approxDurationCoeficient: 18
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Emel",
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        voice: "tr-TR-EmelNeural",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-tr-tr-emelneural-tr-tr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-tr-tr.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-tr-tr.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-tr-tr.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-tr-tr.mp3"
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-tr-tr.mp3"
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-tr-tr.mp3"
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-tr-tr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-tr-tr.mp3"
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-tr-tr.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-tr-tr.mp3"
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-tr-tr.mp3"
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-tr-tr.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-tr-tr.mp3"
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Turkish (Turkey)",
        locale: "tr-TR",
        icon: "tr",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-tr-tr.mp3"
      },
      {
        character: "Elanor A",
        name: "Turkish-TR",
        voice: "tr-TR-Wavenet-A",
        icon: "tr",
        voiceProvider: "google",
        locale: "tr-TR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-tr-tr-wavenet-a-tr-tr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Lucy C",
        name: "Turkish-TR",
        voice: "tr-TR-Wavenet-C",
        icon: "tr",
        voiceProvider: "google",
        locale: "tr-TR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-tr-tr-wavenet-c-tr-tr.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Daisy D",
        name: "Turkish-TR",
        voice: "tr-TR-Wavenet-D",
        icon: "tr",
        voiceProvider: "google",
        locale: "tr-TR",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-tr-tr-wavenet-d-tr-tr.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Ukrainian",
    male: [
      {
        voiceProvider: "azure",
        character: "Ostap",
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        voice: "uk-UA-OstapNeural",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-uk-ua-ostapneural-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Antoni",
        voice: "ErXwobaYiN019PkySvjV",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "calm"],
        order: 1,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-erxwobayin019pkysvjv-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Josh",
        voice: "TxGEqnHWrfWFTfGW9XjX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "young"],
        order: 2,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-txgeqnhwrfwftfgw9xjx-uk-ua.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Arnold",
        voice: "VR6AewLTigWG4xSOukaG",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["crisp", "middle-aged"],
        order: 3,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-vr6aewltigwg4xsoukag-uk-ua.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Adam",
        voice: "pNInz6obpgDQGcFmaJgB",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 4,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pninz6obpgdqgcfmajgb-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Bill",
        voice: "pqHfZKP75CvOlQylNhV4",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 5,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pqhfzkp75cvolqylnhv4-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Jessie",
        voice: "t0jbNlBVZ17f02VDIeMI",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "old"],
        order: 6,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-t0jbnlbvz17f02vdiemi-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Michael",
        voice: "flq6f7yk4E4fJM5XTYuZ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["orutund", "old"],
        order: 7,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-flq6f7yk4e4fjm5xtyuz-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Liam",
        voice: "TX3LPaxmHKxFdv7VOQHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["neutral", "young"],
        order: 8,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tx3lpaxmhkxfdv7voqhj-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Harry",
        voice: "SOYHLrjzK2X1ezoPC6cr",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["anxious", "young"],
        order: 9,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-soyhlrjzk2x1ezopc6cr-uk-ua.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Patrick",
        voice: "ODq5zmih8GrVes37Dizd",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["shouty", "middle-aged"],
        order: 10,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-odq5zmih8grves37dizd-uk-ua.mp3",
        approxDurationCoeficient: 21
      },
      {
        character: "Thomas",
        voice: "GBv7mTt0atIp3Br8iCZE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 11,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-gbv7mtt0atip3br8icze-uk-ua.mp3",
        approxDurationCoeficient: 11
      },
      {
        character: "Drew",
        voice: "29vD33N1CtxCmqQRPOHJ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["well-rounded", "middle-aged"],
        order: 12,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-29vd33n1ctxcmqqrpohj-uk-ua.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Paul",
        voice: "5Q0t7uMcjvnagumLfvZi",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["narration", "middle-aged"],
        order: 13,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-5q0t7umcjvnagumlfvzi-uk-ua.mp3",
        approxDurationCoeficient: 6
      },
      {
        character: "Callum",
        voice: "N2lVS1w4EtoT3dr4eOWO",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 14,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-n2lvs1w4etot3dr4eowo-uk-ua.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Giovanni",
        voice: "zcAOhNBS3c14rBihAFp1",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["italian-accent", "young"],
        order: 15,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zcaohnbs3c14rbihafp1-uk-ua.mp3"
      },
      {
        character: "Clyde",
        voice: "2EiwWnXFnvU5JabPnv8n",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["masculine", "middle-aged"],
        order: 17,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-2eiwwnxfnvu5jabpnv8n-uk-ua.mp3",
        approxDurationCoeficient: 6
      },
      {
        character: "George",
        voice: "JBFqnCBsd6RMkjVDRZzb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "narration"],
        order: 19,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbfqncbsd6rmkjvdrzzb-uk-ua.mp3",
        approxDurationCoeficient: 4
      },
      {
        character: "Dave",
        voice: "CYw3kZ02Hs0563khs1Fj",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["essex-accent", "young"],
        order: 20,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-cyw3kz02hs0563khs1fj-uk-ua.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Joseph",
        voice: "Zlb1dXrM653N07WRdFW3",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "middle-aged"],
        order: 21,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zlb1dxrm653n07wrdfw3-uk-ua.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Daniel",
        voice: "onwK4e9ZLuTAKqWW03F9",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["deep", "middle-aged"],
        order: 22,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-onwk4e9zlutakqww03f9-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Charlie",
        voice: "IKne3meq5aSn9XLyUdCD",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["casual", "middle-aged"],
        order: 23,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ikne3meq5asn9xlyudcd-uk-ua.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "James",
        voice: "ZQe5CZNOzWyzPSCn5a3c",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "old"],
        order: 24,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zqe5cznozwyzpscn5a3c-uk-ua.mp3",
        approxDurationCoeficient: 12
      },
      {
        character: "Fin",
        voice: "D38z5RcWu1voky8WS1ja",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["sailor", "old"],
        order: 25,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-d38z5rcwu1voky8ws1ja-uk-ua.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Jeremy",
        voice: "bVMeCyTHy58xNoL34h3p",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["excited", "young"],
        order: 26,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-bvmecythy58xnol34h3p-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Chris",
        voice: "iP95p4xoKVk53GoZ742B",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-ip95p4xokvk53goz742b-uk-ua.mp3"
      },
      {
        character: "Brian",
        voice: "nPczCjzI2devNBz1zQrb",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-npczcjzi2devnbz1zqrb-uk-ua.mp3"
      },
      {
        character: "Sam",
        voice: "yoZ06aMxZJJ28mfd3POQ",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["fast-paced", "young"],
        order: 99999,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-yoz06amxzjj28mfd3poq-uk-ua.mp3",
        approxDurationCoeficient: 7
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Polina",
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        voice: "uk-UA-PolinaNeural",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-uk-ua-polinaneural-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Rachel",
        voice: "21m00Tcm4TlvDq8ikWAM",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 1,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-21m00tcm4tlvdq8ikwam-uk-ua.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Matilda",
        voice: "XrExE9yKIg1WjnnlVkGX",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 3,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xrexe9ykig1wjnnlvkgx-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Grace",
        voice: "oWAxZDx7w5VEj9dCyTzz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["southern-accent", "young"],
        order: 4,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-owaxzdx7w5vej9dcytzz-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Domi",
        voice: "AZnzlk1XvdvUeBnXmlld",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["upbeat", "young"],
        order: 5,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-aznzlk1xvdvuebnxmlld-uk-ua.mp3",
        approxDurationCoeficient: 19
      },
      {
        character: "Gigi",
        voice: "jBpfuIE2acCO8z3wKNLl",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 6,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-jbpfuie2acco8z3wknll-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Serena",
        voice: "pMsXgVXv3BLzUgSXRplE",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 7,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pmsxgvxv3blzugsxrple-uk-ua.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Glinda",
        voice: "z9fAnlkpzviPz146aGWa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["strong", "middle-aged"],
        order: 8,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-z9fanlkpzvipz146agwa-uk-ua.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Mimi",
        voice: "zrHiDhphv9ZnVXBqCLjz",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["childish", "young"],
        order: 9,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-zrhidhphv9znvxbqcljz-uk-ua.mp3",
        approxDurationCoeficient: 10
      },
      {
        character: "Emily",
        voice: "LcfcDJNUP1GQjkzn1xUU",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "middle-aged"],
        order: 10,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-lcfcdjnup1gqjkzn1xuu-uk-ua.mp3",
        approxDurationCoeficient: 9
      },
      {
        character: "Lily",
        voice: "pFZP5JQG7iQjIQuC4Bku",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["raspy", "middle-aged"],
        order: 11,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-pfzp5jqg7iqjiquc4bku-uk-ua.mp3",
        approxDurationCoeficient: 15
      },
      {
        character: "Charlotte",
        voice: "XB0fDUnXU5powFXDhCwa",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["swedish-accent", "young"],
        order: 12,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb0fdunxu5powfxdhcwa-uk-ua.mp3",
        approxDurationCoeficient: 13
      },
      {
        character: "Dorothy",
        voice: "ThT5KcBeYPX3keUQqHPh",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 13,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-tht5kcbeypx3keuqqhph-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Sarah",
        voice: "EXAVITQu4vr4xnSDxMaL",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: ["calm", "young"],
        order: 99999,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-exavitqu4vr4xnsdxmal-uk-ua.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Alice",
        voice: "Xb7hH8MSUJpSbSDYk0k2",
        voiceProvider: "elevenlabs",
        premium: true,
        tags: [],
        order: 99999,
        name: "Ukrainian (Ukraine)",
        locale: "uk-UA",
        icon: "ua",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/elevenlabs/elevenlabs-xb7hh8msujpsbsdyk0k2-uk-ua.mp3",
        approxDurationCoeficient: 16
      },
      {
        character: "Elanor A",
        name: "Ukrainian-UA",
        voice: "uk-UA-Wavenet-A",
        icon: "ua",
        voiceProvider: "google",
        locale: "uk-UA",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-uk-ua-wavenet-a-uk-ua.mp3",
        approxDurationCoeficient: 17
      }
    ]
  },
  {
    name: "Urdu",
    male: [
      {
        voiceProvider: "azure",
        character: "Salman",
        name: "Urdu (India)",
        locale: "ur-IN",
        voice: "ur-IN-SalmanNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ur-in-salmanneural-ur-in.mp3",
        approxDurationCoeficient: 13
      },
      {
        voiceProvider: "azure",
        character: "Asad",
        name: "Urdu (Pakistan)",
        locale: "ur-PK",
        voice: "ur-PK-AsadNeural",
        icon: "pk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ur-pk-asadneural-ur-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Oscar B",
        name: "Urdu-IN",
        voice: "ur-IN-Wavenet-B",
        icon: "in",
        voiceProvider: "google",
        locale: "ur-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ur-in-wavenet-b-ur-in.mp3"
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Gul",
        name: "Urdu (India)",
        locale: "ur-IN",
        voice: "ur-IN-GulNeural",
        icon: "in",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ur-in-gulneural-ur-in.mp3",
        approxDurationCoeficient: 15
      },
      {
        voiceProvider: "azure",
        character: "Uzma",
        name: "Urdu (Pakistan)",
        locale: "ur-PK",
        voice: "ur-PK-UzmaNeural",
        icon: "pk",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-ur-pk-uzmaneural-ur-in.mp3",
        approxDurationCoeficient: 14
      },
      {
        character: "Elanor A",
        name: "Urdu-IN",
        voice: "ur-IN-Wavenet-A",
        icon: "in",
        voiceProvider: "google",
        locale: "ur-IN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-ur-in-wavenet-a-ur-in.mp3"
      }
    ]
  },
  {
    name: "Uzbek",
    male: [
      {
        voiceProvider: "azure",
        character: "Sardor",
        name: "Uzbek (Latin, Uzbekistan)",
        locale: "uz-UZ",
        voice: "uz-UZ-SardorNeural",
        icon: "uz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-uz-uz-sardorneural-uz-uz.mp3",
        approxDurationCoeficient: 16
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Madina",
        name: "Uzbek (Latin, Uzbekistan)",
        locale: "uz-UZ",
        voice: "uz-UZ-MadinaNeural",
        icon: "uz",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-uz-uz-madinaneural-uz-uz.mp3",
        approxDurationCoeficient: 15
      }
    ]
  },
  {
    name: "Vietnamese",
    male: [
      {
        voiceProvider: "azure",
        character: "NamMinh",
        name: "Vietnamese (Vietnam)",
        locale: "vi-VN",
        voice: "vi-VN-NamMinhNeural",
        icon: "vn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-vi-vn-namminhneural-vi-vn.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Oscar D",
        name: "Vietnamese-VN",
        voice: "vi-VN-Neural2-D",
        icon: "vn",
        voiceProvider: "google",
        locale: "vi-VN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-vi-vn-neural2-d-vi-vn.mp3"
      },
      {
        character: "Miles B",
        name: "Vietnamese-VN",
        voice: "vi-VN-Wavenet-B",
        icon: "vn",
        voiceProvider: "google",
        locale: "vi-VN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-vi-vn-wavenet-b-vi-vn.mp3",
        approxDurationCoeficient: 19
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "HoaiMy",
        name: "Vietnamese (Vietnam)",
        locale: "vi-VN",
        voice: "vi-VN-HoaiMyNeural",
        icon: "vn",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-vi-vn-hoaimyneural-vi-vn.mp3",
        approxDurationCoeficient: 18
      },
      {
        character: "Elanor A",
        name: "Vietnamese-VN",
        voice: "vi-VN-Neural2-A",
        icon: "vn",
        voiceProvider: "google",
        locale: "vi-VN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-vi-vn-neural2-a-vi-vn.mp3",
        approxDurationCoeficient: 17
      },
      {
        character: "Lucy C",
        name: "Vietnamese-VN",
        voice: "vi-VN-Wavenet-C",
        icon: "vn",
        voiceProvider: "google",
        locale: "vi-VN",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/google/google-vi-vn-wavenet-c-vi-vn.mp3",
        approxDurationCoeficient: 19
      }
    ]
  },
  {
    name: "Welsh",
    male: [
      {
        voiceProvider: "azure",
        character: "Aled",
        name: "Welsh (United Kingdom)",
        locale: "cy-GB",
        voice: "cy-GB-AledNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-cy-gb-aledneural-cy-gb.mp3",
        approxDurationCoeficient: 15
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Nia",
        name: "Welsh (United Kingdom)",
        locale: "cy-GB",
        voice: "cy-GB-NiaNeural",
        icon: "gb",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-cy-gb-nianeural-cy-gb.mp3",
        approxDurationCoeficient: 16
      }
    ]
  },
  {
    name: "Zulu",
    male: [
      {
        voiceProvider: "azure",
        character: "Themba",
        name: "Zulu (South Africa)",
        locale: "zu-ZA",
        voice: "zu-ZA-ThembaNeural",
        icon: "za",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zu-za-thembaneural-zu-za.mp3",
        approxDurationCoeficient: 16
      }
    ],
    female: [
      {
        voiceProvider: "azure",
        character: "Thando",
        name: "Zulu (South Africa)",
        locale: "zu-ZA",
        voice: "zu-ZA-ThandoNeural",
        icon: "za",
        url: "https://d3u63mhbhkevz8.cloudfront.net/voices/azure/azure-zu-za-thandoneural-zu-za.mp3",
        approxDurationCoeficient: 15
      }
    ]
  }
];

export const genAIVoicesList = [
  {
    name: "Chinese",
    male: [
      {
        id: 2121,
        character: "Yunxiang",
        name: "Chinese (zh-CN-shandong)",
        locale: "zh-CN-shandong",
        voice: "zh-CN-shandong-YunxiangNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2151,
        character: "Yunze",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunzeNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "sad", "depressed", "documentary-narration"]
      },
      {
        id: 2152,
        character: "Yunxia",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunxiaNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["calm", "fearful", "cheerful", "angry", "sad"]
      },
      {
        id: 2153,
        character: "Yunjian",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunjianNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration-relaxed", "sports-commentary", "sports-commentary-excited"]
      },
      {
        id: 2154,
        character: "Yunhao",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunhaoNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["advertisement-upbeat"]
      },
      {
        id: 2155,
        character: "Yunfeng",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunfengNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "disgruntled", "cheerful", "fearful", "sad", "serious", "depressed"]
      },
      {
        id: 2156,
        character: "Yunyang",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunyangNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["customerservice", "narration-professional", "newscast-casual"]
      },
      {
        id: 2157,
        character: "Yunxi",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunxiNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: [
          "narration-relaxed",
          "embarrassed",
          "fearful",
          "cheerful",
          "disgruntled",
          "serious",
          "angry",
          "sad",
          "depressed",
          "chat",
          "assistant",
          "newscast"
        ]
      },
      {
        id: 2158,
        character: "Yunye",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-YunyeNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["embarrassed", "calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "sad"]
      },
      {
        id: 2159,
        character: "WanLung",
        name: "Chinese (zh-HK)",
        locale: "zh-HK",
        voice: "zh-HK-WanLungNeural",
        icon: "hk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2160,
        character: "YunJhe",
        name: "Chinese (zh-TW)",
        locale: "zh-TW",
        voice: "zh-TW-YunJheNeural",
        icon: "tw",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2150,
        character: "Yundeng",
        name: "Chinese (zh-CN-henan)",
        locale: "zh-CN-henan",
        voice: "zh-CN-henan-YundengNeural",
        icon: "cn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2161,
        character: "Xiaoni",
        name: "Chinese (zh-CN-shaanxi)",
        locale: "zh-CN-shaanxi",
        voice: "zh-CN-shaanxi-XiaoniNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2176,
        character: "Xiaobei",
        name: "Chinese (zh-CN-liaoning)",
        locale: "zh-CN-liaoning",
        voice: "zh-CN-liaoning-XiaobeiNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2177,
        character: "Xiaozhen",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaozhenNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "disgruntled", "cheerful", "fearful", "sad", "serious"]
      },
      {
        id: 2178,
        character: "Xiaoyi",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyiNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "disgruntled", "affectionate", "cheerful", "fearful", "sad", "embarrassed", "serious", "gentle"]
      },
      {
        id: 2179,
        character: "Xiaomeng",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaomengNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["chat"]
      },
      {
        id: 2180,
        character: "Xiaoxiao",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoxiaoNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: [
          "assistant",
          "chat",
          "customerservice",
          "newscast",
          "affectionate",
          "angry",
          "calm",
          "cheerful",
          "disgruntled",
          "fearful",
          "gentle",
          "lyrical",
          "sad",
          "serious"
        ]
      },
      {
        id: 2181,
        character: "Xiaochen",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaochenNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2182,
        character: "Xiaohan",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaohanNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "sad", "gentle", "affectionate", "embarrassed"]
      },
      {
        id: 2183,
        character: "Xiaomo",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaomoNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: [
          "embarrassed",
          "calm",
          "fearful",
          "cheerful",
          "disgruntled",
          "serious",
          "angry",
          "sad",
          "depressed",
          "affectionate",
          "gentle",
          "envious"
        ]
      },
      {
        id: 2184,
        character: "Xiaoqiu",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoqiuNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2185,
        character: "Xiaorui",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoruiNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["calm", "fearful", "angry", "sad"]
      },
      {
        id: 2186,
        character: "Xiaoshuang",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoshuangNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["child", "chat"]
      },
      {
        id: 2187,
        character: "Xiaoxuan",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoxuanNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["calm", "fearful", "cheerful", "disgruntled", "serious", "angry", "gentle", "depressed"]
      },
      {
        id: 2188,
        character: "Xiaoyan",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyanNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2189,
        character: "Xiaoyou",
        name: "Chinese (zh-CN)",
        locale: "zh-CN",
        voice: "zh-CN-XiaoyouNeural",
        icon: "cn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["child"]
      },
      {
        id: 2190,
        character: "HiuMaan",
        name: "Chinese (zh-HK)",
        locale: "zh-HK",
        voice: "zh-HK-HiuMaanNeural",
        icon: "hk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2191,
        character: "HiuGaai",
        name: "Chinese (zh-HK)",
        locale: "zh-HK",
        voice: "zh-HK-HiuGaaiNeural",
        icon: "hk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2192,
        character: "HsiaoChen",
        name: "Chinese (zh-TW)",
        locale: "zh-TW",
        voice: "zh-TW-HsiaoChenNeural",
        icon: "tw",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2193,
        character: "HsiaoYu",
        name: "Chinese (zh-TW)",
        locale: "zh-TW",
        voice: "zh-TW-HsiaoYuNeural",
        icon: "tw",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Filipino",
    male: [
      {
        id: 2394,
        character: "Angelo",
        name: "Filipino (fil-PH)",
        locale: "fil-PH",
        voice: "fil-PH-AngeloNeural",
        icon: "ph",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2425,
        character: "Blessica",
        name: "Filipino (fil-PH)",
        locale: "fil-PH",
        voice: "fil-PH-BlessicaNeural",
        icon: "ph",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "English",
    male: [
      {
        id: 1833,
        character: "Christopher",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-ChristopherNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1834,
        character: "Liam",
        name: "English (en-CA)",
        locale: "en-CA",
        voice: "en-CA-LiamNeural",
        icon: "ca",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "fast-paced"]
      },
      {
        id: 1835,
        character: "Prabhat",
        name: "English (en-IN)",
        locale: "en-IN",
        voice: "en-IN-PrabhatNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "slow-paced"]
      },
      {
        id: 1836,
        character: "Jacob",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-JacobNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["upbeat", "promo"]
      },
      {
        id: 1803,
        character: "Guy",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-GuyNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["promo", "upbeat", "newscast"]
      },
      {
        id: 1837,
        character: "William",
        name: "English (en-AU)",
        locale: "en-AU",
        voice: "en-AU-WilliamNeural",
        icon: "au",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["promo", "fast-paced"]
      },
      {
        id: 1838,
        character: "Tony",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-TonyNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"]
      },
      {
        id: 1839,
        character: "Steffan",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-SteffanNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1840,
        character: "Jason",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-JasonNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"]
      },
      {
        id: 1841,
        character: "Davis",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-DavisNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["chat", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"]
      },
      {
        id: 1842,
        character: "Thomas",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-ThomasNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1843,
        character: "Oliver",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-OliverNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1844,
        character: "Noah",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-NoahNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1845,
        character: "Ethan",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-EthanNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1846,
        character: "Elliot",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-ElliotNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1847,
        character: "Alfie",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-AlfieNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1848,
        character: "Brandon",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-BrandonNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1849,
        character: "Eric",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-EricNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1850,
        character: "Ryan",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-RyanNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "fast-paced"]
      },
      {
        id: 1851,
        character: "Sam",
        name: "English (en-HK)",
        locale: "en-HK",
        voice: "en-HK-SamNeural",
        icon: "hk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "slow-paced"]
      },
      {
        id: 1852,
        character: "Connor",
        name: "English (en-IE)",
        locale: "en-IE",
        voice: "en-IE-ConnorNeural",
        icon: "ie",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "fast-paced"]
      },
      {
        id: 1853,
        character: "Chilemba",
        name: "English (en-KE)",
        locale: "en-KE",
        voice: "en-KE-ChilembaNeural",
        icon: "ke",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1854,
        character: "Abeo",
        name: "English (en-NG)",
        locale: "en-NG",
        voice: "en-NG-AbeoNeural",
        icon: "ng",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "fast-paced"]
      },
      {
        id: 1855,
        character: "Mitchell",
        name: "English (en-NZ)",
        locale: "en-NZ",
        voice: "en-NZ-MitchellNeural",
        icon: "nz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1856,
        character: "James",
        name: "English (en-PH)",
        locale: "en-PH",
        voice: "en-PH-JamesNeural",
        icon: "ph",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1857,
        character: "Wayne",
        name: "English (en-SG)",
        locale: "en-SG",
        voice: "en-SG-WayneNeural",
        icon: "sg",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "slow-paced"]
      },
      {
        id: 1858,
        character: "Elimu",
        name: "English (en-TZ)",
        locale: "en-TZ",
        voice: "en-TZ-ElimuNeural",
        icon: "tz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "fast-paced"]
      },
      {
        id: 1859,
        character: "Luke",
        name: "English (en-ZA)",
        locale: "en-ZA",
        voice: "en-ZA-LukeNeural",
        icon: "za",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "fast-paced"]
      }
    ],
    female: [
      {
        id: 1898,
        character: "Aria",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-AriaNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["promo", "upbeat", "chat", "customerservice", "narration-professional", "newscast-casual", "newscast-formal", "cheerful", "empathetic"]
      },
      {
        id: 1914,
        character: "Nancy",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-NancyNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"]
      },
      {
        id: 1915,
        character: "Neerja",
        name: "English (en-IN)",
        locale: "en-IN",
        voice: "en-IN-NeerjaNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["promo", "upbeat"]
      },
      {
        id: 1916,
        character: "Ashley",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-AshleyNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1917,
        character: "Jenny",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-JennyNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm", "assistant", "chat", "customerservice", "newscast"]
      },
      {
        id: 1918,
        character: "Leah",
        name: "English (en-ZA)",
        locale: "en-ZA",
        voice: "en-ZA-LeahNeural",
        icon: "za",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "slow-paced"]
      },
      {
        id: 1919,
        character: "Libby",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-LibbyNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "upbeat"]
      },
      {
        id: 1920,
        character: "Sonia",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-SoniaNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1921,
        character: "Clara",
        name: "English (en-CA)",
        locale: "en-CA",
        voice: "en-CA-ClaraNeural",
        icon: "ca",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "upbeat"]
      },
      {
        id: 1922,
        character: "Jane",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-JaneNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"]
      },
      {
        id: 1923,
        character: "Jenny Multilingual",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-JennyMultilingualNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1924,
        character: "Olivia",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-OliviaNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1925,
        character: "Maisie",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-MaisieNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["child"]
      },
      {
        id: 1926,
        character: "Hollie",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-HollieNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1927,
        character: "Bella",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-BellaNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1928,
        character: "Abbi",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-AbbiNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 1929,
        character: "Amber",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-AmberNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1930,
        character: "Ana",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-AnaNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["child"]
      },
      {
        id: 1931,
        character: "Cora",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-CoraNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1932,
        character: "Elizabeth",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-ElizabethNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1933,
        character: "Michelle",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-MichelleNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1934,
        character: "Monica",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-MonicaNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1935,
        character: "Sara",
        name: "English (en-US)",
        locale: "en-US",
        voice: "en-US-SaraNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "upbeat", "cheerful", "angry", "sad"]
      },
      {
        id: 1936,
        character: "Natasha",
        name: "English (en-AU)",
        locale: "en-AU",
        voice: "en-AU-NatashaNeural",
        icon: "au",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["promo", "calm"]
      },
      {
        id: 1937,
        character: "Mia",
        name: "English (en-GB)",
        locale: "en-GB",
        voice: "en-GB-MiaNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1938,
        character: "Yan",
        name: "English (en-HK)",
        locale: "en-HK",
        voice: "en-HK-YanNeural",
        icon: "hk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1939,
        character: "Emily",
        name: "English (en-IE)",
        locale: "en-IE",
        voice: "en-IE-EmilyNeural",
        icon: "ie",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1940,
        character: "Asilia",
        name: "English (en-KE)",
        locale: "en-KE",
        voice: "en-KE-AsiliaNeural",
        icon: "ke",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1941,
        character: "Ezinne",
        name: "English (en-NG)",
        locale: "en-NG",
        voice: "en-NG-EzinneNeural",
        icon: "ng",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1942,
        character: "Molly",
        name: "English (en-NZ)",
        locale: "en-NZ",
        voice: "en-NZ-MollyNeural",
        icon: "nz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      },
      {
        id: 1943,
        character: "Rosa",
        name: "English (en-PH)",
        locale: "en-PH",
        voice: "en-PH-RosaNeural",
        icon: "ph",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "slow-paced"]
      },
      {
        id: 1944,
        character: "Luna",
        name: "English (en-SG)",
        locale: "en-SG",
        voice: "en-SG-LunaNeural",
        icon: "sg",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "slow-paced"]
      },
      {
        id: 1945,
        character: "Imani",
        name: "English (en-TZ)",
        locale: "en-TZ",
        voice: "en-TZ-ImaniNeural",
        icon: "tz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["narration", "calm"]
      }
    ]
  },
  {
    name: "Turkish",
    male: [
      {
        id: 3492,
        character: "Ahmet",
        name: "Turkish (tr-TR)",
        locale: "tr-TR",
        voice: "tr-TR-AhmetNeural",
        icon: "tr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3523,
        character: "Emel",
        name: "Turkish (tr-TR)",
        locale: "tr-TR",
        voice: "tr-TR-EmelNeural",
        icon: "tr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Afrikaans",
    male: [
      {
        id: 1974,
        character: "Willem",
        name: "Afrikaans (af-ZA)",
        locale: "af-ZA",
        voice: "af-ZA-WillemNeural",
        icon: "za",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 1975,
        character: "Adri",
        name: "Afrikaans (af-ZA)",
        locale: "af-ZA",
        voice: "af-ZA-AdriNeural",
        icon: "za",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Albanian",
    male: [
      {
        id: 1976,
        character: "Ilir",
        name: "Albanian (sq-AL)",
        locale: "sq-AL",
        voice: "sq-AL-IlirNeural",
        icon: "al",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 1977,
        character: "Anila",
        name: "Albanian (sq-AL)",
        locale: "sq-AL",
        voice: "sq-AL-AnilaNeural",
        icon: "al",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Amharic",
    male: [
      {
        id: 1978,
        character: "Ameha",
        name: "Amharic (am-ET)",
        locale: "am-ET",
        voice: "am-ET-AmehaNeural",
        icon: "et",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 1979,
        character: "Mekdes",
        name: "Amharic (am-ET)",
        locale: "am-ET",
        voice: "am-ET-MekdesNeural",
        icon: "et",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Arabic",
    male: [
      {
        id: 1980,
        character: "Hamdan",
        name: "Arabic (ar-AE)",
        locale: "ar-AE",
        voice: "ar-AE-HamdanNeural",
        icon: "ae",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2009,
        character: "Abdullah",
        name: "Arabic (ar-OM)",
        locale: "ar-OM",
        voice: "ar-OM-AbdullahNeural",
        icon: "om",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2010,
        character: "Rami",
        name: "Arabic (ar-LB)",
        locale: "ar-LB",
        voice: "ar-LB-RamiNeural",
        icon: "lb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2011,
        character: "Ali",
        name: "Arabic (ar-BH)",
        locale: "ar-BH",
        voice: "ar-BH-AliNeural",
        icon: "bh",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2012,
        character: "Ismael",
        name: "Arabic (ar-DZ)",
        locale: "ar-DZ",
        voice: "ar-DZ-IsmaelNeural",
        icon: "dz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2013,
        character: "Shakir",
        name: "Arabic (ar-EG)",
        locale: "ar-EG",
        voice: "ar-EG-ShakirNeural",
        icon: "eg",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2014,
        character: "Bassel",
        name: "Arabic (ar-IQ)",
        locale: "ar-IQ",
        voice: "ar-IQ-BasselNeural",
        icon: "iq",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2015,
        character: "Taim",
        name: "Arabic (ar-JO)",
        locale: "ar-JO",
        voice: "ar-JO-TaimNeural",
        icon: "jo",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2016,
        character: "Fahed",
        name: "Arabic (ar-KW)",
        locale: "ar-KW",
        voice: "ar-KW-FahedNeural",
        icon: "kw",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2017,
        character: "Omar",
        name: "Arabic (ar-LY)",
        locale: "ar-LY",
        voice: "ar-LY-OmarNeural",
        icon: "ly",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2018,
        character: "Jamal",
        name: "Arabic (ar-MA)",
        locale: "ar-MA",
        voice: "ar-MA-JamalNeural",
        icon: "ma",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2019,
        character: "Moaz",
        name: "Arabic (ar-QA)",
        locale: "ar-QA",
        voice: "ar-QA-MoazNeural",
        icon: "qa",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2020,
        character: "Hamed",
        name: "Arabic (ar-SA)",
        locale: "ar-SA",
        voice: "ar-SA-HamedNeural",
        icon: "sa",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2021,
        character: "Laith",
        name: "Arabic (ar-SY)",
        locale: "ar-SY",
        voice: "ar-SY-LaithNeural",
        icon: "sy",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2022,
        character: "Hedi",
        name: "Arabic (ar-TN)",
        locale: "ar-TN",
        voice: "ar-TN-HediNeural",
        icon: "tn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2023,
        character: "Saleh",
        name: "Arabic (ar-YE)",
        locale: "ar-YE",
        voice: "ar-YE-SalehNeural",
        icon: "ye",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2024,
        character: "Aysha",
        name: "Arabic (ar-OM)",
        locale: "ar-OM",
        voice: "ar-OM-AyshaNeural",
        icon: "om",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2039,
        character: "Layla",
        name: "Arabic (ar-LB)",
        locale: "ar-LB",
        voice: "ar-LB-LaylaNeural",
        icon: "lb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2040,
        character: "Fatima",
        name: "Arabic (ar-AE)",
        locale: "ar-AE",
        voice: "ar-AE-FatimaNeural",
        icon: "ae",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2041,
        character: "Laila",
        name: "Arabic (ar-BH)",
        locale: "ar-BH",
        voice: "ar-BH-LailaNeural",
        icon: "bh",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2042,
        character: "Amina",
        name: "Arabic (ar-DZ)",
        locale: "ar-DZ",
        voice: "ar-DZ-AminaNeural",
        icon: "dz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2043,
        character: "Salma",
        name: "Arabic (ar-EG)",
        locale: "ar-EG",
        voice: "ar-EG-SalmaNeural",
        icon: "eg",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2044,
        character: "Rana",
        name: "Arabic (ar-IQ)",
        locale: "ar-IQ",
        voice: "ar-IQ-RanaNeural",
        icon: "iq",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2045,
        character: "Sana",
        name: "Arabic (ar-JO)",
        locale: "ar-JO",
        voice: "ar-JO-SanaNeural",
        icon: "jo",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2046,
        character: "Noura",
        name: "Arabic (ar-KW)",
        locale: "ar-KW",
        voice: "ar-KW-NouraNeural",
        icon: "kw",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2047,
        character: "Iman",
        name: "Arabic (ar-LY)",
        locale: "ar-LY",
        voice: "ar-LY-ImanNeural",
        icon: "ly",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2048,
        character: "Mouna",
        name: "Arabic (ar-MA)",
        locale: "ar-MA",
        voice: "ar-MA-MounaNeural",
        icon: "ma",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2049,
        character: "Amal",
        name: "Arabic (ar-QA)",
        locale: "ar-QA",
        voice: "ar-QA-AmalNeural",
        icon: "qa",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2050,
        character: "Zariyah",
        name: "Arabic (ar-SA)",
        locale: "ar-SA",
        voice: "ar-SA-ZariyahNeural",
        icon: "sa",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2051,
        character: "Amany",
        name: "Arabic (ar-SY)",
        locale: "ar-SY",
        voice: "ar-SY-AmanyNeural",
        icon: "sy",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2052,
        character: "Reem",
        name: "Arabic (ar-TN)",
        locale: "ar-TN",
        voice: "ar-TN-ReemNeural",
        icon: "tn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2053,
        character: "Maryam",
        name: "Arabic (ar-YE)",
        locale: "ar-YE",
        voice: "ar-YE-MaryamNeural",
        icon: "ye",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Armenian",
    male: [
      {
        id: 2058,
        character: "Hayk",
        name: "Armenian (hy-AM)",
        locale: "hy-AM",
        voice: "hy-AM-HaykNeural",
        icon: "am",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2059,
        character: "Anahit",
        name: "Armenian (hy-AM)",
        locale: "hy-AM",
        voice: "hy-AM-AnahitNeural",
        icon: "am",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Azerbaijani",
    male: [
      {
        id: 2060,
        character: "Babek",
        name: "Azerbaijani (az-AZ)",
        locale: "az-AZ",
        voice: "az-AZ-BabekNeural",
        icon: "az",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2061,
        character: "Banu",
        name: "Azerbaijani (az-AZ)",
        locale: "az-AZ",
        voice: "az-AZ-BanuNeural",
        icon: "az",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Bangla",
    male: [
      {
        id: 2062,
        character: "Pradeep",
        name: "Bangla (bn-BD)",
        locale: "bn-BD",
        voice: "bn-BD-PradeepNeural",
        icon: "bd",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2064,
        character: "Nabanita",
        name: "Bangla (bn-BD)",
        locale: "bn-BD",
        voice: "bn-BD-NabanitaNeural",
        icon: "bd",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Basque",
    male: [
      {
        id: 2066,
        character: "Ander",
        name: "Basque (eu-ES)",
        locale: "eu-ES",
        voice: "eu-ES-AnderNeural",
        icon: "es",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2067,
        character: "Ainhoa",
        name: "Basque (eu-ES)",
        locale: "eu-ES",
        voice: "eu-ES-AinhoaNeural",
        icon: "es",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Bengali",
    male: [
      {
        id: 2068,
        character: "Bashkar",
        name: "Bengali (bn-IN)",
        locale: "bn-IN",
        voice: "bn-IN-BashkarNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2069,
        character: "Tanishaa",
        name: "Bengali (bn-IN)",
        locale: "bn-IN",
        voice: "bn-IN-TanishaaNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Bosnian",
    male: [
      {
        id: 2070,
        character: "Goran",
        name: "Bosnian (bs-BA)",
        locale: "bs-BA",
        voice: "bs-BA-GoranNeural",
        icon: "ba",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2071,
        character: "Vesna",
        name: "Bosnian (bs-BA)",
        locale: "bs-BA",
        voice: "bs-BA-VesnaNeural",
        icon: "ba",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Bulgarian",
    male: [
      {
        id: 2072,
        character: "Borislav",
        name: "Bulgarian (bg-BG)",
        locale: "bg-BG",
        voice: "bg-BG-BorislavNeural",
        icon: "bg",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2101,
        character: "Kalina",
        name: "Bulgarian (bg-BG)",
        locale: "bg-BG",
        voice: "bg-BG-KalinaNeural",
        icon: "bg",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Spanish",
    male: [
      {
        id: 3268,
        character: "Pelayo",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-PelayoNeural",
        icon: "es",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3301,
        character: "Yago",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-YagoNeural",
        icon: "mx",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3302,
        character: "Luciano",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-LucianoNeural",
        icon: "mx",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3303,
        character: "Liberto",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-LibertoNeural",
        icon: "mx",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3304,
        character: "Gerardo",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-GerardoNeural",
        icon: "mx",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3305,
        character: "Cecilio",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-CecilioNeural",
        icon: "mx",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3306,
        character: "Alonso",
        name: "Spanish (es-US)",
        locale: "es-US",
        voice: "es-US-AlonsoNeural",
        icon: "us",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3307,
        character: "Tomas",
        name: "Spanish (es-AR)",
        locale: "es-AR",
        voice: "es-AR-TomasNeural",
        icon: "ar",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3308,
        character: "Marcelo",
        name: "Spanish (es-BO)",
        locale: "es-BO",
        voice: "es-BO-MarceloNeural",
        icon: "bo",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3309,
        character: "Lorenzo",
        name: "Spanish (es-CL)",
        locale: "es-CL",
        voice: "es-CL-LorenzoNeural",
        icon: "cl",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3310,
        character: "Gonzalo",
        name: "Spanish (es-CO)",
        locale: "es-CO",
        voice: "es-CO-GonzaloNeural",
        icon: "co",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3311,
        character: "Juan",
        name: "Spanish (es-CR)",
        locale: "es-CR",
        voice: "es-CR-JuanNeural",
        icon: "cr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3312,
        character: "Manuel",
        name: "Spanish (es-CU)",
        locale: "es-CU",
        voice: "es-CU-ManuelNeural",
        icon: "cu",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3313,
        character: "Emilio",
        name: "Spanish (es-DO)",
        locale: "es-DO",
        voice: "es-DO-EmilioNeural",
        icon: "do",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3314,
        character: "Luis",
        name: "Spanish (es-EC)",
        locale: "es-EC",
        voice: "es-EC-LuisNeural",
        icon: "ec",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3315,
        character: "Javier",
        name: "Spanish (es-GQ)",
        locale: "es-GQ",
        voice: "es-GQ-JavierNeural",
        icon: "gq",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3316,
        character: "Andres",
        name: "Spanish (es-GT)",
        locale: "es-GT",
        voice: "es-GT-AndresNeural",
        icon: "gt",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3317,
        character: "Carlos",
        name: "Spanish (es-HN)",
        locale: "es-HN",
        voice: "es-HN-CarlosNeural",
        icon: "hn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3318,
        character: "Jorge",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-JorgeNeural",
        icon: "mx",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3319,
        character: "Federico",
        name: "Spanish (es-NI)",
        locale: "es-NI",
        voice: "es-NI-FedericoNeural",
        icon: "ni",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3320,
        character: "Roberto",
        name: "Spanish (es-PA)",
        locale: "es-PA",
        voice: "es-PA-RobertoNeural",
        icon: "pa",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3321,
        character: "Alex",
        name: "Spanish (es-PE)",
        locale: "es-PE",
        voice: "es-PE-AlexNeural",
        icon: "pe",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3322,
        character: "Victor",
        name: "Spanish (es-PR)",
        locale: "es-PR",
        voice: "es-PR-VictorNeural",
        icon: "pr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3323,
        character: "Mario",
        name: "Spanish (es-PY)",
        locale: "es-PY",
        voice: "es-PY-MarioNeural",
        icon: "py",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3324,
        character: "Rodrigo",
        name: "Spanish (es-SV)",
        locale: "es-SV",
        voice: "es-SV-RodrigoNeural",
        icon: "sv",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3325,
        character: "Mateo",
        name: "Spanish (es-UY)",
        locale: "es-UY",
        voice: "es-UY-MateoNeural",
        icon: "uy",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3326,
        character: "Sebastian",
        name: "Spanish (es-VE)",
        locale: "es-VE",
        voice: "es-VE-SebastianNeural",
        icon: "ve",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3327,
        character: "Alvaro",
        name: "Spanish (es-ES)",
        locale: "es-ES",
        voice: "es-ES-AlvaroNeural",
        icon: "es",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3328,
        character: "Renata",
        name: "Spanish (es_ES)",
        locale: "es_ES",
        voice: "es-MX-RenataNeural",
        icon: "es",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3348,
        character: "Nuria",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-NuriaNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3349,
        character: "Marina",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-MarinaNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3350,
        character: "Larissa",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-LarissaNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3351,
        character: "Carlota",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-CarlotaNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3352,
        character: "Candela",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-CandelaNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3353,
        character: "Beatriz",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-BeatrizNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3354,
        character: "Elvira",
        name: "Spanish (es-ES)",
        locale: "es-ES",
        voice: "es-ES-ElviraNeural",
        icon: "es",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3355,
        character: "Paloma",
        name: "Spanish (es-US)",
        locale: "es-US",
        voice: "es-US-PalomaNeural",
        icon: "us",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3356,
        character: "Elena",
        name: "Spanish (es-AR)",
        locale: "es-AR",
        voice: "es-AR-ElenaNeural",
        icon: "ar",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3357,
        character: "Sofia",
        name: "Spanish (es-BO)",
        locale: "es-BO",
        voice: "es-BO-SofiaNeural",
        icon: "bo",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3358,
        character: "Catalina",
        name: "Spanish (es-CL)",
        locale: "es-CL",
        voice: "es-CL-CatalinaNeural",
        icon: "cl",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3359,
        character: "Salome",
        name: "Spanish (es-CO)",
        locale: "es-CO",
        voice: "es-CO-SalomeNeural",
        icon: "co",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3360,
        character: "Maria",
        name: "Spanish (es-CR)",
        locale: "es-CR",
        voice: "es-CR-MariaNeural",
        icon: "cr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3361,
        character: "Belkys",
        name: "Spanish (es-CU)",
        locale: "es-CU",
        voice: "es-CU-BelkysNeural",
        icon: "cu",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3362,
        character: "Ramona",
        name: "Spanish (es-DO)",
        locale: "es-DO",
        voice: "es-DO-RamonaNeural",
        icon: "do",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3363,
        character: "Andrea",
        name: "Spanish (es-EC)",
        locale: "es-EC",
        voice: "es-EC-AndreaNeural",
        icon: "ec",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3364,
        character: "Teresa",
        name: "Spanish (es-GQ)",
        locale: "es-GQ",
        voice: "es-GQ-TeresaNeural",
        icon: "gq",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3365,
        character: "Marta",
        name: "Spanish (es-GT)",
        locale: "es-GT",
        voice: "es-GT-MartaNeural",
        icon: "gt",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3366,
        character: "Karla",
        name: "Spanish (es-HN)",
        locale: "es-HN",
        voice: "es-HN-KarlaNeural",
        icon: "hn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3367,
        character: "Dalia",
        name: "Spanish (es-MX)",
        locale: "es-MX",
        voice: "es-MX-DaliaNeural",
        icon: "mx",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3368,
        character: "Yolanda",
        name: "Spanish (es-NI)",
        locale: "es-NI",
        voice: "es-NI-YolandaNeural",
        icon: "ni",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3369,
        character: "Margarita",
        name: "Spanish (es-PA)",
        locale: "es-PA",
        voice: "es-PA-MargaritaNeural",
        icon: "pa",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3370,
        character: "Camila",
        name: "Spanish (es-PE)",
        locale: "es-PE",
        voice: "es-PE-CamilaNeural",
        icon: "pe",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3371,
        character: "Karina",
        name: "Spanish (es-PR)",
        locale: "es-PR",
        voice: "es-PR-KarinaNeural",
        icon: "pr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3372,
        character: "Tania",
        name: "Spanish (es-PY)",
        locale: "es-PY",
        voice: "es-PY-TaniaNeural",
        icon: "py",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3373,
        character: "Lorena",
        name: "Spanish (es-SV)",
        locale: "es-SV",
        voice: "es-SV-LorenaNeural",
        icon: "sv",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3374,
        character: "Valentina",
        name: "Spanish (es-UY)",
        locale: "es-UY",
        voice: "es-UY-ValentinaNeural",
        icon: "uy",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3375,
        character: "Paola",
        name: "Spanish (es-VE)",
        locale: "es-VE",
        voice: "es-VE-PaolaNeural",
        icon: "ve",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Burmese",
    male: [
      {
        id: 2116,
        character: "Thiha",
        name: "Burmese (my-MM)",
        locale: "my-MM",
        voice: "my-MM-ThihaNeural",
        icon: "mm",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2117,
        character: "Nilar",
        name: "Burmese (my-MM)",
        locale: "my-MM",
        voice: "my-MM-NilarNeural",
        icon: "mm",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Catalan",
    male: [
      {
        id: 2118,
        character: "Enric",
        name: "Catalan (ca-ES)",
        locale: "ca-ES",
        voice: "ca-ES-EnricNeural",
        icon: "es",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2119,
        character: "Joana",
        name: "Catalan (ca-ES)",
        locale: "ca-ES",
        voice: "ca-ES-JoanaNeural",
        icon: "es",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2120,
        character: "Alba",
        name: "Catalan (ca-ES)",
        locale: "ca-ES",
        voice: "ca-ES-AlbaNeural",
        icon: "es",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Croatian",
    male: [
      {
        id: 2201,
        character: "Srecko",
        name: "Croatian (hr-HR)",
        locale: "hr-HR",
        voice: "hr-HR-SreckoNeural",
        icon: "hr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2230,
        character: "Gabrijela",
        name: "Croatian (hr-HR)",
        locale: "hr-HR",
        voice: "hr-HR-GabrijelaNeural",
        icon: "hr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Czech",
    male: [
      {
        id: 2245,
        character: "Antonin",
        name: "Czech (cs-CZ)",
        locale: "cs-CZ",
        voice: "cs-CZ-AntoninNeural",
        icon: "cz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2274,
        character: "Vlasta",
        name: "Czech (cs-CZ)",
        locale: "cs-CZ",
        voice: "cs-CZ-VlastaNeural",
        icon: "cz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Danish",
    male: [
      {
        id: 2290,
        character: "Jeppe",
        name: "Danish (da-DK)",
        locale: "da-DK",
        voice: "da-DK-JeppeNeural",
        icon: "dk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2320,
        character: "Christel",
        name: "Danish (da-DK)",
        locale: "da-DK",
        voice: "da-DK-ChristelNeural",
        icon: "dk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Dutch",
    male: [
      {
        id: 2338,
        character: "Maarten",
        name: "Dutch (nl-NL)",
        locale: "nl-NL",
        voice: "nl-NL-MaartenNeural",
        icon: "nl",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2367,
        character: "Arnaud",
        name: "Dutch (nl-BE)",
        locale: "nl-BE",
        voice: "nl-BE-ArnaudNeural",
        icon: "be",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2371,
        character: "Colette",
        name: "Dutch (nl-NL)",
        locale: "nl-NL",
        voice: "nl-NL-ColetteNeural",
        icon: "nl",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2386,
        character: "Fenna",
        name: "Dutch (nl-NL)",
        locale: "nl-NL",
        voice: "nl-NL-FennaNeural",
        icon: "nl",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2387,
        character: "Dena",
        name: "Dutch (nl-BE)",
        locale: "nl-BE",
        voice: "nl-BE-DenaNeural",
        icon: "be",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Estonian",
    male: [
      {
        id: 2392,
        character: "Kert",
        name: "Estonian (et-EE)",
        locale: "et-EE",
        voice: "et-EE-KertNeural",
        icon: "ee",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2393,
        character: "Anu",
        name: "Estonian (et-EE)",
        locale: "et-EE",
        voice: "et-EE-AnuNeural",
        icon: "ee",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Finnish",
    male: [
      {
        id: 2442,
        character: "Harri",
        name: "Finnish (fi-FI)",
        locale: "fi-FI",
        voice: "fi-FI-HarriNeural",
        icon: "fi",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2471,
        character: "Selma",
        name: "Finnish (fi-FI)",
        locale: "fi-FI",
        voice: "fi-FI-SelmaNeural",
        icon: "fi",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2486,
        character: "Noora",
        name: "Finnish (fi-FI)",
        locale: "fi-FI",
        voice: "fi-FI-NooraNeural",
        icon: "fi",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "French",
    male: [
      {
        id: 2488,
        character: "Yves",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-YvesNeural",
        icon: "fr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2521,
        character: "Maurice",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-MauriceNeural",
        icon: "fr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2522,
        character: "Jerome",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-JeromeNeural",
        icon: "fr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2523,
        character: "Claude",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-ClaudeNeural",
        icon: "fr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2524,
        character: "Alain",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-AlainNeural",
        icon: "fr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2525,
        character: "Henri",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-HenriNeural",
        icon: "fr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2526,
        character: "Gerard",
        name: "French (fr-BE)",
        locale: "fr-BE",
        voice: "fr-BE-GerardNeural",
        icon: "be",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2527,
        character: "Antoine",
        name: "French (fr-CA)",
        locale: "fr-CA",
        voice: "fr-CA-AntoineNeural",
        icon: "ca",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2528,
        character: "Jean",
        name: "French (fr-CA)",
        locale: "fr-CA",
        voice: "fr-CA-JeanNeural",
        icon: "ca",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2529,
        character: "Fabrice",
        name: "French (fr-CH)",
        locale: "fr-CH",
        voice: "fr-CH-FabriceNeural",
        icon: "ch",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2530,
        character: "Yvette",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-YvetteNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2550,
        character: "Josephine",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-JosephineNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2551,
        character: "Jacqueline",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-JacquelineNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2552,
        character: "Eloise",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-EloiseNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["child"]
      },
      {
        id: 2553,
        character: "Coralie",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-CoralieNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2554,
        character: "Celeste",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-CelesteNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2555,
        character: "Brigitte",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-BrigitteNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2556,
        character: "Denise",
        name: "French (fr-FR)",
        locale: "fr-FR",
        voice: "fr-FR-DeniseNeural",
        icon: "fr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2557,
        character: "Charline",
        name: "French (fr-BE)",
        locale: "fr-BE",
        voice: "fr-BE-CharlineNeural",
        icon: "be",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2558,
        character: "Sylvie",
        name: "French (fr-CA)",
        locale: "fr-CA",
        voice: "fr-CA-SylvieNeural",
        icon: "ca",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2559,
        character: "Ariane",
        name: "French (fr-CH)",
        locale: "fr-CH",
        voice: "fr-CH-ArianeNeural",
        icon: "ch",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Galician",
    male: [
      {
        id: 2560,
        character: "Roi",
        name: "Galician (gl-ES)",
        locale: "gl-ES",
        voice: "gl-ES-RoiNeural",
        icon: "es",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2561,
        character: "Sabela",
        name: "Galician (gl-ES)",
        locale: "gl-ES",
        voice: "gl-ES-SabelaNeural",
        icon: "es",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Georgian",
    male: [
      {
        id: 2562,
        character: "Giorgi",
        name: "Georgian (ka-GE)",
        locale: "ka-GE",
        voice: "ka-GE-GiorgiNeural",
        icon: "ge",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2563,
        character: "Eka",
        name: "Georgian (ka-GE)",
        locale: "ka-GE",
        voice: "ka-GE-EkaNeural",
        icon: "ge",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "German",
    male: [
      {
        id: 2564,
        character: "Ralf",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-RalfNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2595,
        character: "Klaus",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-KlausNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2596,
        character: "Killian",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-KillianNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2597,
        character: "Kasper",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-KasperNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2598,
        character: "Christoph",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-ChristophNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2599,
        character: "Bernd",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-BerndNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2600,
        character: "Conrad",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-ConradNeural",
        icon: "de",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2601,
        character: "Jonas",
        name: "German (de-AT)",
        locale: "de-AT",
        voice: "de-AT-JonasNeural",
        icon: "at",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2602,
        character: "Jan",
        name: "German (de-CH)",
        locale: "de-CH",
        voice: "de-CH-JanNeural",
        icon: "ch",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2604,
        character: "Tanja",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-TanjaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2621,
        character: "Maja",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-MajaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2622,
        character: "Louisa",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-LouisaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2623,
        character: "Klarissa",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-KlarissaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2624,
        character: "Gisela",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-GiselaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["child"]
      },
      {
        id: 2625,
        character: "Elke",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-ElkeNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2626,
        character: "Amala",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-AmalaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2627,
        character: "Katja",
        name: "German (de-DE)",
        locale: "de-DE",
        voice: "de-DE-KatjaNeural",
        icon: "de",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2628,
        character: "Ingrid",
        name: "German (de-AT)",
        locale: "de-AT",
        voice: "de-AT-IngridNeural",
        icon: "at",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2629,
        character: "Leni",
        name: "German (de-CH)",
        locale: "de-CH",
        voice: "de-CH-LeniNeural",
        icon: "ch",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Greek",
    male: [
      {
        id: 2631,
        character: "Nestoras",
        name: "Greek (el-GR)",
        locale: "el-GR",
        voice: "el-GR-NestorasNeural",
        icon: "gr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2660,
        character: "Athina",
        name: "Greek (el-GR)",
        locale: "el-GR",
        voice: "el-GR-AthinaNeural",
        icon: "gr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Gujarati",
    male: [
      {
        id: 2676,
        character: "Niranjan",
        name: "Gujarati (gu-IN)",
        locale: "gu-IN",
        voice: "gu-IN-NiranjanNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2678,
        character: "Dhwani",
        name: "Gujarati (gu-IN)",
        locale: "gu-IN",
        voice: "gu-IN-DhwaniNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Hebrew",
    male: [
      {
        id: 2680,
        character: "Avri",
        name: "Hebrew (he-IL)",
        locale: "he-IL",
        voice: "he-IL-AvriNeural",
        icon: "il",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2683,
        character: "Hila",
        name: "Hebrew (he-IL)",
        locale: "he-IL",
        voice: "he-IL-HilaNeural",
        icon: "il",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Hindi",
    male: [
      {
        id: 2686,
        character: "Madhur",
        name: "Hindi (hi-IN)",
        locale: "hi-IN",
        voice: "hi-IN-MadhurNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2717,
        character: "Swara",
        name: "Hindi (hi-IN)",
        locale: "hi-IN",
        voice: "hi-IN-SwaraNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Hungarian",
    male: [
      {
        id: 2734,
        character: "Tamas",
        name: "Hungarian (hu-HU)",
        locale: "hu-HU",
        voice: "hu-HU-TamasNeural",
        icon: "hu",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2735,
        character: "Noemi",
        name: "Hungarian (hu-HU)",
        locale: "hu-HU",
        voice: "hu-HU-NoemiNeural",
        icon: "hu",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Icelandic",
    male: [
      {
        id: 2737,
        character: "Gunnar",
        name: "Icelandic (is-IS)",
        locale: "is-IS",
        voice: "is-IS-GunnarNeural",
        icon: "is",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2738,
        character: "Gudrun",
        name: "Icelandic (is-IS)",
        locale: "is-IS",
        voice: "is-IS-GudrunNeural",
        icon: "is",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Indonesian",
    male: [
      {
        id: 2739,
        character: "Ardi",
        name: "Indonesian (id-ID)",
        locale: "id-ID",
        voice: "id-ID-ArdiNeural",
        icon: "id",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2770,
        character: "Gadis",
        name: "Indonesian (id-ID)",
        locale: "id-ID",
        voice: "id-ID-GadisNeural",
        icon: "id",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Irish",
    male: [
      {
        id: 2787,
        character: "Colm",
        name: "Irish (ga-IE)",
        locale: "ga-IE",
        voice: "ga-IE-ColmNeural",
        icon: "ie",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2788,
        character: "Orla",
        name: "Irish (ga-IE)",
        locale: "ga-IE",
        voice: "ga-IE-OrlaNeural",
        icon: "ie",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Italian",
    male: [
      {
        id: 2789,
        character: "Rinaldo",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-RinaldoNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2819,
        character: "Lisandro",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-LisandroNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2820,
        character: "Gianni",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-GianniNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2821,
        character: "Cataldo",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-CataldoNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2822,
        character: "Calimero",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-CalimeroNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2823,
        character: "Benigno",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-BenignoNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2824,
        character: "Diego",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-DiegoNeural",
        icon: "it",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2826,
        character: "Pierina",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-PierinaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2842,
        character: "Palmira",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-PalmiraNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2843,
        character: "Irma",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-IrmaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2844,
        character: "Imelda",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-ImeldaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2845,
        character: "Fiamma",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-FiammaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2846,
        character: "Fabiola",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-FabiolaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2847,
        character: "Isabella",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-IsabellaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 2848,
        character: "Elsa",
        name: "Italian (it-IT)",
        locale: "it-IT",
        voice: "it-IT-ElsaNeural",
        icon: "it",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Japanese",
    male: [
      {
        id: 2850,
        character: "Keita",
        name: "Japanese (ja-JP)",
        locale: "ja-JP",
        voice: "ja-JP-KeitaNeural",
        icon: "jp",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2881,
        character: "Nanami",
        name: "Japanese (ja-JP)",
        locale: "ja-JP",
        voice: "ja-JP-NanamiNeural",
        icon: "jp",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["chat", "customerservice", "cheerful"]
      }
    ]
  },
  {
    name: "Javanese",
    male: [
      {
        id: 2898,
        character: "Dimas",
        name: "Javanese (jv-ID)",
        locale: "jv-ID",
        voice: "jv-ID-DimasNeural",
        icon: "id",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2899,
        character: "Siti",
        name: "Javanese (jv-ID)",
        locale: "jv-ID",
        voice: "jv-ID-SitiNeural",
        icon: "id",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Kannada",
    male: [
      {
        id: 2900,
        character: "Gagan",
        name: "Kannada (kn-IN)",
        locale: "kn-IN",
        voice: "kn-IN-GaganNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2902,
        character: "Sapna",
        name: "Kannada (kn-IN)",
        locale: "kn-IN",
        voice: "kn-IN-SapnaNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Kazakh",
    male: [
      {
        id: 2904,
        character: "Daulet",
        name: "Kazakh (kk-KZ)",
        locale: "kk-KZ",
        voice: "kk-KZ-DauletNeural",
        icon: "kz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2905,
        character: "Aigul",
        name: "Kazakh (kk-KZ)",
        locale: "kk-KZ",
        voice: "kk-KZ-AigulNeural",
        icon: "kz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Khmer",
    male: [
      {
        id: 2906,
        character: "Piseth",
        name: "Khmer (km-KH)",
        locale: "km-KH",
        voice: "km-KH-PisethNeural",
        icon: "kh",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2907,
        character: "Sreymom",
        name: "Khmer (km-KH)",
        locale: "km-KH",
        voice: "km-KH-SreymomNeural",
        icon: "kh",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Korean",
    male: [
      {
        id: 2908,
        character: "InJoon",
        name: "Korean (ko-KR)",
        locale: "ko-KR",
        voice: "ko-KR-InJoonNeural",
        icon: "kr",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2939,
        character: "Sun-Hi",
        name: "Korean (ko-KR)",
        locale: "ko-KR",
        voice: "ko-KR-SunHiNeural",
        icon: "kr",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Lao",
    male: [
      {
        id: 2956,
        character: "Chanthavong",
        name: "Lao (lo-LA)",
        locale: "lo-LA",
        voice: "lo-LA-ChanthavongNeural",
        icon: "la",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2957,
        character: "Keomany",
        name: "Lao (lo-LA)",
        locale: "lo-LA",
        voice: "lo-LA-KeomanyNeural",
        icon: "la",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Latvian",
    male: [
      {
        id: 2958,
        character: "Nils",
        name: "Latvian (lv-LV)",
        locale: "lv-LV",
        voice: "lv-LV-NilsNeural",
        icon: "lv",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2959,
        character: "Everita",
        name: "Latvian (lv-LV)",
        locale: "lv-LV",
        voice: "lv-LV-EveritaNeural",
        icon: "lv",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Lithuanian",
    male: [
      {
        id: 2960,
        character: "Leonas",
        name: "Lithuanian (lt-LT)",
        locale: "lt-LT",
        voice: "lt-LT-LeonasNeural",
        icon: "lt",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2961,
        character: "Ona",
        name: "Lithuanian (lt-LT)",
        locale: "lt-LT",
        voice: "lt-LT-OnaNeural",
        icon: "lt",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Macedonian",
    male: [
      {
        id: 2962,
        character: "Aleksandar",
        name: "Macedonian (mk-MK)",
        locale: "mk-MK",
        voice: "mk-MK-AleksandarNeural",
        icon: "mk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2963,
        character: "Marija",
        name: "Macedonian (mk-MK)",
        locale: "mk-MK",
        voice: "mk-MK-MarijaNeural",
        icon: "mk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Malay",
    male: [
      {
        id: 2964,
        character: "Osman",
        name: "Malay (ms-MY)",
        locale: "ms-MY",
        voice: "ms-MY-OsmanNeural",
        icon: "my",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 2995,
        character: "Yasmin",
        name: "Malay (ms-MY)",
        locale: "ms-MY",
        voice: "ms-MY-YasminNeural",
        icon: "my",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Malayalam",
    male: [
      {
        id: 3012,
        character: "Midhun",
        name: "Malayalam (ml-IN)",
        locale: "ml-IN",
        voice: "ml-IN-MidhunNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3015,
        character: "Sobhana",
        name: "Malayalam (ml-IN)",
        locale: "ml-IN",
        voice: "ml-IN-SobhanaNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Maltese",
    male: [
      {
        id: 3018,
        character: "Joseph",
        name: "Maltese (mt-MT)",
        locale: "mt-MT",
        voice: "mt-MT-JosephNeural",
        icon: "mt",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3019,
        character: "Grace",
        name: "Maltese (mt-MT)",
        locale: "mt-MT",
        voice: "mt-MT-GraceNeural",
        icon: "mt",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Marathi",
    male: [
      {
        id: 3020,
        character: "Manohar",
        name: "Marathi (mr-IN)",
        locale: "mr-IN",
        voice: "mr-IN-ManoharNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3022,
        character: "Aarohi",
        name: "Marathi (mr-IN)",
        locale: "mr-IN",
        voice: "mr-IN-AarohiNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Mongolian",
    male: [
      {
        id: 3025,
        character: "Bataa",
        name: "Mongolian (mn-MN)",
        locale: "mn-MN",
        voice: "mn-MN-BataaNeural",
        icon: "mn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3026,
        character: "Yesui",
        name: "Mongolian (mn-MN)",
        locale: "mn-MN",
        voice: "mn-MN-YesuiNeural",
        icon: "mn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Nepali",
    male: [
      {
        id: 3027,
        character: "Sagar",
        name: "Nepali (ne-NP)",
        locale: "ne-NP",
        voice: "ne-NP-SagarNeural",
        icon: "np",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3028,
        character: "Hemkala",
        name: "Nepali (ne-NP)",
        locale: "ne-NP",
        voice: "ne-NP-HemkalaNeural",
        icon: "np",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Norwegian",
    male: [
      {
        id: 3029,
        character: "Finn",
        name: "Norwegian (nb-NO)",
        locale: "nb-NO",
        voice: "nb-NO-FinnNeural",
        icon: "no",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3032,
        character: "Pernille",
        name: "Norwegian (nb-NO)",
        locale: "nb-NO",
        voice: "nb-NO-PernilleNeural",
        icon: "no",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3033,
        character: "Iselin",
        name: "Norwegian (nb-NO)",
        locale: "nb-NO",
        voice: "nb-NO-IselinNeural",
        icon: "no",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Pashto",
    male: [
      {
        id: 3037,
        character: "Gul Nawaz",
        name: "Pashto (ps-AF)",
        locale: "ps-AF",
        voice: "ps-AF-GulNawazNeural",
        icon: "af",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3038,
        character: "Latifa",
        name: "Pashto (ps-AF)",
        locale: "ps-AF",
        voice: "ps-AF-LatifaNeural",
        icon: "af",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Persian",
    male: [
      {
        id: 3039,
        character: "Farid",
        name: "Persian (fa-IR)",
        locale: "fa-IR",
        voice: "fa-IR-FaridNeural",
        icon: "ir",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3040,
        character: "Dilara",
        name: "Persian (fa-IR)",
        locale: "fa-IR",
        voice: "fa-IR-DilaraNeural",
        icon: "ir",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Polish",
    male: [
      {
        id: 3041,
        character: "Marek",
        name: "Polish (pl-PL)",
        locale: "pl-PL",
        voice: "pl-PL-MarekNeural",
        icon: "pl",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3072,
        character: "Agnieszka",
        name: "Polish (pl-PL)",
        locale: "pl-PL",
        voice: "pl-PL-AgnieszkaNeural",
        icon: "pl",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3087,
        character: "Zofia",
        name: "Polish (pl-PL)",
        locale: "pl-PL",
        voice: "pl-PL-ZofiaNeural",
        icon: "pl",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Portuguese",
    male: [
      {
        id: 3091,
        character: "Duarte",
        name: "Portuguese (pt-PT)",
        locale: "pt-PT",
        voice: "pt-PT-DuarteNeural",
        icon: "pt",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3121,
        character: "Valerio",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-ValerioNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3122,
        character: "Nicolau",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-NicolauNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3123,
        character: "Julio",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-JulioNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3124,
        character: "Humberto",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-HumbertoNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3125,
        character: "Fabio",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-FabioNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3126,
        character: "Donato",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-DonatoNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3127,
        character: "Antonio",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-AntonioNeural",
        icon: "br",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3130,
        character: "Yara",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-YaraNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3147,
        character: "Manuela",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-ManuelaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3148,
        character: "Leticia",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-LeticiaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3149,
        character: "Leila",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-LeilaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3150,
        character: "Giovanna",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-GiovannaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3151,
        character: "Elza",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-ElzaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3152,
        character: "Brenda",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-BrendaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3153,
        character: "Fernanda",
        name: "Portuguese (pt-PT)",
        locale: "pt-PT",
        voice: "pt-PT-FernandaNeural",
        icon: "pt",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3154,
        character: "Raquel",
        name: "Portuguese (pt-PT)",
        locale: "pt-PT",
        voice: "pt-PT-RaquelNeural",
        icon: "pt",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3155,
        character: "Francisca",
        name: "Portuguese (pt-BR)",
        locale: "pt-BR",
        voice: "pt-BR-FranciscaNeural",
        icon: "br",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: ["calm"]
      }
    ]
  },
  {
    name: "Romanian",
    male: [
      {
        id: 3162,
        character: "Emil",
        name: "Romanian (ro-RO)",
        locale: "ro-RO",
        voice: "ro-RO-EmilNeural",
        icon: "ro",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3191,
        character: "Alina",
        name: "Romanian (ro-RO)",
        locale: "ro-RO",
        voice: "ro-RO-AlinaNeural",
        icon: "ro",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Russian",
    male: [
      {
        id: 3207,
        character: "Dmitry",
        name: "Russian (ru-RU)",
        locale: "ru-RU",
        voice: "ru-RU-DmitryNeural",
        icon: "ru",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3210,
        character: "Svetlana",
        name: "Russian (ru-RU)",
        locale: "ru-RU",
        voice: "ru-RU-SvetlanaNeural",
        icon: "ru",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3211,
        character: "Dariya",
        name: "Russian (ru-RU)",
        locale: "ru-RU",
        voice: "ru-RU-DariyaNeural",
        icon: "ru",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Serbian",
    male: [
      {
        id: 3215,
        character: "Nicholas",
        name: "Serbian (sr-RS)",
        locale: "sr-RS",
        voice: "sr-RS-NicholasNeural",
        icon: "rs",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3216,
        character: "Sophie",
        name: "Serbian (sr-RS)",
        locale: "sr-RS",
        voice: "sr-RS-SophieNeural",
        icon: "rs",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Sinhala",
    male: [
      {
        id: 3217,
        character: "Sameera",
        name: "Sinhala (si-LK)",
        locale: "si-LK",
        voice: "si-LK-SameeraNeural",
        icon: "lk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3218,
        character: "Thilini",
        name: "Sinhala (si-LK)",
        locale: "si-LK",
        voice: "si-LK-ThiliniNeural",
        icon: "lk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Slovak",
    male: [
      {
        id: 3219,
        character: "Lukas",
        name: "Slovak (sk-SK)",
        locale: "sk-SK",
        voice: "sk-SK-LukasNeural",
        icon: "sk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3248,
        character: "Viktoria",
        name: "Slovak (sk-SK)",
        locale: "sk-SK",
        voice: "sk-SK-ViktoriaNeural",
        icon: "sk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Slovenian",
    male: [
      {
        id: 3264,
        character: "Rok",
        name: "Slovenian (sl-SI)",
        locale: "sl-SI",
        voice: "sl-SI-RokNeural",
        icon: "si",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3265,
        character: "Petra",
        name: "Slovenian (sl-SI)",
        locale: "sl-SI",
        voice: "sl-SI-PetraNeural",
        icon: "si",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Somali",
    male: [
      {
        id: 3266,
        character: "Muuse",
        name: "Somali (so-SO)",
        locale: "so-SO",
        voice: "so-SO-MuuseNeural",
        icon: "so",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3267,
        character: "Ubax",
        name: "Somali (so-SO)",
        locale: "so-SO",
        voice: "so-SO-UbaxNeural",
        icon: "so",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Sundanese",
    male: [
      {
        id: 3376,
        character: "Jajang",
        name: "Sundanese (su-ID)",
        locale: "su-ID",
        voice: "su-ID-JajangNeural",
        icon: "id",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3377,
        character: "Tuti",
        name: "Sundanese (su-ID)",
        locale: "su-ID",
        voice: "su-ID-TutiNeural",
        icon: "id",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Swahili",
    male: [
      {
        id: 3378,
        character: "Rafiki",
        name: "Swahili (sw-KE)",
        locale: "sw-KE",
        voice: "sw-KE-RafikiNeural",
        icon: "ke",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3379,
        character: "Daudi",
        name: "Swahili (sw-TZ)",
        locale: "sw-TZ",
        voice: "sw-TZ-DaudiNeural",
        icon: "tz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3380,
        character: "Zuri",
        name: "Swahili (sw-KE)",
        locale: "sw-KE",
        voice: "sw-KE-ZuriNeural",
        icon: "ke",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3381,
        character: "Rehema",
        name: "Swahili (sw-TZ)",
        locale: "sw-TZ",
        voice: "sw-TZ-RehemaNeural",
        icon: "tz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Swedish",
    male: [
      {
        id: 3382,
        character: "Mattias",
        name: "Swedish (sv-SE)",
        locale: "sv-SE",
        voice: "sv-SE-MattiasNeural",
        icon: "se",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3413,
        character: "Sofie",
        name: "Swedish (sv-SE)",
        locale: "sv-SE",
        voice: "sv-SE-SofieNeural",
        icon: "se",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3428,
        character: "Hillevi",
        name: "Swedish (sv-SE)",
        locale: "sv-SE",
        voice: "sv-SE-HilleviNeural",
        icon: "se",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Tamil",
    male: [
      {
        id: 3432,
        character: "Valluvar",
        name: "Tamil (ta-IN)",
        locale: "ta-IN",
        voice: "ta-IN-ValluvarNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3461,
        character: "Surya",
        name: "Tamil (ta-MY)",
        locale: "ta-MY",
        voice: "ta-MY-SuryaNeural",
        icon: "my",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3462,
        character: "Kumar",
        name: "Tamil (ta-LK)",
        locale: "ta-LK",
        voice: "ta-LK-KumarNeural",
        icon: "lk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3463,
        character: "Anbu",
        name: "Tamil (ta-SG)",
        locale: "ta-SG",
        voice: "ta-SG-AnbuNeural",
        icon: "sg",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3466,
        character: "Kani",
        name: "Tamil (ta-MY)",
        locale: "ta-MY",
        voice: "ta-MY-KaniNeural",
        icon: "my",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3481,
        character: "Pallavi",
        name: "Tamil (ta-IN)",
        locale: "ta-IN",
        voice: "ta-IN-PallaviNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3482,
        character: "Saranya",
        name: "Tamil (ta-LK)",
        locale: "ta-LK",
        voice: "ta-LK-SaranyaNeural",
        icon: "lk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3483,
        character: "Venba",
        name: "Tamil (ta-SG)",
        locale: "ta-SG",
        voice: "ta-SG-VenbaNeural",
        icon: "sg",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Telugu",
    male: [
      {
        id: 3486,
        character: "Mohan",
        name: "Telugu (te-IN)",
        locale: "te-IN",
        voice: "te-IN-MohanNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3487,
        character: "Shruti",
        name: "Telugu (te-IN)",
        locale: "te-IN",
        voice: "te-IN-ShrutiNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Thai",
    male: [
      {
        id: 3488,
        character: "Niwat",
        name: "Thai (th-TH)",
        locale: "th-TH",
        voice: "th-TH-NiwatNeural",
        icon: "th",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3489,
        character: "Premwadee",
        name: "Thai (th-TH)",
        locale: "th-TH",
        voice: "th-TH-PremwadeeNeural",
        icon: "th",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3491,
        character: "Achara",
        name: "Thai (th-TH)",
        locale: "th-TH",
        voice: "th-TH-AcharaNeural",
        icon: "th",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Ukrainian",
    male: [
      {
        id: 3541,
        character: "Ostap",
        name: "Ukrainian (uk-UA)",
        locale: "uk-UA",
        voice: "uk-UA-OstapNeural",
        icon: "ua",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3570,
        character: "Polina",
        name: "Ukrainian (uk-UA)",
        locale: "uk-UA",
        voice: "uk-UA-PolinaNeural",
        icon: "ua",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Urdu",
    male: [
      {
        id: 3586,
        character: "Salman",
        name: "Urdu (ur-IN)",
        locale: "ur-IN",
        voice: "ur-IN-SalmanNeural",
        icon: "in",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3587,
        character: "Asad",
        name: "Urdu (ur-PK)",
        locale: "ur-PK",
        voice: "ur-PK-AsadNeural",
        icon: "pk",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3588,
        character: "Gul",
        name: "Urdu (ur-IN)",
        locale: "ur-IN",
        voice: "ur-IN-GulNeural",
        icon: "in",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      },
      {
        id: 3589,
        character: "Uzma",
        name: "Urdu (ur-PK)",
        locale: "ur-PK",
        voice: "ur-PK-UzmaNeural",
        icon: "pk",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Uzbek",
    male: [
      {
        id: 3590,
        character: "Sardor",
        name: "Uzbek (uz-UZ)",
        locale: "uz-UZ",
        voice: "uz-UZ-SardorNeural",
        icon: "uz",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3591,
        character: "Madina",
        name: "Uzbek (uz-UZ)",
        locale: "uz-UZ",
        voice: "uz-UZ-MadinaNeural",
        icon: "uz",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Vietnamese",
    male: [
      {
        id: 3592,
        character: "NamMinh",
        name: "Vietnamese (vi-VN)",
        locale: "vi-VN",
        voice: "vi-VN-NamMinhNeural",
        icon: "vn",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3595,
        character: "HoaiMy",
        name: "Vietnamese (vi-VN)",
        locale: "vi-VN",
        voice: "vi-VN-HoaiMyNeural",
        icon: "vn",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Welsh",
    male: [
      {
        id: 3598,
        character: "Aled",
        name: "Welsh (cy-GB)",
        locale: "cy-GB",
        voice: "cy-GB-AledNeural",
        icon: "gb",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3599,
        character: "Nia",
        name: "Welsh (cy-GB)",
        locale: "cy-GB",
        voice: "cy-GB-NiaNeural",
        icon: "gb",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  },
  {
    name: "Zulu",
    male: [
      {
        id: 3600,
        character: "Themba",
        name: "Zulu (zu-ZA)",
        locale: "zu-ZA",
        voice: "zu-ZA-ThembaNeural",
        icon: "za",
        gender: "male",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ],
    female: [
      {
        id: 3601,
        character: "Thando",
        name: "Zulu (zu-ZA)",
        locale: "zu-ZA",
        voice: "zu-ZA-ThandoNeural",
        icon: "za",
        gender: "female",
        premium: false,
        voiceProvider: "azure",
        tags: []
      }
    ]
  }
];
