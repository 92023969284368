import { Col, Divider, Row } from "antd";
import { CircularAvatarTile } from "../Components";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { ModuleDataType, TabData } from "../Core/types";
import { useTranslation } from "react-i18next";

const DescriptionTab: React.FC<{ data: TabData; module: ModuleDataType }> = ({ data, module }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background: "white",
        border: "1px solid #f0f0f0",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 6px 18px",
        borderRadius: "12px",
        height: "100%"
      }}
    >
      <div style={{ padding: "24px", paddingBottom: 0 }}>
        <Title level={4} style={{ fontWeight: 700 }}>
          {/* {module.heading} */}
          {t(module.heading)}
        </Title>
        <Paragraph style={{ fontSize: "12px" }}>{t(module.description)}</Paragraph>
      </div>
      <Divider style={{ borderWidth: "4px", margin: 0 }} />
      <Row gutter={[32, 8]} style={{ padding: "24px" }}>
        <Col xs={{ span: 12 }}>
          <Row gutter={[8, 10]}>
            {data?.content?.map((tile, key) => (
              <Col key={key} xs={{ span: 24 }} lg={{ span: 12 }}>
                <CircularAvatarTile image={tile.imgSrc} heading={tile.point} description={tile.description as string} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={{ span: 12 }}>
          <img src={data.bannerSrc} width={"100%"} style={{ padding: "50px" }} />
        </Col>
      </Row>
    </div>
  );
};

export { DescriptionTab };
