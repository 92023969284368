
import { useSelector } from "react-redux";
import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip ,Radio, InputNumber} from "antd";
interface DynamicInputsProps {
    numColumns: number;
    numRows: number;
    items: string[]; // Assuming items is an array of strings
    setItems: (items: string[]) => void; // Function to set items, accepting an array of strings
  }
  
export const DynamicInputs: React.FC<DynamicInputsProps> = ({ numColumns, numRows ,items,setItems}) => {
  // Then, in your component, update the input generation logic to use inputValues
  const [inputValues, setInputValues] = useState<string[]>(Array(numColumns).fill(''));

  // Function to handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, colIndex: number) => {
    const updatedValues = [...inputValues];
    // Calculate the index in the one-dimensional array
    const index = rowIndex * numColumns + colIndex;
    updatedValues[index] = e.target.value;
    setInputValues(updatedValues);
    setItems(updatedValues);
    console.log(inputValues);
   
  };
  
  const generateInputs = () => {
    const inputs = [];

    // Add the "Columns" label row followed by column labels
    const columnLabels = [];
    columnLabels.push(
      <Col key={`empty`} style={{ width: '100px', margin: '5px' }} />
    );
    for (let j = 0; j < numColumns; j++) {
      if(j===0){
      columnLabels.push(
        <Col key={`col-${0}-${j}`} style={{ width: '100px', margin: '5px', textAlign: 'revert-layer'}}>
          <span>Columns</span>
        </Col>
      );}
    }
    inputs.push(<Row key={`row-label`} gutter={16} style={{ marginBottom: '10px' }}>{columnLabels}</Row>);

    // Add the rows with row labels followed by row inputs
    for (let i = 0; i < numRows; i++) {
      const rowInputs = [];
      if (i === 1 && numColumns >=1) {
        rowInputs.push(
          <Col key={`row-label-${i}`} style={{ width: '100px', margin: '5px', textAlign: 'end' }}>
            <span>Rows</span>
          </Col>
        );
      }
       else {
        rowInputs.push(
          <Col key={`row-label-${i}`} style={{ width: '100px', margin: '5px', textAlign: 'center' }}></Col>
        );
      }
      for (let j = 0; j < numColumns; j++) {
        rowInputs.push(
          <Col key={`col-${i}-${j}`}>
            <Input
              value={i>=1?"":inputValues[j]}
              disabled={i>=1}
              onChange={(e) => handleInputChange(e, i, j)}
              style={{ width: '100px', margin: '5px' }}
            />
          </Col>
        );
      } 
      inputs.push(<Row key={`row-${i}`} gutter={16} style={{ marginBottom: '10px' }}>{rowInputs}</Row>);
    }
    return inputs;
  };
  return (
    <div style={{marginLeft:'40px',marginTop:'20px'}}>
      {generateInputs()}
    </div>
  );
};