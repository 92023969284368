import Icon from "../common/Icon";
import { removeLines } from "./canvasSnapping";

const isDebug = process.env.NODE_ENV === "development";
const PROPS_TO_INCLUDE = ["id", "animation", "meta"];

const MAX_OBJ_WIDTH = 640 * 3;
const MAX_OBJ_HEIGHT = 360 * 3;

const style = {
  positionAbsolute: { position: "absolute" },
  topLeftPostionZ: { position: "absolute", top: "50%", left: "50%", zIndex: 1 },
  marginTop20: { marginTop: 20 }
};

const copyIcon = <Icon name="copy" />;
const pasteIcon = <Icon name="edit" />;
const deleteIcon = <Icon name="delete" />;
const layersIcon = <Icon name="page_collection" />;
const shapesIcon = <Icon name="elements" className="element-icon" />;
const alignLeftIcon = <Icon name="align_left" />;
const alignCenterIcon = <Icon name="align_center" />;
const alignRightIcon = <Icon name="align_right" />;
const alignTopIcon = <Icon name="align_top" />;
const alignMiddleIcon = <Icon name="align_middle" />;
const alignBottomIcon = <Icon name="align_bottom" />;
const distributeHorizontalIcon = <Icon name="distribute_horizontal" />;
const distributeVerticalIcon = <Icon name="distribute_vertical" />;
const backIcon = <Icon name="back" />;
const frontIcon = <Icon name="front" />;
const forwardIcon = <Icon name="forward" />;
const backwardIcon = <Icon name="backward" />;
const rightArrowIcon = <Icon name="right_arrow" />;
const moveToIcon = <Icon name="move_layer" />;
const checkIcon = <Icon name="check" className="check-icon" />;
const textIcon = <Icon name="text" className="element-icon" />;
const listIcon = <Icon name="list" className="element-icon" />;
const imageIcon = <Icon name="image" className="element-icon" />;
const videoIcon = <Icon name="video_camera" className="element-icon" />;
const gifIcon = <Icon name="play_simple" className="element-icon" />;

const alignMenuItemChildren = [
  {
    icon: alignLeftIcon,
    label: "Left",
    key: "left"
  },
  {
    icon: alignCenterIcon,
    label: "Center",
    key: "center"
  },
  {
    icon: alignRightIcon,
    label: "Right",
    key: "right"
  },
  {
    icon: alignTopIcon,
    label: "Top",
    key: "top"
  },
  {
    icon: alignMiddleIcon,
    label: "Middle",
    key: "middle"
  },
  {
    icon: alignBottomIcon,
    label: "Bottom",
    key: "bottom"
  },
  {
    key: "distribute_horizontal",
    label: "Distribute horizontal spacing",
    icon: distributeHorizontalIcon
  },
  {
    key: "distribute_vertical",
    label: "Distribute vertical spacing",
    icon: distributeVerticalIcon
  }
];

const moveToMenuItemChildren = [
  {
    icon: backIcon,
    label: "Back",
    key: "sendToBack"
  },
  {
    icon: backwardIcon,
    label: "Backward",
    key: "sendBackwards"
  },
  {
    icon: forwardIcon,
    label: "Forward",
    key: "bringForward"
  },
  {
    icon: frontIcon,
    label: "Front",
    key: "bringToFront"
  }
];

const createLayerSubmenuItem = (layer, activeObject) => {
  const menuItemProps = {
    rect: { icon: shapesIcon, title: "Rectangle" },
    circle: { icon: shapesIcon, title: "Circle" },
    triangle: { icon: shapesIcon, title: "Triangle" },
    ellipse: { icon: shapesIcon, title: "Ellipse" },
    image: { icon: imageIcon, title: "Image" },
    path: { icon: shapesIcon, title: "SVG" },
    group: { icon: shapesIcon, title: "SVG" },
    video: { icon: videoIcon, title: "Video" },
    gif: { icon: gifIcon, title: "GIF" },
    lottie: { icon: gifIcon, title: "Lottie" },
    textbox: { icon: textIcon, title: layer.text },
    "i-text": { icon: textIcon, title: layer.text },
    list: { icon: listIcon, title: layer.text },
    frame: { icon: imageIcon, title: "Frame" }
  };

  const isObjectSelected = activeObject?.type === "activeSelection" ? activeObject.contains(layer) : layer.id === activeObject.id;

  return {
    label: (
      <div className="label-wrapper">
        <span className="check-icon-container">{isObjectSelected && checkIcon}</span>
        {menuItemProps[layer.type].icon}
        <span className="layer-title">{menuItemProps[layer.type].title}</span>
        {!["image", "group", "gif", "video", "lottie", "frame"].includes(layer.type) && (
          <div className="layer-color" style={{ backgroundColor: layer.fill }}></div>
        )}
      </div>
    ),
    key: layer.id
  };
};

const listenForShiftPressed = (isShiftPressed) => {
  const onShiftKeyDown = (e) => {
    if (e.key === "Shift") {
      isShiftPressed.current = true;
      removeLines();
    }
  };

  const onShiftKeyUp = (e) => {
    if (e.key === "Shift") isShiftPressed.current = false;
  };

  document.addEventListener("keydown", onShiftKeyDown);
  document.addEventListener("keyup", onShiftKeyUp);
  return () => {
    document.removeEventListener("keydown", onShiftKeyDown);
    document.removeEventListener("keyup", onShiftKeyUp);
  };
};

export {
  isDebug,
  PROPS_TO_INCLUDE,
  MAX_OBJ_WIDTH,
  MAX_OBJ_HEIGHT,
  style,
  copyIcon,
  pasteIcon,
  deleteIcon,
  layersIcon,
  alignLeftIcon,
  rightArrowIcon,
  moveToIcon,
  alignMenuItemChildren,
  moveToMenuItemChildren,
  createLayerSubmenuItem,
  listenForShiftPressed
};
