import { siteConfig } from "../../../Services";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setCTFinalProductAction,
  setCTSourceAction,
  setFooterButtonsConfigAction,
  setNextButtonDisableAction,
  setNextStepperStateAction,
  setPlatformListStateAction,
  setPreviousStepperStateAction,
  setRefetchContentHistoryListAction,
  setRefetchListStateAction,
  setRegenerateStateAction,
  setResponseCtAction
} from "../../../Store/actions/_contentTranscreationActions";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT } from "../../../Utils";
import { NameValueTypes } from "../../../Utils/_gTypes";
import { CT_FINAL_PRODUCTS_LIST_ITEM_TYPE, FooterButtonsConfigTypes } from "./modals";
// import { SSE } from "sse.js";

export const CT_FINAL_PRODUCTS = {
  VIDEO: "Video",
  SOCIAL_MEDIA_POST: "Social Media Post",
  BANNER:"Banner",
  JOURNAL_AD:"Journal Ad",
  LITERATURE:"Leave Behind Literature" ,
  POSTER:"Poster", 
  NEWSLETTER:"Newsletter" ,
  BROCHURE:"Brochure",
};

export const CT_FINAL_PRODUCTS_LIST: CT_FINAL_PRODUCTS_LIST_ITEM_TYPE[] = [];
Object.keys(CT_FINAL_PRODUCTS).forEach((key, index) =>
  CT_FINAL_PRODUCTS_LIST.push({ id: index, name: CT_FINAL_PRODUCTS[key as keyof typeof CT_FINAL_PRODUCTS], selected: index === 0 ? true : false })
);

export const CT_SOURCE_TYPES = {
  EMAIL: "Email",
  BLOG: "Blog",
  BANNER: "Banner",
  SOCIALMEDIA:"Social Media Post",
  POSTER:"Poster"
};

export const CT_VIDEO_SOURCE_LIST: CT_FINAL_PRODUCTS_LIST_ITEM_TYPE[] = [];
Object.keys(CT_SOURCE_TYPES).forEach((key, index) =>
  CT_VIDEO_SOURCE_LIST.push({ id: index, name: CT_SOURCE_TYPES[key as keyof typeof CT_SOURCE_TYPES], selected: index === 0 ? true : false })
);

export const CT_SOURCE_TYPE_LIST: NameValueTypes[] = [
  {
    name: "Email",
    value: "Email"
  },
  {
    name: "Blog",
    value: "Blog"
  },
  {
    name: "Banner",
    value:"Banner"
  }
  ,
  {
    name:"Social Media Post",
    value:"Social Media Post"
  },
  {
    name:"Poster",
    value:"Poster"
  }
  
];

const FooterButtonsConfigId = {
  PREVIOUS: 1,
  VIEW_SUMMARY: 2,
  NEXT: 3
};

export const FooterButtonsConfig: FooterButtonsConfigTypes[] = [
  {
    id: FooterButtonsConfigId.PREVIOUS,
    name: ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GO_BACK,
    isVisible: true
  },
  {
    id: FooterButtonsConfigId.VIEW_SUMMARY,
    name: ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.VIEW_SUMMARY,
    isVisible: false
  },
  {
    id: FooterButtonsConfigId.NEXT,
    name: ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.LETS_BEGIN,
    isVisible: true
  }
];

export const ENUM_PROCESS_SCREENS = Object.freeze({
  STORYBOARD_LIST_SCREEN: "STORYBOARD_LIST_SCREEN",
  IMAGE_GENERATION_SOURCE_SELECTION_SCREEN: "IMAGE_GENERATION_SOURCE_SELECTION_SCREEN",
  GENERATED_IMAGES_SCREEN: "GENERATED_IMAGES_SCREEN",
  TEMPLATE_SELECTION_SCREEN: "TEMPLATE_SELECTION_SCREEN",
  AVATAR_SELECTION_SCREEN: "AVATAR_SELECTION_SCREEN",
  VIDEO_PREVIEW_SCREEN: "VIDEO_PREVIEW_SCREEN",
  GO_TO_DASHBOARD: "GO_TO_DASHBOARD"
});

// const objFooterConfig:FooterButtonsConfigTypes = FooterButtonsConfig?.find((item:FooterButtonsConfigTypes)=> item?.id === FooterButtonsConfigId.VIEW_SUMMARY);

// objFooterConfig["isVisible"] = true;

// //action dispatch

// export const getCustomSSE = (url: string, payload?: any) => {
//   var headers: Record<string, string> = {
//     Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
//   };

//   const options = { ...headers };

//   if (payload) options["payload"] = payload;

//   const sse = new SSE(siteConfig.BASE_URL + url, {
//     //@ts-ignore
//     options
//   });

//   return sse;
// };
export const resetTranscreation = (dispatch: any) => {
  dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_0));
  dispatch(setNextStepperStateAction(false));
  dispatch(setPreviousStepperStateAction(false));
  dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED));
  dispatch(setNextButtonDisableAction(true));
  dispatch(setResponseCtAction({}));
  dispatch(setRefetchContentHistoryListAction(false));
  dispatch(setCTSourceAction(CT_SOURCE_TYPES.EMAIL));

  dispatch(setCTFinalProductAction(CT_FINAL_PRODUCTS.VIDEO));
  dispatch(setFooterButtonsConfigAction([...FooterButtonsConfig]));
  dispatch(setRegenerateStateAction(false));
  dispatch(setRefetchListStateAction(false));
  dispatch(setPlatformListStateAction([]));
};
