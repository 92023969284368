import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateReadMeStatus } from "../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import "./_Notifications.css"
const { Text } = Typography;

type IProps = {
  notification: Array<{ id: string; content: string; heading: string; created_at: string }>; // Adjust the type according to the actual structure of your notification
  setNotification: React.Dispatch<React.SetStateAction<Notification[]>>;
  setNotificationsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Notifications: FC<IProps> = ({ notification, setNotification, setNotificationsVisible }) => {
  const dispatch = useDispatch();
  const { selectedReports, activeModule, syncedNotificationsData } = useSelector((state: any) => state?.semanticSearchReducer);
  const { i18n, t } = useTranslation();
  const { isLoading }: { isLoading: boolean } = useSelector((state: any) => state?.nonPersistedReducer);
  const [isReadingAllFiles, setIsReadingAllFiles] = useState<Boolean>(false);

  const handleReadAllNotifications = async () => {
    setIsReadingAllFiles(true);
    const response = await UpdateReadMeStatus();
    if (!response) {
      dispatch(setNotificationMsgAction("Could not mark all notifications as read."));
      setIsReadingAllFiles(false);
      setNotificationsVisible(false);
      return;
    }
    dispatch(setNotificationMsgAction("Marked all notifications as read."));

    setIsReadingAllFiles(false);
    setNotification([]);
    setNotificationsVisible(false);
  };

  return (
    <div className="syncedNotification-container">
    <div className="syncedNotification-header">
      <div className="syncedNotification-title">NOTIFICATIONS ({notification.length})</div>
      <div
        className="syncedNotification-read-all"
        onClick={handleReadAllNotifications}
      >
        {isReadingAllFiles ? <Spin /> : "READ ALL"}
      </div>
    </div>
    <div className="syncedNotification-content">
      {notification && notification.length >= 1 ? notification.map((item, index) => (
        <div key={index} className="syncedNotification-item">
          <div className="syncedNotification-item-header">
            <div className="syncedNotification-item-heading">{item?.heading}</div>
            <div className="syncedNotification-item-date">{item?.created_at.split("T")[0]}</div>
          </div>
          <div className="syncedNotification-item-content">{item?.content}</div>
        </div>
      )) : null}
    </div>
  </div>
  );
};
