import { Card, Typography } from "antd";
import { FC } from "react";
import { TileProps } from "../Core/types";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const CircularAvatarTile: FC<TileProps> = ({ image, heading, description }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: "0px 6px 18px #00000029",
        borderRadius: "12px",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#CCEEFF",
          width: "50px",
          height: "50px",
          borderRadius: "50%"
        }}
      >
        <img src={image} width="24px" height={"24px"} />
      </div>
      <Title
        level={5}
        style={{
          fontWeight: "700",
          marginTop: "5px"
        }}
      >
        {t(heading)}
      </Title>
      <div>
        <Text style={{ fontSize: "12px" }}>{t(description as string)}</Text>
      </div>
    </Card>
  );
};

export { CircularAvatarTile };
