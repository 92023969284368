import { FC, useEffect, useRef, useState } from "react";
import { getPromptResultRequest } from "../../../Store/requests/_contentAIRequests";
import { globalConstant } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingStatusAction } from "../../../Store/actions/_commonActions";
import { Spin } from "antd";
import {
  setCurrentActiveStepActions,
  setResponseDataAction,
  setResponseLoaderFlagAction,
  setSelectedTagsAction
} from "../../../Store/actions/_contentAIActions";
import { CONTENT_CREATOR_STEPS, RESPONSE_LOADER_TEXT } from "../Core/constants";
import { GenerateResponse, InitialResponseScreen, RefinedResponseScreen } from "../Components/Content Creator";
import { useTranslation } from "react-i18next";

export const ContentCreatorWrapper: FC<{ setGetResultTimer: Function; clearGetResultTimer: Function }> = ({
  setGetResultTimer,
  clearGetResultTimer
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { currentActiveStep, responseLoading } = useSelector((state: any) => state?.contentAIReducer);
  const [userPrompt, setUserPrompt] = useState("");
  const [regenerating, setRegenerating] = useState(false);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = () => {
    !currentActiveStep && dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.GENERATE_RESPONSE));
  };

  const getResult = async (prompt_id: number, isEnriched: boolean, tagsSet?: boolean) => {
    clearGetResultTimer();

    const res = await getPromptResultRequest(prompt_id, isEnriched);

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      regenerating && setRegenerating(false);
      return;
    }

    //Set the selectedTags if not already set which is being tracked using tagsSet
    if (!tagsSet && res["selected_tags"]) {
      dispatch(setSelectedTagsAction(res["selected_tags"]));
    }

    if (res?.status === globalConstant.SUCCESS) {
      dispatch(setResponseLoaderFlagAction(false));
      regenerating && setRegenerating(false);
      dispatch(setResponseDataAction({ prompt: res.prompt, content: res.html_result, rules: res.rules }));

      if (isEnriched) {
        dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.REFINED_RESPONSE));
      } else {
        dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.INITIAL_RESPONSE));
      }

      return;
    }

    setGetResultTimer(
      setTimeout(() => {
        getResult(prompt_id, isEnriched, true);
      }, 5000)
    );
  };

  return (
    <>
      {responseLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "75vh", flexDirection: "column", margin: "auto" }}>
          <Spin size="large" />
          <div style={{ margin: "2rem" }}>
            {`${regenerating ? t(RESPONSE_LOADER_TEXT[CONTENT_CREATOR_STEPS.GENERATE_RESPONSE]) : t(RESPONSE_LOADER_TEXT[currentActiveStep])}`}
            <span className="loading-dots"></span>
          </div>
        </div>
      ) : (
        <>
          {/* Select Tags from Drop Down */}
          {/* No Loading Indicator. Does change anything with responseLoading or isLoading as dispatch is not being passed to the request handler */}
          {currentActiveStep === CONTENT_CREATOR_STEPS.GENERATE_RESPONSE && (
            <GenerateResponse userPrompt={userPrompt} setUserPrompt={setUserPrompt} getResult={getResult} />
          )}
          {/* Initial Response */}
          {currentActiveStep === CONTENT_CREATOR_STEPS.INITIAL_RESPONSE && (
            <InitialResponseScreen getResult={getResult} setRegenerating={setRegenerating} />
          )}
          {/* Refined Response | Enriched Prompt result */}
          {currentActiveStep === CONTENT_CREATOR_STEPS.REFINED_RESPONSE && <RefinedResponseScreen getResult={getResult} />}
        </>
      )}
    </>
  );
};
