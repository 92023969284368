import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, Typography, UploadFile, UploadProps } from "antd";
import { useDispatch } from "react-redux";
import Dragger from "antd/es/upload/Dragger";
import { setNotificationMsgAction } from "../../Store/actions/_commonActions";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { FileView } from "./Components/Upload Documents";
import { setActiveScreenTMAction, setOpenAddGlossaryModalTMAction, setUploadDocsDataTMAction } from "../../Store/actions/_translationModuleActions";
import { enumTMActiveScreen, initialUploadDocsData, initialUploadFileCustomData } from "./Core/constant";
import _iDetailExtractRequests from "../../Store/requests/_iDetailExtractRequests";
import { apiResponse } from "../../Utils/_gTypes";
import { useSelector } from "react-redux";
import { UploadDocsTypes, UploadFileCustom } from "./Core/modals";
import _translationModuleRequests from "../../Store/requests/_translationModuleRequests";
import { globalConstant } from "../../Utils";

const { Text } = Typography;
const supportedFormats = [
  "application/pdf",
  //"application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //.docx
  //"application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation" //.pptx
];

export const UploadDocumentMain = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const uploadDocsData: UploadDocsTypes = useSelector((state: any) => state?.translationModuleReducer?.uploadDocsData);

  const [percent, setPercent] = useState<number>(0);
  const [uploading, setUploading] = useState(false);
  const [processFiles, setProcessFiles] = useState<any[]>([]);

  useEffect(() => {
    if (!uploadDocsData?.files?.length && uploadDocsData.package_id) {
      dispatch(setUploadDocsDataTMAction({ ...initialUploadDocsData }));
    }
  }, [uploadDocsData]);

  useEffect(() => {
    if (!processFiles.length) return;

    if (!uploading) {
      handleUploadFile(processFiles[0], false, 0);
    }
  }, [processFiles]);

  const validateFileFormat = (file: any) => {
    if (supportedFormats.includes(file?.type)) {
      return true;
    }
    return false;
  };

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._UploadDocumentMain.${value as string}`])}`;
  };

  const props: UploadProps = {
    name: "file",
    accept: ".pdf,.docx,.pptx",
    // accept:
    //   "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
    multiple: true,
    showUploadList: false,
    customRequest: async ({ file }) => {
      if (!validateFileFormat(file)) {
        return;
      }

      setProcessFiles((pFiles) => [...pFiles, file]);
    },
    onChange: async (info) => {
      const { file } = info;
      const isLt50MB = (file.size as number) / 1024 / 1024 <= 128; //File size should be <= 50MB

      if (!validateFileFormat(info.file)) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
        return;
      }

      if (!isLt50MB) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
        return;
      }

      // await handleUploadFile(file, false, 0, arrFileList);
    }
  };

  const getAPIConfig = (arrFiles: UploadFileCustom[], currentIndex: number) => {
    return {
      onloadstart: () => {},
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (percentCompleted === 100) {
          return;
        }

        setPercent(percentCompleted);
      },

      onDownloadProgress: function () {
        arrFiles[currentIndex]["isUploading"] = false;

        dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, package_id: uploadDocsData.package_id, files: [...arrFiles] }));

        setPercent(100);
      }
    };
  };

  const handleUploadFile = async (file: any, reUpload: boolean, index: number) => {
    setUploading(true);

    const arrFiles: UploadFileCustom[] = [...uploadDocsData.files];

    let currentIndex: number = 0;

    if (reUpload) {
      currentIndex = index;
      arrFiles[index]["isUploading"] = true;
      arrFiles[index]["isError"] = false;
      arrFiles[index]["isUploaded"] = false;
    } else {
      currentIndex = arrFiles.length;
      arrFiles?.push({
        file_id: null,
        isError: false,
        isUploaded: false,
        isUploading: true,
        file: file,
        file_name: file?.name,
        file_size: file?.size,
        file_type: file?.type
      });
    }

    dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, package_id: uploadDocsData.package_id, files: [...arrFiles] }));

    const instFormData: FormData = new FormData();

    instFormData.append("file", file);

    const res: apiResponse = await _translationModuleRequests.uploadFile(
      instFormData,
      uploadDocsData.package_id ? uploadDocsData.package_id : null,
      undefined,
      getAPIConfig(arrFiles, currentIndex)
    );

    if (res?.data?.package_id && res?.data?.file_id) {
      const { package_id, file_id } = res.data;

      arrFiles[currentIndex]["file_id"] = file_id;
      arrFiles[currentIndex]["isError"] = false;
      arrFiles[currentIndex]["isUploaded"] = true;
      arrFiles[currentIndex]["isUploading"] = false;

      dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, package_id: package_id, files: [...arrFiles] }));
    } else {
      arrFiles[currentIndex]["file_id"] = null;
      arrFiles[currentIndex]["isError"] = true;
      arrFiles[currentIndex]["isUploaded"] = false;
      arrFiles[currentIndex]["isUploading"] = false;

      setPercent(0);

      dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, package_id: uploadDocsData.package_id, files: [...arrFiles] }));
    }

    setUploading(false);

    setProcessFiles((pFiles) => pFiles.slice(1));
  };

  const handleFileManipulation = (isDelete: boolean, index: number) => {
    if (isDelete) {
      handleDeleteFile(uploadDocsData.files[index]["file_id"], index);
      return;
    }

    handleUploadFile(uploadDocsData.files[index]["file"], true, index);
  };

  const handleDeleteFile = async (fileId: number | null, index: number) => {
    const res: apiResponse = await _translationModuleRequests.deleteFile(fileId ? fileId : 0);

    if (res?.message?.toUpperCase() === globalConstant.SUCCESS) {
      const arrFiles: UploadFileCustom[] = [...uploadDocsData.files];

      arrFiles?.splice(index, 1);

      dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, files: [...arrFiles] }));
    }
  };

  return (
    <div className="col-12" style={{ padding: 0 }}>
      <div className="cu-upload-right-pane" style={{ margin: "0px", marginLeft: "0px" }}>
        <Flex align="center" justify="space-between" style={{ marginBottom: "33px" }}>
          <Text style={{ fontSize: "18px", fontWeight: "700" }}>
            {t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.fileupload"])}
          </Text>
          <Button
            type="link"
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: uploadDocsData.files?.length && uploadDocsData?.package_id ? "rgba(3, 78, 162, 1)" : "rgba(230, 231, 232, 1)"
            }}
            onClick={() => dispatch(setOpenAddGlossaryModalTMAction(true))}
            disabled={uploadDocsData.files?.length && uploadDocsData?.package_id ? false : true}
          >
            {getTranslatedText("ADD GLOSSARY")}
          </Button>
        </Flex>

        <div className="custom-row">
          <div className="col-7" style={{ paddingLeft: 0 }}>
            <div style={{ maxHeight: "64vh", height: "100%" }}>
              <div className="cu-file-upload" style={{ height: "100%" }}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <img width="150px" src={PROJECT_ICONS.C_AI_FILE_UPLOAD_GIF} />
                  </p>
                  <div className="cu-upload-hint">
                    <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"])}</div>
                  </div>
                </Dragger>
              </div>
              <div className="cu-upload-footer">
                <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB"])}</div>
                <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.supportedfiletype"])} .pdf, .docx, .pptx</div>
              </div>
            </div>
          </div>

          <div className="col-5" style={{ paddingLeft: "10px" }}>
            <div className="cu-files-list-area">
              <Flex justify="space-between" style={{ marginBottom: "5px" }}>
                <Text style={{ fontSize: "16px", fontWeight: "600" }}>
                  {" "}
                  {t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.uploadedfile"])} ({uploadDocsData.files?.length})
                </Text>
                {uploadDocsData.files?.length ? (
                  <Button
                    type="link"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: uploadDocsData.files?.length && !uploading && !processFiles?.length ? "rgba(3, 78, 162, 1)" : "rgba(230, 231, 232, 1)"
                    }}
                    onClick={() => {
                      dispatch(setUploadDocsDataTMAction({ ...initialUploadDocsData }));
                    }}
                    disabled={uploadDocsData.files?.length && !uploading && !processFiles?.length ? false : true}
                  >
                    {" "}
                    {getTranslatedText("Clear All")}
                  </Button>
                ) : (
                  <></>
                )}
              </Flex>
              <div className="cu-files-list">
                {uploadDocsData?.files?.length ? (
                  uploadDocsData?.files?.map((item: UploadFileCustom, index: number) => (
                    <>
                      {item?.file && (
                        <FileView
                          key={item.file_id}
                          isError={item.isError}
                          isUploaded={item.isUploaded}
                          uploading={item.isUploading}
                          name={item?.file_name}
                          size={item?.file_size}
                          handleFileManipulation={(isDelete) => handleFileManipulation(isDelete, index)}
                          percent={item.isUploading === true && item.isUploaded === false ? percent : 100}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <div className="no-files-found">{t(I18N_MODULE_KEYS["content.text.nofilesuploaded"])}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12" style={{ padding: 0, marginTop: "50px" }}>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "12px", height: "71px", background: "rgba(248, 248, 248, 1)", borderRadius: "8px" }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "rgba(154, 154, 154, 1)",
                  letterSpacing: "0.6px"
                }}
              >
                {uploadDocsData?.files?.filter((item) => item?.isUploaded === true)?.length} of {uploadDocsData?.files?.length}{" "}
                {getTranslatedText("files uploaded")}
              </Text>
              <Button
                type="primary"
                style={{
                  fontSize: "12px",
                  background: uploadDocsData.files?.length && !uploading && !processFiles?.length ? "rgba(3, 78, 162, 1)" : "rgba(230, 231, 232, 1)",
                  padding: "4px, 10px, 4px, 10px",
                  width: "92px",
                  borderRadius: "0px"
                }}
                onClick={() => dispatch(setActiveScreenTMAction(enumTMActiveScreen.TRANSLATE_ASSET))}
                disabled={uploadDocsData.files?.length && !uploading && !processFiles?.length ? false : true}
              >
                {getTranslatedText("NEXT")}
              </Button>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
};
