import { LeftOutlined, PlaySquareOutlined } from "@ant-design/icons";
import { Button, Card, Carousel, Col, Drawer, Flex, Image, Input, Modal, Row, Select, Typography, message } from "antd";
import { CSSProperties, FC, useEffect, useState } from "react";
import "./_sidebar.css";
import { elaiAvatarsList } from "../../../core/mockData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store";
import { useDispatch } from "react-redux";
import { updateElaiVideoData } from "../../../../../../Store/requests/_contentTranscreationRequests";
import { setElaiTranslationDataAction } from "../../../../../../Store/actions/_contentTranscreationActions";

const { Text } = Typography;

const objText: CSSProperties = {
  lineHeight: "110%",
  fontSize: "12px",
  fontWeight: "700"
};

type AvatarListDataType = {
  code: string;
  id: number;
  _id: string;
  avatar_id: string;
  name: string;
  variant_name: string;
  variant_id: string;
  intro: string;
  introPoster: string;
  is_active: boolean;
  canvas: string;
};

type AvatarDataType = {
  code: string;
  id: string;
  name: string;
  duration: number | string;
  avatar_id: string;
  list: AvatarListDataType[];
  variants: AvatarListDataType[];
  gender: string;
};

// const avatarData: AvatarDataType[] = [
//   {
//     duration: "1.2",
//     avatar_id: "kira",
//     name: "Kira",
//     list: [
//       {
//         id: 6,
//         _id: "6426982cd78b5bdaad49b022",
//         avatar_id: "kira",
//         name: "Kira",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-doctor-1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-doctor.png",
//         is_active: true
//       },
//       {
//         id: 5,
//         _id: "6426982cd78b5bdaad49b022",
//         avatar_id: "kira",
//         name: "Kira",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/kira-business-1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/kira/new_intro/Kira-business.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   },
//   {
//     duration: "1",
//     avatar_id: "max",
//     name: "Max",
//     list: [
//       {
//         id: 7,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Business",
//         variant_id: "business",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-business_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-business.png",
//         is_active: true
//       },
//       {
//         id: 8,
//         _id: "642697c64719fcc41a34fe03",
//         avatar_id: "max",
//         name: "Max",
//         variant_name: "Doctor",
//         variant_id: "doctor",
//         intro: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/max-doctor_1080.mp4",
//         introPoster: "https://d3u63mhbhkevz8.cloudfront.net/common/max/new_intro/Max-doctor.png",
//         is_active: true
//       }
//     ]
//   }
// ];

const avatarData: any = elaiAvatarsList;

const all_avatar_key = "all_avatar_key";

export const AvatarDrawer: FC = () => {
  const [filteredAvatarData, setFilteredAvatarData] = useState<AvatarDataType[]>([]);
  const [selectedAvatarKey, setSelectedAvatarKey] = useState<string>(all_avatar_key);
  const [selectedMainAvatar, setSelectedMainAvatar] = useState<AvatarDataType[] | null>(null);
  const [clickedAvatarItem, setClickedAvatarItem] = useState<AvatarListDataType | null>(null);

  const { elaiResponse, activeSlide } = useSelector((state: RootState) => state.contentTranscreationReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredAvatarData([...avatarData]);
  }, []);

  console.log("selectedAvatarKey", selectedAvatarKey);
  console.log("selectedMainAvatar", selectedMainAvatar);

  const handleOnChangeSelect = (key: string) => {
    setSelectedAvatarKey(key);
    setSelectedMainAvatar(null);

    if (key === all_avatar_key) setFilteredAvatarData([...avatarData]);
    else setFilteredAvatarData([...avatarData?.filter((item: AvatarDataType) => item?.id === key)]);
  };

  const handleOnChangeInput = (e: any) => {
    setSelectedMainAvatar(null);
    let { value } = e?.target;

    value = value?.toLowerCase?.()?.trim?.();

    if (!value) handleOnChangeSelect(selectedAvatarKey);
    else
      setFilteredAvatarData([...filteredAvatarData?.filter((item: AvatarDataType) => item?.name?.toLocaleLowerCase?.()?.trim?.()?.includes(value))]);
  };

  const updateAvatar = async (listItem: AvatarListDataType) => {
    const mainAvatarObject = selectedMainAvatar;
    console.log("Updated Avatar Item", listItem);

    const avatarObj = {
      code: selectedMainAvatar?.[0].code + "." + listItem.code,
      gender: selectedMainAvatar?.[0].gender
    };

    const updatedElaiResponse = structuredClone(elaiResponse);

    const slideAvatar = updatedElaiResponse.slides[activeSlide].avatar;
    slideAvatar.id = avatarObj.code;
    slideAvatar.gender = avatarObj.gender;

    const canvas = updatedElaiResponse.slides[activeSlide].canvas;
    const canvasAvatarObject = canvas.objects.filter((object: any) => object.type === "avatar")[0];
    canvasAvatarObject.src = listItem.canvas;

    console.log("updatedElai", updatedElaiResponse, "elai", elaiResponse);

    const res = await updateElaiVideoData(updatedElaiResponse);

    console.log("res", res);
    message.success("Saved!");

    dispatch(setElaiTranslationDataAction({ ...elaiResponse, ...res }));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }}>
        <Flex gap="small">
          <Select
            value={selectedAvatarKey}
            style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }}
            showSearch
            optionFilterProp="label"
            defaultValue={all_avatar_key}
            onChange={handleOnChangeSelect}
            options={[
              {
                value: all_avatar_key,
                label: "All Avatar"
              },
              ...avatarData?.map((item: AvatarDataType) => {
                return {
                  value: item?.id,
                  label: item?.name
                };
              })
            ]}
          />
          <Input placeholder="Search by name" onChange={handleOnChangeInput} />
        </Flex>
      </Col>
      <Col span={24} style={{ maxHeight: "calc(100vh - 190px)", overflowY: "auto" }}>
        {filteredAvatarData && filteredAvatarData?.length ? (
          <Row gutter={[16, 16]}>
            {filteredAvatarData?.map((item: AvatarDataType, index: number) => {
              return (
                <AvatarItemCard
                  key={`avatardata_index_${index}`}
                  item={item}
                  handlePreview={(i) => setClickedAvatarItem(item?.variants?.[0])}
                  onClick={() => setSelectedMainAvatar([item])}
                  isInnerCard={false}
                />
              );
            })}
          </Row>
        ) : (
          <Text style={{ ...objText, margin: "auto" }}>No Avatar Found!</Text>
        )}
        <Flex style={{ height: "calc(100vh - 300px)" }}>
          <AvatarListSlider
            open={selectedMainAvatar === null ? false : true}
            onClose={() => setSelectedMainAvatar(null)}
            item={selectedMainAvatar}
            handlePreview={(i) => setClickedAvatarItem(i)}
            updateAvatar={updateAvatar}
          />
        </Flex>
      </Col>

      <PreviewModal
        open={clickedAvatarItem === null ? false : true}
        src={clickedAvatarItem?.intro || ""}
        closeModal={() => setClickedAvatarItem(null)}
        avatarName={clickedAvatarItem?.name || ""}
      />
    </Row>
  );
};

type IProps = {
  src: string;
  open: boolean;
  avatarName: string;
  closeModal: () => void;
};
const PreviewModal: FC<IProps> = ({ src, open, closeModal, avatarName }) => {
  const [renderLoading, setRenderLoading] = useState<boolean>(true);
  return (
    <Modal
      open={open}
      destroyOnClose
      closable
      afterClose={closeModal}
      onCancel={closeModal}
      title={<Text style={objText}>{avatarName}</Text>}
      footer={false}
    >
      <Flex vertical gap="middle">
        <video controls src={src} style={{ maxHeight: "50vh" }} onEnded={() => setRenderLoading(true)} onLoadedData={() => setRenderLoading(false)} />

        <Flex justify="flex-end" align="center" gap="small">
          <Button onClick={closeModal}>Close</Button>
          <Button type="primary">Choose</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

type AvatarListProps = {
  open: boolean;
  onClose: () => void;
  item: AvatarDataType[] | null;
  handlePreview: (item: AvatarListDataType | null) => void;
  updateAvatar: Function;
};

const AvatarListSlider: FC<AvatarListProps> = ({ open, onClose, item, handlePreview, updateAvatar }) => {
  return (
    <Drawer
      title={
        <Flex justify="space-between">
          <LeftOutlined onClick={onClose} />
          <Text style={{ ...objText, margin: "auto" }}>{item?.[0]?.name}</Text>
        </Flex>
      }
      style={{ width: "400px", background: "white", boxShadow: "none" }}
      bodyStyle={{ boxShadow: "none" }}
      getContainer={false}
      closeIcon={false}
      onClose={onClose}
      open={open}
      rootStyle={{ boxShadow: "none" }}
      className="sidebar"

      // style={{[`ant-drawer-content-wrapper`]:{boxShadow:"none"}}}
    >
      <Row gutter={[16, 16]}>
        {item?.map((i: AvatarDataType, index: number) => {
          return <AvatarItemCard item={i} handlePreview={handlePreview} isInnerCard={true} updateAvatar={updateAvatar} />;
        })}
      </Row>
    </Drawer>
  );
};

type AvatarItemCardProps = {
  item: AvatarDataType;
  handlePreview: (item: AvatarListDataType | null) => void;
  onClick?: () => void;
  isInnerCard: boolean;
  updateAvatar?: Function;
};

const AvatarItemCard: FC<AvatarItemCardProps> = ({ item, handlePreview, onClick, isInnerCard, updateAvatar }) => {
  return (
    <>
      {isInnerCard ? (
        <>
          {item?.variants?.map((listItem: AvatarListDataType, listIndex: number) => {
            return (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} key={listIndex}>
                  <Flex vertical gap="small">
                    <Image
                      key={`avatardatalist_index_${listIndex}`}
                      src={listItem.canvas}
                      preview={false}
                      onClick={() => {
                        updateAvatar?.(listItem);
                      }}
                    ></Image>
                    <Flex justify="space-between" align="center">
                      <Text style={objText}>{listItem?.name}</Text>
                      <Button icon={<PlaySquareOutlined />} style={{ padding: "0 9px", fontSize: "12px" }} onClick={() => handlePreview(listItem)}>
                        Preview
                      </Button>
                    </Flex>
                  </Flex>
                </Col>
              </>
            );
          })}
        </>
      ) : (
        <>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Flex vertical gap="small">
              <Carousel style={{ border: "1px solid #d9d9d9", borderRadius: "8px", transition: "border .2s ease-in-out", cursor: "pointer" }}>
                {item?.variants?.map((listItem: AvatarListDataType, listIndex: number) => {
                  return <Image key={`avatardatalist_index_${listIndex}`} src={listItem.canvas} preview={false} onClick={onClick}></Image>;
                })}
              </Carousel>
              <Flex justify="space-between" align="center">
                <Text style={objText}>{item?.name}</Text>
                <Button icon={<PlaySquareOutlined />} style={{ padding: "0 9px", fontSize: "12px" }} onClick={() => handlePreview(null)}>
                  Preview
                </Button>
              </Flex>
            </Flex>
          </Col>
        </>
      )}
    </>
  );
};
