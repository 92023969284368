import { Button, Card, Col, Row, Typography, Spin } from "antd";
import { SSE } from "sse.js";
import { FC, useEffect, useRef, useState } from "react";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { CT_DASHBOARD_STATUS, getDefaultLayout, handleCancelApiRequest } from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  Billing,
  CopilotMode,
  FileSystemWrapper,
  FloatingSideBarSemantic,
  FollowupSearch,
  LandingInitialSearch,
  NextInsightPage,
  PptEditDownload,
  UserHistory,
} from "./Components";
import "./Components/Core/semantic_search.css";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { setLayoutSyncAction, setLoadingStatusAction, setNotificationMsgAction } from "../../Store/actions/_commonActions";
import { SemanticSearchLoading, SemanticSearchZeroStates, AdvancedFilterContent, AdvancedFilterHead } from "./Components";
import { Authorizer, siteConfig } from "../../Services";
import { getMedicalReferenceChunks, getMessage } from "../../Store/requests/_semanticSearchRequests";
import {
  setAdvancedFilter,
  setAdvancedFilterStatus,
  setCardsDataAction,
  setEncodedSearchQueryAction,
  setFilterListAction,
  setInitialFolders,
  setRefetchListAction,
  setSearchQuery,
  setSelectedReportAction,
  setSemanticSearchId,
  setTabListAction,
  setCardData,
  setStatistisc,
  setKvalue,
  setFormat,
  setEncodedSemanticSearchId,
  setActiveModuleActions,
  setRegenerateStatus,
  setimageCardsDataAction,
  setActiveCardType,
  setSemanticSearchImageId,
  setLandingScreen,
  setCardDataCollection,
  setConversationId,
  setPreviousId,
  setNextId,
  setProModeStatus,
  setProModeScreen,
  setMessageIId,
  setEncodedSemanticSearchIdKvalue,
  setEditableScreen,
  setLoadingforReportGeneration,
  setReportGenerationType,
  setSearchType,
  setLLMModel,
  setVideoCardsDataAction,
  setAudioCardsDataAction,
  FileForChatWithDoc,
  setBatchQAStatus,
  setBatchQAData,
  setBatchQAFolders,
} from "../../Store/actions/_semanticSearchActions";
import { setActionDrawerOpenAction, onDrawerBackAction, setActionDrawerHeaderOptionsAction } from "../../Components/ActionDrawer/State";
import { MED_USER_EMAIL, SEMANTIC_SEARCH_CARD, SEMANTIC_SEARCH_MODULES } from "./Components/Core/constants";
import _localStorageService from "../../Services/_localStorageService";
import { ModalComponentForFormat, PdfEditDownload } from "./Components/SearchQueryScreens";
import { Notifications } from "../../Components/Navbar/Semantic Search/_Notifications";
import { GeneralArtifacts } from "./Components/Artifacts/_GeneralArtifacts";
import { UseCaseSelection } from "./Components/_UseCaseSelection";
import { ArtifactsWrapper } from "./Components/Artifacts/_ArtifactsWrapper";
import { FloatingBallSideBarMenu } from "./Components/_FloatingBallSideBarMenu";
import MultiModalFileUpload from "./Components/_MultiModalFileUpload";
import { BatchQAScreen } from "./Components/BatchQA/_BatchQAScreen";
import { LiteratureReferenceWrapper } from "./Components/SearchQueryScreens/LiteratureReference/LiteratureReferenceWrapper";
import { LiteratureReferenceCardSearchBar } from "./Components/SearchQueryScreens/LiteratureReference/_LiteratureReferenceCardSearchBar";
import { SearchingContainer } from "../Translation Module/Components/Translate Asset";
import { LiteratureReferenceCard } from "./Components/SearchQueryScreens/LiteratureReference/_LiteratureReferenceCard";

const authorizer = new Authorizer();

const SemanticSearchWrapper: FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const timerRef: any = useRef();
  const apiCounterRef: any = useRef();
  const controllerRef: any = useRef();
  const [columns, setColumns] = useState<string[]>([""]);
  const [tagsForProMode, setTagForProMode] = useState<string[]>([]);
  controllerRef.current = new AbortController();
  const [internalVarA, setInternalVarA] = useState<number>(10);
  const [internalVarB, setInternalVarB] = useState<string>(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]));
  const [stepperForinternalVarB, setStepperForInternalVarb] = useState<number>(1);
  const {
    semanticEncodedSearchid,
    searchQuery,
    advancedFilter,
    refetchList,
    advancedFilterList,
    advancedFilterStatus,
    cardData,
    activeModule,
    regeneratestatus,
    cardsImageData,
    cardType,
    semanticSearchImageId,
    landingScreen,
    cardDataCollection,
    conversationId,
    semanticEncodedSearchidkvalue,
    previousId,
    nextId,
    proModeStatus,
    proModeScreen,
    messageId,
    editReportGenerationScreen,
    reportType,
    llmModel,
    fileForChatWithDoc,batchQAStatus
  } = useSelector((state: any) => state?.semanticSearchReducer);
const {userDetails} = useSelector((state: any) => state?.authReducer);
  const [cardsDatalocal, setCardDatalocal] = useState<any>([]);
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);
  const { isLoading }: { isLoading: boolean } = useSelector((state: any) => state?.nonPersistedReducer);
  const [resp, setresp] = useState<any>({});
  const [apiCounter, setApiCounter] = useState<number>(0);
  const sseRef = useRef<SSE | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();

  console.log(userDetails);
  console.log("This is user Details")

  apiCounterRef.current = apiCounter;

  useEffect(() => {
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;

    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;

    initiate();

    return () => {
      goBackToDashboard();
    };
  }, []);

  const initiate = async () => {
    dispatch(setActiveModuleActions(SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH));

    const isValid = await authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);

    if (!isValid) return;
    startSemanticLayout();
  };

  useEffect(() => {
    if (semanticEncodedSearchid !== 0) {
      console.log("this is one ide" + semanticEncodedSearchid);
      setApiCounter(0);
      if (searchQuery && userDetails?.email!==MED_USER_EMAIL) {
        if (proModeStatus) {
        } else {
          getAnswer();
        }
      }
      else if(searchQuery && userDetails?.email===MED_USER_EMAIL){
          getChunksForMedicalReference();
      }
    } else {
      console.log("it is being called");
      handleStopApiCall();
      //  handleStopApiCall()b
      //  fetchData()
    }
  }, [semanticEncodedSearchid]);
  useEffect(() => {
    dispatch(setFormat(stepperForinternalVarB));

    if (semanticEncodedSearchidkvalue !== 0) {
      console.log("this is one ide" + semanticEncodedSearchidkvalue);
      getAnswerForKvalue();
    }
  }, [semanticEncodedSearchidkvalue]);

  useEffect(() => {
    if (nextId) {
      dispatch(setLoadingStatusAction(true));
      fetchNextMessage();
    }
  }, [nextId]);

  useEffect(() => {
    if (previousId) {
      dispatch(setLoadingStatusAction(true));
      fetchPreviousMessage();
    }
  }, [previousId]);
  useEffect(() => {
    if (messageId) {
      dispatch(setLoadingStatusAction(true));
      fetchSpecificMessage();
    }
  }, [messageId]);
  useEffect(() => {
    if (landingScreen) {
      
      if (sseRef.current) sseRef.current.close();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }
  }, [landingScreen]);


  const getChunksForMedicalReference=async()=>{
    dispatch(setLoadingStatusAction(true));
   const res= await getMedicalReferenceChunks({question:searchQuery,filters:advancedFilterList});
  if(!res){
    dispatch(setSearchQuery(""))
    dispatch(setLandingScreen(true));
    dispatch(setLoadingStatusAction(false))
    return;
  }
  dispatch(setCardsDataAction(res?.data))
  dispatch(setLoadingStatusAction(false))
  }

  const fetchMessage = async (id: any, actionType: any) => {
    dispatch(setNextId(0));
    dispatch(setPreviousId(0));
    dispatch(setMessageIId(0));
    try {
      // Call your API here
      const res = await getMessage(id); // Replace this with your API call
      if (!res) {
        dispatch(setLoadingStatusAction(false));
        dispatch(setNotificationMsgAction("Error fetching the response "));
        return;
      }

      console.log("API called:", res?.data);
      dispatch(setCardData(res?.data?.text_semantic_search));
      console.log(res?.data?.text_semantic_search?.result);
      dispatch(setSearchQuery(res?.data?.question));
      dispatch(setProModeScreen(res?.data?.text_semantic_search?.promode));

      const outputFormat = res?.data?.text_semantic_search?.output_format;
      if (outputFormat === "table") {
        setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.tablesmall"]));
        setStepperForInternalVarb(3);
        dispatch(setFormat(3));
      } else if (outputFormat === "text") {
        setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]));
        setStepperForInternalVarb(1);
        dispatch(setFormat(1));
      } else {
        setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.listsmall"]));
        setStepperForInternalVarb(2);
        dispatch(setFormat(2));
      }

      dispatch(setCardsDataAction(res?.data?.text_semantic_search_chunks?.data));
      dispatch(setimageCardsDataAction(res?.data?.image_descriptions));
      dispatch(setAudioCardsDataAction(res?.data?.audio_transcripts));
      dispatch(setVideoCardsDataAction(res?.data?.video_transcripts));
      dispatch(setLoadingStatusAction(false));

      // Reset specific action
      if (actionType === "previous") {
        dispatch(setPreviousId(0));
      } else if (actionType === "next") {
        dispatch(setNextId(0));
      } else if (actionType === "specific") {
        dispatch(setMessageIId(0));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(setNotificationMsgAction("Error fetching the response "));
      dispatch(setLoadingStatusAction(false));

      // Reset specific action
      if (actionType === "previous") {
        dispatch(setPreviousId(0));
      } else if (actionType === "next") {
        dispatch(setNextId(0));
      } else if (actionType === "specific") {
        dispatch(setMessageIId(0));
      }

      // Handle errors here
    }
  };

  // Usage examples
  const fetchSpecificMessage = async () => {
    await fetchMessage(messageId, "specific");
  };

  const fetchPreviousMessage = async () => {

    await fetchMessage(previousId, "previous");
  };

  const fetchNextMessage = async () => {

    await fetchMessage(nextId, "next");
  };

  useEffect(() => {
    async function applyadvancedfilterapicall() {
      dispatch(setFormat(stepperForinternalVarB));
      if (!searchQuery) {
        dispatch(setRegenerateStatus(false));
        return;
      }

      dispatch(setLoadingStatusAction(true));
      getAnswerForRegenerate();
    }
    if (advancedFilterStatus || regeneratestatus) {
      if(userDetails.email!==MED_USER_EMAIL)
      {applyadvancedfilterapicall();}
    }
  }, [advancedFilterList, regeneratestatus]);

  const startSemanticLayout = () => {
    const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
    dispatch(setLayoutSyncAction({ ...objNewLayoutSync, SEMANTIC_SEARCH: true }));
    resetReduxState();
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  };

  const goBackToDashboard = () => {
    const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);
    dispatch(setLayoutSyncAction({ ...objNewLayoutSync, CONTENT_TRANSCREATION: true }));
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
    handleCancelApiRequest();
    resetReduxState();
  };

  const resetReduxState = async () => {
    dispatch(setSearchQuery(""));
    dispatch(setLandingScreen(true));
    dispatch(setTabListAction([]));
    dispatch(setInitialFolders([]));
    dispatch(setCardsDataAction([]));
    dispatch(setSemanticSearchId(0));
    dispatch(setFilterListAction([]));
    dispatch(setAdvancedFilter(false));
    dispatch(setSelectedReportAction([]));
    dispatch(setLoadingStatusAction(false));
    dispatch(setAdvancedFilterStatus(false));
    dispatch(setEncodedSearchQueryAction(""));
    dispatch(setActionDrawerOpenAction(false));
    dispatch(onDrawerBackAction(() => {}));
    dispatch(setEncodedSemanticSearchIdKvalue(0));
    dispatch(setActionDrawerHeaderOptionsAction([]));
    dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
    dispatch(setKvalue(4));
    dispatch(setFormat(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"])));
    dispatch(setEncodedSemanticSearchId(0));
    dispatch(setActiveModuleActions(SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH));
    dispatch(setCardDataCollection([]));
    dispatch(setEncodedSemanticSearchId(0));
    dispatch(setActiveModuleActions(SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH));
    dispatch(setLandingScreen(true));
    dispatch(setPreviousId(0));
    dispatch(setNextId(0));
    dispatch(setProModeStatus(false));
    dispatch(setProModeScreen(false));
    dispatch(setConversationId(0));
    dispatch(setMessageIId(0));
    dispatch(setEditableScreen(false));
    dispatch(setLoadingforReportGeneration(false));
    dispatch(setReportGenerationType(""));
    dispatch(setSearchType(""));
    dispatch(setLLMModel("gpt-4o"));
    dispatch(setAudioCardsDataAction([]));
    dispatch(setVideoCardsDataAction([]));
    dispatch(FileForChatWithDoc(""))
    dispatch(setFilterListAction([]));
    dispatch(setBatchQAStatus(false));
    dispatch(setBatchQAData([]));
    dispatch(setBatchQAFolders([]));
  };

  // Define checkAllEventsEnded in the broader scope

  // Other utility functions
  const parseJSON = (data: string) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const createSSEConnection = (apiUrl: string, headers: Record<string, string>, payload: any) => {
    return new SSE(apiUrl, {
      headers,
      payload: JSON.stringify(payload),
    });
  };

  const handleSSEError = (sse: SSE, timeoutId: NodeJS.Timeout, message: string) => (error: any) => {
    sse.close();
    clearTimeout(timeoutId);
    if (sseRef.current) sseRef.current.close();
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    dispatch(setLandingScreen(true));
    dispatch(setLoadingStatusAction(false));
    dispatch(setRegenerateStatus(false));
    dispatch(setNotificationMsgAction(message));
    console.error("SSE Error:", error);
  };

  const handleEndEvent = (event: MessageEvent, event1Ended: boolean) => {
    event1Ended = true;
    console.log("SSE message:", event.data);
    const jsonObject = parseJSON(event.data);
    if (jsonObject) {
      console.log(jsonObject?.text_semantic_search);
      dispatch(setCardData(jsonObject?.text_semantic_search));
      dispatch(setCardsDataAction(jsonObject?.text_semantic_search_chunks?.data));
      dispatch(setimageCardsDataAction(jsonObject.image_descriptions));
      dispatch(setAudioCardsDataAction(jsonObject?.audio_transcripts));
      dispatch(setVideoCardsDataAction(jsonObject?.video_transcripts));
      setresp(jsonObject?.text_semantic_search);
    }
    const checkAllEventsEnded = () => {
      if (!event1Ended) {
        if (cardDataCollection?.length >= 1) {
          console.log(cardDataCollection);
          dispatch(setCardDataCollection([...cardDataCollection, "extra"]));
        } else {
          dispatch(setCardDataCollection(["first"]));
        }
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        dispatch(setLoadingStatusAction(false));
        dispatch(setRegenerateStatus(false));
      }
    };
    checkAllEventsEnded();
  };

  const getPayload = (type: "default" | "regenerate" | "kvalue"): any => {
    const fileForChatWithDocArray = [fileForChatWithDoc]
    switch (type) {
      case "regenerate":
        return {
          message_id: cardData?.message,
          output_format: stepperForinternalVarB === 1 ? "text" : stepperForinternalVarB === 3 ? "table" : "list",
          k: internalVarA,
          user_id: 0,
          filters: advancedFilterList,
          promode: proModeScreen,
          tags: tagsForProMode,
          column_names: columns?.filter((column) => column?.trim() !== ""),
        };
      case "kvalue":
        return {
          conversation_id: conversationId,
          question: searchQuery,
          text_semantic_search: {
            output_format: stepperForinternalVarB === 1 ? "text" : stepperForinternalVarB === 2 ? "list" : "table",
            k: internalVarA,
            user_id: 0,
            filters: fileForChatWithDoc?fileForChatWithDocArray : advancedFilterList,
            promode: proModeScreen,
            tags: tagsForProMode,
            column_names: columns?.filter((column) => column?.trim() !== ""),
            filter_type:fileForChatWithDoc?"file":advancedFilterList.length>=1 ?"folder":""

          },
          promode: proModeScreen,
        };
      default:
        return {
          conversation_id: conversationId,
          question: searchQuery,
          text_semantic_search: {
            output_format: "text",
            k: 10,
            user_id: 0,
            filters: fileForChatWithDoc?fileForChatWithDocArray : advancedFilterList,
            promode: proModeScreen,
            tags: tagsForProMode,
            filter_type:fileForChatWithDoc?"file":advancedFilterList.length>=1 ?"folder":""
          },
          promode: proModeScreen,
          llm_model: llmModel,
        };
    }
  };

  const handleSSEEvent = (eventName: string, dataReceivedFlag: boolean) => {
    return (event: MessageEvent) => {
      dataReceivedFlag = true;
      console.log(`SSE message ${eventName}:`, event.data);
      const jsonObject = parseJSON(event.data);
      if (jsonObject) {
        switch (eventName) {
          case "text_semantic_search":
            dispatch(setCardData(jsonObject));
            break;
          case "text_semantic_search_chunks":
            dispatch(setCardsDataAction(jsonObject.data));
            break;
          case "image_descriptions":
            dispatch(setimageCardsDataAction(jsonObject));
            break;
          case "audio_transcripts":
            dispatch(setAudioCardsDataAction(jsonObject));
            break;
          case "video_transcripts":
            dispatch(setVideoCardsDataAction(jsonObject));
            break;
          default:
            console.warn(`Unhandled SSE event type: ${eventName}`);
        }
      }
      const checkAllEventsEnded = () => {
        if (event) {
          if (cardDataCollection?.length >= 1) {
            console.log(cardDataCollection);
            dispatch(setCardDataCollection([...cardDataCollection, "extra"]));
          } else {
            dispatch(setCardDataCollection(["first"]));
          }
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          dispatch(setLoadingStatusAction(false));
          dispatch(setRegenerateStatus(false));
        }
      };
      checkAllEventsEnded();
    };
  };

  const getAnswerCommon = async (type: "default" | "kvalue") => {
    let apiUrl = "";
    setCardDatalocal(null);
    dispatch(setLoadingStatusAction(true));
    apiUrl = `${apiurl}/conversation/message`;

    const accessToken = token || "";
    console.log(accessToken);

    let event1Ended = false;
    let textSemanticSearchReceived = false;
    let textSimilaritySearchReceived = false;
    let imageSemanticSearchReceived = false;
    let audioSemanticSearchReceived = false;
    let videoSemanticSearchReceived = false;

    const timeoutId = setTimeout(() => closeSSEConnections(), 100000);
    timeoutRef.current = timeoutId;

    const closeSSEConnections = () => {
      if (!event1Ended) {
        if (cardDataCollection?.length >= 1) {
          console.log(cardDataCollection);
          dispatch(setCardDataCollection([...cardDataCollection, "extra"]));
        } else {
          dispatch(setCardDataCollection(["first"]));
        }
      }
      if (!textSemanticSearchReceived) dispatch(setCardData([]));
      if (!textSimilaritySearchReceived) dispatch(setCardsDataAction([]));
      if (!imageSemanticSearchReceived) dispatch(setimageCardsDataAction([]));
      if (!audioSemanticSearchReceived) dispatch(setAudioCardsDataAction([]));
      if (!videoSemanticSearchReceived) dispatch(setVideoCardsDataAction([]));

      dispatch(setLoadingStatusAction(false));
      dispatch(setRegenerateStatus(false));
      clearTimeout(timeoutId);
      if (sseRef.current) sseRef.current.close();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      console.log("SSE connections closed due to timeout.");
    };

    const payload = getPayload(type);

    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };

    const sse = createSSEConnection(apiUrl ? apiUrl : "", headers, payload);
    sseRef.current = sse;

    sse.addEventListener("text_semantic_search", handleSSEEvent("text_semantic_search", textSemanticSearchReceived));
    sse.addEventListener("text_semantic_search_chunks", handleSSEEvent("text_semantic_search_chunks", textSimilaritySearchReceived));
    sse.addEventListener("image_descriptions", handleSSEEvent("image_descriptions", imageSemanticSearchReceived));
    sse.addEventListener("audio_transcripts", handleSSEEvent("audio_transcripts", audioSemanticSearchReceived));
    sse.addEventListener("video_transcripts", handleSSEEvent("video_transcripts", videoSemanticSearchReceived));
    sse.addEventListener("end", (event: any) => handleEndEvent(event, event1Ended));
    sse.onerror = handleSSEError(sse, timeoutId, "An error occurred while establishing SSE connection.");
    sse.stream();
  };

  const getAnswerForKvalue = () => getAnswerCommon("kvalue");

  const getAnswer = () => getAnswerCommon("default");

  const getAnswerForRegenerate = () => {
    setCardDatalocal(null);
    dispatch(setLoadingStatusAction(true));
    const accessToken = token || ""; // Provide a default value if it's null
    console.log(accessToken);
    const timeoutId = setTimeout(() => {
      closeSSEConnections(); // Close SSE connections regardless of event status
    }, 100000); // 80 seconds in milliseconds
    timeoutRef.current = timeoutId;

    const checkAllEventsEnded = () => {
      if (event1Ended) {
        dispatch(setLoadingStatusAction(false));
        dispatch(setRegenerateStatus(false));
      }
      if (sseRef.current) sseRef.current.close();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
    // Define the payload
    const payload = getPayload("regenerate");
    const apiUrl = `${apiurl}/document/conversation/reformat`;
    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };

    let event1Ended = false; // Flag to track if event 1 has ended
    const closeSSEConnections = () => {
      if (!event1Ended) {
        // Handle event 1 that didn't end
        dispatch(setNotificationMsgAction("Error in regenerating the response"));
        dispatch(setCardData([]));

        // Do something for event 1 that didn't end
      }

      sse.close(); // Close the first SSE connection
      dispatch(setLoadingStatusAction(false));
      dispatch(setRegenerateStatus(false));
      clearTimeout(timeoutId); // Clear the timeout
      if (sseRef.current) sseRef.current.close();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      console.log("SSE connections closed due to timeout.");
    };

    // Set a timeout for 80 seconds
    const handleEndEvent1 = () => {
      event1Ended = true; // Set flag to true indicating that event 1 has ended
      checkAllEventsEnded();

      // Change state or perform actions related to event 1 ending
    };
    const sse = new SSE(apiUrl, {
      headers,
      payload: JSON.stringify(payload),
    });
    sseRef.current = sse;

    sse.addEventListener("end", (event: any) => {
      console.log("SSE message:", event.data);
      const jsonObject = parseJSON(event.data);
      if (jsonObject) {
        console.log(jsonObject);
        dispatch(setCardData(jsonObject));
        setresp(jsonObject);
      }
      handleEndEvent1(); // Call the end event handler for event 1
    });

    sse.onerror = handleSSEError(sse, timeoutId, "An error occurred while establishing SSE connection.");
  };

  const handleStopApiCall = () => {
    dispatch(setRegenerateStatus(false));
    clearTimeout(timerRef.current);
    handleCancelApiRequest();
    dispatch(setLoadingStatusAction(false));
  };



  return (
    <div className="semantic-search">
      <FloatingBallSideBarMenu/>
     {/* <MultiModalFileUpload/> */}
      {activeModule === SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH && userDetails?.email!==MED_USER_EMAIL? (
        <>
          {landingScreen && !advancedFilter ? (
            <Row gutter={[8, 8]} style={{ height: "83vh" }}>
              <Col span={24}>
                <SemanticSearchZeroStates />
                <LandingInitialSearch
                  columns={columns}
                  setColumns={setColumns}
                  internalVarA={internalVarA}
                  setInternalVarA={setInternalVarA}
                  internalVarB={internalVarB}
                  setInternalVarB={setInternalVarB}
                  stepperForinternalVarB={stepperForinternalVarB}
                  setStepperForInternalVarb={setStepperForInternalVarb}
                  tagsForProMode={tagsForProMode}
                  setTagsForProMode={setTagForProMode}
                />
              </Col>
            </Row>
            
          ) : !landingScreen && advancedFilter ? (
            <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>
              <Col xs={{ span: 24 }}>
                <AdvancedFilterHead />
              </Col>
              <AdvancedFilterContent
                columns={columns}
                internalVarB={internalVarB}
                internalVarA={internalVarA}
                stepperForinternalVarB={stepperForinternalVarB}
              />
            </Row>
          ) : !landingScreen && searchQuery && isLoading && !proModeStatus ? (
            <Row gutter={[16, 24]} style={{ height: "83vh" }}>
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <SemanticSearchLoading />
              </Col>
            </Row>
          ) : !landingScreen && searchQuery && isLoading && proModeStatus ? (
            <Row style={{ padding: "4vh 11vh" }} gutter={[16, 16]}>
              <CopilotMode tagsForProMode={tagsForProMode} setTagsForProMode={setTagForProMode} />
            </Row>
          ) : !landingScreen && searchQuery && !isLoading && advancedFilter ? (
            <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>
              <Col xs={{ span: 24 }}>
                <AdvancedFilterHead />
              </Col>
              <AdvancedFilterContent
                columns={columns}
                internalVarB={internalVarB}
                internalVarA={internalVarA}
                stepperForinternalVarB={stepperForinternalVarB}
              />
            </Row>
          ) :  
          !landingScreen && batchQAStatus && !isLoading ?(<>
            <Row style={{ padding: "5vh 10vh" }} gutter={[16, 16]}>
          <BatchQAScreen/>
          </Row>
          </>)
          :
          !landingScreen && !batchQAStatus && isLoading?(<>
          <Row gutter={[16, 24]} style={{ height: "83vh" }}>
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <SemanticSearchLoading />
              </Col>
            </Row>
          </>)
          :
          !editReportGenerationScreen ? (
            <>
              <Row style={{ padding: "5vh 10vh" }} gutter={[16, 16]}>
                <NextInsightPage
                  columns={columns}
                  setColumns={setColumns}
                  internalVarA={internalVarA}
                  setInternalVarA={setInternalVarA}
                  internalVarB={internalVarB}
                  setInternalVarB={setInternalVarB}
                  stepperForinternalVarB={stepperForinternalVarB}
                  setStepperForInternalVarb={setStepperForInternalVarb}
                />
                <Col span={24} style={{ position: "fixed", bottom: "0px", width: "100%", zIndex: 1000 }}>
                  <FollowupSearch
                    columns={columns}
                    setColumns={setColumns}
                    internalVarA={internalVarA}
                    setInternalVarA={setInternalVarA}
                    internalVarB={internalVarB}
                    setInternalVarB={setInternalVarB}
                    stepperForinternalVarB={stepperForinternalVarB}
                    setStepperForInternalVarb={setStepperForInternalVarb}
                    tagsForProMode={tagsForProMode}
                    setTagsForProMode={setTagForProMode}
                  />
                </Col>
              </Row>
            </>
          ) : reportType === "Powerpoint" ? (
            <>
              <Row style={{ padding: "1vh 7vh" }} gutter={[8, 8]}>
                <PptEditDownload />
              </Row>
            </>
          ) : (
            <>
              <>
                <Row style={{ padding: "1vh 7vh" }} gutter={[8, 8]}>
                  <PdfEditDownload />
                </Row>
              </>
            </>
          )}
        </>
      ) : 
      activeModule === SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH && userDetails?.email===MED_USER_EMAIL?(<>

{landingScreen && !searchQuery && !isLoading && !advancedFilter? (
                    <Row style={{ padding: "6vh 12vh" }} gutter={[16, 16]}>
                      <LiteratureReferenceWrapper/>
                      <Col span={24} style={{ position: "fixed", bottom: "0px", width: "100%", zIndex: 1000 }}>
                      <LiteratureReferenceCardSearchBar/>
</Col>
                      </Row>): !landingScreen && searchQuery && isLoading  && !advancedFilter? (
            <Row gutter={[16, 24]} style={{ height: "83vh" }}>
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <SemanticSearchLoading />
              </Col>
            </Row>):
            !landingScreen && !isLoading && searchQuery && !advancedFilter? (<>
            

            <Row style={{ padding: "6vh 12vh" }} gutter={[16, 16]}>
                      <LiteratureReferenceCard/>
                      <Col span={24} style={{ position: "fixed", bottom: "0px", width: "100%", zIndex: 1000 }}>
                      <LiteratureReferenceCardSearchBar/>
</Col>
                      </Row>







            </>):landingScreen && !isLoading && !searchQuery && advancedFilter ? (<>
            

              <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>
              <Col xs={{ span: 24 }}>
                <AdvancedFilterHead />
              </Col>
              <AdvancedFilterContent
                columns={columns}
                internalVarB={internalVarB}
                internalVarA={internalVarA}
                stepperForinternalVarB={stepperForinternalVarB}
              />
            </Row>




            </>):!landingScreen && !isLoading && searchQuery && advancedFilter ? (<>
            

            <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>
            <Col xs={{ span: 24 }}>
              <AdvancedFilterHead />
            </Col>
            <AdvancedFilterContent
              columns={columns}
              internalVarB={internalVarB}
              internalVarA={internalVarA}
              stepperForinternalVarB={stepperForinternalVarB}
            />
          </Row>




          </>):!landingScreen && !isLoading && !searchQuery && advancedFilter ? (<>
            

            <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>
            <Col xs={{ span: 24 }}>
              <AdvancedFilterHead />
            </Col>
            <AdvancedFilterContent
              columns={columns}
              internalVarB={internalVarB}
              internalVarA={internalVarA}
              stepperForinternalVarB={stepperForinternalVarB}
            />
          </Row>




          </>):(<>
              <Row style={{ padding: "6vh 12vh" }} gutter={[16, 16]}>
                      <LiteratureReferenceWrapper/>
                      <Col span={24} style={{ position: "fixed", bottom: "0px", width: "100%", zIndex: 1000 }}>
                      <LiteratureReferenceCardSearchBar/>
</Col>
                      </Row></>)}


      </>)
      :
      activeModule === SEMANTIC_SEARCH_MODULES.FILE_MANAGEMENT_SYSTEM ? (
        <>
     <FileSystemWrapper/>
        </>
      ) : 
      activeModule === SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS? (
        <>
     <ArtifactsWrapper/>
        </> 
      ) :activeModule === SEMANTIC_SEARCH_MODULES.USER_HISTORY ? (
        <>
          <Row style={{ padding: "6vh 11vh" }} gutter={[8, 8]}>
            <UserHistory
              columns={columns}
              setColumns={setColumns}
              internalVarA={internalVarA}
              setInternalVarA={setInternalVarA}
              internalVarB={internalVarB}
              setInternalVarB={setInternalVarB}
              stepperForinternalVarB={stepperForinternalVarB}
              setStepperForInternalVarb={setStepperForInternalVarb}
            />
          </Row>
        </>
      ) : (
        <>
          <Row style={{ padding: "6vh 11vh" }} gutter={[8, 8]}>
            <Billing />
          </Row>
        </>
      )}
    </div>
  );
};

export { SemanticSearchWrapper };
