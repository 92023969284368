import React, { FC, useEffect, useState, useRef } from "react";
import { Checkbox, Input, Col, Card, Button, Spin, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SSE } from "sse.js";
import _localStorageService from "../../../../Services/_localStorageService";
import { setBatchQAData, setBatchQAFolders, setBatchQAStatus, setCardDataCollection, setConversationId, setLandingScreen, setMessageIId } from "../../../../Store/actions/_semanticSearchActions";
import { setLoadingStatusAction } from "../../../../Store/actions/_commonActions";

interface Item {
  id: number;
  text: string;
  checked: boolean;
  editable: boolean;
}

interface FolderItem {
  id: number;
  folderName: string;
  checked: boolean;
}

const BatchQAScreen: FC = () => {
  const { batchQAData, batchQAFolders, cardDataCollection, landingScreen } = useSelector((state: any) => state.semanticSearchReducer);
  const dispatch = useDispatch();
  const [items, setItems] = useState<Item[]>([]);
  const [folders, setFolders] = useState<FolderItem[]>([]);
  const [isLoading, setLoading] = useState(false);
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();

  const sseConnectionRef = useRef<SSE | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const parseJSON = (data: string) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let answer: any[] = [];
    const apiUrl = `${apiurl}/conversation/batch-qna`;
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    };
    const payload = {
      questions: items.filter(item => item.checked).map(item => item.text),
      filters: folders.filter(folder => folder.checked).map(folder => folder.folderName),
      user_id: "0"
    };

    sseConnectionRef.current = new SSE(apiUrl, { headers, payload: JSON.stringify(payload) });

    timeoutRef.current = setTimeout(() => {
      setLoading(false);
      message.error('No response received within 2 minutes, please try again.');
    }, 120000); // 120 seconds timeout

    sseConnectionRef.current.addEventListener("qna", (event: MessageEvent) => {
      const data = parseJSON(event.data);
      if (data) {
        console.log("Received qna event data:", data);
        answer.push(data,data);
        finalizeDataProcessing(answer);
      } else {
        console.error("Failed to parse qna event data");
      }
    });

    sseConnectionRef.current.addEventListener("end", () => {
      finalizeDataProcessing(answer);
    });

    sseConnectionRef.current.onerror = (error) => {
      console.error("SSE Connection Error:", error);
      setLoading(false);
    };
  };

  const finalizeDataProcessing = (answer: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (sseConnectionRef.current) {
      sseConnectionRef.current.close();
    }
    if (answer.length >= 2) {
      console.log("Answers for cardDataCollection");
    }
    dispatch(setCardDataCollection(answer));
    console.log(answer);
    if (answer.length > 0) {
      console.log(answer[0]);
      console.log(answer[0]?.id);
      dispatch(setMessageIId(answer[0]?.id));
      dispatch(setConversationId(answer[0]?.conversation_id));
    }
    dispatch(setBatchQAStatus(false));
    console.log('Final QnA Data:');
  };

  useEffect(() => {
    if (batchQAData && Array.isArray(batchQAData)) {
      setItems(batchQAData.map((question: string, index: number) => ({
        id: index,
        text: question,
        checked: true,
        editable: false
      })));
    }
    if (batchQAFolders && Array.isArray(batchQAFolders)) {
      setFolders(batchQAFolders.map((folder: any, index: number) => ({
        id: index,
        folderName: folder?.name,
        checked: true
      })));
    }

    // Cleanup function
    return () => {
      if (landingScreen) {
        if (sseConnectionRef.current) {
          sseConnectionRef.current.close();
        }
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    };
  }, [landingScreen]);

  const toggleCheckbox = (id: number) => {
    setItems(
      items.map(item => item.id === id ? { ...item, checked: !item.checked } : item)
    );
  };

  const toggleAllCheckboxes = () => {
    const allChecked = items.every(item => item.checked);
    setItems(items.map(item => ({ ...item, checked: !allChecked })));
  };

  const toggleFolderCheckbox = (id: number) => {
    setFolders(
      folders.map(folder => folder.id === id ? { ...folder, checked: !folder.checked } : folder)
    );
  };

  const enableEditing = (id: number) => {
    setItems(
      items.map(item => item.id === id ? { ...item, editable: true } : item)
    );
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setItems(
      items.map(item => item.id === id ? { ...item, text: event.target.value } : item)
    );
  };

  const handleBlur = (id: number) => {
    setItems(
      items.map(item => item.id === id ? { ...item, editable: false } : item)
    );
  };

  return (
    <>
      <Col span={17} style={{ paddingLeft: "20px" }}>
        <Card className="card-element">
          <div style={{ color: '#9A9A9A' }}>Step 1</div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontWeight: 'bold' }}>Choose Questions:</div>
            <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#1E4DA1', cursor: 'pointer' }} onClick={toggleAllCheckboxes}>SELECT ALL</div>
          </div>
          <div style={{ marginTop: '5px', height: '450px', overflowY: 'scroll' }}>
  {items.length > 0 ? (
    items.map(item => (
      <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Checkbox
          checked={item.checked}
          onChange={() => toggleCheckbox(item.id)}
        />
        {item.editable ? (
          <Input
            style={{ fontWeight: 'bold' }}
            value={item.text}
            onChange={(e) => handleTextChange(e, item.id)}
            onBlur={() => handleBlur(item.id)}
            autoFocus
          />
        ) : (
          <span
            onDoubleClick={() => enableEditing(item.id)}
            style={{ marginLeft: '10px', fontWeight: 'bold', cursor: 'pointer' }}
          >
            {item.text}
          </span>
        )}
      </div>
    ))
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <span>No questions found</span>
    </div>
  )}
</div>

          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#999999', marginTop: '5px' }}>
            <div>Tip: Double Click on Question to Edit</div>
            <div>
              <Button type="link" style={{ color: '#1E4DA1' }} onClick={() => {
                dispatch(setLandingScreen(true));
                dispatch(setBatchQAStatus(false));
                dispatch(setBatchQAData([]));
                dispatch(setBatchQAFolders([]));
              }}>CANCEL</Button>
              <Button disabled={items.filter(item => item.checked).length === 0} onClick={handleSubmit} loading={isLoading} style={{ color: 'white', background: '#1E4DA1' }}>
                SUBMIT
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={7}>
        <Card className="card-element">
          <div style={{ color: '#9A9A9A' }}>Step 2</div>
          <div><span style={{ fontWeight: 'bold' }}>Choose Folders</span> <span style={{ color: '#9A9A9A' }}>(optional)</span></div>
          <div style={{ marginTop: '5px', height: '400px', overflowY: 'scroll', borderBottom: '1px solid black' }}>
            {folders.map(folder => (
              <div key={folder.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Checkbox
                  checked={folder.checked}
                  onChange={() => toggleFolderCheckbox(folder.id)}
                />
                <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{folder.folderName}</span>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px', cursor: 'not-allowed' }}>
            <Button style={{ fontWeight: 'bold', fontSize: '18px', color: '#1E4DA1' }} type="link">UPLOAD DOCUMENT</Button>
          </div>
        </Card>
      </Col>
    </>
  );
};

export { BatchQAScreen };
