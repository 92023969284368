import { FC, useState } from "react";
import { Flex, Select, Space, Spin, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { TTSelectData } from "../../Core/modals";
import { setOpenAddGlossaryModalTMAction } from "../../../../Store/actions/_translationModuleActions";
import { useDispatch } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

type IProps = {
  textHeading: string;
  selectData: TTSelectData;
  isGlossaryEnable: boolean;
  textareaValue: string;
  updateTextareaValue: (value: string, type: string) => void;
  loading?: boolean;
  isEditable?: boolean;
};

export const IOTextTranslation: FC<IProps> = ({
  textHeading,
  selectData,
  isGlossaryEnable,
  textareaValue,
  updateTextareaValue,
  loading,
  isEditable
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isEditActive, setIsEditActive] = useState<boolean>(false);

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._TextTranslationMain._IOTextTranslation.${value as string}`])}`;
  };

  return (
    <Flex vertical gap="small" style={{ height: "100%" }}>
      <Flex justify="space-between">
        <Text style={{ fontSize: "18px", fontWeight: "600" }}>{textHeading}</Text>
        {isGlossaryEnable && (
          <Text
            style={{ fontSize: "15px", fontWeight: "600", color: "rgba(3, 78, 162, 1)", cursor: "pointer" }}
            onClick={() => dispatch(setOpenAddGlossaryModalTMAction(true))}
          >
            {getTranslatedText("ADD GLOSSARY")}
          </Text>
        )}
      </Flex>
      <Select
        {...selectData}
        style={{ width: "400px", borderRadius: "0px", height: "40px", marginBottom: "10px" }}
        labelRender={() => (
          <Flex vertical>
            <Text style={{ fontSize: "11px", fontWeight: "400", color: "rgba(102, 103, 107, 1)" }}>{selectData.placeholder}</Text>
            <Text style={{ fontSize: "12px", fontWeight: "400", color: "rgba(35, 31, 32, 1)" }}>{selectData.value}</Text>
          </Flex>
        )}
      />

      {loading ? (
        <Flex justify="center" align="center" style={{ border: "2px dotted rgba(236, 236, 236, 1)", borderRadius: "5px", height: "100%" }}>
          <Spin />
        </Flex>
      ) : (
        <>
          <TextArea
            rows={24}
            placeholder={getTranslatedText("Enter your text")}
            onBlur={() => setIsEditActive(false)}
            onFocus={() => setIsEditActive(true)}
            value={textareaValue}
            style={{
              resize: "none",
              background: isEditActive ? "rgba(245, 250, 255, 1)" : "",
              border: `2px dotted ${isEditActive ? "rgba(3, 78, 162, 1)" : "rgba(236, 236, 236, 1)"}`,
              cursor: !isEditable ? "pointer" : ""
            }}
            readOnly={isEditable ? false : true}
            onChange={(e) => updateTextareaValue(e.target.value, textHeading.toLocaleLowerCase())}
          />
        </>
      )}
    </Flex>
  );
};
