import { Col, Row, InputNumber, Space, Tooltip, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { formatSeconds } from "../useVideoControls";
//import LazySlider from '../../../../../../components/LazySlider'

import { memo, useEffect, useState } from "react";
import LazySlider from "../../../../common/LazySlider";

const STYLE = {
  marginTop14: { marginTop: 14 }
};

export const Trim = memo((props: { [x: string]: any }) => {
  const { canvasActiveObject, onStep, onTrimChange, onTrimStartChange, onTrimEndChange } = props;
  const [trimObj, setTrimObj] = useState({
    start: canvasActiveObject.trimStart,
    end: canvasActiveObject.trimEnd
  });

  const elementDuration = canvasActiveObject.getElement().duration;
  useEffect(() => {
    setTrimObj({
      start: canvasActiveObject.trimStart,
      end: canvasActiveObject.trimEnd
    });
  }, [canvasActiveObject.trimStart, canvasActiveObject.trimEnd]);

  return (
    <>
      <Row style={STYLE.marginTop14}>
        <Col span={4} style={{ marginTop: 4 }}>
          <Space>
            <h4>Trim</h4>
            <Tooltip title="To trim a video, just drag circles from the beginning or end of the slider">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={18} style={{ marginLeft: 20 }}>
          <Space direction="horizontal">
            <Space direction="vertical">
              <InputNumber value={trimObj.start} precision={2} stringMode={false} onChange={onTrimStartChange} defaultValue={0} />
              <Typography.Text>Start (s)</Typography.Text>
            </Space>
            <Space direction="vertical">
              <InputNumber value={trimObj.end} precision={2} stringMode={false} onChange={onTrimEndChange} defaultValue={0} />
              <Typography.Text>End (s)</Typography.Text>
            </Space>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 14, marginLeft: 5 }} align="middle">
        <Col span={24}>
          <LazySlider
            value={[canvasActiveObject.trimStart, canvasActiveObject.trimEnd || elementDuration]}
            min={0}
            max={elementDuration}
            tooltip={{ formatter: formatSeconds }}
            step={0.01}
            range
            allowCross={false}
            onStep={onStep}
            onChange={onTrimChange}
          />
        </Col>
      </Row>
    </>
  );
});
