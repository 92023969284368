import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setActiveScreenTMAction, setFTTranslateFileFlagTMAction } from "../../../../Store/actions/_translationModuleActions";
import { enumTMActiveScreen } from "../../Core/constant";
import { LanguageListTypes, UploadDocsTypes } from "../../Core/modals";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const FooterContainer: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { languageList, uploadDocsData }: { languageList: LanguageListTypes[]; uploadDocsData: UploadDocsTypes } = useSelector(
    (state: any) => state?.translationModuleReducer
  );

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._TranslateAssetMain._FooterContainer.${value as string}`])}`;
  };

  return (
    <div className="col-12" style={{ padding: 0, marginTop: "50px" }}>
      <Flex
        justify="space-between"
        align="center"
        style={{ padding: "12px", height: "71px", background: "rgba(248, 248, 248, 1)", borderRadius: "8px" }}
      >
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "rgba(154, 154, 154, 1)",
            letterSpacing: "0.6px"
          }}
        >
          {uploadDocsData?.asset?.language_ids?.length}/{languageList?.length} {getTranslatedText("Language Selected")}
        </Text>
        <Flex justify="flex-end" align="center" gap={"middle"}>
          <Button
            style={{ fontSize: "12px", color: "rgba(102, 103, 107, 1)" }}
            type="link"
            onClick={() => {
              dispatch(setActiveScreenTMAction(enumTMActiveScreen.NOTHING));
            }}
          >
            {getTranslatedText("CANCEL")}
          </Button>
          <Button
            type="primary"
            style={{
              fontSize: "12px",
              background: !uploadDocsData.asset.language_ids?.length ? "rgba(230, 231, 232, 1)" : "rgba(3, 78, 162, 1)",
              width: "88px",
              borderRadius: "0px"
            }}
            onClick={() => {
              dispatch(setFTTranslateFileFlagTMAction(true));
              dispatch(setActiveScreenTMAction(enumTMActiveScreen.FINAL));
            }}
            disabled={uploadDocsData.asset.language_ids?.length ? false : true}
          >
            {getTranslatedText("PROCEED")}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};
