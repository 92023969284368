import { Button, Input, InputRef, Modal, Typography } from "antd";
import { FC, MutableRefObject, Ref, RefObject, useEffect, useRef, useState } from "react";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setCTSourceAction,
  setResponseCtAction
} from "../../../../Store/actions/_contentTranscreationActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { siteConfig } from "../../../../Services";
import { ENUM_PROCESS_SCREENS } from "../../Core/constants";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT } from "../../../../Utils";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
const { Text } = Typography;

const urlRegex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;

export const BlogModal: FC<{ open: boolean; setOpen: Function }> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { responseCT } = useSelector((state: any) => state?.contentTranscreationReducer);
  const [inputVal, setInputVal] = useState<string>(responseCT?.blog_url || "");

  const inputRef: Ref<InputRef> = useRef(null);

  //console.log("Blog Modal", { inputVal }, responseCT?.blog_url);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    });
  });

  useEffect(() => {
    if (responseCT?.blog_url) setInputVal(responseCT?.blog_url);
    else setInputVal("");
  }, [responseCT]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setInputVal(value);
    dispatch(setResponseCtAction({ ...responseCT, blog_url: value }));
  };

  const handleCancel = () => {
    setOpen(false);
    //clearData();
  };

  const clearData = () => {
    setInputVal("");
  };

  const handleSubmit = () => {
    if (!responseCT?.blog_url) {
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.blogmodal.notification.fillblogurl"])));
      return;
    } else if (!urlRegex.test(responseCT?.blog_url)) {
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.blogmodal.notification.entervalidurl"])));
      return;
    }

    setOpen(false);
    //clearData();

    localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);
    dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_3));
    dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES));
  };

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") handleSubmit();
  };

  return (
    <Modal
      title={t(I18N_MODULE_KEYS["content.text.enterblogurl"])}
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t(I18N_MODULE_KEYS["content.button.cancel"])}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {t(I18N_MODULE_KEYS["content.button.submit"])}
        </Button>
      ]}
    >
      <Input ref={inputRef} value={inputVal} onChange={handleOnChange} onKeyDown={handleEnterKey} required />
    </Modal>
  );
};
