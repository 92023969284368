import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { setSessionExpiredFlagAction } from "../../Store/actions/_authActions";

import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import LocalStorageService from "../../Services/_localStorageService";

const SessionExpiredModal: FC<{ open: boolean }> = ({ open }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [show, setShow] = useState(open);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleLoginClick = () => {
    LocalStorageService.removeToken();
    dispatch({ type: "USER_LOGOUT" }); // Clear Redux State
    dispatch(setSessionExpiredFlagAction(false));
    navigate(ENUM_ROUTE_URLS.LOGIN);
  };

  return (
    <Modal
      title={`${t(I18N_MODULE_KEYS["content.text.sessionexpired"])}`}
      open={show}
      closeIcon={false}
      centered
      keyboard={false}
      maskClosable={false}
      footer={[
        <Button key="submit" type="primary" onClick={handleLoginClick} style={{ background: "rgb(5, 70, 165)" }}>
          Login
        </Button>
      ]}
    >
      {/* <p>Your session has expired. Please login again!</p> */}
      <p>{t(I18N_MODULE_KEYS["content.text.sessionhasexpired"])}</p>
    </Modal>
  );
};

export default SessionExpiredModal;
