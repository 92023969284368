import { Col, Button } from "antd";
import ButtonGroup from "antd/es/button/button-group";

import { boldIcon, italicIcon, underlineIcon } from "../constants";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../../../Store/actions/_contentTranscreationActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../Store";

export const FontDecoration = ({
  activeObjectProps,
  getTextSelectionStyle,
  getTextSelectionFontFamily
}: {
  activeObjectProps: any;
  getTextSelectionStyle: Function;
  getTextSelectionFontFamily: Function;
}) => {
  const dispatch = useDispatch();

  const isUnderlineText = getTextSelectionStyle(activeObjectProps, "underline") ? "primary" : "default";
  const isBoldText = getTextSelectionStyle(activeObjectProps, "fontWeight") === "bold" ? "primary" : "default";
  const isItalicText = getTextSelectionStyle(activeObjectProps, "fontStyle") === "italic" ? "primary" : "default";

  const onBoldClick = () => {
    console.log("getTextSelectionStyle", getTextSelectionStyle(activeObjectProps, "fontWeight"));
    dispatch(
      setActiveObjectModifier({
        change: "fontWeight",
        value: getTextSelectionStyle(activeObjectProps, "fontWeight") === "bold" ? "normal" : "bold"
      })
    );
  };

  const onItalicClick = () => {
    dispatch(
      setActiveObjectModifier({
        change: "fontStyle",
        value: getTextSelectionStyle(activeObjectProps, "fontStyle") === "italic" ? "normal" : "italic"
      })
    );
  };

  const onUnderlineClick = () => {
    dispatch(
      setActiveObjectModifier({
        change: "underline",
        value: !getTextSelectionStyle(activeObjectProps, "underline")
      })
    );
  };

  return (
    <Col>
      <ButtonGroup>
        <Button icon={boldIcon} type={isBoldText} onClick={onBoldClick} />
        <Button icon={italicIcon} type={isItalicText} onClick={onItalicClick} />
        <Button icon={underlineIcon} type={isUnderlineText} onClick={onUnderlineClick} />
      </ButtonGroup>
    </Col>
  );
};
