import { DrawerActionTypes } from "./_constant";
import { DrawerAction, DrawerState, DrawerHeaderItem } from "./_types";

// Action creators for setting drawer
export const setActionDrawerDataAction = (drawerData: DrawerState): DrawerAction => ({
  type: DrawerActionTypes.SET_DRAWER_DATA,
  payload: drawerData
});

export const setActionDrawerOpenAction = (drawerState: boolean): DrawerAction => ({
  type: DrawerActionTypes.SET_DRAWER_OPEN,
  payload: drawerState
});

export const setActionDrawerClosedAction = (drawerState: boolean): DrawerAction => ({
  type: DrawerActionTypes.SET_DRAWER_CLOSED,
  payload: drawerState
});

export const setActionDrawerComponentAction = (componentId: string | null): DrawerAction => ({
  type: DrawerActionTypes.SET_DRAWER_COMPONENT,
  payload: componentId
});

export const setActionDrawerHeaderOptionsAction = (headerOptions: Array<DrawerHeaderItem>): DrawerAction => ({
  type: DrawerActionTypes.SET_DRAWER_HEADER_OPTIONS,
  payload: headerOptions
});

export const afterActionDrawerCloseAction = (afterClose: () => void): DrawerAction => ({
  type: DrawerActionTypes.ON_DRAWER_CLOSE,
  payload: afterClose
});

export const onDrawerBackAction = (onBackAction: () => void): DrawerAction => ({
  type: DrawerActionTypes.ON_DRAWER_BACK_ACTION,
  payload: onBackAction
});
