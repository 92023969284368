import { Result, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

export const Error = () => {
  return (
    <Result status="error" title="Submission Failed" subTitle="Some Error occured while fetching data.">
      {/* <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16
            }}
          >
            The content you submitted has the following error:
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          account has been frozen. <a>Thaw immediately &gt;</a>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          account is not yet eligible to apply. <a>Apply Unlock &gt;</a>
        </Paragraph>
      </div> */}
    </Result>
  );
};
