import { Button, Col, Image, Input, Row, Spin ,Typography} from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
const { Paragraph } = Typography;


export interface LeftCardForNewFolderCreationProps {
    inputvalue: string;
    handleFolderNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleCancelCreateFolder: () => void;
    issavingFolder: boolean;
    handleSaveCreateFolder: () => void;
  }


export const LeftCardForNewFolderCreation: React.FC<LeftCardForNewFolderCreationProps> = ({
    inputvalue,handleFolderNameChange,handleCancelCreateFolder,issavingFolder,handleSaveCreateFolder
}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  console.log(tabList, "tabList");

  return (
    <>
    <div style={{ fontSize: "16px", fontWeight: "bold", paddingBottom: "10px" }}>
    {t(I18N_MODULE_KEYS["semantic.search.filesystem.createfolder"])}
  </div>
  <Input
    placeholder={t(I18N_MODULE_KEYS["semantic.search.header.enterfoldername"])}
    value={inputvalue} 
    onChange={handleFolderNameChange}
  ></Input>
  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
    <Button type="text" onClick={handleCancelCreateFolder} disabled={issavingFolder}>
      {t(I18N_MODULE_KEYS["content.button.cancel"]).toUpperCase()}
    </Button>
    {issavingFolder ? (
      <>
        <Spin style={{ padding: "5px" }}></Spin>
      </>
    ) : (
      <Button type="text" style={{ color: "#1e4da1", fontWeight: "bold" }} onClick={handleSaveCreateFolder}>
        {t(I18N_MODULE_KEYS["content.button.save"]).toUpperCase()}
      </Button>
    )}
  </div>
  </>
  )
};

