// import { FC } from "react";
// import { Layout, theme } from "antd";
// import { useDispatch, useSelector } from "react-redux";
// import { setDarkModeStatusAction } from "../../Store/actions/_commonActions";

// const { Header, Content, Footer } = Layout;

// type IProps = {
//   children?: any;
//   style?: any;
// };

// const DHeader: FC<IProps> = ({ children, style }) => {
//   return <Header style={{ ...style }}>{children || ""}</Header>;
// };

// export default DHeader;
// import { FC } from "react";
// import { Layout, theme } from "antd";
// import { useDispatch, useSelector } from "react-redux";
// import { setDarkModeStatusAction, settoggleSidebarCollapse } from "../../Store/actions/_commonActions"; // Import the new action

// import { MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons"; // Import the Ant Design icons

// const { Header, Content, Footer } = Layout;

// type IProps = {
//   children?: any;
//   style?: any;
// };

// const DHeader: FC<IProps> = ({ children, style }) => {
//   const dispatch = useDispatch();
//   const { isCollapsed } = useSelector((state: any) => state?.commonReducer);

//   const toggleSidebar = () => {
//     dispatch(settoggleSidebarCollapse(!isCollapsed)); // Dispatch the action to toggle the collapsed state
//   };

//   return (
//     <Header
//       style={{
//         ...style,
//         position: "sticky",
//         top: 0,
//         zIndex: 1,
//         width: "100%",
//         display: "flex",
//         background: "#0546a5",
//         alignItems: "center",
//         justifyContent: "space-between",
//         color:'white' // Space between aligns the icon to the left and children to the right
//       }}
//     >
//       <div>
//         {/* Add the icon with the onClick event */}
//         {isCollapsed ? (

//           <MenuUnfoldOutlined onClick={toggleSidebar}  style={{ fontSize: "24px" }}  />
//         ) : (
//           <MenuFoldOutlined onClick={toggleSidebar}  style={{ fontSize: "24px" }}  />
//         )}
//       </div>
//       <div>{children || ""}</div>
//     </Header>
//   );
// };

// export default DHeader;
import { FC } from "react";
import { Layout, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setDarkModeStatusAction, settoggleSidebarCollapse } from "../../Store/actions/_commonActions";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Space } from "antd"; // Import Space component

const { Header, Content, Footer } = Layout;

type IProps = {
  children?: any;
  style?: any;
};

const DHeader: FC<IProps> = ({ children, style }) => {
  return (
    <Header
      style={{
        ...style
      }}
    >
      <div style={{ width: "100%" }}>{children || ""}</div>
    </Header>
  );
};

export default DHeader;
