import { LeftOutlined, PlaySquareOutlined } from "@ant-design/icons";
import { Button, Card, Carousel, Col, Drawer, Flex, Image, Input, Modal, Popconfirm, Row, Select, Typography, message, notification } from "antd";
import { CSSProperties, FC, useEffect, useState } from "react";
import "./_sidebar.css";
import { elaiApprovedAvatarsList, elaiAvatarsList, elaiVoicesList } from "../../../core/mockData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store";
import { useDispatch } from "react-redux";
import { updateElaiVideoData } from "../../../../../../Store/requests/_contentTranscreationRequests";
import { setElaiTranslationDataAction, setSaveElaiObject } from "../../../../../../Store/actions/_contentTranscreationActions";

const { Text } = Typography;

const objText: CSSProperties = {
  lineHeight: "110%",
  fontSize: "12px",
  fontWeight: "700"
};

type AvatarListDataType = {
  code: string;
  id: number;
  _id: string;
  avatar_id: string;
  name: string;
  variant_name: string;
  variant_id: string;
  intro: string;
  introPoster: string;
  is_active: boolean;
  canvas: string;
};

type AvatarDataType = {
  code: string;
  id: string;
  name: string;
  duration: number | string;
  avatar_id: string;
  list: AvatarListDataType[];
  variants: AvatarListDataType[];
  gender: string;
};

const avatarData: any = elaiApprovedAvatarsList;
const voices: any = elaiVoicesList;

const all_avatar_key = "all_avatar_key";

export const AvatarControls: FC = () => {
  const [filteredAvatarData, setFilteredAvatarData] = useState<AvatarDataType[]>([]);
  const [selectedAvatarKey, setSelectedAvatarKey] = useState<string>(all_avatar_key);
  const [selectedMainAvatar, setSelectedMainAvatar] = useState<AvatarDataType | null>(null);
  const [clickedAvatarItem, setClickedAvatarItem] = useState<AvatarListDataType | null>(null);

  const [isOpenConfirmType, setIsOpenConfirmType] = useState(false);
  const [isOpenConfirmAvatar, setIsOpenConfirmAvatar] = useState({ name: "", open: false });

  const [showPreview, setShowPreview] = useState(false);

  const { elaiResponse, activeSlide } = useSelector((state: RootState) => state.contentTranscreationReducer);

  const { voiceList } = useSelector((state: any) => state?.playerReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredAvatarData([...avatarData]);
  }, []);

  console.log("selectedAvatarKey", selectedAvatarKey);
  console.log("selectedMainAvatar", selectedMainAvatar);

  const handleOnChangeSelect = (key: string) => {
    setSelectedAvatarKey(key);
    setSelectedMainAvatar(null);

    if (key === all_avatar_key) setFilteredAvatarData([...avatarData]);
    else setFilteredAvatarData([...avatarData?.filter((item: AvatarDataType) => item?.id === key)]);
  };

  const handleOnChangeInput = (e: any) => {
    setSelectedMainAvatar(null);
    let { value } = e?.target;

    value = value?.toLowerCase?.()?.trim?.();

    if (!value) handleOnChangeSelect(selectedAvatarKey);
    else
      setFilteredAvatarData([...filteredAvatarData?.filter((item: AvatarDataType) => item?.name?.toLocaleLowerCase?.()?.trim?.()?.includes(value))]);
  };

  // const onChooseAvatar = (avatar, avatarGroup) => {
  //   const avatarCode = avatarGroup?.code ? `${avatarGroup.code}.${avatar.code}` : avatar.code;

  //   if (avatarGroup) {
  //     avatar = { ...avatarGroup, ...avatar, code: avatarCode };
  //     delete avatar.variants;
  //     delete avatar.items;
  //   }

  //   const { code, gender, type, limit, accountId, canvas } = avatar;

  //   const linkedSlideChanges = {
  //     avatar: { code, gender, type, limit, accountId, canvas },
  //     status: data.status
  //   };

  //   const avatarChanges = { url: avatar.canvas, tilt: avatar.tilt };

  //   if (data.avatar.code !== avatarCode) linkedSlideChanges.status = "edited";

  //   if (data.avatar.gender !== avatar.gender && !avatar.defaultVoice) {
  //     linkedSlideChanges.voice = voices.find((voice) => voice.name === data.language)[avatar.gender][0].voice;
  //     linkedSlideChanges.voiceProvider = "azure";
  //   } else if (avatar.defaultVoice) {
  //     const voice = voices.find((lang) => lang.name === data.language)[avatar.gender].find((voice) => voice.character === avatar.defaultVoice);
  //     if (voice) {
  //       linkedSlideChanges.voice = voice.voice;
  //       linkedSlideChanges.voiceProvider = voice.voiceProvider;
  //     }
  //   }

  //   if (canvasActiveObject.avatarType === "voiceover") {
  //     linkedSlideChanges.status = "edited";
  //     linkedSlideChanges.voiceType = data.voiceType !== "silence" ? data.voiceType : "text";
  //     avatarChanges.avatarType = "transparent";
  //   }

  //   setActiveObjectModifier({
  //     change: "presenter",
  //     value: avatarChanges,
  //     async: true,
  //     linkedSlideChanges
  //   });
  //   if (video.slides.length > 1) {
  //     setIsOpenConfirmAvatar({ name: (avatarGroup?.name ?? "") + " " + (avatar?.name ?? ""), open: true });
  //   }
  // };

  const existInGenAIVoiceList = (GenAIVoiceList: any, voice: string) => {
    for (let item of GenAIVoiceList) {
      if (item.voice === voice) return true;
    }

    return false;
  };
  const getDefaultVoice = (voices: any, gender: any, language: any) => {
    console.log({ voices, gender, language });
    //const voiceObject = voices[language ? voices.findIndex((l: any) => l.name === language) : 0][gender][0];

    //Below one is working, but some of the azure ones are not there in GenAI voices list
    // const voiceObject = voices[language ? voices.findIndex((l: any) => l.name === language) : 0][gender].find(
    //   (item: any) => item.voiceProvider === "azure"
    // );

    //Updating it to crosscheck with GenAI Voices

    const GenAIVoiceList = voiceList[language ? voiceList.findIndex((l: any) => l.name === language) : 0][gender];

    const voiceObject = voices[language ? voices.findIndex((l: any) => l.name === language) : 0][gender].find(
      (item: any) => item.voiceProvider === "azure" && existInGenAIVoiceList(GenAIVoiceList, item.voice)
    );

    if (!voiceObject) {
      notification.error({ message: "Avatar voice not found" });
      return {};
    }

    return {
      voice: voiceObject.defaultStyle ? `${voiceObject.voice}:${voiceObject.defaultStyle}` : voiceObject.voice,
      voiceProvider: voiceObject.voiceProvider
    };
  };

  const updateAvatar = async (listItem: AvatarListDataType) => {
    setClickedAvatarItem(listItem);
    setIsOpenConfirmType(true);

    //const updatedElaiResponse = structuredClone(elaiResponse);
    const updatedElaiResponse = JSON.parse(JSON.stringify(elaiResponse));

    const slide = updatedElaiResponse.slides[activeSlide];

    const avatarGroup = selectedMainAvatar;
    let avatar: any = structuredClone(listItem);
    console.log("Updated Avatar Item", listItem);

    const avatarCode = avatarGroup?.code ? `${avatarGroup.code}.${avatar.code}` : avatar.code;

    if (avatarGroup) {
      avatar = { ...avatarGroup, ...avatar, code: avatarCode };
      delete avatar.variants;
      delete avatar.items;
    }

    const { code, gender, type, limit, accountId, canvas: avatarUrl, tilt } = avatar;

    slide.avatar = { id: code, gender, type, limit, accountId, canvas: avatarUrl, tilt };

    //const avatarChanges = { url: avatar.canvas, tilt: avatar.tilt };

    //Voice selection
    // if (slide.avatar.gender !== avatar.gender && !avatar.defaultVoice) {
    //   slide.voice = voices.find((voice: any) => voice.name === slide.language)[avatar.gender][0].voice;
    //   slide.voiceProvider = "azure";
    // } else if (avatar.defaultVoice) {
    //   const voice = voices
    //     .find((lang: any) => lang.name === slide.language)
    //     [avatar.gender].find((voice: any) => voice.character === avatar.defaultVoice);
    //   if (voice) {
    //     slide.voice = voice.voice;
    //     slide.voiceProvider = voice.voiceProvider;
    //   }
    // }

    if (slide.avatar.gender !== avatar.gender && !avatar.defaultVoice) {
      console.log("Setting voice");
      let { voice, voiceProvider } = getDefaultVoice(voices, avatar.gender, slide.language);

      console.log(voice, voiceProvider);
      slide.voice = voice;
      slide.voiceProvider = voiceProvider;
    } else if (avatar.defaultVoice) {
      const [voiceProvider, voiceId] = avatar.defaultVoice.split("@");

      console.log("else if (avatar.defaultVoice)", voiceProvider, voiceId);
      if (voiceProvider !== "azure") {
        let { voice, voiceProvider } = getDefaultVoice(voices, avatar.gender, slide.language);
        slide.voice = voice;
        slide.voiceProvider = voiceProvider;
      } else {
        slide.voice = voiceId;
        slide.voiceProvider = voiceProvider;
      }
    } else {
      console.log("last part");
      let { voice, voiceProvider } = getDefaultVoice(voices, avatar.gender, slide.language);

      console.log(voice, voiceProvider);

      if (voice && voiceProvider) {
        slide.voice = voice;
        slide.voiceProvider = voiceProvider;
      }
    }

    // const avatarObj = {
    //   code: selectedMainAvatar?.code + "." + listItem.code,
    //   gender: selectedMainAvatar?.gender
    // };

    // const slideAvatar = updatedElaiResponse.slides[activeSlide].avatar;
    // slideAvatar.id = avatarObj.code;
    // slideAvatar.gender = avatarObj.gender;

    const canvasAvatarObject = slide.canvas.objects.filter((object: any) => object.type === "avatar")[0];
    canvasAvatarObject.src = listItem.canvas;
    //@ts-ignore
    canvasAvatarObject.tilt = selectedMainAvatar?.tilt;

    console.log("updatedElai", updatedElaiResponse, "elai", elaiResponse);

    dispatch(setSaveElaiObject({ ...updatedElaiResponse, updatedObject: "avatar" }));

    // const res = await updateElaiVideoData(updatedElaiResponse);

    // console.log("res", res);
    // message.success("Saved!");

    // dispatch(setElaiTranslationDataAction({ ...elaiResponse, ...res }));
  };

  const updateSlideAvatar = (slide: any, avatarObj: any) => {
    const slideAvatar = slide.avatar;
    slideAvatar.id = avatarObj.code;
    slideAvatar.gender = avatarObj.gender;

    const canvas = slide.canvas;
    const canvasAvatarObject = canvas.objects.filter((object: any) => object.type === "avatar")[0];
    canvasAvatarObject.src = avatarObj.canvas;
  };

  const onPopconfirmCancel = () => {
    setIsOpenConfirmType(false);
    setIsOpenConfirmAvatar({ name: "", open: false });
  };

  const applyAvatarToAllSlides = async () => {
    const avatarObj = {
      code: selectedMainAvatar?.code + "." + clickedAvatarItem?.code,
      gender: selectedMainAvatar?.gender,
      canvas: clickedAvatarItem?.canvas
    };

    const updatedElaiResponse = JSON.parse(JSON.stringify(elaiResponse));

    for (let slide of updatedElaiResponse.slides) {
      slide !== activeSlide && updateSlideAvatar(slide, avatarObj);
    }

    dispatch(setSaveElaiObject({ ...updatedElaiResponse, updatedObject: "avatar" }));

    // const res = await updateElaiVideoData(updatedElaiResponse);

    // console.log("res", res);
    // message.success("Avatar applied to all Slides!");

    // dispatch(setElaiTranslationDataAction({ ...elaiResponse, ...res }));
  };

  const onPopconfirmTypeApply = () => applyAvatarToAllSlides();

  const onPopconfirmTypeOpenChange = () => setIsOpenConfirmType((p) => !p);

  const onPopconfirmAvatarApply = () => applyAvatarToAllSlides();

  const onPopconfirmAvatarOpenChange = () => setIsOpenConfirmAvatar((p) => ({ ...p, open: !p.open }));

  const popconfirmApplyProps = {
    icon: "",
    okText: "Apply to all",
    showCancel: false,
    disabled: elaiResponse?.slides?.length < 2,
    onCancel: onPopconfirmCancel
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }}>
        <Flex gap="small">
          <Select
            value={selectedAvatarKey}
            style={{ minWidth: "120px" }}
            showSearch
            optionFilterProp="label"
            defaultValue={all_avatar_key}
            onChange={handleOnChangeSelect}
            options={[
              {
                value: all_avatar_key,
                label: "All Avatar"
              },
              ...avatarData?.map((item: AvatarDataType) => {
                return {
                  value: item?.id,
                  label: item?.name
                };
              })
            ]}
          />
          <Popconfirm
            {...popconfirmApplyProps}
            overlayClassName="avatar-apply-all-popconfirm dark-popconfirm"
            open={isOpenConfirmType}
            title={
              <>
                <span className="current-label">Current: </span>
                <span className="type-label">{selectedMainAvatar?.name + " " + clickedAvatarItem?.name}</span>
                {/* <span className="type-label">{option.title}</span> */}
              </>
            }
            placement={"bottom" /* option.placement */}
            onConfirm={onPopconfirmTypeApply}
            onOpenChange={onPopconfirmTypeOpenChange}
          ></Popconfirm>

          <Input placeholder="Search by name" onChange={handleOnChangeInput} />
        </Flex>
      </Col>
      <Col span={24} style={{ maxHeight: "calc(100vh - 190px)", overflowY: "auto" }}>
        {filteredAvatarData && filteredAvatarData?.length ? (
          <Row gutter={[16, 16]}>
            {filteredAvatarData?.map((item: AvatarDataType, index: number) => {
              return (
                <AvatarItemCard
                  key={`avatardata_index_${index}`}
                  item={item}
                  handlePreview={(i) => {
                    setShowPreview(true);
                    setClickedAvatarItem(item?.variants?.[0]);
                  }}
                  onClick={() => setSelectedMainAvatar(item)}
                  isInnerCard={false}
                />
              );
            })}
          </Row>
        ) : (
          <Text style={{ ...objText, margin: "auto" }}>No Avatar Found!</Text>
        )}
        <Flex style={{ height: "calc(100vh - 300px)" }}>
          <AvatarListSlider
            open={selectedMainAvatar === null ? false : true}
            onClose={() => setSelectedMainAvatar(null)}
            item={selectedMainAvatar}
            handlePreview={(i) => setClickedAvatarItem(i)}
            updateAvatar={updateAvatar}
          />
        </Flex>
      </Col>

      <PreviewModal
        open={showPreview}
        src={clickedAvatarItem?.intro || ""}
        closeModal={() => setShowPreview(false)}
        avatarName={clickedAvatarItem?.name || ""}
      />
    </Row>
  );
};

type IProps = {
  src: string;
  open: boolean;
  avatarName: string;
  closeModal: () => void;
};
const PreviewModal: FC<IProps> = ({ src, open, closeModal, avatarName }) => {
  const [renderLoading, setRenderLoading] = useState<boolean>(true);
  return (
    <Modal
      open={open}
      destroyOnClose
      closable
      afterClose={closeModal}
      onCancel={closeModal}
      title={<Text style={objText}>{avatarName}</Text>}
      footer={false}
    >
      <Flex vertical gap="middle">
        <video controls src={src} style={{ maxHeight: "50vh" }} onEnded={() => setRenderLoading(true)} onLoadedData={() => setRenderLoading(false)} />

        <Flex justify="flex-end" align="center" gap="small">
          <Button onClick={closeModal}>Close</Button>
          <Button type="primary">Choose</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

type AvatarListProps = {
  open: boolean;
  onClose: () => void;
  item: AvatarDataType | null;
  handlePreview: (item: AvatarListDataType | null) => void;
  updateAvatar: Function;
};

const AvatarListSlider: FC<AvatarListProps> = ({ open, onClose, item, handlePreview, updateAvatar }) => {
  return (
    <Drawer
      title={
        <Flex justify="space-between">
          <LeftOutlined onClick={onClose} />
          <Text style={{ ...objText, margin: "auto" }}>{item?.name}</Text>
        </Flex>
      }
      style={{ width: "100%", background: "white", boxShadow: "none" }}
      bodyStyle={{ boxShadow: "none" }}
      getContainer={false}
      closeIcon={false}
      onClose={onClose}
      open={open}
      rootStyle={{ boxShadow: "none" }}
      className="sidebar"

      // style={{[`ant-drawer-content-wrapper`]:{boxShadow:"none"}}}
    >
      <Row gutter={[16, 16]}>
        {item && <AvatarItemCard item={item} handlePreview={handlePreview} isInnerCard={true} updateAvatar={updateAvatar} />}
      </Row>
    </Drawer>
  );
};

type AvatarItemCardProps = {
  item: AvatarDataType;
  handlePreview: (item: AvatarListDataType | null) => void;
  onClick?: () => void;
  isInnerCard: boolean;
  updateAvatar?: Function;
};

const AvatarItemCard: FC<AvatarItemCardProps> = ({ item, handlePreview, onClick, isInnerCard, updateAvatar }) => {
  return (
    <>
      {isInnerCard ? (
        <>
          {item?.variants?.map((listItem: AvatarListDataType, listIndex: number) => {
            return (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} key={listIndex}>
                  <Flex vertical gap="small">
                    <Image
                      key={`avatardatalist_index_${listIndex}`}
                      src={listItem.canvas}
                      preview={false}
                      onClick={() => {
                        updateAvatar?.(listItem);
                      }}
                    ></Image>
                    <Flex justify="space-between" align="center">
                      <Text style={objText}>{listItem?.name}</Text>
                      <Button icon={<PlaySquareOutlined />} style={{ padding: "0 9px", fontSize: "12px" }} onClick={() => handlePreview(listItem)}>
                        Preview
                      </Button>
                    </Flex>
                  </Flex>
                </Col>
              </>
            );
          })}
        </>
      ) : (
        <>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Flex vertical gap="small">
              <Carousel style={{ border: "1px solid #d9d9d9", borderRadius: "8px", transition: "border .2s ease-in-out", cursor: "pointer" }}>
                {item?.variants?.map((listItem: AvatarListDataType, listIndex: number) => {
                  return <Image key={`avatardatalist_index_${listIndex}`} src={listItem.canvas} preview={false} onClick={onClick}></Image>;
                })}
              </Carousel>
              <Flex justify="space-between" align="center">
                <Text style={objText}>{item?.name}</Text>
                <Button icon={<PlaySquareOutlined />} style={{ padding: "0 9px", fontSize: "12px" }} onClick={() => handlePreview(null)}>
                  Preview
                </Button>
              </Flex>
            </Flex>
          </Col>
        </>
      )}
    </>
  );
};
