import { Navigate, Route, Routes } from "react-router-dom";
import { ENUM_ROUTE_URLS, PrivateRoutes, PublicRoutes } from "./_routesConfig";
import AccessToPrivateRoutes from "../Components/Auth/_AccessToPrivateRoutes";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={ENUM_ROUTE_URLS.LOGIN} />} />
      {PublicRoutes.map((item, index) => {
        return <Route key={index} path={item?.path} element={item?.component} />;
      })}

      {PrivateRoutes.map((item, index) => {
        return <Route key={index} path={item?.path} element={<AccessToPrivateRoutes>{item?.component}</AccessToPrivateRoutes>} />;
      })}
    </Routes>
  );
};

export default MainRoutes;
