import { Card } from "antd";
import "./AvatarVideoTile.css";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useEffect, useRef, useState } from "react";

interface IProps {
  imgSrc: string;
  videoSrc: string | undefined;
  description: string;
  style?: {};
  showRight?: boolean;
  onClick?: Function;
}

const AvatarVideoTile: React.FC<IProps> = ({
  imgSrc,
  videoSrc,
  description,
  style = {},
  showRight = true,
  onClick = () => {},
}) => {
  const [playState, setPS] = useState("pause"); //'pause', 'play'
  const [currentTime, setCT] = useState<number>(0);
  const [duration, setD] = useState(0);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const fullscreenchangeHandler = (event: Event) => {
      if (document.fullscreenElement) {
        //@ts-ignore
        event.target.controls = true;
      }
      //@ts-ignore
      else event.target.controls = false;
    };

    const pauseEventHandler = () => {
      setPS("pause");
    };

    const playEventHandler = () => {
      setPS("play");
    };

    const timeUpdateHandler = (event: Event) => {
      //@ts-ignore
      setCT(event?.target?.currentTime);
    };

    const durationChangeHandler = (event: Event) => {
      //@ts-ignore
      setD(event?.target?.duration);
    };

    videoRef.current?.addEventListener(
      "fullscreenchange",
      fullscreenchangeHandler
    );
    videoRef.current?.addEventListener("pause", pauseEventHandler);
    videoRef.current?.addEventListener("play", playEventHandler);
    videoRef.current?.addEventListener("timeupdate", timeUpdateHandler);
    videoRef.current?.addEventListener("durationchange", durationChangeHandler);

    return () => {
      videoRef.current?.removeEventListener(
        "fullscreenchange",
        fullscreenchangeHandler
      );
    };
  }, [videoRef.current]);

  const getTimeString = (seconds: number | any): string => {
    return new Date((seconds || 0) * 1000).toISOString().substring(14, 19); //<24 hrs
  };

  return (
    <div
      style={{
        boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
        borderRadius: "12px",
      }}
    >
      <Card
        hoverable
        bodyStyle={{
          ...style,
          borderRadius: "12px",
          overflow: "hidden",
          padding: 0,
        }}
        style={{ borderRadius: "12px", overflow: "hidden" }}
        onClick={() => onClick()}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ position: "relative", display: "flex" }}>
            <video
              ref={videoRef}
              /* controls */
              disablePictureInPicture
              preload="metadata" //"none"
              controlsList="nodownload noplaybackrate"
              src={videoSrc}
              poster={imgSrc}
              //width="298px"
              width="258px"
              //height="249px"
              height="198px"
              style={{ objectFit: "cover", borderRadius: "12px" }}
            />
            {videoSrc && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background:
                    "linear-gradient(hsla(0, 0%, 0%, 0), hsl(0, 0%, 15%) 100%)",
                  borderRadius: "0 0 12px 12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "whitesmoke",
                  }}
                >
                  <div className="button-container">
                    {playState === "pause" ? (
                      <img
                        src={PROJECT_ICONS.CT_PLAY_ICON}
                        onClick={() => {
                          videoRef?.current?.play();
                        }}
                      />
                    ) : (
                      <img
                        src={PROJECT_ICONS.CT_PAUSE_ICON}
                        onClick={() => {
                          videoRef?.current?.pause();
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <span>{getTimeString(currentTime)}</span> /{" "}
                    <span>{getTimeString(duration)}</span>
                  </div>
                </div>

                <div className="button-container">
                  <img
                    src={PROJECT_ICONS.CT_FULLSCREEN_ICON}
                    onClick={() => {
                      videoRef?.current?.requestFullscreen();
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className="d-flex justify-content-between"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                font: "normal normal bold 16px/22px Nunito Sans",
                letterSpacing: "0px",
                margin: "0.5rem",
              }}
              className="d-flex align-items-center"
            >
              {description}
            </div>

            {showRight && (
              <>
                <span style={{ width: "2rem" }}></span>
                <span style={{ margin: "0.5rem" }}>⟶</span>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AvatarVideoTile;
