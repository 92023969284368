import { Row } from "antd";
import { SourceCard } from "../../Components/SourceCard";
import { useEffect, useState } from "react";
import { CT_SOURCE_TYPES, CT_VIDEO_SOURCE_LIST } from "../../Core/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCTFinalProductAction, setCTSourceAction } from "../../../../Store/actions/_contentTranscreationActions";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

export const SelectVideoSource = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { source } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [videoSourceList, setVSList] = useState(
    CT_VIDEO_SOURCE_LIST.map((item) => {
      item.selected = item.name === source ? true : false;
      return item;
    })
  );

  return (
    <div>
      <h2 style={{ marginBottom: "1rem" }}>{t(I18N_MODULE_KEYS["content.transcreation.selectsource.2.title"])}</h2>
      <Row gutter={[16, 16]}>
        {videoSourceList.map((item, index) => (
          <SourceCard
            key={index}
            selected={item.selected}
            // title={item.name + " to Video"}
            title={
              item.name === CT_SOURCE_TYPES.BLOG
                ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.2.card.blog"])
                : item.name===CT_SOURCE_TYPES.EMAIL? t(I18N_MODULE_KEYS["content.transcreation.selectsource.2.card.email"])
                : item.name===CT_SOURCE_TYPES.BANNER ?t(I18N_MODULE_KEYS["content.transcreation.selectsource.2.card.banner"])
                : item.name===CT_SOURCE_TYPES.SOCIALMEDIA ?t(I18N_MODULE_KEYS["content.transcreation.selectsource.2.card.socialmediapost"])
                :t(I18N_MODULE_KEYS["content.transcreation.selectsource.2.card.poster"]) 
            }
            onClick={() => {
              //console.log({ index });
              if(item.name === CT_SOURCE_TYPES.BLOG || item.name === CT_SOURCE_TYPES.EMAIL){
              setVSList((list) => [
                ...list.map((item, idx) => {
                  item.selected = index === idx ? true : false;
                  return item;
                })
              ]);

              //console.log(productList[index].name)
              dispatch(setCTSourceAction(videoSourceList[index].name));
            }}}
          />
        ))}
        {/*   <SourceCard title="Video" onClick={() => {}} />
        <SourceCard title="Social Media Post" onClick={() => {}} /> */}
      </Row>
    </div>
  );
};
