import { Checkbox, Flex, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LanguageListTypes, UploadDocsTypes } from "../../Core/modals";
import { useDispatch } from "react-redux";
import { setUploadDocsDataTMAction } from "../../../../Store/actions/_translationModuleActions";

const { Text } = Typography;

export const ListingContainer: FC = () => {
  const dispatch = useDispatch();

  const { languageList, uploadDocsData }: { languageList: LanguageListTypes[]; uploadDocsData: UploadDocsTypes } = useSelector(
    (state: any) => state?.translationModuleReducer
  );

  const [filteredLanguage, setFilteredLanguage] = useState<LanguageListTypes[]>([]);

  useEffect(() => {
    handleFilter();
  }, [uploadDocsData?.asset?.searchedVal]);

  const handleCheckbox = (id: number) => {
    const arrLanguageIds: number[] = [...uploadDocsData.asset.language_ids];

    if (arrLanguageIds?.includes(id)) {
      arrLanguageIds.splice(arrLanguageIds.indexOf(id), 1);
    } else {
      arrLanguageIds.push(id);
    }

    dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, asset: { ...uploadDocsData.asset, language_ids: [...arrLanguageIds] } }));
  };

  const handleFilter = () => {
    let arrLanguageList: LanguageListTypes[] = [...languageList];

    arrLanguageList = arrLanguageList.filter((item: LanguageListTypes) =>
      item?.name?.toLocaleLowerCase()?.includes(uploadDocsData.asset.searchedVal?.toLocaleLowerCase() as string)
    );

    setFilteredLanguage([...arrLanguageList]);
  };

  const getLanguageArray = () => {
    if (uploadDocsData.asset.searchedVal) {
      return filteredLanguage;
    }

    return languageList;
  };

  return (
    <Flex>
      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          height: "40vh",
          overflowX: "scroll",
          gap: "12px 30px",
          width: "100%"
        }}
      >
        {getLanguageArray()?.length ? (
          <>
            {getLanguageArray()?.map((item: LanguageListTypes, index: number) => {
              return (
                <li>
                  <Flex gap={"small"} align="center" style={{ cursor: "pointer" }} onClick={() => handleCheckbox(item.id)}>
                    <Checkbox checked={uploadDocsData?.asset?.language_ids?.includes(item.id)} />
                    <Text style={{ fontSize: "12px", fontWeight: "400", cursor: "pointer" }}>{item.name}</Text>
                  </Flex>
                </li>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </ul>
    </Flex>
  );
};
