import { useEffect, useState } from "react";
import { CUUploadLeftPane } from "./CUUploadLeftPane";
import { CUUploadRightPane } from "./CUUploadRightPane";
import { ContentAITags } from "../../../Core/types";
import { siteConfig } from "../../../../../Services";
import { getAllTagsRequest } from "../../../../../Store/requests/_contentAIRequests";
import { globalConstant } from "../../../../../Utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const contentAIModule = "content_universe";

export const CUUploadScreen = () => {
  const dispatch = useDispatch();
  const { selectedTags } = useSelector((state: any) => state?.contentAIReducer);

  const [allTags, setAllTags] = useState<ContentAITags>({});
  const [loadingTags, setLoadingTags] = useState(false);

  useEffect(() => {
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;
    getAllTags();
  }, []);

  const getAllTags = async () => {
    setLoadingTags(true);

    const res = await getAllTagsRequest();

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      if (res.tags) {
        let tags: ContentAITags = res.tags;

        Object.keys(tags).forEach((category) => {
          for (let tag of tags[category]) {
            if (selectedTags[contentAIModule].includes(tag.id)) {
              tag.selected = true;
            }
          }
        });
        setAllTags(tags);
      }
    }

    setLoadingTags(false);
  };

  return (
    <div className="custom-row cu-upload-screen" style={{ background: "#fff", borderRadius: "10px" }}>
      <CUUploadLeftPane allTags={allTags} loadingTags={loadingTags} setAllTags={setAllTags} getAllTags={getAllTags} />
      <CUUploadRightPane />
    </div>
  );
};
