import { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { Dropdown } from "antd";
import { removeHandler, copyHandler, pasteHandler, getActiveObjectLayers } from "../../fabric-files/utils/canvas";
import {
  copyIcon,
  pasteIcon,
  deleteIcon,
  layersIcon,
  alignLeftIcon,
  rightArrowIcon,
  moveToIcon,
  alignMenuItemChildren,
  moveToMenuItemChildren,
  createLayerSubmenuItem,
  PROPS_TO_INCLUDE
} from "./constans.js";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";
import { setActiveObjectModifier, setElaiTranslationDataAction, setSaveElaiObject } from "../../../../../Store/actions/_contentTranscreationActions";
import { useDispatch } from "react-redux";
import { Canvas } from "fabric/fabric-impl";

const ContextMenu = ({
  children,
  index,
  data,
  canvas
}: // data,
// canvas,
// canvasActiveObject,
// isShiftPressed,
// setActiveObjectModifier,
// updateCanvas,
// getSlideChangesOnPasteAvatar
{
  children: any;
  index: number;
  data: any;
  canvas: Canvas | undefined;
}) => {
  const { canvasActiveObject, elaiResponse } = useSelector((state: RootState) => state.contentTranscreationReducer);
  const dispatch = useDispatch();

  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [contextMenuOpenKeys, _setContextMenuOpenKeys] = useState([]);
  const layersSubmenuClicked = useRef(false);
  const contextMenuOpenKeysRef = useRef(null);

  useEffect(() => {
    //console.log({ isOpenContextMenu, contextMenuOpenKeys });
    if (!isOpenContextMenu && contextMenuOpenKeys.length) {
      setContextMenuOpenKeys([]);
    }
  }, [isOpenContextMenu]);

  const setContextMenuOpenKeys = (state: any) => {
    _setContextMenuOpenKeys(state);
    contextMenuOpenKeysRef.current = state;
  };

  const onContextMenuOpenChange = (v: any) => setIsOpenContextMenu(v);

  const handleSubmenuOpen = (openKeys: string[]) => {
    console.log({ openKeys });
    if (layersSubmenuClicked.current) {
      layersSubmenuClicked.current = false;
      openKeys = ["layers"];
    }
    setContextMenuOpenKeys(openKeys);
  };

  const updateCanvas = (canvas?: Canvas) => {
    const updatedElaiResponse = structuredClone(elaiResponse);
    updatedElaiResponse.slides[index].canvas = canvas?.toObject(PROPS_TO_INCLUDE);
    console.log("updated elai video object", updatedElaiResponse);
    dispatch(setSaveElaiObject(updatedElaiResponse));
  };

  const getSlideChangesOnPasteAvatar = ({ linkedSlideChanges }: { linkedSlideChanges: any }) => {
    console.log("getSlideChangesOnPasteAvatar:", { linkedSlideChanges });
  };

  const handleClickCanvasContextMenu = useCallback(
    async ({ key, keyPath }: { key: any; keyPath: any }) => {
      /* if (keyPath.includes("layers")) {
        layersSubmenuClicked.current = true;
        dispatch(setActiveObjectModifier({ change: "layersSelection", value: key }));
        if (
          (canvasActiveObject?.type !== "activeSelection" || canvasActiveObject.getObjects().length === 1) &&
          canvasActiveObject.id === +key &&
          isShiftPressed.current
        ) {
          setIsOpenContextMenu(false);
        }
      } else */ if (key === "copy") {
        const { id: slideId, avatar, voice, voiceProvider } = data;
        await copyHandler({ slideId, canvas, avatar, voice, voiceProvider, activeObject: canvasActiveObject });
      } else if (key === "paste") {
        await pasteHandler({ slideId: data.id, canvas, speech: data.speech }, canvas, updateCanvas, getSlideChangesOnPasteAvatar);
      } else if (key === "delete") {
        removeHandler(canvas);
        canvas?.renderAll();
        updateCanvas(canvas);
      } else if (keyPath.includes("moveTo")) {
        dispatch(setActiveObjectModifier({ change: "layering", action: key }));
      } else if (keyPath.includes("align")) {
        dispatch(setActiveObjectModifier({ change: "groupedAlignment", value: key }));
      }
      if (!keyPath.includes("layers")) setIsOpenContextMenu(false);
    },
    [canvas, canvasActiveObject, data, updateCanvas]
  );

  const handleMenuMouseLeave = () => {
    setTimeout(() => {
      //@ts-ignore
      if (!contextMenuOpenKeysRef.current?.length) setIsOpenContextMenu(false);
    }, 100);
  };

  const canvasContextMenuItems = useMemo(() => {
    if (!isOpenContextMenu) return [];
    const pasteMenuItem = {
      icon: pasteIcon,
      label: "Paste",
      key: "paste"
    };
    if (!canvasActiveObject) return [pasteMenuItem];
    // const layers = getActiveObjectLayers(canvasActiveObject);
    // const layersMenuItemChildren = layers.map((layer) => createLayerSubmenuItem(layer, canvasActiveObject));

    const moveToMenuItem = {
      icon: moveToIcon,
      label: "Move to",
      key: "moveTo",
      expandIcon: rightArrowIcon,
      children: moveToMenuItemChildren,
      popupClassName: "canvas-context-submenu"
    };

    const menuItems = [
      {
        icon: copyIcon,
        label: "Copy",
        key: "copy"
      },
      pasteMenuItem,
      {
        icon: deleteIcon,
        label: "Delete",
        key: "delete"
      },
      moveToMenuItem
    ];

    // if (layers.length)
    //   menuItems.push({
    //     icon: layersIcon,
    //     label: "Select layer",
    //     key: "layers",
    //     expandIcon: rightArrowIcon,
    //     children: layersMenuItemChildren,
    //     popupClassName: "canvas-layer-submenu"
    //   });

    // if (canvasActiveObject.type === "activeSelection")
    //   menuItems.push({
    //     icon: alignLeftIcon,
    //     label: "Align elements",
    //     key: "align",
    //     expandIcon: rightArrowIcon,
    //     children: alignMenuItemChildren,
    //     popupClassName: "canvas-context-submenu"
    //   });

    if (canvasActiveObject?.type === "avatar") {
      return [pasteMenuItem, moveToMenuItem];
    }

    return menuItems;
  }, [canvasActiveObject, canvasActiveObject?.left, canvasActiveObject?.top, isOpenContextMenu]);

  return (
    <Dropdown
      open={isOpenContextMenu}
      menu={{
        items: canvasContextMenuItems,
        openKeys: contextMenuOpenKeys,
        onOpenChange: handleSubmenuOpen,
        onClick: handleClickCanvasContextMenu
      }}
      trigger={["contextMenu"]}
      overlayClassName="canvas-context-menu"
      dropdownRender={(menu) => <div onMouseLeave={handleMenuMouseLeave}>{menu}</div>}
      onOpenChange={onContextMenuOpenChange}
    >
      {children}
    </Dropdown>
  );
};

export default ContextMenu;
