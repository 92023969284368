import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import {
  AlignLeftOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { nextinsightsecondcardindividualstyle, nextinsightsecondcardindividualstyledisable, nextinsightsecondextracardstyle } from "../StylesFolderForNextInsight/_NextInsightPageStyleConstant";

interface NextInsightPageProps {
  showComponentForChunks: (type: string, url: string) => void;
}

export const NextInsightVideos: FC<NextInsightPageProps> = ({ showComponentForChunks }) => {
  const { cardsVideoData } = useSelector((state: any) => state.semanticSearchReducer);

  return (
    <>
      <Row style={{  }}>
        <Image src={PROJECT_ICONS.PLAYOUTLINE} style={{ paddingRight: "9px" }} />
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsVideoData && cardsVideoData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          Video(s)
        </p>
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsVideoData && cardsVideoData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          |
        </p>
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsVideoData && cardsVideoData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          {cardsVideoData ? (cardsVideoData.length >= 10 ? cardsVideoData.length : "0" + cardsVideoData.length) : "00"}{" "}
        </p>
      </Row>
      <Row gutter={[24, 24]} style={{ paddingLeft: "30px",marginTop:'15px' }}>
        {cardsVideoData && cardsVideoData.length > 0 ? (
          cardsVideoData.length <= 4 ? (
            cardsVideoData.map((video: any, index: number) => (
              <Col
                key={index}
                span={5}
                style={nextinsightsecondcardindividualstyle}
                onClick={() => {
                  showComponentForChunks("Video", video?.s3_url);
                }}
              >
                <Image src={PROJECT_ICONS.VIDEO} preview={false}  onClick={() => {
                    showComponentForChunks("Video", video?.s3_url);
                  }} />
              </Col>
            ))
          ) : (
            <>
              {cardsVideoData.slice(0, 3).map((video: any, index: number) => (
                <Col
                  key={index}
                  span={5}
                  style={nextinsightsecondcardindividualstyle}
                  onClick={() => {
                    showComponentForChunks("Video", video?.s3_url);
                  }}
                >
                  <Image src={PROJECT_ICONS.VIDEO} preview={false}  onClick={() => {
                    showComponentForChunks("Video", video?.s3_url);
                  }}/>
                </Col>
              ))}
              <Col
                span={5}
                style={nextinsightsecondextracardstyle}
                onClick={() => {
                  showComponentForChunks("Video", cardsVideoData[0]?.s3_url);
                }}
              >
                <div style={{ color: "white", fontWeight: "600", fontSize: "15px" }} onClick={() => {
                  showComponentForChunks("Video", cardsVideoData[0]?.s3_url);
                }}>
                  +0{cardsVideoData.length - 3}
                </div>
              </Col>
            </>
          )
        ) : (
          <Col span={5} style={nextinsightsecondcardindividualstyledisable}>
            <Image src={PROJECT_ICONS.VIDEO} preview={false} /> 
          </Col>
        )} 
      </Row>
    </>
  );
};
