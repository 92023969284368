import { Button, Typography } from "antd";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useDispatch } from "react-redux";
import { setActiveScreenTMAction } from "../../../../Store/actions/_translationModuleActions";
import { enumTMActiveScreen } from "../../Core/constant";
import { AssetDataTypes } from "../../Core/modals";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import _translationModuleRequests from "../../../../Store/requests/_translationModuleRequests";
import { useState } from "react";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";

const { Text } = Typography;
export default function TranslationLoadingScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { package_id }: { package_id: number } = useSelector((state: any) => state?.translationModuleReducer?.uploadDocsData);

  const [cancelTranslation, setCancelTranslation] = useState(false);

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module.FinalTranslationScreen.TranslationLoadingScreen.${value as string}`])}`;
  };

  const {
    asset: { language_ids }
  }: {
    asset: AssetDataTypes;
  } = useSelector((state: any) => state?.translationModuleReducer?.uploadDocsData);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ display: "flex" /* , justifyContent: "space-between" */ }}>
        <div style={{ display: "flex", flexDirection: "column", flex: "0 0 auto" }}>
          <Text style={{ fontSize: "18px", fontWeight: "600" }}>{getTranslatedText("Translate your package")}</Text>
          <Text style={{ fontSize: "10px", color: "#66676B" }}>
            {getTranslatedText("At the moment, AI-generated translation will be applied to all of your assets.")}
          </Text>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", gap: "10px", flex: "1 1 auto", flexDirection: "column" }}>
        <Lottie id="email" style={{ height: "300px" }} animationData={PROJECT_ICONS.TRANSLATION_ANIMATION} loop={true} />
        <div
          style={{
            display: "flex",
            flex: "2",
            flexDirection: "column",
            gap: "5px",
            whiteSpace: "break-spaces",
            textAlign: "center",
            color: "#66676B"
          }}
        >
          <div>{getTranslatedText("Please wait...")}</div>
          <div>
            {language_ids.length} {getTranslatedText("Selected Language")} <b>{getTranslatedText("“Translation”")}</b>
          </div>
          <div>{getTranslatedText("is in Progress")}</div>
          <div>
            <Button
              type="default"
              style={{ border: "#034EA2 1px solid", color: "#034EA2", padding: "0 1rem", margin: "1rem auto" }}
              loading={cancelTranslation}
              onClick={async () => {
                setCancelTranslation(true);
                await _translationModuleRequests.revokeFileTranslation(package_id);
                setCancelTranslation(false);
                dispatch(setActiveScreenTMAction(enumTMActiveScreen.TRANSLATE_ASSET));
                dispatch(setNotificationMsgAction(getTranslatedText("Translation Cancelled!")));
              }}
            >
              {cancelTranslation ? getTranslatedText("Cancelling Translation") : getTranslatedText("Cancel Translation")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
