import React, { useState, useEffect, useRef } from "react";
import { Layout, Image, Row, Col, Input, Modal, Button } from "antd";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import { useSelector, useDispatch } from "react-redux";
import {
  setEditableScreen,
  setLoadingforReportGeneration,
  setPdfForReports,
  setSlidesForReports,
} from "../../../../../Store/actions/_semanticSearchActions";
import _localStorageService from "../../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { generatePdf } from "../../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import TextArea from "antd/es/input/TextArea";

export const ModalLoaderForPptGeneration: React.FC = () => {
  const { loadingForReportGeneration, conversationId, reportType } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  // const [retryEnable, setRetryEnable] = useState(false);
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const accessToken = token || "";
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const sseRef = useRef<SSE | null>(null); // Ref for SSE instance
  const [stepper,setStepper]=useState(1);
  const [input,setInput]=useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };
  const handleSendContext = async()=>{
    setStepper(2);
    }
    const handleSendWithoutContext = async()=>{
      setInput("")
      setStepper(2);
  
    }
  
  useEffect(() => {
    const generateReportPpt = async () => {
     
        const timeoutId = setTimeout(() => {
          closeSSEConnections();
        }, 100000); // 20 seconds in milliseconds

        timeoutRef.current = timeoutId;

        const apiUrl = `${apiurl}/document/report/generate/ppt`;
        const headers = {
          Authorization: accessToken,
          "Content-Type": "application/json",
        };
        const payload = {
          conversation_id: conversationId,
          user_id: "0",
          user_context:input,
        };

        const closeSSEConnections = async () => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          if (sseRef.current) {
            sseRef.current.close();
          }
          dispatch(setNotificationMsgAction("Please try again for generating the Powerpoint."));
          // setRetryEnable(true);
          console.log("SSE connections closed due to timeout.");
          dispatch(setSlidesForReports({}));
          dispatch(setEditableScreen(false));
          dispatch(setLoadingforReportGeneration(false));
        };

        const parseJSON = (data: any) => {
          try {
            return JSON.parse(data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        };

        const sse = new SSE(apiUrl, {
          headers,
          payload: JSON.stringify(payload),
        });

        sse.addEventListener("end", (event: any) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          clearTimeout(timeoutId);
          const jsonObject = event?.data;
          console.log(jsonObject);
          dispatch(setSlidesForReports(parseJSON(jsonObject)));
          dispatch(setLoadingforReportGeneration(false));
          dispatch(setEditableScreen(true));
        });

        sse.addEventListener("error", (event: any) => {
          console.error("SSE error:", event);
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          clearTimeout(timeoutId);
          if (sseRef.current) {
            sseRef.current.close();
          }
          dispatch(setNotificationMsgAction("Please try again for regenerating the Powerpoint."));
          dispatch(setSlidesForReports({}));
          // setRetryEnable(true);
          dispatch(setEditableScreen(false));
          dispatch(setLoadingforReportGeneration(false));
        });

        sseRef.current = sse; // Save the SSE instance in the ref
        sse.stream(); // Start the SSE stream
      
    };
    if(stepper===2){
    generateReportPpt();
    }
    // Cleanup function to clear timeout and close SSE connection
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (sseRef.current) {
        sseRef.current.close();
      }
    };
  }, [stepper]);

  useEffect(() => {
    const generateReport = async () => {
      if (reportType === "Powerpoint") {
      } else {
        const res = await generatePdf({ conversation_id: conversationId, regenerate: false });
        if (!res) {
          dispatch(setNotificationMsgAction("Please try again for generating the PDF."));
          dispatch(setPdfForReports({}));
          dispatch(setEditableScreen(false));
          dispatch(setLoadingforReportGeneration(false));
          // setRetryEnable(true);
          return;
        }
        dispatch(setPdfForReports(res));
        dispatch(setEditableScreen(true));
        dispatch(setLoadingforReportGeneration(false));
      }
    };

    generateReport();

    // Cleanup function to clear timeout and close SSE connection
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (sseRef.current) {
        sseRef.current.close();
      }
    };
  }, []);

  const handleCancel = async () => {
    dispatch(setLoadingforReportGeneration(false));
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (sseRef.current) {
      sseRef.current.close();
    }
  };
  return (
    <Modal
    visible={loadingForReportGeneration}
    footer={null}
    closable={false}
    centered
    maskStyle={{
      backdropFilter: "blur(30px)",
      background: "rgba(44, 78, 101, 0.5)",
    }}
  >
{stepper===1 && reportType==="Powerpoint"?(<>
<div style={{fontWeight:'600',fontSize:'18px'}}>Context for PPT</div>
<TextArea rows={9}  placeholder="Enter your prompt" style={{border:'none',resize:'none' ,marginTop:'20px',background:'#F8F8F8',fontSize:'15px', fontStyle: 'italic'
}} draggable={false} autoSize={false}  value={input}
onChange={handleInputChange}/>
<div style={{display:'flex',justifyContent:'flex-end',marginTop:'20px',gap:'10px'}}>
<Button type="link" style={{color:'#034EA2',paddingLeft:'10px'}} onClick={handleCancel}>CANCEL</Button>
<button style={{
          background: "white",
          border: "1px solid #1E4DA1",
          color: "#1E4DA1",
          fontSize: "14px",
          fontWeight: "600",
          borderRadius: "4px",
          padding: "6px 12px",
          cursor: "pointer",
        }}
        onClick={handleSendWithoutContext}
      >SKIP</button>
<button
        style={{
          background: input ? "#1E4DA1" : '#E6E7E8',
          border: input ? "1px solid #1E4DA1" : "1px solid #E6E7E8",
          color: input ? "white" : "#66676B",
          fontSize: "14px",
          fontWeight: "600",
          borderRadius: "4px",
          padding: "6px 12px",
          cursor: "pointer",
        }}
        onClick={handleSendContext}
      >
        SUBMIT
      </button>
</div>
</>):(
<>

    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "40px" }}>
      <Lottie animationData={PROJECT_ICONS.COFFEE} style={{ height: "140px", width: "100px" }} />
      <span style={{ fontSize: "16px", fontWeight: "500", color: "#515151", textAlign: "center" }}>
        Hey, John Doe, your {reportType} is generating.
      </span>
      <span style={{ fontSize: "16px", fontWeight: "500", color: "#515151", textAlign: "center" }}>Please wait and grab a cup of coffee.</span>
    </div>
   
    <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
      <button
        style={{
          background: "#1E4DA1",
          border: "1px solid #1E4DA1",
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
          borderRadius: "4px",
          padding: "6px 12px",
          cursor: "pointer",
        }}
        onClick={handleCancel}
      >
        Cancel
      </button>
     
    </div></>)}
  </Modal>
  );
};







