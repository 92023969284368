import { fabric } from 'fabric'
import { lookupEasing, DEFAULT_ANIMATION_EASING } from './easings'

const FADE_SHIFT_RATIO_ENTER = 1.4
const FADE_SHIFT_RATIO_EXIT = 1.2
const SHIFT_DURATION = 0.4
const SHIFT_DURATION_EXIT = 0.25
const ANIM_DEFAULT_DELAY = 0.28
const ANIM_DEFAULT_DELAY_EXIT = 0.21
const DEFAULT_DURATION_EXIT_DELAY = 0.9
const SHIFT_DISTANCE = 70
const BREATHE_INIT_SCALE = 0.8
const BREATHE_SCALE_PER_SECOND = 1 / 25 / 5

const SHIFT_DURATION_MOVE = 20
const SHIFT_DISTANCE_MOVE = 60
const SHIFT_DURATION_MOVE_CENTER = 35
const DURATION_MOVE_COEFF = 1.7
const DURATION_MOVE_MIN = 10

const ANIMATION_SAFE_TIME_ZONE = 0.05

const prepareAnimation = async (canvas, data, animation, exitAnimation, slideDuration) => {
  if (!canvas || !data?.objects.length) return

  const appearOrder = canvas
    .getObjects()
    .filter((o) => !o.animation?.type && !o.animation?.exitType)
    .sort((a, b) => a.top - b.top || a.left - b.left)

  if (animation === 'waves') {
    let centerObjectStartsFromLeft = true
    canvas
      .getObjects()
      .sort((a, b) => a.top - b.top)
      .forEach((obj) => {
        const center = obj.getCenterPoint()
        /**
         * - calculate where object is located (left, right or center)
         * - based on this determine initial direction and x coordinates
         */
        if (center.x < 213 || center.x >= 426) {
          // left or right
          obj.set({
            shiftDuration: SHIFT_DURATION_MOVE,
            shiftDistance: center.x < 213 ? SHIFT_DISTANCE_MOVE : -SHIFT_DISTANCE_MOVE,
          })
        } else {
          // center objects should start from left/right one by one ordered from top to bottom
          obj.set({
            shiftDuration: SHIFT_DURATION_MOVE_CENTER,
            shiftDistance: centerObjectStartsFromLeft ? SHIFT_DISTANCE_MOVE : -SHIFT_DISTANCE_MOVE,
          })
          centerObjectStartsFromLeft = !centerObjectStartsFromLeft
        }
      })
  }

  canvas.forEachObject((obj, index) => {
    if (obj.type === 'avatar') return
    const { left, top } = obj.getBoundingRect()
    const order = appearOrder.findIndex((o) => o.id === obj.id)
    const animationStartTime = obj.animation?.type ? Number(obj.animation.startTime) : order * ANIM_DEFAULT_DELAY
    const animationEndOrderTime = slideDuration - DEFAULT_DURATION_EXIT_DELAY - order * ANIM_DEFAULT_DELAY_EXIT
    const animationEndTime = obj.animation?.exitType
      ? Number(
          obj.animation.endTime
            ? obj.animation.endTime < 0
              ? slideDuration + obj.animation.endTime
              : obj.animation.endTime
            : animationEndOrderTime,
        )
      : animationEndOrderTime

    const center = obj.getCenterPoint()
    const animationEnterDuration = obj.animation?.duration || SHIFT_DURATION
    const animationExitDuration = obj.animation?.exitDuration || SHIFT_DURATION_EXIT

    const hasEnterAnimation = obj.animation?.type !== undefined ? obj.animation?.type : animation
    obj.set({
      id: obj.id,
      originalStyles: { top, left, opacity: obj.opacity, scaleX: obj.scaleX, scaleY: obj.scaleY, center },
      animationType: obj.animation?.type !== undefined ? obj.animation?.type : animation,
      animationExitType: obj.animation?.exitType !== undefined ? obj.animation?.exitType : exitAnimation,
      animationStartTime,
      animationEndTime,
      animationEnterDuration,
      animationExitDuration,
      animationEnterEasing: obj.animation?.easing || DEFAULT_ANIMATION_EASING,
      animationExitEasing: obj.animation?.exitEasing || DEFAULT_ANIMATION_EASING,
      fadeInDuration: animationEnterDuration * FADE_SHIFT_RATIO_ENTER,
      fadeOutDuration: animationExitDuration * FADE_SHIFT_RATIO_EXIT,
      hasControls: false,
      hasBorders: false,
      opacity: hasEnterAnimation ? 0 : obj.opacity,
    })

    if (obj.animationType === 'waves' && !obj.shiftDuration) {
      // if waves animation is applied only to specific object, we always start it from right
      obj.set({
        shiftDuration: SHIFT_DURATION_MOVE,
        shiftDistance: -SHIFT_DISTANCE_MOVE,
      })
    }
    if (obj.animationType === 'move') {
      // objects always start from left and speed (shiftDuration) depends on layout position. closer objects move faster
      const moveDurationCoeff = DURATION_MOVE_COEFF * index
      obj.set({
        shiftDuration:
          SHIFT_DURATION_MOVE - moveDurationCoeff < DURATION_MOVE_MIN
            ? DURATION_MOVE_MIN
            : SHIFT_DURATION_MOVE - moveDurationCoeff,
        shiftDistance: SHIFT_DISTANCE_MOVE,
      })
    }
  })

  canvas.verticalMoveSpeed = Math.max(3, SHIFT_DISTANCE_MOVE / slideDuration)

  if (!canvas._disposed) canvas.renderAll()
}

const balanceAnimate = (obj, t) => {
  const cycle = Math.trunc(Math.abs((t - obj.animationStartTime) / obj.shiftDuration))
  const startTime = obj.animationStartTime + obj.shiftDuration * cycle
  let centerX
  if (cycle % 2 === 0) {
    centerX =
      obj.originalStyles.center.x - obj.shiftDistance + ((t - startTime) * obj.shiftDistance * 2) / obj.shiftDuration
  } else {
    centerX =
      obj.originalStyles.center.x + obj.shiftDistance - ((t - startTime) * obj.shiftDistance * 2) / obj.shiftDuration
  }
  obj.setPositionByOrigin(new fabric.Point(centerX, obj.originalStyles.center.y), 'center', 'center')
}

const animateCanvasObjects = (canvas, player) => {
  if (!canvas.getObjects().length) {
    return
  }
  const t = player.currentTime
  canvas.forEachObject((obj) => {
    if (obj.type === 'avatar') {
      return
    }
    obj.selectable = true
    if (obj.animationType) {
      /**
       * OPACITY ANIMATION
       */
      if (t >= obj.animationStartTime && t <= obj.animationStartTime + obj.fadeInDuration)
        obj.opacity = (t - obj.animationStartTime) / (obj.fadeInDuration / obj.originalStyles.opacity)
      else if (t <= obj.animationStartTime) obj.opacity = 0
      else if (t >= obj.animationStartTime + obj.fadeInDuration) obj.opacity = obj.originalStyles.opacity
      /**
       * MOVE/WAVES ANIMATIONS
       */
      if (obj.animationType === 'move_down') {
        const centerY =
          obj.originalStyles.center.y -
          SHIFT_DISTANCE_MOVE / 2 +
          (t - obj.animationStartTime) * canvas.verticalMoveSpeed
        obj.setPositionByOrigin(new fabric.Point(obj.originalStyles.center.x, centerY), 'center', 'center')
        return canvas.renderAll()
      } else if (obj.animationType === 'move_up') {
        const centerY =
          obj.originalStyles.center.y +
          SHIFT_DISTANCE_MOVE / 2 -
          (t - obj.animationStartTime) * canvas.verticalMoveSpeed
        obj.setPositionByOrigin(new fabric.Point(obj.originalStyles.center.x, centerY), 'center', 'center')
        return canvas.renderAll()
      } else if (['waves', 'move'].includes(obj.animationType)) {
        balanceAnimate(obj, t)
        return canvas.renderAll()
      }
      /**
       * POSITION/SHIFT ANIMATION
       */
      if (
        t &&
        t >= obj.animationStartTime &&
        t <= obj.animationStartTime + obj.animationEnterDuration + ANIMATION_SAFE_TIME_ZONE
      ) {
        const elapsed = Math.min(t, obj.animationStartTime + obj.animationEnterDuration)
        if (obj.animationType === 'fade_left') {
          const centerX =
            obj.originalStyles.center.x -
            SHIFT_DISTANCE +
            SHIFT_DISTANCE *
              lookupEasing(obj.animationEnterEasing)((elapsed - obj.animationStartTime) / obj.animationEnterDuration)
          obj.setPositionByOrigin(new fabric.Point(centerX, obj.originalStyles.center.y), 'center', 'center')
        } else if (obj.animationType === 'fade_right') {
          const centerX =
            obj.originalStyles.center.x +
            SHIFT_DISTANCE -
            SHIFT_DISTANCE *
              lookupEasing(obj.animationEnterEasing)((elapsed - obj.animationStartTime) / obj.animationEnterDuration)
          obj.setPositionByOrigin(new fabric.Point(centerX, obj.originalStyles.center.y), 'center', 'center')
        } else if (obj.animationType === 'fade_top') {
          const centerY =
            obj.originalStyles.center.y -
            SHIFT_DISTANCE +
            SHIFT_DISTANCE *
              lookupEasing(obj.animationEnterEasing)((elapsed - obj.animationStartTime) / obj.animationEnterDuration)
          obj.setPositionByOrigin(new fabric.Point(obj.originalStyles.center.x, centerY), 'center', 'center')
        } else if (obj.animationType === 'fade_bottom') {
          const centerY =
            obj.originalStyles.center.y +
            SHIFT_DISTANCE -
            SHIFT_DISTANCE *
              lookupEasing(obj.animationEnterEasing)((elapsed - obj.animationStartTime) / obj.animationEnterDuration)
          obj.setPositionByOrigin(new fabric.Point(obj.originalStyles.center.x, centerY), 'center', 'center')
        }
        /**
         * SCALE ANIMATION
         */
        if (obj.animationType === 'breathe') {
          obj.set({
            scaleX: obj.originalStyles.scaleX * BREATHE_INIT_SCALE + BREATHE_SCALE_PER_SECOND * t,
            scaleY: obj.originalStyles.scaleY * BREATHE_INIT_SCALE + BREATHE_SCALE_PER_SECOND * t,
            left: obj.originalStyles.center.x,
            top: obj.originalStyles.center.y,
            originX: 'center',
            originY: 'center',
          })
        }
      } else if (
        t > obj.animationStartTime + obj.animationEnterDuration &&
        (!obj.animationExitType || t < obj.animationEndTime)
      ) {
        // ensure that object is in original state after enter animation
        obj.opacity = obj.originalStyles.opacity
        obj.setPositionByOrigin(
          new fabric.Point(obj.originalStyles.center.x, obj.originalStyles.center.y),
          'center',
          'center',
        )
      }
    }
    /***
     * DISAPPEARING ANIMATION
     */
    if (obj.animationExitType) {
      /**
       * OPACITY ANIMATION
       */
      if (t >= obj.animationEndTime && t <= obj.animationEndTime + obj.fadeOutDuration)
        obj.opacity =
          (obj.animationEndTime + obj.fadeOutDuration - t) / (obj.fadeOutDuration / obj.originalStyles.opacity)
      else if (t > obj.animationEndTime + obj.fadeOutDuration) obj.opacity = 0
      /**
       * POSITION/SHIFT ANIMATION
       */
      if (
        t >= obj.animationEndTime &&
        t <= obj.animationEndTime + obj.animationExitDuration + ANIMATION_SAFE_TIME_ZONE
      ) {
        const elapsed = Math.min(t, obj.animationEndTime + obj.animationExitDuration)
        if (obj.animationExitType === 'fade_to_right') {
          const centerX =
            obj.originalStyles.center.x +
            SHIFT_DISTANCE *
              lookupEasing(obj.animationExitEasing)((elapsed - obj.animationEndTime) / obj.animationExitDuration)
          obj.setPositionByOrigin(new fabric.Point(centerX, obj.originalStyles.center.y), 'center', 'center')
        } else if (obj.animationExitType === 'fade_to_left') {
          const centerX =
            obj.originalStyles.center.x -
            SHIFT_DISTANCE *
              lookupEasing(obj.animationExitEasing)((elapsed - obj.animationEndTime) / obj.animationExitDuration)
          obj.setPositionByOrigin(new fabric.Point(centerX, obj.originalStyles.center.y), 'center', 'center')
        } else if (obj.animationExitType === 'fade_to_bottom') {
          const centerY =
            obj.originalStyles.center.y +
            SHIFT_DISTANCE *
              lookupEasing(obj.animationExitEasing)((elapsed - obj.animationEndTime) / obj.animationExitDuration)
          obj.setPositionByOrigin(new fabric.Point(obj.originalStyles.center.x, centerY), 'center', 'center')
        } else if (obj.animationExitType === 'fade_to_top') {
          const centerY =
            obj.originalStyles.center.y -
            SHIFT_DISTANCE *
              lookupEasing(obj.animationExitEasing)((elapsed - obj.animationEndTime) / obj.animationExitDuration)
          obj.setPositionByOrigin(new fabric.Point(obj.originalStyles.center.x, centerY), 'center', 'center')
        }
      }
    }
    if (!canvas._disposed) canvas.renderAll()
  })
}

export { animateCanvasObjects, prepareAnimation }
