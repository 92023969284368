import { FilterTwoTone, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Flex, Input, Space, Typography } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { LanguageListTypes, UploadDocsTypes } from "../../Core/modals";
import { useDispatch } from "react-redux";
import { setUploadDocsDataTMAction } from "../../../../Store/actions/_translationModuleActions";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const SearchingContainer: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { languageList, uploadDocsData }: { languageList: LanguageListTypes[]; uploadDocsData: UploadDocsTypes } = useSelector(
    (state: any) => state?.translationModuleReducer
  );

  const handleSearch = (name: string) => {
    dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, asset: { ...uploadDocsData.asset, searchedVal: name } }));
  };

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._TranslateAssetMain._SearchingContainer.${value as string}`])}`;
  };

  return (
    <Flex vertical gap="small" style={{ marginTop: "15px" }}>
      <Text style={{ fontSize: "14px", fontWeight: "600" }}>
        {getTranslatedText("Languages")} ({languageList?.length})
      </Text>
      <Flex vertical gap="small">
        <Flex align="center" gap="small">
          <Input
            style={{ width: "25vw", height: "40px" }}
            prefix={
              <Button type="link" disabled={false}>
                <SearchOutlined style={{ fontSize: "20px", marginTop: "3px" }} />
              </Button>
            }
            placeholder={`Search Language`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            onKeyDownCapture={(e) => {}}
          />
          {/* <Badge dot={true}>
            <FilterTwoTone style={{ fontSize: "22px", cursor: "pointer" }} />
          </Badge>
          <Text style={{ fontSize: "12px", fontWeight: "400" }}>Filter By</Text> */}
        </Flex>

        <Text style={{ fontSize: "12px", fontWeight: "400", color: "rgba(102, 103, 107, 1)", marginLeft: "5px" }}>
          {" "}
          {getTranslatedText("Base Language English (US)")}
        </Text>
      </Flex>
    </Flex>
  );
};
