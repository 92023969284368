import { AudioOutlined } from "@ant-design/icons";
import React from "react";
import { Input, Space } from "antd";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../i18n/core/constants";

const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff"
    }}
  />
);
const onSearch = (value: string) => console.log(value);

const Searchbox: React.FC = () => {
  const { t } = useTranslation();
  return <Search placeholder={`${t(I18N_MODULE_KEYS["header.searchtext"])}`} onSearch={onSearch} enterButton style={{ width: 300, padding: 20 }} />;
};

export default Searchbox;
