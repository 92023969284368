import { SET_LOGIN_DETAILS, SET_SESSION_EXPIRED_FLAG, SET_USER_DETAILS } from "../constants/_authConstants";

type IntialState = {
  loginDetails: any;
  userDetails: any;
};

const initialState: IntialState = {
  loginDetails: {},
  userDetails: {}
};

export default function authReducer(state: IntialState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOGIN_DETAILS: {
      return {
        ...state,
        loginDetails: payload
      };
    }
    case SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: payload
      };
    }
    case SET_SESSION_EXPIRED_FLAG: {
      return {
        ...state,
        sessionExpired: payload
      };
    }
    default: {
      return state;
    }
  }
}
