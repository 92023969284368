import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import Markdown from "markdown-to-jsx";

const { Paragraph } = Typography;

interface NextInsightPageProps {
    modalopenfiletype: any;
    videoparadata:any,
    isLoadingCoffee:any,
}

export const VideoSemantic: FC<NextInsightPageProps> = ({modalopenfiletype,videoparadata,isLoadingCoffee}) => {
  const {searchQuery} = useSelector((state: any) => state.semanticSearchReducer);
  
  return (
    
   <>
             <Card style={{ width: "100%", opacity: "1", borderRadius: "10px", boxShadow: "0px 0px 2px #0000001F", height: "550px" }}>
                {isLoadingCoffee ? (
                  <>
                    <Lottie style={{ height: "300px", marginTop: "50px" }} animationData={PROJECT_ICONS.CUP} loop={true} />
                    <div style={{ textAlign: "center", font: "normal normal 600 18px/30px Nunito Sans", letterSpacing: "0px", color: "#66676B" }}>
                      Grab a Cup of Coffee
                    </div>
                    <div style={{ textAlign: "center", font: "normal normal 600 18px/30px Nunito Sans", letterSpacing: "0px", color: "#66676B" }}>
                      Loading is in Progress
                    </div>
                  </>
                ) : (
                  <Row style={{ height: "100%" }}>
                    <Col span={24}>
                      <Row>
                        <Col span={24} style={{ marginTop: "7px" }}>
                          <Paragraph
                            type="secondary"
                            style={{
                              color: "#000000",
                              font: "normal normal 400 16px/14px Nunito Sans",
                            }}
                          >
                            Search Query : {searchQuery}
                          </Paragraph>
                        </Col>
                        <Col span={24} style={{ cursor: "pointer" }} onClick={() => {}}>
                          <Paragraph
                            type="secondary"
                            style={{
                              font: "normal normal 700 18px/14px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#000000",
                              opacity: "1",
                              cursor: "pointer",
                              textDecoration: "none", // Initial state: no underline
                            }}
                            // Apply underline on hover
                            onMouseEnter={(e) => {
                              e.currentTarget.style.textDecoration = "none";
                            }}
                            // Remove underline when not hovering
                            onMouseLeave={(e) => {
                              e.currentTarget.style.textDecoration = "none";
                            }}
                          >
                            {videoparadata?.filename}
                          </Paragraph>
                        </Col>
                        <Col span={24}>
                          <div>
                            <Row>
                              <img src={PROJECT_ICONS.COPY} style={{ paddingRight: "10px", cursor: "pointer", height: "14px" }} />
                              <img src={PROJECT_ICONS.SHARE_ICON} style={{ paddingRight: "10px", height: "14px" }} />

                              <img src={PROJECT_ICONS.DOWNLOAD_ICON} style={{ paddingRight: "10px", cursor: "pointer", height: "14px" }} />

                              <img src={PROJECT_ICONS.THUMBS_UP} style={{ paddingRight: "10px", cursor: "pointer", height: "14px" }}></img>

                              <img src={PROJECT_ICONS.THUMBS_DOWN} style={{ cursor: "pointer", height: "14px" }}></img>
                            </Row>
                          </div>
                        </Col>
                        <Row style={{ paddingTop: "20px" }} gutter={48}>
                          <div
                            style={{
                              background: "#E5F1F8",
                              padding: "10px",
                              marginLeft: "20px",
                              borderRadius: "10px",
                              height: "380px",
                              width: "410px",
                            }}
                          >
                            <video
                              src={videoparadata?.s3_url}
                              style={{
                                width: "100%", // Ensure the image maintains its aspect ratio
                                height: "360px", // Ensure the image maintains its aspect ratio
                                borderRadius: "5px", // Optional: Add border radius to the image ,
                              }}
                              controls
                            />
                          </div>

                          <div className="image-description">
                          {videoparadata?.items?.map((data: any) => (
                              <div
                                key={data.id}
                                style={{
                                  padding: "20px",
                                  paddingLeft: "25px",
                                  background: "rgb(237, 244, 255)",
                                  marginBottom: "20px",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 10px #0000001F",display:'flex',
                                  flexDirection:'column'
                                }}
                              >
                                <Markdown>{data?.description}</Markdown>
                                
                              </div>
                            ))}
                          </div>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Card>
    </>
  );
};
