import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip, Switch, Tag } from "antd";
import { useSelector } from "react-redux";
import _localStorageService from "../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { useDispatch } from "react-redux";
import { setLoadingStatusAction } from "../../../../Store/actions/_commonActions";
import { setEncodedSemanticSearchId, setProModeStatus } from "../../../../Store/actions/_semanticSearchActions";
interface ComponentAProps {
  tagsForProMode: string[];
  setTagsForProMode: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CopilotMode: React.FC<ComponentAProps> = ({ setTagsForProMode, tagsForProMode }) => {
  const { searchQuery, semanticEncodedSearchid } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const [isloading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [inputValue, setisInputvalue] = useState("");

  // Function to handle tag selection
  const handleTagSelect = (tag: string) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        return prevSelectedTags.filter((selectedTag) => selectedTag !== tag);
      } else {
        return [...prevSelectedTags, tag];
      }
    });
  };
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setisInputvalue(event.target.value); // Remove leading and trailing whitespace
  };

  const handleCallingSend = async () => {
    const newTags = inputValue.split(",").filter((tag) => tag.trim() !== "");

    // Concatenate newTags with selectedTags
    const updatedTags = [...selectedTags, ...newTags];

    // Set the updated tags array to tagsForProMode state
    setTagsForProMode(updatedTags);
    dispatch(setLoadingStatusAction(true));
    dispatch(setProModeStatus(false));
    if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };

  // Update input value only if search is not in progress
  const handleCallingSkip = async () => {
    setTagsForProMode([]);

    dispatch(setLoadingStatusAction(true));
    dispatch(setProModeStatus(false));
    if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = token || "";
      const payload = {
        question: searchQuery,
      };
      const apiUrl = `${apiurl}/document/conversation/generate-tags`;
      const headers = {
        Authorization: accessToken,
        "Content-Type": "application/json",
      };

      const handleErrorEvent = (error: any) => {
        setIsLoading(false);

        console.error("SSE Error:", error);
        // Enable input on SSE error
      };
      const sse = new SSE(apiUrl, {
        headers,
        payload: JSON.stringify(payload),
      });
      sse.addEventListener("end", (event: any) => {
        console.log("SSE message:", event);
        if (event.data) {
          const keywords = event.data.split(",").map((keyword: any) => keyword.trim());
          console.log("Keywords:", keywords);
          setTags(keywords);
          setIsLoading(false);
          // Now you can use 'keywords' array for further processing
        }
      });

      sse.addEventListener("error", handleErrorEvent);
    };
    setIsLoading(true);
    fetchData(); // Call the function to fetch data on component mount
    return () => {
      console.log("Unmount");
    };
  }, []); // Empt

  return (
    <>
      {isloading ? (
        <>
          <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "250px" }}>
            <Spin />
          </Col>
        </>
      ) : (
        <>
          <Col span={16} style={{ paddingLeft: "20px" }}>
            <Card style={{ width: "100%", opacity: "1", borderRadius: "25px", height: "592px" }}>
              <Col span={24}>
                <p
                  style={{
                    color: "#66676B",
                    padding: "0px",
                    fontSize: "22px",
                    fontWeight: "400",
                  }}
                >
                  {searchQuery}
                </p>
              </Col>
              <Col span={24} style={{ marginTop: "15px", paddingLeft: "25px" }}>
                <div style={{ background: "#F4F6F9", padding: "24px", borderRadius: "10px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>Pro Mode</p>
                  <p style={{ color: "#66676B", fontSize: "16px", fontWeight: "600", paddingTop: "5px" }}>What aspects would you like to know</p>
                  <div style={{ display: "flex", paddingTop: "10px", overflowX: "auto" }}>
                    {tags.map((item: any, index: any) => (
                      <Tag
                        key={index}
                        style={{
                          padding: "5px",
                          color: selectedTags.includes(item) ? "#FFFFFF" : "#034EA2",
                          background: selectedTags.includes(item) ? "#034EA2" : "#FFFFFF",
                          border: "1px #034EA2 solid",
                          borderRadius: "24px",
                          fontSize: "12px",
                          fontWeight: "400",
                          marginRight: "10px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleTagSelect(item)}
                      >
                        {item.slice(0, 30)}
                      </Tag>
                    ))}
                  </div>
                  <p style={{ color: "#66676B", fontSize: "14px", fontWeight: "400", marginTop: "15px" }}>
                    If other, please input the aspects you want to know
                  </p>
                  <Input
                    style={{ marginTop: "15px", borderRadius: "none" }}
                    onChange={handleInput}
                    placeholder="Please provide input for other aspects , if any"
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{
                        marginTop: "20px",
                        background: selectedTags.length > 0 ? "#034EA2" : "#E6E7E8", // Set background color based on selectedTags
                        color: selectedTags.length > 0 ? "#FFFFFF" : "#66676B", // Set text color based on selectedTags
                      }}
                      onClick={selectedTags.length > 0 ? handleCallingSend : undefined} // Conditionally enable onClick based on selectedTags
                    >
                      SEND
                    </Button>
                  </div>
                </div>
              </Col>
              <Col span={24} style={{ marginTop: "15px", paddingLeft: "25px" }}>
                <div style={{ background: "#F4F6F9", padding: "24px", borderRadius: "10px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#66676B" }}>Skip questions and read the answer</p>
                    <Button
                      style={{ color: "#034EA2", background: "#FFFFFF", border: "1px #034EA2 solid", marginLeft: "3px" }}
                      onClick={handleCallingSkip}
                    >
                      SKIP
                    </Button>
                  </div>
                </div>
              </Col>
            </Card>
          </Col>

          <Col span={8} style={{ paddingLeft: "20px" }}>
            <Card
              style={{
                width: "100%",
                height: "592px",
                opacity: "1",
                overflowY: "scroll",
                borderRadius: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <p style={{ color: "#66676B" }}>Complete the prompt to view the details</p>
              </div>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};
