import React from "react";
import { Layout } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { Link } from "react-router-dom";
import { settoggleSidebarCollapse } from "../../Store/actions/_commonActions"; // Import the action
import { SideBarMenu } from "../../Components/Menu/SideBarMenu";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";

const { Sider } = Layout;

const SideBar: React.FC = () => {
  const { i18n, t } = useTranslation();

  const { isDarkMode, isCollapsed } = useSelector((state: any) => state?.commonReducer);

  return (
    <Sider
      breakpoint="xl"
      defaultCollapsed
      collapsedWidth="50px"
      collapsed={isCollapsed}
      onBreakpoint={(broken) => {
        //dispatch(settoggleSidebarCollapse(broken)); //Responsive Slider. Resets on reload as broken is true which sets the isCollapsed.
      }}
      trigger={null}
      theme={isDarkMode ? "dark" : "light"}
      style={{ background: "#0D308E" }}
    >
      <div style={{ height: "64px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Link to={ENUM_ROUTE_URLS.DASHBOARD} className="logo" style={{ color: "white", display: "flex", alignItems: "center", cursor: "pointer" }}>
          <div style={{ fontSize: "2em", fontWeight: 600, display: "flex", justifyContent: "center", alignItems: "center", gap: "2px" }}>
            <img src={PROJECT_ICONS.SIDEBAR_GROUP_7532} alt="logo" style={!isCollapsed ? { height: "20px" } : undefined} />
            {!isCollapsed && t(I18N_MODULE_KEYS["app.logo"])}
          </div>
        </Link>
      </div>
      <SideBarMenu />
    </Sider>
  );
};

export default SideBar;
