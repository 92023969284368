import { Skeleton } from "antd";
import { ContentAITag, ContentAITags } from "../../../Core/types";
import { Fragment, useState } from "react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import LabelCheckBox from "../../Common/LabelCheckBox";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const CUUploadLeftPane = ({
  loadingTags,
  allTags,
  setAllTags,
  getAllTags
}: {
  loadingTags: boolean;
  allTags: ContentAITags;
  setAllTags: Function;
  getAllTags: Function;
}) => {
  const { t } = useTranslation();

  const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({});

  const handleTagSelection = (checked: boolean, tag: ContentAITag) => {
    tag.selected = checked;
    setAllTags({ ...allTags });
  };

  const getSelectedTagsCount = (categroy: string) => {
    let count = 0;
    allTags[categroy].forEach((tag) => {
      tag.selected && count++;
    });
    return (count.toString().length === 1 ? "0" : "") + count;
  };

  const resetTagsSelection = () => {
    getAllTags();
  };

  return (
    <div className="col-3 p-0">
      <div className="cu-upload-left-pane">
        {/* <div className="step">STEP 1</div> */}
        <div className="step">{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.step1"])}</div>
        {/* <div className="title">Choose Details</div> */}
        <div className="title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.choosedetails"])}</div>

        <div className="tags-block">
          {loadingTags ? (
            <div style={{ padding: "0 15px" }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <div className="tags">
                {Object.keys(allTags).map((category, key, arr) => {
                  return (
                    <Fragment key={key}>
                      <div
                        className="list brand-heading"
                        onClick={() => {
                          setCollapse((prevState) => ({ ...prevState, [category]: !prevState[category] }));
                        }}
                      >
                        <img
                          src={collapse[category] ? PROJECT_ICONS.C_AI_DOWN_ARROW : PROJECT_ICONS.C_AI_UP_ARROW}
                          style={{ width: "16px", height: "20px" }}
                        />
                        <span style={{ textTransform: "capitalize" }}>
                          {category.includes("mlr") ? "MLR Approved" : category.split("_").join(" ")}
                        </span>
                        ({getSelectedTagsCount(category)})
                      </div>
                      {!collapse[category] && (
                        <div>
                          <div className="brandList">
                            {allTags[category].map((tag, key) => (
                              <LabelCheckBox
                                key={key}
                                label={tag.name}
                                labelStyle={{ textTransform: "capitalize", margin: "10px 0" }}
                                className="brand"
                                name="brand"
                                value={tag.name}
                                checked={tag.selected || tag.is_default ? true : false}
                                onChange={(event) => {
                                  handleTagSelection(event.target.checked, tag);
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                      {key !== arr.length - 1 ? <hr /> : null}
                    </Fragment>
                  );
                })}
              </div>
              <div className="text-right">
                <span className="closeBtn" onClick={resetTagsSelection}>
                  {/* Reset */}
                  {t(I18N_MODULE_KEYS["content.button.reset"])}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
