import { FC, useEffect, useRef, useState } from "react";
import { PhonemetableType, SlideDataType, VoiceListGenderType, VoiceListType } from "../core/modals";
import { editStoryBoardRequest, getElaiDurationRequest, saveSelectedVoiceRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { useSelector } from "react-redux";
import { Button, Col, Menu, Row, Select, Space, Typography, Modal, Table, Pagination, Spin, Image, Avatar, Tooltip } from "antd";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { getItem } from "../../Content History/core/constants";
import { MenuItem } from "../../Content History/core/modals";
import { handleCancelApiRequest, useOutsideAlerter } from "../../../../Utils";
import TextArea from "antd/es/input/TextArea";
import BreakParser from "../core/breakparser";
import { getELaiResponse, initialVoiceListGenderType } from "../core/constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setElaiTranslationDataAction, setPhonemeListAction, setSaveElaiObject } from "../../../../Store/actions/_contentTranscreationActions";
import { PlusOutlined } from "@ant-design/icons";
import { PhonemeActions, PhonemeInput, columns } from "./phoneme";
import { setLanguageAction, setNarrationAction, setSelectedVoiceAction, setStopAction } from "../../../../Components/Player";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { VoiceListModal } from "./VoiceListModal";

const enumTimerIcon = {
  ["0.25"]: "🕛" || "\ud83d" || "\udd5d",
  ["0.5"]: "🕐" || "\ud83d" || "\udd50",
  ["1"]: "🕑" || "\ud83d" || "\udd51",
  ["1.5"]: "🕝" || "\ud83d" || "\udd5d"
};

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  fontWeight: 400,
  backgroundColor: "#80808026",
  border: "1px solid transparent",
  cursor: "pointer",
  transition: "all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
  touchAction: "manipulation",
  lineHeight: "1.5714285714285714",
  color: "rgba(0, 0, 0, 0.88)"
};

const { Text } = Typography;

const timerIcon = PROJECT_ICONS.CT_TIMER;

const MenuItems: MenuItem[] = [
  getItem(`${enumTimerIcon["0.25"]} 0.25s`, "0.25s", <></>),
  getItem(`${enumTimerIcon["0.5"]} 0.5s`, "0.5s", <></>),
  getItem(`${enumTimerIcon[1]} 1s`, "1s", <></>),
  getItem(`${enumTimerIcon["1.5"]} 1.5s`, "1.5s", <></>)
];

const initialPhonemeList: PhonemetableType = {
  id: 1,
  word: "",
  pronunciation: ""
};

export const NarrationArea: FC<{ slide: SlideDataType; story: any[]; activeSlide: number; setAudioList: Function; globalPlayer: any }> = ({
  slide,
  story,
  activeSlide,
  setAudioList,
  globalPlayer
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const menuRef: any = useRef(null);
  const textAreaRef: any = useRef(null);

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  const { voiceList, selectedVoice } = useSelector((state: any) => state?.playerReducer);
  const { elaiResponse, phonemeList } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [loading, setLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [narrationLocal, setNarrationLocal] = useState<string>("");
  const [initiateDuration, setInitiateDuration] = useState<boolean>(false);
  const [voiceModalVisible, setVoiceModalVisible] = useState<boolean>(false);
  const [selectedVoiceItem, setSelectedVoiceItem] = useState<VoiceListGenderType>({ ...initialVoiceListGenderType });

  const isMounted = useRef(true);

  useOutsideAlerter(menuRef, () => setShowMenu(false));

  useEffect(() => {
    dispatch(setLanguageAction(elaiResponse?.locale));
  }, []);

  useEffect(() => {
    return () => {
      console.log("unmounting NarrationArea");
      console.log("value", isMounted.current);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (slide?.speech) {
      setNarrationLocal(slide?.speech);

      dispatch(setNarrationAction(getParsedArrayForTTS(slide?.speech)));
    }
  }, [slide, activeSlide]);

  // useEffect(() => {
  //   handleCancelApiRequest();
  //   if (initiateDuration === true) {
  //   }
  // }, [activeSlide]);

  useEffect(() => {
    if (narrationLocal && initiateDuration === false && !globalPlayer.playing) {
      setInitiateDuration(true);

      handleEditNarration(narrationLocal, true, selectedVoice);
    }
  }, [narrationLocal]);

  useEffect(() => {
    if (voiceList?.length && slide?.voice && slide?.language && slide?.avatar?.gender) {
      handleSelectedVoiceName();
    }
  }, [voiceList, slide, elaiResponse, slide?.voice]);

  const handleSelectedVoiceName = () => {
    const objFilteredVoiceList: VoiceListType = { ...voiceList?.find((item: VoiceListType) => item?.name === slide?.language) };

    //for voices like 'zh-CN-YunxiaNeural:calm', only the first part is needed
    let slideVoice = slide.voice?.split(":")?.[0];

    if (slide?.avatar?.gender) {
      //@ts-ignore
      const objSelectedVoice: VoiceListGenderType = objFilteredVoiceList[slide?.avatar?.gender]?.find?.(
        (item: VoiceListGenderType) => item?.voice === slideVoice
      );

      if (objSelectedVoice) {
        setSelectedVoiceItem(objSelectedVoice);
        // setSelectedVoiceName(`${objSelectedVoice?.character} (${slide?.language} - ${objSelectedVoice?.icon?.toLocaleUpperCase?.()})`);
      } else {
        let voiceItem = objFilteredVoiceList[slide?.avatar?.gender as keyof typeof objFilteredVoiceList][0];
        setSelectedVoiceItem(voiceItem as VoiceListGenderType);
      }
    } else {
      setSelectedVoiceItem({ ...initialVoiceListGenderType });
    }
  };

  const handleButtonClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);

    dispatch(setNarrationAction(getParsedArrayForTTS(getNarrationLocalParsed(narrationLocal))));
  };

  const getNarrationLocalParsed = (value: string) => {
    if (value?.includes(enumTimerIcon["0.25"])) {
      value = value?.replaceAll(enumTimerIcon["0.25"], `<break time='0.25s' />`);
    } else if (value?.includes(enumTimerIcon["0.5"])) {
      value = value?.replaceAll(enumTimerIcon["0.5"], `<break time='0.5s' />`);
    } else if (value?.includes(enumTimerIcon[1])) {
      value = value?.replaceAll(enumTimerIcon["1"], `<break time='1s' />`);
    } else if (value?.includes(enumTimerIcon["1.5"])) {
      value = value?.replaceAll(enumTimerIcon["1.5"], `<break time='1.5s' />`);
    }

    if (value?.includes("\uD83D")) {
      value = getNarrationLocalParsed(value);
    }

    return value;
  };

  const handleEditNarration = async (narrationLocal: string, setDurationOnly: boolean, selectedVoice: string, onBlur?: boolean) => {
    let value = getNarrationLocalParsed(narrationLocal);

    setNarrationLocal(value);

    let arrParsedValues = getParsedArrayForTTS(value);

    dispatch(setNarrationAction(arrParsedValues));

    //setLoading(true);

    if (!setDurationOnly) {
      await editStoryBoardRequest({
        storyboard_id: elaiResponse?.storyboard_id,
        stories: [{ ...story, narration: value }],
        is_translate: true
      });

      //Canvas Update Call
      const updatedElaiResponse = JSON.parse(JSON.stringify(elaiResponse));
      const slide = updatedElaiResponse.slides[activeSlide];
      slide.speech = value;
      dispatch(setSaveElaiObject(updatedElaiResponse));
    }

    const arrSlides = [...elaiResponse?.slides];

    const res = await getElaiDurationRequest({
      text: arrParsedValues?.filter((item) => typeof item === "string")?.join?.(""),
      // voice: selectedVoice ? selectedVoice : elaiResponse?.locale
      voice: selectedVoice ? selectedVoice : slide?.voice
      // voice: "zh-CN-shaanxi"
    });

    //setLoading(false);

    if (!isMounted.current) return;

    if (!res) return;

    let arrTimes: number[] = arrParsedValues?.filter?.((item) => typeof item === "number");

    let nTotalTime: number = 0;

    if (arrTimes?.length) {
      nTotalTime = arrTimes?.reduce?.((a, b) => a + b) + res?.approxDuration;
    } else {
      nTotalTime = res?.approxDuration;
    }

    console.log(nTotalTime, "totaltime");

    arrSlides[activeSlide] = {
      ...arrSlides[activeSlide],
      speech: value,
      // approxDuration: res?.approxDuration ? nTotalTime : arrSlides[activeSlide]?.approxDuration
      duration: res?.approxDuration ? nTotalTime : arrSlides[activeSlide]?.approxDuration
    };

    dispatch(setElaiTranslationDataAction({ ...elaiResponse, slides: [...arrSlides] }));

    onBlur &&
      setAudioList((prevAL: any) => {
        let newAL = [...prevAL];
        delete newAL[activeSlide];
        return newAL;
      });
  };

  const getParsedArrayForTTS = (input: string) => {
    const parser = new BreakParser(input);
    const segments = parser.parse();

    if (!segments?.length) {
      return [input];
    }

    const result: any[] = segments?.map((item: string | number) => {
      if (typeof item === "number") return item;

      if (item?.includes(">")) {
        item = item?.replace(">", " ");
      }

      return item;
    });

    return result || [];
  };

  const getParsedTextForNarration = (narration: string) => {
    const arrParsedData: any[] | string = getParsedArrayForTTS(narration);

    let arrNew = arrParsedData?.map((item) => {
      if (typeof item === "number") {
        if (item === 0.25) return enumTimerIcon["0.25"];
        if (item === 0.5) return enumTimerIcon["0.5"];
        if (item === 1) return enumTimerIcon["1"];
        if (item === 1.5) return enumTimerIcon["1.5"];
      }

      return item;
    });

    return arrNew?.join("");
  };

  const handleOnClickMenu = (option: any) => {
    let strNarration = getParsedTextForNarration(narrationLocal);

    strNarration += `<break time='${option?.key}' />`;

    // if (strNarration[cursorPosition]) {
    //   strNarration = strNarration?.slice(0, cursorPosition) + `<break time='${option?.key}' />` + strNarration?.slice(cursorPosition);
    // } else {
    //   strNarration += `<break time='${option?.key}' />`;
    // }

    setNarrationLocal(strNarration);

    setShowMenu(false);

    textAreaRef?.current?.focus();
  };

  const handleOnChangeNarration = (e: any) => {
    let value = e?.target?.value;
    setNarrationLocal(value);
  };

  const handleOnChangeSelect = async (option: string) => {
    return;
    // const lang = voiceList?.find((item: any) => item?.value === option)?.lang;

    // dispatch(setSelectedVoiceAction(option));

    // await saveSelectedVoiceRequest({
    //   video_id: elaiResponse?.video_id,
    //   lang: lang
    // });

    // getELaiResponse(elaiResponse, elaiResponse?.video_id, elaiResponse?.storyboard_id, elaiResponse?.locale, dispatch, navigate, false);

    // handleEditNarration(narrationLocal, true, selectedVoice);`
  };

  const handleAddPhoneme = () => {
    const arrPhonemeList: PhonemetableType[] = [...phonemeList];

    arrPhonemeList?.push({ ...initialPhonemeList, id: Math.floor(Math.random() * Date.now()) });

    dispatch(setPhonemeListAction([...arrPhonemeList]));
  };

  // Calculate pagination
  const pageSize = 3; // Number of items per page

  // Paginated data
  const paginatedData = phonemeList?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns: ColumnsType<any> = [
    {
      title: <span style={{ fontWeight: "800", fontSize: "14px" }}>{t(I18N_MODULE_KEYS["content.text.word"])}</span>,
      dataIndex: "word",
      key: "word",
      render: (_, record) => <PhonemeInput record={record} type="word" />
    },
    {
      title: <span style={{ fontWeight: "800", fontSize: "14px" }}>{t(I18N_MODULE_KEYS["content.text.pronunciation"])}</span>,
      dataIndex: "pronunciation",
      key: "pronunciation",
      render: (_, record) => <PhonemeInput record={record} type="pronunciation" />
    },
    {
      title: <span style={{ fontWeight: "800", fontSize: "14px" }}>{t(I18N_MODULE_KEYS["content.text.action"])}</span>,
      key: "action",
      render: (_, record) => <PhonemeActions record={record} />
    }
  ];

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span style={{ fontWeight: "bold" }}>{t(I18N_MODULE_KEYS["content.text.speechtext"])} ~</span>
            {loading ? (
              <Spin style={{ marginLeft: "7px" }} size="small" />
            ) : (
              <>
                {slide.voiceType === "text" &&
                  (slide.duration && !isNaN(slide.duration) ? (
                    <span style={{ fontWeight: "bold" }}>{slide.duration.toFixed(1)}s </span>
                  ) : slide.approxDuration ? (
                    <span style={{ fontWeight: "bold" }}>{slide?.approxDuration.toFixed(1)}s </span>
                  ) : null)}
              </>
            )}
          </div>
        </Col>
        {selectedVoiceItem?.id && (
          <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setVoiceModalVisible(!voiceModalVisible)} style={{ width: "40%" }}>
              {isLoading ? (
                <Spin size="small" />
              ) : (
                <Space>
                  <Avatar
                    src={`https://d3u63mhbhkevz8.cloudfront.net/flags/${selectedVoiceItem?.icon?.toLocaleLowerCase?.()}.svg`}
                    style={{ width: "24px", height: "24px" }}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                      fontWeight: "750",
                      lineHeight: "1.2rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    <Tooltip title={`${selectedVoiceItem?.character} (${slide?.language} - ${selectedVoiceItem?.icon?.toLocaleUpperCase?.()})` || ""}>
                      {`${selectedVoiceItem?.character} (${slide?.language} - ${selectedVoiceItem?.icon?.toLocaleUpperCase?.()})`?.length < 30
                        ? `${selectedVoiceItem?.character} (${slide?.language} - ${selectedVoiceItem?.icon?.toLocaleUpperCase?.()})`?.slice(0, 13) +
                          "..."
                        : ""}
                    </Tooltip>
                  </Text>
                </Space>
              )}
            </Button>
          </Col>
        )}
        <Col span={24}>
          <div className="narration-area">
            <TextArea
              ref={textAreaRef}
              value={getParsedTextForNarration(narrationLocal)}
              onChange={(e) => handleOnChangeNarration(e)}
              className="narration-textarea"
              onBlur={() => handleEditNarration(narrationLocal, false, selectedVoice, true)}
              autoSize={{ maxRows: 6 }}
              onKeyDown={(e: any) => setCursorPosition(e?.target?.selectionStart)}
              onFocus={() => dispatch(setStopAction(true))}
              onMouseDownCapture={(e: any) => setCursorPosition(e?.target?.selectionStart)}
              disabled={isLoading || loading}
            />
          </div>
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Space className="narration-button-group">
            <Button
              icon={<img src={PROJECT_ICONS.CT_HEADPHONES} style={{ marginRight: "5px", height: "17px" }} />}
              onClick={handleButtonClick}
              style={{ ...buttonStyle }}
            >
              <Text style={{ opacity: 1, fontSize: "14px", margin: "auto" }}>{t(I18N_MODULE_KEYS["content.text.phoneme"])}</Text>
            </Button>
            <div style={{ position: "relative" }} ref={menuRef}>
              {showMenu && !isLoading && !loading && (
                <Menu
                  style={{
                    position: "absolute",
                    bottom: "35px",
                    zIndex: 2,
                    width: "104px",
                    borderRadius: "4px",
                    boxShadow: "0px 0px 10px #0000001A",
                    background: "#FFFFFF 0% 0% no-repeat padding-box"
                  }}
                  disabled={isLoading || loading}
                  onClick={(option) => {
                    handleOnClickMenu(option);
                  }}
                  items={MenuItems}
                />
              )}
              <Button
                icon={<Image src={timerIcon} style={{ marginRight: "5px", height: "17px" }} preview={false} />}
                style={{ ...buttonStyle }}
                onClick={() => setShowMenu(!showMenu)}
                disabled={isLoading || loading}
              >
                <Text style={{ opacity: 1, fontSize: "14px", margin: "auto" }} disabled={isLoading || loading}>
                  {t(I18N_MODULE_KEYS["content.text.pause"])}
                </Text>
              </Button>
            </div>
          </Space>
        </Col>
      </Row>
      <Modal
        onCancel={handleModalClose}
        visible={modalVisible}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "15px", fontSize: "15px" }}>
            <Button type="primary" key="save" style={{ background: "#4868ff", fontSize: "15px", borderRadius: "10px" }}>
              {/* Update */}
              {t(I18N_MODULE_KEYS["content.button.update"])}
            </Button>
          </div>
        )}
        style={{ borderRadius: "30px" }}
      >
        <div style={{ borderRadius: "10px", overflow: "scroll", maxHeight: "50%" }}>
          <div style={{ padding: "15px" }}>
            <p style={{ fontSize: "24px", fontWeight: "800" }}> {t(I18N_MODULE_KEYS["content.text.phonemedictionary"])}</p>
            <p className="subheader" style={{ fontSize: "15px", paddingTop: "7px", paddingRight: "10px", fontWeight: "400" }}>
              {t(I18N_MODULE_KEYS["content.text.phonemedescription"])}
            </p>
            <Button
              type="primary"
              className="btn-add"
              style={{ marginTop: "20px", background: "#4868ff", fontSize: "14px", borderRadius: "7px" }}
              onClick={handleAddPhoneme}
            >
              <PlusOutlined />
              {t(I18N_MODULE_KEYS["content.text.addphoneme"])}
            </Button>
            <Table
              dataSource={paginatedData}
              columns={columns}
              style={{ marginTop: "20px", maxHeight: "33%", clear: "both", maxWidth: "100%", minHeight: "221px" }}
              pagination={false}
            />
            {phonemeList?.length >= 3 && (
              <Space style={{ marginTop: "5px", display: "flex", justifyContent: "end" }}>
                <Pagination
                  showSizeChanger={false}
                  defaultCurrent={1}
                  pageSize={pageSize}
                  total={phonemeList?.length}
                  onChange={(current) => handleChange(current)}
                />
              </Space>
            )}
          </div>
        </div>
      </Modal>

      {voiceModalVisible && (
        <VoiceListModal
          showModal={voiceModalVisible}
          closeModal={() => setVoiceModalVisible(false)}
          narration={narrationLocal}
          slide={slide}
          selectedVoiceItem={selectedVoiceItem}
          activeSlide={activeSlide}
          setAudioList={setAudioList}
        />
      )}
    </>
  );
};
