import { Card, Spin } from "antd";
import { FC } from "react";
import { SlideThumbnail } from "../core/modals";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setElaiTranslationDataAction } from "../../../../Store/actions/_contentTranscreationActions";
import { setNarrationAction, setStopAction } from "../../../../Components/Player";
import { LoadingOutlined } from "@ant-design/icons";

const styles = {
  wrapper: { position: "relative", transform: "translate(0px, 0px)" },
  container: { width: "180px", height: "101px" }
};

export const SlidesList: FC<{ activeSlide?: number; setActiveSlide: Function; thumbnails: SlideThumbnail[] }> = ({
  thumbnails,
  activeSlide,
  setActiveSlide
}) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);
  const { elaiResponse } = useSelector((state: any) => state?.contentTranscreationReducer);

  const handleSlideClick = (activeSlide: number) => {
    dispatch(setElaiTranslationDataAction({ ...elaiResponse, activeSlide: activeSlide }));

    if (activeSlide === elaiResponse?.activeSlide) return;

    dispatch(setStopAction(true));

    setTimeout(() => {
      dispatch(setNarrationAction(elaiResponse?.slides[activeSlide]?.speech));
    }, 100);
  };

  const handleRef = (ref: HTMLDivElement | null, thumbnail: any) => {
    if (!ref) return;

    if (thumbnail.imgSrc) {
      const element = thumbnail.imgSrc;
      ref.innerHTML = ``;
      element && (element.style.width = "100%");
      ref.appendChild(element);
    } else {
      const canvas = ref.querySelector("canvas");
      if (canvas) ref.removeChild(canvas);
    }
  };

  return (
    <div>
      {thumbnails?.map?.((thumbnail: SlideThumbnail, index: number) => (
        <div
          key={index || "thumbnail-" + index}
          style={{ display: "flex", padding: "8px", gap: "8px", userSelect: "none" }}
          // onClick={() => handleSlideClick(index)}
        >
          <div style={{ alignSelf: "center" }}>{index + 1}.</div>

          {thumbnail && thumbnail?.imgSrc ? (
            <Card
              bodyStyle={{
                padding: 0,
                display: "flex",
                overflow: "hidden",
                borderRadius: "8px",
                border: "2px solid " + ((activeSlide && index === activeSlide) || (!activeSlide && index === 0) ? "blue" : "#d9d9d9")
              }}
              style={{ height: "100%", border: "none", cursor: "pointer" }}
              onClick={() => setActiveSlide(index)}
            >
              {typeof thumbnail.imgSrc === "string" ? (
                <img src={thumbnail?.imgSrc} width={"100%"} />
              ) : (
                <div ref={(ref) => handleRef(ref, thumbnail)}></div>
              )}
            </Card>
          ) : (
            <Card
              style={{ border: "none", cursor: "pointer", height: "108px", width: "100%" }}
              bodyStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                padding: 0,
                overflow: "hidden",
                borderRadius: "8px",
                border: "2px solid #d9d9d9"
              }}
            >
              <Spin />
            </Card>
          )}
        </div>
      ))}
    </div>
  );
};
