import { SET_LOGIN_DETAILS, SET_SESSION_EXPIRED_FLAG, SET_USER_DETAILS } from "../constants/_authConstants";

export const setLoginDetailsAction = (data: any) => {
  return { type: SET_LOGIN_DETAILS, payload: data };
};

export const setUserDetailsAction = (data: any) => {
  return { type: SET_USER_DETAILS, payload: data };
};

export const setSessionExpiredFlagAction = (data: boolean) => {
  return { type: SET_SESSION_EXPIRED_FLAG, payload: data };
};
