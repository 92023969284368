export const SET_ACTIVE_STEPPER = "contentTranscreation/SET_ACTIVE_STEPPER";
export const SET_NEXT_STEPPER_STATE = "contentTranscreation/SET_NEXT_STEPPER_STATE";
export const SET_PREVIOUS_STEPPER_STATE = "contentTranscreation/SET_PREVIOUS_STEPPER_STATE";
export const SET_BUTTON_TEXT_STATE = "contentTranscreation/SET_BUTTON_TEXT_STATE";
export const SET_NEXT_BUTTON_DISABLE = "contentTranscreation/SET_NEXT_BUTTON_DISABLE";

export const SET_RESPONSE_CT = "contentTranscreation/SET_RESPONSE_CT";
export const SET_REFETCH_CONTENT_HISTORY_LIST = "contentTranscreation/SET_REFETCH_CONTENT_HISTORY_LIST";
export const SET_CT_SOURCE = "contentTranscreation/SET_CT_SOURCE";
export const SET_CT_FINAL_PRODUCT = "contentTranscreation/SET_CT_FINAL_PRODUCT";

export const SET_FOOTER_BUTTONS_CONFIG = "contentTranscreation/SET_FOOTER_BUTTONS_CONFIG";
export const SET_REGENERATE_STATE = "contentTranscreation/SET_REGENERATE_STATE";
export const SET_REFETCH_LIST = "contentTranscreation/SET_REFETCH_LIST";

export const SET_PLATFORM_LIST = "contentTranscreation/SET_PLATFORM_LIST";

//content history
export const SET_SELECTED_FILTERS = "contentHistory/SET_SELECTED_FILTERS";
export const SET_SHOW_MORE_LIST = "contentHistory/SET_SHOW_MORE_LIST";
export const SET_SELECTED_EMAIL_DOC = "contentHistory/SET_SELECTED_EMAIL_DOC";
export const SET_FILTER_APPLIED_STATUS = "contentHistory/SET_FILTER_APPLIED_STATUS";

//content translation
export const SET_ELAI_RESPONSE_DATA = "contentTranslation/SET_ELAI_RESPONSE_DATA";
export const SET_CTL_ACTIVE_SLIDE = "contentTranslation/SET_CTL_ACTIVE_SLIDE";
export const SET_PHONEME_LIST = "contentTranslation/SET_PHONEME_LIST";

//Canvas Edit
export const SET_CTL_SIDEBAR_OPEN = "contentTranslation/SET_CTL_SIDEBAR_OPEN";
export const SET_CTL_CANVAS_ACTIVE_OBJECT = "contentTranslation/SET_CTL_CANVAS_ACTIVE_OBJECT";
export const SET_CTL_ACTIVE_OBJECT_MODIFIER = "contentTranslation/SET_CTL_ACTIVE_OBJECT_MODIFIER";
export const SET_CTL_SAVE_ELAI_OBJECT = "contentTranslation/SET_CTL_SAVE_ELAI_OBJECT";
export const SET_CTL_SLIDE_PLAYING = "contentTranslation/SET_CTL_SLIDES_PLAYING";
