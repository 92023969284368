import { Spin, Typography } from "antd";
import { useEffect, useState } from "react";

const { Text } = Typography;

export const Overlay = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const onscroll = () => {
      setScrollY(window.scrollY);
    };

    document.addEventListener("scroll", onscroll);

    return () => {
      document.removeEventListener("scroll", onscroll);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        backgroundColor: "rgba(255,255,255,0.5)"
      }}
      onClick={() => {}}
    >
      <div
        style={{
          position: "sticky",
          top: `calc(50vh + ${scrollY}px)`,
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spin />
        <Text>Fetching...</Text>
      </div>
    </div>
  );
};
