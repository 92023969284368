import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../../Store/actions/_contentTranscreationActions";
import { debounce } from "../../../fabric-files/utils/helpers";

export const useTextControlsState = () => {
  const { canvasActiveObject } = useSelector((state: RootState) => state.contentTranscreationReducer);

  const dispatch = useDispatch();

  const [manualFontSize, setManualFontSize] = useState(false);

  const activeObjectProps = canvasActiveObject?.type === "activeSelection" ? canvasActiveObject.getObjects?.()[0] : canvasActiveObject;

  const getTextSelectionStyle = (activeObject: any, style: any) => {
    if (!activeObject) return;
    const selectionStyles = activeObject?.selectionStyles;
    if (
      activeObject &&
      selectionStyles?.length &&
      selectionStyles[0][style] &&
      selectionStyles.every((char: any) => char[style] === selectionStyles[0][style])
    )
      return selectionStyles[0][style];
    return activeObject[style];
  };

  const getTextSelectionFontFamily = (activeObject: any) => {
    const fontFamily = getTextSelectionStyle(activeObject, "fontFamily");
    return fontFamily?.split(",")[0];
  };

  const handleColorChange = (color: any, hex: any) => {
    //console.log("color:", color, hex);
    dispatch(setActiveObjectModifier({ change: "fill", value: hex ? hex : "#fffffff" }));
  };

  const colorEditButton = getTextSelectionStyle(activeObjectProps, "fill");
  //console.log({ colorEditButton });

  const onFontSizeChange = (v: any) => {
    setManualFontSize(v);
    debounceFontSizeChange(v);
  };

  const saveFontSize = (v: any) => {
    if (v) {
      const value = v > 300 ? 300 : v < 3 ? 3 : v;
      dispatch(setActiveObjectModifier({ change: "fontSize", value }));
      setManualFontSize(false);
    }
  };

  const debounceFontSizeChange = useCallback(
    debounce((v: any) => saveFontSize(v), 800),
    []
  );

  const onBlurFontSizeInput = () => {
    setManualFontSize(false);
  };

  const onStep = (v: any) => setManualFontSize(v);

  const onSliderChange = (v: any) => {
    setManualFontSize(false);
    dispatch(setActiveObjectModifier({ change: "fontSize", value: v }));
  };

  const handleLineHeightChange = (v: any) => {
    dispatch(setActiveObjectModifier({ change: "lineHeight", value: v }));
  };

  return {
    onStep,
    onSliderChange,
    manualFontSize,
    colorEditButton,
    onFontSizeChange,
    onBlurFontSizeInput,
    activeObjectProps,
    handleColorChange,
    getTextSelectionStyle,
    getTextSelectionFontFamily,
    handleLineHeightChange
  };
};
