import { Space, Spin, Typography } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const CHZeroStates: FC = () => {
  const { t } = useTranslation();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  return (
    <div style={{ margin: "auto" }}>
      {isLoading ? (
        <Spin />
      ) : (
        <Text style={{ opacity: 1, fontSize: "12px", fontWeight: "bold" }}>{t(I18N_MODULE_KEYS["content.history.wrapper.text.nohistoryfound"])}</Text>
      )}
    </div>
  );
};
