import { Avatar, Card, Col, Menu, Row, Tag, Tooltip, Typography } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { DataType, MenuItem } from "../../core/modals";
import { enumDocStatus, enumHistoryIndex, getItem } from "../../core/constants";
import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, FormOutlined, TranslationOutlined } from "@ant-design/icons";
import { CHZeroStates } from "../../components/CHZeroState";
import {
  CONTENT_TRANSCREATION_STEPS,
  ENUM_CONTENT_TRANSCREATION_BUTTON_TXT,
  ENUM_ROUTE_URLS,
  getUTCtoLocalDateTimeString,
  useOutsideAlerter
} from "../../../../../Utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setCTSourceAction,
  setResponseCtAction,
  setSelectedEmailDocAction
} from "../../../../../Store/actions/_contentTranscreationActions";
import { useNavigate } from "react-router-dom";
import { siteConfig } from "../../../../../Services";
import { CT_SOURCE_TYPES, ENUM_PROCESS_SCREENS } from "../../../Core/constants";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { ShowMoreListCard } from "./ShowMoreListCard";
import { MenuComponent } from "../../components/MenuComponent";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { t } from "i18next";
import { setActionDrawerDataAction } from "../../../../../Components/ActionDrawer/State";

const { Text } = Typography;

type CHGridViewType = {
  list: DataType[];
};

const threeDotsMenuNotStartedItems: MenuItem[] = [
  getItem("", "1", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.rename"]}`} icon={<EditOutlined />} onClick={() => null} />),
  getItem("", "2", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.delete"]}`} icon={<DeleteOutlined />} onClick={() => null} />)
];
const threeDotsMenuInProgressItems: MenuItem[] = [
  getItem("", "3", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.edit"]}`} icon={<FormOutlined />} onClick={() => null} />),
  ...threeDotsMenuNotStartedItems
];
const threeDotsMenuCompletedItems: MenuItem[] = [
  getItem("", "1", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.preview"]}`} icon={<EyeOutlined />} onClick={() => null} />),
  getItem("", "2", <MenuComponent title={`${I18N_MODULE_KEYS["content.button.translate"]}`} icon={<TranslationOutlined />} onClick={() => null} />),
  getItem("", "3", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.download"]}`} icon={<DownloadOutlined />} onClick={() => null} />),
  getItem("", "4", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.rename"]}`} icon={<EditOutlined />} onClick={() => null} />),
  getItem("", "5", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.delete"]}`} icon={<DeleteOutlined />} onClick={() => null} />)
];

// const threeDotsMenuInProgressItems: MenuItem[] = [
//   getItem("", "4", <MenuComponent title="Rename" icon={<EditOutlined />} onClick={() => null} />),
//   getItem("", "5", <MenuComponent title="Delete" icon={<DeleteOutlined />} onClick={() => null} />)
// ];

// const threeDotsMenuCompletedItems: MenuItem[] = [
//   getItem("", "1", <MenuComponent title="Preview" icon={<EyeOutlined />} onClick={() => null} />),
//   getItem("", "2", <MenuComponent title="Translate" icon={<TranslationOutlined />} onClick={() => null} />),
//   getItem("", "3", <MenuComponent title="Download" icon={<DownloadOutlined />} onClick={() => null} />),
//   getItem("", "4", <MenuComponent title="Rename" icon={<EditOutlined />} onClick={() => null} />),
//   getItem("", "5", <MenuComponent title="Delete" icon={<DeleteOutlined />} onClick={() => null} />)
// ];

export const CHGridView: FC<CHGridViewType> = ({ list }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuRef: any = useRef();

  const { responseCT, selectedEmailDoc }: { selectedEmailDoc: DataType; responseCT: any } = useSelector(
    (state: any) => state?.contentTranscreationReducer
  );

  const [localList, setLocalList] = useState<DataType[]>([]);

  useOutsideAlerter(menuRef, () => dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, threeDotMenuOpen: false })));

  useEffect(() => {
    const arrList: DataType[] = [...list];

    if (arrList && arrList?.length) {
      setLocalList([...localList, ...arrList]);
    } else {
      setLocalList([]);
      setLocalList([...arrList]);
    }
  }, [list]);

  useEffect(() => {
    if (selectedEmailDoc?.preview) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, preview: false }));
      handleClickButton(selectedEmailDoc, selectedEmailDoc?.video?.status, enumHistoryIndex.VIDEO);
    }
  }, [selectedEmailDoc?.preview]);

  const toggleCollapsed = (item: DataType) => {
    if (selectedEmailDoc?.threeDotMenuOpen === undefined || selectedEmailDoc?.threeDotMenuOpen === false) {
      dispatch(setSelectedEmailDocAction({ ...item, threeDotMenuOpen: true }));
    } else {
      dispatch(setSelectedEmailDocAction({ ...item, threeDotMenuOpen: false }));
    }
  };

  const handleClickButton = (item: DataType, status: string, columnIndex: number) => {
    //if we have "not started" and "failed" status, then only navigation does not work.

    if (status === enumDocStatus.NOT_APPLICABLE) return;

    if (status !== enumDocStatus.FAILED && status !== enumDocStatus.NOT_STARTED) {
      if (columnIndex === enumHistoryIndex.STORYBOARD) {
        localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);

        handleNavigation(
          CONTENT_TRANSCREATION_STEPS.STEP_3,
          {
            ...responseCT,
            email_doc_id: item?.email_id,
            storyboard_id: item?.storyboard?.id,
            html_id: item?.html?.id,
            blog_url: item?.source === "blog" ? item?.filename : "",
            isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
          },
          ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES
        );

        dispatch(setCTSourceAction(item?.source === "blog" ? CT_SOURCE_TYPES.BLOG : CT_SOURCE_TYPES.EMAIL));
      } else if (columnIndex === enumHistoryIndex.IMAGE) {
        localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN);

        handleNavigation(
          CONTENT_TRANSCREATION_STEPS.STEP_3,
          {
            ...responseCT,
            email_doc_id: item?.email_id,
            storyboard_id: item?.storyboard?.id,
            html_id: item?.html?.id,
            blog_url: item?.source === "blog" ? item?.filename : "",
            isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
          },
          // ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_TO_VIDEOS
          ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_TEMPLATE
        );

        dispatch(setCTSourceAction(item?.source === "blog" ? CT_SOURCE_TYPES.BLOG : CT_SOURCE_TYPES.EMAIL));
      } else if (columnIndex === enumHistoryIndex.VIDEO) {
        if (status === enumDocStatus.COMPLETED) {
          localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN);

          handleNavigation(
            CONTENT_TRANSCREATION_STEPS.STEP_3,
            {
              ...responseCT,
              email_doc_id: item?.email_id,
              storyboard_id: item?.video?.id,
              html_id: item?.html?.id,
              blog_url: item?.source === "blog" ? item?.filename : "",
              isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
            },
            ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_VIDEO
          );
        } else {
          dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.history.view.gridview.notification.videogenerationinprogress"])));
        }
      }
    } else {
    }
  };

  const handleNavigation = (step: number, response: any, btnTxt: string) => {
    dispatch(setActiveStepperStateAction(step));

    dispatch(setResponseCtAction({ ...response }));

    dispatch(setButtonTextStateAction(btnTxt));

    navigate(ENUM_ROUTE_URLS.CONTENT_TRANSCREATION);
  };

  const getTagColour = (status: string) => {
    switch (status) {
      case enumDocStatus.COMPLETED:
        return "success";
      case enumDocStatus.IN_PROGRESS:
        return "warning";
      case enumDocStatus.NOT_STARTED:
        return "error";
      case enumDocStatus.NOT_APPLICABLE:
        return "default";
      case enumDocStatus.FAILED:
        return "error";
      default:
        return "primary";
    }
  };

  const getTagWRTStatus = (status: string) => {
    switch (status) {
      case enumDocStatus.COMPLETED:
        return `${t(I18N_MODULE_KEYS["content.text.completed"])}`;
      case enumDocStatus.IN_PROGRESS:
        return `${t(I18N_MODULE_KEYS["content.text.inprogress"])}`;
      case enumDocStatus.NOT_STARTED:
        return `${t(I18N_MODULE_KEYS["content.text.notstarted"])}`;
    }

    return "";
  };

  const getMenuItems = (item: DataType) => {
    if (item?.video?.status === enumDocStatus.COMPLETED) return threeDotsMenuCompletedItems;
    if (item?.video?.status === enumDocStatus.IN_PROGRESS) return threeDotsMenuInProgressItems;
    return threeDotsMenuNotStartedItems;
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {localList && localList?.length ? (
          <>
            {localList?.map((item: DataType, index: number) => {
              return (
                <Col key={`${item?.filename}_${index}`} xs={{ span: 24 }} md={{ span: 8 }}>
                  <Card
                    style={{ height: "100%" }}
                    bodyStyle={{
                      padding: "10px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 6px 18px #00000029",
                      borderRadius: "12px",
                      opacity: 1,
                      height: "100%"
                    }}
                  >
                    <Row gutter={[8, 4]}>
                      <Col xs={{ span: 24 }} style={{ position: "relative" }}>
                        <div
                          style={{
                            background: `url(${item?.thumbnail_url})`,
                            objectFit: "cover",
                            height: "220px",
                            borderRadius: "10px"
                          }}
                        ></div>

                        <Avatar
                          className="well"
                          src={PROJECT_ICONS.CH_THREE_DOTS_HORIZONTAL}
                          style={{ position: "absolute", background: "white", top: "8px", right: "8px", cursor: "pointer" }}
                          onClick={() => toggleCollapsed(item)}
                        />
                        {selectedEmailDoc?.email_id === item?.email_id && selectedEmailDoc?.threeDotMenuOpen && (
                          <div ref={menuRef}>
                            <Menu
                              style={{
                                position: "absolute",
                                top: "40px",
                                right: "7px",
                                zIndex: 1,
                                width: "150px",
                                borderRadius: "4px",
                                boxShadow: "0px 0px 10px #0000001A",
                                background: "#FFFFFF 0% 0% no-repeat padding-box"
                              }}
                              items={getMenuItems(item)}
                            />
                          </div>
                        )}
                      </Col>
                      <Col xs={{ span: 24 }} style={{ marginBottom: "18px", marginTop: "5px" }}>
                        <Tooltip title={item?.filename || ""}>
                          <Text style={{ fontSize: "14px", fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {item?.filename?.length < 30 ? item?.filename : item?.filename?.slice(0, 30) + "..." || " "}
                          </Text>
                        </Tooltip>
                      </Col>
                      <Col xs={{ span: 24 }}>
                        <Row>
                          <Col xs={{ span: 24 }} lg={{ span: 9 }}>
                            {/* <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>Uploaded On</Text> */}
                            <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>{t(I18N_MODULE_KEYS["content.text.uploadedon"])}</Text>
                          </Col>
                          {item?.video?.status !== enumDocStatus.COMPLETED ? (
                            <>
                              <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                                <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>
                                  {t(I18N_MODULE_KEYS["content.text.storyboard"])}
                                </Text>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                                {/* <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>Image Clips</Text> */}

                                <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>
                                  {t(I18N_MODULE_KEYS["content.text.image/clips"])}
                                </Text>
                              </Col>
                            </>
                          ) : (
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} style={{ display: "flex", justifyContent: "center" }}>
                              {" "}
                              <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>{t(I18N_MODULE_KEYS["content.text.action"])}</Text>
                            </Col>
                          )}
                          <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                            {/* <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>Video</Text> */}
                            <Text style={{ opacity: 1, color: "#848484", fontSize: "12px" }}>{t(I18N_MODULE_KEYS["content.text.video"])}</Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={{ span: 24 }}>
                        <Row>
                          <Col xs={{ span: 24 }} lg={{ span: 9 }}>
                            <Text style={{ opacity: 1, fontSize: "11px", fontWeight: "bold" }}>{getUTCtoLocalDateTimeString(item?.uploaded_on)}</Text>
                          </Col>
                          {item?.video?.status !== enumDocStatus?.COMPLETED ? (
                            <>
                              <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                                <Tag
                                  color={getTagColour(item?.storyboard?.status)}
                                  style={{ cursor: "pointer", fontSize: "10px" }}
                                  onClick={() => handleClickButton(item, item?.storyboard?.status, enumHistoryIndex?.STORYBOARD)}
                                >
                                  {/* {item?.storyboard?.status} */}
                                  {t(getTagWRTStatus(item?.storyboard?.status || ""))}
                                </Tag>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                                <Tag
                                  color={getTagColour(item?.image?.status)}
                                  style={{ cursor: "pointer", fontSize: "10px" }}
                                  onClick={() => handleClickButton(item, item?.image?.status, enumHistoryIndex?.IMAGE)}
                                >
                                  {t(getTagWRTStatus(item?.image?.status || ""))}
                                  {/* {item?.image?.status} */}
                                </Tag>
                              </Col>
                            </>
                          ) : (
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} style={{ display: "flex", justifyContent: "center" }}>
                              {" "}
                              <Tag
                                color={"success"}
                                style={{ cursor: "pointer", fontSize: "10px" }}
                                onClick={() => {
                                  dispatch(
                                    setResponseCtAction({
                                      ...responseCT,
                                      email_doc_id: item?.email_id,
                                      storyboard_id: item?.storyboard?.id,
                                      video_id: item?.video?.id,
                                      html_id: item?.html?.id,
                                      blog_url: item?.source === "blog" ? item?.filename : "",
                                      isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
                                    })
                                  );

                                  setTimeout(() => {
                                    dispatch(
                                      setActionDrawerDataAction({
                                        drawerTitle: t(I18N_MODULE_KEYS["content.transcreation.button.summary"]),
                                        drawerOpen: true,
                                        componentId: "ViewSummary"
                                      })
                                    );
                                  }, 10);
                                }}
                              >
                                {t(I18N_MODULE_KEYS["content.transcreation.footer.button.viewsummary"])}
                              </Tag>
                            </Col>
                          )}
                          <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                            <Tag
                              color={getTagColour(item?.video?.status)}
                              style={{ cursor: "pointer", fontSize: "10px" }}
                              onClick={() => handleClickButton(item, item?.video?.status, enumHistoryIndex?.VIDEO)}
                            >
                              {/* {item?.video?.status} */}
                              {t(getTagWRTStatus(item?.video?.status || ""))}
                            </Tag>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
            <ShowMoreListCard />
          </>
        ) : (
          <CHZeroStates />
        )}
      </Row>
    </>
  );
};
