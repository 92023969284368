import { Button, Modal, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

const { Text } = Typography;

const commonIFrameStyles = `
<style>
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Open+Sans:wght@400;500;600;700&family=Playfair+Display:wght@600;700;900&display=swap");

  body{
    font-family: Nunito Sans;
    // font-size: 12px;
    white-space: pre;
    text-wrap: pretty;
  }
</style>
`;

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;

export default function EditTranslatedTextModal({
  editTranslatedText,
  setEditTranlsatedText,
  translatedText,
  setTranslatedText,
  updateTranslatedText
}: {
  editTranslatedText: boolean;
  setEditTranlsatedText: Function;
  translatedText: string;
  setTranslatedText: Function;
  updateTranslatedText: (updatedText: string) => Promise<boolean>;
}) {
  const { t } = useTranslation();

  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const [updatedTranslatedText, setUpdatedTranslatedText] = useState(translatedText);

  const [saveChanges, setSaveChanges] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openExitEditModeModal, setOpenExitEditModeModal] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const successMessage = (text: string = "") => messageApi.open({ type: "success", content: text, duration: 1.5 });
  const errorMessage = (text: string = "") => messageApi.open({ type: "error", content: text, duration: 1.5 });
  const warningMessage = (text: string = "") => messageApi.open({ type: "warning", content: text, duration: 1.5 });
  const infoMessage = (text: string = "") => messageApi.info(text, 1.5);

  useEffect(() => {
    setUpdatedTranslatedText(translatedText);
  }, [translatedText]);

  useEffect(() => {
    if (!saveChanges) return;
    setSaveChanges(false);

    //API call to save the translated text
    updateTranslatedText(getIframeContent() || translatedText).then((res) => {
      if (res) {
        setTranslatedText(getIframeContent());
        successMessage(getTranslatedText("Content saved!"));
      } else {
        errorMessage(getTranslatedText("Saving failed! Please retry."));
      }
    });
  }, [saveChanges]);

  useEffect(() => {
    if (!discardChanges) return;

    setIframeContent(updatedTranslatedText);

    setDiscardChanges(false);

    warningMessage(getTranslatedText("Content discarded!"));
  }, [discardChanges]);

  const getIframeContent = () => {
    return iFrameRef.current?.contentDocument?.body?.innerHTML;
  };

  const setIframeContent = (content: string) => {
    if (iFrameRef.current?.contentDocument?.body.innerHTML) iFrameRef.current.contentDocument.body.innerHTML = content;
  };

  const editorHasUnsavedChanges = () => {
    const iframeInnerHTML = iFrameRef.current?.contentDocument?.body?.innerHTML;
    iframeInnerHTML !== updatedTranslatedText && console.log({ iframeInnerHTML, updatedTranslatedText });
    return iframeInnerHTML !== updatedTranslatedText;
  };

  const saveEditorChangesHandler = () => {
    if (editorHasUnsavedChanges()) {
      //Save editor contents
      setSaveChanges(true);
    } else {
      //dispatch(setNotificationMsgAction("No recent changes to save."));
      infoMessage(getTranslatedText("No recent changes to save."));
      console.log("No recent changes to save");
    }
  };

  const discardEditorChangesHandler = () => {
    if (editorHasUnsavedChanges()) {
      //Open discard modal
      setOpenDiscardModal(true);
    } else {
      //dispatch(setNotificationMsgAction("No unsaved changes to discard."));
      infoMessage(getTranslatedText("No unsaved changes to discard."));
      console.log("No unsaved changes to discard");
    }
  };

  const exitEditModeHandler = () => {
    if (editorHasUnsavedChanges()) {
      console.log("Changes would be lost!");
      setOpenExitEditModeModal(true);
    } else setEditTranlsatedText(false);
  };

  const cancelDiscardModalHandler = () => {
    //close the dialog
    setOpenDiscardModal(false);
  };

  const loseChangesHandler = () => {
    setDiscardChanges(true);
    //close the dialog
    setOpenDiscardModal(false);
  };

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module.FinalTranslationScreen.EditTranslatedTextModal.${value as string}`])}`;
  };

  return (
    <>
      {contextHolder}
      <Modal
        keyboard={false}
        maskClosable={false}
        className="iDetailFinalOutput"
        open={editTranslatedText} //{true}
        centered={true}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
        footer={null}
        width={"calc(100vw - 64px)"}
        closeIcon={false}
        styles={{ body: { height: "calc(90vh - 84px)", display: "flex", flexDirection: "column", gap: "10px" } }} //20 + 12 + 32 + 20
      >
        <div style={{ flex: "0 0 auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
            <Text style={{ fontSize: "18px", fontWeight: "700" }}>{getTranslatedText("Modify Translated Content")}</Text>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                <Button onClick={saveEditorChangesHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
                  {getTranslatedText("Save")}
                </Button>
                <Button onClick={discardEditorChangesHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
                  {getTranslatedText("Discard")}
                </Button>
                <Button onClick={exitEditModeHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
                  {getTranslatedText("Close")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <iframe
          ref={iFrameRef}
          srcDoc={commonIFrameStyles + scrollBarStyles + updatedTranslatedText}
          style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px" }}
          onLoad={() => {
            if (iFrameRef.current?.contentDocument?.body.contentEditable) {
              iFrameRef.current.contentDocument.body.contentEditable = "true";
            }
          }}
        />
      </Modal>

      <Modal
        open={openDiscardModal}
        centered={true}
        title={getTranslatedText("Discard unsaved changes")}
        onCancel={cancelDiscardModalHandler}
        footer={[
          <Button key="cancel" type="primary" onClick={cancelDiscardModalHandler} style={{ background: "rgba(3, 78, 162, 1)", color: "white" }}>
            {getTranslatedText("Cancel")}
          </Button>,
          <Button key="discard" onClick={loseChangesHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
            {getTranslatedText("Lose Changes")}
          </Button>
        ]}
      >
        <Text>{getTranslatedText("If you proceed, you will lose all the changes you have made.")}</Text>
      </Modal>

      <Modal
        open={openExitEditModeModal}
        centered={true}
        title={getTranslatedText("Unsaved changes")}
        onCancel={() => {
          setOpenExitEditModeModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            style={{ color: "rgba(3, 78, 162, 1)" }}
            onClick={() => {
              setOpenExitEditModeModal(false);
            }}
          >
            {getTranslatedText("Cancel")}
          </Button>,
          <Button
            key="saveAndExit"
            type="primary"
            style={{ background: "rgba(3, 78, 162, 1)", color: "white" }}
            onClick={() => {
              setSaveChanges(true);
              setOpenExitEditModeModal(false);
              setEditTranlsatedText(false);
            }}
          >
            {getTranslatedText("Save and Exit")}
          </Button>,
          <Button
            key="exitWithoutSaving"
            style={{ color: "rgba(3, 78, 162, 1)" }}
            onClick={() => {
              setDiscardChanges(true);
              setOpenExitEditModeModal(false);
              setEditTranlsatedText(false);
            }}
          >
            {getTranslatedText("Exit without Saving")}
          </Button>
        ]}
      >
        <Text>{getTranslatedText("If you proceed, you will lose all the changes you have made.")}</Text>
      </Modal>
    </>
  );
}
