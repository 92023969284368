// import { FC, useEffect, useRef, useState } from "react";
// import {
//   DrawerBody,
//   DrawerFooter,
//   DrawerHeader
// } from "../../../../Components/ActionDrawer/Components";
// import { Avatar, Button, Col, Row, Spin, Typography } from "antd";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setActionDrawerComponentAction,
//   setActionDrawerDataAction,
//   setActionDrawerOpenAction
// } from "../../../../Components/ActionDrawer/State";
// import {
//   SendOutlined,
//   ShareAltOutlined,
//   CloudDownloadOutlined,
//   ArrowLeftOutlined,
//   CopyOutlined
// } from "@ant-design/icons";
// import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
// import {
//   getAnswerDataByIdrequest,
//   getSemanticSummaryRequest
// } from "../../../../Store/requests/_semanticSearchRequests";
// import { CustomButtonForApiCalling } from "../../../../Components/Buttons";
// import { onDownload } from "../../../../Utils";
// import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";

// const { Paragraph } = Typography;

// const ENUM_ACTIVE_STEP = {
//   VIEW: 1,
//   LOADING: 2,
//   SUMMARIZED: 3
// };

// export const SemanticSearchView: FC = () => {
//   const dispatch = useDispatch();
//   const textareaRef: any = useRef(null);

//   // const { others } = useSelector((state: any) => state?.actionDrawerReducer);

//   const [content, setContent] = useState<string>("");
//   const [loading, setLoading] = useState<boolean>(false);
//   const [answerData, setAnswerData] = useState<any>();
//   const [activeStep, setActiveStep] = useState<number>(ENUM_ACTIVE_STEP.VIEW);
//   const [isHovered, setIsHovered] = useState<boolean>(false);
//   const [s3link, sets3Link] = useState<string>("");
//   const { drawerOpen, componentId, others } = useSelector(
//     (state: any) => state?.actionDrawerReducer
//   );
//   const { searchQuery } = useSelector(
//     (state: any) => state?.semanticSearchReducer
//   );

//   useEffect(() => {
//     if (componentId === "SemanticSearchView" && !drawerOpen) {
//       dispatch(setActionDrawerComponentAction(null));
//     }
//   }, [drawerOpen]);

//   useEffect(() => {
//     if (others?.id) {
//       getAnswerDataById(others?.id);
//     }
//   }, [others]);

//   const onClose = () => {
//     dispatch(setActionDrawerOpenAction(false));
//   };

//   const getAnswerDataById = async (id: number) => {
//     setActiveStep(ENUM_ACTIVE_STEP.LOADING);
//     setLoading(true);
//     const res = await getAnswerDataByIdrequest(id);
//     setLoading(false);
//     setActiveStep(ENUM_ACTIVE_STEP.VIEW);

//     if (!res) return; 

//     setAnswerData({ ...res?.data });
//     setContent(res?.data?.phrased_result);
//     sets3Link(res?.data?.s3_url);
//   };

//   // useEffect(() => {
//   //   if (others) {
//   //     setContent(others?.phrased_result);
//   //   }
//   // }, [others]);

//   const getSummarisedDocument = async () => {
//     setActiveStep(ENUM_ACTIVE_STEP.LOADING);

//     const res = await getSemanticSummaryRequest(
//       { filename: `${answerData?.folder_name}/${answerData?.filenames}` },
//       undefined,
//       dispatch
//     );

//     if (!res) return;

//     if (res?.data) {
//       setActiveStep(ENUM_ACTIVE_STEP.SUMMARIZED);
//       setContent(res?.data?.summary);
//     }
//   };

//   const copyToClipboard = () => {
//     const el = document.createElement("textarea");

//     el.value = content;

//     el?.setAttribute("id", "text-area");

//     document.body.appendChild(el);

//     el?.select();

//     document?.execCommand("copy");

//     document?.getElementById("text-area")?.remove();

//     dispatch(setNotificationMsgAction("Text Copied Successfully!"));
//   };

//   return (
//     <>
//       <DrawerHeader>
//         {
//           <Row style={{ height: "100%" }}>
//             <Col span={24}>
//               {activeStep === ENUM_ACTIVE_STEP.VIEW && (
//                 <>
//                   <Row>
//                     <Col span={24}>
//                       <Paragraph
//                         type="secondary"
//                         style={{
//                           color: "#66676B"
//                         }}
//                       >
//                         Search Query : {searchQuery}
//                       </Paragraph>
//                     </Col>
//                     <Col span={24}>
//                       <Paragraph
//                         type="secondary"
//                         style={{
//                           font: "normal normal bold 18px/30px Nunito Sans",
//                           letterSpacing: "0px",
//                           color: "#66676B",
//                           opacity: "1"
//                         }}
//                       >
//                         {answerData?.filenames}
//                       </Paragraph>
//                     </Col>
//                     <Col xs={{ span: 24 }}>
//                       <Row gutter={[16, 16]}>
//                         <Col xs={{ span: 24 }} md={{ span: 6 }}>
//                           <div
//                             className="d-flex"
//                             style={{ flexDirection: "row" }}
//                           >
//                             <img
//                               src={PROJECT_ICONS.SEND_ICON}
//                               style={{
//                                 backgroundColor: "#f7fcff",
//                                 cursor: "pointer",
//                                 fontSize: "18px",
//                                 color: "#034EA2",
//                                 fontWeight: "600"
//                               }}
//                             />
//                             <span
//                               style={{
//                                 font: "normal normal 600 16px/30px Nunito Sans",
//                                 letterSpacing: "0px",
//                                 color: "#034EA2",
//                                 opacity: "1",
//                                 cursor: "pointer",
//                                 marginLeft: "10px"
//                               }}
//                             >
//                               Send to Report
//                             </span>
//                           </div>
//                         </Col>
//                         <Col xs={{ span: 24 }} md={{ span: 4 }}>
//                           <div
//                             className="d-flex"
//                             style={{ flexDirection: "row" }}
//                           >
//                             <img
//                               src={PROJECT_ICONS.SHARE_ICON}
//                               style={{
//                                 backgroundColor: "#f7fcff",
//                                 cursor: "pointer",
//                                 fontSize: "18px",
//                                 color: "#034EA2",
//                                 fontWeight: "600"
//                               }}
//                             />
//                             <span
//                               style={{
//                                 font: "normal normal 600 16px/30px Nunito Sans",
//                                 letterSpacing: "0px",
//                                 color: "#034EA2",
//                                 opacity: "1",
//                                 cursor: "pointer",
//                                 marginLeft: "10px"
//                               }}
//                             >
//                               Share
//                             </span>
//                           </div>
//                         </Col>
//                         <Col xs={{ span: 24 }} md={{ span: 5 }}>
//                           <div
//                             className="d-flex"
//                             style={{ flexDirection: "row" }}
//                           >
//                             <img
//                               src={PROJECT_ICONS.DOWNLOAD_ICON}
//                               style={{
//                                 backgroundColor: "#f7fcff",
//                                 cursor: "pointer",
//                                 fontSize: "18px",
//                                 color: "#034EA2",
//                                 fontWeight: "600"
//                               }}
//                             />
//                             <span
//                               style={{
//                                 font: "normal normal 600 16px/30px Nunito Sans",
//                                 letterSpacing: "0px",
//                                 color: "#034EA2",
//                                 opacity: "1",
//                                 cursor: "pointer",
//                                 marginLeft: "10px"
//                               }}
//                             >
//                               Download
//                             </span>
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </>
//               )}
//               {activeStep === ENUM_ACTIVE_STEP.LOADING && !loading && (
//                 <div
//                   style={{
//                     padding: "10px",
//                     backgroundColor: "#E6F6FF",
//                     borderRadius: "6px",
//                     width: "98%",
//                     fontSize: "16px",
//                     color: "#66676B",
//                     marginTop: "10px"
//                   }}
//                 >
//                   Summarising the document..
//                 </div>
//               )}
//               {activeStep === ENUM_ACTIVE_STEP.SUMMARIZED && (
//                 <>
//                   <Row>
//                     <Col
//                       xs={24}
//                       className="d-flex"
//                       style={{ flexDirection: "row" }}
//                     >
//                       <Avatar
//                         icon={
//                           <ArrowLeftOutlined style={{ color: "#0546a5" }} />
//                         }
//                         shape="square"
//                         style={{
//                           backgroundColor: "#f7fcff",
//                           cursor: "pointer",
//                           fontSize: "18px",
//                           color: "#034EA2",
//                           fontWeight: "600"
//                         }}
//                         onClick={() => {
//                           setContent(answerData?.phrased_result);
//                           setActiveStep(ENUM_ACTIVE_STEP.VIEW);
//                         }}
//                       />
//                       <Paragraph
//                         type="secondary"
//                         style={{
//                           font: "normal normal 600 16px/30px Nunito Sans",
//                           letterSpacing: "0px",
//                           color: "#034EA2",
//                           opacity: "1",
//                           cursor: "pointer"
//                         }}
//                       >
//                         Back to Document
//                       </Paragraph>
//                       {/* </div>{" "} */}
//                     </Col>
//                     <Col xs={24}>
//                       <Paragraph
//                         type="secondary"
//                         style={{
//                           font: "normal normal 600 18px/26px Nunito Sans",
//                           letterSpacing: "0px",
//                           color: "#231F20",
//                           opacity: "1",
//                           cursor: "pointer"
//                         }}
//                       >
//                         Document Summarisation
//                       </Paragraph>
//                     </Col>
//                     <Col xs={{ span: 24 }}>
//                       <Row gutter={[16, 16]}>
//                         <Col xs={{ span: 24 }} md={{ span: 4 }}>
//                           <div
//                             className="d-flex"
//                             style={{ flexDirection: "row" }}
//                           >
//                             <img
//                               src={PROJECT_ICONS.SHARE_ICON}
//                               style={{
//                                 backgroundColor: "#f7fcff",
//                                 cursor: "pointer",
//                                 fontSize: "18px",
//                                 color: "#034EA2",
//                                 fontWeight: "600"
//                               }}
//                             />
//                             <span
//                               style={{
//                                 font: "normal normal 600 16px/30px Nunito Sans",
//                                 letterSpacing: "0px",
//                                 color: "#034EA2",
//                                 opacity: "1",
//                                 cursor: "pointer",
//                                 marginLeft: "10px"
//                               }}
//                             >
//                               Share
//                             </span>
//                           </div>
//                         </Col>
//                         <Col xs={{ span: 24 }} md={{ span: 5 }}>
//                           <div
//                             className="d-flex"
//                             style={{ flexDirection: "row" }}
//                           >
//                             <img
//                               src={PROJECT_ICONS.DOWNLOAD_ICON}
//                               style={{
//                                 backgroundColor: "#f7fcff",
//                                 cursor: "pointer",
//                                 fontSize: "18px",
//                                 color: "#034EA2",
//                                 fontWeight: "600"
//                               }}
//                             />
//                             <span
//                               style={{
//                                 font: "normal normal 600 16px/30px Nunito Sans",
//                                 letterSpacing: "0px",
//                                 color: "#034EA2",
//                                 opacity: "1",
//                                 cursor: "pointer",
//                                 marginLeft: "10px"
//                               }}
//                             >
//                               Download
//                             </span>
//                           </div>
//                         </Col>
//                         <Col xs={{ span: 24 }} md={{ span: 5 }}>
//                           <div
//                             className="d-flex"
//                             style={{ flexDirection: "row" }}
//                           >
//                             <img
//                               src={PROJECT_ICONS.COPY_ICON}
//                               style={{
//                                 backgroundColor: "#f7fcff",
//                                 cursor: "pointer",
//                                 fontSize: "18px",
//                                 color: "#034EA2",
//                                 fontWeight: "600"
//                               }}
//                               onClick={copyToClipboard}
//                             />
//                             <span
//                               style={{
//                                 font: "normal normal 600 16px/30px Nunito Sans",
//                                 letterSpacing: "0px",
//                                 color: "#034EA2",
//                                 opacity: "1",
//                                 cursor: "pointer",
//                                 marginLeft: "10px"
//                               }}
//                               onClick={copyToClipboard}
//                             >
//                               Copy
//                             </span>
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </>
//               )}
//             </Col>
//           </Row>
//         }
//       </DrawerHeader>
//       <DrawerBody>
//         {
//           <Row>
//             <Col
//               style={{
//                 width: "100%"
//               }}
//             >
//               {activeStep === ENUM_ACTIVE_STEP.LOADING ? (
//                 <div
//                   className="d-flex justify-content-center align-items-center"
//                   style={{ height: "100%" }}
//                 >
//                   <Spin />
//                 </div>
//               ) : (
//                 <div
//                   style={{
//                     textAlign: "left",
//                     font: "normal normal normal 16px/26px Nunito Sans",
//                     letterSpacing: "0px",
//                     color: "#66676B",
//                     opacity: "1",
//                     lineHeight: "26px",
//                     marginTop: "10px"
//                   }}
//                 >
//                   {content}
//                 </div>
//               )}
//             </Col>
//           </Row>
//         }
//       </DrawerBody>
//        {/* <DrawerFooter>
//         {
//           <Row>
//             <Col
//               className="d-flex"
//               span={24}
//               style={{
//                 justifyContent: "space-between",
//                 padding: "10px",
//                 alignItems: "center"
//               }}
//             >
//               <Col
//                 className="pointer"
//                 style={{ color: "#0546a5", fontSize: "12px" }}
//               ></Col>
//               <Col>
//                 <Button
//                   className="button-font button-with-border"
//                   onClick={() => {
//                     onClose();
//                     setActiveStep(ENUM_ACTIVE_STEP.VIEW);
//                   }}
//                 >
//                   CLOSE
//                 </Button>
//                 {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
//                   <CustomButtonForApiCalling
//                     loading={
//                       activeStep === ENUM_ACTIVE_STEP.LOADING && !loading
//                         ? true
//                         : false
//                     }
//                     btnText="SUMMARISE"
//                     className="button-font button-blue"
//                     style={{ margin: "10px" }}
//                     onClick={getSummarisedDocument}
//                   />
//                 )}
//               </Col>
//             </Col>
//           </Row>
//         }
//      </DrawerFooter> */}
//        <DrawerFooter>
//         {
//           // <Row>
//           //   <Col
//           //     className="d-flex"
//           //     span={24}
//           //     style={{
//           //       justifyContent: "space-between",
//           //       padding: "10px",
//           //       alignItems: "center"
//           //     }}
//           //   >

//           //     <Col
//           //       className="pointer"
//           //       style={{ color: "#0546a5", fontSize: "12px" }}
//           //     ></Col>
              
//           //     {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
//           //         <Button type="link" style={{alignItems:'flex-start'}}>View Document</Button>
//           //       )}
                
//           //       <Col>
//           //       <Button
//           //         className="button-font button-with-border"
//           //         onClick={() => {
//           //           onClose();
//           //           setActiveStep(ENUM_ACTIVE_STEP.VIEW);
//           //         }}
//           //       >
//           //         CLOSE
//           //       </Button>
//           //       {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
//           //         <CustomButtonForApiCalling
//           //           loading={
//           //             activeStep === ENUM_ACTIVE_STEP.LOADING && !loading
//           //               ? true
//           //               : false
//           //           }
//           //           btnText="SUMMARISE"
//           //           className="button-font button-blue"
//           //           style={{ margin: "10px" }}
//           //           onClick={getSummarisedDocument}
//           //         />
//           //       )}
              
//           //     </Col>
//           //   </Col>
//           // </Row>
//           <Row>
//   <Col
//     span={24}
//     style={{
//       display: "flex",
//       justifyContent: "space-between",
//       padding: "10px",
//       alignItems: "center"
//     }}
//   >
//     <Col className="pointer" style={{ color: "#0546a5" , paddingBottom:'10px'}}>
//       {/* Content for the left column */}
//       {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
//         <div>
//       <Button
//       type={isHovered? "default" : "link"}// Change button type on hover
//       style={{
//         alignItems: "flex-start",
//        color:"#034EA2" 
//       }}  
//       onMouseEnter={() => setIsHovered(true)} 
//       onMouseLeave={() => setIsHovered(false)} 
//       onClick={()=>{
//         window.open(s3link, "_blank");
//       }}
//     >
//       {/* <a href={s3link}  target="_blank"
//             rel="noopener noreferrer" style={{textDecoration:'none', color:'#034EA2'}}>
//       View Document</a> */}  
//       View Document
//     </Button>
//     </div>
//     )}
//     </Col>
     
    
    
//     <Col>
//       <div style={{ textAlign: 'right' }}>
//         <Button
//           className="button-font button-with-border"
//           onClick={() => {
//             onClose();
//             setActiveStep(ENUM_ACTIVE_STEP.VIEW);
//           }}
//         >
//           CLOSE
//         </Button>
//         {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
//           <CustomButtonForApiCalling
//             loading={
//               activeStep === ENUM_ACTIVE_STEP.LOADING && !loading ? true : false
//             }
//             btnText="SUMMARISE"
//             className="button-font button-blue"
//             style={{ margin: "10px" }}
//             onClick={getSummarisedDocument}
//           />
//         )}
//       </div>
//     </Col>
//   </Col>
// </Row>
//         }
//       </DrawerFooter> 
//     </>
//   );
// };
import { FC, useEffect, useRef, useState } from "react";
import {
  DrawerBody,
  DrawerFooter,
  DrawerHeader
} from "../../../../Components/ActionDrawer/Components";
import { Avatar, Button, Col, Row, Spin, Typography ,Drawer,Tooltip,Input,Image} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setActionDrawerComponentAction,
  setActionDrawerDataAction,
  setActionDrawerOpenAction
} from "../../../../Components/ActionDrawer/State";
import Lottie from "lottie-react";
import {
  SendOutlined,
  ShareAltOutlined,
  CloudDownloadOutlined,
  ArrowLeftOutlined,
  CopyOutlined
} from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import {
  getAnswerDataByIdImagerequest,
  getAnswerDataByIdrequest,
  getSemanticSummaryRequest,semanticImageInterested,semanticIntereseted
} from "../../../../Store/requests/_semanticSearchRequests";
import { CustomButtonForApiCalling } from "../../../../Components/Buttons";
import { onDownload } from "../../../../Utils";
import { handleCancelApiRequest } from "../../../../Utils";
import { FolderOpenTwoTone, FolderOutlined, SearchOutlined } from "@ant-design/icons";
import { EventSourcePolyfill } from "event-source-polyfill";
import { PostSemanticChatSession, getChats, getChatsSpecific, getQuestionRecommendation,PostSemanticChat,semanticQaIntereseted,semanticQaFeedBack} from "../../../../Store/requests/_semanticSearchRequests";
import _localStorageService from "../../../../Services/_localStorageService";
import {
  setEncodedSearchQueryAction,
  setSearchQuery,
  setAdvancedFilter,
  setFilterListAction,
  setButtonText,
  setAdvancedFilterStatus,
} from "../../../../Store/actions/_semanticSearchActions";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { SEMANTIC_SEARCH_CARD } from "../Core/constants";
import { ImageMask } from "../../../../Components/Image";
const { Paragraph } = Typography;

const ENUM_ACTIVE_STEP = {
  VIEW: 1,
  LOADING: 2,
  SUMMARIZED: 3
};
interface Folder {
  id: number;
  name: string;
  files: Record<string, string>; // A record of file name to file URL
}
interface SSEData {
  id: any;
  created_at: string;
  question: string;
  answer: string;
  interested: any | null;
  page_number: number | null;
  k: number;
  score_threshold: number;
  status: string;
}

export const SemanticSearchView: FC = () => {
  const textareaRef: any = useRef(null);
  const timerRef1: any = useRef();
  const apiCounterRef1: any = useRef();

  // const { others } = useSelector((state: any) => state?.actionDrawerReducer);
  const [apiCounter1, setApiCounter] = useState<number>(0);
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [answerData, setAnswerData] = useState<any>();
  const [activeStep, setActiveStep] = useState<number>(ENUM_ACTIVE_STEP.VIEW);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [s3link, sets3Link] = useState<string>("");
  const [Interested, setisInterested] = useState<any>(null);
  const [filedownloadinprogress,setfiledownloadinprogress]=useState(false);
  const { drawerOpen, componentId, others } = useSelector(
    (state: any) => state?.actionDrawerReducer
  );
 
  const {
    tabList,
    advancedFilterList,
    searchQuery,
    initialFolders,
    advancedFilterStatus,
    encodedSearchQuery,
    advancedFilter,
    refetchList,
    Format,
    cardsData,
    semanticSearchid,
    cardData,cardType,semanticSearchImageId
  } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const apiurl=process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const folders = initialFolders as Folder[];
  const token = _localStorageService.getAccessToken();
  const [selectedFolders, setSelectedFolders] = useState<string[]>(folders.map((folder) => folder.name));
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>();
  const [selectAllChecked, setSelectAllChecked] = useState(true); // Track "Select All" checkbox state
  const [searchFolder, setSearchFolder] = useState<string>("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const filesLength = cardData ? (cardData.filenames ? Object.keys(cardData.filenames).length : 0) : 0;
  const [copiedValue, setCopiedValue] = useState("");
  const [feedback, setFeedback] = useState("");
  const [showFeedBack, setShowFeedBack] = useState<Boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userInputForSearch, setUserInputForSearch] = useState("");
  const [id, setId] = useState("");
  const [msgid, setMsgId] = useState("");
  const [sseData, setSSEData] = useState<SSEData[]>([]);
  const [filename, setFileName] = useState<string>("");
  const [currentid, setCurrentid] = useState<any>(0);
  const [disable, setInputDisabled] = useState(false);
  const [s3url, sets3url] = useState<String>("");
  const scrollableDivRef = useRef<HTMLDivElement>(null); // Ref for the scrollable div
  const [downloadinprogress, setDownloadinProgress] = useState<Boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isopenforFolderTime, setisopenforFolderTime] = useState<boolean>(false);
  const [downloadinprogressfolder, setDownloadProgressFolder] = useState<Boolean>(false);
  const [previouschats,setpreviouschats]=useState<any[]>([]);
const[isloadingpreviouschats,setisloadingpreviouschats]=useState<boolean>(false);
const [recommendQuestion,setRecommendQuestion]=useState<any[]>([]);  
const getchatsfunctioninitial = async()=>{
  setisloadingpreviouschats(true);
  const res= await getChats({filename:filename})
  if(!res){
    setpreviouschats([])
    console.log("It is working opening")
   setisloadingpreviouschats(false);
   return;
  } 
 
  console.log(res)
  setpreviouschats(res?.data)
  console.log("It is working opening")
 setisloadingpreviouschats(false);
 
}
  useEffect (()=>{
 if(drawerVisible)
 { console.log("It is working opening")}
  },[drawerVisible])
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent form submission, if using within a form
        if (buttonRef.current) {
          buttonRef.current.click(); // Trigger button click event
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect (()=>{
 
    const getchatsfunction = async()=>{
    setisloadingpreviouschats(true);
    const res= await getChats({filename:filename})
    if(!res){
      setpreviouschats([])
      console.log("It is working opening")
     setisloadingpreviouschats(false);
     return;
    } 
    const resp= await getQuestionRecommendation({filename:filename})
    if(!resp){
      setpreviouschats([])
      console.log("It is working opening")
     setisloadingpreviouschats(false);
     return;
    }
    console.log(res)
    console.log(resp)
    setRecommendQuestion(resp?.data)
    setpreviouschats(res?.data)
    console.log("It is working opening")
   setisloadingpreviouschats(false);
   
  }
if(drawerVisible){
  getchatsfunction()
}

   },[drawerVisible])
  const handleUserInput = (event: any) => {
    setUserInput(event.target.value);
    setUserInputForSearch(event.target.value);
  };

  const showDrawer = () => {
    setFileName(answerData?.folder_name+"/"+answerData?.filenames);
    setDrawerVisible(true);
    // onClose();
    // setActiveStep(ENUM_ACTIVE_STEP.VIEW);
  
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const openS3LinkInNewTab = (link: any) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    setSelectedFolder(folders[0]);
  }, []);
  const handleDownload = () => {
    setDownloadinProgress(true);
    const accessToken = token || ""; // Provide a default value if it's null
    console.log(accessToken);
    const headers: Record<string, string> = {
      Authorization: accessToken,
    };
    const eventSource = new EventSourcePolyfill(`${apiurl}/download-insight/${semanticSearchid}`, {
      headers,
    });
    // eventSource.addEventListener("pending", (event) => {
    //   console.log("pending", event);
    //   // const newElement = document.createElement("li");
    //   // const eventList = document.getElementById("list");
    //   // const time = JSON.parse(event.data).time;
    //   // newElement.textContent = `ping at ${time}`;
    //   //eventList.appendChild(newElement);
    // });
    eventSource.onmessage = (event) => {
      // const newResult = JSON.parse(event.data); // Assuming data is JSON
      // setSSEData((prevData) => [...prevData, { input: userInput, response: newResult }]);
      console.log("data", event);
    };

    eventSource.addEventListener("end", (event) => {
      console.log("end", event);
      const messageEvent = event as MessageEvent;
      console.log("end", event);
      const newResult = messageEvent?.data;
      sets3url(newResult);
      downloadFile(newResult, newResult);
      setDownloadinProgress(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.fileuploaded"])));
      eventSource.close();
    });
    eventSource.addEventListener("error", (event) => {
      setDownloadinProgress(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.connectionerror"])));
      console.log("error", event);
      eventSource.close();
    });
    // eventSource.onerror = (error) => {
    //   console.error("SSE Error for download:", error);
    //   // eventSource.close();
    //   // eventSource.close();
    //   // Enable input on SSE error
    // };

    // Close the SSE connection when component unmounts or ID changes
  };
  const handleDownloadForFolder = (s: string) => {
    setDownloadProgressFolder(true);
    const accessToken = token || ""; // Provide a default value if it's null
    console.log(accessToken);
    const headers: Record<string, string> = {
      Authorization: accessToken,
    };
    const eventSource = new EventSourcePolyfill(`${apiurl}/document/chat/download?chat_id=${id}&time=${s}`, {
      headers,
    });
    // eventSource.addEventListener("pending", (event) => {
    //   console.log("pending", event);
    //   // const newElement = document.createElement("li");
    //   // const eventList = document.getElementById("list");
    //   // const time = JSON.parse(event.data).time;
    //   // newElement.textContent = `ping at ${time}`;
    //   //eventList.appendChild(newElement);
    // });
    eventSource.onmessage = (event) => {
      // const newResult = JSON.parse(event.data); // Assuming data is JSON
      // setSSEData((prevData) => [...prevData, { input: userInput, response: newResult }]);
      console.log("data", event);
    };

    eventSource.addEventListener("end", (event) => {
      console.log("end", event);
      const messageEvent = event as MessageEvent;
      console.log("end", event);
      const newResult = messageEvent?.data;
      sets3url(newResult);
      downloadFile(newResult, newResult);
      setDownloadProgressFolder(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["chat.drawer.notification.chatdownload"])));
      eventSource.close();
    });
    eventSource.addEventListener("error", (event) => {
      eventSource.close();
      setDownloadProgressFolder(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["chat.drawer.notification.errorchat"])));
      console.log("error", event);
    });
    // eventSource.onerror = (error) => {
    //   console.error("SSE Error for download:", error);
    //   // eventSource.close();
    //   // eventSource.close();
    //   // Enable input on SSE error
    // };
    return () => {
      console.log("Fired Closed");
      setDownloadProgressFolder(false);
      eventSource.close(); // Close the SSE connection when component unmounts or ID changes
    };

    // Close the SSE connection when component unmounts or ID changes
  };
  const downloadFile = async (fileUrl: string, fileName: string) => {
    setfiledownloadinprogress(true);
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName || "file"); // Set the filename

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setfiledownloadinprogress(false);
  };

  
  // Function to handle the input change and trigger search after a pause
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Clear any previous search timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to trigger the search after 3 seconds
    const newTimeout = setTimeout(() => {
      setSearchFolder(inputValue);
      performSearch(inputValue);
    }, 2000);

    setSearchTimeout(newTimeout);
  };
  
  const performSearch = async (query: string) => {
    // Implement your search logic here
    const matchingFolder = folders.find((folder) => folder.name.toLowerCase() === query.toLowerCase());

    // If a matching folder is found, set it as the selectedFolder
    if (matchingFolder) {
      console.log(searchFolder);
      setSelectedFolder(matchingFolder);
      console.log(matchingFolder);
    } else if (query === "" && !matchingFolder) {
      setSelectedFolder(folders[0]);
    } else {
      setSelectedFolder(null);
    }

    // Update the search query in the state
  };
  console.log(Format);
  // interface MarkdownTableProps {
  //     markdownString: string;
  //   }
  //   const tableStyle = {
  //     width: '100%',
  //     borderCollapse: 'collapse',
  //     marginBottom: '16px',
  //     borderRadius: '20px',
  //   };

  //   const thTdStyle = {

  //     paddingLeft: '18px',
  //     padding:'18px',
  //     textAlign: 'left',
  //     fontSize:'14px',
  //     color:'black'
  //   };

  //   const thStyle = {
  //     backgroundColor: '#E1E9F3',
  //   };
  // const tableStyle = {
  //   margin: '20px',
  //   border: '1px solid #ccc',
  //   borderRadius: '5px',
  //   overflow: 'hidden',
  // };

  // const thTdStyle = {
  //   padding: '10px',
  //   borderBottom: '1px solid #ccc',
  //   textAlign: 'left',
  // };

  // const thStyle = {
  //   backgroundColor: '#f2f2f2',
  //   fontWeight: 'bold',
  // };

  // const MarkdownTable: React.FC<MarkdownTableProps> = ({ markdownString }) => {
  //     return (

  //         <Markdown
  //         options={{
  //           overrides: {
  //             table: {
  //               component: 'table',
  //               props: { style: tableStyle },
  //             },
  //             th: {
  //               component: 'th',
  //               props: { style: { ...thTdStyle, ...thStyle } },
  //             },
  //             td: {
  //               component: 'td',
  //               props: { style: thTdStyle },
  //             },
  //           },
  //         }}
  //       >
  //         {markdownString}
  //       </Markdown>

  //     );

  //   };

  const downloadFromS3 = (s3Url: string) => {
    const link = document.createElement("a");
    link.href = s3Url;
    link.setAttribute("download", ""); // This attribute triggers a download when clicked
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleInputChangeFeedback = (event: any) => { 
    setFeedback(event.target.value);
  };
  const copyValueToClipboard = (valueToCopy: any) => {
    navigator.clipboard
      .writeText(valueToCopy)
      .then(() => {
        // Set a state or provide a message indicating successful copy
        setCopiedValue(valueToCopy);
        console.log("Value copied to clipboard:", valueToCopy);
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.copy"])));
      })
      .catch((error) => {
        console.error("Unable to copy:", error);
        dispatch(setNotificationMsgAction("Text Unable to Copy"));
        // Handle error if copying fails
      });
  };
  
  const handleSelectAllToggle = () => {
    if (!selectAllChecked) {
      // Select all folders
      const allFolderNames = folders.map((folder) => folder.name);
      setSelectedFolders(allFolderNames);
    } else {
      // Unselect all folders
      setSelectedFolders([]);
    }
    setSelectAllChecked(!selectAllChecked); // Toggle the state of "Select All" checkbox
  };

  const handleFolderClick = (folderName: any) => {
    setSelectedFolder(folderName);
  };
  const handleFolderToggle = (folderName: any) => {
    if (selectedFolders.includes(folderName)) {
      // If the folder is already selected, unselect it by removing its ID from the array
      setSelectedFolders(selectedFolders.filter((name) => name !== folderName));

      const allOtherchecked = folders.every((folder) => folder.name !== folderName || selectedFolders.includes(folder.name));
      if (allOtherchecked) {
        // If all other checkboxes are unchecked, uncheck the "Select All" checkbox
        setSelectAllChecked(false);
      }
    } else {
      // If the folder is not selected, select it by adding its ID to the array
      setSelectedFolders([...selectedFolders, folderName]);
    }
  };
  const ResetEverything = () => {
    setUserInput("");
    setUserInputForSearch("");
     setisopenforFolderTime(false);
     setDownloadProgressFolder(false)
    setCurrentid("")
    setId("");
    setSSEData([]);
    setMsgId("")
    setFileName("");
    setInputDisabled(false);
    onClose();
    setActiveStep(ENUM_ACTIVE_STEP.VIEW);
    setDrawerVisible(false);
    
  };
  const handlePostRequest = async () => {
    if(userInput){
      if(id){
    
    setInputDisabled(true);
    const res = await PostSemanticChatSession({ filename: filename, question: userInput,chat_id:id });
    if (!res){setInputDisabled(false); return;}
    setId(res?.data?.chat_id);
    setMsgId(res?.data?.message_id);
    setCurrentid(res?.data?.chat_id)
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
    console.log(res?.chat_id, "chatid");
   
    console.log(res);
    setUserInputForSearch("");}
  else{
    setInputDisabled(true);
    const res = await PostSemanticChat({ filename: filename, question: userInput });
    if (!res){setInputDisabled(false); return;}
    setId(res?.data?.chat_id);
    setMsgId(res?.data?.message_id);
    setCurrentid(res?.data?.chat_id)
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
    console.log(res?.chat_id, "chatid");

    console.log(res);
    setUserInputForSearch("")
    getchatsfunctioninitial()
  }
  }

  };
const handlePostRequestDirect = async (s: string) => {
  if(id){
  setInputDisabled(true);
 
  const res = await PostSemanticChatSession({ filename: filename, question: s ,chat_id:id});
  if (!res){setInputDisabled(false); return;}
  setUserInput(s);
  setCurrentid(res?.data?.chat_id)
  setId(res?.data?.chat_id);
    setMsgId(res?.data?.message_id);
  if (scrollableDivRef.current) {
    scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
  }
  console.log(res?.chat_id, "chatid");
  
  console.log(res);
  setUserInputForSearch("");}
  else{
    setInputDisabled(true);
    const res = await PostSemanticChat({ filename: filename, question: s });
    if (!res){setInputDisabled(false); return;}
    setUserInput(s);
    setCurrentid(res?.data?.chat_id)
    setId(res?.data?.chat_id);
    setMsgId(res?.data?.message_id);
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
    console.log(res?.chat_id, "chatid");
   
    console.log(res);
    setUserInputForSearch("");
    getchatsfunctioninitial()

  }
};
useEffect(() => {
  console.log("m on door");
  if (id) {
    if (userInput.trim() !== "") {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      }
      setSSEData((prevData) => [...prevData, {   id: msgid,
        question: userInput,
        answer: "Waiting for response...",
        created_at: "", // Set to empty string
        interested: null, // Set to null
        page_number: null, // Set to null
        k: 0, // Set to zero
        score_threshold: 0, // Set to zero
        status: ""}]);

    }

    const accessToken = token || ""; // Provide a default value if it's null
    console.log(accessToken);
    const headers: Record<string, string> = {
      Authorization: accessToken,
    };

    const eventSource = new EventSourcePolyfill(`${apiurl}/document/chat/${msgid}`, {
      headers,
    });

    eventSource.onmessage = (event) => {
      // const newResult = JSON.parse(event.data); // Assuming data is JSON
      // setSSEData((prevData) => [...prevData, { input: userInput, response: newResult }]);
      console.log("data", event);
    };

    // eventSource.addEventListener("pending", (event) => {
    //   console.log("pending", event);
    //   // const newElement = document.createElement("li");
    //   // const eventList = document.getElementById("list");
    //   // const time = JSON.parse(event.data).time;
    //   // newElement.textContent = `ping at ${time}`;
    //   //eventList.appendChild(newElement);
    // });
    eventSource.addEventListener("end", (event) => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      }
      console.log("end", event);
      const messageEvent = event as MessageEvent;
      console.log("end", event);
      const newResult = messageEvent?.data; // Assuming data is JSON
      setSSEData((prevData) => {
        // Update the specific entry matching the userInput
        console.log(currentid + "This is the one to be matched")
        const updatedData = prevData.map((data) => (data.id === msgid ? { ...data, answer: newResult } : data));

        return updatedData;
      });
      eventSource.close();
      setInputDisabled(false);
      setUserInput("")
      // const newElement = document.createElement("li");
      // const eventList = document.getElementById("list");
      // const time = JSON.parse(event.data).time;
      // newElement.textContent = `ping at ${time}`;
      //eventList.appendChild(newElement);
    });

    eventSource.onerror = (error) => {
      console.error("SSE Error:", error);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.connectionerror"])))
       eventSource.close();
       setInputDisabled(false);
       setUserInput("")
      // Enable input on SSE error
    };

    return () => {
      console.log("Fired Closed");
      eventSource.close(); 
    };
  }
}, [msgid]);
const handleCallChatSession = async(id:any)=>{
  const res = await getChatsSpecific({filename:filename,chat_id:id})
  setInputDisabled(false)
  setId(id)
  setSSEData(res?.data[0]?.messages)
  console.log(res?.data[0]?.messgages + "iclicked here")
  console.log(sseData + "iclicked here")
}

    const updateInterest = async (val:any) => {
      if(cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS)
     { const res = await semanticIntereseted({
        id: others?.id, 
        interested: val,
      });
      console.log(res);}
      else{
        const res = await semanticImageInterested({
          image_id: semanticSearchImageId, 
          file_id:others?.id,
          interested: val,
          
        });
        console.log(res);
      }
    };
  
   

  
  useEffect(() => {
    if (componentId === "SemanticSearchView" && !drawerOpen) {
      dispatch(setActionDrawerComponentAction(null));
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (others?.id) {
      if(cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS)
     { getAnswerDataById(others?.id);}
     else{
      getAnswerDataByIdImage(others?.id);
     }
    }
  }, [others]);

  const onClose = () => {
    dispatch(setActionDrawerOpenAction(false));
  };

  const getAnswerDataById = async (id: number) => {
    setActiveStep(ENUM_ACTIVE_STEP.LOADING);
    setLoading(true);
    const res = await getAnswerDataByIdrequest(id);
    setLoading(false);
    setActiveStep(ENUM_ACTIVE_STEP.VIEW);

    if (!res) return;  

    setAnswerData({ ...res?.data });
    setContent(res?.data?.phrased_result);
    sets3Link(res?.data?.s3_url);
    setisInterested(res?.data?.interested);
  };
  const getAnswerDataByIdImage = async (id: number) => {
    setActiveStep(ENUM_ACTIVE_STEP.LOADING);
    setLoading(true);
    const res = await getAnswerDataByIdImagerequest(id,semanticSearchImageId);
    setLoading(false);
    setActiveStep(ENUM_ACTIVE_STEP.VIEW);

    if (!res) return;  

    setAnswerData({ ...res?.data });
    setContent(res?.data?.description);
    sets3Link(res?.data?.s3_url);
    setisInterested(res?.data?.interested);
  };

  // useEffect(() => {
  //   if (others) {
  //     setContent(others?.phrased_result);
  //   }
  // }, [others]);

  // const getSummarisedDocument = async () => {
  //   setActiveStep(ENUM_ACTIVE_STEP.LOADING);

  //   const res = await getSemanticSummaryRequest(
  //     { filename: `${answerData?.folder_name}/${answerData?.filenames}` },
  //     undefined,
  //     dispatch
  //   );

  //   if (!res) return;

  //   if (res?.data) {
  //     setActiveStep(ENUM_ACTIVE_STEP.SUMMARIZED);
  //     setContent(res?.data?.summary);
  //   }
  // };
  const getSummarisedDocument = async () => {
    if (apiCounterRef1.current === 10) {
      console.log('Max retry count reached. Stopping retries.');
      setActiveStep(ENUM_ACTIVE_STEP.SUMMARIZED);
      setContent("");
      return;}
    setActiveStep(ENUM_ACTIVE_STEP.LOADING);
    clearTimeout(timerRef1.current);
    const res = await getSemanticSummaryRequest(
      { filename: `${answerData?.folder_name}/${answerData?.filenames}` },
      undefined,
      dispatch
    );
    if (res && res.data && res.data.status === 'SUCCESS') {
      setActiveStep(ENUM_ACTIVE_STEP.SUMMARIZED);
      setContent(res.data.summary);
    return;}
    
   if (res?.data?.status === "PENDING") {
      timerRef1.current = setTimeout(() => {
        setApiCounter(apiCounterRef1.current + 1);

        getSummarisedDocument();
      }, 7000); 
    } else {
      handleStopApiCall();
    }
  }; 


  const handleStopApiCall = () => {
    clearTimeout(timerRef1.current);

    handleCancelApiRequest();
  };
 
  console.log(timerRef1)

  const copyToClipboard = () => {
    const el = document.createElement("textarea");

    el.value = content;

    el?.setAttribute("id", "text-area");

    document.body.appendChild(el);

    el?.select();

    document?.execCommand("copy");

    document?.getElementById("text-area")?.remove();

    dispatch(setNotificationMsgAction("Text Copied Successfully!"));
  };
  console.log(Interested+"ss")
  return (
    <>
      <DrawerHeader >
        {
          <Row style={{ height: "100%" }}>
            <Col span={24}>
              {activeStep === ENUM_ACTIVE_STEP.VIEW && (
                <>
                  <Row>
                    <Col span={24}>
                      <Paragraph
                        type="secondary"
                        style={{
                          color: "#66676B"
                          ,
                          font:'normal normal 600 14px/22px Nunito Sans'
                        }}
                      >
                         {t(I18N_MODULE_KEYS["semantic.search.result.drawer.searchquery"])} : {searchQuery}
                      </Paragraph>
                    </Col>
                    <Col span={24}>
                      <Paragraph
                        type="secondary"
                        style={{
                          font: "normal normal bold 18px/30px Nunito Sans",
                          letterSpacing: "0px",
                          color: "#66676B",
                          opacity: "1"
                        }}
                      >
                        {cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS?(
                        answerData?.filenames
                        ):
                        (
                          answerData?.filename

                        )
                        }
                      </Paragraph>
                    </Col>
                    <Col xs={{span:8}} md={{span:8}}>
                    <Row gutter={[24, 24]} justify="start">
                        <Col xs={{ span: 24 }} md={{ span: 10 }}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}onClick={()=>{ if(Interested===true)
                              {updateInterest(null)
                                setisInterested(null) }
                            else{
                              updateInterest(true)
                              setisInterested(true)
                            }}}
                          >
                           {Interested === true ? ( // Render the thumbs-up icon conditionally
          <img
            src={PROJECT_ICONS.THUMBS_UP_SOLID} // Use the selected icon
            style={{
              backgroundColor: "#f7fcff",
              cursor: "pointer",
              fontSize: "18px",
              color: "#34C759", // Change the color for selected
              fontWeight: "600",
            }}
          />
        ) : (
          <img
            src={PROJECT_ICONS.THUMBS_UP}
            style={{
              backgroundColor: "#f7fcff",
              cursor: "pointer",
              fontSize: "18px",
              color: "#034EA2",
              fontWeight: "600",
            }}
          />
        )}
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              }}
                            >
                              {t(I18N_MODULE_KEYS["content.text.interested"])}
                            </span>
                          </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 14 }} style={{paddingLeft:'3px'}}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}onClick={async()=>{
                              if(Interested===false)
                             { updateInterest(null) 
                              setisInterested(null) 
                                
                            }
                            else{
                              updateInterest(false)
                              setisInterested(false)
                            }}
                          } 
                          >
                                                                 {Interested === false ? ( // Render the thumbs-up icon conditionally
          <img
            src={PROJECT_ICONS.THUMBS_UP_SOLID} // Use the selected icon
            style={{
              backgroundColor: "#f7fcff",
              cursor: "pointer",
              fontSize: "18px",
              color: "#34C759", // Change the color for selected
              fontWeight: "600",
              transform: "rotate(180deg)"
            }}
          />
        ) : (
          <img
            src={PROJECT_ICONS.THUMBS_DOWN}
            style={{
              backgroundColor: "#f7fcff",
              cursor: "pointer",
              fontSize: "18px",
              color: "#034EA2",
              fontWeight: "600",
            }}
          />
        )}
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              }}
                            >
                             {t(I18N_MODULE_KEYS["content.text.notinterested"])}
                            </span>
                          </div>
                        </Col>
                        
                      </Row>
                    </Col>
                    {cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS ?(
                    <Col xs={{ span: 16}} md={{span:16}} style={{paddingLeft:'80px'}}>
                      <Row gutter={[16, 16]} justify="end">
                        <Col xs={{ span: 24 }} md={{ span: 9}} >
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}
                          >
                            <img
                              src={PROJECT_ICONS.SEND_ICON}
                              style={{
                                backgroundColor: "#f7fcff",
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "#034EA2",
                                fontWeight: "600",
                                paddingLeft:'40px',
                                textAlign:'end'
                              }}
                            />
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px",
                                textAlign:'end'
                              }}
                            >
                              {t(I18N_MODULE_KEYS["content.text.sendtoreport"])}
                            </span>
                          </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }} style={{paddingRight:'10px'}}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}
                          >
                            <img
                              src={PROJECT_ICONS.SHARE_ICON}
                              style={{
                                backgroundColor: "#f7fcff",
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "#034EA2",
                                fontWeight: "600",
                             
                              }}
                            />
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              }}
                            >
                            {t(I18N_MODULE_KEYS["content.text.share"])}
                            </span>
                          </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 5 }}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}
                          >
                            <img
                              src={PROJECT_ICONS.DOWNLOAD_ICON}
                              style={{
                                backgroundColor: "#f7fcff",
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "#034EA2",
                                fontWeight: "600",
                                
                              }}
                            />
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              }}
                            >
                              {t(I18N_MODULE_KEYS["content.text.download"])}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>):(<></>)}
                    { cardType === SEMANTIC_SEARCH_CARD.DOCUMENTS ? (
                    <Col span={24}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
  <div
    style={{
      font: "normal normal bold 15px/22px Nunito Sans",
      letterSpacing: "0px",
      color: "#66676B",
      paddingTop: '15px',
      opacity: "1"
    }}
  >
    {t(I18N_MODULE_KEYS["semantic.search.result.drawer.pagenumber"])}
  </div>
  { cardType === SEMANTIC_SEARCH_CARD.DOCUMENTS ? (
  <div style={{paddingTop:'15px',paddingLeft:'5px'}}>
  {answerData?.page_number.length > 0 ? answerData?.page_number.map((item:any, index:any) => (
    <span key={index} style={{ font: "normal normal 15px/22px Nunito Sans", letterSpacing: "0px", color: "#66676B", paddingTop: '15px', opacity: "1" }}>
      {item}
      {index < answerData.page_number.length - 1 && ', '}
    </span>
  )):(
    <span  style={{ font: "normal normal 15px/22px Nunito Sans", letterSpacing: "0px", color: "#66676B", paddingTop: '15px', opacity: "1" }}>
      {t(I18N_MODULE_KEYS["semantic.search.result.drawer.notfound"])}
    </span>
  )}
</div>):(<></>)}

</div>

                    </Col>):(<></>)}
                  </Row>
                </>
              )}
              {activeStep === ENUM_ACTIVE_STEP.LOADING && !loading && (
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "#E6F6FF",
                    borderRadius: "6px",
                    width: "98%",
                    fontSize: "16px",
                    color: "#66676B",
                    marginTop: "10px"
                  }}
                >
                  Summarising the document..
                </div>
              )}
              {activeStep === ENUM_ACTIVE_STEP.SUMMARIZED && (
                <>
                  <Row>
                    <Col
                      xs={24}
                      className="d-flex"
                      style={{ flexDirection: "row" }}
                    >
                      <Avatar
                        icon={
                          <ArrowLeftOutlined style={{ color: "#0546a5" }} />
                        }
                        shape="square"
                        style={{
                          backgroundColor: "#f7fcff",
                          cursor: "pointer",
                          fontSize: "18px",
                          color: "#034EA2",
                          fontWeight: "600",
                          paddingRight:'10px'
                        }}
                        onClick={() => {
                          handleStopApiCall()
                          setContent(answerData?.phrased_result);
                          setActiveStep(ENUM_ACTIVE_STEP.VIEW);
                          
                        }}
                      />
                      <Paragraph
                        type="secondary"
                        style={{
                          font: "normal normal 600 18px/30px Nunito Sans",
                          letterSpacing: "0px",
                          color: "#034EA2",
                          opacity: "1",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          handleStopApiCall()
                          setContent(answerData?.phrased_result);
                          setActiveStep(ENUM_ACTIVE_STEP.VIEW);}}

                      >
                        Back to Document
                      </Paragraph>
                      {/* </div>{" "} */}
                    </Col>
                    <Col xs={24}>
                      <Paragraph
                        type="secondary"
                        style={{
                          font: "normal normal 600 18px/26px Nunito Sans",
                          letterSpacing: "0px",
                          color: "#231F20",
                          opacity: "1",
                          cursor: "pointer"
                        }}
                      >
                        Document Summarisation
                      </Paragraph>
                    </Col>
                    <Col xs={{ span: 24 }}>
                      <Row gutter={[24, 24]}>
                        <Col xs={{ span: 24 }} md={{ span: 3 }} style={{paddingLeft:'10px'}}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}
                          >
                            <img
                              src={PROJECT_ICONS.SHARE_ICON}
                              style={{
                                backgroundColor: "#f7fcff",
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "#034EA2",
                                fontWeight: "600"  ,paddingLeft:'10px'
                              }}
                            />
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              }}
                            >
                              {t(I18N_MODULE_KEYS["content.text.share"])}
                            </span>
                          </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4}} style={{paddingLeft:'10px'}}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}
                          >
                            <img
                              src={PROJECT_ICONS.DOWNLOAD_ICON}
                              style={{
                                backgroundColor: "#f7fcff",
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "#034EA2",
                                fontWeight: "600"  ,paddingLeft:'10px'
                              }}
                            />
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              
                              }}
                            >
                             {t(I18N_MODULE_KEYS["content.text.download"])}
                            </span>
                          </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }} style={{paddingLeft:'10px'}}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "row" }}
                          >
                            <img
                              src={PROJECT_ICONS.COPY_ICON}
                              style={{
                                backgroundColor: "#f7fcff",
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "#034EA2",
                                fontWeight: "600",
                                paddingLeft:'10px'
                              }}
                              onClick={copyToClipboard}
                            />
                            <span
                              style={{
                                font: "normal normal 600 16px/30px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#034EA2",
                                opacity: "1",
                                cursor: "pointer",
                                marginLeft: "10px"
                              }}
                              onClick={copyToClipboard}
                            >
                              Copy
                            </span>
                          </div>
                        </Col> 
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        }
      </DrawerHeader>
      <DrawerBody>
        {
          <Row>
            <Col
              style={{
                width: "100%"
              }}
            >
              {activeStep === ENUM_ACTIVE_STEP.LOADING ? (
              <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '500px',
        
              }}
            >
              <Lottie style={{ height: '50%' }} animationData={PROJECT_ICONS.CUP} loop={true} />
              <div style={{textAlign: 'center',
font: 'normal normal 600 18px/30px Nunito Sans',
letterSpacing: '0px',
color: '#66676B'}}>Grab a Cup of Coffee</div>
              <div style={{textAlign: 'center',
font: 'normal normal 600 18px/30px Nunito Sans',
letterSpacing: '0px',
color: '#66676B'}}>Loading is in Progress</div>
            </div>
            
            
              ) : (
                cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS?(
                <div
                    style={{
                    textAlign: "left",
                    font: "normal normal normal 16px/26px Nunito Sans",
                    letterSpacing: "0px",
                    color: "#66676B",
                    opacity: "1",
                    lineHeight: "26px",
                    marginTop: "10px",
                    overflowY:'scroll',
                    height:'460px' 
                  }}
                >
                  {content}
                </div>):(<>
                <Row style={{paddingTop:'20px'}} gutter={48}>
                <Col span={10} style={{background:'#E5F1F8',padding:'10px',marginLeft:'20px',borderRadius:'2px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Image style={{
                    lineHeight: "26px",
                   
                
                    width:"", // Set width to 100%
                    height: '300px', // Maintain aspect ratio
                    borderRadius:'5px',
                    padding:'10px'
                   }}
                   src={answerData?.s3_url}
                   preview={{
                    mask: <ImageMask />
                   }}
                   >
                {/* // src= "https://wallpapercave.com/wp/wp2649231.jpg"> */}

                   </Image>
                   </Col>
                  <Col span={13}
                    style={{
                    textAlign: "left",
                    font: "normal normal normal 16px/26px Nunito Sans",
                    letterSpacing: "0px",
                    color: "#66676B",
                    opacity: "1",
                    lineHeight: "26px",
                    marginTop: "10px",
                    overflowY:'scroll',
                    width:'60%',
                    height:'460px' 
                  }}
                >
                  {content===null?"No information to be displayed":content}
               </Col>
               </Row>
                </>)
              )}
            </Col>
          </Row>
        }
      </DrawerBody>
       {/* <DrawerFooter>
        {
          <Row>
            <Col
              className="d-flex"
              span={24}
              style={{
                justifyContent: "space-between",
                padding: "10px",
                alignItems: "center"
              }}
            >
              <Col
                className="pointer"
                style={{ color: "#0546a5", fontSize: "12px" }}
              ></Col>
              <Col>
                <Button
                  className="button-font button-with-border"
                  onClick={() => {
                    onClose();
                    setActiveStep(ENUM_ACTIVE_STEP.VIEW);
                  }}
                >
                  CLOSE
                </Button>
                {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
                  <CustomButtonForApiCalling
                    loading={
                      activeStep === ENUM_ACTIVE_STEP.LOADING && !loading
                        ? true
                        : false
                    }
                    btnText="SUMMARISE"
                    className="button-font button-blue"
                    style={{ margin: "10px" }}
                    onClick={getSummarisedDocument}
                  />
                )}
              </Col>
            </Col>
          </Row>
        }
     </DrawerFooter> */}
       <DrawerFooter>
        {
          // <Row>
          //   <Col
          //     className="d-flex"
          //     span={24}
          //     style={{
          //       justifyContent: "space-between",
          //       padding: "10px",
          //       alignItems: "center"
          //     }} 
          //   >

          //     <Col
          //       className="pointer"
          //       style={{ color: "#0546a5", fontSize: "12px" }}
          //     ></Col>
              
          //     {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
          //         <Button type="link" style={{alignItems:'flex-start'}}>View Document</Button>
          //       )}
                
          //       <Col>
          //       <Button
          //         className="button-font button-with-border"
          //         onClick={() => {
          //           onClose();
          //           setActiveStep(ENUM_ACTIVE_STEP.VIEW);
          //         }}
          //       >
          //         CLOSE
          //       </Button>
          //       {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
          //         <CustomButtonForApiCalling
          //           loading={
          //             activeStep === ENUM_ACTIVE_STEP.LOADING && !loading
          //               ? true
          //               : false
          //           }
          //           btnText="SUMMARISE"
          //           className="button-font button-blue"
          //           style={{ margin: "10px" }}
          //           onClick={getSummarisedDocument}
          //         />
          //       )}
              
          //     </Col>
          //   </Col>
          // </Row>
          <Row>
  <Col
    span={24}
    style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      alignItems: "center"
    }}
  >
    <Col className="pointer" style={{  paddingBottom:'10px'}}>
  
    </Col>
     
    
    
    <Col>
      <div style={{ textAlign: 'right' }}>
        <Button
          className="button-font button-with-border"
          onClick={() => {
            handleStopApiCall()
            onClose();
            setActiveStep(ENUM_ACTIVE_STEP.VIEW);

          }}
        >
         {t(I18N_MODULE_KEYS["content.text.close"])}
        </Button>
        {activeStep !== ENUM_ACTIVE_STEP.SUMMARIZED && (
          <CustomButtonForApiCalling
            // loading={
            //   activeStep === ENUM_ACTIVE_STEP.LOADING && !loading ? true : false
            // }
            loading={filedownloadinprogress}
            btnText={cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS?t(I18N_MODULE_KEYS["content.text.chat"]):t(I18N_MODULE_KEYS["content.text.download"])
          }
            className="button-font button-blue"
            style={{ margin: "10px" }}
            onClick={cardType===SEMANTIC_SEARCH_CARD.DOCUMENTS?() => showDrawer():()=>{downloadFile(answerData?.s3_url,answerData?.s3_url)}}
            
          />
        )}
      </div>
    </Col>
  </Col>
</Row>
        }
      </DrawerFooter> 
      <Drawer
        placement="right"
        width={1300}
        visible={drawerVisible}
        closable={false} // Hides the default close button
        mask={false}
        style={{ background: "#EEF4FF", padding: "8px" }}
      >
        <div style={{display:'flex',gap:'20px'}}>
        <div style={{ height: "650px", background: "white", borderRadius: "20px" ,minWidth:'30%',overflow:'auto'}}> 
        <div style={{ display: "flex", justifyContent: "space-between", padding: "32px", borderBottom: "1px solid #BABABA" }}>
                <div style={{ fontSize: "16px", display: "flex" }}>
                  <p style={{ fontWeight: "700" }}>{t(I18N_MODULE_KEYS["chatdocument.search.chathistory"])}</p>
               
                </div>
                <div style={{color:'#034EA2'}}>{t(I18N_MODULE_KEYS["chatdocument.search.clearall"])}</div>
              
          
            </div>
            <div></div>
            {isloadingpreviouschats ? (<><Spin></Spin></>):(<>
              {previouschats?.length > 0 ? (

        previouschats?.map(item => (
          <div key={item.id} style={{ display: "flex", justifyContent: "space-between", padding: "10px",cursor:'pointer'}} onClick={()=>{handleCallChatSession(item.chat_id)}}>
            <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
              <img style={{ fontSize: "16px", height: "30px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE} alt="Message Icon" />
              <div>
                <div style={{  fontSize: "16px", display: "flex" }}>
                  <Tooltip title={item.title}>
                    <p style={{ marginLeft: "2px", fontWeight: "bold" }}>{item?.title?.slice(0, 35)}...</p>
                  </Tooltip>
                </div>
                <div style={{ display: "flex" }}>
                  <img style={{ paddingTop: "5px", height: "18px" }} src={PROJECT_ICONS.UNION} alt="Union Icon" />
                  <p style={{ color: "#66676B", paddingTop: "2px", paddingLeft: "5px" }}>7 {t(I18N_MODULE_KEYS["chatdocument.search.pages"])}</p>
                </div>
              </div>
            </div>
            <div style={{padding: "12px",fontSize:'12px'}}>{item?.days_since_last_use}d</div>
          </div>
        ))
      ) : (
        <div  style={{ display: "flex", padding: "20px" , fontSize:'16px',fontWeight:'600'}}>{t(I18N_MODULE_KEYS["chatdocument.search.notfound"])}</div>
      )}</>)}
        </div>
        <div style={{ height: "650px", background: "white", borderRadius: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", borderBottom: "1px solid #BABABA" }}>
            <div style={{ padding: "10px", display: "flex", gap: "30px" }}>
              <img style={{ fontSize: "16px", height: "30px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
              <div>
                <div style={{ width: "430px", fontSize: "16px", display: "flex" }}>
                  <p style={{ color: "#66676B", fontWeight: "500" }}>{t(I18N_MODULE_KEYS["chatdocument.search.chatwith"])}</p>
                  <Tooltip title={filename}>
                    <p style={{ marginLeft: "5px", fontWeight: "bold" }}>{filename?.slice(0, 36)}...</p>
                  </Tooltip>
                </div>
                <div style={{ display: "flex" }}>
                  <img style={{ paddingTop: "5px", height: "18px" }} src={PROJECT_ICONS.UNION} />
                  <p style={{ color: "#66676B", paddingTop: "2px", paddingLeft: "5px" }}>7 {t(I18N_MODULE_KEYS["chatdocument.search.pages"])}</p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "90px", padding: "10px" }}>
              <div
                style={{ color: "#034EA2", fontSize: "16px", width:'180px',fontWeight: "600", padding: "2px", cursor: "pointer", position: "relative" }}
                onClick={() => {
                  {
                    !downloadinprogressfolder ? setisopenforFolderTime(!isopenforFolderTime) : <></>;
                  }
                }}
              >
                <div style={{display:'flex'}}>
                {t(I18N_MODULE_KEYS["chatdocument.search.downloadchat"])}{" "}
                  {!downloadinprogressfolder ? (
                    <img
                      src={PROJECT_ICONS.ARROW}
                      style={{ paddingLeft: "7px", height:'8px',transform: !isopenforFolderTime ? "rotate(180deg)" : "rotate(0deg)", paddingRight: "5px",marginTop:'7px'}}
                    />
                  ) : (
                    <>
                      <Spin style={{ paddingLeft: "5px" }}></Spin>
                    </>
                  )}
                </div>
                {isopenforFolderTime ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "32px",
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fff",
                      zIndex: 1000,
                      borderRadius: "3px",
                      boxShadow: "0px 2px 3px #0000001F",
                    }}
                  >
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("1d");
                        setisopenforFolderTime(false);
                      }}
                    >
                     {t(I18N_MODULE_KEYS["chatdocument.search.today"])}
                    </div>
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("7d");
                        setisopenforFolderTime(false);
                      }}
                    >
                     {t(I18N_MODULE_KEYS["chatdocument.search.7days"])}
                    </div>
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("1m");
                        setisopenforFolderTime(false);
                      }}
                    >
                      {t(I18N_MODULE_KEYS["chatdocument.search.lastmonth"])}
                    </div>
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("1y");
                        setisopenforFolderTime(false);
                      }}
                    > 
                      {t(I18N_MODULE_KEYS["chatdocument.search.lastyear"])}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ color: "#034EA2", fontSize: "28px", fontWeight: "600", cursor: "pointer" }} onClick={ResetEverything}>
                X
              </div>
            </div>
          </div>
        
          <div style={{ padding: "15px" }}>
            <div ref={scrollableDivRef} style={{ height: "440px", overflowY: "auto", padding: "10px" }}>
              <div>
                <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                  <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                  <p style={{ fontSize: "15px", marginLeft: "25px", background: "#8C32FF", color: "white", borderRadius: "8px", padding: "10px" }}>
                    Don't know what to ask ? Click on any suggested topics or ask your own question
                  </p>
                </div>
                <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                  <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                  <div>
                    <p style={{ fontSize: "15px", marginLeft: "25px", background: "#F8F9FD", color: "black", borderRadius: "8px", padding: "10px" }}>
                      Suggested Topics
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        marginLeft: "25px",
                        background: "#F8F9FD",
                        color: "#034EA2",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        paddingLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handlePostRequestDirect("Provide summary of this document");
                      }}
                    >
                      Provide summary of this document
                    </p>
                    <p
                      onClick={() => {
                        handlePostRequestDirect("Give interesting insights from this document");
                      }}
                      style={{
                        fontSize: "15px",
                        marginLeft: "25px",
                        background: "#F8F9FD",
                        color: "#034EA2",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Give interesting insights from this document
                    </p>
                    {recommendQuestion?.map((question, index) => (
  <p
    key={index}
    onClick={() => {
      handlePostRequestDirect(`${question}`);
    }}
    style={{
      fontSize: "15px",
      marginLeft: "25px",
      background: "#F8F9FD",
      color: "#034EA2",
      textDecoration: "underline",
      borderRadius: "8px",
      padding: "10px",
      paddingTop: '2px',
      cursor: "pointer",
    }}
  >
    {question}
  </p>
))}

                  </div>
                </div>
              </div>
              {sseData?.map((data, index) => (
                <div key={index}>
                  <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                    <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.PERSON}></img>
                    <p style={{ fontSize: "15px", marginLeft: "25px", background: "#8C32FF", color: "white", borderRadius: "8px", padding: "10px" }}>
                      {data.question}
                    </p>
                  </div>
                  <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                    <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                    <p style={{ fontSize: "15px", marginLeft: "25px", background: "#F8F9FD", color: "black", borderRadius: "8px", padding: "10px" }}>
                      {data.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "20px" }}>
              <Input
                style={{ border: "1px solid #949494 ", borderRadius: "3px" }}
                value={userInputForSearch}
                suffix={
                  <Button
                    style={{ border: "none" }}
                    ref={buttonRef}
                    disabled={disable}
                    onClick={
                      () => {
                        handlePostRequest();
                      }
                      // savePrompt(searchTerm && searchQuery ? false : true)
                    }

                    // disabled={isLoading}
                  >
                    <span style={{ color: "#66676B", fontSize: "10px" }}>{t(I18N_MODULE_KEYS["semantic.search.input.heading.search"])}</span>
                  </Button>
                }
                placeholder= {t(I18N_MODULE_KEYS["semantic.search.input.heading.entersearch"])}
                onChange={handleUserInput}
                disabled={disable}
              />
            </div>
          </div>
        </div>
        </div>
      </Drawer>
    </>
  );
};
 