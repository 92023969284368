import React, { useEffect, useState } from "react";
import { SlideDataType } from "../core/modals";
import { NarrationArea } from "./NarrationArea";
import { useSelector } from "react-redux";
import { storyBoardDashboardRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { CT_DASHBOARD_STATUS } from "../../../../Utils";

export const NarrationAreaList = React.memo(({ slides, activeSlide, setAudioList, globalPlayer }: { slides: SlideDataType[]; activeSlide: number; setAudioList: Function, globalPlayer: any }) => {
  const { elaiResponse } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [stories, setStories] = useState<any[]>([]);

  useEffect(() => {
    createStoryBoardDashBoard(elaiResponse?.storyboard_id);
  }, []);

  const createStoryBoardDashBoard = async (storyboard_id: number) => {
    const res = await storyBoardDashboardRequest(storyboard_id);

    if (res?.error && res?.status === CT_DASHBOARD_STATUS.FAILED) {
      return;
    }

    if (res && res?.storyboard && res?.storyboard?.stories) {
      setStories(res?.storyboard?.stories);
      return;
    }
  };

  return (
    <>
      {slides[activeSlide] ? (
        <div style={{ padding: "8px", width: "100%" }}>
          <div key={slides[activeSlide]?.id}>
            <NarrationArea slide={slides[activeSlide]} activeSlide={activeSlide} story={stories[activeSlide]} setAudioList={setAudioList} globalPlayer={globalPlayer}/>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
});
