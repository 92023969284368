import React, { useState, useEffect } from "react";
import { Col, Image, Tag } from "antd"; // Import Image component from Ant Design
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useSelector } from "react-redux";
import { MessageType } from "../Core/constants";

const TypedAnimation = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const { cardData } = useSelector((state: any) => state?.semanticSearchReducer);

  useEffect(() => {
    const textAndImagesToType: MessageType[] = [
      { text: " Understanding question", image: PROJECT_ICONS.VECTOR6 },
      { text: "Reading response", image: PROJECT_ICONS.VECTOR7 },
      { text: "Searching universe", image: PROJECT_ICONS.GROUP2 },
      { text: `Found ${cardData && cardData.filenames ? Object.entries(cardData.filenames).length : 0} sources`, image: PROJECT_ICONS.GROUP11913 },
    ];

    const typingInterval = setInterval(() => {
      if (textAndImagesToType.length === 0) {
        clearInterval(typingInterval);
      } else {
        const nextItem = textAndImagesToType.shift();
        if (nextItem) {
          setMessages((prevMessages) => [...prevMessages, nextItem]);
        }
      }
    }, 1000); // Adjust typing speed here (milliseconds)

    return () => clearInterval(typingInterval);
  }, []);

  return (
    <Col span={16} style={{ marginTop: "20px" }}>
      <div style={{ background: "#F4F6F9", padding: "17px", paddingLeft: "20px", borderRadius: "10px" }}>
        {messages.map((message, index) =>
          index === 0 ? (
            <>
              <div key={index} style={{ display: "flex", gap: "10px", padding: "6px" }}>
                <Image src={PROJECT_ICONS.COMPASS} preview={false} style={{ paddingTop: "2px" }}></Image>
                <div style={{ fontWeight: "600", fontSize: "18px" }}>Pro Mode</div>
                <div style={{ paddingTop: "2px" }}>|</div>
                <Image src={PROJECT_ICONS.VECTOR6} preview={false}></Image>
                <div style={{ color: "#66676B", fontSize: "14px", paddingTop: "2px" }}>{message.text}</div>
              </div>
            </>
          ) : index == 1 ? (
            <>
              <div key={index} style={{ display: "flex", gap: "10px", padding: "6px" }}>
                <Image src={message.image} preview={false}></Image>
                <div style={{ color: "#66676B", fontSize: "14px", paddingLeft: "10px", display: "flex", alignItems: "center" }}>{message.text}</div>
              </div>
              <div style={{ marginLeft: "30px" }}>
                {cardData &&
                  cardData.tags &&
                  cardData.tags.length > 0 &&
                  cardData.tags.map((tag: any, index: any) => (
                    <Tag
                      key={index}
                      style={{
                        backgroundColor: "#E0E2E6",
                        color: "#66676B",
                        borderRadius: "24px",
                        marginLeft: index > 0 ? "5px" : "0",
                        marginBottom: "5px",
                      }}
                    >
                      {tag}
                    </Tag>
                  ))}
              </div>
            </>
          ) : (
            <>
              <>
                <div key={index} style={{ display: "flex", gap: "10px", padding: "6px" }}>
                  <Image src={message.image} preview={false}></Image>
                  <div style={{ color: "#66676B", fontSize: "14px", paddingLeft: "10px", display: "flex", alignItems: "center" }}>{message.text}</div>
                </div>
              </>
            </>
          )
        )}
      </div>
    </Col>
  );
};

export default TypedAnimation;
