import { Button, Col, Image, Input, Modal, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { DeleteOutlined, EditOutlined, FolderOutlined } from "@ant-design/icons";
import { I18N_MODULE_KEYS } from "../../../../../../Components/i18n/core/constants";
import { Folder } from "../../../Core/constants";
import { PROJECT_ICONS } from "../../../../../../Assets/Img/_DIcons";
const { Paragraph } = Typography;

export interface ModalForCreatingSubFolderProps {
    modalForSubFolder: boolean;
    setModalForSubFolderTrue: (value: boolean) => void;
    handleCreateSUBFolder: () => void;
    inputValueForSubFolder: string;
    handleInputChangeForSubFolder: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ModalForCreatingSubFolder: React.FC<ModalForCreatingSubFolderProps> = ({
  modalForSubFolder,
  setModalForSubFolderTrue,
  handleCreateSUBFolder,
  inputValueForSubFolder,
  handleInputChangeForSubFolder,
}) => {
  const { tabList, folderData } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  console.log(tabList, "tabList");

  return (
    <>
      <Modal
        width={400}
        title=""
        centered
        visible={modalForSubFolder}
        onCancel={() => {
          setModalForSubFolderTrue(false);
        }}
        onOk={() => {
          handleCreateSUBFolder();
        }}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: "rgb(30, 77, 161)", color: "white" } }} // Add this line
      >
        <div style={{ padding: "10px" }}>
          <p style={{ fontWeight: "bold", color: "black" }}>Name for the sub-folder : </p>
          <Input
            value={inputValueForSubFolder}
            onChange={handleInputChangeForSubFolder}
            style={{ marginTop: "10px" }} // Optional: Add some space between the label and input
          />
        </div>
      </Modal>
    </>
  );
};
