import { FC } from "react";
import { Pagination, Table } from "antd";
import { DataType, PageTypes } from "../../core/modals";
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";
import { StatusButton } from "./_StatusButton";
import { enumHistoryIndex } from "../../core/constants";
import { Tooltip } from "antd";
import { getUTCtoLocalDateTimeString } from "../../../../../Utils";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

type CHListViewTypes = {
  list: DataType[];
  handleOnChange: (current: number) => void;
  page: PageTypes;
};

export const CHListView: FC<CHListViewTypes> = ({ list, handleOnChange, page }) => {
  const { t } = useTranslation();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  const ContentHistoryColumns: ColumnsType<DataType> = [
    {
      // title: "Name",
      title: `${t(I18N_MODULE_KEYS["content.text.name"])}`,
      dataIndex: "filename",
      width: "2%",
      render: (value, record, index) => (
        <Tooltip title={value || ""}>
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {/* {value?.includes(".pdf") ? value : value?.slice(0, 30) + "..." || " "} */}
            {value?.length < 30 ? value : value?.slice(0, 30) + "..." || " "}
          </span>
        </Tooltip>
      )
    },
    {
      // title: "Uploaded On",
      title: `${t(I18N_MODULE_KEYS["content.text.uploadedon"])}`,
      dataIndex: "uploaded_on",
      render: (value) => <div style={{ whiteSpace: "nowrap" }}>{getUTCtoLocalDateTimeString(value)}</div>
    },
    // {
    //   title: "HTML Email",
    //   dataIndex: "html",
    //   render: (value, record, index) => <StatusButton value={value} record={record} index={enumHistoryIndex.HTML} />
    // },
    {
      // title: "Story board",
      title: `${t(I18N_MODULE_KEYS["content.text.storyboard"])}`,
      dataIndex: "storyboard",
      render: (value, record, index) => <StatusButton value={value} record={record} index={enumHistoryIndex.STORYBOARD} />
    },
    {
      // title: "Image/Clips",
      title: `${t(I18N_MODULE_KEYS["content.text.image/clips"])}`,
      dataIndex: "image",
      render: (value, record, index) => <StatusButton value={value} record={record} index={enumHistoryIndex.IMAGE} />
    },
    {
      // title: "Video",
      title: `${t(I18N_MODULE_KEYS["content.text.video"])}`,
      dataIndex: "video",
      render: (value, record, index) => <StatusButton value={value} record={record} index={enumHistoryIndex.VIDEO} />
    },
    {
      // title: "Action",
      title: `${t(I18N_MODULE_KEYS["content.text.action"])}`,
      dataIndex: "action",
      render: (value, record, index) => <StatusButton value={{ id: null, status: "Delete" }} record={record} index={6} />
    }
  ];

  return (
    <>
      <Table columns={ContentHistoryColumns} dataSource={list} pagination={false} loading={isLoading} />
      <div className="d-flex" style={{ justifyContent: "flex-end", marginTop: "10px" }}>
        <Pagination showSizeChanger={false} defaultCurrent={1} pageSize={page?.page_size} total={page?.total_count} onChange={handleOnChange} />
      </div>
    </>
  );
};
