import { FC, useEffect, useRef, useState } from "react";
import { SearchOutlined, PlusOutlined, EyeOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Image, Input, Row, Space, Spin, Tag, Typography } from "antd";
import {
  getLiveImagesForGenerateImagesRequest,
  getLiveVideosForGenerateImagesRequest,
  saveLiveImageRequest,
  saveLiveVideoRequest
} from "../../../../../../Store/requests/_contentTranscreationRequests";
import "./AddLiveImagesOrVideos.css";
import { useDispatch, useSelector } from "react-redux";
import { setActiveObjectModifier, setRefetchListStateAction } from "../../../../../../Store/actions/_contentTranscreationActions";
import { setNotificationMsgAction } from "../../../../../../Store/actions/_commonActions";
import { globalConstant } from "../../../../../../Utils";
import { I18N_MODULE_KEYS } from "../../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../../Store";

const { Paragraph } = Typography;

type ImageListTypes = {
  id: number;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  photographer_id: number;
  avg_color: string;
  visible: boolean;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
  liked: boolean;
  alt: string;
};

type VideoListTypes = {
  visible: boolean;
  id: number;
  width: number;
  height: number;
  duration: number;
  full_res: null | any;
  tags: any[];
  url: string;
  image: string;
  avg_color: null | any;
  user: {
    id: number;
    name: string;
    url: string;
  };
  video_files: {
    id: number;
    quality: string;
    file_type: string;
    width: number;
    height: number;
    fps: number;
    link: string;
  }[];
  video_pictures: {
    id: number;
    nr: number;
    picture: string;
  }[];
};

const ENUM_SELECTED_TAG = {
  IMAGES: "Images",
  VIDEOS: "Videos"
};

export const AddLiveImagesOrVideos: FC<{ visible: boolean }> = ({ visible: isVisible }) => {
  const dispatch = useDispatch();

  const btnRef: any = useRef();

  const { t } = useTranslation();

  const { canvasActiveObject } = useSelector((state: RootState) => state?.contentTranscreationReducer);

  const [page, setPage] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [imageList, setImageList] = useState<ImageListTypes[]>([]);
  const [videoList, setVideoList] = useState<VideoListTypes[]>([]);
  const [renderLoading, setRenderLoading] = useState<boolean>(true);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string>(ENUM_SELECTED_TAG.IMAGES);

  // useEffect(() => {
  //   initiate();
  // }, []);

  // const initiate = async () => {
  //   getLiveImagesOrVideos("medical", 1, true);
  // };

  // useEffect(() => {
  //   setSearchQuery("");
  //   getLiveImagesOrVideos("medical", 1, true);
  //   if (selectedTag === ENUM_SELECTED_TAG.VIDEOS && !videoList?.length) {
  //   }
  // }, [selectedTag]);

  // useEffect(() => {
  //   if (others?.scene) {
  //     setSearchQuery(others?.scene);
  //     getLiveImagesOrVideos(others?.scene, 1, true);
  //   }
  // }, [others]);
  useEffect(() => {
    getLiveImagesOrVideos(searchQuery, 1, true);
  }, [selectedTag]);

  const handleToggling = (selectedTag: string) => {
    setSelectedTag(selectedTag);
  };

  const getLiveImagesOrVideos = async (query: string, page: number, isSearched: boolean) => {
    let res;

    setSearchLoading(true);

    if (selectedTag === ENUM_SELECTED_TAG.IMAGES) res = await getLiveImagesForGenerateImagesRequest(query || "medical", page);
    else if (selectedTag === ENUM_SELECTED_TAG.VIDEOS) res = await getLiveVideosForGenerateImagesRequest(query || "doctor", page);

    setSearchLoading(false);

    if (!res) {
      console.log("no Data Found");
      emptyImageOrVideo();
      return;
    }

    if (res[selectedTag === ENUM_SELECTED_TAG.IMAGES ? "photos" : "videos"]?.length) {
      if (selectedTag === ENUM_SELECTED_TAG.IMAGES) {
        if (isSearched) setImageList(res?.photos);
        else setImageList(pushNewEntries(imageList, res?.photos));
      } else if (selectedTag === ENUM_SELECTED_TAG.VIDEOS) {
        if (isSearched) setVideoList(res?.videos);
        else setVideoList(pushNewEntries(videoList, res?.videos));
      }
    } else {
      emptyImageOrVideo();
    }
  };

  const emptyImageOrVideo = () => {
    setImageList([]);
    setVideoList([]);
  };

  const handleShowMore = () => {
    getLiveImagesOrVideos(searchQuery, page + 1, false);
  };

  const pushNewEntries = (prevArray: any[], newArray: []) => {
    const arrPrevCopy: any[] = [...prevArray];
    arrPrevCopy.push(...newArray);
    setPage((p) => p + 1);
    return [...arrPrevCopy];
  };

  const getMaxResolutionVideoUrlNew = (video_files: any[]) => {
    //get that video url whose height is maximum of all items.

    let arrHeights: number[] = video_files
      ?.filter((item: any) => item?.quality === "hd" || item?.quality === "uhd")
      ?.sort(function (a, b) {
        return a?.height - b?.height;
      });

    let objVideoFile: any = arrHeights[arrHeights?.length - 1];

    return objVideoFile || "";
  };

  const getMaxResolutionVideoUrl = (video_files: any[]) => {
    //get that video url whose height is maximum of all items.

    let arrHeights: number[] = video_files
      ?.map((item: any) => item?.height)
      ?.sort(function (a, b) {
        return a - b;
      });

    let objVideoFile: any = video_files?.find((item: any) => item?.quality === "hd" && item?.height === arrHeights[arrHeights?.length - 1]);

    return objVideoFile?.link || "";
  };

  const placeImageOnCanvas = (imageUrl: string) => {
    //addToCanvas

    dispatch(setActiveObjectModifier({ newObject: "image", url: imageUrl }));
  };
  const placeVideoOnCanvas = (file: any) => {
    //addToCanvas

    const videos = file.video_files.filter((f: any) => f.quality === "hd" && f.width <= 1920);
    videos.sort((a: any, b: any) => b.width - a.width);
    dispatch(
      setActiveObjectModifier({
        newObject: "video",
        url: videos.length ? videos[0].link : file.video_files[0].link,
        thumbnail: file.screenshot
      })
    );
  };

  const handleAddLiveImageOrVideo = async (selectedItem: any) => {
    let res;

    // dispatch(
    //   setActionDrawerDataAction({
    //     drawerOpen: false,
    //     drawerWidth: "400px",
    //     componentId: "",
    //     drawerTitle: ""
    //   })
    // );

    if (selectedTag === ENUM_SELECTED_TAG.IMAGES) {
      //res = await saveLiveImageRequest({ story_id: others?.story_id, image_url: selectedItem?.src?.original });
      console.log("Image Src:", selectedItem?.src?.original);
      res = await placeImageOnCanvas(selectedItem?.src?.original);
    } else if (selectedTag === ENUM_SELECTED_TAG.VIDEOS) {
      placeVideoOnCanvas(selectedItem);
      //const objMaxResFile = getMaxResolutionVideoUrlNew(selectedItem?.video_files);
      //return;
      // res = await saveLiveVideoRequest({
      //   story_id: others?.story_id,
      //   video: {
      //     thumbnail: selectedItem?.video_pictures?.find((item: any, index: number) => index === 0)?.picture,
      //     source: objMaxResFile?.link,
      //     width: objMaxResFile?.width,
      //     height: objMaxResFile?.height
      //   }
      // video_url: selectedItem?.video_files?.find((item: any) => item?.quality === "hd" && item?.height  1920)?.link,
      // video_url: getMaxResolutionVideoUrl(selectedItem?.video_files),
      // thumbnail_url: selectedItem?.video_pictures?.find((item: any, index: number) => index === 0)?.picture
      //});
    }

    // if (!res) {
    //   console.log("no Image or video saved");
    //   dispatch(setNotificationMsgAction(globalConstant.SOMETHING_WENT_WRONG));
    //   return;
    // }

    // if (res?.message || res?.length) dispatch(setRefetchListStateAction(true));
    // else dispatch(setNotificationMsgAction(globalConstant.SOMETHING_WENT_WRONG));
  };

  const changeItemVisibility = (index: number, visibleStatus: boolean) => {
    let arrList: VideoListTypes[] | ImageListTypes[] | any;

    if (selectedTag === ENUM_SELECTED_TAG.IMAGES) arrList = [...imageList];
    else arrList = [...videoList];

    arrList[index]["visible"] = visibleStatus;

    if (selectedTag === ENUM_SELECTED_TAG.IMAGES) setImageList(arrList);
    else setVideoList(arrList);
  };

  const handlePreview = (item: VideoListTypes | ImageListTypes, index: number, visibleStatus: boolean, isAdd: boolean, forImage: boolean) => {
    if (isAdd) {
      handleAddLiveImageOrVideo(item);
      changeItemVisibility(index, false);
    } else {
      changeItemVisibility(index, visibleStatus);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ display: isVisible ? "flex" : "none", height: "100%", flexDirection: "column" }}>
        <div style={{ display: "flex", width: "100%", flex: "none" }}>
          <Col xs={{ span: 4 }}>
            <Tag
              className="pointer"
              color={selectedTag === ENUM_SELECTED_TAG.IMAGES ? "processing" : "default"}
              onClick={() => handleToggling(ENUM_SELECTED_TAG.IMAGES)}
            >
              {ENUM_SELECTED_TAG.IMAGES}
            </Tag>
          </Col>

          <Col xs={{ span: 4 }} /* style={{ display: "none" }} */>
            <Tag
              className="pointer"
              color={selectedTag === ENUM_SELECTED_TAG.VIDEOS ? "processing" : "default"}
              onClick={() => handleToggling(ENUM_SELECTED_TAG.VIDEOS)}
            >
              {ENUM_SELECTED_TAG.VIDEOS}
            </Tag>
          </Col>
        </div>

        <Col xs={{ span: 24 }} style={{ flex: "none" }}>
          <Input
            suffix={
              <Button
                type="link"
                onClick={() => {
                  emptyImageOrVideo();
                  setSearchLoading(true);
                  setTimeout(() => {
                    getLiveImagesOrVideos(searchQuery, 1, true);
                  }, 100);
                }}
                ref={btnRef}
                disabled={searchLoading}
              >
                <SearchOutlined />
              </Button>
            }
            placeholder="Search awesome media on Pexels"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e?.target?.value);
            }}
            readOnly={searchLoading}
            disabled={searchLoading}
            onKeyDownCapture={(e) => {
              const { which } = e;

              if (which === 13) {
                btnRef?.current?.click();
              }
            }}
          />
        </Col>
        <Col xs={{ span: 24 }} style={{ flex: 1 }}>
          <Row gutter={[8, 8]} style={{ height: "100%", overflowY: "scroll" }}>
            {selectedTag === ENUM_SELECTED_TAG.IMAGES && (
              <>
                {imageList && imageList?.length ? (
                  <>
                    {imageList?.map((item: ImageListTypes, index: number) => {
                      return (
                        <Col xs={{ span: 8 }} key={`imageList_${index}`}>
                          <Image
                            src={item?.src?.tiny}
                            style={{ borderRadius: "5px", width: "107px", height: "61px", objectFit: "cover" }}
                            preview={{
                              visible: item?.visible,
                              closeIcon: <CustomCloseIcon handleCloseIconClick={() => handlePreview(item, index, false, false, true)} />,
                              mask: (
                                <CustomOverlay
                                  handleClickAddIcon={() => {
                                    console.log("handleClickAddIcon called");
                                    handlePreview(item, index, false, true, true);
                                  }}
                                  handleClickPreviewIcon={() => {
                                    handlePreview(item, index, true, false, true);
                                  }}
                                />
                              ),

                              imageRender: () => (
                                <PreviewModal
                                  visible={visible}
                                  key={`imageList_${item?.id}`}
                                  id={`image_preview_${item?.id}`}
                                  handleAdd={() => {
                                    handleAddLiveImageOrVideo(item);
                                    changeItemVisibility(index, false);
                                  }}
                                  onCancel={() => setVisible(false)}
                                  loading={renderLoading}
                                >
                                  <Image
                                    id={`video_preview_${index}`}
                                    alt="example"
                                    style={{ maxHeight: "80vh" }}
                                    src={item?.src?.original}
                                    preview={false}
                                    loading={"eager"}
                                    onLoad={() => {
                                      setRenderLoading(false);
                                    }}
                                    onEnded={() => setRenderLoading(true)}
                                  />
                                </PreviewModal>
                              ),
                              toolbarRender: () => null
                            }}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {!searchLoading && (
                      <Paragraph
                        type="secondary"
                        className="optimize-font"
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        No {selectedTag} Found!
                      </Paragraph>
                    )}
                  </>
                )}
              </>
            )}

            {selectedTag === ENUM_SELECTED_TAG.VIDEOS && (
              <>
                {videoList && videoList?.length ? (
                  <>
                    {videoList?.map((item: VideoListTypes, index: number) => {
                      return (
                        <Col xs={{ span: 8 }} key={`videoList_${index}`}>
                          <Image
                            src={item?.video_pictures?.find((item: any, index: number) => index === 0)?.picture}
                            style={{ borderRadius: "5px", width: "107px", height: "61px", objectFit: "cover" }}
                            preview={{
                              visible: item?.visible,
                              closeIcon: <CustomCloseIcon handleCloseIconClick={() => handlePreview(item, index, false, false, false)} />,
                              mask: (
                                <CustomOverlay
                                  handleClickAddIcon={() => {
                                    handlePreview(item, index, false, true, false);
                                  }}
                                  handleClickPreviewIcon={() => {
                                    handlePreview(item, index, true, false, false);
                                  }}
                                />
                              ),

                              imageRender: () => (
                                <PreviewModal
                                  key={`videoList_${item?.id}`}
                                  id={`video_preview_${item?.id}`}
                                  handleAdd={() => {
                                    handleAddLiveImageOrVideo(item);
                                    changeItemVisibility(index, false);
                                  }}
                                  visible={visible}
                                  onCancel={() => setVisible(false)}
                                  loading={renderLoading}
                                >
                                  <video
                                    id={`image_preview_${index}`}
                                    style={{ maxHeight: "50vh" }}
                                    controls
                                    // src={item?.video_files?.find((item: any) => item?.quality === "hd")?.link}
                                    src={getMaxResolutionVideoUrlNew(item?.video_files)?.link}
                                    onLoadedData={() => setRenderLoading(false)}
                                    onEnded={() => setRenderLoading(true)}
                                  />
                                </PreviewModal>
                              ),
                              toolbarRender: () => null
                            }}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {!searchLoading && (
                      <Paragraph
                        type="secondary"
                        className="optimize-font"
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        {/* No {selectedTag} Found! */}
                        {t(I18N_MODULE_KEYS["content.text.novideosfound"])}
                      </Paragraph>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        </Col>

        <Col xs={{ span: 24 }} style={{ flex: "none" }}>
          {searchLoading ? (
            <Spin className="d-flex justify-content-center" />
          ) : (
            <Button type="default" style={{ width: "100%" }} onClick={handleShowMore}>
              {/* Show More */}
              {t(I18N_MODULE_KEYS["content.button.showmore"])}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

type PreviewModalTypes = {
  children: any;
  loading?: boolean;
  visible?: boolean;
  onCancel: () => void;
  handleAdd: () => void;
  id: string;
};

const PreviewModal: FC<PreviewModalTypes> = ({ children, loading, handleAdd, visible, onCancel, id }) => {
  const { t } = useTranslation();
  return (
    <Space style={{ background: "white", borderRadius: "10px" }} direction="vertical">
      {children}
      <div style={{ display: "flex", justifyContent: loading ? "center" : "flex-end", marginBottom: "10px", marginRight: "10px" }}>
        {loading ? (
          <Spin />
        ) : (
          <Button type="primary" onClick={handleAdd}>
            {t(I18N_MODULE_KEYS["content.button.add"])}
          </Button>
        )}
      </div>
    </Space>
  );
};

type CustomOverLayTypes = {
  handleClickAddIcon: () => void;
  handleClickPreviewIcon: () => void;
};

const CustomOverlay: FC<CustomOverLayTypes> = ({ handleClickAddIcon, handleClickPreviewIcon }) => {
  return (
    <Space>
      <PlusOutlined onClick={handleClickAddIcon} />
      <EyeOutlined onClick={handleClickPreviewIcon} />
    </Space>
  );
};

type CustomCloseIconsProps = {
  handleCloseIconClick: () => void;
};
const CustomCloseIcon: FC<CustomCloseIconsProps> = ({ handleCloseIconClick }) => {
  return (
    <Space onClick={handleCloseIconClick}>
      <CloseOutlined />
    </Space>
  );
};
