import { PauseOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row, Slider, Spin } from "antd";
import { SlideDataType, VoiceListGenderType, VoiceListType } from "../core/modals";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { audioData } from "../core/contentTranslationMockAudioData";
import { pauseVideos, playVideos, seekVideos, syncVideosWithDuration } from "../utils/videos";
import { animateCanvasObjects, prepareAnimation } from "../utils/animations/animation";
import { useSelector } from "react-redux";
import { fetchAudioRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { useDispatch } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { LoadingOutlined } from "@ant-design/icons";
import { handleCancelApiRequest } from "../../../../Utils";
import { setCTLActiveSlideAction, setSlidePlaying } from "../../../../Store/actions/_contentTranscreationActions";

const style = {
  topM2: { top: -2 },
  handleStyle: { top: 3 },
  rotateIcon: { position: "relative", left: 1, top: 1 },
  railStyle: { backgroundColor: "rgba(189, 189, 189, 0.1)", height: 8, borderRadius: 8 },
  trackStyle: { backgroundColor: "rgba(72, 104, 255, 0.2)", height: 8, borderRadius: 8 }
};

export const CanvasPlayer = ({
  index,
  slide,
  activeSlide,
  canvasList,
  slidesLength,
  audioList,
  setAudioList,
  globalPlayer,
  setGlobalPlayer,
  handlePause,
  onStopPlaying,
  slides,
  seekTimeForResume,
  setSeekTimeForResume
}: {
  index: number;
  slide: SlideDataType;
  activeSlide?: number;
  canvasList: any;
  slidesLength: number;
  audioList: any;
  setAudioList: Function;
  globalPlayer: any;
  setGlobalPlayer: Function;
  handlePause: Function;
  onStopPlaying: Function;
  slides: SlideDataType[];
  seekTimeForResume: number;
  setSeekTimeForResume: Function;
}) => {
  const { elaiResponse } = useSelector((state: any) => state?.contentTranscreationReducer);

  const { voiceList }: { voiceList: VoiceListType[] } = useSelector((state: any) => state?.playerReducer);

  const dispatch = useDispatch();

  //   activeSlide = elaiResponse?.activeSlide;
  console.log("CanvasPlayer globalPlayer", globalPlayer.playing);
  const [play, setPlay] = useState(false);

  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [clearRunningInterval, setClearRunningInterval] = useState(false);
  const audioRef = useRef<HTMLAudioElement>();
  const globalPlayerRef = useRef();

  const manualChangingTime = useRef(false);

  //Cancel pending API requests and pause the current audio on unmount
  useEffect(() => {
    return () => {
      console.log("Unmounting CanvasPlayer");
      networkCallInProgress.current && handleCancelApiRequest();
      isMounted.current = false;
      audioRef.current?.pause();
    };
  }, []);

  useEffect(() => {
    clearRunningInterval && clearInterval(intervalId);
  }, [clearRunningInterval]);

  useEffect(() => {
    globalPlayerRef.current = globalPlayer.playing;
  }, [globalPlayer.playing]);

  //isMounted true if current Slide's canvas is visible
  useEffect(() => {
    if (index !== activeSlide) {
      console.log("Hiding CanvasPlayer");
      networkCallInProgress.current && handleCancelApiRequest();
      isMounted.current = false;

      stopThings();
    } else {
      isMounted.current = true;
    }
  }, [activeSlide]);

  useEffect(() => {
    if (globalPlayer.playing) {
      if (index === activeSlide) {
        //setPlayer((p) => ({ ...p, currentTime: player.seekTimeForResume || 0 }));
        setPlayer((p) => ({ ...p, currentTime: seekTimeForResume ? seekTimeForResume : 0 }));
        //start the audio and video from starting
        //playThings(player.seekTimeForResume || 0);
        playThings(seekTimeForResume || 0);

        console.log(`index: ${index} seekTimeForResume : `, seekTimeForResume);
        seekTimeForResume && setSeekTimeForResume(0);
      } else {
        stopThings();
      }
    } else {
      // if (index === activeSlide) pauseThings();
    }
  }, [globalPlayer.playing, activeSlide]);

  useEffect(() => {
    if (!globalPlayer.playing && index === activeSlide) pauseThings();
  }, [globalPlayer.playing]);

  const [player, setPlayer] = useState<{
    status: string;
    currentTime: number;
    canvasReady: boolean;
    audioReady: boolean;
    voiceAudio: HTMLAudioElement | null;
    seekTimeForResume?: number;
  }>({
    status: "idle", //"idle", "loading", "playing", "switching"
    currentTime: 0,
    canvasReady: false,
    audioReady: false,
    voiceAudio: null
  });

  const activeSlideIndexRef = useRef(activeSlide);

  const isMounted = useRef(true);

  const networkCallInProgress = useRef(false); //to indicate ongoing network call for current slide

  //on change of voice, reset the currentTime to 0
  useEffect(() => {
    if (index === activeSlide) setPlayer((p) => ({ ...p, currentTime: 0 }));
  }, [audioList]);

  let avatarGender = slide.avatar?.gender;
  let slideLanguage = slide.language;

  const getVoiceId = () => {
    console.log({ voiceList, slideLanguage });
    let languageObject = voiceList?.find((voiceObj) => voiceObj.name === slideLanguage);

    let genderObject = languageObject?.[avatarGender as keyof VoiceListType];

    //for voices like 'zh-CN-YunxiaNeural:calm', only the first part is needed
    let slideVoice = slide.voice?.split(":")?.[0];
    let voiceObject = (genderObject as VoiceListGenderType[])?.find((obj: VoiceListGenderType) => obj.voice === slideVoice);
    return voiceObject?.id || 0;
  };

  const waitSeeked = async (audio: HTMLAudioElement) => await new Promise((resolve) => audio.addEventListener("seeked", resolve, { once: true }));

  const getAudio = async () => {
    console.log("getAudio Index", index);
    console.log("audioList item at index", audioList[index]);
    if (audioList[index]) {
      let audioObj = audioList[index];
      if (!audioObj?.audio) {
        console.log("Error getting Audio file");
      }
      return audioObj?.audio;
    } else if (!audioList[index]) {
      //fetch audio
      //audio.onloadedmetadata = onloadedMetadata
      console.log("audio Slide index", index);
      //   let audioMockData = audioData;
      //   const audio = new Audio(`data:audio/x-wav;base64, ${audioMockData.data}`);

      setPlayer((p) => ({
        ...p,
        status: "loading"
      }));

      setGlobalPlayer((p: any) => ({ ...p, preloading: true }));

      networkCallInProgress.current = true;
      const res = await fetchAudioRequest({ text: slide?.speech, voice_id: getVoiceId() });
      networkCallInProgress.current = false;

      setGlobalPlayer((p: any) => ({ ...p, preloading: false }));

      if (!res) {
        //dispatch(setNotificationMsgAction("Error fetching audio."));

        setPlayer((p) => ({
          ...p,
          status: "idle"
        }));
        return;
      }

      let { data, duration } = res;
      const audio = new Audio(`data:audio/x-wav;base64, ${data}`);

      audio.preload = "auto";
      audio.onended = () => {
        console.log("audio ended!");
        setPlay(false);
        setClearRunningInterval(true);
        // setPlayer((p) => ({
        //   ...p,
        //   status: "idle",
        //   currentTime: 0
        // }));

        console.log("audio ended global.playing", globalPlayer.playing, globalPlayerRef.current);
        //TODO currentTime for switching would be  currentTime: prevState.currentTime for combined preview play
        if (globalPlayerRef.current) {
          setPlayer((prevState) => ({
            ...prevState,
            status: "switching",
            currentTime: 0 //prevState.currentTime
          }));
        } else {
          setPlayer((prevState) => ({
            ...prevState,
            status: "idle",
            currentTime: 0
          }));
        }
      };

      audioRef.current = audio;

      setAudioList((prevAL: any) => {
        let newAL = [...prevAL];
        newAL[index] = { audio: audio, duration: duration };
        return newAL;
      });

      setPlayer((p: any) => ({ ...p, status: "loading", voiceAudio: audio, audioReady: true }));

      return audio;
    }
  };

  const checkObjectsAnimationTime = (objects: any) => {
    return objects.some(
      (obj: any) =>
        (obj.animation?.type && obj.animation.startMarker && !obj.animation.startTime) ||
        (obj.animation?.exitType && obj.animation.endMarker && !obj.animation.endTime)
    );
  };

  const prepareCanvasForAnimation = useCallback(async () => {
    let canvas = canvasList[index];
    let data = slide;
    if (player.status === "playing") {
      // wait for canvas to load
      if (!canvas) return;
      //@ts-ignore
      if (canvas.activeLoading) await canvas.activeLoading;
      // reload canvas from slide state to update canvas with markers time if needed
      if (checkObjectsAnimationTime(canvas.getObjects()))
        await (canvas as fabric.Canvas).loadFromJSON(data.canvas, () => {
          console.log("Second Load from JSON");
        });

      //@ts-ignore
      prepareAnimation(canvas, data.canvas, data.animation, data.exitAnimation, data.duration);
      //@ts-ignore
      canvas.startAnimations();
      syncVideosWithDuration(canvas, data.duration || data.approxDuration);
      await playVideos(canvas, player.currentTime);
      //@ts-ignore
      canvas.played = true;
    }
    if (canvas && player.status === "idle") {
      pauseVideos();
      //if (globalPlayer.playing) return onStopPlaying(player.currentTime);

      const ao = canvas.getActiveObject();
      //@ts-ignore
      if (!canvas.initialized) setPlayer((p) => ({ ...p, canvasReady: false }));
      //@ts-ignore
      if (canvas.played || !canvas.initialized) await canvas.loadFromJSON(data.canvas);
      //@ts-ignore
      canvas.startAnimations();
      setPlayer((p) => ({ ...p, canvasReady: true }));
      //@ts-ignore
      if (ao && canvas.played) {
        canvas.forEachObject((obj: any) => {
          //@ts-ignore
          if (obj.id === ao.id) {
            canvas.setActiveObject(obj).renderAll();
          }
        });
      }
      //@ts-ignore
      canvas.played = false;
    }
    if (canvas && player.status === "switching") {
      if (globalPlayer.playing) onStopPlaying();
      else setPlayer((p) => ({ ...p, status: "idle", currentTime: 0 }));
    }
  }, [canvasList[index], player.status]);

  /**
   * Prepares canvas objects for animation, returns the canvas to the main state after animation
   */
  useEffect(() => {
    prepareCanvasForAnimation();
  }, [prepareCanvasForAnimation]);

  /**
   * Plays animation
   */
  useEffect(() => {
    let canvas = canvasList[index];

    if (!canvas) return;

    // console.log("currentTime updated", player.currentTime);
    let animationId: any;
    if (player.status === "playing") {
      //console.log("animation requested");
      animationId = requestAnimationFrame(() => animateCanvasObjects(canvas, player));
    }
    return () => {
      //console.log("unmount: cancelling animation");
      animationId && cancelAnimationFrame(animationId);
    };
  }, [player.status, player.currentTime]);

  useEffect(() => {
    if (player.status === "idle") dispatch(setSlidePlaying(false));
    else dispatch(setSlidePlaying(true));
  }, [player.status]);

  const durationOffset = useMemo(() => {
    return slides.slice(0, index).reduce((duration, slide) => duration + (slide.duration || slide.approxDuration || 0), 0);
  }, [activeSlide, globalPlayer.playing]);

  const time = (globalPlayer.playing && player.status !== "playing" ? 0 : player.currentTime) + (globalPlayer.playing ? durationOffset : 0);

  const formatter = { formatter: (v: any) => `${v?.toFixed(1) ?? 0}s` };

  const formatTime = (v: any, units: boolean = true) => `${v?.toFixed(1) ?? 0}${units ? "s" : ""}`;

  const onChangeSlideTime = (time: any) => {
    let canvas = canvasList[index];

    if (!canvas) return;

    if (player.status === "playing") playVideos(canvas, time);
    else if (player.status === "idle") {
      seekVideos(canvas, slide.duration, time);
      animateCanvasObjects(canvas, player);
    }
  };

  const changePlayerTime = (time: number, slideChanged: boolean) => {
    if (slideChanged) {
      setSeekTimeForResume(time);
      //setPlayer({ ...player, seekTimeForResume: time });
      player.voiceAudio?.pause();
    } else {
      if (player.voiceAudio) player.voiceAudio.currentTime = time;
      manualChangingTime.current = true;
      setPlayer({ ...player, currentTime: time });
      onChangeSlideTime(time);
    }
  };

  const onSliderChanged = (currentTime: any) => {
    console.log("onSliderChanged time", currentTime);
    if (!globalPlayer.playing) return changePlayerTime(currentTime, false);

    let slideIndex = 0;
    let offset = 0;
    for (const slide of slides) {
      //@ts-ignore
      if (currentTime < offset + (slide.duration || slide.approxDuration)) break;
      //@ts-ignore
      offset += slide.duration || slide.approxDuration;
      slideIndex++;
    }
    changePlayerTime(currentTime - offset, slideIndex !== activeSlide);
    //if (slideIndex !== activeSlide) updateActiveSlide(slideIndex);

    //TODO for Preview Video slider
    // if (slideIndex !== activeSlide) {
    //   updateActiveSlide(slideIndex < slides.length ? slideIndex : Number(activeSlide) + 1);
    // }

    if (slideIndex !== activeSlide) {
      let newIndex = Number(activeSlide) + 1 < slides.length ? Number(activeSlide) + 1 : Number(activeSlide);
      updateActiveSlide(slideIndex < slides.length ? slideIndex : newIndex);
    }

    // console.log({ currentTime });
    // onChangeSlideTime(currentTime);
    // //@ts-ignore
    // if (player.voiceAudio) player.voiceAudio.currentTime = currentTime;
    // setPlayer((p) => ({
    //   ...p,
    //   currentTime: currentTime
    // }));
  };

  const updateActiveSlide = (slideIndex: number) => {
    console.log("updateActiveSlide", slideIndex);
    dispatch(setCTLActiveSlideAction(slideIndex));
  };

  const getDuration = () => {
    if (globalPlayer.playing) return globalPlayer.duration;
    if (audioList[index]) {
      let audioObj = audioList[index];
      if (!audioObj?.audio) {
        console.log("Error getting Audio file");
      }
      return audioObj?.duration;
    }
  };

  const getPlayPauseIcon = () => {
    switch (player.status) {
      case "loading":
        return <LoadingOutlined />;
      case "idle":
        return <PlayCircleOutlined />;
      default:
        return <PauseOutlined />;
    }
  };

  const pauseThings = () => {
    setPlay(false);
    //Stop Video and animation updates that happens through prepareCanvasForAnimation()
    clearInterval(intervalId);
    setPlayer((p) => ({ ...p, status: "idle" }));
    player.voiceAudio?.pause();
  };

  const stopThings = () => {
    setPlay(false);
    //Stop Video and animation updates that happens through prepareCanvasForAnimation()
    clearInterval(intervalId);
    //setPlayer((p) => ({ ...p, status: "idle", currentTime: player.seekTimeForResume || 0 }));
    setPlayer((p) => ({ ...p, status: "idle", currentTime: 0 }));

    //console.log("stopping things:", player.voiceAudio, JSON.parse(JSON.stringify(player)));
    if (player.voiceAudio) {
      console.log("pausing the audio");
      player.voiceAudio.pause();
      player.voiceAudio.currentTime = 0;
    }
  };

  const playThings = async (time?: number) => {
    setPlay(true);
    //clear previous interval
    clearInterval(intervalId);

    try {
      let audio: HTMLAudioElement = await getAudio();

      if (!isMounted.current) {
        console.log(`isMounted is false so discarding the audio for index: ${index} with isMounted: ${isMounted.current}`);
        setPlayer((prevState) => ({
          ...prevState,
          status: "idle",
          currentTime: 0
        }));
        return;
      }

      if (!audio) {
        console.log(`Audio Missing for index: ${index} with isMounted: ${isMounted.current}`);
        dispatch(setNotificationMsgAction("Audio missing!"));
        throw new Error("Audio missing!");
      }

      //set the audio current time as player's slider time
      //audio.currentTime = time !== undefined ? time : player.currentTime;

      if (time !== undefined) {
        const shouldSeek = audio.currentTime !== time;
        audio.currentTime = time;
        if (shouldSeek) await waitSeeked(audio);
      } else {
        console.log("preview currentTime", player.currentTime);
        const shouldSeek = audio.currentTime !== player.currentTime;
        audio.currentTime = player.currentTime;
        if (shouldSeek) await waitSeeked(audio);
      }

      //   setPlayer((prevState) => ({
      //     ...prevState,
      //     status: "playing"
      //   }));

      audio.play().then(() => {
        //Play animations and video
        let playingInterval = setInterval(() => {
          setPlayer((p) => {
            return { ...p, status: "playing", currentTime: p.currentTime + 0.04 };
          });
        }, 40);

        setIntervalId(playingInterval);
        setClearRunningInterval(false);
      });
    } catch (error) {
      console.log("Error occurred while playing!", error);
      dispatch(setNotificationMsgAction(`Error occurred while playing! ${error}`));
      setPlay(false);
      globalPlayer.playing && setGlobalPlayer((p: any) => ({ ...p, playing: false }));
    }
  };

  const handleClick = async () => {
    if (play) {
      pauseThings();
      globalPlayer.playing && setGlobalPlayer((p: any) => ({ ...p, playing: false }));
    } else {
      playThings();
    }
  };

  return (
    <Row gutter={16} align="middle" style={{ display: index !== activeSlide ? "none" : "", padding: "1rem" }}>
      <Col span={2}>
        <Button
          loading={Object.values(canvasList).length !== slidesLength && player.status === "loading" /* || !player.canvasReady */}
          type="primary"
          //   icon={player.status === "idle" ? <PlayCircleOutlined /> : <PauseOutlined />}
          icon={getPlayPauseIcon()}
          disabled={player.status === "loading"}
          onClick={handleClick}
        />
      </Col>
      <Col span={20}>
        <Slider
          value={time}
          min={0}
          // max={getDuration() || slide.duration}
          max={globalPlayer.playing ? globalPlayer.duration : getDuration() || slide.duration}
          step={0.01}
          tooltip={formatter}
          onChange={onSliderChanged}
          disabled={player.status === "loading"}
          style={style.topM2}
          styles={{ handle: style.handleStyle, rail: style.railStyle, track: style.trackStyle }}
        />
      </Col>
      <Col span={2}>
        {formatTime(time)}
        {/* /{formatTime(getDuration())} */}
      </Col>
    </Row>
  );
};
