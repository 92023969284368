import { FC, useMemo, useState, useRef, useEffect } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { PostSemanticChatSession, getChats, getChatsSpecific, getQuestionRecommendation } from "../../../../Store/requests/_semanticSearchRequests";
import _localStorageService from "../../../../Services/_localStorageService";
import { setAdvancedFilter, setFilterListAction, setAdvancedFilterStatus } from "../../../../Store/actions/_semanticSearchActions";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import Markdown from "markdown-to-jsx";
import { PostSemanticChat } from "../../../../Store/requests/_semanticSearchRequests";
import { ComponentChatWithDocProps, ReferenceFilesDrawerProps, SSEData } from "../Core/constants"; 

interface Folder {
  id: number;
  name: string;
  files: Record<string, string>; // A record of file name to file URL
}


export const ReferenceFilesDrawer: FC<ReferenceFilesDrawerProps> = ({ 
    drawerVisibleforReferenceFiles,
    setDrawerVisibleforReferenceFiles,
    openS3LinkInNewTab,
    showDrawer
  }) => {
  const { searchQuery, initialFolders, Format, cardData } = useSelector((state: any) => state.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  // You can add your component logic here

  return (
    <>
      {/* Add your component JSX here */}
      <Drawer
        placement="right"
        width={500}
        visible={drawerVisibleforReferenceFiles}
        closable={false} // Hides the default close button
        mask={true}
        style={{ background: "#EEF4FF", padding: "8px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "black",
              paddingLeft: "5px",
              fontSize: "18px",

              fontWeight: "bold",
            }}
          >
            {cardData?.filenames ? <> Sources ({Object.entries(cardData?.filenames).length}) |</> : <> Sources |</>}

            <Button
              style={{
                color: "#034EA2",
                paddingLeft: "3px",
                fontSize: "18px",

                fontWeight: "bold",
              }}
              type="text"
            >
              Download All
            </Button>
          </div>
          <div
            style={{ color: "#034EA2", paddingTop: "5px", fontWeight: "bolder", fontSize: "25px", cursor: "pointer" }}
            onClick={() => {
              setDrawerVisibleforReferenceFiles(false);
            }}
          >
            X
          </div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <Card style={{ minHeight: "650px", background: "#F3F6FA" }}>
            <Row gutter={16}>
              <Col span={24}>
                <Card style={{ height: "600px", overflowY: "scroll" }}>
                  <div style={{}}>
                    {cardData?.filenames ? (
                      Object?.entries(cardData?.filenames)?.map(([name, link]) => (
                        <Tooltip title={name} key={name}>
                          <div
                            style={{
                              cursor: "pointer",
                              padding: "13px",
                              textAlign: "left",
                              font: "normal normal normal 16px/30px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#66676B",
                              backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                              backgroundSize: "cover",
                              margin: "15px",
                              borderRadius: "20px",
                              height: "80px",
                              // overflow: 'hidden', // Ensure text doesn't overflow
                              // textOverflow: 'ellipsis', // Truncate text with ellipsis
                              // whiteSpace: 'nowrap' // Prevent text from wrapping
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p onClick={() => openS3LinkInNewTab(link)}> {name.slice(0, 30)}...</p>
                            <Tooltip title={t(I18N_MODULE_KEYS["chatdocument.search.chatwithdocument"])}>
                              <Image
                                src={PROJECT_ICONS.CHAT}
                                style={{ height: "22px", width: "auto" }}
                                preview={false}
                                onClick={() => showDrawer(name)}
                              ></Image>
                            </Tooltip>
                          </div>
                        </Tooltip>
                      ))
                    ) : (
                      <>Not Found</>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </Drawer>
    </>
  );
};

