import { FC, useEffect, useRef, useState } from "react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

// const values = ["How can I help you?", "My Name is Lin"];

export const ChatBotInput: FC<{ values: string[] }> = ({ values }) => {
  const timerRef: any = useRef();

  const [value, setValue] = useState("");

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    clearInterval(timerRef.current);
    handleTimer();
  }, [values]);

  const handleTimer = () => {
    let index = 0,
      counter = 0,
      deleting = false;

    timerRef.current = setInterval(() => {
      setValue((prevValue) => {
        if (!deleting && prevValue.length < values[index].length) {
          counter++;
        }
        //Start deleting
        else if (deleting || prevValue.length === values[index].length) {
          deleting = true;
          counter -= 2;
          if (counter <= 0) {
            index = (index + 1) % values.length;
            counter = 0;
            deleting = false;
          }
        }

        return values[index].slice(0, counter);
      });
    }, 200);
  };

  return (
    <div className="chat-bot-input">
      {value}
      <img className="chat-bot-input-cursor" src={PROJECT_ICONS.C_AI_CHAT_BOT_INPUT_CURSOR} />
    </div>
  );
};
