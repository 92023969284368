import { UploadFile } from "antd";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { ProgressBar } from "./ProgressBar";

export const FileView = ({ file, uploading }: { file: UploadFile; uploading: boolean }) => {
  const getFileSize = () => {
    if (!file.size) return "0 KB";
    if (file.size < 1024 * 1024) {
      return (file.size / 1024).toFixed(2) + " KB";
    } else if (file.size > 1024 * 1024) {
      return (file.size / (1024 * 1024)).toFixed(2) + " MB";
    }
  };
  return (
    <div className={"fileview"}>
      <div>
        <div className="file-icon">
          <img width="28px" src={PROJECT_ICONS.C_AI_FILE_PLACEHOLDERE_ICON}></img>
        </div>
      </div>
      <div className="file-details">
        <div className="file-description">
          <div>
            <div>{file.name}</div>
            <div className="file-size">{getFileSize()}</div>
          </div>
          <div className="check-icon">{file?.status === "done" && <img width="16px" src={PROJECT_ICONS.C_AI_CHECK_ICON}></img>}</div>
        </div>
        <div className="upload-progress">
          <ProgressBar uploading={uploading} error={file?.status === "error"} />
        </div>
      </div>
    </div>
  );
};
