import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import {
  AlignLeftOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { nextinsightsecondcardindividualstyle, nextinsightsecondcardindividualstyledisable, nextinsightsecondextracardstyle } from "../StylesFolderForNextInsight/_NextInsightPageStyleConstant";

interface NextInsightPageProps {
  showComponentForChunks: (type: string, url: string) => void;
}

export const NextInsightAudios: FC<NextInsightPageProps> = ({ showComponentForChunks }) => {
  const { cardsAudioData } = useSelector((state: any) => state.semanticSearchReducer);

  return (
    <>
      <Row style={{ padding: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
        <Image src={PROJECT_ICONS.PLAYOUTLINE} style={{ paddingRight: "9px" }} />
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsAudioData && cardsAudioData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          Audio(s)
        </p>
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsAudioData && cardsAudioData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          |
        </p>
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsAudioData && cardsAudioData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          {cardsAudioData ? (cardsAudioData.length >= 10 ? cardsAudioData.length : "0" + cardsAudioData.length) : "00"}{" "}
        </p>
      </Row>
      <Row gutter={[24, 24]} style={{ paddingLeft: "30px", display: "flex", alignContent: "center" }}>
        {cardsAudioData && cardsAudioData.length > 0 ? (
          cardsAudioData.length <= 4 ? (
            cardsAudioData.map((audio: any, index: number) => (
              <Col
                key={index}
                span={5}
                style={nextinsightsecondcardindividualstyle}
                onClick={() => {
                  showComponentForChunks("Audio", audio?.s3_url);
                }}
              >
                <Image src={PROJECT_ICONS.AUDIO} preview={false} />
              </Col>
            ))
          ) : (
            <>
              {cardsAudioData.slice(0, 3).map((audio: any, index: number) => (
                <Col
                  key={index}
                  span={5}
                  style={nextinsightsecondcardindividualstyle}
                  onClick={() => {
                    showComponentForChunks("Audio", audio?.s3_url);
                  }}
                >
                  <Image src={PROJECT_ICONS.AUDIO} preview={false} />
                </Col>
              ))}
              <Col
                span={5}
                style={nextinsightsecondextracardstyle}
                onClick={() => {
                  showComponentForChunks("Audio", cardsAudioData[0]?.s3_url);
                }}
              >
                <div style={{ color: "white", fontWeight: "600", fontSize: "15px" }}>
                  +0{cardsAudioData.length - 3}
                </div>
              </Col>
            </>
          )
        ) : (
          <Col span={5} style={nextinsightsecondcardindividualstyledisable}>
            <Image src={PROJECT_ICONS.AUDIO} preview={false} />
          </Col>
        )}
      </Row>
    </>
  );
};
