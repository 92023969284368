import { Col, Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";

import { alignCenterIcon, alignLeftIcon, alignRightIcon } from "../constants";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../../../Store/actions/_contentTranscreationActions";

export const TextAlign = ({ activeObjectProps, getTextSelectionStyle }: { activeObjectProps: any; getTextSelectionStyle: Function }) => {
  const dispatch = useDispatch();

  const textAlign = getTextSelectionStyle(activeObjectProps, "textAlign");

  const isLeftAlign = textAlign === "left" ? "primary" : "default";
  const isAlignRight = textAlign === "right" ? "primary" : "default";
  const isAlignCenter = textAlign === "center" ? "primary" : "default";

  const onLeftAlignClick = () => {
    dispatch(setActiveObjectModifier({ change: "textAlign", value: "left" }));
  };

  const onAlignCenterClick = () => {
    dispatch(setActiveObjectModifier({ change: "textAlign", value: "center" }));
  };

  const onAlignRightClick = () => {
    dispatch(setActiveObjectModifier({ change: "textAlign", value: "right" }));
  };

  return (
    <Col>
      <ButtonGroup>
        <Button icon={alignLeftIcon} type={isLeftAlign} onClick={onLeftAlignClick} />
        <Button icon={alignCenterIcon} type={isAlignCenter} onClick={onAlignCenterClick} />
        <Button icon={alignRightIcon} type={isAlignRight} onClick={onAlignRightClick} />
      </ButtonGroup>
    </Col>
  );
};
