import { FC, useEffect, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Flex, Input, Popconfirm, Typography } from "antd";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { CoreClaimContentTypes, CoreClaimsTypes, UpdateAssetGuide } from "../core/modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import { apiResponse } from "../../../Utils/_gTypes";
import _iDetailExtractRequests from "../../../Store/requests/_iDetailExtractRequests";
import { globalConstant } from "../../../Utils";
import { setAssetGuideDataIDXAction } from "../../../Store/actions/_iDetailExtractActions";

const { Text } = Typography;

type LoadingTypes = {
  save: boolean;
  delete: boolean;
};

const initialLoading: LoadingTypes = {
  save: false,
  delete: false
};

export const ContentListItem: FC<{
  coreClaimIndex: number;
  contentItem: CoreClaimContentTypes;
  coreClaimData: CoreClaimsTypes[];
}> = ({ contentItem, coreClaimIndex, coreClaimData }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { assetGuideData } = useSelector((state: any) => state?.iDetailExtractReducer);

  const loadingRef: any = useRef();
  const [loading, setLoading] = useState<boolean>(false);
  loadingRef.current = loading;

  useEffect(() => {}, [loadingRef.current]);

  const [isError, setIsError] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [contentLocal, setContentLocal] = useState<string>("");
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [pageNumberLocal, setPageNumberLocal] = useState<number>(1);

  useEffect(() => {
    if (contentItem.id && isEditActive === true) {
      setPageNumberLocal(contentItem.page_number);
      setContentLocal(contentItem.content);
    } else if (contentItem.id === null) {
      //for new item
      setPageNumberLocal(contentItem.page_number);
      setContentLocal(contentItem.content);

      setIsEditActive(true);
      setIsHover(true);
    }
  }, [contentItem, isEditActive, isHover]);

  useEffect(() => {
    setIsError(false);
  }, [contentLocal, pageNumberLocal]);

  // useImperativeHandle(ref, () => ({
  //   afterAdding: () => {
  //     setIsEditActive(true);
  //     setIsHover(true);
  //   },
  //   onLoadingStart: () => {
  //     setLoading(true);
  //   },
  //   onLoadingStop: () => {
  //     setLoading(false);
  //   },
  //   afterSave: (contentId: number) => {
  //     if (selectedContentId === contentId) {
  //       // if (contentItem.id === contentId) {
  //       // setTimeout(() => {
  //       setLoading(false);
  //       setIsHover(false);
  //       setIsError(false);
  //       setIsDeleted(false);
  //       setIsEditActive(false);
  //       // }, 1000);
  //       // successMessage("Content Updated!");
  //       if (isDeleted) {
  //         dispatch(
  //           setNotificationMsgAction(
  //             t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]) + "!"
  //           )
  //         );

  //         return;
  //       }
  //       dispatch(
  //         setNotificationMsgAction(t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated"]) + "!")
  //       );
  //     } else {
  //       console.log(
  //         "content not saved due to different id " + "content id before saving: " + contentItem.id + ", content id after saving" + contentId
  //       );
  //     }
  //   }
  // }));

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.${value as string}`])}`;
  };

  const afterSave = () => {
    setLoading(false);
    setIsHover(false);
    setIsError(false);
    setIsDeleted(false);
    setIsEditActive(false);
    if (isDeleted) {
      dispatch(
        setNotificationMsgAction(t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]) + "!")
      );

      return;
    }
    dispatch(
      setNotificationMsgAction(t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated"]) + "!")
    );
  };

  const handleSaveMain = (content: string, pageNumber: number, contentItem: CoreClaimContentTypes) => {
    let arrCoreClaimData: CoreClaimsTypes[] = [...coreClaimData];

    let contentIndex = arrCoreClaimData[coreClaimIndex]["content"].indexOf(contentItem);

    arrCoreClaimData[coreClaimIndex]["content"][contentIndex]["content"] = content;
    arrCoreClaimData[coreClaimIndex]["content"][contentIndex]["page_number"] = pageNumber;

    handleUpdateClaims(arrCoreClaimData, contentItem.id);
  };

  const handleDeleteMain = async (contentId: number) => {
    let arrCoreClaimData: CoreClaimsTypes[] = [...coreClaimData];

    arrCoreClaimData[coreClaimIndex]["content"]?.forEach((item: CoreClaimContentTypes) => {
      if (item.id === contentId) {
        item["is_active"] = false;
        return;
      }
    });

    await handleUpdateClaims(arrCoreClaimData, contentId);
  };

  const handleUpdateClaims = async (arrCoreClaimData: CoreClaimsTypes[], contentId: number | null) => {
    let arrNewEntryExistWithContent = arrCoreClaimData[coreClaimIndex]["content"]?.filter((item) => {
      if (item?.id === null && !item?.content?.length) {
        return item;
      }
    });

    if (arrNewEntryExistWithContent && arrNewEntryExistWithContent?.length) {
      arrCoreClaimData[coreClaimIndex]["content"] = arrCoreClaimData[coreClaimIndex]["content"]?.filter((item, index) => {
        if (item.id === null && !item.content?.length) {
          return;
        }

        return item;
      });
    }

    setLoading(true);
    const res: apiResponse = await _iDetailExtractRequests.updateClaims({
      assetguide_id: assetGuideData.assetguide_id,
      claims: [...arrCoreClaimData],
      file_url: assetGuideData.file_url
    });
    setLoading(false);

    if (res.message?.toUpperCase() === globalConstant.SUCCESS) {
      if (contentId === null) {
        const data: UpdateAssetGuide = await getUpdatedClaimsData();
        if (arrNewEntryExistWithContent && arrNewEntryExistWithContent?.length) {
          arrNewEntryExistWithContent?.forEach((item) => {
            data?.claims[coreClaimIndex]["content"].push({ ...item });
          });
        }
        dispatch(setAssetGuideDataIDXAction({ ...assetGuideData, assetguide_id: data?.assetguide_id, claims: [...data?.claims] }));
      } else {
        if (arrNewEntryExistWithContent && arrNewEntryExistWithContent?.length) {
          arrNewEntryExistWithContent?.forEach((item) => {
            arrCoreClaimData[coreClaimIndex]["content"].push({ ...item });
          });
        }
        dispatch(setAssetGuideDataIDXAction({ ...assetGuideData, claims: [...arrCoreClaimData] }));
      }

      afterSave();
    }
  };

  const getUpdatedClaimsData = async () => {
    const res: apiResponse = await _iDetailExtractRequests.getClaimsData(assetGuideData.assetguide_id);

    return res?.data;
  };

  return (
    <li
      key={contentItem.id}
      onMouseOver={() => {
        if (isDeleted === true) {
          return;
        }
        setIsHover(true);
      }}
      onMouseOut={() => isDeleted === true || (isEditActive === false && setIsHover(false))}
      style={{
        border: isHover ? "1px solid rgb(3, 78, 162)" : "1px solid transparent",
        borderRadius: "8px",
        fontSize: "13px",
        padding: "5px",
        cursor: "pointer"
      }}
    >
      <Flex vertical gap="small" style={{ position: "relative" }}>
        {isHover && (
          <Flex
            justify="flex-end"
            gap="small"
            style={{ position: "absolute", top: "-5px", right: "-5px", background: "#e0e4e5", padding: "5px", borderRadius: "6px", zIndex: "2" }}
          >
            {isEditActive === false && <EditTwoTone onClick={() => setIsEditActive(true)} />}

            <Popconfirm
              key={contentItem.id}
              title={`${t(I18N_MODULE_KEYS["content.text.Are you sure you want to delete?"])}`}
              open={isDeleted}
              description={``}
              okText={`${t(I18N_MODULE_KEYS["content.button.yes"])}`}
              cancelText={`${t(I18N_MODULE_KEYS["content.button.no"])}`}
              okButtonProps={{ loading: loadingRef.current }}
              onConfirm={async () => {
                await handleDeleteMain(contentItem.id as number);
                setIsDeleted(false);
              }}
              onCancel={() => {
                setIsDeleted(false);
                if (isEditActive === false) {
                  setIsHover(false);
                }
              }}
            >
              <DeleteTwoTone
                onMouseOut={() => {}}
                onClick={() => {
                  setIsDeleted(true);
                }}
              />
            </Popconfirm>
          </Flex>
        )}

        {isEditActive ? (
          <Flex vertical gap="middle">
            <Flex vertical>
              <Text style={{ fontSize: "13px", fontWeight: "400", color: "grey" }}>{getTranslatedText("Content")}</Text>
              <TextArea
                content={contentLocal}
                value={contentLocal}
                onChange={(e) => setContentLocal(e.target.value)}
                style={{ borderColor: "lightgrey" }}
              />

              {isError && !contentLocal && (
                <Text style={{ fontSize: "12px", fontWeight: "400", color: "red" }}>{getTranslatedText("Content can not be empty.")}</Text>
              )}
            </Flex>

            <Flex vertical>
              <Text style={{ fontSize: "13px", fontWeight: "400", color: "grey" }}>{getTranslatedText("Page No")}.</Text>
              <Input
                type="number"
                min={1}
                value={pageNumberLocal}
                style={{ borderColor: "lightgrey" }}
                onChange={(e) => setPageNumberLocal(parseInt(e.target.value) as number)}
              />
              {isError && !pageNumberLocal && (
                <Text style={{ fontSize: "12px", fontWeight: "400", color: "red" }}>{getTranslatedText("Page number can not be empty.")}</Text>
              )}
            </Flex>

            <Flex gap="small" align="center">
              <Button
                key={"submit-save"}
                style={{
                  background:
                    (contentLocal !== contentItem.content || contentItem.page_number !== pageNumberLocal) && loading === false
                      ? "rgb(5, 70, 165)"
                      : "rgb(196 198 202)",
                  color: "white"
                }}
                loading={loadingRef.current}
                disabled={loadingRef.current || (contentLocal !== contentItem.content || contentItem.page_number !== pageNumberLocal ? false : true)}
                onClick={() => {
                  if (!contentLocal || !pageNumberLocal) {
                    setIsError(true);
                    return;
                  }

                  handleSaveMain(contentLocal, pageNumberLocal, contentItem);
                }}
              >
                {t(I18N_MODULE_KEYS["content.button.save"])}
              </Button>
              {contentLocal !== contentItem.content || contentItem.page_number !== pageNumberLocal ? (
                <Popconfirm
                  title={`${t(I18N_MODULE_KEYS["content.text.disardallchanges"])}`}
                  description={`${t(I18N_MODULE_KEYS["content.text.areyousuretodiscard"])}`}
                  okText={`${t(I18N_MODULE_KEYS["content.button.yes"])}`}
                  cancelText={`${t(I18N_MODULE_KEYS["content.button.no"])}`}
                  onConfirm={() => {
                    setPageNumberLocal(contentItem.page_number);
                    setContentLocal(contentItem.content);
                  }}
                >
                  <Button style={{ margin: "10px 10px" }}>{t(I18N_MODULE_KEYS["content.button.discard"])}</Button>
                </Popconfirm>
              ) : (
                <Button
                  style={{ margin: "10px 10px" }}
                  onClick={() => {
                    if (!contentLocal || !pageNumberLocal) {
                      setIsError(true);
                      return;
                    }
                    setIsHover(false);
                    setIsEditActive(false);
                  }}
                >
                  {t(I18N_MODULE_KEYS["content.button.cancel"])}
                </Button>
              )}
            </Flex>
          </Flex>
        ) : (
          <>{contentItem.content}</>
        )}
      </Flex>
    </li>
  );
};
