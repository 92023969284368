import { Button, Col, Image, Modal, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import TextArea from "antd/es/input/TextArea";
import { exportArtefactReport, getArtifactById, UpdateArtifactData } from "../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";

const { Paragraph } = Typography;

export interface ModalForEditingArtifactContentProps {
  isModalOpenForEditing: boolean;
  data: any;
  setIsModalOpenForEditing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTypeData: any[];
  setSelectedData: React.Dispatch<React.SetStateAction<any[]>>;
  setLoadData?:React.Dispatch<React.SetStateAction<LoadDataType>>;
  switchForArtefact:number;
  setData:React.Dispatch<React.SetStateAction<any>>;
  setCommonArtefacts: (value: any[]) => void;
  commonArtefacts: any[];
  setFileSpecificArtefact: (value: any[]) => void;
  fileSpecificArtefact:any[];

}
interface LoadDataType {
  isLoading: boolean;
  message: string;
};


export const ModalForEditingArtifactContent: FC<ModalForEditingArtifactContentProps> = ({
  isModalOpenForEditing,
  data,
  setIsModalOpenForEditing,
  selectedTypeData,
  setSelectedData,
  setLoadData,switchForArtefact,setData,setCommonArtefacts,commonArtefacts,fileSpecificArtefact,setFileSpecificArtefact

}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [value, setValue] = useState(data?.data);
  const [loading, setLoading] = useState(false);
  console.log(selectedTypeData);
  console.log(data);

  const updateArtifact = async (updatedArtifact: any) => {
    const newArtefacts = fileSpecificArtefact.map(artefact => {
      if (artefact.key === 'Image' || artefact.key === 'Summary' || artefact.key === "Data Table") {
        return {
          ...artefact,
          item: artefact.item.map((item: any) =>
            item.id === updatedArtifact.id ? updatedArtifact : item
          ),
        };
      }
      return artefact;
    });
  
    setFileSpecificArtefact(newArtefacts)
  };
  

  const handleCreateNewArtifacts = async () => {
    setLoading(true);
    try {
      const res = await UpdateArtifactData({ artifact_id: data?.id, data: value });
        const updatedData= await getArtifactById({artifact_id:data?.id})
        console.log(updatedData);
        setData(updatedData?.data)
        setSelectedData((prevData) =>
          prevData.map((item) =>
            item.id === updatedData?.data?.id ? updatedData?.data : item
          )
        );
        if(updatedData){
          dispatch(setNotificationMsgAction("Artefact Content updated successfully "))
        }
        updateArtifact(updatedData?.data)
        
      
    } catch (error) {
      // Handle error if any
    } finally {
      setLoading(false);
      setIsModalOpenForEditing(false);
      
    }
  };

  return (
    <Modal
      title="Edit Artefact Content"
      visible={isModalOpenForEditing}
      onCancel={() => {
        setIsModalOpenForEditing(false);
      }}
      onOk={handleCreateNewArtifacts}
      maskStyle={{
        backdropFilter: "blur(30px)",
        background: "rgba(44, 78, 101, 0.5)",
      }}
      footer={[
        <Button key="back" onClick={() => setIsModalOpenForEditing(false)}>
          CANCEL
        </Button>,
        <Button key="submit" style={{background:'#1E4DA1',color:'white'}} loading={loading} onClick={handleCreateNewArtifacts}>
        SAVE
        </Button>,
      ]}
    >
      <TextArea
        rows={20}
        value={value}
        onChange={(e) => { 
          setValue(e.target.value); // Update the state with the new value
        }}
        placeholder="Edit Artefact Content"
      />
    </Modal>
  );
};
