import { FC, useEffect, useState } from "react";
import Meta from "antd/es/card/Meta";
import { Card, Checkbox, Col, Row } from "antd";
import { NameValueTypes } from "../../../../../Utils/_gTypes";
import { useDispatch } from "react-redux";
import { setRegenerateStateAction, setResponseCtAction } from "../../../../../Store/actions/_contentTranscreationActions";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const selectedCardStyle: React.CSSProperties = {
  boxShadow: "0px 6px 18px #00000029",
  border: " 5px solid rgb(0, 84, 207)",
  padding: "16px",
  borderRadius: "10px" // Add border radius to the card
};

const cardStyle: React.CSSProperties = {
  boxShadow: "0px 6px 18px #00000029",
  border: " 0.4000000059604645px solid #000000",
  padding: "16px",
  borderRadius: "10px" // Add border radius to the card
};

type IProps = {
  list: NameValueTypes[];
};

export const ImgGenSrcSelectionScreen: FC<IProps> = ({ list }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { responseCT } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [selectedPlatform, setSelectedPlatform] = useState<number>(responseCT?.storyboard?.platform?.id || 1);

  // useEffect(() => {
  //   dispatch(setResponseCtAction({ ...responseCT, platform_id: selectedPlatform }));
  // }, [selectedPlatform]);

  useEffect(() => {
    if (responseCT?.imageSourceHasNext === true) {
      dispatch(setResponseCtAction({ ...responseCT, imageSourceHasNext: false }));
      setSelectedPlatform(responseCT?.platform_id);
    }
  }, [responseCT?.imageSourceHasNext]);

  return (
    <>
      {/* <div style={{ fontWeight: "bold", fontSize: "16px" }}>Choose Your Platform For Image/Clip Creation</div> */}
      <div style={{ fontWeight: "bold", fontSize: "16px" }}>
        {t(I18N_MODULE_KEYS["content.transcreation.process.selectimage.title.chooseplatform"])}
      </div>
      <Card>
        <Row gutter={[16, 16]}>
          {list?.map((item: NameValueTypes) => {
            return (
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Card
                  style={
                    selectedPlatform === item?.id
                      ? { ...selectedCardStyle, cursor: item?.id === 2 || item?.id === 3 ? "disabled" : "pointer" }
                      : { ...cardStyle, cursor: item?.id === 2 || item?.id === 3 ? "disabled" : "pointer" }
                  }
                  hoverable
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <div
                      className=" justify-content-center"
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                      onClick={() => {
                        if  (item?.id === 3) {
                          return;
                        }

                        dispatch(setResponseCtAction({ ...responseCT, platform_id: item?.id }));

                        if (responseCT?.has_next === true) {
                          dispatch(setRegenerateStateAction(true));
                          return;
                        }

                        setSelectedPlatform(item?.id ? item?.id : 1);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        {/* <Checkbox
                          checked={selectedPlatform === item?.id}
                          disabled={item.id === 2 || item?.id === 3}
                          onChange={() => setSelectedPlatform(item?.id ? item?.id : 1)}
                        ></Checkbox> */}
                      </div>
                      <img
                        alt="Image 2"
                        src={item?.url}
                        style={{
                          // height: "150px",
                          height: "150px",
                          width: "200px",
                          // width: "300px",
                          margin: "auto"
                        }}
                      />
                    </div>
                  }
                ></Card>
              </Col>
            );
          })}
        </Row>
      </Card>
    </>
  );
};

// <Col xs={{ span: 24 }} md={{ span: 8 }}>
//   <Card
//     style={cardStyle}
//     hoverable
//     cover={
//       <div
//         className=" justify-content-center"
//         style={{
//           display: "flex",
//           flexDirection: "column"
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-end"
//           }}
//         >
//           <Checkbox disabled></Checkbox>
//         </div>
//         <img
//           alt="Image 1"
//           src={PROJECT_ICONS.CT_DALLE}
//           style={{
//             height: "150px",
//             width: "250px",
//             margin: "auto"
//           }}
//         />
//       </div>
//     }
//   >
//     <Meta />
//   </Card>
// </Col>

// <Col xs={{ span: 24 }} md={{ span: 8 }}>
//   <Card
//     style={cardStyle}
//     hoverable
//     cover={
//       <div
//         className=" justify-content-center"
//         style={{
//           display: "flex",
//           flexDirection: "column"
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-end"
//           }}
//         >
//           <Checkbox disabled></Checkbox>
//         </div>
//         <img alt="Image 3" src={PROJECT_ICONS.CT_MIDJOURNEY} style={{ height: "150px", margin: "auto" }} />
//       </div>
//     }
//   >
//     <Meta />
//   </Card>
// </Col>
// <Col xs={{ span: 24 }} md={{ span: 8 }}>
//   <Card
//     style={cardStyle}
//     hoverable
//     cover={
//       <div
//         className=" justify-content-center"
//         style={{
//           display: "flex",
//           flexDirection: "column"
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-end"
//           }}
//         >
//           <Checkbox></Checkbox>
//         </div>
//         <img alt="Image 3" src={PROJECT_ICONS.CT_VIDEO_CLIP_ICON} style={{ height: "150px", margin: "auto" }} />
//       </div>
//     }
//   >
//     <Meta />
//   </Card>
// </Col>
