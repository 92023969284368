import { LayoutSyncTypes } from "../../Utils/_gTypes";
import {
  SET_AXIOS_SOURCE_TOKEN,
  SET_DARK_MODE_STATUS,
  SET_LAYOUT_SYNC,
  SET_MINI_MASTER_DATA,
  SET_TOGGLE_SIDEBAR_COLLAPSE
} from "../constants/_commonConstants";

export const LayoutSyncAction: LayoutSyncTypes = {
  CONTENT_TRANSCREATION: true,
  SEMANTIC_SEARCH: false,
  CONTENT_AI: false,
  TRANSLATION_MODULE: false
};

const s = JSON.stringify(LayoutSyncAction);

const initialState = {
  layoutSync: JSON.parse(s),
  miniMasterData: [],
  isDarkMode: false,
  isCollapsed: false,
  axiosSourceToken: {}
};

export default function commonReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_LAYOUT_SYNC: {
      return {
        ...state,
        layoutSync: payload
      };
    }
    case SET_MINI_MASTER_DATA: {
      return {
        ...state,
        miniMasterData: payload
      };
    }

    case SET_DARK_MODE_STATUS: {
      return {
        ...state,
        isDarkMode: payload
      };
    }

    case SET_TOGGLE_SIDEBAR_COLLAPSE: {
      return {
        ...state,
        isCollapsed: payload // Toggle the collapsed state
      };
    }
    case SET_AXIOS_SOURCE_TOKEN: {
      return {
        ...state,
        axiosSourceToken: payload // Toggle the collapsed state
      };
    }

    default: {
      return state;
    }
  }
}
