import React, { useTransition } from "react";
import { Steps, StepsProps } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import "./Stepper.css";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";

export const Stepper: FC = () => {
  const { activeStepper } = useSelector((state: any) => state?.contentTranscreationReducer);

  const { t } = useTranslation();

  const items = [
    {
      // title: "Source"
      title: `${t(I18N_MODULE_KEYS["content.transcreation.activebar.heading.source"])}`
    },
    // {
    //   title: "Optimize"
    // },
    {
      // title: "Process"
      title: `${t(I18N_MODULE_KEYS["content.transcreation.activebar.heading.process"])}`
    },
    {
      // title: "Successful"
      title: `${t(I18N_MODULE_KEYS["content.transcreation.activebar.heading.successful"])}`
    }
  ];
  const customDot: StepsProps["progressDot"] = (dot, { status, index }) => {
    //console.log({ status, index }); //status: "wait" | "process" | "finish"
    return (
      <div
        className="progressDot"
        style={status !== "wait" ? { backgroundColor: "#0546a5", backgroundClip: "content-box" } : { backgroundColor: "#fff" }}
      ></div>
    );
  };

  return <Steps className="ct-stepper" progressDot={customDot} current={activeStepper - 1} labelPlacement="vertical" items={items} />;
};
