export const zhLocale = {
  /*主要常量*/
  ["app.name"]: "生成式AI",
  ["app.logo"]: "extgen",

  /*头部常量*/
  ["header.searchtext"]: "在这里搜索...",
  ["header.menu.language.english"]: "英语",
  ["header.menu.language.spanish"]: "西班牙语",
  ["header.menu.language.chinese"]: "中文",
  ["header.menu.language.hindi"]: "印地语",
  ["header.menu.myprofile"]: "我的档案",
  ["header.menu.logout"]: "登出",

  /*侧边栏常量*/
  ["sidebar.dashboard"]: "仪表板",
  ["sidebar.history"]: "内容历史",

  /*模块常量 */
  ["module.dashboard"]: "仪表板",
  ["module.transcreation"]: "内容创译",
  ["module.login"]: "登录",
  ["module.signup"]: "注册",
  ["module.history"]: "内容历史",
  ["module.translation"]: "内容翻译",
  ["module.semantic"]: "语义搜索",
  ["module.fieldcopilot"]: "领域副驾驶员",
  ["module.contentcreation"]: "内容创建",
  ["module.idetailextract"]: "爱详提取",
  ["module.translation-module"]: "翻译",

  /*Dashboard constant*/
  ["content.dashboard.card.generativeai.title"]: "生成式 AI",
  ["content.dashboard.card.generativeai.desc"]:
    "生成人工智慧 (AI) 是能夠使用生成模型生成文字、圖像或其他媒體的人工智慧。生成式 AI模型學習輸入訓練資料的模式和結構，然後產生具有相似特徵的新資料。",

  ["content.dashboard.card.idetail.title"]: "爱详提取",
  ["content.dashboard.card.idetail.desc"]:
    "变革性人工智能动力引擎将iDetail转化为从印刷 - 就绪横幅到个性化电子邮件的多功能资产，以满足不同的受众需求。",

  ["content.dashboard.card.translation-module.title"]: "内容翻译 ",
  ["content.dashboard.card.translation-module.desc"]: "由生成式人工智能驱动的工具，可在多种语言间提供实时、上下文和术语感知的翻译。",

  ["content.dashboard.card.transcreation.title"]: "内容创译",
  ["content.dashboard.card.transcreation.desc"]: "将内容从一种语言转换为另一种语言的过程，同时保持其意图、上下文、风格和语气。",

  ["content.dashboard.card.semantic.title"]: "下一个要点",
  ["content.dashboard.card.semantic.desc"]: "高级语义搜索将非结构化数据转化为可操作的见解，自动化研究，并增强战略决策。", 
  
  ["content.dashboard.card.contentai.title"]: "内容AI",
  ["content.dashboard.card.contentai.desc"]: "AI通过认知计算技能提供个性化支持，利用其理解、学习和适应个体需求的能力。",

  ["content.dashboard.card.fieldcopilot.title"]: "区域辅助",
  ["content.dashboard.card.fieldcopilot.desc"]: "赋予销售代表卓越的HCP、患者洞察力和先进的医药和产品推荐能力。",

  ["content.dashboard.card.kolvideo.title"]: "KOL 视频",
  ["content.dashboard.card.kolvideo.desc"]: "使用 AI 生成的 KOL 頭像、GenAI 支援的腳本生成和全面的編輯套件無縫創建 KOL 影片。",

  ["content.dashboard.card.assistant.title"]: "Gen AI 仪表板助手",
  ["content.dashboard.card.assistant.desc"]: "通过自然语言对话输入、交互式视觉洞察和简便的叙述，简化数据分析，以便做出明智和快速的决策。",

  ["content.dashboard.card.hyper.title"]: "超个性化",
  ["content.dashboard.card.hyper.desc"]: "根据个体偏好和需求量身定制体验，创造深度个性化和有意义的互动。",

  ["content.dashboard.card.patientjourney.title"]: "患者旅程绘制",
  ["content.dashboard.card.patientjourney.desc"]: "透過 Gen AI 驅動的患者旅程繪製、理解和個人化醫療保健體驗，徹底改變製藥業的患者護理。",

  ["content.dashboard.card.translationlocalisation.title"]: "内容翻译与本地化",
  ["content.dashboard.card.translationlocalisation.desc"]: "通过翻译和本地化适应全球观众，确保文化相关性、语言准确性和地区吸引力。",

  ["content.dashboard.card.personalizedvideo.title"]: "个性化视频",
  ["content.dashboard.card.personalizedvideo.desc"]: "通过个性化视频改革医疗沟通，为医疗专业人员（HCP）和患者量身定制信息传递。",

  ["content.dashboard.card.sentimentanalysis.title"]: "情绪分析",
  ["content.dashboard.card.sentimentanalysis.desc"]: "利用认知计算识别和分类文本中用户意见，以增强情感分析和理解。",

  ["content.dashboard.card.contentcreation.title"]: "社交媒体内容创建",
  ["content.dashboard.card.contentcreation.desc"]: "轻松地制作社交媒体内容并对消息进行再创作，重新定义HCP、患者和HCP市场的沟通。",

  ["content.dashboard.card.button.description"]: "描述",
  ["content.dashboard.card.button.demo"]: "演示",

  ["content.transcreation.activebar.heading.source"]: "来源",
  ["content.transcreation.activebar.heading.process"]: "处理",
  ["content.transcreation.activebar.heading.successful"]: "成功",

  ["content.transcreation.selectsource.1.title"]: "今天您想要创建什么？",
  ["content.transcreation.selectsource.1.card.video"]: "视频",
  ["content.transcreation.selectsource.1.card.social"]: "社交媒体帖子",
  ["content.transcreation.selectsource.1.card.banner"]: "横幅",
["content.transcreation.selectsource.1.card.journalad"]: "期刊广告",
["content.transcreation.selectsource.1.card.literature"]: "随手资料",
  ["content.transcreation.selectsource.1.card.poster"]: "海报",
  ["content.transcreation.selectsource.1.card.newsletter"]: "简报",
  ["content.transcreation.selectsource.1.card.brochure"]: "小册子",

  ["content.transcreation.selectsource.2.title"]: "选择一个选项",
  ["content.transcreation.selectsource.2.card.email"]: "电子邮件到视频",
  ["content.transcreation.selectsource.2.card.blog"]: "博客到视频",
  ["content.transcreation.selectsource.2.card.banner"]: "横幅到视频",
  ["content.transcreation.selectsource.2.card.socialmediapost"]: "社交媒体帖子转视频",
  ["content.transcreation.selectsource.2.card.poster"]: "海报转视频",

  ["content.transcreation.source.card.upload"]: "上传文件",
  ["content.transcreation.source.card.pullClaims"]: "从 DAMS 提取声明",
["content.transcreation.source.card.uploadasset"]: "上传资产并提取声明",
  ["content.transcreation.source.card.uploaded"]: "已上传的文件",

  ["content.transcreation.source.card.text.drag"]: "拖放文件进行上传或单击浏览文件以选择文件",
  ["content.transcreation.source.card.text.file"]: "文件类型：PDF，最大文件大小：30 MB",
  ["content.transcreation.source.card.text.nofileselected"]: "未选择文件！",

  ["content.transcreation.source.card.text.subject"]: "主题行",
  ["content.transcreation.source.card.text.image"]: "使用的图像",
  ["content.transcreation.source.card.text.body"]: "正文",

  ["content.transcreation.process.storyboard.title"]: "创建故事板",
  ["content.transcreation.process.storyboard.text.selectall"]: "全选",
  ["content.transcreation.process.storyboard.text.deselectall"]: "取消全选",
  ["content.transcreation.process.storyboard.text.scene"]: "场景",
  ["content.transcreation.process.storyboard.text.title"]: "标题",
  ["content.transcreation.process.storyboard.text.narration"]: "叙述",
  ["content.transcreation.process.storyboard.text.story"]: "故事",
  ["content.transcreation.process.storyboard.text.nostoryfound"]: "未找到故事！",

  ["content.transcreation.process.storyboard.modal.title.editstoryboard"]: "编辑故事板",
  ["content.transcreation.process.selectimage.title.chooseplatform"]: "选择您的图片/剪辑创建平台",
  ["content.transcreation.process.generatedimage.title.imagegeneration"]: "图像生成",
  ["content.transcreation.process.generatedimage.title.clipgeneration"]: "剪辑生成",
  ["content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo"]: "请选择单个或多个选项以生成视频",
  ["content.transcreation.process.generatedimage.drawer.title.addliveclips"]: "添加实时剪辑",

  ["content.transcreation.process.template.title.chooseyourtemplate"]: "选择您的模板",
  ["content.transcreation.process.template.title.notemplatefound"]: "未找到模板！",
  ["content.transcreation.process.avatar.title.chooseyouravatar"]: "选择您的头像",
  ["content.transcreation.process.avatar.title.noavatarfound"]: "未找到头像！",

  ["content.transcreation.successful.title.videogeneration"]: "视频生成",
  ["content.transcreation.successful.title.generatevideo"]: "生成视频",

  ["content.transcreation.viewsummary.drawer.title.summary"]: "摘要",
  ["content.transcreation.viewsummary.drawer.title.storyboard"]: "故事板",
  ["content.transcreation.viewsummary.drawer.title.images"]: "图像",
  ["content.transcreation.viewsummary.drawer.title.templates"]: "模板",
  ["content.transcreation.viewsummary.drawer.title.avatars"]: "头像",
  ["content.transcreation.viewsummary.drawer.title.clips"]: "剪辑",

  // 转创作页脚
  ["content.transcreation.footer.button.getstarted"]: "开始",
  ["content.transcreation.footer.button.viewdashboard"]: "查看仪表板",
  ["content.transcreation.footer.button.regeneratedoc"]: "重新生成文档",
  ["content.transcreation.footer.button.createstoryboard"]: "创建故事板",
  ["content.transcreation.footer.button.regeneratestory"]: "重新生成故事",
  ["content.transcreation.footer.button.choosetemplate"]: "选择模板",
  ["content.transcreation.footer.button.chooseavatar"]: "选择头像",
  ["content.transcreation.footer.button.viewsummary"]: "查看摘要",
  ["content.transcreation.footer.button.ordervideo"]: "订购视频",

  ["content.transcreation.modal.ordervideosuccess"]: "视频订单成功",
  ["content.transcreation.modal.regneration"]: "警告！视频已经生成",
  ["content.transcreation.modal.regenerationdescription"]: "后续的故事板、图像和视频等内容将被丢弃，并重新生成视频。您想继续吗？",

  // //notifications transcreation
  ["content.transcreation.steppers.1.notification.selectfile"]: "请选择要上传的文件。",
  ["content.transcreation.steppers.1.notification.filesizeissue"]: "文件大小超过30MB的限制！",
  ["content.transcreation.steppers.1.notification.filetypeissue"]: "不支持的文件类型",
  ["content.transcreation.steppers.2.notification.textcopied"]: "文本已成功复制！",
  ["content.transcreation.steppers.blogmodal.notification.fillblogurl"]: "请填写博客网址。",
  ["content.transcreation.steppers.blogmodal.notification.entervalidurl"]: "请输入有效的URL",

  ["content.transcreation.wrapper.notification.postpublicationfail"]: "发布失败！",
  ["content.transcreation.wrapper.notification.postpublicationsuccess"]: "发布成功！",
  ["content.history.notification.featureinprogress"]: "此功能正在开发中。",
  ["content.history.core.notification.filedeletionsuccess"]: "文件已成功删除。",
  ["content.history.core.notification.filerenamesucess"]: "文件已成功重命名。",
  ["content.history.core.notification.videodownloadsucess"]: "视频已成功下载。",
  ["content.history.core.notification.videodownloaderror"]: "下载视频时发生错误。",
  ["content.history.view.gridview.notification.videogenerationinprogress"]: "视频生成仍在进行中！",
  ["content.translation.translationwrapper.notification.videorendererror"]: "渲染视频时发生错误",
  ["content.translation.core.notification.limitexceed"]: "超出限制！数据未更新。",
  ["content.translation.core.notification.noslide"]: "没有要翻译的幻灯片。",

  // Content AI 常量
  "content.contentai.headings.contentai": "内容 AI",
  "content.contentai.headings.contentcreator": "内容创作者",
  "content.contentai.headings.llmexperience": "法学硕士体验标记",
  "content.contentai.headings.llmcompliance": "法学硕士合规标记",
  "content.contentai.headings.llmmlrguidline": "法学硕士 艾马艾儿指南",
  "content.contentai.headings.contentuniverse": "内容宇宙",

  "content.contentai.headings.contentai.text.mynameisline": "我的名字是Lin",
  "content.contentai.headings.contentai.text.howcanihelpyou": "我如何帮助您？",
  "content.contentai.headings.contentai.text.createpharmacontent": "以领域特异性创建制药内容",
  "content.contentai.headings.contentai.text.complianceandregulation": "合规与法规",

  "content.contentai.headings.contentai.text.enrichprompt": "丰富提示",
  "content.contentai.headings.contentai.text.revisedprompt": "修订提示",
  "content.contentai.headings.contentai.text.appliedrules": "已应用规则",
  "content.contentai.headings.contentai.text.triggeredprompt": "触发提示",
  "content.contentai.headings.contentai.text.refinedresponse": "精炼响应",

  "content.contentai.headings.llm.text.rule": "规则",
  "content.contentai.headings.llm.text.rules": "规则",
  "content.contentai.headings.llm.text.addnewrules": "添加新规则",
  "content.contentai.headings.llm.text.norulefound": "未找到规则",
  "content.contentai.headings.llm.text.enterrule": "输入规则",
  "content.contentai.headings.llm.text.applicability": "适用性",
  "content.contentai.headings.llm.text.tagsupdated": "标签已更新",

  "content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags": "所选标签的内容不可用。",
  "content.contentai.headings.contentuniverse.text.pleaseselectatag": "请选择一个标签。",
  "content.contentai.headings.contentuniverse.text.step1": "第一步",
  "content.contentai.headings.contentuniverse.text.step2": "第二步",
  "content.contentai.headings.contentuniverse.text.fileupload": "文件上传",
  "content.contentai.headings.contentuniverse.text.draganddropyourfileshere": "拖放您的文件到这里。",
  "content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB": "最大上传文件大小：128 MB。",
  "content.contentai.headings.contentuniverse.text.supportedfiletype": "支持的文件类型:",
  "content.contentai.headings.contentuniverse.text.uploadedfile": "已上传文件",
  "content.contentai.headings.contentuniverse.text.choosedetails": "选择详情",

  // Content AI 通知

  ["content.contentai.headings.contentuniverse.notification.contentdeleted"]: "内容已删除！",
  ["content.contentai.headings.contentuniverse.notification.contentemptyerror"]: "内容不能为空！",
  ["content.contentai.headings.contentuniverse.notification.contentupdate"]: "内容已更新！",
  ["content.contentai.LLM.notification.ruleemptyerror"]: "规则名称不能为空！",
  ["content.contentai.LLM.notification.newruleadded"]: "新增规则！",
  ["content.contentai.LLM.LLMBasecomponent.notification.cancelled"]: "已取消！",
  ["content.contentai.LLM.LLMBasecomponent.notification.rulesapplied"]: "已应用内容宇宙规则！",
  ["content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied"]: "已应用提示规则！",
  ["content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat"]: "不支持的格式！\n仅支持 .xlsx 文件！",

  //语义搜索模块浮动侧边栏
  ["semantic.search.floatingsidebar.search"]: "搜索",
  ["semantic.search.floatingsidebar.filemanagement"]: "文件管理",

  //语义搜索
  ["semantic.search.header.heading.search"]: "搜索查询",
  ["semantic.search.landing.content.enterquery"]: "输入查询以查看结果",
  ["semantic.search.landing.content.number"]: "数量",
  ["semantic.search.landing.content.documentsindexed"]: "索引文档",
  ["semantic.search.landing.content.insightsgiven"]: "给予见解",
  ["semantic.search.landing.content.insightsdownloaded"]: "下载见解",
  ["semantic.search.input.heading.search"]: "搜索",
  ["semantic.search.input.heading.entersearch"]: "输入搜索查询",
  ["semantic.search.input.heading.clear"]: "清除",
  ["semantic.search.result.enterfeedback"]: "输入您的反馈",
  ["semantic.search.loading.pleasewait"]: "请稍候...",
  ["semantic.search.loading.searchinprogress"]: "您的搜索正在进行中",
  ["semantic.search.result.searchdepth"]: "搜索深度",
  ["semantic.search.result.format"]: "格式",
  ["semantic.search.result.regenerate"]: "重新生成",
  ["semantic.search.result.depthoption.quickview"]: "快速查看",
  ["semantic.search.result.depthoption.deepdive"]: "深度挖掘",
  ["semantic.search.result.depthoption.fullspectrum"]: "全谱",
  ["semantic.search.result.depthoption.quickviewsmall"]: "快速查看",
  ["semantic.search.result.depthoption.deepdivesmall"]: "深度挖掘",
  ["semantic.search.result.depthoption.fullspectrumsmall"]: "全谱",
  ["semantic.search.result.formatoption.text"]: "文本",
  ["semantic.search.result.formatoption.list"]: "列表",
  ["semantic.search.result.formatoption.table"]: "表格",
  ["semantic.search.result.formatoption.textsmall"]: "文本",
  ["semantic.search.result.formatoption.listsmall"]: "列表",
  ["semantic.search.result.formatoption.tablesmall"]: "表格",
  ["semantic.search.result.answercard.files"]: "参考文件",
  ["semantic.search.result.answercard.answerfeedback"]: "反馈",
  ["semantic.search.result.answercard.nextinsight"]: "下一个见解",

  // semantic-search generate report
  ["semantic.search.result.modal.generate"]: "生成报告",
  ["semantic.search.result.modaltable.share"]: "分享",
  ["semantic.search.result.modaltable.add"]: "添加到报告",
  ["semantic.search.result.modaltable.added"]: "已添加到报告",
  ["semantic.search.result.viewmore"]: "查看更多...",
  ["semantic.search.result.noanswers"]: "未找到答案！",
  ["semantic.search.result.noreference"]: "未找到任何参考",
  ["semantic.search.result.assets"]: "资产位置",

  ["semantic.search.result.searchresults"]: "搜索结果",
  ["semantic.search.result.actions"]: "操作",
  ["semantic.search.result.noreportselected"]: "未选择任何报告！",

  // drawer
  ["semantic.search.result.drawer.pagenumber"]: "页码：",
  ["semantic.search.result.drawer.searchquery"]: "搜索查询",
  ["semantic.search.result.drawer.notfound"]: "未找到",

  //语义高级过滤器
  ["semantic.search.header.searchbyfoldername"]: "按文件夹名称、文档名称搜索",
  ["semantic.search.header.enterfoldername"]: "输入文件夹名称",
  ["semantic.search.header.advancedfilters"]: "高级过滤器",
  ["semantic.search.header.appliedfilters"]: "已应用过滤器",
  ["semantic.search.advancedfilters.tip"]: "提示：点击类别查看文件夹",
  ["semantic.search.advancedfilters.selectall"]: "全选",
  ["semantic.search.advancedfilters.document"]: "文档",
  ["semantic.search.advancedfilters.URL"]: "URL",
  ["semantic.search.advancedfilters.image"]: "图片",
  ["semantic.search.advancedfilters.video"]: "视频",
  ["semantic.search.advancedfilters.audio"]: "音频",

  //语义计费页面
  ["semantic.search.billing.heading"]: "使用和计费详情",
  ["semantic.search.billing.downloadreport"]: "下载报告",
  ["semantic.search.billing.apiconsumption"]: "API消耗",
  ["semantic.search.billing.monthlybilling"]: "每月计费",
  ["semantic.search.billing.api"]: "API",
  ["semantic.search.billing.units"]: "单位",
  ["semantic.search.billing.unitprice"]: "单位价格（$）",
  ["semantic.search.billing.cost"]: "成本（$）",
  ["semantic.search.billing.costheads"]: "成本项",
  ["semantic.search.billing.amount"]: "金额（$）",
  ["semantic.search.billing.chat"]: "聊天",
  ["semantic.search.billing.embeddings"]: "嵌入",
  ["semantic.search.billing.notavailable"]: "不可用",
  ["semantic.search.billing.totalamount"]: "总金额",
  ["semantic.search.billing.notification"]: "文件已成功下载",

  //语义用户历史
  ["semantic.search.userhistory.heading"]: "用户历史",
  ["semantic.search.userhistory.insightssearched"]: "搜索的见解",
  ["semantic.search.userhistory.chats"]: "聊天",
  ["semantic.search.userdetails.nochatsfound"]: "未找到聊天记录",
  ["semantic.search.userdetails.noinsightsfound"]: "未找到见解",

  //语义文件系统
  ["semantic.search.filesystem.uploaddocument"]: "上传文档",
  ["semantic.search.filesystem.upload"]: "上传",
  ["semantic.search.filesystem.createnewfolder"]: "创建新文件夹",
  ["semantic.search.filesystem.createfolder"]: "创建新文件夹",
  ["semantic.search.filesystem.browsefolder"]: "拖放文件以上传或浏览",
  ["semantic.search.filesystem.nodocsavaliable"]: "没有可用的文档",
  ["semantic.search.filesystem.nodocsavaliablecreate"]: "创建文件夹并上传您的文档",
  ["semantic.search.filesystem.chat"]: "聊天",
  ["semantic.search.filesystem.Document By"]: "文档名称",
  ["semantic.search.filesystem.DocumentType"]: "文档类型",
  ["semantic.search.filesystem.Uploaded By"]: "上传者",
  ["semantic.search.filesystem.Uploaded Date"]: "上传日期",
  ["semantic.search.filesystem.actions"]: "操作",
  ["semantic.search.filesystem.documents"]: "文档",
  ["semantic.search.filesystem.videos"]: "视频",
  ["semantic.search.filesystem.audios"]: "音频",
  ["semantic.search.filesystem.images"]: "图片",
  ["semantic.search.filesystem.urls"]: "Web链接",
  ["semantic.search.filesystem.documentsuploading"]: "文件上传",
  ["semantic.search.filesystem.deletionfolderconfirmation"]: "您是否要继续删除文件夹",
  ["semantic.search.filesystem.deletionfileconfirmation"]: "您是否要继续删除文件",
  ["semantic.search.filesystem.notification.foldercreation"]: "文件夹创建成功",
  ["semantic.search.filesystem.notification.noinput"]: "未提供文件夹创建的输入",
  ["semantic.search.filesystem.notification.folderdeletion"]: "文件夹删除成功",
  ["semantic.search.filesystem.notification.filedeletion"]: "文件删除成功",
  ["semantic.search.filesystem.notification.folderdeletion.notupdated"]: "文件夹删除成功，但在界面上未更新",
  ["semantic.search.filesystem.notification.filedeletion.notupdated"]: "文件删除成功，但在界面上未更新",
  ["semantic.search.filesystem.notification.errorfolderdeletion"]: "删除文件夹时发生错误",
  ["semantic.search.filesystem.notification.errorfiledeletion"]: "删除文件时发生错误",
  ["semantic.search.filesystem.notification.fileuploaded"]: "文件成功上传",
  ["semantic.search.filesystem.notification.nofileselected"]: "未选择要上",
  ["semantic.search.result.notification.fileuploaded"]: "文件已下载",
  ["semantic.search.result.notification.copy"]: "文本已成功复制",
  ["semantic.search.result.notification.unabletodownload"]: "无法下载文件",
  ["semantic.search.result.notification.connectionerror"]: "连接错误",
  ["semantic.search.result.notification.feedbacksubmitted"]: "反馈提交成功",
  ["semantic.search.advancedfilters.notification.applied"]: "高级筛选已应用",
  ["semantic.search.result.modaltable.addcolumns"]: "添加手动列",
  ["semantic.search.result.modaltable.douwanttoaddcolumns"]: "是否要添加手动列？",
  ["semantic.search.result.modaltable.notification.manualcolumnsadded"]: "已添加手动列",
  ["semantic.search.result.modaltable.enternewitem"]: "输入新项目",
  ["semantic.search.filesystem.notification.foldercannotbecreated"]: "文件夹无法成功创建",
  ["semantic.search.filesystem.notification.filecannotbeuploaded"]: "文件无法成功上传",

  ["semantic.search.filesystem.deletionimageconfirmation"]: "你想要继续删除图片吗？",
  ["semantic.search.filesystem.notification.imagedeletion"]: "图片删除成功",
  ["semantic.search.filesystem.notification.imagedeletion.notupdated"]: "图片删除成功，但在界面上未更新",
  ["semantic.search.filesystem.notification.errorimagedeletion"]: "图片删除错误",
  ["semantic.search.filesystem.notification.noimageselected"]: "未选择要上传的图片",
  ["semantic.search.filesystem.notification.imageuploaded"]: "图片成功上传",
  ["semantic.search.filesystem.notification.imagecannotbeuploaded"]: "图片无法成功上传",

  ["chatdocument.search.chatwithdocument"]: "与文档聊天",
  ["chatdocument.search.chathistory"]: "聊天记录",
  ["chatdocument.search.chatwith"]: "与之聊天",
  ["chatdocument.search.clearall"]: "清除全部",
  ["chatdocument.search.downloadchat"]: "下载聊天",
  ["chatdocument.search.pages"]: "页",
  ["chatdocument.search.notfound"]: "未找到任何先前的聊天记录",
  ["chatdocument.search.today"]: "今天的聊天",
  ["chatdocument.search.7days"]: "过去7天",
  ["chatdocument.search.lastmonth"]: "上个月",
  ["chatdocument.search.lastyear"]: "过去1年",
  ["chat.drawer.notification.chatdownload"]: "聊天已下载",
  ["chat.drawer.notification.errorchat"]: "连接错误或没有启动下载的聊天",

  //login
  ["content.login.text.customizeyouremailusingai"]: "使用人工智能定制您的电子邮件",
  ["content.login.text.email"]: "电子邮件",
  ["content.login.text.password"]: "密码",
  ["content.login.text.confirmpassword"]: "确认密码",
  ["content.login.text.convertyouremailintoHTMLCraftImagesand"]: "将您的电子邮件转换为HTML，制作图像，并",
  ["content.login.text.weavevideostoleavealasting"]: "编织视频，留下持久的印记",
  ["content.login.text.pleaseenteravalidemailaddress"]: "请输入有效的电子邮件地址",
  ["content.login.text.pleaseenteravalidpassword"]: "请输入有效的密码",
  ["content.login.text.passwordisrequired"]: "密码是必需的",
  ["content.login.text.emailisrequired"]: "电子邮件是必需的",
  ["content.login.text.rememberme"]: "记住我",
  ["content.login.text.forgotpassword"]: "忘记密码？",
  ["content.login.text.donthaveanaccount"]: "还没有账户？",
  ["content.login.text.createanaccount"]: "创建一个账户",
  ["content.signup.text.alreadyhaveanaccount"]: "已经有账户？",
  ["content.verifiedpage.text.signupsuccessfull"]: "注册成功。请检查您的邮件进行验证",
  ["content.forgot.text.gotologinpage"]: "转到登录页面",
  ["content.forgot.text.resetpassword"]: "重置密码",
  ["content.forgot.text.clicktologin"]: "点击登录",
  ["content.forgot.text.helloagain"]: "你好，再次！",

  /**Content translation */
  ["content.translation.voicemodal.Select a Voice"]: "选择语音",
  ["content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs."]:
    "听取语音示例并使用标签找到适合您需求的最佳语音。",
  ["content.translation.voicemodal.Select Gender"]: "选择性别",
  ["content.translation.voicemodal.Select by Tags"]: "按标签选择",
  ["content.translation.voicemodal.Show Premium"]: "显示高级",
  ["content.translation.voicemodal.Apply to All"]: "应用于所有",
  ["content.translation.voicemodal.Apply Voices"]: "应用语音",

  //Use Case Constants
  /** Field Copilot */
  ["content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant"]: "田野副驾驶员 - 您的生成式 AI 助手",
  ["content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations"]:
    "为代表提供无与伦比的艾尺屁、患者洞察和先进的制药和产品推荐",
  ["content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point"]: "一站式访问点",
  ["content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools"]:
    "生成AI 启用的综合合成提供了一个统一的视图，减少了对多种工具的需求",
  ["content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations"]: "量身定制的建议",
  ["content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time"]:
    "为代表问题提供个性化答案，实时提供上下文建议",
  ["content.usecase.fieldcopilot.description.tabcontents.Scientific Responses"]: "科学回应",
  ["content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric"]: "以合规为中心",
  ["content.usecase.fieldcopilot.description.tabcontents.Every interaction and recommendation is designed to be compliant, ensuring peace of mind"]:
    "每次互动和建议都设计为符合规定，确保安心",
  ["content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base"]: "策划知识库",
  ["content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base"]:
    "访问精心选择的制药公司和产品数据和知识库",
  ["content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access"]: "营销360洞察访问",
  ["content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey"]:
    "与数字平台生态系统无缝集成，实时更新客户旅程",
  ["content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management"]: "辅助任务管理",
  ["content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders"]:
    "快速访问每日日程安排、即将到来的约会和任务提醒",
  ['content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation']:
    '不仅是答案，而且是对每个建议背后的"为什么"、"谁"和"什么"的洞察',

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"]: "业务价值主张",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop"]: "直观界面和反馈循环",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface"]: "交互式界面",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI."]:
    "允许代表与 AI 进行互动并提供输入。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection"]: "反馈收集",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model."]:
    "捕获用户的反馈以完善和改进模型。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability"]: "实时适应性",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback."]:
    "根据即时反馈更新模型的响应。",

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture"]: "生成式 AI 模型架构",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI"]: "生成式 AI",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures."]:
    "使用GAN或Transformer架构选择LLM。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization"]: "训练和优化",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques."]:
    "通过训练技术提升性能。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability"]: "部署和可扩展性",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions."]: "确保无缝解决方案。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System"]: "集成数据与知识系统",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources"]: "多样化的数据源",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data."]: "收集多样化和高质量的数据。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning"]: "数据清洗",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data."]:
    "消除不一致性、噪音或不相关的数据。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering"]: "特征工程",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance."]:
    "改进数据以提高模型性能。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework"]: "合规与报告框架",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation"]: "偏见缓解",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases."]:
    "确保模型不会持续存在有害偏见。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency"]: "透明度",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions."]:
    "提供人工智能决策的洞见。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails"]: "合规与防护栏",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries."]: "在法律和道德边界内运作。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library"]: "全面的提示库",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries"]: "动态查询",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs."]:
    "适用于各种用户需求的可适应提示。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions"]: "引导式交互",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results."]: "引导用户获得期望的结果。",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts"]: "分类提示",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access."]:
    "按主题或功能组织查询，方便访问。",

  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"]: "投资回报率",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact"]: "财务影响",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency"]: "成本效率",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules."]:
    "通过利用基于人工智能的、按需的模块来降低培训费用。",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI"]: "最大化投资回报率",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns."]:
    "优化代表绩效可以放大销售结果，确保每一美元培训都能带来更高的回报。",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence"]: "销售运营卓越性",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding"]: "快速入职",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity."]:
    "基于人工智能的模块加速新代表的整合，提高了生产力。",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability"]: "动态适应性",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs."]:
    "为代表提供实时市场见解，减少大量的重新培训需求。",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements"]: "增强互动",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements"]: "知情互动",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning."]:
    "通过及时的、由人工智能驱动的见解，加强与艾尺屁的互动，巩固市场定位。",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation"]: "风险缓解",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation."]:
    "内置的合规和报告机制确保遵守全球标准，保护品牌声誉。",

  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"]: "成功指标",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time"]: "任务完成时间",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it"]:
    "医疗代表在使用人工智能副驾驶的情况下完成任务所需的时间与不使用的时间相比。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement"]: "销售/生产力改善",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot"]:
    "医疗代表在使用人工智能副驾驶时实现的销售或生产力增长。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontentsAccuracy of Information"]: "信息准确性",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot"]:
    "人工智能副驾驶提供的信息准确性。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback"]: "客户反馈",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot"]:
    "与使用人工智能副驾驶的医疗代表互动的客户的反馈。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate"]: "参与率",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions"]:
    "医疗代表在与人工智能副驾驶互动时的积极参与频率。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization"]: "个性化",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths"]:
    "人工智能副驾驶适应个体医疗代表工作风格和优势的能力。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations"]: "合规性与法规",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies"]:
    "人工智能副驾驶协助医疗代表遵守行业法规和公司政策。",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings"]: "成本节约",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot"]: "通过使用AI副驾驶实现的成本节约。",

  ["content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation"]: "内容生成",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"]: "未来潜力",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements"]:
    "人工智能可以根据最新的医学研究和指南，协助生成各种类型的内容，如文章、演示文稿和案例研究。这些内容可以帮助医疗代表有效地教育医疗专业人员，并使其了解最新的进展。",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations"]: "培训模拟",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations"]:
    "生成式人工智能可以创建逼真的医学场景模拟，使医疗代表能够在受控环境中练习他们的沟通技巧。这将有助于他们完善对不同情况的陈述和回应。",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation"]: "语言翻译",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach"]:
    "由人工智能驱动的语言翻译可以使医疗代表与来自不同语言背景的医疗专业人员进行交流，消除语言障碍，扩大他们的影响范围。",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics"]: "预测分析",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement"]:
    "通过分析历史数据和模式，人工智能可以预测与特定医疗专业人员联系的最合适时间和方法。这将增强互动的有效性并提高参与度。",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction"]: "语音交互",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points"]:
    "随着语音识别技术的进步，人工智能可以在电话或虚拟会议期间帮助医疗代表，提供实时信息和建议的谈话要点。",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Ethical Considerations"]: "道德考虑",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA"]:
    "尽管潜在的好处可观，但会有道德考虑，特别是涉及患者隐私和数据安全的问题。医疗代表和人工智能开发者需要确保患者信息得到负责任的处理，并符合HIPAA等法规的规定。",
  /************/

  /**Content Creation */
  "content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations":
    "具有域特异性、合规性和法规的快速内容生成",
  "content.usecase.contentai.description.Prompt Enrichment": "提示丰富化",
  "content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience":
    "允许用户丰富提示以获得与目标受众 resonates 的所需内容",
  "content.usecase.contentai.description.Automatic regulatory compliance check": "自动监管合规性检查",
  "content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards.":
    "生成的内容符合相关主体，确保内容符合所有医疗法律和监管标准。",
  "content.usecase.contentai.description.Localization optimization": "本地化优化",
  "content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement":
    "生成适合不同地区和语言的内容，不仅可以翻译，还可以进行文化适应，以确保最大程度的参与",
  "content.usecase.contentai.description.Responsive design generator": "响应式设计生成器",
  "content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices.":
    "生成的内容不仅在消息上量身定制，而且在设计上量身定制，确保在台式机、平板电脑和移动设备上的最佳查看。",
  "content.usecase.contentai.description.Scalable": "可扩展的",
  "content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs":
    "能够轻松生成大量内容，使企业能够扩大其电子邮件营销活动，而无需成本成本的增加",

  "content.usecase.contentai.business-value-proposition.Faster Time-To-Market": "更快的上市时间",
  "content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches.":
    "通过更快的内容生成，广告活动可以更快地执行，使企业能够迅速应对市场变化或新产品推出。",
  "content.usecase.contentai.business-value-proposition.Cost Efficiency": "成本效益",
  "content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets.":
    "减少与聘请内容写作者、翻译人员和设计师相关的成本，尤其是在扩大到更大的受众或进入新市场时。",
  "content.usecase.contentai.business-value-proposition.Market Expansion": "市场扩展",
  "content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities":
    "使企业能够轻松地接触和与全球受众交流，打开新市场和机会的大门",
  "content.usecase.contentai.business-value-proposition.Increased Engagements": "增加的互动",
  "content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs":
    "个性化和相关的内容通常会导致更高的打开率和点击率，并可能会与医疗专业人士建立更强大的关系",

  "content.usecase.contentai.return-on-investment.Financial Benefits": "财务收益",
  "content.usecase.contentai.return-on-investment.Cost savings": "成本节约",
  "content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors":
    "更高的速度和效率，更少的培训成本，减少错误",
  "content.usecase.contentai.return-on-investment.Improved top-line": "改善顶线",
  "content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream":
    "增强的参与度可能导致销售增加，直接影响收入流",
  "content.usecase.contentai.return-on-investment.Operational Benefits": "运营优势",
  "content.usecase.contentai.return-on-investment.Time saving": "节省时间",
  "content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution":
    "更快的内容生成、本地化、优化，简化广告活动执行",
  "content.usecase.contentai.return-on-investment.Employee efficiency": "员工效率",
  "content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects":
    "人力资源可以专注于战略性方面，而不是重复性任务",
  "content.usecase.contentai.return-on-investment.Marketing Benefits": "营销效益",
  "content.usecase.contentai.return-on-investment.Global reach": "全球覆盖",
  "content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint":
    "定制内容有助于与全球受众进行有效沟通，扩大市场印记",
  "content.usecase.contentai.return-on-investment.Market agility": "市场敏捷性",

  "content.usecase.contentai.success-metrics.Content production speed": "内容生成速度",
  "content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe":
    "测量从内容构思到发布所需的时间，并跟踪在特定时间范围内生成的内容数量",
  "content.usecase.contentai.success-metrics.Cost per content piece": "每篇内容的成本",
  "content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content": "计算生成每篇内容所需的成本",
  "content.usecase.contentai.success-metrics.CAC": "客户获取成本",
  "content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content": "通过内容获取客户的成本",
  "content.usecase.contentai.success-metrics.ROI": "投资回报率",
  "content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content":
    "从内容中获得的净利润除以生成内容的成本",
  "content.usecase.contentai.success-metrics.Conversion rate": "转化率",
  "content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content":
    "查看内容后采取所需行动的用户百分比",
  "content.usecase.contentai.success-metrics.Error rates": "错误率",
  "content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication":
    "发布后发现的错误频率（例如语法、事实）",
  "content.usecase.contentai.success-metrics.Revision rate": "修订率",
  "content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production.": "测量生成后修订或更正的频率。",
  /************/

  //基础
  ["content.history.wrapper.text.newfilename"]: "新文件名",
  ["content.history.wrapper.text.searchvideo"]: "搜索视频",
  ["content.history.wrapper.text.nohistoryfound"]: "未找到历史记录",

  ["content.button.submit"]: "提交",
  ["content.button.cancel"]: "取消",
  ["content.button.goback"]: "返回",
  ["content.button.save"]: "保存",
  ["content.button.proceed"]: "继续",
  ["content.button.regenerate"]: "重新生成",
  ["content.button.replace"]: "替换",
  ["content.button.add"]: "添加",
  ["content.button.showmore"]: "显示更多",
  ["content.button.discard"]: "丢弃",
  ["content.text.noimagefound"]: "未找到图像！",
  ["content.text.gotodashboard"]: "转到仪表板",
  ["content.button.next"]: "下一步",
  ["content.button.generate"]: "生成",
  ["content.text.enteryourprompt"]: "输入您的提示",
  ["content.text.generateinitialresponse"]: "生成初始响应",
  ["content.text.searchhere"]: "在此处搜索",

  ["content.text.promptcannotbeempty"]: "提示不能为空",
  ["content.text.filterby"]: "按过滤器",
  ["content.text.nofilterapplied"]: "未应用过滤器",
  ["content.button.reset"]: "重置",
  ["content.button.back"]: "返回",
  ["content.button.apply"]: "应用",
  ["content.button.upload"]: "上传",
  ["content.text.sessionexpired"]: "会话已过期",
  ["content.text.sessionhasexpired"]: "您的会话已过期。请重新登录！",
  ["content.text.actions"]: "操作",
  ["content.text.filterapplied"]: "已应用过滤器",
  ["content.text.selected"]: "已选择",
  ["content.text.edit"]: "编辑",
  ["content.text.delete"]: "删除",
  ["content.text.interested"]: "感兴趣",
  ["content.text.notinterested"]: "不感兴趣",
  ["content.text.share"]: "分享",
  ["content.text.response"]: "响应",
  ["content.button.update"]: "更新",
  ["content.text.errornoresponse"]: "错误：无响应！",
  ["content.text.erroruploadfailed"]: "错误：上传失败！",
  ["content.text.nofilesuploaded"]: "未上传文件！",
  ["content.button.delete"]: "删除",
  ["content.button.no"]: "否",
  ["content.button.yes"]: "是",
  ["content.button.stop"]: "停止",
  ["content.button.yesproceed"]: "是的，继续",
  ["content.transcreation.button.summary"]: "摘要",
  ["content.text.selectalanguage"]: "选择语言",
  ["content.text.translate"]: "翻译",
  ["content.text.name"]: "名称",
  ["content.text.uploadedon"]: "上传于",
  ["content.text.storyboard"]: "故事板",
  ["content.text.image/clips"]: "图像/剪辑",
  ["content.text.video"]: "视频",
  ["content.text.action"]: "操作",
  ["content.text.somethingwentwrong"]: "出现问题",
  ["content.text.internalservererror"]: "内部服务器错误",
  ["content.text.completed"]: "已完成",
  ["content.text.inprogress"]: "进行中",
  ["content.text.notstarted"]: "未开始",
  ["content.text.notapplicable"]: "不适用",
  ["content.text.failed"]: "失败",
  ["content.text.preview"]: "预览",
  ["content.text.download"]: "下载",
  ["content.text.rename"]: "重命名",
  ["content.text.render"]: "渲染",
  ["content.text.pause"]: "暂停",
  ["content.text.changevoice"]: "更改语音",
  ["content.text.speechtext"]: "语音文本",
  ["content.text.changeavatar"]: "更改头像",
  ["content.text.filteravatar"]: "按服装筛选头像",
  ["content.text.sendtoreport"]: "发送到报告",
  ["content.text.close"]: "关闭",
  ["content.text.chat"]: "聊天",

  ["content.text.phoneme"]: "音素",
  ["content.text.phonemedictionary"]: "音素词典",
  ["content.text.addphoneme"]: "添加音素",
  ["content.text.word"]: "单词",
  ["content.text.pronunciation"]: "发音",
  ["content.text.phonemedescription"]: "您在整个帐户的所有视频中将使用的音素列表。这里的任何单词都将按照“发音”列中的拼写在您的所有视频中。",

  ["content.text.error.400"]: "响应：错误请求",
  ["content.text.error.401"]: "响应：未经授权",
  ["content.text.error.402"]: "响应：需要付款",
  ["content.text.error.403"]: "响应：禁止访问",
  ["content.text.error.404"]: "响应：未找到",
  ["content.text.error.405"]: "响应：不允许的方法",
  ["content.text.error.406"]: "响应：不可接受",
  ["content.text.error.407"]: "响应：需要代理身份验证",
  ["content.text.error.408"]: "响应：请求超时",
  ["content.text.error.409"]: "响应：冲突",
  ["content.text.error.410"]: "响应：已经不存在",
  ["content.text.error.411"]: "响应：需要长度",
  ["content.text.error.412"]: "响应：前提条件失败",
  ["content.text.error.413"]: "响应：请求太大",
  ["content.text.error.414"]: "响应：请求URI太长",
  ["content.text.error.415"]: "响应：不支持的媒体类型",
  ["content.text.error.416"]: "响应：范围不可满足",
  ["content.text.error.417"]: "响应：期望失败",
  ["content.text.error.500"]: "响应：内部服务器错误",
  ["content.text.error.501"]: "响应：未实现",
  ["content.text.error.502"]: "响应：错误的网关",
  ["content.text.error.503"]: "响应：服务不可用",
  ["content.text.error.504"]: "响应：网关超时",
  ["content.text.error.505"]: "响应：HTTP版本不受支持",
  ["content.text.error.511"]: "响应：需要网络身份验证",

  ["content.text.disardallchanges"]: "放弃所有更改",
  ["content.text.areyousuretodiscard"]: "确定要放弃所有更改吗？",
  ["content.text.novideosfound"]: "没有找到视频！",

  ["content.text.generating.initialresponse"]: "生成初始响应",
  ["content.text.generating.refinedresponse"]: "生成精炼响应",
  ["content.text.regenerating.refinedresponse"]: "重新生成精炼响应",
  ["content.text.notification"]: "通知",

  ["content.text.enterblogurl"]: "输入博客网址",

  ["content.text.description"]: "描述",
  "content.button.start": "开始",

  "content.text.Strategic ROI of": "战略投资回报率",

  "content.text.Title should not be empty!": "标题不能为空！",
  "content.text.Scene should not be empty!": "场景不能为空！",
  "content.text.Narration should not be empty!": "叙述不能为空！",

  ["content.text.Updation of generated content is not allowed now as Video is rendered."]: "由于视频已渲染，现在不允许更新生成的内容。",

  ["content.text.Are you sure you want to delete?"]: "你确定你要删除？",
  ["content.text.Remove"]: "删除",

  "idetailextract._UploadFileCard.Upload Document": "上传文件",
  "idetailextract._UploadFileCard.Uploading": "正在上传",
  "idetailextract._UploadFileCard.Click to Upload or Drop PDF here ": "点击上传或拖放PDF文件至此",
  "idetailextract._UploadFileCard.from url": "从URL上传",
  "idetailextract._UploadFileCard.Set Language for Scanned files": "设置扫描文件语言",
  "idetailextract._UploadFileCard.Country": "国家",
  "idetailextract._UploadFileCard.Brand": "品牌",
  "idetailextract._UploadFileCard.Language": "语言",

  "idetailextract._CategoriesModal.Selected Categories": "已选择的类别",
  ["idetailextract._CategoriesModal.Search Catogory"]: "搜索类别",
  "idetailextract._CategoriesModal.Suggested": "建议的",
  "idetailextract._CategoriesModal.Additional": "附加",
  "idetailextract._CategoriesModal.No Data Found": "未找到数据",
  "idetailextract._CategoriesModal.Identified Categories": "已识别的类别",

  "idetailextract._CoreClaimMain._Headertab.Create Copy": "创建副本",

  "idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview": "上传的PDF预览",
  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Page"]: "页面",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.PDF > Document Copy"]: "PDF > 文档副本",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Selected Categories"]: "已选择的类别",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.ADD CATEGORIES"]: "添加类别",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.This is a preview version and you can edit and create a copy out of it."]:
    "注意：这是预览版本，您可以编辑并创建副本。",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Step"]: "步骤",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.of CVA"]: "的CVA",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Add"]: "添加",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Next Step"]: "下一步",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Previous Step"]: "上一步",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE ASSET TYPE"]: "选择资产类型",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE CLAIM CATEGORY"]: "选择索赔类别",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Choose ‘Asset & Claim category to proceed"]: "选择“资产和索赔类别”以继续",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Core Claims"]: "核心索赔",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Asset Creation Recommendation"]: "资产创建建议",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated"]: "内容已更新",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]: "内容已删除",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content"]: "内容",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page No"]: "页码",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content can not be empty"]: "内容不能为空",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page number can not be empty"]: "页码不能为空",

  ["idetailextract._CoreClaimMain._TinyMCE.Loading Editor"]: "加载编辑器",

  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content saved"]: "内容已保存",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Saving failed! Please retry."]: "保存失败！请重试。",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content discarded!"]: "内容已丢弃！",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No recent changes to save."]: "没有最近的更改需要保存。",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No unsaved changes to discard."]: "没有未保存的更改需要丢弃。",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Your Emailer Suggestion"]: "您的邮件建议",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save"]: "保存",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard"]: "丢弃",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit Edit Mode"]: "退出编辑模式",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Preview"]: "预览",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Cancel"]: "取消",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Lose Changes"]: "丢失更改",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.If you proceed, you will lose all the changes you have made."]:
    "如果继续，您将丢失所有已做的更改。",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit without Saving"]: "退出而不保存",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save and Exit"]: "保存并退出",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard unsaved changes"]: "丢弃未保存的更改",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Unsaved changes"]: "未保存的更改",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Proceed"]: "继续",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Close"]: "关闭",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Edit"]: "编辑",

  /**
   * Translation Module
   */

  "translation-module._TextTranslationMain.Choose Base Language": "选择基础语言",
  "translation-module._TextTranslationMain.Choose Target Language": "选择目标语言",
  "translation-module._TextTranslationMain.RESET": "重置",
  "translation-module._TextTranslationMain.TRANSLATE": "翻译",
  "translation-module._TextTranslationMain.Input": "输入",
  "translation-module._TextTranslationMain.Output": "输出",

  "translation-module._TextTranslationMain._IOTextTranslation.Enter your text": "输入您的文本",
  "translation-module._TextTranslationMain._IOTextTranslation.ADD GLOSSARY": "添加词汇表",

  "translation-module._AddGlossaryModal.Add Glossary": "添加词汇表",
  "translation-module._AddGlossaryModal.Set rules for how words and phrases are translated": "设置单词和短语的翻译规则",
  "translation-module._AddGlossaryModal.Replace": "替换",
  "translation-module._AddGlossaryModal.e.g, hello!": "例如，你好！",
  "translation-module._AddGlossaryModal.With": "用",
  "translation-module._AddGlossaryModal.e.g, 更高": "例如，更高",
  "translation-module._AddGlossaryModal.Source --> Target": "来源 --> 目标",
  "translation-module._AddGlossaryModal.RESET": "重置",
  "translation-module._AddGlossaryModal.ADD": "添加",
  "translation-module._AddGlossaryModal.UPDATE": "更新",
  "translation-module._AddGlossaryModal.Search item": "搜索项目",
  "translation-module._AddGlossaryModal.OR": "或者",
  "translation-module._AddGlossaryModal.Action": "操作",

  ["translation-module._MainTabs.TEXT TRANSLATION"]: "文本翻译",
  ["translation-module._MainTabs.UPLOAD DOCUMENT(S)"]: "上传文档",
  ["translation-module._UploadDocumentMain.File Upload"]: "上传文件",
  ["translation-module._UploadDocumentMain.ADD GLOSSARY"]: "添加术语表",
  ["translation-module._UploadDocumentMain.Drag And Drop Your Files Here."]: "拖拽您的文件至此。",
  ["translation-module._UploadDocumentMain."]: "支持的文件类型：.pdf",
  ["translation-module._UploadDocumentMain.of"]: "的",
  ["translation-module._UploadDocumentMain.files uploaded"]: "个文件已上传",
  ["translation-module._UploadDocumentMain.Uploaded File"]: "已上传文件",
  ["translation-module._UploadDocumentMain.No files uploaded!"]: "没有上传任何文件！",
  ["translation-module._UploadDocumentMain.NEXT"]: "下一步",
  ["translation-module._UploadDocumentMain.Clear All"]: "清除全部",

  ["translation-module._TranslateAssetMain._HeadingContainer.Translate your Asset"]: "翻译您的资产",
  ["translation-module._TranslateAssetMain._HeadingContainer.ADD GLOSSARY"]: "添加词汇表",
  ["translation-module._TranslateAssetMain._HeadingContainer.At the moment, AI-generated translation will be applied to all of your assets."]:
    "目前，所有资产将应用 AI 生成的翻译。",

  ["translation-module._TranslateAssetMain._SearchingContainer.Languages"]: "语言",
  ["translation-module._TranslateAssetMain._SearchingContainer.Search Language"]: "搜索语言",
  ["translation-module._TranslateAssetMain._SearchingContainer.Base Language English (US)"]: "基础语言: 英语 (美国)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Add Context to the Translation (Optional)"]: "向翻译添加上下文 (可选)",

  "translation-module._TranslateAssetMain._FooterContainer.Language Selected": "已选择的语言",
  "translation-module._TranslateAssetMain._FooterContainer.CANCEL": "取消",
  "translation-module._TranslateAssetMain._FooterContainer.PROCEED": "继续",
  "translation-module.FinalTranslationScreen.Translation is Done!": "翻译完成！",
  "translation-module.FinalTranslationScreen.Please select a file from the Dropdown to view the translated asset.":
    "请选择一个文件从下拉菜单查看翻译的资产。",
  "translation-module.FinalTranslationScreen.Edit": "编辑",
  "translation-module.FinalTranslationScreen.Input": "输入",
  "translation-module.FinalTranslationScreen.Output": "输出",
  "translation-module.FinalTranslationScreen.DOWNLOAD": "下载",
  "translation-module.FinalTranslationScreen.Languages": "语言",
  "translation-module.FinalTranslationScreen.START AGAIN": "重新开始",
  "translation-module.FinalTranslationScreen.GET YOUR QUALITY REPORT": "获取您的质量报告",

  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translate your package"]: "翻译您的软件包",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.At the moment, AI-generated translation will be applied to all of your assets."]:
    "目前，所有资产都将应用人工智能生成的翻译。",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Please wait..."]: "请等待...",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Selected Language"]: "已选语言",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.“Translation”"]: "“翻译”",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.is in Progress"]: "正在进行",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancel Translation"]: "取消翻译",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancelling Translation"]: "取消翻译",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translation Cancelled!"]: "翻译已取消！",

  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content saved!": "内容已保存！",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Saving failed! Please retry.": "保存失败！请重试。",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content discarded!": "内容已丢弃！",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No recent changes to save.": "没有最近的更改可保存。",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No unsaved changes to discard.": "没有未保存的更改可丢弃。",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Modify Translated Content": "修改已翻译内容",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save": "保存",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard": "丢弃",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Close": "关闭",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard unsaved changes": "丢弃未保存的更改",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Cancel": "取消",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Lose Changes": "失去更改",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Unsaved changes": "未保存的更改",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save and Exit": "保存并退出",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Exit without Saving": "退出而不保存",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.If you proceed, you will lose all the changes you have made.":
    "如果继续，您将丢失所有已做的更改。"
};
