import Lottie from "lottie-react";
import { FC } from "react";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { Row, Col, Typography } from "antd";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
const { Paragraph } = Typography;

export const SemanticSearchLoading: FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <Row gutter={[8, 8]} justify="center" align="middle">
      <Col span={24} className="d-flex justify-content-center align-items-center">
        <Lottie id="email" animationData={PROJECT_ICONS.SEMANTIC_LOADING_LOGO} loop={true} style={{ height: "23vh", width: "23vh" }} />
      </Col>
      <Col>
        <div
          style={{
            textAlign: "center",
            font: "normal normal normal 22px/30px Nunito Sans",
            letterSpacing: "0px",
            color: "#66676B",
            opacity: "1",
          }}
        >
          {t(I18N_MODULE_KEYS["semantic.search.loading.pleasewait"])}
        </div>
        <div
          style={{
            textAlign: "center",
            font: "normal normal normal 22px/30px Nunito Sans",
            letterSpacing: "0px",
            color: "#66676B",
            opacity: "1",
          }}
        >
          {t(I18N_MODULE_KEYS["semantic.search.loading.searchinprogress"])}
        </div>
      </Col>
    </Row>
  );
};
