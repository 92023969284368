import { Row, Col, InputNumber, ColorPicker } from "antd";
import { FontSelection } from "./components/FontSelection";
import { FontDecoration } from "./components/FontDecoration";
import { TextAlign } from "./components/TextAlign";
import { STYLE } from "./constants";
import { TextType } from "./components/TextType";
import LazySlider from "../../common/LazySlider";
import { CommonControls } from "../../common/CommonControls";
import { useTextControlsState } from "./useTextControlsState";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store";

export const TextControls = () => {
  const { canvasActiveObject } = useSelector((state: RootState) => state.contentTranscreationReducer);

  const {
    onStep,
    onSliderChange,
    manualFontSize,
    colorEditButton,
    onFontSizeChange,
    onBlurFontSizeInput,
    activeObjectProps,
    handleColorChange,
    getTextSelectionStyle,
    getTextSelectionFontFamily,
    handleLineHeightChange
  } = useTextControlsState();

  const fontSize = getTextSelectionStyle(activeObjectProps, "fontSize");
  const fontSizeValue = manualFontSize === false ? fontSize : manualFontSize;

  return (
    <div className="text-controls tab-content">
      <Row wrap={false} gutter={[4, 4]} style={STYLE.marginBottom16}>
        <FontSelection activeObjectProps={activeObjectProps} getTextSelectionFontFamily={getTextSelectionFontFamily} />
        <FontDecoration
          activeObjectProps={activeObjectProps}
          getTextSelectionStyle={getTextSelectionStyle}
          getTextSelectionFontFamily={getTextSelectionFontFamily}
        />
        <TextAlign activeObjectProps={activeObjectProps} getTextSelectionStyle={getTextSelectionStyle} />
      </Row>

      <Row style={STYLE.marginBottom16}>
        <Col span={5}>
          <h4 style={STYLE.marginColor}>Color</h4>
        </Col>
        <Col span={18}>
          <ColorPicker value={colorEditButton} onChange={handleColorChange} />
        </Col>
      </Row>

      <Row style={STYLE.marginBottom16}>
        <Col span={5}>
          <h4 style={STYLE.marginColor}>List</h4>
        </Col>
        <Col span={18}>
          <TextType activeObjectProps={activeObjectProps} />
        </Col>
      </Row>

      <Row align="middle" style={STYLE.marginBottom10}>
        <Col span={4}>
          <h4>Size</h4>
        </Col>
        <Col span={5}>
          <InputNumber value={fontSizeValue} style={STYLE.width60} controls={false} onChange={onFontSizeChange} onBlur={onBlurFontSizeInput} />
        </Col>
        <Col span={15}>
          <LazySlider
            value={fontSize}
            linkedElement={canvasActiveObject}
            linkedElementKey="fontSize"
            min={3}
            max={300}
            tooltip={{ open: false }}
            onStep={onStep}
            onChange={onSliderChange}
          />
        </Col>
      </Row>

      <Row align="middle">
        <Col span={9}>
          <h4>Line height</h4>
        </Col>
        <Col span={15}>
          <LazySlider
            value={activeObjectProps?.lineHeight}
            linkedElement={canvasActiveObject}
            linkedElementKey="lineHeight"
            min={0.5}
            max={2.5}
            step={0.01}
            onStep={() => {} /* onStep */}
            onChange={handleLineHeightChange}
          />
        </Col>
      </Row>
      <CommonControls activeObjectProps={activeObjectProps} />
    </div>
  );
};
