import { CSSProperties } from 'react';

export const advancedFilterCardStyle: CSSProperties = {
  width: '100%',
  boxShadow: '0px 0px 10px #0000001F',
  opacity: '1',
  height: '500px',
};

export const advancedFilterSelectAllCheckboxStyle: CSSProperties = {
  textAlign: 'left',
  paddingBottom: '20px',
  font: 'normal normal 600 16px/30px Nunito Sans',
  letterSpacing: '0px',
  color: '#66676B',
  opacity: 1,
};

export const advancedFilterCheckboxStyle: CSSProperties = {
  textAlign: 'left',
  font: 'normal normal 16px/30px Nunito Sans',
  letterSpacing: '0px',
  color: '#034EA2',
  opacity: 1,
};

export const advancedFilterSelectedCountStyle: CSSProperties = {
  paddingLeft: '20px',
  color: '#66676B',
};

export const advancedFilterTipStyle: CSSProperties = {
  paddingTop: '114px',
  textAlign: 'center',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '30px',
  fontFamily: 'Nunito Sans',
  letterSpacing: '0px',
  color: '#66676B',
  opacity: 1,
};

export const advancedFilterSearchPrefixStyle: CSSProperties = {
  fontSize: 16,
  color: '#1677ff',
};

export const advancedFilterSearchSuffixStyle: CSSProperties = {
  color: '#66676B',
  fontSize: '10px',
};

export const advancedFilterSelectAllStyle: CSSProperties = {
  textAlign: 'left',
  paddingBottom: '10px',
  font: 'normal normal 600 16px/30px Nunito Sans',
  letterSpacing: '0px',
  color: '#66676B',
  opacity: 1,
};

export const advancedFilterFolderIconStyle: CSSProperties = {
  color: '#83CCFB',
  fontSize: '20px',
  padding: '5px',
};

export const advancedFilterFolderTextStyle: CSSProperties = {
  paddingBottom: '10px',
  fontWeight: '800',
};

export const advancedFilterSharepointTextStyle: CSSProperties = {
  fontSize: '10px',
  fontStyle: 'italic',
  color: '#555',
  paddingLeft: '30px',
  fontWeight: '800',
};

export const advancedFilterFileLinkStyle: CSSProperties = {
  color: '#66676B',
};

export const advancedFilterFileIconStyle: CSSProperties = {
  marginRight: '3px',
  width: '16px',
  height: '18px',
};

export const advancedFilterButtonLinkStyle: CSSProperties = {
  width: '100%',
};

export const advancedFilterCancelButtonStyle: CSSProperties = {
  border: '1px solid #204D9C',
  borderRadius: '3px',
  opacity: '1',
  color: '#034EA2',
};

export const advancedFilterApplyButtonStyle: CSSProperties = {
  backgroundColor: '#034EA2',
  color: 'white',
  borderRadius: '3px',
  opacity: '1',
};

export const advancedFilterSelectedFolderStyle: CSSProperties={
    textAlign: "left",
    font: "normal normal 14px/30px Nunito Sans",
    letterSpacing: "0px",
    color: "#66676B",
    opacity: 1,
    paddingBottom: "15px",
  }


