import { FooterButtonsConfigTypes } from "../../Pages/Content Transcreation/Core/modals";
import { NameValueTypes } from "../../Utils/_gTypes";
import {
  SET_ACTIVE_STEPPER,
  SET_BUTTON_TEXT_STATE,
  SET_CTL_ACTIVE_OBJECT_MODIFIER,
  SET_CTL_ACTIVE_SLIDE,
  SET_CTL_CANVAS_ACTIVE_OBJECT,
  SET_CTL_SAVE_ELAI_OBJECT,
  SET_CTL_SIDEBAR_OPEN,
  SET_CTL_SLIDE_PLAYING,
  SET_CT_FINAL_PRODUCT,
  SET_CT_SOURCE,
  SET_ELAI_RESPONSE_DATA,
  SET_FILTER_APPLIED_STATUS,
  SET_FOOTER_BUTTONS_CONFIG,
  SET_NEXT_BUTTON_DISABLE,
  SET_NEXT_STEPPER_STATE,
  SET_PHONEME_LIST,
  SET_PLATFORM_LIST,
  SET_PREVIOUS_STEPPER_STATE,
  SET_REFETCH_CONTENT_HISTORY_LIST,
  SET_REFETCH_LIST,
  SET_REGENERATE_STATE,
  SET_RESPONSE_CT,
  SET_SELECTED_EMAIL_DOC,
  SET_SELECTED_FILTERS,
  SET_SHOW_MORE_LIST
} from "../constants/_contentTranscreationConstants";

export const setActiveStepperStateAction = (step: number) => {
  return { type: SET_ACTIVE_STEPPER, payload: step };
};

export const setNextStepperStateAction = (state: boolean) => {
  return { type: SET_NEXT_STEPPER_STATE, payload: state };
};

export const setPreviousStepperStateAction = (state: boolean) => {
  return { type: SET_PREVIOUS_STEPPER_STATE, payload: state };
};

export const setButtonTextStateAction = (state: string) => {
  return { type: SET_BUTTON_TEXT_STATE, payload: state };
};

export const setNextButtonDisableAction = (state: boolean) => {
  return { type: SET_NEXT_BUTTON_DISABLE, payload: state };
};

export const setResponseCtAction = (state: any) => {
  return { type: SET_RESPONSE_CT, payload: state };
};

export const setRefetchContentHistoryListAction = (state: any) => {
  return { type: SET_REFETCH_CONTENT_HISTORY_LIST, payload: state };
};

export const setCTSourceAction = (state: string) => {
  return { type: SET_CT_SOURCE, payload: state };
};

export const setCTFinalProductAction = (state: string) => {
  return { type: SET_CT_FINAL_PRODUCT, payload: state };
};

export const setFooterButtonsConfigAction = (state: FooterButtonsConfigTypes[]) => {
  return { type: SET_FOOTER_BUTTONS_CONFIG, payload: state };
};

export const setRegenerateStateAction = (state: boolean) => {
  return { type: SET_REGENERATE_STATE, payload: state };
};

export const setRefetchListStateAction = (state: boolean) => {
  return { type: SET_REFETCH_LIST, payload: state };
};

export const setPlatformListStateAction = (state: NameValueTypes[]) => {
  return { type: SET_PLATFORM_LIST, payload: state };
};

//content history
export const setSelectedFilterAction = (state: NameValueTypes[]) => {
  return { type: SET_SELECTED_FILTERS, payload: state };
};
export const setShowMoreListAction = (state: boolean) => {
  return { type: SET_SHOW_MORE_LIST, payload: state };
};
export const setSelectedEmailDocAction = (state: any) => {
  return { type: SET_SELECTED_EMAIL_DOC, payload: state };
};
export const setFilterAppliedStatusAction = (state: boolean) => {
  return { type: SET_FILTER_APPLIED_STATUS, payload: state };
};

//content Translation
export const setElaiTranslationDataAction = (state: any) => {
  return { type: SET_ELAI_RESPONSE_DATA, payload: state };
};
export const setCTLActiveSlideAction = (state: any) => {
  return { type: SET_CTL_ACTIVE_SLIDE, payload: state };
};
export const setPhonemeListAction = (state: any) => {
  return { type: SET_PHONEME_LIST, payload: state };
};
export const setCTLSideBarOpenAction = (state: boolean) => {
  return { type: SET_CTL_SIDEBAR_OPEN, payload: state };
};
export const setActiveObjectModifier = (state: any) => {
  return { type: SET_CTL_ACTIVE_OBJECT_MODIFIER, payload: state };
};
export const setCanvasActiveObject = (state: any) => {
  return { type: SET_CTL_CANVAS_ACTIVE_OBJECT, payload: state };
};
export const setSaveElaiObject = (state: any) => {
  return { type: SET_CTL_SAVE_ELAI_OBJECT, payload: state };
};
export const setSlidePlaying = (state: boolean) => {
  return { type: SET_CTL_SLIDE_PLAYING, payload: state };
};
