import { Col, Row } from "antd";
import { CircularAvatarTile } from "../Components";
import { TabData } from "../Core/types";
import { useTranslation } from "react-i18next";

const FuturePotentialTab: React.FC<{ data: TabData }> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[10, 10]}>
      {data?.content?.map((tile, key) => (
        <Col key={key} xs={{ span: 24 }} md={{ span: 8 }}>
          <CircularAvatarTile image={tile.imgSrc} heading={t(tile.point)} description={tile.description as string} />
        </Col>
      ))}
    </Row>
  );
};

export { FuturePotentialTab };
