import { useEffect, useState } from "react";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const ProgressBar = ({ uploading, error }: { uploading: boolean; error: boolean }) => {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0); //30s as the base
  const [uploadStarted, setUploadStarted] = useState(false);

  useEffect(() => {
    if (!uploading && !uploadStarted) return;
    if (uploading) {
      setUploadStarted(true);
    }
    //If uploading status has changed from true to false, i.e. marked to completion or failed
    else if (!uploading && uploadStarted) {
      setUploadStarted(false);
      setProgress(30); //100%
    }
    let timerId = setInterval(
      () =>
        setProgress((p) => {
          //Increment in 0.5 till 25 then in 0.1 to account for the upload delay
          return p + 0.5 <= 25 ? p + 0.5 : p + 0.1;
        }),
      500
    );

    if (!uploading) clearInterval(timerId);

    return () => {
      //console.log("Unmounting ProgressBar");
      clearInterval(timerId);
    };
  }, [uploading]);

  return (
    <>
      {/* {error && <div className="cu-error-message">Error: Upload Failed!</div>} */}
      {error && <div className="cu-error-message">{t(I18N_MODULE_KEYS["content.text.erroruploadfailed"])}</div>}
      {!error && (
        <>
          <div className="upload-progress-bar-area">
            <div className={"upload-progress-bar " + (uploading ? "shimmer" : "")} style={{ width: (progress / 30) * 100 + "%" }}></div>
          </div>
          <div className="upload-percentage">{((progress / 30) * 100).toFixed(1)}%</div>
        </>
      )}
    </>
  );
};
