import { FC, useState } from "react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";

import { useDispatch, useSelector } from "react-redux";
import { CONTENT_CREATOR_STEPS, SELECTED_TAGS_INITIAL_STATE } from "../../Core/constants";
import {
  setCurrentActiveStepActions,
  setPromptIDAction,
  setResponseLoaderFlagAction,
  setSelectedTagsAction
} from "../../../../Store/actions/_contentAIActions";
import { createEnrichPromptRequest } from "../../../../Store/requests/_contentAIRequests";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

type IProps = {
  getResult: (prompt_id: number, isEnriched: true) => void;
};

type StatusTypes = {
  interested: boolean;
  not_interested: boolean;
};

const initialStatus: StatusTypes = {
  interested: false,
  not_interested: false
};

export const RefinedResponseScreen: FC<IProps> = ({ getResult }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);
  const {
    promptID,
    refinedResponseData: { prompt, content, rules }
  } = useSelector((state: any) => state?.contentAIReducer);

  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const [status, setStatus] = useState<StatusTypes>({ ...initialStatus });

  const handleStatus = (key: string, value: boolean | null, otherKey: string) => {
    setStatus((p: StatusTypes) => ({
      ...p,
      [key]: value,
      [otherKey]: false
    }));
  };

  const handleBackButtonClick = () => {
    // const resetContentAIReduxData = () => {
    //   dispatch(setPromptIDAction(0)); //Reset promptID
    //   dispatch(setResponseDataAction({ prompt: "", content: "", rules: [] }));
    // };
    // resetContentAIReduxData();
    dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.GENERATE_RESPONSE));
    dispatch(setSelectedTagsAction(SELECTED_TAGS_INITIAL_STATE));
    dispatch(setPromptIDAction(0));
  };

  const regenerateButtonHandler = async () => {
    dispatch(setResponseLoaderFlagAction(true));

    const res = await createEnrichPromptRequest(promptID, "content_universe", undefined, true);

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      return;
    }

    getResult(res?.id, true);
  };

  return (
    /*  <div className="custom-row refined-response-block"> */
    <div className="col-12 textBlock">
      <div className="row">
        <div className="col-6">
          {/* <div className="title">Triggered Prompt - Refined Response</div> */}
          <div className="title">{`${t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.triggeredprompt"])} - ${t(
            I18N_MODULE_KEYS["content.contentai.headings.contentai.text.refinedresponse"]
          )}`}</div>
        </div>
        <div className="col-6 socialLinks">
          <span>
            <img
              src={status.interested === true ? PROJECT_ICONS.THUMBS_UP_SOLID : PROJECT_ICONS.THUMBS_UP}
              width="20"
              className="mr-2"
              onClick={() => handleStatus("interested", !status.interested, "not_interested")}
            />
            {/* Interested */}
            {t(I18N_MODULE_KEYS["content.text.interested"])}
          </span>
          <span>
            <img
              src={status.not_interested === true ? PROJECT_ICONS.THUMBS_UP_SOLID : PROJECT_ICONS.THUMBS_UP}
              onClick={() => handleStatus("not_interested", !status.not_interested, "interested")}
              style={{ transform: "rotate(180deg)" }}
              width="20"
              className="mr-2"
            />
            {/* Not Interested */}
            {t(I18N_MODULE_KEYS["content.text.notinterested"])}
          </span>
          <span>
            <img src={PROJECT_ICONS.C_AI_SHARE} width="20" className="mr-2" />
            {/* Share */}
            {t(I18N_MODULE_KEYS["content.text.share"])}
          </span>
        </div>
      </div>

      <div className="custom-row">
        {!fullscreen && (
          <div className="col-4 refined-response-left-block">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 15px",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0px",
                width: "100%",

                flex: "0 0 auto"
              }}
            >
              {/* <div className="title">Revised Prompt</div> */}
              <div className="title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.revisedprompt"])}</div>
              <div className="button-group">
                <a className="edit" onClick={handleBackButtonClick}>
                  {/* Back */}
                  {t(I18N_MODULE_KEYS["content.button.back"])}
                </a>
                <a className="button" onClick={regenerateButtonHandler}>
                  {/* Regenerate */}
                  {t(I18N_MODULE_KEYS["content.button.regenerate"])}
                </a>
              </div>
            </div>
            <p className="col-12 user-prompt">{prompt + " ?"}</p>
            <hr style={{ borderColor: "#97C4D9", flex: "0 0 auto" }} />
            {/* <p className="col-12 applied-rules-title">Applied Rules:</p> */}
            <p className="col-12 applied-rules-title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.appliedrules"])}</p>

            <div className="col-12 refined-response-rules">
              <ol>
                {rules && rules?.length ? (
                  rules?.map((item: string, index: number) => {
                    return <li key={`rules_${index}`}>{item}</li>;
                  })
                ) : (
                  <p>
                    {/* No Rules Found! */}

                    {t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.norulefound"])}
                  </p>
                )}
              </ol>
            </div>
          </div>
        )}

        <div className={!fullscreen ? "col-8" : "col-12"}>
          <div className="refined-response-right-block">
            <div className="row">
              <div className="col-6">
                {/* <div className="title">Refined Response</div> */}
                <div className="title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.refinedresponse"])}</div>
              </div>
              {/* <div className="col-6 text-right">
                {!fullscreen ? (
                  <span className={!fullscreen ? "maximise" : "open"}>
                    <img src={PROJECT_ICONS.C_AI_MAXIMIZE} width="20" onClick={() => setFullscreen(true)} />
                  </span>
                ) : (
                  <span className="minimise">
                    <img src={PROJECT_ICONS.C_AI_MINIMIZE} width="20" onClick={() => setFullscreen(false)} />
                  </span>
                )}
              </div> */}
            </div>
            <br />
            <div className="refined-response-html" dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
