import Icon from "./Icon";

export const distributeMenuItems = [
  {
    key: "distribute_horizontal",
    label: "Distribute horizontal spacing",
    icon: <Icon name="distribute_horizontal" />
  },
  {
    key: "distribute_vertical",
    label: "Distribute vertical spacing",
    icon: <Icon name="distribute_vertical" />
  }
];

export const STYLE = {
  margin0: { margin: 0 },
  marginTop10: { marginTop: "10px" },
  marginBottom0: { marginBottom: 0 },
  marginTop20: { marginTop: "20px" },
  paddingRight2: { paddingRight: "2px" },
  spaceBetween: { display: "flex", justifyContent: "space-between" }
};
