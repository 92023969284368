import { enumTMActiveScreen, enumTMMainTabs, initialTextTranslationData, initialUploadDocsData } from "../../Pages/Translation Module/Core/constant";
import { GlossaryTypes, LanguageListTypes, TextTranslationTypes, UploadDocsTypes } from "../../Pages/Translation Module/Core/modals";
import {
  TM_SET_ACTIVE_SCREEN,
  TM_SET_FILE_TRANSLATION_API_RESPONSE,
  TM_SET_FILE_TRANSLATION_RESET_DATA,
  TM_SET_FT_SELECTED_FILE,
  TM_SET_FT_SELECTED_LANGUAGE,
  TM_SET_FT_TRANSLATE_FILE_FLAG,
  TM_SET_GLOSSARY_LIST,
  TM_SET_LANGUAGE_LIST,
  TM_SET_MAIN_TABS,
  TM_SET_OPEN_ADD_GLOSSARY_MODAL,
  TM_SET_TEXT_TRANSLATION_DATA,
  TM_SET_UPLOAD_DOCS_DATA
} from "../constants/_translationModuleConstants";

type IntialState = {
  mainTabs: number;
  activeScreen: number;
  openAddGlossaryModal: boolean;
  languageList: LanguageListTypes[];
  uploadDocsData: UploadDocsTypes;
  textTranslationData: TextTranslationTypes;
  glossary: GlossaryTypes[];
};

const initialState: IntialState = {
  mainTabs: enumTMMainTabs.TEXT_TRANSLATION,
  activeScreen: enumTMActiveScreen.NOTHING,
  openAddGlossaryModal: false,
  languageList: [],
  uploadDocsData: { ...initialUploadDocsData },
  textTranslationData: { ...initialTextTranslationData },
  glossary: []
};

export default function translationModuleReducer(state: IntialState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case TM_SET_MAIN_TABS: {
      return {
        ...state,
        mainTabs: payload
      };
    }
    case TM_SET_ACTIVE_SCREEN: {
      return {
        ...state,
        activeScreen: payload
      };
    }
    case TM_SET_OPEN_ADD_GLOSSARY_MODAL: {
      return {
        ...state,
        openAddGlossaryModal: payload
      };
    }
    case TM_SET_LANGUAGE_LIST: {
      return {
        ...state,
        languageList: payload
      };
    }
    case TM_SET_UPLOAD_DOCS_DATA: {
      return {
        ...state,
        uploadDocsData: payload
      };
    }
    case TM_SET_TEXT_TRANSLATION_DATA: {
      return {
        ...state,
        textTranslationData: payload
      };
    }
    case TM_SET_FILE_TRANSLATION_API_RESPONSE: {
      return {
        ...state,
        uploadDocsData: {
          ...state.uploadDocsData,
          translationAPIResponse: payload
        }
      };
    }
    case TM_SET_FT_SELECTED_FILE: {
      return {
        ...state,
        uploadDocsData: {
          ...state.uploadDocsData,
          selectedFile: payload
        }
      };
    }
    case TM_SET_FT_SELECTED_LANGUAGE: {
      return {
        ...state,
        uploadDocsData: {
          ...state.uploadDocsData,
          selectedLanguage: payload
        }
      };
    }
    case TM_SET_FT_TRANSLATE_FILE_FLAG: {
      return {
        ...state,
        uploadDocsData: {
          ...state.uploadDocsData,
          translateFileFlag: payload
        }
      };
    }
    case TM_SET_FILE_TRANSLATION_RESET_DATA: {
      return {
        ...state,
        uploadDocsData: { ...initialUploadDocsData }
      };
    }
    case TM_SET_GLOSSARY_LIST: {
      return {
        ...state,
        glossary: payload
      };
    }
    default: {
      return state;
    }
  }
}
