import { Button, Modal, Tooltip } from "antd";
import ReactDiffViewer from "react-diff-viewer-continued";
import "./CompareStoryBoard.css";

export const CompareStoryBoard = ({
  show,
  setShow,
  firstString,
  secondString
}: {
  show: boolean;
  setShow: Function;
  firstString: string;
  secondString: string;
}) => {
  return (
    <Modal
      className="compare-storyboard-modal"
      centered
      width={"1000px"}
      open={show}
      title="Compare Storyboard"
      cancelText="Close"
      onCancel={() => setShow(false)}
      footer={(_, { OkBtn, CancelBtn }) => [
        <CancelBtn />,
        <Tooltip title="Apply the Compared Storyboard">
          <Button
            type="primary"
            onClick={() => {
              setShow(false);
            }}
          >
            Apply
          </Button>
        </Tooltip>
      ]}
    >
      <ReactDiffViewer oldValue={firstString} newValue={secondString} splitView={true} />
    </Modal>
  );
};
