import { DrawerActionTypes } from "./_constant";
import { DrawerState } from "./_types";

const initialDrawerState: DrawerState = {
  drawerTitle: "Title Here",
  drawerDescription: "Description Here",
  drawerWidth: "600px",
  drawerPermanent: true,
  drawerOpen: false,
  backButton: false,
  backAction: () => {},
  componentId: null,
  afterClose: () => {},
  others: {},
  headerOptions: []
};

const actionDrawerReducer = (state = initialDrawerState, action: any): DrawerState => {
  const { type, payload } = action;
  switch (type) {
    case DrawerActionTypes.SET_DRAWER_DATA:
      return {
        ...state,
        drawerTitle: payload.drawerTitle,
        drawerDescription: payload.drawerDescription,
        drawerWidth: payload.drawerWidth,
        drawerPermanent: payload.drawerPermanent,
        drawerOpen: payload.drawerOpen,
        backButton: payload.backButton,
        componentId: payload.componentId,
        others: payload?.others ? payload?.others : {}
      };
    case DrawerActionTypes.SET_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: payload
      };
    case DrawerActionTypes.SET_DRAWER_COMPONENT:
      return {
        ...state,
        componentId: payload
      };
    case DrawerActionTypes.SET_DRAWER_HEADER_OPTIONS:
      return {
        ...state,
        headerOptions: payload
      };
    case DrawerActionTypes.ON_DRAWER_CLOSE:
      return {
        ...state,
        afterClose: payload
      };
    case DrawerActionTypes.ON_DRAWER_BACK_ACTION:
      return {
        ...state,
        backAction: payload
      };
    default:
      return state;
  }
};
export default actionDrawerReducer;
