const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}

const promisify = (f) => {
  return function (...args) {
    return new Promise((resolve, reject) => {
      function callback(err, result) {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      }
      args.push(callback)
      f.call(this, ...args)
    })
  }
}

const uniqueId = () => Math.floor(Math.random() * Date.now())

const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

const debounce = (func, delay, withCounter = false) => {
  let timeoutId
  let counter = 0
  const debounced = (...args) => {
    clearTimeout(timeoutId)
    counter++
    timeoutId = setTimeout(() => {
      if (withCounter) args.push(counter)
      func.apply(this, args)
      counter = 0
    }, delay)
  }
  debounced.cancel = () => {
    clearTimeout(timeoutId)
  }
  return debounced
}

const queueMicrotask = window.queueMicrotask || ((fn) => Promise.resolve().then(fn))

const requestIdleCallback = window.requestIdleCallback || ((fn) => setTimeout(fn, 0))

const escapeRegExp = (string) => string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

const clone = (obj) => {
  try {
    // by default try to use structuredClone,
    // but it can throw error if object contains references to DOM elements
    if (window.structuredClone) {
      return window.structuredClone(obj)
    }
  } catch {
    // ignore
  }
  // otherwise use JSON representation
  return JSON.parse(JSON.stringify(obj))
}

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const getRandomNumber = (max) => Math.floor(Math.random() * max)

const backoffMs = (attempt) => (attempt + 1) * 2000

const isSignedUrl = (url) => /x-amz-|cloudfront\.net|blob\.core\.windows\.net/i.test(url)

export {
  timeSince,
  promisify,
  uniqueId,
  capitalizeFirstLetter,
  debounce,
  queueMicrotask,
  requestIdleCallback,
  escapeRegExp,
  clone,
  sleep,
  getRandomNumber,
  backoffMs,
  isSignedUrl,
}
