import React, { useState, useEffect, useRef } from "react";
import { Layout, Image, Row, Col, Input, Modal } from "antd";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import { useSelector, useDispatch } from "react-redux";
import {
  setEditableScreen,
  setLoadingforReportGeneration,
  setLoadingforSwitching,
  setPdfForReports,
  setReportGenerationType,
  setSlidesForReports,
} from "../../../../../Store/actions/_semanticSearchActions";
import _localStorageService from "../../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { generatePdf } from "../../../../../Store/requests/_semanticSearchRequests";

export const ModalForSwitchingFromPPTToPDF: React.FC<any> = ({}) => {
  const { loadingForReportGeneration, conversationId, loadingForSwitching } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
//   const [retryEnable, setRetryEnable] = useState(false);
  const token = _localStorageService.getAccessToken();
  const accessToken = token || "";

  useEffect(() => {
    const generateReport = async () => {
      const res = await generatePdf({ conversation_id: conversationId, regenerate: false });
      if (!res) {
        dispatch(setNotificationMsgAction("Please try again for regenerating the PDF."));
        dispatch(setLoadingforSwitching(false));
        // setRetryEnable(true);
        return;
      }
      dispatch(setReportGenerationType("PDF"));
      dispatch(setPdfForReports(res));
      dispatch(setEditableScreen(true));
      dispatch(setLoadingforSwitching(false));
    };

    generateReport();
  }, []);
  const handleCancel = async () => {
    dispatch(setLoadingforSwitching(false));
  };

//   const handleRetry = async () => {
//     // setRetryEnable(false);
//     const res = await generatePdf({ conversation_id: conversationId, regenerate: false });
//     if (!res) {
//       dispatch(setNotificationMsgAction("Please try again for generating the PDF."));
//       dispatch(setPdfForReports({}));
//       dispatch(setEditableScreen(true));
//       dispatch(setLoadingforSwitching(true));
//       setRetryEnable(true);
//       return;
//     }
//     dispatch(setReportGenerationType("PDF"));

//     dispatch(setPdfForReports(res));
//     dispatch(setEditableScreen(true));
//     dispatch(setLoadingforSwitching(false));
//   };
  return (
    <>
      <Modal
        visible={loadingForSwitching}
        footer={null}
        closable={false}
        centered
        onCancel={handleCancel}
        maskStyle={{
          backdropFilter: "blur(30px)",
          background: "rgba(44, 78, 101, 0.5)",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "40px" }}>
          <Lottie animationData={PROJECT_ICONS.COFFEE} style={{ height: "140px", width: "100px" }} />
          <span style={{ fontSize: "16px", fontWeight: "500", color: "#515151", textAlign: "center" }}>Hey, John Doe, your PDF is generating.</span>
          <span style={{ fontSize: "16px", fontWeight: "500", color: "#515151", textAlign: "center" }}>Please wait and grab a cup of coffee.</span>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <button
            style={{
              background: "#1E4DA1",
              border: "1px solid #1E4DA1",
              color: "white",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "4px",
              padding: "6px 12px",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            Cancel
          </button>
         
        </div>
      </Modal>
    </>
  );
};
