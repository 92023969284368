import { Modal } from "antd";
import { FC } from "react";

export const DeleteModal: FC<{ title: string; isModalOpen: boolean; handleOk: () => void; handleCancel: () => void }> = ({
  title,
  isModalOpen,
  handleOk,
  handleCancel
}) => {
  return <Modal centered title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}></Modal>;
};
