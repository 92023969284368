import { FC } from "react";
import { Col, Layout, Row, Space, Typography } from "antd";
import DHeader from "./Spaces/_header";
import SideBar from "./Spaces/_sidebar";
import Searchbox from "../Components/Navbar/_Searchbox";
import Usericon from "../Components/Navbar/_Usericon";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { settoggleSidebarCollapse } from "../Store/actions/_commonActions";
import { ENUM_ROUTE_URLS } from "../Routes/_routesConfig";
import { MenuI18N } from "../Components/i18n";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../Components/i18n/core/constants";

type IProps = {
  children?: any;
};

const { Paragraph } = Typography;

export const AdminLayout: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  const { isCollapsed } = useSelector((state: any) => state?.commonReducer);
  const location = useLocation();

  const toggleSidebar = () => {
    dispatch(settoggleSidebarCollapse(!isCollapsed));
  };

  // Define an index signature for the route titles
  const routeTitles: Record<string, string> = {
    // Add more routes and titles as needed
    [ENUM_ROUTE_URLS.DASHBOARD]: t(I18N_MODULE_KEYS["module.dashboard"]),
    [ENUM_ROUTE_URLS.CONTENT_TRANSCREATION]: t(I18N_MODULE_KEYS["module.transcreation"]),
    [ENUM_ROUTE_URLS.CONTENT_HISTORY]: t(I18N_MODULE_KEYS["module.history"]),
    [ENUM_ROUTE_URLS.IDETAIL_EXTRACT]: t(I18N_MODULE_KEYS["module.idetailextract"]),
    [ENUM_ROUTE_URLS.CONTENT_TRANSLATION]: t(I18N_MODULE_KEYS["module.translation"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_TRANSCREATION]: t(I18N_MODULE_KEYS["module.transcreation"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SEMANTIC_SEARCH]: t(I18N_MODULE_KEYS["module.semantic"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.INTELLIGENT_ASSISTANT]: "Intelligent Assistant",
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_AI]: t(I18N_MODULE_KEYS["module.contentcreation"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT]: t(I18N_MODULE_KEYS["module.fieldcopilot"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.HYPER_PERSONALISATION]: t(I18N_MODULE_KEYS["content.dashboard.card.hyper.title"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SENTIMENT_ANALYSIS]: t(I18N_MODULE_KEYS["content.dashboard.card.sentimentanalysis.title"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.KOL_VIDEO]: t(I18N_MODULE_KEYS["content.dashboard.card.kolvideo.title"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.GEN_AI_DASHBOARD_ASSISTANT]: t(I18N_MODULE_KEYS["content.dashboard.card.generativeai.title"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.PATIENT_JOURNEY_MAPPING]: t(I18N_MODULE_KEYS["content.dashboard.card.patientjourney.title"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.TRANSLATION_LOCALISATION]: t(
      I18N_MODULE_KEYS["content.dashboard.card.translationlocalisation.title"]
    ),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.PERSONALIZED_VIDEO]: t(I18N_MODULE_KEYS["content.dashboard.card.personalizedvideo.title"]),
    [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_CREATION]: t(I18N_MODULE_KEYS["content.dashboard.card.contentcreation.title"])
  };

  // const routeTitles: Record<string, string> = {
  //   // Add more routes and titles as needed
  //   [ENUM_ROUTE_URLS.DASHBOARD]: "Dashboard",
  //   [ENUM_ROUTE_URLS.CONTENT_TRANSCREATION]: "Content Transcreation",
  //   [ENUM_ROUTE_URLS.CONTENT_HISTORY]: "Content History",
  //   [ENUM_ROUTE_URLS.CONTENT_TRANSLATION]: "Content Translation",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_TRANSCREATION]: "Content Transcreation",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SEMANTIC_SEARCH]: "Semantic Search",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.INTELLIGENT_ASSISTANT]: "Intelligent Assistant",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_AI]: "Content Creation",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT]: "Field Copilot",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.HYPER_PERSONALISATION]: "Hyper Personalisation",
  //   [ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SENTIMENT_ANALYSIS]: "Sentiment Analysis"
  // };

  // Get the current title based on the location pathname
  const currentTitle = routeTitles[location?.pathname] || "Default Title";

  return (
    <>
      <SideBar />
      <Layout>
        <DHeader
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            background: "#0546a5",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white"
          }}
        >
          {/* <DTheme /> */}
          <Row>
            <Col xs={{ span: 1 }} className="d-flex align-items-center">
              {isCollapsed ? (
                <MenuUnfoldOutlined onClick={toggleSidebar} style={{ fontSize: "24px" }} />
              ) : (
                <MenuFoldOutlined onClick={toggleSidebar} style={{ fontSize: "24px" }} />
              )}
              {/* <span>{currentTitle}</span> */}
            </Col>
            <Col xs={{ span: 11 }} className="d-flex align-items-center">
              <Paragraph
                className="optimize-font"
                type="secondary"
                style={{
                  fontSize: "15px",
                  marginBottom: "0px",
                  font: "normal normal bold 16px/24px Nunito Sans",
                  color: "white"
                }}
              >
                {currentTitle}
              </Paragraph>
            </Col>
            <Col
              // xs={{ span: 24 }}
              xs={{ span: 12 }}
              className="d-flex justify-content-end"
              style={{ alignItems: "center", position: "relative" }}
            >
              <Space>
                <Searchbox />
                <Usericon />
                <MenuI18N />
              </Space>
            </Col>
          </Row>
        </DHeader>
        {children}
      </Layout>
    </>
  );
};
