import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { nextinsightsecondcardindividualstyle, nextinsightsecondcardindividualstyledisable, nextinsightsecondextracardstyle } from "../StylesFolderForNextInsight/_NextInsightPageStyleConstant";
import { NextInsightDocs } from "./_NextInsightDocs";
import { NextInsightImages } from "./_NextInsightImages";
import { NextInsightAudios } from "./_NextInsightAudios";
import { NextInsightVideos } from "./_NextInsightVideos";

interface NextInsightPageProps {
  showComponentForChunks: (type: string, url: string) => void;
}

export const NextInsightSimilaritySearch: FC<NextInsightPageProps> = ({ showComponentForChunks }) => {

  return (
    <>
      <NextInsightDocs showComponentForChunks={showComponentForChunks}/>
      <NextInsightImages showComponentForChunks={showComponentForChunks}/>
      <NextInsightAudios showComponentForChunks={showComponentForChunks} />
      <NextInsightVideos showComponentForChunks={showComponentForChunks}/>
    </>
  );
};
