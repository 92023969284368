import { RcFile } from "antd/es/upload";
import { siteConfig } from "../../Services";
import { handleDeleteDataFromApi, handleGetDataFromApi, handlePatchDataFromApi, handlePostDataFromApi, handlePutDataFromApi } from "../../Utils";
import { apiResponse } from "../../Utils/_gTypes";
import { SSE } from "sse.js";

const getUserID = () => "11";

// export const uploadEmailDocRequest = async (body: FormData, regenerate: string, isLoading?: any) => {
export const uploadEmailDocRequest = async (body: FormData, regenerate: boolean, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.UPLOAD_EMAIL_DOC + `/${getUserID()}?regenerate=${regenerate}`,
    body,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const uploadEmailRegenerate = async (regenerate: boolean, email_doc_id: number | undefined, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    "/email/regenerate",
    // `/${getUserID()}?regenerate=${regenerate}${email_doc_id ? `&email_doc_id=${email_doc_id}` : ""}`,
    { regenerate: regenerate, email_doc_id: email_doc_id },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const uploadEmailDashboardRequest = async (file_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.UPLOAD_EMAIL_DASHBOARD + `/${file_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const createHtmlRequest = async (body: { email_doc_id: number; regenerate: boolean }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.CREATE_HTML, { ...body, user_id: getUserID() }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const createHtmlDashboardRequest = async (body: { prompt: string; id: number }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.CREATE_HTML_DASHBOARD,
    { ...body, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const createStoryBoardRequest = async (body: { email_doc_id: number; regenerate?: boolean }, isLoading?: any) => {
  const { email_doc_id, regenerate = false } = body; // Default value for regenerate is false

  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.CREATE_STORYBOARD,
    { email_doc_id, regenerate, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const regnerateCustomImages = async (body: { storyboard_id: number; story_id: number; regenerate?: boolean }, isLoading?: any) => {
  const { storyboard_id, story_id, regenerate = true } = body;

  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.GET_IMAGES, // Replace with your actual API endpoint
    { storyboard_id, story_id, regenerate, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const storyBoardDashboardRequest = async (storyboard_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.STORYBOARD_DASHBOARD + `/${storyboard_id}`, isLoading ? isLoading : undefined);
  // const res: apiResponse = await handlePostDataFromApi(
  //   siteConfig.STORYBOARD_DASHBOARD,
  //   { ...body, user_id: getUserID() },
  //   isLoading ? isLoading : undefined
  // );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const storyBoardReorderRequest = async (body: { storyboard_id: number; stories: [] }, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.STORYBOARD_DASHBOARD,
    { ...body, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getImagesRequest = async (
  body: { storyboard_id: number; stories: number[]; regenerate: boolean; platform_id: number },
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.GET_IMAGES, { ...body, user_id: getUserID() }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getImagesDashboardRequest = async (storyboard_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_IMAGES_DASHBOARD + `/${storyboard_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const createVideoRequest = async (body: { storyboard_id: number; template_id?: number; avatar_id?: 5 }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.CREATE_VIDEO, { ...body, user_id: getUserID() }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getVideoRequest = async (video_dashboard_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_VIDEO + `/${video_dashboard_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const editStoryBoardRequest = async (body: { storyboard_id: number; stories: any[]; is_translate?: boolean }, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.EDIT_STORYBOARD,
    { ...body, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const editEmailDocumentRequest = async (
  body: { email_doc_id: number; body?: string; subject?: string; filename?: string },
  isLoading?: any
) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.EDIT_DOCUMENT, { ...body, user_id: getUserID() }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};
export const replaceGeneratedImageRequest = async (body: { story_id: number; storyboard_id: number; file: any }, isLoading?: any) => {
  // /image-upload?user_id=11&story_id=1&storyboard_id=7
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.IMAGE_UPLOAD + `?user_id=${getUserID()}&story_id=${body.story_id}&storyboard_id=${body.storyboard_id}`,
    body?.file,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getHistoryStatusRequest = async (body: { search_term: string; filters: string[] }, page: number, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    // siteConfig.GET_HISTORY_STATUS + `?page=${page}`,
    siteConfig.GET_HISTORY_STATUS,
    { ...body, page: page },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const deleteFileRequest = async (email_id: number | null, isLoading?: any) => {
  const res: apiResponse = await handleDeleteDataFromApi(siteConfig.DELETE_FILE + `/${email_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getVideoTemplatesRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_TEMPLATES + "?page=1&page_size=10", isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getAvatarsRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_AVATARS + "?page=1&page_size=50", isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

// export const createBlogUrlRequest = async (body: { blog_url: string; template_id: string; avatar_id: string }, isLoading?: any) => {
export const createBlogUrlRequest = async (body: { blog_url: string }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.CREATE_BLOG_URL, { ...body }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getStoryBoardIdrequest = async (blog_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_STORYBOARD_ID + `?blog_id=${blog_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const updateBlogVideoRequest = async (body: { elai_avatar_id: string; storyboard_content_id: number }, isLoading?: any) => {
  const res: apiResponse = await handlePatchDataFromApi(siteConfig.UPDATE_BLOG_VIDEO, { ...body }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getSummaryRequest = async (body: { storyboard_id: any; video_id?: number; avatar_id: any; template_id: any }, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(
    siteConfig.VIDEO_SUMMARY +
      `${
        body.video_id
          ? `?storyboard_id=${body.storyboard_id}&video_id=${body.video_id as number}`
          : `?storyboard_id=${body.storyboard_id}&avatar_id=${body.avatar_id}&template_id=${body.template_id}`
      }`,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const getLiveImagesForGenerateImagesRequest = async (query: string, page: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(
    siteConfig.GET_LIVE_IMAGES + `?query=${query}&per_page=30&page=${page}`,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const getLiveVideosForGenerateImagesRequest = async (query: string, page: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(
    siteConfig.GET_LIVE_VIDEOS + `?query=${query}&per_page=30&page=${page}`,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const saveLiveImageRequest = async (body: { story_id: number; image_url: string }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.SAVE_LIVE_IMAGE, { ...body }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};
// export const saveLiveVideoRequest = async (body: { story_id: number; thumbnail_url: string; video_url: string }, isLoading?: any) => {
export const saveLiveVideoRequest = async (
  body: { story_id: number; video: { source: string; thumbnail: string; height: number; width: number } },
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.SAVE_LIVE_VIDEO, { ...body }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getPlatformListRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_PLATFORM_LIST, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};
export const getHistoryStatusFilterListRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_HISTORY_FILTER_LIST, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const getListOfLanguagesRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_LIST_OF_LANGUAGES, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const saveTranslateLanguageRequest = async (body: { video_id: number; language_id: number }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.SAVE_TRANSLATE_LANGUAGE,
    { ...body, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getElaiResponseDataRequest = async (video_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_ELAI_RESPONSE_DATE + `/${video_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const saveElaiTranslatedJson = async (body: { video_id: number; storyboard_id: number }, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.GET_VIDEO, { ...body, user_id: getUserID() }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const saveSelectedVoiceRequest = async (body: { video_id: number; voice_id: number; tag: string }, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.SAVE_VOICE, { ...body, user_id: getUserID() }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  return data;
};

export const changeAvatarRequest = async (body: { video_id: number; avatar_id: number }, isLoading?: any) => {
  const { video_id, avatar_id } = body;

  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.CHANGE_AVATAR, // Replace with your actual API endpoint
    { video_id, avatar_id, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};
export const RenderVideo = async (body: { video_id: number; avatar_id: number }, isLoading?: any) => {
  const { video_id, avatar_id } = body;
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.RENDER,
    { video_id, avatar_id, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getElaiDurationRequest = async (body: { text: string; voice?: string }, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.GET_DURATION, { ...body }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getElaiVideoData = async (elaiVideoId: string, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_ELAI_VIDEOS + `/${elaiVideoId}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const updateElaiVideoData = async (body: any, isLoading?: any) => {
  const res: apiResponse = await handlePatchDataFromApi(siteConfig.GET_ELAI_VIDEOS + `/${body._id}`, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const uploadFileToS3 = async (file: FormData, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.GET_FILE_UPLOADS + "/11", file, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getUploadedFiles = async (isLoading?: any) => {
  //api/uploads?page=0&q=cal

  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_FILE_UPLOADS, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const deleteFileFromS3 = async (fileId: number, type: string, isLoading?: any) => {
  const res: apiResponse = await handleDeleteDataFromApi(
    (type === "image" ? siteConfig.IMAGE_FILE_UPLOADS : siteConfig.VIDEO_FILE_UPLOADS) + `/${fileId}`,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const getVoiceListRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_VOICE_LIST, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const fetchAudioRequest = async (body: { text: string; voice_id: number; tag?: string }, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.FETCH_AUDIO, { ...body }, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getStoryBoardConversationID = async (storyboard_id: number, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.STORYBOARD_CONVERSATION,
    { storyboard_id, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const getStoryBoardConversation = async (conversation_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.STORYBOARD_CONVERSATION + `/${conversation_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const sendImprovementCommand = async (conversation_id: number, message: string, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.STORYBOARD_CONVERSATION_CHAT,
    { user_id: getUserID(), conversation_id, message },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const getStoryBoardConversationChat = async (chatID: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.STORYBOARD_CONVERSATION_CHAT + `/${chatID}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const compareChatWithCurrentStoryboard = async (chatID: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(
    siteConfig.STORYBOARD_CONVERSATION_CHAT + `/${chatID}` + siteConfig.STORYBOARD_CHAT_COMPARE,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const applyChatToStoryboard = async (chatID: number, isLoading?: any) => {
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.STORYBOARD_CONVERSATION_CHAT + `/${chatID}` + siteConfig.STORYBOARD_CHAT_APPLY,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }

  return data;
};

export const SSE_CT = (url: string, payload?: string) => {
  var headers: Record<string, string> = {
    Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) as string
  };
  return new SSE(siteConfig.BASE_URL + url, { headers, payload });
};

export const SSE_CT_CHAT = (payload: { conversation_id: number; message: string }) =>
  SSE_CT(siteConfig.STORYBOARD_CONVERSATION_CHAT, JSON.stringify({ user_id: getUserID(), ...payload }));
