import { Input } from "antd";
import { FC } from "react";
import { PhonemetableType } from "../../core/modals";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPhonemeListAction } from "../../../../../Store/actions/_contentTranscreationActions";

export const PhonemeInput: FC<{ record: PhonemetableType; type: string }> = ({ record, type }) => {
  const dispatch = useDispatch();

  const { phonemeList } = useSelector((state: any) => state?.contentTranscreationReducer);

  const handleOnChange = (value: string) => {
    const arrPhonemeList: PhonemetableType[] = [...phonemeList];

    arrPhonemeList?.forEach((item: PhonemetableType) => {
      if (item?.id === record?.id) {
        item[type === "word" ? "word" : "pronunciation"] = value;
      }
    });

    dispatch(setPhonemeListAction([...arrPhonemeList]));
  };

  return (
    <Input
      value={type === "word" ? record?.word : record?.pronunciation}
      onChange={(e) => handleOnChange(e?.target?.value)}
    />
  );
};
