import { AddLiveImagesOrVideos } from "../../Pages/Content Transcreation/Components";
import { AvatarDrawer } from "../../Pages/Content Transcreation/Content Translation/components/sidebar/avatar";
import { ViewSummary } from "../../Pages/Content Transcreation/_ViewSummary";
import { SemanticSearchView } from "../../Pages/Semantic Search/Components";

const componentCache: { [componentId: string]: React.ComponentType<any> } = {
  SemanticSearchView: SemanticSearchView,
  ViewSummary: ViewSummary,
  AddLiveImagesOrVideos: AddLiveImagesOrVideos,
  AvatarDrawer: AvatarDrawer
};

export { componentCache };
