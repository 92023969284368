import { Col, Row, Typography } from "antd";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

export const ArtifactsHead: FC = () => {
  const { i18n, t } = useTranslation();
  const headerstyle={ font: 'normal normal 600 18px/30px Nunito Sans',
  letterSpacing: '0px',
  color: '#231F20', 
  opacity: 1} 
  return (
    <Row gutter={[8, 8]}>
      <Col xs={{ span: 24 }} md={{ span: 6 }}>
        <Paragraph 
          type="secondary"
          className="optimize-font"   
          style={headerstyle}
        >
        Artefacts
        </Paragraph> 
      </Col>
    </Row>
  );
};