import { useLocation } from "react-router";

/**
 * Returns current module based on page location
 * @returns string
 */
export const useCurrentModulePath = () => {
  const location = useLocation();

  return location.pathname.split("/").filter((path) => path)[0];
};
