import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { Row, Col, Input, Button, Tooltip, Switch } from "antd";
import { ArrowUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setEncodedSearchQueryAction,
  setSearchQuery,
  setAdvancedFilter,
  setSemanticSearchId,
  setKvalue,
  setFormat,
  setEncodedSemanticSearchId,
  setActiveCardType,
  setLandingScreen,
  setProModeScreen,
  setProModeStatus,
  setReportGenerationType,
  setLoadingforReportGeneration,
  setEditableScreen,
  setSlidesForReports,
  setPdfForReports,
  setLoadingforSwitching,
} from "../../../../Store/actions/_semanticSearchActions";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { CT_DASHBOARD_STATUS, handleCancelApiRequest, onDownload } from "../../../../Utils";
import { SEMANTIC_SEARCH_CARD, SEMANTIC_SEARCH_MODULES } from ".././Core/constants";
import TextArea from "antd/es/input/TextArea";

interface ComponentAProps {
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  internalVarA: number;
  setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
  internalVarB: string;
  setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
  setStepperForInternalVarb: React.Dispatch<React.SetStateAction<number>>;
  stepperForinternalVarB: number;
  tagsForProMode: string[];
  setTagsForProMode: React.Dispatch<React.SetStateAction<string[]>>;
}

export const FollowupSearch: React.FC<ComponentAProps> = ({
  tagsForProMode,
  setTagsForProMode,
  stepperForinternalVarB,
  setStepperForInternalVarb,
  internalVarA,
  setInternalVarA,
  internalVarB,
  setInternalVarB,
  columns,
  setColumns,
}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const btnRef: any = useRef();
  const timerRef: any = useRef();

  const { searchQuery, semanticEncodedSearchid } = useSelector((state: any) => state?.semanticSearchReducer);

  const { isLoading }: { isLoading: boolean } = useSelector((state: any) => state?.nonPersistedReducer);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [kval, setKval] = useState<any>(4);
  const debouncedChangeHandler = useMemo(() => debounce((val: string) => handleInputChange(val), 350), []);
  const [inputValue, setInputValue] = useState("");
  const [localsearcjid, setlocalsearchid] = useState(semanticEncodedSearchid);
  const [promodeinitial, setPromodeInitial] = useState<boolean>(false); // State for toggle switch

  // Update input value only if search is not in progress
  const handlePromodeChange = (checked: boolean) => {
    setPromodeInitial(checked);
  };
  // Update input value only if search is not in progress

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      // handleCancelApiRequest();
    };
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      console.log(semanticEncodedSearchid + "this is one now  currently looking");
      if (!localsearcjid) {
        dispatch(setEncodedSearchQueryAction(""));
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(searchQuery);
  }, [searchQuery]);

  const handleInputChange = (e: any) => {
    if (!isLoading) {
      setSearchTerm(e.target.value);
      setInputValue(e.target.value);
      if (e.target.value === "") {
        setEncodedSemanticSearchId(0);
        setSemanticSearchId(0);
        setlocalsearchid(0);
      }
    }
  };

  const savePrompt = async (isSave: boolean) => {
    dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
    setInternalVarA(10);
    setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]));
    setStepperForInternalVarb(1);
    console.log(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]) + "this is beimg called as setintervalb");
    dispatch(setFormat(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"])));
    dispatch(setKvalue(kval));
    dispatch(setLoadingforReportGeneration(false));
    dispatch(setEditableScreen(false));
    dispatch(setReportGenerationType(""));
    dispatch(setSlidesForReports({}));
    dispatch(setPdfForReports({}));
    dispatch(setLoadingforSwitching(false));
    if (!isSave) {
      setSearchTerm("");
      dispatch(setLandingScreen(true));

      dispatch(setSearchQuery(""));
      setInputValue("");
      clearTimeout(timerRef.current);
      handleCancelApiRequest();
      dispatch(setLoadingStatusAction(false));
      dispatch(setEncodedSemanticSearchId(0));
      dispatch(setSemanticSearchId(0));
      dispatch(setEncodedSearchQueryAction(""));
      dispatch(setProModeScreen(false));
      dispatch(setProModeStatus(false));
      setTagsForProMode([]);

      return;
    }

    if (!searchTerm) return;
    dispatch(setLandingScreen(false));
    setTagsForProMode([]);

    dispatch(setAdvancedFilter(false));

    dispatch(setProModeScreen(promodeinitial));
    dispatch(setProModeStatus(promodeinitial));
    dispatch(setSearchQuery(searchTerm));

    dispatch(setLoadingStatusAction(true));

    if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };

  return (
    <>
      <div style={{ marginTop: "35px" }}>
        <Col span={15} style={{ paddingLeft: "60px" }}>
          <div style={{ color: "black", background: "#DEE8FA", borderRadius: "50px", boxShadow: "0px 0px 10px #0000001F", padding: "10px" }}>
            <div style={{ background: "white", borderRadius: "50px" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  paddingTop: "2px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingBottom: "2px",
                }}
              >
                <TextArea
                  style={{
                    flex: "1",
                    border: "none",
                    borderRadius: "50px",
                    height: "40px",
                    resize: "none",
                  }}
                  placeholder="Ask Anything..."
                  onChange={handleInputChange}
                  readOnly={isLoading}
                  disabled={isLoading}
                  onKeyDownCapture={(e) => {
                    const { which } = e;

                    if (which === 13) {
                      btnRef?.current?.click();
                      if (!isLoading) {
                      }
                    }
                  }}
                />
                <div style={{ display: "flex", alignItems: "center", padding: "7px" }}>
                  <Switch checked={promodeinitial} onChange={handlePromodeChange} />
                  <Tooltip title="Our premier search tool, tailored for in-depth responses to intricate inquiries - offers a comparable functionality.">
                    <p style={{ paddingLeft: "10px", fontWeight: "600", cursor: "pointer" }}>
                      Pro Mode
                      <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                    </p>
                  </Tooltip>
                </div>
                <Button
                  type="link"
                  onClick={() => savePrompt(searchTerm && searchQuery && isLoading ? false : true)}
                  ref={btnRef}
                  style={{ padding: "7px" }}
                >
                  <span style={{ color: "#66676B", fontSize: "20px" }}>
                    {searchTerm && searchQuery && isLoading ? (
                      t(I18N_MODULE_KEYS["semantic.search.input.heading.clear"])
                    ) : (
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "2px solid #66676B",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowUpOutlined style={{ fontSize: "15px" }} />
                      </div>
                    )}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </>
  );
};
