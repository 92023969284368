import { Row, Col } from "antd";

// import { GroupedAlignment } from './groupedAlignment'
// import LazySlider from '../../../../../components/LazySlider'
// import { LayerControl } from '../../../../../components/LayerControl'
// import ColorEditorButton from '../../../colorsEditor/colorEditorButton'

import { STYLE } from "./constants";
import LazySlider from "./LazySlider";
import { LayerControl } from "./LayerControl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";
import { setActiveObjectModifier } from "../../../../../Store/actions/_contentTranscreationActions";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { textTypes } from "../../fabric-files/utils/canvas";
import { fabric } from "fabric";
// import { useCommonControlsState } from './useCommonControlsState'
// import { SHAPES_TYPES } from '../../../constants'

export const CommonControls = ({ activeObjectProps }: { activeObjectProps: any }) => {
  const { canvasActiveObject } = useSelector((state: RootState) => state.contentTranscreationReducer);
  const dispatch = useDispatch();

  // const { canvasActiveObject, activeObjectProps, activeObjectType, setActiveObjectModifier, isActiveSelection, selectionWithAvatar } = props;

  // const { hasApi, handleOpacityChange, handleImagePosition, handleClipPathChange, onChangeRadiusStep, handleBorderWidth, handleBorderColor } =
  //   useCommonControlsState({
  //     canvasActiveObject,
  //     setActiveObjectModifier
  //   });

  const activeObjectType = useMemo(
    () =>
      canvasActiveObject?.type === "activeSelection"
        ? canvasActiveObject.getObjects().every((obj: any) => obj.type === canvasActiveObject.getObjects()[0].type) ||
          canvasActiveObject.getObjects().every((obj: any) => textTypes.includes(obj.type))
          ? canvasActiveObject.getObjects()[0].type
          : null
        : canvasActiveObject?.type,
    [canvasActiveObject, canvasActiveObject?._objects]
  );

  const handleOpacityChange = (v: any) => {
    dispatch(setActiveObjectModifier({ change: "opacity", value: v }));
  };

  const onChangeRadiusStep = (v: any) => {
    canvasActiveObject.set(
      "clipPath",
      new fabric.Rect({
        width: canvasActiveObject.width,
        height: canvasActiveObject.height,
        rx: v,
        ry: v,
        left: -canvasActiveObject.width / 2,
        top: -canvasActiveObject.height / 2
      })
    );
    canvasActiveObject.canvas.renderAll();
  };

  const handleClipPathChange = (v: any) => dispatch(setActiveObjectModifier({ change: "clipPath", value: v }));

  return (
    <>
      <>
        {/* {SHAPES_TYPES.includes(activeObjectType) && (
            <>
              <Row align="middle" style={STYLE.marginTop10}>
                <Col span={9}>
                  <h4 style={STYLE.marginBottom0}>Border width</h4>
                </Col>
                <Col span={15} style={STYLE.paddingRight2}>
                  <LazySlider
                    value={activeObjectProps.strokeWidth || 0}
                    linkedElement={activeObjectProps}
                    min={0}
                    max={20}
                    step={1}
                    onChange={handleBorderWidth}
                  />
                </Col>
              </Row>
              {activeObjectProps.strokeWidth > 0 && (
                <Row align="middle" style={STYLE.marginTop10}>
                  <Col span={9}>
                    <h4 style={STYLE.marginBottom0}>Border color</h4>
                  </Col>
                  <Col span={15}>
                    <div style={STYLE.colorButton}>
                      <ColorEditorButton color={activeObjectProps.stroke || "rgba(0, 0, 0, 0)"} onChangeColor={handleBorderColor} />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
          {(activeObjectType === "rect" || (activeObjectType === "image" && !activeObjectProps.meta?.listeningAvatar)) && (
            <Row align="middle" style={STYLE.marginTop10}>
              <Col span={9}>
                <h4 style={STYLE.marginBottom0}>Border radius</h4>
              </Col>
              <Col span={15} style={STYLE.paddingRight2}>
                {activeObjectType === "image" && (
                  <LazySlider
                    value={activeObjectProps.clipPath?.rx}
                    min={0}
                    max={160}
                    step={8}
                    onStep={onChangeRadiusStep}
                    tooltip={{ formatter: (v) => v / 8 }}
                    onChange={handleClipPathChange}
                  />
                )}
                {activeObjectType === "rect" && (
                  <LazySlider
                    value={activeObjectProps.rx}
                    linkedElement={activeObjectProps}
                    linkedElementKey={["rx", "ry"]}
                    min={0}
                    max={20}
                    step={1}
                    onChange={handleImagePosition}
                  />
                )}
              </Col>
            </Row>
          )} */}

        {(activeObjectType === "rect" || (activeObjectType === "image" && !activeObjectProps.meta?.listeningAvatar)) && (
          <Row align="middle" style={STYLE.marginTop10}>
            <Col span={9}>
              <h4 style={STYLE.marginBottom0}>Border radius</h4>
            </Col>
            <Col span={15} style={STYLE.paddingRight2}>
              {activeObjectType === "image" && (
                <LazySlider
                  value={activeObjectProps.clipPath?.rx}
                  min={0}
                  max={160}
                  step={8}
                  onStep={onChangeRadiusStep}
                  tooltip={{ formatter: (v: any) => v / 8 }}
                  onChange={handleClipPathChange}
                />
              )}
            </Col>
          </Row>
        )}

        <Row align="middle" style={STYLE.marginTop10}>
          <Col span={9}>
            <h4>Opacity</h4>
          </Col>
          <Col span={15}>
            <LazySlider
              value={activeObjectProps?.opacity}
              linkedElement={canvasActiveObject}
              linkedElementKey="opacity"
              min={0.05}
              max={1}
              step={0.01}
              onStep={() => {}}
              onChange={handleOpacityChange}
            />
          </Col>
        </Row>
        {/* {!isActiveSelection && !activeObjectProps.meta?.listeningAvatar && hasApi && ["image", "frame", "video"].includes(activeObjectType) && (
          <Row align="middle" style={STYLE.marginTop10}>
            <Col span={9}>
              <h4>API Id</h4>
            </Col>
            <Col span={15}>{activeObjectProps.id}</Col>
          </Row>
        )} */}
        <Row align="middle" className="layer-control" style={STYLE.marginTop10}>
          <Col span={6}>
            <h4 style={STYLE.margin0}>Layering</h4>
          </Col>
          <LayerControl />
        </Row>
      </>
    </>
  );
};
