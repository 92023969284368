import { LoadingOutlined } from "@ant-design/icons";
import { ChangeEvent, FC, useState } from "react";
import { addNewRuleRequest } from "../../../../Store/requests/_contentAIRequests";
import { globalConstant } from "../../../../Utils";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { useDispatch } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { t } from "i18next";

export const AddNewRule: FC<{ setAddNewRule: Function; getSelectedTagsIds: Function; contentAIModule: string; setRefetchRules: Function }> = ({
  setAddNewRule,
  getSelectedTagsIds,
  contentAIModule,
  setRefetchRules
}) => {
  const dispatch = useDispatch();
  const [newRule, setNewRule] = useState("");
  const [saveRuleLoading, setSRLoading] = useState(false);

  const saveNewRule = async () => {
    if (!newRule.trim()) {
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.LLM.notification.ruleemptyerror"])));
      return;
    }
    setSRLoading(true);

    //API Call to save this rule
    const res = await addNewRuleRequest({ tags: getSelectedTagsIds(), name: newRule, rule_type: contentAIModule });

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      console.log("Rule Added Successfully!");
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.LLM.notification.newruleadded"])));
      setAddNewRule(false);
      setRefetchRules((p: boolean) => !p);
    }

    setSRLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    let newRule = value.length >= 200 ? value.substring(0, 200) : value;
    setNewRule(newRule);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="title" style={{ padding: "15px" }}>
          {/* Add New Rule */}
          {t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.addnewrules"])}
        </div>
      </div>
      <div className="col-12">
        <div
          style={{
            border: "1px solid #BABABA",
            resize: "vertical",
            overflow: "auto",
            minHeight: "150px",
            display: "flex",
            flexDirection: "column",
            padding: "5px"
          }}
        >
          {/* <div style={{ color: "#231F20", fontWeight: 600 }}>Enter Rule</div> */}
          <div style={{ color: "#231F20", fontWeight: 600 }}>{t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.enterrule"])}</div>
          <textarea
            autoFocus
            value={newRule}
            onChange={handleChange}
            style={{ flex: "1 1 auto", border: "none", resize: "none", outline: "none", color: "#231F20", padding: "5px" }}
          />
          <div style={{ fontSize: "12px", color: "#66676B" }}>{newRule.length}/200</div>
        </div>
        <div className="text-right" style={{ marginTop: "10px" }}>
          <span className="closeBtn" onClick={() => setAddNewRule(false)}>
            {/* Cancel */}
            {t(I18N_MODULE_KEYS["content.button.cancel"])}
          </span>
          <span className="updateBtn" style={{ marginRight: "0px" }} onClick={saveNewRule}>
            {saveRuleLoading && <LoadingOutlined style={{ marginRight: "5px" }} />}
            {/* Save */}
            {t(I18N_MODULE_KEYS["content.button.save"])}
          </span>
        </div>
      </div>
    </div>
  );
};
