import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  TabsProps,
  Tooltip,
  Typography,
  Upload,
  UploadProps
} from "antd";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { setGlossaryListTMAction, setOpenAddGlossaryModalTMAction } from "../../../../Store/actions/_translationModuleActions";
import { enumTMMainTabs, initialUploadFileCustomData } from "../../Core/constant";
import { GlossaryTypes, LanguageListTypes, TextTranslationTypes, UploadDocsTypes, UploadFileCustom } from "../../Core/modals";
import { PageTypes } from "../../../Content Transcreation/Content History/core/modals";
import { NameValueTypes, apiResponse } from "../../../../Utils/_gTypes";
import _translationModuleRequests from "../../../../Store/requests/_translationModuleRequests";
import { globalConstant } from "../../../../Utils";
import { GlossaryTable } from "./_GlossaryTable";

import debounce from "lodash/debounce";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import { FileView } from "../Upload Documents";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import Dragger from "antd/es/upload/Dragger";

import "../../../../Assets/Css/TranslationModule.css";

const { Text } = Typography;
const initialPage: PageTypes = {
  page_size: 7,
  total_count: 0,
  current_page: 1
};

type ReplaceWithTypes = {
  replace: string;
  with: string;
  targetLanguage: string;
  sourceLanguage: string;
};

const initialReplaceWithTypes: ReplaceWithTypes = {
  replace: "",
  with: "",
  targetLanguage: "",
  sourceLanguage: ""
};

type LoadingTypes = {
  button: boolean;
  table: boolean;
  replace: boolean;
  file: boolean;
};

const initialLoading = {
  button: false,
  table: false,
  replace: false,
  file: false
};

const enumActiveTabs = {
  MANUAL: "1",
  UPLOAD: "2"
};

const items: TabsProps["items"] = [
  {
    key: enumActiveTabs.MANUAL,
    label: "Manual",
    children: ""
  },
  {
    key: enumActiveTabs.UPLOAD,
    label: "Upload",
    children: ""
  }
];

export const AddGlossaryModal: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const replaceRef: any = useRef(null);
  const replaceWithRef: any = useRef();

  const { openAddGlossaryModal, mainTabs, languageList } = useSelector((state: any) => state?.translationModuleReducer);
  const glossary: GlossaryTypes[] = useSelector((state: any) => state?.translationModuleReducer?.glossary);
  const uploadDocsData: UploadDocsTypes = useSelector((state: any) => state?.translationModuleReducer.uploadDocsData);
  const textTranslationData: TextTranslationTypes = useSelector((state: any) => state?.translationModuleReducer.textTranslationData);

  const [percent, setPercent] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isEditId, setIsEditId] = useState<number | null>(null);
  const [page, setPage] = useState<PageTypes>({ ...initialPage });
  const [isEditFromTable, setIsEditFromTable] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(enumActiveTabs.MANUAL);
  const [loading, setLoading] = useState<LoadingTypes>({ ...initialLoading });
  const [filteredGlossary, setFilteredGlossary] = useState<NameValueTypes[]>([]);
  const [replaceWith, setReplaceWith] = useState<ReplaceWithTypes>({ ...initialReplaceWithTypes });
  const [uploadFile, setUploadFile] = useState<UploadFileCustom>({ ...initialUploadFileCustomData }); // this state is handling upload file of add glossary
  replaceWithRef.current = replaceWith;

  const debouncedChangeHandler = useMemo(
    () => debounce((val, isReplace, sourceID, targetID) => debounceSearchWrapper(val, isReplace, sourceID, targetID), 350),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      dispatch(setOpenAddGlossaryModalTMAction(false));
    };
  }, []);

  useEffect(() => {
    if (openAddGlossaryModal === false) handleReset();
  }, [openAddGlossaryModal]);

  useEffect(() => {
    if (!openAddGlossaryModal) return;

    if (mainTabs === enumTMMainTabs.TEXT_TRANSLATION) {
      setReplaceWith((p) => ({
        ...p,
        sourceLanguage: textTranslationData.IOData.baseLanguage,
        targetLanguage: textTranslationData.IOData.targetLanguage
      }));

      getGlossaryList(
        getSourceLangId(textTranslationData.IOData.baseLanguage),
        getTargetLangId(textTranslationData.IOData.targetLanguage),
        searchTerm
      );
    } else {
      setReplaceWith((p) => ({
        ...p,
        sourceLanguage: "English",
        targetLanguage: languageList[0]?.name
      }));

      getGlossaryList(getSourceLangId("English"), getTargetLangId(languageList[0]?.name), searchTerm);
    }
  }, [textTranslationData, uploadDocsData, openAddGlossaryModal]);

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._AddGlossaryModal.${value as string}`])}`;
  };

  const getGlossaryList = async (sourceLangId: number, targetLangId: number, searchTerm: string, isReplace?: boolean, isPageChanged?: number) => {
    setLoading((p) => ({ ...p, [isReplace ? "replace" : "table"]: true }));
    const res: apiResponse = await _translationModuleRequests.getGLossary(
      sourceLangId,
      targetLangId,
      searchTerm,
      !isReplace ? (isPageChanged ? isPageChanged : page.current_page) : undefined
    );
    setLoading((p) => ({ ...p, [isReplace ? "replace" : "table"]: false }));

    if (res?.message?.toLocaleUpperCase() === globalConstant.SUCCESS) {
      if (!isReplace) {
        dispatch(setGlossaryListTMAction([...res?.data?.data]));

        setTimeout(() => {
          setPage((p: PageTypes) => ({
            ...p,
            total_count: res?.data?.total_items
          }));
        }, 100);
      }

      return res?.data?.data;
    }

    return [];
  };

  const handleReplaceWith = async (name: string, value: string) => {
    setReplaceWith((p: ReplaceWithTypes) => ({
      ...p,
      [name]: value
    }));
  };

  const getSourceLangId = (sourceLanguage: string) => {
    return languageList?.find((item: LanguageListTypes) => item?.name?.toLocaleLowerCase?.() === sourceLanguage?.toLocaleLowerCase?.())?.id as number;
  };

  const getTargetLangId = (targetLanguage: string) => {
    return languageList?.find((item: LanguageListTypes) => item?.name?.toLocaleLowerCase?.() === targetLanguage?.toLocaleLowerCase?.())?.id as number;
  };

  const debounceSearchWrapper = async (val: string, isReplace: boolean, sourceID: number, targetID: number) => {
    if (!isReplace) {
      await getGlossaryList(sourceID, targetID, val, false, 1);
      return;
    }

    await setFilteredGlossaryList([], sourceID, targetID, val, isReplace);
  };

  const setFilteredGlossaryList = async (arrFilteredData: NameValueTypes[], sourceID: number, targetID: number, val: string, isReplace: boolean) => {
    const arrGlossaryData: GlossaryTypes[] = arrFilteredData?.length
      ? [...arrFilteredData]
      : [...(await getGlossaryList(sourceID, targetID, val, true, 1))];

    let arrNewData: NameValueTypes[] = [];

    arrGlossaryData.forEach((item: GlossaryTypes) => {
      arrNewData.push({
        value: item.source,
        label: item.source,
        source: item.source,
        target: item.target,
        id: item.id
      });
    });

    setFilteredGlossary(arrNewData);

    if (isReplace) return;
    handleSelectedReplaceValue(replaceWithRef.current.replace, arrNewData);
  };

  const handleSelectedReplaceValue = (val: string, arrFilteredGlossary: NameValueTypes[]) => {
    if (isEditFromTable) return;

    if (arrFilteredGlossary && arrFilteredGlossary?.length) {
      const objGlossaryItem: NameValueTypes | undefined = arrFilteredGlossary?.find(
        (item) => item.source?.toLocaleLowerCase?.() === val?.toLocaleLowerCase?.()
      );

      if (!objGlossaryItem) {
        setIsEditId(null);
        return;
      }

      handleReplaceWith("with", objGlossaryItem.target as string);
      setIsEditId(objGlossaryItem.id as number);
    } else {
      setIsEditId(null);
    }
  };

  const handleAdd = async () => {
    if (!replaceWithRef.current.replace || !replaceWithRef.current.with || !replaceWithRef.current.targetLanguage) {
      return;
    }

    if (isEditId !== null) {
      handleEditCurrentItem();
      return;
    }

    setLoading((p) => ({ ...p, button: true }));
    const res: apiResponse = await _translationModuleRequests.addGlossary({
      source_language_id: getSourceLangId(replaceWithRef.current.sourceLanguage) as number,
      target_language_id: getTargetLangId(replaceWithRef.current.targetLanguage) as number,
      source: replaceWithRef.current.replace,
      target: replaceWithRef.current.with
    });
    setLoading((p) => ({ ...p, button: false }));

    if (res?.message?.toLocaleUpperCase() === globalConstant.SUCCESS) {
      await getGlossaryList(
        getSourceLangId(replaceWithRef.current.sourceLanguage),
        getTargetLangId(replaceWithRef.current.targetLanguage),
        searchTerm,
        false,
        1
      );

      setReplaceWith((p) => ({ ...p, replace: "", with: "" }));

      setPage((p) => ({ ...p, current_page: 1 }));
    }
  };

  const handlePageChange = async (current: number) => {
    setPage((p: PageTypes) => ({
      ...p,
      current_page: current
    }));

    await getGlossaryList(
      getSourceLangId(replaceWithRef.current.sourceLanguage),
      getTargetLangId(replaceWithRef.current.targetLanguage),
      searchTerm,
      false,
      current
    );
  };

  const getSourceLabel = () => {
    return `${replaceWithRef.current.sourceLanguage} ---> ${replaceWithRef.current.targetLanguage}`;
  };

  const handleDelete = async (record: GlossaryTypes) => {
    const res: apiResponse = await _translationModuleRequests.deleteGlossary(record["id"]);

    setReplaceWith((p) => ({ ...p, replace: "", with: "" }));

    if (res?.message?.toUpperCase() === globalConstant.SUCCESS) {
      await getGlossaryList(
        getSourceLangId(replaceWithRef.current.sourceLanguage),
        getTargetLangId(replaceWithRef.current.targetLanguage),
        searchTerm,
        false,
        glossary?.length === 1 ? page.current_page - 1 : 0
      );
    }
  };

  const handleEdit = (record: GlossaryTypes) => {
    setActiveTab(enumActiveTabs.MANUAL);

    setTimeout(() => {
      setReplaceWith((p) => ({
        ...p,
        replace: record.source,
        with: record.target
      }));

      replaceRef?.current?.focus?.();

      setFilteredGlossary([]);

      setIsEditId(record.id);

      setIsEditFromTable(true);
    }, 100);
  };

  const handleEditCurrentItem = async () => {
    const res: apiResponse = await _translationModuleRequests.updateGlossary(
      {
        source_language_id: getSourceLangId(replaceWithRef.current.sourceLanguage) as number,
        target_language_id: getTargetLangId(replaceWithRef.current.targetLanguage) as number,
        source: replaceWithRef.current.replace,
        target: replaceWithRef.current.with
      },
      isEditId as number
    );

    if (res.message?.toUpperCase?.() === globalConstant.SUCCESS) {
      setIsEditId(null);

      setIsEditFromTable(false);

      setPage((p) => ({ ...p, current_page: 1 }));

      setReplaceWith((p) => ({ ...p, replace: "", with: "" }));

      getGlossaryList(
        getSourceLangId(replaceWithRef.current.sourceLanguage),
        getTargetLangId(replaceWithRef.current.targetLanguage),
        searchTerm,
        false,
        1
      );
    }
  };

  const handleReset = () => {
    setIsEditId(null);
    setFilteredGlossary([]);
    setIsEditFromTable(false);
    setActiveTab(enumActiveTabs.MANUAL);
    setUploadFile({ ...initialUploadFileCustomData });
    setReplaceWith((p) => ({ ...p, replace: "", with: "" }));
  };

  const onChangingSourceTargetLanguage = async (name: string, key: string) => {
    setSearchTerm("");
    setFilteredGlossary([]);

    setPage({ ...initialPage });

    setReplaceWith((p) => ({
      ...p,
      [key]: name
    }));

    if (key === "sourceLanguage") {
      await getGlossaryList(getSourceLangId(name), getTargetLangId(replaceWithRef.current.targetLanguage), "", false, 1);
    } else {
      await getGlossaryList(getSourceLangId(replaceWithRef.current.sourceLanguage), getTargetLangId(name), "", false, 1);
    }

    const arrGlossary: GlossaryTypes[] = await getGlossaryList(
      getSourceLangId(key === "sourceLanguage" ? name : replaceWithRef.current.sourceLanguage),
      getTargetLangId(key === "targetLanguage" ? name : replaceWithRef.current.targetLanguage),
      "",
      true
    );

    if (arrGlossary && arrGlossary?.length && replaceWithRef.current.replace) {
      const objGlossaryItem: GlossaryTypes | undefined = arrGlossary?.find(
        (item) => item.source?.toLocaleLowerCase?.() === replaceWithRef.current.replace.toLocaleLowerCase?.()
      );

      if (!objGlossaryItem) {
        setIsEditId(null);
        return;
      }

      handleReplaceWith("with", objGlossaryItem.target);
      setIsEditId(objGlossaryItem.id);
    } else {
      setIsEditId(null);
    }
  };

  const onChangeActiveTabs = (key: string) => {
    setActiveTab(key);
  };

  const handleFileManipulation = (isDelete: boolean) => {
    if (isDelete) {
      setUploadFile({ ...initialUploadFileCustomData });
      return;
    }

    handleUploadFile(uploadFile.file);
  };

  const validateFileFormat = (file: any) => {
    if (file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return true;
    }
    return false;
  };

  const props: UploadProps = {
    name: "file",
    accept: ".xlsx",
    multiple: true,
    showUploadList: false,
    customRequest: async ({ file }) => {
      if (!validateFileFormat(file)) {
        return;
      }

      handleUploadFile(file);
    },
    onChange: async (info) => {
      const { file } = info;
      const isLt50MB = (file.size as number) / 1024 / 1024 <= 128; //File size should be <= 50MB

      if (!validateFileFormat(info.file)) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
        return;
      }

      if (!isLt50MB) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
        return;
      }
    }
  };

  const getAPIConfig = () => {
    return {
      onloadstart: () => {},
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        console.log(percentCompleted, "percentCompleted");

        if (percentCompleted === 100) {
          return;
        }

        setPercent(percentCompleted);
      },

      onDownloadProgress: function () {
        setUploadFile((p) => ({ ...p, isUploading: false }));

        setPercent(0);
      }
    };
  };

  const handleUploadFile = async (file: any) => {
    setUploadFile((p) => ({ ...p, isUploading: true, file: file, file_name: file?.name, file_size: file?.size, file_type: file?.type }));

    const instFormData: FormData = new FormData();

    instFormData.append("file", file);
    instFormData.append("user_id", "1");
    instFormData.append("source_language_id", getSourceLangId(replaceWithRef.current.sourceLanguage)?.toString?.());
    instFormData.append("target_language_id", getTargetLangId(replaceWithRef.current.targetLanguage)?.toString?.());

    const res: apiResponse = await _translationModuleRequests.glossaryUploadExcel(instFormData, undefined, getAPIConfig());

    if (res?.message?.toUpperCase?.() === globalConstant.SUCCESS) {
      setUploadFile((p) => ({ ...p, isUploaded: true, isUploading: false, isError: false }));
    } else {
      setUploadFile((p) => ({ ...p, isUploaded: false, isUploading: false, isError: true }));
    }

    await getGlossaryList(
      getSourceLangId(replaceWithRef.current.sourceLanguage),
      getTargetLangId(replaceWithRef.current.targetLanguage),
      searchTerm,
      false,
      1
    );
  };

  useEffect(() => {
    console.log(percent, "percent");
  }, [percent]);

  useEffect(() => {
    console.log(uploadFile, "uploadFile");
  }, [uploadFile]);

  return (
    <Modal
      title={""}
      className="translation-module"
      style={{ top: 50 }}
      open={openAddGlossaryModal}
      // maskStyle={{ backdropFilter: "blur(2px)" }}
      styles={{ body: { backdropFilter: "blur(2px)" } }}
      centered
      width={900}
      footer={() => <></>}
      onCancel={() => dispatch(setOpenAddGlossaryModalTMAction(false))}
    >
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Flex vertical gap={10}>
            <Flex vertical>
              <Text style={{ fontSize: "18px", fontWeight: "700" }}>{getTranslatedText("Add Glossary")}</Text>
              <Text style={{ fontSize: "14px", fontWeight: "400", color: "rgba(123, 123, 123, 1)" }}>
                {getTranslatedText("Set rules for how words and phrases are translated")}
              </Text>
            </Flex>
            <Flex vertical>
              <Tabs defaultActiveKey={enumActiveTabs.MANUAL} activeKey={activeTab} items={items} onChange={onChangeActiveTabs} />
              <Flex vertical gap={27}>
                {activeTab === enumActiveTabs.MANUAL && (
                  <>
                    <Flex vertical gap="small">
                      <Text style={{ fontSize: "14px", fontWeight: "400" }}> {getTranslatedText("Replace")}</Text>
                      <AutoComplete
                        suffixIcon={loading.replace && <Spin size="small" />}
                        ref={replaceRef}
                        style={{ height: "40px" }}
                        options={filteredGlossary?.length ? filteredGlossary : []}
                        placeholder={getTranslatedText("e.g, hello!")}
                        value={replaceWithRef.current.replace}
                        onSearch={(val) => {
                          setIsEditFromTable(false);

                          if (!val) {
                            setFilteredGlossary([]);
                          }

                          val = val?.trim();

                          debouncedChangeHandler(
                            val,
                            true,
                            getSourceLangId(replaceWithRef.current.sourceLanguage),
                            getTargetLangId(replaceWithRef.current.targetLanguage)
                          );
                        }}
                        onChange={(val) => {
                          handleReplaceWith("replace", val?.trim());
                        }}
                        onSelect={(name) => handleSelectedReplaceValue(name, filteredGlossary)}
                        onBlur={() => handleSelectedReplaceValue(replaceWithRef.current.replace, filteredGlossary)}
                      />
                    </Flex>
                    <Flex vertical gap="small">
                      <Text style={{ fontSize: "14px", fontWeight: "400" }}> {getTranslatedText("With")}</Text>
                      <Input
                        name="with"
                        placeholder={getTranslatedText("e.g, 更高")}
                        value={replaceWithRef.current.with}
                        style={{ height: "40px" }}
                        onChange={(e) => handleReplaceWith(e.target.name, e.target.value?.trim())}
                        readOnly={loading.button || loading.replace}
                      />
                    </Flex>
                  </>
                )}
                <Flex vertical gap="small">
                  {/* <Text style={{ fontSize: "14px", fontWeight: "400" }}> {getTranslatedText("Source --> Target")}</Text> */}
                  <Text style={{ fontSize: "14px", fontWeight: "400" }}> Source</Text>
                  <Select
                    placeholder={getTranslatedText("Select Target Language")}
                    options={languageList?.map((item: LanguageListTypes) => {
                      return { value: item.name };
                    })}
                    style={{ height: "40px" }}
                    onChange={(name) => onChangingSourceTargetLanguage(name, "sourceLanguage")}
                    value={replaceWithRef.current.sourceLanguage ? replaceWithRef.current.sourceLanguage : "Select Source Language"}
                    labelRender={() => (
                      <Text
                        style={{
                          fontSize: replaceWithRef.current.sourceLanguage ? "15px" : "14px",
                          fontWeight: replaceWithRef.current.sourceLanguage ? "600" : "400",
                          color: replaceWithRef.current.sourceLanguage ? "" : "rgb(192 189 189)"
                        }}
                      >
                        {" "}
                        {replaceWithRef.current.sourceLanguage}
                        {/* {replaceWithRef.current.targetLanguage ? getSourceLabel() : getTranslatedText("Select Target Language")} */}
                      </Text>
                    )}
                  />
                  <Space style={{ border: "1px solid #d9d9d9", height: "40px", borderRadius: "8px", paddingLeft: "8px" }}></Space>
                </Flex>
                <Flex vertical gap="small">
                  <Text style={{ fontSize: "14px", fontWeight: "400" }}>Target</Text>
                  <Select
                    placeholder={getTranslatedText("Select Target Language")}
                    options={languageList?.map((item: LanguageListTypes) => {
                      return { value: item.name };
                    })}
                    style={{ height: "40px" }}
                    // onChange={onChangingTargetLanguage}
                    onChange={(name) => onChangingSourceTargetLanguage(name, "targetLanguage")}
                    value={replaceWithRef.current.targetLanguage ? replaceWithRef.current.targetLanguage : "Select Target Language"}
                    labelRender={() => (
                      <Text
                        style={{
                          fontSize: replaceWithRef.current.targetLanguage ? "15px" : "14px",
                          fontWeight: replaceWithRef.current.targetLanguage ? "600" : "400",
                          color: replaceWithRef.current.targetLanguage ? "" : "rgb(192 189 189)"
                        }}
                      >
                        {" "}
                        {replaceWithRef.current.targetLanguage}
                        {/* {replaceWithRef.current.targetLanguage ? getSourceLabel() : getTranslatedText("Select Target Language")} */}
                      </Text>
                    )}
                  />
                  <Space style={{ border: "1px solid #d9d9d9", height: "40px", borderRadius: "8px", paddingLeft: "8px" }}></Space>
                </Flex>
                {activeTab === enumActiveTabs.UPLOAD && (
                  <Flex vertical gap="small">
                    <Text style={{ fontSize: "14px", fontWeight: "400" }}> Upload File</Text>
                    <Dragger {...props}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: uploadFile.file_name ? "row" : "column",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <img width={uploadFile.file_name ? "60px" : "150px"} src={PROJECT_ICONS.C_AI_FILE_UPLOAD_GIF} />

                        <span>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"])}</span>
                      </div>
                    </Dragger>

                    {/* {uploadFile.file_name && ( */}
                    <div style={{ display: uploadFile.file_name ? "block" : "none" }}>
                      <FileView
                        key={uploadFile.file_id}
                        isError={uploadFile.isError}
                        isUploaded={uploadFile.isUploaded}
                        uploading={uploadFile.isUploading}
                        name={uploadFile?.file_name}
                        size={uploadFile?.file_size}
                        handleFileManipulation={(d) => handleFileManipulation(d)}
                        percent={uploadFile.isUploading === true && uploadFile.isUploaded === false ? percent : 100}
                        isRemoveIcon={true}
                      />
                    </div>
                  </Flex>
                )}
                {/* )} */}
                {activeTab === enumActiveTabs.MANUAL && (
                  <Flex justify="flex-end" align="center" gap={"middle"}>
                    <Button style={{ fontSize: "12px", color: "rgba(102, 103, 107, 1)" }} type="link" onClick={handleReset} disabled={loading.button}>
                      {getTranslatedText("RESET")}
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        fontSize: "12px",
                        background:
                          loading.button || !replaceWithRef.current.replace || !replaceWithRef.current.with
                            ? "rgba(230, 231, 232, 1)"
                            : "rgba(3, 78, 162, 1)",
                        width: "88px",
                        borderRadius: "0px"
                      }}
                      loading={loading.button}
                      disabled={loading.button || !replaceWithRef.current.replace || !replaceWithRef.current.with}
                      onClick={handleAdd}
                    >
                      {isEditId !== null ? getTranslatedText("UPDATE") : getTranslatedText("ADD")}
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Col>
        <Col span={2}>
          <Flex vertical justify="center" align="center" style={{ height: "100%" }}>
            <Divider type="vertical" style={{ height: "100%", background: "rgba(206, 206, 206, 1)" }} />
            {/* <Text style={{ padding: "10px", border: "1px solid  rgba(206, 206, 206, 1)", borderRadius: "25px", color: "rgb(140 139 139)" }}>
              {getTranslatedText("OR")}
            </Text> */}
            {/* <Divider type="vertical" style={{ height: "100%", background: "rgba(206, 206, 206, 1)" }} /> */}
          </Flex>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Flex vertical gap="middle" style={{ marginTop: "10px" }}>
            <Input
              placeholder={getTranslatedText("Search item")}
              value={searchTerm}
              style={{ height: "30px", padding: "0px 10px" }}
              allowClear
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage((p) => ({ ...p, current_page: 1 }));
                debouncedChangeHandler(
                  e.target.value,
                  false,
                  getSourceLangId(replaceWithRef.current.sourceLanguage),
                  getTargetLangId(replaceWithRef.current.targetLanguage)
                );
              }}
            />
            <GlossaryTable
              page={page}
              list={glossary || []}
              loading={loading.table}
              handleEdit={(record: GlossaryTypes) => handleEdit(record)}
              handleDelete={(record: GlossaryTypes) => handleDelete(record)}
              handleOnChange={(current) => handlePageChange(current)}
            />
          </Flex>
        </Col>
      </Row>
    </Modal>
  );
};

// <Flex vertical>
//   <Text style={{ fontSize: "18px", fontWeight: "700" }}>Upload File</Text>
//   <Text style={{ fontSize: "14px", fontWeight: "400", color: "rgba(123, 123, 123, 1)" }}>description goes here</Text>
// </Flex>
// <div className="col-7" style={{ paddingLeft: 0, background: "rgba(239, 250, 255, 1)" }}>
//   <div style={{ height: "38vh" }}>
//     <div className="cu-file-upload" style={{ height: "100%" }}>
//       <Dragger>
//         <p className="ant-upload-drag-icon">
//           <img width="150px" src={PROJECT_ICONS.C_AI_FILE_UPLOAD_GIF} />
//         </p>
//         <div className="cu-upload-hint">
//           <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"])}</div>
//         </div>
//       </Dragger>
//     </div>
//   </div>
// </div>
