import { FC, useState } from "react";
import { LLMRulesComponentProps } from "../../Core/types";
import RuleComponent from "./RuleComponent";
import { Spin } from "antd";
import { AddNewRule } from "./AddNewRule";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const LLMRulesComponent: FC<LLMRulesComponentProps> = ({
  props: { filteredRules, getSelectedTagsIds, setRefetchRules, loadingRules, contentAIModule }
}) => {

  const {t} = useTranslation()

  const [addNewRule, setAddNewRule] = useState(false);
  const [editRuleId, setEditRuleId] = useState(0); //stores the ruleId of the rule currently being edited

  const addNewRuleHandler = () => {
    setEditRuleId(0); //No rules in edit mode when adding new rule
    setAddNewRule(true);
  };

  return (
    <div>
      {!addNewRule && (
        <>
          <div className="row">
            <div className="col-10">
              <div style={{ padding: "15px", display: "flex", alignItems: "center", gap: "5px", fontWeight: 600 }}>
                {/* <div className="title">Rules</div> */}
                <div className="title">{t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.rules"])}</div>
                <span>|</span>
                <div style={{ color: "#034EA2", cursor: "pointer" }} onClick={addNewRuleHandler}>
                  {/* ADD NEW RULE */}
                  {t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.addnewrules"])}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="title" style={{ padding: "15px 0" }}>
                {/* Actions */}
                {t(I18N_MODULE_KEYS["content.text.actions"])}
              </div>
            </div>
          </div>

          {loadingRules ? (
            <div className="rules">
              <div className="row">
                <div className="col-12 heading" style={{ textAlign: "center" }}>
                  <Spin />
                </div>
              </div>
            </div>
          ) : filteredRules.length ? (
            filteredRules.map((rule, key) => (
              <RuleComponent
                key={rule.rule_id}
                rule={rule}
                ruleNo={key + 1}
                filteredTagsIds={getSelectedTagsIds()}
                setRefetchRules={setRefetchRules}
                editRuleId={editRuleId}
                setEditRuleId={setEditRuleId}
              />
            ))
          ) : (
            <div className="rules">
              <div className="row">
                <div className="col-12 heading" style={{ textAlign: "center" }}>
                  {/* No Rules Found */}
                  {t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.norulefound"])}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {addNewRule && (
        <AddNewRule
          setAddNewRule={setAddNewRule}
          getSelectedTagsIds={getSelectedTagsIds}
          contentAIModule={contentAIModule}
          setRefetchRules={setRefetchRules}
        />
      )}
    </div>
  );
};
