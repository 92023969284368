import { Layout, theme } from "antd";
import DHeader from "./Spaces/_header";
import { FC, useState, useEffect } from "react";
import { SemanticSearchHeaderWrapper } from "../Components/Navbar/Semantic Search";
import { LayoutSyncTypes } from "../Utils/_gTypes";
import { useSelector } from "react-redux";
import { TranslationModuleHeader } from "../Pages/Translation Module/Components";

type IProps = {
  children?: any;
};

export const SemanticLayout: FC<IProps> = ({ children }) => {
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  const [isInitialView, setIsInitialView] = useState(true);

  useEffect(() => {
    // Automatically switch to the second view after a 3-second delay
    const timeout = setTimeout(() => {
      setIsInitialView(false);
    }, 1500);

    // Clear the timeout when the component unmounts
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const {
    token: { colorBgContainer }
  } = theme.useToken();
  return (
    <>
      <Layout>
        {layoutSync.SEMANTIC_SEARCH && (
          <DHeader
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              height: layoutSync.SEMANTIC_SEARCH && "100px",
              background: colorBgContainer,
              padding: layoutSync.SEMANTIC_SEARCH && "10px 20px"
            }}
          >
            <SemanticSearchHeaderWrapper />
          </DHeader>
        )}
        {layoutSync.TRANSLATION_MODULE && (
          <DHeader
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              height: "105px",
              background: colorBgContainer,
              padding: "15px"
            }}
          >
            <TranslationModuleHeader />
          </DHeader>
        )}
        {children}
      </Layout>
      {/* {children} */}
    </>
  );
};
