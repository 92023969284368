import { Button, Col, Image, Row, Spin } from "antd";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFileActiveCardType } from "../../../../Store/actions/_semanticSearchActions";
import { ComponentFileProps, SEMANTIC_SEARCH_CARD } from "../Core/constants";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { getDocuments, getImageDocuments, syncS3 } from "../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";

export const FileSystemTabs: React.FC<ComponentFileProps> = ({
  foldersLength,
  setFoldersLength,
  folderData,setFolderData
}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [activeButton, setActiveButton] = useState<string>(
    SEMANTIC_SEARCH_CARD.DOCUMENTS
  );
  const[isSyncing,setIsSyncing]=useState(false);

  console.log(tabList, "tabList");

  const handleFileCardType = (s: string) => {
    console.log( "this is folderslength in tabs");
      if (foldersLength === 0) {
        return;
      }
     dispatch(setFileActiveCardType(s));
    setActiveButton(s);
  };
  const handleS3Sync = async()=>{
    setIsSyncing(true);
  const res = await syncS3();
  if(!res){
    dispatch(setNotificationMsgAction("Error while syncing folders."))
    setIsSyncing(false);

    return;
  }
  const folderData = await getDocuments()
  if (!folderData) {    setIsSyncing(false);
    return;}
    const length=folderData?.data
    setFolderData(length)
    setFoldersLength(length?.length)
    console.log(length?.length)
    setIsSyncing(false);

  }

  return (
    <Row gutter={[16, 24]} style={{ padding: "10px",display:'flex' }} >
      <Col xs={22}>
        <Button
          type={activeButton === SEMANTIC_SEARCH_CARD.DOCUMENTS ? "primary" : "default"}
          style={{
            marginRight: "5px",
            background:
              activeButton === SEMANTIC_SEARCH_CARD.DOCUMENTS
                ? "rgb(5, 70, 165)"
                : "#F3F6FA",
            borderRadius: "15px"
          }}
          onClick={() => {
            handleFileCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS);
          }}
        >
          {t(I18N_MODULE_KEYS["semantic.search.filesystem.documents"])}
        </Button>
        
        <Button
        
         
        disabled={foldersLength===0}
        type={activeButton === SEMANTIC_SEARCH_CARD.VIDEOS ? "primary" : "default"}
         style={{
           marginRight: "5px",
           background:
             activeButton === SEMANTIC_SEARCH_CARD.VIDEOS
               ? "rgb(5, 70, 165)"
               : "#F3F6FA",
           borderRadius: "15px"}}
        onClick={() => {
         handleFileCardType(SEMANTIC_SEARCH_CARD.VIDEOS);
       }}
       >
         {t(I18N_MODULE_KEYS["semantic.search.filesystem.videos"])}
       </Button>
       <Button
        
         
        disabled={foldersLength===0}
        type={activeButton === SEMANTIC_SEARCH_CARD.AUDIOS ? "primary" : "default"}
         style={{
           marginRight: "5px",
           background:
             activeButton === SEMANTIC_SEARCH_CARD.AUDIOS
               ? "rgb(5, 70, 165)"
               : "#F3F6FA",
           borderRadius: "15px"}}
        onClick={() => {
         handleFileCardType(SEMANTIC_SEARCH_CARD.AUDIOS);
       }}
       >
         {t(I18N_MODULE_KEYS["semantic.search.filesystem.audios"])}
       </Button>
        <Button
        
         
         disabled={foldersLength===0}
         type={activeButton === SEMANTIC_SEARCH_CARD.IMAGES ? "primary" : "default"}
          style={{
            marginRight: "5px",
            background:
              activeButton === SEMANTIC_SEARCH_CARD.IMAGES
                ? "rgb(5, 70, 165)"
                : "#F3F6FA",
            borderRadius: "15px"}}
         onClick={() => {
          handleFileCardType(SEMANTIC_SEARCH_CARD.IMAGES);
        }}
        >
          {t(I18N_MODULE_KEYS["semantic.search.filesystem.images"])}
        </Button>
        <Button
          type="default"
          disabled
          style={{ borderRadius: "15px", marginRight: "5px" }}
        >
          {t(I18N_MODULE_KEYS["semantic.search.filesystem.urls"])}
        </Button>
       
      </Col>
      <div style={{display:'flex',paddingTop:'10px',cursor:'pointer'}} >
         <p style={{color:'#000000',fontWeight:500,marginRight:'10px',paddingTop:'2px',fontSize:'14px'}}>Sync now</p>
          {isSyncing?(<><Spin></Spin></>):(<><Image src= {PROJECT_ICONS.REFRESH} preview={false}/> </>)}
        </div>
    </Row>
  );
};

