import { FC } from "react";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import { LayoutSyncTypes } from "../Utils/_gTypes";
import { AdminLayout } from "./_AdminLayout";
import { SemanticLayout } from "./_SemanticLayout";
import { ActionDrawer } from "../Components/ActionDrawer";
import { MODULE_PATHNAME } from "../Utils";
import { useCurrentModulePath } from "./hooks/useCurrentModulePath";

type IProps = {
  children?: any;
};

export const DLayout: FC<IProps> = ({ children }) => {
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);
  const currentModulePath = useCurrentModulePath();

  const applyLayout = () => {
    switch (currentModulePath) {
      case MODULE_PATHNAME.CONTENT_TRANSCREATION:
        return <AdminLayout>{children}</AdminLayout>;
      case MODULE_PATHNAME.SEMANTIC_SEARCH:
        return <SemanticLayout>{children}</SemanticLayout>;
      case MODULE_PATHNAME.CONTENT_AI:
        return <SemanticLayout>{children}</SemanticLayout>;
      case MODULE_PATHNAME.TRANSLATION_MODULE:
        return <SemanticLayout>{children}</SemanticLayout>;
      case MODULE_PATHNAME.CONTENT_TRANSLATION:
        return <div>{children}</div>;
      default:
        return <AdminLayout>{children}</AdminLayout>;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
      {applyLayout()}
      <ActionDrawer />
    </Layout>
  );
};
