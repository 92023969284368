import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import fabric from "../fabric-files/utils/fabric/fabric";
import { Canvas } from "fabric/fabric-impl";
import { SlideDataType, SlideThumbnail } from "../core/modals";
import { Button, Spin, message, notification } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setActiveObjectModifier,
  setCanvasActiveObject,
  setElaiTranslationDataAction,
  setSaveElaiObject
} from "../../../../Store/actions/_contentTranscreationActions";
import { RootState } from "../../../../Store";
import { updateElaiVideoData } from "../../../../Store/requests/_contentTranscreationRequests";
import ContextMenu from "./canvas/ContextMenu";
import {
  changeCanvasActiveObject,
  createCanvasObject,
  deleteObjectsOutsideCanvas,
  manageLayersSelection,
  modifyBackground,
  removeNonUniformScalingControls,
  scaleTextboxObjects
} from "../fabric-files/utils/canvas";
import { isDebug, listenForShiftPressed } from "./canvas/constans";
import { removeLines, snapObjects } from "./canvas/canvasSnapping";
import { sleep } from "../fabric-files/utils/helpers";

const WORKING_AREA_PADDING_PX = 20 * 2;
const MAX_OBJ_WIDTH = 640 * 3;
const MAX_OBJ_HEIGHT = 360 * 3;
const PROPS_TO_INCLUDE = ["id", "animation", "meta"];
const TEMPLATE_THUMBMAIL_MAX_OBJECT = 5;
const THUMBNAIL_MODES = {
  REGULAR: "regular",
  TEMPLATE: "template"
};
const CONTROLS_VISIBILITY_DELAY_MS = 10;

let updateTimeout: any;

export const FabricCanvas = ({
  slide,
  hidden,
  setThumbnails,
  setCanvasList,
  index,
  workingAreaRef
}: {
  slide: SlideDataType;
  hidden?: boolean;
  setThumbnails: Function;
  setCanvasList: Function;
  index: number;
  workingAreaRef: RefObject<HTMLDivElement>;
}) => {
  console.log("Rerendered!");
  const dispatch = useDispatch();
  const { elaiResponse, activeObjectModifier, canvasActiveObject, activeSlide, saveElaiObject } = useSelector(
    (state: RootState) => state?.contentTranscreationReducer
  );

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  const [canvas, setCanvas] = useState<Canvas>();
  const [loading, setLoading] = useState(true);

  const isShiftPressed = useRef(null);

  useEffect(() => {
    if (!slide) return;
    const canvas = initCanvas();

    return () => {
      canvas.dispose();
    };
  }, [slide.id, slide.avatar?.id]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log(loading, "loading");
  }, [loading]);

  useEffect(() => {
    getCanvasThumbnail();

    setCanvasList((prevCanvasList: any) => {
      let updatedCanvasList = [...prevCanvasList];
      updatedCanvasList[index] = canvas;
      return updatedCanvasList;
    });
  }, [canvas]);

  useEffect(() => {
    index === activeSlide && saveElaiObject && getCanvasThumbnail();
  }, [saveElaiObject]);

  //When Canvas is ready, attach the shift listener
  useEffect(() => {
    return listenForShiftPressed(isShiftPressed);
  }, [canvas]);

  const initCanvas = () => {
    const zoom = calculateZoom();
    const canvas = new fabric.Canvas("canvas-" + slide.id, {
      height: Math.round(360 * zoom),
      width: Math.round(640 * zoom),
      preserveObjectStacking: true
    });
    canvas.setZoom(zoom);

    canvas.loadFromJSON(slide.canvas, () => {
      // if (activeObject?.bg) canvas.switchToBackgroundMode();
      // else if (isBgMode) canvas.switchToElementsMode();
      //@ts-ignore
      // const isBgMode = canvas.bgMode;
      // //@ts-ignore
      // if (canvasActiveObject?.bg) canvas.switchToBackgroundMode();
      // //@ts-ignore
      // else if (isBgMode) canvas.switchToElementsMode();

      //console.log("Rendered");
      if (isLoading === false) {
        setLoading(false);
      }
      setCanvas(canvas);
    });

    canvas.stateful = true;

    initializeCanvasEvents(canvas);

    return canvas;
  };

  const handleChangesOutsideCanvas = useCallback(async () => {
    if (!activeObjectModifier || !canvas || index !== activeSlide) return;

    if (activeObjectModifier === "discardActiveObject") {
      canvas.discardActiveObject().renderAll();
      dispatch(setCanvasActiveObject(null));
      return dispatch(setActiveObjectModifier(null));
    }

    if (activeObjectModifier.change === "layersSelection") {
      manageLayersSelection(activeObjectModifier.value, canvas, canvasActiveObject, false /* isShiftPressed.current */);
      canvas.renderAll();
      return dispatch(setActiveObjectModifier(null));
    }

    // setPlayer((p) => ({ ...p, canvasReady: false }));
    await applyActiveObjectModifierToCanvas(activeObjectModifier, canvas);
    // setPlayer((p) => ({ ...p, canvasReady: true }));
    canvas.renderAll();

    saveCanvas(canvas);

    // updateCanvas({ linkedSlideChanges: activeObjectModifier.linkedSlideChanges });
    dispatch(setActiveObjectModifier(null));
  }, [activeObjectModifier, canvas]);

  /**
   * Handles changes to active object or adding new objects outside of canvas (used mostly in sidebar)
   */
  useEffect(() => {
    handleChangesOutsideCanvas();
  }, [handleChangesOutsideCanvas]);

  const applyActiveObjectModifierToCanvas = async (activeObjectModifier: any, canvas: Canvas) => {
    try {
      if (activeObjectModifier.newObject) {
        await createCanvasObject(activeObjectModifier, canvas);
      } else if (activeObjectModifier.change) {
        if (canvasActiveObject.type === "activeSelection") {
          if (activeObjectModifier.change === "groupedAlignment") {
            await changeCanvasActiveObject(activeObjectModifier, canvas, canvasActiveObject);
          } else {
            canvasActiveObject.forEachObject(async (obj: any) => await changeCanvasActiveObject(activeObjectModifier, canvas, obj));
          }
        } else {
          await changeCanvasActiveObject(activeObjectModifier, canvas, canvasActiveObject);
          //if (canvasActiveObject.type === "video") syncVideoState(canvas, canvasActiveObject, activeObjectModifier.changedFields, data.duration);
        }
      } else if (activeObjectModifier.background) {
        modifyBackground(canvas, canvasActiveObject, activeObjectModifier);
      }
    } catch (e: any) {
      if (typeof e === "boolean") {
        notification.error({
          message: "Cannot load media."
        });
      } else {
        const err = e.toString();
        console.log(err);
        notification.error({
          message: err === "[object Event]" ? "Unknown error occured." : err
        });
      }
    }
  };

  const updateCanvas = (canvas: Canvas) => {
    // console.log("updateCanvas called", canvas);
    // if (!canvas) return;
    // setCanvasData(canvas.toObject(PROPS_TO_INCLUDE));
    // console.log("updateCanvas done", canvas.toObject(PROPS_TO_INCLUDE));
    // Update thumbnail
  };

  // const updateCanvas = useCallback(
  //   ({ linkedSlideChanges = null } = {}) => {
  //     if (!canvas) return
  //     const updatedCanvas = canvas.toObject(PROPS_TO_INCLUDE)
  //     updateSlide({ ...linkedSlideChanges, canvas: updatedCanvas }, { slideIndex: activeSlide })
  //     canvasRegistry.updateThumbnail({ id: data.id, canvas: updatedCanvas })
  //   },
  //   [canvas, activeSlide],
  // )

  const initializeCanvasEvents = (canvas: any) => {
    canvas.__eventListeners = {};

    canvas.on("object:modified", (e: any) => {
      const { target } = e;
      console.log("object:modified");
      console.log("target type", target.type);
      if (target.type === "i-text" && target.scaleX !== target.scaleY) {
        target.set({ scaleY: target.scaleX });
      }
      target._objects?.forEach((obj: any) => {
        if (obj.type === "i-text") obj.set({ scaleX: target.scaleX, scaleY: target.scaleX, dirty: true });
      });

      if (e.action === "scale") scaleTextboxObjects(target, canvas);

      //to not update state when only text is changed, because state was updated on text:changed event
      if (!target.text || e.action) {
        saveCanvas(canvas);
      } //else isBackgroundModified.current = true;
    });
    canvas.on("selection:created", () => {
      if (isDebug) console.log("selection:created");
      console.log("selection:created: canvas.getActiveObject()", canvas, canvas.getActiveObject());
      const target = removeNonUniformScalingControls(canvas.getActiveObject());
      //isAvatarSelected.current = target?.type === "avatar";
      dispatch(setCanvasActiveObject(target));
    });
    canvas.on("selection:cleared", () => {
      if (isDebug) console.log("selection:cleared");
      dispatch(
        setCanvasActiveObject(
          ((ao: any) => {
            if (ao?.selectionStyles?.length) ao.selectionStyles = [];
            if (canvas.bgMode) {
              canvas.setActiveObject(ao);
              return ao;
            }
            return null;
          })(canvasActiveObject)
        )
      );
    });
    canvas.on("selection:updated", () => {
      if (isDebug) console.log("selection:updated");
      const target = removeNonUniformScalingControls(canvas.getActiveObject());
      dispatch(setCanvasActiveObject(target));
    });
    // canvas.on("mouse:down", () => {
    //   setVisible(false);
    // });
    // canvas.on("mouse:dblclick", ({ target }) => {
    //   const canvasContainsBgObj = canvas.getObjects().some((obj) => obj.bg);
    //   if (canvas.bgMode) {
    //     switchToElementsMode();
    //   } else if ((!target || target.bg) && canvasContainsBgObj) {
    //     switchToBackgroundMode();
    //   }
    // });
    canvas.on("object:moving", (e: any) => {
      snapObjects(e, canvas, isShiftPressed.current);
    });
    canvas.on("object:moved", (e: any) => {
      removeLines();
      if (!canvas.bgMode) deleteObjectsOutsideCanvas(e.target, canvas, updateCanvas);
    });
    // canvas.on("mouse:over", (e) => {
    //   if (!e.target?.bg) return;
    //   if (canvas.bgMode) e.target.set({ hoverCursor: "move" });
    //   else e.target.set({ hoverCursor: "default" });
    // });
    canvas.on("text:selection:changed", ({ target }: { target: any }) => {
      if (isDebug) console.log("text:selection:changed");
      target.set({ selectionStyles: target.getSelectionStyles(target.selectionStart, target.selectionEnd) });
      dispatch(setCanvasActiveObject(target));
    });
    canvas.on("text:changed", (v: any) => {
      console.log("text:changed");
      const { target } = v;
      target.text = target.text.replaceAll("\t", "");

      if (target.scaleX * target.width > MAX_OBJ_WIDTH) {
        const scale = MAX_OBJ_WIDTH / target.width;
        target.set({ scaleX: scale, scaleY: scale });
      }
      if (target.scaleY * target.height > MAX_OBJ_HEIGHT) {
        const scale = MAX_OBJ_HEIGHT / target.height;
        target.set({ scaleX: scale, scaleY: scale });
      }
      saveCanvas(canvas);
    });

    canvas.on("object:scaling", ({ target, transform }: { target: any; transform: any }) => {
      if (target.scaleX * target.width > MAX_OBJ_WIDTH) target.set({ scaleX: MAX_OBJ_WIDTH / target.width });
      if (target.scaleY * target.height > MAX_OBJ_HEIGHT) target.set({ scaleY: MAX_OBJ_HEIGHT / target.height });
      /**
       * This is required for adjusting border radius when scaling rectangles
       */
      if (target.type === "rect" && transform.action !== "scale")
        target.set({
          width: target.width * target.scaleX,
          height: target.height * target.scaleY,
          scaleX: 1,
          scaleY: 1,
          objectCaching: false
        });

      /**
       * Prevent unpropotional scaling of text
       */
      if (target.type === "i-text" && target.scaleX !== target.scaleY) {
        target.set({ scaleY: target.scaleX });
      }
      if (target._objects && target._objects.every((obj: any) => obj.type === "i-text")) {
        target.set({ scaleY: target.scaleX });
      }
    });
  };

  const calculateZoom = (workingArea = workingAreaRef.current) => {
    if (!workingArea) return 1;
    //@ts-ignore
    const zoom = (workingArea.parentElement.clientWidth - WORKING_AREA_PADDING_PX) / 640;
    return Math.min(getSuitableMaxZoom(), Math.max(1, zoom));
  };

  const getSuitableMaxZoom = () => {
    if (window.innerHeight < 800) return 1.2;
    if (window.innerHeight < 1000) return 1.5;
    return 2;
  };

  const createThumbnailCanvas = (canvas: fabric.Canvas, cacheKey: string, forceCache: boolean) => {
    //@ts-ignore
    canvas = canvas.lowerCanvasEl;

    const thumbnailCanvas = document.createElement("canvas");
    const context = thumbnailCanvas.getContext("2d");

    if (!canvas || !context) return null;

    context.imageSmoothingEnabled = true;
    context.imageSmoothingQuality = "high";

    thumbnailCanvas.width = canvas.width || 0;
    thumbnailCanvas.height = canvas.height || 0;
    thumbnailCanvas.style.display = "block";

    //@ts-ignore
    context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
    try {
      const imgSrc = thumbnailCanvas.toDataURL("image/jpeg", 0.8);
      return imgSrc;
    } catch (err) {
      console.log("error thumbnail", err);
      return thumbnailCanvas;
    }
  };

  const createImage = (srcBase64: string) => {
    const img = new Image();
    img.src = srcBase64;
    return img;
  };

  const checkObjectVisibilityForTemplate = (canvas: Canvas, obj: any) => {
    if (!obj.animation?.startTime) return true;
    // NOTE: in can be improved to check if overlapping with other objects etc.
    return canvas.getObjects().filter((o: any) => o.type !== "avatar" && !o.bg).length <= TEMPLATE_THUMBMAIL_MAX_OBJECT;
  };

  const showControls = (canvas: Canvas, visibility: boolean, thumbnailMode = THUMBNAIL_MODES.REGULAR) => {
    canvas.getObjects().forEach((obj: any) => {
      obj.forEachControl((control: any) => (control.visible = visibility));
      obj.borderColor = visibility ? "rgba(72, 104, 255, 1)" : "rgba(72, 104, 255, 0)";
      if (visibility && Object.hasOwn(obj, "visiblePrev")) {
        obj.visible = obj.visiblePrev;
        delete obj.visiblePrev;
      } else {
        obj.visiblePrev = obj.visible;
        // if it's a template mode, we should force it to show only the objects at the beginning of the slide
        // otherwise it will show all objects and it looks messy
        obj.visible = visibility || thumbnailMode === THUMBNAIL_MODES.REGULAR ? true : checkObjectVisibilityForTemplate(canvas, obj);
      }
    });
    //@ts-ignore
    canvas.renderAllSafe();
  };

  async function getCanvasThumbnail() {
    let showControlsWithDelay = false;
    if (!canvas) return;

    //@ts-ignore
    if (canvas.activeLoading) {
      //@ts-ignore
      await canvas.activeLoading;
    }

    showControls(canvas, false, THUMBNAIL_MODES.REGULAR);

    const imgSrc = createThumbnailCanvas(canvas, "testing", true);

    /**
     * thumbnails
     * [
     * {
     *  slideId: number,
     *  imgSrc: string
     * }
     * ]
     */
    setThumbnails((prevThumbnails: any) => {
      let updatedThumbnails = [...prevThumbnails];
      //if (!updatedThumbnails[index])
      updatedThumbnails[index] = { slideId: slide.id, imgSrc: imgSrc };
      return updatedThumbnails;
    });
    //if (showControlsWithDelay) await sleep(CONTROLS_VISIBILITY_DELAY_MS);
    showControls(canvas, true, THUMBNAIL_MODES.REGULAR);
  }

  const saveCanvas = useCallback(
    async (canvas: Canvas) => {
      if (!canvas) return;

      console.log("updated canvas Object", canvas.toObject());

      const updatedElaiResponse = structuredClone(elaiResponse);

      updatedElaiResponse.slides[index].canvas = canvas.toObject(PROPS_TO_INCLUDE);

      delete updatedElaiResponse.storyboard_id;
      delete updatedElaiResponse.video_id;
      updatedElaiResponse.data = {};
      console.log("elai video object", elaiResponse);
      console.log("updated elai video object", updatedElaiResponse);

      dispatch(setSaveElaiObject(updatedElaiResponse));

      // const res = await updateElaiVideoData(updatedElaiResponse);

      // message.success("Saved!");
    },
    [canvas]
  );

  return (
    <>
      <div style={{ display: hidden || loading ? "none" : "block" }}>
        <ContextMenu data={slide} index={index} canvas={canvas}>
          <div onContextMenu={(e) => e.preventDefault()}>
            <canvas id={`canvas-${slide.id}`} />
          </div>
        </ContextMenu>
      </div>

      {loading && !hidden && (
        <div
          style={{ border: "2px solid #d9d9d9", height: "360px", width: "640px", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Spin />
        </div>
      )}
    </>
  );
};
