import { MutableRefObject, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import _iDetailExtractRequests from "../../../Store/requests/_iDetailExtractRequests";
import { Spin, Typography } from "antd";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export default function TinyMCE({
  editorRef,
  content,
  hide,
  onEditorChangesSaveCallback,
  onEditorChangesCancelCallback,
  uploadImageToS3
}: {
  editorRef: MutableRefObject<TinyMCEEditor>;
  content: string;
  hide: boolean;
  onEditorChangesSaveCallback: Function;
  onEditorChangesCancelCallback: Function;
  uploadImageToS3: Function;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log("editor loaded!");
  }, []);

  const tinyMCEPlugins =
    "anchor autolink charmap code codesample image link lists searchreplace table wordcount linkchecker preview save fullscreen quickbars pagebreak";

  const tinyMCEPremiumPlugins =
    "casechange export formatpainter pageembed tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate tableofcontents footnotes mergetags autocorrect typography inlinecss markdown";

  const tinyMCEToolbar =
    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | align lineheight | link image table | pagebreak | checklist numlist bullist indent outdent | charmap | removeformat | codesample code | fullscreen preview save print";

  return (
    <div style={{ flex: "1 1 auto", display: hide ? "none" : "flex", flexDirection: "column", justifyContent: " space-around" }}>
      {loading && (
        <div style={{ margin: "auto", display: "flex", flexDirection: "column", gap: "10px" }}>
          <Spin />
          <Text>{t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._TinyMCE.Loading Editor"])}...</Text>
        </div>
      )}
      <div style={{ display: loading ? "none" : "", height: "100%" }}>
        <Editor
          disabled={readOnly}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            setLoading(false);
          }}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            //readonly: readOnly,
            plugins:
              //"anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
              //"anchor autolink charmap codesample image imagetools link lists searchreplace table wordcount casechange export formatpainter pageembed linkchecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate tableofcontents footnotes mergetags autocorrect typography inlinecss markdown preview save print fullscreen quickbars pagebreak",
              tinyMCEPlugins,

            toolbar:
              //"undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              //"undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | align lineheight | link image table | pagebreak | checklist numlist bullist indent outdent | charmap | removeformat | spellcheckdialog | code | fullscreen preview save print",
              tinyMCEToolbar,

            toolbar_mode: "sliding",

            images_upload_handler: function (blobInfo, progress) {
              console.log("Image details:", blobInfo);
              return uploadImageToS3(blobInfo);
            },
            //automatic_uploads: false, //for local uploads
            //imagetools_cors_hosts: ["localhost", "otherdomain.com"],
            //imagetools_proxy: "proxy.php",
            //imagetools_toolbar: "rotateleft rotateright | flipv fliph | imageoptions", //'editimage'
            quickbars_image_toolbar: "alignleft aligncenter alignright | imageoptions", //"alignleft aligncenter alignright | rotateleft rotateright | imageoptions",
            editimage_toolbar: "", //"rotateleft rotateright | flipv fliph | editimage imageoptions",
            images_reuse_filename: true,
            save_onsavecallback: () => {
              console.log("Saved");
              onEditorChangesSaveCallback();
            },
            save_oncancelcallback: () => {
              console.log("Save canceled");
              onEditorChangesCancelCallback();
            },
            resize: false,
            height: "100%",
            skin: "small",
            icons: "small",
            branding: false //hide tiny logo
          }}
          initialValue={content}
        />
      </div>
    </div>
  );
}
