import { Button, Col, Image, Row, Spin ,Typography} from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
const { Paragraph } = Typography;


export interface ZeroFolderBackendProps {
    handleCreateNewFolder:() => void;
  }


export const ZeroFoldersBackend: React.FC<ZeroFolderBackendProps> = ({
  handleCreateNewFolder
}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  console.log(tabList, "tabList");

  return (
<>
          <Col xs={{ span: 24 }} style={{ paddingTop: "10px" }}>
            <Row gutter={[8, 8]} justify="center" align="middle" style={{ paddingTop: "100px" }}>
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <Lottie id="email" animationData={PROJECT_ICONS.CREATE_FOLDER} loop={true} style={{ height: "23vh", width: "23vh" }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <Paragraph
                  className="optimize-font"
                  style={{
                    marginBottom: "0px",
                    font: "normal normal 32px/22px Nunito Sans",
                  }}
                >
                  Welcome Admin!
                </Paragraph>
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <Paragraph
                  className="optimize-font"
                  style={{
                    marginBottom: "0px",
                    font: "normal normal 18px/24px Nunito Sans",
                    paddingTop: "6px",
                    color: "#66676B",
                  }}
                >
                  There are no Files Found
                </Paragraph>
              </Col>

              <Col span={24} className="d-flex justify-content-center align-items-center">
                <Button
                  style={{
                    backgroundColor: "#1e4da1",
                    color: "white",
                    border: "#034EA2",
                    borderRadius: "4px",
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={handleCreateNewFolder}
                ></Button>
              </Col>
            </Row>
          </Col>
        </>
   );
};

