import { UploadFile } from "antd";
import {
  IDX_SET_ACTIVE_SCREEN,
  IDX_SET_ASSET_GUIDE_DATA,
  IDX_SET_ASSET_MAIN_DATA,
  IDX_SET_CLICKED_PAGE_NUMBER,
  IDX_SET_OPEN_CATEGORY_MODAL_STATE,
  IDX_SET_OPEN_HTML_OUTPUT_MODAL,
  IDX_SET_SELECTED_CATEGORIES,
  SET_UPLOADED_PDF_DATA
} from "../constants/_iDetailExtractConstants";
import { AssetMainDataTypes, ListKeyDataTypes, UpdateAssetGuide } from "../../Pages/IDetail Extract/core/modal";

export const setUploadedPdfDataAction = (payload: UploadFile | null) => {
  return { type: SET_UPLOADED_PDF_DATA, payload: payload };
};

export const setSelectedCategoriesIDXAction = (payload: ListKeyDataTypes[]) => {
  return { type: IDX_SET_SELECTED_CATEGORIES, payload: payload };
};

export const setOpenCategoryModalStateIDXAction = (payload: boolean) => {
  return { type: IDX_SET_OPEN_CATEGORY_MODAL_STATE, payload: payload };
};

export const setAssetGuideDataIDXAction = (payload: UpdateAssetGuide | null) => {
  return { type: IDX_SET_ASSET_GUIDE_DATA, payload: payload };
};

export const setActiveScreenIDXAction = (payload: number) => {
  return { type: IDX_SET_ACTIVE_SCREEN, payload: payload };
};

export const setClickedPageNumberIDXAction = (payload: number) => {
  return { type: IDX_SET_CLICKED_PAGE_NUMBER, payload: payload };
};

export const setAssetMainDataIDXAction = (payload: AssetMainDataTypes) => {
  return { type: IDX_SET_ASSET_MAIN_DATA, payload: payload };
};

export const setOpenHtmlOutputModalIDXAction = (payload: boolean) => {
  return { type: IDX_SET_OPEN_HTML_OUTPUT_MODAL, payload: payload };
};
