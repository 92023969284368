import { FC, useMemo ,useState} from "react";
import { Avatar, Button, Card, Col, Row, Typography ,Space,Checkbox,Input,Image,Tooltip, Tag, Spin,Drawer} from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, FolderOutlined,LeftOutlined,RightOutlined,SearchOutlined } from "@ant-design/icons";
import { setActionDrawerDataAction } from "../../../../Components/ActionDrawer/State";
import  { PROJECT_ICONS }  from "../../../../Assets/Img/_DIcons";
import { useEffect,useRef } from "react";
import { setActiveModuleActions, setAdvancedFilter, setAdvancedFilterStatus, setCardDataCollection, setConversationId, setEncodedSemanticSearchId, setFilterListAction, setFormat, setLandingScreen, setLLMModel, setMessageIId, setSearchQuery, setSemanticSearchId, setSemanticSearchImageId } from "../../../../Store/actions/_semanticSearchActions";
import { useDispatch, useSelector } from "react-redux";
import { PostSemanticChat, PostSemanticChatSession, getBilling, getChats, getChatsHistory, getChatsSpecific, getQuestionRecommendation, getSemanticSearch } from "../../../../Store/requests/_semanticSearchRequests";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { SEMANTIC_SEARCH_MODULES } from "../Core/constants";
import { getInsightsHistory } from "../../../../Store/requests/_semanticSearchRequests";
import { EventSourcePolyfill } from "event-source-polyfill";
import _localStorageService from "../../../../Services/_localStorageService";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { kMaxLength } from "buffer";
import './_Usage.css';
import Markdown from "markdown-to-jsx";
import { AnyAaaaRecord } from "dns";

const { Paragraph } = Typography;
interface SSEData {
    id: any;
    created_at: string;
    question: string;
    answer: string;
    interested: any | null;
    page_number: number | null;
    k: number;
    score_threshold: number;
    status: string;
  }
interface ComponentAProps {
    columns:string[];
    setColumns:React.Dispatch<React.SetStateAction<string[]>>;
    internalVarA: number;
    setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
    internalVarB: string;
    setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
    stepperForinternalVarB:number;
     setStepperForInternalVarb:React.Dispatch<React.SetStateAction<number>>;
  }
export const UserHistory: React.FC<ComponentAProps> = ({ setStepperForInternalVarb,stepperForinternalVarB,internalVarA, setInternalVarA ,internalVarB,setInternalVarB,columns,setColumns}) => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const {
        semanticEncodedSearchid,
        searchQuery, 
        encodedSearchQuery,
        advancedFilter,
        refetchList,
        advancedFilterList,
        advancedFilterStatus,
        cardData,
        semanticSearchid,
        cardDataconst, Format,kvalue ,activeModule,regeneratestatus
       
      
      } = useSelector((state: any) => state?.semanticSearchReducer);
      const [arrayofinsights,setarrayofinsights] =useState([]);
      const [totalcountInsight,setTotalCountInsight]=useState(0);
      const [totalcountChat,setTotalCountChat]=useState(0);
      const [isloadingpage,setIsloadingpage]=useState<boolean>(false);
      const [arrayofchats,setarrayofchats] =useState([]);
      const [drawerVisible, setDrawerVisible] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [billing,setBilling]=useState<any[]>([])
  const apiurl=process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const [userInputForSearch, setUserInputForSearch] = useState("");
  const [id, setId] = useState("");
  const [msgid, setMsgId] = useState("");
  const [sseData, setSSEData] = useState<SSEData[]>([]);
  const [filename, setFileName] = useState<string>("");
  const [currentid, setCurrentid] = useState<any>(0);
  const [disable2, setInputDisabled2] = useState(false);
  const [s3url, sets3url] = useState<String>("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const buttonRef1 = useRef<HTMLButtonElement | null>(null);
  const buttonRef2 = useRef<HTMLButtonElement | null>(null);

  const scrollableDivRef = useRef<HTMLDivElement>(null); // Ref for the scrollable div
  const [downloadinprogress, setDownloadinProgress] = useState<Boolean>(false);
  const [disable, setInputDisabled] = useState(false);
  const [disable1, setInputDisabled1] = useState(false);
  const [isopenforFolderTime, setisopenforFolderTime] = useState<boolean>(false);
  const [downloadinprogressfolder, setDownloadProgressFolder] = useState<Boolean>(false);
  const [previouschats,setpreviouschats]=useState<any[]>([]);
const[isloadingpreviouschats,setisloadingpreviouschats]=useState<boolean>(false);
const [recommendQuestion,setRecommendQuestion]=useState<any[]>([]);  
const [initialpagenumberforinsight,setinitialPageNumberforInsight]=useState(1);
const [initialpagenumberforChat,setinitialPageNumberforChat]=useState(1);
const [userInputForInsight,setUserInputForInsight]=useState("")
const [userInputForChat,setUserInputForChat]=useState("")

const getchatsfunctioninitial = async()=>{
  setisloadingpreviouschats(true);
  const res= await getChats({filename:filename})
  if(!res){
    setpreviouschats([]) 
    console.log("It is working opening")
   setisloadingpreviouschats(false);
   return;
  } 
  console.log(res)
  setpreviouschats(res?.data)
  console.log("It is working opening")
 setisloadingpreviouschats(false);
 
}
useEffect(() => {
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission, if using within a form
      if (buttonRef.current) {
        buttonRef.current.click(); // Trigger button click event
      }
    }
  };

  document.addEventListener("keydown", handleKeyPress);

  return () => {
    document.removeEventListener("keydown", handleKeyPress);
  };
}, []);
useEffect (()=>{
    if(drawerVisible)
    { console.log("It is working opening")}
     },[drawerVisible])
     useEffect(() => {
       const handleKeyPress = (event: any) => {
         if (event.key === "Enter") {
           event.preventDefault(); // Prevent form submission, if using within a form
           if (buttonRef1.current) {
             buttonRef1.current.click(); // Trigger button click event
           }
         }
       };
   
       document.addEventListener("keydown", handleKeyPress);
   
       return () => {
         document.removeEventListener("keydown", handleKeyPress);
       };
     }, []);
     useEffect(() => {
      const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission, if using within a form
          if (buttonRef2.current) {
            buttonRef2.current.click(); // Trigger button click event
          }
        }
      };
  
      document.addEventListener("keydown", handleKeyPress);
  
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, []);
     useEffect (()=>{
    
       const getchatsfunction = async()=>{
       setisloadingpreviouschats(true);
       const res= await getChats({filename:filename})
       if(!res){
         setpreviouschats([])
         console.log("It is working opening")
        setisloadingpreviouschats(false);
        return;
       } 
       const resp= await getQuestionRecommendation({filename:filename})
       if(!resp){
         setpreviouschats([])
         console.log("It is working opening")
        setisloadingpreviouschats(false);
        return;
       }
       console.log(res)
       console.log(resp)
       setRecommendQuestion(resp?.data)
       setpreviouschats(res?.data)
       console.log("It is working opening")
      setisloadingpreviouschats(false);
      
     }
   if(drawerVisible){
     getchatsfunction()
   }
   
      },[drawerVisible])
     const handleUserInput = (event: any) => {
       setUserInput(event.target.value);
       setUserInputForSearch(event.target.value);
     };
     const handleUserInputForInsight = (event: any) => {
      setUserInputForInsight(event.target.value);
      setinitialPageNumberforInsight(1)
      if(event.target.value===""){
        setUserInputForInsight("")
        setinitialPageNumberforInsight(1)
       getInsightHistoryKeyword("")
      }
    };
    const handleUserInputForChat = (event: any) => {
   
      setUserInputForChat(event.target.value);
      setinitialPageNumberforChat(1)
      if(event.target.value===""){
        setUserInputForChat("")

        setinitialPageNumberforChat(1);
       getChatHistoryKeyword("")
      }
    };
     const showDrawer = async(s: string,chat_id:any) => {
        const res = await getChatsSpecific({filename:s,chat_id:chat_id})
     setInputDisabled(false)
     setId(chat_id)
     setSSEData(res?.data[0]?.messages)
     console.log(res?.data[0]?.messgages + "iclicked here")
     console.log(sseData + "iclicked here")
       setFileName(s);
       setDrawerVisible(true);
     };
   
     const closeDrawer = () => {
       setDrawerVisible(false);
     };
   
     const openS3LinkInNewTab = (link: any) => {
       window.open(link, "_blank");
     };
   
     
     const handleDownload = () => {
       setDownloadinProgress(true);
       const accessToken = token || ""; // Provide a default value if it's null
       console.log(accessToken);
       const headers: Record<string, string> = {
         Authorization: accessToken,
       };
       const eventSource = new EventSourcePolyfill(`${apiurl}/download-insight/${semanticSearchid}`, {
         headers,
       });
       // eventSource.addEventListener("pending", (event) => {
       //   console.log("pending", event);
       //   // const newElement = document.createElement("li");
       //   // const eventList = document.getElementById("list");
       //   // const time = JSON.parse(event.data).time;
       //   // newElement.textContent = `ping at ${time}`;
       //   //eventList.appendChild(newElement);
       // });
       eventSource.onmessage = (event:any) => {
         // const newResult = JSON.parse(event.data); // Assuming data is JSON
         // setSSEData((prevData) => [...prevData, { input: userInput, response: newResult }]);
         console.log("data", event);
       };
   
       eventSource.addEventListener("end", (event:any) => {
         console.log("end", event);
         const messageEvent = event as MessageEvent;
         console.log("end", event);
         const newResult = messageEvent?.data;
         sets3url(newResult);
         downloadFile(newResult, newResult);
         setDownloadinProgress(false);
         dispatch(setNotificationMsgAction("Files Downloaded"));
         eventSource.close();
       });
       eventSource.addEventListener("error", (event:any) => {
         setDownloadinProgress(false);
         dispatch(setNotificationMsgAction("Connection Error"));
         console.log("error", event);
         eventSource.close();
       });
       // eventSource.onerror = (error) => {
       //   console.error("SSE Error for download:", error);
       //   // eventSource.close();
       //   // eventSource.close();
       //   // Enable input on SSE error
       // };
   
       // Close the SSE connection when component unmounts or ID changes
     };
     const handleDownloadForFolder = (s: string) => {
       setDownloadProgressFolder(true);
       const accessToken = token || ""; // Provide a default value if it's null
       console.log(accessToken);
       const headers: Record<string, string> = {
         Authorization: accessToken,
       };
       const eventSource = new EventSourcePolyfill(`${apiurl}/document/chat/download?chat_id=${id}&time=${s}`, {
         headers,
       });
       // eventSource.addEventListener("pending", (event) => {
       //   console.log("pending", event);
       //   // const newElement = document.createElement("li");
       //   // const eventList = document.getElementById("list");
       //   // const time = JSON.parse(event.data).time;
       //   // newElement.textContent = `ping at ${time}`;
       //   //eventList.appendChild(newElement);
       // });
       eventSource.onmessage = (event:any) => {
         // const newResult = JSON.parse(event.data); // Assuming data is JSON
         // setSSEData((prevData) => [...prevData, { input: userInput, response: newResult }]);
         console.log("data", event);
       };
   
       eventSource.addEventListener("end", (event:any) => {
         console.log("end", event);
         const messageEvent = event as MessageEvent;
         console.log("end", event);
         const newResult = messageEvent?.data;
         sets3url(newResult);
         downloadFile(newResult, newResult);
         setDownloadProgressFolder(false);
         dispatch(setNotificationMsgAction("Chat Downloaded"));
         eventSource.close();
       });
       eventSource.addEventListener("error", (event:any) => {
         eventSource.close();
         setDownloadProgressFolder(false);
         dispatch(setNotificationMsgAction("Connection error or no chat initiated for download"));
         console.log("error", event);
       });
       // eventSource.onerror = (error) => {
       //   console.error("SSE Error for download:", error);
       //   // eventSource.close();
       //   // eventSource.close();
       //   // Enable input on SSE error
       // };
       return () => {
         console.log("Fired Closed");
         setDownloadProgressFolder(false);
         eventSource.close(); // Close the SSE connection when component unmounts or ID changes
       };
   
       // Close the SSE connection when component unmounts or ID changes
     };
     const downloadFile = async (fileUrl: string, fileName: string) => {
       try {
         const response = await fetch(fileUrl);
         const blob = await response.blob();
   
         // Create a link element
         const link = document.createElement("a");
         link.href = window.URL.createObjectURL(blob);
         link.setAttribute("download", fileName || "file"); // Set the filename
   
         // Trigger the download
         link.click();
   
         // Clean up
         window.URL.revokeObjectURL(link.href);
         link.remove();
       } catch (error) {
         console.error("Error downloading file:", error);
       }
     };
   
     
     // Function to handle the input change and trigger search after a pause
  
  
     // interface MarkdownTableProps {
     //     markdownString: string;
     //   }
     //   const tableStyle = {
     //     width: '100%',
     //     borderCollapse: 'collapse',
     //     marginBottom: '16px',
     //     borderRadius: '20px',
     //   };
   
     //   const thTdStyle = {
   
     //     paddingLeft: '18px',
     //     padding:'18px',
     //     textAlign: 'left',
     //     fontSize:'14px',
     //     color:'black'
     //   };
   
     //   const thStyle = {
     //     backgroundColor: '#E1E9F3',
     //   };
     // const tableStyle = {
     //   margin: '20px',
     //   border: '1px solid #ccc',
     //   borderRadius: '5px',
     //   overflow: 'hidden',
     // };
   
     // const thTdStyle = {
     //   padding: '10px',
     //   borderBottom: '1px solid #ccc',
     //   textAlign: 'left',
     // };
   
     // const thStyle = {
     //   backgroundColor: '#f2f2f2',
     //   fontWeight: 'bold',
     // };
   
     // const MarkdownTable: React.FC<MarkdownTableProps> = ({ markdownString }) => {
     //     return (
   
     //         <Markdown
     //         options={{
     //           overrides: {
     //             table: {
     //               component: 'table',
     //               props: { style: tableStyle },
     //             },
     //             th: {
     //               component: 'th',
     //               props: { style: { ...thTdStyle, ...thStyle } },
     //             },
     //             td: {
     //               component: 'td',
     //               props: { style: thTdStyle },
     //             },
     //           },
     //         }}
     //       >
     //         {markdownString}
     //       </Markdown>
   
     //     );
   
     //   };
   
     const downloadFromS3 = (s3Url: string) => {
       const link = document.createElement("a");
       link.href = s3Url;
       link.setAttribute("download", ""); // This attribute triggers a download when clicked
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
     };
   
     const ResetEverything = () => {
       setUserInput("");
       setUserInputForSearch("");
        setisopenforFolderTime(false);
        setDownloadProgressFolder(false)
       setCurrentid("")
       setId("");
       setSSEData([]);
       setMsgId("")
       setFileName("");
       setInputDisabled(false);
       setDrawerVisible(false);
     };
     const handlePostRequest = async () => {
       if(userInput){
         if(id){
       
       setInputDisabled(true);
       const res = await PostSemanticChatSession({ filename: filename, question: userInput,chat_id:id });
       if (!res){setInputDisabled(false); return;}
       setId(res?.data?.chat_id);
       setMsgId(res?.data?.message_id);
       setCurrentid(res?.data?.chat_id)
       if (scrollableDivRef.current) {
         scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
       }
       console.log(res?.chat_id, "chatid");
      
       console.log(res);
       setUserInputForSearch("");}
     else{
       setInputDisabled(true);
       const res = await PostSemanticChat({ filename: filename, question: userInput });
       if (!res){setInputDisabled(false); return;}
       setId(res?.data?.chat_id);
       setMsgId(res?.data?.message_id);
       setCurrentid(res?.data?.chat_id)
       if (scrollableDivRef.current) {
         scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
       }
       console.log(res?.chat_id, "chatid");
   
       console.log(res);
       setUserInputForSearch("")
       getchatsfunctioninitial()
     }
     }
   
     };
   const handlePostRequestDirect = async (s: string) => {
     if(id){
     setInputDisabled(true);
    
     const res = await PostSemanticChatSession({ filename: filename, question: s ,chat_id:id});
     if (!res){setInputDisabled(false); return;}
     setUserInput(s);
     setCurrentid(res?.data?.chat_id)
     setId(res?.data?.chat_id);
       setMsgId(res?.data?.message_id);
     if (scrollableDivRef.current) {
       scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
     }
     console.log(res?.chat_id, "chatid");
     
     console.log(res);
     setUserInputForSearch("");}
     else{
       setInputDisabled(true);
       const res = await PostSemanticChat({ filename: filename, question: s });
       if (!res){setInputDisabled(false); return;}
       setUserInput(s);
       setCurrentid(res?.data?.chat_id)
       setId(res?.data?.chat_id);
       setMsgId(res?.data?.message_id);
       if (scrollableDivRef.current) {
         scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
       }
       console.log(res?.chat_id, "chatid");
      
       console.log(res);
       setUserInputForSearch("");
       getchatsfunctioninitial()
   
     }
   };
   console.log(arrayofchats)
   useEffect(() => {
     console.log("m on door");
     if (id) {
       if (userInput.trim() !== "") {
         if (scrollableDivRef.current) {
           scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
         }
         setSSEData((prevData) => [...prevData, {   id: msgid,
           question: userInput,
           answer: "Waiting for response...",
           created_at: "", // Set to empty string
           interested: null, // Set to null
           page_number: null, // Set to null
           k: 0, // Set to zero
           score_threshold: 0, // Set to zero
           status: ""}]);
   
       }
   
       const accessToken = token || ""; // Provide a default value if it's null
       console.log(accessToken);
       const headers: Record<string, string> = {
         Authorization: accessToken,
       };
   
       const eventSource = new EventSourcePolyfill(`${apiurl}/document/chat/${msgid}`, {
         headers,
       });
   
       eventSource.onmessage = (event:any) => {
         // const newResult = JSON.parse(event.data); // Assuming data is JSON
         // setSSEData((prevData) => [...prevData, { input: userInput, response: newResult }]);
         console.log("data", event);
       };
   
       // eventSource.addEventListener("pending", (event) => {
       //   console.log("pending", event);
       //   // const newElement = document.createElement("li");
       //   // const eventList = document.getElementById("list");
       //   // const time = JSON.parse(event.data).time;
       //   // newElement.textContent = `ping at ${time}`;
       //   //eventList.appendChild(newElement);
       // });
       eventSource.addEventListener("end", (event:any) => {
         if (scrollableDivRef.current) {
           scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
         }
         console.log("end", event);
         const messageEvent = event as MessageEvent;
         console.log("end", event);
         const newResult = messageEvent?.data; // Assuming data is JSON
         setSSEData((prevData) => {
           // Update the specific entry matching the userInput
           console.log(currentid + "This is the one to be matched")
           const updatedData = prevData.map((data) => (data.id === msgid ? { ...data, answer: newResult } : data));
   
           return updatedData;
         });
         eventSource.close();
         setInputDisabled(false);
         setUserInput("")
         // const newElement = document.createElement("li");
         // const eventList = document.getElementById("list");
         // const time = JSON.parse(event.data).time;
         // newElement.textContent = `ping at ${time}`;
         //eventList.appendChild(newElement);
       });
   
       eventSource.onerror = (error:any) => {
         console.error("SSE Error:", error);
         dispatch(setNotificationMsgAction("Connection Error"))
          eventSource.close();
          setInputDisabled(false);
          setUserInput("")
         // Enable input on SSE error
       };
   
       return () => {
         console.log("Fired Closed");
         eventSource.close(); 
       };
     }
   }, [msgid]);
   const handleCallChatSession = async(id:any)=>{
     const res = await getChatsSpecific({filename:filename,chat_id:id})
     setInputDisabled(false)
     setId(id)
     setSSEData(res?.data[0]?.messages)
     console.log(res?.data[0]?.messgages + "iclicked here")
     console.log(sseData + "iclicked here")
   }
 
   
    
    const prefix = (
        <SearchOutlined
          style={{ 
            fontSize: 16,
            color: "#1677ff"
          }}
        />
      );
      const getInsightHistoryKeyword = async (customKeyword?: string) => {
        setInputDisabled1(true);
      
        const keywordToUse = customKeyword === "" ? customKeyword : userInputForInsight;
      
        const res = await getInsightsHistory({ page: initialpagenumberforinsight, keyword: keywordToUse });
      
        if (!res) {
          setarrayofinsights([]);
          setInputDisabled1(false);
          setTotalCountInsight(0);
          return;
        }
        setTotalCountInsight(res?.total_count);
        setarrayofinsights(res?.data);
        setInputDisabled1(false);
    };
    
      
    const getChatHistoryKeyword = async (customKeyword?: string) => {
      setInputDisabled2(true);
      
      const keywordToUse = customKeyword === "" ?  customKeyword:userInputForChat;
      
      const resp = await getChatsHistory({ page: initialpagenumberforChat, keyword: keywordToUse });
      console.log(resp);
      
      if (!resp) {
          setarrayofchats([]);
          setInputDisabled2(false);
          setTotalCountChat(0);
          return;
      }
      setTotalCountChat(resp?.total_count)
      setarrayofchats(resp?.data);
      setInputDisabled2(false);
  };
  
      useEffect(() => {
         
        const getInsightHistory = async () => {
            const res = await getInsightsHistory({page:1,keyword:''})
          
            
            const resp = await getChatsHistory({page:1,keyword:''})
            const resp2 = await getBilling()
            
          
            console.log(resp);
            if(!res || !resp || !resp2){
              getInsightHistory1()
              return;
          }
          
          setTotalCountInsight(res?.total_count);
          setTotalCountChat(resp?.total_count);
            setBilling(resp2?.data)
            setarrayofinsights(res?.data)
            setarrayofchats(resp?.data)
            setIsloadingpage(false) 
        }  
        const getInsightHistory1 = async () => {
          const res = await getInsightsHistory({page:1,keyword:''})
          if(!res){
              setarrayofinsights([])
              setIsloadingpage(false)
              setTotalCountInsight(0);
             
              return;
          }
          
          const resp = await getChatsHistory({page:1,keyword:''})
          console.log(resp);
          if(!resp){
            setarrayofchats([])
            setIsloadingpage(false)
            setTotalCountChat(0);
            return;
        }
        const resp2 = await getBilling()
        if(!resp2){
          setBilling([])
          setIsloadingpage(false)
          return;
      }
        
      setTotalCountInsight(res?.total_count);
      setTotalCountChat(resp?.total_count);
          setarrayofinsights(res?.data)
          setarrayofchats(resp?.data)
          setBilling(resp2?.data)
          setIsloadingpage(false)
      }  
        console.log("calls insights history")
        if(activeModule===SEMANTIC_SEARCH_MODULES.USER_HISTORY)
        
      { setIsloadingpage(true)
    getInsightHistory()
    // getInsightHistory1()
   
    }
      }, [activeModule]);
      const incrementChat = async()=>{
      
        setInputDisabled2(true)
        const resp = await getChatsHistory({page:initialpagenumberforChat+1,keyword:userInputForChat})
        console.log(resp);
        if(!resp){
          setinitialPageNumberforChat(initialpagenumberforChat+1)
          setarrayofchats([])
          setInputDisabled2(false)
          setTotalCountChat(0);
          return;
        }
        setTotalCountChat(resp?.total_count);
        setinitialPageNumberforChat(initialpagenumberforChat+1)
        setarrayofchats(resp.data)
        setInputDisabled2(false)
      }
      const incrementInsight = async()=>{
        setInputDisabled1(true)
        const res = await getInsightsHistory({page:initialpagenumberforinsight+1,keyword:userInputForInsight})
        console.log(res);
        if(!res){
          setinitialPageNumberforInsight(initialpagenumberforinsight+1)
          setarrayofinsights([])
          setInputDisabled1(false)
          setTotalCountInsight(0);
          return;
        }
        setTotalCountInsight(res?.total_count);
       setinitialPageNumberforInsight(initialpagenumberforinsight+1)
        setarrayofinsights(res?.data)
        setInputDisabled1(false)
      }
      const decrementChat = async()=>{
        if(initialpagenumberforChat!==1)
        
       {
        setInputDisabled2(true) 
        const resp = await getChatsHistory({page:initialpagenumberforChat-1,keyword:userInputForChat})
        console.log(resp);
        if(!resp){
          setinitialPageNumberforChat(initialpagenumberforChat-1)
          setarrayofchats([])
          setInputDisabled2(false)
          setTotalCountChat(0);
          return;
        }
        setTotalCountChat(resp?.total_count);
        setinitialPageNumberforChat(initialpagenumberforChat-1)
        setarrayofchats(resp.data)
        setInputDisabled2(false)
      }
        
      }
      const decrementInsight = async()=>{
        if(initialpagenumberforinsight!==1)
       { setInputDisabled1(true) 
        const res = await getInsightsHistory({page:initialpagenumberforinsight-1,keyword:userInputForInsight})
        console.log(res);
        if(!res){
          setinitialPageNumberforInsight(initialpagenumberforinsight-1)
          setarrayofinsights([])
          setInputDisabled1(false)
          setTotalCountInsight(0);
          return;
        }
        setinitialPageNumberforInsight(initialpagenumberforinsight-1)
        setTotalCountInsight(res?.total_count);
        setarrayofinsights(res?.data)
      setInputDisabled1(false)
      }

      }

      const suffix = (
        <span style={{ color: "#66676B", fontSize: "10px" }}>SEARCH</span>
      );
      const handlesearchAPI = async(id:number,prompt:string,filter:any[],k:number,format:any,columns:any[],message:any,previous_message:any,next_message:any,conversation_id:any,llm_model:any)=>{
           dispatch(setLandingScreen(false))
           dispatch(setSearchQuery(prompt))
           if(filter.length!==0){
            dispatch(setAdvancedFilterStatus(true))
           
           }
           else{
            dispatch(setAdvancedFilterStatus(false))
           }
           setInternalVarA(k)
           if(format==="table"){
           setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.tablesmall"]))
           setStepperForInternalVarb(3)
           dispatch(setFormat(3));
           }
           else if(format==='text'){setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]))
           setStepperForInternalVarb(1);
           dispatch(setFormat(1));
           }
           else{
            setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.listsmall"]))
            dispatch(setFormat(2));
            setStepperForInternalVarb(2);

           }
           setColumns(columns)
           if(previous_message || next_message)
            {dispatch(setCardDataCollection(["d","dj"]))}
            dispatch(setConversationId(conversation_id));
            
            dispatch(setMessageIId(message))
        //  dispatch(setSemanticSearchImageId(0));
            dispatch(setLLMModel(llm_model))
            dispatch(setActiveModuleActions(SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH))
           
           
 };
      
    return (   
    <>
    {isloadingpage ? (<div className="spinner-container"><Spin></Spin></div>):(<>
      <Col span={24}>
        <Card
          style={{ 
            width: "100%",
            boxShadow: "0px 0px 10px #0000001F",
            minHeight: "550px",
            opacity: "1",
          }}
        > 
            <div style={{background:'#F3F6FA',padding:'10px',borderRadius:'4px'}}>
             <Row gutter={24} style={{}}>
          <Col span={9} style={{}}>
          <div style={{fontWeight:'600',fontSize:'16px',paddingLeft:'5px',color:'#66676B'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.apiconsumption"])}</div>
           <Row style={{padding:'10px'}}>
            <Col span={5} style={{paddingLeft:'15px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.api"])}</Col>
            <Col  span={5} style={{paddingLeft:'15px' ,fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.units"])}</Col>
            <Col span={5} style={{paddingLeft:'15px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.unitprice"])}</Col>
            <Col span={9} style={{paddingLeft:'15px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.cost"])}</Col>
           </Row>
           <div style={{background:'white',margin:'12px',borderRadius:'10px'}}>
           <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.chat"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
      
            { billing[0] ? billing[0].object.length>0 ? billing[0].object.map((item: any) => (
 <Row style={{  }}>
 <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.model:0}</Col>
 <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_tokens:0}</Col>
 <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.unit_price:0}</Col>
 <Col span={9} style={{ paddingLeft: '5px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_cost:0}</Col>
</Row>
  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
          
            </div>
           </div>
           <div style={{background:'white',margin:'12px',borderRadius:'10px'}}>
           <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.floatingsidebar.search"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
            { billing[1] ? billing[1].object.length>0 ? billing[1].object.map((item: any) => (
 <Row style={{  }}>
 <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.model:0}</Col>
 <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_tokens:0}</Col>
 <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.unit_price:0}</Col>
 <Col span={9} style={{ paddingLeft: '5px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_cost:0}</Col>
</Row>
  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
        
            </div>
           </div>
           <div style={{background:'white',margin:'12px',borderRadius:'10px'}}>
            <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.embeddings"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
          
         
            { billing[2] ? billing[2].object.length>0 ? billing[2].object.map((item: any) => (
     <Row style={{  }}>
     <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.model:0}</Col>
     <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_tokens:0}</Col>
     <Col span={5} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.unit_price:0}</Col>
     <Col span={9} style={{ paddingLeft: '5px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_cost:0}</Col>
   </Row>
  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>) 
}
            
           
            </div>
           </div>
          </Col>
          <Col span={9}>
          <div style={{fontWeight:'600',fontSize:'16px',color:'#66676B'}}>{t(I18N_MODULE_KEYS["semantic.search.userhistory.insightssearched"])} ({totalcountInsight})</div>
           <Row style={{marginTop:'10px'}}>
            <Input
              prefix={prefix}
              placeholder={t(I18N_MODULE_KEYS["semantic.search.floatingsidebar.search"])}
              style={{fontSize:'12px'}}
              suffix={
                <button
                style={{ border: "none",background:disable1?'#e5e4e2':'white',cursor:'pointer' }}
                                  ref={buttonRef1}
                  disabled={disable}
                  onClick={
                    () => {
                      getInsightHistoryKeyword();
                    }
                    // savePrompt(searchTerm && searchQuery ? false : true)
                  }
                 
                  // disabled={isLoading}
                >
                  <span style={{  fontSize: "8px",color: "#66676B" }}>{t(I18N_MODULE_KEYS["semantic.search.input.heading.search"])}</span>
                </button>
              }
              value={userInputForInsight}
                  onChange={handleUserInputForInsight}
              disabled={disable1}
            />
         </Row>
         <div style={{ paddingTop: '10px', height: '500px', overflow: 'auto' }}>
  {arrayofinsights && arrayofinsights.length > 0 ? (
    arrayofinsights.map((item:any) => (
      <Row
        style={{
          background: 'white',
          marginTop: '10px',
          borderRadius: '10px',
          padding: '10px',
          color: '#034EA2',
          fontWeight: '600',
          cursor: 'pointer'
        }}
        onClick={() => {
          handlesearchAPI(
            item?.id,
            item?.prompt,
            item?.filters,
            item?.k,
            item?.output_format,
            item?.column_names,
            item?.message,
            item?.next_message,
            item?.previous_message,
            item?.conversation_id,
            item?.model
          );
        }}
      >
        {item.prompt}
      </Row>
    ))
  ) : (
    <Row style={{ background: 'white',
    cursor: 'pointer',
    marginTop: '10px',
    borderRadius: '10px',
    padding: '10px',
    color: '#034EA2',
    fontWeight: '600' }}>
      {t(I18N_MODULE_KEYS["semantic.search.userdetails.noinsightsfound"])}
    </Row>
  )}
</div>

        <div style={{display:'flex',justifyContent:'flex-end',paddingTop:'10px'}}>
          <LeftOutlined style={{padding:'5px',color:'#66676B',cursor:'pointer'}} onClick={decrementInsight}/>
          <RightOutlined style={{padding:'5px',color:'#66676B',cursor:'pointer'}} onClick={incrementInsight}></RightOutlined>
        </div>
    
            
          </Col>
          <Col span={6}>
          <div style={{fontWeight:'600',fontSize:'16px',paddingLeft:'5px',color:'#66676B'}}>{t(I18N_MODULE_KEYS["semantic.search.userhistory.chats"])} ({totalcountChat})</div>
           <Row style={{marginTop:'10px'}}>
            <Input
              prefix={prefix}
              suffix={
                <button
                  style={{ border: "none",background:disable2?'#F4F4F4':'white',cursor:'pointer' }}
                  ref={buttonRef2}
                  disabled={disable}
                  
                  onClick={
                    () => {
                      getChatHistoryKeyword();

                    }
                    // savePrompt(searchTerm && searchQuery ? false : true)
                  }
                
                  // disabled={isLoading}
                >
                  
                  <span style={{ color: "#66676B", fontSize: "8px" }}>{t(I18N_MODULE_KEYS["semantic.search.input.heading.search"])}</span>
                </button>
              }
              value={userInputForChat}
              onChange={handleUserInputForChat}
              disabled={disable2}
              placeholder={t(I18N_MODULE_KEYS["semantic.search.floatingsidebar.search"])}
              style={{fontSize:'12px'}}
            />
         </Row>
         <div style={{ paddingTop: '10px', height: '500px', overflow: 'auto' }}>
  {arrayofchats && arrayofchats.length > 0 ? (
    arrayofchats.map((item:any) => (
      <Row
        onClick={() => showDrawer(item.filename, item.chat_id)}
        style={{
          background: 'white',
          cursor: 'pointer',
          marginTop: '10px',
          borderRadius: '10px',
          padding: '10px',
          color: '#034EA2',
          fontWeight: '600'
        }}
      >
        {item.question}
      </Row>
    ))
  ) : (
    <Row style={{ background: 'white',
    cursor: 'pointer',
    marginTop: '10px',
    borderRadius: '10px',
    padding: '10px',
    color: '#034EA2',
    fontWeight: '600' }}>
      {t(I18N_MODULE_KEYS["semantic.search.userdetails.nochatsfound"])}
    </Row>
  )}
</div>

         <div style={{display:'flex',justifyContent:'flex-end',paddingTop:'10px'}}>
          <LeftOutlined style={{padding:'5px',color:'#66676B',cursor:'pointer'}} onClick={decrementChat}/>
          <RightOutlined style={{padding:'5px',color:'#66676B',cursor:'pointer'}} onClick={incrementChat}></RightOutlined>
        </div>
           
          </Col>
        </Row></div>

          {/* Your content goes here */}
         
          
        </Card>
      </Col>
        <Drawer
        placement="right"
        width={1300}
        visible={drawerVisible}
        closable={false} // Hides the default close button
        mask={false}
        style={{ background: "#EEF4FF", padding: "8px" }}
      >
        <div style={{display:'flex',gap:'20px'}}>
        <div style={{ height: "650px", background: "white", borderRadius: "20px" ,minWidth:'30%',overflow:'auto'}}> 
        <div style={{ display: "flex", justifyContent: "space-between", padding: "32px", borderBottom: "1px solid #BABABA" }}>
                <div style={{ fontSize: "16px", display: "flex" }}>
                  <p style={{ fontWeight: "700" }}>{t(I18N_MODULE_KEYS["chatdocument.search.chathistory"])}</p>
               
                </div>
                <div style={{color:'#034EA2'}}>{t(I18N_MODULE_KEYS["chatdocument.search.clearall"])}</div>
              
          
            </div>
            <div></div>
            {isloadingpreviouschats ? (<><Spin></Spin></>):(<>
              {previouschats?.length > 0 ? (

        previouschats?.map(item => (
          <div key={item.id} style={{ display: "flex", justifyContent: "space-between", padding: "10px",cursor:'pointer'}} onClick={()=>{handleCallChatSession(item.chat_id)}}>
            <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
              <img style={{ fontSize: "16px", height: "30px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE} alt="Message Icon" />
              <div>
                <div style={{  fontSize: "16px", display: "flex" }}>
                  <Tooltip title={item.title}>
                    <p style={{ marginLeft: "2px", fontWeight: "bold" }}>{item?.title?.slice(0, 35)}...</p>
                  </Tooltip>
                </div>
                <div style={{ display: "flex" }}>
                  <img style={{ paddingTop: "5px", height: "18px" }} src={PROJECT_ICONS.UNION} alt="Union Icon" />
                  <p style={{ color: "#66676B", paddingTop: "2px", paddingLeft: "5px" }}>7 {t(I18N_MODULE_KEYS["chatdocument.search.pages"])}</p>
                </div>
              </div>
            </div>
            <div style={{padding: "12px",fontSize:'12px'}}>{item?.days_since_last_use}d</div>
          </div>
        ))
      ) : (
        <div  style={{ display: "flex", padding: "20px" , fontSize:'16px',fontWeight:'600'}}>{t(I18N_MODULE_KEYS["chatdocument.search.notfound"])}</div>
      )}</>)}
        </div>
        <div style={{ height: "650px", background: "white", borderRadius: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", borderBottom: "1px solid #BABABA" }}>
            <div style={{ padding: "10px", display: "flex", gap: "30px" }}>
              <img style={{ fontSize: "16px", height: "30px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
              <div>
                <div style={{ width: "430px", fontSize: "16px", display: "flex" }}>
                  <p style={{ color: "#66676B", fontWeight: "500" }}>{t(I18N_MODULE_KEYS["chatdocument.search.chatwith"])}</p>
                  <Tooltip title={filename}>
                    <p style={{ marginLeft: "5px", fontWeight: "bold" }}>{filename?.slice(0, 36)}...</p>
                  </Tooltip>
                </div>
                <div style={{ display: "flex" }}>
                  <img style={{ paddingTop: "5px", height: "18px" }} src={PROJECT_ICONS.UNION} />
                  <p style={{ color: "#66676B", paddingTop: "2px", paddingLeft: "5px" }}>7 {t(I18N_MODULE_KEYS["chatdocument.search.pages"])}</p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "90px", padding: "10px" }}>
              <div
                style={{ color: "#034EA2", fontSize: "16px", width:'180px',fontWeight: "600", padding: "2px", cursor: "pointer", position: "relative" }}
                onClick={() => {
                  {
                    !downloadinprogressfolder ? setisopenforFolderTime(!isopenforFolderTime) : <></>;
                  }
                }}
              >
                <div style={{display:'flex'}}>
                {t(I18N_MODULE_KEYS["chatdocument.search.downloadchat"])}{" "}
                  {!downloadinprogressfolder ? (
                    <img
                      src={PROJECT_ICONS.ARROW}
                      style={{ paddingLeft: "7px", height:'8px',transform: !isopenforFolderTime ? "rotate(180deg)" : "rotate(0deg)", paddingRight: "5px",marginTop:'7px'}}
                    />
                  ) : (
                    <>
                      <Spin style={{ paddingLeft: "5px" }}></Spin>
                    </>
                  )}
                </div>
                {isopenforFolderTime ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "32px",
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fff",
                      zIndex: 1000,
                      borderRadius: "3px",
                      boxShadow: "0px 2px 3px #0000001F",
                    }}
                  >
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("1d");
                        setisopenforFolderTime(false);
                      }}
                    >
                     {t(I18N_MODULE_KEYS["chatdocument.search.today"])}
                    </div>
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("7d");
                        setisopenforFolderTime(false);
                      }}
                    >
                      {t(I18N_MODULE_KEYS["chatdocument.search.7days"])}
                    </div>
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("1m");
                        setisopenforFolderTime(false);
                      }}
                    >
                     {t(I18N_MODULE_KEYS["chatdocument.search.lastmonth"])}
                    </div>
                    <div
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                        fontSize: "14px",
                        paddingTop: "5px",
                      }}
                      onClick={() => {
                        handleDownloadForFolder("1y");
                        setisopenforFolderTime(false);
                      }}
                    > 
                      {t(I18N_MODULE_KEYS["chatdocument.search.lastyear"])}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ color: "#034EA2", fontSize: "28px", fontWeight: "600", cursor: "pointer" }} onClick={ResetEverything}>
                X
              </div>
            </div>
          </div>
        
          <div style={{ padding: "15px" }}>
            <div ref={scrollableDivRef} style={{ height: "440px", overflowY: "auto", padding: "10px" }}>
              <div>
                <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                  <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                  <p style={{ fontSize: "15px", marginLeft: "25px", background: "#8C32FF", color: "white", borderRadius: "8px", padding: "10px" }}>
                    Don't know what to ask ? Click on any suggested topics or ask your own question
                  </p>
                </div>
                <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                  <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                  <div>
                    <p style={{ fontSize: "15px", marginLeft: "25px", background: "#F8F9FD", color: "black", borderRadius: "8px", padding: "10px" }}>
                      Suggested Topics
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        marginLeft: "25px",
                        background: "#F8F9FD",
                        color: "#034EA2",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        paddingLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handlePostRequestDirect("Provide summary of this document");
                      }}
                    >
                      Provide summary of this document
                    </p>
                    <p
                      onClick={() => {
                        handlePostRequestDirect("Give interesting insights from this document");
                      }}
                      style={{
                        fontSize: "15px",
                        marginLeft: "25px",
                        background: "#F8F9FD",
                        color: "#034EA2",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Give interesting insights from this document
                    </p>
                    {recommendQuestion?.map((question, index) => (
  <p
    key={index}
    onClick={() => {
      handlePostRequestDirect(`${question}`);
    }}
    style={{
      fontSize: "15px",
      marginLeft: "25px",
      background: "#F8F9FD",
      color: "#034EA2",
      textDecoration: "underline",
      borderRadius: "8px",
      padding: "10px",
      paddingTop: '2px',
      cursor: "pointer",
    }}
  >
    {question}
  </p>
))}

                  </div>
                </div>
              </div>
              {sseData?.map((data, index) => (
                <div key={index}>
                  <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                    <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.PERSON}></img>
                    <p style={{ fontSize: "15px", marginLeft: "25px", background: "#8C32FF", color: "white", borderRadius: "8px", padding: "10px" }}>
                      {data.question}
                    </p>
                  </div>
                  <div style={{ display: "flex", paddingLeft: "10px", marginTop: "35px" }}>
                    <img style={{ fontSize: "15px", height: "35px", paddingTop: "5px" }} src={PROJECT_ICONS.MESSAGE}></img>
                    <p style={{ fontSize: "15px", marginLeft: "25px", background: "#F8F9FD", color: "black", borderRadius: "8px", padding: "10px" }}>
                    {data?.answer ?(<><Markdown>{data?.answer}</Markdown></>):(<></>)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "20px" }}>
              <Input
                style={{ border: "1px solid #949494 ", borderRadius: "3px" }}
                value={userInputForSearch}
                suffix={
                  <Button
                    style={{ border: "none" }}
                    ref={buttonRef}
                    disabled={disable}
                    onClick={
                      () => {
                        handlePostRequest();
                      }
                      // savePrompt(searchTerm && searchQuery ? false : true)
                    }

                    // disabled={isLoading}
                  >
                    <span style={{ color: "#66676B", fontSize: "10px" }}> SEARCH</span>
                  </Button>
                }
                placeholder="Enter Search query"
                onChange={handleUserInput}
                disabled={disable}
              />
            </div>
          </div>
        </div>
        </div>
      </Drawer></>
      )}
      </>
    );
  };