import { FC, useTransition } from "react";
import { Avatar, Button, Card, Image } from "antd";

import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";

const { Title, Paragraph, Text } = Typography;

type IProps = {
  image: string;
  heading: string;
  description: string;
  handleSeeMoreAction: () => void;
  viewUseCaseLibrary?: () => void;
  imageStyle?: any;
};

const DashBoardCard: FC<IProps> = ({ image, heading, description, handleSeeMoreAction, viewUseCaseLibrary, imageStyle }) => {
  const { i18n, t } = useTranslation();

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: "0px 6px 18px #00000029",
        borderRadius: "12px",
        height: "100%"
      }} //Height changed so responsiveness might be issue//
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
      }}
    >
      <div>
        <Avatar
          style={imageStyle ? { background: "#dceaff", width: "40px", height: "40px" } : { width: "40px", height: "40px" }}
          src={imageStyle ? <Image src={image} style={imageStyle ?? { ...imageStyle }} preview={false} /> : image}
          size={{ xs: 24, sm: 32, md: 40, lg: 45, xl: 50 }}
        />
        <Title
          level={5}
          style={{
            fontStyle: "normal normal bold 24px/32px Nunito Sans",
            marginTop: "5px"
          }}
        >
          {heading || ""}
        </Title>
        <div>
          <Text style={{ fontSize: "12px" }}>{description || ""}</Text>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <Button onClick={viewUseCaseLibrary} type="primary" style={{ background: "rgb(5, 70, 165)", margin: "10px 0 0px 10px" }}>
          {t(I18N_MODULE_KEYS["content.dashboard.card.button.description"])}
        </Button>
        <Button onClick={handleSeeMoreAction} type="primary" style={{ background: "rgb(5, 70, 165)", margin: "10px 0 0px 10px" }}>
          {t(I18N_MODULE_KEYS["content.dashboard.card.button.demo"])}
        </Button>
      </div>
    </Card>
  );
};

export { DashBoardCard };
