"use-strict";

import axios from "axios";

import LocalStorageService from "./_localStorageService";
import { globalConstant, networkErrorMsgs } from "../Utils";
import { siteConfig } from "./_siteConfig";
import { ENUM_ROUTE_URLS } from "../Routes/_routesConfig";
import { setAxiosSourceTokenAction, setLoadingStatusAction, setNotificationMsgAction } from "../Store/actions/_commonActions";
import LogRocket from "logrocket";
import { setLoginDetailsAction, setSessionExpiredFlagAction } from "../Store/actions/_authActions";
import { redirect } from "react-router";
import { I18N_MODULE_KEYS } from "../Components/i18n/core/constants";

const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: siteConfig.BASE_URL
});

const setAxiosTokenState = () => {
  const signal = new AbortController();
  // const source = axios.CancelToken.source();

  //@ts-ignore
  window?.store?.dispatch(setAxiosSourceTokenAction(signal));

  return signal;
};

// setAxiosTokenState();

api.interceptors.request.use(
  (config: any) => {
    const token = LocalStorageService.getAccessToken();

    if (token) {
      // config.headers["Authorization"] = "Bearer " + token;
      config.headers["Authorization"] = token;
      config.headers["accept-language"] = LocalStorageService.getAcceptLanguage();
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async function (error) {
    console.log(error);

    if (!error) {
      console.log("API Response error: Empty Error object.");
      return;
    }

    const originalRequest = error?.config;

    if (error?.response?.status === 401) {
      //LocalStorageService.setSessionExpired(true);
      //LocalStorageService.removeToken();
      //@ts-ignore
      window.store?.dispatch(setSessionExpiredFlagAction(true));
      //window.location.href = ENUM_ROUTE_URLS.LOGIN;
    }

    if (error?.response?.status === 401 && !originalRequest?._retry) {
      // originalRequest._retry = true
      // const refreshToken = LocalStorageService.getRefreshToken();
      // return axios.post('/auth/token', { refresh_token: refreshToken })
      //   .then(res => {
      //     if (res.status === 201) {
      //       LocalStorageService.setToken(res.data)
      //       axios.defaults.headers.common['Authorization'] =
      //         'Bearer ' + LocalStorageService.getAccessToken()
      //       return axios(originalRequest)
      //     }
      //   })
    }
    return Promise.reject(error);
  }
);

class ApiService {
  getAxiosSourceTokenState() {
    //@ts-ignore
    return window?.store?.getState()?.commonReducer.axiosSourceToken || undefined;
  }

  handleError(err: any, isAborted: boolean) {
    try {
      console.log(err);

      LogRocket.error("An error occurred:", err);

      if (err.response) {
        const errorMsgKeys = Object.keys(networkErrorMsgs);

        if (errorMsgKeys?.includes(err.response.status)) {
          //@ts-ignore
          window?.store?.dispatch(setNotificationMsgAction(networkErrorMsgs[err.response.status]));
        }

        //if API response has error details and details is of type string
        else if (err.response.data?.detail && typeof err.response.data?.detail === "string") {
          //@ts-ignore
          window?.store?.dispatch(setNotificationMsgAction(err.response.data.detail));
        } else {
          //@ts-ignore
          window?.store?.dispatch(setNotificationMsgAction(err.response.status + ": " + err.response.statusText));
        }
        return err.response;
      } else if (err.request) {
        // The request was made but no response was received
        if (!isAborted)
          //@ts-ignore
          // window?.store?.dispatch(setNotificationMsgAction(err.message ? err.message : globalConstant.INTERNAL_SERVER_ERROR + " : No Response!"));
          window?.store?.dispatch(setNotificationMsgAction(`${I18N_MODULE_KEYS["content.text.error.500"]}`));
        // window?.store?.dispatch(
        //   setNotificationMsgAction(
        //     err.message ? err.message : globalConstant.INTERNAL_SERVER_ERROR + `${I18N_MODULE_KEYS["content.text.errornoresponse"]}`
        //   )
        // );
        return err.request;
      } else {
        if (!isAborted) {
          // window?.store?.dispatch(setLoadingStatusAction(false));
          //@ts-ignore
          window?.store?.dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
        }

        return { status: 0, data: {} };
      }
    } catch (err) {
      console.log(err);
    }
  }

  async get(url: string) {
    setAxiosTokenState();

    const abortController = this.getAxiosSourceTokenState();

    let res: any;
    await api
      .get(siteConfig.BASE_URL + url, { signal: abortController.signal })
      .then((data: any) => {
        res = data;
      })
      .catch((err: any) => {
        res = this.handleError(err, abortController?.signal?.aborted);
      });

    return res;
  }

  async post(url: string, body: any, config?: any) {
    setAxiosTokenState();

    const abortController = this.getAxiosSourceTokenState();

    let res: any;
    await api
      .post(siteConfig.BASE_URL + url, body, { signal: abortController.signal, ...config })

      .then((data: any) => {
        res = data;
      })
      .catch((err: any) => {
        res = this.handleError(err, abortController?.signal?.aborted);
      });

    return res;
  }

  async put(url: string, body: any) {
    setAxiosTokenState();

    const abortController = this.getAxiosSourceTokenState();

    let res: any;
    await api
      .put(siteConfig.BASE_URL + url, body, { signal: abortController.signal })
      .then((data: any) => {
        res = data;
      })
      .catch((err: any) => {
        res = this.handleError(err, abortController?.signal?.aborted);
      });

    return res;
  }

  async delete(url: string) {
    setAxiosTokenState();

    const abortController = this.getAxiosSourceTokenState();

    let res: any;
    await api
      .delete(siteConfig.BASE_URL + url, { signal: abortController.signal })
      .then((data: any) => {
        res = data;
      })
      .catch((err: any) => {
        res = this.handleError(err, abortController?.signal?.aborted);
      });

    return res;
  }

  async patch(url: string, body: any) {
    setAxiosTokenState();

    const abortController = this.getAxiosSourceTokenState();

    let res: any;
    await api
      .patch(siteConfig.BASE_URL + url, body, { signal: abortController.signal })
      .then((data: any) => {
        res = data;
      })
      .catch((err: any) => {
        res = this.handleError(err, abortController?.signal?.aborted);
      });

    return res;
  }
}

export default new ApiService();
