import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, MenuProps } from "antd";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { HistoryOutlined, PieChartOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../i18n/core/constants";

type MenuItem = Required<MenuProps>["items"][number];

export const SideBarMenu: FC = () => {
  const location = useLocation();
  const { i18n, t } = useTranslation();

  const MenuItems = [
    [<Link to={ENUM_ROUTE_URLS.DASHBOARD}>{t(I18N_MODULE_KEYS["sidebar.dashboard"])}</Link>, ENUM_ROUTE_URLS.DASHBOARD, <PieChartOutlined />],
    [<Link to={ENUM_ROUTE_URLS.CONTENT_HISTORY}>{t(I18N_MODULE_KEYS["sidebar.history"])}</Link>, ENUM_ROUTE_URLS.CONTENT_HISTORY, <HistoryOutlined />]
  ];

  const getItem = (label: React.ReactNode, key: React.Key, icon?: any, children?: MenuItem[]): MenuItem => {
    return { key, icon, children, label } as MenuItem;
  };

  //@ts-ignore
  const items: MenuItem[] = MenuItems.map((item) => getItem(...item));

  return (
    <Menu
      mode="inline"
      selectedKeys={[location.pathname]}
      theme="dark"
      style={{
        background: "#0D308E",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
      items={items}
    />
  );
};
