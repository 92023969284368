import { RcFile } from "antd/es/upload";
import { siteConfig } from "../../Services";
import { handleGetDataFromApi, handlePostDataFromApi, handlePutDataFromApi } from "../../Utils";
import { apiResponse } from "../../Utils/_gTypes";
const getUserID = () => "0";

export const getRegionRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_REGION, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const getBrandRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_BRAND, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const getChannelRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_CHANNEL, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const getAllTagsRequest = async (isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_ALL_TAGS, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const getPromptResultRequest = async (prompt_id: number, isEnriched: boolean, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(
    siteConfig.CREATE_PROMPT + `/get_result?prompt=${prompt_id}&enriched=${isEnriched}`,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const createPromptRequest = async (body: { prompt: string }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.CREATE_PROMPT,
    { ...body, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  // if (status === 400) {
  //   return { status, detail: data?.detail };
  // }

  return undefined;
};

export const regenerateInitialResponse = async (body: { prompt_id: number; regenerate: boolean }, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.CREATE_PROMPT,
    { ...body, user_id: getUserID() },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  // if (status === 400) {
  //   return { status, detail: data?.detail };
  // }

  return undefined;
};

export const createEnrichPromptRequest = async (
  prompt_id: number,
  source: string,
  contentIDs?: number[] | undefined,
  regenerate: boolean = false,
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.ENRICH_PROMPT + `/${prompt_id}`,
    { user_id: getUserID(), source: source, content_ids: contentIDs, regenerate },
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const getFilteredRulesRequest = async (
  body: {
    [key: string]: number[];
  },
  contentAIModuleName: string,
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.FILTER_RULES + `?name=${contentAIModuleName}`,
    body,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const updateRuleTagsRequest = async (
  body: {
    rule_id: number;
    tags: number[];
  },
  isLoading?: any
) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.UPDATE_RULE, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const updateRuleNameRequest = async (
  body: {
    rule_id: number;
    name: string;
  },
  isLoading?: any
) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.UPDATE_RULE, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const addNewRuleRequest = async (
  body: {
    tags: number[];
    name: string;
    rule_type: string;
  },
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.ADD_RULE, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const deleteRuleRequest = async (rule_id: number, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.DELETE_RULE + `/${rule_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const updateCUContentRequest = async (
  body: {
    content_id: number;
    content: string;
  },
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.CU_UPDATE_CONTENT, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const deleteCUContentRequest = async (content_id: number, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.CU_DELETE_CONTENT + `/${content_id}`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const uploadSingleCUFileRequest = async (body: FormData, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.CU_UPLOAD_FILE, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const getRuleTagsRequest = async (rule_id: number, isLoading?: any) => {
  const res: apiResponse = await handleGetDataFromApi(`/rule/${rule_id}/tags`, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};

export const addRulesToPromptRequest = async (
  body: {
    tags: number[];
    prompt: number;
    rule_type: string;
  },
  isLoading?: any
) => {
  const res: apiResponse = await handlePostDataFromApi(siteConfig.ADD_PROMPT_RULES, body, isLoading ? isLoading : undefined);

  const { status, data } = res;

  if (status === 200 || status === 201) {
    // handleAPIErrors(res);
    return data;
  }

  return undefined;
};
