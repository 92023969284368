import { CSSProperties, ChangeEvent, EventHandler, FC, useEffect, useRef, useState } from "react";
import { CUContentComponentProps, CUContentType } from "../../Core/types";
import LabelCheckBox from "../Common/LabelCheckBox";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { deleteCUContentRequest, updateCUContentRequest } from "../../../../Store/requests/_contentAIRequests";
import { useDispatch } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { globalConstant } from "../../../../Utils";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
const inputBoxStyle: CSSProperties = {
  outline: "none",
  width: "100%",
  border: "1px dashed #034EA2",
  borderRadius: "8px",
  padding: "10px",
  resize: "none",
  lineHeight: "24px",
  maxHeight: "100%",
  fontSize: "14px"
};

const CUContentComponent: FC<CUContentComponentProps> = ({
  content,
  onChange,
  setEditContentId,
  getAppliedContent,
  getCUSelectedTagsIds,
  setCUD
}) => {
  const dispatch = useDispatch();

  const [editContent, setEditContent] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(content.content);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const {t}=useTranslation();
  //Adjust the TextArea height based on the content scrollable height on ComponentDidMount
  useEffect(() => {
    if (!editContent) return;
    if (!textAreaRef.current) return;
    textAreaRef.current.style.height = "0px";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 5 + "px";

    //For setting focus to the last character
    textAreaRef.current.value = "";
    textAreaRef.current.value = updatedContent;

    //Scroll to bottom
    textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
  }, [editContent]);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUpdatedContent(e.target.value);

    if (!textAreaRef.current) return;
    textAreaRef.current.style.height = "0px";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 5 + "px";
  };

  const handleDelete = async () => {
    const res = await deleteCUContentRequest(content.id);

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      console.log("Content Deleted Successfully!");
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.notification.contentdeleted"])));

      //Refetch contents with Applied Tags
      //getAppliedContent(getCUSelectedTagsIds(), true);

      setCUD((pContent: CUContentType[]) => pContent.filter((item) => item.id !== content.id));
    }
  };

  const handleEdit = () => {
    setEditContentId(content.id);
    setEditContent(true);
  };

  const handleCancel = () => {
    setUpdatedContent(content.content);
    setEditContent(false);
    setEditContentId(0); //Reset the Content Id to 0. 0 shows that no content is in edit mode.
  };

  const handleSave = async () => {
    //Return if updated content is empty
    if (!updatedContent.trim()) {
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.notification.contentemptyerror"])));
      return;
    }

    //console.log({ updatedContent });
    const res = await updateCUContentRequest({ content_id: content.id, content: updatedContent });

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      console.log("Content updated successfully!");
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.notification.contentupdate"])));

      //Refetch contents with Applied Tags
      //getAppliedContent(getCUSelectedTagsIds(), true);

      //TODO If separate Component is used then use the below code to maintain the update position.
      setCUD((pContent: CUContentType[]) =>
        pContent.map((item) => {
          if (item.id === content.id) {
            item.content = updatedContent;
          }
          return item;
        })
      );

      setEditContent(false);
      setEditContentId(0); //Reset the Content Id to 0. 0 shows that no content is in edit mode.
    }
  };

  return (
    <div style={{ display: "flex", gap: "15px", ...(editContent ? { height: "100%" } : {}) }}>
      {!editContent && (
        <LabelCheckBox
          label={content.content}
          labelStyle={{ textTransform: "capitalize", whiteSpace: "pre-line", lineHeight: "24px", alignItems: "normal" }}
          checkboxStyle={{ width: "20px", height: "20px", marginRight: "15px", marginTop: "3px" }}
          name={`content-${content.id}`}
          value={`content-${content.id}`}
          checked={content.selected ?? false}
          onChange={(e) => onChange(content.id)}
        />
      )}
      {editContent && <textarea autoFocus ref={textAreaRef} value={updatedContent} onChange={handleTextAreaChange} style={inputBoxStyle} />}
      <div style={{ cursor: "pointer" }}>
        {!editContent && <img src={PROJECT_ICONS.C_AI_DELETE_TRASH_ICON} style={{ width: "20px" }} onClick={handleDelete} />}
        {editContent && <img src={PROJECT_ICONS.C_AI_CLOSE_SQUARE_ICON} style={{ width: "20px" }} onClick={handleCancel} />}
      </div>
      <div style={{ cursor: "pointer" }}>
        {!editContent && <img src={PROJECT_ICONS.C_AI_EDIT_PENCIL_ICON} style={{ width: "20px" }} onClick={handleEdit} />}
        {editContent && <img src={PROJECT_ICONS.C_AI_SAVE_ICON} style={{ width: "20px" }} onClick={handleSave} />}
      </div>
    </div>
  );
};

export default CUContentComponent;
