import { FC } from "react";

import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT } from "../../../Utils";
import {
  setNextStepperStateAction,
  setPreviousStepperStateAction,
  setRegenerateStateAction
} from "../../../Store/actions/_contentTranscreationActions";
import { CustomButtonForApiCalling } from "../../../Components/Buttons";
import { setActionDrawerDataAction } from "../../../Components/ActionDrawer/State";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { siteConfig } from "../../../Services";

export const CTFooter: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

  const { activeStepper, buttonText, regenerate } = useSelector((state: any) => state?.contentTranscreationReducer);

  const handleNext = () => {
    setBaseURL();
    dispatch(setNextStepperStateAction(true));
  };

  const handlePrevious = () => {
    dispatch(setPreviousStepperStateAction(true));
  };

  //Required for 'Rules' tab as Content AI base URL would be used otherwise when clicking the footer buttons while on Rules Tab.
  const setBaseURL = () => {
    siteConfig.BASE_URL !== process.env.REACT_APP_API_URL && (siteConfig.BASE_URL = process.env.REACT_APP_API_URL);
  };

  return (
    <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px" }}>
      {activeStepper !== CONTENT_TRANSCREATION_STEPS.STEP_4 && (
        <Button
          className="ant-btn-lg"
          type="primary"
          style={{ background: "rgb(5, 70, 165)", color: "white" }}
          onClick={handlePrevious}
          // disabled={isLoading}
        >
          {/* Go Back */}
          {t(I18N_MODULE_KEYS["content.button.goback"])}
        </Button>
      )}

      {buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.ORDER_VIDEO && (
        <Button
          className="ant-btn-lg"
          type="primary"
          style={{ background: "rgb(5, 70, 165)", color: "white" }}
          onClick={() => {
            dispatch(
              setActionDrawerDataAction({
                drawerTitle: t(I18N_MODULE_KEYS["content.transcreation.button.summary"]),
                drawerOpen: true,
                componentId: "ViewSummary"
              })
            );
          }}
        >
          {/* View Summary */}
          {t(I18N_MODULE_KEYS["content.transcreation.footer.button.viewsummary"])}
        </Button>
      )}

      {(buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES ||
        buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD ||
        buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CONERT_TO_HTML) && (
        <CustomButtonForApiCalling
          btnText={
            (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES &&
              t(I18N_MODULE_KEYS["content.transcreation.footer.button.regeneratestory"])) ||
            (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD &&
              t(I18N_MODULE_KEYS["content.transcreation.footer.button.regeneratedoc"])) ||
            ""
          }
          // btnText={
          //   "Regenerate " +
          //   ((buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES && "Story") ||
          //     (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD && "Doc") ||
          //     // (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD && "HTML") ||
          //     (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CONERT_TO_HTML && "Doc"))
          // }
          loading={regenerate}
          className="ant-btn-lg"
          onClick={() => {
            setBaseURL();
            dispatch(setRegenerateStateAction(true));
          }}
          disabled={isLoading}
          style={{ background: "#0546A5" }}
        />
      )}
      {buttonText && (
        <CustomButtonForApiCalling
          btnText={`${t(buttonText)}` || `${t(I18N_MODULE_KEYS["content.button.next"])}`}
          loading={isLoading}
          className="ant-btn-lg"
          onClick={handleNext}
          style={{ background: "#0546A5" }}
        />
      )}
    </div>
  );
};
