import React, { RefObject, useEffect, useState } from "react";
import { SlideDataType } from "../core/modals";
import { FabricCanvas } from "./Canvas";
import { Col, Row } from "antd";
import { Player } from "../../../../Components/Player";
import { ChangeAvatar } from "./ChangeAvatar";
import { useSelector } from "react-redux";
import { getAvatarsRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { useDispatch } from "react-redux";
import { CanvasPlayer } from "./CanvasPlayer";

export const CanvasList = React.memo(
  ({
    slides,
    setThumbnails,
    activeSlide,
    workingAreaRef,
    canvasList,
    setCanvasList,
    audioList,
    setAudioList,
    globalPlayer,
    setGlobalPlayer,
    handlePause,
    onStopPlaying
  }: {
    slides: SlideDataType[];
    setThumbnails: Function;
    activeSlide: number;
    workingAreaRef: RefObject<HTMLDivElement>;
    canvasList: fabric.Canvas[];
    setCanvasList: Function;
    audioList: any[];
    setAudioList: Function;
    globalPlayer: any;
    setGlobalPlayer: Function;
    handlePause: Function;
    onStopPlaying: Function;
  }) => {
    const dispatch = useDispatch();

    //const { elaiResponse } = useSelector((state: any) => state?.contentTranscreationReducer);

    const [avatars, setAvatars] = useState<any[]>([]);
    const [seekTimeForResume, setSeekTimeForResume] = useState(0);

    useEffect(() => {
      setTimeout(() => {
        getAvatars();
      }, 2000);
    }, []);

    const getAvatars = async () => {
      // const res = await getAvatarsRequest(dispatch);
      const res = await getAvatarsRequest();
      setAvatars(res);
      console.log(res, "res of avatar");
      if (res && res?.length) {
      }
    };

    useEffect(() => {
      console.log(avatars, "avatars useEffect");
    }, [avatars]);

    return (
      <Row gutter={[8, 8]} style={{ position: "relative" }}>
        <Col xs={{ span: 24 }} style={{ display: "flex", flexDirection: "column", alignItems: "center" /* , padding: "8px" */ }}>
          {slides?.map((slide, index) => (
            <FabricCanvas
              key={slide?.id}
              index={index}
              slide={slide}
              // hidden={slide?.id !== activeSlide + 1}
              hidden={index !== activeSlide}
              setThumbnails={setThumbnails}
              setCanvasList={setCanvasList}
              workingAreaRef={workingAreaRef}
            />
          ))}
        </Col>
        <Col xs={{ span: 24 }} style={{ marginTop: "10px" }}>
          <hr />
        </Col>
        <Col xs={{ span: 24 }} style={{ display: "flex", justifyContent: "center" }}>
          <Row style={{ width: "768px" }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              {/* <Player
            duration={slides[activeSlide]?.approxDuration || 0}
            language={elaiResponse?.locale}
            slide={slides[activeSlide]}
            activeSlide={activeSlide}
          /> */}
              {slides?.map((slide, index) => (
                <CanvasPlayer
                  index={index}
                  slide={slide}
                  activeSlide={activeSlide}
                  canvasList={canvasList}
                  slidesLength={slides.length}
                  audioList={audioList}
                  setAudioList={setAudioList}
                  globalPlayer={globalPlayer}
                  setGlobalPlayer={setGlobalPlayer}
                  handlePause={handlePause}
                  onStopPlaying={onStopPlaying}
                  slides={slides}
                  seekTimeForResume={seekTimeForResume}
                  setSeekTimeForResume={setSeekTimeForResume}
                />
              ))}
            </Col>
            {/* <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <ChangeAvatar slide={slides[activeSlide]} avatarList={avatars} setAudioList={setAudioList} />
            </Col> */}
          </Row>
        </Col>
      </Row>
    );
  }
);
