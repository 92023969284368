import { Col, Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";

import { minusIcon, listIcon, orderedListIcon } from "../constants";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../../../Store/actions/_contentTranscreationActions";

export const TextType = ({ activeObjectProps }: { activeObjectProps: any }) => {
  const dispatch = useDispatch();

  const isTextType = activeObjectProps?.type === "i-text" || activeObjectProps?.type === "textbox" ? "primary" : "default";
  const isListType = activeObjectProps?.listType === "unordered" ? "primary" : "default";
  const isOrderedListType = activeObjectProps?.listType === "ordered" ? "primary" : "default";

  const onTextTypeClick = () => {
    dispatch(setActiveObjectModifier({ change: "textType", value: "text" }));
  };

  const onListTypeClick = () => {
    dispatch(setActiveObjectModifier({ change: "textType", value: "unordered" }));
  };

  const onOrderedListTypeClick = () => {
    dispatch(setActiveObjectModifier({ change: "textType", value: "ordered" }));
  };

  return (
    <Col>
      <ButtonGroup>
        <Button icon={minusIcon} type={isTextType} onClick={onTextTypeClick} />
        <Button icon={listIcon} type={isListType} onClick={onListTypeClick} />
        <Button icon={orderedListIcon} type={isOrderedListType} onClick={onOrderedListTypeClick} />
      </ButtonGroup>
    </Col>
  );
};
