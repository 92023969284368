import { SyncOutlined } from "@ant-design/icons";
import { Tooltip, message, notification } from "antd";
import Icon from "../common/Icon";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";
import { updateElaiVideoData } from "../../../../../Store/requests/_contentTranscreationRequests";
import { useDispatch } from "react-redux";
import { setCanvasActiveObject, setElaiTranslationDataAction, setSaveElaiObject } from "../../../../../Store/actions/_contentTranscreationActions";
import { handleCancelApiRequest } from "../../../../../Utils";

export const HeaderControls = ({ setAudioList }: { setAudioList: Function }) => {
  const { elaiResponse, saveElaiObject, activeSlide } = useSelector((state: RootState) => state.contentTranscreationReducer);

  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false); // For showing loader

  const timerId = useRef<NodeJS.Timeout>();

  const requestRef = useRef(0); // To keep track of request version

  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log("useEffect dispatch handleBeforeUnload ");
      dispatch(setCanvasActiveObject(null)); // 'Elements' tab is selected by default
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!saveElaiObject) return;

    console.log("saveElaiObject", saveElaiObject);

    //update thumbnail

    const currentRequest = ++requestRef.current;

    setSaving(true);

    clearTimeout(timerId.current);
    handleCancelApiRequest();

    timerId.current = setTimeout(
      () =>
        (async () => {
          const avatarUpdated = isAvatarUpdated();

          cleanElaiObject(saveElaiObject);

          const res = await updateElaiVideoData(saveElaiObject);

          if (currentRequest !== requestRef.current) {
            console.log("Discarding Last Response");
            return;
          }
          setSaving(false);
          if (!res) {
            notification.error({ message: "Saving failed!" });
            return;
          }
          console.log("res", res);

          dispatch(setElaiTranslationDataAction({ ...elaiResponse, ...res }));
          avatarUpdated && setAudioList([]);
          dispatch(setSaveElaiObject(null));

          message.success("Saved!");
        })(),
      1000
    );
  }, [saveElaiObject]);

  const cleanElaiObject = (elaiObject: any) => {
    delete elaiObject.storyboard_id;
    delete elaiObject.video_id;
    delete elaiObject.message;
    delete elaiObject.task_id;
    delete elaiObject.is_render;
    delete elaiObject.locale;
    elaiObject.data = {};
  };

  const isAvatarUpdated = () => {
    if (saveElaiObject?.updatedObject === "avatar") {
      return true;
    }
    return false;
  };

  return (
    <Tooltip
      key="autoSave"
      placement="top"
      title={!saving ? "All changes are saved" : "Saving..."}
      //title={!saving ? "All changes are saved" : "Your story has updates that are not saved. Click to save as draft."}
      mouseEnterDelay={0.2}
    >
      <div className="save-icon-wrapper" style={{ display: "flex" }}>
        {!saving ? (
          <Icon
            name="check"
            style={{ fontSize: "14px", color: "#3bad5f", paddingRight: 8 }}
            onClick={() => {
              /* buttonSaveVideo() */
            }}
          />
        ) : (
          <SyncOutlined
            spin
            style={{
              fontSize: "22px",
              //opacity: videoSavingStatus === "saving" ? "0.25" : "0.45",
              color: "#f3f3f3"
            }}
            onClick={() => {
              /* buttonSaveVideo() */
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};
