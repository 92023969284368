import { DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { FC, useEffect, useState } from "react";
import { PhonemetableType } from "../../core/modals";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPhonemeListAction } from "../../../../../Store/actions/_contentTranscreationActions";
import {
  setLanguageAction,
  setNarrationAction,
  setPauseAction,
  setPlayAction,
  setResumeAction,
  setSelectedVoiceAction,
  setStopAction
} from "../../../../../Components/Player";

const enumPlayer = {
  PLAY: "play",
  PAUSE: "pause"
};

export const PhonemeActions: FC<{ record?: PhonemetableType }> = ({ record }) => {
  const dispatch = useDispatch();

  const { play, pause, resume, stop } = useSelector((state: any) => state?.playerReducer);
  const { phonemeList } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [player, setPlayer] = useState<string>(enumPlayer.PLAY);

  useEffect(() => {
    if (stop === true) {
      setPlayer(enumPlayer.PLAY);
    }
  }, [stop]);

  const handleDelete = () => {
    const arrPhonemeList: PhonemetableType[] = [...phonemeList];

    dispatch(setPhonemeListAction([...arrPhonemeList?.filter((item: PhonemetableType) => item?.id !== record?.id)]));
  };

  const handlePlayer = (type: string) => {
    const arrPhonemeList: PhonemetableType[] = [...phonemeList];

    dispatch(setNarrationAction([arrPhonemeList?.find((item: PhonemetableType) => item?.id === record?.id)?.pronunciation]));

    dispatch(setLanguageAction("en-US"));

    dispatch(setSelectedVoiceAction(""));

    if (type === enumPlayer.PLAY) {
      if (play === false) {
        dispatch(setPlayAction(true));
        dispatch(setStopAction(false));
      } else if (play === true && pause === true) {
        dispatch(setResumeAction(true));
        dispatch(setPauseAction(false));
      }

      setPlayer(enumPlayer.PAUSE);
    } else if (type === enumPlayer.PAUSE) {
      dispatch(setPauseAction(true));
      setPlayer(enumPlayer.PLAY);
    }
  };

  return (
    <Space size="middle">
      {player === enumPlayer.PLAY ? (
        <PlayCircleOutlined onClick={() => handlePlayer(enumPlayer.PLAY)} />
      ) : (
        <PauseCircleOutlined onClick={() => handlePlayer(enumPlayer.PAUSE)} />
      )}
      <DeleteOutlined style={{ cursor: "pointer" }} onClick={handleDelete} />
    </Space>
  );
};
