import { Flex, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { setUploadDocsDataTMAction } from "../../../../Store/actions/_translationModuleActions";
import { UploadDocsTypes } from "../../Core/modals";
import { useSelector } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const OptionalContainer: FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { uploadDocsData }: { uploadDocsData: UploadDocsTypes } = useSelector((state: any) => state?.translationModuleReducer);

  const handleOnChange = (val: string) => {
    dispatch(setUploadDocsDataTMAction({ ...uploadDocsData, asset: { ...uploadDocsData.asset, context: val } }));
  };

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._TranslateAssetMain._OptionalContainer.${value as string}`])}`;
  };

  return (
    <Flex vertical style={{ background: "rgba(242, 248, 255, 1)", height: "100%", borderRadius: "5px", padding: "20px" }} gap="middle">
      <Text style={{ fontSize: "18px", fontWeight: "600" }}> {getTranslatedText("Add Context to the Translation (Optional)")}</Text>
      <TextArea rows={18} placeholder={getTranslatedText("Enter your text")} onChange={(e) => handleOnChange(e.target.value)} />
    </Flex>
  );
};
