import { QuestionCircleOutlined } from "@ant-design/icons";
import { Row, Col, Switch, InputNumber, Tooltip, Space } from "antd";

import LazySlider from "../../../common/LazySlider";
import { syncVideoState } from "../../../../utils/videos";

import { formatVolume, useVideoControls } from "./useVideoControls";

//import { Trim } from './components'
import { memo } from "react";
import { Trim } from "./components/Trim";

const STYLE = {
  marginTop14: { marginTop: 14 }
};

export const VideoControls = (props: { [x: string]: any }) => {
  const { duration, canvasActiveObject, activeObjectProps } = props;

  const { handleFitAudio, handleLoop, onStep, onTrimChange, onSpeedRateChange, onVolumeChange, onTrimStartChange, onTrimEndChange } =
    useVideoControls({
      duration,
      syncVideoState,
      canvasActiveObject,
      volume: activeObjectProps.volume
    });

  const VolumeSlider = memo(
    () => (
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <Space>
            <h4>Volume</h4>
            <Tooltip title="Use this to change volume of your video (works with default speed rate only)">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={15}>
          <LazySlider
            min={0}
            max={1}
            step={0.01}
            linkedElementKey="volume"
            onChange={onVolumeChange}
            value={activeObjectProps.volume}
            linkedElement={activeObjectProps}
            tooltip={{ formatter: formatVolume }}
            disabled={activeObjectProps.speedRate !== 1}
          />
        </Col>
      </Row>
    )/* ,
    [formatVolume, activeObjectProps, onVolumeChange] */
  );

  return (
    <>
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <h4>Loop Video</h4>
        </Col>
        <Col span={15}>
          <Switch checked={activeObjectProps.loop} onChange={handleLoop} />
        </Col>
      </Row>
      {
        // active when video is not in a group selection
        canvasActiveObject.getElement && (
          <Trim
            canvasActiveObject={canvasActiveObject}
            onStep={onStep}
            onTrimChange={onTrimChange}
            onTrimStartChange={onTrimStartChange}
            onTrimEndChange={onTrimEndChange}
          />
        )
      }
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <Space>
            <h4>Fit Audio</h4>
            <Tooltip title="Trimmed video will be automatically speeded up to a duration of generated audio. Very useful for screencasts.">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={15}>
          <Switch checked={activeObjectProps.durationFitAudio} onChange={handleFitAudio} />
        </Col>
      </Row>
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <Space>
            <h4>Speed Rate</h4>
            <Tooltip title="Use this to speed up or slow down your video">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={15}>
          <InputNumber value={activeObjectProps.speedRate} min={0.3} max={16} onChange={onSpeedRateChange} />
        </Col>
      </Row>
      {activeObjectProps.hasAudio && <VolumeSlider />}
    </>
  );
};
