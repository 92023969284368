import React, { FC, useState, useEffect, useRef } from "react";
import { Row, Col, Button, Avatar, Image, Typography, Space,Badge  } from "antd";
import { BellFilled, InfoOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Notifications } from "./_Notifications"; // Import the Notifications component
import Usericon from "../_Usericon";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import _localStorageService from "../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { getAllNotificationForUser } from "../../../Store/requests/_semanticSearchRequests";
import { MED_USER_EMAIL, SEMANTIC_SEARCH_MODULES } from "../../../Pages/Semantic Search/Components/Core/constants";
import { setLandingScreen, setSearchQuery, setEncodedSemanticSearchId, setSemanticSearchId, setEncodedSearchQueryAction, setCardDataCollection, setConversationId, setPreviousId, setNextId, setProModeStatus, setProModeScreen, setLoadingforReportGeneration, setEditableScreen, setReportGenerationType, setSlidesForReports, setPdfForReports, setLoadingforSwitching, setSearchType, setLLMModel, FileForChatWithDoc, setAudioCardsDataAction, setVideoCardsDataAction, setAdvancedFilter, setFilterListAction, setBatchQAStatus, setBatchQAData, setBatchQAFolders } from "../../../Store/actions/_semanticSearchActions";
import { MenuI18N } from "../../i18n";
import { I18N_MODULE_KEYS } from "../../i18n/core/constants";
import { siteConfig } from "../../../Services";
import { SET_BATCH_QA_STATUS } from "../../../Store/constants/_semanticSearchConstants";

const { Paragraph } = Typography;

type IProps = {};

const prefix = (
  <Image
    src={PROJECT_ICONS.SEARCH_ICON}
    style={{
      height: "20px",
      paddingRight: "2px",
      paddingBottom: "4px",
    }}
  />
);

export const SemanticSearchHeaderWrapper: FC<IProps> = () => {
  const dispatch = useDispatch();
  const { selectedReports, activeModule } = useSelector((state: any) => state?.semanticSearchReducer);
  const {userDetails}= useSelector((state: any) => state?.authReducer);
  const { i18n, t } = useTranslation();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const sseRef = useRef<SSE | null>(null);
  const [notification, setNotification] = useState<any[]>([]);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setNotificationsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setNotificationsVisible]);


  const notificationSound = new Audio(PROJECT_ICONS.NOTIFICATIONSOUND); // Add your sound file here


  useEffect(() => {
    if (notification && notification.length >= 1) {
      notificationSound.play();
    }
  }, [notification])

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
        const response = await getAllNotificationForUser();
        setNotification(response?.data);
        getNotifications("default");
      } catch (error) {
        console.error("Error fetching initial notifications:", error);
      }
    };
    fetchNotifications();

    return () => {
      if (sseRef.current) {
        sseRef.current.close();
        console.log('SSE connection closed');
      }
    };
  }, []);

  const getPayload = (type: "default"): any => {
    switch (type) {
      default:
        return {};
    }
  };

  const createSSEConnection = (apiUrl: string, headers: Record<string, string>, payload: any) => {
    return new SSE(apiUrl, { headers });
  };

  const getNotifications = async (type: "default") => {
    let apiUrl = `${apiurl}/notification?user_id=0`;
    const accessToken = token || "";
    const payload = getPayload(type);
    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };
    const sse = createSSEConnection(apiUrl ? apiUrl : "", headers, payload);
    sseRef.current = sse;
    sse.addEventListener("notification", (event: any) => {
      try {
        const notificationArray = JSON.parse(event.data);
        if(notificationArray && notificationArray.length >=1){
        setNotification(prevNotifications => [...notificationArray,...prevNotifications || []]);
      }
      } catch (error) {
        console.error("Error parsing notification data:", error);
      }
    });
    sse.stream();
  };

  const handleBellClick = () => {
    if(notification && notification?.length>=1){
    setNotificationsVisible(!notificationsVisible);
    }
  };

  const handleResetStates = async () => {
    dispatch(setLandingScreen(true));
    dispatch(setSearchQuery(""));
    dispatch(setEncodedSemanticSearchId(0));
    dispatch(setSemanticSearchId(0));
    dispatch(setEncodedSearchQueryAction(""));
    dispatch(setCardDataCollection([]));
    dispatch(setConversationId(0));
    dispatch(setLandingScreen(true));
    dispatch(setPreviousId(0));
    dispatch(setNextId(0));
    dispatch(setProModeStatus(false));
    dispatch(setProModeScreen(false));
    dispatch(setCardDataCollection([]));
    dispatch(setConversationId(0));
    dispatch(setLoadingforReportGeneration(false));
    dispatch(setEditableScreen(false));
    dispatch(setReportGenerationType(""));
    dispatch(setSlidesForReports({}));
    dispatch(setPdfForReports({}));
    dispatch(setLoadingforSwitching(false));
    dispatch(setSearchType(""));
    dispatch(setLLMModel("gpt-4o"));
    dispatch(FileForChatWithDoc(""))
    dispatch(setAudioCardsDataAction([]));
    dispatch(setVideoCardsDataAction([]));
    dispatch(setFilterListAction([]));
    dispatch(setBatchQAStatus(false));
    dispatch(setBatchQAData([]));
    dispatch(setBatchQAFolders([]));
  };

  return (
    <>
      <Row gutter={[8, 8]} style={{ width: "100%", height: "100%", justifyContent: "space-between" }}>
        <Col span={16} className="d-flex flex-direction-column align-items-start" style={{ paddingLeft: "15px", paddingTop: "15px" }}>
          <img
            src={PROJECT_ICONS.SEMANTIC_HEADER_LOGO}
            alt="infoIcon"
            style={{ height: "35px", marginBottom: "10px", cursor: "pointer" }}
            onClick={handleResetStates}
          />
          <span className="semi-bold-font" style={{ lineHeight: "10px", paddingLeft: "10px", paddingTop: "10px", fontSize: "18px", cursor: "pointer" }}>
          {activeModule === SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH && userDetails.email!==MED_USER_EMAIL
            ? t(I18N_MODULE_KEYS["semantic.search.header.heading.search"])
            :
            activeModule === SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH && userDetails.email===MED_USER_EMAIL?"Literature Reference"
            : activeModule === SEMANTIC_SEARCH_MODULES.FILE_MANAGEMENT_SYSTEM
              ? t(I18N_MODULE_KEYS["semantic.search.floatingsidebar.filemanagement"])
              : activeModule === SEMANTIC_SEARCH_MODULES.USER_HISTORY
              ? t(I18N_MODULE_KEYS["semantic.search.userhistory.heading"])
              : activeModule === SEMANTIC_SEARCH_MODULES.USER_HISTORY
              ?t(I18N_MODULE_KEYS["semantic.search.billing.heading"])
              :activeModule === SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS
              ? "Artefacts":"Usecases"
              }
          </span>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 4 }} className="d-flex flex-direction-column">
          <Col className="d-flex justify-content-end">
            <Space style={{ marginLeft: "10px" }}>
            <Badge count={notification?notification.length:0} offset={[-12,0]} style={{padding:'1px'}}  >
                <Avatar
                  icon={<BellFilled />}
                  style={{
                    marginRight: "10px",
                    borderRadius: "15px",
                    backgroundColor: "#1e4da1",
                    cursor: "pointer",
                  }}
                  onClick={handleBellClick}
                />
              </Badge>
              <Avatar icon={<InfoOutlined />} style={{ marginRight: "10px", borderRadius: "15px", backgroundColor: "#1e4da1" }} />
              <Usericon />
              <MenuI18N />
            </Space>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              style={
                selectedReports?.length
                  ? { color: "#FFFFFF", background: "#FFFFFF", marginBottom: "10px", border: "none" }
                  : { color: "#FFFFFF", background: "#FFFFFF", marginBottom: "10px", border: "none" }
              }
              className="button-font"
              type={selectedReports?.length ? "primary" : "default"}
            ></Button>
          </Col>
        </Col>
      </Row>

      {/* Notifications Component */}
      {notificationsVisible && (
    <div 
    style={{ 
    position: "absolute", 
    top: "50px", 
    right: "20px", 
    zIndex: 1000,
    overflow: 'auto', // Allows scrolling when content overflows
    overflowX: 'hidden', // Adds horizontal scrolling if needed
    whiteSpace: 'normal' // Ensures content wraps to the next line
  }}
  ref={notificationRef}
>
          <Notifications notification={notification} setNotification={setNotification}  setNotificationsVisible={ setNotificationsVisible} />
        </div>
      )}
    </>
  );
};
