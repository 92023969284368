import { Space, Typography } from "antd";
import { FC, ReactElement } from "react";
import { DeleteFile, enumDocStatus } from "../core/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DataType } from "../core/modals";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { setResponseCtAction, setSelectedEmailDocAction } from "../../../../Store/actions/_contentTranscreationActions";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { setActionDrawerDataAction } from "../../../../Components/ActionDrawer/State";

type MenuComponentTypes = {
  title: string;
  icon?: ReactElement;
  onClick: (obj: any) => void;
};

const { Text } = Typography;

export const MenuComponent: FC<MenuComponentTypes> = ({ title, icon, onClick }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { selectedEmailDoc }: { selectedEmailDoc: DataType } = useSelector((state: any) => state?.contentTranscreationReducer);

  const handleMenuItemClick = (type: string) => {
    if (type === I18N_MODULE_KEYS["content.text.edit"]) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, edit: true, threeDotMenuOpen: false }));
    } else if (type === I18N_MODULE_KEYS["content.text.delete"]) {
      // DeleteFile(selectedEmailDoc?.email_id, dispatch, t);
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, delete: true, threeDotMenuOpen: false }));
    } else if (type === I18N_MODULE_KEYS["content.text.rename"]) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, rename: true, threeDotMenuOpen: false }));
    } else if (type === I18N_MODULE_KEYS["content.button.translate"]) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, translate: true, threeDotMenuOpen: false }));
    } else if (type === I18N_MODULE_KEYS["content.text.download"]) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, download: true, threeDotMenuOpen: false }));
    } else if (type === I18N_MODULE_KEYS["content.text.preview"]) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, preview: true, threeDotMenuOpen: false }));
    } else if (type === I18N_MODULE_KEYS["content.transcreation.footer.button.viewsummary"]) {
      dispatch(
        setResponseCtAction({
          ...selectedEmailDoc,
          email_doc_id: selectedEmailDoc?.email_id,
          storyboard_id: selectedEmailDoc?.storyboard?.id,
          video_id: selectedEmailDoc?.video?.id,
          html_id: selectedEmailDoc?.html?.id,
          blog_url: selectedEmailDoc?.source === "blog" ? selectedEmailDoc?.filename : "",
          isVideoRendered: selectedEmailDoc?.video?.status === enumDocStatus.COMPLETED ? true : false
        })
      );

      setTimeout(() => {
        dispatch(
          setActionDrawerDataAction({
            drawerTitle: t(I18N_MODULE_KEYS["content.transcreation.button.summary"]),
            drawerOpen: true,
            componentId: "ViewSummary"
          })
        );
      }, 10);
    } else {
      dispatch(setNotificationMsgAction("This feature is work in progress."));
    }
  };
  // const handleMenuItemClick = (type: string) => {
  //   if (type === "Delete") {
  //     DeleteFile(selectedEmailDoc?.email_id, dispatch);
  //   } else if (type === "Rename") {
  //     dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, rename: true, threeDotMenuOpen: false }));
  //   } else if (type === "Translate") {
  //     dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, translate: true, threeDotMenuOpen: false }));
  //   } else if (type === "Download") {
  //     dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, download: true, threeDotMenuOpen: false }));
  //   } else if (type === "Preview") {
  //     dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, preview: true, threeDotMenuOpen: false }));
  //   } else {
  //     dispatch(setNotificationMsgAction("This feature is work in progress."));
  //   }
  // };
  return (
    <Space onClick={() => handleMenuItemClick(title)} style={{ width: "100%" }}>
      {icon}
      {/* <Text style={{ opacity: 1, fontSize: "12px", fontWeight: "bold" }}>{title}</Text> */}
      <Text style={{ opacity: 1, fontSize: "12px", fontWeight: "bold" }}>{`${t(title)}`}</Text>
    </Space>
  );
};
