import { FC } from "react";
import Lottie from "lottie-react";
import { Card, Col, Row } from "antd";
import ReactPlayer from "react-player";
import { CT_DASHBOARD_STATUS } from "../../../../../Utils";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";

type IProps = {
  status: string;
  video_url: string;
};

export const VideoProcessScreen: FC<IProps> = ({ status, video_url }) => {
  return (
    <Row gutter={[16, 24]}>
      <Col xs={{ span: 24 }}>
        <Card className="d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            {status === CT_DASHBOARD_STATUS.SUCCESS ? (
              <ReactPlayer url={video_url || ""} playing={true} controls />
            ) : (
              <Lottie
                id="video_gen_ai"
                animationData={PROJECT_ICONS.VIDEO_GENERATION_ANIME}
                loop={true}
                style={{ maxWidth: "33%", marginBottom: "10vh" }}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};
