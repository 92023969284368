import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { Button, Checkbox, Col, Flex, Input, Modal, Row, Typography } from "antd";
import { ListKeyDataTypes, ListKeyMessagesTypes, UpdateAssetGuide } from "./core/modal";
import { apiResponse } from "../../Utils/_gTypes";
import _iDetailExtractRequests from "../../Store/requests/_iDetailExtractRequests";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedCategoriesIDXAction } from "../../Store/actions/_iDetailExtractActions";
import { SearchOutlined } from "@ant-design/icons";

type IProps = {
  openModal: boolean;
  closeModal: () => void;
  afterProceed: (data: UpdateAssetGuide) => void;
  listKeyMessages: ListKeyMessagesTypes | null;
  setListKeyMessage: (data: ListKeyMessagesTypes) => void;
};

const { Search } = Input;
const { Text } = Typography;

const enumCurrentTab: { SUGGESTED: string; ADDITIONAL: string } = {
  SUGGESTED: "suggested",
  ADDITIONAL: "additional"
};

export const CategoriesModal: FC<IProps> = ({ openModal, closeModal, afterProceed, listKeyMessages, setListKeyMessage }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { uploadedPDFData, assetGuideData, openCategoryModal } = useSelector((state: any) => state?.iDetailExtractReducer);

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`idetailextract._CategoriesModal.${value as string}`])}`;
  };

  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchedVal, setSearchedVal] = useState<string>("");
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<ListKeyDataTypes[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(enumCurrentTab.SUGGESTED);

  useEffect(() => {
    if (listKeyMessages && listKeyMessages[currentTab]) {
      checkIsAllCheckboxSelected();
      setFilteredData(
        listKeyMessages[currentTab]?.filter((item: ListKeyDataTypes) => item?.name?.toLocaleLowerCase?.()?.includes(searchedVal?.toLowerCase?.()))
      );
    }
  }, [listKeyMessages, currentTab]);

  useEffect(() => {
    if (openCategoryModal === false) {
      setCurrentTab(enumCurrentTab.SUGGESTED);
    }
  }, [openCategoryModal]);

  useEffect(() => {
    getTotalCategoriesSelected();
  }, [filteredData, listKeyMessages, currentTab]);

  const handleOnSearch = (e: any) => {
    const { value } = e.target;

    setSearchedVal(value);

    if (listKeyMessages) {
      let arrListKeyMessage: ListKeyDataTypes[] = [...listKeyMessages[currentTab]];

      arrListKeyMessage = arrListKeyMessage.filter?.((item: ListKeyDataTypes) => item?.name?.toLocaleLowerCase?.()?.includes(value?.toLowerCase?.()));

      setFilteredData([...arrListKeyMessage]);
    } else setFilteredData([]);
  };

  const handleProceed = async () => {
    const arrKeyMessages = filteredData?.filter((item) => item?.is_selected === true)?.map((item) => item?.id);

    setProceedLoading(true);

    let res: apiResponse;

    /**
     * Here we have two conditions to proceed, one i sthe first user lands on categories modal and that time we will call POST api to save the categories,
     * and another one is that when this modal will opened from Core Claim screen and that we will call PATCH api to update the categories.
     * but in frontend how we will get to know that what api we have to call, so for that we have to check key assetguide_id in assetGuideData
     * if key already presents then simply call PATCH api and otherwise call POST api
     */
    if (assetGuideData?.assetguide_id) {
      res = await _iDetailExtractRequests.updateCategories({
        assetguide_id: assetGuideData.assetguide_id,
        keymessages: arrKeyMessages
      });
    } else {
      res = await _iDetailExtractRequests.createClaims({
        keymessages: arrKeyMessages,
        file_id: uploadedPDFData?.file_id
      });
    }

    setProceedLoading(false);

    if (res?.data?.assetguide_id) {
      afterProceed(res?.data);

      dispatch(setSelectedCategoriesIDXAction([...filteredData?.filter((item) => item?.is_selected === true)]));
    }
  };

  const handleCheckbox = (e: any, index: number, isLabel: boolean, item: ListKeyDataTypes) => {
    const objListKeyMessage: ListKeyMessagesTypes = { ...listKeyMessages };

    index = searchedVal ? objListKeyMessage[currentTab].indexOf(item) : index;

    if (isLabel) {
      objListKeyMessage[currentTab][index]["is_selected"] = !objListKeyMessage[currentTab][index]["is_selected"];
    } else {
      objListKeyMessage[currentTab][index]["is_selected"] = e.target.checked;
    }

    checkIsAllCheckboxSelected();

    setListKeyMessage({ ...objListKeyMessage });
  };

  const handleSelectAll = (isAllSelected: boolean) => {
    setIsAllSelected(isAllSelected);

    const arrFilteredData: ListKeyDataTypes[] = [...filteredData];

    arrFilteredData?.forEach((item: ListKeyDataTypes) => {
      if (isAllSelected) {
        item.is_selected = true;
      } else {
        item.is_selected = false;
      }
    });

    setFilteredData([...arrFilteredData]);
  };

  const checkIsAllCheckboxSelected = () => {
    if (listKeyMessages && listKeyMessages[currentTab].length === listKeyMessages[currentTab]?.filter((i) => i.is_selected === true)?.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  };

  const getTotalCategoriesSelected = () => {
    const nFilterCount = filteredData?.filter((item: ListKeyDataTypes) => item?.is_selected === true).length;
    const strOppositeTab: string = currentTab === enumCurrentTab.SUGGESTED ? enumCurrentTab.ADDITIONAL : enumCurrentTab.SUGGESTED;
    const nOtherCount: number | undefined = listKeyMessages?.[strOppositeTab]?.filter((item: ListKeyDataTypes) => item?.is_selected === true)?.length;

    //@ts-ignore
    setTotalCount(nFilterCount + nOtherCount);
  };

  useEffect(() => {
    console.log(currentTab, "currentTab");
  }, [currentTab]);

  const itemsPerColumn = 3;
  const columns = Math.ceil(filteredData.length / itemsPerColumn);

  return (
    <Modal
      title={getTranslatedText("Identified Categories")}
      style={{ top: 20 }}
      open={openModal}
      maskStyle={{ backdropFilter: "blur(2px)" }}
      styles={{ body: { backdropFilter: "blur(2px)" } }}
      centered
      keyboard={false}
      closeIcon={false}
      maskClosable={false}
      width={600}
      footer={() => (
        <Flex justify="space-between">
          <Text style={{ fontSize: "12px", fontWeight: "400" }}>
            {getTranslatedText("Selected Categories")} | {totalCount}
          </Text>

          <Flex gap="small">
            <Button key="submit" type="link" onClick={closeModal} style={{ color: "rgba(46, 39, 43, 1)" }}>
              {t(I18N_MODULE_KEYS["content.button.cancel"])}
            </Button>
            <Button
              key="submit-proceed"
              type="primary"
              onClick={handleProceed}
              style={{
                background:
                  filteredData?.filter((item) => item.is_selected === true)?.length ||
                  listKeyMessages?.[currentTab === enumCurrentTab.SUGGESTED ? enumCurrentTab.ADDITIONAL : enumCurrentTab.SUGGESTED]?.filter(
                    (item: ListKeyDataTypes) => item?.is_selected === true
                  )?.length
                    ? "rgb(5, 70, 165)"
                    : "rgb(196 198 202)"
              }}
              disabled={
                filteredData?.filter((item: ListKeyDataTypes) => item?.is_selected === true).length ||
                listKeyMessages?.[currentTab === enumCurrentTab.SUGGESTED ? enumCurrentTab.ADDITIONAL : enumCurrentTab.SUGGESTED]?.filter(
                  (item: ListKeyDataTypes) => item?.is_selected === true
                )?.length
                  ? false
                  : true
              }
              loading={proceedLoading}
            >
              {t(I18N_MODULE_KEYS["content.button.proceed"])}
            </Button>
          </Flex>
        </Flex>
      )}
    >
      <Flex vertical gap="middle">
        <Input
          prefix={<SearchOutlined style={{ color: "#bfbfbf" }} />}
          placeholder={getTranslatedText("Search Catogory")}
          allowClear
          onChange={handleOnSearch}
          style={{ width: 304 }}
        />

        <Flex gap="middle">
          <Text
            style={{
              color: currentTab === enumCurrentTab.SUGGESTED ? "rgba(3, 78, 162, 1)" : "black",
              fontSize: "14px",
              fontWeight: "600",
              cursor: "pointer"
            }}
            onClick={() => setCurrentTab(enumCurrentTab.SUGGESTED)}
          >
            {getTranslatedText("Suggested")} ({listKeyMessages?.suggested?.length}){" "}
          </Text>
          <Text
            style={{
              color: currentTab === enumCurrentTab.ADDITIONAL ? "rgba(3, 78, 162, 1)" : "black",
              fontSize: "14px",
              fontWeight: "600",
              cursor: "pointer"
            }}
            onClick={() => setCurrentTab(enumCurrentTab.ADDITIONAL)}
          >
            {getTranslatedText("Additional")} ({listKeyMessages?.additional?.length}){" "}
          </Text>
        </Flex>

        <Flex gap={3}>
          <Checkbox checked={isAllSelected} onChange={() => handleSelectAll(!isAllSelected)} />
          <Button type="link" style={{ padding: "4px 3px" }} onClick={() => handleSelectAll(!isAllSelected)}>
            {`${t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.selectall"])}`}
          </Button>
        </Flex>

        {filteredData?.length ? (
          <ul
            style={{
              listStyleType: "none",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              height: "200px",
              overflowX: "scroll",
              gap: "12px 30px"
            }}
          >
            {filteredData?.map((colItem: ListKeyDataTypes, colIndex: number) => {
              return (
                <li key={`categories_modal_item_${colItem.id}_${colIndex}`}>
                  <Flex gap={"small"} align="center">
                    <Checkbox checked={colItem?.is_selected} onChange={(e) => handleCheckbox(e, colIndex, false, colItem)} />
                    <Text
                      style={{ fontSize: "12px", fontWeight: "400", cursor: "pointer" }}
                      onClick={(e) => handleCheckbox(e, colIndex, true, colItem)}
                    >
                      {colItem?.name}
                    </Text>
                  </Flex>
                </li>
              );
            })}
          </ul>
        ) : (
          <Flex justify="center" align="center" style={{ height: "200px" }}>
            <Flex justify="center" align="center">
              <Text style={{ fontSize: "13px", fontWeight: "400", color: "grey" }}>{getTranslatedText("No Data Found")}!</Text>
            </Flex>
          </Flex>
        )}

        <hr />
      </Flex>
    </Modal>
  );
};
