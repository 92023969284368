import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import Markdown from "markdown-to-jsx";

const { Paragraph } = Typography;

interface NextInsightPageProps {
    modalopenfiletype: any;
    paradata:any,
    isLoadingCoffee:any,
    selectedBoxName:string,
    s3_url:string
}

export const DocumentSimilarity: FC<NextInsightPageProps> = ({modalopenfiletype,paradata,isLoadingCoffee,selectedBoxName,s3_url}) => {
  const {searchQuery} = useSelector((state: any) => state.semanticSearchReducer);
  const openS3LinkInNewTab = (link: any) => {
    window.open(link, "_blank");
  };
  
  return (
    
   <>
              <Card className="Card">
                {isLoadingCoffee ? (
                  <>
                    <Lottie style={{ height: "300px", marginTop: "50px" }} animationData={PROJECT_ICONS.CUP} loop={true} />
                    <div style={{ textAlign: "center", font: "normal normal 600 18px/30px Nunito Sans", letterSpacing: "0px", color: "#66676B" }}>
                      Grab a Cup of Coffee
                    </div>
                    <div style={{ textAlign: "center", font: "normal normal 600 18px/30px Nunito Sans", letterSpacing: "0px", color: "#66676B" }}>
                      Loading is in Progress
                    </div>
                  </>
                ) : (
                  <Row style={{ height: "100%" }}>
                    <Col span={24} style={{ marginTop: "7px" }}>
                      <Row>
                        <Col span={24}>
                          <Paragraph
                            type="secondary"
                            style={{
                              color: "#000000",
                              font: "normal normal 400 16px/14px Nunito Sans",
                            }}
                          >
                            Search Query : {searchQuery}
                          </Paragraph>
                        </Col>
                        <Col
                          span={24}
                          style={{ cursor: "pointer" }}
                          // openS3LinkInNewTab(paradata?.s3_url
                        >
                          <Paragraph
                            type="secondary"
                            style={{
                              font: "normal normal 700 18px/14px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#000000",
                              opacity: "1",
                              cursor: "pointer",
                              textDecoration: "none", // Initial state: no underline
                            }}
                            // Apply underline on hover
                            onMouseEnter={(e) => {
                              e.currentTarget.style.textDecoration = "underline";
                            }}
                            // Remove underline when not hovering
                            onMouseLeave={(e) => {
                              e.currentTarget.style.textDecoration = "none";
                            }}
                            onClick={() => {
                              openS3LinkInNewTab(s3_url);
                            }}
                          >
                            {selectedBoxName}
                          </Paragraph>
                        </Col>
                        <Col span={24}>
                          <div>
                            <Row>
                              <img src={PROJECT_ICONS.COPY} style={{ paddingRight: "10px", cursor: "pointer", height: "14px" }} />
                              <img src={PROJECT_ICONS.SHARE_ICON} style={{ paddingRight: "10px", height: "14px" }} />

                              <img src={PROJECT_ICONS.DOWNLOAD_ICON} style={{ paddingRight: "10px", cursor: "pointer", height: "14px" }} />

                              <img src={PROJECT_ICONS.THUMBS_UP} style={{ paddingRight: "10px", cursor: "pointer", height: "14px" }}></img>

                              <img src={PROJECT_ICONS.THUMBS_DOWN} style={{ cursor: "pointer", height: "14px" }}></img>
                            </Row>
                          </div>
                        </Col>
                        <Row style={{ paddingTop: "20px" }} gutter={48}>
                          {modalopenfiletype === "Document" ? (
                            <></>
                          ) : (
                            <Col
                              span={9}
                              style={{
                                background: "#E5F1F8",
                                padding: "10px",
                                marginLeft: "20px",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            ></Col>
                          )}
                          <Col
                            span={modalopenfiletype === "Document" ? 24 : 14}
                            style={{
                              textAlign: "left",
                              font: "normal normal normal 14px/26px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#66676B",
                              opacity: "1",
                              lineHeight: "26px",
                              marginTop: "0px",
                              overflowY: "scroll",
                              width: "60%",
                              height: "380px",
                            }}
                          >
                            {paradata?.map((data: any) => (
                              <div
                                key={data.id}
                                style={{
                                  padding: "20px",
                                  paddingLeft: "25px",
                                  background: "rgb(237, 244, 255)",
                                  marginBottom: "20px",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 10px #0000001F",
                                }}
                              >
                                <Markdown>{data.content}</Markdown>
                                <div style={{ display: "flex", fontSize: "13px" }}>
                                  <span style={{ fontWeight: "bold" }}>Page Number</span> : {data.page_number}
                                </div>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Card>
    </>
  );
};
