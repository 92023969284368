import { UploadFile } from "antd";
import {
  IDX_SET_ACTIVE_SCREEN,
  IDX_SET_ASSET_GUIDE_DATA,
  IDX_SET_ASSET_MAIN_DATA,
  IDX_SET_CLICKED_PAGE_NUMBER,
  IDX_SET_OPEN_CATEGORY_MODAL_STATE,
  IDX_SET_OPEN_HTML_OUTPUT_MODAL,
  IDX_SET_SELECTED_CATEGORIES,
  SET_UPLOADED_PDF_DATA
} from "../constants/_iDetailExtractConstants";
import { AssetMainDataTypes, ListKeyDataTypes, UpdateAssetGuide } from "../../Pages/IDetail Extract/core/modal";
import { enumIDXActiveScreen } from "../../Pages/IDetail Extract/core/constant";

type IntialState = {
  activeScreen: number;
  uploadedPDFData: UploadFile | null;
  selectedCategories: ListKeyDataTypes[];
  openCategoryModal: boolean;
  assetGuideData: UpdateAssetGuide | null;
  clickedPageNumber: number;
  assetMainData: AssetMainDataTypes | null;
  openHtmlOutputModal: boolean;
};

const initialState: IntialState = {
  activeScreen: enumIDXActiveScreen.UPLOAD_PDF,
  uploadedPDFData: null,
  selectedCategories: [],
  openCategoryModal: false, // this state is to handle category modal open or close from upload pdf screen and core claim screen
  assetGuideData: null,
  clickedPageNumber: 1,
  assetMainData: null,
  openHtmlOutputModal: false
};

export default function iDetailExtractReducer(state: IntialState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case SET_UPLOADED_PDF_DATA: {
      return {
        ...state,
        uploadedPDFData: payload
      };
    }
    case IDX_SET_SELECTED_CATEGORIES: {
      return {
        ...state,
        selectedCategories: payload
      };
    }
    case IDX_SET_OPEN_CATEGORY_MODAL_STATE: {
      return {
        ...state,
        openCategoryModal: payload
      };
    }
    case IDX_SET_ASSET_GUIDE_DATA: {
      return {
        ...state,
        assetGuideData: payload
      };
    }
    case IDX_SET_ACTIVE_SCREEN: {
      return {
        ...state,
        activeScreen: payload
      };
    }
    case IDX_SET_CLICKED_PAGE_NUMBER: {
      return {
        ...state,
        clickedPageNumber: payload
      };
    }
    case IDX_SET_ASSET_MAIN_DATA: {
      return {
        ...state,
        assetMainData: payload
      };
    }
    case IDX_SET_OPEN_HTML_OUTPUT_MODAL: {
      return {
        ...state,
        openHtmlOutputModal: payload
      };
    }
    default: {
      return state;
    }
  }
}
