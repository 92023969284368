import { FC, PropsWithChildren, ReactNode } from "react";

import clsx from "clsx";
import { DrawerHeaderItem } from "../State";
import { Col, Row } from "antd";

type Props = {
  drawerId?: string;
  drawerName?: string;
  drawerWidth?: string;
  className?: string;
  drawerTitle?: string;
  drawerDescription?: string;
  isFooter?: boolean;
  isBack?: boolean;
  goBack?: () => void;
  onClose?: () => void;
  headerOptions?: Array<DrawerHeaderItem>;
};

const DrawerHeader: FC<Props & PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

const DrawerBody: FC<Props & PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <Row>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

const DrawerFooter: FC<Props & PropsWithChildren> = (props) => {
  const { drawerId, children } = props;

  return (
    <Row
      style={{
        position: "sticky",
        top: "100vh",
        height: "55px",
        borderTop: "3px dotted gainsboro",
        marginTop: "30px"
      }}
    >
      <Col span={24}>{children}</Col>
    </Row>
  );
};

export { DrawerHeader, DrawerBody, DrawerFooter };
