export default function MSOfficeDocsPreview({ file }: { file: { url: string } }) {
  return (
    <div id="msdoc-renderer" style={{ width: "100%", height: "100%" }}>
      <iframe
        id="msdoc-iframe"
        title="msdoc-iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.url)}`}
        style={{ width: "100%", height: "100%", border: 0 }}
      />
    </div>
  );
}
