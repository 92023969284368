/* eslint-disable jsx-a11y/alt-text */
import { Button, Modal, Row, Tabs, Tooltip } from "antd";
import Icon from "../common/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";
import { setActiveObjectModifier, setCTLSideBarOpenAction } from "../../../../../Store/actions/_contentTranscreationActions";
import { useDispatch } from "react-redux";
import { TextControls } from "./text/TextControls";
import { AvatarDrawer } from "./avatar/_AvatarDrawer";
import { AvatarControls } from "./avatar/AvatarControls";
import { ElementsTab } from "./elements/ElementsTab";
import Search from "antd/es/input/Search";
import { UploadsTab } from "./uploads/UploadsTab";
import { textTypes } from "../../fabric-files/utils/canvas";

export const Sidebar = (/* props */) => {
  const { sideBarOpen, canvasActiveObject, slidePlaying } = useSelector((state: RootState) => state.contentTranscreationReducer);

  console.log("slidePlaying", slidePlaying);
  const dispatch = useDispatch();

  const [activeSidebarTab, setActiveSidebarTab] = useState("elements");

  const arrowIcon = <Icon name={sideBarOpen ? "right_arrow" : "left_arrow"} />;

  const itemList = [
    {
      key: "presenter",
      disabled: slidePlaying,
      label: (
        <div className="main-menu-item">
          <Icon name="user" style={{ height: "20px", width: "20px" }} />
          <span className="tab-text">Avatar</span>
        </div>
      ),
      children: (
        <div style={{ width: "345px", padding: "8px", pointerEvents: slidePlaying ? "none" : "auto" }}>
          <AvatarControls />
        </div>
      )
    },
    {
      key: "text",
      disabled: slidePlaying,
      label: (
        <div className="main-menu-item">
          <Icon name="text" />
          <span className="tab-text">Text</span>
        </div>
      ),
      children: (
        <div style={{ width: "345px", padding: "8px", pointerEvents: slidePlaying ? "none" : "auto" }}>
          <TextControls />
        </div>
      )
    },
    {
      key: "elements",
      disabled: slidePlaying,
      label: (
        <div className="main-menu-item">
          <Icon name="elements" className="tab-icon" />
          <span className="tab-text">Elements</span>
        </div>
      ),
      children: (
        <div style={{ width: "345px", padding: "8px", height: "100%", pointerEvents: slidePlaying ? "none" : "auto" }}>
          <ElementsTab />
        </div>
      )
    },
    {
      key: "uploads",
      disabled: slidePlaying,
      label: (
        <div className="main-menu-item">
          <Icon name="upload" className="tab-icon" />
          <span className="tab-text">Uploads</span>
        </div>
      ),
      children: (
        <div style={{ width: "345px", padding: "8px", pointerEvents: slidePlaying ? "none" : "auto" }}>
          <UploadsTab />
        </div>
      )
    }
  ];

  const activeObjectType = useMemo(
    () =>
      canvasActiveObject?.type === "activeSelection"
        ? canvasActiveObject.getObjects().every((obj: any) => obj.type === canvasActiveObject.getObjects()[0].type) ||
          canvasActiveObject.getObjects().every((obj: any) => textTypes.includes(obj.type))
          ? canvasActiveObject.getObjects()[0].type
          : null
        : canvasActiveObject?.type,
    [canvasActiveObject, canvasActiveObject?._objects]
  );

  /**
   * Deselect canvas active object if sidebar is closed
   */
  useEffect(() => {
    if (!sideBarOpen && canvasActiveObject) {
      dispatch(setActiveObjectModifier("discardActiveObject"));
    }
  }, [sideBarOpen]);

  /**
   * Manage sidebar when canvasActiveObject changes
   */
  useEffect(() => {
    if (canvasActiveObject) {
      const isActiveObjectText =
        (canvasActiveObject.type === "activeSelection"
          ? canvasActiveObject.getObjects().every((obj: any) => textTypes.includes(obj.type))
          : textTypes.includes(canvasActiveObject.type)) || textTypes.includes(activeObjectType);

      if (!sideBarOpen) {
        dispatch(setCTLSideBarOpenAction(true));
      }
      if (activeObjectType === "avatar") {
        setActiveSidebarTab("presenter");
      } else if (isActiveObjectText) {
        setActiveSidebarTab("text");
      } else setActiveSidebarTab("elements");
    } else {
      setActiveSidebarTab("elements");
    }
  }, [canvasActiveObject, canvasActiveObject?._objects]);

  const onTabClick = (key: any) => {
    if (!slidePlaying) {
      setActiveSidebarTab(key);
    }
    if (key === "text") {
      dispatch(setActiveObjectModifier({ newObject: "text" }));
    } else if (key === "elements" && canvasActiveObject) {
      dispatch(setActiveObjectModifier("discardActiveObject"));
    }
    dispatch(setCTLSideBarOpenAction(true));
  };

  const displaySidebar = () => {
    console.log("clicked", sideBarOpen);
    dispatch(setCTLSideBarOpenAction(!sideBarOpen));
  };

  return (
    <>
      <div className={`sidebar-wrapper ${sideBarOpen ? "open" : ""}`}>
        <Button icon={arrowIcon} className="btn-collapse-sidebar" onClick={displaySidebar} />
        <Tabs
          activeKey={activeSidebarTab}
          onTabClick={onTabClick}
          className={`sidebar ${sideBarOpen ? "" : "close"}`}
          tabPosition="right"
          items={itemList}
          style={{ cursor: slidePlaying ? "not-allowed" : "auto" }}
        />
      </div>
    </>
  );
};
