import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Col, Row, Skeleton, Space, Spin, Typography } from "antd";
import { SlidesList } from "./components/SlidesList";
import { SlideThumbnail, VoiceListType } from "./core/modals";
import { CanvasList } from "./components/CanvasList";
import { NarrationAreaList } from "./components/NarrationAreaList";
import { DataType } from "../Content History/core/modals";
import "./core/contentTranslation.css";
import DHeader from "../../../Layouts/Spaces/_header";
import { LeftOutlined, LoadingOutlined, PauseOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT, ENUM_ROUTE_URLS } from "../../../Utils";
import { cardStyle, getELaiResponse } from "./core/constants";
import { RenderVideo, getVoiceListRequest } from "../../../Store/requests/_contentTranscreationRequests";
import { Authorizer, siteConfig } from "../../../Services";
import { ENUM_PROCESS_SCREENS } from "../Core/constants";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setCTLActiveSlideAction,
  setCanvasActiveObject,
  setElaiTranslationDataAction,
  setResponseCtAction
} from "../../../Store/actions/_contentTranscreationActions";
import { setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import { useDispatch } from "react-redux";
import TextToSpeech from "../../../Components/Text To Speech/_TextToSpeech";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { MenuI18N } from "../../../Components/i18n";
import { getVoiceListAction } from "../../../Components/Player";
import { latestData2, latestData3, latestData4 } from "./core/contentTranslationMockData";
import { useGlobalPlayer } from "./utils/useGlobalPlayer";
import _localStorageService from "../../../Services/_localStorageService";
import { RootState } from "../../../Store";
import { Sidebar } from "./components/sidebar/SideBar";
import { setActionDrawerDataAction } from "../../../Components/ActionDrawer/State";
import { HeaderControls } from "./components/header/HeaderControls";
const { Paragraph, Text } = Typography;

const authorizer = new Authorizer();

export const ContentTranslationWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const workingAreaRef = useRef<HTMLDivElement>(null);

  const { elaiResponse, activeSlide, sideBarOpen /* canvasActiveObject, activeObjectModifier */ } = useSelector(
    (state: RootState) => state?.contentTranscreationReducer
  );
  //let { elaiResponse } = useSelector((state: any) => state?.contentTranscreationReducer);
  //let elaiResponse: any = {};
  //if (!Object.keys(elaiResponse).length) elaiResponse = latestData4; //latestData2;

  console.log({ elaiResponse });
  const { responseCT }: { selectedEmailDoc: DataType; responseCT: any } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [rendering, setRendering] = useState<boolean>(false);
  const [thumbnails, setThumbnails] = useState<SlideThumbnail[]>([]);
  const [canvasList, setCanvasList] = useState<fabric.Canvas[]>([]);
  const [audioList, setAudioList] = useState([]);
  const [previewActive, setPreviewActive] = useState(false);

  console.log({ canvasList, audioList });

  useEffect(() => {
    initiate();
  }, []);

  useEffect(() => {
    // if (!elaiResponse?.activeSlide) {
    //   dispatch(setElaiTranslationDataAction({ ...elaiResponse, activeSlide: 0 }));
    // }

    setTimeout(() => {
      getVoiceList();
    }, 1000);

    // getELaiResponse({}, 96, elaiResponse?.storyboard_id, elaiResponse?.locale, dispatch, navigate, false);
  }, []);

  const initiate = async () => {
    const isValid = await authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);
    if (!isValid) return;
  };

  useEffect(() => {
    removeGifObject(elaiResponse);

    //on elaiResponse update, clear the audio cache of the activeSlide
    //Voice change is getting applied to all the slides, hence clear the audioCache.
    //Later clear the audio of activeSlide only when slide's voice or narration is updated.
    // console.log("elaiResponse updated, deleting audio of activeSlide");
    // //delete audioList[activeSlide];
    // audioList.length = 0;
  }, [elaiResponse]);

  useEffect(() => {
    if (!elaiResponse || !elaiResponse.slides) {
      return;
    }

    const duration = elaiResponse.slides.reduce(
      //@ts-ignore
      (duration, slide) => duration + (slide.duration || slide.approxDuration || 0),
      0
    );
    setGlobalPlayer((p) => ({ ...p, duration }));
  }, [elaiResponse]);

  useEffect(() => {
    dispatch(setCanvasActiveObject(null));
  }, [activeSlide]);

  const updateActiveSlide = (activeSlide: number) => {
    dispatch(setCTLActiveSlideAction(activeSlide));
  };

  const removeGifObject = (elaiResponse: any) => {
    if (!Object.keys(elaiResponse).length) return;
    let slides = elaiResponse?.slides;

    let gifFound = false;
    slides &&
      slides.forEach((slide: any) => {
        let canvas = slide.canvas;
        let canvasObjects = canvas.objects;
        //@ts-ignore
        canvas.objects = canvasObjects.filter((obj) => {
          let found = obj.type?.toLowerCase() === "gif";
          found && (gifFound = true);
          return !found;
        });
      });
    gifFound && dispatch(setElaiTranslationDataAction({ ...elaiResponse, slides }));
  };

  const { globalPlayer, setGlobalPlayer, playVideo, stopVideo, onStopPlaying } = useGlobalPlayer({
    video: elaiResponse,
    audioCache: audioList,
    activeSlide,
    updateActiveSlide: updateActiveSlide,
    setActiveSlideWithUrl: updateActiveSlide,
    audioList,
    setAudioList
  });

  useEffect(() => {
    setPreviewActive(globalPlayer.playing);
  }, [globalPlayer.playing]);

  const isPlaying = globalPlayer.playing;

  console.log("CTW globalPlayer", globalPlayer.playing);
  const previewVideo = () => {
    playVideo();
  };

  const handlePause = () => {
    if (isPlaying) setGlobalPlayer((p) => ({ ...p, playing: false }));
  };

  const handleRenderRequest = async () => {
    setRendering(true);

    const res = await RenderVideo({ video_id: elaiResponse?.video_id, avatar_id: elaiResponse?.slides?.[activeSlide]?.avatar?.status });

    setRendering(false);

    if (!res) {
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.translation.translationwrapper.notification.videorendererror"])));
      return;
    }

    localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN);

    handleNavigation(
      CONTENT_TRANSCREATION_STEPS.STEP_3,
      {
        ...responseCT,
        storyboard_id: res?.video
      },
      ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_VIDEO
    );
  };

  const handleNavigation = (step: number, response: any, btnTxt: string) => {
    dispatch(setActiveStepperStateAction(step));

    dispatch(setResponseCtAction({ ...response }));

    dispatch(setButtonTextStateAction(btnTxt));

    navigate(ENUM_ROUTE_URLS.CONTENT_TRANSCREATION);
  };

  const getVoiceList = async () => {
    const res: VoiceListType[] = await getVoiceListRequest();

    console.log(res, "voice list");

    if (res) {
      dispatch(getVoiceListAction([...res]));
    }
  };

  return (
    <div className="content-translation">
      {/* <TextToSpeech key="content-translation" /> */}
      <>
        <DHeader
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            background: "#0546a5",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white"
          }}
        >
          <Row>
            <Col xs={{ span: 1 }} className="d-flex align-items-center">
              <LeftOutlined style={{ cursor: "pointer" }} onClick={() => navigate(ENUM_ROUTE_URLS.CONTENT_HISTORY)} />
            </Col>
            <Col xs={{ span: 11 }} className="d-flex align-items-center">
              <Paragraph
                className="optimize-font"
                type="secondary"
                style={{
                  fontSize: "15px",
                  marginBottom: "0px",
                  font: "normal normal bold 16px/24px Nunito Sans",
                  color: "white"
                }}
              >
                {elaiResponse?.name}
              </Paragraph>
            </Col>
            <Col xs={{ span: 12 }} className="d-flex justify-content-end" style={{ alignItems: "center" }}>
              <Space>
                {(!globalPlayer.playing || globalPlayer.preloading) && <HeaderControls setAudioList={setAudioList} />}
                {(!globalPlayer.playing || globalPlayer.preloading) && (
                  <Button
                    type="link"
                    style={{ color: "white" }}
                    disabled={Object.values(canvasList).length !== elaiResponse?.slides?.length || globalPlayer.preloading}
                    loading={Object.values(canvasList).length !== elaiResponse?.slides?.length || globalPlayer.preloading} //{globalPlayer.preloading}
                    // type="default"
                    icon={<PlayCircleOutlined />}
                    onClick={previewVideo}
                  >
                    {/* Preview */}
                    {t(I18N_MODULE_KEYS["content.text.preview"])}
                  </Button>
                )}

                {globalPlayer.playing && !globalPlayer.preloading && (
                  <Button
                    key="playStop"
                    type="link"
                    style={{ color: "white", border: "1px solid white" }}
                    icon={<PauseOutlined />}
                    onClick={stopVideo}
                  >
                    Stop
                  </Button>
                )}

                <Button style={{ color: "#0546A5", background: "white" }} onClick={handleRenderRequest} disabled={elaiResponse?.is_render}>
                  {/* <Text style={{ textAlign: "left", fontSize: "14px", letterSpacing: "0px", color: "#0546A5", opacity: 1 }}>Render</Text> */}
                  <Text style={{ textAlign: "left", fontSize: "14px", letterSpacing: "0px", color: "#0546A5", opacity: 1 }}>
                    {t(I18N_MODULE_KEYS["content.text.render"])}
                  </Text>
                  {rendering ? <LoadingOutlined /> : <></>}
                </Button>

                <MenuI18N />
              </Space>
            </Col>
          </Row>
        </DHeader>
        <Row gutter={[8, 8]} style={{ padding: "12px" }}>
          <Col xs={{ span: 4 }} style={{ height: "calc(100vh - 64px - 48px)", overflowY: "scroll" }}>
            <Card style={{ ...cardStyle }} styles={{ body: { padding: 0 } }}>
              <SlidesList thumbnails={thumbnails} activeSlide={activeSlide || 0} setActiveSlide={updateActiveSlide} />
            </Card>
          </Col>
          <Col
            xs={{ span: sideBarOpen ? 13 : 19 }}
            style={{ height: "calc(100vh - 64px - 48px)", overflowY: "scroll" /* , padding: "8px"  */ }}
            ref={workingAreaRef}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card style={{ ...cardStyle }}>
                  {elaiResponse?.slides && (
                    <CanvasList
                      slides={elaiResponse?.slides && elaiResponse?.slides?.length ? elaiResponse?.slides : []}
                      workingAreaRef={workingAreaRef}
                      setThumbnails={setThumbnails}
                      activeSlide={activeSlide || 0}
                      canvasList={canvasList}
                      setCanvasList={setCanvasList}
                      audioList={audioList}
                      setAudioList={setAudioList}
                      globalPlayer={globalPlayer}
                      setGlobalPlayer={setGlobalPlayer}
                      handlePause={handlePause}
                      onStopPlaying={onStopPlaying}
                    />
                  )}
                </Card>
              </Col>
              <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                <Card style={{ ...cardStyle, width: "100%", display: previewActive ? "none" : "block" }}>
                  {elaiResponse?.slides && (
                    <NarrationAreaList
                      slides={elaiResponse?.slides}
                      activeSlide={activeSlide || 0}
                      setAudioList={setAudioList}
                      globalPlayer={globalPlayer}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: sideBarOpen ? 7 : 1 }}>
            <Sidebar />
          </Col>
        </Row>
      </>
    </div>
  );
};
