import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { Progress } from "antd";

export const ProgressBar = ({
  uploading,
  error,
  percent,
  isUploaded
}: {
  uploading: boolean;
  error: boolean;
  percent: number;
  isUploaded: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!error && (
        <>
          <div className="upload-progress-bar-area">
            <div
              className={"upload-progress-bar " + (uploading ? "shimmer" : "")}
              style={{
                width: (percent / 100) * 100 + "%",
                background: isUploaded ? "#368a04" : ""
              }}
            ></div>
          </div>
          <div className="upload-percentage">{percent}%</div>
        </>
      )}
    </>
  );
};
