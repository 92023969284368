import { FC } from "react";
import { LLMFilteredTagsProps } from "../../Core/types";
import { useSelector } from "react-redux";
import { CONTENT_AI_MODULES } from "../../Core/constants";
import { t } from "i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const LLMFilteredTags: FC<LLMFilteredTagsProps> = ({ props: { allTags, setAllTags, selectedUITags, setSelectedUITags } }) => {
  const { t } = useTranslation();
  
  const { activeModule } = useSelector((state: any) => state?.contentAIReducer);

  const handleUITagDeletion = (category: string, id: number) => {
    //update the main tags object which is used for populating left panel
    let allContentAITags = { ...allTags };
    let deSelectedTag: any = allContentAITags[category as keyof typeof allContentAITags].find((tag) => tag.id === id);
    deSelectedTag.selected = false;

    setAllTags(allContentAITags);

    //update array which is used in population of UI tag with delete button
    let prevsSelectedTags = [...selectedUITags];

    let indexOfDeselectedTag = prevsSelectedTags.findIndex((selectedTag) => selectedTag.id === id);
    prevsSelectedTags.splice(indexOfDeselectedTag, 1);
    setSelectedUITags(prevsSelectedTags);
  };

  return (
    <div /* className="col-12" */ style={{ padding: "0 15px /* 10px 15px */" /* , marginBottom: "15px" */ }}>
      {!selectedUITags.length ? (
        <div>
          {/* <i>No Filters Applied</i> */}
          <i>{t(I18N_MODULE_KEYS["content.text.nofilterapplied"])}</i>
        </div>
      ) : (
        <>
          <span style={{ color: "#66676B", marginRight: "15px", fontWeight: 600 }}>
            {/* Filters Applied | {(selectedUITags.length.toString().length === 1 ? "0" : "") + selectedUITags.length} */}
            {t(I18N_MODULE_KEYS["content.text.filterapplied"])} | {(selectedUITags.length.toString().length === 1 ? "0" : "") + selectedUITags.length}
          </span>
          <span id="tags">
            {selectedUITags.map((tag, key) => (
              <span key={key} style={{ textTransform: "capitalize" }}>
                {tag.name}
                {activeModule !== CONTENT_AI_MODULES.CONTENT_UNIVERSE && (
                  <i
                    onClick={() => {
                      handleUITagDeletion(tag.category, tag.id);
                    }}
                  >
                    x
                  </i>
                )}
              </span>
            ))}
          </span>
        </>
      )}
    </div>
  );
};
