export const esLocale = {
  /*Constantes principales*/
  ["app.name"]: "Inteligencia Artificial Generativa",
  ["app.logo"]: "extgen",

  /*Constantes de encabezado*/
  ["header.searchtext"]: "Buscar aquí...",
  ["header.menu.language.english"]: "Inglés",
  ["header.menu.language.spanish"]: "Español",
  ["header.menu.language.chinese"]: "Chino",
  ["header.menu.language.hindi"]: "Hindi",
  ["header.menu.myprofile"]: "Mi Perfil",
  ["header.menu.logout"]: "Cerrar Sesión",

  /*Constantes de la barra lateral*/
  ["sidebar.dashboard"]: "Tablero",
  ["sidebar.history"]: "Historial de Contenido",

  /*Constantes de módulos*/
  ["module.dashboard"]: "Tablero",
  ["module.transcreation"]: "Transcreación de Contenido",
  ["module.login"]: "Iniciar sesión",
  ["module.signup"]: "Registrarse",
  ["module.history"]: "Historial de Contenido",
  ["module.translation"]: "Traducción de Contenido",
  ["module.semantic"]: "Búsqueda Semántica",
  ["module.fieldcopilot"]: "Copiloto de Campo",
  ["module.contentcreation"]: "Creación de Contenido",
  ["module.idetailextract"]: "Extracción de Detalles",
  ["module.translation-module"]: "Traducción",

  /*Constantes de contenido*/
  ["content.dashboard.card.generativeai.title"]: "Inteligencia Artificial Generativa",
  ["content.dashboard.card.generativeai.desc"]:
    "La inteligencia artificial generativa (IA) es inteligencia artificial capaz de generar texto, imágenes u otros medios, utilizando modelos generativos. Los modelos de IA generativa aprenden los patrones y la estructura de los datos de entrenamiento de entrada y luego generan nuevos datos que tienen características similares.",

  ["content.dashboard.card.idetail.title"]: "iDetalleXtracción",
  ["content.dashboard.card.idetail.desc"]:
    "Motor impulsado por IA transformadora que convierte iDetail en una amplia gama de activos versátiles que van desde banners listos para imprimir hasta correos electrónicos personalizados, adaptados para satisfacer a audiencias diversas.",

  ["content.dashboard.card.translation-module.title"]: "Traducción de contenido",
  ["content.dashboard.card.translation-module.desc"]:
    "Herramienta alimentada por IA generativa para proporcionar traducción en tiempo real, consciente del contexto y la terminología en múltiples idiomas.",

  ["content.dashboard.card.transcreation.title"]: "Transcreación de Contenido",
  ["content.dashboard.card.transcreation.desc"]:
    "Proceso de crear y adaptar contenido de un idioma a otro, manteniendo su intención, contexto y estilo.",

  ["content.dashboard.card.semantic.title"]: "PRÓXIMO RESUMEN",
  ["content.dashboard.card.semantic.desc"]: "La búsqueda semántica avanzada transforma datos no estructurados en conocimientos accionables, automatiza la investigación y mejora las decisiones estratégicas.",
    
  ["content.dashboard.card.contentai.title"]: "IA de Contenido",
  ["content.dashboard.card.contentai.desc"]:
    "La IA proporciona soporte personalizado con habilidades de computación cognitiva, aprovechando su capacidad para entender, aprender y adaptarse a las necesidades individuales.",

  ["content.dashboard.card.fieldcopilot.title"]: "Copiloto de Campo",
  ["content.dashboard.card.fieldcopilot.desc"]:
    "Empoderando a los representantes con información sin igual sobre profesionales de la salud (HCP), percepciones de pacientes y recomendaciones avanzadas de productos farmacéuticos.",

  ["content.dashboard.card.kolvideo.title"]: "Video KOL",
  ["content.dashboard.card.kolvideo.desc"]:
    "Crea videos KOL sin problemas con avatares KOL generados por IA, generación de guiones alimentada por GenAI y una suite de edición integral.",

  ["content.dashboard.card.assistant.title"]: "Asistente de Tablero Gen AI",
  ["content.dashboard.card.assistant.desc"]:
    "Simplificando el análisis de datos con entradas de conversación en lenguaje natural, percepciones visuales interactivas y narrativas fáciles para una toma de decisiones informada y rápida.",

  ["content.dashboard.card.hyper.title"]: "Hiperpersonalización",
  ["content.dashboard.card.hyper.desc"]:
    "Adaptando experiencias a las preferencias y necesidades individuales, creando una interacción profundamente personalizada y significativa.",

  ["content.dashboard.card.patientjourney.title"]: "Mapeo del Viaje del Paciente",
  ["content.dashboard.card.patientjourney.desc"]:
    "Revolucionando la atención al paciente en la industria farmacéutica a través del mapeo del viaje del paciente impulsado por Gen AI, entendiendo y personalizando experiencias de atención médica.",

  ["content.dashboard.card.translationlocalisation.title"]: "Traducción y Localización de Contenido",
  ["content.dashboard.card.translationlocalisation.desc"]:
    "Adaptando contenido para audiencias globales mediante traducción y localización, asegurando relevancia cultural, precisión lingüística y atractivo regional.",

  ["content.dashboard.card.personalizedvideo.title"]: "Videos Personalizados",
  ["content.dashboard.card.personalizedvideo.desc"]:
    "Revoluciona la comunicación en el cuidado de la salud con videos personalizados para adaptar la entrega de información a profesionales de la salud (HCP) y pacientes.",

  ["content.dashboard.card.sentimentanalysis.title"]: "Análisis de Sentimientos",
  ["content.dashboard.card.sentimentanalysis.desc"]:
    "Utilizando la computación cognitiva para identificar y categorizar opiniones de usuarios en texto para un análisis y comprensión de sentimientos mejorados.",

  ["content.dashboard.card.contentcreation.title"]: "Creación de Contenido en Redes Sociales",
  ["content.dashboard.card.contentcreation.desc"]:
    "Crea contenido para redes sociales y transcrea mensajes fácilmente para redefinir la comunicación para profesionales de la salud (HCP), pacientes y marketing de HCP.",

  ["content.dashboard.card.button.description"]: "Descripción",
  ["content.dashboard.card.button.demo"]: "Demo",

  ["content.transcreation.activebar.heading.source"]: "Fuente",
  ["content.transcreation.activebar.heading.process"]: "Proceso",
  ["content.transcreation.activebar.heading.successful"]: "Exitoso",

  //Paso de selección de origen de transcreación
  ["content.transcreation.selectsource.1.title"]: "¿Qué te gustaría crear hoy?",
  ["content.transcreation.selectsource.1.card.video"]: "Video",
  ["content.transcreation.selectsource.1.card.social"]: "Publicación en Redes Sociales",
  ["content.transcreation.selectsource.1.card.banner"]: "Banner",
["content.transcreation.selectsource.1.card.journalad"]: "Anuncio de revista",
["content.transcreation.selectsource.1.card.literature"]: "Literatura para dejar",
  ["content.transcreation.selectsource.1.card.poster"]: "Póster",
  ["content.transcreation.selectsource.1.card.newsletter"]: "Boletín",
  ["content.transcreation.selectsource.1.card.brochure"]: "Folleto",

  ["content.transcreation.selectsource.2.title"]: "Selecciona una opción",
  ["content.transcreation.selectsource.2.card.email"]: "Correo Electrónico a Video",
  ["content.transcreation.selectsource.2.card.blog"]: "Blog a Video",
  ["content.transcreation.selectsource.2.card.banner"]: "Banner a video",
["content.transcreation.selectsource.2.card.socialmediapost"]: "Publicación en redes sociales a video",
  ["content.transcreation.selectsource.2.card.poster"]: "Póster a video",

  ["content.transcreation.source.card.upload"]: "Cargar Archivo",
  ["content.transcreation.source.card.pullClaims"]: "Extraer reclamaciones de DAMS",
["content.transcreation.source.card.uploadasset"]: "Subir activo y extraer reclamaciones",
  ["content.transcreation.source.card.uploaded"]: "Archivo Cargado",

  ["content.transcreation.source.card.text.drag"]: "Arrastra y suelta para cargar o haz clic en Examinar Archivo para elegir un archivo",
  ["content.transcreation.source.card.text.file"]: "Tipo de Archivo: PDF, Tamaño Máximo de Archivo: 30 MB",
  ["content.transcreation.source.card.text.nofileselected"]: "¡Ningún Archivo Seleccionado!",

  ["content.transcreation.source.card.text.subject"]: "Línea de Asunto",
  ["content.transcreation.source.card.text.image"]: "Imagen Utilizada",
  ["content.transcreation.source.card.text.body"]: "Cuerpo",

  ["content.transcreation.process.storyboard.title"]: "Crear Tablero de Historia",
  ["content.transcreation.process.storyboard.text.selectall"]: "Seleccionar Todo",
  ["content.transcreation.process.storyboard.text.deselectall"]: "Deseleccionar Todo",
  ["content.transcreation.process.storyboard.text.scene"]: "Escena",
  ["content.transcreation.process.storyboard.text.title"]: "Título",
  ["content.transcreation.process.storyboard.text.narration"]: "Narración",
  ["content.transcreation.process.storyboard.text.story"]: "historia",
  ["content.transcreation.process.storyboard.text.nostoryfound"]: "¡No se encontró ninguna historia!",

  ["content.transcreation.process.storyboard.modal.title.editstoryboard"]: "Editar Tablero de Historia",
  ["content.transcreation.process.selectimage.title.chooseplatform"]: "Elige tu Plataforma para Crear Imágenes/Clips",
  ["content.transcreation.process.generatedimage.title.imagegeneration"]: "Generación de Imágenes",
  ["content.transcreation.process.generatedimage.title.clipgeneration"]: "Generación de Clips",
  ["content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo"]: "Por favor, elige una o varias opciones para generar el video",
  ["content.transcreation.process.generatedimage.drawer.title.addliveclips"]: "Agregar Clips en Vivo",

  ["content.transcreation.process.template.title.chooseyourtemplate"]: "Elige tu Plantilla",
  ["content.transcreation.process.template.title.notemplatefound"]: "¡No se encontraron plantillas!",
  ["content.transcreation.process.avatar.title.chooseyouravatar"]: "Elige tu Avatar",
  ["content.transcreation.process.avatar.title.noavatarfound"]: "¡No se encontraron avatares!",

  ["content.transcreation.successful.title.videogeneration"]: "Generación de Video",
  ["content.transcreation.successful.title.generatevideo"]: "Generar vídeo",

  ["content.transcreation.viewsummary.drawer.title.summary"]: "Resumen",
  ["content.transcreation.viewsummary.drawer.title.storyboard"]: "Tablero de Historia",
  ["content.transcreation.viewsummary.drawer.title.images"]: "Imágenes",
  ["content.transcreation.viewsummary.drawer.title.templates"]: "Plantillas",
  ["content.transcreation.viewsummary.drawer.title.avatars"]: "Avatares",
  ["content.transcreation.viewsummary.drawer.title.clips"]: "Clips",

  //Pie de página de transcreación
  ["content.transcreation.footer.button.getstarted"]: "Empezar",
  ["content.transcreation.footer.button.viewdashboard"]: "Ver Tablero",
  ["content.transcreation.footer.button.regeneratedoc"]: "Regenerar Documento",
  ["content.transcreation.footer.button.createstoryboard"]: "Crear Tablero de Historia",
  ["content.transcreation.footer.button.regeneratestory"]: "Regenerar Historia",
  ["content.transcreation.footer.button.choosetemplate"]: "Elegir Plantilla",
  ["content.transcreation.footer.button.chooseavatar"]: "Elegir Avatar",
  ["content.transcreation.footer.button.viewsummary"]: "Ver Resumen",
  ["content.transcreation.footer.button.ordervideo"]: "Ordenar vídeo",

  ["content.transcreation.modal.ordervideosuccess"]: "El vídeo se ha ordenado con éxito",
  ["content.transcreation.modal.regneration"]: "¡Advertencia! El vídeo ya está generado.",
  ["content.transcreation.modal.regenerationdescription"]:
    "El contenido siguiente, como el guión gráfico, la imagen y el vídeo, se descartará y el vídeo se generará nuevamente. Quieres proceder?",
  //notification transcreation
  ["content.transcreation.steppers.1.notification.selectfile"]: "Por favor, selecciona un archivo para subir.",
  ["content.transcreation.steppers.1.notification.filesizeissue"]: "¡El tamaño del archivo supera el límite de 30 MB!",
  ["content.transcreation.steppers.1.notification.filetypeissue"]: "Tipo de archivo no admitido.",
  ["content.transcreation.steppers.2.notification.textcopied"]: "¡Texto copiado exitosamente!",
  ["content.transcreation.steppers.blogmodal.notification.fillblogurl"]: "Por favor, completa la URL del blog.",
  ["content.transcreation.steppers.blogmodal.notification.entervalidurl"]: "Por favor, ingresa una URL válida.",

  ["content.transcreation.wrapper.notification.postpublicationfail"]: "¡Error al publicar!",
  ["content.transcreation.wrapper.notification.postpublicationsuccess"]: "¡Publicación realizada con éxito!",
  ["content.history.notification.featureinprogress"]: "Esta característica está en proceso de desarrollo.",

  ["content.history.core.notification.filedeletionsuccess"]: "El archivo se ha eliminado correctamente.",
  ["content.history.core.notification.filerenamesuccess"]: "El archivo se ha renombrado correctamente.",
  ["content.history.core.notification.videodownloadsuccess"]: "El vídeo se ha descargado correctamente.",
  ["content.history.core.notification.videodownloaderror"]: "Se produjo un error al descargar el vídeo.",
  ["content.history.view.gridview.notification.videogenerationinprogress"]: "¡La generación del vídeo aún está en progreso!",
  ["content.translation.translationwrapper.notification.videorendererror"]: "Error al renderizar el vídeo.",
  ["content.translation.core.notification.limitexceed"]: "¡Se excede el límite! Los datos no se han actualizado.",
  ["content.translation.core.notification.noslide"]: "No hay diapositiva para traducir.",

  //content ai
  ["content.contentai.headings.contentai"]: "INTELIGENCIA DE CONTENIDO",
  ["content.contentai.headings.contentcreator"]: "CREADOR DE CONTENIDO",
  ["content.contentai.headings.llmexperience"]: "MARCADOR DE EXPERIENCIA LLM",
  ["content.contentai.headings.llmcompliance"]: "MARCADOR DE CUMPLIMIENTO LLM",
  ["content.contentai.headings.llmmlrguidline"]: "DIRECTRICES MLR DE LLM",
  ["content.contentai.headings.contentuniverse"]: "UNIVERSO DE CONTENIDOS",

  ["content.contentai.headings.contentai.text.mynameisline"]: "Mi Nombre es Lin",
  ["content.contentai.headings.contentai.text.howcanihelpyou"]: "¿Cómo puedo ayudarte?",
  ["content.contentai.headings.contentai.text.createpharmacontent"]: "Crear Contenido Farmacéutico con Especificidad de Dominio",
  ["content.contentai.headings.contentai.text.complianceandregulation"]: "Cumplimiento y Regulaciones",

  ["content.contentai.headings.contentai.text.enrichprompt"]: "Enriquecer Indicación",
  ["content.contentai.headings.contentai.text.revisedprompt"]: "Indicación Revisada",
  ["content.contentai.headings.contentai.text.appliedrules"]: "Reglas Aplicadas",
  ["content.contentai.headings.contentai.text.triggeredprompt"]: "Indicación Activada",
  ["content.contentai.headings.contentai.text.refinedresponse"]: "Respuesta Refinada",

  ["content.contentai.headings.llm.text.addnewrules"]: "Agregar Nuevas Reglas",
  ["content.contentai.headings.llm.text.norulefound"]: "No se Encontraron Reglas",
  ["content.contentai.headings.llm.text.enterrule"]: "Ingresar Regla",
  ["content.contentai.headings.llm.text.applicability"]: "Aplicabilidad",
  ["content.contentai.headings.llm.text.tagsupdated"]: "etiquetas actualizadas",

  ["content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags"]:
    "Contenido no disponible para la(s) etiqueta(s) seleccionada(s).",
  ["content.contentai.headings.contentuniverse.text.pleaseselectatag"]: "Por favor, selecciona una etiqueta.",
  ["content.contentai.headings.contentuniverse.text.step1"]: "PASO 1",
  ["content.contentai.headings.contentuniverse.text.step2"]: "PASO 2",
  ["content.contentai.headings.contentuniverse.text.fileupload"]: "Subida de Archivos",
  ["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"]: "Arrastra y suelta tus archivos aquí.",
  ["content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB"]: "Tamaño máximo de archivo para carga: 128 MB.",
  ["content.contentai.headings.contentuniverse.text.supportedfiletype"]: "Tipo de archivo admitido:",
  ["content.contentai.headings.contentuniverse.text.uploadedfile"]: "Archivo Subido",
  ["content.contentai.headings.contentuniverse.text.choosedetails"]: "Elegir Detalles",
  // Notificaciones de Content AI

  ["content.contentai.headings.contentuniverse.notification.contentdeleted"]: "¡Contenido eliminado!",
  ["content.contentai.headings.contentuniverse.notification.contentemptyerror"]: "¡El contenido no puede estar vacío!",
  ["content.contentai.headings.contentuniverse.notification.contentupdate"]: "¡Contenido actualizado!",
  ["content.contentai.LLM.notification.ruleemptyerror"]: "¡El nombre de la regla no puede estar vacío!",
  ["content.contentai.LLM.notification.newruleadded"]: "¡Nueva regla agregada!",
  ["content.contentai.LLM.LLMBasecomponent.notification.cancelled"]: "¡Cancelado!",
  ["content.contentai.LLM.LLMBasecomponent.notification.rulesapplied"]: "¡Reglas del Universo de Contenido aplicadas!",
  ["content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied"]: "¡Reglas de Prompt aplicadas!",
  ["content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat"]: "¡Formato no compatible!\n¡Solo se admiten archivos .xlsx!",

  // Módulo de búsqueda semántica, barra lateral flotante
  ["semantic.search.floatingsidebar.search"]: "Buscar",
  ["semantic.search.floatingsidebar.filemanagement"]: "Gestión de Archivos",

  // Búsqueda semántica
  ["semantic.search.header.heading.search"]: "Consulta de Búsqueda",
  ["semantic.search.landing.content.enterquery"]: "Ingrese la consulta para ver los resultados",
  ["semantic.search.landing.content.number"]: "Número de",
  ["semantic.search.landing.content.documentsindexed"]: "Documentos indexados",
  ["semantic.search.landing.content.insightsgiven"]: "Perspicacias dadas",
  ["semantic.search.landing.content.insightsdownloaded"]: "Perspicacias descargadas",
  ["semantic.search.input.heading.search"]: "BUSCAR",
  ["semantic.search.input.heading.entersearch"]: "Ingrese la consulta de búsqueda",
  ["semantic.search.input.heading.clear"]: "BORRAR",
  ["semantic.search.result.enterfeedback"]: "Ingrese su retroalimentación",
  ["semantic.search.loading.pleasewait"]: "Por favor espera...",
  ["semantic.search.loading.searchinprogress"]: "Tu búsqueda está en progreso",
  ["semantic.search.result.searchdepth"]: "Profundidad de Búsqueda",
  ["semantic.search.result.format"]: "Formato",
  ["semantic.search.result.regenerate"]: "Volver a Generar",
  ["semantic.search.result.depthoption.quickview"]: "Vista Rápida",
  ["semantic.search.result.depthoption.deepdive"]: "Inmersión Profunda",
  ["semantic.search.result.depthoption.fullspectrum"]: "Espectro Completo",
  ["semantic.search.result.depthoption.quickviewsmall"]: "vista rápida",
  ["semantic.search.result.depthoption.deepdivesmall"]: "inmersión profunda",
  ["semantic.search.result.depthoption.fullspectrumsmall"]: "espectro completo",
  ["semantic.search.result.formatoption.text"]: "Texto",
  ["semantic.search.result.formatoption.list"]: "Lista",
  ["semantic.search.result.formatoption.table"]: "Tabla",
  ["semantic.search.result.formatoption.textsmall"]: "texto",
  ["semantic.search.result.formatoption.listsmall"]: "lista",
  ["semantic.search.result.formatoption.tablesmall"]: "tabla",
  ["semantic.search.result.answercard.files"]: "Archivo(s) de Referencia",
  ["semantic.search.result.answercard.answerfeedback"]: "Retroalimentación",
  ["semantic.search.result.answercard.nextinsight"]: "Siguiente Perspicacia",
  ["semantic.search.result.notification.fileuploaded"]: "Archivo Descargado",
  ["semantic.search.result.notification.copy"]: "Texto copiado exitosamente",
  ["semantic.search.result.notification.unabletodownload"]: "No se puede descargar el archivo",
  ["semantic.search.result.notification.connectionerror"]: "Error de Conexión",
  ["semantic.search.result.notification.feedbacksubmitted"]: "Retroalimentación enviada exitosamente",
  ["semantic.search.result.modaltable.addcolumns"]: "Agregar Columnas Manuales",
  ["semantic.search.result.modaltable.douwanttoaddcolumns"]: "¿Deseas agregar columnas manuales?",
  ["semantic.search.result.modaltable.notification.manualcolumnsadded"]: "Columnas Manuales Agregadas",
  ["semantic.search.result.modaltable.enternewitem"]: "Ingrese un nuevo ítem",

  // semantic-search generate report
  ["semantic.search.result.modal.generate"]: "Generar Informe",
  ["semantic.search.result.modaltable.share"]: "Compartir",
  ["semantic.search.result.modaltable.add"]: "Agregar al Informe",
  ["semantic.search.result.modaltable.added"]: "Agregado al Informe",
  ["semantic.search.result.viewmore"]: "Ver Más...",
  ["semantic.search.result.noanswers"]: "¡No se encontraron respuestas!",
  ["semantic.search.result.noreference"]: "No se encontró ninguna referencia",
  ["semantic.search.result.assets"]: "Ubicación de Activos",

  ["semantic.search.result.searchresults"]: "Resultados de la Búsqueda",
  ["semantic.search.result.actions"]: "Acciones",
  ["semantic.search.result.noreportselected"]: "¡No se ha seleccionado ningún informe!",

  // drawer
  ["semantic.search.result.drawer.pagenumber"]: "Número de Página:",
  ["semantic.search.result.drawer.searchquery"]: "Consulta de Búsqueda",
  ["semantic.search.result.drawer.notfound"]: "No Encontrado",

  // Filtros avanzados semánticos
  ["semantic.search.header.searchbyfoldername"]: "Buscar por Nombre de Carpeta, Nombre de Documento",
  ["semantic.search.header.enterfoldername"]: "Ingrese el Nombre de la Carpeta",
  ["semantic.search.header.advancedfilters"]: "Filtros Avanzados",
  ["semantic.search.header.appliedfilters"]: "Filtros Aplicados",
  ["semantic.search.advancedfilters.tip"]: "Consejo: Haz clic en la categoría para ver las carpetas",
  ["semantic.search.advancedfilters.selectall"]: "Seleccionar Todo",
  ["semantic.search.advancedfilters.document"]: "Documento",
  ["semantic.search.advancedfilters.URL"]: "URL",
  ["semantic.search.advancedfilters.image"]: "Imagen",
  ["semantic.search.advancedfilters.video"]: "Vídeo",
  ["semantic.search.advancedfilters.audio"]: "Audio",
  ["semantic.search.advancedfilters.notification.applied"]: "Filtro Avanzado Aplicado",

  // Página de facturación semántica
  ["semantic.search.billing.heading"]: "Detalles de Uso y Facturación",
  ["semantic.search.billing.downloadreport"]: "Descargar Informe",
  ["semantic.search.billing.apiconsumption"]: "Consumo de API",
  ["semantic.search.billing.monthlybilling"]: "Facturación Mensual",
  ["semantic.search.billing.api"]: "API",
  ["semantic.search.billing.units"]: "Unidades",
  ["semantic.search.billing.unitprice"]: "Precio por Unidad ($)",
  ["semantic.search.billing.cost"]: "Costo ($)",
  ["semantic.search.billing.costheads"]: "Cabezas de Costo",
  ["semantic.search.billing.amount"]: "Cantidad ($)",
  ["semantic.search.billing.chat"]: "Chat",
  ["semantic.search.billing.embeddings"]: "Incrustaciones",
  ["semantic.search.billing.notavailable"]: "No Disponible",
  ["semantic.search.billing.totalamount"]: "Monto Total",
  ["semantic.search.billing.notification"]: "El archivo se ha descargado exitosamente",

  // Historial de usuario semántico
  ["semantic.search.userhistory.heading"]: "Historial de Usuario",
  ["semantic.search.userhistory.insightssearched"]: "Perspicacias Buscadas",
  ["semantic.search.userhistory.chats"]: "Chats",
  ["semantic.search.userdetails.nochatsfound"]: "No se encontraron chats",
  ["semantic.search.userdetails.noinsightsfound"]: "No se encontraron perspicacias",

  // Sistema de Archivos Semántico
  ["semantic.search.filesystem.uploaddocument"]: "SUBIR DOCUMENTO",
  ["semantic.search.filesystem.upload"]: "Subir",
  ["semantic.search.filesystem.createnewfolder"]: "CREAR NUEVA CARPETA",
  ["semantic.search.filesystem.createfolder"]: "Crear Nueva Carpeta",
  ["semantic.search.filesystem.browsefolder"]: "Arrastra y Suelta Archivos para Subir o Explora",
  ["semantic.search.filesystem.nodocsavaliable"]: "No hay documentos disponibles",
  ["semantic.search.filesystem.nodocsavaliablecreate"]: "Crea carpetas y carga tus documentos",
  ["semantic.search.filesystem.chat"]: "CHAT",
  ["semantic.search.filesystem.Document By"]: "Nombre del Documento",
  ["semantic.search.filesystem.DocumentType"]: "Tipo de Documento",
  ["semantic.search.filesystem.Uploaded By"]: "Subido Por",
  ["semantic.search.filesystem.Uploaded Date"]: "Fecha de Subida",
  ["semantic.search.filesystem.actions"]: "Acciones",
  ["semantic.search.filesystem.documents"]: "Documentos",
  ["semantic.search.filesystem.videos"]: "Vídeos",
  ["semantic.search.filesystem.audios"]: "Audios",
  ["semantic.search.filesystem.images"]: "Imágenes",
  ["semantic.search.filesystem.urls"]: "URLs Web",
  ["semantic.search.filesystem.documentsuploading"]: "Cargando Documentos",
  ["semantic.search.filesystem.deletionfolderconfirmation"]: "¿Deseas proceder con la eliminación de la carpeta?",
  ["semantic.search.filesystem.deletionfileconfirmation"]: "¿Deseas proceder con la eliminación del archivo?",
  ["semantic.search.filesystem.notification.foldercreation"]: "Carpeta creada exitosamente",
  ["semantic.search.filesystem.notification.noinput"]: "No se proporcionó entrada para la creación de la carpeta",
  ["semantic.search.filesystem.notification.folderdeletion"]: "Carpeta eliminada exitosamente",
  ["semantic.search.filesystem.notification.filedeletion"]: "Archivo eliminado exitosamente",
  ["semantic.search.filesystem.notification.folderdeletion.notupdated"]: "Carpeta eliminada exitosamente pero no actualizada en la interfaz",
  ["semantic.search.filesystem.notification.filedeletion.notupdated"]: "Archivo eliminado exitosamente pero no actualizado en la interfaz",
  ["semantic.search.filesystem.notification.errorfolderdeletion"]: "Error en la eliminación de la carpeta",
  ["semantic.search.filesystem.notification.errorfiledeletion"]: "Error en la eliminación del archivo",
  ["semantic.search.filesystem.notification.nofileselected"]: "No se seleccionó ningún archivo para cargar",
  ["semantic.search.filesystem.notification.fileuploaded"]: "Archivos cargados exitosamente",
  ["semantic.search.filesystem.notification.foldercannotbecreated"]: "No se pudo crear la carpeta correctamente",
  ["semantic.search.filesystem.notification.filecannotbeuploaded"]: "No se pudieron subir los archivos correctamente",

  ["semantic.search.filesystem.deletionimageconfirmation"]: "¿Desea continuar con la eliminación de la imagen?",
  ["semantic.search.filesystem.notification.imagedeletion"]: "Imagen eliminada exitosamente",
  ["semantic.search.filesystem.notification.imagedeletion.notupdated"]: "Imagen eliminada exitosamente pero no actualizada en la interfaz",
  ["semantic.search.filesystem.notification.errorimagedeletion"]: "Error en la eliminación de la imagen",
  ["semantic.search.filesystem.notification.noimageselected"]: "No se seleccionó ninguna imagen para cargar",
  ["semantic.search.filesystem.notification.imageuploaded"]: "Imágenes cargadas exitosamente",
  ["semantic.search.filesystem.notification.imagecannotbeuploaded"]: "No se pudieron cargar las imágenes exitosamente",
  //Chat Document
  ["chatdocument.search.chatwithdocument"]: "Chat con documento",

  ["chatdocument.search.chathistory"]: "Historial de chat",
  ["chatdocument.search.chatwith"]: "Chatear con",
  ["chatdocument.search.clearall"]: "Limpiar todo",
  ["chatdocument.search.downloadchat"]: "DESCARGAR CHAT",
  ["chatdocument.search.pages"]: "páginas",
  ["chatdocument.search.notfound"]: "No se encontraron chats anteriores",
  ["chatdocument.search.today"]: "Chat de hoy",
  ["chatdocument.search.7days"]: "Últimos 7 días",
  ["chatdocument.search.lastmonth"]: "Último mes",
  ["chatdocument.search.lastyear"]: "Último 1 año",
  ["chat.drawer.notification.chatdownload"]: "Chat Descargado",
  ["chat.drawer.notification.errorchat"]: "Error de conexión o no se ha iniciado ninguna descarga de chat",

  //login
  ["content.login.text.customizeyouremailusingai"]: "Personaliza tu correo electrónico usando inteligencia artificial",
  ["content.login.text.email"]: "Correo electrónico",
  ["content.login.text.password"]: "Contraseña",
  ["content.login.text.confirmpassword"]: "Confirmar Contraseña",
  ["content.login.text.convertyouremailintoHTMLCraftImagesand"]: "Convierte tu correo electrónico en HTML, crea imágenes y",
  ["content.login.text.weavevideostoleavealasting"]: "Teje videos para dejar una impresión duradera",
  ["content.login.text.pleaseenteravalidemailaddress"]: "Por favor, ingresa una dirección de correo electrónico válida",
  ["content.login.text.pleaseenteravalidpassword"]: "Por favor, ingresa una contraseña válida",
  ["content.login.text.passwordisrequired"]: "Se requiere contraseña",
  ["content.login.text.emailisrequired"]: "Se requiere correo electrónico",
  ["content.login.text.rememberme"]: "Recuérdame",
  ["content.login.text.forgotpassword"]: "¿Olvidaste tu contraseña?",
  ["content.login.text.donthaveanaccount"]: "¿No tienes una cuenta?",
  ["content.login.text.createanaccount"]: "Crear una cuenta",
  ["content.signup.text.alreadyhaveanaccount"]: "¿Ya tienes una cuenta?",
  ["content.verifiedpage.text.signupsuccessfull"]: "Registro exitoso. Por favor, verifica tu correo electrónico",
  ["content.forgot.text.gotologinpage"]: "Ir a la página de inicio de sesión",
  ["content.forgot.text.resetpassword"]: "RESTABLECER CONTRASEÑA",
  ["content.forgot.text.clicktologin"]: "Hacer clic para iniciar sesión",
  ["content.forgot.text.helloagain"]: "¡Hola de nuevo!",

  //Use Case Constants
  /** Field Copilot */

  ["content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant"]: "Field Copilot: Tu Asistente Generativo Impulsado por IA",
  ["content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations"]:
    "Empoderando a los Representantes con Perspicacias Inigualables sobre los Profesionales de la Salud, Pacientes y Recomendaciones Avanzadas de Farmacología y Productos",
  ["content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point"]: "Punto de Acceso Único",
  ["content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools"]:
    "La síntesis habilitada por IA generativa ofrece una visión consolidada, reduciendo la necesidad de múltiples herramientas",
  ["content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations"]: "Recomendaciones Personalizadas",
  ["content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time"]:
    "Respuestas personalizadas a las preguntas de los representantes, proporcionando recomendaciones contextuales en tiempo real",
  ["content.usecase.fieldcopilot.description.tabcontents.Scientific Responses"]: "Respuestas Científicas",
  ["content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric"]: "Centrado en el Cumplimiento Normativo",
  ["content.usecase.fieldcopilot.description.tabcontents.Every interaction and recommendation is designed to be compliant, ensuring peace of mind"]:
    "Cada interacción y recomendación está diseñada para cumplir con las normativas, asegurando tranquilidad",
  ["content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base"]: "Base de Conocimiento Curada",
  ["content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base"]:
    "Acceso a un corpus meticulosamente seleccionado de datos y base de conocimiento de empresas farmacéuticas y productos",
  ["content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access"]: "Acceso a Perspicacias de Marketing 360",
  ["content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey"]:
    "Integración perfecta con el ecosistema de plataformas digitales que proporciona actualizaciones en tiempo real sobre el recorrido del cliente",
  ["content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management"]: "Gestión de Tareas Asistida",
  ["content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders"]:
    "Acceso rápido a horarios diarios, citas futuras y recordatorios de tareas",
  [`content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation`]: `No solo respuestas, sino perspicacias sobre el "por qué", "quién" y "qué" detrás de cada recomendación`,

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"]: "Propuesta de Valor Empresarial",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop"]:
    "Interfaz Intuitiva y Bucle de Retroalimentación",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface"]: "Interfaz Interactiva",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI."]:
    "Permitiendo a los representantes involucrarse y proporcionar información a la IA.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection"]: "Recolección de Retroalimentación",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model."]:
    "Capturando las respuestas de los usuarios para refinar y mejorar el modelo.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability"]: "Adaptabilidad en Tiempo Real",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback."]:
    "Actualizando las respuestas del modelo en función de la retroalimentación inmediata.",

  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture"]:
    "Arquitectura del Modelo de IA Generativa",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI"]: "IA Generativa",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures."]:
    "Seleccionar LLM utilizando arquitecturas GANs o de transformadores.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization"]: "Entrenamiento y Optimización",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques."]:
    "Mejorando el rendimiento a través de técnicas de entrenamiento.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability"]: "Implementación y Escalabilidad",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions."]: "Asegurando soluciones sin problemas.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System"]:
    "Sistema Integrado de Datos y Conocimientos",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources"]: "Fuentes de Datos Diversas",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data."]:
    "Reuniendo datos variados y de alta calidad.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning"]: "Limpieza de Datos",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data."]:
    "Eliminando inconsistencias, ruido o datos irrelevantes.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering"]: "Ingeniería de Funciones",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance"]:
    "Mejorando los datos para mejorar el rendimiento del modelo.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework"]: "Marco de Cumplimiento y Reporte",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation"]: "Mitigación de Sesgos",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases."]:
    "Asegurando que el modelo no perpetúe sesgos dañinos.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency"]: "Transparencia",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions."]:
    "Proporcionando perspicacias sobre cómo la IA toma decisiones.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails"]: "Cumplimiento Normativo y Lineamientos",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries."]:
    "Operando dentro de los límites legales y éticos.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library"]: "Biblioteca de Indicaciones Integral",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries"]: "Consultas Dinámicas",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs."]:
    "Indicaciones adaptables para diversas necesidades de los usuarios.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions"]: "Interacciones Guiadas",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results."]:
    "Dirigiendo a los usuarios para obtener resultados deseados.",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts"]: "Indicaciones Categorizadas",
  ["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access."]:
    "Organizando consultas por temas o funciones para un fácil acceso.",

  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"]: "Retorno de la Inversión",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact"]: "Impacto Financiero",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency"]: "Eficiencia de Costos",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules."]:
    "Reducir los gastos de capacitación aprovechando los módulos bajo demanda asistidos por IA.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI"]: "Retorno de la Inversión Maximizado",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns."]:
    "El rendimiento optimizado de los representantes amplifica los resultados de ventas, asegurando que cada dólar de capacitación rinda mayores retornos.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence"]: "Excelencia en Operaciones de Ventas",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding"]: "Integración Rápida",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity."]:
    "Los módulos guiados por IA aceleran la integración de nuevos representantes, mejorando el tiempo de productividad.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability"]: "Adaptabilidad Dinámica",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs."]:
    "Equipar a los representantes con perspicacias de mercado en tiempo real, minimizando las extensas necesidades de reentrenamiento.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements"]: "Interacciones Mejoradas",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements"]: "Interacciones Informadas",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning."]:
    "Elevar las interacciones con los profesionales de la salud a través de perspicacias oportunas impulsadas por IA, solidificando la posición en el mercado.",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation"]: "Mitigación de Riesgos",
  ["content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation."]:
    "El mecanismo de cumplimiento y reporte incorporado garantiza el cumplimiento de los estándares globales, protegiendo la reputación de la marca.",

  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"]: "Métricas de Éxito",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time"]: "Tiempo de Finalización de Tareas",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it"]:
    "Tiempo que lleva a un representante médico completar sus tareas con la asistencia del copiloto de IA en comparación con sin él.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement"]: "Mejora en Ventas/Productividad",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot"]:
    "Aumento en ventas o productividad logrado por representantes médicos mientras usan el copiloto de IA.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontentsAccuracy of Information"]: "Precisión de la Información",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot"]:
    "La precisión de la información proporcionada por el copiloto de IA.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback"]: "Retroalimentación de los Clientes",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot"]:
    "Retroalimentación de los clientes que interactuaron con los representantes médicos mientras usaban el copiloto de IA.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate"]: "Tasa de Participación",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions"]:
    "Con qué frecuencia los representantes médicos participan activamente con el copiloto de IA durante sus interacciones.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization"]: "Personalización",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths"]:
    "La capacidad del copiloto de IA para adaptar su ayuda a los estilos de trabajo y fortalezas individuales de los representantes médicos.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations"]: "Cumplimiento Normativo y Regulaciones",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies"]:
    "El copiloto de IA ayuda a los representantes médicos a cumplir con las regulaciones de la industria y las políticas de la empresa.",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings"]: "Ahorro de Costos",
  ["content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot"]:
    "Los ahorros de costos logrados mediante el uso del copiloto de IA.",

  ["content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation"]: "Generación de Contenido",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"]: "Potencial Futuro",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements"]:
    "La IA podría ayudar en la generación de varios tipos de contenido, como artículos, presentaciones y estudios de casos, basados en las últimas investigaciones médicas y directrices. Este contenido podría ayudar a los representantes médicos a educar a los profesionales de la salud de manera efectiva y mantenerlos actualizados sobre los avances.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations"]: "Simulaciones de Entrenamiento",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations"]:
    "La IA generativa podría crear simulaciones realistas de escenarios médicos, permitiendo a los representantes médicos practicar sus habilidades de comunicación en un entorno controlado. Esto les ayudaría a perfeccionar sus argumentos y respuestas para diferentes situaciones.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation"]: "Traducción de Idiomas",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach"]:
    "La traducción de idiomas impulsada por IA podría permitir que los representantes médicos se comuniquen con profesionales de la salud de diferentes antecedentes lingüísticos, derribando barreras idiomáticas y ampliando su alcance.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics"]: "Análisis Predictivo",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement"]:
    "Al analizar datos y patrones históricos, la IA podría predecir los momentos y métodos más adecuados para contactar a profesionales de la salud específicos. Esto mejoraría la efectividad de las interacciones y aumentaría la participación.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction"]: "Interacción por Voz",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points"]:
    "Con los avances en tecnología de reconocimiento de voz, la IA podría ayudar a los representantes médicos durante llamadas telefónicas o reuniones virtuales proporcionando información en tiempo real y puntos de conversación sugeridos.",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.Personal Health Monitoring"]: "Monitoreo de Salud Personal",
  ["content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered wearables could monitor the health and well-being of medical representatives, providing alerts for breaks or reminders for healthy habits during busy workdays"]:
    "Los dispositivos ponibles impulsados por IA podrían monitorear la salud y el bienestar de los representantes médicos, proporcionando alertas para descansos o recordatorios para hábitos saludables durante días de trabajo ocupados.",
  /************/

  /**Content Creation */
  "content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations":
    "Generación más rápida de contenido con Especificidad de Dominio, Cumplimiento y Regulaciones",
  "content.usecase.contentai.description.Prompt Enrichment": "Enriquecimiento de Indicaciones",
  "content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience":
    "Permite al usuario enriquecer la indicación para obtener el contenido deseado que resuena con el público objetivo",
  "content.usecase.contentai.description.Automatic regulatory compliance check": "Verificación automática de cumplimiento regulatorio",
  "content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards.":
    "El contenido generado cumple con los organismos pertinentes, asegura que el contenido cumpla con todas las normas legales y regulatorias médicas.",
  "content.usecase.contentai.description.Localization optimization": "Optimización de localización",
  "content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement":
    "Genera contenido adecuado para diferentes regiones e idiomas, que no solo se traduce sino que también se adapta culturalmente para garantizar el máximo compromiso",
  "content.usecase.contentai.description.Responsive design generator": "Generador de diseño receptivo",
  "content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices.":
    "Genera contenido que no solo está adaptado en mensaje sino también en diseño, garantizando una visualización óptima en computadoras de escritorio, tabletas y dispositivos móviles.",
  "content.usecase.contentai.description.Scalable": "Escalable",
  "content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs":
    "Puede generar una gran cantidad de contenido con facilidad, lo que permite a las empresas escalar sus campañas de correo electrónico sin aumentos proporcionales en los costos",

  "content.usecase.contentai.business-value-proposition.Faster Time-To-Market": "Más rápido tiempo de lanzamiento al mercado",
  "content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches.":
    "Con una generación de contenido más rápida, las campañas pueden ejecutarse más ágilmente, lo que permite a las empresas responder rápidamente a los cambios en el mercado o al lanzamiento de nuevos productos.",
  "content.usecase.contentai.business-value-proposition.Cost Efficiency": "Eficiencia de costos",
  "content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets.":
    "Costos reducidos asociados con la contratación de escritores de contenido, traductores y diseñadores, especialmente al escalar hacia audiencias más grandes o expandirse a nuevos mercados.",
  "content.usecase.contentai.business-value-proposition.Market Expansion": "Expansión del mercado",
  "content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities":
    "Permitir a las empresas llegar y comunicarse fácilmente con audiencias globales, abriendo puertas a nuevos mercados y oportunidades",
  "content.usecase.contentai.business-value-proposition.Increased Engagements": "Aumento de la participación",
  "content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs":
    "El contenido personalizado y relevante típicamente conduce a tasas de apertura y tasas de clic más altas y puede conducir a relaciones más sólidas con los profesionales de la salud",

  "content.usecase.contentai.return-on-investment.Financial Benefits": "Beneficios financieros",
  "content.usecase.contentai.return-on-investment.Cost savings": "Ahorro de costos",
  "content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors":
    "Mayor velocidad y eficiencia, menos costos de capacitación, menos errores",
  "content.usecase.contentai.return-on-investment.Improved top-line": "Mejora de la línea superior",
  "content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream":
    "Un mayor compromiso puede llevar a un aumento en las ventas, impactando directamente en los ingresos",
  "content.usecase.contentai.return-on-investment.Operational Benefits": "Beneficios operativos",
  "content.usecase.contentai.return-on-investment.Time saving": "Ahorro de tiempo",
  "content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution":
    "Generación de contenido más rápida, localización, optimización, simplificación de la ejecución de campañas",
  "content.usecase.contentai.return-on-investment.Employee efficiency": "Eficiencia del empleado",
  "content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects":
    "En lugar de tareas repetitivas, los recursos humanos pueden centrarse en aspectos estratégicos",
  "content.usecase.contentai.return-on-investment.Marketing Benefits": "Beneficios de marketing",
  "content.usecase.contentai.return-on-investment.Global reach": "Alcance global",
  "content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint":
    "La adaptación de contenido facilita la comunicación efectiva con audiencias globales, expandiendo la presencia en el mercado",
  "content.usecase.contentai.return-on-investment.Market agility": "Agilidad de mercado",
  "content.usecase.contentai.return-on-investment.Rapid content generation allow businesses to swiftly react to market trends or changes":
    "La generación rápida de contenido permite a las empresas reaccionar rápidamente a las tendencias o cambios del mercado",

  "content.usecase.contentai.success-metrics.Content production speed": "Velocidad de producción de contenido",
  "content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe":
    "Medir el tiempo tomado desde la ideación del contenido hasta su publicación, y hacer un seguimiento del número de piezas de contenido producidas dentro de un período de tiempo específico",
  "content.usecase.contentai.success-metrics.Cost per content piece": "Costo por pieza de contenido",
  "content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content":
    "Calcular el costo asociado con la producción de cada pieza de contenido",
  "content.usecase.contentai.success-metrics.CAC": "CAC",
  "content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content":
    "El costo asociado con la adquisición de un cliente a través del contenido",
  "content.usecase.contentai.success-metrics.ROI": "ROI",
  "content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content":
    "Beneficio neto del contenido dividido por el costo de producción del contenido",
  "content.usecase.contentai.success-metrics.Conversion rate": "Tasa de conversión",
  "content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content":
    "El porcentaje de usuarios que realizan una acción deseada después de ver el contenido",
  "content.usecase.contentai.success-metrics.Error rates": "Tasas de error",
  "content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication":
    "Frecuencia de errores (por ejemplo, gramaticales, factuales) identificados después de la publicación",
  "content.usecase.contentai.success-metrics.Revision rate": "Tasa de revisión",
  "content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production.":
    "Medir la frecuencia de revisiones o correcciones después de la producción.",

  /************/

  /**Content translation */
  ["content.translation.voicemodal.Select a Voice"]: "Seleccionar una Voz",
  ["content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs."]:
    "Escuchar muestras de voz y usar etiquetas para encontrar la mejor voz para tus necesidades.",
  ["content.translation.voicemodal.Select Gender"]: "Seleccionar Género",
  ["content.translation.voicemodal.Select by Tags"]: "Seleccionar por Etiquetas",
  ["content.translation.voicemodal.Show Premium"]: "Mostrar Premium",
  ["content.translation.voicemodal.Apply to All"]: "Aplicar a Todos",
  ["content.translation.voicemodal.Apply Voices"]: "Aplicar Voces",

  // Básicos
  ["content.history.wrapper.text.newfilename"]: "Nuevo Nombre de Archivo",
  ["content.history.wrapper.text.searchvideo"]: "Buscar Vídeo",
  ["content.history.wrapper.text.nohistoryfound"]: "No se encontró historial",

  ["content.button.submit"]: "Enviar",
  ["content.button.cancel"]: "Cancelar",
  ["content.button.goback"]: "Volver",
  ["content.button.save"]: "Guardar",
  ["content.button.proceed"]: "Proceder",
  ["content.button.regenerate"]: "Regenerar",
  ["content.button.replace"]: "Reemplazar",
  ["content.button.add"]: "Agregar",
  ["content.button.showmore"]: "Mostrar Más",
  ["content.button.discard"]: "Descartar",
  ["content.text.noimagefound"]: "¡No se encontró ninguna imagen!",
  ["content.text.gotodashboard"]: "Ir al Tablero",
  ["content.button.next"]: "Siguiente",
  ["content.button.generate"]: "Generar",
  ["content.text.enteryourprompt"]: "Ingrese su Indicación",
  ["content.text.generateinitialresponse"]: "Generar Respuesta Inicial",
  ["content.text.searchhere"]: "Buscar Aquí",

  ["content.text.promptcannotbeempty"]: "La indicación no puede estar vacía",
  ["content.text.filterby"]: "Filtrar Por",
  ["content.text.nofilterapplied"]: "No se aplicaron filtros",
  ["content.button.reset"]: "Restablecer",
  ["content.button.back"]: "Atrás",
  ["content.button.apply"]: "Aplicar",
  ["content.button.upload"]: "Subir",
  ["content.text.sessionexpired"]: "Sesión Expirada",
  ["content.text.sessionhasexpired"]: "Su sesión ha expirado. ¡Por favor, inicie sesión nuevamente!",
  ["content.text.actions"]: "Acciones",
  ["content.text.filterapplied"]: "Filtros Aplicados",
  ["content.text.selected"]: "Seleccionado",
  ["content.text.edit"]: "Editar",
  ["content.text.delete"]: "Eliminar",
  ["content.text.interested"]: "Interesado",
  ["content.text.notinterested"]: "No Interesado",
  ["content.text.share"]: "Compartir",
  ["content.text.response"]: "Respuesta",
  ["content.button.update"]: "Actualizar",
  ["content.text.errornoresponse"]: "Error: ¡Sin respuesta!",
  ["content.text.erroruploadfailed"]: "Error: ¡Carga fallida!",
  ["content.text.nofilesuploaded"]: "No se han subido archivos!",
  ["content.button.delete"]: "Eliminar",
  ["content.button.no"]: "No",
  ["content.button.yes"]: "Sí",
  ["content.button.stop"]: "Detener",
  ["content.button.yesproceed"]: "Sí, Continuar",
  ["content.transcreation.button.summary"]: "Resumen",

  ["content.text.selectalanguage"]: "Seleccionar un Idioma",
  ["content.text.translate"]: "Traducir",
  ["content.text.name"]: "Nombre",
  ["content.text.uploadedon"]: "Subido el",
  ["content.text.storyboard"]: "Historia",
  ["content.text.image/clips"]: "Imagen/Clips",
  ["content.text.video"]: "Vídeo",
  ["content.text.action"]: "Acción",
  ["content.text.somethingwentwrong"]: "Algo salió mal",
  ["content.text.internalservererror"]: "Error Interno del Servidor",
  ["content.text.completed"]: "Completado",
  ["content.text.inprogress"]: "En Progreso",
  ["content.text.notstarted"]: "No Iniciado",
  ["content.text.notapplicable"]: "No Aplicable",
  ["content.text.failed"]: "Fallido",
  ["content.text.preview"]: "Vista Previa",
  ["content.text.download"]: "Descargar",
  ["content.text.rename"]: "Renombrar",
  ["content.text.render"]: "Renderizar",
  ["content.text.pause"]: "Pausar",
  ["content.text.changevoice"]: "Cambiar Voz",
  ["content.text.speechtext"]: "Texto de Voz",
  ["content.text.changeavatar"]: "CAMBIAR AVATAR",
  ["content.text.filteravatar"]: "Filtrar avatar por atuendo",
  ["content.text.sendtoreport"]: "Enviar al Informe",
  ["content.text.close"]: "Cerrar",
  ["content.text.chat"]: "Chat",

  ["content.text.phoneme"]: "Fonema",
  ["content.text.phonemedictionary"]: "Diccionario de Fonemas",
  ["content.text.addphoneme"]: "Agregar Fonema",
  ["content.text.word"]: "Palabra",
  ["content.text.pronunciation"]: "Pronunciación",
  ["content.text.phonemedescription"]:
    "Su lista de fonemas que se utilizarán en todos sus vídeos en toda la cuenta. Cualquier palabra aquí se deletreará como en la columna 'Pronunciación' en todos sus vídeos.",

  ["content.text.error.400"]: "Respuesta: Solicitud incorrecta",
  ["content.text.error.401"]: "Respuesta: No autorizado",
  ["content.text.error.402"]: "Respuesta: Pago requerido",
  ["content.text.error.403"]: "Respuesta: Prohibido",
  ["content.text.error.404"]: "Respuesta: No encontrado",
  ["content.text.error.405"]: "Respuesta: Método no permitido",
  ["content.text.error.406"]: "Respuesta: No aceptable",
  ["content.text.error.407"]: "Respuesta: Se requiere autenticación de proxy",
  ["content.text.error.408"]: "Respuesta: Tiempo de espera de la solicitud",
  ["content.text.error.409"]: "Respuesta: Conflicto",
  ["content.text.error.410"]: "Respuesta: Ya no existe",
  ["content.text.error.411"]: "Respuesta: Se requiere longitud",
  ["content.text.error.412"]: "Respuesta: Falló la condición previa",
  ["content.text.error.413"]: "Respuesta: Solicitud demasiado grande",
  ["content.text.error.414"]: "Respuesta: URI de solicitud demasiado larga",
  ["content.text.error.415"]: "Respuesta: Tipo de medio no soportado",
  ["content.text.error.416"]: "Respuesta: Rango no satisfactorio",
  ["content.text.error.417"]: "Respuesta: Falló la expectativa",
  ["content.text.error.500"]: "Respuesta: Error interno del servidor",
  ["content.text.error.501"]: "Respuesta: No implementado",
  ["content.text.error.502"]: "Respuesta: Puerta de enlace incorrecta",
  ["content.text.error.503"]: "Respuesta: Servicio no disponible",
  ["content.text.error.504"]: "Respuesta: Tiempo de espera de la puerta de enlace",
  ["content.text.error.505"]: "Respuesta: Versión de HTTP no compatible",
  ["content.text.error.511"]: "Respuesta: Se requiere autenticación de red",

  ["content.text.disardallchanges"]: "Descartar todos los cambios",
  ["content.text.areyousuretodiscard"]: "¿Estás seguro de descartar todos los cambios?",
  ["content.text.novideosfound"]: "¡No se encontraron vídeos!",

  ["content.text.generating.initialresponse"]: "Generando Respuesta Inicial",
  ["content.text.generating.refinedresponse"]: "Generando Respuesta Refinada",
  ["content.text.regenerating.refinedresponse"]: "Regenerando Respuesta Refinada",
  ["content.text.notification"]: "Notificación",

  ["content.text.enterblogurl"]: "Introduce la URL del Blog",
  ["content.text.description"]: "Descripción",
  "content.button.start": "Comenzar",

  "content.text.Strategic ROI of": "ROI estratégico de",

  "content.text.Title should not be empty!": "¡El título no debe estar vacío!",
  "content.text.Scene should not be empty!": "¡La escena no debe estar vacía!",
  "content.text.Narration should not be empty!": "¡La narración no debe estar vacía!",

  ["content.text.Updation of generated content is not allowed now as Video is rendered."]:
    "No se permite la actualización del contenido generado ahora mientras se procesa el vídeo.",

  ["content.text.Are you sure you want to delete?"]: "¿Estás segura de que quieres eliminar?",

  ["content.text.Remove"]: "Eliminar",

  "idetailextract._UploadFileCard.Upload Document": "Subir documento",
  "idetailextract._UploadFileCard.Uploading": "Subiendo",
  "idetailextract._UploadFileCard.Click to Upload or Drop PDF here ": "Haga clic para cargar o arrastre el PDF aquí",
  "idetailextract._UploadFileCard.from url": "desde URL",
  "idetailextract._UploadFileCard.Set Language for Scanned files": "Establecer idioma para archivos escaneados",
  "idetailextract._UploadFileCard.Country": "País",
  "idetailextract._UploadFileCard.Brand": "Marca",
  "idetailextract._UploadFileCard.Language": "Idioma",

  "idetailextract._CategoriesModal.Selected Categories": "Categorías seleccionadas",
  ["idetailextract._CategoriesModal.Search Catogory"]: "Categoría de búsqueda",
  "idetailextract._CategoriesModal.Suggested": "Sugerido",
  "idetailextract._CategoriesModal.Additional": "Adicional",
  "idetailextract._CategoriesModal.Identified Categories": "Categorías Identificadas",
  "idetailextract._CategoriesModal.No Data Found": "No se encontraron datos",

  "idetailextract._CoreClaimMain._Headertab.Create Copy": "Crear copia",

  "idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview": "Vista previa del PDF cargado",
  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Page"]: "Página",

  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.PDF > Document Copy": "PDF > Copia de Documento",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Selected Categories": "Categorías seleccionadas",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.ADD CATEGORIES": "AGREGAR CATEGORÍAS",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.This is a preview version and you can edit and create a copy out of it.":
    "Nota: Esta es una versión de vista previa y puede editar y crear una copia de ella.",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Step": "Paso",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.of CVA": "de CVA",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Add": "Agregar",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Next Step": "Siguiente Paso",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Previous Step": "Paso Anterior",

  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE ASSET TYPE": "ELIJA TIPO DE ACTIVO",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE CLAIM CATEGORY": "ELIJA CATEGORÍA DE RECLAMO",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Choose ‘Asset & Claim category to proceed":
    "Elija 'Tipo de activo y categoría de reclamo para continuar'",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Core Claims": "Reclamos principales",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Asset Creation Recommendation": "Recomendación de creación de activos",

  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated": "Contenido actualizado",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]: "Contenido eliminado",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content": "Contenido",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page No": "Número de página",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content can not be empty": "El contenido no puede estar vacío",
  "idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page number can not be empty": "El número de página no puede estar vacío",

  "idetailextract._CoreClaimMain._TinyMCE.Loading Editor": "Cargando editor",

  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content saved": "Contenido guardado",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Saving failed! Please retry.": "¡Error al guardar! Por favor, inténtelo de nuevo.",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content discarded!": "¡Contenido descartado!",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.No recent changes to save.": "No hay cambios recientes para guardar.",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.No unsaved changes to discard.": "No hay cambios no guardados para descartar.",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Your Emailer Suggestion": "Tu sugerencia de correo electrónico",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save": "Guardar",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard": "Descartar",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit Edit Mode": "Salir del modo de edición",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Preview": "Vista previa",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Cancel": "Cancelar",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Lose Changes": "Perder cambios",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.If you proceed, you will lose all the changes you have made.":
    "Si continúa, perderá todos los cambios que haya realizado.",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit without Saving": "Salir sin guardar",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save and Exit": "Guardar y salir",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard unsaved changes": "Descartar cambios no guardados",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Unsaved changes": "Cambios no guardados",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Proceed": "Continuar",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Close": "Cerrar",
  "idetailextract._CoreClaimMain.FinalHTMLOutputModal.Edit": "Editar",

  /**
   * Translation Module
   */

  "translation-module._TextTranslationMain.Choose Base Language": "Seleccionar idioma base",
  "translation-module._TextTranslationMain.Choose Target Language": "Seleccionar idioma de destino",
  "translation-module._TextTranslationMain.RESET": "RESTABLECER",
  "translation-module._TextTranslationMain.TRANSLATE": "TRADUCIR",
  "translation-module._TextTranslationMain.Input": "Entrada",
  "translation-module._TextTranslationMain.Output": "Salida",

  "translation-module._TextTranslationMain._IOTextTranslation.Enter your text": "Ingrese su texto",
  "translation-module._TextTranslationMain._IOTextTranslation.ADD GLOSSARY": "AGREGAR GLOSARIO",

  "translation-module._AddGlossaryModal.Add Glossary": "Agregar Glosario",
  "translation-module._AddGlossaryModal.Set rules for how words and phrases are translated":
    "Establecer reglas para cómo se traducen palabras y frases",
  "translation-module._AddGlossaryModal.Replace": "Reemplazar",
  "translation-module._AddGlossaryModal.e.g, hello!": "por ejemplo, ¡hola!",
  "translation-module._AddGlossaryModal.With": "Con",
  "translation-module._AddGlossaryModal.e.g, 更高": "por ejemplo, 更高",
  "translation-module._AddGlossaryModal.Source --> Target": "Origen --> Destino",
  "translation-module._AddGlossaryModal.RESET": "RESTABLECER",
  "translation-module._AddGlossaryModal.ADD": "AÑADIR",
  "translation-module._AddGlossaryModal.UPDATE": "ACTUALIZAR",
  "translation-module._AddGlossaryModal.Search item": "Elemento de búsqueda",
  "translation-module._AddGlossaryModal.OR": "O",
  "translation-module._AddGlossaryModal.Action": "Acción",

  ["translation-module._MainTabs.TEXT TRANSLATION"]: "TRADUCCIÓN DE TEXTO",
  ["translation-module._MainTabs.UPLOAD DOCUMENT(S)"]: "SUBIR DOCUMENTO(S)",
  ["translation-module._UploadDocumentMain.File Upload"]: "Cargar archivo",
  ["translation-module._UploadDocumentMain.ADD GLOSSARY"]: "AGREGAR GLOSARIO",
  ["translation-module._UploadDocumentMain.Drag And Drop Your Files Here."]: "Arrastra y suelta tus archivos aquí.",
  ["translation-module._UploadDocumentMain."]: "Tipo de archivo compatible: .pdf",
  ["translation-module._UploadDocumentMain.of"]: "de",
  ["translation-module._UploadDocumentMain.files uploaded"]: "archivos subidos",
  ["translation-module._UploadDocumentMain.Uploaded File"]: "Archivo subido",
  ["translation-module._UploadDocumentMain.No files uploaded!"]: "¡No se han subido archivos!",
  ["translation-module._UploadDocumentMain.NEXT"]: "SIGUIENTE",
  ["translation-module._UploadDocumentMain.Clear All"]: "Limpiar todo",

  ["translation-module._TranslateAssetMain._HeadingContainer.Translate your Asset"]: "Traduce tu activo",
  ["translation-module._TranslateAssetMain._HeadingContainer.ADD GLOSSARY"]: "AGREGAR GLOSARIO",
  ["translation-module._TranslateAssetMain._HeadingContainer.At the moment, AI-generated translation will be applied to all of your assets."]:
    "Por el momento, la traducción generada por IA se aplicará a todos tus activos.",

  ["translation-module._TranslateAssetMain._SearchingContainer.Languages"]: "Idiomas",
  ["translation-module._TranslateAssetMain._SearchingContainer.Search Language"]: "Idioma de búsqueda",
  ["translation-module._TranslateAssetMain._SearchingContainer.Base Language English (US)"]: "Idioma base: inglés (EE. UU.)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Add Context to the Translation (Optional)"]:
    "Agregar contexto a la traducción (opcional)",

  "translation-module._TranslateAssetMain._OptionalContainer.Enter your text": "Ingresa tu texto",

  "translation-module._TranslateAssetMain._FooterContainer.Language Selected": "Idioma Seleccionado",
  "translation-module._TranslateAssetMain._FooterContainer.CANCEL": "CANCELAR",
  "translation-module._TranslateAssetMain._FooterContainer.PROCEED": "CONTINUAR",
  "translation-module.FinalTranslationScreen.Translation is Done!": "¡La traducción está hecha!",
  "translation-module.FinalTranslationScreen.Please select a file from the Dropdown to view the translated asset.":
    "Por favor selecciona un archivo del menú desplegable para ver el activo traducido.",
  "translation-module.FinalTranslationScreen.Edit": "Editar",
  "translation-module.FinalTranslationScreen.Input": "Entrada",
  "translation-module.FinalTranslationScreen.Output": "Salida",
  "translation-module.FinalTranslationScreen.DOWNLOAD": "DESCARGAR",
  "translation-module.FinalTranslationScreen.Languages": "Idiomas",
  "translation-module.FinalTranslationScreen.START AGAIN": "EMPEZAR DE NUEVO",
  "translation-module.FinalTranslationScreen.GET YOUR QUALITY REPORT": "OBTÉN TU INFORME DE CALIDAD",

  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translate your package"]: "Traduce tu paquete",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.At the moment, AI-generated translation will be applied to all of your assets."]:
    "En este momento, la traducción generada por inteligencia artificial se aplicará a todos sus activos.",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Please wait..."]: "Por favor, espere...",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Selected Language"]: "Idioma seleccionado",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.“Translation”"]: "“Traducción”",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.is in Progress"]: "está en progreso",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancel Translation"]: "Cancelar traducción",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancelling Translation"]: "Cancelando Traducción",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translation Cancelled!"]: "¡Traducción cancelada!",

  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content saved!": "¡Contenido guardado!",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Saving failed! Please retry.":
    "¡Error al guardar! Por favor, inténtelo de nuevo.",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content discarded!": "¡Contenido descartado!",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No recent changes to save.": "No hay cambios recientes para guardar.",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No unsaved changes to discard.": "No hay cambios no guardados para descartar.",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Modify Translated Content": "Modificar contenido traducido",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save": "Guardar",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard": "Descartar",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Close": "Cerrar",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard unsaved changes": "Descartar cambios no guardados",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Cancel": "Cancelar",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Lose Changes": "Perder cambios",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Unsaved changes": "Cambios no guardados",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save and Exit": "Guardar y salir",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Exit without Saving": "Salir sin guardar",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.If you proceed, you will lose all the changes you have made.":
    "Si continúa, perderá todos los cambios que haya realizado."
};
