import { CT_FINAL_PRODUCTS, CT_SOURCE_TYPES, FooterButtonsConfig } from "../../Pages/Content Transcreation/Core/constants";
import { FooterButtonsConfigTypes } from "../../Pages/Content Transcreation/Core/modals";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT } from "../../Utils";
import { NameValueTypes } from "../../Utils/_gTypes";
import {
  SET_ACTIVE_STEPPER,
  SET_BUTTON_TEXT_STATE,
  SET_CTL_ACTIVE_OBJECT_MODIFIER,
  SET_CTL_ACTIVE_SLIDE,
  SET_CTL_CANVAS_ACTIVE_OBJECT,
  SET_CTL_SAVE_ELAI_OBJECT,
  SET_CTL_SIDEBAR_OPEN,
  SET_CTL_SLIDE_PLAYING,
  SET_CT_FINAL_PRODUCT,
  SET_CT_SOURCE,
  SET_ELAI_RESPONSE_DATA,
  SET_FILTER_APPLIED_STATUS,
  SET_FOOTER_BUTTONS_CONFIG,
  SET_NEXT_BUTTON_DISABLE,
  SET_NEXT_STEPPER_STATE,
  SET_PHONEME_LIST,
  SET_PLATFORM_LIST,
  SET_PREVIOUS_STEPPER_STATE,
  SET_REFETCH_CONTENT_HISTORY_LIST,
  SET_REFETCH_LIST,
  SET_REGENERATE_STATE,
  SET_RESPONSE_CT,
  SET_SELECTED_EMAIL_DOC,
  SET_SELECTED_FILTERS,
  SET_SHOW_MORE_LIST
} from "../constants/_contentTranscreationConstants";

type IntialState = {
  activeStepper: number;
  nextStepper: boolean;
  previousStepper: boolean;
  buttonText: string;
  nextButtonDisable: boolean;
  responseCT: any;
  refetchHistoryContent: boolean;
  source: string;
  finalProduct: string;
  footerButtonsConfig: FooterButtonsConfigTypes[];
  regenerate: boolean;
  refetchList: boolean;
  platformList: NameValueTypes[];

  //content history
  selectedFilters: string[];
  showMoreList: boolean;
  selectedEmailDoc: any;
  filterApplied: boolean;

  //content-translation
  elaiResponse: any;
  activeSlide: number;
  phonemeList: any[];
  sideBarOpen: boolean;
  activeObjectModifier: any;
  canvasActiveObject: any;
  saveElaiObject: any;
  slidePlaying: boolean;
};

const initialState: IntialState = {
  activeStepper: CONTENT_TRANSCREATION_STEPS.STEP_0,
  nextStepper: false,
  previousStepper: false,
  buttonText: ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED,
  nextButtonDisable: true,
  responseCT: {},
  refetchHistoryContent: false,
  source: CT_SOURCE_TYPES.EMAIL,
  finalProduct: CT_FINAL_PRODUCTS.VIDEO,
  footerButtonsConfig: [...FooterButtonsConfig],
  regenerate: false,
  refetchList: false,
  platformList: [],
  selectedFilters: [],
  showMoreList: false,
  selectedEmailDoc: {},
  elaiResponse: {},
  activeSlide: 0,
  filterApplied: false,
  phonemeList: [],
  sideBarOpen: false,
  activeObjectModifier: undefined,
  canvasActiveObject: undefined,
  saveElaiObject: undefined,
  slidePlaying: false
};

export default function contentTranscreationReducer(state: IntialState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_STEPPER: {
      return {
        ...state,
        activeStepper: payload
      };
    }
    case SET_NEXT_STEPPER_STATE: {
      return {
        ...state,
        nextStepper: payload
      };
    }
    case SET_PREVIOUS_STEPPER_STATE: {
      return {
        ...state,
        previousStepper: payload
      };
    }
    case SET_BUTTON_TEXT_STATE: {
      return {
        ...state,
        buttonText: payload
      };
    }
    case SET_RESPONSE_CT: {
      return {
        ...state,
        responseCT: payload
      };
    }
    case SET_NEXT_BUTTON_DISABLE: {
      return {
        ...state,
        nextButtonDisable: payload
      };
    }
    case SET_REFETCH_CONTENT_HISTORY_LIST: {
      return {
        ...state,
        refetchHistoryContent: payload
      };
    }
    case SET_CT_SOURCE: {
      return {
        ...state,
        source: payload
      };
    }
    case SET_CT_FINAL_PRODUCT: {
      return {
        ...state,
        finalProduct: payload
      };
    }
    case SET_FOOTER_BUTTONS_CONFIG: {
      return {
        ...state,
        footerButtonsConfig: payload
      };
    }
    case SET_REGENERATE_STATE: {
      return {
        ...state,
        regenerate: payload
      };
    }
    case SET_REFETCH_LIST: {
      return {
        ...state,
        refetchList: payload
      };
    }
    case SET_PLATFORM_LIST: {
      return {
        ...state,
        platformList: payload
      };
    }
    case SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: payload
      };
    }
    case SET_SHOW_MORE_LIST: {
      return {
        ...state,
        showMoreList: payload
      };
    }
    case SET_SELECTED_EMAIL_DOC: {
      return {
        ...state,
        selectedEmailDoc: payload
      };
    }
    case SET_ELAI_RESPONSE_DATA: {
      return {
        ...state,
        elaiResponse: payload
      };
    }
    case SET_CTL_ACTIVE_SLIDE: {
      return {
        ...state,
        activeSlide: payload
      };
    }
    case SET_FILTER_APPLIED_STATUS: {
      return {
        ...state,
        filterApplied: payload
      };
    }
    case SET_PHONEME_LIST: {
      return {
        ...state,
        phonemeList: payload
      };
    }
    case SET_CTL_SIDEBAR_OPEN: {
      return {
        ...state,
        sideBarOpen: payload
      };
    }
    case SET_CTL_ACTIVE_OBJECT_MODIFIER: {
      return {
        ...state,
        activeObjectModifier: payload
      };
    }
    case SET_CTL_CANVAS_ACTIVE_OBJECT: {
      return {
        ...state,
        canvasActiveObject: payload
      };
    }
    case SET_CTL_SAVE_ELAI_OBJECT: {
      return {
        ...state,
        saveElaiObject: payload
      };
    }
    case SET_CTL_SLIDE_PLAYING: {
      return {
        ...state,
        slidePlaying: payload
      };
    }
    default: {
      return state;
    }
  }
}
