import { fabric } from 'fabric'

const Frame = fabric.util.createClass(fabric.Path, {
  type: 'frame',
  objectCaching: false,

  initialize: function (path, options) {
    options || (options = {})
    this.img = options.img
    this.imgSrc = options.imgSrc
    this.svgWidth = options.svgWidth
    this.patternWidth = options.patternWidth
    this.animation = options.animation
    this.img.scaleToWidth(options.patternWidth)
    this.img.top = 0
    this.img.left = 0
    this.patternSourceCanvas = new fabric.StaticCanvas(null, { enableRetinaScaling: false })
    this.patternSourceCanvas.add(this.img)
    this.patternSourceCanvas.setDimensions({
      width: this.img.getScaledWidth(),
      height: this.img.getScaledHeight(),
    })
    this.callSuper('initialize', path, options)
    this.patternSourceCanvas.renderAll()
    this.fill = new fabric.Pattern({
      source: this.patternSourceCanvas.getElement(),
      repeat: 'repeat',
      offsetX: this.fill.offsetX || 0,
      offsetY: this.fill.offsetY || 0,
    })
    this.setControlsVisibility({ mb: false, ml: false, mr: false, mt: false, mtr: false })
  },

  _draw: function () {
    this.patternSourceCanvas.clear()
    this.img.scaleToWidth(this.patternWidth)
    this.patternSourceCanvas.setDimensions({
      width: this.img.getScaledWidth(),
      height: this.img.getScaledHeight(),
    })
    this.patternSourceCanvas.add(this.img)
    this.patternSourceCanvas.renderAllSafe()
  },

  set: function (key, value) {
    if (typeof key === 'object') this._setObject(key)
    else this._set(key, value)
    if (key === 'scale') {
      this.patternWidth = value
      this._draw()
    } else if (key === 'offsetX' || key === 'offsetY') this.fill[key] = value
    return this
  },

  setAsync: async function (key, value) {
    this._set(key, value)
    if (key === 'image') {
      this.imgSrc = value
      this.img = await fabric.imageFromURL(value)
      this._draw()
      this.canvas.requestRenderAll()
    }
    return this
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      imgSrc: this.imgSrc,
      patternWidth: this.patternWidth,
      svgWidth: this.svgWidth,
      animation: this.animation,
    })
  },
})

Frame.fromObject = async function (object, callback) {
  fabric
    .imageFromURL(object.imgSrc)
    .then((img) => {
      img.scaleToWidth(object.patternWidth / 2)
      object.img = img
      const frame = new fabric.Frame(object.path, object)
      callback(frame)
    })
    .catch(() => callback(null, true))
}

Frame.fromImage = async function (svgSrc, img) {
  const svg = await fabric.svgFromURL(svgSrc)
  if (!svg.path) return false
  const initWidth = img.getScaledWidth()
  const imgSrc = img.toObject().src
  const frame = new fabric.Frame(svg.path, {
    id: img.id,
    top: img.top,
    left: img.left,
    imgSrc,
    img,
    svgWidth: svg.width,
    patternWidth: svg.width,
    animation: img.animation,
  })
  frame.scaleToWidth(initWidth)
  return frame
}

Frame.async = true

export default Frame
