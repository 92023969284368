import { useEffect, useState } from "react";

import { queueMicrotask } from "../fabric-files/utils/helpers";
import { audioData } from "../core/contentTranslationMockAudioData";
//import { setTimeOnObjectsWithMarkers } from '../../utils/canvas'

export const useGlobalPlayer = (props) => {
  const { video, audioCache, activeSlide, updateActiveSlide, setActiveSlideWithUrl, audioList, setAudioList } = props;

  console.log("useGlobalPlayer activeSlide", activeSlide);
  const [globalPlayer, setGlobalPlayer] = useState({
    playing: false,
    preloading: false,
    duration: 0,
    onPlay: null
  });

  useEffect(() => {
    if (!video || !video.slides) {
      return;
    }

    const duration = video.slides.reduce((duration, slide) => duration + (slide.duration || slide.approxDuration || 0), 0);
    setGlobalPlayer((p) => ({ ...p, duration }));
  }, [video]);

  const preLoadSlide = async () => {
    if (globalPlayer.playing && activeSlide + 1 < video.slides.length) {
      const slide = video.slides[activeSlide + 1];
      //const speechData = await getAudio(activeSlide + 1); //audioCache.preloadAudioForSlide(slide);
      //if (speechData?.markers) updateSlideWithMarkers(slide, activeSlide + 1, speechData.markers);
    }
  };

  // useEffect(() => {
  //   preLoadSlide();
  // }, [activeSlide]);

  const getAudio = async (index) => {
    if (audioList[index]) {
      let audioObj = audioList[index];
      if (!audioObj?.audio) {
        console.log("Error getting Audio file");
      }
      return audioObj?.audio;
    } else if (!audioList[index]) {
      //fetch audio
      //audio.onloadedmetadata = onloadedMetadata
      let audioMockData = audioData;
      const audio = new Audio(`data:audio/x-wav;base64, ${audioMockData.data}`);
      audio.preload = "auto";
      // audio.onended = () => {
      //   console.log("audio ended!");
      //   setPlay(false);
      //   setPlayer((p) => ({
      //     ...p,
      //     status: "idle",
      //     currentTime: 0
      //   }));
      // };

      setAudioList((prevAL) => {
        let newAL = [...prevAL];
        newAL[index] = { audio: audio, duration: audioMockData.duration };
        return newAL;
      });

      return audio;
    }
  };

  const playVideo = async () => {
    if (globalPlayer.playing) {
      stopVideo();
      // for time to update all related effects
      await new Promise((resolve) => queueMicrotask(resolve));
    }

    updateActiveSlide(0);

    setGlobalPlayer((p) => ({ ...p, playing: true }));

    // if (globalPlayer.playing) {
    //   stopVideo();
    //   // for time to update all related effects
    //   await new Promise((resolve) => queueMicrotask(resolve));
    // }

    // setGlobalPlayer((p) => ({ ...p, preloading: true }));

    // const slide = video.slides[0];
    // //TODO
    // //const speechData = await getAudio(0); //audioCache.preloadAudioForSlide(slide);

    // setGlobalPlayer((p) => ({ ...p, preloading: false }));

    // updateActiveSlide(0);
    // //setActiveSidebarTab("music");
    // globalPlayer.onPlay?.();
    // setGlobalPlayer((p) => ({ ...p, playing: true }));

    // if (video.slides.length > 1) {
    //   const slide = video.slides[1];

    //   //TODO
    //   //  const speechData = await getAudio(1); //audioCache.preloadAudioForSlide(slide);

    //   //if (speechData?.markers) updateSlideWithMarkers(slide, 1, speechData.markers);
    // }
  };

  const stopVideo = () => {
    console.log("Stopping the globalPlayer");
    setGlobalPlayer((p) => ({ ...p, playing: false }));
  };

  const onStopPlaying = (time) => {
    if (!globalPlayer.playing || (typeof time !== "undefined" && time !== video.slides[activeSlide].duration)) {
      return;
    }

    if (activeSlide + 1 < video.slides.length) {
      const nextSlide = activeSlide + 1;
      setActiveSlideWithUrl(nextSlide);
    } else {
      stopVideo();
    }
  };

  return {
    globalPlayer,
    setGlobalPlayer,
    playVideo,
    stopVideo,
    onStopPlaying
  };
};
