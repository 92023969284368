import { FC, useEffect } from "react";
import { Card, Flex } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getDefaultLayout, globalConstant } from "../../Utils";
import { LayoutSyncTypes, apiResponse } from "../../Utils/_gTypes";
import { MainTabs } from "./Components";
import { Authorizer, siteConfig } from "../../Services";
import { setLayoutSyncAction } from "../../Store/actions/_commonActions";
import _localStorageService from "../../Services/_localStorageService";
import "../../Assets/Css/TranslationModule.css";
import { TextTranslationMain } from "./_TextTranslationMain";
import { enumTMActiveScreen, enumTMMainTabs } from "./Core/constant";
import { UploadDocumentMain } from "./_UploadDocumentMain";
import { TranslateAssetMain } from "./_TranslateAssetMain";

import { setLanguageListTMAction } from "../../Store/actions/_translationModuleActions";
import _translationModuleRequests from "../../Store/requests/_translationModuleRequests";
import FinalTranslationScreen from "./Components/Upload Documents/FinalTranslationScreen";
import { AddGlossaryModal } from "./Components/Glossary";

const authorizer = new Authorizer();

export const TranslationModuleWrapper: FC = () => {
  const dispatch = useDispatch();

  const { activeScreen, mainTabs } = useSelector((state: any) => state?.translationModuleReducer);
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_TRANSLATION_MODULE;

  useEffect(() => {
    init();

    handleLanguageList();
  }, []);

  const isValid = () => {
    authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);
  };

  const init = async () => {
    isValid();

    if (!layoutSync.CONTENT_AI) {
      const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);
      dispatch(setLayoutSyncAction({ ...objNewLayoutSync, TRANSLATION_MODULE: true }));
    }
  };

  const handleLanguageList = async () => {
    const res: apiResponse = await _translationModuleRequests.getLanguageList();

    if (res?.message?.toUpperCase() === globalConstant.SUCCESS) {
      dispatch(setLanguageListTMAction([...res?.data]));
    }
  };

  return (
    <Flex className="translation-module">
      <Flex style={{ marginLeft: "2.5rem", marginRight: "2.5rem", height: "100%" }} vertical>
        {activeScreen === enumTMActiveScreen.NOTHING && <MainTabs />}
        <Card style={{ height: "100%" }} bodyStyle={{ height: "100%", overflowY: "scroll", padding: "16px 24px" }}>
          {activeScreen === enumTMActiveScreen.NOTHING ? (
            <>
              <div style={{ display: mainTabs === enumTMMainTabs.TEXT_TRANSLATION ? "block" : "none" }}>
                <TextTranslationMain />
              </div>

              <div style={{ display: mainTabs === enumTMMainTabs.UPLOAD_DOCUMENTS ? "block" : "none" }}>
                <UploadDocumentMain />
              </div>
            </>
          ) : (
            <>
              {activeScreen === enumTMActiveScreen.TRANSLATE_ASSET && <TranslateAssetMain />}
              {activeScreen === enumTMActiveScreen.FINAL && <FinalTranslationScreen />}
            </>
          )}
        </Card>
      </Flex>

      <AddGlossaryModal />
    </Flex>
  );
};
