import { FC, useEffect, useMemo, useRef } from "react";
import { DataType, MenuItem, StatusTypes } from "../../core/modals";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteFile, enumDocStatus, enumHistoryIndex, getItem, handleDownloadVideo } from "../../core/constants";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT, useOutsideAlerter } from "../../../../../Utils";
import { siteConfig } from "../../../../../Services";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setCTSourceAction,
  setResponseCtAction,
  setSelectedEmailDocAction
} from "../../../../../Store/actions/_contentTranscreationActions";
import { ENUM_ROUTE_URLS } from "../../../../../Routes/_routesConfig";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { Avatar, Menu, Space, Tag, Typography } from "antd";
import { CT_SOURCE_TYPES, ENUM_PROCESS_SCREENS } from "../../../Core/constants";
import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, FormOutlined, TranslationOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { MenuComponent } from "../../components/MenuComponent";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const threeDotsMenuNotStartedItems: MenuItem[] = [
  getItem("", "1", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.rename"]}`} icon={<EditOutlined />} onClick={() => null} />)
];
const threeDotsMenuInProgressItems: MenuItem[] = [
  getItem("", "2", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.edit"]}`} icon={<FormOutlined />} onClick={() => null} />),
  ...threeDotsMenuNotStartedItems
];
const threeDotsMenuCompletedItems: MenuItem[] = [
  getItem("", "1", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.preview"]}`} icon={<EyeOutlined />} onClick={() => null} />),
  getItem("", "2", <MenuComponent title={`${I18N_MODULE_KEYS["content.button.translate"]}`} icon={<TranslationOutlined />} onClick={() => null} />),
  getItem("", "4", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.rename"]}`} icon={<EditOutlined />} onClick={() => null} />),
  getItem("", "5", <MenuComponent title={`${I18N_MODULE_KEYS["content.text.delete"]}`} icon={<DeleteOutlined />} onClick={() => null} />),
  getItem(
    "",
    "6",
    <MenuComponent title={`${I18N_MODULE_KEYS["content.transcreation.footer.button.viewsummary"]}`} icon={<EyeOutlined />} onClick={() => null} />
  )
];

// const threeDotsMenuInProgressItems: MenuItem[] = [getItem("", "1", <MenuComponent title="Rename" icon={<EditOutlined />} onClick={() => null} />)];
// const threeDotsMenuCompletedItems: MenuItem[] = [
//   getItem("", "1", <MenuComponent title="Preview" icon={<EyeOutlined />} onClick={() => null} />),
//   getItem("", "2", <MenuComponent title="Translate" icon={<TranslationOutlined />} onClick={() => null} />),
//   getItem("", "4", <MenuComponent title="Rename" icon={<EditOutlined />} onClick={() => null} />),
//   getItem("", "5", <MenuComponent title="Delete" icon={<DeleteOutlined />} onClick={() => null} />)
// ];

export const StatusButton: FC<{
  value: StatusTypes;
  record: DataType;
  index: number;
}> = ({ value, record, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const menuRef: any = useRef();

  const { responseCT, selectedEmailDoc } = useSelector((state: any) => state?.contentTranscreationReducer);

  const status: string = useMemo(() => {
    switch (value?.status) {
      case enumDocStatus.COMPLETED:
        return "success";
      case enumDocStatus.IN_PROGRESS:
        return "warning";
      case enumDocStatus.NOT_STARTED:
        return "error";
      case enumDocStatus.NOT_APPLICABLE:
        return "default";
      case enumDocStatus.FAILED:
        return "error";
      default:
        return "primary";
    }
  }, [value]);

  useOutsideAlerter(menuRef, () => dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, threeDotMenuOpen: false })));

  useEffect(() => {
    if (selectedEmailDoc?.preview) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, preview: false }));
      handleClickButton(selectedEmailDoc, selectedEmailDoc?.video?.status, enumHistoryIndex.VIDEO);
    }
  }, [selectedEmailDoc?.preview]);

  const handleClickButton = (item: DataType, status: string, columnIndex: number) => {
    //if we have "not started" and "failed" status, then only navigation does not work.

    if (status === enumDocStatus.NOT_APPLICABLE) return;

    if (status !== enumDocStatus.FAILED && status !== enumDocStatus.NOT_STARTED) {
      if (columnIndex === enumHistoryIndex.STORYBOARD && record?.video?.status !== enumDocStatus.COMPLETED) {
        localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);

        handleNavigation(
          CONTENT_TRANSCREATION_STEPS.STEP_3,
          {
            ...responseCT,
            email_doc_id: item?.email_id,
            storyboard_id: item?.storyboard?.id,
            html_id: item?.html?.id,
            platform_id: item?.storyboard?.platform?.id,
            blog_url: item?.source === "blog" ? item?.filename : "",
            isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
          },
          ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES
        );

        dispatch(setCTSourceAction(item?.source === "blog" ? CT_SOURCE_TYPES.BLOG : CT_SOURCE_TYPES.EMAIL));
      } else if (columnIndex === enumHistoryIndex.IMAGE && record?.video?.status !== enumDocStatus.COMPLETED) {
        localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN);

        handleNavigation(
          CONTENT_TRANSCREATION_STEPS.STEP_3,
          {
            ...responseCT,
            email_doc_id: item?.email_id,
            storyboard_id: item?.storyboard?.id,
            html_id: item?.html?.id,
            blog_url: item?.source === "blog" ? item?.filename : "",
            platform_id: item?.storyboard?.platform?.id,
            isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
          },
          // ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_TO_VIDEOS
          ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_TEMPLATE
        );

        dispatch(setCTSourceAction(item?.source === "blog" ? CT_SOURCE_TYPES.BLOG : CT_SOURCE_TYPES.EMAIL));
      } else if (columnIndex === enumHistoryIndex.VIDEO) {
        if (status === enumDocStatus.COMPLETED) {
          localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN);

          handleNavigation(
            CONTENT_TRANSCREATION_STEPS.STEP_3,
            {
              ...responseCT,
              email_doc_id: item?.email_id,
              storyboard_id: item?.video?.id,
              html_id: item?.html?.id,
              platform_id: item?.storyboard?.platform?.id,
              blog_url: item?.source === "blog" ? item?.filename : "",
              isVideoRendered: item?.video?.status === enumDocStatus.COMPLETED ? true : false
            },
            ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_VIDEO
          );
        } else {
          dispatch(setNotificationMsgAction("Video Generation still in progress!"));
        }
      }
    } else {
    }
  };

  const handleNavigation = (step: number, response: any, btnTxt: string) => {
    dispatch(setActiveStepperStateAction(step));

    dispatch(setResponseCtAction({ ...response }));

    dispatch(setButtonTextStateAction(btnTxt));

    navigate(ENUM_ROUTE_URLS.CONTENT_TRANSCREATION);
  };

  const toggleCollaped = (item: DataType) => {
    if (selectedEmailDoc?.threeDotMenuOpen === false) {
      dispatch(setSelectedEmailDocAction({ ...item, threeDotMenuOpen: true }));
    } else {
      dispatch(setSelectedEmailDocAction({ ...item, threeDotMenuOpen: false }));
    }
  };

  const getTagWRTStatus = (status: string) => {
    switch (status) {
      case enumDocStatus.COMPLETED:
        return `${t(I18N_MODULE_KEYS["content.text.completed"])}`;
      case enumDocStatus.IN_PROGRESS:
        return `${t(I18N_MODULE_KEYS["content.text.inprogress"])}`;
      case enumDocStatus.NOT_STARTED:
        return `${t(I18N_MODULE_KEYS["content.text.notstarted"])}`;
    }

    return "";
  };

  const getMenuItems = (item: DataType) => {
    if (item?.video?.status === enumDocStatus.COMPLETED) return threeDotsMenuCompletedItems;
    if (item?.video?.status === enumDocStatus.IN_PROGRESS) return threeDotsMenuInProgressItems;
    return threeDotsMenuNotStartedItems;
  };

  if (status === "primary" && index === enumHistoryIndex.ACTION) {
    return (
      <>
        <Space>
          {record?.video?.status === enumDocStatus.COMPLETED ? (
            <DownloadOutlined
              style={{ color: "#0546A5", cursor: "pointer" }}
              onClick={() => handleDownloadVideo(record?.video?.video_url || "", dispatch, t)}
            />
          ) : (
            <DeleteOutlined
              style={{ color: "#0546A5", cursor: "pointer" }}
              // onClick={() => (record?.video?.status === enumDocStatus.COMPLETED ? "download" : DeleteFile(record?.email_id, dispatch, t))}
              onClick={() =>
                record?.video?.status === enumDocStatus.COMPLETED
                  ? "download"
                  : dispatch(setSelectedEmailDocAction({ ...record, delete: true, threeDotMenuOpen: false }))
              }
            />
          )}
          <Text
            style={{ opacity: 1, color: "#0546A5", fontSize: "12px", cursor: "pointer" }}
            onClick={() =>
              record?.video?.status === enumDocStatus.COMPLETED
                ? handleDownloadVideo(record?.video?.video_url || "", dispatch, t)
                : // : DeleteFile(record?.email_id, dispatch, t)
                  dispatch(setSelectedEmailDocAction({ ...record, delete: true, threeDotMenuOpen: false }))
            }
          >
            {/* {record?.video?.status === enumDocStatus.COMPLETED ? "Download" : "Delete"} */}
            {record?.video?.status === enumDocStatus.COMPLETED
              ? `${t(I18N_MODULE_KEYS["content.text.download"])}`
              : `${t(I18N_MODULE_KEYS["content.text.delete"])}`}
          </Text>

          <Avatar
            src={PROJECT_ICONS.CH_THREE_DOTS_VERTICAL}
            style={{ position: "absolute", background: "transparent", top: "15px", right: "30px", cursor: "pointer", height: "26px", width: "22px" }}
            onClick={() => toggleCollaped(record)}
          />
          {selectedEmailDoc?.email_id === record?.email_id && selectedEmailDoc?.threeDotMenuOpen && (
            <div ref={menuRef}>
              <Menu
                style={{
                  position: "absolute",
                  top: "40px",
                  right: "37px",
                  zIndex: 1,
                  width: "150px",
                  borderRadius: "4px",
                  boxShadow: "0px 0px 10px #0000001A",
                  background: "#FFFFFF 0% 0% no-repeat padding-box"
                }}
                items={getMenuItems(record)}
              />
            </div>
          )}
        </Space>
      </>
    );
  } else {
    return (
      <Tag
        color={record?.video?.status === enumDocStatus.COMPLETED && index !== enumHistoryIndex.VIDEO ? "default" : `${status}`}
        onClick={() => handleClickButton(record, value?.status, index)}
        style={{ cursor: record?.video?.status === enumDocStatus.COMPLETED && index !== enumHistoryIndex.VIDEO ? "no-drop" : `pointer` }}
      >
        {t(getTagWRTStatus(value?.status))}
      </Tag>
    );
  }
};
