import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/**Liraries Imports */
import TextArea from "antd/es/input/TextArea";
import { Button, Card, Col, Modal, Row, Space, Typography } from "antd";

/**Global Methods and Constants Imports*/
import { siteConfig } from "../../../Services";
import { GenerateImageContentTypes } from "../Core/modals";
import { ENUM_ROUTE_URLS } from "../../../Routes/_routesConfig";
import { CT_SOURCE_TYPES, ENUM_PROCESS_SCREENS } from "../Core/constants";
import { setActionDrawerDataAction } from "../../../Components/ActionDrawer/State";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import {
  CONTENT_TRANSCREATION_STEPS,
  CT_DASHBOARD_STATUS,
  ENUM_CONTENT_TRANSCREATION_BUTTON_TXT,
  globalConstant,
  handleCancelApiRequest
} from "../../../Utils";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setNextStepperStateAction,
  setPlatformListStateAction,
  setPreviousStepperStateAction,
  setRefetchListStateAction,
  setRegenerateStateAction,
  setResponseCtAction
} from "../../../Store/actions/_contentTranscreationActions";

// type video = {
//   thumbnail: string;
//   source: string;
//   height: number;
//   width: number;
// };

/**Request Methods Imports*/
import {
  createVideoRequest,
  editStoryBoardRequest,
  getImagesDashboardRequest,
  getImagesRequest,
  getVideoRequest,
  storyBoardDashboardRequest,
  createStoryBoardRequest,
  regnerateCustomImages,
  replaceGeneratedImageRequest,
  createBlogUrlRequest,
  storyBoardReorderRequest,
  getPlatformListRequest
} from "../../../Store/requests/_contentTranscreationRequests";

/**Components Imports*/
import { Error } from "../../../Components/Error";
import ProcessAvatar from "../Components/Process/ProcessAvatar";
import ProcessTemplate from "../Components/Process/ProcessTemplate";
import { CustomButtonForApiCalling } from "../../../Components/Buttons";
import { GeneratedImageScreen, ImgGenSrcSelectionScreen, RegenerateModal, StoryBoardListScreen, VideoProcessScreen } from "../Components";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { NameValueTypes } from "../../../Utils/_gTypes";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { SSE } from "sse.js";

const { Paragraph, Text } = Typography;

const getCustomSSE = (url: string, isJson: boolean = true, payload?: any) => {
  var headers: Record<string, string> = {
    Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
  };

  const sse = new SSE(siteConfig.BASE_URL + url, {
    headers,
    payload: isJson ? JSON.stringify(payload) : payload
  });

  return sse;
};

type ProcessBodyStructureTypes = {
  generate_images: GenrateImagesBodyTypes;
  proceed_to_videos: any[];
  generate_videos: BodyTypes;
  videoTemplates: any[];
  avatars: any[];
};

type GenrateImagesBodyTypes = {
  stories: GenerateImageContentTypes[];
  status: string;
};

type BodyTypes = {
  content: any;
  status: string;
};

type regenerateImageType = {
  id: number;
  file?: any;
  story_id?: number;
  scene?: string;
};

const initialGenerateImages = {
  stories: [],
  status: CT_DASHBOARD_STATUS.PENDING
};

const initalBody: BodyTypes = {
  content: "",
  status: CT_DASHBOARD_STATUS.PENDING
};

const initialProcessBodyStructure: ProcessBodyStructureTypes = {
  generate_images: { ...initialGenerateImages },
  proceed_to_videos: [],
  generate_videos: { ...initalBody },
  videoTemplates: [],
  avatars: []
};

//PAGE                        current process step          Footer Button Text              Now
//Storyboard Page >>             PROCEED_IMAGES           Proceed to image creation
//Select Midjourney screen >>    GENERATE_IMAGES            Generate Images
//Generated Image >>             PROCEED_TO_VIDEOS          Proceed To Videos             Choose Template
//choose template >>              CHOOSE_TEMPLATE             Choose Avatar -- new
//choose avatar >>                CHOOSE_AVATAR               Order video   -- new
//Generate Video >>               GENERATE_VIDEO             Generate Video --skip
//Generated Video >>             GO_TO_DASHBOARD             Go to Dashboard

export const CTProcess: FC<{ setOpenBUM?: Function }> = ({ setOpenBUM }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const timerRef: any = useRef();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);
  const { activeStepper, nextStepper, previousStepper, responseCT, source, buttonText, regenerate, refetchList, platformList } = useSelector(
    (state: any) => state?.contentTranscreationReducer
  );

  const [body, setBody] = useState<ProcessBodyStructureTypes>({
    ...initialProcessBodyStructure
  });
  const [error, setError] = useState<boolean>(false);
  const [emailDocId, setEmailDocId] = useState<number>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false); //for storyboard list screen, selcting or deselcting all storyboard by one click
  const [showModalVideo, setShowModalVideo] = useState<boolean>(false);
  const [loadingForModal, setLoadingForModal] = useState<boolean>(false);
  const [regenerateShowModal, setRegenerateShowModal] = useState<boolean>(false);
  const [currentStoryBoardForEdit, setCurrentStoryBoardForEdit] = useState<any>({});
  const [errorEditSB, setErrorEditSB] = useState<any>({});
  const [regenerateImageData, setRegenerateImageData] = useState<regenerateImageType>({
    id: 0,
    file: undefined,
    story_id: undefined,
    scene: undefined
  });
  const [currentProcessStep, setCurrentProcessStep] = useState<string>(ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);
  const [sceneSelection, setSceneSelection] = useState<GenerateImageContentTypes | null>(null);
  const [sceneSelectionAll, setSceneSelectionAll] = useState<boolean>(false);

  useEffect(() => {
    const step = localStorage.getItem(siteConfig.CT_PROCESS_KEY);

    setCurrentProcessStep(step ? step : ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);

    setPlatformListInRedux();

    if (source === CT_SOURCE_TYPES.EMAIL) {
      initiateEmail(step);
    } else if (source === CT_SOURCE_TYPES.BLOG) {
      initiateBlog(step);
    } else {
      console.log("Not Email or Blog!");
    }

    return () => {
      localStorage.removeItem(siteConfig?.CT_PROCESS_KEY);
      clearTimeout(timerRef.current);
      dispatch(setLoadingStatusAction(false));
    };
  }, []);

  const initiateEmail = async (step: string | null) => {
    const { storyboard_id } = responseCT;

    if (!storyboard_id) return;

    if (step === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN || step === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD) {
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES));
      await createStoryBoardDashBoard(storyboard_id);
    } else if (step === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN) {
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_TEMPLATE_EMAIL));
      // await createStoryBoardDashBoard(storyboard_id, step);
      await getImagesDashboard(storyboard_id);
    } else if (step === ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN) {
      await createStoryBoardDashBoard(storyboard_id, step);
      getVideoDashboard(storyboard_id);
    } else {
      console.log("else of initiate storyboard 3_process.tsx");
    }
  };

  const initiateBlog = async (step: string | null) => {
    const { storyboard_id } = responseCT;

    if (step === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN || step === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD) {
      createBlogUrl();
      // dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES));
      // await createStoryBoardDashBoard(storyboard_id);
    } else if (step === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN) {
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_TEMPLATE_EMAIL));
      // await createStoryBoardDashBoard(storyboard_id, step);
      await getImagesDashboard(storyboard_id);
    } else if (step === ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN) {
      await createStoryBoardDashBoard(storyboard_id, step);
      getVideoDashboard(storyboard_id);
    } else {
      console.log("else of initiate storyboard 3_process.tsx");
    }
  };

  useEffect(() => {
    if (nextStepper && (activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_3 || activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_4)) {
      dispatch(setNextStepperStateAction(false));

      handleCancelApiRequest();

      if (currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN) {
        generateImagesNew();
        // setCurrentProcessStep(ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN);
        // dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN) {
        // generateImagesNew();
        generateImages();
        // getImagesDashboard(responseCT?.storyboard_id);
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN) {
        setCurrentProcessStep(ENUM_PROCESS_SCREENS.TEMPLATE_SELECTION_SCREEN);
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_AVATAR_EMAIL));
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.TEMPLATE_SELECTION_SCREEN) {
        setCurrentProcessStep(ENUM_PROCESS_SCREENS.AVATAR_SELECTION_SCREEN);
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.ORDER_VIDEO));
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.AVATAR_SELECTION_SCREEN) {
        createVideos();
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.GO_TO_DASHBOARD) {
        submitFinal();
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN) {
        submitFinal();
      }
    }
  }, [nextStepper]);

  useEffect(() => {
    if (previousStepper && (activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_3 || activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_4)) {
      dispatch(setPreviousStepperStateAction(false));

      dispatch(setLoadingStatusAction(false));

      handleCancelApiRequest();

      setError(false);

      clearTimeout(timerRef.current);

      if (source === CT_SOURCE_TYPES.EMAIL && currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN) {
        // dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_2));
        dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_1));
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD));
      } else if (source == CT_SOURCE_TYPES.BLOG && currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN) {
        //dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_0));
        dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS["STEP_0.5"]));
        setOpenBUM?.(true);
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN) {
        setCurrentProcessStep(ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES));
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN) {
        setCurrentProcessStep(ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN);
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.TEMPLATE_SELECTION_SCREEN) {
        setCurrentProcessStep(ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN);
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_TEMPLATE_EMAIL));
      } else if (currentProcessStep === ENUM_PROCESS_SCREENS.AVATAR_SELECTION_SCREEN) {
        setCurrentProcessStep(ENUM_PROCESS_SCREENS.TEMPLATE_SELECTION_SCREEN);
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_AVATAR_EMAIL));
      }
    }
  }, [previousStepper]);

  useEffect(() => {
    if (regenerate && (activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_3 || activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_4)) {
      if (responseCT?.has_next === false) {
        regnerateStoryboard();
      } else if (responseCT?.has_next === true) {
        setRegenerateShowModal(true);
        dispatch(setRegenerateStateAction(false));
      }
    }
  }, [regenerate]);

  useEffect(() => {
    if (
      refetchList &&
      (activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_3 || activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_4) &&
      currentProcessStep === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN
    ) {
      dispatch(setRefetchListStateAction(false));
      getImagesDashboard(responseCT?.storyboard_id);
    }
  }, [refetchList]);

  useEffect(() => {
    localStorage.setItem(siteConfig.CT_PROCESS_KEY, currentProcessStep);

    if (
      currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN &&
      nextStepper === false &&
      responseCT?.storyboard?.stories?.length &&
      buttonText !== ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD
    ) {
      createStoryBoardDashBoard(responseCT?.storyboard_id);
    }
  }, [currentProcessStep]);

  useEffect(() => {
    if (currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN) {
      const isAllSelected = body?.generate_images?.stories?.every?.((item: GenerateImageContentTypes) => item?.checked === true);

      setIsAllSelected(isAllSelected === true ? true : false);
    }
  }, [currentProcessStep && body?.generate_images?.stories]);

  const setPlatformListInRedux = async () => {
    const res = await getPlatformListRequest();

    if (res) {
      dispatch(setPlatformListStateAction(res));
    }
  };

  const createBlogUrl = async () => {
    if (responseCT?.storyboard_id && responseCT?.email_doc_id) {
      createStoryBoardDashBoard(responseCT?.storyboard_id);
      return;
    }

    dispatch(setLoadingStatusAction(true));

    const res = await createBlogUrlRequest({
      blog_url: responseCT?.blog_url
    });

    if (res?.message === globalConstant.SOMETHING_WENT_WRONG) {
      setError(true);
      dispatch(setLoadingStatusAction(false));

      // dispatch(setResponseCtAction({}));
      return;
    }

    dispatch(
      setResponseCtAction({
        ...responseCT,
        blog_id: res?.message,
        is_new: res?.is_new,
        email_doc_id: res?.message
      })
    );

    setEmailDocId(res?.message);

    dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES));

    // const storyBoardIdRes = await createStoryBoardRequest({
    //   email_doc_id: res?.message,
    //   regenerate: true
    // });

    const sse = await getCustomSSE(siteConfig.CREATE_STORYBOARD, true, { email_doc_id: res?.message || emailDocId, regenerate: true });

    sse.addEventListener("end", (data: any) => {
      sse.close();

      if (data?.data) {
        dispatch(
          setResponseCtAction({
            ...responseCT,
            storyboard_id: data?.data
          })
        );

        createStoryBoardDashBoard(data?.data);
      } else if (data?.detail) {
        dispatch(setNotificationMsgAction(data?.detail));
      }
    });

    sse.onerror = () => {
      setError(true);
      dispatch(setLoadingStatusAction(false));

      sse.close();
    };

    // if (!storyBoardIdRes) {
    //   setError(true);
    //   dispatch(setLoadingStatusAction(false));
    //   return;
    // }

    // createStoryBoardDashBoard(storyBoardIdRes?.message);
  };

  const createStoryBoardDashBoard = async (storyboard_id: number, step?: string | null) => {
    clearTimeout(timerRef.current);

    const res = await storyBoardDashboardRequest(storyboard_id, dispatch);

    dispatch(setLoadingStatusAction(true));

    if (res?.error && res?.status === CT_DASHBOARD_STATUS.FAILED) {
      dispatch(setNotificationMsgAction(res?.error));

      return;
    }

    if (res && res?.storyboard && res?.storyboard?.stories) {
      setBody((p: ProcessBodyStructureTypes) => ({
        ...p,
        generate_images: {
          stories: [...res?.storyboard?.stories] || [],
          status: res?.storyboard?.status
        }
      }));

      dispatch(
        setResponseCtAction({
          ...responseCT,
          ...res,
          storyboard_id: res?.storyboard?.id,
          platform_id: res?.storyboard?.platform?.id
        })
      );

      if (res?.storyboard?.status === CT_DASHBOARD_STATUS.SUCCESS) {
        dispatch(setLoadingStatusAction(false));

        // const stories: GenerateImageContentTypes[] =
        //   isAllSelected === true ? getUpadatedStoriesWRTSelectAll(isAllSelected, res?.storyboard?.stories) : res?.storyboard?.stories;

        setBody((p: ProcessBodyStructureTypes) => ({
          ...p,
          generate_images: {
            // stories: [...stories] || [],
            stories: [...res?.storyboard?.stories] || [],
            status: res?.storyboard?.status
          }
        }));

        if (step === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_TO_VIDEOS && checkStatusOfAllImages(res?.storyboard?.stories)) {
          setTimeout(() => {
            getImagesDashboard(storyboard_id);
          }, 500);
        }
        return;
      }

      timerRef.current = setTimeout(() => {
        createStoryBoardDashBoard(storyboard_id);
      }, 5000);
    }
  };

  const handleSceneReorder = async (reorderedStories: any) => {
    let res = await storyBoardReorderRequest({
      storyboard_id: responseCT?.storyboard_id,
      stories: reorderedStories.map((story: any, index: any) => {
        story.order_id = index + 1;
        return story;
      }) as []
    });

    if (res?.message === "Storyboard content updated successfully") {
      console.log("Order Updated!");
      setBody({
        ...body,
        generate_images: { ...body?.generate_images, stories: reorderedStories }
      });
    }
  };

  const handlePrompts = (e: any, objPrompts: GenerateImageContentTypes, index: number) => {
    let arrPrompts: GenerateImageContentTypes[] = [...body?.generate_images?.stories];

    const { checked } = e?.target;

    if (responseCT?.has_next === true) {
      dispatch(setRegenerateStateAction(true));
      setSceneSelection({ ...objPrompts, checked: checked });
      return;
    }

    arrPrompts[index]["checked"] = checked;

    setBody({
      ...body,
      generate_images: { ...body?.generate_images, stories: [...arrPrompts] }
    });
  };

  const getUpadatedStoriesWRTSelectAll = (isAllSelected: boolean, stories: GenerateImageContentTypes[]) => {
    setIsAllSelected(isAllSelected);

    let arrPrompts: GenerateImageContentTypes[] = [...stories];

    arrPrompts?.forEach((item: GenerateImageContentTypes) => {
      item["checked"] = isAllSelected;
    });

    // return stories;
    return [...arrPrompts];
  };

  const setUpdatedStoriesWRTSelectAll = (stories: GenerateImageContentTypes[]) => {
    setBody({
      ...body,
      generate_images: { ...body?.generate_images, stories: [...stories] }
    });
  };

  const checkStatusOfAllImages = (arrImages: any) => {
    return arrImages?.filter((item: any) => item?.checked === true)?.some((item: any) => item?.image_status === CT_DASHBOARD_STATUS.PENDING);
  };

  const generateImagesNew = async (existedStories?: any[]) => {
    const { storyboard_id } = responseCT;

    let { stories } = body?.generate_images;

    if (existedStories) {
      stories = [...existedStories];
    }

    let prompts = stories?.filter((item) => item?.checked === true);

    dispatch(setLoadingStatusAction(true));

    const res = await getImagesRequest(
      {
        storyboard_id: storyboard_id,
        stories: prompts?.map((item) => item?.id),
        regenerate: false,
        platform_id: responseCT?.platform_id || 1
      },
      dispatch
    );

    if (res && (res?.message || res?.error)) {
      const { message, error } = res;

      if (error && error?.length) {
        dispatch(setNotificationMsgAction(error));
        dispatch(setLoadingStatusAction(false));
      }

      // if (message && message?.length) {
      if (message) {
        if (message?.length) {
          setCurrentProcessStep(ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN);
          dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));
          dispatch(setResponseCtAction({ ...responseCT, has_next: res?.has_next }));
        }
      }
    } else {
      dispatch(setLoadingStatusAction(false));

      setCurrentProcessStep(ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);

      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.PROCEED_IMAGES));
    }
  };

  const generateImages = async (existedStories?: any[]) => {
    const { storyboard_id } = responseCT;

    let { stories } = body?.generate_images;

    if (existedStories) {
      stories = [...existedStories];
    }

    let prompts = stories?.filter((item) => item?.checked === true);

    dispatch(setLoadingStatusAction(true));

    const res = await getImagesRequest(
      {
        storyboard_id: storyboard_id,
        stories: prompts?.map((item) => item?.id),
        regenerate: false,
        platform_id: responseCT?.platform_id
      },
      dispatch
    );

    if (res && (res?.message || res?.error)) {
      const { message, error } = res;

      if (error && error?.length) {
        dispatch(setNotificationMsgAction(error));
        dispatch(setLoadingStatusAction(false));
      }

      // if (message && message?.length) {
      if (message) {
        if (message?.length) {
          getImagesDashboard(storyboard_id);
        } else if (responseCT?.platform_id === 4) {
          getImagesDashboard(storyboard_id);
        } else {
          console.log("catching in else section of post image api");
        }
      }
    } else {
      dispatch(setLoadingStatusAction(false));

      setCurrentProcessStep(ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN);

      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));
    }
  };

  const getImagesDashboard = async (storyboard_id: number) => {
    clearTimeout(timerRef.current);

    // const res = await getImagesDashboardRequest(storyboard_id, dispatch);
    const res = await getImagesDashboardRequest(storyboard_id, dispatch);

    dispatch(setLoadingStatusAction(true));

    if (res && res?.storyboard && res?.storyboard?.stories) {
      setBody((p: ProcessBodyStructureTypes) => ({
        ...p,
        generate_images: {
          stories: [...res?.storyboard?.stories] || [],
          status: res?.storyboard?.status
        }
      }));

      dispatch(
        setResponseCtAction({
          ...responseCT,
          // ...res?.storyboard,
          ...res,
          storyboard_id: res?.storyboard?.id,
          platform_id: res?.storyboard?.platform?.id
        })
      );

      setCurrentProcessStep(ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN);

      if (res?.error && res?.status === CT_DASHBOARD_STATUS.FAILED) {
        dispatch(setNotificationMsgAction(res?.error));
        dispatch(setLoadingStatusAction(false));
        return;
      }

      if (res?.storyboard?.platform?.platform_type === "image" && checkStatusOfAllImages(res?.storyboard?.stories)) {
        timerRef.current = setTimeout(() => {
          getImagesDashboard(storyboard_id);
        }, 5000);
      } else {
        dispatch(setLoadingStatusAction(false));
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CHOOSE_TEMPLATE_EMAIL));
      }
    } else {
      dispatch(setLoadingStatusAction(false));
      setCurrentProcessStep(ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN);
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));
    }
  };

  const createVideos = async () => {
    const { storyboard_id } = responseCT;

    dispatch(setLoadingStatusAction(true));

    const sse = await getCustomSSE(siteConfig.CREATE_VIDEO, true, {
      storyboard_id: storyboard_id,
      template_id: responseCT?.template ? responseCT?.template?.id : null,
      avatar_id: responseCT?.avatar ? responseCT?.avatar?.id : null
    });

    sse.addEventListener("end", (data: any) => {
      dispatch(setLoadingStatusAction(false));
      sse.close();

      if (data?.error && data?.error?.length) {
        dispatch(setNotificationMsgAction(data?.error));
      } else if (data?.data) {
        setShowModalVideo(true);
        dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_4));
      }
    });

    sse.onerror = () => {
      dispatch(setLoadingStatusAction(false));

      sse.close();
    };

    // if (res && (res?.message || res?.error)) {
    //   const { message, error } = res;

    //   if (error && error?.length) {
    //     dispatch(setNotificationMsgAction(error));
    //   } else if (message) {
    //     setShowModalVideo(true);
    //     dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_4));
    //   }
    // }
  };

  const getVideoDashboard = async (video_dashboard_id: number) => {
    clearTimeout(timerRef.current);

    const data = await getVideoRequest(video_dashboard_id, dispatch);

    dispatch(setLoadingStatusAction(true));

    setBody((p: ProcessBodyStructureTypes) => ({
      ...p,
      generate_videos: { content: { ...data }, status: data?.status }
    }));

    dispatch(setResponseCtAction({ ...responseCT, ...data }));

    if (data?.status === CT_DASHBOARD_STATUS.SUCCESS) {
      dispatch(setLoadingStatusAction(false));

      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GO_TO_DASHBOARD));

      dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_4));

      return;
    }

    timerRef.current = setTimeout(() => {
      getVideoDashboard(video_dashboard_id);
    }, 5000);
  };

  const submitFinal = () => {
    navigate(ENUM_ROUTE_URLS.DASHBOARD);
  };

  const handleOnChangeStoryBoard = (e: any) => {
    const { name, value } = e?.target;

    setErrorEditSB({});

    setCurrentStoryBoardForEdit((p: any) => ({
      ...p,
      [name]: value
    }));
  };

  const handleEditStoryboard = async () => {
    const { storyboard_id } = responseCT;

    let objErrorEditSB: any = { ...errorEditSB };

    let error = false;

    if (!currentStoryBoardForEdit?.title) {
      error = true;
      objErrorEditSB["title"] = t(I18N_MODULE_KEYS["content.text.Title should not be empty!"]);
    }
    if (!currentStoryBoardForEdit?.scene) {
      error = true;
      objErrorEditSB["scene"] = t(I18N_MODULE_KEYS["content.text.Scene should not be empty!"]);
    }
    if (!currentStoryBoardForEdit?.narration) {
      error = true;
      objErrorEditSB["narration"] = t(I18N_MODULE_KEYS["content.text.Narration should not be empty!"]);
    }

    if (error) {
      setErrorEditSB({ ...objErrorEditSB });
      return;
    }
    setLoadingForModal(true);

    const res = await editStoryBoardRequest({
      storyboard_id: storyboard_id,
      stories: [{ ...currentStoryBoardForEdit }]
    });

    setLoadingForModal(false);

    if (res && res?.message) {
      dispatch(setNotificationMsgAction(res?.message));
      setShowModal(false);
      setCurrentStoryBoardForEdit({});
      setTimeout(() => {
        createStoryBoardDashBoard(storyboard_id);
      }, 100);
    }
  };

  const createStoryboardApi = async () => {
    const { email_doc_id } = responseCT;

    let emailDocIdLocal: number = emailDocId || email_doc_id;

    if (!emailDocId && !email_doc_id) {
      const res = await createBlogUrlRequest({
        blog_url: responseCT?.blog_url
      });

      if (res?.message === globalConstant.SOMETHING_WENT_WRONG) {
        setError(true);
        dispatch(setLoadingStatusAction(false));
        return;
      }

      dispatch(
        setResponseCtAction({
          ...responseCT,
          blog_id: res?.message,
          is_new: res?.is_new,
          email_doc_id: res?.message
        })
      );

      setEmailDocId(res?.message);

      emailDocIdLocal = res?.message;
    }

    setBody({
      ...body,
      generate_images: { ...body?.generate_images, status: CT_DASHBOARD_STATUS.PENDING }
    });

    dispatch(setLoadingStatusAction(true));

    const sse = await getCustomSSE(siteConfig.CREATE_STORYBOARD, true, { email_doc_id: emailDocIdLocal, regenerate: true });

    sse.addEventListener("end", (data: any) => {
      sse.close();

      if (data?.data) {
        dispatch(
          setResponseCtAction({
            ...responseCT,
            storyboard_id: data?.data
          })
        );

        createStoryBoardDashBoard(data?.data);
      } else if (data?.detail) {
        dispatch(setNotificationMsgAction(data?.detail));
      }
    });

    sse.onerror = () => {
      dispatch(setLoadingStatusAction(false));

      sse.close();
    };

    // if (res && res?.message) {
    //   const { message } = res;

    //   // Update the storyboard_id in responseCT
    //   dispatch(
    //     setResponseCtAction({
    //       ...responseCT,
    //       storyboard_id: message
    //     })
    //   );

    //   // if (has_next) {
    //   //   setRegenerateShowModal(true);
    //   //   return;
    //   // }

    //   createStoryBoardDashBoard(message);
    // } else if (typeof res?.detail) {
    //   dispatch(setNotificationMsgAction(res?.detail));
    // }
  };

  const regnerateStoryboard = async () => {
    await createStoryboardApi();
    dispatch(setRegenerateStateAction(false));
  };

  const regenerateImage = async (itemId: number) => {
    const { storyboard_id } = responseCT;

    // Make the API request to create the storyboard using the html_id
    const res = await regnerateCustomImages({
      storyboard_id: storyboard_id,
      story_id: itemId
    });

    if (res && res?.message) {
      const { storyboard_id } = responseCT;

      dispatch(setResponseCtAction({ ...responseCT, storyboard_id: storyboard_id }));

      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));

      setTimeout(() => {
        getImagesDashboard(storyboard_id);
      }, 100);
    }
  };

  const replaceGeneratedImage = async (itemId: number, file: any) => {
    const { storyboard_id } = responseCT;

    const formData = new FormData();

    formData?.append("file", file?.originFileObj);

    const res = await replaceGeneratedImageRequest(
      {
        story_id: itemId,
        storyboard_id: storyboard_id,
        file: formData
      },
      dispatch
    );

    if (res) {
      const { storyboard_id } = responseCT;

      dispatch(setResponseCtAction({ ...responseCT, storyboard_id: storyboard_id }));

      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES));

      setTimeout(() => {
        getImagesDashboard(storyboard_id);
      }, 100);
    }
  };

  const sortStoriesByOrderId = (stories: any) => {
    return stories?.sort((a: any, b: any) => a.order_id - b.order_id);
  };

  const updateStoryboard = (res: any) => {
    setBody((p: ProcessBodyStructureTypes) => ({
      ...p,
      generate_images: {
        stories: res.storyboard?.stories,
        status: res.storyboard?.status
      }
    }));

    dispatch(setResponseCtAction({ ...responseCT, storyboard: res.storyboard }));
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 24 }}>
          <Card
            style={{
              boxShadow: "0px 6px 18px #00000029",
              borderRadius: "12px"
            }}
          >
            {error ? (
              <Error />
            ) : (
              <>
                {currentProcessStep === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN && (
                  <>
                    <Paragraph
                      className="optimize-font"
                      style={{
                        fontSize: "15px",
                        marginBottom: "0px",
                        font: "normal normal bold 18px/30px Nunito Sans",
                        fontWeight: "800"
                      }}
                    >
                      {/* Image Generation */}
                      {/* {responseCT?.storyboard?.platform?.platform_type === "video" ? "Clip " : "Image "}Generation */}
                      {responseCT?.storyboard?.platform?.platform_type === "video"
                        ? `${t(I18N_MODULE_KEYS["content.transcreation.process.generatedimage.title.clipgeneration"])}`
                        : `${t(I18N_MODULE_KEYS["content.transcreation.process.generatedimage.title.imagegeneration"])}`}
                    </Paragraph>
                    <Paragraph
                      type="secondary"
                      className="optimize-font"
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      {/* Kindly choose a single or multiple options to generate video */}
                      {t(I18N_MODULE_KEYS["content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo"])}
                    </Paragraph>
                  </>
                )}

                {currentProcessStep === ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN && (
                  <Paragraph
                    className="optimize-font"
                    style={{
                      fontSize: "15px",
                      font: "normal normal bold 16px/24px Nunito Sans",
                      fontWeight: "800"
                    }}
                  >
                    {/* Video Generation */}
                    {t(I18N_MODULE_KEYS["content.transcreation.successful.title.videogeneration"])}
                  </Paragraph>
                )}

                {(currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN ||
                  currentProcessStep === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.ORDER_VIDEO) && (
                  <StoryBoardListScreen
                    status={body?.generate_images?.status}
                    stories={sortStoriesByOrderId(body?.generate_images?.stories)}
                    handleSelectAll={(isAllSelected, stories) => {
                      if (responseCT?.has_next === true) {
                        dispatch(setRegenerateStateAction(true));
                        setSceneSelectionAll(true);
                        return;
                      }

                      setUpdatedStoriesWRTSelectAll(getUpadatedStoriesWRTSelectAll(isAllSelected, stories));
                    }}
                    handlePrompts={handlePrompts}
                    handleSceneReorder={handleSceneReorder}
                    setCurrentStoryBoardForEdit={setCurrentStoryBoardForEdit}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    isAllSelected={isAllSelected}
                    updateStoryboard={updateStoryboard}
                  />
                )}

                {currentProcessStep === ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN && (
                  <ImgGenSrcSelectionScreen list={platformList} />
                )}

                {currentProcessStep === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN && (
                  <GeneratedImageScreen
                    stories={body?.generate_images?.stories}
                    replaceGeneratedImage={(id, file) => {
                      if (responseCT?.has_next === false) {
                        replaceGeneratedImage(id, file);
                        setRegenerateImageData({ id: 0 });
                      } else {
                        setRegenerateImageData({ file: file, id: id });
                        setRegenerateShowModal(true);
                      }
                    }}
                    regenerateImage={(id) => {
                      if (responseCT?.has_next === false) {
                        regenerateImage(id);
                        setRegenerateImageData({ id: 0 });
                      } else {
                        setRegenerateImageData({ id: id });
                        setRegenerateShowModal(true);
                      }
                    }}
                    addLiveImageOrVideo={(story_id, scene) => {
                      if (responseCT?.has_next === false) {
                        dispatch(
                          setActionDrawerDataAction({
                            drawerOpen: true,
                            drawerWidth: "400px",
                            componentId: "AddLiveImagesOrVideos",
                            // drawerTitle: "Add Live Images/Videos",
                            // drawerTitle: "Add Live Clips",
                            drawerTitle: `${t(I18N_MODULE_KEYS["content.transcreation.process.generatedimage.drawer.title.addliveclips"])}`,
                            others: { story_id: story_id, scene: scene }
                          })
                        );
                        setRegenerateImageData({ id: 0, story_id: 0 });
                      } else {
                        setRegenerateImageData({ id: 0, story_id: story_id, scene: scene });
                        setRegenerateShowModal(true);
                      }
                    }}
                    isLoading={isLoading}
                    platform={responseCT?.storyboard?.platform}
                  />
                )}
                {currentProcessStep === ENUM_PROCESS_SCREENS.TEMPLATE_SELECTION_SCREEN && <ProcessTemplate />}

                {currentProcessStep === ENUM_PROCESS_SCREENS.AVATAR_SELECTION_SCREEN && <ProcessAvatar />}

                {currentProcessStep === ENUM_PROCESS_SCREENS.VIDEO_PREVIEW_SCREEN && (
                  <VideoProcessScreen status={body?.generate_videos?.status} video_url={body?.generate_videos?.content?.video_url || ""} />
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move"
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {/* Edit Story Board */}
            {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.modal.title.editstoryboard"])}
          </div>
        }
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          setErrorEditSB({});
        }}
        footer
      >
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }}>
            <Paragraph
              type="secondary"
              className="optimize-font"
              style={{
                fontSize: "12px",
                marginBottom: "0px"
              }}
            >
              {/* Title */}
              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.title"])}
            </Paragraph>
            <TextArea
              onChange={handleOnChangeStoryBoard}
              style={{ border: errorEditSB?.title && "1px solid red" }}
              value={currentStoryBoardForEdit?.title}
              name="title"
              autoSize={{ maxRows: 6 }}
              required
            />
            {errorEditSB?.title && (
              <span
                style={{
                  fontSize: "11px",
                  marginBottom: "0px",
                  color: "red",
                  fontWeight: "700"
                }}
              >
                {errorEditSB?.title}
              </span>
            )}
          </Col>
          <Col xs={{ span: 24 }}>
            <Paragraph
              type="secondary"
              className="optimize-font"
              style={{
                fontSize: "12px",
                marginBottom: "0px"
              }}
            >
              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.scene"])}
            </Paragraph>
            <TextArea
              onChange={handleOnChangeStoryBoard}
              style={{ border: errorEditSB?.scene && "1px solid red" }}
              value={currentStoryBoardForEdit?.scene}
              name="scene"
              autoSize={{ maxRows: 6 }}
              required
            />
            {errorEditSB?.scene && (
              <span
                style={{
                  fontSize: "11px",
                  marginBottom: "0px",
                  color: "red",
                  fontWeight: "700"
                }}
              >
                {errorEditSB?.scene}
              </span>
            )}
          </Col>
          <Col xs={{ span: 24 }}>
            <Paragraph
              type="secondary"
              className="optimize-font"
              style={{
                fontSize: "12px",
                marginBottom: "0px"
              }}
            >
              {/* Narration */}
              {t(I18N_MODULE_KEYS["content.transcreation.process.storyboard.text.narration"])}
            </Paragraph>
            <TextArea
              name="narration"
              onChange={handleOnChangeStoryBoard}
              style={{ border: errorEditSB?.narration && "1px solid red" }}
              value={currentStoryBoardForEdit?.narration}
              autoSize={{ maxRows: 6 }}
              required
            />
            {errorEditSB?.narration && (
              <span
                style={{
                  fontSize: "11px",
                  marginBottom: "0px",
                  color: "red",
                  fontWeight: "700"
                }}
              >
                {errorEditSB?.narration}
              </span>
            )}
          </Col>

          {/* <Col xs={{ span: 24 }} sm={{ span: 4 }}>
          </Col> */}
          <Col xs={{ span: 24 }}>
            <Space style={{ marginTop: "10px" }}>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setErrorEditSB(false);
                }}
              >
                {t(I18N_MODULE_KEYS["content.button.cancel"])}
              </Button>
              <CustomButtonForApiCalling
                btnText={t(I18N_MODULE_KEYS["content.button.submit"])}
                loading={loadingForModal}
                onClick={handleEditStoryboard}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
      <Modal
        style={{ marginTop: "140px" }}
        open={showModalVideo}
        keyboard={false}
        closable={false}
        footer
        title={t(I18N_MODULE_KEYS["content.transcreation.modal.ordervideosuccess"])}
      >
        <Row gutter={[8, 8]}>
          <Col></Col>

          <Col
            xs={{
              span: 24
            }}
          >
            <Button
              className="ant-btn-md"
              type="primary"
              style={{ marginRight: "5px", background: "#0054CF" }}
              onClick={() => {
                navigate(ENUM_ROUTE_URLS.DASHBOARD);
              }}
            >
              {/* Go to Dashboard */}
              {t(I18N_MODULE_KEYS["content.text.gotodashboard"])}
            </Button>
          </Col>
        </Row>
      </Modal>

      <RegenerateModal
        showModal={regenerateShowModal}
        setShowModal={(val, isSave) => {
          if (isSave) {
            if (currentProcessStep === ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN) {
              if (sceneSelection?.id) {
                let arrPrompts: GenerateImageContentTypes[] = [
                  ...body?.generate_images?.stories?.map((item: GenerateImageContentTypes) => {
                    if (item?.id === sceneSelection?.id) {
                      return sceneSelection;
                    }
                    return item;
                  })
                ];

                setBody({
                  ...body,
                  generate_images: { ...body?.generate_images, stories: [...arrPrompts] }
                });

                setSceneSelection(null);
              } else if (sceneSelectionAll === true) {
                setSceneSelectionAll(false);

                setUpdatedStoriesWRTSelectAll(getUpadatedStoriesWRTSelectAll(!isAllSelected, [...body?.generate_images?.stories]));
              } else {
                regnerateStoryboard();
              }
            } else if (currentProcessStep === ENUM_PROCESS_SCREENS.GENERATED_IMAGES_SCREEN) {
              if (regenerateImageData?.id) {
                if (regenerateImageData?.file) {
                  replaceGeneratedImage(regenerateImageData?.id, regenerateImageData?.file);
                } else {
                  regenerateImage(regenerateImageData?.id);
                }
              } else if (regenerateImageData?.story_id && !regenerateImageData?.id && !regenerateImageData?.file) {
                dispatch(
                  setActionDrawerDataAction({
                    drawerOpen: true,
                    drawerWidth: "400px",
                    componentId: "AddLiveImagesOrVideos",
                    // drawerTitle: "Add Live Images/Videos",
                    // drawerTitle: "Add Live Clips",
                    drawerTitle: `${t(I18N_MODULE_KEYS["content.transcreation.process.generatedimage.drawer.title.addliveclips"])}`,
                    others: { story_id: regenerateImageData?.story_id, scene: regenerateImageData?.scene }
                  })
                );
              }
            } else if (currentProcessStep === ENUM_PROCESS_SCREENS.IMAGE_GENERATION_SOURCE_SELECTION_SCREEN) {
              dispatch(setResponseCtAction({ ...responseCT, imageSourceHasNext: true }));
            }
          }
          setRegenerateShowModal(false);
        }}
      />
    </>
  );
};
