import { Button, Flex, Image, Tooltip, Typography, UploadFile } from "antd";
import { FC, useTransition } from "react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { ProgressBar } from "./_ProgressBar";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { UploadFileCustom } from "../../Core/modals";

const { Text } = Typography;

type IProps = {
  name: string;
  size: number;
  isError: boolean;
  isUploaded: boolean;
  uploading: boolean;
  percent: number;
  handleFileManipulation: (isDelete: boolean) => void;
  isRemoveIcon?: boolean;
};

export const FileView: FC<IProps> = ({ name, size, isError, isUploaded, uploading, percent, handleFileManipulation, isRemoveIcon }) => {
  const { t } = useTranslation();

  const getFileSize = () => {
    if (!size) return;
    if (!size) return "0 KB";
    if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + " KB";
    } else if (size > 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + " MB";
    }
  };
  return (
    <Flex
      vertical
      gap="small"
      justify="center"
      style={{ border: "1px solid rgba(210, 211, 211, 1)", height: "100%", maxHeight: "75px", borderRadius: "5px", padding: "3px 9px" }}
    >
      <Flex gap="small" justify="space-between">
        <Flex gap="small" align="center">
          <Image preview={false} style={{ width: "28px" }} src={PROJECT_ICONS.C_AI_FILE_PLACEHOLDERE_ICON} />
          <Flex vertical>
            <Tooltip title={name || ""}>
              <Text style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {name?.length < 30 ? name : name?.slice(0, 30) + "..." || " "}
              </Text>
            </Tooltip>
            <Text>{getFileSize()}</Text>
          </Flex>
        </Flex>

        <Flex justify="flex-start" gap={2}>
          {!uploading && (
            <>
              {isError && (
                <Image
                  style={{ cursor: "pointer" }}
                  preview={false}
                  width="13px"
                  src={PROJECT_ICONS.CT_RESTART_ICON}
                  onClick={() => handleFileManipulation(false)}
                />
              )}
              {isUploaded && <Image preview={false} width="16px" src={PROJECT_ICONS.C_AI_CHECK_ICON} />}
              {isRemoveIcon ? (
                <Text style={{ textDecoration: "underline", cursor: "pointer", color: "#3f57ff" }} onClick={() => handleFileManipulation(true)}>
                  {t(I18N_MODULE_KEYS["content.text.Remove"])}
                </Text>
              ) : (
                <Image
                  style={{ cursor: "pointer" }}
                  preview={false}
                  width="16px"
                  src={PROJECT_ICONS.DELETE_ICON}
                  onClick={() => handleFileManipulation(true)}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
      <Flex gap="small" justify="center" align="center">
        <ProgressBar uploading={uploading} percent={percent} error={isError} isUploaded={isUploaded} />
      </Flex>
      <Flex>{isError && <div className="cu-error-message">{t(I18N_MODULE_KEYS["content.text.erroruploadfailed"])}</div>}</Flex>
    </Flex>
  );
};
