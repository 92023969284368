import { fabric } from "fabric";
import Lottie from "./Lottie";
import Frame from "./Frame";
import Avatar from "./Avatar";
import Video from "./Video";
//import Gif from './Gif'
import List from "./List";
import { backoffMs, isSignedUrl, promisify, sleep } from "../helpers";
import { getFontFamilyWithFallback } from "./fallbackFonts";
import { PROJECT_ICONS } from "../../../../../../Assets/Img/_DIcons";

//const rotateIcon = PROJECT_ICONS.CT_PLAY_ICON;

fabric.Lottie = Lottie;
fabric.Frame = Frame;
fabric.Avatar = Avatar;
fabric.Video = Video;
//fabric.Gif = Gif
fabric.List = List;

fabric.Object.NUM_FRACTION_DIGITS = 8;

const crossOriginTypes = ["video", "image"];

export const buildUrlWithCors = (src) => {
  if (isSignedUrl(src)) return src;

  const url = new URL(src);
  if (url.search) url.search += "&withcors";
  else url.search = "?withcors";
  return url.toString();
};

export const formatMediaSrc = (src) => {
  if (!src) return src;
  try {
    const url = new URL(src);
    url.search = "";
    const formattedSrc = url.toString();
    return isSignedUrl(src) ? formattedSrc.replace(/_.{6}\./, ".") : formattedSrc;
  } catch {
    return src;
  }
};

fabric.imageFromURL = async (url) => {
  return new Promise((resolve, reject) => {
    new fabric.Image.fromURL(buildUrlWithCors(url), (oImg, error) => (error ? reject(error) : resolve(oImg)), {
      crossOrigin: "anonymous"
    });
  });
};

fabric.svgFromURL = async (url) => {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(buildUrlWithCors(url), (objects) => {
      let oSvg = fabric.util.groupSVGElements(objects);
      objects ? resolve(oSvg) : reject();
    });
  });
};

fabric.Canvas.prototype.loadFromJSON = (function (originalFn) {
  const loadFromJSON = promisify(originalFn);
  return async function (json, callback) {
    let resolve;
    try {
      this.activeLoading = new Promise((_resolve) => (resolve = _resolve));

      const { objects } = json;
      if (objects) {
        for (const obj of objects) {
          // it's weird bug when crossOrigin is missing for some reason, so we set it manually
          if (crossOriginTypes.includes(obj.type) && !obj.crossOrigin) obj.crossOrigin = "anonymous";

          // set background unselectable here because backgound object doesn't have own class
          if (obj.bg) obj.selectable = false;

          if (obj.meta?.listeningAvatar)
            obj._controlsVisibility = {
              ml: false,
              mt: false,
              mr: false,
              mb: false
            };
        }
      }

      await loadFromJSON.call(this, json);

      const missingMedia = [];
      const canvasObjects = this.getObjects();
      if (!objects?.length || !canvasObjects?.length) return;
      for (const obj of objects) {
        const src = obj.src || obj.imgSrc;
        if (!src) continue;
        const exists = !!canvasObjects.find((o) => o.id === obj.id);
        if (!exists) missingMedia.push({ id: obj.id, src, type: obj.type });
        obj._exists = exists;
      }
      callback?.(missingMedia);

      resolve();
      delete this.activeLoading;
    } catch (err) {
      resolve();

      callback?.([]);

      delete this.activeLoading;

      console.log("Fabric.js loadfromcanvas", err);
    }
  };
})(fabric.Canvas.prototype.loadFromJSON);

// fabric.Canvas.prototype.loadFromJSON = (function (originalFn) {
//   const loadFromJSON = promisify(originalFn);
//   return async function (json, callback) {
//     let resolve;

//     this.activeLoading = new Promise((_resolve) => (resolve = _resolve));

//     const { objects } = json;
//     if (objects) {
//       for (const obj of objects) {
//         // it's weird bug when crossOrigin is missing for some reason, so we set it manually
//         if (crossOriginTypes.includes(obj.type) && !obj.crossOrigin) obj.crossOrigin = "anonymous";

//         // set background unselectable here because backgound object doesn't have own class
//         if (obj.bg) obj.selectable = false;

//         if (obj.meta?.listeningAvatar)
//           obj._controlsVisibility = {
//             ml: false,
//             mt: false,
//             mr: false,
//             mb: false
//           };
//       }
//     }

//     await loadFromJSON.call(this, json);

//     const missingMedia = [];
//     const canvasObjects = this.getObjects();
//     if (!objects?.length || !canvasObjects?.length) return;
//     for (const obj of objects) {
//       const src = obj.src || obj.imgSrc;
//       if (!src) continue;
//       const exists = !!canvasObjects.find((o) => o.id === obj.id);
//       if (!exists) missingMedia.push({ id: obj.id, src, type: obj.type });
//       obj._exists = exists;
//     }
//     callback?.(missingMedia);

//     resolve();
//     delete this.activeLoading;
//   };
// })(fabric.Canvas.prototype.loadFromJSON);

fabric.StaticCanvas.prototype.loadFromJSON = (function (originalFn) {
  return promisify(originalFn);
})(fabric.StaticCanvas.prototype.loadFromJSON);

function isDisposed() {
  return !this.lowerCanvasEl && !this._objects?.length;
}
function renderAllSafe() {
  if (this.isDisposed()) return;

  try {
    this.renderAll();
  } catch {
    // ignore, looks like canvas was already destroyed
  }
}

fabric.Canvas.prototype.isDisposed = isDisposed;
fabric.StaticCanvas.prototype.isDisposed = isDisposed;
fabric.Canvas.prototype.renderAllSafe = renderAllSafe;
fabric.StaticCanvas.prototype.renderAllSafe = renderAllSafe;

fabric.Canvas.prototype.startAnimations = function () {
  this.getObjects().forEach((obj) => obj.play?.());
};

fabric.Canvas.prototype.stopAnimations = function () {
  this.getObjects().forEach((obj) => obj.stop?.());
};

/**
 * Exclude pattern canvas data from JSON. Needed for Frame object
 */
fabric.Pattern.prototype.toObject = function (propertiesToInclude) {
  var NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS,
    object;

  object = {
    type: "pattern",
    repeat: this.repeat,
    crossOrigin: this.crossOrigin,
    offsetX: fabric.util.toFixed(this.offsetX, NUM_FRACTION_DIGITS),
    offsetY: fabric.util.toFixed(this.offsetY, NUM_FRACTION_DIGITS),
    patternTransform: this.patternTransform ? this.patternTransform.concat() : null
  };
  fabric.util.populateWithProperties(this, object, propertiesToInclude);
  return object;
};

const imageToObjectOriginalFn = fabric.Image.prototype.toObject;
fabric.Image.prototype.toObject = function (...args) {
  const object = imageToObjectOriginalFn.call(this, ...args);
  Object.assign(object, {
    bg: this.bg
  });
  return object;
};

const textboxToObjectOriginalFn = fabric.Textbox.prototype.toObject;
fabric.Textbox.prototype.toObject = function (...args) {
  const object = textboxToObjectOriginalFn.call(this, ...args);
  return fabric.util.object.extend(object, {
    meta: {
      splittedText: this._splitTextIntoLines(this.text).lines.join("\n")
    },
    fontFamily: object.fontFamily.split(",")[0]
  });
};

const textboxFromObjectOriginalFn = fabric.Textbox.fromObject;
fabric.Textbox.fromObject = function (object, callback) {
  if (!object.fontFamily.includes(",")) object.fontFamily = getFontFamilyWithFallback(object.fontFamily);
  textboxFromObjectOriginalFn.call(object, object, callback);
};

fabric.Canvas.prototype.toObject = (function () {
  return function (propertiesToInclude) {
    /**
     * Ignore broken objects that won't be possible to render in video
     */
    this._objects = this._objects.filter((o) => o && o.width && o.height);
    return this._toObjectMethod("toObject", propertiesToInclude);
  };
})(fabric.Canvas.prototype.toObject);

fabric.Canvas.prototype.switchToBackgroundMode = function () {
  this.bgMode = true;
  //this.discardActiveObject();
  this.forEachObject((obj) => {
    if (!obj.bg) obj.set({ visible: false, selectable: false, hasControls: false, hasBorders: false });
    else {
      obj.set({ selectable: true, hoverCursor: "move" });
      this.setActiveObject(obj);
    }
  });
  this.renderAll();
};

fabric.Canvas.prototype.switchToElementsMode = function (saveActiveObject) {
  this.bgMode = false;
  if (!saveActiveObject) this.discardActiveObject();
  this.forEachObject((obj) => {
    if (!obj.bg) {
      if (obj.avatarType !== "voiceover") obj.set({ visible: true, selectable: true, hasControls: true, hasBorders: true });
    } else obj.set({ selectable: false, hoverCursor: "default" });
  });
  this.renderAll();
};

fabric.Object.prototype.applyAsBackground = function () {
  const objects = this.canvas.getObjects();
  objects.forEach((obj) => {
    if (["image", "video"].includes(obj.type) && obj.bg && obj.id !== this.id) this.canvas.remove(obj);
  });
  const isVideo = this.type === "video";
  const { width, height } = this;
  let scale;
  if (width >= height) {
    scale = isVideo
      ? (Math.ceil((640 / width) * 100) / 100) * Math.max(1, 640 / 360 / (width / height))
      : (Math.ceil((360 / height) * 100) / 100) * Math.max(1, 640 / 360 / (width / height));
  } else {
    scale = isVideo ? Math.ceil((360 / height) * 100) / 100 : Math.ceil((640 / width) * 100) / 100;
  }
  this.set({
    top: (360 - height * scale) / 2,
    left: (640 - width * scale) / 2,
    scaleX: scale,
    scaleY: scale,
    animation: { type: null, startTime: 0, exitType: null },
    bg: true
  });
  this.canvas.switchToBackgroundMode();
  this.canvas.sendToBack(this);
  this.canvas.renderAll();
};

fabric.Object.prototype.detachFromBackground = async function () {
  this.set({
    top: 30,
    left: 30,
    bg: false
  });
  this.scale(Math.min(600 / this.width, 300 / this.height));
  this.canvas.switchToElementsMode(true);
  this.canvas.bringForward(this);
  this.canvas.renderAll();
};

fabric.util.loadImage = (function (originalFn) {
  return function loadImage(url, callback, context, crossOrigin, attempts = 0) {
    originalFn(
      url,
      async function (img, isError) {
        try {
          if (isError) {
            if (attempts < 3) {
              await sleep(backoffMs(attempts));
              return loadImage(url, callback, context, crossOrigin, attempts + 1);
            }
            return callback && callback.call(context, null, true);
          }
          callback && callback.call(context, img, isError);
        } catch {
          // ignore, maybe this video was already closed when callback was fired etc.
        }
      },
      context,
      crossOrigin
    );
  };
})(fabric.util.loadImage);

fabric.dropCache = () =>
  Object.values(fabric)
    .filter((el) => typeof el === "function" && typeof el.dropCache === "function")
    .forEach((shapeClass) => shapeClass.dropCache());

/**
 * Set styles of object controls
 */
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = "#ffffff";
fabric.Object.prototype.cornerStrokeColor = "#4868FF";
fabric.Object.prototype.borderColor = "#4868FF";
fabric.Object.prototype.cornerColorOnModify = "red";
fabric.Object.prototype.borderScaleFactor = 1.5;
fabric.Object.prototype.borderOpacityWhenMoving = 1;

fabric.IText.prototype.editingBorderColor = "#4868FF";
fabric.IText.prototype.setControlsVisibility({
  ml: false,
  mt: false,
  mr: false,
  mb: false
});
fabric.Textbox.prototype.setControlsVisibility({
  ml: true,
  mr: true
});

const img = document.createElement("img");
img.src = PROJECT_ICONS.CT_PLAY_ICON; /* rotateIcon */

fabric.Object.prototype.controls.mtr = new fabric.Control({
  x: 0,
  y: -0.5,
  offsetY: -23,
  cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
  actionHandler: fabric.controlsUtils.rotationWithSnapping,
  actionName: "rotate",
  render: renderIcon,
  cornerSize: 24,
  withConnection: false
});

fabric.Object.prototype.controls.mb = new fabric.Control({
  x: 0,
  y: 0.5,
  cursorStyle: "scale",
  actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: (ctx, left, top, styleOverride, fabricObject) =>
    renderRect(ctx, left, top, styleOverride, fabricObject, { width: 20, height: 4, top: -1, left: -10 }),
  cornerSize: 20
});

fabric.Object.prototype.controls.mt = new fabric.Control({
  x: 0,
  y: -0.5,
  cursorStyle: "scale",
  actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: (ctx, left, top, styleOverride, fabricObject) =>
    renderRect(ctx, left, top, styleOverride, fabricObject, { width: 20, height: 4, top: -3, left: -10 }),
  cornerSize: 20
});

fabric.Object.prototype.controls.mr = new fabric.Control({
  x: 0.5,
  y: 0,
  offsetY: 0,
  cursorStyle: "scale",
  actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: (ctx, left, top, styleOverride, fabricObject) =>
    renderRect(ctx, left, top, styleOverride, fabricObject, { width: 4, height: 20, top: -10, left: -1 }),
  cornerSize: 20
});

fabric.Object.prototype.controls.ml = new fabric.Control({
  x: -0.5,
  y: 0,
  offsetY: 0,
  cursorStyle: "scale",
  actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: (ctx, left, top, styleOverride, fabricObject) =>
    renderRect(ctx, left, top, styleOverride, fabricObject, { width: 4, height: 20, top: -10, left: -3 }),
  cornerSize: 20
});

function renderRect(ctx, left, top, styleOverride, fabricObject, rectStyles) {
  ctx.fillStyle = "#ffffff";
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.strokeRect(rectStyles.left, rectStyles.top, rectStyles.width, rectStyles.height);
  ctx.fillRect(rectStyles.left, rectStyles.top, rectStyles.width, rectStyles.height);
  ctx.restore();
}

function renderIcon(ctx, left, top, styleOverride, fabricObject) {
  var size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}

export default fabric;
