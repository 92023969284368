import { useCallback } from "react";
import { setActiveObjectModifier } from "../../../../../../../Store/actions/_contentTranscreationActions";
import { useDispatch } from "react-redux";

export const formatSeconds = (value: any) => `${value}s`;
export const formatVolume = (value: any) => `${Math.round(value * 100)}%`;

export const useVideoControls = (props: { [x: string]: any }) => {
  const dispatch = useDispatch();
  const { canvasActiveObject, syncVideoState, duration, volume } = props;

  const handleFitAudio = (durationFitAudio: any) => {
    dispatch(
      setActiveObjectModifier(
        durationFitAudio
          ? {
              change: {
                durationFitAudio,
                loop: false
              }
            }
          : {
              change: {
                durationFitAudio,
                speedRate: 1
              }
            }
      )
    );
  };

  const handleLoop = (loop: any) => {
    const trimEnd = canvasActiveObject.getElement().duration;
    const result = loop
      ? {
          change: {
            loop,
            durationFitAudio: false,
            trimStart: 0,
            trimEnd
          }
        }
      : {
          change: {
            loop
          }
        };
    dispatch(setActiveObjectModifier(result));
  };

  const onStep = useCallback(
    ([trimStart, trimEnd]: any[]) => {
      const changedFields: any = {};
      if (trimStart !== canvasActiveObject.trimStart) {
        changedFields.trimStart = true;
        canvasActiveObject.trimStart = trimStart;
      } else if (trimEnd !== canvasActiveObject.trimEnd) {
        changedFields.trimEnd = true;
        canvasActiveObject.trimEnd = trimEnd;
      }

      syncVideoState(canvasActiveObject.canvas, canvasActiveObject, changedFields, duration);
    },
    [duration, canvasActiveObject]
  );

  const onTrimStartChange = (value: any) => {
    if (value >= canvasActiveObject.trimEnd) return;
    dispatch(
      setActiveObjectModifier({
        change: {
          trimStart: value,
          loop: false
        },
        changedFields: {
          trimStart: value !== canvasActiveObject.trimStart,
          trimEnd: false
        }
      })
    );
  };

  const onTrimEndChange = (value: any) => {
    if (value <= canvasActiveObject.trimStart) return;
    dispatch(
      setActiveObjectModifier({
        change: {
          trimEnd: value,
          loop: false
        },
        changedFields: {
          trimEnd: value !== canvasActiveObject.trimEnd,
          trimStart: false
        }
      })
    );
  };

  const onTrimChange = useCallback(
    ([trimStart, trimEnd]: any[]) => {
      const elementDuration = canvasActiveObject.getElement().duration;
      if (elementDuration < 1) return;
      if (trimStart !== canvasActiveObject.trimStart && trimStart >= trimEnd - 1) trimStart = trimEnd - 1;
      else if (trimEnd !== canvasActiveObject.trimEnd && trimEnd <= trimStart + 1) trimEnd = trimStart + 1;
      dispatch(
        setActiveObjectModifier({
          change: {
            trimStart,
            trimEnd,
            loop: false
          },
          changedFields: {
            trimStart: trimStart !== canvasActiveObject.trimStart,
            trimEnd: trimEnd !== (canvasActiveObject.trimEnd || canvasActiveObject.getElement().duration)
          }
        })
      );
    },
    [canvasActiveObject]
  );

  // TODO: activeObjectProps
  const onSpeedRateChange = (speedRate: any) => {
    const modifier = {
      change: {
        speedRate
      }
    };
    if (speedRate !== 1 && volume) {
      //@ts-ignore
      modifier.change.volume = 0;
    }
    dispatch(setActiveObjectModifier(modifier));
  };

  const onVolumeChange = useCallback(
    (volume: any) =>
      dispatch(
        setActiveObjectModifier({
          change: "volume",
          value: volume
        })
      ),
    []
  );

  return {
    onStep,
    handleLoop,
    onTrimChange,
    onVolumeChange,
    handleFitAudio,
    onSpeedRateChange,
    onTrimStartChange,
    onTrimEndChange
  };
};
