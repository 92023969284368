import { Col, Row } from "antd";
import { FC, useState } from "react";
import { Header } from "./Header";
import useCaseData from "./Core/useCaseData";
import { BusinessValuePropositionTab, DescriptionTab, FuturePotentialTab, ReturnOnInvestmentTab, SuccessMetricsTab } from "./Tabs";
import { UC_TAB_IDS } from "./Core/constants";
import { ComingSoon } from "./ComingSoon";
import { useLocation } from "react-router-dom";
import "./Core/usecase-library.css";

const TabComponents = {
  [UC_TAB_IDS.DESCRIPTION]: DescriptionTab,
  [UC_TAB_IDS.BUSINESS_VALUE_PROPOSITION]: BusinessValuePropositionTab,
  [UC_TAB_IDS.RETURN_ON_INVESTMENT]: ReturnOnInvestmentTab,
  [UC_TAB_IDS.SUCCESS_METRICS]: SuccessMetricsTab,
  [UC_TAB_IDS.FUTURE_POTENTIAL]: FuturePotentialTab,
  [UC_TAB_IDS.CUSTOMISATION]: ComingSoon,
  [UC_TAB_IDS.TECHNOLOGY_USED]: ComingSoon
};

const UseCasesLibraryWrapper: FC = () => {
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(UC_TAB_IDS.DESCRIPTION);

  const module = location.pathname.split("/").pop();

  const moduleUseCaseData = useCaseData[module as string];

  const getTabContent = () => {
    return moduleUseCaseData.tabContents[selectedTab].content.length;
  };

  const SelectedTabComponent = TabComponents[selectedTab];

  return (
    <Row gutter={[16, 8]} style={{ margin: "0px 10px" }} className="usecase-library">
      <Col xs={{ span: 24 }}>
        <Header
          moduleId={moduleUseCaseData.module.id}
          module={moduleUseCaseData.module.name}
          tabs={Object.entries(moduleUseCaseData.tabContents).map(([id, { tabName }]) => ({ id, tabName }))}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Col>
      <Col xs={{ span: 24 }} className="tab-content">
        {getTabContent() ? (
          <SelectedTabComponent data={moduleUseCaseData.tabContents[selectedTab]} module={moduleUseCaseData.module} />
        ) : (
          <ComingSoon />
        )}
      </Col>
    </Row>
  );
};

export { UseCasesLibraryWrapper };
