import { Flex, Typography } from "antd";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setActiveScreenTMAction, setMainTabsTMAction } from "../../../Store/actions/_translationModuleActions";
import { useSelector } from "react-redux";
import { enumTMActiveScreen, enumTMMainTabs } from "../Core/constant";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
export const MainTabs: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { mainTabs } = useSelector((state: any) => state?.translationModuleReducer);

  const handleTabsClick = (screen: number) => {
    dispatch(setActiveScreenTMAction(enumTMActiveScreen.NOTHING));
    dispatch(setMainTabsTMAction(screen));
  };

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module._MainTabs.${value as string}`])}`;
  };

  return (
    <Flex gap={45} style={{ marginLeft: "20px" }}>
      <Text
        style={{
          fontSize: "14px",
          fontWeight: mainTabs === enumTMMainTabs.TEXT_TRANSLATION ? "700" : "600",
          color: "rgba(3, 78, 162, 1)",
          cursor: "pointer"
        }}
        onClick={() => handleTabsClick(enumTMMainTabs.TEXT_TRANSLATION)}
      >
        {getTranslatedText("TEXT TRANSLATION")}
      </Text>
      <Text
        style={{
          fontSize: "14px",
          fontWeight: mainTabs === enumTMMainTabs.UPLOAD_DOCUMENTS ? "700" : "600",
          color: "rgba(3, 78, 162, 1)",
          cursor: "pointer"
        }}
        onClick={() => handleTabsClick(enumTMMainTabs.UPLOAD_DOCUMENTS)}
      >
        {getTranslatedText("UPLOAD DOCUMENT(S)")}
      </Text>
    </Flex>
  );
};
