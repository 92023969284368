import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import Dragger from "antd/es/upload/Dragger";
import { SelectComp } from "./Components";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { setNotificationMsgAction } from "../../Store/actions/_commonActions";
import _iDetailExtractRequests from "../../Store/requests/_iDetailExtractRequests";
import { setUploadedPdfDataAction } from "../../Store/actions/_iDetailExtractActions";
import { Button, Card, Col, Flex, Image, Progress, Row, Typography, UploadFile } from "antd";
import { useSelector } from "react-redux";

const { Text } = Typography;

type IProps = {
  /**
   *
   * @param status contains boolean value, TRUE/FALSE, TRUE means file uploaded succesfuly and FALSE means file has some errors while uploading.
   * @desc With the help of this callback function, we can able to move to categories screen or not
   * @returns
   */
  afterUploading: (status: boolean, fileId: number) => void; //
  cancelPDFUpload: () => void;
};

export const UploadFileCard: FC<IProps> = ({ afterUploading, cancelPDFUpload }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { uploadedPDFData } = useSelector((state: any) => state?.iDetailExtractReducer);

  const [percent, setPercent] = useState<number>(0);
  // const [uploadedFile, setUploadedFile] = useState<UploadFile | null>(null);

  const props = {
    name: "file",
    showUploadList: false,
    accept: "application/pdf",
    customRequest: () => {}, //To prevent the POST request error
    onChange(info: any) {
      const { file } = info;
      const isPDF = file.type === "application/pdf";
      const isLt50MB = (file.size as number) / 1024 / 1024 <= 50; //File size should be <= 50MB

      if (!isLt50MB) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
        return;
      }

      if (!isPDF) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
        return;
      }

      handleUploadFile(file);
    }
  };

  const handleUploadFile = async (file: any) => {
    const config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        console.log(percentCompleted);

        if (percentCompleted === 100) {
          return;
        }

        setPercent(percentCompleted);
      },

      onDownloadProgress: function () {
        setPercent(100);
      }
    };

    const instFormData: FormData = new FormData();

    instFormData.append("file", file?.originFileObj);

    const res = await _iDetailExtractRequests.uploadFile(instFormData, undefined, config);

    dispatch(setUploadedPdfDataAction({ ...file, file_id: res?.data?.file_id }));

    if (res?.data?.file_id) {
      afterUploading(true, res?.data?.file_id);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }}>
        <Text style={{ fontSize: "24px", fontWeight: "700" }}>{t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Upload Document"])}</Text>
      </Col>
      <Col xs={{ span: 24 }}>
        <Card>
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }}>
              <Flex gap="middle">
                <SelectComp
                  label={`${t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Country"])}`}
                  placeholder={`${t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Country"])}`}
                  value="USA"
                  options={[{ value: "USA" }]}
                  defaultValue="USA"
                />
                <SelectComp
                  label={`${t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Brand"])}`}
                  placeholder={`${t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Brand"])}`}
                  value="Lynparza"
                  options={[{ value: "Lynparza" }]}
                  defaultValue="Lynparza"
                />
              </Flex>
            </Col>
            <Col xs={{ span: 24 }}>
              <Dragger className="ct-upload-area" style={{ background: "aliceblue" }} {...props} disabled={uploadedPDFData !== null ? true : false}>
                <div style={{ background: "#F4F6F9", borderRadius: "8px" }}>
                  <Flex gap="middle" justify="center" vertical style={{ paddingTop: "8px" }}>
                    {(percent as number) > 0 && (percent as number) < 100 && (
                      <>
                        <Progress type="circle" percent={percent} />
                        <Text style={{ fontSize: "14px", fontWeight: "700", color: "rgba(3, 78, 162, 1)" }}>
                          {t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Uploading"])}...
                        </Text>
                      </>
                    )}
                    {uploadedPDFData && uploadedPDFData?.name ? (
                      <>
                        <Flex gap="middle" justify="center">
                          <Image src={PROJECT_ICONS.IDX_FILE_ICON} preview={false} style={{ height: "17px" }} />
                          <Text style={{ fontSize: "16px", fontWeight: "400" }}>{uploadedPDFData?.name}</Text>
                        </Flex>
                        <div>
                          <Button
                            type="link"
                            onClick={() => {
                              cancelPDFUpload();
                              setPercent(0);
                            }}
                          >
                            {t(I18N_MODULE_KEYS["content.button.cancel"])}
                          </Button>
                          <Button
                            type="primary"
                            style={{ background: "rgba(3, 78, 162, 1)" }}
                            onClick={() => {
                              afterUploading(true, uploadedPDFData?.file_id);
                            }}
                          >
                            {t(I18N_MODULE_KEYS["content.button.proceed"])}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {percent === 0 && (
                          <>
                            <Image preview={false} src={PROJECT_ICONS.IDX_UPLOAD_LOGO} style={{ width: "unset" }} />
                            <Text style={{ fontSize: "16px", fontWeight: "400" }}>
                              {t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Click to Upload or Drop PDF here "])}{" "}
                            </Text>
                            <div>
                              <Button icon={<UploadOutlined />} type="primary" style={{ background: "#0546A5" }}>
                                {t(I18N_MODULE_KEYS["content.transcreation.source.card.upload"])}
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Flex>

                  <Flex justify="end" align="center" style={{ marginRight: "10px" }}>
                    <Button disabled type="link">
                      {/* <Image src={PROJECT_ICONS.IDX_LINK_ICON} preview={false} style={{ color: "rgba(102, 103, 107, 1)" }} /> */}
                      <Text style={{ color: "rgba(102, 103, 107, 1)", fontSize: "12px", fontWeight: "400" }}>
                        {t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.from url"])}
                      </Text>
                    </Button>
                  </Flex>
                </div>
              </Dragger>
            </Col>

            <Col xs={{ span: 24 }}>
              <Flex justify="center" align="center" gap="small">
                <Text style={{ color: "#7C7C7C", fontSize: "16px", fontWeight: "400" }}>
                  {t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Set Language for Scanned files"])}
                </Text>
                <Image preview={false} src={PROJECT_ICONS.IDX_INFORMATION_ICON} />
                <SelectComp
                  label={`${t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Language"])}`}
                  placeholder={`${t(I18N_MODULE_KEYS["idetailextract._UploadFileCard.Language"])}`}
                  value="English"
                  options={[{ value: "English" }]}
                  defaultValue="English"
                />
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
