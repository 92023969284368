import { Col, Divider, Row } from "antd";
import Title from "antd/es/typography/Title";
import { TopAlignedAvatarTile } from "../Components";
import { ModuleDataType, TabData } from "../Core/types";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";

const ReturnOnInvestmentTab: React.FC<{ data: TabData; module: ModuleDataType }> = ({ data, module }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background: "white",
        border: "1px solid #f0f0f0",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 6px 18px",
        borderRadius: "12px",
        height: "100%"
      }}
    >
      <div style={{ padding: "24px", paddingBottom: 0 }}>
        <Title level={4} style={{ fontWeight: 700 }}>
          {`${t(I18N_MODULE_KEYS["content.text.Strategic ROI of"])}`}  {t(module.name)}
        </Title>
      </div>
      <Divider style={{ borderWidth: "4px", margin: 0 }} />
      <Row gutter={[16, 8]} style={{ padding: "24px" }}>
        {data?.content?.map((tile, key) => (
          <Col key={key} xs={{ span: 24 }} md={{ span: 8 }}>
            <TopAlignedAvatarTile image={tile.imgSrc} heading={tile.point} description={tile.description as string} tileColor={tile.tileColor} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export { ReturnOnInvestmentTab };
