import { Row } from "antd";
import { SourceCard } from "../../Components/SourceCard";
import { useState } from "react";
import { CT_FINAL_PRODUCTS, CT_FINAL_PRODUCTS_LIST } from "../../Core/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCTFinalProductAction } from "../../../../Store/actions/_contentTranscreationActions";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

/* const finalProductsList = [
  { id: 1, name: "Video", selected: true },
  { id: 2, name: "Social Media Post" }
]; */

export const SelectFinalProduct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { finalProduct } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [productList, setProductList] = useState(
    CT_FINAL_PRODUCTS_LIST.map((item) => {
      item.selected = item.name === finalProduct ? true : false;
      return item;
    })
  );

  return (
    <div>
      <h2 style={{ marginBottom: "1rem" }}>{t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.title"])}</h2>
      <Row gutter={[16, 16]}>
        {productList.map((item, index) => (
          <SourceCard
            key={index}
            selected={item.selected}
            title = 
            {  item.name === CT_FINAL_PRODUCTS.VIDEO
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.video"])
              : item.name === CT_FINAL_PRODUCTS.SOCIAL_MEDIA_POST 
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.social"])
              : item.name === CT_FINAL_PRODUCTS.BANNER 
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.banner"])
              : item.name === CT_FINAL_PRODUCTS.JOURNAL_AD 
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.journalad"])
              : item.name === CT_FINAL_PRODUCTS.LITERATURE
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.literature"])
              : item.name === CT_FINAL_PRODUCTS.POSTER
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.poster"])
              : item.name === CT_FINAL_PRODUCTS.NEWSLETTER
              ? t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.newsletter"])
               : t(I18N_MODULE_KEYS["content.transcreation.selectsource.1.card.brochure"])
            }
            onClick={() => {
              //console.log({ index });
              if(item.name===CT_FINAL_PRODUCTS.VIDEO || item.name===CT_FINAL_PRODUCTS.SOCIAL_MEDIA_POST)
             { setProductList((list) => [
                ...list.map((item, idx) => {
                  item.selected = index === idx ? true : false;
                  return item;
                })
              ]);

              //console.log(productList[index].name)
              dispatch(setCTFinalProductAction(productList[index].name));
            }}}
          />
        ))}
        {/*   <SourceCard title="Video" onClick={() => {}} />
        <SourceCard title="Social Media Post" onClick={() => {}} /> */}
      </Row>
    </div>
  );
};
