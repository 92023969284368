import { Button, Card, Col, Row } from "antd";
import { AddLiveImagesOrVideos } from "./AddLiveImagesOrVideos";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store";
import { CSSProperties, useMemo } from "react";
import { focusIcon } from "../constants";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../../Store/actions/_contentTranscreationActions";
import { CommonControls } from "../../common/CommonControls";
import { VideoControls } from "./video/VideoControls";

const textTypes = ["i-text", "textbox", "list"];

const STYLE: { [key: string]: CSSProperties } = {
  width100: { width: "100%" },
  margin010100: { margin: "0 10px 10px 0" },
  colorButton: { position: "relative", marginTop: "15px" },
  button: { position: "relative", marginTop: "15px", textAlign: "center" },
  groupSelection: { display: "flex", flexWrap: "wrap", marginTop: "15px", position: "relative" }
};

export const ElementsTab = () => {
  const dispatch = useDispatch();

  const { canvasActiveObject, activeSlide, elaiResponse } = useSelector((state: RootState) => state?.contentTranscreationReducer);

  const activeObjectProps = canvasActiveObject?.type === "activeSelection" ? canvasActiveObject.getObjects()[0] : canvasActiveObject;

  const isActiveObjectText =
    canvasActiveObject?.type === "activeSelection"
      ? canvasActiveObject.getObjects().every((obj: any) => textTypes.includes(obj.type))
      : textTypes.includes(canvasActiveObject?.type);

  const activeObjectType = useMemo(
    () =>
      canvasActiveObject?.type === "activeSelection"
        ? canvasActiveObject.getObjects().every((obj: any) => obj.type === canvasActiveObject.getObjects()[0].type) ||
          canvasActiveObject.getObjects().every((obj: any) => textTypes.includes(obj.type))
          ? canvasActiveObject.getObjects()[0].type
          : null
        : canvasActiveObject?.type,
    [canvasActiveObject, canvasActiveObject?._objects]
  );

  const handleBackgroundApply = () => dispatch(setActiveObjectModifier({ background: "apply" }));

  return (
    <>
      {/* <div>
        <span>Images</span>
        <span>Videos</span>
      </div> */}
      {/* <div>
        <Search />
        <Row gutter={[8, 8]} style={{ overflowY: "scroll" }}>
          {pexelImages.photos.map((photo) => (
            <Col xs={{ span: 8 }}>
              <img src={photo.src.original} height={97} width={97} />
            </Col>
          ))}
        </Row>
      </div> */}
      <AddLiveImagesOrVideos visible={!canvasActiveObject || isActiveObjectText} />

      {canvasActiveObject && canvasActiveObject.type !== "activeSelection" && (activeObjectType === "video" || activeObjectType === "image") && (
        <div style={STYLE.button}>
          <Button type="dashed" icon={focusIcon} style={STYLE.width100} onClick={handleBackgroundApply}>
            Apply as background
          </Button>
        </div>
      )}

      {canvasActiveObject && !isActiveObjectText && (
        <div className="common-options">
          {activeObjectType === "video" && (
            <VideoControls
              duration={elaiResponse?.slides[activeSlide]?.duration}
              canvasActiveObject={canvasActiveObject}
              activeObjectProps={activeObjectProps}
            />
          )}
          <CommonControls activeObjectProps={activeObjectProps} />
        </div>
      )}
    </>
  );
};
