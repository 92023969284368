import { FC, useEffect, useState } from "react";
import { Card, Col, Row, Typography, Tooltip, Tag, Image, Button } from "antd";
import { ContainerOutlined, DownOutlined, EditOutlined, DownloadOutlined, ExpandAltOutlined, TableOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import Markdown from "markdown-to-jsx";
import { ModalForEditingArtifactContent } from "./_ModalForEditingArtifactContent";

interface GeneralArtifactsProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleAdvancedSearchClose: () => void;
  artifacts: any;
  setIsArtifacts: (value: any) => void;
  fileSpecificArtefact: any[];
  setFileSpecificArtefact: (value: any[]) => void;
  commonArtefacts: any[];
  setCommonArtefacts: (value: any[]) => void;
  switchForArtefact: number;
  setSwitchForArtefacts: (value: number) => void;
  setLoadData?: React.Dispatch<React.SetStateAction<LoadDataType>>;
}
interface LoadDataType {
  isLoading: boolean;
  message: string;
}

export const GeneralArtifacts: React.FC<GeneralArtifactsProps> = ({
  setIsModalVisible,
  handleAdvancedSearchClose,
  artifacts,
  setIsArtifacts,
  fileSpecificArtefact,
  setFileSpecificArtefact,
  commonArtefacts,
  setCommonArtefacts,
  switchForArtefact,
  setSwitchForArtefacts,
  setLoadData,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedImageData, setSelectedImageData] = useState<any>([]);
  const [uniquelySelectedImageData, setUniquelySelectedImageData] = useState<any>({});
  const [selectedSummaryData, setSelectedSummaryData] = useState<any>([]);
  const [selectedKeywordsData, setSelectedKeywordsData] = useState<any>([]);
  const [uniquelySelectedSummaryData, setUniquelySelectedSummaryData] = useState<any>({});
  const [selectedSummaryComponent, setSelectedSummaryComponent] = useState("Summary");
  const [selectedDataTableData, setSelectedDataTableData] = useState<any>([]);
  const [uniquelySelectedTableData, setUniquelySelectedTableData] = useState<any>({});
  const [selectedCommonAnswerData, setSelectedCommonAnswerData] = useState<any>([]);
  const [uniquelySelectedCommonAnswerData, setUniquelySelectedCommonAnswerData] = useState<any>({});
  const [selectedType, setSelectedType] = useState<string>("Images");
  const [selectedCommonType, setSelectedCommonType] = useState<string>("Answers");
  const [isModalOpenForEditing, setIsModalOpenForEditing] = useState<boolean>(false);
  const [uniquelySelectedImageDataText, setUniquelySelectedImageDataText] = useState<any>("");
  const [uniquelySelectedImageDataDescription, setUniquelySelectedImageDataDescription] = useState<any>("");
  const [uniquelySelectedTableDataDescription, setUniquelySelectedTableDataDescription] = useState<any>("");

  const [selectedImageComponent, setSelectedImageComponent] = useState("Extracted Text");

  console.log(selectedSummaryData);

  useEffect(() => {
    if (switchForArtefact === 2) {
      console.log("yes i get called ");
      fileSpecificArtefact.forEach((entry: any) => {
        switch (entry.key) {
          case "Data Table":
            setSelectedDataTableData(entry.item);
            setUniquelySelectedTableData(entry.item[0]);
            break;
          case "Image":
            setUniquelySelectedImageData(entry.item[0]);
            setSelectedImageData(entry.item);
            break;
          case "Summary":
            setSelectedSummaryData(entry.item);
            setUniquelySelectedSummaryData(entry.item[0]);
            break;
          default:
            break;
        }
      });
      commonArtefacts.forEach((entry: any) => {
        switch (entry.key) {
          case "Answer":
            setSelectedCommonAnswerData(entry.item);
            setUniquelySelectedCommonAnswerData(entry.item[0]);
            break;
          default:
            break;
        }
      });
    }
  }, [switchForArtefact]);

  useEffect(() => {
    const data = uniquelySelectedImageData?.data || "";

    // Regular expression to match 'extracted_text' and 'description' keys
    const extractedTextRegex = /"extracted_text":\s*"([^"]+)"/;
    const descriptionRegex = /"description":\s*"([^"]+)"/;

    // Extract 'extracted_text' and 'description' from the data
    const extractedTextMatch = data.match(extractedTextRegex);
    const descriptionMatch = data.match(descriptionRegex);

    // Destructure matched data and replace escaped newlines and other characters
    const extractedText = extractedTextMatch ? extractedTextMatch[1].replace(/\\n/g, "\n").trim() : "No Extracted Text Available";
    const descriptionText = descriptionMatch ? descriptionMatch[1].trim() : "No Description Available";

    setUniquelySelectedImageDataText(extractedText);
    setUniquelySelectedImageDataDescription(descriptionText);
    setSelectedImageComponent("Extracted Text");
  }, [uniquelySelectedImageData]);


  useEffect(() => {
    const data = uniquelySelectedTableData?.data || "";
  
    // Regular expression to match the 'description' key and its corresponding JSON object
    const descriptionRegex = /"description":\s*({[^}]*})/s;
  
    // Execute the regular expression to find the description
    const descriptionMatch = data.match(descriptionRegex);
  
    // If a match is found, extract the description part
    const descriptionText = descriptionMatch ? descriptionMatch[1].trim() : "No Description Available";
  
    // Set the description (you need to define 'setUniquelySelectedTableDataDescription' in your component)
    setUniquelySelectedTableDataDescription(descriptionText);
  }, [uniquelySelectedTableData]);
  
  

  useEffect(() => {
    if (selectedType === "Images" && selectedImageData.length > 0) {
      setUniquelySelectedImageData(selectedImageData[0]);
      if (selectedSummaryData.length > 0) {
        setSelectedSummaryComponent("Summary");
        setUniquelySelectedSummaryData(selectedSummaryData[0]);
      }
      setSelectedSummaryComponent("Summary");
    } else if (selectedType === "Summary" && selectedSummaryData.length > 0) {
      setUniquelySelectedSummaryData(selectedSummaryData[0]);
      if (selectedImageData.length > 0) {
        setUniquelySelectedImageData(selectedImageData[0]);
      }
    } else if (selectedType === "Table" && selectedDataTableData.length > 0) {
      setUniquelySelectedTableData(selectedDataTableData[0]);
      if (selectedImageData.length > 0) {
        setUniquelySelectedImageData(selectedImageData[0]);
      }
      if (selectedSummaryData?.length > 0) {
        setSelectedSummaryComponent("Summary");
        setUniquelySelectedSummaryData(selectedSummaryData[0]);
      }
    }
  }, [selectedType]);

  const handleTypeOfArtefact = (type: string) => {
    setSelectedType(type);
  };

  const handleEditingContentOfArtifact = () => {
    setIsModalOpenForEditing(true);
  };

  return (
    <>
      <Col span={5}>
        <Card
          style={{
            width: "100%",
            boxShadow: "0px 0px 10px #0000001F",
            opacity: "1",
            height: "530px",
            borderRadius: "10px",
            border: "1px solid #d9d9d9",
          }}
        >
          <div
            style={{
              border: "2px solid #EDEDED",
              padding: "5px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              if (setLoadData) {
                setLoadData((prevState) => ({
                  ...prevState,
                  message: "file",
                }));
              }

              setIsModalVisible(true);
            }}
          >
            <div style={{ cursor: "pointer", display: "flex", flexDirection: "column" }}>
              <Typography.Text style={{ color: "#848484", fontSize: "12px" }}>Document</Typography.Text>
              <Tooltip title={artifacts}>
                <Typography.Text strong>{artifacts.slice(0, 30)}</Typography.Text>
              </Tooltip>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <DownOutlined />
            </div>
          </div>
          <div style={{ overflowY: "scroll", maxHeight: "420px" }}>
            {switchForArtefact == 2 ? (
              <>
                <div
                  onClick={() => {
                    if (selectedImageData.length > 0) {
                      handleTypeOfArtefact("Images");
                    }
                  }}
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: selectedType === "Images" ? "#1E4DA1" : "#EDF4FF",
                    color: selectedType === "Images" ? "white" : "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: selectedImageData.length > 0 ? "pointer" : "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.IMAGES} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Images</p>
                </div>
                <div
                  onClick={() => {
                    if (selectedSummaryData.length > 0) {
                      handleTypeOfArtefact("Summary");
                    }
                  }}
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: selectedType === "Summary" ? "#1E4DA1" : "#EDF4FF",
                    color: selectedType === "Summary" ? "white" : "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: selectedSummaryData.length > 0 ? "pointer" : "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.SUMMARY} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Summary</p>
                </div>
                <div
                  onClick={() => {
                    if (selectedDataTableData.length > 0) {
                      handleTypeOfArtefact("Table");
                    }
                  }}
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: selectedType === "Table" ? "#1E4DA1" : "#EDF4FF",
                    color: selectedType === "Table" ? "white" : "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: selectedDataTableData.length > 0 ? "pointer" : "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.DATATABLE} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>DataTable</p>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: "#1E4DA1",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <img src={PROJECT_ICONS.ANSWERS} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Answers</p>
                </div>
                <div
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: "#EDF4FF",
                    color: "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.PERSONA} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Persona</p>
                </div>
                <div
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: "#EDF4FF",
                    color: "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.KNOWLEDGEGRAPH} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Knowledge Graph</p>
                </div>
              </>
            )}
          </div>
        </Card>
      </Col>

      <Col span={19}>
        <div
          style={{
            width: "100%",
            boxShadow: "0px 0px 10px #0000001F",
            height: "530px",
            opacity: "1",
            borderRadius: "10px",
            paddingTop: "0px",
            backgroundColor: "white",
            position: "relative", // Added this line to allow absolute positioning within this card
          }}
        >
          {/* Toggler placed here */}
          <div
            style={{
              display: "flex",
              borderRadius: "50px",
              overflow: "hidden",
              border: "2px solid #1e4da1",
              zIndex: "3",
              position: "absolute",
              top: "-10px", // Adjust to make it look centered over the top border
              left: "40%",
              transform: "translateX(-50%)", // Centers it horizontally
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "6px",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
                color: switchForArtefact == 2 ? "#ffffff" : "#1e4da1",
                backgroundColor: switchForArtefact == 2 ? "#1e4da1" : "#edf4ff",
              }}
              onClick={() => setSwitchForArtefacts(2)}
            >
              FILE ARTEFACT
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "6px",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
                color: switchForArtefact == 1 ? "#ffffff" : "#1e4da1",
                backgroundColor: switchForArtefact == 1 ? "#1e4da1" : "#edf4ff",
              }}
              onClick={() => setSwitchForArtefacts(1)}
            >
              COMMON ARTEFACT
            </div>
          </div>
          <Row>
            <Col span={18}>
              <div
                style={{
                  background: "#EDF4FF",
                  height: "530px",
                  marginLeft: "50px",
                  marginRight: "20px",
                  overflow: "auto",
                  paddingBottom: "10px",
                  marginBottom: "15px",
                  paddingTop: "20px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      background: "white",
                      display: "flex",
                      zIndex: "2",
                      position: "absolute",
                      marginRight: "0",
                      marginTop: "3px",
                      gap: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <div style={{ borderRight: "1px solid black", paddingRight: "5px" }}>
                      <EditOutlined
                        onClick={() => {
                          if (
                            !(
                              (selectedType === "Images" && selectedImageData.length === 0) ||
                              (selectedType === "Summary" && selectedSummaryData.length === 0) ||
                              (selectedType === "Table" && selectedDataTableData.length === 0)
                            )
                          ) {
                            handleEditingContentOfArtifact();
                          }
                        }}
                        style={{
                          height: "20px",
                          cursor:
                            (selectedType === "Images" && selectedImageData.length === 0) ||
                            (selectedType === "Summary" && selectedSummaryData.length === 0) ||
                            (selectedType === "Table" && selectedDataTableData.length === 0)
                              ? "not-allowed"
                              : "pointer",
                        }}
                      />
                    </div>
                    <div style={{ borderRight: "1px solid black", paddingRight: "5px", cursor: "not-allowed" }}>
                      <DownloadOutlined style={{ height: "20px" }} />
                    </div>
                    <div>
                      <ExpandAltOutlined style={{ height: "20px", paddingRight: "5px", cursor: "not-allowed" }} />
                    </div>
                  </div>
                </div>
              {switchForArtefact==2?
                (selectedType === "Images" ? (
                  selectedImageData.length > 0 ? (
                    <>
                      <div style={{ padding: "20px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <Image
                            preview={true}
                            src={uniquelySelectedImageData?.artifact_metadata?.s3_url}
                            style={{ height: "380px", width: "100%", borderRadius: "10px" }}
                            alt="Selected artifact"
                          />
                        </div>

                        <div
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            // Allows long words to be broken and wrap to the next line
                          }}
                        >
                          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                            <p
                              onClick={() => setSelectedImageComponent("Extracted Text")}
                              style={{
                                fontWeight: "bold",
                                color: selectedImageComponent === "Extracted Text" ? "rgb(30, 77, 161)" : "black",
                                borderBottom: selectedImageComponent === "Extracted Text" ? "2px solid rgb(30, 77, 161)" : "none",
                                cursor: "pointer",
                              }}
                            >
                              Extracted Text
                            </p>
                            <p
                              onClick={() => setSelectedImageComponent("Description")}
                              style={{
                                fontWeight: "bold",
                                color: selectedImageComponent === "Description" ? "rgb(30, 77, 161)" : "black",
                                borderBottom: selectedImageComponent === "Description" ? "2px solid rgb(30, 77, 161)" : "none",

                                cursor: "pointer",
                              }}
                            >
                              Description
                            </p>
                          </div>
                          {selectedImageComponent === "Extracted Text" && <Markdown>{uniquelySelectedImageDataText}</Markdown>}
                          {selectedImageComponent === "Description" && <Markdown>{uniquelySelectedImageDataDescription}</Markdown>}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No images found.</div>
                  )
                ) : selectedType === "Summary" ? (
                  selectedSummaryData.length > 0 ? (
                    <>
                      <div style={{ padding: "20px" }}>
                        <div
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                            // Allows long words to be broken and wrap to the next line
                            wordBreak: "break-all",
                          }}
                        >
                          <Markdown>{uniquelySelectedSummaryData?.data}</Markdown>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Summary found.</div>
                  )
                ) : (
                  <>
                    {selectedDataTableData.length > 0 ? (
                      <>
                        <div style={{ padding: "20px", width: "100%" }}>
                          <div style={{ fontWeight: "bold", fontSize: "25px", padding: "10px" }}>Data Table</div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Image
                              preview={true}
                              src={uniquelySelectedTableData?.artifact_metadata?.s3_url}
                              style={{ height: "380px", width: "100%", borderRadius: "10px" }}
                              alt="Selected artifact"
                            />
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              marginTop: "10px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word", // Allows long words to be broken and wrap to the next line
                              wordBreak: "break-all",
                            }}
                          >
                            <Markdown>{uniquelySelectedTableDataDescription}</Markdown>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Data Table found.</div>
                    )}
                  </>
                )):(<>
                {selectedCommonType==="Answers"?(<>
                  {selectedCommonAnswerData.length > 0 ? (
                    <>
                      <div style={{ padding: "20px" }}>
                        <div
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                            // Allows long words to be broken and wrap to the next line
                            wordBreak: "break-all",
                          }}
                        >
                          <Markdown>{uniquelySelectedCommonAnswerData?.data}</Markdown>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Answer Data found.</div>
                  )}
                </>):(<></>)}
                </>)}
              </div>
            </Col>
            <Col span={5} style={{ paddingLeft: "20px" }}>
              <div style={{ fontWeight: "bold", fontSize: "20px", marginTop: "18px" }}>
{switchForArtefact === 2 
  ? (selectedType === "Images" ? "Images" : selectedType === "Table" ? "Data Tables" : "") 
  : "Answers"}
              </div>

              <div
                style={{
                  marginTop: "18px",
                  width: "100%",
                  height: "400px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5px",
                }}
              >
              { switchForArtefact==2?(
                selectedType === "Images" ? (
                  <>
                    {selectedImageData.length > 0 ? (
                      selectedImageData.map((item: any, index: any) => (
                        <div
                          key={index}
                          onClick={() => setUniquelySelectedImageData(item)}
                          style={{
                            height: "120px",
                            marginTop: "25px",
                            borderRadius: "10px",
                            border: `4px solid ${uniquelySelectedImageData === item ? "#1E4DA1" : "#EDF4FF"}`,
                            width: "95%",
                            background: "#EDF4FF",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={item?.artifact_metadata?.s3_url}
                            alt={item?.artifact_metadata?.filename}
                            style={{
                              height: "100%",
                              width: "auto",
                              objectFit: "cover",
                              borderRadius: "9px",
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No images available.</div>
                    )}
                  </>
                ) : selectedType === "Summary" ? (
                  <>
                    <div style={{ display: "flex", gap: "10px", overflowX: "unset", width: "100%" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          cursor: uniquelySelectedSummaryData?.keywords?.length > 0 ? "pointer" : "not-allowed",
                          color: selectedSummaryComponent === "Summary" ? "#1E4DA1" : "#000",
                        }}
                        onClick={() => {
                          setSelectedSummaryComponent("Summary");
                        }}
                      >
                        Summary
                        <div
                          style={{
                            height: "3px",
                            backgroundColor: selectedSummaryComponent === "Summary" ? "#1E4DA1" : "transparent",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          cursor: uniquelySelectedSummaryData?.keywords?.length > 0 ? "pointer" : "not-allowed",
                          color: selectedSummaryComponent === "Keywords" ? "#1E4DA1" : "#000",
                        }}
                        onClick={() => {
                          if (uniquelySelectedSummaryData?.keywords?.length > 0) {
                            setSelectedSummaryComponent("Keywords");
                          }
                        }}
                      >
                        Keywords
                        <div
                          style={{
                            height: "3px",
                            backgroundColor: selectedSummaryComponent === "Keywords" ? "#1E4DA1" : "transparent",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                    </div>
                    {selectedSummaryComponent === "Summary" ? (
                      <div
                        style={{
                          height: "500px",
                          overflowY: "scroll",

                          marginLeft: "5px",
                        }}
                      >
                        {selectedSummaryData.length > 0 ? (
                          selectedSummaryData.map((item: any, index: number) => (
                            <div
                              key={index}
                              onClick={() => setUniquelySelectedSummaryData(item)}
                              style={{
                                height: "120px",
                                marginTop: "15px",
                                borderRadius: "10px",
                                border: `4px solid ${uniquelySelectedSummaryData === item ? "#1E4DA1" : "#EDF4FF"}`,
                                width: "95%",
                                background: "#EDF4FF",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <ContainerOutlined style={{ color: "#1E4DA1", fontSize: "50px", fontWeight: "bold" }} />
                            </div>
                          ))
                        ) : (
                          <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Summary available.</div>
                        )}
                      </div>
                    ) : uniquelySelectedSummaryData?.keywords?.length > 0 ? (
                      <div style={{ height: "500px", overflowY: "auto", display: "flex" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "15px" }}>
                          {uniquelySelectedSummaryData.keywords.map((keyword: any, index: number) => (
                            <Tag
                              key={index}
                              title={keyword}
                              style={{
                                backgroundColor: "#f0f0f0",
                                borderRadius: "20px",
                                padding: "10px 10px",
                                fontSize: "12px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {keyword}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Keywords available.</div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: "500px",
                        overflowY: "scroll",

                        marginLeft: "5px",
                      }}
                    >
                      {selectedDataTableData.length > 0 ? (
                        selectedDataTableData.map((item: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => setUniquelySelectedTableData(item)}
                            style={{
                              height: "120px",
                              marginTop: "25px",
                              borderRadius: "10px",
                              border: `4px solid ${uniquelySelectedTableData === item ? "#1E4DA1" : "#EDF4FF"}`,
                              width: "95%",
                              background: "#EDF4FF",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <TableOutlined style={{ color: "#1E4DA1", fontSize: "50px", fontWeight: "bold" }} />
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Data Tables available.</div>
                      )}
                    </div>
                  </>
                )):(<>
                <div
                      style={{
                        height: "500px",
                        overflowY: "scroll",

                        marginLeft: "5px",
                      }}
                    >
                      {selectedCommonAnswerData.length > 0 ? (
                        selectedCommonAnswerData.map((item: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => setUniquelySelectedCommonAnswerData(item)}
                            style={{
                              height: "120px",
                              marginTop: "25px",
                              borderRadius: "10px",
                              border: `4px solid ${uniquelySelectedCommonAnswerData === item ? "#1E4DA1" : "#EDF4FF"}`,
                              width: "95%",
                              background: "#EDF4FF",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <TableOutlined style={{ color: "#1E4DA1", fontSize: "50px", fontWeight: "bold" }} />
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Answers available.</div>
                      )}
                    </div>
                </>)}
              </div>
            </Col>
          </Row>
        </div>
      </Col>

      {isModalOpenForEditing && selectedType === "Images" ? (
        <ModalForEditingArtifactContent
          isModalOpenForEditing={isModalOpenForEditing}
          data={uniquelySelectedImageData}
          setData={setUniquelySelectedImageData}
          setIsModalOpenForEditing={setIsModalOpenForEditing}
          selectedTypeData={selectedImageData} // Add this line
          setSelectedData={setSelectedImageData} // Add this line
          setLoadData={setLoadData}
          switchForArtefact={switchForArtefact}
          setCommonArtefacts={setCommonArtefacts}
          commonArtefacts={commonArtefacts}
          setFileSpecificArtefact={setFileSpecificArtefact}
          fileSpecificArtefact={fileSpecificArtefact}
        />
      ) : (
        <></>
      )}
      {isModalOpenForEditing && selectedType === "Summary" ? (
        <ModalForEditingArtifactContent
          isModalOpenForEditing={isModalOpenForEditing}
          data={uniquelySelectedSummaryData}
          setData={setUniquelySelectedSummaryData}
          setIsModalOpenForEditing={setIsModalOpenForEditing}
          selectedTypeData={selectedSummaryData} // Add this line
          setSelectedData={setSelectedSummaryData} // Add this line
          setLoadData={setLoadData}
          switchForArtefact={switchForArtefact}
          setCommonArtefacts={setCommonArtefacts}
          commonArtefacts={commonArtefacts}
          setFileSpecificArtefact={setFileSpecificArtefact}
          fileSpecificArtefact={fileSpecificArtefact}
        />
      ) : (
        <></>
      )}
      {isModalOpenForEditing && selectedType === "Table" ? (
        <>
          <ModalForEditingArtifactContent
            isModalOpenForEditing={isModalOpenForEditing}
            data={uniquelySelectedTableData}
            setData={setUniquelySelectedTableData}
            setIsModalOpenForEditing={setIsModalOpenForEditing}
            selectedTypeData={selectedDataTableData} // Add this line
            setSelectedData={setSelectedDataTableData} // Add this line
            setLoadData={setLoadData}
            switchForArtefact={switchForArtefact}
            setCommonArtefacts={setCommonArtefacts}
            commonArtefacts={commonArtefacts}
            setFileSpecificArtefact={setFileSpecificArtefact}
            fileSpecificArtefact={fileSpecificArtefact}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
