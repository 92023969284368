import { useRef, useState } from "react";
import { Avatar, Menu } from "antd";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { getItem } from "../../Pages/Content Transcreation/Content History/core/constants";
import i18n from "./_i18n";
import { ENUM_ACCEPT_LANGUAGE, useOutsideAlerter } from "../../Utils";
import _localStorageService from "../../Services/_localStorageService";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { I18N_MODULE_KEYS } from "./core/constants";

// const items = [
//   getItem("English", ENUM_ACCEPT_LANGUAGE.ENGLISH),
//   getItem("Spanish", ENUM_ACCEPT_LANGUAGE.SPANISH),
//   getItem("Chinese", ENUM_ACCEPT_LANGUAGE.CHINESE),
//   getItem("Hindi", ENUM_ACCEPT_LANGUAGE.HINDI)
// ];
// const items = [getItem("English", "en"), getItem("Spanish", "es"), getItem("Chinese", "zh")];

export const MenuI18N = () => {
  const menuRef: any = useRef();

  useOutsideAlerter(menuRef, () => setShowMenu(false));

  const items = [
    getItem(`${t(I18N_MODULE_KEYS["header.menu.language.english"])}`, ENUM_ACCEPT_LANGUAGE.ENGLISH),
    getItem(`${t(I18N_MODULE_KEYS["header.menu.language.spanish"])}`, ENUM_ACCEPT_LANGUAGE.SPANISH),
    getItem(`${t(I18N_MODULE_KEYS["header.menu.language.chinese"])}`, ENUM_ACCEPT_LANGUAGE.CHINESE),
    getItem(`${t(I18N_MODULE_KEYS["header.menu.language.hindi"])}`, ENUM_ACCEPT_LANGUAGE.HINDI)
  ];

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  // const getLocale = (key: string) => {
  //   const locale: string | undefined = [ENUM_ACCEPT_LANGUAGE.ENGLISH, ENUM_ACCEPT_LANGUAGE.CHINESE, ENUM_ACCEPT_LANGUAGE.SPANISH]?.find(
  //     (l: string) => {
  //       if (l?.includes(key)) {
  //         return l;
  //       }
  //     }
  //   );

  //   return locale || "";
  // };

  return (
    <>
      <Avatar
        src={PROJECT_ICONS.MULTILANGUAGE_ICON}
        onClick={() => setShowMenu(!showMenu)}
        style={{ cursor: "pointer" /*display: "none" */, background: "#e6e6e6", padding: "3px", width: "30px", height: "30px" }}
      />
      {showMenu && (
        <div ref={menuRef}>
          <Menu
            style={{
              position: "absolute",
              padding: 0,
              right: layoutSync.CONTENT_AI ? "17px" : 0,
              top: layoutSync.CONTENT_AI ? "20px" : "56px",
              zIndex: 3,
              width: "100px",
              borderRadius: "4px",
              boxShadow: "0px 0px 10px #0000001A",
              background: "#FFFFFF 0% 0% no-repeat padding-box"
            }}
            items={items}
            onClick={(item) => {
              i18n?.changeLanguage?.(item?.key);

              // const locale: string | undefined = getLocale(item?.key);

              // _localStorageService.setAcceptLanguage(locale ? locale : ENUM_ACCEPT_LANGUAGE.ENGLISH);
              _localStorageService.setAcceptLanguage(item?.key);

              setTimeout(() => {
                setShowMenu(false);
              }, 100);
            }}
            selectedKeys={[i18n.language]}
          />
        </div>
      )}
    </>
  );
};
