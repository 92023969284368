import { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Flex, Image, Input, InputNumber, Row, Space, Spin, Typography } from "antd";

import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";

import { useSelector } from "react-redux";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { pdfjs, Document, Page } from "react-pdf";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setClickedPageNumberIDXAction } from "../../../Store/actions/_iDetailExtractActions";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type IProps = {
  fileURL: string;
};

export const CoreClaimPdfPreview: FC<IProps> = ({ fileURL }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const { clickedPageNumber } = useSelector((state: any) => state?.iDetailExtractReducer);

  const [maxView, setMaxView] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  

  useEffect(() => {
    return () => {
      setPageNumber(1);
      setNumPages(null);

      dispatch(setClickedPageNumberIDXAction(1));
    };
  }, []);

  useEffect(() => {
    goToPage(clickedPageNumber);
  }, [clickedPageNumber]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`idetailextract._CoreClaimMain._CoreClaimPdfPreview.${value as string}`])}`;
  };

  function goToPage(page: number) {
    setPageNumber(page);

    if (pdfContainerRef.current) {
      const pageElement: any = pdfContainerRef.current.querySelector(`.react-pdf__Page[data-page-number="${page}"]`);
      if (pageElement) {
        pdfContainerRef.current.scrollTop = pageElement.offsetTop;
      }
    }
  }

  const getClassName = (number: number) => {
    if (number < 10) {
      return "centeritemforsingleunit";
    } else if (number < 100) {
      return "centeritemfordoubleunit";
    } else {
      return "centeritemfortripleunit";
    }
  };

  return (
    <Col xs={{ span: 24 }} md={{ span: maxView ? 24 : 12 }}>
      <Row gutter={[0, 24]} style={{ background: "rgb(224 224 224 / 35%)", padding: "10px" }}>
        <Col xs={{ span: 24 }}>
          <Flex justify="space-between" align="center">
            {/* <Space> */}
            <Text style={{ fontSize: "16px", fontWeight: "700", color: "rgba(3, 78, 162, 1)" }}>
              {t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview"])}
            </Text>
            {numPages && (
              <Flex align="center" gap={5}>
                <Button type="link" disabled={pageNumber <= 1} onClick={() => goToPage(pageNumber - 1)} style={{ padding: "0px" }}>
                  <LeftOutlined />
                </Button>
                <Flex gap={4} align="center">
                  <Text style={{ fontSize: "13px", fontWeight: "400", color: "rgba(26, 26, 26, 1)" }}>{getTranslatedText("Page")}</Text>
                  {
                    <InputNumber
                      className={`coreclaimhidescroll ${getClassName(pageNumber)} `}
                      type="number"
                      value={pageNumber}
                      style={{ height: "22px", width: "28px", padding: "0px", fontSize: "13px", borderRadius: "0px" }}
                      onChange={(val) => {
                        goToPage(val ? val : 0);
                      }}
                      min={1}
                      max={numPages || 1}
                    />
                  }{" "}
                  / {numPages}
                </Flex>
                <Button type="link" disabled={pageNumber >= (numPages || 0)} onClick={() => goToPage(pageNumber + 1)} style={{ padding: "0px" }}>
                  <RightOutlined />
                </Button>
              </Flex>
            )}
            {/* </Space> */}
            <div style={{ marginLeft: "18%" }}>
              <Image
                src={maxView ? PROJECT_ICONS.C_AI_MINIMIZE : PROJECT_ICONS.C_AI_MAXIMIZE}
                preview={false}
                style={{ height: "15px", cursor: "pointer" }}
                onClick={() => setMaxView(!maxView)}
              />
            </div>
          </Flex>
        </Col>
        <Col xs={{ span: 24 }} ref={pdfContainerRef} style={{ overflowY: "auto", height: "77.5vh" }}>
          <Document
            file={fileURL}
            onLoadSuccess={onDocumentLoadSuccess}
            className={"coreclaimpdfviewer"}
            // onLoadProgress={({ loaded, total }) => console.log("Loading a document: " + (loaded / total) * 100 + "%")}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} className={"coreclaimpdfviewer"} />
            ))}
          </Document>
        </Col>
      </Row>
    </Col>
  );
};
