import {
  setActiveScreenTMAction,
  setGlossaryListTMAction,
  setLanguageListTMAction,
  setMainTabsTMAction,
  setOpenAddGlossaryModalTMAction,
  setTextTranslationDataTMAction,
  setUploadDocsDataTMAction
} from "../../../Store/actions/_translationModuleActions";
import {
  AssetDataTypes,
  GlossaryTTDataTypes,
  GlossaryTypes,
  GlossaryUDDataTypes,
  TextTranslationTypes,
  UploadDocsTypes,
  UploadFileCustom
} from "./modals";

export const enumTMActiveScreen = {
  TRANSLATE_ASSET: 3,
  TRANSLATE_PACKAGE: 4,
  FINAL: 5,
  NOTHING: 0
};

export const enumTMMainTabs = {
  TEXT_TRANSLATION: 1,
  UPLOAD_DOCUMENTS: 2
};

export const initialUploadFileCustomData: UploadFileCustom = {
  file_id: null,
  isError: false,
  isUploaded: false,
  isUploading: false,
  file: null,
  file_name: "",
  file_size: 0,
  file_type: ""
};

export const initialAssetData: AssetDataTypes = {
  context: "",
  language_ids: []
};

export const initialGlossaryUD: GlossaryUDDataTypes = {
  package_id: 0,
  language_id: 0
  // glossaryList: []
};

export const initialUploadDocsData: UploadDocsTypes = {
  package_id: null,
  files: [],
  // glossary: { ...initialGlossaryUD },
  asset: { ...initialAssetData }
};

export const initialIOData = { input: "", output: "", baseLanguage: "", targetLanguage: "" };

export const initialGlossaryTT: GlossaryTTDataTypes = {
  content: "",
  input_language: "",
  output_language: ""
  // glossary: []
};

export const initialTextTranslationData: TextTranslationTypes = {
  IOData: { ...initialIOData },
  // glossary: [],
  asset: { ...initialAssetData }
};

export const resetTMData = (dispatch: any) => {
  dispatch(setLanguageListTMAction([]));
  dispatch(setGlossaryListTMAction([]));
  dispatch(setOpenAddGlossaryModalTMAction(false));
  dispatch(setActiveScreenTMAction(enumTMActiveScreen.NOTHING));
  dispatch(setMainTabsTMAction(enumTMMainTabs.TEXT_TRANSLATION));
  dispatch(setUploadDocsDataTMAction({ ...initialUploadDocsData }));
  dispatch(setTextTranslationDataTMAction({ ...initialTextTranslationData }));
  dispatch(setGlossaryListTMAction([]));
};
