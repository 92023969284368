import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { getDocuments, uploadFilesForBatchQA } from "../../../Store/requests/_semanticSearchRequests";
import { setBatchQAData, setBatchQAFolders, setBatchQAStatus, setLandingScreen } from "../../../Store/actions/_semanticSearchActions";

interface MultiModalFileUploadProps {
    isModalVisibleForBatchQA: boolean;
    setIsModalVisibleForBatchQAFiles: React.Dispatch<React.SetStateAction<boolean>>;
    fileList: File[]; // Update the type to `File[]` for better type safety
}

const MultiModalFileUpload: React.FC<MultiModalFileUploadProps> = ({ setIsModalVisibleForBatchQAFiles, isModalVisibleForBatchQA, fileList }) => {
    const dispatch = useDispatch();
    const [isToExecute, setIsToExecute] = useState<boolean>(true);
    const timeoutRef = useRef<number | null>(null); // Use `null` for better type compatibility with `clearTimeout`

    const handleModalUploadFile = async () => {
        if (!fileList || fileList.length === 0) return; // Check for empty fileList

        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append("files", file);
        });

        try {
            const res = await uploadFilesForBatchQA(formData);
            if (!res || !res.data) { // Check if response and data are not undefined
                setIsModalVisibleForBatchQAFiles(false);
                return;
            }

            console.log(res.data);
            dispatch(setBatchQAData(res.data));

            // Set timeout and store the timeout ID in the ref
            timeoutRef.current = window.setTimeout(async () => {
                console.log("called now");
                const respfolders = await getDocuments();
                if (!respfolders || !respfolders.data) { // Check for undefined response
                    dispatch(setBatchQAData([]));
                    setIsModalVisibleForBatchQAFiles(false);
                    return;
                }

                dispatch(setBatchQAFolders(respfolders.data));
                dispatch(setLandingScreen(false));
                dispatch(setBatchQAStatus(true));
            }, 2000);
        } catch (error) {
            console.error("File upload failed:", error);
            setIsModalVisibleForBatchQAFiles(false);
        }
    };

    useEffect(() => {
        if (isModalVisibleForBatchQA) {
            handleModalUploadFile();
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null; // Reset the ref
                dispatch(setBatchQAData([]));
            }
        };
    }, []);

    return (
        <Modal
            visible={isModalVisibleForBatchQA}
            width={475}
            footer={false}
            centered
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#515151', gap: '3px' }}>
                <Lottie animationData={PROJECT_ICONS.UPLOADBATCHQA} loop={true} style={{ height: '150px' }} />
                <div>Almost there...</div>
                <div>Your files are being uploaded</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <div style={{ color: '#515151' }}>Uploading {fileList.length} files</div>
                <div>
                    <Button type="link" style={{ color: '#034EA2' }} onClick={() => {
                        if (timeoutRef.current) {
                            clearTimeout(timeoutRef.current);
                            timeoutRef.current = null; // Reset the ref to prevent memory leaks
                        }
                        dispatch(setBatchQAData([]));
                        setIsModalVisibleForBatchQAFiles(false);
                        setIsToExecute(false);
                        dispatch(setBatchQAStatus(true));
                    }}>
                        CANCEL
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default MultiModalFileUpload;
