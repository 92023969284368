import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { store } from "./Store";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import LogRocket from "logrocket";

import "./index.css";

console.log(process.env.REACT_APP_API_URL, "base url");

//@ts-ignore
LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);
console.log(process.env.REACT_APP_LOGROCKET_APP_ID);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

//@ts-ignore
window["store"] = store;

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <IntlProvider locale="en">
        <App />
      </IntlProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// <React.StrictMode>
//   <App />
// </React.StrictMode>
