import { Card, Col, Row, Skeleton, Spin, Typography } from "antd";

interface IProps {
  imgSrc: string;
  imgHeight?: string;
  description: string;
  showRight?: boolean;
  style?: {};
  onClick?: Function;
}

const ImageTile: React.FC<IProps> = ({
  imgSrc,
  description,
  showRight = false,
  style = {},
  onClick = () => {},
}) => (
  <div
    style={{
      boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
      borderRadius: "12px",
    }}
  >
    <Card
      hoverable
      cover={
        <img
          alt="example"
          src={imgSrc}
          style={{
            borderRadius: "12px",
          }}
        />
      }
      style={{ borderRadius: "12px", overflow: "hidden", ...style }}
      onClick={() => onClick()}
    >
      <div
        className="d-flex justify-content-between"
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            font: "normal normal bold 16px/27px Nunito Sans",
            letterSpacing: "0px",
            color: "#000000",
          }}
          className="d-flex align-items-center"
        >
          {description}
        </div>
        {showRight && (
          <>
            <span style={{ width: "2rem" }}></span>
            <div
              style={{ color: "#0546A5" }}
              className="d-flex justify-content-between align-items-center"
            >
              Explore Now
              <span style={{ margin: "0.5rem" }}>⟶</span>
            </div>
          </>
        )}
      </div>
    </Card>
  </div>
);

export default ImageTile;
