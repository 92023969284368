import { notification } from "antd";
import fabric, { buildUrlWithCors } from "./fabric/fabric";
//import Gif from './fabric/Gif'
import { uniqueId } from "./helpers";
import { getFontFamilyWithFallback } from "./fabric/fallbackFonts";

const isDebug = process.env.NODE_ENV === "development";

const MARKER_REGEX = /<mark name='(\d+)' \/>/g;

const textTypes = ["i-text", "textbox", "list"];

// sort objects as they were on slide
const resortCopiedObjects = (clone) => {
  if (clone !== "activeSelection") return clone;
  const sorted = [];
  for (const obj of clone.canvas._objects) {
    if (clone._objects.find((o) => o.id === obj.id)) sorted.push(obj);
  }
  clone._objects = sorted;
  return clone;
};

const removeHandler = (canvas) => {
  const ao = canvas.getActiveObject();
  if (ao && !ao.isEditing) {
    if (ao.type === "activeSelection") {
      ao.forEachObject((obj) => {
        if (obj.type === "avatar") obj.set("avatarType", "voiceover");
        else canvas.remove(obj);
      });
      canvas.discardActiveObject();
    } else {
      if (ao.type === "avatar") return ao.set("avatarType", "voiceover");
      if (ao.bg) canvas.switchToElementsMode();
      canvas.remove(ao);
    }
    if (isDebug) console.log("object(s):removed");
  }
};

const copyHandler = async (data) => {
  const ao = data.canvas.getActiveObject();
  if (!ao) return;
  if (ao.type === "activeSelection") {
    ao.forEachObject((obj) => obj.type === "avatar" && ao.removeWithUpdate(obj));
  }
  const clonedCanvasObject = fabric.util.object.clone(resortCopiedObjects(ao));
  if (clonedCanvasObject.type === "activeSelection") {
    clonedCanvasObject.forEachObject((obj, index) => {
      obj.set({
        scaleX: ao.getObjects()[index].scaleX,
        scaleY: ao.getObjects()[index].scaleY
      });
    });
  } else {
    clonedCanvasObject.set({
      scaleX: ao.scaleX,
      scaleY: ao.scaleY
    });
  }

  const serializedClonedObject = {
    slideId: data.slideId,
    clonedCanvasObject: {
      ...clonedCanvasObject.toJSON(),
      cLeft: clonedCanvasObject.left,
      cTop: clonedCanvasObject.top
    }
  };

  if (ao.type === "avatar") {
    serializedClonedObject.avatarBundle = data.avatar;
    serializedClonedObject.voice = {
      id: data.voice,
      provider: data.voiceProvider
    };
  }

  await navigator.clipboard.writeText(JSON.stringify(serializedClonedObject));

  if (isDebug) console.log("object(s):copied to clipboard");
};

const pasteHandler = async (data, canvas, updateCanvas, getSlideChangesOnPasteAvatar) => {
  try {
    const temp = await navigator.clipboard.readText();
    const parsedCopiedObject = JSON.parse(temp);
    let linkedSlideChanges = null;

    if (parsedCopiedObject?.clonedCanvasObject) {
      const { clonedCanvasObject } = parsedCopiedObject;
      const sameSlide = parsedCopiedObject.slideId === data.slideId;

      if (clonedCanvasObject.type === "avatar") {
        if (sameSlide) return;
        const avatarObj = canvas.getObjects().find((obj) => obj.type === "avatar");
        canvas.remove(avatarObj);
        linkedSlideChanges = getSlideChangesOnPasteAvatar(parsedCopiedObject);
      }

      fabric.util.enlivenObjects([clonedCanvasObject], ([clonedCanvasObj]) => {
        const propsToInclude = ["animation"];
        clonedCanvasObj.clone((clonedObj) => {
          canvas.discardActiveObject();
          clonedObj.set({
            left: sameSlide ? clonedObj.left + 10 : clonedCanvasObj.cLeft,
            top: sameSlide ? clonedObj.top + 10 : clonedCanvasObj.cTop,
            evented: true
          });

          if (clonedObj.type === "avatar" && clonedObj.avatarType === "voiceover") {
            clonedObj.set({ avatarType: "transparent" });
          }

          if (clonedObj.type === "activeSelection") {
            clonedObj.canvas = data.canvas;
            clonedObj.forEachObject((obj, index) => {
              obj.set({
                id: uniqueId(),
                scaleX: clonedCanvasObj.getObjects()[index].scaleX,
                scaleY: clonedCanvasObj.getObjects()[index].scaleY
              });
              if (!sameSlide) resetCanvasObjectMarkers(obj, data.speech);
              canvas.add(obj);
              obj.play?.();
            });
          } else {
            clonedObj.set({
              id: uniqueId(),
              scaleX: clonedCanvasObj.scaleX,
              scaleY: clonedCanvasObj.scaleY
            });
            if (!sameSlide) resetCanvasObjectMarkers(clonedObj, data.speech);
            canvas.add(clonedObj);
            //to play lottie or gif
            clonedObj.play?.();
          }

          canvas.setActiveObject(clonedObj);
          if (clonedObj.bg) {
            canvas.sendToBack(clonedObj);
            canvas.discardActiveObject();
            clonedObj.set({ selectable: false });
          }

          canvas.requestRenderAll();
          //updateCanvas({ linkedSlideChanges });
          updateCanvas(canvas);

          if (isDebug) console.log("object(s):pasted from clipboard");
        }, propsToInclude);
      });
    }
  } catch (error) {
    console.error("Error pasting object:", error.message);
  }
};

const alignObjectsHandler = (alignType, activeSelection) => {
  groupItemsRefresh(activeSelection);
  const groupWidth = activeSelection.width;
  const groupHeight = activeSelection.height;

  if (alignType.includes("distribute")) {
    if (alignType === "distribute_horizontal") {
      const itemsSummaryWidth = activeSelection._objects.reduce((accumulator, obj) => obj.getBoundingRect().width + accumulator, 0);
      const spacing = (groupWidth - itemsSummaryWidth) / (activeSelection._objects.length - 1);
      const objects = activeSelection._objects.sort((a, b) => a.getCenterPoint().x - b.getCenterPoint().x);

      objects.forEach((object) => {
        const objectIndex = objects.indexOf(object);
        const previousObject = objects[objectIndex - 1];
        if (previousObject) {
          const previousObjectWidth = previousObject.getBoundingRect().width;
          const previousObjectCenter = previousObject.getCenterPoint();
          const objectWidth = object.getBoundingRect().width;
          const objectCenterX = previousObjectCenter.x + previousObjectWidth / 2 + spacing + objectWidth / 2;
          const objectCenter = object.getCenterPoint();
          object.setPositionByOrigin(new fabric.Point(objectCenterX, objectCenter.y), "center", "center");
        }
      });
    } else if (alignType === "distribute_vertical") {
      const itemsSummaryHeight = activeSelection._objects.reduce((accumulator, obj) => obj.getBoundingRect().height + accumulator, 0);
      const spacing = (groupHeight - itemsSummaryHeight) / (activeSelection._objects.length - 1);
      const objects = activeSelection._objects.sort((a, b) => a.getCenterPoint().y - b.getCenterPoint().y);

      objects.forEach((object) => {
        const objectIndex = objects.indexOf(object);
        const previousObject = objects[objectIndex - 1];
        if (previousObject) {
          const previousObjectHeight = previousObject.getBoundingRect().height;
          const previousObjectCenter = previousObject.getCenterPoint();
          const objectHeight = object.getBoundingRect().height;
          const objectCenterY = previousObjectCenter.y + previousObjectHeight / 2 + spacing + objectHeight / 2;
          const objectCenter = object.getCenterPoint();
          object.setPositionByOrigin(new fabric.Point(objectCenter.x, objectCenterY), "center", "center");
        }
      });
    }
  } else {
    activeSelection.forEachObject((obj) => {
      const { width: itemWidth, height: itemHeight } = obj.getBoundingRect();
      const center = obj.getCenterPoint();

      switch (alignType) {
        case "left":
          obj.setPositionByOrigin(new fabric.Point(-groupWidth / 2 + itemWidth / 2, center.y), "center", "center");
          break;
        case "center":
          obj.setPositionByOrigin(new fabric.Point(0, center.y), "center", "center");
          break;
        case "right":
          obj.setPositionByOrigin(new fabric.Point(groupWidth / 2 - itemWidth / 2, center.y), "center", "center");
          break;
        case "top":
          obj.setPositionByOrigin(new fabric.Point(center.x, -groupHeight / 2 + itemHeight / 2), "center", "center");
          break;
        case "middle":
          obj.setPositionByOrigin(new fabric.Point(center.x, 0), "center", "center");
          break;
        case "bottom":
          obj.setPositionByOrigin(new fabric.Point(center.x, groupHeight / 2 - itemHeight / 2), "center", "center");
          break;
        default:
          break;
      }
    });
  }
  groupItemsRefresh(activeSelection);
};

const groupItemsRefresh = (group) => {
  group.forEachObject((item) => {
    group.removeWithUpdate(item).addWithUpdate(item);
  });
};

/**
 * We are adding new object to canvas
 */
const createCanvasObject = async (activeObjectModifier, canvas) => {
  if (activeObjectModifier.newObject === "text") {
    const text = new fabric.Textbox("text", {
      id: uniqueId(),
      left: 100,
      top: 100,
      fontFamily: getFontFamilyWithFallback("Georgia"),
      fill: "#000000",
      minScaleLimit: 0.3
    });
    canvas.add(text);
    canvas.setActiveObject(text);
  } else if (activeObjectModifier.newObject === "image") {
    if (activeObjectModifier.url) {
      const oImg = await fabric.imageFromURL(activeObjectModifier.url);
      oImg.scale(Math.min(600 / oImg.width, 300 / oImg.height));
      oImg.set({ id: uniqueId(), top: 30, left: 30, type: "image" });
      canvas.add(oImg);
      canvas.setActiveObject(oImg);
    }
  } else if (activeObjectModifier.newObject === "video") {
    if (activeObjectModifier.url) {
      const video = await fabric.Video.preloadData(buildUrlWithCors(activeObjectModifier.url)).catch(() => null);
      if (!video) return;
      const oVideo = new fabric.Video(video, {
        id: uniqueId(),
        top: 30,
        left: 30,
        type: "video",
        thumbnail: activeObjectModifier.thumbnail,
        durationFitAudio: activeObjectModifier.durationFitAudio || false
      });
      canvas.add(oVideo);
      canvas.setActiveObject(oVideo);
    }
  } else if (activeObjectModifier.newObject === "svg") {
    if (activeObjectModifier.url) {
      const oSvg = await fabric.svgFromURL(activeObjectModifier.url);
      if (oSvg.type === "group" && oSvg.getObjects().length > 30) throw Error("Your SVG has too many objects. Maximum 30 allowed.");
      oSvg.scale(Math.min(600 / oSvg.width, 300 / oSvg.height));
      oSvg.set({ id: uniqueId(), top: 30, left: 30 });
      canvas.add(oSvg);
      canvas.setActiveObject(oSvg);
    }
  } else if (activeObjectModifier.newObject === "lottie") {
    const lottie = new fabric.Lottie(activeObjectModifier.url, {
      scaleX: 0.5,
      scaleY: 0.5,
      top: 30,
      left: 30,
      id: uniqueId()
    });
    await lottie.load();
    canvas.add(lottie);
    canvas.setActiveObject(lottie);
    lottie.play();
  } else if (activeObjectModifier.newObject === "shape") {
    const figureType = activeObjectModifier.type.replace(activeObjectModifier.type[0], activeObjectModifier.type[0].toUpperCase());
    const figure = new fabric[figureType]({
      id: uniqueId(),
      width: 100,
      height: 100,
      radius: 50,
      fill: "#000000",
      left: 100,
      top: 100,
      stroke: "#d9d9d9",
      strokeWidth: 0,
      strokeUniform: true
    });
    if (activeObjectModifier.type === "ellipse") figure.set({ rx: 80, ry: 40 });
    canvas.add(figure);
    canvas.setActiveObject(figure);
  } else if (activeObjectModifier.newObject === "frame") {
    const ao = canvas.getActiveObject();
    const frame = await fabric.Frame.fromImage(activeObjectModifier.url, ao);
    if (!frame) return notification.error({ message: "Svg frame must contain one path", duration: 3 });
    canvas.remove(canvas.getObjects().find((obj) => obj.id === ao.id));
    canvas.add(frame);
    canvas.setActiveObject(frame);
  } else if (activeObjectModifier.newObject === "gif") {
    // const gif = await Gif.createGif({
    //   id: uniqueId(),
    //   src: activeObjectModifier.url,
    //   previewSrc: activeObjectModifier.previewUrl,
    //   top: 100,
    //   left: 100,
    // })
    // canvas.add(gif)
    // gif.play()
    // canvas.setActiveObject(gif)
  } else if (activeObjectModifier.newObject === "avatar_listener") {
    const avatarObj = canvas.getObjects().find((obj) => obj.type === "avatar");
    const avatarObjScaledWidth = avatarObj.getScaledWidth();
    const listeningAvatarObj = canvas.getObjects().find((obj) => obj.meta?.listeningAvatar);
    if (listeningAvatarObj) canvas.remove(listeningAvatarObj);
    const oImg = await fabric.imageFromURL(activeObjectModifier.value.canvas);
    oImg.scaleToWidth(avatarObjScaledWidth);
    oImg.set({
      id: uniqueId(),
      top: 30,
      left: 30,
      type: "image",
      meta: { listeningAvatar: activeObjectModifier.value }
    });
    oImg.setControlsVisibility({
      ml: false,
      mt: false,
      mr: false,
      mb: false
    });
    canvas.add(oImg);
    canvas.setActiveObject(oImg);
  }
};

/**
 * We are changing current active object
 */
const changeCanvasActiveObject = async (activeObjectModifier, canvas, activeObject) => {
  if (activeObjectModifier.change === "gradient") {
    activeObject.set(
      "fill",
      new fabric.Gradient({
        type: "linear",
        gradientUnits: "percentage",
        coords: {
          x1: activeObjectModifier.fill.coords ? activeObjectModifier.fill.coords.x1 : 0,
          y1: activeObjectModifier.fill.coords ? activeObjectModifier.fill.coords.y1 : 0,
          x2: activeObjectModifier.fill.coords ? activeObjectModifier.fill.coords.x2 : 0.4,
          y2: activeObjectModifier.fill.coords ? activeObjectModifier.fill.coords.y2 : 0.4
        },
        colorStops: [
          {
            offset: 0,
            color: activeObjectModifier.fill.colorStops ? activeObjectModifier.fill.colorStops[0].color : "#edecf8"
          },
          {
            offset: 1,
            color: activeObjectModifier.fill.colorStops ? activeObjectModifier.fill.colorStops[1].color : activeObjectModifier.fill
          }
        ]
      })
    );
  } else if (activeObjectModifier.change === "layering") {
    canvas[activeObjectModifier.action](activeObject);
  } else if (activeObjectModifier.change === "groupedAlignment") {
    alignObjectsHandler(activeObjectModifier.value, canvas.getActiveObject());
  } else if (activeObjectModifier.change === "frame") {
    const ao = canvas.getActiveObject();
    if (!ao.img.id) ao.img.id = ao.id;
    ao.img.scaleToWidth(ao.getScaledWidth());
    ao.img.top = ao.top;
    ao.img.left = ao.left;
    ao.img.animation = ao.animation;
    const frame = await fabric.Frame.fromImage(activeObjectModifier.url, ao.img);
    if (!frame) return notification.error({ message: "Svg frame must contain one path" });
    canvas.remove(canvas.getObjects().find((obj) => obj.id === ao.id));
    canvas.add(frame);
    canvas.setActiveObject(frame);
  } else if (activeObjectModifier.change === "textType") {
    changeTextType(activeObject, activeObjectModifier.value, canvas);
  } else if (activeObjectModifier.change === "clipPath") {
    activeObject.set(
      "clipPath",
      new fabric.Rect({
        width: activeObject.width,
        height: activeObject.height,
        rx: activeObjectModifier.value,
        ry: activeObjectModifier.value,
        left: -activeObject.width / 2,
        top: -activeObject.height / 2
      })
    );
  } else if (activeObjectModifier.type === "SVG") {
    canvas.getActiveObject()._objects[activeObjectModifier.index].set(activeObjectModifier.change, activeObjectModifier.value);
  } else if (textTypes.includes(activeObject.type)) {
    editSelectedText(activeObject, activeObjectModifier);
  } else if (typeof activeObjectModifier.change === "object") {
    activeObject.set(activeObjectModifier.change);
  } else if (activeObjectModifier.async === true) await activeObject.setAsync(activeObjectModifier.change, activeObjectModifier.value);
  else activeObject.set(activeObjectModifier.change, activeObjectModifier.value);
};

const editSelectedText = (textObj, modifier) => {
  if (!textObj.getSelectedText() || textObj.selectionStyles.length === textObj._text.length) {
    for (const line in textObj.styles) {
      for (const char in textObj.styles[line]) {
        if (textObj.styles[line][char][modifier.change]) delete textObj.styles[line][char][modifier.change];
      }
    }
  }
  if (textObj.getSelectedText() && textObj.selectionStyles.length !== textObj._text.length)
    textObj.setSelectionStyles({ [modifier.change]: modifier.value }, textObj.selectionStart, textObj.selectionEnd);
  else textObj.set(modifier.change, modifier.value);
};

const changeTextType = (textObj, type, canvas) => {
  const { id, fontFamily, fontSize, left, top, width, scaleX, scaleY, fill, styles, selectionStyles, animation } = textObj;
  if (type === "text") {
    const text = new fabric.Textbox(textObj.text, {
      id,
      fontFamily,
      fontSize,
      left,
      top,
      width,
      scaleX,
      scaleY,
      fill,
      styles,
      selectionStyles,
      animation
    });
    canvas.remove(textObj);
    canvas.add(text);
    canvas.setActiveObject(text);
  } else {
    const list = new fabric.List(textObj.text, {
      id,
      fontFamily,
      fontSize,
      left,
      top,
      width,
      scaleX,
      scaleY,
      fill,
      styles,
      animation
    });
    if (type === "unordered") {
      list.set({ listType: "unordered", listBullet: "●" });
    } else list.set({ listType: "ordered" });
    canvas.remove(textObj);
    canvas.add(list);
    canvas.setActiveObject(list);
  }
};

const deleteObjectsOutsideCanvas = (object, canvas, updateCanvas) => {
  const bound = object.getBoundingRect();
  if (object.type === "avatar") return;
  if (canvas.getWidth() < bound.left || -bound.width > bound.left || canvas.getHeight() < bound.top || -bound.height > bound.top) {
    canvas.remove(object);
    canvas.renderAll();
    updateCanvas();
  }
};

const checkObjectsAnimationTime = (objects) => {
  return objects.some(
    (obj) =>
      (obj.animation?.type && obj.animation.startMarker && !obj.animation.startTime) ||
      (obj.animation?.exitType && obj.animation.endMarker && !obj.animation.endTime)
  );
};

const setTimeOnObjectsWithMarkers = (objects, markers) => {
  const markerMap = markers.reduce((map, marker) => {
    map[marker.name] = marker;
    return map;
  }, {});

  return objects.map((obj) => {
    if (obj.animation?.startMarker) {
      const { time } = markerMap[obj.animation.startMarker];
      obj.animation = { ...obj.animation, startTime: time };
    }
    if (obj.animation?.endMarker) {
      const { time } = markerMap[obj.animation.endMarker];
      obj.animation = { ...obj.animation, endTime: time };
    }
    return obj;
  });
};

const resetTimeOfObjectsWithMarkers = (objects, availableMarkers) => {
  let requestUpdateCanvas;
  const updatedObjects = objects.map((obj) => {
    if (obj.type === "avatar") return obj;

    //reset animation start/endTime for canvas objects that have start/endMarker properties
    if (obj.animation?.startMarker || obj.animation?.endMarker) {
      obj = { ...obj, animation: { ...obj.animation, startTime: false, endTime: false } };
    }

    //remove marker name from animation property of canvas object if marker was removed from speech
    if (availableMarkers) {
      if (obj.animation?.startMarker && !availableMarkers.includes(obj.animation?.startMarker)) {
        obj = { ...obj, animation: { ...obj.animation, startMarker: false } };
        requestUpdateCanvas = true;
      }

      if (obj.animation?.endMarker && !availableMarkers.includes(obj.animation?.endMarker)) {
        obj = { ...obj, animation: { ...obj.animation, endMarker: false } };
        requestUpdateCanvas = true;
      }
    }

    return obj;
  });
  return { objects: updatedObjects, requestUpdateCanvas };
};

const modifyBackground = (canvas, activeObject, activeObjectModifier) => {
  if (activeObjectModifier.background === "apply") {
    activeObject.applyAsBackground();
    canvas.switchToElementsMode();
  } else if (activeObjectModifier.background === "detach") {
    activeObject.detachFromBackground();
  } else if (activeObjectModifier.background === "save") {
    canvas.switchToElementsMode();
  }
};

const resetCanvasObjectMarkers = (obj, speech) => {
  if (obj.animation?.startMarker || obj.animation?.endMarker) {
    const availableMarkers = [...speech.matchAll(MARKER_REGEX)].map((match) => match[1]);

    obj.set({ animation: { ...obj.animation, startTime: false, endTime: false } });

    if (obj.animation?.startMarker && !availableMarkers.includes(obj.animation?.startMarker)) {
      obj.set({ animation: { ...obj.animation, startMarker: false } });
    }

    if (obj.animation?.endMarker && !availableMarkers.includes(obj.animation?.endMarker)) {
      obj.set({ animation: { ...obj.animation, endMarker: false } });
    }
  }
};

const getActiveObjectLayers = (canvasActiveObject) => {
  const objects = canvasActiveObject.canvas.getObjects();
  return objects.filter((obj) => {
    if (obj.type === "avatar" || obj.meta?.listeningAvatar) return false;
    return canvasActiveObject.type === "activeSelection"
      ? canvasActiveObject.intersectsWithObject(obj) || canvasActiveObject.contains(obj)
      : canvasActiveObject.intersectsWithObject(obj) || canvasActiveObject.id === obj.id;
  });
};

const manageLayersSelection = (key, canvas, canvasActiveObject, isShiftPressed) => {
  const clickedLayer = canvas.getObjects().find((obj) => obj.id === +key);
  const isObjectSelected =
    canvasActiveObject.type === "activeSelection" ? canvasActiveObject.contains(clickedLayer) : clickedLayer.id === canvasActiveObject.id;
  const isGroupSelection = canvasActiveObject.type === "activeSelection" && canvasActiveObject.getObjects().length > 1;
  let objectsToSelect = [];

  if (isShiftPressed) {
    if (isObjectSelected) {
      if (isGroupSelection) {
        objectsToSelect = canvasActiveObject.getObjects().filter((obj) => obj.id !== clickedLayer.id);
      } else {
        return canvas.discardActiveObject();
      }
    } else {
      if (canvasActiveObject.type === "activeSelection") {
        objectsToSelect = [clickedLayer, ...canvasActiveObject.getObjects()];
      } else {
        objectsToSelect = [clickedLayer, canvasActiveObject];
      }
    }
  } else {
    if (!isObjectSelected || isGroupSelection) {
      objectsToSelect = [clickedLayer];
    } else {
      return;
    }
  }

  canvas.discardActiveObject();
  const selection = new fabric.ActiveSelection(objectsToSelect, { canvas: canvas });
  canvas.setActiveObject(selection);
};

const scaleTextboxObjects = (target, canvas) => {
  const isTextboxGroup = target.type === "activeSelection" && target.getObjects().every((obj) => obj.type === "textbox" || obj.type === "list");
  if (target.type === "textbox" || target.type === "list") {
    scaleTextbox(target);
  } else if (isTextboxGroup) {
    target.forEachObject((obj) => {
      target.removeWithUpdate(obj);
      scaleTextbox(obj);
      target.addWithUpdate(obj);
    });
    //refresh group after changing objects dimensions
    target.forEachObject((obj) => {
      target.removeWithUpdate(obj).addWithUpdate(obj);
      canvas.renderAll();
    });
  }
};

const scaleTextbox = (textboxObj) => {
  const updatedFontSize = +(textboxObj.fontSize * textboxObj.scaleX).toFixed(1);
  if (updatedFontSize < 3) {
    textboxObj.fontSize = 3;
    textboxObj.scaleX = 1;
    textboxObj.scaleY = 1;
    return;
  }
  const updatedWidth = textboxObj.width * textboxObj.scaleX;
  textboxObj.fontSize = updatedFontSize;
  textboxObj.width = updatedWidth;
  textboxObj.scaleX = 1;
  textboxObj.scaleY = 1;
};

const removeNonUniformScalingControls = (target) => {
  const isTextboxGroup = target.type === "activeSelection" && target.getObjects().every((obj) => obj.type === "textbox" || obj.type === "list");
  if (isTextboxGroup) {
    target.setControlsVisibility({
      mt: false,
      ml: false,
      mr: false,
      mb: false
    });
  }
  return target;
};

export {
  textTypes,
  removeHandler,
  copyHandler,
  pasteHandler,
  createCanvasObject,
  changeCanvasActiveObject,
  deleteObjectsOutsideCanvas,
  checkObjectsAnimationTime,
  setTimeOnObjectsWithMarkers,
  resetTimeOfObjectsWithMarkers,
  modifyBackground,
  getActiveObjectLayers,
  manageLayersSelection,
  scaleTextbox,
  removeNonUniformScalingControls,
  scaleTextboxObjects
};
