import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HtmlCssPreview from "../Components/_HtmlCsspreview";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setNextStepperStateAction,
  setPreviousStepperStateAction,
  setRegenerateStateAction,
  setResponseCtAction
} from "../../../Store/actions/_contentTranscreationActions";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import {
  CONTENT_TRANSCREATION_STEPS,
  CT_DASHBOARD_STATUS,
  CT_PROMPTS,
  ENUM_CONTENT_TRANSCREATION_BUTTON_TXT,
  globalConstant,
  handleCancelApiRequest
} from "../../../Utils";
import { Card, Col, Image, Input, Row, Skeleton, Space, Typography } from "antd";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { createHtmlDashboardRequest, createHtmlRequest, createStoryBoardRequest } from "../../../Store/requests/_contentTranscreationRequests";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import { siteConfig } from "../../../Services";
import { ENUM_PROCESS_SCREENS } from "../Core/constants";
import { RegenerateModal } from "../Components";

const { TextArea } = Input;
const { Text } = Typography;

type BodyTypes = {
  content: string;
  status: string;
};

const initalBody: BodyTypes = {
  content: "",
  status: CT_DASHBOARD_STATUS.PENDING
};

export const CTOptimize: FC = () => {
  const dispatch = useDispatch();
  const timerRef: any = useRef();
  const { t,i18n } = useTranslation();
  const { activeStepper, nextStepper, previousStepper, responseCT, regenerate } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [body, setBody] = useState<BodyTypes>({ ...initalBody });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const { id, html_id } = responseCT;

    if (html_id) {
      createHtmlDashBoard(html_id);
    }

    return () => {
      dispatch(setLoadingStatusAction(false));
    };
  }, []);

  useEffect(() => {
    if (nextStepper && activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_2) {
      dispatch(setNextStepperStateAction(false));

      if (responseCT?.email_doc_id) createStoryBoardApi(responseCT?.email_doc_id);
    }
  }, [nextStepper]);

  useEffect(() => {
    if (regenerate && activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_2) {
      regenerateHTML();
    }
  }, [regenerate]);

  useEffect(() => {
    if (previousStepper && activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_2) {
      handleCancelApiRequest();
      clearTimeout(timerRef.current);
      dispatch(setPreviousStepperStateAction(false));
      dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_1));
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CONERT_TO_HTML));
    }
  }, [previousStepper]);

  const regenerateHTML = async () => {
    await createHtmlApi(responseCT?.email_doc_id);
    dispatch(setRegenerateStateAction(false));
  };

  const createHtmlApi = async (email_doc_id: number) => {
    const res = await createHtmlRequest({ email_doc_id: email_doc_id, regenerate: true }, dispatch);

    if (res && res?.message) {
      const { message, has_next } = res;

      dispatch(
        setResponseCtAction({
          ...responseCT,
          html_id: message
        })
      );

      createHtmlDashBoard(message);
    } else if (typeof res?.detail && regenerate === true) {
      dispatch(setNotificationMsgAction(res?.detail));
    }
  };

  const createHtmlDashBoard = async (html_id: number) => {
    const res = await createHtmlDashboardRequest(
      {
        prompt: CT_PROMPTS.HTML_CONTENT,
        id: html_id
      },
      dispatch
    );
    dispatch(setLoadingStatusAction(true));

    if (!res) return;

    if (res?.error && res?.status === CT_DASHBOARD_STATUS.FAILED) {
      dispatch(setNotificationMsgAction(res?.error));

      return;
    }

    setBody({ ...res });

    if (res?.status === CT_DASHBOARD_STATUS.SUCCESS) {
      dispatch(setResponseCtAction({ ...responseCT, ...res }));

      dispatch(setLoadingStatusAction(false));

      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD));
      return;
    }

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      createHtmlDashBoard(html_id);
    }, 5000);
  };

  const createStoryBoardApi = async (id: number) => {
    const res = await createStoryBoardRequest({ email_doc_id: id }, dispatch);

    if (res && res?.message) {
      const { message } = res;

      dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_3));

      // dispatch(
      //   setButtonTextStateAction(
      //     ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GENERATE_IMAGES
      //   )
      // );

      dispatch(
        setResponseCtAction({
          ...responseCT,
          storyboard_id: message
        })
      );

      localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);

      console.log(responseCT.storyboard_id);
    } else {
      //dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
    }
  };

  const copyToClipboard = () => {
    const el = document.createElement("textarea");

    el.value = body?.content;

    el?.setAttribute("id", "text-area");

    document.body.appendChild(el);

    el?.select();

    document?.execCommand("copy");

    document?.getElementById("text-area")?.remove();

    dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.2.notification.textcopied"])));
  };

  return (
    <>
      <Row> 
        <Col xs={{ span: 24 }}>
          <Card
            className="overflowY"
            style={{
              boxShadow: "0px 6px 18px #00000029",
              borderRadius: "12px",
              height: "62vh"
            }}
          >
            {body?.status === CT_DASHBOARD_STATUS.SUCCESS ? (
              <Row gutter={[8, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                  <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <Text className="font-nunito-sans optimize-font" style={{ marginBottom: "4px", fontWeight: "800" }}>
                      HTML Code
                    </Text>
                    <div>
                      <Space>
                        <img
                          src={PROJECT_ICONS.COPY_ICON}
                          style={{
                            width: "14px",
                            cursor: "pointer"
                          }}
                          onClick={copyToClipboard}
                        />
                        <Text
                          className="font-nunito-sans optimize-font optimize-font-blue"
                          style={{
                            marginBottom: "4px",
                            marginRight: "18px",
                            cursor: "pointer"
                          }}
                          onClick={copyToClipboard}
                        >
                          Copy Code
                        </Text>
                      </Space>
                      <Space>
                        <img src={PROJECT_ICONS.C_AI_MAXIMIZE} style={{ width: "14px", fontSize: "18px" }} />
                        <Text
                          className="font-nunito-sans optimize-font optimize-font-blue"
                          style={{
                            marginBottom: "4px",
                            marginRight: "2px",
                            cursor: "pointer"
                          }}
                        >
                          Expand
                        </Text>
                      </Space>
                    </div>
                  </div>
                  <TextArea
                    value={body?.content || ""}
                    style={{
                      height: "49vh",
                      // height: "26vh",
                      border: "0.4000000059604645px solid #000000",
                      borderRadius: "12px"
                    }}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                  // style={{ marginTop: "4vh" }}
                >
                  <Text
                    className="font-nunito-sans optimize-font"
                    // style={{ marginBottom: "4px" }}
                    style={{ fontWeight: "800" }}
                  >
                    HTML Preview
                  </Text>
                  <div
                    className="iframe-container"
                    style={{
                      height: "92%",
                      border: "0.4000000059604645px solid #000000",
                      borderRadius: "12px"
                    }}
                  >
                    <HtmlCssPreview htmlWithCss={body.content} />
                  </div>
                </Col>
              </Row>
            ) : (
              <Skeleton active />
            )}
          </Card>
        </Col>
      </Row>
      <RegenerateModal
        showModal={showModal}
        setShowModal={(val, isSave) => {
          if (isSave) createHtmlDashBoard(responseCT?.html_id);
          setShowModal(false);
        }}
      />
    </>
  );
};
