import { FC, useEffect, useRef, useState } from "react";
import { Avatar, Badge, Button, Checkbox, Col, Input, Menu, Modal, Row, Select, Space, Tag, Typography } from "antd";

import {
  getElaiResponseDataRequest,
  getElaiVideoData,
  getHistoryStatusFilterListRequest,
  getHistoryStatusRequest,
  getListOfLanguagesRequest,
  saveTranslateLanguageRequest
} from "../../../Store/requests/_contentTranscreationRequests";
import { DataType, MenuItem, PageTypes } from "./core/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  setCTLActiveSlideAction,
  setElaiTranslationDataAction,
  setFilterAppliedStatusAction,
  setRefetchContentHistoryListAction,
  setSelectedEmailDocAction,
  setSelectedFilterAction,
  setShowMoreListAction
} from "../../../Store/actions/_contentTranscreationActions";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import { ENUM_ROUTE_URLS, globalConstant, handleCancelApiRequest, useOutsideAlerter } from "../../../Utils";
import { DownOutlined, FilterTwoTone, SearchOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { CHListView } from "./view/List/ListView";
import { DeleteFile, RenameFile, getItem, handleDownloadVideo } from "./core/constants";
import { CHGridView } from "./view/Grid/GridView";
import { FilterCheckbox } from "./components/FilterCheckbox";
import { NameValueTypes } from "../../../Utils/_gTypes";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { getELaiResponse } from "../Content Translation/core/constants";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { Authorizer, siteConfig } from "../../../Services";
import _localStorageService from "../../../Services/_localStorageService";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { DeleteModal } from "../../../Components/DeleteModal";
import { RootState } from "../../../Store";
import { Overlay } from "./components/Overlay";

const { Text } = Typography;

const initialPage: PageTypes = {
  page_size: 0,
  total_count: 0,
  current_page: 1
};

const enumViews = {
  GRID: 1,
  LIST: 2
};
const authorizer = new Authorizer();

export const ContentHistoryWrapper: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const btnRef: any = useRef();
  const filterRef: any = useRef(null);

  const [list, setList] = useState<DataType[]>([]);
  const [view, setView] = useState<number>(enumViews.GRID); // By default view should be GRID
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [page, setPage] = useState<PageTypes>({ ...initialPage });
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [filterList, setFilterList] = useState<NameValueTypes[]>([]);
  const [filterListItems, setFilterListItems] = useState<NameValueTypes[]>([]);
  const [languageList, setLanguageList] = useState<NameValueTypes[]>([]);

  const [modalSaveBtnLoading, setModalSaveBtnLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const { refetchHistoryContent, selectedFilters, showMoreList, selectedEmailDoc, filterApplied } = useSelector(
    (state: RootState) => state?.contentTranscreationReducer
  );

  useEffect(() => {
    if (!initiate()) return;

    siteConfig.BASE_URL = process.env.REACT_APP_API_URL;

    getFilters();
    getLanguageList();
    return () => {
      dispatch(setLoadingStatusAction(false));

      dispatch(setSelectedFilterAction([]));

      handleCancelApiRequest();

      dispatch(setSelectedEmailDocAction({}));
    };
  }, []);

  const initiate = async () => {
    const isValid = await authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);

    return isValid;
  };

  useEffect(() => {
    if (refetchHistoryContent) {
      dispatch(setRefetchContentHistoryListAction(false));

      setList([]);

      getHistoryList(page?.current_page, selectedFilters);
    }
  }, [refetchHistoryContent]);

  useEffect(() => {
    if (showMoreList) {
      dispatch(setShowMoreListAction(false));

      if (view === enumViews.GRID) {
        setPage((p: PageTypes) => ({
          ...p,
          current_page: p.current_page + 1
        }));

        getHistoryList(page?.current_page + 1, selectedFilters);
      }
    }
  }, [showMoreList]);

  useEffect(() => {
    if (filterApplied) {
      dispatch(setFilterAppliedStatusAction(false));

      setList([]);

      getHistoryList(page?.current_page, selectedFilters);
    }
  }, [filterApplied, selectedFilters]);

  useEffect(() => {
    console.log(list, "list useefect");
  }, [list]);

  useEffect(() => {
    if (selectedEmailDoc?.download) {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, download: false }));

      handleDownloadVideo(selectedEmailDoc?.video?.video_url, dispatch, t);
    }
  }, [selectedEmailDoc?.download]);

  useEffect(() => {
    if (!selectedEmailDoc?.edit) return;

    setLoading(true);

    (async function () {
      const res = await getElaiVideoData(selectedEmailDoc?.video?.elai_id);

      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, edit: false }));

      if (!res) {
        console.log("Error fetching elai video data");
        dispatch(setNotificationMsgAction("Error fetching elai video data!"));
        setLoading(false);
        return;
      }

      dispatch(setElaiTranslationDataAction({ ...res, storyboard_id: selectedEmailDoc?.storyboard?.id, video_id: selectedEmailDoc?.video?.id }));
      navigate(ENUM_ROUTE_URLS.CONTENT_TRANSLATION);
      dispatch(setCTLActiveSlideAction(0));

      setLoading(false);
    })();
  }, [selectedEmailDoc?.edit]);

  const getHistoryList = async (page: number, selectedFilters: NameValueTypes[]) => {
    setSearchLoading(true);

    const res = await getHistoryStatusRequest(
      { search_term: searchTerm, filters: [...selectedFilters?.map((item: NameValueTypes) => item?.name || "")] },
      page,
      dispatch
    );

    setSearchLoading(false);

    if (!res) return;

    if (res?.data?.length) {
      setPage((p: PageTypes) => ({
        ...p,
        total_count: res?.total_count,
        page_size: res?.page_size,
        current_page: page
      }));
    } else {
      // setPage({ ...initialPage, current_page: res?.page + 1 });

      if (view === enumViews.GRID) {
        return;
      }
    }

    setList([...res?.data]);
  };

  const getFilters = async () => {
    const res = await getHistoryStatusFilterListRequest();

    const arrFilterItems: any[] = [];

    res?.forEach((item: NameValueTypes) => {
      if (item?.name === "SUCCESS") {
        item.ui_name = `${I18N_MODULE_KEYS["content.text.completed"]}`;
      } else if (item?.name === "NOT_STARTED") {
        item.ui_name = `${I18N_MODULE_KEYS["content.text.notstarted"]}`;
      } else if (item?.name === "PENDING") {
        item.ui_name = `${I18N_MODULE_KEYS["content.text.inprogress"]}`;
      }

      // arrFilterItems?.push(getItem("", item?.name ? item?.name : "", <FilterCheckbox item={item} />));
      arrFilterItems?.push(item);
    });

    let defaultStatus: NameValueTypes[] = setByDefaultStatus(res);

    getHistoryList(1, defaultStatus);

    setFilterList(res);

    setFilterListItems([...arrFilterItems]);
  };

  const getLanguageList = async () => {
    const res = await getListOfLanguagesRequest();

    console.log(res, "lamguage list");

    const arrLanguageList: NameValueTypes[] = [];

    res?.forEach((item: NameValueTypes) => {
      arrLanguageList?.push({
        value: item?.id?.toString() || "",
        label: item?.name,
        icon: item?.icon,
        id: item?.id,
        locale: item?.locale
      });
    });

    setLanguageList(arrLanguageList);
  };

  const setByDefaultStatus = (res: NameValueTypes[]) => {
    const objFilteredItem: NameValueTypes = { ...res?.find((item: NameValueTypes) => item?.id === 3) };

    dispatch(setSelectedFilterAction([{ ...objFilteredItem }]));

    return [{ ...objFilteredItem }];
  };

  const handleOnChange = async (current: number) => {
    await getHistoryList(current, selectedFilters);
  };

  useOutsideAlerter(filterRef, () => setFilterOpen(false));

  const handleCloseFilterTags = (removedTagID: number) => {
    const arrSelectedFilters: NameValueTypes[] = [...selectedFilters?.filter((item: NameValueTypes) => item?.id !== removedTagID)];

    dispatch(setSelectedFilterAction([...arrSelectedFilters]));

    dispatch(setFilterAppliedStatusAction(true));
  };

  const toggleView = (view: number) => {
    // let defaultStatus: NameValueTypes[] = setByDefaultStatus(filterList);

    setList([]);

    getHistoryList(1, selectedFilters);
    // getHistoryList(1, defaultStatus);

    setView(view);
  };

  const handleOnChangeFileName = (value: string) => {
    dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, filename: value }));
  };

  const saveTranslateEmail = async (body: { video_id: number; language_id: number }, locale: string) => {
    setModalSaveBtnLoading(true);
    let res = await saveTranslateLanguageRequest({ ...body });

    if (res?.message !== "Video Translated successfully") {
      setModalSaveBtnLoading(false);
      // dispatch(setNotificationMsgAction(globalConstant.SOMETHING_WENT_WRONG));
      // dispatch(setNotificationMsgAction(`${t(globalConstant.SOMETHING_WENT_WRONG)}`));
      return;
    }

    // navigate(ENUM_ROUTE_URLS.CONTENT_TRANSLATION);

    res = await getELaiResponse(res, res?.video_id, res?.storyboard_id, locale, dispatch, navigate, true);
    dispatch(setCTLActiveSlideAction(0));

    setModalSaveBtnLoading(false);

    dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, translate: false }));
  };

  const handleSaveInput = async (type: string) => {
    if (type === "rename") {
      dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, rename: false }));

      RenameFile(selectedEmailDoc?.email_id, selectedEmailDoc?.filename, dispatch, t);
    } else if (type === "translate") {
      const language = languageList?.find((item: NameValueTypes) => item?.label === selectedLanguage);

      saveTranslateEmail({ video_id: selectedEmailDoc?.video?.id, language_id: language?.id || 0 }, language?.locale || "");
    }
  };

  const handleOnChangeCheckbox = (item: NameValueTypes) => {
    let arrSelectedFilters: NameValueTypes[] = [...selectedFilters];

    if (!arrSelectedFilters?.map((item: NameValueTypes) => item?.id)?.includes(item?.id)) {
      arrSelectedFilters?.push(item);
    } else {
      arrSelectedFilters = [...arrSelectedFilters?.filter((i: NameValueTypes) => i?.id !== item?.id)];
    }

    dispatch(setSelectedFilterAction([...arrSelectedFilters]));

    dispatch(setFilterAppliedStatusAction(true));
  };

  return (
    <>
      <Row gutter={[8, 16]}>
        {/* <Row > */}
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Space>
            <div>
              {" "}
              <Input
                style={{ width: "25vw", height: "40px" }}
                prefix={
                  <Button ref={btnRef} type="link" style={{ padding: 0 }} onClick={() => {}} disabled={searchLoading}>
                    <SearchOutlined style={{ fontSize: "20px", marginTop: "3px" }} />
                  </Button>
                }
                // placeholder="Search Video"
                placeholder={`${t(I18N_MODULE_KEYS["content.history.wrapper.text.searchvideo"])}`}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e?.target?.value);
                }}
                readOnly={searchLoading}
                disabled={searchLoading}
                onKeyDownCapture={(e) => {
                  const { which } = e;

                  if (which === 13) {
                    setList([]);
                    getHistoryList(page?.current_page, selectedFilters);
                  }
                }}
              />
            </div>
            <div style={{ position: "relative" }} ref={filterRef}>
              <Badge dot={selectedFilters?.length ? true : false}>
                <FilterTwoTone style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setFilterOpen(!filterOpen)} />
              </Badge>
              {filterOpen && (
                <Menu
                  style={{
                    position: "absolute",
                    right: "-156px",
                    top: 0,
                    zIndex: 2,
                    width: "150px",
                    borderRadius: "4px",
                    boxShadow: "0px 0px 10px #0000001A",
                    background: "#FFFFFF 0% 0% no-repeat padding-box"
                  }}
                >
                  <>
                    {filterListItems?.map?.((item: NameValueTypes, index: number) => {
                      return (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            handleOnChangeCheckbox(item);
                          }}
                        >
                          <Checkbox
                            style={{ marginRight: "7px" }}
                            checked={selectedFilters?.map((item: NameValueTypes) => item?.id)?.includes(item?.id)}
                          />
                          <Text style={{ opacity: 1, fontSize: "12px", fontWeight: "bold" }}>{t(item?.ui_name || "") || ""}</Text>
                        </Menu.Item>
                      );
                    })}
                  </>
                </Menu>
              )}
            </div>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="d-flex" style={{ justifyContent: "flex-end" }}>
          <Space>
            <div>
              <img
                src={view === enumViews.GRID ? PROJECT_ICONS.CH_GRID_ACTIVE_ICON : PROJECT_ICONS.CH_GRID_INACTIVE_ICON}
                style={{ width: "30px", cursor: "pointer" }}
                onClick={() => toggleView(enumViews.GRID)}
              />
            </div>
            <div>
              <img
                src={view === enumViews.LIST ? PROJECT_ICONS.CH_LIST_ACTIVE_ICON : PROJECT_ICONS.CH_LIST_INACTIVE_ICON}
                style={{ width: "30px", cursor: "pointer" }}
                onClick={() => toggleView(enumViews.LIST)}
              />
            </div>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} className="d-flex">
          <Space>
            <Text style={{ opacity: 1, color: "#848484", fontSize: "12px", fontStyle: "italic" }}>
              {/* {selectedFilters?.length ? "Applied Filters:" : "No Filters Applied"}{" "} */}
              {selectedFilters?.length
                ? `${t(I18N_MODULE_KEYS["semantic.search.header.appliedfilters"])}:`
                : `${t(I18N_MODULE_KEYS["content.text.nofilterapplied"])}:`}{" "}
            </Text>
            {selectedFilters?.map((item: NameValueTypes, index: number) => {
              return (
                <Tag color="processing" closable key={`${item?.ui_name}_${index}`} onClose={() => handleCloseFilterTags(item?.id || 0)}>
                  {/* {item?.ui_name} */}
                  {t(item?.ui_name || "")}
                </Tag>
              );
            })}
          </Space>
        </Col>

        <Col
          xs={{ span: 24 }}
          style={
            view === enumViews.LIST
              ? { background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 6px 18px #00000029", borderRadius: "10px", padding: "20px" }
              : {}
          }
        >
          {view === enumViews.GRID && <CHGridView list={list} />}
          {view === enumViews.LIST && <CHListView list={list} handleOnChange={(current) => handleOnChange(current)} page={page} />}
        </Col>
      </Row>
      <Modal
        centered
        title={
          <div
            style={{
              width: "100%",
              cursor: "move"
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {/* {selectedEmailDoc?.rename && "Rename"}
            {selectedEmailDoc?.translate && `Translate ${selectedEmailDoc?.filename}`} */}
            {selectedEmailDoc?.rename && `${t(I18N_MODULE_KEYS["content.text.rename"])}`}
            {selectedEmailDoc?.translate && `${t(I18N_MODULE_KEYS["content.button.translate"])} ${selectedEmailDoc?.filename}`}
          </div>
        }
        open={selectedEmailDoc?.rename || selectedEmailDoc?.translate}
        onCancel={() => dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, rename: false, translate: false }))}
        footer
      >
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }}>
            {/* <Text style={{ opacity: 1, fontSize: "11px", fontWeight: "bold" }}>{selectedEmailDoc?.rename && "New File Name"}</Text> */}
            <Text style={{ opacity: 1, fontSize: "11px", fontWeight: "bold" }}>
              {selectedEmailDoc?.rename && `${t(I18N_MODULE_KEYS["content.history.wrapper.text.newfilename"])}`}
            </Text>
          </Col>
          <Col xs={{ span: 24 }} style={{ marginBottom: "15px" }}>
            {selectedEmailDoc?.rename && (
              <Input value={selectedEmailDoc?.filename} onChange={(e) => handleOnChangeFileName(e?.target?.value)} autoFocus />
            )}
            {selectedEmailDoc?.translate && (
              <Space style={{ display: "flex", justifyContent: "center" }}>
                <Select
                  showSearch
                  placeholder={`${t(I18N_MODULE_KEYS["content.text.selectalanguage"])}`}
                  onChange={(option) => setSelectedLanguage(option)}
                  style={{ width: 460, height: "34px" }}
                  // value={selectedLanguage || "Select a Language"}
                  value={selectedLanguage || `${t(I18N_MODULE_KEYS["content.text.selectalanguage"])}`}
                  allowClear={selectedLanguage ? true : false}
                >
                  {languageList?.map((p: NameValueTypes, i: number) => (
                    //@ts-ignore
                    <Option value={p?.label} key={i}>
                      {" "}
                      <div>
                        <Avatar
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${p?.icon?.toString()?.toLocaleUpperCase()}.svg`}
                          style={{ width: "27px", height: "27px", marginRight: "5px" }}
                        />

                        <Text style={{ opacity: 1, fontSize: "11px", fontWeight: "bold" }}>{p?.label}</Text>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Space>
            )}
          </Col>

          <Col xs={{ span: 24 }} style={{ display: "flex", justifyContent: "end" }}>
            <Space align="end">
              <Button onClick={() => dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, rename: false, translate: false }))}>
                {t(I18N_MODULE_KEYS["content.button.cancel"])}
              </Button>
              <Button
                type="primary"
                onClick={() => handleSaveInput((selectedEmailDoc?.rename && "rename") || (selectedEmailDoc?.translate && "translate"))}
                style={{
                  background: selectedEmailDoc?.translate && !selectedLanguage ? "rgba(0, 0, 0, 0.04)" : "rgb(5, 70, 165)",
                  color: selectedEmailDoc?.translate && !selectedLanguage ? "rgba(0, 0, 0, 0.25)" : "white"
                }}
                disabled={selectedEmailDoc?.translate && !selectedLanguage}
                loading={modalSaveBtnLoading}
              >
                {selectedEmailDoc?.translate ? `${t(I18N_MODULE_KEYS["content.button.translate"])}` : `${t(I18N_MODULE_KEYS["content.button.save"])}`}
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
      <DeleteModal
        title={`${t(I18N_MODULE_KEYS["content.text.Are you sure you want to delete?"])}`}
        isModalOpen={selectedEmailDoc?.delete}
        handleOk={() => DeleteFile(selectedEmailDoc?.email_id, dispatch, t, selectedEmailDoc)}
        handleCancel={() => dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, delete: false }))}
      />
      {/* Temporary Overlay, replace with appropriate CSS styles */}
      {loading && <Overlay />}
    </>
  );
};

// export const ContentHistoryWrapper: FC = () => {
//   const dispatch = useDispatch();

//   const [list, setList] = useState<DataType[]>([]);
//   const [page, setPage] = useState<PageTypes>({ ...initialPage });

//   const { refetchHistoryContent } = useSelector((state: any) => state?.contentTranscreationReducer);
//   const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);

//   useEffect(() => {
//     initiate(1);
//     return () => {
//       dispatch(setLoadingStatusAction(false));
//       handleCancelApiRequest();
//     };
//   }, []);

//   useEffect(() => {
//     if (refetchHistoryContent) {
//       dispatch(setRefetchContentHistoryListAction(false));
//       initiate(page?.current_page);
//     }
//   }, [refetchHistoryContent]);

//   const initiate = async (page: number) => {
//     const res = await getHistoryStatusRequest(page, dispatch);

//     if (!res) return;

//     setPage((p: PageTypes) => ({
//       ...p,
//       total_count: res?.total_count,
//       page_size: res?.page_size,
//       current_page: page
//     }));

//     setList([...res?.data]);
//   };

//   const handleOnChange: PaginationProps["onShowSizeChange"] = async (current) => {
//     await initiate(current);
//   };

//   return (
//     <div>
//       <Table columns={ContentHistoryColumns} dataSource={list} pagination={false} loading={isLoading} />
//       <div className="d-flex" style={{ justifyContent: "flex-end" }}>
//         <Pagination showSizeChanger={false} defaultCurrent={1} pageSize={page?.page_size} total={page?.total_count} onChange={handleOnChange} />
//       </div>
//     </div>
//   );
// };
