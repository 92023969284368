import { Button, Col, Image, Input, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { DeleteOutlined, EditOutlined, FolderOutlined } from "@ant-design/icons";
import { Folder } from "../../Core/constants";
const { Paragraph } = Typography;

export interface LeftCardDisplayingFoldersProps {
    folderData:Array<Folder>[]
    setNewFileStatus: (status: boolean) => void;
    setCreateNewFolderStatuslocal: (status: boolean) => void;
    handlestateofselectedFolder: (folderName: string, folderData: any) => void;
    setShowModalforFolder: (status: boolean) => void;
    selectedFolder: string;
  }
  
  export const LeftCardDisplayingFolders: React.FC<LeftCardDisplayingFoldersProps> = ({
    folderData,
    setNewFileStatus,
    setCreateNewFolderStatuslocal,
    handlestateofselectedFolder,
    setShowModalforFolder,
    selectedFolder,
  })  => {
  const { tabList} = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  console.log(tabList, "tabList");

  return (
    <>
      <div
        style={{
          textAlign: "center",
          padding: "18px",
          fontSize: "16px",
          color: "#1e4da1",
          gap: "10px",
          border: "none",
          display: "flex",
          backgroundColor: "F6FCFB",
          borderRadius: "8px",
          fontWeight: "600",
          alignItems: "center",
          marginLeft: "30px",
          cursor: "pointer",
        }}
        onClick={() => {
          setNewFileStatus(true);
          setCreateNewFolderStatuslocal(true);
        }}
      >
        <img src={PROJECT_ICONS.UNIONCREATEFOLDER} style={{ height: "18px" }} />
        <p> {t(I18N_MODULE_KEYS["semantic.search.filesystem.createnewfolder"])}</p>
      </div>
      <div style={{ overflowY: "auto", height: "400px" }}>
        {folderData?.map((folder: any, index: number) =>
          selectedFolder !== folder?.name ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: folder.source !== "Manual" ? "0px 6px" : "10px 6px", // Adjust padding based on folder.source
                marginTop: folder.source !== "Manual" ? "0px" : "10px", // Adjust marginTop based on folder.source
                fontWeight: "600",
                fontSize: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                handlestateofselectedFolder(folder?.name, folder);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "#1e4da1" }}>
                <FolderOutlined style={{ color: "#1e4da1", fontSize: "20px", paddingLeft: "5px" }} />
                <div style={{ display: "flex", flexDirection: "column", paddingTop: folder.source !== "Manual" ? "15px" : "0px" }}>
                  <span style={{}}>{folder?.name}</span>
                  {folder.source !== "Manual" && <span style={{ fontSize: "10px", fontStyle: "italic", color: "black" }}>via Sharepoint</span>}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  background: "#1e4da1",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: folder.source !== "Manual" ? "0px 6px 6px 6px" : "10px 6px 10px 6px", // Adjust padding based on folder.source
                  marginTop: folder.source !== "Manual" ? "10px" : "10px", // Adjust marginTop based on folder.source
                  fontWeight: "600",
                  fontSize: "15px",
                  cursor: "pointer",
                  display: "flex",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "white", fontWeight: "600", fontSize: "15px" }}>
                  <FolderOutlined style={{ color: "white", fontSize: "20px", paddingLeft: "5px" }} />
                  <div style={{ display: "flex", flexDirection: "column", paddingTop: folder.source !== "Manual" ? "10px" : "0px" }}>
                    <span style={{}}>{folder?.name}</span>
                    {folder.source !== "Manual" && <span style={{ fontSize: "10px", fontStyle: "italic", fontWeight: "bold" }}>via Sharepoint</span>}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <EditOutlined style={{ color: "white", fontSize: "20px", paddingLeft: "5px", cursor: "not-allowed" }} />
                  <DeleteOutlined
                    style={{ color: "white", fontSize: "20px", paddingLeft: "5px", cursor: "pointer" }}
                    onClick={() => {
                      setShowModalforFolder(true);
                    }}
                  />
                </div>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};
