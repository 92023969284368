import { FC, FormEvent, useEffect, useState } from "react";
import { singleRuleTags } from "../../Core/backendAPIMockData";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { deleteRuleRequest, getRuleTagsRequest, updateRuleNameRequest, updateRuleTagsRequest } from "../../../../Store/requests/_contentAIRequests";
import { useDispatch } from "react-redux";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { globalConstant } from "../../../../Utils";
import { Button, Dropdown, MenuProps, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { RuleComponentProps, RuleTags, SingleRuleTag } from "../../Core/types";
import LabelCheckBox from "../Common/LabelCheckBox";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

type MenuItem = Required<MenuProps>["items"][number];

const RuleComponent: FC<RuleComponentProps> = ({ rule, ruleNo, filteredTagsIds, setRefetchRules, editRuleId, setEditRuleId }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [ruleTags, setRuleTags] = useState<RuleTags>({});
  const [show, setShow] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [editRule, setEditRule] = useState(false);
  const [updatedRule, setUpdatedRule] = useState(rule.rule_name);

  //console.log("Rule No:", ruleNo);
  const toggleRuleTags = async () => {
    //show UI Tags
    setShow((s) => !s);

    if (!show) {
      setLoadingTags(true);
      await getRuleTags();
      setLoadingTags(false);
    }
  };

  //fetch Rule Tags
  const getRuleTags = async () => {
    const res = await getRuleTagsRequest(rule.rule_id);

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      setError(false); //Clear the previous error message
      const { tags } = res;
      setRuleTags(tags);
    } else {
      setError(true);
    }
  };

  //update Rule Tags
  const updateRuleTags = async (selectedTagsIDs: number[]) => {
    const res = await updateRuleTagsRequest({ rule_id: rule.rule_id, tags: selectedTagsIDs });

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      console.log("Rules Updated Successfully!");
      // dispatch(setNotificationMsgAction(`Rule ${ruleNo} tags updated!`));
      dispatch(
        setNotificationMsgAction(
          `${t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.rule"])} ${ruleNo} ${t(
            I18N_MODULE_KEYS["content.contentai.headings.llm.text.tagsupdated"]
          )}`
        )
      );
    }
  };

  const updateRuleHandler = async () => {
    setLoadingUpdate(true);
    //https://content-ai-dev.devkraft.in/api/rule/tags
    /**
        {
            "rule_id":4,
            "tags" : [1,2,3] 
        }

        {
            "status": "success"
        }
     */

    let selectedTagsIDs: number[] = [];

    Object.keys(ruleTags).forEach((category) =>
      ruleTags[category].forEach((tag) => {
        tag.selected && selectedTagsIDs.push(tag.id);
      })
    );

    //console.log("selected Tags: ", selectedTagsIDs);
    await updateRuleTags(selectedTagsIDs);
    setLoadingUpdate(false);

    if (!isUpdatedTagsInFilteredTags(selectedTagsIDs)) {
      setRefetchRules((p: boolean) => !p);
    }
  };

  const isUpdatedTagsInFilteredTags = (updatedTagsIDs: number[]) => {
    for (let tagId of updatedTagsIDs) {
      if (filteredTagsIds.includes(tagId)) return true;
    }

    return false;
  };

  const handleEditClick = () => {
    setEditRule((p) => !p);
    if (!editRuleId) setEditRuleId(rule.rule_id);
    else {
      setEditRuleId(0); //set to 0 to indicate no rule is being edited
      setUpdatedRule(rule.rule_name);
    }
  };

  const handleUpdateRule = (e: FormEvent) => {
    e.preventDefault();
    updateRule();
  };

  const updateRule = async () => {
    //Return if new name is empty
    if (!updatedRule.trim()) {
      dispatch(setNotificationMsgAction("Rule name cannot be empty!"));
      return;
    }

    setLoadingUpdate(true);

    //Update API call
    const res = await updateRuleNameRequest({ rule_id: rule.rule_id, name: updatedRule });

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      console.log("Rule Name Updated Successfully!");
      dispatch(setNotificationMsgAction(`Rule ${ruleNo} updated!`));

      //Refetch rules
      setRefetchRules((p: boolean) => !p);

      setEditRule((p) => !p);
      setEditRuleId(0); //set to 0 to indicate no rule is being edited
    }

    setLoadingUpdate(false);
  };

  const handleDeleteClick = async () => {
    const res = await deleteRuleRequest(rule.rule_id);

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      console.log("Rule Deleted Successfully!");
      dispatch(setNotificationMsgAction(`Rule ${ruleNo} deleted!`));

      //Refetch rules
      setRefetchRules((p: boolean) => !p);
    }
  };

  const MenuItems = [
    [
      editRule
        ? `${t(I18N_MODULE_KEYS["content.button.cancel"])} ${t(I18N_MODULE_KEYS["content.text.edit"])}`
        : `${t(I18N_MODULE_KEYS["content.text.edit"])}`,
      "edit",
      <img src={PROJECT_ICONS.C_AI_EDIT_PENCIL_ICON} style={{ width: "16px" }} />
    ],
    [`${t(I18N_MODULE_KEYS["content.text.delete"])}`, "delete", <img src={PROJECT_ICONS.C_AI_DELETE_TRASH_ICON} style={{ width: "16px" }} />]
  ];
  // const MenuItems = [
  //   [editRule ? "Cancel Edit" : "Edit", "edit", <img src={PROJECT_ICONS.C_AI_EDIT_PENCIL_ICON} style={{ width: "16px" }} />],
  //   ["Delete", "delete", <img src={PROJECT_ICONS.C_AI_DELETE_TRASH_ICON} style={{ width: "16px" }} />]
  // ];

  const getItem = (label: React.ReactNode, key: React.Key, icon?: any): MenuItem => {
    return { key, icon, label } as MenuItem;
  };

  //@ts-ignore
  const items: MenuItem[] = MenuItems.map((item) => getItem(...item));

  const onMenuClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "edit":
        handleEditClick();
        break;
      case "delete":
        handleDeleteClick();
        break;
    }
  };

  return (
    /**Disable interaction with Rule component if this rule is not the one being edited. editRuleId ≠ 0. */
    <div
      className={`rules ${!editRule && editRuleId ? "disable-interaction" : ""}`}
      style={editRule ? { border: "1px dashed #034EA2", background: "#F4F9FF" } : {}}
    >
      <div className="row">
        <div className="col-10" style={{ display: "flex", alignItems: "center" }}>
          {editRule ? (
            <form style={{ width: "100%" }} onSubmit={handleUpdateRule}>
              <input autoFocus value={updatedRule} onChange={(e) => setUpdatedRule(e.target.value)} className="edit-rule-input" />
            </form>
          ) : (
            <div className="heading" style={{ fontWeight: show ? 600 : "unset" }}>
              {rule.rule_name}
            </div>
          )}
        </div>
        <div className="col-2 applicability" style={{ display: "flex", fontWeight: 600, justifyContent: "space-between", padding: "15px" }}>
          {editRule ? (
            <div style={{ cursor: "pointer" }} onClick={updateRule}>
              {loadingUpdate && <LoadingOutlined style={{ marginRight: "5px" }} />}
              {/* UPDATE */}
              {t(I18N_MODULE_KEYS["content.button.update"])}
            </div>
          ) : (
            <div onClick={() => toggleRuleTags()} style={{ display: "flex", gap: "5px", cursor: "pointer" }}>
              {/* <span>Applicability</span> */}
              {t(I18N_MODULE_KEYS["content.contentai.headings.llm.text.applicability"])}
              <span>
                {!editRule && editRuleId ? (
                  <img src={show ? PROJECT_ICONS.C_AI_UP_ARROW_GRAY : PROJECT_ICONS.C_AI_DOWN_ARROW_GRAY} style={{ width: "16px", height: "20px" }} />
                ) : (
                  <img src={show ? PROJECT_ICONS.C_AI_UP_ARROW : PROJECT_ICONS.C_AI_DOWN_ARROW} style={{ width: "16px", height: "20px" }} />
                )}
              </span>
            </div>
          )}

          <Dropdown menu={{ items, onClick: onMenuClick }} placement="bottomRight">
            <img
              src={!editRule && editRuleId ? PROJECT_ICONS.C_AI_ACTION_MENU_GRAY_ICON : PROJECT_ICONS.C_AI_ACTION_MENU_ICON}
              style={{ cursor: "pointer", padding: "0px 15px", height: "20px" }}
            />
          </Dropdown>
        </div>
      </div>
      <div className="row toggleView" style={{ ...{ /* borderTop: "1px solid #666666", */ margin: "0" }, ...(!show ? { display: "none" } : {}) }}>
        {loadingTags ? (
          <Spin style={{ margin: "1rem auto" }} />
        ) : error ? (
          <div className="col-12" style={{ textAlign: "center", padding: "1rem" }}>
            {/* Error: No response! */}
            {t(I18N_MODULE_KEYS["content.text.errornoresponse"])}
          </div>
        ) : (
          <>
            {Object.keys(ruleTags).map((category, key) => {
              let categoryTags = ruleTags[category];
              let noOfSelectedTags = 0;

              categoryTags.forEach((tag) => tag.selected && ++noOfSelectedTags);
              return (
                <div key={key} className="col-4 px-35" style={{ marginTop: "10px" }}>
                  <p className="listTitle">
                    <span style={{ textTransform: "capitalize" }}>{category}</span> |{" "}
                    {(noOfSelectedTags.toString().length === 1 ? "0" : "") + noOfSelectedTags}
                  </p>
                  <div className="collapse show">
                    <div className="checkboxList">
                      {categoryTags.map((tag, key) => (
                        <LabelCheckBox
                          key={key}
                          label={tag.name}
                          labelStyle={{ textTransform: "capitalize", margin: "10px 0" }}
                          name={category}
                          value={tag.name}
                          checked={tag.selected}
                          onChange={(event) => {
                            let oldRuleCategoryTags = ruleTags[category];
                            let currentTag = oldRuleCategoryTags.find((tagObj) => tagObj.id === tag.id);
                            if (currentTag) currentTag.selected = event.target.checked;
                            setRuleTags({ ...ruleTags, [category]: oldRuleCategoryTags });
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="col-12">
              <hr className="hr-solid-line" />
              <div className="text-right" style={{ paddingBottom: "20px" }}>
                <span className="closeBtn" onClick={() => setShow((s) => !s)}>
                  {/* Cancel */}
                  {t(I18N_MODULE_KEYS["content.button.cancel"])}
                </span>
                <span className="updateBtn" onClick={updateRuleHandler}>
                  {loadingUpdate && <LoadingOutlined style={{ marginRight: "5px" }} />}
                  {/* Update */}
                  {t(I18N_MODULE_KEYS["content.button.update"])}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RuleComponent;
