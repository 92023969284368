import { FC, useMemo ,useState} from "react";
import { Avatar, Button, Card, Col, Row, Typography ,Space,Checkbox,Input,Image,Tooltip, Tag,Spin} from "antd";
import { FolderOutlined,SearchOutlined } from "@ant-design/icons";

import { setActionDrawerDataAction } from "../../../../Components/ActionDrawer/State";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useEffect } from "react";
import { getBilling, getBillingMonthly, getBillingMonthlyReport, getBillingReport } from "../../../../Store/requests/_semanticSearchRequests";
import { SEMANTIC_SEARCH_MODULES } from "../Core/constants";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import './_Usage.css';
const { Paragraph } = Typography;

export const Billing: FC = () => {
  const { i18n, t } = useTranslation();
  const [billing,setBilling]=useState<any[]>([])
  const [billingMonthly,setBillingMonthly]=useState<any[]>([])
  const [isloadingBillingPage,setisloadingBillingPage]=useState<boolean>(false);
  const {
    semanticEncodedSearchid,
    searchQuery, 
    encodedSearchQuery,
    advancedFilter,
    refetchList,
    advancedFilterList,
    advancedFilterStatus,
    cardData,
    semanticSearchid,
    cardDataconst, Format,kvalue ,activeModule,regeneratestatus
   
  
  } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch(); 
  const [isInProgress,setisInProgress]=useState(false);
  const [isInProgressMonth,setisInProgressMonth]=useState(false);
  const [totalcost,setTotalCost]=useState(0);

  useEffect(() => {
         
    const getBillingDetails = async () => {
        const res = await getBilling()
        if(!res){
           setBilling([])
           setisloadingBillingPage(false);
            return;
        }
        const resp = await getBillingMonthly()
        if(!resp){
         setBillingMonthly([]);
         setisloadingBillingPage(false);
         setTotalCost(0);
         return;
        }
         setBillingMonthly(resp?.data);
        setBilling(res?.data)
        setTotalCost(resp?.total_cost);
        setisloadingBillingPage(false)
      }
    console.log("calls insights history")
    if(activeModule===SEMANTIC_SEARCH_MODULES.USAGE_BILLINGS_DETAILS)
 {setisloadingBillingPage(true);
  getBillingDetails() }
  }, [activeModule]);
  const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName || "file"); // Set the filename

      // Trigger the download
      link.click();
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.billing.notification"])))
      // Clean up
      window.URL.revokeObjectURL(link.href);
      link.remove();
      setisInProgressMonth(false)
      setisInProgress(false)

    } catch (error) {
      setisInProgressMonth(false)
      setisInProgress(false)

      console.error("Error downloading file:", error);
    }
  };

  const handledownload = async()=>{
setisInProgress(true);
const res = await getBillingReport()
console.log(res);
downloadFile(res?.data,res?.data)
  }
  const handledownloadmonthly = async()=>{
    setisInProgressMonth(true);
const res=await getBillingMonthlyReport()
console.log(res)
downloadFile(res?.data,res?.data)

  }
    return (
      <>
      {isloadingBillingPage ? (<><div className="spinner-container"><Spin></Spin></div></>):(
      <Col span={24}>
        <Card
          style={{
            width: "100%",
            boxShadow: "0px 0px 10px #0000001F",
            minHeight: "550px",
            opacity: "1",
          }}
        >
            <div style={{background:'#F3F6FA',padding:'10px',borderRadius:'4px',minHeight:'500px'}}>
             <Row gutter={16} style={{}}>
          <Col span={13} style={{}}>
            <div style={{display:'flex'}}>
           <div style={{fontWeight:'600',fontSize:'16px',paddingLeft:'5px',color:'#66676B'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.apiconsumption"])} |</div>
           <div style={{fontWeight:'600',fontSize:'16px',paddingLeft:'5px',color:'#034EA2',cursor:'pointer'}} onClick={handledownload}>{t(I18N_MODULE_KEYS["semantic.search.billing.downloadreport"])}</div>
           {isInProgress? (<div style={{fontWeight:'600',paddingLeft:'10px'}}><Spin></Spin></div>):(<></>)}
             </div>
           <Row style={{padding:'10px'}}>
            <Col span={6} style={{paddingLeft:'10px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.api"])}</Col>
            <Col  span={6} style={{paddingLeft:'10px' ,fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.units"])}</Col>
            <Col span={6} style={{paddingLeft:'10px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.unitprice"])}</Col>
            <Col span={6} style={{paddingLeft:'10px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.cost"])}</Col>
           </Row>
           <div style={{background:'white',margin:'12px',borderRadius:'10px'}}>
           <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.chat"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
      
           { billing[0] ? billing[0].object.length>0 ? billing[0].object.map((item: any) => (
     <Row style={{  }}>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.model:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_tokens:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.unit_price:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_cost:0}</Col>
   </Row>
  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
          
            </div>
           </div>
           <div style={{background:'white',margin:'12px',borderRadius:'10px'}}>
           <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.floatingsidebar.search"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
            { billing[1] ? billing[1].object.length>0 ? billing[1].object.map((item: any) => (
     <Row style={{  }}>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.model:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_tokens:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.unit_price:0}</Col>
     <Col span={6} style={{ paddingLeft: '5px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_cost:0}</Col>
   </Row>
  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
        
            </div>
           </div>
           <div style={{background:'white',margin:'12px',borderRadius:'10px'}}>
            <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.embeddings"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
          
         
            { billing[2] ? billing[2].object.length>0 ? billing[2].object.map((item: any) => (
     <Row style={{  }}>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.model:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_tokens:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.unit_price:0}</Col>
     <Col span={6} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{item?item.total_cost:0}</Col>
   </Row>
  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
            
           
            </div>
           </div>
          </Col>
          <Col span={11}>
          <div style={{display:'flex'}}>
           <div style={{fontWeight:'600',fontSize:'16px',paddingLeft:'5px',color:'#66676B'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.monthlybilling"])} | </div>
           <div style={{fontWeight:'600',fontSize:'16px',paddingLeft:'5px',color:'#034EA2',cursor:'pointer'}} onClick={handledownloadmonthly}>{t(I18N_MODULE_KEYS["semantic.search.billing.downloadreport"])}</div>
           {isInProgressMonth ? (<div style={{fontWeight:'600',paddingLeft:'10px'}}><Spin></Spin></div>):(<></>)}
           </div>
           <Row style={{padding:'10px'}}>
            <Col span={9} style={{paddingLeft:'10px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.costheads"])}</Col>
            <Col span={9} style={{paddingLeft:'10px',fontWeight:'600',fontSize:'14px',color:'#66676B',paddingTop:'5px'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.amount"])}</Col>
           </Row>
           <div style={{background:'white',margin:'12px',borderRadius:'10px',width:'72%'}}>
           <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.chat"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
          
{ billingMonthly[0] ? billingMonthly[0].object.length>0 ? billingMonthly[0].object.map((item: any) => (
    <Row style={{  }}>
       
    <Col span={12} style={{paddingLeft:'10px',paddingTop:'5px',color:'#66676B',fontWeight:'600',paddingBottom:'5px'}}>{item?item.model:0}</Col>
            <Col span={12} style={{paddingLeft:'10px',paddingTop:'5px',color:'#66676B',fontWeight:'600',paddingBottom:'5px'}}>{item?item.total_cost:0}</Col></Row>

  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
         
           
          
          
            </div>
           </div>
           <div style={{background:'white',margin:'12px',borderRadius:'10px',width:'72%'}}>
           <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.floatingsidebar.search"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
            { billingMonthly[1] ? billingMonthly[1].object.length>0 ? billingMonthly[1].object.map((item: any) => (
    <Row style={{  }}>
       
    <Col span={12} style={{paddingLeft:'10px',paddingTop:'5px',color:'#66676B',fontWeight:'600',paddingBottom:'5px'}}>{item?item.model:0}</Col>
            <Col span={12} style={{paddingLeft:'10px',paddingTop:'5px',color:'#66676B',fontWeight:'600',paddingBottom:'5px'}}>{item?item.total_cost:0}</Col></Row>

  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}
            </div>
           </div>
           <div style={{background:'white',margin:'12px',borderRadius:'10px',width:'72%'}}>
            <div style={{padding:'10px'}}>
            <Tag style={{borderRadius:'20px',fontWeight:'600',color:'#66676B',fontSize:'12px',border:'none',background:'#F4F4F4'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.embeddings"])}</Tag>
            </div>
            <div style={{padding:'5px'}}>
            { billingMonthly[2] ? billingMonthly[2].object.length>0 ? billingMonthly[2].object.map((item: any) => (
    <Row style={{  }}>
       
    <Col span={12} style={{paddingLeft:'10px',paddingTop:'5px',color:'#66676B',fontWeight:'600',paddingBottom:'5px'}}>{item?item.model:0}</Col>
            <Col span={12} style={{paddingLeft:'10px',paddingTop:'5px',color:'#66676B',fontWeight:'600',paddingBottom:'5px'}}>{item?item.total_cost:0}</Col></Row>

  )):(<><Row style={{  }}>
    <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
   </Row></>):(<>
   <Row style={{  }}>
     <Col span={12} style={{ paddingLeft: '10px', paddingTop: '5px', color: '#66676B', fontWeight: '600', paddingBottom: '5px' }}>{t(I18N_MODULE_KEYS["semantic.search.billing.notavailable"])}</Col>
    </Row>
  </>)
}       
            </div>
           </div>
           <div style={{display:'flex',justifyContent:'center'}}><Tag style={{borderRadius:'20px',fontWeight:'600',color:'white',fontSize:'14px',border:'none',background:'#E32A34'}}>{t(I18N_MODULE_KEYS["semantic.search.billing.totalamount"])} | {totalcost} $</Tag></div>
          </Col>
        </Row></div> 

          {/* Your content goes here */}
         
          
        </Card> 
      </Col>)}
      </>
    );
  }; 