import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enLocale, esLocale, hiLocale } from "./locales";
import { zhLocale } from "./locales/chinese/_zh";

i18n
  // .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // lng: getCurrentLang(),
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        translation: {
          ...enLocale
        }
      },
      es: {
        translation: {
          ...esLocale
        }
      },
      zh: {
        translation: {
          ...zhLocale
        }
      },
      hi: {
        translation: {
          ...hiLocale
        }
      }
    }
  });

export default i18n;
