import { FC, useMemo, useState, useRef, useEffect } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { AlignLeftOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import TextArea from "antd/es/input/TextArea";

export const LiteratureReferenceWrapper: FC = () => {
  const { cardDataCollection, proModeScreen, loadingForReportGeneration } = useSelector((state: any) => state.semanticSearchReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(cardDataCollection);
  }, [cardDataCollection]);

  return (
    <>
      <Col span={24}>
        <Card style={{ height: "575px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "5px", color: "#949494" }}>
            <Image src={PROJECT_ICONS.TEXTREFERENCE} />
            <p>Enter the key message to</p>
            <p>be referenced</p>
          </div>
        </Card>
      </Col>
    </>
  );
};
