import { CONTENT_AI_CU_TABS, CONTENT_AI_MODULES, SELECTED_TAGS_INITIAL_STATE } from "../../Pages/Content AI/Core/constants";
import { ContentAITags, SelectedTagsType } from "../../Pages/Content AI/Core/types";
import { SET_LOGIN_DETAILS, SET_USER_DETAILS } from "../constants/_authConstants";
import {
  SET_ACTIVE_MODULE,
  SET_CURRENT_ACTIVE_STEP,
  SET_CU_SELECTED_TAB,
  SET_NAVIGATED_FLAG,
  SET_PROMPT_ID,
  SET_REFINED_RESPONSE_DATA,
  SET_RESPONSE_LOADER_FLAG,
  SET_SELECTED_TAGS,
  SET_TAGS_DATA
} from "../constants/_contentAIConstants";

type IntialState = {
  activeModule: string;
  tags: ContentAITags;
  selectedTags: SelectedTagsType;
  cuSelectedTab: string;
  navigated: boolean;
};

const initialState: IntialState = {
  activeModule: CONTENT_AI_MODULES.CONTENT_CREATOR,
  tags: { region: [], brand: [], channel: [] },
  selectedTags: SELECTED_TAGS_INITIAL_STATE,
  cuSelectedTab: CONTENT_AI_CU_TABS.CONTENT_UNIVERSE,
  navigated: false
};

export default function contentAIReducer(state: IntialState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_MODULE: {
      return {
        ...state,
        activeModule: payload
      };
    }
    case SET_CURRENT_ACTIVE_STEP: {
      return {
        ...state,
        currentActiveStep: payload
      };
    }
    case SET_TAGS_DATA: {
      return {
        ...state,
        tags: payload
      };
    }
    case SET_PROMPT_ID: {
      return {
        ...state,
        promptID: payload
      };
    }
    case SET_REFINED_RESPONSE_DATA: {
      return {
        ...state,
        refinedResponseData: payload
      };
    }
    case SET_SELECTED_TAGS: {
      return {
        ...state,
        selectedTags: payload
      };
    }
    case SET_NAVIGATED_FLAG: {
      return {
        ...state,
        navigated: payload
      };
    }
    case SET_RESPONSE_LOADER_FLAG: {
      return {
        ...state,
        responseLoading: payload
      };
    }
    case SET_CU_SELECTED_TAB: {
      return {
        ...state,
        cuSelectedTab: payload
      };
    }
    default: {
      return state;
    }
  }
}
