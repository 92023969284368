import { resolve } from "path";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";

export const globalConstant = Object.freeze({
  INTERNAL_SERVER_ERROR: `${I18N_MODULE_KEYS["content.text.internalservererror"]}`,
  UNAUTHORIZED: "Unauthorized",
  NOT_FOUND: "Not Found",
  INVALID_CREDENTIALS: "Invalid credentials! Please Try Again.",
  USER_NOT_FOUND: "User not found!",
  USER_ALREADY_PRESENT: "User already present",
  DATA_NOT_SAVED: "Data not saved!",
  SUCCESSFUL_SIGNUP: "Signup Successfull. Please check verification mail.",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  SOMETHING_WENT_WRONG: `${I18N_MODULE_KEYS["content.text.somethingwentwrong"]}`
});

export const networkErrorMsgs = Object.freeze({
  "400": `${I18N_MODULE_KEYS["content.text.error.400"]}`,
  "401": `${I18N_MODULE_KEYS["content.text.error.401"]}`,
  "402": `${I18N_MODULE_KEYS["content.text.error.402"]}`,
  "403": `${I18N_MODULE_KEYS["content.text.error.403"]}`,
  "404": `${I18N_MODULE_KEYS["content.text.error.404"]}`,
  "405": `${I18N_MODULE_KEYS["content.text.error.405"]}`,
  "406": `${I18N_MODULE_KEYS["content.text.error.406"]}`,
  "407": `${I18N_MODULE_KEYS["content.text.error.407"]}`,
  "408": `${I18N_MODULE_KEYS["content.text.error.408"]}`,
  "409": `${I18N_MODULE_KEYS["content.text.error.409"]}`,
  "410": `${I18N_MODULE_KEYS["content.text.error.410"]}`,
  "411": `${I18N_MODULE_KEYS["content.text.error.411"]}`,
  "412": `${I18N_MODULE_KEYS["content.text.error.412"]}`,
  "413": `${I18N_MODULE_KEYS["content.text.error.413"]}`,
  "414": `${I18N_MODULE_KEYS["content.text.error.414"]}`,
  "415": `${I18N_MODULE_KEYS["content.text.error.415"]}`,
  "416": `${I18N_MODULE_KEYS["content.text.error.416"]}`,
  "417": `${I18N_MODULE_KEYS["content.text.error.417"]}`,
  "500": `${I18N_MODULE_KEYS["content.text.error.500"]}`,
  "501": `${I18N_MODULE_KEYS["content.text.error.501"]}`,
  "502": `${I18N_MODULE_KEYS["content.text.error.502"]}`,
  "503": `${I18N_MODULE_KEYS["content.text.error.503"]}`,
  "504": `${I18N_MODULE_KEYS["content.text.error.504"]}`,
  "505": `${I18N_MODULE_KEYS["content.text.error.505"]}`,
  "511": `${I18N_MODULE_KEYS["content.text.error.511"]}`
});

// export const globalConstant = Object.freeze({
//   INTERNAL_SERVER_ERROR: "Internal Server Error",
//   UNAUTHORIZED: "Unauthorized",
//   NOT_FOUND: "Not Found",
//   INVALID_CREDENTIALS: "Invalid credentials! Please Try Again.",
//   USER_NOT_FOUND: "User not found!",
//   USER_ALREADY_PRESENT: "User already present",
//   DATA_NOT_SAVED: "Data not saved!",
//   SUCCESSFUL_SIGNUP: "Signup Successfull. Please check verification mail.",
//   SUCCESS: "SUCCESS",
//   PENDING: "PENDING",
//   SOMETHING_WENT_WRONG: "Something went wrong"
// });

export const MODULE_PATHNAME = Object.freeze({
  LOGIN: "login",
  SIGNUP: "signup",
  VERIFIEDPAGE: "verifiedpage",
  FORGOT: "forgotpassword",
  DASHBOARD: "dashboard",
  CONTENT_HISTORY: "content-history",
  CONTENT_TRANSCREATION: "content-transcreation",
  EXPLORE_GENERATION: "explore-generations",
  SETTINGS: "settings",
  SEMANTIC_SEARCH: "next-gist",
  CONTENT_AI: "content-ai",
  USE_CASES: "use-cases",
  INTELLIGENT_ASSISTANT: "intelligent-assistant",
  FIELD_COPILOT: "field-copilot",
  HYPER_PERSONALISATION: "hyper-personalisation",
  SENTIMENT_ANALYSIS: "sentiment-analysis",
  CONTENT_TRANSLATION: "content-translation",
  KOL_VIDEO: "kol-video",
  GEN_AI_DASHBOARD_ASSISTANT: "gen-ai-dashboard-assistant",
  PATIENT_JOURNEY_MAPPING: "patient-journey-mapping",
  TRANSLATION_LOCALISATION: "translation-localisation",
  PERSONALIZED_VIDEO: "personalized-video",
  CONTENT_CREATION: "content-creation",
  TRANSLATION_MODULE: "translation-module"
});

export const ENUM_ROUTE_URLS = Object.freeze({
  LOGIN: "/login",
  SIGNUP: "/signup",
  VERIFIEDPAGE: "/verifiedpage",
  FORGOT: "/forgotpassword",
  DASHBOARD: "/dashboard",
  CONTENT_HISTORY: "/content-history",
  CONTENT_TRANSLATION: "/content-translation",
  CONTENT_TRANSCREATION: "/content-transcreation",
  EXPLORE_GENERATION: "/explore-generations",
  SETTINGS: "/settings",
  SEMANTIC_SEARCH: "/next-gist",
  CONTENT_AI: "/content-ai",
  USE_CASES: "/use-cases",
  INTELLIGENT_ASSISTANT: "/intelligent-assistant",
  FIELD_COPILOT: "/field-copilot",
  HYPER_PERSONALISATION: "/hyper-personalisation",
  SENTIMENT_ANALYSIS: "/sentiment-analysis",
  KOL_VIDEO: "/kol-video",
  GEN_AI_DASHBOARD_ASSISTANT: "/gen-ai-dashboard-assistant",
  PATIENT_JOURNEY_MAPPING: "/patient-journey-mapping",
  TRANSLATION_LOCALISATION: "/translation-localisation",
  PERSONALIZED_VIDEO: "/personalized-video",
  CONTENT_CREATION: "/content-creation",
  IDETAIL_EXTRACT: "/idetail-extract",
  TRANSLATION_MODULE: "/translation-module"
});

/* export const ENUM_ROUTE_URLS: { [key: string]: string } = {};
Object.keys(MODULE_PATHNAME).forEach((module) => (ENUM_ROUTE_URLS[module] = "/" + MODULE_PATHNAME[module as keyof typeof MODULE_PATHNAME]));
 */

export const ENUM_TOAST_ALERT = Object.freeze({
  SUCCESS: "success",
  ERROR: "error"
});

export const OBJ_TOASTIFY_BASE_STYLING = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined
};

export const ENUM_API_STATUS = Object.freeze({
  SUCCESS: "success",
  ERROR: "error"
});

export const ENUM_MODAL_DRAWER_TYPE = Object.freeze({
  NOTHING: 0,
  ADD_MODAL: 1,
  EDIT_MODAL: 2,
  VIEW_MODAL: 3
});

export const ENUM_FORM_ACTION = Object.freeze({
  EDIT: "EDIT",
  ADD: "ADD",
  VIEW: "VIEW",
  DELETE: "DELETE",
  STATUS: "STATUS",
  OTHERS: "OTHERS"
});

export const nameInitialStates: Array<string> = ["primary", "success", "danger", "warning", "info"];

export const ENUM_STEPPER_STATUS = Object.freeze({
  PENDING: "pending",
  CURRENT: "current",
  COMPLETED: "completed"
});

export const CONTENT_TRANSCREATION_STEPS = Object.freeze({
  STEP_0: 0,
  "STEP_0.5": 0.5,
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 2, //3,
  STEP_4: 3 //4
});

export const ENUM_CONTENT_TRANSCREATION_BUTTON_TXT = Object.freeze({
  GO_BACK: `${I18N_MODULE_KEYS["content.button.goback"]}`,
  LETS_BEGIN: `${I18N_MODULE_KEYS["content.transcreation.footer.button.getstarted"]}`,
  GET_STARTED: `${I18N_MODULE_KEYS["content.transcreation.footer.button.getstarted"]}`,
  CONERT_TO_HTML: "Convert To HTML",
  // CONERT_TO_HTML: "Convert To HTML",
  CREATE_STORY_BOARD: `${I18N_MODULE_KEYS["content.transcreation.footer.button.createstoryboard"]}`,
  // PROCEED_IMAGES: "Proceed to Image Creation",
  PROCEED_IMAGES: `${I18N_MODULE_KEYS["content.button.proceed"]}`,
  // GENERATE_IMAGES: "Generate Images",
  GENERATE_IMAGES: `${I18N_MODULE_KEYS["content.button.next"]}`,
  START_VIDEO_CREATION: "Start Video Creation",
  CHOOSE_TEMPLATE_EMAIL: `${I18N_MODULE_KEYS["content.transcreation.footer.button.choosetemplate"]}`, //For Email
  CHOOSE_AVATAR_EMAIL: `${I18N_MODULE_KEYS["content.transcreation.footer.button.chooseavatar"]}`, //For Email

  CHOOSE_TEMPLATE: `${I18N_MODULE_KEYS["content.transcreation.footer.button.chooseavatar"]}`, //For Blogs
  CHOOSE_AVATAR: "Proceed to Storyboard", //For Blogs

  PROCEED_TO_VIDEOS: "Proceed To Videos",
  GENERATE_VIDEO: `${I18N_MODULE_KEYS["content.transcreation.successful.title.generatevideo"]}`,
  GO_TO_DASHBOARD: `${I18N_MODULE_KEYS["content.text.gotodashboard"]}`,
  PROCEED_TO_STORYBOARD: "Proceed to Storyboard",
  ORDER_VIDEO: `${I18N_MODULE_KEYS["content.transcreation.footer.button.ordervideo"]}`,
  VIEW_SUMMARY: `${I18N_MODULE_KEYS["content.transcreation.footer.button.viewsummary"]}`
});

// export const ENUM_CONTENT_TRANSCREATION_BUTTON_TXT = Object.freeze({
//   GO_BACK: `${getTranslatedText(I18N_MODULE_KEYS["content.button.goback"])}`,
//   LETS_BEGIN: `${getTranslatedText(I18N_MODULE_KEYS["content.transcreation.footer.button.getstarted"])}`,
//   GET_STARTED: `${getTranslatedText(I18N_MODULE_KEYS["content.transcreation.footer.button.getstarted"])}`,
//   CONERT_TO_HTML: "Convert To HTML",
//   // CONERT_TO_HTML: "Convert To HTML",
//   CREATE_STORY_BOARD: `${getTranslatedText(I18N_MODULE_KEYS["content.transcreation.footer.button.createstoryboard"])}`,
//   // PROCEED_IMAGES: "Proceed to Image Creation",
//   PROCEED_IMAGES:`${getTranslatedText(I18N_MODULE_KEYS["content.button.proceed"])}`,
//   // GENERATE_IMAGES: "Generate Images",
//   GENERATE_IMAGES: `${getTranslatedText(I18N_MODULE_KEYS["content.button.next"])}`,
//   START_VIDEO_CREATION: "Start Video Creation",
//   CHOOSE_TEMPLATE_EMAIL: "Choose Template", //For Email
//   CHOOSE_AVATAR_EMAIL: "Choose Avatar", //For Email

//   CHOOSE_TEMPLATE: "Choose Avatar", //For Blogs
//   CHOOSE_AVATAR: "Proceed to Storyboard", //For Blogs

//   PROCEED_TO_VIDEOS: "Proceed To Videos",
//   GENERATE_VIDEO: "Generate Video",
//   GO_TO_DASHBOARD: "Go to Dashboard",
//   PROCEED_TO_STORYBOARD: "Proceed to Storyboard",
//   ORDER_VIDEO: "Order Video",
//   VIEW_SUMMARY: "View Summary"
// });
// export const ENUM_CONTENT_TRANSCREATION_BUTTON_TXT = Object.freeze({
//   GO_BACK: "Go Back",
//   LETS_BEGIN: "Lets Begin",
//   GET_STARTED: "Get Started",
//   CONERT_TO_HTML: "Convert To HTML",
//   // CONERT_TO_HTML: "Convert To HTML",
//   CREATE_STORY_BOARD: "Create Story Board",
//   // PROCEED_IMAGES: "Proceed to Image Creation",
//   PROCEED_IMAGES: "Proceed",
//   // GENERATE_IMAGES: "Generate Images",
//   GENERATE_IMAGES: "Next",
//   START_VIDEO_CREATION: "Start Video Creation",
//   CHOOSE_TEMPLATE_EMAIL: "Choose Template", //For Email
//   CHOOSE_AVATAR_EMAIL: "Choose Avatar", //For Email

//   CHOOSE_TEMPLATE: "Choose Avatar", //For Blogs
//   CHOOSE_AVATAR: "Proceed to Storyboard", //For Blogs

//   PROCEED_TO_VIDEOS: "Proceed To Videos",
//   GENERATE_VIDEO: "Generate Video",
//   GO_TO_DASHBOARD: "Go to Dashboard",
//   PROCEED_TO_STORYBOARD: "Proceed to Storyboard",
//   ORDER_VIDEO: "Order Video",
//   VIEW_SUMMARY: "View Summary"
// });

export const CT_DASHBOARD_STATUS = Object.freeze({
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  FAILED: "FAILED"
});

export const CT_PROMPTS = Object.freeze({
  UPLOAD_EMAIL: "upload_email",
  HTML_CONTENT: "html_content",
  STORYBOARD: "storyboard"
});

export const ENUM_ACCEPT_LANGUAGE = Object.freeze({
  ENGLISH: "en-US",
  SPANISH: "es-ES",
  CHINESE: "zh-CN",
  HINDI: "hi-IN"
});
