import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";

const ComingSoon: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <img src={PROJECT_ICONS.INDEGENE_COMING_SOON_IMG} width="600px" />
    </div>
  );
};

export { ComingSoon };
