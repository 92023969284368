import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Lottie from "lottie-react";
import { Row, Col, Form, Typography, Input, Button, message } from "antd";

import { Authorizer } from "../../Services/_authorizer";
import { UserOutlined } from "@ant-design/icons";

import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";

import _localStorageService from "../../Services/_localStorageService";

import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";

import "../../Assets/Css/Authentication/login.css";

const { Title } = Typography;

const Forgot = () => {
  const dispatch = useDispatch();
  const authorizer = new Authorizer();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();

  const onForgetPassword = async () => {
    // try {
    //     const values = await form.validateFields(); // Validate form fields
    //     const { mail: email, password ,confirmpassword:confirm_password} = values;

    //     console.log(email);
    //    await authorizer.doSignup({ email, password , confirm_password},dispatch);
    //    navigate("/login")
    // }
    // catch{

    // }
    try {
      const values = await form.validateFields(); // Validate form fields
      const { mail: email } = values;

      console.log(email);
      const signupMessage = await authorizer.doForgot({ email }, dispatch);

      if (signupMessage === "Verification email has been sent. Please check your inbox") {
        navigate(ENUM_ROUTE_URLS.VERIFIEDPAGE); // Navigate to a specific route
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  //   const onLoginHandler = async () => {
  // try {
  //   const email = form.getFieldValue("mail");
  //   const password = form.getFieldValue("password");
  //   console.log(email);
  //   authorizer.doLogin({ email, password },()=> {
  //     navigate(ENUM_ROUTE_URLS.DASHBOARD);
  //   });
  // } catch (error) {
  //   console.error("Form validation error:", error);
  // }
  //   };

  useEffect(() => {
    if (location.state && location.state.from === "/dashboard") {
      message.success("Successfully logged out!");
    }
  }, [location]);

  return (
    <>
      <Form labelAlign="left" labelCol={{ span: 24 }} form={form} className="authentication container1">
        <Row gutter={16}>
          <Col span={12} className="section1">
            <Row className="animcontainer">
              <Col className="animation">
                <Lottie id="email" animationData={PROJECT_ICONS.EMAIL_ANIMATION} loop={true} />
              </Col>
              <Col className="animation-text-wrapper">
                <p>{t(I18N_MODULE_KEYS["content.login.text.customizeyouremailusingai"])}</p>
                <p>{t(I18N_MODULE_KEYS["content.login.text.convertyouremailintoHTMLCraftImagesand"])}</p>
                <p>{t(I18N_MODULE_KEYS["content.login.text.weavevideostoleavealasting"])}</p>
              </Col>
            </Row>
          </Col>
          <Col span={12} className="login-container">
            <Col className="form-conatiner">
              <Col className="second-content">
                <Title level={3}>{t(I18N_MODULE_KEYS["content.forgot.text.resetpassword"])}</Title>
              </Col>
              <Form.Item
                name="mail"
                label={`${t(I18N_MODULE_KEYS["content.login.text.email"])}`}
                rules={[
                  { required: true, message: `${t(I18N_MODULE_KEYS["content.login.text.emailisrequired"])}` },
                  {
                    type: "email",
                    // message: "Please enter a valid email address"
                    message: `${t(I18N_MODULE_KEYS["content.login.text.pleaseenteravalidemailaddress"])}`
                  }
                ]}
              >
                <Input size="large" placeholder="Enter your email" prefix={<UserOutlined />} />
              </Form.Item>
              <Button onClick={onForgetPassword} type="primary" block size="large">
                {t(I18N_MODULE_KEYS["content.button.proceed"])}
              </Button>
              <Button
                style={{ paddingTop: "10px" }}
                type="link"
                block
                size="small"
                onClick={() => {
                  navigate(ENUM_ROUTE_URLS.LOGIN);
                }}
              >
                {/* Click to Login */}
                {t(I18N_MODULE_KEYS["content.forgot.text.clicktologin"])}
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </>
  );
};

export default Forgot;
