const UC_TAB_IDS = {
  DESCRIPTION: "description",
  BUSINESS_VALUE_PROPOSITION: "business-value-proposition",
  RETURN_ON_INVESTMENT: "return-on-investment",
  SUCCESS_METRICS: "success-metrics",
  FUTURE_POTENTIAL: "future-potential",
  CUSTOMISATION: "customisation",
  TECHNOLOGY_USED: "technology-used",
};

export { UC_TAB_IDS };
