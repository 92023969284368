import { Col, Button } from "antd";

import Icon from "./Icon";
import { useDispatch } from "react-redux";
import { setActiveObjectModifier } from "../../../../../Store/actions/_contentTranscreationActions";

const backIcon = <Icon name="back" />;
const frontIcon = <Icon name="front" />;
const forwardIcon = <Icon name="forward" />;
const backwardIcon = <Icon name="backward" />;

const style = {
  col: { display: "flex", justifyContent: "space-between" },
  toBack: { fontSize: "9px", margin: 0 },
  backward: { fontSize: "9px", margin: 0 },
  forward: { fontSize: "9px", margin: 0 },
  toFront: { fontSize: "9px", margin: 0 }
};

export const LayerControl = () => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setActiveObjectModifier({ change: "layering", action: "sendToBack" }));
  };

  const handleBackwards = () => dispatch(setActiveObjectModifier({ change: "layering", action: "sendBackwards" }));

  const handleForward = () => dispatch(setActiveObjectModifier({ change: "layering", action: "bringForward" }));

  const handleFront = () => dispatch(setActiveObjectModifier({ change: "layering", action: "bringToFront" }));

  return (
    <Col span={18} style={style.col}>
      <Button icon={backIcon} size="small" className="btn-layering" onClick={handleBack}>
        <span style={style.toBack}>To Back</span>
      </Button>
      <Button icon={backwardIcon} size="small" className="btn-layering" onClick={handleBackwards}>
        <span style={style.backward}>Backward</span>
      </Button>
      <Button icon={forwardIcon} size="small" className="btn-layering" onClick={handleForward}>
        <span style={style.forward}>Forward</span>
      </Button>
      <Button icon={frontIcon} size="small" className="btn-layering" onClick={handleFront}>
        <span style={style.toFront}>To Front</span>
      </Button>
    </Col>
  );
};
