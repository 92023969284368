import { FC } from "react";
import { LLMBaseComponent } from "../Components/LLM";
import { CONTENT_AI_CU_TABS, CONTENT_AI_MODULES } from "../Core/constants";
import { Tabs } from "antd";
import { CUUploadScreen } from "../Components/Content Universe/Upload Screen/CUUploadScreen";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCUSelectedTabAction } from "../../../Store/actions/_contentAIActions";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const ContentUniverse: FC<{ setGetResultTimer: Function; clearGetResultTimer: Function }> = ({ setGetResultTimer, clearGetResultTimer }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const contentAIModule = CONTENT_AI_MODULES.CONTENT_UNIVERSE.toLowerCase();

  const { cuSelectedTab } = useSelector((state: any) => state?.contentAIReducer);

  const onChange = (key: string) => {
    //console.log(key);
    dispatch(setCUSelectedTabAction(key));
  };

  const tabItems = [
    {
      // label: "CONTENT UNIVERSE",
      label: `${t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse"])}`,
      key: CONTENT_AI_CU_TABS.CONTENT_UNIVERSE,
      children: (
        <LLMBaseComponent
          contentAIModule={contentAIModule}
          contentAIModuleName="Content Universe"
          setGetResultTimer={setGetResultTimer}
          clearGetResultTimer={clearGetResultTimer}
        />
      )
    },
    {
      // label: "UPLOAD",
      label: `${t(I18N_MODULE_KEYS["content.button.upload"])}`,
      key: CONTENT_AI_CU_TABS.CU_UPLOAD,
      children: <CUUploadScreen />
    }
  ];

  return (
    <Tabs
      defaultActiveKey={cuSelectedTab}
      className="cu-tabs"
      onChange={onChange}
      type="card"
      items={tabItems}
      tabBarStyle={{ margin: "0 0 0 1rem" }}
    />
  );
};
