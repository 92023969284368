import { FC } from "react";
import { Button, Card, Flex, Typography, Image } from "antd";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";

const { Text } = Typography;

type IProps = {
  filename: string;
  cancelPDFUpload: () => void;
};

export const HeaderTab: FC<IProps> = ({ filename, cancelPDFUpload }) => {
  const { t } = useTranslation();
  return (
    <Card bodyStyle={{ padding: "10px" }}>
      <Flex justify="space-between">
        <Flex gap="small" align="center" justify="center">
          <Image src={PROJECT_ICONS.IDX_FILE_ICON} preview={false} style={{ height: "17px" }} />
          <Text style={{ fontSize: "13px", fontWeight: "700" }}>{filename}</Text>
        </Flex>

        <Flex gap="small">
          <Button key="submit" type="link" onClick={cancelPDFUpload} style={{ color: "rgb(5, 70, 165)" }}>
            {t(I18N_MODULE_KEYS["content.button.cancel"])}
          </Button>
          <Button key="submit" type="primary" disabled>
            {t(I18N_MODULE_KEYS["idetailextract._CoreClaimMain._Headertab.Create Copy"])}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
