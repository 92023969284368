import { Button } from "antd";
import { FC } from "react";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { HeaderProps } from "./Core/types";

import { useNavigate } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutSyncAction } from "../../Store/actions/_commonActions";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { getDefaultLayout } from "../../Utils";
import { siteConfig } from "../../Services";
import { setNavigatedFlagAction } from "../../Store/actions/_contentAIActions";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { resetTMData } from "../Translation Module/Core/constant";

const selectedTabCSS = {
  fontWeight: "bold",
  color: "#0546A5",
  background: "#DCEAFF"
};

const normalTabCSS = {
  color: "#000"
};

const Header: FC<HeaderProps> = ({ moduleId, module, tabs, selectedTab, setSelectedTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  const handleNavigation = (route: string) => {
    const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);

    navigate(route);

    switch (route) {
      case ENUM_ROUTE_URLS.CONTENT_TRANSCREATION: {
        dispatch(
          setLayoutSyncAction({
            ...objNewLayoutSync,
            CONTENT_TRANSCREATION: true
          })
        );
        siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
        break;
      }
      case ENUM_ROUTE_URLS.SEMANTIC_SEARCH: {
        dispatch(setLayoutSyncAction({ ...objNewLayoutSync, SEMANTIC_SEARCH: true }));

        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
        break;
      }
      case ENUM_ROUTE_URLS.CONTENT_AI: {
        dispatch(setLayoutSyncAction({ ...objNewLayoutSync, CONTENT_AI: true }));
        dispatch(setNavigatedFlagAction(true));

        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;
        break;
      }
      case ENUM_ROUTE_URLS.TRANSLATION_MODULE: {
        dispatch(setLayoutSyncAction({ ...objNewLayoutSync, TRANSLATION_MODULE: true }));
        dispatch(setNavigatedFlagAction(true));

        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_TRANSLATION_MODULE;

        resetTMData(dispatch);
        break;
      }

      default:
        dispatch(
          setLayoutSyncAction({
            ...objNewLayoutSync,
            CONTENT_TRANSCREATION: true
          })
        );
        siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        border: "1px solid",
        borderColor: "#d9d9d9",
        borderRadius: "0.5rem",
        alignItems: "center",
        gap: "0.5rem",
        padding: "0.5rem",
        justifyContent: "space-between",
        background: "white",
        marginBottom: "16px"
      }}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          gap: "0.5rem"
        }}
      >
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            onClick={() => setSelectedTab(tab.id)}
            type="default"
            style={{
              ...(tab.id === selectedTab ? selectedTabCSS : normalTabCSS),
              padding: "1.5rem 1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none"
            }}
          >
            {t(tab.tabName)}
          </Button>
        ))}
      </div>
      <Button
        onClick={() => handleNavigation("/" + moduleId)}
        style={{
          background: " #002382",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
          padding: "1.5rem",
          alignItems: "center"
        }}
      >
        {/* Start {module} */}
        {`${t(I18N_MODULE_KEYS["content.button.start"])}  ${t(module)}`}
        <img src={PROJECT_ICONS.UC_DEMO_ICON} />
      </Button>
    </div>
  );
};

export { Header };
