import { Button, Col, Image, Input, Modal, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { DeleteOutlined, EditOutlined, FolderOutlined } from "@ant-design/icons";
import { I18N_MODULE_KEYS } from "../../../../../../Components/i18n/core/constants";
import { Folder } from "../../../Core/constants";
import { PROJECT_ICONS } from "../../../../../../Assets/Img/_DIcons";
const { Paragraph } = Typography;

export interface ModalForDeletingFolderProps{
    showModalforFolder: boolean; // Add this to correctly match the prop used in the component
  selectedFolder: string;
  setShowModalforFolder: (status: boolean) => void;
  handleDeleteFolder: (folder: string) => void; // Add this 
  }
  
  export const ModalForDeletingFolder: React.FC<ModalForDeletingFolderProps> = ({
    showModalforFolder,
    selectedFolder,
    setShowModalforFolder,
    handleDeleteFolder
  })  => {
  const { tabList,folderData } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  console.log(tabList, "tabList");

  return (
    <>
     <Modal
        width={400}
        title=""
        centered
        visible={showModalforFolder}
        onCancel={() => {
          setShowModalforFolder(false);
        }}
        onOk={() => {
          handleDeleteFolder(selectedFolder);
          setShowModalforFolder(false);
        }}
        okText={t(I18N_MODULE_KEYS["content.button.yes"])}
        cancelText={t(I18N_MODULE_KEYS["content.button.no"])}
      >
        <div style={{ display: "flex", paddingTop: "10px", paddingBottom: "10px" }}>
          <p style={{ fontWeight: "bold", color: "black" }}>{t(I18N_MODULE_KEYS["semantic.search.filesystem.deletionfolderconfirmation"])}</p>
        </div>
      </Modal>
    </>
  );
};
