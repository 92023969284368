import { FC, useEffect, useMemo, useRef, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Skeleton,
  Typography,
  Upload,
  Radio,
  Select,
  Carousel,
  Space,
  Popconfirm,
  Input,
  Flex,
  TabsProps,
  Tabs
} from "antd";
import { DownloadOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
// import Input from "antd/es/input/Input";
// import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  CONTENT_TRANSCREATION_STEPS,
  CT_DASHBOARD_STATUS,
  CT_PROMPTS,
  ENUM_CONTENT_TRANSCREATION_BUTTON_TXT,
  globalConstant,
  handleCancelApiRequest,
  onDownload
} from "../../../Utils";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setNextStepperStateAction,
  setPreviousStepperStateAction,
  setRegenerateStateAction,
  setResponseCtAction
} from "../../../Store/actions/_contentTranscreationActions";
import {
  createHtmlRequest,
  createStoryBoardRequest,
  editEmailDocumentRequest,
  uploadEmailDashboardRequest,
  uploadEmailDocRequest,
  uploadEmailRegenerate
} from "../../../Store/requests/_contentTranscreationRequests";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import { CustomButtonForApiCalling } from "../../../Components/Buttons";
import { RegenerateModal } from "../Components";
import { siteConfig } from "../../../Services";
import { CT_SOURCE_TYPES, ENUM_PROCESS_SCREENS } from "../Core/constants";
import "./styles/1_source.css";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { ImageMask } from "../../../Components/Image";
import { EventSourcePolyfill } from "event-source-polyfill";
import { SSE } from "sse.js";
import { ContentAIWrapper } from "../../Content AI/CT-Rules/ContentAIForCT";
// import TextArea from "antd/es/input/TextArea";

// const { TextArea } = require("antd/es/input/TextArea");
const { TextArea } = Input;

const { Title, Paragraph } = Typography;

type BodyTypes = {
  body: string;
  subject: string;
  image_urls: string[];
  status: string;
};

const initalBody: BodyTypes = {
  body: "",
  subject: "",
  image_urls: [],
  status: CT_DASHBOARD_STATUS.PENDING
};

const getCustomSSE = (url: string, isJson: boolean = true, payload?: any) => {
  var headers: Record<string, string> = {
    Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
  };

  const sse = new SSE(siteConfig.BASE_URL + url, {
    headers,
    payload: isJson ? JSON.stringify(payload) : payload
  });

  return sse;
};

const supportedFormats = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //.docx
  "application/vnd.openxmlformats-officedocument.presentationml.presentation" //.pptx
];

export const CTSource: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const timerRef: any = useRef();

  const { isLoading } = useSelector((state: any) => state?.nonPersistedReducer);
  const { activeStepper, nextStepper, responseCT, buttonText, previousStepper, regenerate, source } = useSelector(
    (state: any) => state?.contentTranscreationReducer
  );

  const [showCard, setShowCard] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [body, setBody] = useState<BodyTypes>({ ...initalBody });
  const [editedBodySubject, setEditedBodySubject] = useState<BodyTypes>({ ...initalBody });
  const [editDocLoading, setEditDocLoading] = useState<boolean>(false);

  useEffect(() => {
    if (source === CT_SOURCE_TYPES.EMAIL && responseCT?.email_doc_id && (!responseCT?.body || responseCT?.subject || !responseCT?.filename)) {
      callGetEmailDashboardApi();
    }

    return () => {
      clearTimeout(timerRef.current);
      dispatch(setLoadingStatusAction(false));
    };
  }, []);

  useEffect(() => {
    if (nextStepper && activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_1) {
      dispatch(setNextStepperStateAction(false));
      clearTimeout(timerRef.current);
      if (uploadFiles && uploadFiles?.length) {
        if (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED) {
          uploadImage(false);
        } else if (buttonText === ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD) {
          // dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_3));
          if (responseCT?.email_doc_id) createStoryBoardApi(responseCT?.email_doc_id);
        }
      } else {
        setShowCard(false);
        dispatch(setResponseCtAction({}));
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.selectfile"])));
        dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED));
      }
    }
  }, [nextStepper]);

  useEffect(() => {
    if (previousStepper && activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_1) {
      handleCancelApiRequest();
      clearTimeout(timerRef.current);
      dispatch(setResponseCtAction({}));
      dispatch(setPreviousStepperStateAction(false));
      //dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_0));
      dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS["STEP_0.5"]));
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED));
    }
  }, [previousStepper]);

  useEffect(() => {
    if (regenerate && activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_1) {
      if (responseCT?.has_next === false) {
        regenerateDoc();
      } else if (responseCT?.has_next === true) {
        setShowModal(true);
        dispatch(setRegenerateStateAction(false));
      }
    }
  }, [regenerate]);

  useEffect(() => {
    if (responseCT && responseCT?.id) {
      setShowCard(true);
      setBody({ ...responseCT });
      setUploadFiles([{ name: responseCT?.filename }]);
    }
  }, [responseCT]);

  const regenerateDoc = async () => {
    setShowCard(false);
    await uploadImage(true);
    dispatch(setRegenerateStateAction(false));
  };

  //calling get email dashboard api for content-history,
  const callGetEmailDashboardApi = async () => {
    setShowCard(false);
    await getUploadEmailDashboard(responseCT?.email_doc_id);
    setShowCard(true);
  };

  const removeFile = (index: number) => {
    // if(responseCT?.isVideoRendered){
    //   dispatch(setNotificationMsgAction())
    //   return
    // }

    const arrUploadFile: any[] = [...uploadFiles];

    arrUploadFile?.splice(index, 1);

    setShowCard(false);

    clearTimeout(timerRef.current);

    setBody({ ...initalBody });

    dispatch(setResponseCtAction({}));

    dispatch(setLoadingStatusAction(false));

    dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED));

    setUploadFiles([...arrUploadFile]);

    handleCancelApiRequest();
  };

  const uploadImage = async (regenerate: boolean) => {
    console.log("Upload doc started");

    setShowCard(true);

    const instFormData: FormData = new FormData();

    instFormData.append("files", uploadFiles[uploadFiles?.length - 1]?.originFileObj);

    console.log("Document Object", uploadFiles[uploadFiles?.length - 1]?.originFileObj);

    let res: any;
    let sse: any;

    dispatch(setLoadingStatusAction(true));

    if (regenerate) {
      body.status = CT_DASHBOARD_STATUS.PENDING;
      sse = getCustomSSE("/file/regenerate", true, { email_doc_id: responseCT?.email_doc_id });
    } else {
      sse = getCustomSSE(siteConfig.UPLOAD_EMAIL_DOC + `/11?regenerate=${regenerate}`, false, instFormData);
    }

    sse.addEventListener("end", (data: any) => {
      sse.close();

      if (data?.data) {
        getUploadEmailDashboard(data?.data);
      }
    });

    sse.onerror = () => {
      dispatch(setLoadingStatusAction(false));

      sse.close();
    };

    if (!res) {
      if (regenerate === false) {
        setShowCard(false);
      }
    }

    if (res?.message) {
      console.log("call upload doc function again because status of doc is still pending");

      getUploadEmailDashboard(res?.message);
    } else if (typeof res?.detail && regenerate === true) {
      dispatch(setNotificationMsgAction(res?.detail));
    }

    console.log("Upload doc ended");
  };

  const CustomEventSourceMethod = (url: string) => {
    return new Promise((resolve, reject) => {
      var headers: Record<string, string> = {
        Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
      };

      const sse: EventSource = new EventSourcePolyfill(`${siteConfig.BASE_URL}${url}`, {
        headers
      });

      sse.onmessage = (ev) => {
        resolve(ev);

        sse.close();
      };

      sse.onerror = () => {
        reject();

        sse.close();
      };
    });
  };

  // const getUploadEmailDashboard = async (id: number) => {
  //   clearTimeout(timerRef.current);

  //   const res = await CustomEventSourceMethod(`/file${id}`);
  //   // setTimeout(() => {
  //   //   CustomEventSourceMethod(`/file/${id}`)
  //   //     ?.then((res) => {
  //   //       console.log(res, "success");
  //   //     })
  //   //     ?.catch((err) => {
  //   //       console?.log(err, "reject");
  //   //     });
  //   // }, 1000);

  //   dispatch(setLoadingStatusAction(true));

  //   if (!res) return;

  //   setBody({ ...res });

  //   setEditedBodySubject({ ...res });

  //   dispatch(setResponseCtAction({ ...responseCT, ...res, email_doc_id: res?.id }));

  //   if (res?.error && res?.status === CT_DASHBOARD_STATUS.FAILED) {
  //     dispatch(setNotificationMsgAction(res?.error));

  //     return;
  //   }

  //   if (res?.status === CT_DASHBOARD_STATUS.SUCCESS) {
  //     dispatch(setLoadingStatusAction(false));

  //     // dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CONERT_TO_HTML));
  //     dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD));
  //     return;
  //   }

  //   timerRef.current = setTimeout(() => {
  //     getUploadEmailDashboard(id);
  //   }, 5000);
  // };

  const getUploadEmailDashboard = async (id: number) => {
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL; //Added to reset the Base URL if changed by Rules Tab while response is not yet a succcess

    clearTimeout(timerRef.current);

    // const res = await uploadEmailDashboardRequest({ prompt: CT_PROMPTS.UPLOAD_EMAIL, id: id }, dispatch);
    const res = await uploadEmailDashboardRequest(id, dispatch);

    dispatch(setLoadingStatusAction(true));

    if (!res) return;

    setBody({ ...res });

    setEditedBodySubject({ ...res });

    dispatch(setResponseCtAction({ ...responseCT, ...res, email_doc_id: res?.id }));

    if (res?.error && res?.status === CT_DASHBOARD_STATUS.FAILED) {
      dispatch(setNotificationMsgAction(res?.error));

      return;
    }

    if (res?.status === CT_DASHBOARD_STATUS.SUCCESS) {
      dispatch(setLoadingStatusAction(false));

      // dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CONERT_TO_HTML));
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.CREATE_STORY_BOARD));
      return;
    }

    timerRef.current = setTimeout(() => {
      getUploadEmailDashboard(id);
    }, 5000);
  };

  const createStoryBoardApi = async (id: number) => {
    // const res = await createStoryBoardRequest({ email_doc_id: id ,}, dispatch);

    const sse = getCustomSSE(siteConfig.CREATE_STORYBOARD, true, { email_doc_id: id, regenerate: false, user_id: 11 });

    // res = await uploadEmailDocRequest(instFormData, false, dispatch);

    console.log("Upload doc API response", sse);

    dispatch(setLoadingStatusAction(true));

    sse.addEventListener("end", (data: any) => {
      if (data?.data) {
        // getUploadEmailDashboard(data?.data);
        dispatch(
          setResponseCtAction({
            ...responseCT,
            storyboard_id: data?.data
          })
        );

        dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_3));

        localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);

        sse.close();
      }
    });

    sse.onerror = () => {
      dispatch(setLoadingStatusAction(false));

      sse.close();
    };
  };

  // const createStoryBoardApi = async (id: number) => {
  //   const res = await createStoryBoardRequest({ email_doc_id: id }, dispatch);

  //   if (res && res?.message) {
  //     const { message } = res;

  //     dispatch(
  //       setResponseCtAction({
  //         ...responseCT,
  //         storyboard_id: message
  //       })
  //     );
  //     dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_3));

  //     localStorage.setItem(siteConfig.CT_PROCESS_KEY, ENUM_PROCESS_SCREENS.STORYBOARD_LIST_SCREEN);
  //   }
  // };

  const createHtmlApi = async (email_doc_id: number) => {
    const res = await createHtmlRequest({ email_doc_id: email_doc_id, regenerate: false }, dispatch);

    if (res && res?.message) {
      const { message } = res;
      dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_2));

      dispatch(
        setResponseCtAction({
          ...responseCT,
          html_id: message
        })
      );
    } else {
      // dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
    }
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e?.target;

    setBody((p) => ({
      ...p,
      [name]: value
    }));
  };

  const handleEditDocument = async () => {
    // if (body?.body) {
    //   dispatch(setNotificationMsgAction("Please enter body"))
    //   return;
    // }

    // if (body?.subject) {
    //   return;
    // }

    const { id } = responseCT;

    setEditDocLoading(true);
    const res = await editEmailDocumentRequest({
      email_doc_id: id,
      subject: body?.subject,
      body: body?.body
    });
    setEditDocLoading(false);

    if (res?.message) {
      dispatch(setNotificationMsgAction(res?.message));
      dispatch(
        setResponseCtAction({
          ...responseCT,
          body: body?.body,
          subject: body?.subject
        })
      );

      setEditedBodySubject({ ...initalBody, body: body?.body, subject: body?.subject });
    } else {
      // dispatch(setNotificationMsgAction(globalConstant.INTERNAL_SERVER_ERROR));
    }
  };

  const handleDiscardOption = () => {
    setBody((p: BodyTypes) => ({
      ...p,
      body: editedBodySubject?.body,
      subject: editedBodySubject?.subject
    }));
  };

  const items: TabsProps["items"] = [
    {
      key: "upload-file",
      label: "Upload File",
      children: (
        <Row gutter={[16, 16]} className="test">
          <Col xs={{ span: 24 }}>
            <Card
              className="overflowHidden"
              style={{
                boxShadow: "0px 6px 18px #00000029",
                borderRadius: "12px"
              }}
            >
              <Row gutter={[8, 8]}>
                <Col xs={{ span: 24 }} md={{ span: 16 }}>
                  <Title level={5} className="font-nunito-sans" style={{ fontWeight: "800" }}>
                    {/* Upload File */}
                    {t(I18N_MODULE_KEYS["content.transcreation.source.card.upload"])}
                  </Title>
                  <Upload
                    accept=".pdf,.docx,.pptx"
                    className="ct-upload-area"
                    onChange={({ file }) => {
                      const isPDF = file.type === "application/pdf";
                      const isLt30MB = (file.size as number) / 1024 / 1024 <= 30; //File size should be <= 30MB

                      if (!isLt30MB) {
                        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
                        return;
                      }

                      if (!supportedFormats.includes(file.type as string)) {
                        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.steppers.1.notification.filetypeissue"])));
                        return;
                      }

                      setUploadFiles([...uploadFiles, { ...file }]);
                    }}
                    customRequest={() => {}} //To prevent the POST request error
                    showUploadList={false}
                    disabled={isLoading || uploadFiles?.length}
                    multiple
                  >
                    <div className="upload-area-body">
                      <Paragraph> {t(I18N_MODULE_KEYS["content.transcreation.source.card.text.drag"])}</Paragraph>
                      <Paragraph className="d-flex justify-content-center">
                        {t(I18N_MODULE_KEYS["content.transcreation.source.card.text.file"])}
                      </Paragraph>
                      <Flex justify="center" gap="10px">
                        <Button
                          icon={<UploadOutlined />}
                          type="primary"
                          style={{ background: uploadFiles?.length ? "" : "#0546A5" }}
                          disabled={uploadFiles?.length ? true : false}
                        >
                          {/* Upload File */}
                          {t(I18N_MODULE_KEYS["content.transcreation.source.card.uploadasset"])}
                        </Button>
                        <Button type="primary" disabled>
                          {t(I18N_MODULE_KEYS["content.transcreation.source.card.pullClaims"])}
                        </Button>
                      </Flex>
                    </div>
                  </Upload>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} className="uploaded-files-section">
                  <Title level={5} className="font-nunito-sans" style={{ fontWeight: "800" }}>
                    {/* Uploaded File ({`${uploadFiles?.length}`}) */}
                    {t(I18N_MODULE_KEYS["content.transcreation.source.card.uploaded"])}({`${uploadFiles?.length}`})
                  </Title>
                  <Card
                    className="overflowY"
                    style={{
                      height: "100%",
                      border: "1px dotted black"
                    }}
                  >
                    {uploadFiles && uploadFiles?.length ? (
                      <>
                        {uploadFiles?.map((item: any, index: number) => {
                          return (
                            <div
                              key={`upload_files_${index}`}
                              className="d-flex"
                              style={{
                                background: index % 2 === 0 ? "#E6F0FF" : "#FFE4EF",
                                borderRadius: "4px",
                                justifyContent: "space-between",
                                padding: "5px",
                                marginBottom: "5px"
                              }}
                            >
                              <Paragraph className="font-nunito-sans" style={{ fontSize: "12px", margin: "4px 0px" }}>
                                {item?.name || `File_${index + 1}`}
                              </Paragraph>
                              <Paragraph
                                style={{
                                  fontSize: "11px",
                                  margin: "4px 4px",
                                  cursor: "pointer"
                                }}
                                onClick={() => removeFile(index)}
                              >
                                X
                              </Paragraph>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <Paragraph
                        type="secondary"
                        className="optimize-font"
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        {/* No File Selected! */}
                        {t(I18N_MODULE_KEYS["content.transcreation.source.card.text.nofileselected"])}
                      </Paragraph>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>

          {showCard && (
            <Col xs={{ span: 24 }}>
              <Card
                style={{
                  boxShadow: "0px 6px 18px #00000029",
                  borderRadius: "12px"
                }}
              >
                {body?.status === CT_DASHBOARD_STATUS.SUCCESS ? (
                  <Row gutter={[24, 8]}>
                    <Col xs={{ span: 24 }} lg={{ span: 16 }} style={{ height: "100%" }}>
                      <Title level={5} className="font-nunito-sans" style={{ marginBottom: "4px", fontWeight: "800" }}>
                        {t(I18N_MODULE_KEYS["content.transcreation.source.card.text.subject"])}
                      </Title>
                      <Input
                        name="subject"
                        style={{
                          marginBottom: "25px",
                          border: "0.4000000059604645px solid #000000",
                          borderRadius: "12px"
                        }}
                        value={body?.subject || ""}
                        onChange={handleOnChange}
                      />

                      <Title level={5} className="font-nunito-sans" style={{ marginBottom: "4px", fontWeight: "800" }}>
                        {/* Body */}
                        {t(I18N_MODULE_KEYS["content.transcreation.source.card.text.body"])}
                      </Title>
                      <TextArea
                        placeholder="input here"
                        className="custom"
                        name="body"
                        style={{
                          height: "24.5vh",
                          border: "0.4000000059604645px solid #000000",
                          borderRadius: "12px"
                        }}
                        value={body?.body || ""}
                        onChange={handleOnChange}
                      />

                      <CustomButtonForApiCalling
                        disabled={!body?.subject || !body?.body}
                        loading={editDocLoading}
                        btnText={t(I18N_MODULE_KEYS["content.button.save"])}
                        onClick={handleEditDocument}
                        style={{ marginTop: "10px" }}
                      />
                      {(body?.body !== editedBodySubject?.body || body?.subject !== editedBodySubject?.subject) && (
                        <Popconfirm
                          title={`${t(I18N_MODULE_KEYS["content.text.disardallchanges"])}`}
                          description={`${t(I18N_MODULE_KEYS["content.text.areyousuretodiscard"])}`}
                          okText={`${t(I18N_MODULE_KEYS["content.button.yes"])}`}
                          cancelText={`${t(I18N_MODULE_KEYS["content.button.no"])}`}
                          onConfirm={handleDiscardOption}
                        >
                          <Button style={{ margin: "10px 10px" }}>{t(I18N_MODULE_KEYS["content.button.discard"])}</Button>
                        </Popconfirm>
                      )}
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Title level={5} className="font-nunito-sans" style={{ marginBottom: "4px", fontWeight: "800" }}>
                        {/* Image Used */}
                        {`${t(I18N_MODULE_KEYS["content.transcreation.source.card.text.image"])} (${body?.image_urls?.length})`}
                        {/* {`${t(I18N_MODULE_KEYS["content.transcreation.source.card.text.image"])} (${t(I18N_MODULE_KEYS["content.button.total"])}: ${
                      body?.image_urls?.length
                    })`} */}
                      </Title>
                      {body?.image_urls && body?.image_urls?.length ? (
                        <>
                          <Carousel
                            autoplay
                            style={{
                              border: "0.4000000059604645px solid #000000",
                              borderRadius: "12px",
                              padding: "10px",
                              background: "0% 0% no-repeat padding-box padding-box rgb(221 221 221)"
                            }}
                          >
                            {body?.image_urls?.map((item: string, index: number) => {
                              return (
                                <div>
                                  <Image
                                    key={`dashboard_image_${index}`}
                                    src={item}
                                    preview={{
                                      toolbarRender: (
                                        _,
                                        { transform: { scale }, actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
                                      ) => (
                                        <Space size={12} className="toolbar-wrapper">
                                          <DownloadOutlined onClick={() => onDownload(item)} />
                                          <SwapOutlined rotate={90} onClick={onFlipY} />
                                          <SwapOutlined onClick={onFlipX} />
                                          <RotateLeftOutlined onClick={onRotateLeft} />
                                          <RotateRightOutlined onClick={onRotateRight} />
                                          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                        </Space>
                                      ),
                                      mask: <ImageMask />
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </Carousel>
                        </>
                      ) : (
                        <Paragraph
                          type="secondary"
                          className="optimize-font"
                          style={{
                            fontSize: "12px"
                          }}
                        >
                          {/* No Image Found! */}
                          {t(I18N_MODULE_KEYS["content.text.noimagefound"])}
                        </Paragraph>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Skeleton active />
                )}
              </Card>
            </Col>
          )}
        </Row>
      )
    },
    {
      key: "rules",
      label: "Rules",
      children: (
        <div>
          <ContentAIWrapper />
        </div>
      )
    }
  ];

  const onChange = (key: string) => {
    if (key === "upload-file") {
      siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
    }
    if (key === "rules") {
      siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;
    }
  };

  return (
    <>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="upload-rules-tab" />
      <RegenerateModal
        showModal={showModal}
        setShowModal={(val, isSave) => {
          // if (isSave) getUploadEmailDashboard(responseCT?.email_doc_id);
          if (isSave) regenerateDoc();
          setShowModal(false);
        }}
      />
    </>
  );
};
