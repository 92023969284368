import { Button, Dropdown, MenuProps, Modal, Typography, message } from "antd";
import { MutableRefObject, useEffect, useRef, useState } from "react";
//import { finalHTMLOutput as finalHTMLOutputData } from "./core/iDetailMockData";
import TinyMCE from "./Components/_TinyMCE";
import { Editor as TinyMCEEditor } from "tinymce";
import _iDetailExtractRequests from "../../Store/requests/_iDetailExtractRequests";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAssetMainDataIDXAction, setOpenHtmlOutputModalIDXAction } from "../../Store/actions/_iDetailExtractActions";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { setNotificationMsgAction } from "../../Store/actions/_commonActions";
//import { setNotificationMsgAction } from "../../Store/actions/_commonActions";

const { Text } = Typography;

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;

export default function FinalHTMLOutputModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { openHtmlOutputModal, assetMainData } = useSelector((state: any) => state?.iDetailExtractReducer);

  const [editHTMLOutput, setEditHTMLOutput] = useState(false);
  const [finalHTMLOutput, setFinalHTMLOutput] = useState(""); //Stringified HTML code. finalHTMLOutputData.
  const [editedOnce, setEditedOnce] = useState(false); //Load TinyMCE for first edit, then hide it for subsequent edits
  const [saveChanges, setSaveChanges] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [discardTriggeredSave, setDiscardTriggeredSave] = useState(false); //Temporary state to differentiate between Save and Discard-Save. To prevent 'Content Saved' message when discarding.
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openExitEditModeModal, setOpenExitEditModeModal] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const editorRef = useRef<TinyMCEEditor>(null);

  const [messageApi, contextHolder] = message.useMessage();
  const successMessage = (text: string = "") => messageApi.open({ type: "success", content: text, duration: 1.5 });
  const errorMessage = (text: string = "") => messageApi.open({ type: "error", content: text, duration: 1.5 });
  const warningMessage = (text: string = "") => messageApi.open({ type: "warning", content: text, duration: 1.5 });
  const infoMessage = (text: string = "") => messageApi.info(text, 1.5);

  useEffect(() => {
    //Don't update the state if editor is already opened. It would reset the cursor to starting position. And Undo would not work as the TinyMCE would be reset with this input.
    //Using setFinalHTMLOutput local state while saving to quickly reflect the changes.
    !editHTMLOutput && setFinalHTMLOutput(assetMainData?.response);
  }, [assetMainData?.response]);

  useEffect(() => {
    editHTMLOutput && !editedOnce && setEditedOnce(true);

    //If switching back from edit mode, update the local state contents with the Redux state
    //finalHTMLOutput is being used in iframe.
    !editHTMLOutput && editedOnce && setFinalHTMLOutput(assetMainData?.response);
  }, [editHTMLOutput]);

  useEffect(() => {
    if (!saveChanges) return;
    setSaveChanges(false);

    //If this save has been triggered due to Discard-Save
    if (discardTriggeredSave) {
      setDiscardTriggeredSave(false);
      return;
    }

    const updatedContent = editorRef.current?.getContent() || "";

    _iDetailExtractRequests.saveFinalHTMLData(assetMainData?.id, updatedContent).then((res) => {
      if (!res?.error) {
        //Dispatch saved notification

        //Updating the local state also to quickly reflect the changes
        //setFinalHTMLOutput(updatedContent);
        dispatch(setAssetMainDataIDXAction({ ...assetMainData, response: updatedContent }));

        //dispatch(setNotificationMsgAction("Content saved!"));
        successMessage("Content saved!");

        console.log("Updated content Saved!");
      } else {
        //dispatch(setNotificationMsgAction("Saving failed! Please retry."));
        errorMessage("Saving failed! Please retry.");
        console.log("Saving failed!");
      }
    });
  }, [saveChanges]);

  useEffect(() => {
    if (!discardChanges) return;

    //Restore editor contents with last saved item
    editorRef.current?.setContent(assetMainData?.response);

    //Save these changes in the editor. It would flip the 'Dirty' flag to false.
    editorRef.current?.execCommand("mceSave");

    setDiscardChanges(false);

    warningMessage("Content discarded!");
  }, [discardChanges]);

  const editorHasUnsavedChanges = () => {
    const tinymce = editorRef.current;
    return tinymce && !tinymce?.isNotDirty;
  };

  const onEditorChangesSaveCallback = async () => {
    setSaveChanges(true);
  };

  //Don't save the recent changes made after the last save
  const onEditorChangesCancelCallback = () => {
    setDiscardChanges(true);
    setDiscardTriggeredSave(true);
  };

  const saveEditorChangesHandler = () => {
    if (editorHasUnsavedChanges()) {
      //Save editor contents
      triggerSaveCommandTMCE();
    } else {
      //dispatch(setNotificationMsgAction("No recent changes to save."));
      infoMessage("No recent changes to save.");
      console.log("No recent changes to save");
    }
  };

  const triggerSaveCommandTMCE = () => {
    const tinymce = editorRef.current;
    tinymce?.execCommand("mceSave");
  };

  const discardEditorChangesHandler = () => {
    //Discard editor contents
    const tinymce = editorRef.current;
    console.log({ editorHasUnsavedChanges: editorHasUnsavedChanges() });
    //editorHasUnsavedChanges() && tinymce?.execCommand("mceCancel");

    if (editorHasUnsavedChanges()) {
      //Open discard modal
      setOpenDiscardModal(true);
    } else {
      //dispatch(setNotificationMsgAction("No unsaved changes to discard."));
      infoMessage("No unsaved changes to discard.");
      console.log("No unsaved changes to discard");
    }
  };

  const exitEditModeHandler = () => {
    if (editorHasUnsavedChanges()) {
      console.log("Changes would be lost!");
      setOpenExitEditModeModal(true);
    } else setEditHTMLOutput(false);
  };

  const previewEditorChangesHandler = () => {
    const tinymce = editorRef.current;
    //@ts-ignore
    // window.tinymce?.activeEditor?.execCommand("mcePreview");
    tinymce?.execCommand("mcePreview");
  };

  const cancelDiscardModalHandler = () => {
    //close the dialog
    setOpenDiscardModal(false);
  };

  const loseChangesHandler = () => {
    triggerDiscardCommandTMCE();

    //close the dialog
    setOpenDiscardModal(false);
  };

  const triggerDiscardCommandTMCE = () => {
    editorRef.current?.execCommand("mceCancel");
  };

  const showTinyMCEEditor = () => {
    setEditHTMLOutput((p) => !p);
  };

  const uploadImageToS3 = async (blobInfo: any) => {
    const formData: FormData = new FormData();
    formData.append("file", blobInfo.blob());

    return new Promise(async (resolve, reject) => {
      const res = await _iDetailExtractRequests.uploadImage(assetMainData?.id, formData);

      if (res) {
        res?.data && resolve(res?.data?.url);
        console.log(res.data?.url);
      }
    }) as Promise<string>;
  };

  const getTranslatedText = (value: string) => {
    return t(I18N_MODULE_KEYS[`idetailextract._CoreClaimMain.FinalHTMLOutputModal.${value}` as keyof typeof I18N_MODULE_KEYS]);
  };

  const getCommonTranslatedText = (value: string) => {
    return t(I18N_MODULE_KEYS[`content.text.${value}` as keyof typeof I18N_MODULE_KEYS]);
  };

  const downloadMenuitems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a style={{ fontSize: "12px" }} onClick={() => downloadFile(assetMainData?.response, "html")}>
          HTML
        </a>
      )
    },
    {
      key: "2",
      label: (
        <a
          style={{ fontSize: "12px" }}
          onClick={async () => {
            setDownloading(true);
            let res = await _iDetailExtractRequests.getHTMLOutputPDF(assetMainData?.id);
            setDownloading(false);

            if (!res) {
              dispatch(setNotificationMsgAction(getCommonTranslatedText("download") + " " + getCommonTranslatedText("failed") + "!"));
              return;
            }

            if (res.data?.url) {
              downloadFile(res.data?.url, "pdf");
            }
          }}
        >
          PDF
        </a>
      )
    }
  ];

  return (
    <>
      {contextHolder}
      <Modal
        keyboard={false}
        maskClosable={false}
        className="iDetailFinalOutput"
        open={openHtmlOutputModal} //{true}
        centered={true}
        onCancel={() => {
          if (editHTMLOutput) {
            if (editorHasUnsavedChanges()) setOpenExitEditModeModal(true);
            else {
              setEditHTMLOutput(false);
              dispatch(setOpenHtmlOutputModalIDXAction(false));
            }
          } else dispatch(setOpenHtmlOutputModalIDXAction(false));
        }}
        okText={getTranslatedText("Proceed")}
        cancelText={getTranslatedText("Close")}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
        width={"calc(100vw - 64px)"}
        // width={"100%"}
        closeIcon={false}
        styles={{ body: { height: "calc(90vh - 84px)", display: "flex", flexDirection: "column", gap: "10px" } }} //20 + 12 + 32 + 20
      >
        <div style={{ flex: "0 0 auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
            <Text style={{ fontSize: "18px", fontWeight: "700" }}>{getTranslatedText("Your Emailer Suggestion")}</Text>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
              {!editHTMLOutput && (
                <Button onClick={showTinyMCEEditor} style={{ color: "rgba(3, 78, 162, 1)" }}>
                  {getTranslatedText("Edit")}
                </Button>
              )}
              {editHTMLOutput && (
                <>
                  <Button
                    //disabled={!editorHasUnsavedChanges()} //a State dependent on onChange of Editor would be needed
                    onClick={saveEditorChangesHandler}
                    style={{ color: "rgba(3, 78, 162, 1)" }}
                  >
                    {getTranslatedText("Save")}
                  </Button>
                  <Button onClick={discardEditorChangesHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
                    {getTranslatedText("Discard")}
                  </Button>
                  <Button onClick={exitEditModeHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
                    {getTranslatedText("Exit Edit Mode")}
                  </Button>
                  <Button onClick={previewEditorChangesHandler} style={{ /* borderColor: "rgba(3, 78, 162, 1)", */ color: "rgba(3, 78, 162, 1)" }}>
                    {getTranslatedText("Preview")}
                  </Button>
                </>
              )}
              <Dropdown menu={{ items: downloadMenuitems }} placement="bottomLeft" trigger={["click"]}>
                <Button style={{ color: "rgba(3, 78, 162, 1)" }} onClick={(e) => e.preventDefault()} loading={downloading}>
                  {getCommonTranslatedText("download")}
                </Button>
              </Dropdown>
            </div>
            {/* <Button
              onClick={async () => {
                const res = await _iDetailExtractRequests.getAssetMainData(assetMainData?.id);

                console.log({ res });
                dispatch(setAssetMainDataIDXAction(res?.data));
              }}
            >
              Get Latest
            </Button> */}
          </div>
        </div>
        {!editHTMLOutput && (
          <iframe srcDoc={scrollBarStyles + finalHTMLOutput} style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px" }} />
        )}
        {editedOnce && (
          <TinyMCE
            editorRef={editorRef as MutableRefObject<TinyMCEEditor>}
            content={finalHTMLOutput}
            hide={!editHTMLOutput}
            onEditorChangesSaveCallback={onEditorChangesSaveCallback}
            onEditorChangesCancelCallback={onEditorChangesCancelCallback}
            uploadImageToS3={uploadImageToS3}
          />
        )}
      </Modal>

      <Modal
        open={openDiscardModal}
        centered={true}
        title={getTranslatedText("Discard unsaved changes")}
        onCancel={cancelDiscardModalHandler}
        footer={[
          <Button key="cancel" type="primary" onClick={cancelDiscardModalHandler} style={{ background: "rgba(3, 78, 162, 1)", color: "white" }}>
            {getTranslatedText("Cancel")}
          </Button>,
          <Button key="discard" onClick={loseChangesHandler} style={{ color: "rgba(3, 78, 162, 1)" }}>
            {getTranslatedText("Lose Changes")}
          </Button>
        ]}
      >
        <Text>{getTranslatedText("If you proceed, you will lose all the changes you have made.")}</Text>
      </Modal>

      <Modal
        open={openExitEditModeModal}
        centered={true}
        title={getTranslatedText("Unsaved changes")}
        onCancel={() => {
          setOpenExitEditModeModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            style={{ color: "rgba(3, 78, 162, 1)" }}
            onClick={() => {
              setOpenExitEditModeModal(false);
            }}
          >
            {getTranslatedText("Cancel")}
          </Button>,
          <Button
            key="saveAndExit"
            type="primary"
            style={{ background: "rgba(3, 78, 162, 1)", color: "white" }}
            onClick={() => {
              triggerSaveCommandTMCE();
              setOpenExitEditModeModal(false);
              setEditHTMLOutput(false);
            }}
          >
            {getTranslatedText("Save and Exit")}
          </Button>,
          <Button
            key="exitWithoutSaving"
            style={{ color: "rgba(3, 78, 162, 1)" }}
            onClick={() => {
              triggerDiscardCommandTMCE();
              setOpenExitEditModeModal(false);
              setEditHTMLOutput(false);
            }}
          >
            {getTranslatedText("Exit without Saving")}
          </Button>
        ]}
      >
        <Text>{getTranslatedText("If you proceed, you will lose all the changes you have made.")}</Text>
      </Modal>
    </>
  );
}

const downloadFile = async (content: string, type: string) => {
  var element = document.createElement("a");

  if (type === "pdf") {
    element.setAttribute("href", content);
    element.setAttribute("download", "Result.pdf"); // would not affect the final filename
  }
  if (type === "html") {
    element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", "Result.html");
  }

  element.click();
};
