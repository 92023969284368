import { UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { FileView } from "./FileView";
import { useEffect, useState } from "react";
import { uploadSingleCUFileRequest } from "../../../../../Store/requests/_contentAIRequests";
import { globalConstant } from "../../../../../Utils";
import { useDispatch } from "react-redux";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const CUUploadRightPane = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [files, setFiles] = useState<any[]>([]);
  const [processFiles, setProcessFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!processFiles.length) return;

    if (!uploading) {
      uploadFile();
    }

    async function uploadFile() {
      setUploading(true);

      //console.log("File to Process", processFiles[0]);
      const formData: FormData = new FormData();
      formData.append("file", processFiles[0]);

      let res = await uploadSingleCUFileRequest(formData);

      setUploading(false);
      if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase() || res?.status === globalConstant.SUCCESS) {
        dispatch(setNotificationMsgAction(`${processFiles[0]?.name?.substring(0, 30) || "File"} uploaded!`));

        //Set status="done"
        setFiles((pFiles) =>
          pFiles.map((file) => {
            if (file?.uid === processFiles[0]?.uid) {
              file.status = "done";
            }
            return file;
          })
        );
        setProcessFiles((pFiles) => pFiles.slice(1));
      } else {
        //TODO Both If and Else statements could be combined if no further functionality is needed.

        // dispatch(setNotificationMsgAction(`Error: Uplaod failed!\n${processFiles[0]?.name}`));
        dispatch(setNotificationMsgAction(`${t(I18N_MODULE_KEYS["content.text.erroruploadfailed"])}\n${processFiles[0]?.name}`));
        setFiles((pFiles) =>
          pFiles.map((file) => {
            if (file?.uid === processFiles[0]?.uid) {
              file.status = "error";
            }
            return file;
          })
        );

        //No retry functionality for now. Pop the file even after error.
        setProcessFiles((pFiles) => pFiles.slice(1));
      }
    }
  }, [processFiles]);

  const validateFileFormat = (file: any) => {
    if (file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return true;
    }
    return false;
  };

  const props: UploadProps = {
    name: "file",
    accept: ".xlsx",
    multiple: true,
    showUploadList: false,
    customRequest: async ({ file }) => {
      if (!validateFileFormat(file)) {
        return;
      }
      //console.log("customRequest Uploaded", file);
      setProcessFiles((pFiles) => [...pFiles, file]);
    },
    onChange(info) {
      const { status } = info.file;

      if (!validateFileFormat(info.file)) {
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat"])));
        return;
      }
      //console.log({ info });
      //setFiles(info.fileList);
      setFiles((pFiles) => [...pFiles, info.file]);
    },
    onDrop(e) {
      //console.log("Dropped files", e.dataTransfer.files);
    }
  };

  return (
    <div className="col-9">
      <div className="cu-upload-right-pane">
        {/* <div className="step">STEP 2</div> */}
        <div className="step">{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.step2"])}</div>
        {/* <div className="title">File Upload</div> */}
        <div className="title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.fileupload"])}</div>
        <div className="custom-row">
          <div className="col-7" style={{ paddingLeft: 0 }}>
            <div style={{ maxHeight: "450px", height: "100%" }}>
              <div className="cu-file-upload">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <img width="150px" src={PROJECT_ICONS.C_AI_FILE_UPLOAD_GIF} />
                  </p>
                  <div className="cu-upload-hint">
                    {/* <div>Drag and drop your files here.</div> */}
                    <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"])}</div>
                    {/* <div>Or import from: </div> */}
                  </div>
                </Dragger>
              </div>
              <div className="cu-upload-footer">
                <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB"])}</div>
                <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.supportedfiletype"])} .xlsx</div>
                {/* <div>Maximum upload file size: 128 MB.</div>
                <div>Supported file type: .docx, pdf</div> */}
              </div>
            </div>
          </div>
          <div className="col-5" style={{ paddingLeft: "10px" }}>
            <div className="cu-files-list-area">
              {/* <div className="title">Uploaded File</div> */}
              <div className="title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentuniverse.text.uploadedfile"])}</div>
              <div className="cu-files-list">
                {files.length ? (
                  files
                    .slice(0)
                    .reverse()
                    .map((file, i) => <FileView key={file.uid} file={file} uploading={uploading && file.uid === processFiles[0]?.uid} />)
                ) : (
                  // <div className="no-files-found">No files uploaded!</div>
                  <div className="no-files-found">{t(I18N_MODULE_KEYS["content.text.nofilesuploaded"])}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
