import { FC, useEffect, useState } from "react";
import {
  addRulesToPromptRequest,
  createEnrichPromptRequest,
  getAllTagsRequest,
  getFilteredRulesRequest,
  getPromptResultRequest
} from "../../../../Store/requests/_contentAIRequests";
import { useDispatch, useSelector } from "react-redux";
import { globalConstant } from "../../../../Utils";
import { CUContentType, ContentAIRules, ContentAITag, ContentAITags, LLMProps } from "../../Core/types";
import { LLMLeftPane } from "./LLMLeftPane";
import { LLMFilteredTags } from "./LLMFilteredTags";
import { LLMRulesComponent } from "./LLMRulesComponent";
import { siteConfig } from "../../../../Services";
import { CUContentsComponent } from "../Content Universe/CUContentsComponent";
import { CUFilteredTags } from "../Content Universe/CUFilteredTags";
import { LoadingOutlined } from "@ant-design/icons";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import {
  setActiveModuleActions,
  setCurrentActiveStepActions,
  setResponseDataAction,
  setResponseLoaderFlagAction,
  setSelectedTagsAction
} from "../../../../Store/actions/_contentAIActions";
import { CONTENT_AI_MODULES, CONTENT_CREATOR_STEPS } from "../../Core/constants";
import { Skeleton, Spin } from "antd";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import LabelCheckBox from "../Common/LabelCheckBox";

export const LLMBaseComponent: FC<LLMProps> = ({ contentAIModule, contentAIModuleName, setGetResultTimer, clearGetResultTimer }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { activeModule, promptID, selectedTags } = useSelector((state: any) => state?.contentAIReducer);

  const [allTags, setAllTags] = useState<ContentAITags>({} /* structuredClone(contentAITags) */);
  const [selectedUITags, setSelectedUITags] = useState<ContentAITag[]>([]); //used to show UI Tags with delete button
  const [selectedTagsIds, setSelectedTagsIds] = useState<{ [key: string]: number[] }>({}); //stores selected tag IDs in respective categories. Used for fetching rules
  const [filteredRules, setFilteredRules] = useState<ContentAIRules>([]);
  const [refetchRules, setRefetchRules] = useState(false); //refetch Rules again for the selectedTagsIds. Used in 'Rule Component' to refetch Rules when a Rule's Tags are updated and all these tags are not there in selectedTagsIds.
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingRules, setLoadingRules] = useState(false);
  const [loadingProceed, setLoadingProceed] = useState(false);

  const [contentUniverseLoading, setCUL] = useState(false);

  const [contentUniverseData, setCUD] = useState<CUContentType[]>([]);
  const [cuContentTagIDs, setCUContentTagIDs] = useState<Set<number>>(new Set());
  const [cuSelectedUITag, setCUSelectedUITag] = useState<number>(0);

  let cuInitialSelectedUITag: number;

  /*   console.log("Selected IDs", selectedTagsIds);
  console.log("Filtered Rules", filteredRules); */

  //Get all the tags on the first load
  useEffect(() => {
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;
    getAllTags();
  }, []);

  const getAllTags = async () => {
    setLoadingTags(true);
    activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE && setCUL(true);

    const res = await getAllTagsRequest();

    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase()) {
      if (res.tags) {
        let tags: ContentAITags = res.tags;

        Object.keys(tags).forEach((category) => {
          for (let tag of tags[category]) {
            if (selectedTags[contentAIModule].includes(tag.id)) {
              tag.selected = true;
            }
          }
        });

        updateSelectedUITags(tags);
        setAllTags(tags);

        activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE && getAppliedContent(selectedTags[contentAIModule]);
      }
    }
    contentUniverseLoading && setCUL(false);
    setLoadingTags(false);
  };

  const getAppliedContent = async (selectedTagsIds: number[], updateTags?: boolean) => {
    //console.log("getAppliedContent", { selectedTagsIds });
    //setLoadingApply(true);
    //let selectedTagsIds = getCUSelectedTagsIds();
    //console.log("Final Selected Tag IDs", selectedTagsIds);
    const res = await addRulesToPromptRequest({ tags: selectedTagsIds, prompt: promptID, rule_type: contentAIModule }, dispatch);

    setCUL(false);
    if (res?.status === globalConstant.SUCCESS.toLocaleLowerCase() || res?.status === globalConstant.SUCCESS) {
      updateTags && updateSelectedUITags(allTags);
      let { content, content_ids }: { content: CUContentType[]; content_ids: number[] } = res;

      //console.log({ content_ids, content });
      let contentTagIds: Set<number> = new Set<number>();

      if (content) {
        let selectedTagId = cuInitialSelectedUITag; //selectedTagsIds[0];
        //console.log("getAppliedContent: ", selectedTagId);

        //convert content_ids to numbers if they are in string
        content_ids = content_ids.map((item) => Number(item));

        content.forEach((item) => {
          if (item.tags.includes(selectedTagId)) item.visible = true;
          else item.visible = false;

          item.tags.forEach((tagId) => contentTagIds.add(tagId));

          if (content_ids?.includes(item.id)) item.selected = true;
        });
      }
      setCUContentTagIDs(contentTagIds);
      content && setCUD(content);
      //content && setCUD(data.content);
      //console.log({ allTags, content });
    }
  };

  //Fetch Rules based on selected Tags
  /**
   * {
   *    brand: [1, 2],
   *    region: [3],
   *    channel: [2]
   * }
   */
  useEffect(() => {
    const getFilteredRules = async () => {
      setLoadingRules(true);
      const res = await getFilteredRulesRequest(selectedTagsIds, contentAIModule, dispatch);

      if (res) {
        setFilteredRules(res);
      }
      setLoadingRules(false);
    };

    if (activeModule !== CONTENT_AI_MODULES.CONTENT_UNIVERSE) {
      //if (getSelectedTagsIds().length)
      //Don't call API if selected tags are empty. Just remove all the rules
      if (Object.keys(selectedTagsIds).length) getFilteredRules();
      //else setFilteredRules([]);
    }
  }, [selectedTagsIds, refetchRules]);

  //Stores selected tag IDs which would be used when 'Apply' is clicked
  /**
   * {
   *    tags: [1, 2, 3], //These are the selected Tag Ids
   *    rule_type: "llm_compliance", //LLM Module where Apply was clicked
   *    prompt: 1
   * }
   */
  useEffect(() => {
    let checkedTags: { [key: string]: number[] } = {};
    Object.keys(allTags).forEach((category) => {
      checkedTags[category + "_id"] = [];

      allTags[category as keyof typeof allTags].forEach((tag) => (tag.selected ? checkedTags[category + "_id"].push(tag.id) : null));
    });
    setSelectedTagsIds(checkedTags);
  }, [allTags]);

  //For updating Array which populates UI Tags
  const updateSelectedUITags = (allTags: ContentAITags) => {
    let checkedTags: ContentAITag[] = [];
    Object.keys(allTags).forEach((category) =>
      allTags[category as keyof typeof allTags].forEach(
        (tag) => (tag.selected ? checkedTags.push(tag) && !cuInitialSelectedUITag && (cuInitialSelectedUITag = tag.id) : null) //cuInitialSelectedUITag stores the first UI tag for Selection
      )
    );
    setSelectedUITags(checkedTags);
  };

  /*   console.log("selectedUITags: ", selectedUITags);
  console.log("alltags:", allTags); */

  const getSelectedTagsIds = () => {
    return selectedUITags.map((tag) => tag.id);
  };

  const getCUSelectedTagsIds = () => {
    let Ids: number[] = [];
    Object.entries(selectedTagsIds).map(([category, tags]) => Ids.push(...tags));
    return Ids;
  };

  const cancelCUScreen = () => {
    dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.LLM.LLMBasecomponent.notification.cancelled"])));
    dispatch(setActiveModuleActions(CONTENT_AI_MODULES.CONTENT_CREATOR));
  };

  const proceedWithSelectedContent = async () => {
    const selectedContentIds = contentUniverseData.filter((item) => item.selected).map((item) => item.id);
    //console.log("Selected Content Ids", selectedContentIds);

    if (!selectedContentIds.length) {
      dispatch(setNotificationMsgAction("Please select at least one content to proceed."));
      return;
    }

    setLoadingProceed(true);

    //if (selectedContentIds.length) dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.REFINED_RESPONSE));
    const res = await createEnrichPromptRequest(promptID, "content_universe", selectedContentIds);

    setLoadingProceed(false);
    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      return;
    } else {
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.contentai.LLM.LLMBasecomponent.notification.rulesapplied"])));
      console.log("Content Universe Rules applied!");

      dispatch(setActiveModuleActions(CONTENT_AI_MODULES.CONTENT_CREATOR));
      dispatch(setCurrentActiveStepActions(CONTENT_CREATOR_STEPS.REFINED_RESPONSE));
      getEnrichedResult();
    }
  };

  const getEnrichedResult = async (tagsSet?: boolean) => {
    dispatch(setResponseLoaderFlagAction(true));
    clearGetResultTimer?.();

    const res = await getPromptResultRequest(promptID, true);

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      return;
    }

    //Set the selectedTags if not already set which is being tracked using tagsSet
    if (!tagsSet && res["selected_tags"]) {
      dispatch(setSelectedTagsAction(res["selected_tags"]));
    }

    if (res?.status === globalConstant.SUCCESS) {
      dispatch(setResponseLoaderFlagAction(false));
      dispatch(setResponseDataAction({ prompt: res.prompt, content: res.html_result, rules: res.rules }));
    } else {
      setGetResultTimer?.(
        setTimeout(() => {
          getEnrichedResult(true);
        }, 5000)
      );
    }
  };

  const getSlectedContentLength = () => {
    let selectedContentLength = 0;
    contentUniverseData.forEach((item) => item.selected && ++selectedContentLength);

    return (Number(selectedContentLength).toString().length === 1 ? "0" : "") + selectedContentLength;
  };

  const getTotalContentLength = () => {
    let noOfItems = contentUniverseData.length;
    return (Number(noOfItems).toString().length === 1 ? "0" : "") + noOfItems;
  };

  const areAllCUItemsSelected = () => {
    return Number(getSlectedContentLength()) === contentUniverseData.length;
  };

  const selectDeselectAllCUItems = () => {
    //If all items are selected, deselect all
    if (areAllCUItemsSelected()) {
      setCUD((prevCUD: CUContentType[]) => {
        return prevCUD.map((item) => {
          item.selected = false;
          return item;
        });
      });
    }
    //If some items are not selected, select all
    else {
      setCUD((prevCUD: CUContentType[]) => {
        return prevCUD.map((item) => {
          item.selected = true;
          return item;
        });
      });
    }
  };

  return (
    <div className="custom-row" style={{ background: "#fff", borderRadius: "10px" }}>
      <LLMLeftPane
        props={{
          allTags,
          setAllTags,
          selectedUITags,
          setSelectedUITags,
          getAllTags,
          getSelectedTagsIds,
          getCUSelectedTagsIds,
          loadingTags,
          contentAIModule,
          setCUD,
          updateSelectedUITags,
          setCUContentTagIDs,
          setCUL
        }}
      />
      <div className="col-9" style={{ padding: "0" }}>
        {activeModule !== CONTENT_AI_MODULES.CONTENT_UNIVERSE && (
          <div className="custom-row right-container" /* style={{ marginTop: "20px" }} */>
            <LLMFilteredTags props={{ allTags, setAllTags, selectedUITags, setSelectedUITags }} />
            <LLMRulesComponent props={{ filteredRules, getSelectedTagsIds, setRefetchRules, loadingRules, contentAIModule }} />
          </div>
        )}
        {activeModule === CONTENT_AI_MODULES.CONTENT_UNIVERSE && (
          <div className="custom-row cu-right-container" /* style={{ marginTop: "20px" }} */>
            {!contentUniverseLoading ? (
              <>
                {/*  <div className="custom-row cu-filtered-tags">
                  <CUFilteredTags props={{ selectedUITags, setCUD, setCUSelectedUITag }} />
                </div> */}
                <LLMFilteredTags props={{ allTags, setAllTags, selectedUITags, setSelectedUITags }} />

                {!!selectedUITags.length && !!contentUniverseData.length && (
                  <>
                    <div style={{ display: "flex", gap: "15px", padding: "0 15px" }}>
                      <LabelCheckBox
                        //label={areAllCUItemsSelected() ? "De-Select All" : "Select All"}
                        label={t(
                          I18N_MODULE_KEYS[`content.transcreation.process.storyboard.text.${areAllCUItemsSelected() ? "deselectall" : "selectall"}`]
                        )}
                        labelStyle={{
                          textTransform: "capitalize",
                          whiteSpace: "pre-line",
                          lineHeight: "24px",
                          alignItems: "normal",
                          color: "#004ea2",
                          fontWeight: 600,
                          cursor: "pointer"
                        }}
                        checkboxStyle={{ width: "20px", height: "20px", marginRight: "15px", marginTop: "3px" }}
                        checked={areAllCUItemsSelected()}
                        onChange={selectDeselectAllCUItems}
                      />
                    </div>
                    <hr style={{ margin: "5px 0" }} />
                  </>
                )}

                <div className="cu-content-container">
                  <div className="col-12">
                    <CUContentsComponent
                      props={{
                        contentUniverseData,
                        setCUD,
                        cuContentTagIDs,
                        cuSelectedUITag,
                        selectedUITags,
                        getAppliedContent,
                        getCUSelectedTagsIds
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="cu-content-footer">
                  {/* <div>
                    Selected | {getSlectedContentLength()}/{getTotalContentLength()}
                  </div> */}
                  <div>
                    {t(I18N_MODULE_KEYS["content.text.selected"])} | {getSlectedContentLength()}/{getTotalContentLength()}
                  </div>
                  <div className="cu-button-group">
                    <span className="closeBtn" onClick={cancelCUScreen}>
                      {/* Cancel */}
                      {t(I18N_MODULE_KEYS["content.button.cancel"])}
                    </span>
                    <span
                      className={`updateBtn ${(!promptID || !Number(getSlectedContentLength())) && "disabled"}`}
                      style={{ margin: 0 }}
                      onClick={proceedWithSelectedContent}
                    >
                      {loadingProceed && <LoadingOutlined style={{ marginRight: "5px" }} />}
                      {/* Proceed */}
                      {t(I18N_MODULE_KEYS["content.button.proceed"])}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
