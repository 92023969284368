import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Layout, Image, Button, Menu } from "antd";
import "./PdfEditDownload.css";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import {
  setEditableScreen,
  setLoadingforReportGeneration,
  setLoadingforSwitching,
  setPdfForReports,
  setReportGenerationType,
  setSlidesForReports,
} from "../../../../../Store/actions/_semanticSearchActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ModalLoaderForPDFGenerationRegenerate } from "../Modals/_ModalLoaderForPDFGenerationRegenerate";

import _localStorageService from "../../../../../Services/_localStorageService";
import { CheckCircleOutlined, CloseCircleOutlined, WindowsOutlined } from "@ant-design/icons";
import { semanticPDFUpdate } from "../../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { ModalForSwitchingFromPDFToPPT } from "../Modals/_ModalForSwitchingFromPDFToPPT";
import { SSE } from "sse.js";
export const PdfEditDownload = () => {
  const { reportSlides, loadingForReportGeneration, reportType, reportPdf, loadingForSwitching } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const [reportId, setReportId] = useState(reportPdf?.report_id);
  const [pdfId, setPdfId] = useState(reportPdf?.pdf_id);
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const accessToken = token || "";
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [content, setContent] = useState(reportPdf?.content);
  const editorRef = useRef<any>(null); // Creating a ref for the editor instance
  const [save, seiIsSave] = useState<Boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setmenuItems] = useState([]);
  console.log(reportPdf);
  useEffect(() => {
    const handlesavedata1 = async () => {
      console.log("Saved" + pdfId);
      if (editorRef.current) {
        let contentLocal = editorRef.current.getContent().replace(/\n/g, "");
        console.log(pdfId + "before entering updat api");
        const res = await semanticPDFUpdate({ pdf_id: pdfId, content: contentLocal });
        if (!res) {
          setContent(reportPdf?.content);
          dispatch(setNotificationMsgAction("Please try saving the changes again"));
          seiIsSave(false);
          return;
        } else {
          setContent(editorRef.current.getContent());
          dispatch(setPdfForReports(res?.data));
          seiIsSave(false);

          dispatch(setNotificationMsgAction("Changes saved successfully"));
        }
      }
    };
    if (save) {
      handlesavedata1();
    }
  }, [save]);

  console.log(content);
  console.log(content !== reportPdf?.content);
  console.log(pdfId);
  console.log(reportId);
  const handleRegenerate = async () => {
    console.log("click");
    dispatch(setLoadingforReportGeneration(true));
  };
  const handleUpdatedResponse = async () => {};
  const handleDownloadofPDF = async () => {
    setIsDownloadInProgress(true);
    const timeoutId = setTimeout(() => {
      closeSSEConnections(); // Close SSE connections regardless of event status
    }, 120000); // 80 seconds in milliseconds
    const closeSSEConnections = async () => {
      sse.close();
      clearTimeout(timeoutId); // Clear the timeout
      setIsDownloadInProgress(false);
      dispatch(setNotificationMsgAction("Please try downloading again"));
      console.log("SSE connections closed due to timeout.");
    };
    const apiUrl = `${apiurl}/document/report/export/pdf/${reportId}`;
    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };
    const payload = {
      user_id: "0",
    };
    const parseJSON = (data: any) => {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
    const sse = new SSE(apiUrl, {
      headers,
      payload: JSON.stringify(payload),
    });
    sse.addEventListener("end", (event: any) => {
      const jsonObject = event?.data;
      console.log(jsonObject);
      clearTimeout(timeoutId); // Clear the timeout

      downloadFile(jsonObject, jsonObject);

      setIsDownloadInProgress(false);
    });
    sse.addEventListener("error", (event: any) => {
      clearTimeout(timeoutId); // Clear the timeout
      setIsDownloadInProgress(false);
      dispatch(setNotificationMsgAction("Please try downloading again"));
    });
  };
  const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName || "file"); // Set the filename

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
      link.remove();
      dispatch(setLoadingforReportGeneration(false));
      dispatch(setEditableScreen(false));
      dispatch(setReportGenerationType(''));
      dispatch(setSlidesForReports({}));
      dispatch(setPdfForReports({}));
      dispatch(setLoadingforSwitching(false));
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handlesavedata = async () => {
    console.log("Saved" + pdfId);
    seiIsSave(true);
  };

  const tinyMCEPlugins =
    "anchor autolink charmap code codesample image link lists searchreplace table wordcount linkchecker preview save fullscreen quickbars pagebreak";

  const tinyMCEPremiumPlugins =
    "casechange export formatpainter pageembed tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate tableofcontents footnotes mergetags autocorrect typography inlinecss markdown";

  const tinyMCEToolbar = "undo redo | save";
  const handleMenuClick = (e: any) => {
    console.log(menuItems);
    setIsMenuOpen(false);

    // Check if slides exists and is truthy
    if (Object.keys(reportSlides).length !== 0) {
      dispatch(setReportGenerationType(e));
    }

    // Check if reportSlides is an empty object, undefined, or null
    else if (!reportSlides || Object.keys(reportSlides).length === 0) {
      dispatch(setLoadingforSwitching(true));
      console.log("Loading for report generation...");
    }
  };

  return (
    <>
      <Layout className="pptEditDownload layout">
        <div className="layout-padding">
          <div className="header">
            <div className="header-left">
              <span>Export to PDF</span>
              <span>|</span>
              <span
                className="change-text"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                CHANGE
                {isMenuOpen && (
                  <Menu
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      position: "absolute",
                      zIndex: 10000,
                      marginTop: "10px",
                      borderRadius: "10px",
                      fontSize: "10px",
                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        handleMenuClick("Powerpoint");
                      }}
                      style={{ fontSize: "10px", marginTop: "0" }} // Apply fontSize here
                    >
                      <Image src={PROJECT_ICONS.PPT} style={{ paddingRight: "10px" }} />
                      Powerpoint
                    </Menu.Item>
                  </Menu>
                )}
              </span>
            </div>
            <div className="header-right">
              <Image src={PROJECT_ICONS.REFRESH} />
              <span className="regenerate-text" onClick={handleRegenerate}>
                REGENERATE
              </span>
              <Button className="button" onClick={handleDownloadofPDF} loading={isDownloadInProgress}>
                DOWNLOAD
              </Button>
              <button className="button-primary" style={{ cursor: "not-allowed" }}>
                PRESENTATION
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" ,height:window.innerHeight < 700 ? 500 : 600,}}>
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            initialValue={content}
            onInit={(evt, editor) => {
              // This function is called when the editor is initialized
              editorRef.current = editor; // Assigning the editor instance to the ref
            }}
            init={{
              height: window.innerHeight < 700 ? 500 : 600,
              width: 1000,
              branding: false,
              save_onsavecallback: () => {
                handlesavedata();
              },
              save_oncancelcallback: () => {
                console.log("Save canceled");
                // onEditorChangesCancelCallback();
              },
              menubar: false,
              plugins:
                //"anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
                //"anchor autolink charmap codesample image imagetools link lists searchreplace table wordcount casechange export formatpainter pageembed linkchecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate tableofcontents footnotes mergetags autocorrect typography inlinecss markdown preview save print fullscreen quickbars pagebreak",
                tinyMCEPlugins,

              toolbar:
                //"undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                //"undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | align lineheight | link image table | pagebreak | checklist numlist bullist indent outdent | charmap | removeformat | spellcheckdialog | code | fullscreen preview save print",
                tinyMCEToolbar,

              toolbar_mode: "sliding",
              content_style:
                `.heading {
          text-align: center;
      }` +
                `.heading p {
          margin: 5px;
      }` +
                `.heading p:first-child {
          font-size: 18px;
          padding: 0;
          margin-top: 10px;
          margin-bottom: 5px;
          color: #003399;
      }` +
                `.heading p:nth-child(2) {
          font-size: 16px;
          color: #227acb;
      }` +
                `.padding {
          padding: 3px 7px;
      }` +
                `.query {
          padding: 5px;
          background-color: #e0eaf5;
      }
      ` +
                `.query p:first-child {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
      }` +
                `.query p:nth-child(2) {
          margin: 0;
          font-style: italic;
          font-size: 15px;
      }` +
                `.answer {
          margin: 10px 0px;
          padding: 10px;
          background-color: #e0eaf5;
      }` +
                `.answer p:first-child {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
      }` +
                `.answer p:nth-child(2) {
          margin: 0;
          font-style: italic;
          font-size: 16px;
      }` +
                `.reference p {
          font-size: 12px;
          margin-top: 20px;
          color: #227acb;
      }` +
                `.reference p:first-child {
          margin-top: 20px;
          font-size: 16px;
          font-weight: bold;
          color: black;
      }` +
                `.additional-info {
          margin-right: 5px;
          margin-top: 30px;
      }` +
                ` .additional-info p {
          font-style: italic;
          margin: 0;
          color: #5a5a5a;
          padding: 2px;
          font-size: 12px;
      }` +
                `.gap {
          margin-left: 15px !important;
      }` +
                `.border-dotted-add {
          border: 1px solid #8080807d;
      }` +
                ` .border-dotted {
          border: 2px solid #8080807d;
      }`,
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", marginRight: "30px" }}>
          <button
            className="go-back"
            onClick={() => {
              dispatch(setLoadingforReportGeneration(false));
              dispatch(setEditableScreen(false));
              dispatch(setReportGenerationType(""));
              dispatch(setSlidesForReports({}));
              dispatch(setPdfForReports({}));
              dispatch(setLoadingforSwitching(false));
            }}
          >
            Go Back
          </button>
        </div>
      </Layout>
      {loadingForReportGeneration && (
        <ModalLoaderForPDFGenerationRegenerate setContent={setContent} setReportId={setReportId} setPdfId={setPdfId} editorRef={editorRef} />
      )}
      {loadingForSwitching && <ModalForSwitchingFromPDFToPPT />}
    </>
  );
};
