import { FC } from "react";
import { Button } from "antd";

type IProps = {
  type?: string | undefined;
  loading: boolean;
  btnText: string;
  onClick?: () => void;
  className?: string;
  children?: any;
  style?: any;
  disabled?: boolean;
};

const CustomButtonForApiCalling: FC<IProps> = ({ loading, btnText, onClick, className, children, style, disabled }) => {
  return (
    <Button
      onClick={onClick}
      type="primary"
      className={className || ""}
      loading={loading}
      // style={{ ...style, background: !disabled && "rgb(5, 70, 165)", color: !disabled && "white" }}
      style={{ ...style, background: "rgb(5, 70, 165)", color: "white" }}
      disabled={disabled}
    >
      {btnText}
    </Button>
  );
};

export { CustomButtonForApiCalling };
