import { FC, useState } from "react";
import { Button, Col, Modal, Row, Space, Typography } from "antd";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
const { Paragraph, Title } = Typography;

type IProps = {
  showModal: boolean;
  setShowModal: (v: boolean, isSave: boolean) => void;
};

export const RegenerateModal: FC<IProps> = ({ showModal, setShowModal }) => {
  const {t}=useTranslation();
  return (
    <Modal
    centered
      title={
        <div
          style={{
            width: "100%",
            cursor: "move"
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
         {t(I18N_MODULE_KEYS["content.transcreation.modal.regneration"])}
        </div>
      }
      open={showModal}
      onCancel={() => setShowModal(false, false)}
      footer
    >
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }}>
          <Paragraph
            // level={1}
            style={{
              fontSize: "12px",
              marginBottom: "0px"
            }}
          >
  {t(I18N_MODULE_KEYS["content.transcreation.modal.regenerationdescription"])}
            </Paragraph>
        </Col>

        <Col xs={{ span: 24 }} style={{ justifyContent: "end" }}>
          <Space align="end">
            <Button onClick={() => setShowModal(false, false)} >
            {t(I18N_MODULE_KEYS["content.button.no"])}
            </Button>
            <Button type="primary" onClick={() => setShowModal(false, true)} style={{ background: "rgb(5, 70, 165)", color: "white" }}>
            {t(I18N_MODULE_KEYS["content.button.yesproceed"])}
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};
