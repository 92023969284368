import Icon from "../common/Icon";

const OBJECT_TYPES = ["image", "video"];

const focusIcon = <Icon name="focus" />;
const saveIcon = <Icon name="save" />;

const STYLE = {
  width100: { width: "100%" },
  margin010100: { margin: "0 10px 10px 0" },
  colorButton: { position: "relative", marginTop: "15px" },
  button: { position: "relative", marginTop: "15px", textAlign: "center" },
  groupSelection: { display: "flex", flexWrap: "wrap", marginTop: "15px", position: "relative" }
};

const DICT = {
  all: "Elements",
  image: "Images",
  video: "Videos",
  shape: "Shapes",
  sticker: "Stickers",
  gif: "GIFs",
  lottie: "Lottie"
};

export { OBJECT_TYPES, focusIcon, saveIcon, STYLE, DICT };
