import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { setRefetchContentHistoryListAction, setSelectedEmailDocAction } from "../../../../Store/actions/_contentTranscreationActions";
import { deleteFileRequest, editEmailDocumentRequest, saveTranslateLanguageRequest } from "../../../../Store/requests/_contentTranscreationRequests";
import { MenuItem } from "./modals";

export const enumDocStatus = {
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  NOT_STARTED: "Not Started",
  FAILED: "Failed",
  ACTION: "Delete",
  NOT_APPLICABLE: "Not Applicable"
};

export const enumHistoryIndex = {
  HTML: 2,
  STORYBOARD: 3,
  IMAGE: 4,
  VIDEO: 5,
  ACTION: 6
};

export function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}

export const DeleteFile = async (email_id: number, dispatch: any, t: any, selectedEmailDoc: any) => {
  dispatch(setSelectedEmailDocAction({ ...selectedEmailDoc, delete: false }));

  const res = await deleteFileRequest(email_id);

  if (!res) return;

  // dispatch(setNotificationMsgAction("File has been successfully deleted."));
  dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.history.core.notification.filedeletionsuccess"])));

  dispatch(setRefetchContentHistoryListAction(true));
};

export const RenameFile = async (email_id: number, file_name: string, dispatch: any, t: any) => {
  const res = await editEmailDocumentRequest({ email_doc_id: email_id, filename: file_name });

  if (!res) return;

  // dispatch(setNotificationMsgAction("File has been successfully renamed."));
  dispatch(setNotificationMsgAction(`${t(I18N_MODULE_KEYS["content.history.core.notification.filerenamesucess"])}`));

  dispatch(setRefetchContentHistoryListAction(true));
};

export const handleDownloadVideo = async (videoUrl: string, dispatch: any, t: any) => {
  try {
    const videoRequest = new Request(videoUrl);
    fetch(videoRequest).then(() => {
      const link = document.createElement("a");
      link.href = videoUrl;
      link.setAttribute("download", videoUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        // dispatch(setNotificationMsgAction("Video has been successfully downloaded."));
        dispatch(setNotificationMsgAction(`${t(I18N_MODULE_KEYS["content.history.core.notification.videodownloadsucess"])}`));
      }, 100);
    });
  } catch (error) {
    // dispatch(setNotificationMsgAction("Error occured while downloading video."));
    dispatch(setNotificationMsgAction(`${t(I18N_MODULE_KEYS["content.history.core.notification.videodownloaderror"])}`));
    console.error(error);
  }
};
