import React, { FC, useMemo, useState } from "react";
import axios from "axios";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Row, Typography, Input, Image, Upload, Table, Spin, Modal } from "antd";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { FolderOutlined, SearchOutlined, EditOutlined, DeleteOutlined, UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";
import { getFileManagementDocuments, handleCreateFolder, uploadFilesToFMS } from "../../../../Store/requests/_semanticSearchRequests";
import _localStorageService from "../../../../Services/_localStorageService";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { ComponentFileProps, Folder, SEMANTIC_SEARCH_CARD, SEMANTIC_SEARCH_MODULES } from "../Core/constants";
import { audiocolumnsConfig, columnsConfig, imagecolumnsConfig, videocolumnsConfig } from "./FileSystemConstants/_ColumnsConfig";
import { ChatWithDocumentComponent } from "../ChatWithDocument/_ChatWithDocumentComponent";
import { ZeroFoldersBackend } from "./_FileSystemFolderComponents/_ZeroFoldersBackend";
import { LeftCardForNewFolderCreation } from "./_FileSystemFolderComponents/_LeftCardForNewFolderCreationScreen";
import { LeftCardDisplayingFolders } from "./_FileSystemFolderComponents/_LeftCardDisplayingFolders";
import { RightCardNoFilesAnimation } from "./_FileSystemFolderComponents/_RightCardNoFilesAnimation";
import { RightCardHeaderFilesSaving } from "./_FileSystemFolderComponents/_RightCardHeaderFilesSaving";
import { RightCardHeaderFileUploadScreen } from "./_FileSystemFolderComponents/_RightCardHeaderFileUploadScreen";
import { RightCardDisplayingPathandSubFolder } from "./_FileSystemFolderComponents/_RightCardDisplayingPathandSubFolder";
import { ModalForDeletingFolder } from "./_FileSystemFolderComponents/ModalsForFileSystem/_ModalForDeletingFolder";
import { ModalForCreatingSubFolder } from "./_FileSystemFolderComponents/ModalsForFileSystem/_ModalForCreatingSubFolder";

const { Paragraph } = Typography;

export const FileSystemFolders: React.FC<ComponentFileProps> = ({ folderData, setFolderData, foldersLength, setFoldersLength }) => {
  const { filecardType } = useSelector((state: any) => state?.semanticSearchReducer);
  const { i18n, t } = useTranslation();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const [createnewfolderstatuslocal, setCreateNewFolderStatuslocal] = useState<Boolean>(false);
  const [uploadfilestatus, setNewFileStatus] = useState<Boolean>(false);
  const token = _localStorageService.getAccessToken();
  const firstFolderName = folderData?.length > 0 ? folderData[0]?.name : "";
  const [selectedFolder, setselectedFolder] = useState<string>(firstFolderName);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [inputvalue, setinputvalue] = useState<string>("");
  const [selectedFolderTable, setselectedFolderTable] = useState<Folder>(folderData ? folderData[0] : []);
  const [pathName, setPathName] = useState<any[]>([folderData ? folderData[0] : []]);
  const [dataSource, setDataSource] = useState<any[]>([...(selectedFolderTable?.folders || []), ...(selectedFolderTable?.document || [])]);
  const [issavingFolder, setissavingFolder] = useState<boolean>(false);
  const [issavingFiles, setissavingFiles] = useState<Boolean>(false);
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filename, setFileName] = useState<string>("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isOpenArr, setIsOpenArr] = useState(Array(selectedFolderTable?.document?.length).fill(false));
  const [showModalforFolder, setShowModalforFolder] = useState<boolean>(false);
  const [showModalforFile, setShowModalforFile] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [ismenuopen, setisMenuOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null); // Explicitly specify the type
  const [selectedOption, setSelectedOption] = useState(0); // Default selection is 'Quick'
  const [modalForSubFolder, setModalForSubFolderTrue] = useState<boolean>(false);
  const [inputValueForSubFolder, setInputValueForSubFolder] = useState("");

  const handleInputChangeForSubFolder = (event: any) => {
    setInputValueForSubFolder(event.target.value);
  };

  useEffect(() => {
    const handleTypeChange = () => {
      switch (filecardType) {
        case SEMANTIC_SEARCH_CARD.DOCUMENTS:
          setIsOpenArr(Array(selectedFolderTable?.document?.length).fill(false));
          setDataSource([...(selectedFolderTable?.folders || []), ...(selectedFolderTable?.document || [])]);
          break;
        case SEMANTIC_SEARCH_CARD.AUDIOS:
          setIsOpenArr(Array(selectedFolderTable?.audio?.length).fill(false));
          setDataSource([...(selectedFolderTable?.folders || []), ...(selectedFolderTable?.audio || [])]);
          break;
        case SEMANTIC_SEARCH_CARD.IMAGES:
          setIsOpenArr(Array(selectedFolderTable?.image?.length).fill(false));
          setDataSource([...(selectedFolderTable?.folders || []), ...(selectedFolderTable?.image || [])]);
          break;
        case SEMANTIC_SEARCH_CARD.VIDEOS:
          setIsOpenArr(Array(selectedFolderTable?.video?.length).fill(false));
          setDataSource([...(selectedFolderTable?.folders || []), ...(selectedFolderTable?.video || [])]);
          break;
        default:
          setIsOpenArr([]);
          setDataSource([]);
          break;
      }
    };
    handleTypeChange();
  }, [filecardType]);

  const handleToggle = (option: any) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        // Click detected outside the modal, close the modal here
        setIsOpenArr(Array(selectedFolderTable?.document?.length).fill(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent form submission, if using within a form
        if (buttonRef.current) {
          buttonRef.current.click(); // Trigger button click event
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const showDrawer = (s: string) => {
    setFileName(s);
    setDrawerVisible(true);
  };

  const handleDeleteChildFolder = async (documentId: any) => {
    const accessToken = token || "";
    const url = `${apiurl}`;

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json",
        },
        data: {
          foldername: documentId,
          user_id: "0",
        },
      });

      if (response.status === 204) {
        const folderDatalocal = await getFileManagementDocuments();
        setFolderData(folderDatalocal?.data);
        setFoldersLength(folderDatalocal?.data?.length);

        const foundFolder = findFolderByPath(folderDatalocal?.data);
        setselectedFolderTable(foundFolder);
        handleDataSource(foundFolder);

        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.folderdeletion"])));
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.errorfolderdeletion"])));
    }
  };

  const handleDeleteDocument = async (documentId: any) => {
    setSelectedOption(0);

    const accessToken = token || "";
    console.log(documentId);
    const url = `${apiurl}`;

    const response = await axios.delete(url, {
      headers: {
        Authorization: accessToken,
        "Content-Type": "application/json",
      },
      data: {
        filename: documentId,
        user_id: "0",
      },
    });
    if (response.status === 204) {
      const folderDatalocal = await getFileManagementDocuments();
      setFolderData(folderDatalocal?.data);
      setFoldersLength(folderDatalocal?.data?.length);
      const foundFolder = findFolderByPath(folderDatalocal?.data);

      if (!foundFolder) {
        setCreateNewFolderStatuslocal(false);
        setSelectedFiles([]);
        setNewFileStatus(false);
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.filedeletion.notupdated"])));
        setinputvalue("");
      }
      // Update selectedFolderTable and dataSource if a folder with matching name is found
      if (foundFolder) {
        setselectedFolderTable(foundFolder);
        handleDataSource(foundFolder);
      }
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      setNewFileStatus(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.filedeletion"])));
      setinputvalue("");
    } else {
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      setNewFileStatus(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.errorfiledeletion"])));
      setinputvalue("");
      setSelectedRecord("");
    }
  };

  const handleDeleteFolder = async (documentId: any) => {
    const accessToken = token || "";
    console.log(documentId);
    const url = `${apiurl}`;

    const response = await axios.delete(url, {
      headers: {
        Authorization: accessToken,
        "Content-Type": "application/json",
      },
      data: {
        foldername: documentId,
        user_id: "0",
      },
    });
    if (response.status === 204) {
      const folderDatalocal = await getFileManagementDocuments();
      setFolderData(folderDatalocal?.data);
      setFoldersLength(folderDatalocal?.data?.length);
      const foundFolder = folderDatalocal?.data[0];
      if (!foundFolder) {
        setCreateNewFolderStatuslocal(false);
        setSelectedFiles([]);
        setNewFileStatus(false);
        dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.folderdeletion.notupdated"])));
        setinputvalue("");
      }
      // Update selectedFolderTable and dataSource if a folder with matching name is found
      if (foundFolder) {
        setselectedFolderTable(foundFolder);
        setselectedFolder(foundFolder?.name);
        setPathName([foundFolder]);
        handleDataSource(foundFolder);
      }
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      setNewFileStatus(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.folderdeletion"])));
      setinputvalue("");
    } else {
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      setNewFileStatus(false);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.errorfolderdeletion"])));
      setinputvalue("");
    }
  };
  console.log(selectedFolderTable);

  const getColumns = (cardType: string) => {
    if (cardType === SEMANTIC_SEARCH_CARD?.DOCUMENTS) {
      return columnsConfig(
        t,
        PROJECT_ICONS,
        isOpenArr,
        setIsOpenArr,
        showDrawer,
        setShowModalforFile,
        showModalforFile,
        handleDeleteDocument,
        selectedRecord,
        setSelectedRecord,
        modalRef,
        handlestateofChildFolder,
        handleDeleteChildFolder
      );
    } else if (cardType === SEMANTIC_SEARCH_CARD?.IMAGES) {
      // Define and return imageColumnsConfig similarly
      return imagecolumnsConfig(
        t,
        PROJECT_ICONS,
        isOpenArr,
        setIsOpenArr,
        showDrawer,
        setShowModalforFile,
        showModalforFile,
        handleDeleteDocument,
        selectedRecord,
        setSelectedRecord,
        modalRef,
        handlestateofChildFolder,
        handleDeleteChildFolder
      );
    } else if (cardType === SEMANTIC_SEARCH_CARD?.AUDIOS) {
      return audiocolumnsConfig(
        t,
        PROJECT_ICONS,
        isOpenArr,
        setIsOpenArr,
        showDrawer,
        setShowModalforFile,
        showModalforFile,
        handleDeleteDocument,
        selectedRecord,
        setSelectedRecord,
        modalRef,
        handlestateofChildFolder,
        handleDeleteChildFolder
      );
    } else if (cardType === SEMANTIC_SEARCH_CARD?.VIDEOS) {
      return videocolumnsConfig(
        t,
        PROJECT_ICONS,
        isOpenArr,
        setIsOpenArr,
        showDrawer,
        setShowModalforFile,
        showModalforFile,
        handleDeleteDocument,
        selectedRecord,
        setSelectedRecord,
        modalRef,
        handlestateofChildFolder,
        handleDeleteChildFolder
      );
    }
  };

  const handleFolderNameChange = (e: any) => {
    setinputvalue(e.target.value);
    setSelectedOption(0);
  };
  const handleCancelCreateFolder = async () => {
    if (foldersLength === 999999) {
      setFoldersLength(0);
      setCreateNewFolderStatuslocal(false);
      setNewFileStatus(false);
      setinputvalue("");
      setSelectedOption(0);
    }
    setCreateNewFolderStatuslocal(false);
    setNewFileStatus(false);
    setinputvalue("");
    setSelectedOption(0);
  };
  const handlestateofselectedFolder = async (foldername: string, folder: Folder) => {
    setissavingFiles(false);

    setSelectedFiles([]);
    setNewFileStatus(false);
    setselectedFolder(foldername);
    setselectedFolderTable(folder);
    setPathName([folder]);
    handleDataSource(folder);

    setSelectedOption(0);
  };

  const handlestateofChildFolder = async (foldername: string, folder: Folder) => {
    setissavingFiles(false);

    setSelectedFiles([]);
    setNewFileStatus(false);
    setselectedFolderTable(folder);
    setPathName([...pathName, folder]);
    handleDataSource(folder);

    setSelectedOption(0);
  };

  const handleSaveCreateFolder = async () => {
    if (inputvalue === "") {
      if (foldersLength === 999999) {
        setFoldersLength(0);
      }
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.noinput"])));
      setNewFileStatus(false);
      setinputvalue("");
      setSelectedOption(0);

      return;
    }
    setissavingFolder(true);
    const res = await handleCreateFolder({ folder_name: inputvalue });
    if (!res) {
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      dispatch(setNotificationMsgAction("Folder already exists"));
      setNewFileStatus(false);
      setinputvalue("");
      setissavingFolder(false);
      setSelectedOption(0);

      return;
    }
    console.log("this is it");
    console.log();
    console.log(res);
    console.log("this is it");
    const folderDatalocal = await getFileManagementDocuments();
    if (!folderDatalocal) {
      if (foldersLength === 999999) {
        setFoldersLength(0);
      }
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.foldercannotbecreated"])));
      setNewFileStatus(false);
      setinputvalue("");
      setissavingFolder(false);
      setSelectedOption(0);
      setPathName([]);

      return;
    }
    setFolderData(folderDatalocal?.data);
    setFoldersLength(folderDatalocal?.data?.length);
    const foundFolder = folderDatalocal?.data.find((folder: any) => folder.name === inputvalue);
    setselectedFolder(inputvalue);
    setselectedFolderTable(foundFolder);
    setPathName([foundFolder]);
    handleDataSource(foundFolder);

    setissavingFolder(false);
    setCreateNewFolderStatuslocal(false);
    setSelectedFiles([]);
    dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.foldercreation"])));
    setNewFileStatus(false);
    setinputvalue("");
    setSelectedOption(0);
  };

  function findFolderByPath(folderdataofind: any) {
    let currentFolder = folderdataofind;

    for (let i = 0; i < pathName.length; i++) {
      let found = false;
      for (let j = 0; j < currentFolder.length; j++) {
        if (currentFolder[j].name === pathName[i].name) {
          // Assuming pathName is an array of names, not objects
          found = true;
          if (i === pathName.length - 1) {
            console.log(currentFolder[j]);
            console.log("This is the new current folder");
            pathName[i] = currentFolder[j];
            return pathName[i];
          }
          pathName[i] = currentFolder[j];
          currentFolder = currentFolder[j].folders; // Update currentFolder to traverse deeper
        }
      }
      if (!found) {
        return pathName[0]; // Folder path not found
      }
    }
    return null;
  }
  const handleUploadDocument = async (num: number) => {
    if (selectedFiles.length === 0) {
      setissavingFiles(false);
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      setNewFileStatus(false);
      setinputvalue("");
      setisMenuOpen(false);
      setSelectedOption(0);

      dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.nofileselected"])));
      return;
    }
    setissavingFiles(true);

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append("files", file.originFileObj);
    });
    const res = await uploadFilesToFMS(formData, pathName[pathName.length - 1].name, num);
    if (!res) {
      setissavingFiles(false);
      return;
    }
    setselectedFolder(pathName[0].name);
    console.log(res);

    const folderDatalocal = await getFileManagementDocuments();
    if (!folderDatalocal) {
      setissavingFiles(false);
      setCreateNewFolderStatuslocal(false);
      setSelectedFiles([]);
      setNewFileStatus(false);
      setinputvalue("");
      setisMenuOpen(false);
      setSelectedOption(0);

      setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.filecannotbeuploaded"]));
      return;
    }
    setFolderData(folderDatalocal?.data);
    setFoldersLength(folderDatalocal?.data?.length);
    const foundFolder = findFolderByPath(folderDatalocal?.data);

    // Update selectedFolderTable and dataSource if a folder with matching name is found
    if (foundFolder) {
      setselectedFolderTable(foundFolder);
      handleDataSource(foundFolder);
    }
    dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.filesystem.notification.fileuploaded"])));
    setissavingFiles(false);
    setisMenuOpen(false);
    setSelectedOption(0);

    setCreateNewFolderStatuslocal(false);
    setSelectedFiles([]);
    setNewFileStatus(false);
    setinputvalue("");
  };
  const handleUploadDocumentreverse = async () => {
    setNewFileStatus(true);
    setSelectedOption(0);
  };
  const handleCreateNewFolder = async () => {
    setissavingFiles(false);
    setFoldersLength(999999);
    setCreateNewFolderStatuslocal(true);
    setNewFileStatus(true);
    setSelectedOption(0);
  };

  const renderFileList = (): JSX.Element => {
    return (
      <>
        {selectedFiles.map((file) => (
          <div
            key={file.uid}
            style={{
              padding: "1px",
              background: "rgb(241 245 249)",
              borderRadius: "3px",
              margin: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ marginRight: "5px", padding: "5px" }}>{file.name}</span>
            <CloseCircleOutlined style={{ cursor: "pointer", color: "#1e4da1", padding: "5px" }} onClick={(e) => handleRemoveFile(e, file.uid)} />
          </div>
        ))}
      </>
    );
  };

  const handleRemoveFile = (e: any, uid: any) => {
    e.stopPropagation(); // Stop event propagation to prevent triggering the parent Upload component
    // Remove the file with the specified uid from selectedFiles
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
  };

  const handleToggleBetweenParentChildFolders = (folder: Folder) => {
    const index = pathName.findIndex((folderitem: Folder) => folderitem === folder);
    if (index !== -1) {
      const newPathName = pathName.slice(0, index + 1);
      setPathName(newPathName);
      setselectedFolderTable(folder);
      handleDataSource(folder);
    }
  };

  const handleCreateSUBFolder = async () => {
    const res = await handleCreateFolder({ folder_name: inputValueForSubFolder, parent_folder_id: pathName[pathName.length - 1].id });
    if (!res) {
      setInputValueForSubFolder("");
      setModalForSubFolderTrue(false);
      return;
    }
    const folderDatalocal = await getFileManagementDocuments();
    setFolderData(folderDatalocal?.data);
    setFoldersLength(folderDatalocal?.data?.length);

    const foundFolder = findFolderByPath(folderDatalocal?.data);
    setselectedFolderTable(foundFolder);
    handleDataSource(foundFolder);
    setInputValueForSubFolder("");

    setModalForSubFolderTrue(false);
  };

  const handleDataSource = async (foldertobemapped: Folder) => {
    if (filecardType === SEMANTIC_SEARCH_CARD.DOCUMENTS) {
      setDataSource([...foldertobemapped?.folders, ...foldertobemapped?.document]);
    } else if (filecardType === SEMANTIC_SEARCH_CARD.AUDIOS) {
      setDataSource([...foldertobemapped?.folders, ...foldertobemapped?.audio]);
    } else if (filecardType === SEMANTIC_SEARCH_CARD.IMAGES) {
      setDataSource([...foldertobemapped?.folders, ...foldertobemapped?.image]);
    } else if (filecardType === SEMANTIC_SEARCH_CARD.VIDEOS) {
      setDataSource([...foldertobemapped?.folders, ...foldertobemapped?.video]);
    }
  };
  return (
    <>
      {foldersLength === 0 ? (
        <>
          <ZeroFoldersBackend handleCreateNewFolder={handleCreateNewFolder} />
        </>
      ) : (
        <>
          <Col span={6}>
            <Card style={{ width: "100%", boxShadow: "0px 0px 10px #0000001F", opacity: "1", height: "530px" }}>
              {!createnewfolderstatuslocal ? (
                <>
                  <LeftCardDisplayingFolders
                    folderData={folderData}
                    setNewFileStatus={setNewFileStatus}
                    setCreateNewFolderStatuslocal={setCreateNewFolderStatuslocal}
                    handlestateofselectedFolder={handlestateofselectedFolder}
                    setShowModalforFolder={setShowModalforFolder}
                    selectedFolder={selectedFolder}
                  />
                </>
              ) : (
                <>
                  <LeftCardForNewFolderCreation
                    inputvalue={inputvalue}
                    handleFolderNameChange={handleFolderNameChange}
                    handleCancelCreateFolder={handleCancelCreateFolder}
                    issavingFolder={issavingFolder}
                    handleSaveCreateFolder={handleSaveCreateFolder}
                  />
                </>
              )}
            </Card>
          </Col>

          <Col span={18}>
            <Card style={{ width: "100%", boxShadow: "0px 0px 10px #0000001F", minHeight: "530px", opacity: "1" }}>
              {uploadfilestatus ? (
                issavingFiles ? (
                  <>
                    <RightCardHeaderFilesSaving selectedFiles={selectedFiles} createnewfolderstatuslocal={createnewfolderstatuslocal} />
                    {(selectedFolderTable?.document?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.DOCUMENTS) ||
                    (selectedFolderTable?.image?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.IMAGES) ||
                    (selectedFolderTable?.audio?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.AUDIOS) ||
                    (selectedFolderTable?.video?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.VIDEOS) ||
                    createnewfolderstatuslocal ? (
                      <>
                        <RightCardNoFilesAnimation />
                      </>
                    ) : (
                      <>
                        <div style={{ height: "400px", overflowY: "auto" }}>
                          <Table columns={getColumns(filecardType)} dataSource={dataSource} pagination={false} />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!createnewfolderstatuslocal ? (
                      <>
                        <RightCardHeaderFileUploadScreen
                          createnewfolderstatuslocal={createnewfolderstatuslocal}
                          selectedOption={selectedOption}
                          handleToggle={handleToggle}
                          handleUploadDocument={handleUploadDocument}
                          setSelectedFiles={setSelectedFiles}
                          renderFileList={renderFileList}
                          selectedFiles={selectedFiles}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {(selectedFolderTable?.document?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.DOCUMENTS) ||
                    (selectedFolderTable?.image?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.IMAGES) ||
                    (selectedFolderTable?.audio?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.AUDIOS) ||
                    (selectedFolderTable?.video?.length === 0 && filecardType === SEMANTIC_SEARCH_CARD.VIDEOS) ||
                    createnewfolderstatuslocal ? (
                      <>
                        <RightCardNoFilesAnimation />
                      </>
                    ) : (
                      <>
                        <div style={{ height: "400px", overflowY: "auto" }}>
                          <Table columns={getColumns(filecardType)} dataSource={dataSource} pagination={false} />
                        </div>
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <RightCardDisplayingPathandSubFolder
                    pathName={pathName}
                    setModalForSubFolderTrue={setModalForSubFolderTrue}
                    getColumns={getColumns}
                    dataSource={dataSource}
                    handleUploadDocumentreverse={handleUploadDocumentreverse}
                    handleToggleBetweenParentChildFolders={handleToggleBetweenParentChildFolders}
                  />
                </>
              )}
            </Card>
            {/* </Card> */}
          </Col>
        </>
      )}
      {drawerVisible ? (
        <>
          <ChatWithDocumentComponent
            drawervisible={drawerVisible}
            filenamefromnextinsight={filename}
            setdrawervisible={setDrawerVisible}
            setfilename={setFileName}
          />
        </>
      ) : (
        <></>
      )}
      {showModalforFolder ? (
        <>
          <ModalForDeletingFolder
            showModalforFolder={showModalforFolder}
            handleDeleteFolder={handleDeleteFolder}
            selectedFolder={selectedFolder}
            setShowModalforFolder={setShowModalforFolder}
          />
        </>
      ) : (
        <></>
      )}
      {modalForSubFolder ? (
        <>
          <ModalForCreatingSubFolder
            modalForSubFolder={modalForSubFolder}
            setModalForSubFolderTrue={setModalForSubFolderTrue}
            handleCreateSUBFolder={handleCreateSUBFolder}
            inputValueForSubFolder={inputValueForSubFolder}
            handleInputChangeForSubFolder={handleInputChangeForSubFolder}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
