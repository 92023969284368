import React, { useState } from "react";
import { Layout, Image, Row, Col, Input, Modal, Button, Menu } from "antd";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import "./PptEditDownload.css";
import Lottie from "lottie-react";
import { useDispatch } from "react-redux";
import {
  setEditableScreen,
  setLoadingforReportGeneration,
  setLoadingforSwitching,
  setPdfForReports,
  setReportGenerationType,
  setSlidesForReports,
} from "../../../../../Store/actions/_semanticSearchActions";
import { SSE } from "sse.js"; // Make sure to import or replace this with your actual SSE import
import _localStorageService from "../../../../../Services/_localStorageService";
import { useSelector } from "react-redux";
import { getUpdatedSlide, semanticPPTUpdate } from "../../../../../Store/requests/_semanticSearchRequests";
import { ModalLoaderForPptGeneration } from "../Modals/_ModalLoaderForPptGeneration";
import { ModalLoaderForPptGenerationRegenerate } from "../Modals/_ModalLoaderForPptGenerationRegenerate";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { setNotificationMsgAction } from "../../../../../Store/actions/_commonActions";
import { ModalForSwitchingFromPPTToPDF } from "../Modals/_ModalForSwitchingFromPPTToPDF";

const { TextArea } = Input;
const { Content } = Layout;

export const PptEditDownload: React.FC = () => {
  const { reportSlides, loadingForReportGeneration, reportType, reportPdf, loadingForSwitching } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const [slides, setSlides] = useState(reportSlides?.slides || []);
  const [outline, setOutline] = useState(reportSlides?.outline || "");
  const dispatch = useDispatch();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const accessToken = token || "";
  const [selectedSlid, setSelectedSlides] = useState(slides[0] || {});
  const [content, setContent] = useState(selectedSlid?.content || "");
  const [contentTitle, setContentTitle] = useState(selectedSlid?.title || "");
  const [save, setIsSaved] = useState(false);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setmenuItems] = useState([]);

  const handleContentChange = (e: any) => {
    const { value } = e.target;
    setContent(value);
  };

  const handleContentChangeForTitle = (e: any) => {
    const { value } = e.target;
    if (value?.length <= 98) {
      // Max rows is 9
      setContentTitle(value);
    }
    console.log(value.length);
  };
  const handleUpdatedResponse = async () => {
    const res = await semanticPPTUpdate({ slide_id: selectedSlid?.slide_id, title: contentTitle, content: content });
    if (!res) {
      dispatch(setNotificationMsgAction("Please try saving the changes again"));
      setContent(selectedSlid?.content);
      setContentTitle(selectedSlid?.title);
      return;
    }
    console.log(res);
    const resp = await getUpdatedSlide(selectedSlid?.slide_id);
    if (!resp) {
      dispatch(setNotificationMsgAction("Changes are saved but not displayed"));

      return;
    }
    console.log(resp);
    setSelectedSlides(resp?.data?.updated_content);
    setContent(resp?.data?.updated_content?.content);
    setContentTitle(resp?.data?.updated_content?.title);
    setSlides((prevSlides: any) => {
      return prevSlides.map((slide: any) => (slide.slide_id === resp?.data?.updated_content?.slide_id ? resp?.data?.updated_content : slide));
    });
    dispatch(setNotificationMsgAction("Changes are saved successfully"));
  };

  const handleSelectedSlide = (s: any) => {
    setContent(s.content || "");
    setContentTitle(s.title || "");
    setSelectedSlides(s);
  };

  const handleRegenerate = async () => {
    console.log("click");
    dispatch(setLoadingforReportGeneration(true));
  };

  const handleDownloadofSlide = async () => {
    setIsDownloadInProgress(true);
    const timeoutId = setTimeout(() => {
      closeSSEConnections(); // Close SSE connections regardless of event status
    }, 120000); // 80 seconds in milliseconds

    const closeSSEConnections = async () => {
      sse.close();
      clearTimeout(timeoutId); // Clear the timeout
      setIsDownloadInProgress(false);
      dispatch(setNotificationMsgAction("Please try downloading again"));
      console.log("SSE connections closed due to timeout.");
    };

    const apiUrl = `${apiurl}/document/report/export/ppt`;
    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };
    const payload = {
      report_id: reportSlides?.report_id,
      user_id: "0",
    };

    const parseJSON = (data: any) => {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    const sse = new SSE(apiUrl, {
      headers,
      payload: JSON.stringify(payload),
    });

    sse.addEventListener("end", (event: any) => {
      const jsonObject = event?.data;
      console.log(jsonObject);
      downloadFile(jsonObject, jsonObject);
      clearTimeout(timeoutId); // Clear the timeout
      setIsDownloadInProgress(false);
    });

    sse.addEventListener("error", (event: any) => {
      clearTimeout(timeoutId);
      setIsDownloadInProgress(false);
      dispatch(setNotificationMsgAction("Please try downloading again"));
    });
  };

  const handlePresentationClick = async () => {
    const apiUrl = `${apiurl}/document/report/export/ppt`;
    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };
    const payload = {
      report_id: reportSlides?.report_id,
      user_id: "0",
    };

    const parseJSON = (data: any) => {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    const sse = new SSE(apiUrl, {
      headers,
      payload: JSON.stringify(payload),
    });

    sse.addEventListener("end", (event: any) => {
      const jsonObject = event?.data;
      console.log(jsonObject);
      if (jsonObject) {
        // Open the received URL in a new tab
        window.open(jsonObject, "_blank");
      }
    });

    sse.addEventListener("error", (event: any) => {
      // Handle SSE error
    });
  };

  const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName || "file"); // Set the filename

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
      link.remove();
      dispatch(setLoadingforReportGeneration(false));
      dispatch(setEditableScreen(false));
      dispatch(setReportGenerationType(''));
      dispatch(setSlidesForReports({}));
      dispatch(setPdfForReports({}));
      dispatch(setLoadingforSwitching(false));
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleMenuClick = (e: any) => {
    console.log(menuItems);
    setIsMenuOpen(false);

    // Check if slides exists and is truthy
    if (Object.keys(reportPdf).length !== 0) {
      dispatch(setReportGenerationType(e));
    }

    // Check if reportSlides is an empty object, undefined, or null
    else if (!reportPdf || Object.keys(reportPdf).length === 0) {
      dispatch(setLoadingforSwitching(true));
      console.log("Loading for report generation...");
    }
  };

  return (
    <>
      <Layout className="pptEditDownload layout">
        <div className="layout-padding">
          <div className="header">
            <div className="header-left">
              <span>Export to Powerpoint</span>
              <span>|</span>
              <span
                className="change-text"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                CHANGE
                {isMenuOpen && (
                  <Menu
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      position: "absolute",
                      zIndex: 10000,
                      marginTop: "10px",
                      borderRadius: "10px",
                      fontSize: "10px",
                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        handleMenuClick("PDF");
                      }}
                      style={{ fontSize: "10px", marginTop: "0" }} // Apply fontSize here
                    >
                      <Image src={PROJECT_ICONS.PDF} style={{ paddingRight: "10px" }} />
                      PDF
                    </Menu.Item>
                  </Menu>
                )}
              </span>
            </div>
            <div className="header-right">
              <Image src={PROJECT_ICONS.REFRESH} />
              <span className="regenerate-text" onClick={handleRegenerate}>
                REGENERATE
              </span>
              <Button className="button" onClick={handleDownloadofSlide} loading={isDownloadInProgress}>
                DOWNLOAD
              </Button>
              <button className="button-primary" onClick={handlePresentationClick}>
                PRESENTATION
              </button>
            </div>
          </div>
          <div className="main-content">
            <Row className="row">
              <Col span={6}>
                <div>
                  <span className="outline-header">Outline</span>
                  <div className="outline-container">{outline}</div>
                </div>
                <div className="pages-container">
                  <span className="pages-header">Pages</span>
                  {slides &&
                    slides.length > 0 &&
                    slides.map((slide: any, index: number) => (
                      <div
                        key={index}
                        className="page"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: slide === selectedSlid ? "4px solid #034EA2" : "",
                          borderRadius: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSelectedSlide(slide);
                        }}
                      >
                        <span className="pagetitle">{slide.title}</span>
                        <span className="pagecontent" dangerouslySetInnerHTML={{ __html: slide.content.replace(/\n/g, "<br>") }}></span>
                      </div>
                    ))}
                  {!slides || (slides.length === 0 && <div>No slides available</div>)}
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <Image src={PROJECT_ICONS.VECTOR8} style={{ height: "25px", marginBottom: "10px" }} />
                  <span className="edit-notice"> Click on text to edit</span>
                </div>
                {slides?.length === 0 ? (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h3>No content found</h3>
                  </div>
                ) : (
                  <div className="slide-container">
                    <TextArea
                      className="title"
                      value={contentTitle}
                      onChange={handleContentChangeForTitle}
                      style={{ marginTop: "5px" }}
                      autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                    <TextArea
                      className="slide-content"
                      value={content}
                      onChange={handleContentChange}
                      autoSize={{ minRows: 11, maxRows: 11 }}
                      style={{ marginTop: "0px" }}
                    />
                    {(content !== selectedSlid?.content || contentTitle !== selectedSlid?.title) && (
                      <div style={{ display: "flex", gap: "10px", marginTop: "10px", justifyContent: "flex-end", marginLeft: "100px" }}>
                        <div style={{ fontWeight: "bold" }}>Save Changes</div>
                        <CheckCircleOutlined
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                            handleUpdatedResponse();
                          }}
                        />
                        <span style={{ fontWeight: "bold" }}>|</span>
                        <CloseCircleOutlined
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                            setContent(selectedSlid?.content);
                            setContentTitle(selectedSlid?.title);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px" }}>
                  <button
                    className="go-back"
                    onClick={() => {
                      dispatch(setLoadingforReportGeneration(false));
                      dispatch(setEditableScreen(false));
                      dispatch(setReportGenerationType(""));
                      dispatch(setSlidesForReports({}));
                      dispatch(setPdfForReports({}));
                      dispatch(setLoadingforSwitching(false));
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
      {loadingForReportGeneration && (
        <ModalLoaderForPptGenerationRegenerate
          slides={slides}
          outline={outline}
          setOutline={setOutline}
          selectedSlid={selectedSlid}
          content={content}
          contentTitle={contentTitle}
          save={save}
          setSlides={setSlides}
          setSelectedSlides={setSelectedSlides}
          setContent={setContent}
          setContentTitle={setContentTitle}
          setIsSaved={setIsSaved}
          handleRegenerate={handleRegenerate}
        />
      )}
      {loadingForSwitching && <ModalForSwitchingFromPPTToPDF />}
    </>
  );
};

export default PptEditDownload;
