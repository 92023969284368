import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Lottie from "lottie-react";

import { Authorizer } from "../../Services/_authorizer";
import { UserOutlined, LockFilled } from "@ant-design/icons";

import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";

import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import _localStorageService from "../../Services/_localStorageService";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import { Row, Col, Form, Typography, Input, Button, Space, message } from "antd";

import "../../Assets/Css/Authentication/login.css";

const { Title, Text } = Typography;

const Signup = () => {
  const dispatch = useDispatch();
  const authorizer = new Authorizer();

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onSignupHandler = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields
      const { mail: email, password, confirmpassword: confirm_password } = values;

      console.log(email);
      const signupMessage = await authorizer.doSignup({ email, password, confirm_password }, dispatch);

      if (signupMessage === "Verification email has been sent. Please check your inbox") {
        navigate(ENUM_ROUTE_URLS.VERIFIEDPAGE); // Navigate to a specific route
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  useEffect(() => {
    if (location.state && location.state.from === "/dashboard") {
      message.success("Successfully logged out!");
    }
  }, [location]);

  return (
    <>
      <Form labelAlign="left" labelCol={{ span: 24 }} form={form} className="authentication container1">
        <Row gutter={16}>
          <Col span={12} className="section1">
            <Row className="animcontainer">
              <Col className="animation">
                <Lottie id="email" animationData={PROJECT_ICONS.EMAIL_ANIMATION} loop={true} />
              </Col>
              <Col className="animation-text-wrapper">
                <p>{t(I18N_MODULE_KEYS["content.login.text.customizeyouremailusingai"])}</p>
                <p>{t(I18N_MODULE_KEYS["content.login.text.convertyouremailintoHTMLCraftImagesand"])}</p>
                <p>{t(I18N_MODULE_KEYS["content.login.text.weavevideostoleavealasting"])}</p>
              </Col>
            </Row>
          </Col>
          <Col span={12} className="login-container">
            <Col className="form-conatiner">
              <Col className="second-content">
                <Title level={3}>Sign Up</Title>
              </Col>
              <Form.Item
                name="mail"
                label={`${t(I18N_MODULE_KEYS["content.login.text.email"])}`}
                rules={[
                  { required: true, message: `${t(I18N_MODULE_KEYS["content.login.text.emailisrequired"])}` },
                  {
                    type: "email",
                    message: `${t(I18N_MODULE_KEYS["content.login.text.pleaseenteravalidemailaddress"])}`
                  }
                ]}
              >
                <Input size="large" placeholder="Enter your email" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                label={`${t(I18N_MODULE_KEYS["content.login.text.password"])}`}
                name="password"
                rules={[
                  { required: true, message: `${t(I18N_MODULE_KEYS["content.login.text.passwordisrequired"])}` }
                  // { type: "password", message: "Please enter a valid password" }
                ]}
              >
                <Input.Password size="large" placeholder="Enter your password" prefix={<LockFilled />} />
              </Form.Item>
              <Form.Item
                label={`${t(I18N_MODULE_KEYS["content.login.text.confirmpassword"])}`}
                name="confirmpassword"
                rules={[
                  { required: true, message: `${t(I18N_MODULE_KEYS["content.login.text.pleaseenteravalidpassword"])}` }
                  // { type: "password", message: "Please enter a valid password" }
                ]}
              >
                <Input.Password size="large" placeholder="Confirm your password" prefix={<LockFilled />} />
              </Form.Item>
              <Button onClick={onSignupHandler} type="primary" block size="large">
                {/* Create an account */}
                {`${t(I18N_MODULE_KEYS["content.login.text.createanaccount"])}`}
              </Button>
              <Space className="login-space" direction="horizontal">
                <Text>
                  {t(I18N_MODULE_KEYS["content.signup.text.alreadyhaveanaccount"])}{" "}
                  <a href={ENUM_ROUTE_URLS.LOGIN}>{t(I18N_MODULE_KEYS["module.login"])}</a>
                </Text>
              </Space>
            </Col>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </>
  );
};

export default Signup;
