import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { DollarCircleOutlined, HistoryOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import _localStorageService from "../../Services/_localStorageService";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveCardType,
  setActiveModuleActions,
  setAdvancedFilter,
  setAdvancedFilterStatus,
  setCardDataCollection,
  setConversationId,
  setEncodedSemanticSearchId,
  setFilterListAction,
  setLandingScreen,
  setMessageIId,
  setNextId,
  setPreviousId,
  setProModeScreen,
  setProModeStatus,
  setSearchQuery,
  setSemanticSearchId
} from "../../Store/actions/_semanticSearchActions";
import { MED_USER_EMAIL, SEMANTIC_SEARCH_CARD, SEMANTIC_SEARCH_MODULES } from "../../Pages/Semantic Search/Components/Core/constants";
import { I18N_MODULE_KEYS } from "../i18n/core/constants";
import { useTranslation } from "react-i18next";
const Usericon: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);
  const {userDetails}= useSelector((state: any) => state?.authReducer);
  const handleLogout = () => {
    _localStorageService.removeToken();
    dispatch({ type: "USER_LOGOUT" });
    navigate(ENUM_ROUTE_URLS.LOGIN);
  };
  const handleUserHistoryScreen = () => {
    dispatch(setAdvancedFilterStatus(false));
    dispatch(setAdvancedFilter(false));
    dispatch(setSearchQuery(""));
    dispatch(setFilterListAction([]));
    dispatch(setEncodedSemanticSearchId(0));
    dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS))
    dispatch(setLandingScreen(true))
    dispatch(setSemanticSearchId(0));
    dispatch(setActiveModuleActions(SEMANTIC_SEARCH_MODULES.USER_HISTORY));
    dispatch(setLandingScreen(true)); 
    dispatch(setCardDataCollection([]))
    dispatch(setConversationId(0));
    dispatch(setMessageIId(0));

     dispatch(setPreviousId(0)) 
     dispatch(setNextId(0)) 
    dispatch(setProModeStatus(false)) 
       dispatch(setProModeScreen(false))
  };
  const handleUsageScreen = () => {
    dispatch(setAdvancedFilterStatus(false));
    dispatch(setLandingScreen(true))
    dispatch(setAdvancedFilter(false));
    dispatch(setSearchQuery(""));
    dispatch(setFilterListAction([]));
    dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS))
    dispatch(setCardDataCollection([]))
    dispatch(setConversationId(0));
    dispatch(setMessageIId(0));

    dispatch(setActiveModuleActions(SEMANTIC_SEARCH_MODULES.USAGE_BILLINGS_DETAILS));
    dispatch(setLandingScreen(true)); 
     dispatch(setPreviousId(0)) 
     dispatch(setNextId(0)) 
    dispatch(setProModeStatus(false)) 
       dispatch(setProModeScreen(false))
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <UserOutlined style={{ paddingRight: 10 }} />
        {t(I18N_MODULE_KEYS["header.menu.myprofile"])}
      </Menu.Item>
      {layoutSync.SEMANTIC_SEARCH && userDetails.email!==MED_USER_EMAIL? (
        <>
          <Menu.Item key="2" onClick={handleUserHistoryScreen}>
            <HistoryOutlined style={{ paddingRight: 10 }} />
            {t(I18N_MODULE_KEYS["semantic.search.userhistory.heading"])}
          </Menu.Item>
          <Menu.Item key="3" onClick={handleUsageScreen}>
            <DollarCircleOutlined style={{ paddingRight: 10 }} />
            {t(I18N_MODULE_KEYS["semantic.search.billing.heading"])}
          </Menu.Item>
        </>
      ) : (
        <></>
      )}
      <Menu.Item key="4" onClick={handleLogout}>
        <LogoutOutlined style={{ paddingRight: 10 }} />
        {t(I18N_MODULE_KEYS["header.menu.logout"])}
      </Menu.Item>
    </Menu>
  );
  const avatarStyle = {
    cursor: "pointer" // Adding pointer cursor on hover
  };
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Avatar style={avatarStyle} src={<img src={PROJECT_ICONS.USERICON_LOGO} alt="avatar" />} />
    </Dropdown>
  );
};

export default Usericon;
