import { globalConstant } from "../../../../Utils";

export const hiLocale = {
  /*मुख्य स्थितियाँ*/
  ["app.name"]: "जनरेटिव ए.आई.",
  ["app.logo"]: "extgen",

  /*हेडर स्थितियाँ*/
  ["header.searchtext"]: "यहाँ खोजें...",
  ["header.menu.language.english"]: "अंग्रेज़ी",
  ["header.menu.language.spanish"]: "स्पैनिश",
  ["header.menu.language.chinese"]: "चीनी",
  ["header.menu.language.hindi"]: "हिन्दी",
  ["header.menu.myprofile"]: "मेरी प्रोफ़ाइल",
  ["header.menu.logout"]: "लॉग आउट",

  /*Sidebar constants*/
  ["sidebar.dashboard"]: "डैशबोर्ड",
  ["sidebar.history"]: "कंटेंट इतिहास",

  /*Module Constants */
  ["module.dashboard"]: "डैशबोर्ड",
  ["module.login"]: "लॉगिन",
  ["module.signup"]: "साइन अप",
  ["module.transcreation"]: "कंटेंट ट्रांसक्रिएशन",
  ["module.history"]: "कंटेंट इतिहास",
  ["module.translation"]: "कंटेंट अनुवाद",
  ["module.semantic"]: "सेमैंटिक सर्च",
  ["module.fieldcopilot"]: "फ़ील्ड कोपायलट",
  ["module.contentcreation"]: "कंटेंट निर्माण",
  ["module.idetailextract"]: "आईडिटेलएक्सट्रैक्ट",
  ["module.translation-module"]: "अनुवाद",

  /*Content constants*/
  ["content.dashboard.card.generativeai.title"]: "जेनरेटिव ए.आई.",
  ["content.dashboard.card.generativeai.desc"]:
    "जेनरेटिव आर्टिफिशियल इंटेलिजेंस (ए.आई.) एक ऐसी आर्टिफिशियल इंटेलिजेंस है जो पाठ, छवियाँ, या अन्य मीडिया निर्माण करने की क्षमता रखती है, जनरेटिव मॉडल्स का उपयोग करके। जेनरेटिव ए.आई. मॉडल्स अपने प्रशिक्षण डेटा के पैटर्न और संरचना को सीखते हैं और फिर उसी के समान विशेषताओं वाला नया डेटा निर्माण करते हैं।",

  ["content.dashboard.card.idetail.title"]: "आईडिटेलएक्सट्रैक्ट",
  ["content.dashboard.card.idetail.desc"]:
    "ट्रांसफॉर्मेटिव एआई पावर्ड इंजन जो iDetail को प्रिंट-तैयार बैनर से व्यक्तिगत ईमेल तक के विभिन्न प्रकार के एसेट्स में परिवर्तित करता है, जो विविध दर्शकों को समारूपित करने के लिए विशेष रूप से तैयार किए गए होते हैं।",

  ["content.dashboard.card.translation-module.title"]: "कंटेंट अनुवाद",
  ["content.dashboard.card.translation-module.desc"]:
    "जनरेटिव एआई पावर टूल, कई भाषाओं में वास्तविक समय, संदर्भ और शब्दावली जागरूक अनुवाद प्रदान करने के लिए।",

  ["content.dashboard.card.transcreation.title"]: "कंटेंट ट्रांसक्रिएशन",
  ["content.dashboard.card.transcreation.desc"]:
    "एक भाषा से दूसरे में कंटेंट बनाने और अनुकूलित करने की प्रक्रिया, जबकि इसके उद्देश्य, संदर्भ और शैली, टोन को बनाए रखते हुए।",

  ["content.dashboard.card.semantic.title"]: "नेक्स्ट गिस्ट",
  ["content.dashboard.card.semantic.desc"]: "एडवांस्ड सेमांटिक सर्च अनसंरचित डेटा को क्रियाशील इंसाइट्स में बदलता है, अनुसंधान को स्वचालित करता है, और रणनीतिक निर्णयों को बढ़ाता है।",

  ["content.dashboard.card.contentai.title"]: "कंटेंट ए.आई.",
  ["content.dashboard.card.contentai.desc"]:
    "ए.आई. व्यक्तिगत समर्थन प्रदान करती है जिसमें मनोबूद्धि गणना कौशल होता है, जो व्यक्ति की आवश्यकताओं को समझने, सीखने और उसे अनुकूलित करने की क्षमता का उपयोग करती है।",

  ["content.dashboard.card.fieldcopilot.title"]: "फ़ील्ड कोपायलट",
  ["content.dashboard.card.fieldcopilot.desc"]:
    "प्रतिष्ठान को अतुलनीय एचसीपी, रोगी की दृष्टिकोण, और उन्नत फार्मा और उत्पाद की सिफारिशों के साथ सशक्त करना।",

  ["content.dashboard.card.kolvideo.title"]: "के.ओ.एल. वीडियो",
  ["content.dashboard.card.kolvideo.desc"]:
    "ए.आई.-जेनरेटेड के.ओ.एल. अवतार्स, जेन ए.आई. पावर्ड स्क्रिप्ट जनरेशन, और एक व्यापक संपादन सुइट के साथ सीमितरूप से के.ओ.एल. वीडियो बनाएँ।",

  ["content.dashboard.card.assistant.title"]: "जेन ए.आई. डैशबोर्ड सहायक",
  ["content.dashboard.card.assistant.desc"]:
    "प्राकृतिक भाषा वार्ता इनपुट्स, इंटरैक्टिव दृष्टि, और सूचना और त्वरित निर्णय लेने के लिए सरल कथनों के साथ डेटा विश्लेषण को सरल बनाना।",

  ["content.dashboard.card.hyper.title"]: "हाइपर व्यक्तिगतकरण",
  ["content.dashboard.card.hyper.desc"]:
    "व्यक्ति की पसंदों और आवश्यकताओं के केंद्र में अनुकूलित अनुभवों को बनाए रखना, एक गहरे व्यक्तिगत और सार्थक इंटरएक्शन बनाए रखना।",
  ["content.dashboard.card.patientjourney.title"]: "रोगी की यात्रा मैपिंग",
  ["content.dashboard.card.patientjourney.desc"]:
    "जेन ए.आई.-ड्राइवन पेशेंट जर्नी मैपिंग, स्वास्थ्य सेवा को क्रांति कैसे ला रहा है, समझता है और स्वास्थ्य अनुभव को व्यक्तिगत बनाना।",

  ["content.dashboard.card.translationlocalisation.title"]: "कंटेंट अनुवाद और स्थानीयकरण",
  ["content.dashboard.card.translationlocalisation.desc"]:
    "अनुवाद और स्थानीयकरण के माध्यम से वैश्विक दर्शकों के लिए कंटेंट को अनुकूलित करना, सांस्कृतिक प्रासंगिकता, भाषाई सटीकता, और क्षेत्रीय प्रभाव सुनिश्चित करना।",

  ["content.dashboard.card.personalizedvideo.title"]: "व्यक्तिगत वीडियो",
  ["content.dashboard.card.personalizedvideo.desc"]:
    "व्यक्तिगत वीडियोज़ के साथ हेल्थकेयर पेशेवरों (एच.सी.पी.) और रोगियों के लिए जानकारी पहुंचाने के लिए हेल्थकेयर संवाद को क्रांति कैसे ला रहा है।",

  ["content.dashboard.card.sentimentanalysis.title"]: "भावना विश्लेषण",
  ["content.dashboard.card.sentimentanalysis.desc"]:
    "बढ़ी हुई भावना विश्लेषण और समझ के लिए प्रयुक्त कर्णात्मक गणना का उपयोग करना, प्रयुक्त कर्णात्मक विश्लेषण और समझ के लिए।",

  ["content.dashboard.card.contentcreation.title"]: "सोशल मीडिया कंटेंट निर्माण",
  ["content.dashboard.card.contentcreation.desc"]:
    "हेल्थकेयर पेशेवरों (एच.सी.पी.), रोगियों, और एच.सी.पी. मार्केटिंग के लिए संवाद को पुनर्निर्भर करने के लिए सोशल मीडिया कंटेंट बनाएं और कंटेंट संदेशों को आसानी से रूपांतरित करें।",

  ["content.dashboard.card.button.description"]: "विवरण",
  ["content.dashboard.card.button.demo"]: "डेमो",

  //transcreation select source step
  ["content.transcreation.selectsource.1.title"]: "आप आज क्या बनाना चाहेंगे?",
  ["content.transcreation.selectsource.1.card.video"]: "वीडियो",
  ["content.transcreation.selectsource.1.card.social"]: "सोशल मीडिया पोस्ट",
  ["content.transcreation.selectsource.1.card.banner"]: "बैनर",
  ["content.transcreation.selectsource.1.card.journalad"]: "पत्रिका विज्ञापन",
  ["content.transcreation.selectsource.1.card.literature"]: "छोड़ने के लिए साहित्य",
  ["content.transcreation.selectsource.1.card.poster"]: "पोस्टर",
  ["content.transcreation.selectsource.1.card.newsletter"]: "समाचार पत्रिका",
  ["content.transcreation.selectsource.1.card.brochure"]: "ब्रोशर",

  ["content.transcreation.selectsource.2.title"]: "एक विकल्प चुनें",
  ["content.transcreation.selectsource.2.card.email"]: "ईमेल से वीडियो",
  ["content.transcreation.selectsource.2.card.blog"]: "ब्लॉग से वीडियो",
  ["content.transcreation.selectsource.2.card.banner"]: "बैनर से वीडियो",
  ["content.transcreation.selectsource.2.card.socialmediapost"]: "सोशल मीडिया पोस्ट से वीडियो",
  ["content.transcreation.selectsource.2.card.poster"]: "पोस्टर से वीडियो",

  ["content.transcreation.source.card.upload"]: "फ़ाइल अपलोड करें",
  ["content.transcreation.source.card.pullClaims"]: "DAMS से दावे खींचें",
["content.transcreation.source.card.uploadasset"]: "संपत्ति अपलोड करें और दावे निकालें",
  ["content.transcreation.source.card.uploaded"]: "अपलोड की गई फ़ाइल",

  ["content.transcreation.source.card.text.drag"]: "अपलोड करने के लिए ड्रैग और ड्रॉप करें या फ़ाइल को चुनने के लिए ब्राउज़ फ़ाइल पर क्लिक करें",
  ["content.transcreation.source.card.text.file"]: "फ़ाइल प्रकार: पीडीएफ, अधिकतम फ़ाइल का आकार: 30 MB",
  ["content.transcreation.source.card.text.nofileselected"]: "कोई फ़ाइल चयन नहीं की गई है!",

  ["content.transcreation.source.card.text.subject"]: "विषय",
  ["content.transcreation.source.card.text.image"]: "छवि का उपयोग किया गया",
  ["content.transcreation.source.card.text.body"]: "शरीर",

  ["content.transcreation.process.storyboard.title"]: "कहानी बोर्ड बनाएं",
  ["content.transcreation.process.storyboard.text.selectall"]: "सबका चयन करें",
  ["content.transcreation.process.storyboard.text.deselectall"]: "सभी का चयन रद्द करें",
  ["content.transcreation.process.storyboard.text.scene"]: "दृश्य",
  ["content.transcreation.process.storyboard.text.title"]: "शीर्षक",
  ["content.transcreation.process.storyboard.text.narration"]: "वर्णन",
  ["content.transcreation.process.storyboard.text.story"]: "कहानी",
  ["content.transcreation.process.storyboard.text.nostoryfound"]: "कोई कहानी नहीं मिली!",
  ["content.transcreation.process.storyboard.modal.title.editstoryboard"]: "कहानी बोर्ड संपादित करें",
  ["content.transcreation.process.selectimage.title.chooseplatform"]: "छवि/क्लिप बनाने के लिए अपना प्लेटफ़ॉर्म चुनें",
  ["content.transcreation.process.generatedimage.title.imagegeneration"]: "छवि निर्माण",
  ["content.transcreation.process.generatedimage.title.clipgeneration"]: "क्लिप निर्माण",
  ["content.transcreation.process.generatedimage.text.kindlychoosegeneratevideo"]: "कृपया वीडियो निर्माण करने के लिए एक या एकाधिक विकल्प चुनें",
  ["content.transcreation.process.generatedimage.drawer.title.addliveclips"]: "लाइव क्लिप्स जोड़ें",

  ["content.transcreation.process.template.title.chooseyourtemplate"]: "अपना टेम्प्लेट चुनें",
  ["content.transcreation.process.template.title.notemplatefound"]: "कोई टेम्पलेट नहीं मिला!",
  ["content.transcreation.process.avatar.title.chooseyouravatar"]: "अपना अवतार चुनें",
  ["content.transcreation.process.avatar.title.noavatarfound"]: "कोई अवतार नहीं मिला!",

  ["content.transcreation.successful.title.videogeneration"]: "वीडियो निर्माण",
  ["content.transcreation.successful.title.generatevideo"]: "वीडियो निर्माण करें",

  ["content.transcreation.viewsummary.drawer.title.summary"]: "सारांश",
  ["content.transcreation.viewsummary.drawer.title.storyboard"]: "कहानी बोर्ड",
  ["content.transcreation.viewsummary.drawer.title.images"]: "छवियाँ",
  ["content.transcreation.viewsummary.drawer.title.templates"]: "टेम्पलेट्स",
  ["content.transcreation.viewsummary.drawer.title.avatars"]: "अवतार",
  ["content.transcreation.viewsummary.drawer.title.clips"]: "क्लिप्स",

  //transcreation header
  ["content.transcreation.activebar.heading.source"]: "स्रोत",
  ["content.transcreation.activebar.heading.process"]: "प्रक्रिया",
  ["content.transcreation.activebar.heading.successful"]: "सफल",

  //transcreation footer
  ["content.transcreation.footer.button.getstarted"]: "शुरू हो जाओ",
  ["content.transcreation.footer.button.viewdashboard"]: "डैशबोर्ड देखें",
  ["content.transcreation.footer.button.regeneratedoc"]: "दस्तावेज़ पुनः निर्माण करें",
  ["content.transcreation.footer.button.createstoryboard"]: "स्टोरीबोर्ड बनाएं",
  ["content.transcreation.footer.button.regeneratestory"]: "कहानी पुनः निर्माण करें",
  ["content.transcreation.footer.button.choosetemplate"]: "टेम्पलेट चुनें",
  ["content.transcreation.footer.button.chooseavatar"]: "अवतार चुनें",
  ["content.transcreation.footer.button.viewsummary"]: "सारांश देखें",
  ["content.transcreation.footer.button.ordervideo"]: "वीडियो आदेश करें",

  ["content.transcreation.modal.ordervideosuccess"]: "वीडियो सफलता पूर्वक आदेश दिया गया है",
  ["content.transcreation.modal.regneration"]: "चेतावनी! वीडियो पहले ही जेनरेट हो चुका है",
  ["content.transcreation.modal.regenerationdescription"]:
    "स्टोरीबोर्ड, छवि और वीडियो जैसी सफल कंटेंट को हटा दिया जाएगा और वीडियो फिर से तैयार किया जाएगा। क्या आपकी आगे बढ़ने की इच्छा है?",

  //notifications transcreation
  ["content.transcreation.steppers.1.notification.selectfile"]: "कृपया अपलोड करने के लिए कोई भी फ़ाइल चयन करें।",
  ["content.transcreation.steppers.1.notification.filesizeissue"]: "फ़ाइल का आकार 30MB सीमा को पार कर गया है!",
  ["content.transcreation.steppers.1.notification.filetypeissue"]: "फ़ाइल प्रकार समर्थित नहीं है",
  ["content.transcreation.steppers.2.notification.textcopied"]: "पाठ सफलता पूर्वक कॉपी हो गया है!",
  ["content.transcreation.steppers.blogmodal.notification.fillblogurl"]: "कृपया ब्लॉग URL भरें।",
  ["content.transcreation.steppers.blogmodal.notification.entervalidurl"]: "कृपया मान्य URL दर्ज करें",

  ["content.transcreation.wrapper.notification.postpublicationfail"]: "पोस्ट प्रकाशन असफल रहा!",
  ["content.transcreation.wrapper.notification.postpublicationsuccess"]: "पोस्ट सफलता पूर्वक प्रकाशित हुआ!",
  ["content.history.notification.featureinprogress"]: "यह सुविधा काम में है।",
  ["content.history.core.notification.filedeletionsuccess"]: "फ़ाइल को सफलता पूर्वक हटा दिया गया है।",
  ["content.history.core.notification.filerenamesucess"]: "फ़ाइल को सफलता पूर्वक पुनरनामकृत किया गया है।",
  ["content.history.core.notification.videodownloadsucess"]: "वीडियो को सफलता पूर्वक डाउनलोड किया गया है।",
  ["content.history.core.notification.videodownloaderror"]: "वीडियो डाउनलोड करते समय त्रुटि हुई है।",
  ["content.history.view.gridview.notification.videogenerationinprogress"]: "वीडियो जनरेशन अभी भी प्रगति में है!",
  ["content.translation.translationwrapper.notification.videorendererror"]: "वीडियो रेंडर करते समय त्रुटि हुई है",
  ["content.translation.core.notification.limitexceed"]: "सीमा पार हो गई है! डेटा अपडेट नहीं हुआ।",
  ["content.translation.core.notification.noslide"]: "अनुवाद करने के लिए कोई स्लाइड नहीं है।",

  //content ai constants
  ["content.contentai.headings.contentai"]: "कंटेंट ए.आई.",
  ["content.contentai.headings.contentcreator"]: "कंटेंट क्रिएटर",
  ["content.contentai.headings.llmexperience"]: "एल.एल.एम अनुभव मार्कर",
  ["content.contentai.headings.llmcompliance"]: "एल.एल.एम अनुपालन मार्कर",
  ["content.contentai.headings.llmmlrguidline"]: "एल.एल.एम एम.एल. आरमार्गदर्शिका",
  ["content.contentai.headings.contentuniverse"]: "कंटेंट यूनिवर्स",

  ["content.contentai.headings.contentai.text.mynameisline"]: "मेरा नाम लिन है",
  ["content.contentai.headings.contentai.text.howcanihelpyou"]: "मैं आपकी कैसे सहायता कर सकती हूँ?",
  ["content.contentai.headings.contentai.text.createpharmacontent"]: "डोमेन विशिष्टता के साथ फार्मा कंटेंट बनाएं",
  ["content.contentai.headings.contentai.text.complianceandregulation"]: "अनुपालन और विधियाँ।",

  ["content.contentai.headings.contentai.text.enrichprompt"]: "संवर्धित प्रॉम्प्ट",
  ["content.contentai.headings.contentai.text.revisedprompt"]: "संशोधित प्रॉम्प्ट",
  ["content.contentai.headings.contentai.text.appliedrules"]: "लागू किए गए नियम",
  ["content.contentai.headings.contentai.text.triggeredprompt"]: "ट्रिगर किया गया प्रॉम्प्ट",
  ["content.contentai.headings.contentai.text.refinedresponse"]: "शुद्धिकृत प्रतिसाद",

  ["content.contentai.headings.llm.text.rule"]: "नियम",
  ["content.contentai.headings.llm.text.rules"]: "नियम",
  ["content.contentai.headings.llm.text.addnewrules"]: "नए नियम जोड़ें",
  ["content.contentai.headings.llm.text.norulefound"]: "कोई नियम नहीं मिला",
  ["content.contentai.headings.llm.text.enterrule"]: "नियम दर्ज करें",
  ["content.contentai.headings.llm.text.applicability"]: "लागूता",
  ["content.contentai.headings.llm.text.tagsupdated"]: "टैग्स अपडेट किए गए हैं!",

  ["content.contentai.headings.contentuniverse.text.contentnotavailableforselectedtags"]: "चयनित टैग(टैग्स) के लिए कंटेंट उपलब्ध नहीं है।",
  ["content.contentai.headings.contentuniverse.text.pleaseselectatag"]: "कृपया एक टैग चयन करें।",
  ["content.contentai.headings.contentuniverse.text.step1"]: "धाप 1",
  ["content.contentai.headings.contentuniverse.text.step2"]: "धाप 2",
  ["content.contentai.headings.contentuniverse.text.fileupload"]: "फ़ाइल अपलोड",
  ["content.contentai.headings.contentuniverse.text.draganddropyourfileshere"]: "यहाँ अपनी फ़ाइलें खींचें और छोड़ें।",
  ["content.contentai.headings.contentuniverse.text.maximumuploadfilesize:128MB"]: "अधिकतम अपलोड फ़ाइल का आकार: 128 MB।",
  ["content.contentai.headings.contentuniverse.text.supportedfiletype"]: "समर्थित फ़ाइल प्रकार:",
  ["content.contentai.headings.contentuniverse.text.uploadedfile"]: "अपलोड की गई फ़ाइल",
  ["content.contentai.headings.contentuniverse.text.choosedetails"]: "विवरण चुनें",

  //content AI Notifications
  ["content.contentai.headings.contentuniverse.notification.contentdeleted"]: "कंटेंट हटा दी गई है!",
  ["content.contentai.headings.contentuniverse.notification.contentemptyerror"]: "कंटेंट खाली नहीं हो सकती!",
  ["content.contentai.headings.contentuniverse.notification.contentupdate"]: "कंटेंट अपडेट की गई है!",
  ["content.contentai.LLM.notification.ruleemptyerror"]: "नियम का नाम खाली नहीं हो सकता!",
  ["content.contentai.LLM.notification.newruleadded"]: "नया नियम जोड़ा गया है!",
  ["content.contentai.LLM.LLMBasecomponent.notification.cancelled"]: "रद्द किया गया है!",
  ["content.contentai.LLM.LLMBasecomponent.notification.rulesapplied"]: "कंटेंट ब्रह्मांड नियम लागू हो गए हैं!",
  ["content.contentai.LLM.LLMLeftPanel.notification.promptrulesapplied"]: "प्रॉम्प्ट नियम लागू हो गए हैं!",
  ["content.contentai.contentuniverse.uploadscreen.notification.unsupportedformat"]: "असमर्थित प्रारूप!\nकेवल .xlsx फ़ाइलें समर्थित हैं।",

  //semantic search module floating sidebar
  ["semantic.search.floatingsidebar.search"]: "खोज",
  ["semantic.search.floatingsidebar.filemanagement"]: "फ़ाइल प्रबंधन",

  //semantic search
  ["semantic.search.header.heading.search"]: "खोज क्वेरी",
  ["semantic.search.landing.content.enterquery"]: "परिणाम देखने के लिए क्वेरी दर्ज करें",
  ["semantic.search.landing.content.number"]: "संख्या",
  ["semantic.search.landing.content.documentsindexed"]: "दस्तावेज़ सूचीबद्ध किए गए",
  ["semantic.search.landing.content.insightsgiven"]: "दी गई अंशों",
  ["semantic.search.landing.content.insightsdownloaded"]: "अंश डाउनलोड किए गए",
  ["semantic.search.input.heading.search"]: "खोज",
  ["semantic.search.input.heading.entersearch"]: "खोज क्वेरी दर्ज करें",
  ["semantic.search.input.heading.clear"]: "साफ़",
  ["semantic.search.result.enterfeedback"]: "अपनी प्रतिक्रिया दर्ज करें",
  ["semantic.search.loading.pleasewait"]: "कृपया प्रतीक्षा करें ...",
  ["semantic.search.loading.searchinprogress"]: "आपकी खोज प्रगति में है",
  ["semantic.search.result.searchdepth"]: "खोज की गहराई",
  ["semantic.search.result.format"]: "स्वरूप",
  ["semantic.search.result.regenerate"]: "पुनः निर्माण",
  ["semantic.search.result.depthoption.quickview"]: "त्वरित दृष्टि",
  ["semantic.search.result.depthoption.deepdive"]: "गहरा अध्ययन",
  ["semantic.search.result.depthoption.fullspectrum"]: "पूर्ण विस्तार",
  ["semantic.search.result.depthoption.quickviewsmall"]: "त्वरित दृष्टि",
  ["semantic.search.result.depthoption.deepdivesmall"]: "गहरा अध्ययन",
  ["semantic.search.result.depthoption.fullspectrumsmall"]: "पूर्ण विस्तार",
  ["semantic.search.result.formatoption.text"]: "पाठ",
  ["semantic.search.result.formatoption.list"]: "सूची",
  ["semantic.search.result.formatoption.table"]: "तालिका",
  ["semantic.search.result.formatoption.textsmall"]: "पाठ",
  ["semantic.search.result.formatoption.listsmall"]: "सूची",
  ["semantic.search.result.formatoption.tablesmall"]: "तालिका",
  ["semantic.search.result.answercard.files"]: "संदर्भ फ़ाइल(एँ)",
  ["semantic.search.result.answercard.answerfeedback"]: "प्रतिक्रिया",
  ["semantic.search.result.answercard.nextinsight"]: "अगला अंश",
  ["semantic.search.result.notification.fileuploaded"]: "फ़ाइल डाउनलोड की गई",
  ["semantic.search.result.notification.copy"]: "पाठ सफलतापूर्वक कॉपी किया गया",
  ["semantic.search.result.notification.unabletodownload"]: "फ़ाइल डाउनलोड करने में असमर्थ",
  ["semantic.search.result.notification.connectionerror"]: "कनेक्शन त्रुटि",
  ["semantic.search.result.notification.feedbacksubmitted"]: "प्रतिक्रिया सफलतापूर्वक सबमिट की गई",
  ["semantic.search.result.modaltable.addcolumns"]: "मैन्युअल कॉलम जोड़ें",
  ["semantic.search.result.modaltable.douwanttoaddcolumns"]: "क्या आप मैन्युअल कॉलम जोड़ना चाहते हैं?",
  ["semantic.search.result.modaltable.notification.manualcolumnsadded"]: "मैन्युअल कॉलम जोड़े गए",
  ["semantic.search.result.modaltable.enternewitem"]: "नई आइटम दर्ज करें",

  //semantic-search generate report
  ["semantic.search.result.modal.generate"]: "रिपोर्ट बनाएं",
  ["semantic.search.result.modaltable.share"]: "शेयर करें",
  ["semantic.search.result.modaltable.add"]: "रिपोर्ट में जोड़ें",
  ["semantic.search.result.modaltable.added"]: "रिपोर्ट में जोड़ा गया",
  ["semantic.search.result.viewmore"]: "और देखें",
  ["semantic.search.result.noanswers"]: "कोई जवाब नहीं मिला!",

  ["semantic.search.result.noreference"]: "कोई संदर्भ नहीं मिला",
  ["semantic.search.result.assets"]: "संपत्तियों का स्थान",
  ["semantic.search.result.searchresults"]: "खोज परिणाम",
  ["semantic.search.result.actions"]: "क्रियाएँ",
  ["semantic.search.result.noreportselected"]: "कोई रिपोर्ट चयनित नहीं है!",

  //drawer
  ["semantic.search.result.drawer.pagenumber"]: "पृष्ठ संख्या:",
  ["semantic.search.result.drawer.searchquery"]: "खोज पूछताछ",
  ["semantic.search.result.drawer.notfound"]: "नहीं मिला",

  //semantic advanced filter
  ["semantic.search.header.searchbyfoldername"]: "फ़ोल्डर नाम, दस्तावेज़ नाम से खोजें",
  ["semantic.search.header.enterfoldername"]: "फ़ोल्डर नाम दर्ज करें",

  ["semantic.search.header.advancedfilters"]: "उन्नत फ़िल्टर",
  ["semantic.search.header.appliedfilters"]: "लागू किए गए फ़िल्टर",
  ["semantic.search.advancedfilters.tip"]: "सुझाव: फ़ोल्डर देखने के लिए श्रेणी पर क्लिक करें",
  ["semantic.search.advancedfilters.selectall"]: "सबका चयन करें",
  ["semantic.search.advancedfilters.document"]: "दस्तावेज़",
  ["semantic.search.advancedfilters.URL"]: "यूआरएल",
  ["semantic.search.advancedfilters.image"]: "छवि",
  ["semantic.search.advancedfilters.video"]: "वीडियो",
  ["semantic.search.advancedfilters.audio"]: "ऑडियो",
  ["semantic.search.advancedfilters.notification.applied"]: "उन्नत फ़िल्टर लागू किया गया है",

  //semantic billing page
  ["semantic.search.billing.heading"]: "उपयोग और बिलिंग विवरण",
  ["semantic.search.billing.downloadreport"]: "रिपोर्ट डाउनलोड करें",
  ["semantic.search.billing.apiconsumption"]: "एपीआई उपभोक्ति",
  ["semantic.search.billing.monthlybilling"]: "मासिक बिलिंग",
  ["semantic.search.billing.api"]: "एपीआई",
  ["semantic.search.billing.units"]: "इकाइयाँ",
  ["semantic.search.billing.unitprice"]: "इकाई मूल्य ($)",
  ["semantic.search.billing.cost"]: "लागत ($)",
  ["semantic.search.billing.costheads"]: "लागत के हेड",
  ["semantic.search.billing.amount"]: "रकम ($)",
  ["semantic.search.billing.chat"]: "चैट",
  ["semantic.search.billing.embeddings"]: "एम्बेडिंग्स",
  ["semantic.search.billing.notavailable"]: "उपलब्ध नहीं है",
  ["semantic.search.billing.totalamount"]: "कुल रकम",
  ["semantic.search.billing.notification"]: "फ़ाइल सफलतापूर्वक डाउनलोड हो गई है",

  //semantic userhistory
  ["semantic.search.userhistory.heading"]: "उपयोगकर्ता इतिहास",
  ["semantic.search.userhistory.insightssearched"]: "खोजे गए इंसाइट्स",
  ["semantic.search.userhistory.chats"]: "चैट्स",
  ["semantic.search.userdetails.nochatsfound"]: "कोई चैट्स नहीं मिलीं",
  ["semantic.search.userdetails.noinsightsfound"]: "कोई इंसाइट्स नहीं मिलीं",

  //semnatic FileSystem
  ["semantic.search.filesystem.uploaddocument"]: "दस्तावेज़ अपलोड करें",
  ["semantic.search.filesystem.upload"]: "अपलोड",
  ["semantic.search.filesystem.createnewfolder"]: "नया फ़ोल्डर बनाएँ",
  ["semantic.search.filesystem.createfolder"]: "नया फ़ोल्डर बनाएँ",
  ["semantic.search.filesystem.browsefolder"]: "फ़ाइलें अपलोड करने के लिए फ़ोल्डर खींचें या ब्राउज़ करें",
  ["semantic.search.filesystem.nodocsavaliable"]: "कोई दस्तावेज़ उपलब्ध नहीं हैं",
  ["semantic.search.filesystem.nodocsavaliablecreate"]: "फ़ोल्डर बनाएँ और अपने दस्तावेज़ अपलोड करें",
  ["semantic.search.filesystem.chat"]: "चैट",
  ["semantic.search.filesystem.Document By"]: "दस्तावेज़ का नाम",
  ["semantic.search.filesystem.DocumentType"]: "दस्तावेज़ का प्रकार",
  ["semantic.search.filesystem.Uploaded By"]: "अपलोड किया गया व्यक्ति",
  ["semantic.search.filesystem.Uploaded Date"]: "अपलोड तिथि",
  ["semantic.search.filesystem.actions"]: "क्रियाएँ",
  ["semantic.search.filesystem.documents"]: "दस्तावेज़",
  ["semantic.search.filesystem.videos"]: "वीडियो",
  ["semantic.search.filesystem.audios"]: "ऑडियो",
  ["semantic.search.filesystem.images"]: "छवियाँ",
  ["semantic.search.filesystem.urls"]: "वेब यूआरएल",
  ["semantic.search.filesystem.documentsuploading"]: "दस्तावेज़ अपलोड हो रहे हैं",
  ["semantic.search.filesystem.deletionfolderconfirmation"]: "क्या आप फ़ोल्डर हटाने के साथ आगे बढ़ना चाहते हैं",
  ["semantic.search.filesystem.deletionfileconfirmation"]: "क्या आप फ़ाइल हटाने के साथ आगे बढ़ना चाहते हैं",
  ["semantic.search.filesystem.notification.foldercreation"]: "फ़ोल्डर सफलतापूर्वक बनाया गया",
  ["semantic.search.filesystem.notification.noinput"]: "फ़ोल्डर बनाने के लिए कोई इनपुट नहीं दिया गया",
  ["semantic.search.filesystem.notification.folderdeletion"]: "फ़ोल्डर सफलतापूर्वक हटा दिया गया",
  ["semantic.search.filesystem.notification.filedeletion"]: "फ़ाइल सफलतापूर्वक हटा दी गई है",
  ["semantic.search.filesystem.notification.folderdeletion.notupdated"]: "फ़ोल्डर सफलतापूर्वक हटा दिया गया, लेकिन इंटरफ़ेस पर अपडेट नहीं किया गया",
  ["semantic.search.filesystem.notification.filedeletion.notupdated"]: "फ़ाइल सफलतापूर्वक हटा दी गई, लेकिन इंटरफ़ेस पर अपडेट नहीं किया गया",
  ["semantic.search.filesystem.notification.errorfolderdeletion"]: "फ़ोल्डर हटाने में त्रुटि",
  ["semantic.search.filesystem.notification.errorfiledeletion"]: "फ़ाइल हटाने में त्रुटि",
  ["semantic.search.filesystem.notification.nofileselected"]: "कोई फ़ाइल अपलोड करने के लिए चयन नहीं किया गया था",
  ["semantic.search.filesystem.notification.fileuploaded"]: "फ़ाइलें सफलतापूर्वक अपलोड हो गईं हैं",
  ["semantic.search.filesystem.notification.foldercannotbecreated"]: "फ़ोल्डर सफलतापूर्वक बनाया नहीं जा सका",
  ["semantic.search.filesystem.notification.filecannotbeuploaded"]: "फ़ाइलें सफलतापूर्वक अपलोड नहीं की जा सकीं",

  ["semantic.search.filesystem.deletionimageconfirmation"]: "क्या आप चित्र हटाने के साथ आगे बढ़ना चाहते हैं?",
  ["semantic.search.filesystem.notification.imagedeletion"]: "छवि सफलतापूर्वक हटा दी गई",
  ["semantic.search.filesystem.notification.imagedeletion.notupdated"]: "छवि सफलतापूर्वक हटा दी गई, लेकिन इंटरफेस पर अपडेट नहीं की गई",
  ["semantic.search.filesystem.notification.errorimagedeletion"]: "छवि हटाने में त्रुटि",
  ["semantic.search.filesystem.notification.noimageselected"]: "अपलोड करने के लिए कोई छवि नहीं चयन की गई थी",
  ["semantic.search.filesystem.notification.imageuploaded"]: "छवियाँ सफलतापूर्वक अपलोड हो गईं",
  ["semantic.search.filesystem.notification.imagecannotbeuploaded"]: "छवियाँ सफलतापूर्वक अपलोड नहीं हो सकीं",

  //chat document
  ["chatdocument.search.chatwithdocument"]: "दस्तावेज़ के साथ चैट",
  ["chatdocument.search.chathistory"]: "चैट इतिहास",
  ["chatdocument.search.chatwith"]: "के साथ चैट करें",
  ["chatdocument.search.clearall"]: "सभी साफ़ करें",
  ["chatdocument.search.downloadchat"]: "चैट डाउनलोड करें",
  ["chatdocument.search.pages"]: "पृष्ठ",
  ["chatdocument.search.notfound"]: "पिछली कोई चैट नहीं मिली",
  ["chatdocument.search.today"]: "आज की चैट",
  ["chatdocument.search.7days"]: "पिछले 7 दिन",
  ["chatdocument.search.lastmonth"]: "पिछले महीने",
  ["chatdocument.search.lastyear"]: "पिछले 1 साल",
  ["chat.drawer.notification.chatdownload"]: "चैट डाउनलोड किया गया",
  ["chat.drawer.notification.errorchat"]: "कनेक्शन त्रुटि या डाउनलोड के लिए कोई चैट प्रारंभ नहीं किया गया",

  //content history
  ["content.history.wrapper.text.newfilename"]: "नया फ़ाइल नाम",
  ["content.history.wrapper.text.searchvideo"]: "वीडियो खोजें",
  ["content.history.wrapper.text.nohistoryfound"]: "कोई इतिहास नहीं मिला",

  //login
  ["content.login.text.customizeyouremailusingai"]: "ए.आई. का उपयोग करके अपना ईमेल कस्टमाइज़ करें",
  ["content.login.text.email"]: "ईमेल",
  ["content.login.text.password"]: "पासवर्ड",
  ["content.login.text.confirmpassword"]: "पासवर्ड की पुष्टि करें",
  ["content.login.text.convertyouremailintoHTMLCraftImagesand"]: "अपने ईमेल को एचटीएमएल में बदलें, छवियाँ बनाएं और",
  ["content.login.text.weavevideostoleavealasting"]: "एक दीर्घकालिक प्रभाव छोड़ने के लिए वीडियो बनाएं",
  ["content.login.text.pleaseenteravalidemailaddress"]: "कृपया एक मान्य ईमेल पता दर्ज करें",
  ["content.login.text.pleaseenteravalidpassword"]: "कृपया एक मान्य पासवर्ड दर्ज करें",
  ["content.login.text.passwordisrequired"]: "पासवर्ड आवश्यक है",
  ["content.login.text.emailisrequired"]: "ईमेल आवश्यक है",
  ["content.login.text.rememberme"]: "मुझे याद रखें",
  ["content.login.text.forgotpassword"]: "पासवर्ड भूल गए?",
  ["content.login.text.donthaveanaccount"]: "खाता नहीं है?",
  ["content.login.text.createanaccount"]: "खाता बनाएं",
  ["content.signup.text.alreadyhaveanaccount"]: "पहले से ही खाता है?",
  ["content.verifiedpage.text.signupsuccessfull"]: "साइनअप सफल हुआ। कृपया सत्यापन के लिए अपने मेल की जाँच करें",
  ["content.forgot.text.gotologinpage"]: "लॉगिन पृष्ठ पर जाएं",
  ["content.forgot.text.resetpassword"]: "पासवर्ड रीसेट करें",
  ["content.forgot.text.clicktologin"]: "लॉगिन के लिए क्लिक करें",
  ["content.forgot.text.helloagain"]: "फिर से नमस्ते!",

  //Use Case Constants
  /** Field Copilot */
  "content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant": "फ़ील्ड कोपायलट - आपका जेनरेटिव एआई-पावर्ड सहायक",
  "content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations":
    "अद्वितीय एचसीपी, रोगी इनसाइट्स, और उन्नत फार्मा और उत्पाद सिफारिशों के साथ प्रतिनिधियों को सशक्तिकरण",
  "content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point": "एक-स्टॉप एक्सेस प्वाइंट",
  "content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools":
    "जेनरेटिव एआई सक्षम संश्लेषण एक संयुक्त दृष्टिकोण प्रदान करता है, अनेक उपकरणों की आवश्यकता को कम करता है",
  "content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations": "विशेष रूप से अनुकूलित सिफारिशें",
  "content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time":
    "प्रतिनिधि प्रश्नों के लिए व्यक्तिगत उत्तर, वास्तविक समय में संदर्भ सिफारिशें प्रदान करना",
  "content.usecase.fieldcopilot.description.tabcontents.Scientific Responses": "वैज्ञानिक प्रतिक्रियाएँ",
  "content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric": "अनुपालन केंद्रित",
  "content.usecase.fieldcopilot.description.tabcontents.Every interaction and recommendation is designed to be compliant, ensuring peace of mind":
    "प्रत्येक बातचीत और सिफारिश अनुपालित बनाई गई है, शांति की सुनिश्चित करते हुए",
  "content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base": "संग्रहीत ज्ञान आधार",
  "content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base":
    "फार्मा कंपनी और उत्पाद डेटा और ज्ञान आधार के ध्यानपूर्वक चुने गए संग्रह का उपयोग करें",
  "content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access": "मार्केटिंग 360 इंसाइट्स एक्सेस",
  "content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey":
    "ग्राहक यात्रा पर वास्तविक समय में अपडेट प्रदान करने वाले डिजिटल प्लेटफ़ॉर्म पारिस्थितिकी के संगत संगठन",
  "content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management": "सहायित कार्य प्रबंधन",
  "content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders":
    "दैनिक कार्यक्रम, आगामी अपॉइंटमेंट्स, और कार्य रिमाइंडर्स का त्वरित पहुंच",
  'content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation':
    'बस उत्तर नहीं, हर सिफारिश के पीछे "क्यों", "कौन", और "क्या" में अंतर्दृष्टि',

  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition": "व्यवसाय मूल्य प्रस्ताव",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop":
    "स्पष्ट मार्गदर्शिका और प्रतिक्रिया प्रवाह",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface": "इंटरैक्टिव इंटरफ़ेस",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI":
    "प्रतिनिधियों को एआई से संवाद करने और इनपुट प्रदान करने की अनुमति देना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection": "प्रतिक्रिया संग्रह",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model.":
    "मॉडल को संशोधित और सुधारित करने के लिए उपयोगकर्ता प्रतिक्रियाओं को पकड़ना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability": "वास्तविक समय में अनुकूलन",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback.":
    "तत्काल प्रतिक्रिया के आधार पर मॉडल की प्रतिक्रियाएं अपडेट करना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI.":
    "प्रतिनिधियों को एआई से संबंधित होने और इसे इनपुट प्रदान करने की अनुमति देना।",

  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture": "जेनरेटिव एआई मॉडल आर्किटेक्चर",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI": "जेनरेटिव एआई",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures.":
    "जीएन या ट्रांसफार्मर आर्किटेक्चर का उपयोग करके एलएलएम का चयन करें।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization": "प्रशिक्षण और अनुकूलन",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques.":
    "प्रशिक्षण तकनीकों के माध्यम से प्रदर्शन को बढ़ावा देना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability": "डिप्लॉयमेंट और स्केलेबिलिटी",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions.": "संगत समाधान सुनिश्चित करना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System": "समन्वित डेटा और ज्ञान प्रणाली",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources": "विविध डेटा स्रोत",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data.":
    "विभिन्न और उच्च गुणवत्ता वाले डेटा को एकत्र करना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning": "डेटा सफाई",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data.":
    "असंगतताओं, शोर, या अनुपयुक्त डेटा को हटाना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering": "विशेषता इंजीनियरिंग",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance.":
    "मॉडल प्रदर्शन को सुधारने के लिए डेटा को सुधारना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework": "अनुपालन और रिपोर्टिंग फ्रेमवर्क",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation": "पक्षपात कमीकरण",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases.":
    "मॉडल के द्वारा हानिकारक पक्षपात को बनाये नहीं रखना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency": "पारदर्शिता",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions.":
    "यहाँ पर दिया गया गया है कि एआई कैसे निर्णय लेता है।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails": "अनुपालन और गार्डरेल्स",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries.":
    "कानूनी और नैतिक सीमाओं के अंदर संचालन करना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library": "व्यापक प्रोम्ट पुस्तकालय",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries": "गतिशील प्रश्न",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs.":
    "विविध उपयोगकर्ता आवश्यकताओं के लिए अनुकूलित प्रोम्प्ट्स।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions": "मार्गदर्शित इंटरैक्शन्स",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results.":
    "उपयोगकर्ताओं को वांछित परिणाम प्राप्त करने के लिए मार्गदर्शन करना।",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts": "श्रेणीबद्ध प्रोम्प्ट्स",
  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access.":
    "सरल पहुंच के लिए थीम या फ़ंक्शन्स द्वारा प्रश्नों का आयोजन करना।",

  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment": "निवेश का फिर लौट",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact": "वित्तीय प्रभाव",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency": "लागत की कुशलता",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules.":
    "एआई सहायित, आवश्यकतानुसार मॉड्यूल का उपयोग करके प्रशिक्षण व्यय को कम करें",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI": "अधिकतम निवेश का फिर लौट",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns.":
    "संचारक के प्रदर्शन को अनुकूलित करने से बिक्री परिणामों को बढ़ावा मिलता है, हर प्रशिक्षण डॉलर के उच्च लाभ दिलाते हुए",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence": "बिक्री ऑप्स उत्कृष्टता",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding": "त्वरित ओनबोर्डिंग",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity.":
    "एआई-मार्गदर्शित मॉड्यूल नए प्रतिनिधियों के एकीकरण की गति को तेजी से बढ़ाते हैं, उत्पादकता के समय को बढ़ाते हैं",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability": "गतिशील अनुकूलन",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs.":
    "प्रतिनिधियों को वास्तविक समय में बाजार के अंदर्स्थ कंटेंट से लैस करना, विस्तृत पुनः प्रशिक्षण की आवश्यकता को कम करना।",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements": "प्रदर्शनों को बढ़ाया गया",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements": "जानकारीय प्रदर्शन",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning.":
    "अपडेट समय पर, एआई द्वारा प्रेरित दृष्टिकोणों के माध्यम से एचसीपी इंटरैक्शन को उच्च करना, बाजारीय स्थिति को मजबूत करना।",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation": "जोखिम संघर्ष",
  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation.":
    "अंतर्निहित अनुपालन और रिपोर्टिंग तंत्र संग्रहीत मानकों का पालन सुनिश्चित करते हैं, ब्रांड प्रतिष्ठा की रक्षा करते हैं",

  "content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics": "सफलता मापक",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time": "कार्य पूरा करने का समय",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it":
    "चिकित्सा प्रतिनिधि के टास्क को पूरा करने में असिस्टेंट के साथ समय जो ए.आई. को-पायलट के",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement": "बिक्री/उत्पादकता में सुधार",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot":
    "चिकित्सा प्रतिनिधियों द्वारा एआई को-पायलट का उपयोग करते समय बिक्री या उत्पादकता में वृद्धि",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Accuracy of Information": "सूचना की सटीकता",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot":
    "एआई को-पायलट द्वारा प्रदान की गई जानकारी की सटीकता",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback": "ग्राहक प्रतिक्रिया",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot":
    "चिकित्सा प्रतिनिधियों के साथ एआई को-पायलट का उपयोग करते समय ग्राहकों की प्रतिक्रिया",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate": "व्याप्ति दर",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions":
    "चिकित्सा प्रतिनिधियों द्वारा एक्टिव रूप से एआई को-पायलट के साथ कितनी बार योगदान होता है जब वे अपने बातचीत में होते हैं",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization": "व्यक्तिगतकरण",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths":
    "एआई को-पायलट की क्षमता अपने योग्यता और कार्य शैली के अनुसार अपने सहायता को समायोजित करने के लिए",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations": "अनुपालन और विनियम",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies":
    "एआई को-पायलट चिकित्सा प्रतिनिधियों को उद्योग नियम और कंपनी नीतियों का पालन करने में मदद करता है",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings": "लागत में बचत",
  "content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot":
    "एआई को-पायलट का उपयोग करके प्राप्त की गई लागत में बचत",

  "content.usecase.fieldcopilot.application-areas.tabcontents.Application Areas": "अनुप्रयोग क्षेत्र",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Healthcare Representatives": "स्वास्थ्यदेखभाल प्रतिनिधियाँ",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Pharmaceutical Sales Teams": "फार्मास्युटिकल बिक्री टीमें",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Product Specialists": "उत्पाद विशेषज्ञ",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Customer Support": "ग्राहक सहायता",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Marketing and Strategy": "मार्केटिंग और रणनीति",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Training and Development": "प्रशिक्षण और विकास",
  "content.usecase.fieldcopilot.application-areas.tabcontents.Regulatory Compliance": "नियामक अनुपालन",

  "content.usecase.fieldcopilot.resources.tabcontents.Resources": "संसाधन",
  "content.usecase.fieldcopilot.resources.tabcontents.Whitepapers": "सफेद पत्र",
  "content.usecase.fieldcopilot.resources.tabcontents.Case Studies": "मामला अध्ययन",
  "content.usecase.fieldcopilot.resources.tabcontents.Webinars": "वेबिनार",
  "content.usecase.fieldcopilot.resources.tabcontents.Demos": "डेमो",
  "content.usecase.fieldcopilot.resources.tabcontents.Videos": "वीडियो",
  "content.usecase.fieldcopilot.resources.tabcontents.E-books": "ई-पुस्तकें",

  "content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation": "कंटेंट उत्पादन",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential": "भविष्य की संभावना",
  "content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements":
    "ए.आई. नवीनतम चिकित्सा अनुसंधान और दिशानिर्देशों के आधार पर लेख, प्रस्तुतियाँ, और मामले की विभिन्न प्रकार की कंटेंट निर्माण करने में सहायक हो सकता है। यह कंटेंट चिकित्सा प्रतिनिधियों को स्वास्थ्य सेवा पेशेवरों को प्रभावी रूप से शिक्षित करने में मदद कर सकती है और उन्हें प्रगतियों पर अपडेट करने में मदद कर सकती है।",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations": "प्रशिक्षण सिमुलेशन",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations":
    "उत्पादनात्मक एआई चिकित्सा परिदृश्यों के वास्तविक सिमुलेशन बना सकता है, जिससे चिकित्सा प्रतिनिधियों को एक नियंत्रित वातावरण में अपने संचार कौशल का अभ्यास करने की अनुमति मिलेगी। यह उन्हें अलग-अलग परिस्थितियों में अपने पिच और प्रतिक्रियाओं को शुद्ध करने में मदद करेगा।",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation": "भाषा अनुवाद",
  "content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach":
    "एआई-सशक्त भाषा अनुवाद चिकित्सा प्रतिनिधियों को विभिन्न भाषाई पृष्ठभूमियों से स्वास्थ्य सेवा पेशेवरों के साथ संवाद करने की संभावना है, भाषा की बाधाओं को तोड़ते हुए और उनकी आवृत्ति को बढ़ाते हुए।",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics": "पूर्वानुमान विश्लेषण",
  "content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement":
    "ऐतिहासिक डेटा और पैटर्न का विश्लेषण करके, एआई विशेष स्वास्थ्य सेवा पेशेवरों से संपर्क करने के लिए सबसे उपयुक्त समय और विधियों का पूर्वानुमान लगा सकता है। यह अंतर्क्रियाओं की प्रभावकारिता को बढ़ाएगा और उन्हें बेहतर बनाएगा।",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction": "आवाज़ अंतराक्रिया",
  "content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points":
    "आवाज़ पहचान प्रौद्योगिकी में उन्नति के साथ, एआई चिकित्सा प्रतिनिधियों को फोन कॉल या आभासी मीटिंग के दौरान वास्तविक समय जानकारी और सुझावित बातचीत के बिंदु प्रदान करके मदद कर सकता है।",
  "content.usecase.fieldcopilot.future-potential.tabcontents.Ethical Considerations": "नैतिक विचार",
  "content.usecase.fieldcopilot.future-potential.tabcontents.While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA":
    "हालांकि संभावनात्मक लाभ ठोस हैं, लेकिन नैतिक विचार होंगे, विशेष रूप से मरीजों के गोपनीयता और डेटा सुरक्षा के संबंध में। चिकित्सा प्रतिनिधियों और एआई डेवलपरों को यह सुनिश्चित करने की आवश्यकता होगी कि मरीज की जानकारी का उन्हां के अधिकारों के अनुसार और HIPAA जैसे विनियमों का पालन करके उपयुक्त ढंग से संभाला जाता है।",
  /************/

  /**Content Creation */
  "content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations":
    "डोमेन विशिष्टता, अनुपालन और विनियामकों के साथ तेजी से कंटेंट निर्माण करना",
  "content.usecase.contentai.description.Prompt Enrichment": "प्रोम्प्ट समृद्धि",
  "content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience":
    "उपयोगकर्ता को ध्यान में रखते हुए प्रोम्प्ट को समृद्ध करने की अनुमति देता है ताकि लक्षित दर्शकों के साथ संवाद करने वाली वास्तविक कंटेंट मिल सके",
  "content.usecase.contentai.description.Automatic regulatory compliance check": "स्वचालित विनियामक अनुपालन जाँच",
  "content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards.":
    "निर्माण कंटेंट प्रासंगिक निकायों के साथ अनुपालन करती है, कंटेंट सभी चिकित्सा कानूनी और विनियामक मानकों को पूरा करती है।",
  "content.usecase.contentai.description.Localization optimization": "स्थानीयकरण अनुकूलन",
  "content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement":
    "विभिन्न क्षेत्रों और भाषाओं के लिए योग्य कंटेंट निर्माण करता है, जिसे केवल अनुवाद किया जा सकता है, बल्कि सांस्कृतिक रूप से अनुकूलित किया जा सकता है ताकि अधिकतम आकर्षण सुनिश्चित हो।",
  "content.usecase.contentai.description.Responsive design generator": "उत्तरदायी डिज़ाइन जेनरेटर",
  "content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices.":
    "कंटेंट का उत्तरदायी संदेश होने के साथ-साथ डिज़ाइन में भी अनुकूलित होता है, जो डेस्कटॉप, टैबलेट और मोबाइल उपकरणों पर आदर्श दृश्यिता सुनिश्चित करता है।",
  "content.usecase.contentai.description.Scalable": "स्केलेबल",
  "content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs":
    "आसानी से बड़ी मात्रा में कंटेंट निर्माण कर सकता है, जिससे व्यवसाय अपने ईमेल अभियान को बढ़ा सकें बिना लागतों में समानुपातिक वृद्धि के।",

  "content.usecase.contentai.business-value-proposition.Faster Time-To-Market": "बाजार में तेजी से पहुंचने का समय घटाएं",
  "content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches.":
    "तेजी से कंटेंट निर्माण करने के साथ, अधिक दक्ष रूप से प्रचार कार्य किया जा सकता है, जिससे व्यवसाय बाजार में परिवर्तनों या नए उत्पाद लॉन्च करने का त्वरित उत्तर दे सकते हैं।",
  "content.usecase.contentai.business-value-proposition.Cost Efficiency": "लागत की कुशलता",
  "content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets.":
    "विशेष रूप से जब बड़े दर्शकों की ओर बढ़ाई जाती है या नए बाजारों में विस्तार किया जाता है, तो कंटेंट लेखकों, अनुवादकों और डिज़ाइनरों को नियुक्त करने की लागत कम होती है।",
  "content.usecase.contentai.business-value-proposition.Market Expansion": "बाजार का विस्तार",
  "content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities":
    "व्यवसायों को सरलता से वैश्विक दर्शकों तक पहुंचने और संचार करने की अनुमति देता है, नए बाजारों और अवसरों के लिए दरवाज़े खोलता है।",
  "content.usecase.contentai.business-value-proposition.Increased Engagements": "वृद्धि हुई व्यक्तिगतता",
  "content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs":
    "व्यक्तिगत और प्रासंगिक कंटेंट आमतौर पर अधिक खुले दरों और क्लिक-थ्रू दरों को लाती है और एच.सी.पी. के साथ मजबूत संबंधों को बढ़ावा देती है।",

  "content.usecase.contentai.return-on-investment.Financial Benefits": "वित्तीय लाभ",
  "content.usecase.contentai.return-on-investment.Cost savings": "लागत बचत",
  "content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors":
    "उच्च गति और कुशलता, कम प्रशिक्षण लागत, कम त्रुटियाँ",
  "content.usecase.contentai.return-on-investment.Improved top-line": "सुधारा गया शीर्ष-लाइन",
  "content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream":
    "बढ़ी हुई गतिविधि बिक्री में वृद्धि कर सकती है, सीधे रूप से राजस्व धार को प्रभावित करती है",
  "content.usecase.contentai.return-on-investment.Operational Benefits": "परिचालन लाभ",
  "content.usecase.contentai.return-on-investment.Time saving": "समय बचत",
  "content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution":
    "तेजी से कंटेंट निर्माण, स्थानांतरण, अनुकूलन, प्रचार कार्य संचालन",
  "content.usecase.contentai.return-on-investment.Employee efficiency": "कर्मचारी कुशलता",
  "content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects":
    "दोहरी कार्यों के बजाय, मानव संसाधन स्ट्रैटेजिक पहलुओं पर ध्यान केंद्रित कर सकते हैं",
  "content.usecase.contentai.return-on-investment.Marketing Benefits": "विपणन लाभ",
  "content.usecase.contentai.return-on-investment.Global reach": "वैश्विक पहुंच",
  "content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint":
    "कंटेंट अनुकूलन वैश्विक दर्शकों के साथ प्रभावी संचार को सुविधाजनक बनाता है, बाजार के पैरों को बढ़ाता है",
  "content.usecase.contentai.return-on-investment.Market agility": "बाजार की लचीलापन",
  "content.usecase.contentai.return-on-investment.Rapid content generation allow businesses to swiftly react to market trends or changes":
    "तेजी से कंटेंट निर्माण करने की अनुमति व्यवसायों को बाजार के ट्रेंड या परिवर्तनों के लिए शीघ्र रिएक्ट करने देती है",

  "content.usecase.contentai.success-metrics.Content production speed": "कंटेंट उत्पादन की गति",
  "content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe":
    "कंटेंट आविष्कार से प्रकाशित करने तक लिए गए समय को मापें, और एक विशिष्ट समय-सीमा के अंदर निर्माण की गई कंटेंट के नंबर को ट्रैक करें",
  "content.usecase.contentai.success-metrics.Cost per content piece": "प्रति कंटेंट टुकड़े की लागत",
  "content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content":
    "प्रत्येक कंटेंट टुकड़े का उत्पादन करने के संबंधित लागत की गणना करें",
  "content.usecase.contentai.success-metrics.CAC": "सीएसी",
  "content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content":
    "कंटेंट के माध्यम से एक ग्राहक प्राप्त करने के संबंधित लागत",
  "content.usecase.contentai.success-metrics.ROI": "आरओआई",
  "content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content":
    "कंटेंट की लागत से बाँटी गई नेट लाभ",
  "content.usecase.contentai.success-metrics.Conversion rate": "रूपांतरण दर",
  "content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content":
    "कंटेंट देखने के बाद एक वांछित क्रिया करने वाले उपयोगकर्ताओं का प्रतिशत",
  "content.usecase.contentai.success-metrics.Error rates": "त्रुटि दरें",
  "content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication":
    "त्रुटियों की आवृत्ति (जैसे, व्याकरणिक, तथ्यात्मक) पोस्ट-प्रकाशन में पहचानी गई",
  "content.usecase.contentai.success-metrics.Revision rate": "संशोधन दर",
  "content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production.":
    "उत्पादन के बाद संशोधन या सुधारों की आवृत्ति का माप करें।",
  /************/

  /**Content translation */
  ["content.translation.voicemodal.Select a Voice"]: "एक आवाज़ चुनें",
  ["content.translation.voicemodal.Listen to voice samples and use tags to find the best voice for your needs."]:
    "आवाज़ सैम्पल सुनें और टैग का उपयोग करके अपनी आवश्यकताओं के लिए सबसे अच्छी आवाज़ खोजें।",
  ["content.translation.voicemodal.Select Gender"]: "लिंग का चयन करें",
  ["content.translation.voicemodal.Select by Tags"]: "टैग के द्वारा चयन करें",
  ["content.translation.voicemodal.Show Premium"]: "प्रीमियम दिखाएं",
  ["content.translation.voicemodal.Apply to All"]: "सभी पर लागू करें",
  ["content.translation.voicemodal.Apply Voices"]: "आवाज़ लागू करें",

  // basics
  ["content.button.submit"]: "सबमिट करें",
  ["content.button.cancel"]: "रद्द करें",
  ["content.button.goback"]: "वापस जाओ",
  ["content.button.save"]: "बचाना",
  ["content.button.proceed"]: "आगे बढ़ें",
  ["content.button.regenerate"]: "पुनः निर्माण करें",
  ["content.button.replace"]: "प्रतिस्थापित करें",
  ["content.button.add"]: "जोड़ें",
  ["content.button.showmore"]: "और दिखाएं",
  ["content.button.discard"]: "खारिज करें",
  ["content.text.noimagefound"]: "कोई छवि नहीं मिली!",
  ["content.text.gotodashboard"]: "डैशबोर्ड पर जाएं",
  ["content.button.next"]: "आगे",
  ["content.button.generate"]: "निर्माण करें",
  ["content.text.enteryourprompt"]: "अपना प्रॉम्प्ट दर्ज करें",
  ["content.text.generateinitialresponse"]: "मौखिक प्रतिक्रिया निर्माण करें",
  ["content.text.searchhere"]: "यहां खोजें",

  ["content.text.promptcannotbeempty"]: "प्रॉम्प्ट खाली नहीं हो सकता",
  ["content.text.filterby"]: "फ़िल्टर करें द्वारा",
  ["content.text.nofilterapplied"]: "कोई फ़िल्टर लागू नहीं है",
  ["content.button.reset"]: "रीसेट",
  ["content.button.back"]: "पीछे जाएं",
  ["content.button.apply"]: "लागू करें",
  ["content.button.upload"]: "अपलोड करें",
  ["content.text.sessionexpired"]: "सत्र समाप्त हो गया है",
  ["content.text.sessionhasexpired"]: "आपका सत्र समाप्त हो गया है। कृपया पुनः लॉगिन करें!",
  ["content.text.actions"]: "क्रियाएँ",
  ["content.text.filterapplied"]: "फ़िल्टर लागू हैं",
  ["content.text.selected"]: "चयनित",
  ["content.text.edit"]: "संपादित करें",
  ["content.text.delete"]: "हटाएं",
  ["content.text.interested"]: "रुचिकर",
  ["content.text.notinterested"]: "रुचिनहींकर",
  ["content.text.share"]: "साझा करें",
  ["content.text.response"]: "प्रतिक्रिया",
  ["content.button.update"]: "अद्यतन करें",
  ["content.text.errornoresponse"]: "त्रुटि: कोई प्रतिक्रिया नहीं!",
  ["content.text.erroruploadfailed"]: "त्रुटि: अपलोड विफल हुआ!",
  ["content.text.nofilesuploaded"]: "कोई फ़ाइलें अपलोड नहीं हुईं!",
  ["content.button.delete"]: "हटाएं",
  ["content.button.no"]: "नहीं",
  ["content.button.yes"]: "हाँ",
  ["content.button.stop"]: "रुकें",
  ["content.button.yesproceed"]: "हाँ, आगे बढ़ें",
  ["content.transcreation.button.summary"]: "सारांश",
  ["content.text.selectalanguage"]: "भाषा चुनें",
  ["content.text.translate"]: "अनुवाद करें",
  ["content.text.name"]: "नाम",
  ["content.text.uploadedon"]: "अपलोड किया गया",
  ["content.text.storyboard"]: "स्टोरीबोर्ड",
  ["content.text.image/clips"]: "छवि/क्लिप्स",
  ["content.text.video"]: "वीडियो",
  ["content.text.action"]: "क्रिया",
  ["content.text.somethingwentwrong"]: "कुछ गड़बड़ हो गई है",
  ["content.text.internalservererror"]: "आंतरिक सर्वर त्रुटि",
  ["content.text.completed"]: "पूर्ण",
  ["content.text.inprogress"]: "काम जारी है",
  ["content.text.notstarted"]: "शुरू नहीं हुआ",
  ["content.text.notapplicable"]: "लागू नहीं है",
  ["content.text.failed"]: "विफल",
  ["content.text.preview"]: "पूर्व दर्शन",
  ["content.text.download"]: "डाउनलोड",
  ["content.text.rename"]: "नाम बदलें",
  ["content.text.render"]: "प्रदान करना",
  ["content.text.pause"]: "विराम",
  ["content.text.changevoice"]: "आवाज बदलें",
  ["content.text.speechtext"]: "भाषण पाठ",
  ["content.text.changeavatar"]: "परिवर्तन अवतार",
  ["content.text.filteravatar"]: "आउटफिट के अनुसार अवतार को छानें",
  ["content.text.sendtoreport"]: "रिपोर्ट में भेजें",
  ["content.text.close"]: "बंद करें",
  ["content.text.chat"]: "  चैट",
  ["content.text.phoneme"]: "स्वनिम",
  ["content.text.phonemedictionary"]: "स्वनिम शब्दकोश",
  ["content.text.addphoneme"]: "स्वनिम जोड़ें",
  ["content.text.word"]: "शब्द",
  ["content.text.pronunciation"]: "उच्चारण",
  ["content.text.phonemedescription"]:
    "आपकी फोनेम की सूची जो खाते के सभी वीडियों में उपयोग की जाएगी। इसमें कोई भी शब्द होगा जो यहां के “उच्चारण” स्तंभ में बताया गया है।",

  ["content.text.error.400"]: "प्रतिसाद: बेड रिक्वेस्ट",
  ["content.text.error.401"]: "प्रतिसाद: अनधिकृत",
  ["content.text.error.402"]: "प्रतिसाद: भुगतान आवश्यक",
  ["content.text.error.403"]: "प्रतिसाद: निषिद्ध",
  ["content.text.error.404"]: "प्रतिसाद: नहीं मिला",
  ["content.text.error.405"]: "प्रतिसाद: विधि अनुमत नहीं है",
  ["content.text.error.406"]: "प्रतिसाद: स्वीकृति नहीं हुई",
  ["content.text.error.407"]: "प्रतिसाद: प्रॉक्सी प्रमाणीकरण आवश्यक है",
  ["content.text.error.408"]: "प्रतिसाद: अनुरोध समय सीमा समाप्त हो गई",
  ["content.text.error.409"]: "प्रतिसाद: संघर्ष",
  ["content.text.error.410"]: "प्रतिसाद: गया",
  ["content.text.error.411"]: "प्रतिसाद: लंबाई आवश्यक है",
  ["content.text.error.412"]: "प्रतिसाद: पूर्व-शर्त असफल",
  ["content.text.error.413"]: "प्रतिसाद: अनुरोध बहुत बड़ा",
  ["content.text.error.414"]: "प्रतिसाद: अनुरोध-यूआरआई बहुत लंबा है",
  ["content.text.error.415"]: "प्रतिसाद: समर्थित मीडिया प्रकार नहीं है",
  ["content.text.error.416"]: "प्रतिसाद: सीमा नहीं संतुष्ट",
  ["content.text.error.417"]: "प्रतिसाद: अपेक्षा असफल रही",
  ["content.text.error.500"]: "प्रतिसाद: आंतरिक सर्वर त्रुटि",
  ["content.text.error.501"]: "प्रतिसाद: क्रियाशील नहीं",
  ["content.text.error.502"]: "प्रतिसाद: बुरा गेटवे",
  ["content.text.error.503"]: "प्रतिसाद: सेवा अनुपलब्ध",
  ["content.text.error.504"]: "प्रतिसाद: गेटवे समय सीमा समाप्त हो गई",
  ["content.text.error.505"]: "प्रतिसाद: एचटीटीपी संस्करण समर्थित नहीं है",
  ["content.text.error.511"]: "प्रतिसाद: नेटवर्क प्रमाणीकरण आवश्यक है",

  ["content.text.disardallchanges"]: "सभी परिवर्तनों को छोड़ें",
  ["content.text.areyousuretodiscard"]: "क्या आप सभी परिवर्तनों को छोड़ने के लिए सुनिश्चित हैं?",
  ["content.text.novideosfound"]: "कोई वीडियो नहीं मिला!",

  ["content.text.generating.initialresponse"]: "प्रारंभिक प्रतिक्रिया निर्माण कर रहा है",
  ["content.text.generating.refinedresponse"]: "बनाया गया सुधारित प्रतिक्रिया",
  ["content.text.regenerating.refinedresponse"]: "पुनः निर्माण सुधारित प्रतिक्रिया",

  ["content.text.notification"]: "सूचना",
  ["content.text.enterblogurl"]: "ब्लॉग URL दर्ज करें",
  ["content.text.description"]: "विवरण",

  "content.button.start": "शुरू करें",

  "content.text.Strategic ROI of": "रणनीतिक निवेश का रिटर्न",

  "content.text.Title should not be empty!": "शीर्षक खाली नहीं होना चाहिए!",
  "content.text.Scene should not be empty!": "दृश्य खाली नहीं होना चाहिए!",
  "content.text.Narration should not be empty!": "वर्णन खाली नहीं होना चाहिए!",

  ["content.text.Updation of generated content is not allowed now as Video is rendered."]:
    "अब जेनरेट की गई सामग्री को अपडेट करने की अनुमति नहीं है क्योंकि वीडियो प्रस्तुत किया गया है।",

  ["content.text.Are you sure you want to delete?"]: "क्या आप आश्वस्त है कि आपको डिलीट करना है?",
  ["content.text.Remove"]: "हटाएं",

  ["idetailextract._UploadFileCard.Upload Document"]: "दस्तावेज़ अपलोड करें",
  ["idetailextract._UploadFileCard.Uploading"]: "अपलोड हो रहा है",
  ["idetailextract._UploadFileCard.Click to Upload or Drop PDF here "]: "अपलोड करने के लिए क्लिक करें या यहाँ PDF छोड़ें",
  ["idetailextract._UploadFileCard.from url"]: "URL से",
  ["idetailextract._UploadFileCard.Set Language for Scanned files"]: "स्कैन किए गए फ़ाइलों के लिए भाषा सेट करें",
  ["idetailextract._UploadFileCard.Country"]: "देश",
  ["idetailextract._UploadFileCard.Brand"]: "ब्रांड",
  ["idetailextract._UploadFileCard.Language"]: "भाषा",

  ["idetailextract._CategoriesModal.Selected Categories"]: "चयनित श्रेणियाँ",
  ["idetailextract._CategoriesModal.Search Catogory"]: "खोज श्रेणी",
  ["idetailextract._CategoriesModal.Suggested"]: "सुझाव",
  ["idetailextract._CategoriesModal.Additional"]: "अतिरिक्त",
  ["idetailextract._CategoriesModal.No Data Found"]: "कोई डेटा नहीं मिला",
  ["idetailextract._CategoriesModal.Identified Categories"]: "पहचानी गई श्रेणियाँ",

  ["idetailextract._CoreClaimMain._Headertab.Create Copy"]: "कॉपी बनाएं",

  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Uploaded PDF Preview"]: "अपलोड किया गया PDF पूर्वावलोकन",
  ["idetailextract._CoreClaimMain._CoreClaimPdfPreview.Page"]: "पृष्ठ",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.PDF > Document Copy"]: "PDF > दस्तावेज़ प्रतिलिपि",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Selected Categories"]: "चयनित श्रेणियाँ",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.ADD CATEGORIES"]: "श्रेणियाँ जोड़ें",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.This is a preview version and you can edit and create a copy out of it."]:
    "नोट: यह एक पूर्वावलोकन संस्करण है और आप इसे संपादित कर सकते हैं और इसकी प्रतिलिपि बना सकते हैं।",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Step"]: "स्टेप",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.of CVA"]: "की CVA",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Add"]: "जोड़ें",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Next Step"]: "अगला कदम",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Previous Step"]: "पिछला कदम",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE ASSET TYPE"]: "एसेट प्रकार का चयन करें",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.CHOOSE CLAIM CATEGORY"]: "दावा श्रेणी का चयन करें",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Choose ‘Asset & Claim category to proceed"]:
    "आगे बढ़ने के लिए 'संपत्ति और दावा श्रेणी का चयन करें",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Core Claims"]: "मुख्य दावे",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.Asset Creation Recommendation"]: "एसेट निर्माण सिफारिश",

  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Updated"]: "सामग्री अपडेट की गई",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content Deleted"]: "सामग्री हटा दी गई",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content"]: "सामग्री",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page No"]: "पृष्ठ संख्या",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Content can not be empty"]: "सामग्री खाली नहीं हो सकती",
  ["idetailextract._CoreClaimMain._CoreClaimPdfDocEditor._ContentListItem.Page number can not be empty"]: "पृष्ठ संख्या खाली नहीं हो सकती",

  ["idetailextract._CoreClaimMain._TinyMCE.Loading Editor"]: "संपादक लोड हो रहा है",

  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content saved"]: "सामग्री सहेजी गई",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Saving failed! Please retry."]: "सहेजने में विफल! कृपया पुनः प्रयास करें।",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Content discarded!"]: "सामग्री छोड़ दी गई!",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No recent changes to save."]: "सहेजने के लिए कोई हाल की परिवर्तन नहीं हैं।",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.No unsaved changes to discard."]: "छोड़ने के लिए कोई असहेजित परिवर्तन नहीं हैं।",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Your Emailer Suggestion"]: "आपका ईमेल सुझाव",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save"]: "सहेजें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard"]: "छोड़ें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit Edit Mode"]: "संपादन मोड से बाहर निकलें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Preview"]: "पूर्वावलोकन",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Cancel"]: "रद्द करें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Lose Changes"]: "परिवर्तनों को खोएं",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.If you proceed, you will lose all the changes you have made."]:
    "यदि आप आगे बढ़ते हैं, तो आप जो कोई भी परिवर्तन किया है, वह सभी खो देंगे।",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Exit without Saving"]: "सहेजे बिना बाहर निकलें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Save and Exit"]: "सहेजें और बाहर निकलें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Discard unsaved changes"]: "असहेजित परिवर्तन छोड़ें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Unsaved changes"]: "असहेजित परिवर्तन",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Proceed"]: "आगे बढ़ें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Close"]: "बंद करें",
  ["idetailextract._CoreClaimMain.FinalHTMLOutputModal.Edit"]: "संपादित करें",

  /**
   * Translation Module
   */

  "translation-module._TextTranslationMain.Choose Base Language": "आधार भाषा चुनें",
  "translation-module._TextTranslationMain.Choose Target Language": "लक्षित भाषा चुनें",
  "translation-module._TextTranslationMain.RESET": "रीसेट",
  "translation-module._TextTranslationMain.TRANSLATE": "अनुवाद करें",
  "translation-module._TextTranslationMain.Input": "इनपुट",
  "translation-module._TextTranslationMain.Output": "आउटपुट",

  "translation-module._TextTranslationMain._IOTextTranslation.Enter your text": "अपना पाठ दर्ज करें",
  "translation-module._TextTranslationMain._IOTextTranslation.ADD GLOSSARY": "शब्दकोश जोड़ें",

  "translation-module._AddGlossaryModal.Add Glossary": "शब्दकोश जोड़ें",
  "translation-module._AddGlossaryModal.Set rules for how words and phrases are translated": "शब्द और वाक्यों का अनुवाद कैसे होगा इसके नियम सेट करें",
  "translation-module._AddGlossaryModal.Replace": "प्रतिस्थापित करें",
  "translation-module._AddGlossaryModal.e.g, hello!": "उदाहरण के लिए, नमस्ते!",
  "translation-module._AddGlossaryModal.With": "साथ",
  "translation-module._AddGlossaryModal.e.g, 更高": "उदाहरण के लिए, 更高",
  "translation-module._AddGlossaryModal.Source --> Target": "स्रोत --> लक्ष्य",
  "translation-module._AddGlossaryModal.RESET": "रीसेट",
  "translation-module._AddGlossaryModal.ADD": "जोड़ें",
  "translation-module._AddGlossaryModal.UPDATE": "अपडेट",
  "translation-module._AddGlossaryModal.Search item": "आइटम खोजें",
  "translation-module._AddGlossaryModal.OR": "या",
  "translation-module._AddGlossaryModal.Action": "क्रिया",

  ["translation-module._MainTabs.TEXT TRANSLATION"]: "पाठ अनुवाद",
  ["translation-module._MainTabs.UPLOAD DOCUMENT(S)"]: "दस्तावेज़ अपलोड करें",
  ["translation-module._UploadDocumentMain.File Upload"]: "फ़ाइल अपलोड",
  ["translation-module._UploadDocumentMain.ADD GLOSSARY"]: "शब्दकोश जोड़ें",
  ["translation-module._UploadDocumentMain.Drag And Drop Your Files Here."]: "अपनी फ़ाइलें यहाँ खींचें और छोड़ें।",
  ["translation-module._UploadDocumentMain."]: "समर्थित फ़ाइल प्रकार: .pdf",
  ["translation-module._UploadDocumentMain.of"]: "का",
  ["translation-module._UploadDocumentMain.files uploaded"]: "फ़ाइलें अपलोड की गईं",
  ["translation-module._UploadDocumentMain.Uploaded File"]: "अपलोड की गई फ़ाइल",
  ["translation-module._UploadDocumentMain.No files uploaded!"]: "कोई फ़ाइलें अपलोड नहीं की गईं!",
  ["translation-module._UploadDocumentMain.NEXT"]: "आगे",
  ["translation-module._UploadDocumentMain.Clear All"]: "सभी को साफ़ करें",

  ["translation-module._TranslateAssetMain._HeadingContainer.Translate your Asset"]: "अपनी संपत्ति का अनुवाद करें",
  ["translation-module._TranslateAssetMain._HeadingContainer.ADD GLOSSARY"]: "शब्दकोश जोड़ें",
  ["translation-module._TranslateAssetMain._HeadingContainer.At the moment, AI-generated translation will be applied to all of your assets."]:
    "वर्तमान में, ए.आई. जेनरेटेड अनुवाद को आपकी सभी संपत्तियों पर लागू किया जाएगा।",

  ["translation-module._TranslateAssetMain._SearchingContainer.Languages"]: "भाषाएँ",
  ["translation-module._TranslateAssetMain._SearchingContainer.Search Language"]: "खोज भाषा",
  ["translation-module._TranslateAssetMain._SearchingContainer.Base Language English (US)"]: "मूल भाषा: अंग्रेजी (यूएस)",

  ["translation-module._TranslateAssetMain._OptionalContainer.Add Context to the Translation (Optional)"]: "अनुवाद में संदर्भ जोड़ें (वैकल्पिक)",

  "translation-module._TranslateAssetMain._OptionalContainer.Enter your text": "अपना पाठ दर्ज करें",

  "translation-module._TranslateAssetMain._FooterContainer.Language Selected": "भाषा चयनित",
  "translation-module._TranslateAssetMain._FooterContainer.CANCEL": "रद्द करें",
  "translation-module._TranslateAssetMain._FooterContainer.PROCEED": "आगे बढ़ें",
  "translation-module.FinalTranslationScreen.Translation is Done!": "अनुवाद हो गया है!",
  "translation-module.FinalTranslationScreen.Please select a file from the Dropdown to view the translated asset.":
    "कृपया अनुवादित संपत्ति देखने के लिए ड्रॉपडाउन से एक फ़ाइल का चयन करें।",
  "translation-module.FinalTranslationScreen.Edit": "संपादित करें",
  "translation-module.FinalTranslationScreen.Input": "इनपुट",
  "translation-module.FinalTranslationScreen.Output": "आउटपुट",
  "translation-module.FinalTranslationScreen.DOWNLOAD": "डाउनलोड",
  "translation-module.FinalTranslationScreen.Languages": "भाषाएँ",
  "translation-module.FinalTranslationScreen.START AGAIN": "फिर से शुरू करें",
  "translation-module.FinalTranslationScreen.GET YOUR QUALITY REPORT": "अपनी गुणवत्ता रिपोर्ट प्राप्त करें",

  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translate your package"]: "अपनी पैकेज का अनुवाद करें",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.At the moment, AI-generated translation will be applied to all of your assets."]:
    "वर्तमान में, सभी आपके संपत्तियों पर एआई द्वारा उत्पन्न अनुवाद लागू होगा।",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Please wait..."]: "कृपया प्रतीक्षा करें...",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Selected Language"]: "चयनित भाषा",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.“Translation”"]: "“अनुवाद”",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.is in Progress"]: "काम जारी है",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancel Translation"]: "अनुवाद रद्द करें",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Cancelling Translation"]: "अनुवाद रद्द हो रहा है",
  ["translation-module.FinalTranslationScreen.TranslationLoadingScreen.Translation Cancelled!"]: "अनुवाद रद्द!",

  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content saved!": "सामग्री सहेजी गई!",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Saving failed! Please retry.": "सहेजने में विफल! कृपया पुनः प्रयास करें।",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Content discarded!": "सामग्री त्याग दी गई!",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No recent changes to save.":
    "सहेजने के लिए कोई हाल के मामूली परिवर्तन नहीं हैं।",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.No unsaved changes to discard.": "त्यागने के लिए कोई असहेजीत परिवर्तन नहीं हैं।",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Modify Translated Content": "अनुवादित सामग्री संशोधित करें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save": "सहेजें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard": "त्यागें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Close": "बंद करें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Discard unsaved changes": "असहेजीत परिवर्तनों को त्यागें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Cancel": "रद्द करें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Lose Changes": "परिवर्तनों को खो दें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Unsaved changes": "असहेजीत परिवर्तन",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Save and Exit": "सहेजें और बाहर निकलें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.Exit without Saving": "सहेजे बिना बाहर निकलें",
  "translation-module.FinalTranslationScreen.EditTranslatedTextModal.If you proceed, you will lose all the changes you have made.":
    "यदि आप आगे बढ़ते हैं, तो आप उन सभी परिवर्तनों को खो देंगे जो आपने किए हैं।"
};
