import { CSSProperties, ChangeEventHandler } from "react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";

export default function LabelCheckBox({
  name,
  value,
  checked,
  onChange,
  label,
  labelStyle,
  checkboxStyle,
  ...restProps
}: {
  name?: string;
  value?: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  labelStyle?: CSSProperties;
  checkboxStyle?: CSSProperties;
} & React.InputHTMLAttributes<HTMLInputElement>) {
  let className = restProps.className;

  return (
    <label style={{ display: "flex", alignItems: "center", width: "fit-content", ...labelStyle }}>
      <img
        src={PROJECT_ICONS.C_AI_CHECKBOX_CHECKED_ICON}
        style={{ display: checked ? "block" : "none", width: "16px", height: "16px", marginRight: "5px", ...checkboxStyle }}
      />
      <img
        src={PROJECT_ICONS.C_AI_CHECKBOX_ICON}
        style={{ display: checked ? "none" : "block", width: "16px", height: "16px", marginRight: "5px", ...checkboxStyle }}
      />
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        {...restProps}
        className={(className ? className : "") + " custom-checkbox"}
      />
      {label}
    </label>
  );
}
