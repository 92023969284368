import { FC, useState } from "react";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { Avatar, Space } from "antd";
import Usericon from "../../../Components/Navbar/_Usericon";
import { Link } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../../Routes/_routesConfig";
import { CONTENT_AI_MODULES, CONTENT_AI_MODULE_NAMES } from "../Core/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActiveModuleActions } from "../../../Store/actions/_contentAIActions";
import { MenuI18N } from "../../../Components/i18n";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";

export const ContentAIHeader: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { activeModule } = useSelector((state: any) => state?.contentAIReducer);

  const [open, setOpen] = useState(false);

  const handleClick = (module: string) => {
    setOpen(false);
    activeModule !== module && dispatch(setActiveModuleActions(module));
  };

  return (
    <div className="p-0" style={{ marginLeft: "2.5rem", marginRight: "2.5rem" }}>
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col" style={{ display: "flex", alignItems: "center" }}>
          <Link to={ENUM_ROUTE_URLS.DASHBOARD}>
            <img src={PROJECT_ICONS.C_AI_LOGO} />
          </Link>
          {/* <span className="d-flex flex-direction-column semi-bold-font" style={{ marginTop: "10px" }}>
            Content Creator
          </span> */}
        </div>
        <div className="modules-list">
          {Object.keys(CONTENT_AI_MODULES).map((module: string) => (
            <a key={module} className={activeModule === module ? "active" : ""} onClick={() => handleClick(module)}>
              {/* {CONTENT_AI_MODULE_NAMES[module]} */}

              {
                //@ts-ignore
                t(I18N_MODULE_KEYS[CONTENT_AI_MODULE_NAMES[module]])
              }
            </a>
          ))}
        </div>

        <div className="col right-controls">
          {/* <img src={PROJECT_ICONS.C_AI_NOTIFICATION} width="30" className="mr-3" />
          <img src={PROJECT_ICONS.C_AI_INFO} width="30" className="mr-3" /> */}

          <Space>
            <Avatar src={<Usericon />} />
            <MenuI18N />
          </Space>
        </div>
      </div>
    </div>
  );
};
