import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Button, Tooltip } from "antd";

import { createPromptRequest } from "../../../../Store/requests/_contentAIRequests";
import { setPromptIDAction, setResponseLoaderFlagAction } from "../../../../Store/actions/_contentAIActions";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";

import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { ChatBotInput } from "./ChatBotInput";
import _localStorageService from "../../../../Services/_localStorageService";

type IProps = {
  userPrompt: string;
  setUserPrompt: Function;
  getResult: (prompt_id: number, isEnriched: false) => void;
};

export const GenerateResponse: FC<IProps> = ({ userPrompt, setUserPrompt, getResult }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const values = useMemo(() => {
    return [
      `${t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.howcanihelpyou"])}`,
      `${t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.mynameisline"])}`
    ];
  }, [_localStorageService.getAcceptLanguage()]);

  const handleGenerateResponseClick = async (e: any) => {
    e.preventDefault();

    if (isUserPromptEmpty()) return;

    dispatch(setResponseLoaderFlagAction(true));

    const res = await createPromptRequest({ prompt: userPrompt.trim() });

    if (!res) {
      dispatch(setResponseLoaderFlagAction(false));
      return;
    }

    //setUserPrompt(""); //Clear the User Prompt

    dispatch(setPromptIDAction(res?.id));

    getResult(res?.id, false);
  };

  const isUserPromptEmpty = () => !userPrompt.trim();

  return (
    <>
      <div className="col generate-response-screen">
        <div className="module-visual-block grid-css">
          <div className="chat-bot-box">
            <ChatBotInput values={values} />
            <img className="chat-bot-image" src={PROJECT_ICONS.C_AI_CHAT_BOT_IMAGE} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p className="module-title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentai"])}</p>
            <p className="module-description">
              <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.createpharmacontent"])}</div>
              <div>{t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.complianceandregulation"])}</div>
            </p>
          </div>
        </div>

        <div className="question-block">
          <div className="question-block-title">{t(I18N_MODULE_KEYS["content.contentai.headings.contentai.text.howcanihelpyou"])}</div>
          <div className="question-outer-block">
            <form className="question-input-block" onSubmit={handleGenerateResponseClick}>
              <input
                autoFocus
                type="text"
                placeholder={`${t(I18N_MODULE_KEYS["content.text.enteryourprompt"])}`}
                value={userPrompt}
                onChange={(e) => setUserPrompt(e.target.value)}
              />

              <Tooltip title={isUserPromptEmpty() ? `${t(I18N_MODULE_KEYS["content.text.promptcannotbeempty"])}` : null}>
                <Button type="primary" onClick={handleGenerateResponseClick} disabled={isUserPromptEmpty()}>
                  {t(I18N_MODULE_KEYS["content.button.generate"])}
                </Button>
              </Tooltip>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
