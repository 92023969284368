import { Card, Typography } from "antd";
import { FC } from "react";
import { TileContent, TileProps } from "../Core/types";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const TopAlignedAvatarTile: FC<TileProps> = ({ image, heading, description, tileColor }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: "0px 6px 18px #00000029",
        borderRadius: "12px",
        height: "100%"
      }}
      bodyStyle={{ padding: 0, height: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          gap: "8px",
          alignItems: "center",
          background: tileColor || "#DCEAFF",
          borderRadius: "12px 12px 0 0",
          padding: "15px 20px"
        }}
      >
        <img src={image} width={"24px"} />
        <Title
          level={5}
          style={{
            fontWeight: "700",
            marginBottom: 0
          }}
        >
          {t(heading)}
        </Title>
      </div>

      <div
        style={{
          padding: "20px"
        }}
      >
        <div style={{ fontSize: "12px", display: "flex", flexDirection: "column", gap: "10px" }}>
          {(description as TileContent).map((item, key) => (
            <div key={key}>
              <span style={{ fontWeight: 650 }}>{t(item.heading)}: </span> {t(item.description)}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export { TopAlignedAvatarTile };
