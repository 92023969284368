import { Button, Col, Image, Input, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../../../Components/i18n/core/constants";
const { Paragraph } = Typography;
interface RightCardHeaderFilesSavingProps {
    createnewfolderstatuslocal: Boolean; // Indicates if a new folder creation status is active
    selectedFiles: Array<any>; // Represents the files that have been selected, can be more specific if file structure is known
  }
  

export const RightCardHeaderFilesSaving: React.FC<RightCardHeaderFilesSavingProps> = ({createnewfolderstatuslocal ,selectedFiles}) => {
  const { tabList, filecardType } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  return (
    <>
      <div
        style={{
          border: "1px #BABABA dashed",
          padding: "15px",
          borderRadius: "6px",
          backgroundColor: createnewfolderstatuslocal ? "#F1F1F1" : "white",
          overflowY: "auto",
          maxHeight: "100px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <img src={PROJECT_ICONS.FOLDERFILE} />
            <p style={{ paddingLeft: "10px", paddingTop: "5px", fontSize: "16px", width: "200px" }}>
              {selectedFiles.length} {t(I18N_MODULE_KEYS["semantic.search.filesystem.documentsuploading"])}
            </p>
            <Spin style={{ paddingTop: "5px" }}></Spin>
          </div>
          <div style={{ color: "#034EA2", fontWeight: "700", cursor: "not-allowed", padding: "10px" }}>CANCEL ALL</div>
        </div>
      </div>
    </>
  );
};
