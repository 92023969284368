import { FC } from "react";
import { Card, Typography, Watermark } from "antd";

type IProps = {
  bgImage: string;
  heading: string;
  description: string;
};

const { Title, Paragraph, Text } = Typography;

const DashBoardBanner: FC<IProps> = ({ bgImage, heading, description }) => {
  return (
    <Card
      style={{
        backgroundImage: `url("${bgImage}")`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Title
        level={5}
        style={{
          fontStyle: "normal normal bold 24px/32px Nunito Sans",
          marginTop: "5px",
          color: "white"
        }}
      >
        {heading || ""}
      </Title>
      <Paragraph style={{ fontSize: "12px", color: "white" }}>{description || ""}</Paragraph>
    </Card>
  );
};

export { DashBoardBanner };
